import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SampleFormatter } from "@app/evaluation/sample-formatter.service";
import { HostNavigator } from "@core/browser";
import { Sample, Status } from '@core/data';
import { TastingSample } from "@core/data/types/tasting-sample";
import { BehaviorSubject, combineLatest, forkJoin, Observable, Observer, of } from "rxjs";
import { catchError, debounceTime, map, startWith, switchMap } from "rxjs/operators";
import { TastingsSampleLabelOptions } from './tastings-sample-label-options';


@Component({
    selector: 'pv-tastings-sample-labels',
    templateUrl: 'tastings-sample-labels.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['tastings-sample-labels.component.scss'],
    host: {
        class: 'pv-tastings-sample-labels'
    }
})
export class TastingsSampleLabelsComponent implements OnInit, OnDestroy{

    _samples$ = new BehaviorSubject<TastingSample[]>([]);
    _options$ = new BehaviorSubject<TastingsSampleLabelOptions>({
        layout: 'port',
        size: 'md',
        includeFields: [],
        margin: '5mm',
        whitespace: false
    });

    state$: Observable<TastingsSampleLabelState>;

    @Input()
    set options(options: TastingsSampleLabelOptions){
        this._options$.next(options);
    }

    @Input()
    set samples(samples: TastingSample[]){
        this._samples$.next(samples);
    }

    constructor(
        private _hostNav: HostNavigator,
        private _changeDetector: ChangeDetectorRef,
        private _sampleFormatter: SampleFormatter
    ) {}

    ngOnInit() {

        // listen for changes
        this.state$ = combineLatest(this._samples$, this._options$)
            .pipe(
                debounceTime(100),
                switchMap(changes => {

                    let [samples, options] = changes;

                    if(Array.isArray(samples) && samples.length > 0){
                        // translate samples to label data
                        let labels = samples.map(sample => {
                            return this.getLabelData(sample, options);
                        });
                        return this.loadImages(labels, options);
                    }else{
                        return of({
                            labels: [],
                            options,
                            status: Status.EMPTY
                        });
                    }

                })
            );

    }

    retry(){
        this._samples$.next(this._samples$.getValue());
    }

    ngOnDestroy(){
        this._samples$.complete();
        this._options$.complete();
    }

    private loadImages(labels: LabelData[], options: TastingsSampleLabelOptions): Observable<TastingsSampleLabelState> {

        let loaders = labels.map(label => {
            return this.loadImage(label);
        });

        return forkJoin(loaders).pipe(
            map(labels => {
                return {
                    labels,
                    options,
                    status: Status.OK
                };
            }),
            catchError(err => {
                return of({
                    labels: [],
                    status: Status.ERROR,
                    options: null
                });
            }),
            startWith({
                labels: [],
                status: Status.LOADING,
                options: null
            })
        );

    }

    private loadImage(label: LabelData): Observable<LabelData>{

        return Observable.create((observer: Observer<LabelData>) => {

            const img = new Image();

            img.onload = () => {
                observer.next(label);
                observer.complete();
            };
            img.onerror = (e) => {
                // img.src = '';
                observer.error(e);
            };
            img.src = label.src;

            return () => {
                // img.src = '';
            };
        });
    }


    private getLabelData(sample: TastingSample, options: TastingsSampleLabelOptions): LabelData{

        const headings: string[] = [];
        const lines: string[] = [];

        if(Array.isArray(options.includeFields)){
            options.includeFields.forEach(field => {

                switch(field){
                    case 'label':
                        lines.push(sample.label);
                        break;
                        //WIP: Fix eloquent relationship to obtain TastingsEvent from TastingSample
                    case 'eventTitle':
                        lines.push(sample.label);
                        break;
                    case 'venueName':
                        lines.push(sample.label);
                        break;
                }


            });
        }

        return {
            src: this.getLabelSrc(sample.code),
            code: sample.code,
            headings,
            lines,
        };
    }

    private getLabelSrc(code: string) {
        return this._hostNav.hostUrl('barcode/image/' + encodeURIComponent(code) + '.svg');
    }

}

interface TastingsSampleLabelState {
    status: Status;
    options: TastingsSampleLabelOptions;
    labels: LabelData[];
}

interface LabelData {
    src: string;
    code: string;
    headings: string[];
    lines: string[];
}

