import { CultevaModuleDictionary } from '@core/modules';
import { Module } from '@core/modules/_types';
import { Dictionary } from 'library/src/dictionary';
import { CULTEVA_MENU_DEFAULT, CULTEVA_MENU_GROUPS, CULTEVA_MENU_ITEMS } from './menu';
import { Menu, MenuGroup, MenuItem } from './_types';

//WIP
export class CultevaMenu {
    public items   = new CultevaMenuItemDictionary();
    public groups  = new CultevaMenuGroupDictionary();
    public modules = new CultevaModuleDictionary();

    //helper functions
    moduleIdByGroupId(groupId: string) {
        let moduleId = this.groups.get(groupId).module;
        if(!moduleId) return null;
        else return this.modules.get(moduleId);
    }

    groupByModuleId(moduleId: string): MenuGroup {
        return this.groups.all().find(group => {
            return group.module === moduleId;
        });
    }

    listAllModulesNames() : string[] {
        let list = [];
        this.modules.serialize().forEach(module => {
            list.push(module.name);
        });;
        return list;
    }

    listAllModules(): Module[] {
        let mods: Module[] = [];
        this.modules.serialize().forEach(module => {
            mods.push(module);
        });;
        return mods;
    }

    generateMenu(moduleIds: string[]): CultevaMenuModel {
        //get default menu groups ids and item ids
        let defaultMenuIds: Menu = {
            ...CULTEVA_MENU_DEFAULT
        };

        //create empty CultevaMenuModel
        let generatedMenu: CultevaMenuModel = { groups: [] };

        //For each default startMenuGroupId, add groups to menu object
        defaultMenuIds.startMenuGroupIds.forEach(menuGroupId => {
            generatedMenu.groups.push(this.groups.get(menuGroupId));
        });

        //For each module subscribed to, mark group as subscribed
        moduleIds.forEach(moduleId => {
            this.groups.upateSubscribed(moduleId, true);
        });

        //For each module, add groups to menu object
        this.listAllModules().forEach(module => {
            generatedMenu.groups.push(this.groupByModuleId(module.id));
        });

        //For each default endMenuGroupId, add groups to menu object
        defaultMenuIds.endMenuGroupIds.forEach(menuGroupId => {
            generatedMenu.groups.push(this.groups.get(menuGroupId));
        });

        //For each group in menu object, replace id with MenuItem
        let generatedMenuCopy: CultevaMenuModel = JSON.parse(JSON.stringify(generatedMenu))
        generatedMenuCopy.groups.forEach(group => {
            for (let j = 0; j < group.menuItems.length; j++) {
                let tempId = group.menuItems[j] as string;
                //If group is not subscribed to, mark externalPath as true (navigation to landing page)
                this.items.updateExternalPath(tempId, group.subscribed, true);
                group.menuItems[j] = this.items.get(tempId) as MenuItem;
            }
        });

        generatedMenu = generatedMenuCopy;
        return generatedMenu;
    }

    serialize(): CultevaMenuModel{
        return {
            groups: this.groups.serialize(),
        };
    }


}
export interface CultevaMenuModel {
    groups: MenuGroup[];

}

//Dictionary Constructor declarations
export class CultevaMenuItemDictionary extends Dictionary<MenuItem> {
    constructor(){
        super(CULTEVA_MENU_ITEMS);
    }

    //The following items will always route to external paths
    alwaysExternal: string[] = [
        'support',
        'user_manual',
        'contact_support',
    ];

    updateExternalPath(itemId, subscribed, value) {
        let itemCopy = { ...this._items.find(item => item.id === itemId) }
        itemCopy.externalPath = false;

        if (!subscribed) itemCopy.externalPath = true;
        if (this.alwaysExternal.includes(itemCopy.id)) itemCopy.externalPath = true;

        let items = this._items.map(item => {
            if (item.id === itemCopy.id) {
                item = itemCopy;
            }

            return item;
        })

        this._items = items;
    }
}

export class CultevaMenuGroupDictionary extends Dictionary<MenuGroup> {
    constructor(){
        super(CULTEVA_MENU_GROUPS);
    }

    upateSubscribed(moduleRef, value) {
        let itemCopy = { ...this._items.find(menuGroup => menuGroup.module === moduleRef) };
        itemCopy.subscribed = value;

        let items = this._items.map(item => {
            if (item.module === moduleRef) {
                item = itemCopy;
            }

            return item;
        });

        this._items = items;
    }
}


