import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URI } from '../http/api';
import { Collection } from '../http/collection';
import { Filter, FilterBuilder } from '../http/filter';
import { SearchRequest, SearchResponse } from '../http/search';
import { Site } from '../types/site';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Store } from '@ngxs/store';


export class SiteUpdated {
    static readonly type = "[SiteService] Site Updated";
    constructor(public site: Site){}
}

export class SiteDeleted {
    static readonly type = "[SiteService] Site Deleted";
    constructor(public site: Site){}
}
export class SiteCreated {
    static readonly type = "[SiteService] Site Created";
    constructor(public site: Site){}
}

export class SiteIndexImported {
    static readonly type = "[SiteService] Site Index Imported";
    constructor(public response: SiteImportResponse){}
}

export interface SiteImportResponse {
    response: string;
}
export interface SiteExportResponse {}

@Injectable()
export class SiteService {

    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string,
        private _store: Store
    ){}

    query(filter: Filter) {
        let params = (new FilterBuilder(filter)).getQueryParams();
        return this._http.get<Collection<Site>>(`${this._baseUri}/sites`, { params });
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<Site>>(`${this._baseUri}/sites/search`, {params: <any>search});
    }

    get(key: string) {
        return this._http.get<Site>(`${this._baseUri}/sites/${key}`);
    }

    create(model: Partial<Site>) {
        return this._http.post<Site>(`${this._baseUri}/sites`, model)
            .pipe(tap(result => this._store.dispatch(new SiteCreated(result))));
    }

    update(key: string, model: Partial<Site>) {
        return this._http.put<Site>(`${this._baseUri}/sites/${key}`, model)
            .pipe(tap(result => this._store.dispatch(new SiteUpdated(result))));
    }

    delete(key: string) {
        return this._http.delete<Site>(`${this._baseUri}/sites/${key}`)
                    .pipe(
                        catchError(e => {
                            if(e instanceof HttpErrorResponse && e.status === 400 && e.error.message === 'has_samples'){
                                return throwError(new SiteConnectionsError(e));
                            }
                            return throwError(e);
                        }),
                        tap(result => this._store.dispatch(new SiteDeleted(result)))
                    );
    }

    import(orgKey: string, file: File) {
        let data = new FormData();
        data.set('file', file);
        data.set('ownerOrgKey', orgKey);

        return this._http.post<SiteImportResponse>(`${this._baseUri}/sites/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new SiteIndexImported(result));
            }));
    }

    downloadImportTemplate() {
        return this._http.get<Blob>(`${this._baseUri}/sites/import/template`, {
            responseType: 'blob' as 'json',
        });
    }

    export(filter: Filter, ownerOrgKey: string, type = 'xlsx') {
        let params = (new FilterBuilder(filter)).getQueryParams();
        return this._http.get<SiteExportResponse>(`${this._baseUri}/sites/export`, { params: {...params, type, ownerOrgKey}});
    }
}

export interface SiteFormDialogData {
    key?: string;
    defaults?: Partial<Site>;
}

export class SiteConnectionsError extends HttpErrorResponse {}