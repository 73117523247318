import { FilterPage, FilterSort, Status, Tag, WeightedChar } from "@core/data";

export interface TastingCustomTagsIndexStateModel {
    // org context
    orgKey: string;

    // page data
    status: Status;
    data: Tag[];
    total: number;

    // data options
    displayColumns: string[];
    sortOptions: {id: string, label: string}[];
    pageSizeOptions: number[];
    weightedCharOptions: WeightedChar[];

    // filter options
    page: FilterPage;
    sort: FilterSort;
    query: TastingCustomTagsQuery;

    // selection
    selectedKeys: string[];
    selectedData: Tag[];
}

export interface TastingCustomTagsQuery {
    search: string;
    weightedCharKeys: string[];
}

export const TASTING_CUSTOM_TAG_SORT_OPTIONS: {id: string, label: string}[] = [
    { id: 'label', label: 'Label' },
    { id: 'updatedAt', label: 'Updated Date' },
    { id: 'createdAt', label: 'Created Date' },
];

export const TASTING_CUSTOM_TAG_DISPLAY_COLUMNS = [
    'checkbox',
    'label',
    'weightedCharsCount',
    'actions',
    'timestamps'
];

export const DEFAULTS: TastingCustomTagsIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'updatedAt',
        order: 'desc'
    },
    displayColumns: TASTING_CUSTOM_TAG_DISPLAY_COLUMNS,
    sortOptions: TASTING_CUSTOM_TAG_SORT_OPTIONS,
    pageSizeOptions: [5, 10, 20, 50, 100, 500],
    weightedCharOptions: [],
    query: {
        search: null,
        weightedCharKeys: [],
    },
    selectedKeys: [],
    selectedData: [],
};