import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { WeatherStation, ReportWeather } from '@core/data';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Snackbar } from '@core/material';
import { LIBRARY } from '@app/evaluation/library';
import { Library, WeatherReport } from '@library';
import { coerceTimestampProperty } from '@core/utils';



export interface ReportWeatherFormDialogData {
    stations: WeatherStation[];
    data: Partial<ReportWeather>;
}


@Component({
    selector: 'pv-report-weather-form-dialog',
    templateUrl: 'report-weather-form.dialog.html',
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportWeatherFormDialog implements OnInit, OnDestroy {


    formGroup = new FormGroup({
        id: new FormControl(null),
        title: new FormControl(null, [Validators.required]),
        stationKey: new FormControl(null, [Validators.required]),
        reportId: new FormControl(null, [Validators.required]),
        startDate: new FormControl(null, [Validators.required]),
        endDate: new FormControl(null, [Validators.required]),
        interval: new FormControl(null, [Validators.required]),
    });

    reportOptions: WeatherReport[];
    stationOptions: WeatherStation[];

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportWeatherFormDialogData,
        private _ref: MatDialogRef<ReportWeatherFormDialog>,
        private _snackbar: Snackbar,
        @Inject(LIBRARY) private _library: Library,
    ){}


    ngOnInit(){

        this.reportOptions = this._library.weatherReports.all();
        this.stationOptions = this._data.stations;

        this.formGroup.reset({
            id: this._data.data.id,
            title: this._data.data.title,
            stationKey: this._data.data.stationKey,
            reportId: this._data.data.reportId,
            startDate: this._data.data.startDate,
            endDate: this._data.data.endDate,
            interval: this._data.data.interval,
        });
    }


    ngOnDestroy(){

    }


    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.valid){

            const form = this.formGroup.value;

            const cast = {
                id: this._data.data.id,
                title: form.title,
                stationKey: form.stationKey,
                reportId: form.reportId,
                startDate: coerceTimestampProperty(form.startDate, null, true),
                endDate: coerceTimestampProperty(form.endDate, null, true),
                interval: form.interval,
            };

            this._ref.close(cast);
        }else{
            this._snackbar.formInvalid();
        }

    }


    cancel(){
        this._ref.close();
    }

}