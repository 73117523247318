import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TastingSample } from "@core/data/types/tasting-sample";
import { Dialog, Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import { Subject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CultivarTastingsSampleFormState, CultivarTastingsSampleFormStateModel, InitCultivarTastingsSampleForm, SubmitCultivarTastingSampleForm } from './cultivar-tastings-sample-form.state';
import { Cultivar, Site, SiteFormDialogData, Status, TastingsSampleCultivarBulkActionRow } from '@core/data';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteFormDialog } from '../site-form/site-form.dialog';
import { coerseDateProperty } from '@core/utils';
import * as moment from 'moment';

export interface CultivarTastingsSampleFormDialogData {
    cultivarKeys: string[];
    seletedCultivars: Cultivar[];
    defaults?: Partial<TastingSample>;
    hasEvaluationModule: boolean;
}

interface FormRow {
    siteKey: Site;
    cultivarKey: string;
    harvestDate: string;
    connectHarvestSample: boolean;
}

@Component({
    selector: 'pv-cultivar-tasting-sample-form-dialog',
    templateUrl: 'cultivar-tastings-sample-form.dialog.html',
    styleUrls: ['_cultivar-tastings-sample-form.theme.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class CultivarTastingsSampleFormDialog implements OnInit, OnDestroy {
    @Select(CultivarTastingsSampleFormState)
    state$: Observable<CultivarTastingsSampleFormStateModel>;

    @Select(CultivarTastingsSampleFormState.data)
    data$: Observable<Partial<TastingSample>>;

    private _destroy$ = new Subject();

    // Global site control is used to set all sites to same value
    globalSiteControl = new FormControl(null);
    // Global harvest date control used to set all harvest dates to same value
    globalHarvestDateControl = new FormControl(null);
    ownerOrgKeyControl = new FormControl(null);
    globalConnectHarvestControl = new FormControl();

    formArray = new FormArray([]);
    formGroup: FormGroup = new FormGroup({
        cultivarSampleRows: this.formArray,
        globalSite: this.globalSiteControl,
        globalHarvestDate: this.globalHarvestDateControl,
        globalConnectHarvest: this.globalConnectHarvestControl,
        ownerOrgKey: this.ownerOrgKeyControl,
    });

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<CultivarTastingsSampleFormDialog>,
        private _dialog: Dialog,
        private _formBuilder: FormBuilder,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: CultivarTastingsSampleFormDialogData,
    ) { }

    ngOnInit(): void {
        this._store.dispatch(
            new InitCultivarTastingsSampleForm(
                this.data.cultivarKeys,
                this.data.seletedCultivars,
                this.data.defaults
            )
        );

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close();
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });

        // Set all sites to same value if globalSiteControl value changes
        this.globalSiteControl.valueChanges
            .subscribe(value => {
                this.formArray.controls.forEach((control, i) => {
                    control.setValue({...this.formArray.getRawValue()[i], siteKey: value});
                })
            });

        // Set all harvest dates to same value if globalHarvestDateControl value changes
        this.globalHarvestDateControl.valueChanges
            .subscribe(value => {
                this.formArray.controls.forEach((control, i) => {
                    control.setValue({...this.formArray.getRawValue()[i], harvestDate: value});
                })
            });

        // Set all connect harvest checkboxes to same value if globalConnectHarvestControl value changes
        this.globalConnectHarvestControl.valueChanges
            .subscribe(value => {
                this.formArray.controls.forEach((control, i) => {
                    control.setValue({...this.formArray.getRawValue()[i], connectHarvestSample: value});
                })
            });
    }

    reset(model: Partial<TastingSample>): void {
        let data = {
            cultivarSampleRows: this.buildCultivarSampleRows(this.data.seletedCultivars),
            ownerOrgKey: model.ownerOrgKey,
        }

        if (!this.data.hasEvaluationModule) this.globalConnectHarvestControl.disable()

        this.formGroup.reset(data);
    }

    addSite(event: MouseEvent, index?: number) {

        event.stopPropagation();

        const data: SiteFormDialogData = {
            defaults: {
                ownerOrgKey: this.ownerOrgKeyControl.value,
            }
        };

        this._dialog.open(SiteFormDialog, { data })
            .afterClosed()
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if (result) {
                    if (index != null) this.currentSiteControl(index).setValue(result);
                    else this.globalSiteControl.setValue(result);
                }
            });
    }

    buildCultivarSampleRows(cultivars: Cultivar[]) {
        cultivars.forEach(cultivar => {
            this.addCultivarSampleRow(cultivar.key);
        });

        return this.cultivarSampleRows().value;
    }

    newCultivarSampleRow(key: string): FormGroup {
        return new FormGroup({
            cultivarKey: new FormControl(key, [Validators.required]),
            siteKey: new FormControl(null, [Validators.required]),
            harvestDate: new FormControl(null),
            connectHarvestSample: new FormControl({value: false, disabled: !this.data.hasEvaluationModule}),
        });
    }

    addCultivarSampleRow(cultivarKey: string): void {
        this.cultivarSampleRows().push(this.newCultivarSampleRow(cultivarKey));
    }

    cultivarSampleRows(): FormArray {
        return this.formGroup.get('cultivarSampleRows') as FormArray;
    }

    currentSiteControl(index: number): FormControl {
        return this.cultivarSampleRows().at(index).get('siteKey') as FormControl;
    }

    getCultivarSampleRows(data: FormRow[]): TastingsSampleCultivarBulkActionRow[] {
        let rows: TastingsSampleCultivarBulkActionRow[] = [];
        data.forEach(entry => {
            rows.push({
                ...entry,
                siteKey: entry.siteKey.key,
                harvestDate: entry.harvestDate ? coerseDateProperty(entry.harvestDate) : coerseDateProperty(moment()),
                connectHarvestSample: entry.connectHarvestSample,
            });
        })

        return rows;
    }

    cancel(): void {
        this._dialogRef.close();
    }

    attempt(): void {
        const form = this.formGroup.value;

        let data = {
            ownerOrgKey: form.ownerOrgKey,
            cultivarSampleRows: this.getCultivarSampleRows(form.cultivarSampleRows)
        }

        this.save(data.ownerOrgKey, data.cultivarSampleRows);
    }

    save(ownerOrgKey: string, cultivarSampleRows: TastingsSampleCultivarBulkActionRow[]): void {
        this._store.dispatch(new SubmitCultivarTastingSampleForm(ownerOrgKey, cultivarSampleRows));
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}