import { AbstractControl, ValidationErrors } from "@angular/forms";
import { decimalLength } from '../number';

export class CoordinateValidators {

    static lat() {

        return (control: AbstractControl): ValidationErrors | null => {

            let value = control.value;

            if (value === null || value === "") return null;

            if (typeof value === "string") {
                if (!value.match(/^-?\d*(\.\d+)?$/)) return { 'coordinate': true };
                value = parseFloat(value);
            } else if (typeof value === "number") {

            } else {
                return { 'coordinate': true };
            }

            if (isNaN(value) || decimalLength(value) > 10 || value < -90 || value > 90) return { 'coordinate': true };

            return null;
        };
    }

    static lng() {

        return (control: AbstractControl): ValidationErrors | null => {

            let value = control.value;

            if (value === null || value === "") return null;

            if (typeof value === "string") {
                if (!value.match(/^-?\d*(\.\d+)?$/)) return { 'coordinate': true };
                value = parseFloat(value);
            } else if (typeof value === "number") {

            } else {
                return { 'coordinate': true };
            }

            if (isNaN(value) || decimalLength(value) > 10 || value < -180 || value > 180) return { 'coordinate': true };

            return null;
        };
    }
}