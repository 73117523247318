import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileUpdateRequest, Status, User } from '@core/data';
import { Snackbar } from '@core/material';
import { COUNTRIES } from '@core/utils/countries';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthProfileFormState, AuthProfileFormStateModel, InitAuthProfileForm, SubmitAuthProfileForm } from './auth-profile-form.state';

@Component({
    selector: 'pv-auth-profile-form-dialog',
    templateUrl: 'auth-profile-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthProfileFormDialog implements OnInit, OnDestroy {

    @Select(AuthProfileFormState)
    state$: Observable<AuthProfileFormStateModel>;

    @Select(AuthProfileFormState.data)
    data$: Observable<User>;

    nameControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(64)]);
    addrCountryControl = new FormControl(null, [Validators.required]);
    emailControl = new FormControl({ value: null, disabled: true });
    passwordCurrentControl = new FormControl(null, [Validators.required]);
    passwordControl = new FormControl(null, [Validators.required, Validators.minLength(8)]);
    passwordConfirmationControl = new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        control => {
            if (control.value !== this.passwordControl.value) {
                return { 'unmatched': true };
            }
            return null;
        }
    ]);

    updatePasswordControl = new FormControl(false);

    formGroup: FormGroup = new FormGroup({
        name: this.nameControl,
        addrCountry: this.addrCountryControl,
        email: this.emailControl,
        _currentPassword: this.passwordCurrentControl,
        _password: this.passwordControl,
        _passwordConfirmation: this.passwordConfirmationControl,
        _updatePassword: this.updatePasswordControl
    });

    countries = COUNTRIES;

    private _destroy$ = new Subject();

    constructor(
        private _dialogRef: MatDialogRef<void>,
        private _store: Store,
        private _snackbar: Snackbar
    ) { }

    ngOnInit() {

        this.reload();

        this.updatePasswordControl
            .valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(updatePass => {
                this.togglePasswordUpdate(updatePass);
            });

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if (state.status === Status.COMPLETE) {
                    this._snackbar.info("Profile updated");
                    this._dialogRef.close();
                } else if (state.status !== Status.LOADING && state.data) {
                    this.formGroup.enable();
                    this.emailControl.disable();
                    if (state.errors.invalid_credentials) {
                        this.passwordCurrentControl.setErrors({ invalid_credentials: true });
                    }
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                this.reset(data);
            });
    }

    /**
     *
     * @param data user, to reset the form with
     */
    reset(data: User) {
        if (data) {
            this.formGroup.reset({
                name: data.name,
                email: data.email,
                addrCountry: data.addrCountry
            });
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt() {

        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid) {

            let form = this.formGroup.value;

            let request: ProfileUpdateRequest = {
                name: form.name,
                addrCountry: form.addrCountry,
            };

            if (form._updatePassword) {
                request = {
                    ...request,
                    _currentPassword: form._currentPassword,
                    _password: form._password,
                };
            }

            this._store.dispatch(new SubmitAuthProfileForm(form));

        } else {
            this._snackbar.error("Check your input and try again.");
        }
    }

    reload() {
        this._store.dispatch(new InitAuthProfileForm());
    }

    cancel() {
        this._dialogRef.close();
    }

    /**
     *
     * @param update boolean whether password should be updated or not
     */
    togglePasswordUpdate(update: boolean) {
        if (update) {
            this.passwordCurrentControl.enable();
            this.passwordControl.enable();
            this.passwordConfirmationControl.enable();
        } else {
            this.passwordCurrentControl.disable();
            this.passwordControl.disable();
            this.passwordConfirmationControl.disable();
        }

    }

}