import { NgModule } from '@angular/core';

import { DocumentRef } from './document-ref';
import { WindowRef } from './window-ref';
import { SessionStorage } from './session-storage';
import { LocalStorage } from './local-storage';
import { HostNavigator } from './host-navigator';

import { HostLinkDirective } from './components/host-link.directive';

@NgModule({
    imports: [],
    exports: [
        HostLinkDirective
    ],
    declarations: [
        HostLinkDirective
    ],
    providers: [
        HostNavigator,
        DocumentRef,
        WindowRef,
        SessionStorage,
        LocalStorage
    ],
})
export class AppBrowserModule { }