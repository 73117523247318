import { Injectable } from "@angular/core";
import { Sample, Status, TastingSampleService, translateCommonErrorStatus } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

//State model
export interface BulkTasteSampleFormStateModel {
    status: Status;
    data: Sample[];
}

//State defaults
const DEFAULTS: BulkTasteSampleFormStateModel = {
    status: Status.UNINITIALIZED,
    data: null,
}

//State actions
export class InitNewBulkTasteSampleForm {
    static readonly type = "[BulkTasteSampleForm] Init New";
    constructor(public defaults?: Sample[]) {}
}

export class SubmitBulkTasteSampleForm {
    static readonly type = "[BulkTasteSampleForm] Submit";
    constructor(public data: Partial<TastingSample>) {}
}

@State<BulkTasteSampleFormStateModel>({
    name: 'bulk_taste_sample_form_state',
    defaults: DEFAULTS
})
@Injectable()
export class BulkTasteSampleFormState {
    @Selector()
    static data(state: BulkTasteSampleFormStateModel) {return state.data}

    constructor(
        private _tastingSampleService: TastingSampleService
    ) {}

    @Action(InitNewBulkTasteSampleForm, {cancelUncompleted: true})
    initNewBulkTasteSampleForm(ctx: StateContext<BulkTasteSampleFormStateModel>, action: InitNewBulkTasteSampleForm) {
        ctx.setState({
            data: action.defaults,
            status: Status.OK
        });
    }

    @Action(SubmitBulkTasteSampleForm)
    submitBulkTasteSampleForm(ctx: StateContext<BulkTasteSampleFormStateModel>, action: SubmitBulkTasteSampleForm) {
        const state = ctx.getState();

        let request: Observable<TastingSample>;
        request = this._tastingSampleService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }
}