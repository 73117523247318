import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { TimelineGroupDirective } from './timeline-group.directive';
import { TimelineItemDirective } from './timeline-item.directive';



@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TimelineComponent,
        TimelineGroupDirective,
        TimelineItemDirective,
    ],
    exports: [
        TimelineComponent,
        TimelineGroupDirective,
        TimelineItemDirective,
    ]
})
export class TimelineModule {}