import { User } from './user';

export interface OrganizationUser extends User{
    access: UserOrganizationAccess;
}

export interface UserOrganizationAccess {
    role: string;
    isPrimary: boolean;
}

export interface OrganizationInvite {
    id: string;
    userName: string;
    userEmail: string;
    userRole: string;
    orgKey: string;
    expiresAt: string;
    acceptedAt: string;
    createdAt: string;
    updatedAt: string;
}

export const ROLE_LABELS = [
    {
        id: "r",
        label: "Read-Only",
    },
    {
        id: "rw",
        label: "Evaluator",
    },
    {
        id: "admin",
        label: "Admin",
    },
    {
        id: "owner",
        label: "Owner",
    },
    {
        id: "guest",
        label: "Guest",
    },
];