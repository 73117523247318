import { Component } from '@angular/core';
import { HostNavigator } from '@core/browser';


@Component({
    selector: 'pv-barcode-dialog',
    template: `
        <button mat-icon-button matDialogClose><mat-icon>clear</mat-icon></button>
        <img [src]="src" style="width: 100%;">
        <h3 class="ta-c mat-code">{{ _code }}</h3>
    `
})
export class BarcodeDialogComponent {

    _code: string;
    src: string;

    set code(code: string) {
        this._code = code;
        this.src = this._hostNav.hostUrl('barcode/image/' + encodeURIComponent(code) + '.svg');
    }

    constructor(private _hostNav: HostNavigator) { }



}