import { Pipe, PipeTransform, Inject } from '@angular/core';
import {CustomProtocolService} from "@core/data";

@Pipe({
  name: 'labelProtocol'
})
export class LabelProtocolPipe implements PipeTransform {

  constructor(@Inject(CustomProtocolService) private _custom_protocolService: CustomProtocolService){}

  transform(id: string, withDescription = false) {

    if (!id) return '';

    let item = this._custom_protocolService.allProtocols().get(id);

    if (item){

      if(withDescription){
        return `${item.title}: ${item.description}`;
      }else{
        return item.title;
      }

    }else{
      return 'Unknown Protocol';
    }
  }
}
