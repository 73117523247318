import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { CropGroup } from "../../types/EvaluationLibrary/crop-group";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class CropGroupCreated {
    static readonly type = "[CropGroupService] Crop Group Created";
    constructor(public cropGroup: CropGroup) {}
}

export class CropGroupUpdated {
    static readonly type = "[CropGroupService] Crop Group Updated";
    constructor(public cropGroup: CropGroup) {}
}

export class CropGroupDeleted {
    static readonly type = "[CropGroupService] Crop Group Deleted";
    constructor(public cropGroup: CropGroup) {}
}

@Injectable()
export class CropGroupService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) {}

    get(key: string, detail?: DetailRequest): Observable<CropGroup> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<CropGroup>(`${this._baseUri}/evaluation-library/crop-group/${key}`, {params});
    }

    create(model: Partial<CropGroup>) {
        return this._http.post<CropGroup>(`${this._baseUri}/evaluation-library/crop-group`, model)
            .pipe(tap(cropGroup => this._store.dispatch(new CropGroupCreated(cropGroup))));
    }

    update(key: string, model: Partial<CropGroup>) {
        return this._http.put<CropGroup>(`${this._baseUri}/evaluation-library/crop-group/${key}`, model)
            .pipe(tap(cropGroup => this._store.dispatch(new CropGroupCreated(cropGroup))));
    }

    delete(key: string) {
        return this._http.delete<CropGroup>(`${this._baseUri}/evaluation-library/crop-group/${key}`)
            .pipe(tap(cropGroup => this._store.dispatch(new CropGroupCreated(cropGroup))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<CropGroup>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<CropGroup>>(`${this._baseUri}/evaluation-library/crop-group`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<CropGroup>>(`${this._baseUri}/evaluation-library/crop-group/search`, {params: <any>search});
    }
}