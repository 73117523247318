<pv-view #view
    *ngIf="state$ | async as state"
    toolbarTitle="Indexes">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools>
        <button mat-icon-button
                matTooltip="Indexes Index Actions"
                [matMenuTriggerFor]="indexesIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #indexesIndexMenu>
            <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                <button mat-menu-item (click)="add()">
                    <mat-icon>add</mat-icon>
                    <span>Add Index</span>
                </button>
            </ng-container>
            <button mat-menu-item (click)="reload()">
                <mat-icon>refresh</mat-icon>
                <span>Reload Index</span>
            </button>
            <button mat-menu-item (click)="view.togglePrintMode()">
                <mat-icon>print</mat-icon>
                <span>Print Preview</span>
            </button>
            <button mat-menu-item (click)="navigateToSupport()">
                <mat-icon>help_outline</mat-icon>
                <span>Support/Help</span>
            </button>
        </mat-menu>
    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="tastings-custom-indexes-selection-toolbar mat-elevation-z3" color="accent"
        *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Custom Indexes Selected
        </span>

        <button mat-flat-button matTooltip="Delete selected indexes" color="warn" (click)="deleteSelected(state.selectedData)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->

    <!-- SIDEBAR -->
    <div pv-view-sidebar *ngIf="selectedOrg$ | async as selectedOrg">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex">

            <!-- FILTER TAB -->
            <mat-tab label="Filter">

                <ng-template matTabContent>
                    <form class="pv-form single-column dense p-12 tastings-custom-indexes-index-form"
                          [formGroup]="queryFormGroup">

                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>search</mat-icon> Search Indexes
                            </mat-label>
                            <input type="text" matInput formControlName="search"
                                   placeholder="Enter Index title">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Crop</mat-label>
                            <mat-select formControlName="cropKeys" multiple>
                                <mat-option *ngFor="let crop of state.cropOptions" [value]="crop.key">
                                    <mat-icon [svgIcon]="'common:' + crop.legacyId"></mat-icon>
                                    <span>{{ crop.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Weighted Characteristic</mat-label>
                            <mat-select formControlName="weightedCharKeys" multiple>
                                <mat-option *ngFor="let char of state.weightedCharOptions" [value]="char.key">
                                    <span>{{ char.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">

                            <button mat-button type="button" color="warn"
                                    (click)="resetFilter()">
                                Clear Filters
                            </button>
                        </div>

                    </form>

                </ng-template>

            </mat-tab>

            <!-- EXPORT TAB -->
            <!-- <mat-tab label="Export">

                <ng-template matTabContent>
                    <form [formGroup]="exportFormGroup"
                          class="pv-form single-column p-12 sample-export-form"
                          (ngSubmit)="export()">

                        <ng-container *ngIf="filterSelectedLabels">
                            <div class="form-subheader text-primary">
                                Samples Selected
                            </div>
                            <div class="form-caption">
                                Only selected samples will be exported.
                                Clear selection to export all samples in current filter.
                            </div>
                        </ng-container>

                        <mat-form-field appearance="fill">
                            <mat-label>File Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="xlsx">Microsoft Excel (.xlsx)</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">

                            <button mat-flat-button color="accent" type="submit"
                                    [disabled]="exportFormGroup.disabled">Export</button>
                        </div>
                    </form>

                    <mat-action-list dense>
                        <mat-divider></mat-divider>

                        <ng-container *ngFor="let export of state.exports; trackBy: trackByKey"
                                      [ngSwitch]="export.status">
                            <a mat-list-item [href]="export.url" target="_blank"
                               *ngSwitchCase="'COMPLETE'">
                                <mat-icon matListIcon>email</mat-icon>
                                <span matLine>Export Queued</span>
                                <span matLine>Check your email inbox for result.</span>
                            </a>
                            <button type="button" mat-list-item *ngSwitchCase="'LOADING'">
                                <mat-icon matListIcon>queue</mat-icon>
                                <span matLine>Queueing Export...</span>
                                <mat-spinner [diameter]="18"></mat-spinner>
                            </button>
                            <button type="button" mat-list-item *ngSwitchDefault>
                                <mat-icon matListIcon color="warn">error</mat-icon>
                                <span matLine>Export Failed</span>
                                <span matLine>{{ export.status | descStatusMessage }}</span>
                            </button>
                        </ng-container>

                    </mat-action-list>
                </ng-template>

            </mat-tab> -->

            <!-- BULK ACTION TAB -->
            <!-- <mat-tab label="Bulk Action">
                <ng-container *matTabContent>
                    <mat-action-list class="station-detail-actions" dense>
                        <h2 matSubheader>Event Actions</h2>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <mat-list-item (click)="$event.preventDefault(); bulkAddToEvent(state.selectedData)">
                                <mat-icon matListIcon svgIcon="common:add_sample"></mat-icon>
                                <span matLine>Add Samples to Event</span>
                            </mat-list-item>

                            <mat-list-item (click)="$event.preventDefault(); bulkRemoveFromEvent(state.selectedData)">
                                <mat-icon matListIcon svgIcon="common:remove_sample"></mat-icon>
                                <span matLine>Remove Samples from Event</span>
                            </mat-list-item>
                        </ng-container>

                        <h2 matSubheader>Other</h2>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <mat-list-item (click)="$event.preventDefault()" (click)="deleteSelected(state.selectedKeys, state.selectedData)">
                                <mat-icon matListIcon>delete</mat-icon>
                                <span matLine>Delete Samples</span>
                            </mat-list-item>
                        </ng-container>
                    </mat-action-list>
                </ng-container>
            </mat-tab> -->
            <!-- BULK ACTION TAB END -->

        </mat-tab-group>

    </div>
    <!-- SIDEBAR END -->

    <!-- FOOTER -->
    <div pv-view-footer class="pv-table-footer tastings-custom-indexes-footer">
        <label class="mat-caption mr-12" for="custom_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                id="custom_index_sort"
                class="tastings-custom-indexes-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">{{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon
                    [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                    [pageSize]="state.page.size"
                    [pageIndex]="state.page.index"
                    (page)="paginate($event)"
                    [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>
    <!-- FOOTER END -->

    <!-- FAB -->
    <ng-container pv-view-fab *ngIf="selectedOrg$ | async as selectedOrg">
        <ng-container pv-view-fab *ngIf="selectedOrg?.access.role != 'guest'">
            <button pv-view-fab *ngIf="selection.isEmpty()" mat-fab color="primary" (click)="add()" matTooltip="Add Custom Index">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-container>

    <!-- BODY STATUS: OK -->
    <div pv-view-body class="tastings-custom-indexes-index-body" [ngSwitch]="state.status">


        <!-- STATUS MESSAGE: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Custom Indexes..."></pv-view-loader>


        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'"
                         heading="No results"
                         message="The current filters does not match any Indexes. Update the filters or add an Index."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction
                    (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="add()">
                Add
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: INVALID -->
        <pv-view-message *ngSwitchCase="'INVALID'"
                         heading="Invalid Filter Parameters"
                         message="Unable to load data with given filter and sort parameters."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">
                Reload
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                         [message]="state.status | descStatusMessage:'Sample'" icon="error">
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">Reload</button>
        </pv-view-message>


        <!-- STATUS MESSAGE: OK (ngSwitch placeholder) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <!-- PAGE DATA -->
        <ng-container *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <!-- TABLE -->
            <div class="pv-responsive-table">

                <table cdk-table
                       class="pv-tasting-custom-indexes-table"
                       [dataSource]="state.data"
                       [trackBy]="trackByKey"
                       matSort
                       [matSortActive]="state.sort.column"
                       [matSortDirection]="state.sort.order"
                       [matSortDisableClear]="true"
                       (matSortChange)="sort($event)">

                    <!-- Column: Checkbox -->
                    <ng-container cdkColumnDef="checkbox">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle(state.data) : null"
                                          [checked]="selection.hasValue() && isAllSelected(state.data)"
                                          [indeterminate]="selection.hasValue() && !isAllSelected(state.data)"
                                          class="no-print">
                            </mat-checkbox>
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="image-overlay no-print" (click)="selection.toggle(row.key)">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row.key) : null"
                                              [checked]="selection.isSelected(row.key)">
                                </mat-checkbox>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column: First Name -->
                    <ng-container cdkColumnDef="title">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="title">Title</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.title">{{ row.title }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Range -->
                    <ng-container cdkColumnDef="range">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="range">Range</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.range">{{ row.range }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Crop -->
                    <ng-container cdkColumnDef="crop">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="crop">Crop</th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="flex row gap-x-2 items-center">
                                <mat-icon [svgIcon]="'common:' + row.crop?.legacyId" class="mat-24"></mat-icon>
                                <div class="body-line" *ngIf="row.crop">{{ row.crop.label }}</div>
                            </span>
                        </td>
                    </ng-container>

                    <!-- Column: Weighted Chars Count -->
                    <ng-container cdkColumnDef="weightedCharsCount">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="weightedCharsCount">Weighted Characteristics</th>
                        <td cdk-cell *matCellDef="let row">
                            <mat-icon [matBadge]="row.weighted_chars.length" matBadgeColor="primary">analytics</mat-icon>
                        </td>
                    </ng-container>

                    <!-- Column: Actions -->
                    <ng-container cdkColumnDef="actions">
                        <th cdk-header-cell *cdkHeaderCellDef class="no-print"></th>
                        <td cdk-cell *matCellDef="let row" class="no-print">
                            <a mat-icon-button
                                (click)="$event.stopPropagation(); edit(row)"
                                matTooltip="Edit Index">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a mat-icon-button
                               (click)="$event.stopPropagation(); delete(row)"
                               matTooltip="Delete Index">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <!-- Column: Timestamps -->
                    <ng-container cdkColumnDef="timestamps">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="createdAt">Timestamps
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="caption-line">Added
                                {{ row.createdAt | amDateFormat:'ll' }}</span>
                            <span class="caption-line">Updated
                                {{ row.updatedAt | amTimeAgo }}</span>
                        </td>
                    </ng-container>

                    <!-- Row Defs -->
                    <tr cdk-header-row
                        *cdkHeaderRowDef="state.displayColumns">
                    </tr>
                    <tr cdk-row
                        *cdkRowDef="let row; columns: state.displayColumns;"
                        [class.selected]="selection.isSelected(row.key)">
                    </tr>

                </table>
            </div>
            <!-- TABLE END -->
        </ng-container>
        <!-- PAGE DATA END -->

    </div>
</pv-view>