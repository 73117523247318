import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Status } from "@core/data";
import { TastingContact, TastingContactInvitation } from "@core/data/types/tastings-contact";
import { Snackbar } from "@core/material";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InitTastingContactInviteForm, SubmitTastingContactInviteForm, TastingContactInviteFormState, TastingContactInviteFormStateModel } from "./tasting-contact-invite-form.state";

export interface TastingContactInviteFormDialogData {
    orgKey: string;
    contacts: TastingContact[];
}

@Component({
    selector: 'pv-tasting-contact-invite-form-dialog',
    templateUrl: './tasting-contact-invite-form.dialog.html',
    styleUrls: ['tasting-contact-invite-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class TastingContactInviteFormDialog implements OnInit, OnDestroy {
    @Select(TastingContactInviteFormState)
    state$: Observable<TastingContactInviteFormStateModel>;

    public defaultBannerPath = "cloudinary:provar/culteva/tastings/default-banner";

    private _destroy$ = new Subject();

    eventsControl = new FormControl([], [Validators.required]);
    inviteMessageControl = new FormControl(null, [Validators.maxLength(255)]);

    formGroup: FormGroup = new FormGroup({
        events: this.eventsControl,
        inviteMessage: this.inviteMessageControl,
    });

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingContactInviteFormDialog>,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: TastingContactInviteFormDialogData
    ) {}

    ngOnInit(): void {
        this._store.dispatch(new InitTastingContactInviteForm(this.data.orgKey, this.data.contacts));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close()
                else if (state.status !== Status.LOADING) this.eventsControl.enable();
                else this.eventsControl.disable();
            });
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) this.invite()
        else {
            console.warn("TastingContactInviteDialog: form invalid", this.formGroup.value);
            if (!this.formGroup.value.events.length) this._snackbar.error("Invalid input. You need to have at least one event selected.");
            else this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    invite() {
        const form = this.formGroup.value;

        const data: TastingContactInvitation = {
            events: form.events || [],
            contacts: this.getFilteredContacts(this.data.contacts) || [],
            message: form.inviteMessage || '',
            orgKey: this.data.orgKey,
        }

        this._store.dispatch(new SubmitTastingContactInviteForm(data));
    }

    getFilteredContacts(contacts: TastingContact[]): TastingContact[] {
        //Ensures only subscribed contacts receive emails
        return contacts.filter(contact => contact.subscribed == true);
    }

    cancel() {
        this._dialogRef.close();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}