import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { SampleScores, TastingEvaluation, TastingEvaluationIndexType } from "@core/data/types/tastings-evaluation";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { InitNewTastingEvaluationForm, SubmitTastingEvalutaionForm, TastingEvaluationFormState, TastingEvaluationFormStateModel } from "./tastings-evaluations-form.state";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSliderChange } from "@angular/material/slider";
import { Snackbar } from "@core/material";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NumberValidators } from "@core/utils";
import { takeUntil } from "rxjs/operators";
import { Status } from "@core/data";
import { Library } from "@library";
import { LIBRARY } from "@app/evaluation/library";
import { CharTag, TastingsIndex, WeightedChar } from "library/src/tastings-indexes/_types";
import ShortUniqueId from "short-unique-id";

export interface TastingEvaluationsFormDialogData {
    key?: string;
    subjectKey?: string,
    subjectType: TastingEvaluationIndexType,
    defaults?: Partial<TastingEvaluation>;
}

@Component({
    selector: 'pv-tasting-evaluations-form-dialog',
    templateUrl: 'tastings-evaluations-form.dialog.html',
    styleUrls: ['./tastings-evaluations-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class TastingEvaluationFormDialog implements OnInit, OnDestroy {
    @Select(TastingEvaluationFormState)
    state$: Observable<TastingEvaluationFormStateModel>;

    @Select(TastingEvaluationFormState.data)
    data$: Observable<TastingEvaluationFormStateModel>;

    //FORM CONTROLS
    private sampleValidators: Validators = this.isOfTypeEvent(this.data.subjectType) ? [Validators.required] : null;

    //? KEYS
    ownerOrgKeyControl = new FormControl(null);
    sampleKeyControl = new FormControl(null, this.sampleValidators);
    eventKeyControl = new FormControl(null);

    //? DATA
    titleControl = new FormControl(null, [Validators.maxLength(36)]);
    rangeControl = new FormControl(null, [NumberValidators.integer(3), Validators.min(0)]);

    //? SCORES
    scoreNameControl = new FormControl(null, [Validators.required]);
    scoreValueControl = new FormControl(0, [NumberValidators.integer(3), Validators.min(0)]);
    tagNameControl = new FormControl(null, [Validators.required]);
    tagValueControl = new FormControl(false);
    userEmailControl = new FormControl(null, [Validators.email, Validators.required]);
    userSubscriptionControl = new FormControl(false);
    userIdentControl = new FormControl(null, [Validators.required, Validators.minLength(6)]);
    //FORM CONTROLS END

    //SUB GROUPS

    //? TAGS
    tagsArray = new FormArray([
        new FormGroup({
            id: new FormControl(null, [Validators.required]),
            value: new FormControl(false),
        })
    ]);

    //? USER
    userGroup = new FormGroup({
        email: this.userEmailControl,
        subscribed: this.userSubscriptionControl,
        ident: this.userIdentControl
    });

    //? CHARACTERISTICS
    characteristicsArray = new FormArray([]);

    // TODO add answers
    //? SCORES GROUP
    scoresGroup = new FormGroup({
        user: this.userGroup,
        characteristics: this.characteristicsArray,
    });
    //SUB GROUPS END

    //FORM GROUP
    formGroup = new FormGroup({
        ownerOrgKey: this.ownerOrgKeyControl,
        tastingsSampleKey: this.sampleKeyControl,
        tastingsEventKey: this.eventKeyControl,
        title: this.titleControl,
        range: this.rangeControl,
        scores: this.scoresGroup,
    });
    //FORM GROUP END

    private tastingsIndexes = null;
    private baseIndex: TastingsIndex = null;

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingEvaluationsFormDialogData>,
        private _snackbar: Snackbar,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: TastingEvaluationsFormDialogData,
        @Inject(LIBRARY) private _library: Library,
    ) {}

    ngOnInit(): void {
        this._store.dispatch(new InitNewTastingEvaluationForm(this.data.subjectKey, this.data.subjectType, this.data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE){
                    this._snackbar.message("Tastings Evaluation Saved");
                    this._dialogRef.close(state.data);
                }
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);

            })
    }

    formScores(): FormArray {
        return this.formGroup.get('scores').get('characteristics') as FormArray;
    }

    addScore(score: WeightedChar): void {
        this.formScores().push(this.newScoreValue(score));
    }

    newScoreValue(score: WeightedChar): FormGroup {
        return this._formBuilder.group({
            id: score.id,
            value: new FormControl(-1, [NumberValidators.integer(3), Validators.required, Validators.min(-1), Validators.max(this.formGroup.get('range').value)]),
            tags: this.mapEvalTags(score.tags),
            comment: new FormControl(null),
        });
    }

    newTagValue(tag: CharTag): FormGroup {
        return this._formBuilder.group({
            id: tag.id,
            value: this.tagValueControl
        });
    }

    mapEvalScores(scores: SampleScores) {
        for(let score of this.baseIndex.weightedChars) {
            this.addScore(score);
        }
    }

    mapEvalTags(tags: CharTag[]): FormArray {
        let evalTags = new FormArray([]);

        for(let tag of tags) {
            evalTags.push(this.newTagValue(tag));
        }

        return evalTags
    }

    setUserIdent() {
        let user = this.formGroup.get('scores').get('user');
        if (user.get('ident').value) return;

        return this.formGroup.get('scores').get('user').patchValue({
            email: null,
            ident: this.generateIdent(),
            subscribed: false,
        });
    }

    getPrettyScoreName(scoreName: string): string {
        return scoreName.split("_")[1].charAt(0).toUpperCase() + scoreName.split("_")[1].slice(1);
    }

    getPrettyTagName(tagName: string): string {
        let prettyName = "";
        let formattedArray =  tagName.split(/(?=[A-Z])/);

        formattedArray.forEach(word => {
            prettyName += word.charAt(0).toUpperCase() + word.slice(1) + " ";
        });

        return prettyName;
    }

    isOfTypeEvent(subjectType: string): boolean {
        return subjectType === TastingEvaluationIndexType.EVENT;
    }

    reset(model: Partial<TastingEvaluation>) {
        this.tastingsIndexes = this._library.tastingsIndexes.all();
        this.baseIndex = this.tastingsIndexes[0]; // TODO change to snapshot protocol, ammend all uses of baseIndex.

        let data: Partial<TastingEvaluation> = {
            ownerOrgKey: model.ownerOrgKey,
            tastingsSampleKey: model.tastingsSampleKey,
            tastingsEventKey: model.tastingsEventKey,
            title: this.baseIndex.title,
            range: this.baseIndex.range,
            scores: model.scores,
        };

        this.formGroup.reset(data);

        this.mapEvalScores(data.scores);
        this.setUserIdent();
    }

    cancel() {
        this._dialogRef.close();
    }

    attempt() {
        if (this.formGroup.valid) {
            const form = this.formGroup.value;

            const evaluation: Partial<TastingEvaluation> = {
                ownerOrgKey: form.ownerOrgKey,
                range: form.range,
                scores: form.scores,
                tastingsEventKey: form.tastingsEventKey,
                tastingsSampleKey: form.tastingsSampleKey,
                title: form.title,
            }

            this._store.dispatch(new SubmitTastingEvalutaionForm(evaluation));
        } else {
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * Generates a new unique 6 character alphanumeric identifier
     * Defaults to a lenght of 6
     *
     * @returns
    */
        private generateIdent() {
            const uid = new ShortUniqueId();
            return uid();
        }
}