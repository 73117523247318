import { Injectable } from "@angular/core";
import { Status, TastingEventService, translateCommonErrorStatus } from "@core/data";
import { TastingEvent, TastingsEventInvitation } from "@core/data/types/tastings-event";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

//State model
export interface TastingEventInviteFormStateModel {
    orgKey: string;
    status: Status;
    data: Partial<TastingEvent[]>;
}

//State defaults
const DEFAULTS: TastingEventInviteFormStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: null
}

//State actions
export class InitTastingEventInviteForm {
    static readonly type = "[TastingEventInviteForm] Init";
    constructor(public orgKey: string, public defaults?: Partial<TastingEvent[]>) {}
}

export class SubmitTastingEventInviteForm {
    static readonly type = "[TastingEventInviteForm] Submit";
    constructor(public data: Partial<TastingsEventInvitation>) {}
}

//State logic
@State<TastingEventInviteFormStateModel>({
    name: 'tasting_event_invite_form_state',
    defaults: DEFAULTS
})
@Injectable()
export class TastingEventInviteFormState {
    @Selector()
    static data(state: TastingEventInviteFormStateModel) {return state.data}

    constructor(
        private _tastingEventService: TastingEventService,
    ) {}

    @Action(InitTastingEventInviteForm, {cancelUncompleted: true})
    initTastingEventInviteForm(ctx: StateContext<TastingEventInviteFormStateModel>, action: InitTastingEventInviteForm) {
        ctx.setState({
            orgKey: action.orgKey,
            data: action.defaults,
            status: Status.OK,
        });
    }

    @Action(SubmitTastingEventInviteForm)
    submitTastingEventInviteForm(ctx: StateContext<TastingEventInviteFormStateModel>, action: SubmitTastingEventInviteForm) {
        let request: Observable<TastingsEventInvitation>;
        request = this._tastingEventService.invite(action.data);

        ctx.patchState({
            status: Status.LOADING
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }
}
