import { Injectable } from "@angular/core";
import { DetailRequest, FilterBuilder, Status, TastingEventService } from "@core/data";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

//State model
export interface TastingsSampleEventFormStateModel {
    orgKey: string;
    status: Status;
    data: Partial<TastingEvent[]>;
}

//State defaults
const DEFAULTS: TastingsSampleEventFormStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: null,
}

//State actions
export class InitTastingsSampleEventForm {
    static readonly type = "[TastingsSampleEventForm] Init";
    constructor(public orgKey: string, public defaults?: Partial<TastingEvent[]>) {}
}

//State logic
@State<TastingsSampleEventFormStateModel>({
    name: 'tasting_sample_event_form_state',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingsSampleEventFormState {
    @Selector()
    static data(state: TastingsSampleEventFormStateModel) {return state.data;}

    constructor(
        private _tastingsEventService: TastingEventService,
    ) {}

    @Action(InitTastingsSampleEventForm, {cancelUncompleted: true})
    initTastingsSampleEventForm(ctx: StateContext<TastingsSampleEventFormStateModel>, action: InitTastingsSampleEventForm) {
        ctx.setState({
            orgKey: action.orgKey,
            data: {...action.defaults},
            status: Status.OK
        });

        return this.loadTastingEventOptions(ctx);
    }

    private loadTastingEventOptions(ctx: StateContext<TastingsSampleEventFormStateModel>) {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.orgKey)
            .get();

        const detail: DetailRequest = {
            related: [
                'tastingsEventImage',
            ]
        }

        return this._tastingsEventService.query(filter, detail)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        data: result.data
                    })
                }),
                catchError(e => {
                    console.warn('Error loading events', e);
                    ctx.patchState({
                        data: [],
                    });
                    return of(null);
                })
            );
    }
}