import { HttpErrorResponse } from '@angular/common/http';
import { NotFoundError, UnauthenticatedError, UnauthorizedError, ValidationError } from '../http/errors';
import { Status } from './status';
import { KeyPrimary } from '../types/common';


export function patchArrayItem<T extends KeyPrimary>(index: T[], patch: T, push = true) {

    let found = false;

    let patched = index.map(item => {
        if (item.key === patch.key) {
            found = true;
            return Object.assign({}, item, patch);
        }
        return item;
    });

    if (!found) {
        if (push) patched.push(patch);
        else patched.unshift(patch);
    }

    return patched;
}

export function replaceArrayItem<T extends KeyPrimary>(index: T[], replace: T) {

    return index.map(item => {
        if (item.key === replace.key) {
            return replace;
        }
        return item;
    });

}

export function replaceArrayItems<T extends KeyPrimary>(index: T[], replace: T[]) {

    let replacement: T;

    return index.map(item => {
        replacement = replace.find(r => r.key === item.key);
        if (replacement) {
            return replacement;
        }
        return item;
    });

}

export function findArrayItem<T extends KeyPrimary>(index: T[], key: string): any {
    return index.find(item => item.key === key);
}

export function removeArrayItem<T extends KeyPrimary>(index: T[], key: string): any {
    return index.filter(item => item.key !== key);
}

export function translateCommonErrorStatus(error: any): Status {

    if (error instanceof HttpErrorResponse) {
        if (error.status === 404) return Status.NOT_FOUND;
        if (error.status === 401) return Status.UNAUTHENTICATED;
        if (error.status === 403) return Status.UNAUTHORIZED;
        if (error.status === 422) return Status.INVALID;
        if (error.status === 0) return Status.ERROR;
    }

    if (error instanceof NotFoundError) return Status.NOT_FOUND;
    if (error instanceof UnauthenticatedError) return Status.UNAUTHENTICATED;
    if (error instanceof UnauthorizedError) return Status.UNAUTHORIZED;
    if (error instanceof ValidationError) return Status.INVALID;

    return Status.ERROR;
}