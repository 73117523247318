import { Component, HostBinding, Input, ChangeDetectionStrategy } from "@angular/core";
import { TastingSample } from "@core/data/types/tasting-sample";

@Component({
    selector: '[pvTastingSampleLine]',
    template: `
        <mat-icon *ngIf="_icon" [svgIcon]="_icon"></mat-icon>
        <span class="text" [innerHtml]="_html"></span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-model-line pv-tasting-sample-line'
    }
})
export class TastingSampleLineComponent {
    @Input('pvTastingSampleLine')
    set tastingSample(sample: TastingSample){
        if(sample){
            this._html = sample.label;
            this._icon = 'common:tastings_sample';
        }else{
            this._html = '';
            this._icon = null;
        }
    }

    @HostBinding('attr.title')
    _title: string;

    _icon: string = null;
    _html: string;

    constructor(){}
}