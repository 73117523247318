import { ChartOptions } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_MEASURE_CHARTS } from './charts';

export class ChartDictionary extends Dictionary<ChartOptions>{
    constructor(){
        super(LIB_MEASURE_CHARTS);
    }
}

export * from './_types';