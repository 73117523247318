import { FilterPage, FilterSort, Status } from "@core/data";
import { TastingContact } from "@core/data/types/tastings-contact";

export interface TastingContactIndexStateModel {
    //org context
    orgKey: string;

    //page data
    status: Status;
    data: TastingContact[];
    total: number;

    //data options
    displayColumns: string[];
    sortOptions: {id: string, label: string}[];
    pageSizeOptions: number[];

    //filter options
    page: FilterPage;
    sort: FilterSort;
    query: TastingContactIndexQuery;

    //selection
    selectedKeys: string[];
    selectedData: TastingContact[];

    //export result
    exports: {
        key: string;
        status: Status;
    }[];
}

export interface TastingContactIndexQuery {
    search: string; //* Search to include searching by firstName, lastName and email
    subscribed: boolean;
    dobFrom: string;
    dobTo: string;
    country: string[];
    businessAreas: string[];
}

export const TASTING_CONTACT_SORT_OPTIONS: {id: string, label: string}[] = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'dob', label: 'Date of Birth' },
    { id: 'country', label: 'Country' },
    // { id: 'expiryDate', label: 'Expiry Date' },
    { id: 'updatedAt', label: 'Updated Date' },
    { id: 'createdAt', label: 'Created Date' },
]

export const TASTING_CONTACT_DISPLAY_COLUMNS = [
    'checkbox',
    'firstName',
    'lastName',
    'email',
    'dob',
    'country',
    'businessAreas',
    'subscribed',
    'actions',
    'timestamps',
];

export const DEFAULTS: TastingContactIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'updatedAt',
        order: 'desc'
    },
    displayColumns: TASTING_CONTACT_DISPLAY_COLUMNS,
    sortOptions: TASTING_CONTACT_SORT_OPTIONS,
    pageSizeOptions: [5, 10, 20, 50, 100, 500, 1000],
    query: {
        search: null,
        subscribed: null,
        dobFrom: null,
        dobTo: null,
        country: null,
        businessAreas: null,
    },
    selectedData: [],
    selectedKeys: [],
    exports: [],
}