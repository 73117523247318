
import { LocalStorage } from '@core/browser';
import { CultivarService, CultivarUpdated, DetailRequest, Filter, FilterBuilder, FilterQueryMode, patchArrayItem, replaceArrayItem, SampleService, SampleType, SiteUpdated, Status, translateCommonErrorStatus, SampleUpdated, SampleCreated, SampleDeleted } from '@core/data';
import { isNumberRange, isNumberSet, parseNumberRangeValue, parseNumberSet, uuid } from '@core/utils';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isBoolean } from 'lodash';
import { of, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ClearSampleIndexFilter, ExportSampleIndex, InitSampleIndex, LoadSampleIndex, PageSampleIndex, PushSampleIndexModelQuery, QuerySampleIndex, SetSampleIndexSelected, SetSampleLabelOptions, SortSampleIndex, DeleteSample, ImportSampleIndex, DownloadSampleImportTemplate } from './sample-index.state-actions';
import { DEFAULTS, HARVEST_LABEL_OPTIONS, HARVEST_SAMPLE_DISPLAY_COLUMNS, PLANT_LABEL_OPTIONS, PLANT_SAMPLE_DISPLAY_COLUMNS, SampleIndexStateModel } from './sample-index.state-model';
import { Injectable } from '@angular/core';


@State({
    name: 'sample_index',
    defaults: DEFAULTS
})
@Injectable()
export class SampleIndexState {

    @Selector()
    static sort(state: SampleIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: SampleIndexStateModel) {
        return state.query;
    }

    @Selector()
    static labelOptions(state: SampleIndexStateModel) {
        return state.labelOptions;
    }

    @Selector()
    static sampleType(state: SampleIndexStateModel) {
        return state.sampleType;
    }

    @Selector()
    static latestExport(state: SampleIndexStateModel) {
        return state.exports.length ? state.exports[state.exports.length - 1] : null;
    }

    constructor(
        private _sampleService: SampleService,
        private _cultivarService: CultivarService,
        private _storage: LocalStorage
    ) { }

    @Action(InitSampleIndex)
    initSampleIndex(ctx: StateContext<SampleIndexStateModel>, action: InitSampleIndex) {

        const state = ctx.getState();

        if (
            state.orgKey === action.orgKey &&
            state.sampleType === action.sampleType &&
            state.status !== Status.UNINITIALIZED) {
            return;
        }

        // grab default options for given sample type
        const labelOptions = action.sampleType === SampleType.HARVEST ? HARVEST_LABEL_OPTIONS : PLANT_LABEL_OPTIONS;
        const tableColumns = action.sampleType === SampleType.HARVEST ? HARVEST_SAMPLE_DISPLAY_COLUMNS : PLANT_SAMPLE_DISPLAY_COLUMNS;

        ctx.setState({
            ...DEFAULTS,
            sampleType: action.sampleType,
            orgKey: action.orgKey,
            labelOptions,
            displayColumns: tableColumns,
            status: Status.OK
        });

        // restore saved filter
        this.restoreFilter(ctx);

        return forkJoin(
            this.loadSampleIndexLicensees(ctx),
            ctx.dispatch(new LoadSampleIndex())
        );
    }

    @Action(LoadSampleIndex, { cancelUncompleted: true })
    loadSampleIndex(ctx: StateContext<SampleIndexStateModel>, action: LoadSampleIndex) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'site',
                'rootstockCultivar',
                'scionCultivar',
                'plantSample',
                'primaryImage',
                'latestImage',
                'harvestSamples',
                'tastingSamples',
                'evals'
            ],
            counts: [
                'evals',
                'harvestSamples'
            ]
        };


        return this._sampleService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(PageSampleIndex)
    pageSampleIndex(ctx: StateContext<SampleIndexStateModel>, action: PageSampleIndex) {

        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadSampleIndex);

    }

    @Action(SortSampleIndex)
    sortSampleIndex(ctx: StateContext<SampleIndexStateModel>, action: SortSampleIndex) {

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadSampleIndex);
    }

    @Action(QuerySampleIndex)
    querySampleIndex(ctx: StateContext<SampleIndexStateModel>, action: QuerySampleIndex) {

        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadSampleIndex);

    }

    @Action(DeleteSample)
    deleteSample(ctx: StateContext<SampleIndexStateModel>, action: DeleteSample) {
        return this._sampleService.delete(action.sampleKey);
    }

    @Action(PushSampleIndexModelQuery)
    pushSampleIndexModelQuery(ctx: StateContext<SampleIndexStateModel>, action: PushSampleIndexModelQuery) {

        const state = ctx.getState();
        let update;

        switch (action.modelType) {
            case 'scion':
                update = { scionCultivars: patchArrayItem(state.query.scionCultivars, action.model) };
                break;
            case 'rootstock':
                update = { rootstockCultivars: patchArrayItem(state.query.rootstockCultivars, action.model) };
                break;
            case 'site':
                update = { sites: patchArrayItem(state.query.sites, action.model) };
                break;
        }


        ctx.dispatch(new QuerySampleIndex({
            ...state.query,
            ...update
        }));

    }

    @Action(ClearSampleIndexFilter)
    clearSampleIndexFilter(ctx: StateContext<SampleIndexStateModel>, action: ClearSampleIndexFilter) {

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadSampleIndex);

    }

    @Action(SetSampleLabelOptions)
    setSampleLabelOptions(ctx: StateContext<SampleIndexStateModel>, action: SetSampleLabelOptions) {
        ctx.patchState({
            labelOptions: action.options
        });
        this.storeFilter(ctx);
    }

    @Action(ExportSampleIndex)
    exportCultivarIndex(ctx: StateContext<SampleIndexStateModel>, action: ExportSampleIndex) {

        const state = ctx.getState();

        const key = uuid();

        ctx.patchState({
            exports: [{
                key,
                status: Status.LOADING,
            }, ...state.exports]
        });

        let filter: Filter;

        const detail: DetailRequest = {
            counts: [
                'evals',
                'harvestSamples'
            ]
        };

        if (state.selectedKeys.length > 0) {
            filter = (new FilterBuilder())
                .setSort('updatedAt', 'desc')
                .setQuery('ownerOrgKey', state.orgKey)
                .setQuery('key', state.selectedKeys, FilterQueryMode.IN)
                .get();
        } else {
            filter = this.getCurrentFilter(state);
        }

        return this._sampleService.export(filter, state.orgKey, state.sampleType)
            .pipe(
                tap(
                    result => {

                        let exp = {
                            key,
                            status: Status.COMPLETE
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    },
                    error => {

                        let exp = {
                            key,
                            status: translateCommonErrorStatus(error),
                            url: null,
                            filename: null
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    }
                )
            );
    }

    @Action(ImportSampleIndex)
    importSampleIndex(ctx: StateContext<SampleIndexStateModel>, action: ImportSampleIndex) {
        const state = ctx.getState();
        return this._sampleService.import(state.orgKey, action.file)
            .pipe(
                tap(
                    result => {
                        // No Action needed
                    },
                    error => {
                        console.warn('SampleIndexState: Upload Error: ', error);

                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(DownloadSampleImportTemplate)
    downloadSampleImportTemplate(ctx: StateContext<SampleIndexStateModel>, action: DownloadSampleImportTemplate) {
        return this._sampleService.downloadImportTemplate()
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = "sample-import-template";
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn('SampleIndexState: Download Error ', error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(SetSampleIndexSelected)
    setSampleIndexSelected(ctx: StateContext<SampleIndexStateModel>, action: SetSampleIndexSelected) {

        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let sample = state.data.find(s => s.key === key);
            if (sample) return sample;
            return state.selectedData.find(s => s.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(SampleUpdated)
    onSampleUpdated(ctx: StateContext<SampleIndexStateModel>, action: SampleUpdated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadSampleIndex);
    }

    @Action(SampleCreated)
    onSampleCreated(ctx: StateContext<SampleIndexStateModel>, action: SampleCreated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadSampleIndex);
    }

    @Action(SampleDeleted)
    onSampleDeleted(ctx: StateContext<SampleIndexStateModel>, action: SampleDeleted) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadSampleIndex);
    }


    @Action(CultivarUpdated)
    onCultivarUpdated(ctx: StateContext<SampleIndexStateModel>, action: CultivarUpdated) {

        const state = ctx.getState();
        if (state.status === Status.UNINITIALIZED) return;

        ctx.patchState({
            query: {
                ...state.query,
                rootstockCultivars: replaceArrayItem(state.query.rootstockCultivars, action.cultivar),
                scionCultivars: replaceArrayItem(state.query.scionCultivars, action.cultivar)
            }
        });

        return forkJoin(
            this.loadSampleIndexLicensees(ctx),
            ctx.dispatch(new LoadSampleIndex)
        );
    }

    @Action(SiteUpdated)
    onSiteUpdated(ctx: StateContext<SampleIndexStateModel>, action: SiteUpdated) {
        const state = ctx.getState();
        if (state.status === Status.UNINITIALIZED) return;

        ctx.patchState({
            query: {
                ...state.query,
                sites: replaceArrayItem(state.query.sites, action.site),
            }
        });

        return ctx.dispatch(new LoadSampleIndex);
    }


    private loadSampleIndexLicensees(ctx: StateContext<SampleIndexStateModel>) {

        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.orgKey)
            .get();

        return this._cultivarService.groupLicensees(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        licenseeOptions: result.group,
                    });
                }),
                catchError(e => {
                    console.warn("Error loading cultivar licensees", e);
                    ctx.patchState({
                        licenseeOptions: [],
                    });
                    return of(null);
                })
            );
    }

    private getCurrentFilter(state: SampleIndexStateModel): Filter {

        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);
        if (state.sampleType) fb.setQuery('type', state.sampleType);

        if (Array.isArray(state.query.scionCultivars) && state.query.scionCultivars.length > 0) {
            let keys = state.query.scionCultivars.map(i => i.key);
            fb.setQuery('scionCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.rootstockCultivars) && state.query.rootstockCultivars.length > 0) {
            let keys = state.query.rootstockCultivars.map(i => i.key);
            fb.setQuery('rootstockCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.sites) && state.query.sites.length > 0) {
            let keys = state.query.sites.map(i => i.key);
            fb.setQuery('siteKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.birthYear) && state.query.birthYear.length > 0) {
            fb.setQuery('birthYear', state.query.birthYear, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.birthWeek) && state.query.birthWeek.length > 0) {
            fb.setQuery('birthWeek', state.query.birthWeek, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.scionCropId) && state.query.scionCropId.length > 0) {
            fb.setQuery('scionCropId', state.query.scionCropId, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.groupKey) && state.query.groupKey.length > 0) {
            fb.setQuery('groupKey', state.query.groupKey, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.scionLicensee) && state.query.scionLicensee.length > 0) {
            fb.setQuery('scionLicensee', state.query.scionLicensee, FilterQueryMode.IN);
        }

        if (isNumberSet(state.query.rowIndex)) {
            fb.setQuery('rowIndex', parseNumberSet(state.query.rowIndex), FilterQueryMode.IN);
        } else if (isNumberRange(state.query.rowIndex)) {
            fb.setQuery('rowIndex', parseNumberRangeValue(state.query.rowIndex), FilterQueryMode.BETWEEN);
        }

        if (isNumberSet(state.query.positionIndex)) {
            fb.setQuery('positionIndex', parseNumberSet(state.query.positionIndex), FilterQueryMode.IN);
        } else if (isNumberRange(state.query.positionIndex)) {
            fb.setQuery('positionIndex', parseNumberRangeValue(state.query.positionIndex), FilterQueryMode.BETWEEN);
        }

        if(state.query.hideDestroyed) {
            fb.setQuery('deathDate', new Date(), FilterQueryMode.HAS_NO_VALUE);
        }

        if (state.query.excludeYear && (Array.isArray(state.query.birthYear) && state.query.birthYear.length > 0)) {
            fb.setQuery('birthYear', state.query.birthYear, FilterQueryMode.NOT_EQUALS);
        }

        if (state.query.startEvalPeriod && state.query.endEvalPeriod) {
            fb.setQuery('evalPeriod', [state.query.startEvalPeriod, state.query.endEvalPeriod], FilterQueryMode.BETWEEN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<SampleIndexStateModel>) {

        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_sample_index_${state.sampleType}_page`, state.page);
        this._storage.set(`${state.orgKey}_sample_index_${state.sampleType}_sort`, state.sort);
        this._storage.set(`${state.orgKey}_sample_index_${state.sampleType}_query`, state.query);
        this._storage.set(`${state.orgKey}_sample_index_${state.sampleType}_label_options`, state.labelOptions);
    }

    private restoreFilter(ctx: StateContext<SampleIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_sample_index_${state.sampleType}_page`);
        const sort = this._storage.get(`${state.orgKey}_sample_index_${state.sampleType}_sort`);
        const query = this._storage.get(`${state.orgKey}_sample_index_${state.sampleType}_query`);
        const labelOptions = this._storage.get(`${state.orgKey}_sample_index_${state.sampleType}_label_options`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
            labelOptions: labelOptions || DEFAULTS.labelOptions,
        });

    }
}