import { Injectable, Inject } from '@angular/core';
import { CharacteristicType, ColorParams, EventParams, IntervalParams, NominalParams } from '@library';
import { HSL, parseHSL } from 'core/utils';
import * as moment from 'moment';
import { Library } from '@library';
import { SampleCharacteristic } from '@core/data';
import { LIBRARY } from './library';



@Injectable()
export class EvaluationFormatter {

    constructor(
        @Inject(LIBRARY) private library: Library
    ){}

    charValueLabel(value: string, charId: string);
    charValueLabel(value: string, charId: string, rescaled?: number);
    charValueLabel(value: string, charId: string, rescaled?: number, isReportTable?: boolean);
    charValueLabel(value: string, charId: string, rescaled?: number, isReportTable?: boolean, showMinDesc?: boolean);
    charValueLabel(value: string, charId: string = null, rescaled: number = null, isReportTable: boolean = false, showMinDesc: boolean = false): string {

        let c, v;

        c = this.library.chars.get(charId);
        v = value;

        if (c.type === CharacteristicType.Interval && (!this.checkMinDescriptor(charId) && value == null)) return null;
        else if (c.type !== CharacteristicType.Interval && value == null) return null;

        switch(c.type){
            case 'interval':
                return this.intervalValueLabel(v, <IntervalParams> c.params, rescaled, isReportTable, showMinDesc);
            case 'nominal':
                return this.nominalValueLabel(v, <NominalParams> c.params);
            case 'event':
                return this.eventValueLabel(v, <EventParams> c.params);
            case 'color':
                return this.colorValueLabel(v, <ColorParams> c.params);
            default:
                return v;
        }

    }

    eventValueLabel(value: string, params: EventParams): string{

        let date = moment(value);

        if(date.isValid()){
            return date.format('[W]W, D MMM');
        }

        return value;
    }

    nominalValueLabel(value: string, params: NominalParams): string{

        if(value === null) return '';

        return value.split(',')
                    .map(value => {
                        let opt = params.options.find(opt => opt.value === value);
                        if(opt){
                            return opt.label;
                        }
                        return `"${value}"`;
                    })
                    .join(', ');
    }

    intervalValueLabel(value: string, params: IntervalParams, rescaled?: number, isReportTable?: boolean, showMinDesc?: boolean){

        let numValue = parseInt(value);

        if ((numValue == params.min || value == null) && params.minDesc) {
            if (showMinDesc) return value == null ? `${params.minDesc} | (Not Collected)` : `${params.minDesc} | ${value}`;
            else return value == null ? null : `${params.minDesc} | ${value}`;
        }

        if(numValue < params.min || numValue > params.max){
            return 'OoR';
        }

        let relValue = numValue - params.min;
        let fullRange = params.max - params.min;

        let discIndex = Math.floor((relValue / fullRange) * params.descriptors.length);

        if(discIndex === params.descriptors.length) discIndex--;

        let descriptor = params.descriptors[discIndex];

        if (isReportTable && descriptor.toLowerCase() === 'intermediate') descriptor = 'Int.';

        const label = !rescaled ? `${descriptor} | ${numValue}` : `${rescaled} (${descriptor} | ${numValue})`;

        return label;
    }

    colorValueLabel(value: string | HSL, params: ColorParams){

        if(value !== null){
            let hsl = value instanceof HSL ? value : parseHSL(value);

            if(hsl !== null){
                return hsl.describe();
            }
        }

        return '';
    }

    private checkMinDescriptor(charId: string) {
        let hasMinDesc = false;
        let char = charId ? this.library.chars.get(charId) : null;

        if (!char) return false;

        if (char.type === CharacteristicType.Interval && char.params.minDesc) hasMinDesc = true;

        return hasMinDesc;
    }


}
