import { Injectable } from '@angular/core';
import { OrganizationService, OrganizationUser, Status, translateCommonErrorStatus } from '@core/data';
import { Action, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


export interface OrganizationMembersStateModel {
    orgKey: string;
    status: Status;
    data: OrganizationUser[];
}

export class InitOrganizationMembers {
    static readonly type = "[OrganizationMembers] Init";
    constructor(public orgKey: string) { }
}

export class LoadOrganizationMembers {
    static readonly type = "[OrganizationMembers] Load";
    constructor() { }
}

export class RemoveOrganizationMember {
    static readonly type = "[OrganizationMembers] Remove";
    constructor(public userKey: string) { }
}

export class UpdateOrganizationMemberRole {
    static readonly type = "[OrganizationMembers] Update Role";
    constructor(public userKey: string, public newRole: string) { }
}

@State<OrganizationMembersStateModel>({
    name: 'organization_members',
    defaults: {
        status: Status.UNINITIALIZED,
        data: [],
        orgKey: null
    }
})
@Injectable()
export class OrganizationMembersState {

    constructor(private _orgService: OrganizationService) { }

    /**
     *
     * @param ctx State Context
     * @param action orgKey: of the organization you wish to load the users for
     */
    @Action(InitOrganizationMembers)
    initOrganizationMembers(ctx: StateContext<OrganizationMembersStateModel>, action: InitOrganizationMembers) {
        ctx.setState({
            status: Status.UNINITIALIZED,
            data: [],
            orgKey: action.orgKey
        });

        return ctx.dispatch(new LoadOrganizationMembers());
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LoadOrganizationMembers, { cancelUncompleted: true })
    loadOrganizationMembers(ctx: StateContext<OrganizationMembersStateModel>, action: LoadOrganizationMembers) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
            data: []
        });

        return this._orgService.listMembers(state.orgKey)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            data: result,
                            status: Status.OK
                        });
                    },
                    error => {
                        ctx.patchState({
                            data: [],
                            status: translateCommonErrorStatus(error)
                        });
                    }
                ),
                catchError(error => of(null))
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action userKey: key of the user to remove
     */
    @Action(RemoveOrganizationMember)
    removeOrganizationMember(ctx: StateContext<OrganizationMembersStateModel>, action: RemoveOrganizationMember) {

        const state = ctx.getState();

        return this._orgService.removeMember(state.orgKey, action.userKey)
            .pipe(
                tap(result => {

                    const state = ctx.getState();

                    // remove member from array
                    ctx.patchState({
                        data: state.data.filter(member => member.key !== result.key)
                    });

                })
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action userKey: key of the user to change role of, newRole: the new role of the user
     */
    @Action(UpdateOrganizationMemberRole)
    updateOrganizationMemberRole(ctx: StateContext<OrganizationMembersStateModel>, action: UpdateOrganizationMemberRole) {

        const state = ctx.getState();

        return this._orgService.updateMember(state.orgKey, action.userKey, action.newRole)
            .pipe(
                tap(result => {

                    const state = ctx.getState();

                    // replace member in array
                    ctx.patchState({
                        data: state.data.map(user => {
                            if (user.key === result.key) return result;
                            else return user;
                        })
                    });
                })
            );
    }
}