import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomProtocol, CustomProtocolService, Evaluation, SampleStorage, Status } from '@core/data';
import { Snackbar } from '@core/material';
import { coerceMomentProperty, coerseDateProperty, NumberValidators, parseDuration } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EvaluationFormState, EvaluationFormStateModel, InitEvaluationForm, SubmitEvaluationForm } from './evaluation-form.state';
import { Protocol } from '@library';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
    selector: 'pv-evaluation-form-dialog',
    templateUrl: 'evaluation-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EvaluationFormDialog implements OnInit, OnDestroy {

    private childDialogRef: MatDialogRef<MatDialog>;
    treatmentTypesList:  {[key:string]:string}[] = [{id:"MCP", label:"1-MCP"}, {id: "Smartfresh", label:"Smartfresh"}, {id:"Hazel", label:"Hazel"}];

    labelControl = new FormControl(null, [Validators.required, Validators.maxLength(64)]);
    sizeControl = new FormControl(null, [Validators.required, Validators.min(1), NumberValidators.integer(9)]);
    protocolIdControl = new FormControl(null, [Validators.required]);
    evalStartDateControl = new FormControl(null, [Validators.required]);
    evalEndDateControl = new FormControl(null, []);
    storageRegimeControl = new FormArray([]);
    scheduleIdControl = new FormControl('custom', []);
    sampleKeyControl: FormControl = new FormControl(null, [Validators.required]);

    formGroup: FormGroup = new FormGroup({
        sampleKey: this.sampleKeyControl,
        label: this.labelControl,
        size: this.sizeControl,
        protocolId: this.protocolIdControl,
        evalStartDate: this.evalStartDateControl,
        evalEndDate: this.evalEndDateControl,
        scheduleId: this.scheduleIdControl,
        storageRegime: this.storageRegimeControl,
    });

    isDisplay: boolean = true;
    selectedProtocol: Protocol | CustomProtocol = null;
    initialLoad: boolean = true;

    customSchedule = {
        id: 'custom',
        label: 'Custom'
    };

    @Select(EvaluationFormState)
    state$: Observable<EvaluationFormStateModel>;

    @Select(EvaluationFormState.data)
    data$: Observable<Partial<Evaluation>>;

    private _destroy$ = new Subject();

    constructor(
        private _dialogRef: MatDialogRef<EvaluationFormDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: EvaluationFormDialogData,
        private _store: Store,
        private _snackbar: Snackbar,
        private dialog: MatDialog,
        private _custom_protocolService: CustomProtocolService,
    ) { }

    ngOnInit() {

        if (this._data.defaults.massAction) {
            let initForms: InitEvaluationForm[] = [];

            for (let i = 0; i < this._data.defaults.sampleKeys.length; i++) {
                let dataList = (JSON.parse(JSON.stringify(this._data)));
                dataList.defaults.sampleKey = this._data.defaults.sampleKeys[i];

                initForms.push(new InitEvaluationForm(this._data.key, dataList.defaults));
            }
            this._store.dispatch(initForms);
        } else this._store.dispatch(new InitEvaluationForm(this._data.key, this._data.defaults));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) {
                    this._dialogRef.close();
                }else if (state.status !== Status.LOADING && state.data) {
                    this.formGroup.enable();
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if(data) this.reset(data);
            });
    }

    reset(data: Partial<Evaluation>) {
        this.formGroup.reset({
            sampleKey: data.sampleKey,
            label: data.label,
            size: data.size || 1,
            protocolId: data.protocolId.toString() || null,
            evalStartDate: coerceMomentProperty(data.evalStartDate, moment()),
            evalEndDate: coerceMomentProperty(data.evalEndDate, null),
            scheduleId: data.scheduleId,
        });

        this.applyStorageRegime(data.storageRegime || []);
    }


    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt() {

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {

            let form = this.formGroup.value;

            let storageRegime: SampleStorage[];

            if(Array.isArray(form.storageRegime)){
                storageRegime = form.storageRegime.map(line => {
                    return {
                        temp: line.temp,
                        duration: `${line.time}${line.unit}`,
                        treatment: line.treatmentTypes,
                        ca: line.controlledAtmosphere,
                        caOptions: line.caOptions,
                    };
                });
            }else{
                storageRegime = null;
            }

            const data: Partial<Evaluation> = {
                sampleKey: form.sampleKey,
                label: form.label,
                size: coerceNumberProperty(form.size, null),
                protocolId: form.protocolId.toString(),
                evalStartDate: coerseDateProperty(form.evalStartDate, null),
                evalEndDate: coerseDateProperty(form.evalEndDate, null),
                scheduleId: form.scheduleId,
                storageRegime,
            };

            if(this._data.defaults.massAction) {
                let evaluationForms:SubmitEvaluationForm[] = [];

                for (let i = 0; i < this._data.defaults.sampleKeys.length; i++) {
                    const dataMassAction: Partial<Evaluation> = {
                        sampleKey: this._data.defaults.sampleKeys[i],
                        label: form.label,
                        size: coerceNumberProperty(form.size, null),
                        protocolId: form.protocolId.toString(),
                        evalStartDate: coerseDateProperty(form.evalStartDate, null),
                        evalEndDate: coerseDateProperty(form.evalEndDate, null),
                        scheduleId: form.scheduleId,
                        storageRegime,
                    };

                    evaluationForms[i] = new SubmitEvaluationForm(dataMassAction);
                }
                this._store.dispatch(evaluationForms);
                this.submit();
            } else this._store.dispatch(new SubmitEvaluationForm(data));

        } else {
            this._snackbar.formInvalid();
        }

    }

    toggleDisplay(){
        this.isDisplay = !this.isDisplay;
    }

    setSelectedProtocol(event: MatOptionSelectionChange, protocol: Protocol | CustomProtocol ){
        if ((!event.isUserInput && !this.initialLoad) || !protocol ) return

        this.initialLoad = false;

        if (protocol.hasOwnProperty("protocolSubset")){
            this.selectedProtocol = this._custom_protocolService.toProtocol(protocol);
        }
        else this.selectedProtocol = protocol
    }

    cancel() {
        this._dialogRef.close(false);
    }

    submit() {
        this._dialogRef.close(true);
    }

    addStorageLine() {
        this.storageRegimeControl.push(this.makeStorageGroup());
    }

    removeStorageLine() {
        this.storageRegimeControl.removeAt(this.storageRegimeControl.controls.length - 1);
    }

    hasStorageLine() {
        return this.storageRegimeControl.controls.length > 0;
    }

    applyStorageRegime(data: SampleStorage[]) {

        this.storageRegimeControl.clear();

        if (Array.isArray(data)) {
            data.forEach(storageLine => {
                let duration = parseDuration(storageLine.duration);
                this.storageRegimeControl.push(this.makeStorageGroup(storageLine.temp, duration.time, duration.unit, storageLine.treatment ? storageLine.treatment : [], storageLine.ca, storageLine.caOptions ? storageLine.caOptions : {}));
            });
        }
    }

    applySchedule(data: { scheduleId: string, evaluation: any }) {

        this.scheduleIdControl.setValue(data.scheduleId);

        this.applyStorageRegime(data.evaluation.storage);
    }

    private makeStorageGroup(temp: number = 0, time: number = 1, unit: string = 'd', treatmentTypes: string[]= [], controlledAtmosphere: boolean = false, caOptions: {[key:string]:number} = {}) {
        return new FormGroup({
            temp: new FormControl(temp, [Validators.required]),
            time: new FormControl(time, [Validators.required, Validators.min(1)]),
            unit: new FormControl(unit, [Validators.required]),
            treatmentTypes : new FormControl(treatmentTypes),
            controlledAtmosphere: new FormControl(controlledAtmosphere),
            caOptions: new FormGroup({
                co2: new FormControl(caOptions.co2),
                o2: new FormControl(caOptions.o2),
                eth: new FormControl(caOptions.eth),
                rh: new FormControl(caOptions.rh)
            }),
            endDate: new FormControl(null),
        });
    }

    openStorageDialog(templateRef: TemplateRef<any>, storageGroup: FormGroup, storageIndex: number) {
        this.childDialogRef = this.dialog.open(templateRef, {data: {storageGroup, storageIndex}});
    }

    closeStorageDialog() {
        this.childDialogRef.close();
    }

    calculateDuration(endDate: Date, storageIndex: number) {
        let storageGroup;
        let total = 0, days;
        for(let i = 0; i < storageIndex; i++) {
            storageGroup = ((this.formGroup.controls.storageRegime as FormArray).at(i) as FormGroup).controls;

            days = storageGroup.time.value || 0;
            if(storageGroup.time.unit === "m") days *= 30;
            else if(storageGroup.time.unit === "w") days *= 7;

            total += days;
        }
        ((this.formGroup.controls.storageRegime as FormArray).at(storageIndex) as FormGroup).controls.unit.setValue("d");

        if (this._data.defaults.sample) {
            var start = moment(this._data.defaults.sample.birthDate);
        } else {
            var start = moment(this.evalStartDateControl.value);
        }

        let end = moment(endDate);

        let duration = moment.duration(end.diff(start)).asDays() - total;
        return (duration > 0) ? duration : 1;
    }
}

export interface EvaluationFormDialogData {
    key?: string;
    defaults?: Partial<Evaluation>;
}