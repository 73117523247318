<!-- Title -->
<div mat-dialog-title class="mat-dialog-header">
    <h2 class="dialog-title">Send Invitation</h2>
</div>

<!-- Content -->
<div mat-dialog-content>

    <!-- Form -->
    <form #organizationInviteForm="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()">

        <!-- Name -->
        <mat-form-field appearance="standard">
            <mat-label>User Name</mat-label>
            <input type="text" matInput formControlName="userName">
            <mat-error>Enter a name (min 3 chars)</mat-error>
        </mat-form-field>

        <!-- Email -->
        <mat-form-field appearance="standard">
            <mat-label>User Email</mat-label>
            <input type="text" matInput formControlName="userEmail">
            <mat-error>Enter a valid email address</mat-error>
        </mat-form-field>

        <!-- Role -->
        <mat-form-field appearance="standard">
            <mat-label>User Role</mat-label>
            <mat-select formControlName="userRole">
                <mat-option *ngFor="let role of roleOptions" [value]="role.value">{{ role.name }}</mat-option>
            </mat-select>
            <mat-error>Select a role</mat-error>
        </mat-form-field>

    </form>

</div>

<!-- Actions -->
<pv-form-actions mat-dialog-actions [form]="organizationInviteForm" (cancel)="cancel()">
</pv-form-actions>