import { Protocol } from './_types';

export const LIB_PROTOCOL_APPLE_TREE_BREED_EVAL: Protocol = {
    id: "apple_tree_breed_eval",
    title: "Observational Apple Tree Evaluation",
    description: "Observational Tree evaluation is performed to characterise cultivars and selections on the base of a sample set of tree characteristics and includes certain phenological stages. Characteristics such as growth and architecture, flowering and fruit set, pests and diseases and general adaptability are scored for brief reference.",
    sampleType: "plant",
    crop: "apple",

    chars: [

        "date_irb",
        "date_full_flower",
        "date_harvest_start",
        "date_harvest_end",

        "tree_grow_potent",

        "tree_flower_dense",

        "tree_bear_potent",
        "tree_color_inside",

        "tree_overall_disease",

        "tree_dormant_symptom",
        "tree_flower_span",
        "tree_uniform_ripe",
        "tree_overall_product",
        "tree_sunburn_symptom",
        "tree_overall_adapt"

    ],
    measures: [
        "tree_trunk_circum",
        "tree_total_yield",
        'tree_yield_eff',
    ],

    indexes: [],

    calculations: [
        "tree_yield_eff",
        "tons_per_ha_calc",
    ]
};