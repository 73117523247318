import { Protocol } from './_types';
import { Dictionary } from '../dictionary';

import { LIB_PROTOCOL_APPLE_HARVEST_SCREEN_EVAL } from './apple_harvest_screen_eval';
import { LIB_PROTOCOL_APPLE_TREE_SCREEN_EVAL } from './apple_tree_screen_eval';
import { LIB_PROTOCOL_APPLE_HARVEST_BREED_EVAL } from './apple_harvest_breed_eval';
import { LIB_PROTOCOL_APPLE_TREE_BREED_EVAL } from './apple_tree_breed_eval';

import { LIB_PROTOCOL_CITRUS_HARVEST_EATQUAL_EVAL } from './citrus_harvest_eatqual_eval';
import { LIB_PROTOCOL_CITRUS_TREE_SCREEN_EVAL } from './citrus_tree_screen_eval';

import { LIB_PROTOCOL_PEAR_HARVEST_SCREEN_EVAL } from './pear_harvest_screen_eval';
import { LIB_PROTOCOL_PEAR_TREE_SCREEN_EVAL } from './pear_tree_screen_eval';

import { LIB_PROTOCOL_STONE_HARVEST_SCREEN_EVAL } from './stone_harvest_screen_eval';
import { LIB_PROTOCOL_STONE_TREE_SCREEN_EVAL } from './stone_tree_screen_eval';
import { LIB_PROTOCOL_CHERRY_TREE_SCREEN_EVAL } from './cherry_tree_screen_eval';
import { LIB_PROTOCOL_CHERRY_HARVEST_SCREEN_EVAL } from './cherry_harvest_screen_eval';

import { LIB_PROTOCOL_GRAPE_VINE_SCREEN_EVAL } from './grape_vine_screen_eval';
import { LIB_PROTOCOL_GRAPE_HARVEST_SCREEN_EVAL } from './grape_harvest_screen_eval';
import { LIB_PROTOCOL_GRAPE_HARVEST_RAISIN_EVAL } from './grape_harvest_raisin_eval';
import { LIB_PROTOCOL_GRAPE_HARVEST_RAISIN_DRY_EVAL } from './grape_harvest_raisin_dry_eval';

import { LIB_PROTOCOL_HARVEST_IMPRESSION_EVAL } from './harvest_impression_eval';

import { LIB_PROTOCOL_CANNABIS_PLANT_SCREEN_EVAL } from './cannabis_plant_screen_eval';
import { LIB_PROTOCOL_CANNABIS_HARVEST_SCREEN_EVAL } from './cannabis_harvest_screen_eval';

import { LIB_PROTOCOL_MACADAMIA_TREE_SCREEN_EVAL } from './macadamia_tree_screen_eval';
import { LIB_PROTOCOL_MACADAMIA_HARVEST_SCREEN_EVAL } from './macadamia_harvest_screen_eval';

import { LIB_PROTOCOL_BLUEBERRY_HARVEST_SCREEN_EVAL } from './blueberry_harvest_screen_eval';
import { LIB_PROTOCOL_BLUEBERRY_BUSH_SCREEN_EVAL } from './blueberry_bush_screen_eval';

import { LIB_PROTOCOL_ADVANCED_CITRUS_HARVEST_EVAL } from './citrus_advanced_harvest_eval';
import { LIB_PROTOCOL_ADVANCED_CITRUS_TREE_EVAL } from './citrus_advanced_tree_eval';

import { LIB_PROTOCOL_AVOCADO_HARVEST_SCREEN_EVAL } from './avocado_harvest_screen_eval';
import { LIB_PROTOCOL_AVOCADO_TREE_SCREEN_EVAL } from './avocado_tree_screen_eval';

export class ProtocolDictionary extends Dictionary<Protocol>{
    constructor(){
        super([
            LIB_PROTOCOL_APPLE_HARVEST_SCREEN_EVAL,
            LIB_PROTOCOL_APPLE_TREE_SCREEN_EVAL,
            LIB_PROTOCOL_APPLE_HARVEST_BREED_EVAL,
            LIB_PROTOCOL_APPLE_TREE_BREED_EVAL,

            LIB_PROTOCOL_CITRUS_HARVEST_EATQUAL_EVAL,
            LIB_PROTOCOL_CITRUS_TREE_SCREEN_EVAL,

            LIB_PROTOCOL_PEAR_HARVEST_SCREEN_EVAL,
            LIB_PROTOCOL_PEAR_TREE_SCREEN_EVAL,

            LIB_PROTOCOL_STONE_HARVEST_SCREEN_EVAL,
            LIB_PROTOCOL_STONE_TREE_SCREEN_EVAL,
            LIB_PROTOCOL_CHERRY_TREE_SCREEN_EVAL,
            LIB_PROTOCOL_CHERRY_HARVEST_SCREEN_EVAL,

            LIB_PROTOCOL_GRAPE_VINE_SCREEN_EVAL,
            LIB_PROTOCOL_GRAPE_HARVEST_SCREEN_EVAL,
            LIB_PROTOCOL_GRAPE_HARVEST_RAISIN_EVAL,
            LIB_PROTOCOL_GRAPE_HARVEST_RAISIN_DRY_EVAL,

            LIB_PROTOCOL_HARVEST_IMPRESSION_EVAL,

            LIB_PROTOCOL_CANNABIS_PLANT_SCREEN_EVAL,
            LIB_PROTOCOL_CANNABIS_HARVEST_SCREEN_EVAL,

            LIB_PROTOCOL_MACADAMIA_TREE_SCREEN_EVAL,
            LIB_PROTOCOL_MACADAMIA_HARVEST_SCREEN_EVAL,

            LIB_PROTOCOL_BLUEBERRY_HARVEST_SCREEN_EVAL,
            LIB_PROTOCOL_BLUEBERRY_BUSH_SCREEN_EVAL,

            LIB_PROTOCOL_ADVANCED_CITRUS_HARVEST_EVAL,
            LIB_PROTOCOL_ADVANCED_CITRUS_TREE_EVAL,

            LIB_PROTOCOL_AVOCADO_HARVEST_SCREEN_EVAL,
            LIB_PROTOCOL_AVOCADO_TREE_SCREEN_EVAL,

        ], false);
    }
}

export * from './_types';
