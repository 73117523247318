import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dialog, Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { OrganizationFormDialog, OrganizationFormDialogData } from '../organization-form/organization-form.dialog';
import { InitOrganizationDetail, LoadOrganizationDetail, OrganizationDetailState, OrganizationDetailStateModel } from './organization-detail.state';
import { OrganizationGroupFormDialog, OrganizationGroupFormDialogData } from './organization-group-form.dialog';
import { AddGroup, RemoveGroup, GroupsStateModel, InitGroups, LoadGroups, GroupsState } from './groups.state';
import { OrganizationInviteFormDialog } from './organization-invite-form.dialog';
import { CreateOrganizationInvite, ExpireOrganizationInvite, InitOrganizationInvites, LoadOrganizationInvites, OrganizationInvitesState, OrganizationInvitesStateModel } from './organization-invites.state';
import { LoadOrganizationMembers } from './organization-members.state';
import { OrganizationGroup } from '@core/data';
import { InitOrganizationSubscription, LoadOrganizationSubscriptions, OrganizationSubscriptionState, OrganizationSubscriptionStateModel } from '@app/admin/organization-detail/organization-subscription.state';
import { OrganizationMembersState, OrganizationMembersStateModel } from './organization-members.state';
import { CultevaModules } from '@core/modules';
import { MODULES } from '@app/evaluation/modules';
import { Module } from '@core/modules/_types';

@Component({
    selector: 'pv-organization-detail-view',
    templateUrl: './organization-detail.view.html',
    host: {
        class: 'pv-organization-detail-view'
    },
    styleUrls: ['./organization-detail.view.scss']
})
export class OrganizationDetailView implements OnInit, OnDestroy {
    @Select(OrganizationDetailState)
    detailState$: Observable<OrganizationDetailStateModel>;

    @Select(GroupsState)
    groupsState$: Observable<GroupsStateModel>;

    @Select(OrganizationInvitesState)
    invitesState$: Observable<OrganizationInvitesStateModel>;

    // Subscriptions
    @Select(OrganizationSubscriptionState)
    subscriptionState$: Observable<OrganizationSubscriptionStateModel>;

    //Modules
    modules: Module[];

    role$: Observable<string>;
    private _destroy$ = new Subject<void>();

    constructor(
        private _store: Store,
        private _route: ActivatedRoute,
        private _dialogs: Dialog,
        private _snackbar: Snackbar,
        @Inject(MODULES) private _modules: CultevaModules,
    ) { }

    ngOnInit() {
        this.modules = this._modules.serialize().modules;

        this._route.paramMap
            .subscribe(params => {
                let orgKey = params.get('orgKey');
                this._store.dispatch(new InitGroups(orgKey));
                this._store.dispatch(new InitOrganizationSubscription(orgKey));
                this._store.dispatch(new InitOrganizationInvites(orgKey));
                this._store.dispatch(new InitOrganizationDetail(orgKey));
            });
    }

    resolveModuleSubscription(moduleKey: String) {
        if (!this.modules.find(module => module.id === moduleKey)) return "No module found";
        else return this.modules.find(module => module.id === moduleKey).name;
    }

    isCommonLib(moduleKey: string): boolean {
        let module: Module = this.modules.find(module => module.id === moduleKey);

        if (!module) return false;
        if (module.iconLib === 'common') return true;

        return false;
    }

    getIconRef(moduleKey: string): string {
        let module: Module = this.modules.find(module => module.id === moduleKey);

        if (!module) return '';
        if (module.iconLib === 'common') return `common:${module.iconRef}`;
        else return `${module.iconRef}`;
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * @param expiresAt date, checks if date has passed or not
     */
    isExpired(expiresAt: string) {
        return moment(expiresAt).isAfter();
    }

    createInvite() {
        this._dialogs.open(OrganizationInviteFormDialog)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this._store.dispatch(new CreateOrganizationInvite(result))
                        .subscribe(state => {
                            this._snackbar.info("User invite sent");
                        });
                }
            });
    }

    /**
     * @param orgKey the key of the organization you with to update the info of
     */
    updateInfo(orgKey: string) {
        let data: OrganizationFormDialogData = {
            key: orgKey,
        };

        this._dialogs.open(OrganizationFormDialog, { data })
            .afterClosed()
            .subscribe(result => {
                if (result) this.reloadInfo();
            });
    }

    /**
     * @param inviteId the id of the invite that you want to expire
     */
    expireInvite(inviteId: number) {
        this._dialogs.confirm(
            'Expire Invite',
            'Are you sure you want to expire this invitation?',
            'Expire',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new ExpireOrganizationInvite(inviteId))
                    .subscribe(state => {
                        this._snackbar.info('Invitation expired');
                    });
            }
        });
    }

    reloadInfo() {
        this._store.dispatch(new LoadOrganizationDetail);
    }

    reloadMembers() {
        this._store.dispatch(new LoadOrganizationMembers);
    }

    reloadInvites() {
        this._store.dispatch(new LoadOrganizationInvites);
    }

    reloadGroups() {
        this._store.dispatch(new LoadGroups);
    }

    // Subscription Refresh
    refreshSubscriptions() {
        this._store.dispatch(new LoadOrganizationSubscriptions);
    }

    addGroup(orgKey: string, group?: Partial<OrganizationGroup>) {
        let data: OrganizationGroupFormDialogData = {
            ownerOrgKey: orgKey,
            group: group,
        };
        this._dialogs.open(OrganizationGroupFormDialog, { data })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this._store.dispatch(new AddGroup(result))
                        .subscribe(_ => {
                            this._snackbar.info(result.key ? "Organisation group updated" : "Organisation group created");
                        });
                }
            });
    }

    removeGroup(model: OrganizationGroup) {
        if (model.samplesCount || model.usersCount) {
            this._snackbar.error('Remove user and sample connections or refresh data before group can be deleted.');
            return;
        }
        this._dialogs.confirm(
                'Remove Group',
                'Are you sure you want to delete this group? ',
                'Remove',
                'Cancel'
            ).afterClosed().subscribe(res => {
                if(res) {
                    this._store.dispatch(new RemoveGroup(model.key))
                        .subscribe(_ => {
                            this._snackbar.info("Organisation group deleted");
                        });
                }
            });
    }
}
