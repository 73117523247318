import { Injectable } from "@angular/core";
import { DetailRequest, Sample, Status, TastingSampleService, translateCommonErrorStatus } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface TastingsSampleFormStateModel {
    key: string;
    status: Status;
    data: Partial<TastingSample>;
}

const DEFAULTS: TastingsSampleFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
}

export class InitNewTastingSampleForm {
    static readonly type = "[TastiingSampleForm] Init new";
    constructor(public defaults?: Partial<TastingSample>) {}
}

export class InitUpdateTastingSampleForm {
    static readonly type = "[TastiingSampleForm] Init update";
    constructor(public key: string) {}
}

export class SubmitTastingSampleForm {
    static readonly type = "[TastiingSampleForm] Submit";
    constructor(public data: Partial<TastingSample>) {}
}

export class SubmitTastingSampleWithHarvestForm {
    static readonly type = "[TastingSampleFrom] Submit with harvest";
    constructor(public data: Partial<TastingSample>, public harvest: Partial<Sample>) {}
}

@State<TastingsSampleFormStateModel>({
    name: 'tasting_sample_form',
    defaults: DEFAULTS
})
@Injectable()
export class TastingSampleFormState {
    @Selector()
    static data(state: TastingsSampleFormStateModel) {
        return state.data;
    }

    constructor(private _tastingSampleService: TastingSampleService) {}

    @Action(InitNewTastingSampleForm, {cancelUncompleted: true})
    initNewTastingSampleForm(ctx: StateContext<TastingsSampleFormStateModel>, action: InitNewTastingSampleForm) {
        ctx.setState({
            key: null,
            data: {
                ...action.defaults
            },
            status: Status.OK
        });
    }

    @Action(InitUpdateTastingSampleForm, {cancelUncompleted: true})
    initUpdateTastingSampleForm(ctx: StateContext<TastingsSampleFormStateModel>, action: InitUpdateTastingSampleForm) {
        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING
        });

        let detailRequest: DetailRequest = {
            related: [
                'harvestSample',
                'harvestSample.scionCultivar',
                'harvestSample.site',
                'tastingsEvents',
                'scionCultivar',
                'site',
            ]
        };

        return this._tastingSampleService.get(action.key, detailRequest)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            status: Status.OK
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(SubmitTastingSampleForm)
    submitTastingSampleForm(ctx: StateContext<TastingsSampleFormStateModel>, action: SubmitTastingSampleForm) {
        const state = ctx.getState();

        let request: Observable<TastingSample>;

        if (state.key) request = this._tastingSampleService.update(state.key, { ...action.data });
        else request = this._tastingSampleService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(SubmitTastingSampleWithHarvestForm)
    submitTastingSampleWithHarvestForm(ctx: StateContext<TastingsSampleFormStateModel>, action: SubmitTastingSampleWithHarvestForm) {
        const state = ctx.getState();

        let request: Observable<TastingSample>;
        request = this._tastingSampleService.createWithHarvest({ ...action.data }, { ...action.harvest });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        })
                    }
                )
            );
    }
}