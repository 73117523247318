export const environment = {
    name: 'stage',
    production: true,
    secure_http: true,
    debug: true,

    web_host_base_uri: '//stage.culteva.pro',
    api_host_base_uri: '//app.stage.culteva.pro/api',

    cloudinary_cloud_name: 'provar-dev',

    google_api_key: 'AIzaSyDdzLtn2SsGFvgEjrTYpTRb3BgyHA7Vz2w',

    tastings_base_uri: 'https://stage.taste.culteva.pro',

    beamer_config_key: 'nxuhqgCM65474',
    beamer_src_url: 'https://app.getbeamer.com/js/beamer-embed.js',
  };
