<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>Import&nbsp;Tastings Evaluations</span>
        </h2>
        <!-- TODO add something similar to pv org control -->
        <h3 class="dialog-subtitle" *ngIf="isEvaluationImport(state.subjectType)">
            Event: {{ state.subject?.title }}
        </h3>
    </div>

    <div mat-dialog-content>
        <form
            #importForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            novalidate
            autocomplete="no"
        >
            <div class="stepper-wrapper">
                <mat-horizontal-stepper labelPosition="bottom" linear>
                    <mat-step [label]="isStaticSubject() ? 'Event Detail' : 'Select Event'" [stepControl]="subjectKeyControl">
                        <div class="step-layout">
                            <ng-container *ngIf="!isStaticSubject(); else staticEvent">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{ state.subjectType | describeImportSubjectType }}</mat-label>
                                    <mat-select formControlName="subjectKey">
                                        <!--* tastings evaluation import -->
                                        <ng-container *ngIf="isEvaluationImport(state.subjectType)">
                                            <mat-option *ngFor="let event of state.tastingEventOptions" [value]="event.key">
                                                {{ event.title }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #staticEvent>
                                <div class="step-column">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="tastings-card-content">
                                                <ng-container *ngIf="state.subject?.tastingsEventImage?.fileRef as ref; else defaultBanner">
                                                    <pv-image-ref
                                                        class="card-sample-image"
                                                        [fileRef]="ref"
                                                        transformId="thumb_72"
                                                    >
                                                    </pv-image-ref>
                                                </ng-container>
                                                <ng-template #defaultBanner>
                                                    <pv-image-ref
                                                        class="card-sample-image"
                                                        [fileRef]="defaultBannerPath"
                                                        transformId="thumb_72"
                                                    >
                                                    </pv-image-ref>
                                                </ng-template>
                                                <div>
                                                    <mat-card-title>{{ state.subject?.title }}</mat-card-title>
                                                    <mat-card-subtitle>{{ state.subject?.completed ? 'Completed' : 'Active' }}</mat-card-subtitle>
                                                    <span>{{ state.subject?.description }}</span>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </ng-template>
                        </div>
                    </mat-step>
                    <mat-step [label]="getMidStepHeading(state.subjectType)" [stepControl]="importFormGroup">
                        <div class="step-layout">
                            <div class="step-column" *ngIf="currentTypeHasDetail(state.subjectType)">
                                <ng-container *ngFor="let sample of state.subject?.tastingsSamples">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="tastings-card-content">
                                                <ng-container *ngIf="sample.tastingsSampleImage; else cropIcon">
                                                    <pv-image-ref
                                                        class="card-sample-image"
                                                        *ngIf="sample.tastingsSampleImage.fileRef as ref"
                                                        [fileRef]="ref"
                                                        transformId="thumb_72"
                                                    >
                                                    </pv-image-ref>
                                                </ng-container>
                                                <ng-template #cropIcon>
                                                    <mat-icon
                                                        class="card-crop-icon"
                                                        [svgIcon]="'common:' + sample.harvestSample?.scionCultivar?.cropId">
                                                    </mat-icon>
                                                </ng-template>
                                                <div>
                                                    <mat-card-title>{{ sample.code }}</mat-card-title>
                                                    <mat-card-subtitle>{{ sample.label }}</mat-card-subtitle>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </div>
                            <div class="step-column">
                                <ng-container>
                                    <h4 class="form-subheader">Import</h4>
                                    <div class="pv-form single-column">
                                        <pv-import-upload (file)="setFormFile($event)"></pv-import-upload>

                                        <div class="form-actions">
                                            <button
                                                *ngIf="hasImportTemplate(state.subjectType)"
                                                mat-flat-button
                                                color="accent"
                                                (click)="$event.preventDefault(); downloadImportTemplate(state.subjectKey, state.subject)"
                                                [disabled]="importFormGroup.disabled"
                                            >
                                                Download Import Template
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="hasDateControl(state.subjectType)">
                                    <h4 class="form-subheader">Date Scored</h4>

                                    <mat-form-field appearance="standard">
                                        <mat-label>Date</mat-label>
                                        <input matInput formControlName="scoreDate" [matDatepicker]="evalDatepicker" required>
                                        <mat-datepicker-toggle matSuffix [for]="evalDatepicker"></mat-datepicker-toggle>
                                        <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                                        <mat-hint>Date of the event</mat-hint>
                                        <mat-datepicker #evalDatepicker startView="multi-year"></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard">
                                        <mat-label>Start Time</mat-label>
                                        <input matInput type="time" formControlName="scoreTime">
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step label="Submit">
                        <div class="step-layout">
                            <div class="review-col-wrapper">
                                <mat-label class="review-label">Event</mat-label>
                                <div class="review-columns">
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="review-card-content">
                                                <ng-container *ngIf="state.subject?.tastingsEventImage?.fileRef as ref; else defaultBanner">
                                                    <pv-image-ref
                                                        class="review-card-content"
                                                        [fileRef]="ref"
                                                        transformId="thumb_72"
                                                    >
                                                    </pv-image-ref>
                                                </ng-container>
                                                <ng-template #defaultBanner>
                                                    <pv-image-ref
                                                        class="review-card-content"
                                                        [fileRef]="defaultBannerPath"
                                                        transformId="thumb_72"
                                                    >
                                                    </pv-image-ref>
                                                </ng-template>
                                                <div>
                                                    <mat-card-title>{{ state.subject?.title }}</mat-card-title>
                                                    <mat-card-subtitle>{{ state.subject?.completed ? 'Completed' : 'Active' }}</mat-card-subtitle>
                                                    <span>{{ state.subject?.description }}</span>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>

                            <mat-divider [vertical]="true"></mat-divider>

                            <div class="review-col-wrapper" *ngIf="currentTypeHasDetail(state.subjectType)">
                                <mat-label class="review-label">Samples</mat-label>
                                <div class="review-columns">
                                    <ng-container *ngFor="let sample of state.subject?.tastingsSamples">
                                        <mat-card>
                                            <mat-card-content>
                                                <div class="review-card-content">
                                                    <ng-container *ngIf="sample.tastingsSampleImage; else cropIcon">
                                                        <pv-image-ref
                                                            class="review-card-content"
                                                            *ngIf="sample.tastingsSampleImage.fileRef as ref"
                                                            [fileRef]="ref"
                                                            transformId="thumb_72"
                                                        >
                                                        </pv-image-ref>
                                                    </ng-container>
                                                    <ng-template #cropIcon>
                                                        <mat-icon
                                                            class="card-crop-icon"
                                                            [svgIcon]="'common:' + sample.harvestSample?.scionCultivar?.cropId">
                                                        </mat-icon>
                                                    </ng-template>
                                                    <div>
                                                        <mat-card-title>{{ sample.code }}</mat-card-title>
                                                        <mat-card-subtitle>{{ sample.label }}</mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </div>
                            </div>

                            <mat-divider [vertical]="true"></mat-divider>
                            <div class="review-col-wrapper">
                                <mat-label class="review-label">File</mat-label>
                                <div class="review-columns">
                                    <mat-card *ngIf="getFormFile() as file; else fileError">
                                        <mat-card-content>
                                            <div class="review-card-content">
                                                <mat-icon class="review-card-icon">description</mat-icon>
                                                <div>
                                                    <mat-card-title>{{ file?.name }}</mat-card-title>
                                                    <mat-card-subtitle>{{ file?.size | displayFileSize }} </mat-card-subtitle>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                    <ng-template #fileError>
                                        Unable to display file information.
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true"
        [form]="importForm">
    </pv-form-actions>
</ng-container>