import { Injectable, ErrorHandler, NgZone } from "@angular/core";
import { Snackbar } from "@core/material";
import { HttpErrorResponse } from "@angular/common/http";
import { InvalidCredentialsError, DuplicateCommonNameError, DuplicateStationNameError, DuplicateWeatherDataError, DuplicateEmailError, SiteConnectionsError, CultivarConnectionsError } from '@core/data';

// see https://github.com/angular/angular/blob/master/packages/core/src/error_handler.ts

@Injectable()
export class AppErrorHandler extends ErrorHandler {

    lastError: any = null;

    constructor(private _snackbar: Snackbar, private _ngZone: NgZone){
        super();
    }

    handleError(error: any) {

        if(this.lastError === error){
            console.warn("Same error detected.");
            return;
        }


        if(error instanceof InvalidCredentialsError){
            this.snackMessage("Invalid email address or password.");
        }else if(error instanceof DuplicateStationNameError){
            this.snackMessage("Weather Station name must be unique.");
        }else if(error instanceof DuplicateCommonNameError){
            this.snackMessage("Cultivar common name must be unique.");
        }else if(error instanceof SiteConnectionsError){
            this.snackMessage("Could not remove site with sample associations.");
        }else if(error instanceof CultivarConnectionsError){
            this.snackMessage("Could not remove cultivar with sample associations.");
        }else if(error instanceof DuplicateWeatherDataError){
            this.snackMessage("File contains duplicate data.");
        }else if(error instanceof DuplicateEmailError){
            this.snackMessage("Email address has been taken.");
        }else if(error instanceof HttpErrorResponse){
            console.warn('AppErrorHandler: Caught HttpErrorResponse status=%d', error.status);
            switch(error.status){
                case 422:
                    this.snackMessage('The data entered is invalid. Check your input and try again.');
                    break;
                case 403:
                    this.snackMessage('You are not authorized to make that request.');
                    break;
                case 404:
                    this.snackMessage('The resource does not exist.');
                    break;
                default:
                    this.snackMessage('Something went wrong with that request...');
            }
        }else if(error instanceof Error){

            if(error.name === 'TypeError'){
                // TypeError
            }else{
                this.snackMessage('Something went wrong... Contact support if the error persists.');
            }

            console.error('AppErrorHandler: Caught Error [%s] "%s"', error.name, error.message);

        }else if(typeof error === 'string'){
            // console.error('AppErrorHandler: Caught string "%s"', error);
            this.snackMessage('Something went wrong... Contact support if the error persists.');
        }else{
            // console.error('AppErrorHandler: Caught unknown %o', error);
            this.snackMessage('Something went wrong... Contact support if the error persists.');
        }
        console.error(error);
        this.lastError = error;
        // super.handleError(error);
    }

    snackMessage(message: string){
        this._ngZone.run(() => {
            this._snackbar.error(message);
        });
    }

}