import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Dialog, Snackbar } from "@core/material";
import { Observable, Subject } from "rxjs";
import { ClearTastingCustomIndexesIndexFilter, DeleteTastingCustomIndex, InitTastingCustomIndexesIndex, LoadTastingCustomIndexesIndex, PageTastingCustomIndexesIndex, QueryTastingCustomIndexesIndex, SetTastingCustomIndexesIndexSelected, SortTastingCustomIndexesIndex } from "./tasting-custom-indexes-index.state-actions";
import { AuthState } from "@app/auth";
import { FilterSort, Index, Organization } from "@core/data";
import { TastingCustomIndexesIndexState } from "./tasting-custom-indexes-index.state";
import { TastingCustomIndexesIndexStateModel, TastingCustomIndexesQuery } from "./tasting-custom-indexes-index.state-model";
import { FormControl, FormGroup } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { debounceTime, takeUntil } from "rxjs/operators";
import { TastingCustomIndexesFormDialog, TastingCustomIndexesFormDialogData } from "../tasting-custom-indexes-form/tasting-custom-indexes-form.dialog";

@Component({
    selector: 'pv-tasting-custom-indexes-index-view',
    templateUrl: 'tasting-custom-indexes-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tasting-custom-indexes-index-view'
    }
})
export class TastingCustomIndexesIndexView implements OnInit, OnDestroy {

    @Select(AuthState.selectedOrg)
    selectedOrg$: Observable<Organization>;

    @Select(TastingCustomIndexesIndexState)
    state$: Observable<TastingCustomIndexesIndexStateModel>;

    @Select(TastingCustomIndexesIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(TastingCustomIndexesIndexState.query)
    query$: Observable<TastingCustomIndexesQuery>;

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl
    });

    searchControl = new FormControl();
    cropKeysControl = new FormControl([]);
    weightedCharKeysControl = new FormControl([]);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        cropKeys: this.cropKeysControl,
        weightedCharKeys: this.weightedCharKeysControl
    });

    selection = new SelectionModel<string>(true, []);
    selectedTabIndex = 0;
    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
    ) {}

    ngOnInit(): void {
        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(new InitTastingCustomIndexesIndex(params.get('orgKey')));
            });

        //QUERY LOGIC
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                this.queryFormGroup.patchValue(query, {emitEvent: false})
            });

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new QueryTastingCustomIndexesIndex(val));
            });
        //QUERY LOGIC END

        //SORT LOGIC
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingCustomIndexesIndex(val.column, val.order));
            });
        //SORT LOGIC END

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetTastingCustomIndexesIndexSelected(this.selection.selected));
            });
    }

    reload() {
        this._store.dispatch(new LoadTastingCustomIndexesIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingCustomIndexesIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingCustomIndexesIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingCustomIndexesIndex('updatedAt', 'desc'));
        }
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingCustomIndexesIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
     isAllSelected(data: Index[]) {
        return data.every((sample) => {
            return this.selection.isSelected(sample.key);
        });
    }

    isSelected(index: Index) {
        return this.selection.isSelected(index.key)
    }

    select(index: Index) {
        if (this.selection.isSelected(index.key)) this.selection.deselect(index.key);
        else this.selection.select(index.key);
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: Index[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    add() {
        const state: TastingCustomIndexesIndexStateModel = this._store.selectSnapshot(TastingCustomIndexesIndexState);

        const data: TastingCustomIndexesFormDialogData = {
            defaults: {
                ownerOrgKey: state.orgKey
            },
            cropOptions: state.cropOptions,
            weightedCharOptions: state.weightedCharOptions,
        }

        this._dialog.open(TastingCustomIndexesFormDialog, {data});
    }

    edit(index: Index) {
        const state: TastingCustomIndexesIndexStateModel = this._store.selectSnapshot(TastingCustomIndexesIndexState);

        const data: TastingCustomIndexesFormDialogData = {
            key:  index.key,
            cropOptions: state.cropOptions,
            weightedCharOptions: state.weightedCharOptions,
        };

        this._dialog.open(TastingCustomIndexesFormDialog, {data});
    }

    delete(index: Index) {
        if (index.weighted_chars.length > 0) {
            this._snackbar.error('Remove Weighted Characteristics connections or refresh data before Index can be deleted.');
            return;
        }

        this._dialog.confirm(
            `Delete Custom Index ${index.title}`,
            'Are you sure you want to delete this index and all its associated data?'
        )
            .afterClosed()
            .subscribe(result => {
                if (result) this._store.dispatch(new DeleteTastingCustomIndex(index.key));
            });
    }

    deleteSelected(indexes: Index[]) {
        if (!this.canDelete(indexes)) {
            this._snackbar.error('Remove Weighted Characteristics connections or refresh data before Index can be deleted.');
            return;
        }

        var deleteList: DeleteTastingCustomIndex[] = [];
        indexes.forEach(index => deleteList.push(new DeleteTastingCustomIndex(index.key)));

        this._dialog.confirm(
            `Delete ${indexes.length} selected Custom Indexes`,
            `Are you sure you want to delete these indexes and all their associated data?`
        ).afterClosed()
        .subscribe(result => {
            if (result) {
                this._store.dispatch(deleteList).subscribe(complete => {
                    this.selection.clear();
                });
            }
        });
    }

    navigateToSupport() {
        window.open('https://culteva.hipporello.net/desk', '_blank');
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private canDelete(indexes: Index[]): boolean {
        let hasConnections = false;

        indexes.forEach(index => {
            if (index.weighted_chars.length > 0) hasConnections = true;
        });

        return !hasConnections;
    }
}