import * as moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';

export function coerceTimestampProperty(value: any, def: any = null, utc = false) {

    let m = coerceMomentProperty(value);

    if (m !== null) {
        if(utc) m.utcOffset(0, true);
        return m.format(TIMESTAMP_FORMAT);
    }

    return def;
}

export function coerseDateProperty(value: any, def: any = null) {

    let m = coerceMomentProperty(value);

    if (m !== null) {
        return m.format(DATE_FORMAT);
    }

    return def;
}

export function coerceMomentProperty(value: any, def: any = null) {
    if (value === null || value === undefined) return def;
    if (moment.isMoment(value)) return value.isValid() ? value : def;
    let m = moment(value);
    if (m.isValid()) return m;
    return def;
}

export function coerseStringProperty(value: any, def: any = null) {

    if (value === null || value === undefined) return def;
    if (typeof value === "string") return value;
    if (typeof value === "number") return value.toString();

    return String(value);
}