import { Characteristic, CharacteristicType } from './_types';


export const LIB_CHARS_FRUT_HARVEST: Characteristic[] = [

    {
        id: "frut_ripe_stage",
        type: CharacteristicType.Interval,
        label: "Ripening Stage",
        categoryId: "frut_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Unripe",
                "Immature",
                "Ideal Eat Ripeness",
                "Mature",
                "Post Optimum"
            ]
        }
    },
    {
        id: "frut_ripe_uniformity",
        type: CharacteristicType.Interval,
        label: "Ripening Uniformity",
        categoryId: "frut_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Variable",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "frut_firmness",
        type: CharacteristicType.Interval,
        label: "Physical Firmness",
        categoryId: "frut_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft",
                "Medium",
                "Firm",
                "Very Firm",
            ]
        }
    },
    {
        id: "frut_firmness_homog",
        type: CharacteristicType.Interval,
        label: "Firmness Homogeneity",
        categoryId: "frut_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Large Variation",
                "Variable",
                "Intermediate",
                "Little Variation",
                "Homogenous"
            ]
        }
    },
    {
        id: "frut_mature_rate",
        type: CharacteristicType.Interval,
        label: "Maturation Rate",
        categoryId: "frut_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Slow",
                "Slow",
                "Average",
                "Rapid",
                "Very Rapid"
            ]
        }
    },
    {
        id: "frut_size",
        type: CharacteristicType.Interval,
        label: "Fruit Size",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Small",
                "Small",
                "Average",
                "Large",
                "Very Large",
            ]
        }
    },
    {
        id: "frut_over_color",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Over Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "frut_ground_color",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Ground Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "frut_ground_color_pome",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Ground Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "frut_ground_color_stone",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Ground Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" },
                { h: 15, s: 90, l: 85, label: "Cream" },
                { h: 0, s: 0, l: 100, label: "White" }
            ]
        }
    },

    {
        id: "frut_over_color_uniform",
        type: CharacteristicType.Interval,
        label: "Over Colour Uniformity",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Variable",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "frut_flesh_color",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Flesh Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" },
                { h: 15, s: 90, l: 85, label: "Cream" },
                { h: 0, s: 0, l: 100, label: "White" }
            ]
        }
    },
    {
        id: "frut_flesh_intense",
        type: CharacteristicType.Interval,
        label: "Flesh Colour Intensity",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Light",
                "Light",
                "Moderate",
                "Intense",
                "Very Intense",
            ]
        }
    },
    {
        id: "frut_flesh_distrib",
        type: CharacteristicType.Interval,
        label: "Flesh Colour Distribution",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Coverage", maxDesc: "Completely Covered",
            descriptors: [
                "Little Coverage",
                "Half Covered",
                "Mostly Covered"
            ]
        }
    },
    {
        id: "frut_cavity_color",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Stone Cavity Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" },
                { h: 15, s: 90, l: 85, label: "Cream" },
                { h: 0, s: 0, l: 100, label: "White" }
            ]
        }
    },
    {
        id: "frut_flesh_bleed",
        type: CharacteristicType.Nominal,
        categoryId: "frut_appearance",
        label: "Bleeding Flesh",
        params: {
            "multiple": false,
            options: [
                { value: "from_skin", label: "From Skin" },
                { value: "from_stone", label: "From Stone" },
                { value: "throughout", label: "Throughout" }
            ]
        }
    },
    {
        id: "frut_over_area",
        type: CharacteristicType.Interval,
        label: "Over Colour Area",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Coverage", maxDesc: "Completely Covered",
            descriptors: [
                "Little Coverage",
                "Half Covered",
                "Mostly Covered"
            ]
        }
    },
    {
        id: "frut_over_pattern",
        type: CharacteristicType.Nominal,
        categoryId: "frut_appearance",
        label: "Over Colour Pattern",
        params: {
            options: [
                { value: "uncolored", label: "Uncoloured" },
                { value: "striped", label: "Striped" },
                { value: "blushed", label: "Blushed" },
                { value: "bi_colored", label: "Bi-Coloured" },
                { value: "mottled", label: "Mottled" },
                { value: "full_colored", label: "Full Coloured" }
            ]
        }
    },
    {
        id: "frut_over_pattern_cherry",
        type: CharacteristicType.Nominal,
        categoryId: "frut_appearance",
        label: "Over Colour Pattern",
        params: {
            options: [
                { value: "blushed", label: "Blushed" },
                { value: "bi_colored", label: "Bi-Coloured" },
                { value: "full_colored", label: "Full Coloured" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "frut_stripe_intense",
        type: CharacteristicType.Interval,
        label: "Stripe Intensity",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Stripes",
            descriptors: [
                "Thin  Stripes",
                "Medium Stripes",
                "Broad  Stripes"
            ]
        }
    },
    {
        id: "frut_skin_bloom",
        type: CharacteristicType.Nominal,
        categoryId: "frut_appearance",
        label: "Bloom of Skin",
        params: {
            "multiple": false,
            options: [
                { value: "absent", label: "Absent" },
                { value: "light", label: "Light" },
                { value: "moderate", label: "Moderate" },
                { value: "strong", label: "Strong" }
            ]
        }
    },
    {
        id: "frut_flesh_brown",
        type: CharacteristicType.Interval,
        label: "Flesh browning",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Non-browning",
                "Moderate browning",
                "Strong browning",
            ]
        }
    },
    {
        id: "frut_pubescent",
        type: CharacteristicType.Interval,
        label: "Pubescence",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_stem_color",
        type: CharacteristicType.Nominal,
        label: "Stem Colour",
        categoryId: "frut_appearance",
        params: {
            "multiple": false,
            options: [
                { value: "green", label: "Green" },
                { value: "light_pigmented", label: "Light Pigmented" },
                { value: "strong_pigmented", label: "Strong Pigmented" }
            ]
        }
    },
    {
        id: "frut_stem_fresh",
        type: CharacteristicType.Interval,
        label: "Stem Freshness",
        categoryId: "frut_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Dry / Brittle",
                "Wilted",
                "Fresh",
            ]
        }
    },
    {
        id: "frut_pit_color",
        type: CharacteristicType.Color,
        categoryId: "frut_appearance",
        label: "Pit Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "frut_stone_adhesion",
        type: CharacteristicType.Interval,
        label: "Stone Adhesion",
        categoryId: "frut_stone_flesh",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Freestone",
                "Semi-Adhesive",
                "Clingstone"
            ]
        }
    },
    {
        id: "frut_stone_size",
        type: CharacteristicType.Interval,
        label: "Stone Size",
        categoryId: "frut_stone_flesh",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Small",
                "Small",
                "Medium",
                "Large",
                "Very Large"
            ]
        }
    },
    {
        id: "frut_flesh_type_stone",
        type: CharacteristicType.Nominal,
        categoryId: "frut_stone_flesh",
        label: "Flesh Type",
        params: {
            options: [
                { value: "non_melting", label: "Non Melting" },
                { value: "firm_melting", label: "Firm Melting" },
                { value: "melting", label: "Melting" }
            ]
        }
    },
    {
        id: "frut_shape_type_apple",
        type: CharacteristicType.Nominal,
        categoryId: "frut_shape_symmetry",
        label: "Shape Type",
        params: {
            options: [
                { value: "cylindrical_waisted", label: "Cylindrical Waisted" },
                { value: "conic", label: "Conic" },
                { value: "ovoid", label: "Ovoid" },
                { value: "cylindrical", label: "Cylindrical" },
                { value: "ellipsoid", label: "Ellipsoid" },
                { value: "globuse", label: "Globuse" },
                { value: "obloid", label: "Obloid" },
                { value: "apple", label: "Apple" }
            ]
        }
    },
    {
        id: "frut_shape_type_pear",
        type: CharacteristicType.Nominal,
        categoryId: "frut_shape_symmetry",
        label: "Shape Type",
        params: {
            options: [
                { value: "s_1", label: "Turbinate" },
                { value: "s_2", label: "Oblong-Ovate-Pyriform" },
                { value: "s_3", label: "Obovate-Acute-Pyriform" },
                { value: "s_4", label: "Oblong-Pyriform" },
                { value: "s_5", label: "Globular-Obtuse-Pyriform" },
                { value: "s_6", label: "Ovate-Pyriform" },
                { value: "s_7", label: "Oblong-Ovate-Pyriform" },
                { value: "s_8", label: "Ovate" },
                { value: "s_9", label: "Obovate-Pyriform" },
                { value: "s_10", label: "Globular" },
                { value: "s_11", label: "Globular-Acute-Pyriform" },
                { value: "s_12", label: "Oriental-Pear" },
                { value: "s_13", label: "Obloid (Flat)" }
            ]
        }
    },
    {
        id: "frut_shape_type_stone",
        type: CharacteristicType.Nominal,
        categoryId: "frut_shape_symmetry",
        label: "Shape Type",
        params: {
            options: [
                { value: "very_flat", label: "Very Flat (Doughnut)" },
                { value: "slightly_flat", label: "Slightly Flat" },
                { value: "rounded", label: "Rounded" },
                { value: "ovate", label: "Ovate" },
                { value: "oblong", label: "Oblong" },
                { value: "elongated", label: "Elongated" }
            ]
        }
    },
    {
        id: "frut_shape_type_cherry",
        type: CharacteristicType.Nominal,
        categoryId: "frut_shape_symmetry",
        label: "Shape Type",
        params: {
            options: [
                { value: "flat_heart", label: "Flat Heart" },
                { value: "heart", label: "Heart" },
                { value: "kidney", label: "Kidney" },
                { value: "long_heart", label: "Long Heart" },
                { value: "round", label: "Round" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "frut_shape_symmetry",
        type: CharacteristicType.Interval,
        label: "Shape Symmetry",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Asymmetrical",
                "Intermediate",
                "Symmetrical"
            ]
        }
    },
    {
        id: "frut_shape_consistent",
        type: CharacteristicType.Interval,
        label: "Shape Consistency",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Variable",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "frut_pendicul_depth",
        type: CharacteristicType.Interval,
        label: "Pendicular Opening Depth",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Shallow",
                "Shallow",
                "Intermediate",
                "Deep",
                "Very Deep"
            ]
        }
    },
    {
        id: "frut_calyx_depth",
        type: CharacteristicType.Interval,
        label: "Calyx Opening Depth",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Shallow",
                "Shallow",
                "Intermediate",
                "Deep",
                "Very Deep"
            ]
        }
    },
    {
        id: "frut_calyx_width",
        type: CharacteristicType.Interval,
        label: "Calyx Openness Width",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Narrow",
                "Narrow",
                "Intermediate",
                "Wide",
                "Very Wide"
            ]
        }
    },
    {
        id: "frut_pendicul_tear",
        type: CharacteristicType.Interval,
        label: "Pendicular Tearing",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_pendicul_width",
        type: CharacteristicType.Interval,
        label: "Pendicular Opening Width",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Narrow",
                "Narrow",
                "Intermediate",
                "Wide",
                "Very Wide"
            ]
        }
    },
    {
        id: "frut_pestilar_split",
        type: CharacteristicType.Interval,
        label: "Pistilar Cavity Split",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_calyx_tip",
        type: CharacteristicType.Interval,
        label: "Fruit Tip",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_calyx_soft",
        type: CharacteristicType.Interval,
        label: "Fruit Tip Softening",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_suture",
        type: CharacteristicType.Interval,
        label: "Fruit Suture",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_stem_length",
        type: CharacteristicType.Interval,
        label: "Stem Length",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Short ~10mm",
                "Short ~20mm",
                "Intermediate ~30mm",
                "Long ~40mm",
                "Very Long >50mm"
            ]
        }
    },
    {
        id: "frut_stem_thick",
        type: CharacteristicType.Interval,
        label: "Stem Thickness",
        categoryId: "frut_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Thick",
                "Very Thick"
            ]
        }
    },
    {
        id: "frut_abrasion",
        type: CharacteristicType.Interval,
        label: "Abrasions",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_blemish",
        type: CharacteristicType.Interval,
        label: "Blemishes",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_wounds",
        type: CharacteristicType.Interval,
        label: "Wounds",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_russet",
        type: CharacteristicType.Interval,
        label: "Russeting",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_russet_pos_apple",
        type: CharacteristicType.Nominal,
        categoryId: "frut_abrasion_blemish",
        label: "Russeting Position",
        params: {
            options: [
                { value: "stem_area", label: "Stem Area" },
                { value: "calyx_area", label: "Calyx Area" },
                { value: "full", label: "Entire Fruit" }
            ]
        }
    },
    {
        id: "frut_russet_pos_pear",
        type: CharacteristicType.Nominal,
        categoryId: "frut_abrasion_blemish",
        label: "Russeting Position",
        params: {
            options: [
                { value: "stem_area", label: "Stem Area" },
                { value: "calyx_area", label: "Calyx Area" },
                { value: "tip", label: "Tip" },
                { value: "top", label: "Top" },
                { value: "neck", label: "Neck" },
                { value: "full", label: "Entire Fruit" }
            ]
        }
    },
    {
        id: "frut_russet_type",
        type: CharacteristicType.Nominal,
        categoryId: "frut_abrasion_blemish",
        label: "Russeting Type",
        params: {
            options: [
                { value: "fine", label: "Fine" },
                { value: "net_like", label: "Net-Like" },
                { value: "rough", label: "Rough" },
                { value: "cracked", label: "Cracked" },
                { value: "retiform", label: "Retiform" },
            ]
        }
    },
    {
        id: "frut_pestilar_russet",
        type: CharacteristicType.Interval,
        label: "Pistilar Cavity Russeting",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_lenticel",
        type: CharacteristicType.Interval,
        label: "Lenticel Presence",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_spot",
        type: CharacteristicType.Interval,
        label: "Spots",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_sugarspot",
        type: CharacteristicType.Interval,
        label: "Sugar Spots",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_sunburn_presence",
        type: CharacteristicType.Interval,
        label: "Sunburn Presence",
        categoryId: "frut_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_taste_sugar",
        type: CharacteristicType.Interval,
        label: "Sugar",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Sweetness",
                "Low Sweetness",
                "Intermediate",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "frut_taste_acid",
        type: CharacteristicType.Interval,
        label: "Acid",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Acid",
                "Low Acid",
                "Intermediate",
                "Tart",
                "Very Tart"
            ]
        }
    },
    {
        id: "frut_taste_balance",
        type: CharacteristicType.Interval,
        label: "Sugar Acid Balance",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Tarty",
                "Tarty",
                "Balanced",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "frut_taste_astringe",
        type: CharacteristicType.Interval,
        label: "Astringency",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Astringency",
            descriptors: [
                "Very Low Astringency",
                "Low Astringency",
                "Intermediate",
                "Astringent",
                "High Astringency",
            ]
        }
    },
    {
        id: "frut_taste_aroma",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "frut_taste_juice",
        type: CharacteristicType.Interval,
        label: "Juiciness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Dry",
                "Dry",
                "Intermediate",
                "Juicy",
                "Very Juicy"
            ]
        }
    },
    {
        id: "frut_skin_thick",
        type: CharacteristicType.Interval,
        label: "Skin Thickness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Thick / Crisp",
                "Very Thick"
            ]
        }
    },
    {
        id: "frut_text_firm",
        type: CharacteristicType.Interval,
        label: "Firmness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft",
                "Intermediate",
                "Firm",
                "Compact / Hard"
            ]
        }
    },
    {
        id: "frut_text_crisp",
        type: CharacteristicType.Interval,
        label: "Texture Crispness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Melting",
                "Soft",
                "Intermediate",
                "Crisp",
                "Very Crisp",
            ]
        }
    },
    {
        id: "frut_text_crisp_apple",
        type: CharacteristicType.Interval,
        label: "Texture Crispness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Crisp",
                "Very Crisp"
            ]
        }
    },
    {
        id: "frut_text_meal",
        type: CharacteristicType.Interval,
        label: "Texture Mealiness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Mealy",
                "Very Mealy",
            ]
        }
    },
    {
        id: "frut_text_butter",
        type: CharacteristicType.Interval,
        label: "Texture Butteriness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Buttery",
                "Very Buttery",
            ]
        }
    },
    {
        id: "frut_text_crunch",
        type: CharacteristicType.Interval,
        label: "Texture Crunchiness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Resistance",
                "Low Resistance",
                "Intermediate",
                "Crunchy",
                "Very Crunchy"
            ]
        }
    },
    {
        id: "frut_text_grain",
        type: CharacteristicType.Interval,
        label: "Grain",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Melting / Very Fine",
                "Fine",
                "Intermediate",
                "Grainy",
                "Coarse"
            ]
        }
    },
    {
        id: "frut_stonecell",
        type: CharacteristicType.Interval,
        label: "Stone Cells",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Few",
                "Few",
                "Intermediate",
                "Many",
                "Prominent"
            ]
        }
    },
    {
        id: "frut_disease_stone",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "Diseases Detected",
        params: {
            options: [
                { value: "botrytis", label: "Botrytis" },
                { value: "monilinia_laxa", label: "Monilinia Laxa" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "frut_rot_symptom",
        type: CharacteristicType.Interval,
        label: "Mouldy/Rotten Fruit",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High",
            ]
        }
    },
    {
        id: "frut_disease_pome",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "Diseases Detected",
        params: {
            options: [
                { value: "brown_rot", label: "Brown Rot" },
                { value: "core_rot", label: "Core Rot" },
                { value: "varied_rot", label: "Varied Rot" },
                { value: "gloeosporium_rot", label: "Gloeosporium Rot" },
                { value: "grey_mold", label: "Grey Mold" },
                { value: "blue_mold", label: "Blue Mold" },
                { value: "alternaria_rot", label: "Alternaria Rot" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "frut_extdisorder_stone",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "External Disorders Detected",
        params: {
            options: [
                { value: "shrivel", label: "Shrivel" },
                { value: "poor_skin_colour", label: "Poor Skin Colouration" },
                { value: "bruising", label: "Bruising" },
                { value: "rain_marks", label: "Rain marks" },
                { value: "skin_cracking", label: "Skin Cracking" },
                { value: "visible_split_stone", label: "Visible Split Stone" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }

    },
    {
        id: "frut_crack_sens",
        type: CharacteristicType.Interval,
        label: "Cracking Sensitivity",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_bruise_symptom",
        type: CharacteristicType.Interval,
        label: "Bruising",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High",
            ]
        }
    },
    {
        id: "frut_shrivel_symptom",
        type: CharacteristicType.Interval,
        label: "Shrivelling Prevalence",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe",
            ]
        }
    },
    {
        id: "frut_intdisorder_stone",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "Internal Disorders Detected",
        params: {
            options: [
                { value: "internal_sunburn", label: "Internal Sunburn" },
                { value: "external_sunburn", label: "External Sunburn" },
                { value: "heat_damage", label: "Heat damage" },
                { value: "aerated_tissue", label: "Aerated tissue" },
                { value: "gel_breakdown", label: "Gel breakdown" },
                { value: "internal_breakdown", label: "Internal Breakdown" },
                { value: "flesh_breakdown", label: "Flesh Breakdown" },
                { value: "woolliness", label: "Woolliness" },
                { value: "pulpiness", label: "Pulpiness" },
                { value: "rubbery_texture", label: "Rubbery texture" },
                { value: "internal_browning", label: "Internal Browning" },
                { value: "split_pit", label: "Split Pit" },
                { value: "broken_stone", label: "Broken Stone" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }

    },
    {
        id: "frut_woolli_symptom",
        type: CharacteristicType.Interval,
        label: "Woolliness",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_pulp_symptom",
        type: CharacteristicType.Interval,
        label: "Pulpiness",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_sponge_symptom",
        type: CharacteristicType.Interval,
        label: "Sponginess",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_rubber_symptom",
        type: CharacteristicType.Interval,
        label: "Rubberiness",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_gelbreak_symptom",
        type: CharacteristicType.Interval,
        label: "Gel Breakdown",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_intbrown_symptom",
        type: CharacteristicType.Interval,
        label: "Internal Browning",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_intbreak_symptom",
        type: CharacteristicType.Interval,
        label: "Internal Breakdown",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "frut_shrivelling_severity",
        type: CharacteristicType.Interval,
        label: "Shrivelling Severity",
        categoryId: "frut_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "frut_extdisorder_pome",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "External Disorders Detected",
        params: {
            options: [
                { value: "bruising", label: "Bruising" },
                { value: "co2_injury", label: "CO2 Injury" },
                { value: "chemical_injury", label: "Chemical Injury" },
                { value: "friction_rub_marks", label: "Friction Marking / Rub Marks" },
                { value: "heat_injury", label: "Heat Injury" },
                { value: "jonathan_spot", label: "Jonathan Spot" },
                { value: "lenticel_breakdown", label: "Lenticel Breakdown" },
                { value: "lenticel_blotch", label: "Lenticel Blotch" },
                { value: "blister_pit", label: "Blister Pit" },
                { value: "low_oxygen_injury", label: "Low Oxygen Injury" },
                { value: "russeting", label: "Russeting" },
                { value: "retiform_russeting", label: "Retiform Russeting" },
                { value: "shrivel", label: "Shrivel" },
                { value: "scald", label: "Scald" },
                { value: "soft_scald", label: "Soft Scald" },
                { value: "sun_scald", label: "Sun Scald" },
                { value: "superficial_scald", label: "Superficial Scald" },
                { value: "skin_cracking", label: "Skin cracking" },
                { value: "senescent_scald", label: "Senescent Scald" },
                { value: "blemishes", label: "Blemishes" },
                { value: "fruit_scab", label: "Fruit Scab" },
                { value: "grease", label: "Greasiness" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "frut_intdisorder_pome",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "Internal Disorders Detected",
        params: {
            options: [
                { value: "brown_core", label: "Brown Core" },
                { value: "cavities", label: "Cavities" },
                { value: "core_flush", label: "Core flush" },
                { value: "internal_breakdown", label: "Internal Breakdown" },
                { value: "internal_browning", label: "Internal Browning" },
                { value: "internal_co2_injury", label: "Internal CO2 Injury" },
                { value: "low_temperature_breakdown", label: "Low temperature breakdown" },
                { value: "senescent_breakdown", label: "Senescent breakdown" },
                { value: "vascular_breakdown", label: "Vascular breakdown " },
                { value: "water_core", label: "Water Core" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "frut_disorder_pome",
        type: CharacteristicType.Nominal,
        categoryId: "frut_disease_disorder",
        label: "Internal & External Disorders Detected",
        params: {
            options: [
                { value: "bitter_pit", label: "Bitter Pit" },
                { value: "freezing_injury", label: "Freezing injury" },
                { value: "stem_cavity_browning", label: "Stem cavity browning" },
                { value: "premature_ripening", label: "Premature ripening" },
                { value: "co2_injury", label: "CO2 injury" },
                { value: "core_breakdown", label: "Core Breakdown" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },

    {
        id: "frut_overall_keepqual",
        type: CharacteristicType.Interval,
        label: "Keeping Quality",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_overall_appear",
        type: CharacteristicType.Interval,
        label: "Appearance",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_overall_color",
        type: CharacteristicType.Interval,
        label: "Colour Potential",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_overall_texture",
        type: CharacteristicType.Interval,
        label: "Texture",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_overall_taste",
        type: CharacteristicType.Interval,
        label: "Taste & Aroma",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_overall_eatqual",
        type: CharacteristicType.Interval,
        label: "Eating Quality",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_overall_interest",
        type: CharacteristicType.Interval,
        label: "Interest",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "frut_greasiness",
        type: CharacteristicType.Interval,
        label: "Greasiness",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Dry",
                "Dry",
                "Intermediate",
                "Greasy",
                "Very Greasy"
            ]
        }
    },
    {
        id: "frut_astringency",
        type: CharacteristicType.Interval,
        label: "Astringency",
        categoryId: "frut_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Mild",
                "Strong",
                "Intense",
                "Overwhelming",
            ]
        }
    },

];