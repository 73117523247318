import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { AuthState } from "@app/auth";
import { LIBRARY } from "@app/evaluation/library";
import { HostNavigator } from "@core/browser";
import { FilterSort, Organization, Status } from "@core/data";
import { BUSINESSAREAS, TastingContact } from "@core/data/types/tastings-contact";
import { Dialog, Snackbar } from "@core/material";
import { coerseDateProperty } from "@core/utils";
import { Library } from "@library";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { TastingContactFormDialog, TastingContactFormDialogData } from "../tasting-contact-forms/tasting-contact-form.dialog";
import { TastingContactIndexState } from "./tasting-contact-index.state";
import { ClearTastingContactIndexFilter, DeleteTastingContact, ExportTastingContactIndex, InitTastingContactIndex, LoadTastingContactIndex, PageTastingContactIndex, QueryTastingContactIndex, SetTastingContactIndexSelected, SortTastingContactIndex } from "./tasting-contact-index.state-actions";
import { TastingContactIndexQuery, TastingContactIndexStateModel } from "./tasting-contact-index.state-model";
import { COUNTRIES } from '@core/utils';
import { TastingContactInviteFormDialog, TastingContactInviteFormDialogData } from "../tasting-contact-forms/tasting-contact-invite-form.dialog";

@Component({
    selector: 'pv-tasting-contact-index-view',
    templateUrl: 'tasting-contact-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tasting-contact-index-view'
    }
})
export class TastingContactIndexView implements OnInit, OnDestroy {
    @Select(AuthState.selectedOrg)
    selectedOrg$: Observable<Organization>;

    @Select(TastingContactIndexState)
    state$: Observable<TastingContactIndexStateModel>;

    @Select(TastingContactIndexState.sort)
    sort$: Observable<FilterSort>

    @Select(TastingContactIndexState.query)
    query$: Observable<TastingContactIndexQuery>

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    // export form group
    exportFormGroup = new FormGroup({
        type: new FormControl('xlsx'),
    });

    subscriptionFilters: { value: boolean, label: string }[] = [
        { label: 'Subscribed', value: true },
        { label: 'Not Subscribed', value: false },
        { label: 'None', value: null },
    ]

    searchControl = new FormControl();
    subscribedControl = new FormControl(null);
    dobFromControl = new FormControl(null);
    dobToControl = new FormControl(null);
    countryControl = new FormControl(null);
    businessAreasControl = new FormControl(null);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        subscribed: this.subscribedControl,
        dobFrom: this.dobFromControl,
        dobTo: this.dobToControl,
        country: this.countryControl,
        businessAreas: this.businessAreasControl,
    });

    selection = new SelectionModel<string>(true, []);

    activeFilterCount: number = 0;
    showFilter: boolean = false;
    countries: {code: string, label: string}[] = [];
    businessAreas: {id: string, label: string}[] = [];

    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        private _navigator: HostNavigator,
        @Inject(LIBRARY) private _library: Library,
    ) {}

    ngOnInit(): void {
        this.countries = COUNTRIES;
        this.businessAreas = BUSINESSAREAS;

        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(new InitTastingContactIndex(params.get('orgKey')));
            });

        this.state$.subscribe(data => {
            if (data) {
                this.resolveFilterBadge(data.query);
            }
        });

        //QUERY LOGIC
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                let data: TastingContactIndexQuery = {
                    ...query,
                    dobTo: query.dobTo ? coerseDateProperty(query.dobTo) : null,
                    dobFrom: query.dobFrom ? coerseDateProperty(query.dobFrom) : null
                }

                this.queryFormGroup.patchValue(data, {emitEvent: false})
            });

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                let data: TastingContactIndexQuery = {
                    ...val,
                    dobTo: val.dobTo ? coerseDateProperty(val.dobTo) : null,
                    dobFrom: val.dobFrom ? coerseDateProperty(val.dobFrom) : null
                }

                this._store.dispatch(new QueryTastingContactIndex(data));
            });
        //QUERY LOGIC END

        //SORT LOGIC
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingContactIndex(val.column, val.order));
            });
        //SORT LOGIC END

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetTastingContactIndexSelected(this.selection.selected));
            });
    }

    private resolveFilterBadge(formData) {
        this.activeFilterCount = 0;
        Object.keys(formData).forEach(key => {
            if (Array.isArray(formData[key]) && formData[key].length > 0) {
                this.activeFilterCount++;
            } else if ((!Array.isArray(formData[key])) && (formData[key] != null && formData[key].length > 0)) {
                this.activeFilterCount++;
            } else if ((!Array.isArray(formData[key])) && (formData[key] != null)) {
                this.activeFilterCount++;
            }
        });
    }

    reload() {
        this._store.dispatch(new LoadTastingContactIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingContactIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingContactIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingContactIndex('updatedAt', 'desc'));
        }
    }

    export() {
        let exportOptions = this.exportFormGroup.value;

        this.exportFormGroup.disable();

        this._store.dispatch(new ExportTastingContactIndex())
            .subscribe(
                global => {
                    let exp = this._store.selectSnapshot(TastingContactIndexState.latestExport);

                    if (exp && exp.status === Status.COMPLETE) {
                        this._snackbar.info(
                            `Export is queued. The result will be emailed to you once the export is complete.`,
                        );
                    }
                },
                e => {
                    this.exportFormGroup.enable();
                },
                () => {
                    this.exportFormGroup.enable();
                }
            );
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingContactIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
     isAllSelected(data: TastingContact[]) {
        return data.every((sample) => {
            return this.selection.isSelected(sample.key);
        });
    }

    isSelected(event: TastingContact) {
        return this.selection.isSelected(event.key)
    }

    select(event: TastingContact) {
        if (this.selection.isSelected(event.key)) this.selection.deselect(event.key);
        else this.selection.select(event.key);
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: TastingContact[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    //CRUD functions
    add() {
        const state: TastingContactIndexStateModel = this._store.selectSnapshot(TastingContactIndexState);

        const data: TastingContactFormDialogData = {
            defaults: {
                ownerOrgKey: state.orgKey
            }
        }

        this._dialog.open(TastingContactFormDialog, { data });
    }

    edit(tastingContact: TastingContact) {
        const data: TastingContactFormDialogData = { key: tastingContact.key };
        this._dialog.open(TastingContactFormDialog, { data });
    }

    delete(contact: TastingContact) {
        this._dialog.confirm(
            `Delete Tasting Contact ${contact.firstName + " " +  contact.lastName}`,
            'Are you sure you want to delete this contact and all their associated data?'
        )
            .afterClosed()
            .subscribe(result => {
                if (result) this._store.dispatch(new DeleteTastingContact(contact.key));
            });
    }

    deleteSelected(contacts: string[]) {
        var deleteList: DeleteTastingContact[] = [];
        contacts.forEach(key => deleteList.push(new DeleteTastingContact(key)));

        this._dialog.confirm(
            `Delete ${contacts.length} selected Tasting Contacts`,
            `Are you sure you want to delete these contacts and all their associated data?`
        ).afterClosed()
        .subscribe(result => {
            if (result) {
                this._store.dispatch(deleteList).subscribe(complete => {
                    this.selection.clear();
                });
            }
        });
    }

    inviteToEvent(contacts: TastingContact[]) {
        const state: TastingContactIndexStateModel = this._store.selectSnapshot(TastingContactIndexState);
        const data: TastingContactInviteFormDialogData = {contacts: contacts, orgKey: state.orgKey};
        this._dialog.openFullscreen(TastingContactInviteFormDialog, { data }).afterClosed()
            .subscribe(result => {
                this.selection.clear();
            });
    }

    navigateToSupport() {
        window.open('https://culteva.hipporello.net/desk', '_blank');
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}