import { AbstractControl, ValidationErrors } from "@angular/forms";
import { isNumberRange, isNumberSet, isNumberValue } from '../number';


export class NumberValidators {

    static integer(maxDigits: number = 22){
        return (control: AbstractControl): ValidationErrors | null => {

            let value: any = control.value;

            // ignore empty values
            if(value === null || value === "") return null;

            if(isNumberValue(value)){
                let parts = String(value).split('.');
                if(parts[0].length > maxDigits) return {'integer': true};
                if(parts.length > 1) return {'integer': true};
                return null;
            }

            return {'integer': true};
        };
    }

    static decimal(maxDigits: number = 22, maxDecimals: number = 22){

        return (control: AbstractControl): ValidationErrors | null => {

            let value: any = control.value;

            // ignore empty values
            if(value === null || value === "") return null;

            if(isNumberValue(value)){
                let parts = String(value).split('.');
                if(parts[0].length > maxDigits) return {'float': true};
                if(parts.length > 1 && (parts[1].length === 0 || parts[1].length > maxDecimals)) return {'float': true};
                return null;
            }

            return {'float': true};
        };
    }

    static rangeOrSet(){
        return (control: AbstractControl): ValidationErrors | null => {

            let value: any = control.value;

            // ignore empty values
            if(value === null || value === "") return null;

            if(isNumberRange(value)) return null;
            if(isNumberSet(value)) return null;

            return {'rangeorset': true};
        };
    }

}