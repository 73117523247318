import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { AuthState } from "@app/auth";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { FilterSort, Organization, WeightedChar } from "@core/data";
import { TastingCustomWeightedCharIndexState } from "./tasting-custom-weighted-chars-index.state";
import { TastingCustomWeightedCharIndexStateModel, TastingCustomWeightedCharQuery } from "./tasting-custom-weighted-chars-index.state-model";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute } from "@angular/router";
import { Dialog, Snackbar } from "@core/material";
import { FormControl, FormGroup } from "@angular/forms";
import { ClearTastingCustomWeightedCharIndexFilter, DeleteTastingCustomWeightedChar, InitTastingCustomWeightedCharIndex, LoadTastingCustomWeightedCharIndex, PageTastingCustomWeightedCharIndex, QueryTastingCustomWeightedCharIndex, SetTastingCustomWeightedCharIndexSelected, SortTastingCustomWeightedCharIndex } from "./tasting-custom-weighted-chars-index.state-actions";
import { debounceTime, takeUntil } from "rxjs/operators";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { TastingCustomWeightedCharFormDialog, TastingCustomWeightedCharFormDialogData } from "../tasting-custom-weighted-chars-form/tasting-custom-weighted-chars-form.dialog";

@Component({
    selector: 'pv-tasting-custom-weighted-char-index-view',
    templateUrl: 'tasting-custom-weighted-chars-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tasting-custom-weighted-chars-index-view',
    }
})
export class TastingCustomWeightedCharsIndexView implements OnInit, OnDestroy {

    @Select(AuthState.selectedOrg)
    selectedOrg$: Observable<Organization>;

    @Select(TastingCustomWeightedCharIndexState)
    state$: Observable<TastingCustomWeightedCharIndexStateModel>;

    @Select(TastingCustomWeightedCharIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(TastingCustomWeightedCharIndexState.query)
    query$: Observable<TastingCustomWeightedCharQuery>;

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl
    });

    searchControl = new FormControl();
    tagKeysControl = new FormControl([]);
    indexKeysControl = new FormControl([]);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        tagKeys: this.tagKeysControl,
        indexKeys: this.indexKeysControl,
    });

    selection = new SelectionModel<string>(true, []);
    selectedTabIndex = 0;
    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: Dialog
    ) {}

    ngOnInit(): void {
        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(new InitTastingCustomWeightedCharIndex(params.get('orgKey')));
            });

        //QUERY LOGIC
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                this.queryFormGroup.patchValue(query, {emitEvent: false})
            });

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new QueryTastingCustomWeightedCharIndex(val));
            });
        //QUERY LOGIC END

        //SORT LOGIC
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingCustomWeightedCharIndex(val.column, val.order));
            });
        //SORT LOGIC END

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetTastingCustomWeightedCharIndexSelected(this.selection.selected));
            });
    }

    reload() {
        this._store.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingCustomWeightedCharIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingCustomWeightedCharIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingCustomWeightedCharIndex('updatedAt', 'desc'));
        }
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingCustomWeightedCharIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
     isAllSelected(data: WeightedChar[]) {
        return data.every((char) => {
            return this.selection.isSelected(char.key);
        });
    }

    isSelected(char: WeightedChar) {
        return this.selection.isSelected(char.key)
    }

    select(char: WeightedChar) {
        if (this.selection.isSelected(char.key)) this.selection.deselect(char.key);
        else this.selection.select(char.key);
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: WeightedChar[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    add() {
        const state: TastingCustomWeightedCharIndexStateModel = this._store.selectSnapshot(TastingCustomWeightedCharIndexState);

        const data: TastingCustomWeightedCharFormDialogData = {
            defaults: {
                ownerOrgKey: state.orgKey
            },
            tagOptions: state.tagOptions,
        }

        this._dialog.open(TastingCustomWeightedCharFormDialog, {data});
    }

    edit(char: WeightedChar) {
        const state: TastingCustomWeightedCharIndexStateModel = this._store.selectSnapshot(TastingCustomWeightedCharIndexState);
        const data: TastingCustomWeightedCharFormDialogData = {
            key:  char.key,
            tagOptions: state.tagOptions,
        };

        this._dialog.open(TastingCustomWeightedCharFormDialog, {data});
    }

    delete(char: WeightedChar) {
        if (char.indexes.length > 0 || char.tags.length > 0) {
            this._snackbar.error('Remove Index & Tag connections or refresh data before Characteristic can be deleted.');
            return;
        }

        this._dialog.confirm(
            `Delete Custom Weighted Characteristic ${char.label}`,
            'Are you sure you want to delete this characteristic and all its associated data?'
        )
            .afterClosed()
            .subscribe(result => {
                if (result) this._store.dispatch(new DeleteTastingCustomWeightedChar(char.key));
            });
    }

    deleteSelected(chars: WeightedChar[]) {
        if (!this.canDelete(chars)) {
            this._snackbar.error('Remove Index & Tag connections or refresh data before Characteristic can be deleted.');
            return;
        }

        var deleteList: DeleteTastingCustomWeightedChar[] = [];
        chars.forEach(char => deleteList.push(new DeleteTastingCustomWeightedChar(char.key)));

        this._dialog.confirm(
            `Delete ${chars.length} selected Custom Weighted Characteristics`,
            `Are you sure you want to delete these characteristics and all their associated data?`
        ).afterClosed()
        .subscribe(result => {
            if (result) {
                this._store.dispatch(deleteList).subscribe(complete => {
                    this.selection.clear();
                });
            }
        });
    }

    navigateToSupport() {
        window.open('https://culteva.hipporello.net/desk', '_blank');
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private canDelete(chars: WeightedChar[]): boolean {
        let hasConnections = false;

        chars.forEach(char => {
            if (char.indexes.length > 0 || char.tags.length > 0) hasConnections = true;
        });

        return !hasConnections;
    }
}