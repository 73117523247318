<ng-container *ngIf="state$ | async as state">

    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Update Profile</h2>
    </div>

    <div mat-dialog-content>

        <form #authProfileForm="ngForm" [formGroup]="formGroup" class="pv-form" novalidate (ngSubmit)="attempt()">
            <div class="form-column">

                <!-- Name -->
                <mat-form-field appearance="standard">
                    <mat-label>Name</mat-label>
                    <input matInput type="text" formControlName="name" autocomplete="off">
                    <mat-hint>Full name and surname</mat-hint>
                    <mat-error>Name is required, character limit 64</mat-error>
                </mat-form-field>

                <!-- Email -->
                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email">
                    <mat-hint>Contact support to update email.</mat-hint>
                </mat-form-field>

                <!-- Country -->
                <mat-form-field appearance="standard">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="addrCountry">
                        <mat-option *ngFor="let country of countries" [value]="country.code">{{ country.label }}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Country of residence</mat-hint>
                    <mat-error>Country is required</mat-error>
                </mat-form-field>

                <mat-slide-toggle formControlName="_updatePassword">Update Password?</mat-slide-toggle>

            </div>

            <div class="form-column" [class.hide]="!updatePasswordControl.value">
                <!-- Current Password -->
                <mat-form-field appearance="standard">
                    <mat-label>Current Password</mat-label>
                    <input matInput type="password" formControlName="_currentPassword" autocomplete="off">
                    <mat-hint>Your current password</mat-hint>
                    <mat-error>Current password invalid</mat-error>
                </mat-form-field>

                <!-- New Password -->
                <mat-form-field appearance="standard">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" formControlName="_password" autocomplete="off">
                    <mat-hint>Your new password</mat-hint>
                    <mat-error>Minimum of 8 characters</mat-error>
                </mat-form-field>

                <!-- Confirm Password -->
                <mat-form-field appearance="standard">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" formControlName="_passwordConfirmation" autocomplete="off">
                    <mat-hint>Confirm your new password</mat-hint>
                    <mat-error>Passwords do not match</mat-error>
                </mat-form-field>
            </div>

        </form>

    </div>

    <pv-form-actions mat-dialog-actions [form]="authProfileForm" (cancel)="cancel()" [status]="state.status">
    </pv-form-actions>

</ng-container>