<ng-container *ngIf="state$ | async as state">
    <h2 mat-dialog-title>Evaluation End Date</h2>
    <div mat-dialog-content>

        <form #evalCompleteForm="ngForm" (ngSubmit)="attempt()" class="pv-form single-column">
            <mat-form-field appearance="standard">
                <mat-label>Evaluation End Date</mat-label>
                <input matInput [min]="minDate"
                       [formControl]="endDateControl"
                       [matDatepicker]="endDatePicker"
                       placeholder="YYYY-MM-DD">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-hint>Select an end date</mat-hint>
                <mat-error>End date required</mat-error>
            </mat-form-field>

            <p class="form-caption" *ngIf="(endDateControl.value | amDifference:startDate:'days') as diff">
                Duration: {{ diff }} day(s)
            </p>

        </form>


    </div>
    <pv-form-actions mat-dialog-actions
                     [form]="evalCompleteForm"
                     [status]="state.status"
                     (cancel)="cancel()">
    </pv-form-actions>
</ng-container>
