import { Menu, MenuGroup, MenuItem } from "./_types";

//All Culteva Menu items (default state)
export const CULTEVA_MENU_ITEMS: MenuItem[] = [
    {
        id: 'super_admin_org',
        label: 'Organizations',
        iconLib: 'material',
        iconRef: 'account_balance',
        routePath: ['/admin', 'orgs'],
        externalPath: false
    },
    {
        id: 'super_admin_users',
        label: 'Users',
        iconLib: 'material',
        iconRef: 'supervised_user_circle',
        routePath: ['/admin', 'users'],
        externalPath: false
    },
    {
        id: 'home',
        label: 'Home',
        iconLib: 'material',
        iconRef: 'home',
        routePath: ['/'],
        externalPath: false
    },
    {
        id: 'cultivars',
        label: 'Cultivars',
        iconLib: 'common',
        iconRef: 'flat_scion',
        routePath: ['/org', ':orgKey', 'cultivars'],
        externalPath: false
    },
    {
        id: 'protocols',
        label: 'Protocols',
        iconLib: 'material',
        iconRef: 'ballot',
        routePath: ['/org', ':orgKey', 'protocols'],
        externalPath: false
    },
    {
        id: 'site_map',
        label: 'Site Map',
        iconLib: 'material',
        iconRef: 'map',
        routePath: ['/org', ':orgKey', 'sites'],
        externalPath: false
    },
    {
        id: 'weather',
        label: 'Weather',
        iconLib: 'material',
        iconRef: 'thermostat',
        routePath: ['/org', ':orgKey', 'weather-stations'],
        externalPath: false
    },
    {
        id: 'plant_samples',
        label: 'Plant Samples',
        iconLib: 'common',
        iconRef: 'plant_sample',
        routePath: ['/org', ':orgKey', 'samples', 'plant'],
        externalPath: false
    },
    {
        id: 'harvest_samples',
        label: 'Harvest Samples',
        iconLib: 'common',
        iconRef: 'harvest_sample',
        routePath: ['/org', ':orgKey', 'samples', 'harvest'],
        externalPath: false
    },
    {
        id: 'reports',
        label: 'Reports',
        iconLib: 'material',
        iconRef: 'insert_chart',
        routePath: ['/org', ':orgKey', 'reports'],
        externalPath: false
    },
    {
        id: 'groups',
        label: 'Groups',
        iconLib: 'material',
        iconRef: 'groups',
        routePath: ['/org', ':orgKey', 'groups'],
        externalPath: false
    },
    {
        id: 'org_admin',
        label: 'Organization',
        iconLib: 'material',
        iconRef: 'account_balance',
        routePath: ['/org', ':orgKey', 'detail'],
        externalPath: false
    },
    {
        id: 'support',
        label: 'Support Page',
        iconLib: 'material',
        iconRef: 'help_center',
        routePath: ['support'],
        externalPath: true
    },
    {
        id: 'user_manual',
        label: 'User Manual',
        iconLib: 'material',
        iconRef: 'lightbulb_outline',
        routePath: ['support/user-manual'],
        externalPath: true
    },
    {
        id: 'contact_support',
        label: 'Contact Support',
        iconLib: 'material',
        iconRef: 'contact_support',
        routePath: ['support#section_contact_form'],
        externalPath: true
    },
    {
        id: 'tastings_events',
        label: 'Events',
        iconLib: 'common',
        iconRef: 'tastings_events',
        routePath: ['/org', ':orgKey', 'tastings-events'],
        externalPath: false
    },
    {
        id: 'tastings_samples',
        label: 'Samples',
        iconLib: 'common',
        iconRef: 'tastings_sample',
        routePath: ['/org', ':orgKey', 'tastings-samples'],
        externalPath: false
    },
    {
        id: 'tastings_contact',
        label: 'Contacts',
        iconLib: 'material',
        iconRef: 'contacts',
        routePath: ['/org', ':orgKey', 'tastings-contacts'],
        externalPath: false
    },
    {
        id: 'tastings_indexes',
        label: 'Indexes',
        iconLib: 'material',
        iconRef: 'calculate',
        routePath: ['/org', ':orgKey', 'tastings-indexes'],
        externalPath: false
    },
    {
        id: 'tastings_tags',
        label: 'Tags',
        iconLib: 'material',
        iconRef: 'label',
        routePath: ['/org', ':orgKey', 'tastings-tags'],
        externalPath: false
    },
    {
        id: 'tastings_weighted_chars',
        label: 'Weighted Characteristics',
        iconLib: 'material',
        iconRef: 'analytics',
        routePath: ['/org', ':orgKey', 'tastings-weighted-chars'],
        externalPath: false
    },
];

//All Culteva Menu groups (default state)
export const CULTEVA_MENU_GROUPS: MenuGroup[] = [

    //Super-Admin Group
    {
        id: 'super_admin',
        label: 'Super-Admin Dashboard',
        iconLib: 'material',
        iconRef: 'all_inclusive',

        menuItems: [
            'super_admin_org',
            'super_admin_users',
        ],

        subscribed: true,

    },

    //Admin Group
    {
        id: 'admin',
        label: 'Admin',
        iconLib: 'material',
        iconRef: 'all_inclusive',

        menuItems: [
            'org_admin',
        ],

        subscribed: true,

    },

    //Core Group
    {
        id: 'core',
        label: 'Culteva Core',
        iconLib: 'common',
        iconRef: 'flat_scion',

        menuItems: [
            'home',
            'cultivars',
            'reports',
            'site_map',
        ],

        subscribed: true,
    },

    //Evaluation Group
    {
        id: 'evaluation',
        label: 'Evaluation',
        iconLib: 'common',
        iconRef: 'eval_module',

        menuItems: [
            'plant_samples',
            'harvest_samples',
        ],

        module: 'evaluation_module',
        landingPagePath: ['modules/evaluation'],
        subscribed: false
    },

    //Tastings Group
    {
        id: 'tastings',
        label: 'Tastings',
        iconLib: 'common',
        iconRef: 'tastings_module',

        menuItems: [
            'tastings_samples',
            'tastings_events',
            'tastings_contact',
            'tastings_indexes',
            'tastings_tags',
            'tastings_weighted_chars',
        ],

        module: 'tastings_module',
        landingPagePath: ['modules/tasting'],
        subscribed: false
    },

    //Breeding Group
    // {
    //     id: 'breeding',
    //     label: 'Breeding',
    //     iconLib: 'common',
    //     iconRef: 'breeding_module',

    //     menuItems: [
    //         'progeny_samples',
    //     ],

    //     module: 'breeding_module',
    //     landingPagePath: ['support/breeding-module'],
    //     subscribed: false
    // },

    //Utilities Group
    {
        id: 'utils',
        label: 'Utilities',
        iconLib: 'material',
        iconRef: 'handyman',

        menuItems: [
            'protocols',
            'weather',
            'groups',
        ],

        subscribed: true,

    },

    //Support Group
    {
        id: 'support',
        label: 'Support',
        iconLib: 'material',
        iconRef: 'help',

        menuItems: [
            'support',
            'user_manual',
            'contact_support',
        ],

        subscribed: true,
    },
];

//DEFAULT Culteva Menu
export const CULTEVA_MENU_DEFAULT: Menu = {

    //Menu groups with associated menu items
    startMenuGroupIds: [
        'super_admin',
        'admin',
        'core',
    ],

    //Menu groups with associated menu items
    endMenuGroupIds: [
        'utils',
        'support'
    ]
};