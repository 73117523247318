import { Pipe, PipeTransform } from "@angular/core";
import { CharacteristicScore } from "@core/data/types/tastings-evaluation";

@Pipe({
    name: 'descAvgScore'
})
export class UserScorePipe implements PipeTransform {
    constructor() {}

    transform(scores: CharacteristicScore[]): string {
        if (!scores) return 'No Score';
        let scoreValues = scores.map(score => +score.value);
        let reduceSize = 0;

        scoreValues.forEach((score, index) => {
            if (score === -1) {
                scoreValues[index] = 0;
                if (scores.length > 1) reduceSize++;
            }
        });

        if (reduceSize === scores.length) reduceSize = 0;
        let scoreAvg = scoreValues.reduce((acc, curr) => acc += curr) / (scores.length - reduceSize);

        return scoreAvg.toFixed(2).toString()
    }
}