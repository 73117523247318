import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Snackbar } from '@core/material';
import { SampleType, OrganizationGroup } from '@core/data';

export interface OrganizationGroupFormDialogData {
    ownerOrgKey: string;
    group?: Partial<OrganizationGroup>;
    fixedType?: Object;
}

@Component({
    selector: 'pv-organization-group-form-dialog',
    templateUrl: 'organization-group-form.dialog.html',
})
export class OrganizationGroupFormDialog {

    typeOptions = Object.keys(SampleType).map(key => {
        return { value: SampleType[key], name: key[0] + SampleType[key].slice(1) }
    });

    editMode: Boolean = false;
    formGroup = new FormGroup({
        ownerOrgKey: new FormControl(null, [Validators.required]),
        key: new FormControl(null),
        label: new FormControl(null, [Validators.required]),
        description: new FormControl(null),
        type: new FormControl(null, [Validators.required]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrganizationGroupFormDialogData,
        private _dialog: MatDialogRef<OrganizationGroupFormDialog>,
        private _snackbar: Snackbar,
    ) {}

    ngOnInit() {
        this.formGroup.reset({
            ...this.data.group,
            ownerOrgKey: this.data.ownerOrgKey,
        });
        if (this.data.group && this.data.group.key) {
            this.editMode = true;
            this.formGroup.controls.type.disable();
        }
    }

    attempt() {
        if (this.formGroup.valid) this.submit();
        else this._snackbar.error("Check your input and try again.");
    }

    submit() {
        this._dialog.close(this.formGroup.getRawValue());
    }

    cancel() {
        this._dialog.close();
    }
}
