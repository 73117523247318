import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_BASE_URI } from '../http/api';
import { Collection } from '../http/collection';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { Filter, FilterBuilder } from '../http/filter';
import { Organization, OrganizationNote, OrganizationStats, OrganizationSubscription } from '../types/organization';
import { OrganizationInvite, OrganizationUser } from '../types/organization-access';

export interface OrganizationFormRequest extends Partial<Organization> {
    _logoFile?: File;
    _seed_demo?: boolean;
}

export interface OrganizationInviteRequest {
    userName: string;
    userEmail: string;
    userRole: string;
}

export interface OrganizationImportResponse {
    result: string;
}

export interface OrganizationImportMapping {
    key: string;
    translation: string;
}

export class OrganizationSubscriptionCreated {
    static readonly type = "[OrganizationService] Subscription Created";
    constructor(public orgSubscription: OrganizationSubscription){}
}

export class OrganizationSubscriptionUpdated {
    static readonly type = "[OrganizationService] Subscription Updated";
    constructor(public orgSubscription: OrganizationSubscription){}
}
export class OrganizationSubscriptionDeleted {
    static readonly type = "[OrganizationService] Subscription Deleted";
    constructor(public orgSubscription: OrganizationSubscription){}
}

export class OrganizationDataImported {
    static readonly type = "[OrganizationService] Data Imported";
    constructor(public respnse: OrganizationImportResponse) {}
}

@Injectable()
export class OrganizationService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string,
    ) { }

    get(key: string) {
        return this._http.get<Organization>(`${this._baseUri}/organizations/${key}`);
    }

    query(filter: Filter, detail?: DetailRequest) {

        let params = {
            ...DetailRequestBuilder.queryParams(detail),
            ...FilterBuilder.queryParams(filter),
        };

        return this._http.get<Collection<Organization>>(`${this._baseUri}/organizations`, { params });
    }

    update(key: string, request: OrganizationFormRequest) {

        let formData = this.convertToFormData(request);

        formData.set('_method', 'PUT');

        return this._http.post<Organization>(`${this._baseUri}/organizations/${key}`, formData);
    }

    create(request: OrganizationFormRequest) {

        let formData = this.convertToFormData(request);

        return this._http.post<Organization>(`${this._baseUri}/organizations`, formData);
    }

    delete(orgKey: any) {
        return this._http.delete<Collection<Organization>>(`${this._baseUri}/organizations/${orgKey}`);
    }

    // - Stats
    stats(key: string) {
        return this._http.get<OrganizationStats>(`${this._baseUri}/organizations/${key}/stats`);
    }

    // - User Members / Access
    listMembers(orgKey: string) {
        return this._http.get<OrganizationUser[]>(`${this._baseUri}/organizations/${orgKey}/users`);
    }

    getMember(orgKey: string, userKey: string) {
        return this._http.get<OrganizationUser>(`${this._baseUri}/organizations/${orgKey}/users/${userKey}`);
    }

    addMember(orgKey: string, userKey: string, role: string) {
        return this._http.post<OrganizationUser>(`${this._baseUri}/organizations/${orgKey}/users/${userKey}`, {role});
    }

    updateMember(orgKey: string, userKey: string, role: string) {
        return this._http.put<OrganizationUser>(`${this._baseUri}/organizations/${orgKey}/users/${userKey}`, {role});
    }

    removeMember(orgKey: string, userKey: string) {
        return this._http.delete<{ key: string }>(`${this._baseUri}/organizations/${orgKey}/users/${userKey}`);
    }

    // - Invites
    listInvites(orgKey: string, withExpired = false) {
        let params = { withExpired: withExpired ? '1' : '0' };
        return this._http.get<OrganizationInvite[]>(`${this._baseUri}/organizations/${orgKey}/invites`, { params });
    }

    getInvite(orgKey: string, inviteId: number) {
        return this._http.get<OrganizationInvite>(`${this._baseUri}/organizations/${orgKey}/invites/${inviteId}`);
    }

    createInvite(orgKey: string, data: OrganizationInviteRequest) {
        return this._http.post<OrganizationInvite>(`${this._baseUri}/organizations/${orgKey}/invites`, data);
    }

    expireInvite(orgKey: string, inviteId: number) {
        return this._http.put<OrganizationInvite>(`${this._baseUri}/organizations/${orgKey}/invites/${inviteId}/expire`, {});
    }

    // - Notes
    listNotes(orgKey: string) {
        return this._http.get<Array<OrganizationNote>>(`${this._baseUri}/organizations/${orgKey}/notes`);
    }

    createNote(orgKey: string, data: string) {
        return this._http.post<OrganizationNote>(`${this._baseUri}/organizations/${orgKey}/notes`, data);
    }

    deleteNote(orgKey: string, noteKey: string) {
        return this._http.delete<OrganizationNote>(`${this._baseUri}/organizations/${orgKey}/notes/${noteKey}`);
    }

    // - Subscriptions
    listSubscriptions(orgKey: string) {
        return this._http.get<OrganizationSubscription[]>(`${this._baseUri}/organizations/${orgKey}/subscriptions`);
    }

    findSubscription(orgKey: string, subscriptionKey: string) {
        return this._http.get<OrganizationSubscription>(`${this._baseUri}/organizations/${orgKey}/subscriptions/${subscriptionKey}`);
    }

    createSubscription(orgKey: string, payload: Partial<OrganizationSubscription>) {
        return this._http.post<OrganizationSubscription>(`${this._baseUri}/organizations/${orgKey}/subscriptions`, payload)
                            .pipe(tap(subscription => this._store.dispatch(new OrganizationSubscriptionCreated(subscription))));
    }

    updateSubscription(orgKey: string, subscriptionKey: string, payload: Partial<OrganizationSubscription>){
        return this._http.put<OrganizationSubscription>(`${this._baseUri}/organizations/${orgKey}/subscriptions/${subscriptionKey}`, payload)
                            .pipe(tap(subscription => this._store.dispatch(new OrganizationSubscriptionUpdated(subscription))));
    }

    deleteSubscription(orgKey: string, subscriptionKey: string) {
        return this._http.delete<OrganizationSubscription>(`${this._baseUri}/organizations/${orgKey}/subscriptions/${subscriptionKey}`)
                        .pipe(tap(subscription => this._store.dispatch(new OrganizationSubscriptionDeleted(subscription))));
    }

    importData(orgKey: string, file: File, cropId: string, sampleType: string, protocolId: string, mappings?: OrganizationImportMapping[]): Observable<OrganizationImportResponse> {
        let data = new FormData();
        data.set('file', file);
        data.set('cropId', cropId);
        data.set('sampleType', sampleType);
        data.set('protocolId', protocolId);
        if (mappings) data.set('mappings', JSON.stringify(mappings));

        return this._http.post<OrganizationImportResponse>(`${this._baseUri}/organizations/${orgKey}/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new OrganizationDataImported(result));
            }));
    }

    private convertToFormData(value: any) {

        let formData = new FormData;

        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                if (Array.isArray(value[key]) && value[key].length > 0) {
                    formData.set(key, value[key][0]);
                } else if (value[key] !== null) {
                    formData.set(key, value[key]);
                }
            }
        }

        return formData;
    }
}
