import { Pipe, PipeTransform } from '@angular/core';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Organization } from '@core/data';

@Pipe({
	name: 'descAddress',
})
export class DescribeAddressPipe implements PipeTransform {

	constructor(private _sampleFormatter: SampleFormatter) { }

	transform(org: Organization) {
		return this._sampleFormatter.describeSiteAddress(org);
	}
}
