import { Organization } from "./organization";
import { User } from "./user";

export interface ExportHistory {
    // general info
    fileName: string;
    fileUrl: string;
    model: string;

    // foreign keys
    ownerOrgKey: string;
    userId: string;

    // related
    organization?: Organization;
    user?: User;

    // timestamps
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export enum ExportType {
    CULTIVARS = 'Cultivar',
    SITES = 'Site',
    SAMPLES = 'Sample',
    TASTING_EVENTS = 'TastingsEvent',
    TASTING_SAMPLES = 'TastingsSample',
}