import { Measurement } from './_types';

export const LIB_MEASURES_NUT_HARVEST: Measurement[] = [

    {
        id: "nut_husk_weight",
        unit: "g",
        label: "Husk Weight",
        tag: "HWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_husk_wei",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "husk",
    },
    {
        id: "nut_in_husk_weight",
        unit: "g",
        label: "Weight (NIH)",
        tag: "NHWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_husk_wei",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_husk",
    },
    {
        id: "nut_in_husk_height",
        unit: "mm",
        label: "Height (NIH)",
        tag: "NHHEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_husk_hei",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_husk",
    },
    {
        id: "nut_in_husk_diameter",
        unit: "mm",
        label: "Diameter (NIH)",
        tag: "NHDIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_husk_dia",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_husk",
    },
    {
        id: "nut_in_husk_pedi_len",
        unit: "mm",
        label: "Pedicel Length",
        tag: "NHPLNT",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_husk_pedi",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_husk",
    },
    {
        id: "nut_in_husk_ease_dehusk",
        unit: "%",
        label: "Ease of First Dehusking",
        tag: "NHED",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_nut_in_husk_ease_dehusk",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_in_husk",
    },
    {
        id: "nut_in_shell_weight",
        unit: "g",
        label: "Weight (NIS)",
        tag: "NSWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_shell_wei",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_in_shell_height",
        unit: "mm",
        label: "Height",
        tag: "NSHEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_shell_hei",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_in_shell_diameter",
        unit: "mm",
        label: "Diameter",
        tag: "NSDIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_shell_dia",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_in_shell_thickness",
        unit: "mm",
        label: "Shell Thickness",
        tag: "NSTHC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_shell_thickness",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_in_shell_breakage",
        unit: "%",
        label: "Shell Breakage",
        tag: "NSBR",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_nut_in_shell_breakage",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_in_shell_doubles",
        unit: "%",
        label: "Double Kernels",
        tag: "NSDBL",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_nut_in_shell_doubles",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_in_shell_weight_dry",
        unit: "g",
        label: "Dry in Shell Weight (DIS)",
        tag: "NSWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_in_shell_weight_dry",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "nut_in_shell",
    },
    {
        id: "nut_kernel_height",
        unit: "mm",
        label: "Kernel Height",
        tag: "KHEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_height",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_diameter",
        unit: "mm",
        label: "Kernel Diameter",
        tag: "KDIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_diameter",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_rancidity",
        unit: "mEq/kg",
        label: "Kernel Rancidity",
        tag: "KDIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_rancidity",
        format: "####mEq/kg",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_total_oil",
        unit: "%",
        label: "Total Oil Content",
        tag: "KTOIL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_total_oil",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_myristic",
        unit: "%",
        label: "Myristic Acid",
        tag: "MYFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_myristic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_palmitic",
        unit: "%",
        label: "Palmitic Acid",
        tag: "PFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket:"bucket_nut_fatty_palmitic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_palmitoleic",
        unit: "%",
        label: "Palmitoleic Acid",
        tag: "PAFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_palmitoleic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_stearic",
        unit: "%",
        label: "Stearic Acid",
        tag: "SFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_stearic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_cis_oleic",
        unit: "%",
        label: "Cis Oleic Acid",
        tag: "COFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_cis_oleic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_cis_linoleic",
        unit: "%",
        label: "Cis Linoleic Acid",
        tag: "CLFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_cis_linoleic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_n3_linoleic",
        unit: "%",
        label: "n3 Linoleic Acid",
        tag: "N3LFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_n3_linoleic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_arachidic",
        unit: "%",
        label: "Arachidic Acid",
        tag: "ARFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_arachidic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_eicosanoic",
        unit: "%",
        label: "Eicosanoic Acid",
        tag: "EFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_eicosanoic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_eicosadienoic",
        unit: "%",
        label: "Eicosadienoic Acid",
        tag: "ESFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_eicosadienoic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_behenic",
        unit: "%",
        label: "Behenic Acid",
        tag: "BEHFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_behenic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_erucic",
        unit: "%",
        label: "Erucic Acid",
        tag: "ERUFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket:  "bucket_nut_fatty_erucic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_lignoceric",
        unit: "%",
        label: "Lignoceric Acid",
        tag: "LIGFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_lignoceric",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_fatty_nervonic",
        unit: "%",
        label: "Nervonic Acid",
        tag: "NERFA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_fatty_nervonic",
        format:  "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_total_weight",
        unit: "g",
        label: "Total Kernel Weight",
        tag: "TKWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_total_weight",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_total_recovery_weight",
        unit: "g",
        label: "Total Whole Kernel Recovery Weight",
        tag: "TKRWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_total_recovery_weight",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_first_recovery_weight",
        unit: "g",
        label: "First Grade Recovery Weight",
        tag: "FRWEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_first_recovery_weight",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_recovery_perc",
        unit: "%",
        label: "Whole Kernel Recovery Percentage",
        tag: "WKRPER",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_recovery_perc",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_half_recovery_perc",
        unit: "%",
        label: "Half Kernel Recovery Percentage",
        tag: "HKRPER",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_total_recovery_weight",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_broken_perc",
        unit: "%",
        label: "Broken Kernels Percentage",
        tag: "BKPER",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_broken_perc",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel",
    },
    {
        id: "nut_kernel_mould_weight",
        unit: "g",
        label: "Mould",
        tag: "KMLD",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_mould",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_791_weight",
        unit: "g",
        label: "791",
        tag: "K791",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_791",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_pregerm_weight",
        unit: "g",
        label: "Pre - Germinatino",
        tag: "KPGRM",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_pregerm",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_immat_weight",
        unit: "g",
        label: "Immaturity / Shrivelling",
        tag: "KIMM",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_immat",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_misshap_weight",
        unit: "g",
        label: "Misshapenness",
        tag: "KMISS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_misshap",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_sponginess_weight",
        unit: "g",
        label: "Sponginess",
        tag: "KSGS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_sponginess",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_hollow_weight",
        unit: "g",
        label: "Hollow Centre",
        tag: "KSGS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_hollow",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_decom_weight",
        unit: "g",
        label: "Decomposition",
        tag: "KDCMP",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_decom",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_adhered_weight",
        unit: "g",
        label: "Adhered Skin",
        tag: "KADS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_adhered",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_insect_weight",
        unit: "g",
        label: "Insect Damage",
        tag: "KIND",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_insect",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_discolor_weight",
        unit: "g",
        label: "General Discoloration",
        tag: "KIND",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_discolor",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_dark_weight",
        unit: "g",
        label: "Dark Kernel",
        tag: "KDKL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_dark",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_internal_weight",
        unit: "g",
        label: "Internal Kernel Discoloration",
        tag: "KIKD",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_internal",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_onion_weight",
        unit: "g",
        label: "Basal Discoloration / Onion Ring",
        tag: "KOR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_onion",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_distal_weight",
        unit: "g",
        label: "Distal end Browning",
        tag: "KDEB",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_distal",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },
    {
        id: "nut_kernel_phys_weight",
        unit: "g",
        label: "Physiological Browning",
        tag: "KPB",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_nut_kernel_phys",
        format: "####g",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "kernel_disease",
    },

    //Calculated values
    {
        id: "nut_totalHuskToNISRatio_calc",
        unit: "%",
        label: "Ratio of Husk to Nut in Shell Weight",
        tag: "HTNISR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalHuskToNISRatio_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalHuskToNIHRatio_calc",
        unit: "%",
        label: "Ratio of Husk to Nut in Husk Weight",
        tag: "HTNIHR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalHuskToNIHRatio_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalTKR_calc",
        unit: "%",
        label: "Total Kernel Recovery (TKR)",
        tag: "TTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalTKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalUSRK_calc",
        unit: "%",
        label: "Total Unsound Kernel Recovery (USKR)",
        tag: "TUSRK",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalUSRK_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalUSRK2_calc",
        unit: "%",
        label: "Total Unsound Kernel Recovery 2 (USKR2)",
        tag: "TUSRK2",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalUSRK2_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalUWKR_calc",
        unit: "%",
        label: "Whole Kernel Recovery",
        tag: "TUWKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalUWKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalSKR_calc",
        unit: "%",
        label: "Sound Kernel Recovery",
        tag: "TSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalSKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalSKR2_calc",
        unit: "%",
        label: "Sound Kernel Recovery 2",
        tag: "TSKR2",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalSKR2_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_mould_weight_TKR_calc",
        unit: "%",
        label: "Mould (TKR)",
        tag: "MTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_mould_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_mould_weight_USKR_calc",
        unit: "%",
        label: "Mould (USKR)",
        tag: "MUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_mould_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_791_weight_TKR_calc",
        unit: "%",
        label: "791 Spot (TKR)",
        tag: "7TKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_791_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_791_weight_USKR_calc",
        unit: "%",
        label: "791 Spot (USKR)",
        tag: "7USKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_791_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_pregerm_weight_TKR_calc",
        unit: "%",
        label: "Pre - Germination (TKR)",
        tag: "PTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_pregerm_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_pregerm_weight_USKR_calc",
        unit: "%",
        label: "Pre - Germination (USKR)",
        tag: "PUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_pregerm_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_immat_weight_TKR_calc",
        unit: "%",
        label: "Immaturity / Shrivelling (TKR)",
        tag: "ISTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_immat_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_immat_weight_USKR_calc",
        unit: "%",
        label: "Immaturity / Shrivelling (USKR)",
        tag: "ISUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_immat_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_misshap_weight_TKR_calc",
        unit: "%",
        label: "Misshapenness (TKR)",
        tag: "MTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_misshap_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_misshap_weight_USKR_calc",
        unit: "%",
        label: "Misshapenness (USKR)",
        tag: "MUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_misshap_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_sponginess_weight_TKR_calc",
        unit: "%",
        label: "Sponginess (TKR)",
        tag: "STKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_misshap_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_sponginess_weight_USKR_calc",
        unit: "%",
        label: "Sponginess (USKR)",
        tag: "SUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_sponginess_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_hollow_weight_TKR_calc",
        unit: "%",
        label: "Hollow Centre (TKR)",
        tag: "HTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_hollow_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_hollow_weight_USKR_calc",
        unit: "%",
        label: "Hollow Centre (USKR)",
        tag: "HUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_hollow_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_decom_weight_TKR_calc",
        unit: "%",
        label: "Decomposition (TKR)",
        tag: "DTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_decom_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_decom_weight_USKR_calc",
        unit: "%",
        label: "Decomposition (USKR)",
        tag: "DUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_decom_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_adhered_weight_TKR_calc",
        unit: "%",
        label: "Adhered Skin (TKR)",
        tag: "ATKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_adhered_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_adhered_weight_USKR_calc",
        unit: "%",
        label: "Adhered Skin (USKR)",
        tag: "AUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_adhered_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_insect_weight_TKR_calc",
        unit: "%",
        label: "Insect Damage (TKR)",
        tag: "ITKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_insect_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_insect_weight_USKR_calc",
        unit: "%",
        label: "Insect Damage (USKR)",
        tag: "IUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_insect_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_discolor_weight_TKR_calc",
        unit: "%",
        label: "General Discoloration (TKR)",
        tag: "GTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_discolor_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_discolor_weight_USKR_calc",
        unit: "%",
        label: "General Discoloration (USKR)",
        tag: "TUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_discolor_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_dark_weight_TKR_calc",
        unit: "%",
        label: "Dark Kernel (TKR)",
        tag: "DTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_dark_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_dark_weight_USKR_calc",
        unit: "%",
        label: "Dark Kernel (USKR)",
        tag: "DUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_dark_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_internal_discolor_weight_TKR_calc",
        unit: "%",
        label: "Internal Kernel Discoloration (TKR)",
        tag: "IKTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_internal_discolor_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_internal_discolor_weight_USKR_calc",
        unit: "%",
        label: "Internal Kernel Discoloration (USKR)",
        tag: "IKUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_internal_discolor_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_basal_weight_TKR_calc",
        unit: "%",
        label: "Basal Discoloration (TKR)",
        tag: "BDTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_basal_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_basal_weight_USKR_calc",
        unit: "%",
        label: "Basal Discoloration (USKR)",
        tag: "BDUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_basal_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_distal_weight_TKR_calc",
        unit: "%",
        label: "Distal end Browning (TKR)",
        tag: "DETKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_distal_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_distal_weight_USKR_calc",
        unit: "%",
        label: "Distal end Browning (USKR)",
        tag: "DEUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_distal_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_physio_weight_TKR_calc",
        unit: "%",
        label: "Physiological Browning (TKR)",
        tag: "PBTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_physio_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_physio_weight_USKR_calc",
        unit: "%",
        label: "Physiological Browning (USKR)",
        tag: "PBUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_physio_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
];