<ng-container>
	<!-- Title -->
	<div mat-dialog-title class="mat-dialog-header">
		<h2 class="dialog-title">Add to organisation group</h2>
	</div>
	<div mat-dialog-content>
		<!-- Form -->
		<form #orgMemberForm="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()">
			<!-- Group -->

			<mat-form-field *ngIf="groups$ | async as groups; else groupLoader">
				<mat-label>Group</mat-label>
				<mat-select formControlName="groupKey">
					<mat-option *ngFor="let group of groups" [value]="group.key">
						<mat-icon svgIcon="common:{{ group.type }}_sample"></mat-icon>&nbsp;{{ group.label }}
					</mat-option>
				</mat-select>
				<mat-error>Select a group</mat-error>
			</mat-form-field>
			<ng-template #groupLoader>
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</ng-template>
		</form>
	</div>
	<!-- Actions -->
	<pv-form-actions mat-dialog-actions [form]="orgMemberForm" (cancel)="cancel()">
	</pv-form-actions>
</ng-container>
