<h1 mat-dialog-title>Configure Protocol</h1>
<div mat-dialog-content>
    <form class="pv-form" [formGroup]="formGroup">

        <div class="form-column">
            <mat-form-field appearance="standard">
                <mat-label>Protocol</mat-label>
                <input matInput type="text" required formControlName="title">
                <mat-error>Enter the protocol title</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Protocol Description</mat-label>
                <input matInput type="text" formControlName="description">
            </mat-form-field>

            <mat-checkbox formControlName="protocol" class="mat-caption"
                matTooltip="Can be used as a list of characteristics, measurements and indexes to be applied on a sample evaluation">
                Protocol
            </mat-checkbox>

            <mat-checkbox formControlName="filter" class="mat-caption"
                matTooltip="A protocol that can be used as a filter to focus on a sub set of characteristics, measurements and indexes during evaluation on the mobile application">
                Filter
            </mat-checkbox>

            <mat-checkbox formControlName="archived" class="mat-caption" *ngIf="this._data.data?.key"
                matTooltip="Archive this protocol, removing it as a selectable option for evaluations but remaining evailable to any evaluations it is linked to.">
                Archived
            </mat-checkbox>

            <br>
            <mat-icon style="padding-right: 8px;" svgIcon="common:plant_sample"></mat-icon>
            <mat-slide-toggle formControlName="protocolType" matTooltip="Choose whether the protocol is for plant or harvest samples"></mat-slide-toggle>
            <mat-icon style="padding-left: 8px;" svgIcon="common:harvest_sample"></mat-icon>
            <br><br>

            <mat-form-field appearance="standard">
                <mat-label>Crop</mat-label>
                <mat-select formControlName="cropId" required>
                    <mat-select-trigger>
                        <ng-container *ngIf="cropIdControl.value as cropId">
                            <mat-icon [svgIcon]="'common:' + cropId" class="mat-18 mr-12">
                            </mat-icon>
                            <span>{{ cropId | labelCrop }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let crop of cropOptions"
                                [value]="crop.id"
                                (onSelectionChange)="updateCropSelection($event, crop)">

                        <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                        <span>{{ crop.label }}</span>
                    </mat-option>
                </mat-select>
                <mat-error>Select a crop to continue</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always">
                <mat-label>Base Protocol</mat-label>
                <mat-select formControlName="baseProtocol" placeholder="None" (selectionChange)="updateBaseProtocol($event.value)">
                    <mat-option [value]="null">None</mat-option>
                    <mat-option *ngFor="let proto of filteredProtocols" [value]="proto">{{ proto.title }}</mat-option>
                </mat-select>
                <mat-error>Select a protocol as a template for your custom protocol</mat-error>
            </mat-form-field>

            <section>
                <h4>Selected Properties</h4>
                <p class="form-caption">
                    {{ currentSelection | descTableReport }}
                </p>
            </section>
        </div>

        <div class="form-column">

            <mat-form-field formGroupName="protocolSubset" appearance="standard" floatLabel="always">
                <mat-label>Characteristics</mat-label>
                <mat-select formControlName="characteristics" multiple placeholder="None" #multiSelect>
                <mat-select-trigger>
                    {{ characteristicsControl.value?.length + ' Selected' }}
                </mat-select-trigger>
                  <mat-option>
                    <ngx-mat-select-search [formControl]="charGroupsFilterCtrl"
                                           placeholderLabel="Find Characteristic..."
                                           noEntriesFoundLabel="No matching entry found"
                                           style="height: 556px !important; overflow-x: hidden;"
                                            >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-optgroup *ngFor="let group of filteredCharGroups | async; trackBy: trackById" [label]="group.category">
                    <mat-option *ngFor="let char of group.chars; trackBy: trackById"
                                (onSelectionChange)="updateSelection($event, tableReportColumnType.Characteristic)"
                                [value]="char.id" (click)="$event.stopPropagation()">
                      {{char.label}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field formGroupName="protocolSubset" appearance="standard" floatLabel="always">
                <mat-label>Measurements</mat-label>
                <mat-select formControlName="measurements" multiple placeholder="None" #multiSelect>
                    <mat-select-trigger>
                        {{ measurementsControl.value?.length + ' Selected' }}
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="measGroupsFilterCtrl"
                                               placeholderLabel="Find Measurement..."
                                               noEntriesFoundLabel="No matching entry found"
                                               style="height: 556px !important; overflow-x: hidden;"
                                               >
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-optgroup *ngFor="let group of filteredMeasGroups | async; trackBy: trackById" [label]="group.subject">
                        <mat-option *ngFor="let meas of group.measures; trackBy: trackById"
                                    (onSelectionChange)="updateSelection($event, tableReportColumnType.Measurement)"
                                    [value]="meas.id" (click)="$event.stopPropagation()">
                          {{meas.label }} [{{meas.unit}}]
                        </mat-option>
                      </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field formGroupName="protocolSubset" appearance="standard" floatLabel="always">
                <mat-label>Indexes</mat-label>
                <mat-select formControlName="indexes" multiple placeholder="None">
                    <mat-select-trigger>
                        {{ indexesControl.value?.length + ' Selected' }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let index of indexOptions; trackBy: trackById"
                                [value]="index.id"
                                (onSelectionChange)="updateSelection($event, tableReportColumnType.Index)">
                        {{ index.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="attempt()">Save</button>
</mat-dialog-actions>
