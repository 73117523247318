import { Pipe, PipeTransform } from '@angular/core';
import { SampleNote } from '@core/data';

@Pipe({
    name: 'extractHarvestWindow'
})
export class ExtractHarvestWindow implements PipeTransform {

    constructor(){}

    transform(char: String): any {
        let window: string;

        switch (char) {
            case 'E':
                window = "Early";
                break;
            case 'M':
                window = "Middle";
                break;
            case 'L':
                window = "Late";
                break;
            default:
                window = "Unknown";
                break;
        }

        return window;
    }
}


