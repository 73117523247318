import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CustomProtocol, CustomProtocolService, Filter, FilterQueryMode, Sample, SampleService} from '@core/data';
import { Snackbar } from '@core/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OrganizationGroupProtocolFilterFormDialogData {
    orgKey: string;
    groupKey: string;
    groupType: string;
}

export interface OrganizationGroupProtocolFilterFormDialogResult {
    protocolKey: string;
}

@Component({
    selector: 'pv-organization-group-protocol-filter-form-dialog',
    templateUrl: 'organization-group-protocol-filter-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationGroupProtocolFilterFormDialog implements OnInit {
    _protocolKeyControl: FormControl = new FormControl('', [Validators.required]);

    formGroup = new FormGroup({
        protocolKey: this._protocolKeyControl,
    });

    customProtocols$: Observable<CustomProtocol[]>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrganizationGroupProtocolFilterFormDialogData,
        private _dialog: MatDialogRef<OrganizationGroupProtocolFilterFormDialog>,
        private _snackbar: Snackbar,
        private _customProtocolService: CustomProtocolService,
    ) { }

    ngOnInit() {
        let filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: this.data.orgKey,
                }
            ],
            sort: {
                column: 'title',
                order: 'asc',
            }
        };

        this.customProtocols$ =
            this._customProtocolService.query(filter)
                .pipe(
                    map(result => result.data)
                );
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.valid) this.submit();
        else this._snackbar.error("Check your input and try again.");
    }

    submit() {
        this._dialog.close(this.formGroup.value);
    }

    cancel() {
        this._dialog.close();
    }
}
