<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">{{ state.key ? 'Edit Cultivar' : 'Add Cultivar' }}</h2>
        <h3 class="dialog-subtitle">
            Organisation: <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>
    <div mat-dialog-content>
        <form
              id="cultivar_form"
              [formGroup]="formGroup"
              #cultivarForm="ngForm"
              class="pv-form"
              (ngSubmit)="attempt()"
              autocomplete="off">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Common Name</mat-label>
                    <input matInput autocomplete="off" formControlName="commonName" required>
                    <mat-error>Enter a unique name (max 32 chars)</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Crop</mat-label>
                    <mat-select formControlName="cropId" required>
                        <mat-select-trigger>
                            <ng-container *ngIf="cropIdControl.value as cropId">
                                <mat-icon [svgIcon]="'common:' + cropId" class="mat-18 mr-12">
                                </mat-icon>
                                <span>{{ cropId | labelCrop }}</span>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option *ngFor="let crop of cropOptions" [value]="crop.id">
                            <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                            <span>{{ crop.label }}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error>Select a crop</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Harvest Window</mat-label>
                    <mat-select formControlName="harvestWindow">
                        <mat-option [value]="null">Unknown</mat-option>
                        <mat-option value="E">Early Season</mat-option>
                        <mat-option value="M">Mid-Season</mat-option>
                        <mat-option value="L">Late Season</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-checkbox formControlName="isRootstock" class="mat-caption">
                    Primarily used as Rootstock?
                </mat-checkbox>

            </div>

            <div class="form-column">

                <h4 class="form-subheader">Registration Details</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Selection ID</mat-label>
                    <input matInput autocomplete="off" formControlName="selectionId">
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Licensee</mat-label>
                    <input matInput autocomplete="off" formControlName="licensee" [matAutocomplete]="autoLicensee">
                    <mat-autocomplete #autoLicensee="matAutocomplete">
                        <mat-option *ngFor="let option of (filteredLicenseeOptions$ | async)" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Import ID</mat-label>
                    <input matInput autocomplete="off" formControlName="importId">
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

            </div>

            <div class="form-column">
                <h4 class="form-subheader">General Information</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Licensor</mat-label>
                    <input matInput autocomplete="off" formControlName="licensor"/>
                    <mat-hint>Licensor/Breeder/Owner</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Origin</mat-label>
                    <input matInput autocomplete="off" formControlName="origin"/>
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>S-alleles</mat-label>
                    <input matInput autocomplete="off" formControlName="salleles"/>
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Recommended Pollinators</mat-label>
                    <input matInput autocomplete="off" formControlName="pollinators">
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Licensee Testing Agreement</mat-label>
                    <mat-select formControlName="licenseeAgreement">
                        <mat-option [value]="null">Unknown</mat-option>
                        <mat-option value="A">Approved</mat-option>
                        <mat-option value="P">In Progress</mat-option>
                        <mat-option value="C">Completed</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Licensor Testing Agreement</mat-label>
                    <mat-select formControlName="licensorAgreement">
                        <mat-option [value]="null">Unknown</mat-option>
                        <mat-option value="A">Approved</mat-option>
                        <mat-option value="P">In Progress</mat-option>
                        <mat-option value="C">Completed</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Parentage</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Seed Parent</mat-label>
                    <input matInput autocomplete="off" formControlName="seedParent"/>
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Pollen Parent</mat-label>
                    <input matInput autocomplete="off" formControlName="pollenParent"/>
                    <mat-error>Max 32 characters</mat-error>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">
                    Trade/Registered Names
                    <button type="button" mat-icon-button
                            (click)="addTradeName()" [disabled]="formGroup.disabled">
                        <mat-icon>add</mat-icon>
                    </button>
                </h4>

                <div formArrayName="tradeNames"
                     *ngIf="tradeNamesControl.controls.length > 0 else noTradeNames">
                    <mat-form-field *ngFor="let tradeNameControl of tradeNamesControl.controls; let i = index"
                                    class="mat-body-1"
                                    appearance="standard">
                        <mat-label>Designation {{ i + 1 }}</mat-label>
                        <input matInput [formControlName]="i">
                        <button type="button" mat-icon-button matSuffix
                                (click)="removeTradeName(i)">
                            <mat-icon>remove</mat-icon>
                        </button>
                        <mat-error>Max 32 characters</mat-error>
                    </mat-form-field>
                </div>

                <ng-template #noTradeNames>
                    <small class="mat-caption">To specify additional designations, select
                        <code>+</code>.</small>
                </ng-template>
            </div>

        </form>
    </div>
    <pv-form-actions
                     mat-dialog-actions
                     [form]="cultivarForm"
                     (cancel)="cancel()"
                     [status]="state.status">

    </pv-form-actions>
</ng-container>
