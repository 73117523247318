import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, Inject } from "@angular/core";
import {CustomProtocolService, SampleCharacteristic} from '@core/data';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Library, Category, Characteristic } from '@library';
import { EvaluationFormatter } from '@app/evaluation/evaluation-formatter.service';
import { LIBRARY } from '@app/evaluation/library';





@Component({
    selector: 'pv-characteristics-list',
    templateUrl: 'characteristics-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'pv-characteristics-list'
    }
})
export class CharacteristicsListComponent implements OnInit, OnDestroy {

    @Input()
    set values(values: SampleCharacteristic[]) {
        this._values$.next(values);
    }

    @Input()
    set protocolId(protocolId: string) {
        this._protocolId$.next(protocolId);
    }

    private _values$ = new BehaviorSubject<SampleCharacteristic[]>(null);
    private _protocolId$ = new BehaviorSubject<string>(null);

    data$: Observable<any>;

    constructor(
        @Inject(LIBRARY) private _library: Library,
        private _formatter: EvaluationFormatter,
        private _custom_protocolService: CustomProtocolService
    ) { }

    ngOnInit() {
        this.data$ =
            combineLatest(this._values$, this._protocolId$)
                .pipe(
                    map(changes => {
                        let [valuesInput, protocolId] = changes;
                        return this.compileData(valuesInput, protocolId);
                    })
                );
    }

    ngOnDestroy() {
        this._values$.complete();
        this._protocolId$.complete();
    }

    trackGroup(index, item) {
        return item.category.id;
    }

    trackChar(index, item) {
        return item.id;
    }

    private compileData(values: SampleCharacteristic[], protocolId: string = null) {

        const protocol = protocolId ? this._custom_protocolService.allProtocols().get(protocolId) : null;
        values = Array.isArray(values) ? values : [];

        const charIds = protocol ? protocol.chars : [];

        const groups: { category: Category, chars: { label: string, value: string }[] }[] = [];

        const defaultCategory = this._library.categories.get('uncategorized');

        let category: Category, char: Characteristic,
            group: any, value: SampleCharacteristic;

        charIds.forEach(charId => {
            char = this._library.chars.get(charId);

            if (char) {
                category = this._library.categories.get(char.categoryId);
                if (!category) category = defaultCategory;
            } else {
                category = defaultCategory;
            }

            group = groups.find(group => group.category.id === category.id);

            if (!group) {
                group = { category, chars: [] };
                groups.push(group);
            }

            value = values.find(value => value.charId === charId);

            let val = value ? value.value : null;

            group.chars.push({
                id: charId,
                label: char ? char.label : charId,
                value: this._formatter.charValueLabel(val, charId)
            });

        });

        // look for values not in the protocol
        const other =
            values.filter(value => {
                return !charIds.includes(value.charId);
            })
            .map(value => {
                char = this._library.chars.get(value.charId);

                return {
                    id: value.charId,
                    label: char ? char.label : value.charId,
                    value: (char && value.value) ? this._formatter.charValueLabel(value.value, value.charId) : value.value,
                };

            });


        return {
            protocol,
            groups,
            other,
        };
    }

}
