

export function sortBy(array: any[], key: string, asc: boolean = true) {
    let sorted = array.concat().sort((a, b) => {
        let x = a[key];
        let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    if (asc) return sorted;
    return sorted.reverse();
}

export function uniqueStrings(array: string[]) {
    let seen: any = {};
    return array.filter(item => {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

export function mutateArrayReplace(array: any[], replacement: any, predicate: (value, index?, array?) => boolean) {
    return array.map((item, index, array) => {
        if (predicate(item, index, array)) return replacement;
        return item;
    });
}

export function mutateArrayPush(array: any[], item: any) {
    return [...array, item];
}

export function mutateArrayRemove(array: any[], predicate: (value, index?, array?) => boolean) {
    return array.filter((v, i, a) => !predicate(v, i, a));
}

export function arrayFromMap<K, V>(map: Map<K, V>) {
    return Array.from(map, ([key, value]) => value);
}

export function arrayHas(array: any[], value: any) {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === value) return true;
    }
    return false;
}

export function arrayGroupBy(array, key) {
    return array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function arrayContainsAll(needles, haystack) {
    for (var i = 0, len = needles.length; i < len; i++) {
        if (haystack.includes(needles[i]) == -1) return false;
    }
    return true;
}

/**
 * Clean an array of falsy values: "", 0, NaN, null, undefined, and false
 * 
 * @param array the array to clean
 */
export function filterFalsy(array: any[]) {
    return array.filter(el => !!el);
}

/**
 * Clean an array of undefined or null values
 * 
 * @param array the array to clean
 */
export function withoutEmpty(array: any[]) {
    return array.filter(el => el != null);
}