<h2 mat-dialog-title>Print Evaluation Form</h2>
<mat-dialog-content>
    <form [formGroup]="formGroup" id="eval_print_form" class="pv-form single-column" (ngSubmit)="confirm()">

        <p class="mat-caption">
            Use the options below to select which sections to include.
        </p>

        <mat-checkbox formControlName="includeChars">Include Characteristics</mat-checkbox>

        <mat-checkbox formControlName="includeNotes">Include Notes</mat-checkbox>

        <mat-checkbox formControlName="includeMeasures">Include Measurements</mat-checkbox>

        <mat-form-field appearance="standard">
            <mat-label># Measurements</mat-label>
            <input matInput type="number" formControlName="measuresCount">
            <mat-error>Invalid number</mat-error>
        </mat-form-field>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="accent" type="submit" form="eval_print_form">Preview</button>
</mat-dialog-actions>