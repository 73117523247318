import { Pipe, Inject } from "@angular/core";
import * as moment from 'moment';
import { Evaluation, Sample } from '@core/data';
import { LIBRARY } from '../library';
import { Library, CharacteristicType } from '@library';
import { parseDuration, mapNumber } from '@core/utils';

export interface TimelineEvent {
    id: string;
    label: string;
    start: string | moment.Moment;
    end?: string | moment.Moment;
    color: string;
}

export interface EvaluationTimeline {
    storage: TimelineEvent[];
    events: TimelineEvent[];
}

@Pipe({
    name: 'extractTimeline',
})
export class ExtractTimelinePipe {

    constructor(@Inject(LIBRARY) private _evalLib: Library){}

    transform(evalu: Evaluation, storageStart: moment.Moment | string): EvaluationTimeline {

        if(!evalu) return null;
        return this.convert(evalu, storageStart);

    }

    private convert(evalu: Evaluation, storageStart: moment.Moment | string): EvaluationTimeline{

        let events: TimelineEvent[] = [];
        let storage: TimelineEvent[] = [];
        let start = moment(storageStart);

        if(Array.isArray(evalu.chars)){

            evalu.chars.forEach(char => {

                if(!char.value) return;

                let opt = this._evalLib.chars.get(char.charId);

                if(opt && opt.type === CharacteristicType.Event){

                    events.push({
                        id: opt.id,
                        label: opt.label,
                        start: moment(char.value).utc(),
                        color: opt.params.color
                    });

                }


            });

            if(evalu.storageRegime){

                let rollingStart = start.clone();

                evalu.storageRegime.forEach((storageItem, i) => {

                    let dur = parseDuration(storageItem.duration);
                    let hue = mapNumber(storageItem.temp, -5, 10, 240, 120);

                    storage.push({
                        id: `storage_${i}`,
                        label: `${storageItem.duration} @ ${storageItem.temp.toFixed(1)}&deg;C`,
                        start: rollingStart.clone(),
                        end: rollingStart.add(dur.time, dur.unit).clone(),
                        color: `hsla(${hue},80%,70%,0.7)`
                    });


                });
            }



        }


        return {
            events,
            storage
        };
    }

}