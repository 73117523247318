<div class="file-upload" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" [class.dragging]="isDragging">
    <label for="fileInput">Choose Files</label>
    <input #fileInput id="fileInput" (change)="fileInputChanged($event)" type="file" [multiple]="multiple" [accept]="accepts">
    <span class="drop-label">or Drop files here</span>
    <div class="file-hint">File types allowed is {{ accepts }}, max size {{ maxSize | formatBytes:0 }}</div>
    <div class="validation-errors">
        <span *ngFor="let error of validationErrors">{{ error }}</span>
    </div>
</div>
<div class="file-watch" *ngIf="fileUploads.length > 0">
    <div class="file-ref" *ngFor="let upload of fileUploads">
        <h5>{{ upload.name }} 
            <mat-icon class="done-icon" *ngIf="upload.complete">done</mat-icon>
            <mat-icon class="error-icon" *ngIf="upload.error">error</mat-icon>
        </h5>
        <span *ngIf="!upload.complete">{{ upload.loaded | formatBytes:0 }} / {{ upload.total | formatBytes:0 }}</span>
        <mat-progress-bar *ngIf="!upload.complete" [value]="upload.progress"></mat-progress-bar>
    </div>
</div>