import { Injectable } from '@angular/core';
import { AuthService, InvalidCredentialsError, ProfileUpdateRequest, Status, translateCommonErrorStatus, User } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface AuthProfileFormStateModel {
    status: Status;
    data: User;
    errors: any;
}

const DEFAULTS: AuthProfileFormStateModel = {
    status: Status.UNINITIALIZED,
    data: null,
    errors: {}
};

export class InitAuthProfileForm {
    static readonly type = "[AuthProfileForm] Init";
}

export class SubmitAuthProfileForm {
    static readonly type = "[AuthProfileForm] Submit";
    constructor(public data: ProfileUpdateRequest) { }
}

export class AuthProfileUpdated {
    static readonly type = "[AuthProfileForm] Updated";
    constructor(public user: User) { }
}

@State<AuthProfileFormStateModel>({
    name: "auth_profile_form",
    defaults: DEFAULTS
})
@Injectable()
export class AuthProfileFormState {

    @Selector()
    static data(state: AuthProfileFormStateModel) {
        return state.data;
    }

    constructor(private _authService: AuthService) { }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(InitAuthProfileForm, { cancelUncompleted: true })
    initAuthProfileForm(ctx: StateContext<AuthProfileFormStateModel>, action: InitAuthProfileForm) {

        ctx.patchState({
            data: null,
            status: Status.LOADING,
            errors: {}
        });

        return this._authService
            .getProfile()
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: null
                        });
                    }
                )
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action data: profile form data
     */
    @Action(SubmitAuthProfileForm)
    submitAuthProfileForm(ctx: StateContext<AuthProfileFormStateModel>, action: SubmitAuthProfileForm) {

        ctx.patchState({
            status: Status.LOADING,
            errors: {}
        });

        return this._authService.updateProfile(action.data)
            .pipe(
                catchError(e => {
                    if (e instanceof InvalidCredentialsError) {
                        ctx.patchState({
                            status: Status.OK,
                            errors: {
                                'invalid_credentials': true
                            }
                        });
                    } else {
                        ctx.patchState({
                            status: translateCommonErrorStatus(e)
                        });
                    }

                    return throwError(e);
                }),
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE
                        });
                        ctx.dispatch(new AuthProfileUpdated(result));
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }
}