import { Cultivar, CultivarService, FilterBuilder, FilterPage, FilterSort, OrganizationService, Status, translateCommonErrorStatus, removeArrayItem, CultivarUpdated, CultivarDeleted, CultivarCreated, Filter, DetailRequest, FilterQueryMode } from '@core/data';
import { Action, State, StateContext, Selector } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LocalStorage } from '@core/browser';
import { Injectable } from '@angular/core';



export interface CultivarIndexStateModel {

    // org context
    orgKey: string;

    // table data
    status: Status;
    data: Cultivar[];
    total: number;

    // filter options
    page: FilterPage;
    sort: FilterSort;
    query: CultivarIndexQuery;
    licenseeOptions: string[];

    // table options
    displayColumns: string[];
    sortOptions: { id: string, label: string }[];
    pageSizeOptions: number[];

    // export result
    exports: {
        status: Status;
    }[];

    // selection
    selectedKeys: string[];
    selectedData: Cultivar[];
}

export interface CultivarIndexQuery {
    cropId: string[];
    harvestWindow: string[];
    isRootstock: string;
    search: string;
    licensee: string[];
}

const DEFAULTS: CultivarIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'commonName',
        order: 'asc'
    },
    displayColumns: [
        'selectionBox',
        'commonName',
        'actions',
        'cropId',
        'selectionId',
        'importId',
        'licensee',
        'harvestWindow',
        'tradeNames',
        'samples',
        'createdAt',
    ],
    sortOptions: [
        { id: 'commonName', label: "Common Name" },
        { id: 'cropId', label: "Crop" },
        { id: 'selectionId', label: "Selection ID" },
        { id: 'importId', label: "Import ID" },
        { id: 'harvestWindow', label: "Harvest Window" },
        { id: 'licensee', label: "Licensee" },

        { id: 'scionPlantSamplesCount', label: '# Assoc. Plant, Scion' },
        { id: 'scionHarvestSamplesCount', label: '# Assoc. Harvest, Scion' },
        { id: 'rootstockPlantSamplesCount', label: '# Assoc. Plant, Rootstock' },
        { id: 'rootstockHarvestSamplesCount', label: '# Assoc. Harvest, Rootstock' },

        { id: 'createdAt', label: "Created Date" },
        { id: 'updatedAt', label: "Updated Date" },
    ],
    pageSizeOptions: [20,50,100,500,1000],
    query: {
        cropId: [],
        harvestWindow: [],
        isRootstock: '*',
        licensee: [],
        search: null,
    },
    licenseeOptions: [],
    exports: [],
    selectedKeys: [],
    selectedData: [],
};

export class InitCultivarIndex {
    static readonly type = "[CultivarIndex] Init";
    constructor(public orgKey: string){}
}

export class LoadCultivarIndex {
    static readonly type = "[CultivarIndex] Load";
    constructor(){}
}

export class LoadCultivarIndexLicensees {
    static readonly type = "[CultivarIndex] Load Licensees";
    constructor(){}
}

export class PageCultivarIndex {
    static readonly type = "[CultivarIndex] Page";
    constructor(public index: number, public size: number){}
}

export class SortCultivarIndex {
    static readonly type = "[CultivarIndex] Sort";
    constructor(public column: string, public order = 'asc'){}
}

export class FilterCultivarIndex {
    static readonly type = "[CultivarIndex] Filter";
    constructor(public query: CultivarIndexQuery){}
}

export class ResetCultivarIndex {
    static readonly type = "[CultivarIndex] Reset";
    constructor(){}
}

export class ExportCultivarIndex {
    static readonly type = "[CultivarIndex] Export";
    constructor(public type = 'xlsx'){}
}

export class ImportCultivarIndex {
    static readonly type = "[CultivarIndex] Import";
    constructor(public file: File) {}
}

export class DownloadCultivarImportTemplate {
    static readonly type = "[CultivarIndex] Import Template";
    constructor() {}
}

export class DeleteCultivar {
    static readonly type = "[CultivarIndex] Delete";
    constructor(public key: string){}
}

export class SetCultivarIndexSelected {
    static readonly type = "[CultivarIndex] Set Selected";
    constructor(public keys: string[]) {}
}

@State<CultivarIndexStateModel>({
    name: 'cultivar_index',
    defaults: DEFAULTS
})
@Injectable()
export class CultivarIndexState {

    @Selector()
    static query(state: CultivarIndexStateModel){
        return state.query;
    }

    @Selector()
    static sort(state: CultivarIndexStateModel){
        return state.sort;
    }

    @Selector()
    static latestExport(state: CultivarIndexStateModel){
        return state.exports.length ? state.exports[state.exports.length - 1] : null;
    }

    constructor(
        private _cultivarService: CultivarService,
        private _storage: LocalStorage,
    ){}

    @Action(InitCultivarIndex)
    initCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: InitCultivarIndex){

        ctx.patchState({
            orgKey: action.orgKey,
            data: [],
            total: 0,
            exports: []
        });


        this.restoreFilter(ctx);

        ctx.dispatch(new LoadCultivarIndexLicensees);

        return ctx.dispatch(new LoadCultivarIndex);
    }

    @Action(LoadCultivarIndex, {cancelUncompleted: true})
    loadCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: LoadCultivarIndex){

        const state = ctx.getState();

        if(!state.orgKey){
            console.warn("Attempting to load CultivarIndex without organization key.");
        }

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            counts: [
                'scionPlantSamples',
                'scionHarvestSamples',
                'rootstockPlantSamples',
                'rootstockHarvestSamples',
            ]
        };

        return this._cultivarService.query(this.getFilter(state), detail)
                    .pipe(
                        tap(
                            result => {
                                if(result.total === 0) {
                                    ctx.patchState({
                                        status: Status.EMPTY,
                                        total: 0,
                                        data: []
                                    });
                                    return;
                                }

                                ctx.patchState({
                                    status: Status.OK,
                                    data: result.data,
                                    total: result.total
                                });

                                this.storeFilter(ctx);

                            },
                            error => {
                                ctx.patchState({
                                   status: translateCommonErrorStatus(error),
                                   data: [],
                                   total: 0
                                });
                            },
                        )
                    );

    }

    @Action(LoadCultivarIndexLicensees, {cancelUncompleted: true})
    loadCultivarIndexLicensees(ctx: StateContext<CultivarIndexStateModel>, action: LoadCultivarIndexLicensees){

        const state = ctx.getState();

        const filter: Filter = {
            queries: [{ key: 'ownerOrgKey', value: state.orgKey }]
        };

        return this._cultivarService.groupLicensees(filter)
                    .pipe(
                        tap(result => {
                            if(result){
                                ctx.patchState({
                                    licenseeOptions: result.group,
                                });
                            }
                        }),
                        catchError(e => of(null))
                    );

    }


    @Action(PageCultivarIndex)
    pageCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: PageCultivarIndex){

        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadCultivarIndex);

    }

    @Action(SortCultivarIndex)
    sortCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: SortCultivarIndex){

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadCultivarIndex);

    }

    @Action(SetCultivarIndexSelected)
    setCultivarIndexSelected(ctx: StateContext<CultivarIndexStateModel>, action: SetCultivarIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let cultivar = state.data.find(c => c.key === key);
            if (cultivar) return cultivar;
            return state.selectedData.find(c => c.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(FilterCultivarIndex)
    filterCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: FilterCultivarIndex){


        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadCultivarIndex);

    }

    @Action(ResetCultivarIndex)
    resetCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: FilterCultivarIndex){

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadCultivarIndex);

    }

    @Action(ExportCultivarIndex)
    exportCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: ExportCultivarIndex){

        const state = ctx.getState();

        let index = state.exports.length;

        ctx.patchState({
            exports: [...state.exports, {
                status: Status.LOADING
            }]
        });

        return this._cultivarService.export(this.getFilter(state, true), state.orgKey, action.type)
                    .pipe(
                        tap(
                            result => {

                                let exps = [...ctx.getState().exports];

                                exps[index] = {
                                    status: Status.COMPLETE,
                                };

                                ctx.patchState({
                                    exports: exps
                                });
                            },
                            error => {

                                let exps = [...ctx.getState().exports];

                                exps[index] = {
                                    status: Status.ERROR
                                };

                                ctx.patchState({
                                    exports: exps
                                });
                            }
                        )
                    );

    }

    @Action(ImportCultivarIndex)
    importCultivarIndex(ctx: StateContext<CultivarIndexStateModel>, action: ImportCultivarIndex) {
        const state = ctx.getState();
        return this._cultivarService.import(state.orgKey, action.file)
            .pipe(
                tap(
                    result => {
                        // No Action needed
                    },
                    error => {
                        console.warn('CultivarIndexState: Upload Error ', error);

                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(DownloadCultivarImportTemplate)
    downloadCultivarImportTemplate(ctx: StateContext<CultivarIndexStateModel>, action: DownloadCultivarImportTemplate) {
        return this._cultivarService.downloadImportTemplate()
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = "cultivar-import-template";
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn("CultivarIndexState: Download Error ", error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        })
                    }
                )
            );
    }

    @Action(DeleteCultivar)
    deleteCultivar(ctx: StateContext<CultivarIndexStateModel>, action: DeleteCultivar){


        return this._cultivarService.delete(action.key)
                    .pipe(tap(removed => {
                        const state = ctx.getState();

                        if(state.status !== Status.OK) return;

                        ctx.patchState({
                            data: removeArrayItem(state.data, removed.key),
                        });

                    }));

    }


    @Action(CultivarUpdated)
    onCultivarUpdated(ctx: StateContext<CultivarIndexStateModel>, action: CultivarUpdated){
        return this.handleCultivarChanged(ctx);
    }

    @Action(CultivarCreated)
    onCultivarCreated(ctx: StateContext<CultivarIndexStateModel>, action: CultivarCreated){
        return this.handleCultivarChanged(ctx);
    }

    @Action(CultivarDeleted)
    onCultivarDeleted(ctx: StateContext<CultivarIndexStateModel>, action: CultivarDeleted){
        return this.handleCultivarChanged(ctx);
    }


    private handleCultivarChanged(ctx: StateContext<CultivarIndexStateModel>){

        const state = ctx.getState();

        if(state.status === Status.OK){
            return ctx.dispatch(new LoadCultivarIndex());
        }

    }


    private getFilter(state: CultivarIndexStateModel, ignorePage = false){

        const fb = (new FilterBuilder());

        if(state.orgKey){
            fb.setQuery('ownerOrgKey', state.orgKey)
        }

        if(!ignorePage) fb.setPage(state.page);
        fb.setSort(state.sort);

        if(typeof state.query.search === 'string' && state.query.search.length > 0){
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        if(state.query.isRootstock !== '*'){
            fb.setQuery('isRootstock', state.query.isRootstock);
        }

        if(Array.isArray(state.query.cropId) && state.query.cropId.length > 0){
            fb.setQuery('cropId', state.query.cropId, FilterQueryMode.IN);
        }

        if(Array.isArray(state.query.harvestWindow) && state.query.harvestWindow.length > 0){
            fb.setQuery('harvestWindow', state.query.harvestWindow, FilterQueryMode.IN);
        }
        if(Array.isArray(state.query.licensee) && state.query.licensee.length > 0){
            fb.setQuery('licensee', state.query.licensee, FilterQueryMode.IN);
        }



        return fb.get();

    }


    private storeFilter(ctx: StateContext<CultivarIndexStateModel>){
        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_cultivar_index_page`, state.page);
        this._storage.set(`${state.orgKey}_cultivar_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_cultivar_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<CultivarIndexStateModel>){

        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_cultivar_index_page`);
        const sort = this._storage.get(`${state.orgKey}_cultivar_index_sort`);
        const query = this._storage.get(`${state.orgKey}_cultivar_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });
    }



}



