import { Injectable } from '@angular/core';
import { Organization, OrganizationFormRequest, OrganizationService, Status, translateCommonErrorStatus } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

export interface OrganizationFormStateModel {
    key: string;
    status: Status;
    data: Organization;
}

export class InitOrganizationForm {
    static readonly type = "[OrganizationForm] Init";
    constructor(public key: string) { }
}

export class SubmitOrganizationForm {
    static readonly type = "[OrganizationForm] Submit";
    constructor(public data: OrganizationFormRequest) { }
}

@State<OrganizationFormStateModel>({
    name: "organization_form",
    defaults: {
        status: Status.UNINITIALIZED,
        data: null,
        key: null
    }
})
@Injectable()
export class OrganizationFormState {

    @Selector()
    static data(state: OrganizationFormStateModel) {
        return state.data;
    }

    constructor(private _orgService: OrganizationService) { }

    /**
     *
     * @param ctx State Context
     * @param action key: of the organization you wish to load
     */
    @Action(InitOrganizationForm)
    initOrganizationForm(ctx: StateContext<OrganizationFormStateModel>, action: InitOrganizationForm) {

        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING
        });

        return this._orgService.get(action.key)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: null
                        });
                    }
                )
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action data: Organization form request data
     */
    @Action(SubmitOrganizationForm)
    submitOrganizationForm(ctx: StateContext<OrganizationFormStateModel>, action: SubmitOrganizationForm) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        return this._orgService.update(state.key, action.data)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            data: result,
                            status: Status.COMPLETE
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }
}