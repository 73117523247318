import { Flag } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_BREED_FLAG } from './flags';


export class FlagDictionary extends Dictionary<Flag>{
    constructor(){
        super(LIB_BREED_FLAG);
    }
}

export * from './_types';