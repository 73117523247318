import { Protocol } from './_types';

export const LIB_PROTOCOL_CITRUS_HARVEST_EATQUAL_EVAL: Protocol = {
    id: "citrus_harvest_eatqual_eval",
    title: "Citrus Eating Quality Harvest Evaluation (Alpha)",
    description: "Protocol is in development (alpha stage), changes may occur.",
    sampleType: "harvest",
    crop: "hybrid_citrus",
    

    chars: [
        "frut_taste_sugar",
        // "frut_taste_citric",
        "frut_taste_balance",
        "frut_taste_juice",
        // "frut_taste_offtaste",
        // "frut_texture",
        // "frut_taste_raggy",
        "frut_overall_eatqual"
    ],

    measures: [
        "frut_length",
        "frut_weight",
        "frut_diameter",
        "frut_firmness",
        "frut_tss",
        "frut_acid",
        "frut_acid_citric",
        "frut_ph"
    ],

    indexes: [],

}