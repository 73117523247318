import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Status, Cultivar, CultivarService, translateCommonErrorStatus, DuplicateCommonNameError, Filter } from '@core/data';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


export interface CultivarFormStateModel {
    key: string;
    status: Status;
    data: Partial<Cultivar>;
    licenseeOptions: string[];
    errors: {
        duplicateCommonName?: boolean;
    };
}

const DEFAULTS: CultivarFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
    errors: {},
    licenseeOptions: []
};

export class InitCultivarForm {
    static readonly type = "[CultivarForm] Init";
    constructor(public key?: string, public defaults?: Partial<Cultivar>){}
}

export class SubmitCultivarForm {
    static readonly type = "[CultivarForm] Submit";
    constructor(public data: Partial<Cultivar>){}
}
export class LoadCultivarFormLicensees {
    static readonly type = "[CultivarForm] Load licensees";
    constructor(){}
}

@State<CultivarFormStateModel>({
    name: 'cultivar_form',
    defaults: DEFAULTS
})
@Injectable()
export class CultivarFormState {

    @Selector()
    static data(state: CultivarFormStateModel){
        return state.data;
    }

    @Selector()
    static licenseeOptions(state: CultivarFormStateModel){
        return state.licenseeOptions;
    }


    constructor(private _cultivarService: CultivarService){}

    @Action(InitCultivarForm, {cancelUncompleted: true})
    initCultivarForm(ctx: StateContext<CultivarFormStateModel>, action: InitCultivarForm){

        if(action.key){
            // if key provided we are editing
            return this.initEditCultivarForm(ctx, action);
        }else{
            // else new culivar
            return this.initNewCultivarForm(ctx, action);
        }

    }

    private initNewCultivarForm(ctx: StateContext<CultivarFormStateModel>, action: InitCultivarForm){

        if(!action.defaults.ownerOrgKey){
            console.warn('CultivarFormState: No ownerOrgKey provided');
            ctx.setState({
                key: null,
                status: Status.ERROR,
                data: {},
                errors: {},
                licenseeOptions: []
            });
            return;
        }

        ctx.setState({
            key: null,
            status: Status.OK,
            data: {
                ...action.defaults
            },
            errors: {},
            licenseeOptions: []
        });

        ctx.dispatch(new LoadCultivarFormLicensees());

    }

    private initEditCultivarForm(ctx: StateContext<CultivarFormStateModel>, action: InitCultivarForm){

        ctx.setState({
            key: action.key,
            status: Status.LOADING,
            data: null,
            errors: {},
            licenseeOptions: []
        });

        return this._cultivarService.get(action.key)
                    .pipe(
                        tap(
                            result => {
                                ctx.patchState({
                                    key: result.key,
                                    data: result,
                                    status: Status.OK,
                                });
                                ctx.dispatch(new LoadCultivarFormLicensees());
                            },
                            error => {
                                ctx.patchState({
                                    status: translateCommonErrorStatus(error)
                                });
                            }
                        )
                    );
    }



    @Action(SubmitCultivarForm)
    submitCultivarForm(ctx: StateContext<CultivarFormStateModel>, action: SubmitCultivarForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        let request: Observable<Cultivar>;

        if(state.key) request = this._cultivarService.update(state.key, action.data);
        else request = this._cultivarService.create(action.data);

        return request.pipe(
            tap(
                result => {
                    ctx.patchState({
                        key: result.key,
                        data: result,
                        status: Status.COMPLETE,
                        errors: {}
                    });

                },
                error => {

                    if(error instanceof DuplicateCommonNameError){
                        ctx.patchState({
                            status: Status.INVALID,
                            errors: {
                                duplicateCommonName: true
                            }
                        });
                    }else{
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                }
            )
        );

    }

    @Action(LoadCultivarFormLicensees)
    loadLicensees(ctx: StateContext<CultivarFormStateModel>, action: LoadCultivarFormLicensees){

        const state = ctx.getState();

        const filter: Filter = {
            queries: [{ key: 'ownerOrgKey', value: state.data.ownerOrgKey }]
        };

        return this._cultivarService.groupLicensees(filter)
                    .pipe(
                        tap(
                            result => {
                                ctx.patchState({
                                    licenseeOptions: result.group
                                });
                            }
                        )
                    );

    }

}
