import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { API_BASE_URI } from "../http/api";
import { Collection } from "../http/collection";
import { Filter, FilterBuilder } from '../http/filter';
import { DetailRequestBuilder, DetailRequest } from "../http/detail";
import { TastingContact, TastingContactInvitation } from "../types/tastings-contact";

export class TastingContactCreated {
    static readonly type = "[TastingContactService] Tasting Contact Created";
    constructor(public tastingContact: TastingContact){}
}

export class TastingContactUpdated {
    static readonly type = "[TastingContactService] Tasting Contact Updated";
    constructor(public tastingContact: TastingContact){}
}

export class TastingContactDeleted {
    static readonly type = "[TastingContactService] Tasting Contact Deleted";
    constructor(public tastingContact: TastingContact){}
}

export class TastingContactInvited {
    static readonly type = "[TastingContactService] Tasting Contact Invited";
    constructor(public invitation: TastingContactInvitation) {}
}

@Injectable()
export class TastingContactService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) { }

    get(tastingContactKey: string, detail?: DetailRequest): Observable<TastingContact> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<TastingContact>(`${this._baseUri}/tasting-contact/${tastingContactKey}`, {params});
    }

    create(model: Partial<TastingContact>) {
        return this._http.post<TastingContact>(`${this._baseUri}/tasting-contact`, model)
            .pipe(tap(tastingContact => this._store.dispatch(new TastingContactCreated(tastingContact))))
    }

    update(key: string, model: Partial<TastingContact>) {
        return this._http.put<TastingContact>(`${this._baseUri}/tasting-contact/${key}`, model)
                .pipe(tap(tastingContact => this._store.dispatch(new TastingContactUpdated(tastingContact))));
    }

    delete(key: string) {
        return this._http.delete<TastingContact>(`${this._baseUri}/tasting-contact/${key}`)
                .pipe(tap(tastingContact => this._store.dispatch(new TastingContactDeleted(tastingContact))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<TastingContact>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<TastingContact>>(`${this._baseUri}/tasting-contact`, { params });
    }

    export(filter: Filter, detail?: DetailRequest) {
        const data = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.post<TastingContactExportResponse>(`${this._baseUri}/tasting-contact/export`, data);
    }

    invite(invitation: Partial<TastingContactInvitation>) {
        return this._http.post<TastingContactInvitation>(`${this._baseUri}/tasting-contact/invite`, invitation)
            .pipe(tap(invitation => this._store.dispatch(new TastingContactInvited(invitation))));
    }
}

export interface TastingContactExportResponse {}