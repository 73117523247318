import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { ClearTastingsEvaluationsIndexFilter, InitTastingsEvaluationsIndex, LoadTastingsEvaluationsIndex, PageTastingsEvaluationsIndex, QueryTastingsEvaluationsIndex, SetTastingsEvaluationIndexSelected, SortTastingsEvaluationsIndex } from "./tastings-evaluations-index.state-actions";
import { TastingsEvaluationsIndexState } from "./tastings-evaluations-index.state";
import { TastingsEvaluationsIndexQuery, TastingsEvaluationsIndexStateModel } from "./tastings-evaluations-index.state-model";
import { Observable, Subject } from "rxjs";
import { AuthState } from "@app/auth";
import { FilterSort, Organization, Status } from "@core/data";
import { FormControl, FormGroup } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { debounceTime, takeUntil } from "rxjs/operators";
import { coerseDateProperty } from "@core/utils";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { TastingEvaluation, TastingEvaluationIndexType } from "@core/data/types/tastings-evaluation";
import { TastingEvaluationFormDialog, TastingEvaluationsFormDialogData } from "../tastings-evaluations-forms/tastings-evaluations-form.dialog";
import { Dialog } from "@core/material";
import { TastingEvaluationsFormDialog, TastingEvaluationsImportFormDialogData } from "../tastings-evaluation-import-form/tastings-evaluation-import-form.dialog";
import { ImportSubjectType } from "@core/data/types/import-subject";


@Component({
    selector: 'pv-tastings-evaluations-index-view',
    templateUrl: 'tastings-evaluations-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tastings-evaluations-index-view'
    }
})
export class TastingsEvaluationsIndexView implements OnInit, OnDestroy {
    @Select(AuthState.selectedOrg)
    selectedOrg$: Observable<Organization>;

    @Select(TastingsEvaluationsIndexState)
    state$: Observable<TastingsEvaluationsIndexStateModel>;

    @Select(TastingsEvaluationsIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(TastingsEvaluationsIndexState.query)
    query$: Observable<TastingsEvaluationsIndexQuery>;

    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    searchControl = new FormControl();
    createdAtFromControl = new FormControl(null);
    createdAtToControl = new FormControl(null);
    queryFormGroup = new FormGroup({
        search: this.searchControl,
        createdAtFrom: this.createdAtFromControl,
        createdAtTo: this.createdAtToControl,
    });

    subjectType = null;
    subjectKey = null;
    orgKey = null;
    selection = new SelectionModel<string>(true, []);
    selectedTabIndex = 0;
    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _dialog: Dialog,
    ) { }

    ngOnInit(): void {
        this._route.paramMap
            .subscribe(params => {
                this.subjectType = params.get('type');
                this.subjectKey = params.get('subjectKey');
                this.orgKey = params.get('orgKey');

                this._store.dispatch(
                    new InitTastingsEvaluationsIndex(
                        params.get('orgKey'),
                        params.get('type'),
                        params.get('subjectKey'),
                    )
                );
            })

        //QUERY LOGIC
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                let data: TastingsEvaluationsIndexQuery = {
                    ...query,
                    createdAtFrom: query.createdAtFrom ? coerseDateProperty(query.createdAtFrom) : null,
                    createdAtTo: query.createdAtTo ? coerseDateProperty(query.createdAtTo) : null
                }

                this.queryFormGroup.patchValue(data, {emitEvent: false})
            });

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                let data: TastingsEvaluationsIndexQuery = {
                    ...val,
                    createdAtFrom: val.createdAtFrom ? coerseDateProperty(val.createdAtFrom) : null,
                    createdAtTo: val.createdAtTo ? coerseDateProperty(val.createdAtTo) : null
                }

                this._store.dispatch(new QueryTastingsEvaluationsIndex(data));
            });
        //QUERY LOGIC END

        //SORT LOGIC
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingsEvaluationsIndex(val.column, val.order));
            });
        //SORT LOGIC END

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetTastingsEvaluationIndexSelected(this.selection.selected));
            });
    }

    reload() {
        this._store.dispatch(new LoadTastingsEvaluationsIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingsEvaluationsIndex(page.pageIndex, page.pageSize));
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingsEvaluationsIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingsEvaluationsIndex('createdAt', 'desc'));
        }
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingsEvaluationsIndexFilter);
    }

    trackByKey(index, item) {
        return item.key;
    }

    isAllSelected(data: TastingEvaluation[]) {
        return data.every((tastingEvaluation) => {
            return this.selection.isSelected(tastingEvaluation.key);
        });
    }

    isSelected(tastingEvaluation: TastingEvaluation) {
        return this.selection.isSelected(tastingEvaluation.key);
    }

    select(tastingEvaluation: TastingEvaluation) {
        if (this.selection.isSelected(tastingEvaluation.key)) this.selection.deselect(tastingEvaluation.key);
        else this.selection.select(tastingEvaluation.key);
    }

    isTastingsEvent(type: TastingEvaluationIndexType): boolean {
        return type === TastingEvaluationIndexType.EVENT;
    }

    openImportDialog(orgKey: string, eventKey: string = null) {
        const data: TastingEvaluationsImportFormDialogData = {
            orgKey: orgKey,
            subjectKey: eventKey,
            subjectType: ImportSubjectType.TASTINGS_EVALS,
        }

        this._dialog.open(TastingEvaluationsFormDialog, { data });
    }

    openReimportDialog(orgKey:string, eventKey:string = null) {
        const data: TastingEvaluationsImportFormDialogData = {
            orgKey: orgKey,
            subjectKey: eventKey,
            subjectType: ImportSubjectType.TASTINGS_EVAL_REIMPORT,
        }

        this._dialog.open(TastingEvaluationsFormDialog, { data });
    }

    masterToggle(data: TastingEvaluation[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    exportTastingsEvaluations(keys: string[]) {
        //TODO: handle export
    }

    delete(tastingEvaluation: TastingEvaluation): void {
        //TODO: add delete action
    }

    add() {
        const data: TastingEvaluationsFormDialogData = {
            subjectKey: this.subjectKey,
            subjectType: this.subjectType,
            defaults: {
                ownerOrgKey: this.orgKey,
            }
        }

        this._dialog.open(TastingEvaluationFormDialog, { data }).afterClosed()
            .subscribe((result => {
                if (!result) return;

                this.resetFilter();
            }))
    }

    getSubjectLabel(state: TastingsEvaluationsIndexStateModel): string {
        let label: string = "Tasting Evaluations Index";

        if (state.status !== Status.OK) return label;

        if (this.subjectType === TastingEvaluationIndexType.EVENT) {
            label = state.data[0].tastingsEvent.title;
            label += " Evaluations";
        }

        if (this.subjectType === TastingEvaluationIndexType.SAMPLE) {
            label = state.data[0].tastingsSample.label;
            label += " Evaluations";
        }

        return label
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}