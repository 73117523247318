<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>Event invitation</span>
        </h2>
        <h3 class="dialog-subtitle">
            Invite selected contacts to event
        </h3>
        <h4 class="important-note">
            NOTE: Only subscribed contacts will receive invitations
        </h4>
    </div>

    <form id="tasting_contact_invite_form"
        #tastingContactInviteForm="ngForm"
        [formGroup]="formGroup"
        class="pv-form no-flex"
        (ngSubmit)="attempt()"
        novalidate
        autocomplete="no">

        <div mat-dialog-content class="dialog-container">
            <mat-selection-list *ngIf="(state.data && state.data.length) else noEvents" required formControlName="events" class="event-list">
                <mat-list-option *ngFor="let event of state.data" [value]="event.key" class="event-option">
                    <span class="event-option-content">
                        <pv-image-ref
                            class="event-image"
                            [fileRef]="event.tastingsEventImage ? event.tastingsEventImage.fileRef : defaultBannerPath"
                            transformId="thumb_300"
                            height="150"
                            width="150"
                            mode="fill"
                            radius="true">
                        </pv-image-ref>
                        <div class="event-info-container">
                            <div class="event-info-item">
                                <span class="event-title darker">{{event.title}}</span>
                                <span class="event-subtitle">{{event.code}}</span>
                            </div>
                            <div class="event-info-item">
                                <span class="event-text darker">{{event.venueName}}</span>
                                <span class="event-text sm">{{event.eventDate | date:'fullDate'}}</span>
                                <span class="event-text lightened sm">{{event.eventStartTime | date:'HH:mm'}} - {{event.eventEndTime | date:'HH:mm'}}</span>
                            </div>
                        </div>
                    </span>
                </mat-list-option>
            </mat-selection-list>
        </div>

        <mat-form-field appearance="standard">
            <mat-label>Invitation Message</mat-label>
            <textarea matInput rows="4" formControlName="inviteMessage"
                style="resize: none;"></textarea>
            <mat-hint>Invitation message for invite email</mat-hint>
            <mat-error>Description max 255 chars</mat-error>
        </mat-form-field>
    </form>

    <ng-template #noEvents>
        <p class="text-muted">No upcoming events available.</p>
    </ng-template>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingContactInviteForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>