<ng-container>
	<!-- Title -->
	<div mat-dialog-title class="mat-dialog-header">
		<h2 class="dialog-title">Add Members To Group</h2>
	</div>
	<div mat-dialog-content>
		<!-- Form -->
		<form #orgMemberForm="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()">
			<!-- User -->
			<mat-form-field *ngIf="users$ | async as users; else userLoader">
				<mat-label>User</mat-label>
				<mat-select formControlName="userKey" multiple>
					<mat-option *ngFor="let user of users" [value]="user.key">{{ user.name }} &lt;{{ user.email }}&gt;
					</mat-option>
				</mat-select>
				<mat-error>Select a user</mat-error>
			</mat-form-field>
			<ng-template #userLoader>
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</ng-template>
		</form>
	</div>
	<!-- Actions -->
	<pv-form-actions mat-dialog-actions [form]="orgMemberForm" (cancel)="cancel()">
	</pv-form-actions>
</ng-container>
