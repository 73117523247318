import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

export enum AppThemes {
    default = 'app-theme',
    tastings = 'tastings-theme',
    breeding = 'breeding-theme'
}

@Injectable({
    providedIn: 'root',
})
export class AppThemesService {
    currentTheme: AppThemes;
    currentThemeSubject = new Subject<string>();

    constructor() {}

    changeTheme(theme: AppThemes): void {
        this.currentTheme = theme;
        this.currentThemeSubject.next(theme);
    }

    getTheme(): Observable<string> {
		return this.currentThemeSubject.asObservable();
	}
}