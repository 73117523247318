import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Library } from '@library';
import { LIBRARY } from '../library';

@Pipe({
  name: 'labelWeatherReport'
})
export class LabelWeatherReportPipe implements PipeTransform {

    constructor(@Inject(LIBRARY) private _evalLib: Library){}

    transform(id: string) {

        if (!id) return '';

        let item = this._evalLib.weatherReports.get(id);

        if (item){
            return item.label;
        }else{
            return 'Unknown Report';
        }
    }
}