import { WeatherReport } from "./_types";

export const LIB_WEATHER_REPORTS: WeatherReport[] = [

    {
        id: 'air_temp',
        label: 'Air Temperature',
        sensorIds: ['tair'],
        intervalIds: ['hourly', 'daily', 'monthly'],
        attributes: [
            {
                id: 'min',
                label: 'Minimum',
                unit: '\u00B0C',
                unitLabel: 'Temperature',
                color: '#FF8F00'
            },
            {
                id: 'max',
                label: 'Maximum',
                unit: '\u00B0C',
                unitLabel: 'Temperature',
                color: '#708F08'
            },
            {
                id: 'avg',
                label: 'Average',
                unit: '\u00B0C',
                unitLabel: 'Temperature',
                color: '#66686A'
            },
        ]
    },
    {
        id: 'soil_temp',
        label: 'Soil Temperature',
        sensorIds: ['tsoi'],
        intervalIds: ['hourly', 'daily', 'monthly'],
        attributes: [
            {
                id: 'min',
                label: 'Minimum',
                unit: '\u00B0C',
                unitLabel: 'Temperature',
                color: '#FF8F00'
            },
            {
                id: 'max',
                label: 'Maximum',
                unit: '\u00B0C',
                unitLabel: 'Temperature',
                color: '#708F08'
            },
            {
                id: 'avg',
                label: 'Average',
                unit: '\u00B0C',
                unitLabel: 'Temperature',
                color: '#66686A'
            },
        ]
    },
    {
        id: 'chill_units',
        label: 'Chill Units',
        sensorIds: ['tair'],
        intervalIds: ['hourly', 'daily', 'monthly'],
        attributes: [
            {
                id: 'ica',
                label: 'CU Accumulation (Infruitec/PCU)',
                unit: 'CU',
                unitLabel: 'Chill Units',
                color: '#FF8F00'
            },
            {
                id: 'rca',
                label: 'CU Accumulation (Utah,Richardson)',
                unit: 'CU',
                unitLabel: 'Chill Units',
                color: '#708F08'
            },
            // {
            //     id: 'icu',
            //     label: 'CU (Infruitec/PCU)',
            //     unit: 'CU',
            //     unitLabel: 'Chill Units',
            //     color: '#673AB7'
            // },
            // {
            //     id: 'rcu',
            //     label: 'CU (Utah,Richardson)',
            //     unit: 'CU',
            //     unitLabel: 'Chill Units',
            //     color: '#2196F3'
            // },
        ]
    },
    {
        id: 'chill_portions',
        label: 'Dynamic Chilling Portions',
        sensorIds: ['tair'],
        intervalIds: ['hourly'],
        attributes: [
            {
                id: 'dcp',
                label: 'Dynamic Chilling Portions (Erez,Fishman)',
                unit: 'CP',
                unitLabel: 'Chill Portions',
                color: '#FF8F00'
            },
        ]
    },
    {
        id: 'heat_units',
        label: 'Growing Degree Days [10 °C]',
        sensorIds: ['tair'],
        intervalIds: ['daily'],
        attributes: [
            {
                id: 'gda',
                label: 'Growing Degree Days Accumulation [10 °C]',
                unit: 'GDD',
                unitLabel: 'Growing Degree Days',
                color: '#708F08'
            },
        ]
    },
    {
        id: 'heat_units_2',
        label: 'Growing Degree Days [4.5 °C]',
        sensorIds: ['tair'],
        intervalIds: ['daily'],
        attributes: [
            {
                id: 'gda',
                label: 'Growing Degree Days Accumulation [4.5 °C]',
                unit: 'GDD',
                unitLabel: 'Growing Degree Days',
                color: '#708F08'
            },
        ]
    },
    {
        id: 'gdh_1',
        label: 'Growing Degree Hours [10 °C]',
        sensorIds: ['tair'],
        intervalIds: ['hourly'],
        attributes: [
            {
                id: 'gda',
                label: 'Growing Degree Hours Accumulation [10 °C]',
                unit: 'GDH',
                unitLabel: 'Growing Degree Hours',
                color: '#708F08'
            },
        ]
    },
    {
        id: 'gdh_2',
        label: 'Growing Degree Hours [4.5 °C]',
        sensorIds: ['tair'],
        intervalIds: ['hourly'],
        attributes: [
            {
                id: 'gda',
                label: 'Growing Degree Hours Accumulation [4.5 °C]',
                unit: 'GDH',
                unitLabel: 'Growing Degree Hours',
                color: '#708F08'
            },
        ]
    },
    {
        id: 'cum_solar',
        label: 'Cumulative Solar Radiation',
        sensorIds: ['tair'],
        intervalIds: ['daily', 'monthly'],
        attributes: [
            {
                id: 'cs',
                label: 'Solar Radiation Accumulation',
                unit: 'W/m\u00B2',
                unitLabel: 'Watts per meter squared',
                color: '#FF9900'
            },
        ]
    },
    {
        id: 'humidity_rh',
        label: 'Relative Humidity',
        sensorIds: ['hurh'],
        intervalIds: ['hourly', 'daily', 'monthly'],
        attributes: [
            {
                id: 'min',
                label: 'Minimum',
                unit: '%',
                unitLabel: 'Humidity',
                color: '#FF8F00'
            },
            {
                id: 'max',
                label: 'Maximum',
                unit: '%',
                unitLabel: 'Humidity',
                color: '#708F08'
            },
            {
                id: 'avg',
                label: 'Average',
                unit: '%',
                unitLabel: 'Humidity',
                color: '#66686A'
            },
        ]
    },
];