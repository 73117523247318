export interface ReportTheme {
    id: string;
    label: string;

    sectionTitleBackground: string;
    sectionTitleColor: string;
    subsectionTitleBackground: string;
    subsectionTitleColor: string;
    borderColor: string;

    harvestColors: string[][];
    plantColors: string[][];
}

export const COLORS = {
    red: [
        "#B71C1C", // 900
        "#C62828", // 800
        "#D32F2F", // 700
        "#E53935", // 600
        "#F44336", // 500
        "#EF5350", // 400
        "#E57373", // 300
        "#EF9A9A", // 200
        "#FFCDD2", // 100
    ],
    pink: [
        "#880E4F", // 900
        "#AD1457", // 800
        "#C2185B", // 700
        "#D81B60", // 600
        "#E91E63", // 500
        "#EC407A", // 400
        "#F06292", // 300
        "#F48FB1", // 200
        "#F8BBD0", // 100
    ],
    purple: [
        "#4A148C", // 900
        "#6A1B9A", // 800
        "#7B1FA2", // 700
        "#8E24AA", // 600
        "#9C27B0", // 500
        "#AB47BC", // 400
        "#BA68C8", // 300
        "#CE93D8", // 200
        "#E1BEE7", // 100
    ],
    deep_purple: [
        "#311B92", // 900
        "#4527A0", // 800
        "#512DA8", // 700
        "#5E35B1", // 600
        "#673AB7", // 500
        "#7E57C2", // 400
        "#9575CD", // 300
        "#B39DDB", // 200
        "#D1C4E9", // 100
    ],
    indigo: [
        "#1A237E", // 900
        "#283593", // 800
        "#303F9F", // 700
        "#3949AB", // 600
        "#3F51B5", // 500
        "#5C6BC0", // 400
        "#7986CB", // 300
        "#9FA8DA", // 200
        "#C5CAE9", // 100
    ],
    blue: [
        "#0D47A1", // 900
        "#1565C0", // 800
        "#1976D2", // 700
        "#1E88E5", // 600
        "#2196F3", // 500
        "#42A5F5", // 400
        "#64B5F6", // 300
        "#90CAF9", // 200
        "#BBDEFB", // 100
    ],
    light_blue: [
        "#01579B", // 900
        "#0277BD", // 800
        "#0288D1", // 700
        "#039BE5", // 600
        "#03A9F4", // 500
        "#29B6F6", // 400
        "#4FC3F7", // 300
        "#81D4FA", // 200
        "#B3E5FC", // 100
    ],
    cyan: [
        "#006064", // 900
        "#00838F", // 800
        "#0097A7", // 700
        "#00ACC1", // 600
        "#00BCD4", // 500
        "#26C6DA", // 400
        "#4DD0E1", // 300
        "#80DEEA", // 200
        "#B2EBF2", // 100
    ],
    teal: [
        "#004D40", // 900
        "#00695C", // 800
        "#00796B", // 700
        "#00897B", // 600
        "#009688", // 500
        "#26A69A", // 400
        "#4DB6AC", // 300
        "#80CBC4", // 200
        "#B2DFDB", // 100
    ],
    green: [
        "#1B5E20", // 900
        "#2E7D32", // 800
        "#388E3C", // 700
        "#43A047", // 600
        "#4CAF50", // 500
        "#66BB6A", // 400
        "#81C784", // 300
        "#A5D6A7", // 200
        "#C8E6C9", // 100
    ],
    light_green: [
        "#33691E", // 900
        "#558B2F", // 800
        "#689F38", // 700
        "#7CB342", // 600
        "#8BC34A", // 500
        "#9CCC65", // 400
        "#AED581", // 300
        "#C5E1A5", // 200
        "#DCEDC8", // 100
    ],
    lime: [
        "#827717", // 900
        "#9E9D24", // 800
        "#AFB42B", // 700
        "#C0CA33", // 600
        "#CDDC39", // 500
        "#D4E157", // 400
        "#DCE775", // 300
        "#E6EE9C", // 200
        "#F0F4C3", // 100
    ],
    yellow: [
        "#F57F17", // 900
        "#F9A825", // 800
        "#FBC02D", // 700
        "#FDD835", // 600
        "#FFEB3B", // 500
        "#FFEE58", // 400
        "#FFF176", // 300
        "#FFF59D", // 200
        "#FFF9C4", // 100
    ],
    amber: [
        "#FF6F00", // 900
        "#FF8F00", // 800
        "#FFA000", // 700
        "#FFB300", // 600
        "#FFC107", // 500
        "#FFCA28", // 400
        "#FFD54F", // 300
        "#FFE082", // 200
        "#FFECB3", // 100
    ],
    orange: [
        "#E65100", // 900
        "#EF6C00", // 800
        "#F57C00", // 700
        "#FB8C00", // 600
        "#FF9800", // 500
        "#FFA726", // 400
        "#FFB74D", // 300
        "#FFCC80", // 200
        "#FFE0B2", // 100
    ],
    deep_orange: [
        "#BF360C", // 900
        "#D84315", // 800
        "#E64A19", // 700
        "#F4511E", // 600
        "#FF5722", // 500
        "#FF7043", // 400
        "#FF8A65", // 300
        "#FFAB91", // 200
        "#FFCCBC", // 100
    ],
    brown: [
        "#3E2723", // 900
        "#4E342E", // 800
        "#5D4037", // 700
        "#6D4C41", // 600
        "#795548", // 500
        "#8D6E63", // 400
        "#A1887F", // 300
        "#BCAAA4", // 200
        "#D7CCC8", // 100
    ],
    gray: [
        "#212121", // 900
        "#424242", // 800
        "#616161", // 700
        "#757575", // 600
        "#9E9E9E", // 500
        "#BDBDBD", // 400
        "#E0E0E0", // 300
        "#EEEEEE", // 200
        "#F5F5F5", // 100
    ],
    blue_gray: [
        "#CFD8DC", // 100
        "#B0BEC5", // 200
        "#90A4AE", // 300
        "#78909C", // 400
        "#607D8B", // 500
        "#546E7A", // 600
        "#455A64", // 700
        "#37474F", // 800
        "#263238", // 900
    ],
    provar_gray: [ // base: #808285
        "#333435",
        "#4C4E4F",
        "#66686A",
        "#808285", // b
        "#999B9D",
        "#B2B4B5",
        "#CCCDCE",
        "#E5E6E6",
        "#F2F3F3",
    ],
    provar_green: [ // base: #8cb30b
        "#384804",
        "#546B06",
        "#708F08",
        "#8CB30B", // b
        "#A3C23B",
        "#BAD16C",
        "#DCE8B5",
        "#D1E09D",
        "#E8EFCE",
    ],
};

export const REPORT_THEMES: ReportTheme[] = [
    {
        id: "provar",
        label: 'Pro Theme',
        sectionTitleBackground: COLORS.provar_green[2],
        sectionTitleColor: "#ffffff",
        subsectionTitleBackground: COLORS.provar_green[2],
        subsectionTitleColor: "#ffffff",
        borderColor: "#ddd",
        harvestColors: [
            COLORS.provar_green,
            COLORS.provar_gray,
            COLORS.deep_purple,
            COLORS.blue,
            COLORS.cyan,
        ],
        plantColors: [
            COLORS.pink,
            COLORS.deep_orange,
            COLORS.amber,
            COLORS.lime,
            COLORS.gray,
        ]
    },
    {
        id: "canon",
        label: 'Canon',
        sectionTitleBackground: COLORS.blue_gray[2],
        sectionTitleColor: "#ffffff",
        subsectionTitleBackground: COLORS.blue_gray[2],
        subsectionTitleColor: "#ffffff",
        borderColor: "#ddd",
        harvestColors: [
            COLORS.orange,
            COLORS.red,
            COLORS.yellow,
            COLORS.brown,
            COLORS.blue_gray,
        ],
        plantColors: [
            COLORS.light_green,
            COLORS.teal,
            COLORS.light_blue,
            COLORS.indigo,
            COLORS.purple,
        ]
    },
];

export class ReportThemeCompiler {

    constructor(private _options: ReportTheme) {}

    getSeriesShade(sampleType: string, sampleIndex: number, evalIndex: number): string {

        let colors: string[][] = [['#000000']];

        if (sampleType === 'harvest') {
            colors = this._options.harvestColors;
        } else {
            colors = this._options.plantColors;
        }

        const ci = colors[sampleIndex % colors.length];
        return ci[(evalIndex*2) % ci.length];
    }
}
