import { Calculation } from './_types';

export const LIB_CALCULATIONS_FRUIT_HARVEST: Calculation[] = [

        //Calculated values
    {
        id: "sugar_acid_ratio_calc",
        unit: "%",
        label: "Sugar Malic:Acid Ratio",
        tag: "SAR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_sugar_acid_ratio",
        format: "####%",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit_calculated",
    },
    {
        id: "citric_sugar_acid_ratio_calc",
        unit: "%",
        label: "Sugar Citric:Acid Ratio",
        tag: "CAR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_citric_sugar_acid_ratio",
        format: "####%",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit_calculated",
    },
    {
        id: "seed_count_percentage",
        unit: "%",
        label: "Seed Count Percentage",
        tag: "CAR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_seed_count_percentage",
        format: "####%",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit_calculated",
    },
    {
        id: "bunch_sugar_acid_ratio_calc",
        unit: "%",
        label: "Sugar:Acid Ratio (Bunch)",
        tag: "BSAR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_bunch_sugar_acid_ratio",
        format: "####%",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit_calculated",
    },
];