<ng-container>
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Upload Letterheads</h2>
    </div>

    <div mat-dialog-content>
        <form id="letterhead_form"
            [formGroup]="formGroup"
            (ngSubmit)="attempt()"
            autocomplete="disabled"
            #letterheadForm="ngForm"
            novalidate>

            <div class="letterhead-form-container" formArrayName="letterheads">

                <div class="letterhead-file-dropbox"
                     (drop)="onDrop($event)"
                     (dragover)="onDragOver($event)"
                     (dragleave)="onDragLeave($event)"
                     [class.dragging]="isDragging">

                    <div class="letterhead-drop-request">
                        <p>Drag and drop image files here to upload.</p>
                        <label for="letterhead_file_input">Browse Files</label>
                        <input id="letterhead_file_input" (change)="fileInputChanged($event)" type="file">
                        <div class="document-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>
                    </div>

                    <div class="letterhead-drop-results" *ngIf="uploads$ | async as uploads">
                        <div class="document-upload" *ngFor="let upload of uploads; trackBy: trackUpload">
                            <div [ngSwitch]="upload.status">
                                {{ upload.loaded | formatBytes }}/{{ upload.total | formatBytes }}
                                <span *ngSwitchCase="'LOADING'">Uploading...</span>
                                <span *ngSwitchCase="'COMPLETE'" class="text-primary">Done</span>
                                <span *ngSwitchDefault class="text-warn">{{ upload.status | descStatusTitle }}</span>
                            </div>
                            <mat-progress-bar mode="determinate" [value]="upload.loaded / upload.total * 100"></mat-progress-bar>
                        </div>
                    </div>
                </div>

                <div class="letterhead-container">
                    <div class="letterhead-form"
                        *ngFor="let letterheadGroup of formArray.controls; index as i"
                        [formGroupName]="i">

                        <!-- Image preview -->
                        <pv-image-ref class="letterhead-form-file"
                            *ngIf="letterheadGroup.value.fileRef as ref"
                            [fileRef]="ref"
                            [transformId]="c_fit"
                            [height]="250">
                        </pv-image-ref>

                        <!-- Actions -->
                        <div class="actions">
                            <button type="button"
                                    class="letterhead-download-button"
                                    mat-icon-button
                                    (click)="downloadLetterhead(letterheadGroup.value.fileRef, letterheadGroup.value.name)"
                                    matTooltip="Download Letterhead">
                                <mat-icon>cloud_download</mat-icon>
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        (cancel)="cancel()"
        [form]="letterheadForm">
    </pv-form-actions>
</ng-container>