import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HostNavigator } from '@core/browser';
import { Snackbar } from '@core/material';
import { Subject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';
import { NumberValidators } from '@core/utils';

export interface PrintEvaluationFormDialogData {
    evalKey: string;
    includeNotes?: boolean;
    includeMeasures?: boolean;
    includeChars?: boolean;
    measuresCount?: 20;
}

@Component({
    templateUrl: 'print-evaluation-form.dialog.html'
})
export class PrintEvaluationFormDialog implements OnInit, OnDestroy{

    includeMeasuresControl = new FormControl(true);
    measuresCountControl = new FormControl(20, [Validators.min(1), Validators.max(999), NumberValidators.integer(3)]);

    formGroup = new FormGroup({
        includeNotes: new FormControl(true),
        includeMeasures: this.includeMeasuresControl,
        includeChars: new FormControl(true),
        measuresCount: this.measuresCountControl,
    });

    private _destroy$ = new Subject();

    constructor(
        private _dialogRef: MatDialogRef<PrintEvaluationFormDialog>,
        @Inject(MAT_DIALOG_DATA) public data: PrintEvaluationFormDialogData,
        private _navigator: HostNavigator,
        private _snackbar: Snackbar
    ) {}

    ngOnInit(){
        this.formGroup.reset({
            includeNotes: "includeNotes" in this.data ? this.data.includeNotes : true,
            includeMeasures: "includeMeasures" in this.data ? this.data.includeMeasures : true,
            includeChars: "includeChars" in this.data ? this.data.includeChars : true,
            measuresCount: "measuresCount" in this.data ? this.data.measuresCount : 20,
        });

        this.includeMeasuresControl
            .valueChanges
            .pipe(
                takeUntil(this._destroy$), 
                startWith(this.formGroup.value.includeMeasures)
            )
            .subscribe(include => {
                if(include){
                    this.measuresCountControl.enable();
                }else{
                    this.measuresCountControl.disable();
                }
            });

    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    confirm(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.valid){

            let form = this.formGroup.value;
            let sections = [];
            let params:any = {};

            if(form.includeChars) sections.push('chars');
            if(form.includeNotes) sections.push('notes');

            if(form.includeMeasures){
                sections.push('measures');
                params.measuresCount = form.measuresCount ? form.measuresCount : 20
            }

            params.sections = sections.join(','),

            this._navigator.openWindow(`evaluation-form/${this.data.evalKey}`, params);

            this._snackbar.error('Form opening in new window.');
            this._dialogRef.close();
        }else{
            this._snackbar.error('Check your input and try again.');
        }

    }

    cancel(){
        this._dialogRef.close(null);
    }

}
