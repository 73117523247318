<div class="form-status">

    <div class="status-icon" [ngSwitch]="status">
        <ng-container *ngSwitchCase="'UNINITIALIZED'"></ng-container>
        <mat-spinner *ngSwitchCase="'LOADING'" diameter="24" color="accent"></mat-spinner>
        <ng-container *ngSwitchCase="'OK'">
            <mat-icon *ngIf="submitted && invalid" color="warn">assignment_late</mat-icon>
            <mat-icon *ngIf="submitted && !invalid" color="primary">done</mat-icon>
        </ng-container>
        <mat-icon *ngSwitchCase="'COMPLETE'" color="primary">done_outline</mat-icon>
        <mat-icon *ngSwitchCase="'NOT_FOUND'" color="warn">help</mat-icon>
        <mat-icon *ngSwitchCase="'INVALID'" color="warn">assignment_late</mat-icon>
        <mat-icon *ngSwitchCase="'UNAUTHENTICATED'" color="warn">security</mat-icon>
        <mat-icon *ngSwitchCase="'DISCONNECTED'" color="warn">offline_bolt</mat-icon>
        <mat-icon *ngSwitchDefault color="warn">error</mat-icon>
    </div>

    <div class="form-status-messages" [ngSwitch]="status">
        <div *ngSwitchCase="'UNINITIALIZED'" class="status-message"></div>
        <div *ngSwitchCase="'LOADING'" class="status-title text-accent">Loading</div>
        <div *ngSwitchCase="'COMPLETE'" class="status-title text-primary">Complete</div>
        <ng-container *ngSwitchCase="'OK'" class="status-message">
            <ng-container *ngIf="submitted && invalid">
                <div class="status-title text-warn">Form Invalid</div>
                <div class="status-message text-warn">Some field(s) are invalid.</div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="status-title text-warn">{{ status | descStatusTitle }}</div>
            <div class="status-message text-warn">{{ status | descStatusMessage }}</div>
        </ng-container>
        <ng-content select="[pvFormStatus]"></ng-content>
    </div>

</div>
<ng-content></ng-content>
<div class="form-actions">

    <button mat-flat-button
            class="form-action"
            type="button"
            (click)="cancel($event)">
        Cancel
    </button>

    <!-- Project [pvFormAction] -->
    <ng-content select="[pvFormAction]"></ng-content>

    <button mat-raised-button
            class="form-action"
            color="primary"
            type="submit"
            [attr.form]="_formId"
            [disabled]="disabled || status === 'LOADING'"
            (click)="submit($event)"
            [class.tastings-actions-theme]="tastingsEnabled">
        {{ saveAction }}
    </button>
</div>
