import { Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReportRemark } from '@core/data';


@Component({
    selector: 'pv-report-remark-form-dialog',
    templateUrl: 'report-remark-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-report-remark-form-dialog pv-fullscreen-dialog'
    },
    styles: [`
        .mat-dialog-content {
            display: flex;
            flex-flow: row nowrap;
        }

        .markdown-input {
            flex: 1 1 50%;
            height: 100%;
            padding: 12px;
            box-sizing: border-box;
        }

        .markdown-input textarea {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            resize: none;
            font-size: 12px;
        }

        .markdown-preview {
            flex: 1 1 50%;
            border-left: 1px solid rgba(0,0,0,0.3);
            height: 100%;
            padding: 12px;
            box-sizing: border-box;
            overflow-y: auto;
        }
    `]
})
export class ReportRemarkFormDialog {

    sectionId: string;
    remarkControl = new FormControl('', [Validators.maxLength(10000)]);
    showMarkdown = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportRemark,
        private _dialogRef: MatDialogRef<ReportRemarkFormDialog>
    ){
        this.sectionId = _data.sectionId;
        this.remarkControl.reset(_data.text);
    }

    submit(){
        this._dialogRef.close({ ...this._data, text: this.remarkControl.value });
    }

}