import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Snackbar } from '@core/material';
import * as moment from 'moment';
import { Evaluation, Status, Sample } from '@core/data';
import { Select, Store } from '@ngxs/store';
import { EvaluationFormState, EvaluationFormStateModel, InitEvaluationForm, SubmitEvaluationForm } from './evaluation-form.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { coerseDateProperty } from '@core/utils';

export interface EvaluationCompleteFormDialogData {
    key: string;
}

@Component({
    selector: 'pv-evaluation-complete-dialog',
    templateUrl: './evaluation-complete-form.dialog.html',
})
export class EvaluationCompleteFormDialog implements OnInit, OnDestroy {

    @Select(EvaluationFormState)
    state$: Observable<EvaluationFormStateModel>;

    endDateControl: FormControl = new FormControl(null, [Validators.required]);

    formGroup = new FormGroup({
        evalEndDate: this.endDateControl,
    });

    minDate: moment.Moment = null;
    startDate: moment.Moment = null;

    private _destroy$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: EvaluationCompleteFormDialogData,
        public _dialogRef: MatDialogRef<EvaluationCompleteFormDialog>,
        private _snackbar: Snackbar,
        private _store: Store
    ) {}


    ngOnInit() {

        this._store.dispatch(new InitEvaluationForm(this._data.key));

        this.state$
        .pipe(takeUntil(this._destroy$))
        .subscribe(state => {

            if (state.status === Status.COMPLETE) {
                this._snackbar.message(`Updated evaluation '${state.data.label}'`);
                this._dialogRef.close();
            }

            if (state.status !== Status.LOADING && state.data) {
                this.reset(state.data, state.sample);
                this.formGroup.enable();
            } else {
                this.formGroup.disable();
            }
        });
    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    reset(data: Partial<Evaluation>, sample: Sample){

        this.minDate = moment(data.evalStartDate).add(1, 'days');
        this.startDate = moment(data.evalStartDate);

        this.formGroup.reset({
            evalEndDate: data.evalEndDate || moment(this.startDate).add(1, 'days')
        });


    }

    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.valid){

            let data = {
                evalEndDate: coerseDateProperty(this.endDateControl.value)
            };

            this._store.dispatch(new SubmitEvaluationForm(data));
        }else{
            this._snackbar.error('Enter a valid end date after the start date');
        }
    }


    cancel() {
        this._dialogRef.close(false);
    }
}