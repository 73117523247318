import { SampleDataNode } from './report-sample-data-node';
import { SelectionModel } from '@angular/cdk/collections';
import { ReportSampleTreeControl } from './report-sample-tree-control';





export class ReportSampleTreeSelection extends SelectionModel<string>{

    allEvalsSelected: boolean = null;
    allImagesSelected: boolean = null;
    allNotesSelected: boolean = null;

    constructor(){
        super(true);
    }

    selectNode(node: SampleDataNode, select: boolean){

        let isSelected = this.isSelected(node.key);
        let keys = this.getNodeWithDescendents(node).map(n => n.key);

        if(select && !isSelected){
            this.select(...keys);
        }else if(!select && isSelected){
            this.deselect(...keys);
        }

    }

    selectAllNodes(tree: ReportSampleTreeControl, select: boolean){
        tree.dataNodes.forEach(n => this.selectNode(n, select));
    }

    isAllSampleNodesSelected(tree: ReportSampleTreeControl){
       let anyChecked = false;

        if (!tree.dataNodes) return anyChecked;

        tree.dataNodes.forEach(n => {
            if (n.type == 'sample') {
                if(this.isNodeSelected(n)) anyChecked = true;
            }
        });

        return anyChecked;
    }

    isAllSampleNodesEvaluationsSelected(tree: ReportSampleTreeControl): boolean {
        let evaluation: SampleDataNode[] = [];

        tree.dataNodes.forEach(node => {
            if (node.children) {
                evaluation.push(...node.children.filter(child => child.type === 'eval'));
            }
        });

        // If there are no evaluations, return false (disables Notes and Images)
        if (evaluation.length === 0) {
            return false;
        }

        // Check if all evaluations are selected
        for (let evalu of evaluation) {
            if (!this.isNodeSelected(evalu)) {
                return false;
            }
        }

        return true;
    }

    isAnySampleNodesEvaluationsSelected(tree: ReportSampleTreeControl): boolean {
        let evaluation: SampleDataNode[] = [];

        tree.dataNodes.forEach(node => {
            if (node.children) {
                evaluation.push(...node.children.filter(child => child.type === 'eval'));
            }
        });

        // Return true if at least one evaluation is selected
        for (let evalu of evaluation) {
            if (this.isNodeSelected(evalu)) {
                return true;
            }
        }

        // If no evaluations are selected, return false
        return false;
    }

    isAllSampleNodesChildrenSelected(tree: ReportSampleTreeControl, type: 'images' | 'notes'): boolean | undefined {
        if (type === 'images' && this.allImagesSelected !== null) return this.allImagesSelected;
        if (type === 'notes' && this.allNotesSelected !== null) return this.allNotesSelected;

        let evaluation: SampleDataNode[] = [];

        tree.dataNodes.forEach(node => {
            if (node.children) {
                evaluation.push(...node.children.filter(child => child.type === 'eval'));
            }
        });

        // Return true if at least one evaluation is selected
        for (let evalu of evaluation) {
            if (this.isNodeSelected(evalu)) {
                if (type === 'images') this.allImagesSelected = true;
                if (type === 'notes') this.allNotesSelected = true;
                return true;
            }
        }
    }
    isNodeSelected(node: SampleDataNode) {
        return this.isSelected(node.key);
    }

    isSelectable(node: SampleDataNode){
        return node.parent === null || this.isSelected(node.parent.key);
    }

    private getNodeWithDescendents(node: SampleDataNode){
        const nodes = [];
        this.getDescendants(nodes, node);
        return nodes;
    }

    private getDescendants(descendants: SampleDataNode[], node: SampleDataNode): void {
        descendants.push(node);
        if (Array.isArray(node.children)) {
            node.children.forEach((child: SampleDataNode) => this.getDescendants(descendants, child));
        }
    }

    selectAllEvaluations(tree: ReportSampleTreeControl, isChecked: boolean) {
        this.setAllEvalsSelected(null);

        let evaluations: SampleDataNode[] = [];
        tree.dataNodes.map(node => evaluations.push(...node.children || null));
        evaluations = evaluations.filter(child => child.type === 'eval');

        evaluations.forEach(evalu => {
            this.selectNode(evalu, isChecked);
        })
    }

    selectAllImages(tree: ReportSampleTreeControl, isChecked: boolean){
        this.setAllImagesSelected(null);

        let children: SampleDataNode[] = [];
        let images: SampleDataNode[] = [];

        tree.dataNodes.map(node => children.push(...node.children || null));
        children.map(child => images.push(...child.children || null));
        images = images.filter(image => image.type === 'image');

        images.forEach(image => {
            this.selectNode(image, isChecked);
        });
    }

    selectAllNotes(tree: ReportSampleTreeControl, isChecked: boolean){
        this.setAllNotesSelected(null);

        let children: SampleDataNode[] = [];
        let notes: SampleDataNode[] = [];

        tree.dataNodes.map(node => children.push(...node.children || null));
        children.map(child => notes.push(...child.children || null));
        notes = notes.filter(note => note.type === 'note');

        notes.forEach(note => {
            this.selectNode(note, isChecked);
        });
    }

    setAllEvalsSelected(value: boolean) {
        this.allEvalsSelected = value;
    }

    setAllImagesSelected(value: boolean) {
        this.allImagesSelected = value;
    }

    setAllNotesSelected(value: boolean) {
        this.allNotesSelected = value;
    }

}