import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HostNavigator } from '@core/browser';
import { Status } from '@core/data';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthState, AuthStateModel, ConsumeAuthToken, InitAuthState, LoadAuthUser, LogoutAuthUser, SetAuthIntendedUrl } from '../../auth-state';

@Component({
    selector: 'pv-auth-login-view',
    templateUrl: 'auth-login.view.html',
    host: {
        class: 'pv-flex-view bg-primary',
    }
})
export class AuthLoginView implements OnInit, OnDestroy {

    @Select(AuthState)
    state$: Observable<AuthStateModel>;

    private _destroy$ = new Subject();

    returnUrl: string = null;

    constructor(
        private _store: Store,
        private _hostNav: HostNavigator,
        private _route: ActivatedRoute,
        private _router: Router) { }

    ngOnInit() {

        this._route.queryParamMap.subscribe(params => {

            if (params.has('returnTo')) {
                this._store.dispatch(new SetAuthIntendedUrl(params.get('returnTo')));
            }

            if (params.has('token')) {
                let token = params.get('token');
                this._store.dispatch(new ConsumeAuthToken(token));
            }
        });

        this.state$
            .pipe(
                takeUntil(this._destroy$),
            )
            .subscribe(state => {
                if (state.status === Status.OK) {
                    if(state.intendedUrl && state.intendedUrl.startsWith('/')){
                        this._router.navigateByUrl(state.intendedUrl);
                    }else{
                        this._router.navigate(['/']);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    login(): void {
        this._hostNav.redirect('login');
    }

    logout() {
        this._store.dispatch(new LogoutAuthUser());
    }

    reloadUser() {
        this._store.dispatch(new LoadAuthUser());
    }

    retryAuthorization() {
        this._store.dispatch(new InitAuthState());
    }

    checkAuthorization() {
        this._store.dispatch(new LoadAuthUser());
    }
}