import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ImageAttachmentUpdateRequest, ReportImage, ReportRemark, ReportService, Status, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

export interface ImageAttachmentFormStateModel {
    key: string;
    sectionId: string;
    status: Status
    data: ReportRemark;
    uploads: PendingReportImageUploadModel[];
}

export interface PendingReportImageUploadModel {
    id: number;
    loaded: number;
    total: number;
    status: Status;
    name: string;
    data: ReportImage;
}

export class InitImageAttachmentForm {
    static readonly type = "[ImageAttachmentForm] Init";
    constructor(public reportKey: string, public sectionId: string) {}
}

export class SubmitImageAttachmentForm {
    static readonly type = "[ImageAttachmentForm] Submit";
    constructor(public payload: ImageAttachmentUpdateRequest) {}
}

export class UploadImageAttachment {
    static readonly type = "[FileAttachementForm] Upload";
    constructor(public file: File, public sectionId: string) {}
}

@State({
    name: 'image_attachment_form',
    defaults: {
        key: null,
        sectionId: null,
        status: Status.UNINITIALIZED,
        data: null,
        uploads: [],
    }
})
@Injectable()
export class ImageAttachmentFormState {
    @Selector()
    static data(state: ImageAttachmentFormStateModel) { return state.data; }

    @Selector()
    static uploads(state: ImageAttachmentFormStateModel) { return state.uploads; }

    constructor(
        private _reportService: ReportService
    ) {}

    @Action(InitImageAttachmentForm)
    initImageAttachmentForm(ctx: StateContext<ImageAttachmentFormStateModel>, action: InitImageAttachmentForm) {
        ctx.setState({
            key: action.reportKey,
            sectionId: action.sectionId,
            status: Status.UNAUTHENTICATED,
            data: null,
            uploads: [],
        })

        return this._reportService
            .getRemark(action.reportKey, action.sectionId, {related: ['images']})
            .pipe(
                tap(
                    result => {
                        if (!Array.isArray(result)) {
                            ctx.patchState({
                                data: result,
                                status: Status.OK
                            });
                        }else {
                            ctx.patchState({
                                data: {
                                    sectionId: action.sectionId,
                                    text: '',
                                    images: [],
                                },
                                status: Status.OK
                            });
                        }
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }

    @Action(SubmitImageAttachmentForm)
    submitImageAttachmentForm(ctx: StateContext<ImageAttachmentFormStateModel>, action: SubmitImageAttachmentForm) {
        const state = ctx.getState();

        const updatedImages = action.payload.imageOptions.map((image, index) => ({
            ...image,
            position:index
        }));

        const updatedPayload: ImageAttachmentUpdateRequest = {
            ...action.payload,
            imageOptions: [
                ...updatedImages
            ],
        }

        return this._reportService.updateRemarkImageOptions(state.key, action.payload.sectionId, updatedPayload)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE,
                            data: {
                                ...result,
                            }
                        })
                    },error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }

    @Action(UploadImageAttachment)
    uploadImageAttachment(ctx: StateContext<ImageAttachmentFormStateModel>, action: UploadImageAttachment) {
        const state = ctx.getState();

        let upload: PendingReportImageUploadModel = {
            id: Math.round(Math.random() * 10000000),
            loaded: 0,
            total: action.file.size,
            status: Status.LOADING,
            name: action.file.name,
            data: null,
        }

        ctx.patchState({
            uploads: [...state.uploads, upload],
        })

        return this._reportService.uploadSectionImages(state.key, action.sectionId, action.file)
            .pipe(
                tap(
                    event => {
                        const state = ctx.getState();

                        if (state.status !== Status.OK) return;

                        if (event.type === HttpEventType.UploadProgress) {
                            upload = {
                                ...upload,
                                loaded: event.loaded,
                                total: event.total,
                            }

                            this.patchUpload(ctx, upload);
                        }else if (event instanceof HttpResponse) {
                            upload = {
                                ...upload,
                                status: Status.COMPLETE,
                                data: event.body
                            }

                            this.patchUpload(ctx, upload);
                        }
                    },
                    error => {
                        console.warn('ImageAttachmentFormState: Upload Error', error);

                        upload = {
                            ...upload,
                            status: translateCommonErrorStatus(error)
                        }

                        this.patchUpload(ctx, upload);
                    }
                ), catchError(e => of(null))
            );
    }

    private patchUpload(ctx: StateContext<ImageAttachmentFormStateModel>, upload: PendingReportImageUploadModel) {
        const state = ctx.getState();

        let uploads = state.uploads.map(existing => {
            if (existing.id === upload.id) return upload;
            return existing;
        });

        ctx.patchState({ uploads });
    }
}