<ng-container>
	<!-- Title -->
	<div mat-dialog-title class="mat-dialog-header">
		<h2 class="dialog-title">Evaluation Bulk Action</h2>
	</div>
	<div mat-dialog-content>
		<div class="pv-form">
			<!-- Evaluation List -->
			<div class="form-column" *ngIf="evaluations$ | async as evaluations; else evaluLoader">
				<h4 class="form-subheader">Evaluations</h4>
				<mat-list dense>
					<h3 matSubheader>Total Evaluations: <span>{{ evaluations.length }}</span></h3>
					<mat-list-item *ngFor="let evalu of evaluations">
						{{ evalu.label }}
					</mat-list-item>
				</mat-list>
			</div>
			<ng-template #evaluLoader>
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</ng-template>

			<!-- Evaluation Filter Form -->
			<div class="form-column">
				<h4 class="form-subheader">Evaluations Filter</h4>
				<form id="evaluation_filter_form"
					#evaluation_filter_form="ngForm"
					[formGroup]="filterFormGroup"
					(ngSubmit)="updateFilters()"
					autocomplete="off">

					<mat-form-field appearance="standard">
						<mat-label>Evaluation Start Date</mat-label>
						<input matInput formControlName="evalStartDate" [matDatepicker]="startDatePicker" placeholder="YYYY-MM-DD">
						<mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
						<mat-datepicker #startDatePicker></mat-datepicker>
						<mat-hint>Select start date</mat-hint>
					</mat-form-field>

					<mat-form-field>
						<mat-label>Evaluation Size</mat-label>
						<input matInput formControlName="evalSize">
					</mat-form-field>

					<div class="form-actions">
						<button mat-button type="button" color="warn"
								(click)="clearFilters()">
							Clear Filters
						</button>

						<button mat-button type="button" color="primary"
								type="submit">
							Apply Filters
						</button>
					</div>
				</form>
			</div>

			<!-- Evaluation End Date Edit Form -->
			<div class="form-column">
				<h4 class="form-subheader">Evaluations Edit</h4>
				<form id="evaluation_bulk_edit_form"
					#evaluation_bulk_edit_form="ngForm"
					[formGroup]="evaluationEditFormGroup"
					(ngSubmit)="attempt()"
					autocomplete="off">

					<mat-form-field appearance="standard">
						<mat-label>Evaluation End Date</mat-label>
						<input matInput formControlName="evalEndDate" [matDatepicker]="endDatePicker" placeholder="YYYY-MM-DD">
						<mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
						<mat-datepicker #endDatePicker></mat-datepicker>
						<mat-hint>Select end date</mat-hint>
					</mat-form-field>

				</form>
			</div>
		</div>
	</div>
	<pv-form-actions mat-dialog-actions [form]="evaluation_bulk_edit_form" (cancel)="cancel()">
	</pv-form-actions>
</ng-container>
