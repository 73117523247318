import { Protocol } from './_types';

export const LIB_PROTOCOL_ADVANCED_CITRUS_TREE_EVAL: Protocol = {
    id: "citrus_advanced_tree_eval",
    title: "Citrus Advanced Tree Evaluation",
    description: "New Citrus Plant Protocol",
    sampleType: "plant",
    crop: "citrus",

    chars: [
        "date_swollen_bud_citrus",
        "date_balloon_citrus",
        "date_full_bloom_citrus",
        "date_50_petal_citrus",
        "date_90_petal_citrus",
        "date_100_petal_citrus",
        "date_fruit_drop_citrus",
        "date_color_break_citrus",
        "date_harvest_start_citrus",
        "date_harvest_end_citrus",
        "date_winter_pruning",
        "date_fruit_set_application",
        "date_summer_pruning",
        "date_thinning_application",
        "date_gib_application",
        "date_gib_application_two",
        "date_girdling",

        "citrus_tree_shape",
        "citrus_tree_grow_potent",
        "citrus_tree_density",
        "citrus_tree_thorns",
        "citrus_tree_thorn_length",

        "citrus_tree_flower_dense",
        "citrus_tree_flower_events",
        "citrus_tree_leaf_inflorescence",
        "citrus_tree_bear_alternate",

        "citrus_tree_fruit_set_manip",
        "citrus_tree_fruit_set",
        "citrus_tree_fruit_drop",
        "citrus_tree_creasing",
        "citrus_tree_creasing_severity",
        "citrus_tree_split",
        "citrus_tree_split_severity",
        "citrus_tree_disorder_manip",
        "citrus_tree_out_season",
        "citrus_tree_fruit_thin",
        "citrus_tree_thinning_method",
        "citrus_tree_pick_count",
        "citrus_tree_crop_load",

        "citrus_tree_pest_detect",
        "citrus_tree_disease_detect",
        "citrus_tree_granulation",
        "citrus_tree_granulation_severity",
        "citrus_tree_gumming",
        "citrus_tree_gumming_severity",
        "citrus_tree_sunburn_symptom",
        "citrus_tree_sunburn_severity",
        "citrus_tree_alternaria_presence",
        "citrus_tree_alternaria",
        "citrus_tree_variegation_presence",
        "citrus_tree_variegation",
        "citrus_tree_cbs_presence",
        "citrus_tree_cbs",
        "citrus_tree_mal_navels_presence",
        "citrus_tree_malformed_navels",
        "citrus_tree_prot_navels_presence",
        "citrus_tree_protruding_navels",
        "citrus_tree_chimera_presence",
        "citrus_tree_chimera",

        "citrus_tree_flower_span",
        "citrus_tree_uniform_ripe",
        "citrus_tree_overall_product",
        "citrus_tree_precociousness",
        "citrus_tree_fruit_size",
        "citrus_tree_fruit_size_pick",
        "citrus_tree_fruit_color_soft",
        "cit_tree_fruit_color_soft_lvl",
        "citrus_tree_fruit_color_grapef",
        "cit_tree_fruit_color_grapef_lvl",
        "citrus_tree_fruit_color_lemon",
        "cit_tree_fruit_color_lemon_lvl",
        "citrus_tree_overall_adapt",
        "citrus_tree_bud_union",
    ],

    measures: [
        "tree_height",
        "tree_width",
        "tree_fruit_count",
        "tree_total_yield",
        "prod_average",
    ],

    indexes: [],

    calculations: [
        "prod_average",
        "tons_per_ha_calc",
    ]
}