import { Component, ChangeDetectionStrategy, HostBinding, ElementRef, ChangeDetectorRef, Optional, Self } from "@angular/core";
import { ModelAutocompleteComponent } from './model-autocomplete.component';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { Cultivar, CultivarService, DetailRequest, FilterBuilder, Status, translateCommonErrorStatus } from '@core/data';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import { map, catchError, startWith } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';





@Component({
    selector: 'pv-cultivar-autocomplete',
    templateUrl: 'cultivar-autocomplete.component.html',
    styleUrls: [ 'model-autocomplete.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    providers: [{
        provide: MatFormFieldControl,
        useExisting: CultivarAutocompleteComponent
    }]
})
export class CultivarAutocompleteComponent extends ModelAutocompleteComponent<Cultivar> {


    @HostBinding()
    id = `pv-cultivar-autocomplete-${CultivarAutocompleteComponent.nextId++}`;

    constructor(
        private _service: CultivarService,
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeRef: ChangeDetectorRef,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        @Optional() public _parentFormGroup: FormGroupDirective,
        @Optional() public _parentFormField: MatFormField,
    ){
        super(fm, elRef, _changeRef, _defaultErrorStateMatcher, ngControl, _parentForm, _parentFormGroup, _parentFormField);
    }

    displayFn(model: Cultivar){
        if(model) return model.commonName;
        return '';
    }

    getMinHeight(option: Cultivar): string {
        const licenseePresent = option.licensee;
        const onlyLicenseePresent = this.isOnlyLicenseePresent(option);
        const allOtherPropertiesPresent = this.areAllOtherPropertiesPresent(option);

        if (licenseePresent && allOtherPropertiesPresent) {
            return '130px'; // Height when licensee and all other properties are present
        } else if (onlyLicenseePresent) {
            return '70px'; // Height when licensee is the only property present
        } else if (licenseePresent) {
            return '130px'; // Height when licensee is present but any other property is missing
        } else {
            return '100px'; // Default height when licensee is not present
        }
    }

    isOnlyLicenseePresent(option: Cultivar): boolean {
        return option.licensee && !option.rootstockHarvestSamplesCount && !option.rootstockPlantSamplesCount && !option.scionHarvestSamplesCount && !option.scionPlantSamplesCount;
    }

    areAllOtherPropertiesPresent(option: any): boolean {
        return option.rootstockHarvestSamplesCount && option.rootstockPlantSamplesCount && option.scionHarvestSamplesCount && option.scionPlantSamplesCount;
    }

    search(orgKey: string, text: string): Observable<{ status: Status, options: Cultivar[] }> {
        const detail: DetailRequest = {
            counts: [
                'scionPlantSamples',
                'scionHarvestSamples',
                'rootstockPlantSamples',
                'rootstockHarvestSamples',
            ]
        };

        return this._service.query(this.getFilter(orgKey, text), detail)
        .pipe(
            map(result => {
                if (result.total === 0) {
                    return {
                        status: Status.EMPTY,
                        options: []
                    };
                } else {
                    return {
                        status: Status.OK,
                        options: result.data
                    };
                }
            }),
            catchError(error => {
                return throwError({
                    status: translateCommonErrorStatus(error),
                    options: []
                });
            })
        );
    }

    private getFilter(orgKey: string, searchString: string){

        const fb = (new FilterBuilder());

        if(orgKey){
            fb.setQuery('ownerOrgKey', orgKey)
        }

        if(typeof searchString === 'string' && searchString.length > 0){
            if (searchString.trim()) fb.setQuery('$fuzzy', searchString);
        }

        return fb.get();

    }

}