
/**
 * Data Models
 *
 */

export interface CustomProtocol {
    key: string;
    id: string;
    title: string;
    baseProtocolId?: string;
    description: string;
    protocol: boolean;
    filter: boolean;
    archived: boolean;
    ownerOrgKey: string;
    cropId?: string;
    protocolSubset?: ProtocolOptions;
    protocolType?: string;
}

export interface ProtocolOptions {
    columns?: ProtocolColumn[];
}

export enum ProtocolColumnType {
    Property = 'p',
    Characteristic = 'c',
    Measurement = 'm',
    Index = 'i',
}

export interface ProtocolColumn {
    id: string;
    type: ProtocolColumnType;
}
