import { Measurement } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_MEASURES_GRAPES } from './meas_grapes';
import { LIB_MEASURES_FRUT_TREE } from './measures_frut_tree';
import { LIB_MEASURES_FRUT_HARVEST } from './measures_frut_harvest';
import { LIB_MEASURES_CANNABIS } from './measures_cannabis';
import { LIB_MEASURES_NUT_TREE } from './measures_nut_tree';
import { LIB_MEASURES_NUT_HARVEST } from './measures_nut_harvest';
import { LIB_MEASURES_BERRY_HARVEST } from './measures_berry_harvest';
import { LIB_MEASURES_ADVANCED_CITRUS_HARVEST } from './measures_citrus_harvest';

export class MeasurementDictionary extends Dictionary<Measurement>{
    constructor(){
        super([
            ...LIB_MEASURES_FRUT_TREE,
            ...LIB_MEASURES_FRUT_HARVEST,
            ...LIB_MEASURES_GRAPES,
            ...LIB_MEASURES_CANNABIS,
            ...LIB_MEASURES_NUT_TREE,
            ...LIB_MEASURES_NUT_HARVEST,
            ...LIB_MEASURES_BERRY_HARVEST,
            ...LIB_MEASURES_ADVANCED_CITRUS_HARVEST,
        ]);
    }
}

export * from './_types';