import { OrganizationService, OrganizationSubscription, Status, translateCommonErrorStatus } from '@core/data';
import { Action, State, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

export interface OrganizationSubscriptionStateModel {
    status: Status;
    orgKey: string;
    data: OrganizationSubscription[];
}

export class InitOrganizationSubscription {
    static readonly type = "[OrganizationSubscriptionState] Initialize Subscriptions";
    constructor(public orgKey: string) { }
}

export class LoadOrganizationSubscriptions {
    static readonly type = "[OrganizationSubscription] Load Subscriptions";
}

export class DeleteOrgSubscription {
    static readonly type = "[OrgSubscriptionForm] Delete Subscription";
    constructor(public key: string) { }
}

const DEFAULTS: OrganizationSubscriptionStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: null,
    data: [],
}

@State<OrganizationSubscriptionStateModel>({
    name: "organization_subscriptions",
    defaults: DEFAULTS,
})
@Injectable()
export class OrganizationSubscriptionState {

    constructor(
        private _organizationService: OrganizationService,
    ) { }

    /**
     *
     * @param ctx State Context
     * @param action orgKey: of the organization that the subscription belongs to
     */
    @Action(InitOrganizationSubscription)
    initOrganizationSubscription(ctx: StateContext<OrganizationSubscriptionStateModel>, action: InitOrganizationSubscription) {

        ctx.patchState({
            orgKey: action.orgKey,
        });

        return ctx.dispatch(new LoadOrganizationSubscriptions);
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LoadOrganizationSubscriptions, { cancelUncompleted: true })
    loadOrganizationSubscriptions(ctx: StateContext<OrganizationSubscriptionStateModel>, action: LoadOrganizationSubscriptions) {

        ctx.patchState({
            status: Status.LOADING,
        });

        let state = ctx.getState();

        return this._organizationService.listSubscriptions(state.orgKey)
            .pipe(
                tap(
                    result => {

                        let status = Status.OK;

                        if (result.length < 1) status = Status.EMPTY;

                        ctx.patchState({
                            status: status,
                            data: result,
                        });
                    },
                ),
                catchError(error => {

                    ctx.patchState({
                        status: translateCommonErrorStatus(error),
                    });

                    return of(null);

                }),
            );
    }

    @Action(DeleteOrgSubscription)
    deleteSubscription(ctx: StateContext<OrganizationSubscriptionStateModel>, action: DeleteOrgSubscription) {
        const state = ctx.getState();

        return this._organizationService.deleteSubscription(state.orgKey, action.key)
                                        .pipe(
                                            tap(result => {
                                                ctx.patchState({
                                                    status: Status.OK,
                                                });
                                                return ctx.dispatch(new LoadOrganizationSubscriptions());
                                            })
                                        );
    }
}