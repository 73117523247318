import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { Subject, Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { EvaluationScheduleFormState, EvaluationScheduleFormStateModel, InitEvaluationScheduleForm, SubmitEvaluationScheduleForm } from './evaluation-schedule-form.state';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Sample, Evaluation, SampleStorage, Status, CustomProtocolService} from '@core/data';
import { Library, Schedule, Protocol } from '@library';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { parseDuration, coerseDateProperty } from '@core/utils';
import { Snackbar } from '@core/material';
import { LIBRARY } from '@app/evaluation/library';




export interface EvaluationScheduleFormDialogData {
    sampleKey?: string;
    defaults?: Partial<Evaluation>;
}

@Component({
    selector: 'pv-evaluation-schedule-form-dialog',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    templateUrl: 'evaluation-schedule-form.dialog.html'
})
export class EvaluationScheduleFormDialog implements OnInit, OnDestroy {

    @Select(EvaluationScheduleFormState)
    state$: Observable<EvaluationScheduleFormStateModel>;

    @Select(EvaluationScheduleFormState.sample)
    sample$: Observable<Sample>;

    formGroup = new FormGroup({
        sampleKey: new FormControl(null, [Validators.required]),
        scheduleId: new FormControl(null, [Validators.required]),
        protocolId: new FormControl(null, [Validators.required]),
        startDate: new FormControl(null, [Validators.required]),
        duration: new FormControl(null, [Validators.required, Validators.min(1)]),
        size: new FormControl(null, [Validators.required, Validators.min(1)])
    });

    scheduleOptions: Schedule[] = [];
    protocolOptions: Protocol[] = [];

    resultingEvaluations: Partial<Evaluation>[] = [];
    resultingProtocol: Protocol = null;
    resultingSchedule: Schedule = null;

    private _destroy$ = new Subject();

    constructor(
        @Inject(LIBRARY) private _library: Library,
        private _store: Store,
        @Inject(MAT_DIALOG_DATA) private _data: EvaluationScheduleFormDialogData,
        private _ref: MatDialogRef<EvaluationScheduleFormDialog>,
        private _snakcbar: Snackbar,
        private _custom_protocolService: CustomProtocolService
    ){}

    ngOnInit(){

        if (this._data.defaults && this._data.defaults.massAction) {
            let initForms: InitEvaluationScheduleForm[] = [];

            this._data.defaults.sampleKeys.forEach(key => {
                initForms.push(new InitEvaluationScheduleForm(key));
            });

            this._store.dispatch(initForms);

        } else this._store.dispatch(new InitEvaluationScheduleForm(this._data.sampleKey));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._ref.close(state.result);
                }else if(state.status === Status.LOADING){
                    this.formGroup.disable();
                }else{
                    this.formGroup.enable();
                }

            });

        this.sample$.pipe(takeUntil(this._destroy$))
            .subscribe(sample => {
                if(sample) this.reset(sample);
            });

        this.formGroup.valueChanges.pipe(takeUntil(this._destroy$))
            .subscribe(form => this.calcResultingEvaluations());

    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    private reset(sample: Sample){
        let hideArchived = true;

        if(sample.scionCultivar){
            this.scheduleOptions = this._library.schedulesByCrop(sample.scionCultivar.cropId);
            this.protocolOptions = this._custom_protocolService.protocolsByCropSample(sample.scionCultivar.cropId, sample.type, hideArchived);
            if(this.scheduleOptions.length === 0) this.scheduleOptions = this._library.schedules.all();
            if(this.protocolOptions.length === 0) this.protocolOptions = this._custom_protocolService.allProtocols().all();
        }else{
            this.scheduleOptions = this._library.schedules.all();
            this.protocolOptions = this._custom_protocolService.allProtocols().all();
        }

        this.formGroup.reset({
            sampleKey: sample.key,
            scheduleId: this.scheduleOptions.length ? this.scheduleOptions[0].id : null,
            protocolId: this.protocolOptions.length ? this.protocolOptions[0].id : null,
            startDate: sample.birthDate,
            duration: 1,
            size: sample.size,
        });

    }

    cancel(){
        this._ref.close();
    }

    attempt(){

        if(!this.formGroup.valid){
            this._snakcbar.formInvalid();
            return;
        }

        if (this._data.defaults && this._data.defaults.massAction) {
            let scheduleForms: SubmitEvaluationScheduleForm[] = [];

            this._data.defaults.sampleKeys.forEach(key => {
                this.calcResultingEvaluations(key);

                if(!this.checkResultingEvaluations()) return;

                scheduleForms.push(new SubmitEvaluationScheduleForm(this.resultingEvaluations));
            });

            this._store.dispatch(scheduleForms);

        } else {
            if(!this.checkResultingEvaluations()) return;

            this._store.dispatch(new SubmitEvaluationScheduleForm(this.resultingEvaluations));
        }
    }

    private checkResultingEvaluations(): boolean {
        if(!Array.isArray(this.resultingEvaluations) || !this.resultingEvaluations.length){
            this._snakcbar.error("Chosen options does not result in any scheduled evaluation. Check your input.");
            return false;
        }

        return true;
    }

    private calcResultingEvaluations(overrideKey?: string){

        const form = this.formGroup.value;

        this.resultingEvaluations = [];
        this.resultingProtocol = this._library.protocols.get(form.protocolId);
        const schedule = this.resultingSchedule = this._library.schedules.get(form.scheduleId);

        if(!this.formGroup.valid || !schedule){
            return;
        }

        schedule.evaluations.forEach(regime => {

            let start = moment(form.startDate);

            regime.storage.forEach(store => {
                let dur = parseDuration(store.duration);
                start.add(dur.time, dur.unit);
            });

            this.resultingEvaluations.push({
                label: regime.label,
                evalStartDate: coerseDateProperty(start),
                evalEndDate: coerseDateProperty(start.clone().add(form.duration || 1, 'days')),
                protocolId: form.protocolId,
                scheduleId: schedule.id,
                size: form.size,
                storageRegime: regime.storage,
                sampleKey: overrideKey ? overrideKey : form.sampleKey
            });


        });



    }

}
