import { Pipe, PipeTransform } from '@angular/core';
import { SampleNote } from '@core/data';

@Pipe({
    name: 'extractAgreement'
})
export class ExtractAgreementStatus implements PipeTransform {

    constructor(){}

    transform(char: String): any {
        let agreement: string;

        switch (char) {
            case 'A':
                agreement = "Approved";
                break;
            case 'P':
                agreement = "In Progress";
                break;
            case 'C':
                agreement = "Completed";
                break;
            default:
                agreement = "Unknown";
                break;
        }

        return agreement;
    }
}


