import { Injectable } from '@angular/core';
import { DuplicateStationNameError, Status, translateCommonErrorStatus, WeatherStation, WeatherStationService } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


export interface WeatherStationFormStateModel {
    key: string;
    status: Status;
    data: Partial<WeatherStation>;
    errors: {
        duplicateName?: boolean;
    };
}

const DEFAULTS: WeatherStationFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
    errors: {},
};

export class InitWeatherStationForm {
    static readonly type = "[WeatherStationForm] Init";
    constructor(public key?: string, public defaults?: Partial<WeatherStation>) { }
}

export class SubmitWeatherStationForm {
    static readonly type = "[WeatherStationForm] Submit";
    constructor(public data: Partial<WeatherStation>) { }
}

export class CompleteWeatherStationForm {
    static readonly type = "[WeatherStationForm] Complete";
    constructor(public result: WeatherStation) { }
}

@State<WeatherStationFormStateModel>({
    name: 'weather_station_form',
    defaults: DEFAULTS
})
@Injectable()
export class WeatherStationFormState {

    @Selector()
    static data(state: WeatherStationFormStateModel) {
        return state.data;
    }

    constructor(private _weatherService: WeatherStationService) { }

    @Action(InitWeatherStationForm, { cancelUncompleted: true })
    initWeatherStationForm(ctx: StateContext<WeatherStationFormStateModel>, action: InitWeatherStationForm) {

        // if key provided we are editing
        if (action.key) {

            ctx.setState({
                key: action.key,
                status: Status.LOADING,
                data: {
                    ...action.defaults
                },
                errors: {}
            });

            return this._weatherService.get(action.key)
                .pipe(
                    tap(
                        result => {
                            ctx.setState({
                                key: result.key,
                                data: result,
                                status: Status.OK,
                                errors: {}
                            })
                        },
                        error => {
                            ctx.patchState({
                                status: translateCommonErrorStatus(error)
                            });
                        }
                    )
                );


            // else new culivar
        } else {

            ctx.setState({
                key: null,
                status: Status.OK,
                data: {
                    ...action.defaults
                },
                errors: {}
            });

        }

    }


    @Action(SubmitWeatherStationForm)
    submitWeatherStationForm(ctx: StateContext<WeatherStationFormStateModel>, action: SubmitWeatherStationForm) {

        const state = ctx.getState();

        ctx.setState({
            key: state.key,
            data: action.data,
            status: Status.LOADING,
            errors: {}
        });

        let request: Observable<WeatherStation>;

        if (state.key) request = this._weatherService.update(state.key, action.data);
        else if (action.data.type == 'tinyTag') request = this._weatherService.create(action.data);
        else if (action.data.type == 'sigFox') request = this._weatherService.createSigFox(action.data);

        return request.pipe(
            tap(
                result => {
                    ctx.setState({
                        key: result.key,
                        data: result,
                        status: Status.COMPLETE,
                        errors: {}
                    });

                    ctx.dispatch(new CompleteWeatherStationForm(result));

                },
                error => {

                    if (error instanceof DuplicateStationNameError) {
                        ctx.patchState({
                            status: Status.INVALID,
                            errors: {
                                duplicateName: true
                            }
                        });
                    } else {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                }
            )
        );

    }

}
