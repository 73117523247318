<mat-sidenav-container [class.tastings-theme]="tastingsEnabled">

    <!-- SIDENAV -->
    <mat-sidenav #sidenav class="app-sidenav" mode="over">

        <!-- Culteva Logo -->
        <mat-toolbar color="primary">
            <mat-icon svgIcon="culteva:icon_brand_light" class="icon-horz-24 fill-inherit"></mat-icon>
        </mat-toolbar>

        <ng-container *ngIf="contentReady && auth$ | async as auth" [ngSwitch]="auth.status">

            <ng-container *ngSwitchCase="'OK'">

                <!-- ORG SELECT START-->
                <mat-list *ngIf="auth.user?.organizations.length > 0 else noOrgs" class="mat-nopad">
                    <mat-list-item>
                        <mat-icon class='largeIcon'  mat-list-icon>account_balance</mat-icon>
                        <span matLine *ngIf="selectedOrg$ | async as org">{{ org.name }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="orgsMenu"
                                *ngIf="auth.user?.organizations.length > 1">
                            <mat-icon class='largeIcon' >more_vert</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-menu #orgsMenu="matMenu">
                        <button mat-menu-item *ngFor="let org of auth.user.organizations"
                                (click)="selectOrg(org)">
                            <mat-icon class='largeIcon' >account_balance</mat-icon>
                            <span>{{ org.name }}</span>
                        </button>
                    </mat-menu>
                </mat-list>
                <ng-template #noOrgs>
                    <mat-list class="mat-nopad">
                        <mat-list-item>
                            <mat-icon class='largeIcon'  mat-list-icon>account_balance</mat-icon>
                            <span matLine>No organization access</span>
                        </mat-list-item>
                    </mat-list>
                </ng-template>
                <!-- ORG SELECT END -->

            </ng-container>

            <!-- GUEST NAV --->
            <mat-nav-list *ngSwitchDefault class="mat-nopad">
                <a mat-list-item [routerLink]="['/login']">
                    <mat-icon class='largeIcon' matListIcon>security</mat-icon>
                    <p matLine>Sign In</p>
                    <p matLine>Authentication required</p>
                </a>
            </mat-nav-list>

            <!-- USER PROFILE START--->
            <mat-list *ngSwitchCase="'OK'" class="mat-nopad">
                <mat-list-item *ngIf="auth.user">
                    <mat-icon class='largeIcon' mat-list-icon>person</mat-icon>
                    <h4 matLine>{{ auth.user.name }}</h4>
                    <p matLine>{{ auth.user.email }}</p>
                    <button mat-icon-button [matMenuTriggerFor]="profileMenu" tabindex="0">
                        <mat-icon class='largeIcon' >more_vert</mat-icon>
                    </button>
                </mat-list-item>

                <mat-menu #profileMenu="matMenu">
                    <a mat-menu-item (click)="updateProfile()">
                        <mat-icon>account_circle</mat-icon>
                        <span>Edit Profile</span>
                    </a>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon>eject</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>
            </mat-list>
            <mat-divider></mat-divider>
            <!-- USER PROFILE END --->

            <!-- GENERATED NAV CONTENT START -->
            <ng-container *ngIf="selectedOrg$ | async as selectedOrg">
                <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                    <ng-container *ngSwitchCase="'OK'">
                        <ng-container *ngFor='let group of auth.menu.groups'>

                            <!-- MENU GROUPS START-->
                            <ng-container *ngIf="auth.user.isAdmin; then superAdminGroup; else noSuperAdminGroup"></ng-container>
                                <ng-template #superAdminGroup>
                                    <mat-nav-list class="inactiveLink" [ngStyle]="{'background-color': (group.subscribed) ? '#ffffff' : '#ededed'}">
                                        <a mat-list-item>
                                            <mat-icon [ngClass]="(group.subscribed) ? 'largeIcon' : 'largeIcon disabledIcon'" *ngIf="group.iconLib == 'material'" mat-list-icon>{{group.iconRef}}</mat-icon>
                                            <mat-icon [ngClass]="(group.subscribed) ? 'largeIcon' : 'largeIcon disabledIcon'" *ngIf="group.iconLib == 'common'" mat-list-icon svgIcon="{{'common:'+group.iconRef}}"></mat-icon>
                                            <p matLine>{{group.label}}</p>
                                        </a>
                                    </mat-nav-list>
                                </ng-template>

                                <ng-template #noSuperAdminGroup>
                                    <mat-nav-list class="inactiveLink" [ngStyle]="{'background-color': (group.subscribed) ? '#ffffff' : '#ededed'}">
                                        <a mat-list-item
                                            class="sidebar-nav-list-item"
                                            *ngIf="group.id != 'super_admin'">
                                                <mat-icon class='largeIcon' *ngIf="group.iconLib == 'material'" mat-list-icon>{{group.iconRef}}</mat-icon>
                                                <mat-icon class='largeIcon' *ngIf="group.iconLib == 'common'" mat-list-icon svgIcon="{{'common:'+group.iconRef}}"></mat-icon>
                                                <p matLine>{{group.label}}</p>
                                        </a>
                                    </mat-nav-list>
                                </ng-template>

                                <!-- MENU ITEMS PER GROUP START-->
                                <ng-container *ngIf="auth.user.isAdmin; then superAdminItems; else noSuperAdminItems"></ng-container>
                                    <ng-template #superAdminItems>
                                        <mat-nav-list dense *ngFor='let item of group.menuItems'
                                            [ngStyle]="{'background-color': (group.subscribed) ? '#ffffff' : '#ededed'}"
                                            class="sidebar-nav-list">
                                            <a *ngIf="item.externalPath==true"
                                                mat-list-item [hostLink]="(group.subscribed) ? item.routePath : group.landingPagePath"
                                                target="_blank"
                                                class="sidebar-nav-list-item">
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'material'" mat-list-icon>{{item.iconRef}}</mat-icon>
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'common'" mat-list-icon svgIcon="{{'common:'+item.iconRef}}"></mat-icon>
                                                    <p matLine>{{item.label}}</p>
                                            </a>
                                            <a *ngIf="item.externalPath==false"
                                                mat-list-item [routerLink]="(group.subscribed) ? (item.routePath | routerLinkParser : auth.selectedOrgKey) : (group.landingPagePath | routerLinkParser)"
                                                routerLinkActive="list-item-active"
                                                [routerLinkActiveOptions]="{exact:true}"
                                                class="sidebar-nav-list-item">
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'material'" mat-list-icon>{{item.iconRef}}</mat-icon>
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'common'" mat-list-icon svgIcon="{{'common:'+item.iconRef}}"></mat-icon>
                                                    <p matLine>{{item.label}}</p>
                                            </a>
                                        </mat-nav-list>
                                        <mat-divider></mat-divider>
                                    </ng-template>

                                    <ng-template #noSuperAdminItems>
                                        <ng-container *ngIf="group.id != 'super_admin'">
                                        <mat-nav-list dense *ngFor='let item of group.menuItems'
                                            [ngStyle]="{'background-color': (group.subscribed) ? '#ffffff' : '#ededed'}"
                                            class="sidebar-nav-list">
                                            <a *ngIf="item.externalPath==true"
                                                class="sidebar-nav-list-item"
                                                mat-list-item [hostLink]="(group.subscribed) ? item.routePath : group.landingPagePath"  target="_blank">
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'material'" mat-list-icon>{{item.iconRef}}</mat-icon>
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'common'" mat-list-icon svgIcon="{{'common:'+item.iconRef}}"></mat-icon>
                                                    <p matLine>{{item.label}}</p>
                                            </a>
                                            <a *ngIf="item.externalPath==false"
                                                class="sidebar-nav-list-item"
                                                mat-list-item [routerLink]="(group.subscribed) ? (item.routePath | routerLinkParser : auth.selectedOrgKey) : (group.landingPagePath | routerLinkParser)"
                                                routerLinkActive="list-item-active"
                                                [routerLinkActiveOptions]="{exact:true}">
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'material'" mat-list-icon>{{item.iconRef}}</mat-icon>
                                                    <mat-icon [ngClass]="(group.subscribed) ? 'smallIcon' : 'smallIcon disabledIcon'" *ngIf="item.iconLib == 'common'" mat-list-icon svgIcon="{{'common:'+item.iconRef}}"></mat-icon>
                                                    <p matLine>{{item.label}}</p>
                                            </a>
                                        </mat-nav-list>
                                        <mat-divider></mat-divider>
                                        </ng-container>
                                    </ng-template>
                                <!-- MENU ITEMS PER GROUP END-->

                            <!-- MENU GROUPS END-->
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!--TEMP GUEST MENU START-->
                <ng-container *ngIf="selectedOrg?.access.role == 'guest'">
                    <mat-nav-list dense class="mat-nopad">
                        <a mat-list-item [routerLink]="['/']" routerLinkActive="list-item-active"
                        [routerLinkActiveOptions]="{exact:true}">
                            <mat-icon mat-list-icon>home</mat-icon>
                            <p matLine>Home</p>
                        </a>

                        <a mat-list-item [routerLink]="['/org', selectedOrg.key, 'samples', 'plant']"
                        routerLinkActive="list-item-active">
                            <mat-icon mat-list-icon svgIcon="common:plant_sample"></mat-icon>
                            <p matLine>Plant Samples</p>
                        </a>

                        <a mat-list-item [routerLink]="['/org', selectedOrg.key, 'samples', 'harvest']"
                        routerLinkActive="list-item-active">
                            <mat-icon mat-list-icon svgIcon="common:harvest_sample"></mat-icon>
                            <p matLine>Harvest Samples</p>
                        </a>
                    </mat-nav-list>
                </ng-container>
                <!--TEMP GUEST MENU END-->
            </ng-container>
            <!--GENERATED NAV CONTENT END-->

            <!-- DEBUG NAV -->
            <mat-nav-list class="mat-nopad">
                <a *ngIf="isDebug" mat-list-item [routerLink]="['/demo']">
                    <mat-icon class='largeIcon' mat-list-icon>bug_report</mat-icon>
                    <p matLine>Demo</p>
                </a>
            </mat-nav-list>

        </ng-container>

    </mat-sidenav>
    <!-- SIDENAV END -->

    <!-- CONTENT -->
    <div class="app-content">

        <!-- ROUTER OUTLET -->
        <router-outlet></router-outlet>

    </div>
    <!-- CONTENT END -->

</mat-sidenav-container>
