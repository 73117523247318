import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@core/browser';
import { Store } from '@ngxs/store';
import { VERSION } from '../../environments/version';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthState, InvalidateAuthToken } from './auth-state';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor {

    constructor(private _store: Store, private _router: Router) { }

    /**
     *
     * @param request HttpRequest
     * @param next HttpHandler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //Bypass injectRequestAuthorization if request url starts with https://proxy.cors.sh/ (sigfox weather sensor)
        if(!request.url.startsWith('https://proxy.cors.sh/')) request = this.injectRequestAuthorization(request);
        return next.handle(request)
            .pipe(
                catchError((res, caught) => this.interceptUnauthenticatedError(res, caught))
            );
    }

    /**
     *
     * @param request HttpRequest
     */
    private injectRequestAuthorization(request: HttpRequest<any>) {

        let token = this._store.selectSnapshot(AuthState.token);
        let instance = this._store.selectSnapshot(AuthState.instance);

        let headers = request.headers.append('X-Device-Instance', instance);
        headers = headers.append('X-Device-Client', VERSION);
        headers = headers.append('X-Device-Info', Device.describeDevice());

        if (token) {

            headers = headers.set('Authorization', 'Bearer ' + token);

            let cloneOptions = {
                headers: headers
            };

            request = request.clone(cloneOptions);
        }

        return request;
    }

    /**
     *
     * @param response HttpErrorResponse
     * @param caught Http Event
     */
    private interceptUnauthenticatedError(response: any, caught: Observable<HttpEvent<any>>): Observable<any> {

        if (response instanceof HttpErrorResponse && response.status === 401) {
            if (response.error.message && response.error.message.match(/unauthenticated/i)) {
                console.warn('AuthInterceptor: User unauthenticated error caught. Invalidating token.');
                this._store.dispatch(new InvalidateAuthToken());
                const returnTo = this._router.url;
                if(!returnTo.startsWith('/login')){
                    this._router.navigate(['/login'], {queryParams: { returnTo }})
                }
            } else {
                console.warn('AuthInterceptor: Unknown 401 error caught.');
            }
        }
        return throwError(response);
    }

}

