import { LIB_MEASURE_BUCKETS } from './buckets';
import { Dictionary } from '../dictionary';
import { BucketOptions } from './_types';


export class BucketDictionary extends Dictionary<BucketOptions>{
    constructor(){
        super(LIB_MEASURE_BUCKETS);
    }
}

export * from './_types';