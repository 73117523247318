import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Snackbar } from '@core/material';
import {Category, Characteristic, Library, IntervalParams, NominalParams, ColorParams, Protocol, CharacteristicType} from '@library';
import { EvaluationFormatter } from '../../../evaluation-formatter.service';
import {CustomProtocolService, Evaluation, SampleCharacteristic, Status} from '@core/data';
import { FormControl, FormGroup, Form, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { EvaluationValidators } from '@app/evaluation/evaluation-validators';
import { Select, Store } from '@ngxs/store';
import { CharacteristicsFormState, CharacteristicsFormStateModel, InitCharacteristicsForm, SubmitCharacteristicsForm } from './characteristics-form.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { RGB, HSL } from '@core/utils';
import { LIBRARY } from '@app/evaluation/library';

export interface CharsFormDialogData {
    evalKey: string;
}

@Component({
    selector: 'pv-characteristics-form-dialog',
    templateUrl: 'characteristics-form.dialog.html',
    styleUrls: [ 'characteristics-form.dialog.scss' ],
    host: {
        class: 'pv-fullscreen-dialog'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacteristicsFormDialog implements OnInit, OnDestroy {

    @Select(CharacteristicsFormState.data)
    data$: Observable<Evaluation>;

    @Select(CharacteristicsFormState)
    state$: Observable<CharacteristicsFormStateModel>;

    formGroup = new FormGroup({});

    groups: {
        category: Category,
        chars: Characteristic[]
    }[] = [];

    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<CharacteristicsFormDialog>,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: CharsFormDialogData,
        private _formatter: EvaluationFormatter,
        @Inject(LIBRARY) private _library: Library,
        private _changeDetectorRef: ChangeDetectorRef,
        private _store: Store,
        private _custom_protocolService: CustomProtocolService,
    ) {}

    ngOnInit(){

        this._store.dispatch(new InitCharacteristicsForm(this.data.evalKey));

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if(data) this.reset(data);
            });

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Characteristics updated");
                    this._dialogRef.close(state.data);
                }else if(state.status === Status.LOADING){
                    this.formGroup.disable();
                }else{
                    this.formGroup.enable();
                }
            });

    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    private buildForm(protocol: Protocol, model: Evaluation) {
        const chars = protocol.chars.map(charId => {
            return this._library.chars.get(charId);
        }).filter(char => !!char);

        let charValue: SampleCharacteristic,
            value: string, control: FormControl;

        // remove all controls
        Object.keys(this.formGroup.controls)
            .forEach(id => this.formGroup.removeControl(id));

        this.formGroup.reset({});

        this.groups = [];

        chars.forEach(char => {

            let group = this.groups.find(group => group.category.id === char.categoryId);

            if (!group) {
                group = {
                    category: this._library.categories.get(char.categoryId),
                    chars: [],
                };
                this.groups.push(group);
            }

            group.chars.push(char);

            charValue = model.chars.find(value => value.charId === char.id);
            value = charValue ? charValue.value : null;

            switch (char.type) {
                case "nominal":
                    control = this.makeNominalControl(char, value);
                    break;
                case "interval":
                    control = this.makeIntervalControl(char, value);
                    break;
                case "event":
                    control = this.makeEventControl(char, value);
                    break;
                case "color":
                    control = this.makeColorControl(char, value);
                    break;
                default:
                    control = new FormControl(value);
            }

            this.formGroup.registerControl(char.id, control);

        });

        this.formGroup.updateValueAndValidity();
        this._changeDetectorRef.markForCheck();
    }

    reset(model: Evaluation) {
        this.buildForm(this._custom_protocolService.allProtocols().get(model.protocolId), model);
    }

    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.valid){

            const form = this.cast();

            if(form.chars.length === 0){
                this._snackbar.info("No changes to characteristics.");
                this.cancel();
            }else{
            }   this._store.dispatch(new SubmitCharacteristicsForm(form));


        }else{
            this._snackbar.error("One or more characteristics is invalid. Check your inputs and try again.");
        }

    }

    cancel(){
        this._dialogRef.close();
    }


    cast(): {chars: Partial<SampleCharacteristic>[]} {

        let values: SampleCharacteristic[] = [];

        let keys = Object.keys(this.formGroup.controls);

        let control: AbstractControl, char: Characteristic, rawValue: any, value: string;

        keys.forEach((charId) => {
            control = this.formGroup.get(charId);
            if(control.pristine) return;
            value = this.getCastValue(charId);
            values.push({ charId, value });
        });

        return {
            chars: values,
        };


    }

    getCastValue(charId: string){

        let char = this._library.chars.get(charId);
        let control = this.formGroup.get(charId);

        // if(control.invalid) return null;

        let rawValue = control.value;

        switch (char.type) {
            case 'nominal':
                if (Array.isArray(rawValue) && rawValue.length > 0) {
                    return rawValue.join(',');
                }
                return null;
            case 'event':
                if(moment.isMoment(rawValue)) return rawValue.format('YYYY-MM-DD');
                return null;
            case 'color':
                if (rawValue instanceof HSL) {
                    return `hsl(${rawValue.h},${rawValue.s}%,${rawValue.l}%)`;
                }
                return null;
            case 'interval':
                let num = coerceNumberProperty(rawValue, null);
                return num === null ? null : num.toString();
            default:
                return rawValue;
        }

    }

    getValueLabel(charId: string) {

        let value = this.getCastValue(charId);
        let char = this._library.chars.get(charId);

        if (value !== null || char.type === CharacteristicType.Interval) {
            return this._formatter.charValueLabel(value, charId);
        }
        return '';
    }

    private makeIntervalControl(char: Characteristic, modelValue: string): FormControl {
        let params = (<IntervalParams>char.params);
        return new FormControl(
            modelValue !== null ? parseFloat(modelValue) : null,
            [EvaluationValidators.interval(params.min, params.max, params.step)]
        );
    }

    private makeNominalControl(char: Characteristic, modelValue: string): FormControl {
        let multi = (<NominalParams>char.params).multiple ? (<NominalParams>char.params).multiple : true;
        let value = null;

        if (modelValue !== null) {
            if (multi) {
                value = modelValue.split(',');
            } else {
                value = modelValue;
            }
        }

        return new FormControl(value);
    }

    private makeColorControl(char: Characteristic, modelValue: string): FormControl {
        return new FormControl(modelValue === null ? null : HSL.fromString(modelValue), [EvaluationValidators.color]);
    }

    private makeEventControl(char: Characteristic, modelValue: string): FormControl {
        let value = (modelValue !== null) ? moment(modelValue) : null;

        return new FormControl(
            value,
            [EvaluationValidators.event()]
        );

    }



}

