import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Status } from "@core/data";
import { TastingEvent, TastingsEventInvitation } from "@core/data/types/tastings-event";
import { Snackbar } from "@core/material";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InitTastingEventInviteForm, SubmitTastingEventInviteForm, TastingEventInviteFormState, TastingEventInviteFormStateModel } from "./tasting-events-invite-form.state";

export interface TastingEventsInviteFormDialogData {
    orgKey: string;
    events: TastingEvent[];
}

@Component({
    selector: 'pv-tasting-events-invite-form-dialog',
    templateUrl: './tasting-events-invite-form.dialog.html',
    styleUrls: ['tasting-events-invite-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class TastingEventsInviteFormDialog implements OnInit, OnDestroy {
    @Select(TastingEventInviteFormState)
    state$: Observable<TastingEventInviteFormStateModel>;

    private _destroy$ = new Subject();

    public defaultBannerPath = "cloudinary:provar/culteva/tastings/default-banner";

    //Form controls
    inviteEmailListControl = new FormControl(null, [Validators.required]);
    inviteMessageControl = new FormControl(null);
    //Form group
    formGroup: FormGroup = new FormGroup({
        inviteEmailList: this.inviteEmailListControl,
        inviteMessage: this.inviteMessageControl,
    });

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingEventsInviteFormDialog>,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: TastingEventsInviteFormDialogData
    ) {}

    ngOnInit(): void {
        this._store.dispatch(new InitTastingEventInviteForm(this.data.orgKey, this.data.events));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close();
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });
    }

    attempt(): void {
        if (!this.validateEmailInput(this.formGroup)) {
            let emailField: AbstractControl = this.formGroup.get('inviteEmailList');
            emailField.setErrors({incorrect: true});
        }

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) this.invite();
        else {
            console.warn("TastingEventsInviteDialog: form invalid", this.formGroup.value);
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    invite(): void {
        const form = this.formGroup.value;

        const invite: TastingsEventInvitation = {
            emails: this.emailFormatter(form.inviteEmailList) || [],
            message: form.inviteMessage || '',
            events: this.data.events.map(event => {return event.key}),
            orgKey: this.data.orgKey
        }

        this._store.dispatch(new SubmitTastingEventInviteForm(invite));
    }

    private validateEmailInput(formGroup: FormGroup): boolean {
        let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let form = formGroup.value;
        let emailString: string = form.inviteEmailList;
        let individualValidity: boolean = true;

        if (emailString === null) return false;
        if (emailString.trim().length === 0) return false;

        let emails = emailString.trim().split(',');

        emails.forEach(email => {
            if (!regex.test(email.trim())) individualValidity = false;
        })

        return individualValidity;
    }

    private emailFormatter(emailString: string): string[] {
        let emails = emailString.trim().split(',');
        return emails.map(email => {return email.trim()});
    }

    cancel(): void {
        this._dialogRef.close();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}