import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { Crop } from "../../types/EvaluationLibrary/crop";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class CropCreated {
    static readonly type = "[CropService] Crop Created";
    constructor(public crop: Crop) {}
}

export class CropUpdated {
    static readonly type = "[CropService] Crop Updated";
    constructor(public crop: Crop) {}
}

export class CropDeleted {
    static readonly type = "[CropService] Crop Deleted";
    constructor(public crop: Crop) {}
}

@Injectable()
export class CropService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) {}

    get(key: string, detail?: DetailRequest): Observable<Crop> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<Crop>(`${this._baseUri}/evaluation-library/crop/${key}`, {params});
    }

    create(model: Partial<Crop>) {
        return this._http.post<Crop>(`${this._baseUri}/evaluation-library/crop`, model)
            .pipe(tap(index => this._store.dispatch(new CropCreated(index))));
    }

    update(key: string, model: Partial<Crop>) {
        return this._http.put<Crop>(`${this._baseUri}/evaluation-library/crop/${key}`, model)
            .pipe(tap(index => this._store.dispatch(new CropUpdated(index))));
    }

    delete(key: string) {
        return this._http.delete<Crop>(`${this._baseUri}/evaluation-library/crop/${key}`)
            .pipe(tap(index => this._store.dispatch(new CropDeleted(index))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<Crop>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<Crop>>(`${this._baseUri}/evaluation-library/crop`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<Crop>>(`${this._baseUri}/evaluation-library/crop/search`, {params: <any>search});
    }
}