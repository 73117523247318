import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpRequest, HttpEvent, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { API_BASE_URI } from '../http/api';
import { Filter, FilterBuilder } from '../http/filter';
import { Collection } from '../http/collection';
import { WeatherStation, WeatherReportOptions, WeatherReportData } from '../types/weather-station';
import { Observable, throwError } from 'rxjs';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { catchError, tap } from 'rxjs/operators';
import { Dot } from '@core/utils';
import { SimpleEncoder } from '../http/encode';
import * as moment from "moment";
import { TempDataPoint, HumidityDataPoint, SigFoxDataPoint } from "../types/weather-reading";
import { Store } from '@ngxs/store';

export class WeatherStationDeleted {
    static readonly type = "[WeatherStationService] Weather Station Deleted";
    constructor(public weatherStation: WeatherStation) { }
}
export class WeatherStationCreated {
    static readonly type = "[WeatherStationService] Weather Station Created";
    constructor(public weatherStation: WeatherStation) { }
}

export class WeatherStationUpdated {
    static readonly type = "[WeatherStationService] Weather Station Updated";
    constructor(public weatherStation: WeatherStation) { }
}

@Injectable()
export class WeatherStationService {

    public proxy: string = 'https://proxy.cors.sh/';
    public baseUrl: string = 'https://api.sigfox.com/v2/devices/';
    public deviceId: string = 'C15BE2';
    public provarGroupID: string = '60193cd12564327eb9a671ff';

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa('60252c2441758107b06dc58c:091ecef1ab1b58a30cd54905979cab87'),
        }),
    };

    constructor(
        private _store: Store,
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string
    ) { }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<WeatherStation>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };
        return this._http.get<Collection<WeatherStation>>(`${this._baseUri}/weather-stations`, { params });
    }

    get(key: string, detail?: DetailRequest): Observable<WeatherStation> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<WeatherStation>(`${this._baseUri}/weather-stations/${key}`, { params });
    }

    create(data: Partial<WeatherStation>): Observable<WeatherStation> {
        return this._http.post<WeatherStation>(`${this._baseUri}/weather-stations`, data)
            .pipe(this.catchNameError(),
                tap(result => this._store.dispatch(new WeatherStationCreated(result)))
            );
    }

    createSigFox(data: Partial<WeatherStation>): Observable<WeatherStation> {
        return this._http.post<WeatherStation>(`${this._baseUri}/weather-stations/sigFox`, data)
            .pipe(this.catchNameError(),
                tap(result => this._store.dispatch(new WeatherStationCreated(result)))
            );
    }

    update(key: string, data: Partial<WeatherStation>) {
        return this._http.put<WeatherStation>(`${this._baseUri}/weather-stations/${key}`, data)
            .pipe(
                this.catchNameError(),
                tap(result => this._store.dispatch(new WeatherStationUpdated(result)))
            );
    }

    delete(key: string): Observable<WeatherStation> {
        return this._http.delete<WeatherStation>(`${this._baseUri}/weather-stations/${key}`)
            .pipe(tap(result => this._store.dispatch(new WeatherStationDeleted(result))));
    }

    uploadReadings(key: string, data: UploadReadingsFormData): Observable<HttpEvent<UploadReadingsResponse>> {
        let form = new FormData();
        form.set('file', data.file);
        form.set('file_format', data.file_format);
        if (data.import !== 'default') {
            form.set('import', data.import);
        }

        const req = new HttpRequest('POST', `${this._baseUri}/weather-stations/${key}/readings/upload`, form, {
            reportProgress: true
        });

        return this._http.request<UploadReadingsResponse>(req)
            .pipe(this.catchDuplicateDataError());
    }

    report(key: string, reportOptions: WeatherReportOptions): Observable<WeatherReportData> {
        // encode timestamp params because
        // angular will encode + to %20 [space]
        // :<
        // see https://stackoverflow.com/questions/45428842/angular-url-plus-sign-converting-to-space
        const params = new HttpParams({ fromObject: <any>reportOptions, encoder: new SimpleEncoder });

        return this._http.get<WeatherReportData>(
            `${this._baseUri}/weather-stations/${key}/report`,
            { params }
        );

    }

    getMessagesNextPage(url: string): Observable<SigFoxMessageResponse> {
        return this._http.get<SigFoxMessageResponse>(
            `${this.proxy}${url}`,
            { headers: this.httpOptions.headers, responseType: 'json' }
        )
    }

    uploadSigFoxReadings(key: string, data: UploadReadingsSigFoxData): Observable<HttpEvent<UploadReadingsResponse>> {
        const req = new HttpRequest('POST', `${this._baseUri}/weather-stations/${key}/readings/uploadSigfox`, data, {
            reportProgress: true
        });

        return this._http.request<UploadReadingsResponse>(req)
            .pipe(this.catchDuplicateDataError());
    }

    getTempData(dataObject: string[]): TempDataPoint[] {
        let size = dataObject.length;
        let dataPoint: Array<TempDataPoint> = [];

        for (let i = 0; i < size; i++) {
            let dataString = (dataObject[i])

            let tempMinHex: string = dataString.substring(0, 3);
            let tempMaxHex: string = dataString.substring(3, 6);
            let tempAvgHex: string = dataString.substring(6, 9);

            let tempMin: number = (parseInt(tempMinHex, 16) - 1000) / 10;
            let tempMax: number = (parseInt(tempMaxHex, 16) - 1000) / 10;
            let tempAvg: number = (parseInt(tempAvgHex, 16) - 1000) / 10;

            let tmp: TempDataPoint = { min: tempMin, max: tempMax, avg: tempAvg }
            dataPoint.push(tmp);
        }
        return dataPoint;
    }

    getHumidityData(dataObject: string[]): HumidityDataPoint[] {
        let size = dataObject.length;
        let dataPoint: Array<HumidityDataPoint> = [];

        for (let i = 0; i < size; i++) {
            let dataString = (dataObject[i])

            let humMinHex: string = dataString.substring(9, 12);
            let humMaxHex: string = dataString.substring(12, 15);
            let humAvgHex: string = dataString.substring(15, 18);

            let humMin: number = (parseInt(humMinHex, 16)) / 10;
            let humMax: number = (parseInt(humMaxHex, 16)) / 10;
            let humAvg: number = (parseInt(humAvgHex, 16)) / 10;

            let tmp: HumidityDataPoint = { min: humMin, max: humMax, avg: humAvg }
            dataPoint.push(tmp);
        }
        return dataPoint;
    }

    compileSigFoxDataPoint(timestamp: any[], tempPoint: TempDataPoint[], humPoint: HumidityDataPoint[]) {
        let size = tempPoint.length;
        let dataPoint: Array<SigFoxDataPoint> = [];

        for (let i = 0; i < size; i++) {
            let tempArray = (tempPoint[i]);
            let humArray = (humPoint[i]);
            let dateUnix = (timestamp[i])

            let dateArray = this.decodeTimeStamp(dateUnix);

            let tmp: SigFoxDataPoint = { timestamp: dateArray, tempDataPoint: tempArray, humidityDataPoint: humArray }

            dataPoint.push(tmp);
        }

        return dataPoint;
    }

    getDeviceList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa('60252c2441758107b06dc58c:091ecef1ab1b58a30cd54905979cab87'),
            })
        };

        return this._http.get<any>(
            //this.proxy
            + this.baseUrl
            + "?id=C15BE2&groupIds=60193cd12564327eb9a671ff&sort=name",
            httpOptions);
    }

    encodeTimestamp(d: Date): number {
        return d.valueOf();
    }

    decodeTimeStamp(n: number): string {
        return moment.unix(n / 1000).format("YYYY-MM-DD HH:mm:00");
    }

    private catchNameError() {
        return catchError<WeatherStation, Observable<WeatherStation>>(e => {

            if (e instanceof HttpErrorResponse && e.status === 422
                && String(Dot.get(e.error, 'errors.name.0', '')).includes('taken')) {
                return throwError(new DuplicateStationNameError(e));
            }

            return throwError(e);
        });
    }

    private catchDuplicateDataError() {
        return catchError<HttpEvent<UploadReadingsResponse>, Observable<HttpEvent<UploadReadingsResponse>>>(e => {

            if (e instanceof HttpErrorResponse && e.status === 400
                && e.error.message === 'duplicate_data') {
                return throwError(new DuplicateWeatherDataError(e));
            }

            return throwError(e);
        });
    }

}

export interface UploadReadingsResponse {
    message: string;
}

export interface UploadReadingsFormData {
    file: File;
    import: null | ReadingsImportStrategy;
    file_format: ReadingsImportFileFormat;
}

export interface UploadReadingsSigFoxData {
    data: SigFoxDataPoint[];
}

export enum ReadingsImportStrategy {
    DEFAULT = 'default',
    REPLACE_ALL = 'replace_all',
    REPLACE_PERIOD = 'replace_period',
}

export enum ReadingsImportFileFormat {
    TINYTAG_TGP_4510_CSV = 'tinytag_tgp_4510_csv',
    TINYTAG_TGP_4500_CSV = 'tinytag_tgp_4500_csv',
    TINYTAG_TGP_45001_CSV = 'tinytag_tgp_45001_csv',
}

export class DuplicateStationNameError extends HttpErrorResponse { }
export class DuplicateWeatherDataError extends HttpErrorResponse { }

export interface SigFoxMessageResponse {
    data: any;
    paging: { next: any };
}