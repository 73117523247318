


export function splitwords(value: string): Array<string> {
    return value.replace(/([A-Z]+)/g, function (m) { return '_' + m; })
        .match(/([^\W_]+)/g);
}

export function lcfirst(value: string): string {
    return value.charAt(0).toLowerCase() + value.slice(1);
}

export function ucfirst(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function titleCase(value: string): string {
    return splitwords(value).map(ucfirst).join(' ');
}

export function studlyCase(value: string): string {
    return splitwords(value).map(ucfirst).join('');
}

export function camelCase(value: string): string {
    return lcfirst(studlyCase(value));
}

export function snakeCase(value: string): string {
    return splitwords(value).join('_').toLowerCase();
}

export function constantCase(value: string): string {
    return splitwords(value).join('_').toUpperCase();
}

export function uuid(signature = '########-####-4###-$###-#############') {
    let d = new Date().getTime();
    let uuid = signature.replace(/[\#\$]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == '#' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    return uuid;
}

export function fileExtension(filename: string) {
    return filename.split('.').pop();
}
