import { Pipe, PipeTransform } from '@angular/core';
import { Status, StatusTitle, StatusMessage } from '../state/status';

@Pipe({
  name: 'descStatusTitle',
})
export class DescribeStatusTitlePipe implements PipeTransform {

    constructor(){}

    transform(status: Status) {
        if(StatusTitle[status] !== undefined) return StatusTitle[status];
        return null;
    }
}

@Pipe({
    name: 'descStatusMessage',
})
export class DescribeStatusMessagePipe implements PipeTransform {
    constructor() {}

    transform(status: Status, context: string) {
        if(StatusMessage[status] !== undefined) return StatusMessage[status];
        return null
    }
}
