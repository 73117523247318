import { Injectable } from '@angular/core';
import { DetailRequest, SampleService, Status, translateCommonErrorStatus, Sample } from '@core/data';
import { Action, State, StateContext, Selector } from "@ngxs/store";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


export interface SampleFormStateModel {
    key: string;
    status: Status;
    data: Partial<Sample>;
    clone?: boolean;
}

const DEFAULTS: SampleFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
    clone: false,
};

export class InitNewSampleForm {
    static readonly type = "[SampleForm] Init new";
    constructor(public defaults?: Partial<Sample>) { }
}

export class InitUpdateSampleForm {
    static readonly type = "[SampleForm] Init update";
    constructor(public key: string, public clone: boolean = false) { }
}

export class SubmitSampleForm {
    static readonly type = "[SampleForm] Submit";
    constructor(public data: Partial<Sample>) { }
}


@State<SampleFormStateModel>({
    name: 'sample_form',
    defaults: DEFAULTS
})
@Injectable()
export class SampleFormState {

    @Selector()
    static data(state: SampleFormStateModel){
        return state.data;
    }

    constructor(private _sampleService: SampleService) { }

    @Action(InitNewSampleForm, { cancelUncompleted: true })
    initNewSampleForm(ctx: StateContext<SampleFormStateModel>, action: InitNewSampleForm) {

        ctx.setState({
            key: null,
            data: {
                ...action.defaults,
            },
            status: Status.OK
        });

    }

    @Action(InitUpdateSampleForm, { cancelUncompleted: true })
    initUpdateSampleForm(ctx: StateContext<SampleFormStateModel>, action: InitUpdateSampleForm) {

        ctx.setState({
            key: action.key,
            data: null,
            clone: action.clone,
            status: Status.LOADING
        });

        let detailRequest: DetailRequest;

        detailRequest = {
            related: ['scionCultivar', 'rootstockCultivar', 'site', 'plantSample']
        };

        return this._sampleService.get(action.key, detailRequest)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            clone: action.clone,
                            status: Status.OK
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );

    }


    @Action(SubmitSampleForm)
    submitSampleForm(ctx: StateContext<SampleFormStateModel>, action: SubmitSampleForm) {

        const state = ctx.getState();

        let request: Observable<Sample>;

        if (state.key && !state.clone) request = this._sampleService.update(state.key, { ...action.data });
        else request = this._sampleService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );

    }



}