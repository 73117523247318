import { Category } from './_types';

export const LIB_CATEGORIES: Category[]= [

    // fruit tree chars
    { id: "tree_phenology",           label: "Fruit Tree - Phenology" },
    { id: "tree_arch",                label: "Fruit Tree - Growth & Architecture" },
    { id: "tree_flower",              label: "Fruit Tree - Flowering" },
    { id: "tree_bear",                label: "Fruit Tree - Fruit Set" },
    { id: "tree_disorder",            label: "Fruit Tree - Pests, Diseases & Disorders" },
    { id: "tree_adapt",               label: "Fruit Tree - Adaptability" },

    // nut tree chars
    { id: "nut_tree_phenology",       label: "Nut Tree - Phenological Sequence" },
    { id: "nut_tree_arch",            label: "Nut Tree - Growth & Architecture" },
    { id: "nut_tree_trellis",         label: "Nut Tree - Trellis and Training System" },
    { id: "nut_tree_kernel",          label: "Nut Tree - Kernel Set" },
    { id: "nut_tree_flower",          label: "Nut Tree - Flowering" },
    { id: "nut_tree_disorder",        label: "Nut Tree - Pests, Diseases & Disorders" },
    { id: "nut_tree_adapt",           label: "Nut Tree - Adaptability" },

    // nut harvest chars
    { id: "nut_husk",                       label: "Nut - Husk" },
    { id: "nut_shell",                      label: "Nut - Shell" },
    { id: "nut_kernel",                     label: "Nut - Kernel" },
    { id: "nut_eat_quality_dried",          label: "Nut - Eating Quality - Dried / Roasted" },
    { id: "nut_eat_quality_dried_after",    label: "Nut - Eating Quality - Dried / Roasted after Storage" },

    // fruit harvest
    { id: "frut_ripening",            label: "Fruit - Ripening" },
    { id: "frut_appearance",          label: "Fruit - Appearance" },
    { id: "frut_shape_symmetry",      label: "Fruit - Shapes and Symmetry" },
    { id: "frut_abrasion_blemish",    label: "Fruit - Abrasions and Blemishes" },
    { id: "frut_eating_quality",      label: "Fruit - Eating Quality" },
    { id: "frut_disease_disorder",    label: "Fruit - Storage Diseases and Disorders" },
    { id: "frut_stone_flesh",         label: "Fruit - Stone and Flesh Type" },

    // vine chars
    { id: "vine_phenology",           label: "Vine - Phenology" },
    { id: "vine_events",              label: "Vine - Manipulation Events" },
    { id: "vine_bear",                label: "Vine - Fruit Set & Growth" },
    { id: "vine_arch",                label: "Vine - Growth & Architecture" },
    { id: "vine_trellis_train",       label: "Vine - Trellis & Training System" },
    { id: "vine_flower",              label: "Vine - Flowering" },
    { id: "vine_disorder",            label: "Vine - Pests, Diseases & Disorders" },
    { id: "vine_adapt",               label: "Vine - Adaptability" },

    // grape chars
    { id: "grap_bery_loose",          label: "Grape - Berry, Loose" },
    { id: "grap_bery_disorder",       label: "Grape - Berry Storage Disorders" },
    { id: "grap_appearance",          label: "Grape - Appearance" },
    { id: "grap_bery_defects",        label: "Grape - Berry Defects" },
    { id: "grap_bery_decay",          label: "Grape - Berry Decay" },
    { id: "grap_bery_crack",          label: "Grape - Berry Cracking" },
    { id: "grap_bery_ext_browning",   label: "Grape - Berry External Browning" },
    { id: "grap_bery_int_browning",   label: "Grape - Berry Internal Browning" },
    { id: "grap_bery_seed",           label: "Grape - Berry Seed Presence" },
    { id: "grap_ripening",            label: "Grape - Ripening" },
    { id: "grap_eating_quality",      label: "Grape - Eating Quality" },

    // blueberry harvest chars
    { id: "blueberry_disorders",           label: "Blueberry - Disorders" },
    { id: "blueberry_ripening",            label: "Blueberry - Ripening" },
    { id: "blueberry_appearance",          label: "Blueberry - Appearance" },
    { id: "blueberry_shapes",              label: "Blueberry - Shapes and Symmetry" },
    { id: "blueberry_abrasions",           label: "Blueberry - Abrasions and Blemishes" },
    { id: "blueberry_eating_quality",      label: "Blueberry - Eating Quality" },
    { id: "blueberry_storage_disorder",    label: "Blueberry - Storage Diseases & Disorders" },
    { id: "blueberry_bery_decay",          label: "Blueberry - Berry Decay" },
    { id: "blueberry_bery_crack",          label: "Blueberry - Berry Crack" },

    //blueberry plant chars
    { id: "blueberry_phenology",           label: "Bush - Phenology" },
    { id: "blueberry_events",              label: "Bush - Manipulation Events" },
    { id: "blueberry_arch",                label: "Bush - Growth & Architecture" },
    { id: "blueberry_trellis_train",       label: "Bush - Trellis & Training System" },
    { id: "blueberry_flower",              label: "Bush - Flowering & Morphology" },
    { id: "blueberry_bear",                label: "Bush - Fruit Set & Growth" },
    { id: "blueberry_disorder",            label: "Bush - Pests, Diseases & Disorders" },
    { id: "blueberry_adapt",               label: "Bush - Adaptability" },

    // citrus harvest chars
    { id: "citrus_abrasion_blemish",    label: "Citrus - Abrasions and Blemishes" },
    { id: "citrus_firmness",            label: "Citrus - Firmness" },
    { id: "citrus_appearance",          label: "Citrus - Appearance" },
    { id: "citrus_internal_appearance", label: "Citrus - Internal Appearance" },
    { id: "citrus_rind_disorder",       label: "Citrus - Physiological Rind Disorders" },
    { id: "citrus_disease_detect",      label: "Citrus - Disease Detected" },
    { id: "citrus_ripening",            label: "Citrus - Ripening" },
    { id: "citrus_seed_presence",       label: "Citrus - Seed Presence" },
    { id: "citrus_eating_quality",      label: "Citrus - Eating Quality" },

    // citrus plant chars
    { id: "citrus_tree_phenology",      label: "Citrus - Phenology Sequence" },
    { id: "citrus_tree_arch",           label: "Citrus - Growth and Architecture" },
    { id: "citrus_tree_flower",         label: "Citrus - Flowering" },
    { id: "citrus_tree_bear",           label: "Citrus - Fruit Set" },
    { id: "citrus_tree_disorder",       label: "Citrus - Pests, Diseases & Disorders" },
    { id: "citrus_tree_adapt",          label: "Citrus - Adaptability" },

    //avo harvest chars
    { id: "avo_ripening",           label: "Avocado - Ripening" },
    { id: "avo_appearance",         label: "Avocado - Colour and Pattern" },
    { id: "avo_shape_symmetry",     label: "Avocado - Shapes and Symmetry" },
    { id: "avo_abrasion_blemish",   label: "Avocado - Abrasions and Blemishes" },
    { id: "avo_defects",            label: "Avocado - Defects" },
    { id: "avo_eating_quality",     label: "Avocado - Eating Quality" },
    { id: "avo_disease_disorder",   label: "Avocado - Storage Diseases and Disorders" },

    // avo plant chars
    { id: "avo_tree_origin",                    label: "Avocado Tree - Origin" },
    { id: "avo_tree_spr_phenology",             label: "Avocado Tree - Phenology (Spring Vegetative Flush)" },
    { id: "avo_tree_reproductive_phenology",    label: "Avocado Tree - Phenology (Reproductive development)" },
    { id: "avo_tree_sum_phenology",             label: "Avocado Tree - Phenology (Summer Vegetative Flush)" },
    { id: "avo_tree_arch",                      label: "Avocado Tree - Growth and Architecture" },
    { id: "avo_tree_flower",                    label: "Avocado Tree - Flowering" },
    { id: "avo_tree_bear",                      label: "Avocado Tree - Fruit Set" },
    { id: "avo_tree_disorder",                  label: "Avocado Tree - Pests, Diseases & Disorders" },
    { id: "avo_tree_adapt",                     label: "Avocado Tree - Adaptability" },

    // plant chars
    { id: "plant_generic",            label: "Plant - Generic" },
    { id: "plant_disorder",           label: "Plant - Disorders" },
    { id: "plant_pests",              label: "Plant - Pests" },
    { id: "plant_diseases",           label: "Plant - Diseases" },
    { id: "plant_seeding",            label: "Plant - Seeding" },
    { id: "plant_vegetative",         label: "Plant - Vegetative" },
    { id: "plant_flowering",          label: "Plant - Flowering" },
    { id: "plant_flower_dev",         label: "Plant - Flower Development" },
    { id: "plant_harvest",            label: "Plant - Post-Harvest" },

    // general
    { id: "harvest_overall",          label: "Overall Score and Indexing" },
    { id: "recommend",                label: "Recommendations" },
    { id: "uncategorized",            label: "Uncategorised" },

    // tastings
    { id: "tastings_sensory",         label: "Sensory Impression"},
];