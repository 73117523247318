<mat-icon svgIcon="culteva:icon_light" class="mat-64 m-24"></mat-icon>

<ng-container *ngIf="state$ | async as state" [ngSwitch]="state.status">

    <ng-container *ngSwitchCase="'OK'">

        <!-- Authenticated -->
        <mat-card *ngIf="state.user as user else noUserCard" style="min-width: 250px;">

            <mat-card-header>
                <mat-icon mat-card-avatar class="mat-48">verified_user</mat-icon>
                <mat-card-title>Authenticated</mat-card-title>
                <mat-card-subtitle>{{ user.name }} &lt;{{ user.email }}&gt;</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
                <mat-nav-list class="mat-card-inset">

                    <a mat-list-item [routerLink]="['/']">
                        <span matLine><strong>Dashboard</strong></span>
                        <mat-icon>arrow_forward</mat-icon>
                    </a>

                    <a mat-list-item (click)="logout()">
                        <span matLine>Logout</span>
                        <mat-icon>exit_to_app</mat-icon>
                    </a>
                </mat-nav-list>
            </mat-card-content>

        </mat-card>

        <!-- No User Card -->
        <ng-template #noUserCard>

            <mat-card>

                <mat-card-header>
                    <mat-icon mat-card-avatar>error</mat-icon>
                    <mat-card-title>No User Loaded</mat-card-title>
                    <mat-card-subtitle>
                        You have been authorised, but your information could not be loaded. Please try again.
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-actions class="actions-right">
                    <button mat-raised-button (click)="reloadUser()">Reload User</button>
                </mat-card-actions>

            </mat-card>

        </ng-template>

    </ng-container>

    <!-- Unauthenticated -->
    <mat-card *ngSwitchCase="'UNAUTHENTICATED'" style="min-width: 250px;">

        <mat-card-header>
            <mat-icon mat-card-avatar class="mat-48">security</mat-icon>
            <mat-card-title>Sign In</mat-card-title>
            <mat-card-subtitle>To continue to Culteva</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <p>You are not signed-in or your sign-in has expired.</p>
        </mat-card-content>

        <mat-card-actions class="text-right">
            <button mat-raised-button color="primary" (click)="login()">Sign In</button>
        </mat-card-actions>

    </mat-card>

    <mat-spinner *ngSwitchCase="'LOADING'" color="accent"></mat-spinner>

    <!-- Default -->
    <mat-card *ngSwitchDefault>

        <mat-card-header>
            <mat-icon mat-card-avatar>error</mat-icon>
            <mat-card-title>Authentication Error</mat-card-title>
            <mat-card-subtitle>Something went wrong while attempting to authenticate you. Please try again.
            </mat-card-subtitle>
        </mat-card-header>

        <mat-card-actions class="actions-right">
            <button mat-raised-button color="primary" (click)="retryAuthorization()">Retry</button>
        </mat-card-actions>

    </mat-card>

    <p class="text-light text-center">
        <small>
            Culteva&trade; is a product of Provarco (PTY) LTD.
            <a href="https://culteva.pro/terms" class="text-link" target="_blank">Terms and Conditions</a>
        </small>
    </p>

</ng-container>