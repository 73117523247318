import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '@core/data';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';

@Pipe({
    name: 'descSite',
})
export class DescribeSitePipe implements PipeTransform {

    constructor(private _sampleFormatter: SampleFormatter){}

    transform(site: Site, html = false, def = '') {

        if(!site){
            return def;
        }

        return this._sampleFormatter.describeSite(site, html);
    }
}
