<pv-markdown *ngIf="_text" class="pv-report-markdown" [markdown]="_text"></pv-markdown>
<div class="remark-icons no-print">
    <button mat-icon-button *ngIf="editable" [matMenuTriggerFor]="imageMenu" (click)="$event.stopPropagation();">
        <mat-icon
            [matBadge]="_images ? _images?.length : 0"
            matBadgeColor="primary"
            matBadgePosition="before">
            image
        </mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="documentMenu" (click)="$event.stopPropagation();" *ngIf="!(!editable && (_documents === undefined || _documents.length === 0))">
        <mat-icon
            [matBadge]="_documents ? _documents.length : 0"
            matBadgeColor="primary"
            matBadgePosition="before"
            [matBadgeHidden]="_documents === undefined || _documents.length === 0">
            attach_file
        </mat-icon>
    </button>
    <mat-icon *ngIf="editable">text_fields</mat-icon>
</div>

<mat-menu #documentMenu="matMenu">
    <button mat-menu-item *ngIf="editable" (click)="editDocuments()">
        <mat-icon>edit</mat-icon>
        <span>Edit Documents</span>
    </button>

    <!-- No documents -->
    <button disabled mat-menu-item *ngIf="_documents === undefined || _documents.length === 0">
        <mat-icon>quiz</mat-icon>
        <span>No Documents</span>
    </button>

    <!-- For each document add menu item -->
    <button mat-menu-item *ngFor="let doc of _documents" (click)="download(doc)">
        <mat-icon>{{ doc.extension | fileType }}</mat-icon>
        <span>{{doc.name}}</span>
    </button>
</mat-menu>

<mat-menu #imageMenu="matMenu">
    <button mat-menu-item *ngIf="editable" (click)="editImages()">
        <mat-icon>edit</mat-icon>
        <span>Edit Images</span>
    </button>

    <button disabled mat-menu-item *ngIf="_images === undefined || _images?.length === 0">
        <mat-icon>image_not_supported</mat-icon>
        <span>No Images</span>
    </button>
    <button [disabled]="!img.extension && !img.name" mat-menu-item *ngFor="let img of _images" (click)="download(img)">
        <ng-container *ngIf="img.extension && img.name; else invalidImage">
            <mat-icon>{{ img.extension | fileType }}</mat-icon>
            <span>{{img.name}}</span>
        </ng-container>
        <ng-template #invalidImage>
            <mat-icon>image_not_supported</mat-icon>
            <span>Resave Images</span>
        </ng-template>
    </button>
</mat-menu>