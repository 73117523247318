<pv-view #view
         *ngIf="state$ | async as state"
         toolbarTitle="Tasting Event Index">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools *ngIf="selectedOrg$ | async as selectedOrg">

        <button mat-icon-button
            matTooltip="Tasting Event Index Actions"
            [matMenuTriggerFor]="tastingEventIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #tastingEventIndexMenu>
            <button mat-menu-item (click)="navigateToSupport()">
                <mat-icon>help_outline</mat-icon>
                <span>Help</span>
            </button>
            <button mat-menu-item (click)="exportHistory(state.orgKey)">
                <mat-icon>history</mat-icon>
                <span>Export History</span>
            </button>
            <button mat-menu-item (click)="$event.stopPropagation(); openImportDialog(state.orgKey)">
                <mat-icon>publish</mat-icon>
                <span>Import Scores</span>
            </button>
        </mat-menu>
    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="tastings-event-selection-toolbar mat-elevation-z3" color="primary"
                 *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Tasting Events Selected
        </span>

        <button mat-flat-button matTooltip="Delete selected events" color="warn" (click)="deleteSelected()">
            <mat-icon>delete</mat-icon>
        </button>

        <button mat-flat-button matTooltip="Invite contacts to events" color="primary" (click)="inviteContacts(state.selectedData)">
            <mat-icon>mail</mat-icon>
        </button>

        <button disabled mat-flat-button (click)="createReport()" color="primary">
            <mat-icon>insert_chart</mat-icon>
            <span>Create Report</span>
        </button>

    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->

    <div pv-view-footer class="pv-table-footer tastings-events-footer">
        <label class="mat-caption mr-12" for="sample_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                id="sample_index_sort"
                class="tastings-event-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">{{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon
                    [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                    [pageSize]="state.page.size"
                    [pageIndex]="state.page.index"
                    (page)="paginate($event)"
                    [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>

    <!-- FAB -->
    <ng-container pv-view-fab *ngIf="selectedOrg$ | async as selectedOrg">
        <ng-container pv-view-fab *ngIf="selectedOrg?.access.role != 'guest'">
            <button pv-view-fab *ngIf="selection.isEmpty()" mat-fab color="primary" (click)="add()" matTooltip="Add Event">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-container>

    <!-- BODY STATUS: OK -->
    <div pv-view-body class="tastings-event-index-body" [ngSwitch]="state.status">


        <!-- STATUS MESSAGE: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Events..."></pv-view-loader>


        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'"
                         heading="No results"
                         message="The current filters does not match any Events. Update the filters or add an Event."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction
                    (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="add()">
                Add
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: INVALID -->
        <pv-view-message *ngSwitchCase="'INVALID'"
                         heading="Invalid Filter Parameters"
                         message="Unable to load data with given filter and sort parameters."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">
                Reload
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                         [message]="state.status | descStatusMessage:'Sample'" icon="error">
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">Reload</button>
        </pv-view-message>


        <!-- STATUS MESSAGE: OK (ngSwitch placeholder) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <div class="side-filter-container">
            <button class="filter-button" mat-icon-button matTooltip="{{showFilter ?  'Close Filter' : 'Filter options'}}" (click)="showFilter = !showFilter">
                <mat-icon *ngIf="!showFilter" matBadge="{{activeFilterCount}}" matBadgeHidden="{{activeFilterCount === 0}}"
                    matBadgePosition="before" matBadgeColor="accent" matBadgeSize="small">
                    filter_list
                </mat-icon>
                <mat-icon *ngIf="showFilter">close</mat-icon>
            </button>
            <div class="side-panel"  [ngClass]="{'slide-out': showFilter} ">
                <div class="side-panel-content">
                    <button mat-icon-button matTooltip="Reload index" (click)="reload()" id="reloadIndex">
                        <mat-icon>refresh</mat-icon>
                    </button>

                    <div class="filter-title">Filter Events:</div>

                    <form class="pv-tasting-event-query-form" [formGroup]="queryFormGroup">

                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>search</mat-icon> Search Events
                            </mat-label>
                            <input type="text" matInput formControlName="search"
                                    placeholder="Enter event code, venue or title">
                        </mat-form-field>

                        <mat-form-field [color]="primary" appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:tastings_sample"></mat-icon>
                                <span>Filter Tastings Sample(s)</span>
                            </mat-label>
                            <pv-tasting-sample-autocomplete-chips formControlName="tastingsSamples"
                                                        [orgKey]="state.orgKey">
                            </pv-tasting-sample-autocomplete-chips>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:flat_scion"></mat-icon>
                                <span>Filter Scion Cultivar(s)</span>
                            </mat-label>
                            <pv-cultivar-autocomplete-chips formControlName="scionCultivars"
                                                            [orgKey]="state.orgKey">
                            </pv-cultivar-autocomplete-chips>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon>place</mat-icon>
                                <span>Filter Location(s)</span>
                            </mat-label>
                            <pv-site-autocomplete-chips formControlName="sites"
                                                        [orgKey]="state.orgKey">
                            </pv-site-autocomplete-chips>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Crop</mat-label>
                            <mat-select formControlName="scionCropId" multiple>
                                <mat-option *ngFor="let crop of cropOptions" [value]="crop.id">
                                    <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                                    <span>{{ crop.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Licensee</mat-label>
                            <mat-select formControlName="scionLicensee" multiple>
                                <mat-option *ngFor="let licensee of state.licenseeOptions"
                                            [value]="licensee">{{ licensee }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">
                            <button mat-icon-button color="warn" (click)="resetFilter()">
                                <mat-icon matTooltip="Clear Filters">
                                    filter_alt_off
                                </mat-icon>
                            </button>
                        </div>
                    </form>

                    <ng-container *ngIf="showImportExport">
                        <ng-container>
                            <form [formGroup]="exportFormGroup" class="pv-form single-column" (ngSubmit)="export()">
                                <h4 class="form-subheader">Export</h4>

                                <mat-form-field appearance="standard">
                                    <mat-label>File Type</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option value="xlsx">Microsoft Excel (.xlsx)</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="form-actions">
                                    <button mat-flat-button color="accent" type="submit"
                                        [disabled]="exportFormGroup.disabled">Export</button>
                                </div>
                            </form>
                        </ng-container>

                        <ng-container>
                            <form [formGroup]="importFormGroup" class="pv-form single-column event-import-form" (ngSubmit)="attemptImport()">
                                <h4 class="form-subheader">Import</h4>

                                <pv-import-upload (file)="setFormFile($event)"></pv-import-upload>

                                <button mat-flat-button color="accent"
                                    class="event-import-form-button"
                                    matTooltip="Download Import Template"
                                    (click)="$event.preventDefault(); downloadImportTemplate()"
                                    [disabled]="importFormGroup.disabled">Import Template</button>

                                <button mat-flat-button color="accent" type="submit"
                                    class="event-import-form-button"
                                    matTooltip="Import Events"
                                    [disabled]="importFormGroup.disabled">Import</button>
                            </form>
                        </ng-container>
                    </ng-container>

                    <ng-container>
                        <div class="expand-container">
                            <button
                                (click)="showImportExport = !showImportExport"
                                mat-icon-button
                                [matTooltip]="showImportExport ? 'Less Options' : 'More Options'">
                                <mat-icon>{{ showImportExport ? 'expand_less' : 'expand_more' }}</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- PAGE DATA -->
        <ng-container *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <div class="pv-events-title">Upcoming Events</div>
            <div *ngIf="!nonCompletedEvents.length" class="no-events-text"><i>No upcoming events (click on the + to create a new event)</i></div>

            <div class="pv-tasting-card-container">
                <mat-card matTooltip="Click for more info" matTooltipPosition="above" class="pv-tasting-event-card mat-elevation-z5" *ngFor="let event of nonCompletedEvents" (click)="moreInfo(event)" [ngClass]="{'card-selected': isSelected(event)} ">
                    <img mat-card-image class="pv-tasting-event-image" *ngIf="(event.tastingsEventImage?.fileRef | imageFileRef:bannerImageOptions) as src; else defaultBanner" alt="Event banner"
                    [src]='src'>
                    <ng-template #defaultBanner>
                        <img mat-card-image class="pv-tasting-event-image-default" *ngIf="(defaultBannerPath | imageFileRef:bannerImageOptions) as srcDef" alt="Event banner"
                        [src]='srcDef'>
                    </ng-template>


                    <span [matTooltip]="'Change event image'" class="upload-image">
                        <mat-icon (click)="uploadEventImage(event); $event.stopPropagation();">upload_file</mat-icon>
                    </span>

                    <span [matTooltip]="isSelected(event) ? 'Unselect' : 'Select'" class="selection">
                        <mat-icon *ngIf="!isSelected(event)" (click)="select(event); $event.stopPropagation();">check_circle</mat-icon>
                        <mat-icon *ngIf="isSelected(event)" (click)="select(event); $event.stopPropagation();">remove_circle</mat-icon>
                    </span>

                    <mat-card-header class="pv-tasting-event-card-header">
                        <mat-card-title class="pv-tasting-event-card-title" matTooltip="{{event.title}}">{{event.title}}</mat-card-title>
                        <mat-card-subtitle class="pv-tasting-event-card-subtitle">{{event.code}}</mat-card-subtitle>
                    </mat-card-header>

                    <mat-card-content>
                        <div class="pv-tasting-event-card-details">
                            <span class="pv-tasting-events-maps-nav" (click)="$event.stopPropagation(); urlBuilder(event);"><mat-icon>place</mat-icon> {{event.venueName}}</span>
                            <span><mat-icon>event</mat-icon> {{ event.eventDate | date : 'fullDate' }}</span>
                            <span>
                                <mat-icon>alarm</mat-icon>
                                {{ event.eventStartTime ? (event.eventStartTime | date: 'HH:mm') : '--:--' }} - {{ event.eventEndTime ? (event.eventEndTime | date: 'HH:mm') : '--:--' }}
                            </span>
                        </div>
                    </mat-card-content>

                    <div [ngClass]="{'show' : show(event)}" class="overlay">
                        <div class="pv-event-info-container">
                            <div>
                                <div class="pv-tasting-event-subtitle-container">
                                    <h3 class="pv-tasting-event-info-subtitle"><mat-icon>description</mat-icon> Event Description</h3>
                                    <button mat-icon-button [matMenuTriggerFor]="eventMenu" aria-label="Event Options" (click)="$event.stopPropagation()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                </div>
                                <div class="pv-tasting-card-info-content">{{ event.description }}</div>
                            </div>

                            <mat-menu #eventMenu="matMenu">
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="downloadManifest(event); $event.stopPropagation();">
                                    <mat-icon>assignment</mat-icon>
                                    <span>Download Manifest</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); openOverview(event)">
                                    <mat-icon>summarize</mat-icon>
                                    <span>Event Overview</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); openResults(event)">
                                    <mat-icon>verified</mat-icon>
                                    <span>Event Results</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); exportResults(event)">
                                    <mat-icon>import_export</mat-icon>
                                    <span>Export Results</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); openImportDialog(state.orgKey, event.key)">
                                    <mat-icon>publish</mat-icon>
                                    <span>Import Scores</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); downloadReimportTemplate(event)">
                                    <mat-icon>download</mat-icon>
                                    <span>Extract Data For Reimport</span>
                                </button>
                                <button mat-menu-item color="primary" [routerLink]="['/org', state.orgKey, 'tastings-scores', 'event', event.key]">
                                    <mat-icon>score</mat-icon>
                                    <span>View Scores</span>
                                </button>
                            </mat-menu>

                            <div>
                                <div class="pv-tasting-event-subtitle-container">
                                    <h3 class="pv-tasting-event-info-subtitle"><mat-icon svgIcon="common:tastings_sample"></mat-icon> Tasting Samples</h3>
                                </div>
                                <div class="pv-tasting-card-info-content">
                                    <div *ngFor="let ts of event.tastingsSamples" class="pv-tasting-card-info-content-item">
                                        <div class="item-title-container" [routerLink]="['/org', event.ownerOrgKey, 'tastings-samples', {sampleCode: ts.code}]">
                                            <span class="item-title"><mat-icon svgIcon="common:tastings_sample"></mat-icon> {{ts.label}}</span>
                                            <span class="item-subtitle">{{ts.code}}</span>
                                        </div>
                                        <ng-container *ngIf="ts.harvestSample">
                                            <div class="item-info">
                                                <div class="item-info-container">
                                                    <span><mat-icon svgIcon="common:harvest_sample"></mat-icon> {{ts?.harvestSample?.label}}</span>
                                                    <span class="item-subtitle">{{ts?.harvestSample?.code}}</span>
                                                    <span>Harvested: {{ts?.birthDate}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <mat-card-actions class="pv-tasting-event-card-actions">
                        <button mat-icon-button matTooltip="Mark as complete" color="primary" (click)="$event.stopPropagation(); markEventComplete(event)">
                            <mat-icon>event_available</mat-icon>
                        </button>


                        <button mat-icon-button matTooltip="Edit" (click)="$event.stopPropagation(); edit(event);" class="pv-event-info">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button matTooltip="Delete Event" color="warn" (click)="delete(event); $event.stopPropagation();">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="pv-events-title">Completed Events</div>
            <div *ngIf="!completedEvents.length" class="no-events-text"><i>No completed events (events you mark as complete will show here)</i></div>

            <div class="pv-tasting-card-container">
                <mat-card matTooltip="Click for more info" matTooltipPosition="above" class="pv-tasting-event-card mat-elevation-z5" *ngFor="let event of completedEvents" (click)="moreInfo(event)" [ngClass]="{'card-selected': isSelected(event)} ">
                    <img mat-card-image class="pv-tasting-event-image" *ngIf="(event.tastingsEventImage?.fileRef | imageFileRef:bannerImageOptions) as src; else defaultBanner" alt="Event banner"
                    [src]='src'>
                    <ng-template #defaultBanner>
                        <img mat-card-image class="pv-tasting-event-image-default" *ngIf="(defaultBannerPath | imageFileRef:bannerImageOptions) as srcDef" alt="Event banner"
                        [src]='srcDef'>
                    </ng-template>


                    <span [matTooltip]="'Change event image'" class="upload-image">
                        <mat-icon (click)="uploadEventImage(event); $event.stopPropagation();">upload_file</mat-icon>
                    </span>

                    <span [matTooltip]="isSelected(event) ? 'Unselect' : 'Select'" class="selection">
                        <mat-icon *ngIf="!isSelected(event)" (click)="select(event); $event.stopPropagation();">check_circle</mat-icon>
                        <mat-icon *ngIf="isSelected(event)" (click)="select(event); $event.stopPropagation();">remove_circle</mat-icon>
                    </span>

                    <mat-card-header class="pv-tasting-event-card-header">
                        <mat-card-title class="pv-tasting-event-card-title" matTooltip="{{event.title}}">{{event.title}}</mat-card-title>
                        <mat-card-subtitle class="pv-tasting-event-card-subtitle">{{event.code}}</mat-card-subtitle>
                    </mat-card-header>

                    <mat-card-content>
                        <div class="pv-tasting-event-card-details">
                            <span class="pv-tasting-events-maps-nav" (click)="$event.stopPropagation(); urlBuilder(event);"><mat-icon>place</mat-icon> {{event.venueName}}</span>
                            <span><mat-icon>event</mat-icon> {{ event.eventDate | date : 'fullDate' }}</span>
                            <span>
                                <mat-icon>alarm</mat-icon>
                                {{ event.eventStartTime ? (event.eventStartTime | date: 'HH:mm') : '--:--' }} - {{ event.eventEndTime ? (event.eventEndTime | date: 'HH:mm') : '--:--' }}
                            </span>
                        </div>
                    </mat-card-content>

                    <div [ngClass]="{'show' : show(event)}" class="overlay">
                        <div class="pv-event-info-container">
                            <div>
                                <div class="pv-tasting-event-subtitle-container">
                                    <h3 class="pv-tasting-event-info-subtitle"><mat-icon>description</mat-icon> Event Description</h3>
                                    <button mat-icon-button [matMenuTriggerFor]="eventMenuComplete" aria-label="Event Options" (click)="$event.stopPropagation()">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                </div>
                                <div class="pv-tasting-card-info-content">{{ event.description }}</div>
                            </div>

                            <mat-menu #eventMenuComplete="matMenu">
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="downloadManifest(event); $event.stopPropagation();">
                                    <mat-icon>assignment</mat-icon>
                                    <span>Download Manifest</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); openOverview(event)">
                                    <mat-icon>summarize</mat-icon>
                                    <span>Event Overview</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); openResults(event)">
                                    <mat-icon>verified</mat-icon>
                                    <span>Event Results</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); exportResults(event)">
                                    <mat-icon>import_export</mat-icon>
                                    <span>Export Results</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); markEventComplete(event)">
                                    <mat-icon>event_busy</mat-icon>
                                    <span>Mark as active</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); downloadReimportTemplate(event)">
                                    <mat-icon>download</mat-icon>
                                    <span>Extract Data For Reimport</span>
                                </button>
                                <button mat-menu-item disabled="{{ event.tastingsSamples.length == 0 ? true : false}}" color="primary" (click)="$event.stopPropagation(); openImportDialog(state.orgKey, event.key)">
                                    <mat-icon>publish</mat-icon>
                                    <span>Import Scores</span>
                                </button>
                            </mat-menu>

                            <div>
                                <div class="pv-tasting-event-subtitle-container">
                                    <h3 class="pv-tasting-event-info-subtitle"><mat-icon svgIcon="common:tastings_sample"></mat-icon> Tasting Samples</h3>
                                </div>
                                <div class="pv-tasting-card-info-content">
                                    <div *ngFor="let ts of event.tastingsSamples" class="pv-tasting-card-info-content-item">
                                        <div class="item-title-container" [routerLink]="['/org', event.ownerOrgKey, 'tastings-samples', {sampleCode: ts.code}]">
                                            <span class="item-title"><mat-icon svgIcon="common:tastings_sample"></mat-icon> {{ts.label}}</span>
                                            <span class="item-subtitle">{{ts.code}}</span>
                                        </div>
                                        <ng-container *ngIf="ts.harvestSample">
                                            <div class="item-info">
                                                <div class="item-info-container">
                                                    <span><mat-icon svgIcon="common:harvest_sample"></mat-icon> {{ts?.harvestSample?.label}}</span>
                                                    <span class="item-subtitle">{{ts?.harvestSample?.code}}</span>
                                                    <span>Harvested: {{ts?.birthDate}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <mat-card-actions class="pv-tasting-event-card-actions">
                        <button mat-icon-button matTooltip="View Scores" color="primary" [routerLink]="['/org', state.orgKey, 'tastings-scores', 'event', event.key]">
                            <mat-icon>score</mat-icon>
                        </button>


                        <button mat-icon-button matTooltip="Edit" (click)="$event.stopPropagation(); edit(event);" class="pv-event-info">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button mat-icon-button matTooltip="Delete Event" color="warn" (click)="delete(event); $event.stopPropagation();">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </ng-container>
        <!-- PAGE DATA END -->


    </div>
</pv-view>