import { Evaluation, EvaluationService, ImagesUpdateRequest, Status, translateCommonErrorStatus, SampleImage, TastingEventService, TastingsEventImagesUpdateRequest, TastingSampleImagesUpdateRequest, TastingSampleService } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { of } from 'rxjs';
import { TastingEvent, TastingsEventImage } from '@core/data/types/tastings-event';
import { TastingSample, TastingSampleImage } from '@core/data/types/tasting-sample';
import { Injectable } from '@angular/core';

export interface ImagesFormStateModel {
    key: string;
    status: Status;
    data: Evaluation | TastingEvent | TastingSample;
    type: string;
    uploads: PendingUploadModel[];
}

export interface PendingUploadModel {
    id: number;
    loaded: number;
    total: number;
    status: Status;
    name: string;
    data: SampleImage | TastingsEventImage | TastingSampleImage;
}

enum ImageUploadFormStateType {
    SAMPLE_EVALUATION = 'sampleEvaluation',
    TASTINGS_EVENT = 'tastingsEvent',
    TASTINGS_SAMPLE = 'tastingsSample',
}

export class InitImagesForm {
    static readonly type = "[ImagesForm] Init";
    constructor(public evalKey: string){}
}
export class InitTastingEventImagesForm {
    static readonly type = "[TastingEventImagesForm] Init";
    constructor(public eventKey: string){}
}

export class InitTastingSampleImagesForm {
    static readonly type = "[TastingSampleImagesForm] Init";
    constructor(public sampleKey: string){}
}

export class SubmitImagesForm {
    static readonly type = "[ImagesForm] Submit";
    constructor(public payload: ImagesUpdateRequest){}
}

export class SubmitTastingEventImagesForm {
    static readonly type = "[TastingEventImagesForm] Submit";
    constructor(public payload: TastingsEventImagesUpdateRequest){}
}

export class SubmitTastingSampleImagesForm {
    static readonly type = "[TastingSampleImagesForm] Submit";
    constructor(public payload: TastingSampleImagesUpdateRequest){}
}

export class UploadImage {
    static readonly type = "[ImagesForm] Upload";
    constructor(public file: File){}
}

export class UploadTastingsEventImage {
    static readonly type = "[TastingEventImagesForm] Upload";
    constructor(public file: File){}
}

export class UploadTastingSampleImage {
    static readonly type = "[TastingSampleImagesForm] Upload";
    constructor(public file: File){}
}

@State<ImagesFormStateModel>({
    name: "images_form",
    defaults: {
        key: null,
        status: Status.UNINITIALIZED,
        data: null,
        type: null,
        uploads: []
    }
})
@Injectable()
export class ImagesFormState {

    @Selector()
    static data(state: ImagesFormStateModel){
        return state.data;
    }

    @Selector()
    static uploads(state: ImagesFormStateModel){
        return state.uploads;
    }

    constructor(
        private _evalService: EvaluationService,
        private _tastingService: TastingEventService,
        private _tastingSampleService: TastingSampleService,
    ){}

    @Action(InitImagesForm)
    initImagesForm(ctx: StateContext<ImagesFormStateModel>, action: InitImagesForm){

        ctx.setState({
            key: action.evalKey,
            status: Status.LOADING,
            data: null,
            type: ImageUploadFormStateType.SAMPLE_EVALUATION,
            uploads: []
        });

        return this._evalService
            .get(action.evalKey, {related: ['images', 'sample']})
            .pipe(tap(
                result => {
                    ctx.patchState({
                        data: result,
                        status: Status.OK
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(InitTastingEventImagesForm)
    initTastingEventImagesForm(ctx: StateContext<ImagesFormStateModel>, action: InitTastingEventImagesForm){

        ctx.setState({
            key: action.eventKey,
            status: Status.OK,
            data: null,
            type: ImageUploadFormStateType.TASTINGS_EVENT,
            uploads: []
        });

        return this._tastingService
            .get(action.eventKey, {related: ['tastingsEventImage']})
            .pipe(tap(
                result => {
                    ctx.patchState({
                        data: result,
                        status: Status.OK
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(InitTastingSampleImagesForm)
    initTastingSampleImagesForm(ctx: StateContext<ImagesFormStateModel>, action: InitTastingSampleImagesForm){

        ctx.setState({
            key: action.sampleKey,
            status: Status.OK,
            data: null,
            type: ImageUploadFormStateType.TASTINGS_SAMPLE,
            uploads: []
        });

        return this._tastingSampleService
            .get(action.sampleKey, {related: ['tastingsSampleImage']})
            .pipe(tap(
                result => {
                    ctx.patchState({
                        data: result,
                        status: Status.OK
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(SubmitImagesForm)
    submitImagesForm(ctx: StateContext<ImagesFormStateModel>, action: SubmitImagesForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._evalService
            .update(state.key, action.payload)
            .pipe(tap(
                result => {
                    const state = ctx.getState();
                    ctx.patchState({
                        status: Status.COMPLETE,
                        key: result.key,
                        data: {
                            ...state.data,
                            ...result
                        },
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(SubmitTastingEventImagesForm)
    submitTastingEventImagesForm(ctx: StateContext<ImagesFormStateModel>, action: SubmitTastingEventImagesForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._tastingService
            .updateImage(state.key, action.payload)
            .pipe(tap(
                result => {
                    const state = ctx.getState();
                    ctx.patchState({
                        status: Status.COMPLETE,
                        key: result.key,
                        data: {
                            ...state.data,
                            ...result
                        },
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(SubmitTastingSampleImagesForm)
    submitTastingSampleImagesForm(ctx: StateContext<ImagesFormStateModel>, action: SubmitTastingSampleImagesForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._tastingSampleService
            .updateImage(state.key, action.payload)
            .pipe(tap(
                result => {
                    const state = ctx.getState();
                    ctx.patchState({
                        status: Status.COMPLETE,
                        key: result.key,
                        data: {
                            ...state.data,
                            ...result
                        },
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(UploadImage)
    uploadImage(ctx: StateContext<ImagesFormStateModel>, action: UploadImage){

        const state = ctx.getState();

        let upload: PendingUploadModel = {
            id: Math.round(Math.random() * 10000000),
            loaded: 0,
            total: action.file.size,
            status: Status.LOADING,
            name: action.file.name,
            data: null
        };

        ctx.patchState({
            uploads: [...state.uploads, upload]
        });

        return this._evalService.uploadImage(state.key, action.file)
            .pipe(tap(
                event => {
                    const state = ctx.getState();

                    console.info('ImagesFormState: Upload event ', event);

                    if(state.status !== Status.OK) return;

                    // upload progress
                    if(event.type === HttpEventType.UploadProgress){

                        upload = {
                            ...upload,
                            loaded: event.loaded,
                            total: event.total,
                        };

                        this.patchUpload(ctx, upload);

                    // ok response
                    }else if(event instanceof HttpResponse){

                        upload = {
                            ...upload,
                            status: Status.COMPLETE,
                            data: event.body
                        };
                        this.patchUpload(ctx, upload);

                    }

                },
                error => {

                    console.warn('ImagesFormState: Upload error ', error);

                    upload = {
                        ...upload,
                        status: translateCommonErrorStatus(error)
                    };

                    this.patchUpload(ctx, upload);

                }
            ), catchError(e => of(null)));

    }

    @Action(UploadTastingsEventImage)
    uploadTastingsEventImage(ctx: StateContext<ImagesFormStateModel>, action: UploadTastingsEventImage){

        const state = ctx.getState();

        let upload: PendingUploadModel = {
            id: Math.round(Math.random() * 10000000),
            loaded: 0,
            total: action.file.size,
            status: Status.LOADING,
            name: action.file.name,
            data: null
        };

        ctx.patchState({
            uploads: [...state.uploads, upload]
        });

        return this._tastingService.uploadImage(state.key, action.file)
            .pipe(tap(
                event => {
                    const state = ctx.getState();

                    console.info('ImagesFormState: Upload event ', event);

                    if(state.status !== Status.OK) return;

                    // upload progress
                    if(event.type === HttpEventType.UploadProgress){

                        upload = {
                            ...upload,
                            loaded: event.loaded,
                            total: event.total,
                        };

                        this.patchUpload(ctx, upload);

                    // ok response
                    }else if(event instanceof HttpResponse){

                        upload = {
                            ...upload,
                            status: Status.COMPLETE,
                            data: event.body
                        };
                        this.patchUpload(ctx, upload);

                    }

                },
                error => {

                    console.warn('ImagesFormState: Upload error ', error);

                    upload = {
                        ...upload,
                        status: translateCommonErrorStatus(error)
                    };

                    this.patchUpload(ctx, upload);

                }
            ), catchError(e => of(null)));

    }

    @Action(UploadTastingSampleImage)
    uploadTastingSampleImage(ctx: StateContext<ImagesFormStateModel>, action: UploadTastingSampleImage){

        const state = ctx.getState();

        let upload: PendingUploadModel = {
            id: Math.round(Math.random() * 10000000),
            loaded: 0,
            total: action.file.size,
            status: Status.LOADING,
            name: action.file.name,
            data: null
        };

        ctx.patchState({
            uploads: [...state.uploads, upload]
        });

        return this._tastingSampleService.uploadImage(state.key, action.file)
            .pipe(tap(
                event => {
                    const state = ctx.getState();

                    console.info('ImagesFormState: Upload event ', event);

                    if(state.status !== Status.OK) return;

                    // upload progress
                    if(event.type === HttpEventType.UploadProgress){

                        upload = {
                            ...upload,
                            loaded: event.loaded,
                            total: event.total,
                        };

                        this.patchUpload(ctx, upload);

                    // ok response
                    }else if(event instanceof HttpResponse){

                        upload = {
                            ...upload,
                            status: Status.COMPLETE,
                            data: event.body
                        };
                        this.patchUpload(ctx, upload);

                    }

                },
                error => {

                    console.warn('ImagesFormState: Upload error ', error);

                    upload = {
                        ...upload,
                        status: translateCommonErrorStatus(error)
                    };

                    this.patchUpload(ctx, upload);

                }
            ), catchError(e => of(null)));

    }

    private patchUpload(ctx: StateContext<ImagesFormStateModel>, upload: PendingUploadModel){

        const state = ctx.getState();

        let uploads = state.uploads.map(existing => {
            if(existing.id === upload.id) {

                return upload;
            }
            return existing;
        });


        ctx.patchState({ uploads });

    }

}