import { Injectable } from '@angular/core';
import { MapPolygonDirective } from './map-polygon.directive';


@Injectable()
export class MapPolygonRef {

    private _polygon: MapPolygonDirective = null;

    public use(polygon: MapPolygonDirective) {
        this._polygon = polygon;
    }

    public getPolygon(){
        return this._polygon;
    }

}