import { Category } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_CATEGORIES } from './categories';

export class CategoryDictionary extends Dictionary<Category>{
    constructor(){
        super(LIB_CATEGORIES);
    }
}

export * from './_types';