import { TastingContactIndexQuery } from "./tasting-contact-index.state-model";

export class InitTastingContactIndex {
    static readonly type = "[TastingContactIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadTastingContactIndex {
    static readonly type = "[TastingContactIndex] Load";
    constructor() {}
}

export class PageTastingContactIndex {
    static readonly type = "[TastingContactIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingContactIndex {
    static readonly type = "[TastingContactIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingContactIndex {
    static readonly type = "[TastingContactIndex] Query";
    constructor(public query: TastingContactIndexQuery) {}
}

export class ClearTastingContactIndexFilter {
    static readonly type = "[TastingContactIndex] Clear Filter";
    constructor() {}
}

export class ExportTastingContactIndex {
    static readonly type = "[TastingContactIndex] Export";
    constructor() {}
}

export class SetTastingContactIndexSelected {
    static readonly type = "[TastingContactIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingContact {
    static readonly type = "[TastingContactIndex] Delete Tasting Contact";
    constructor(public key: string) {}
}