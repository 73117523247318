import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { InitModelSelect, ModelSelectState, ModelSelectStateModel, SearchModelSelect } from './model-select.state';

@Component({
    templateUrl: 'model-select.dialog.html',
    styleUrls: [
        'model-select.theme.scss',
    ],
})
export class ModelSelectDialog implements OnInit {

    public static RESULT_DEFER_NEW = 'RESULT_DEFER_NEW';
    public static RESULT_CLEAR = 'RESULT_CLEAR';

    searchControl = new FormControl('');

    @Select(ModelSelectState)
    state$: Observable<ModelSelectStateModel>;

    private _destroy$ = new Subject();

    constructor(
        public dialogRef: MatDialogRef<ModelSelectDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ModelSelectDialogData,
        private _store: Store,
    ) {}

    ngOnInit() {

        this._store.dispatch(new InitModelSelect(this.data));

        this.searchControl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                distinctUntilChanged(),
                debounceTime(300),
            ).subscribe((search: string) => {
                this._store.dispatch(new SearchModelSelect(search));
            });

    }

    select(model: any){
        this.dialogRef.close(model);
    }

    cancel() {
        this.dialogRef.close(null);
    }

    refresh() {
        this._store.dispatch(new InitModelSelect(this.data));
    }

    modelHint(model: string) {

        switch (model) {

            case 'site':
                return 'Find by block, site name or address.';

            case 'cultivar':
                return 'Find by common name, crop, import ID, selection ID or licensee.';

            default:
                return '';
        }
    }

    onDestroy() {
        this._destroy$.complete();
        this._destroy$.next();
    }
}

export interface ModelSelectDialogData {
    orgKey: string;
    type: string;
}