import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization, OrganizationFormRequest, Status } from '@core/data';
import { Snackbar } from '@core/material';
import { COUNTRIES } from '@core/utils/countries';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InitOrganizationForm, OrganizationFormState, OrganizationFormStateModel, SubmitOrganizationForm } from './organization-form.state';
import { fileExtension } from '@core/utils';

@Component({
    selector: 'pv-organization-form-dialog',
    templateUrl: './organization-form.dialog.html',
})
export class OrganizationFormDialog implements OnInit, OnDestroy {

    @Select(OrganizationFormState)
    state$: Observable<OrganizationFormStateModel>;

    @Select(OrganizationFormState.data)
    private data$: Observable<Organization>;

    maxSize = 4000000; // 4MB
    allowedExts = ['jpg', 'jpeg', 'png'];
    validationErrors: string[] = [];

    _logoFileControl: FormControl = new FormControl(null);
    businessControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(64)]);
    addrStreetControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(128)]);
    addrCityControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(64)]);
    addrRegionControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(64)]);
    addrCountryControl = new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]);
    addrCodeControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]);
    websiteControl: FormControl = new FormControl(null, []);

    formGroup: FormGroup = new FormGroup({
        _logoFile: this._logoFileControl,
        business: this.businessControl,
        addrStreet: this.addrStreetControl,
        addrCity: this.addrCityControl,
        addrRegion: this.addrRegionControl,
        addrCountry: this.addrCountryControl,
        addrCode: this.addrCodeControl,
        website: this.websiteControl,
    });

    countries = COUNTRIES;

    private _destroy$ = new Subject<void>();

    constructor(
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: MatDialogRef<OrganizationFormDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: OrganizationFormDialogData
    ) { }

    ngOnInit() {

        this._store.dispatch(new InitOrganizationForm(this._data.key));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) {
                    this._snackbar.info('Organization updated');
                    this._dialog.close(state.data);
                } else if (state.status === Status.LOADING) {
                    this.formGroup.disable();
                } else {
                    this.formGroup.enable();
                }
            });

        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {

                if (!data) return;

                this.formGroup.reset({
                    business: data.business,
                    addrStreet: data.addrStreet,
                    addrCity: data.addrCity,
                    addrRegion: data.addrRegion,
                    addrCountry: data.addrCountry,
                    addrCode: data.addrCode,
                    website: data.website,
                    _logoFile: null,
                });
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt(key: string) {

        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid) {
            let value = this.formGroup.value;

            let request: OrganizationFormRequest = {
                ...value,
            };

            this._store.dispatch(new SubmitOrganizationForm(request));

        } else {
            this._snackbar.error("Check your input and try again.");
        }
    }

    cancel() {
        this._dialog.close();
    }

    setFormFile(file: File) {
        this.formGroup.get('_logoFile').setValue(file ? file : null);
    }

}

export interface OrganizationFormDialogData {
    key: string;
}