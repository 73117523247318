import { Characteristic } from './_types';
import { Dictionary } from '../dictionary';

import { LIB_CHARS_FRUT_TREE } from './chars_frut_tree';
import { LIB_CHARS_FRUT_HARVEST } from './chars_frut_harvest';
import { LIB_CHARS_GRAPE_HARVEST } from './chars_grape_harvest';
import { LIB_CHARS_RECO } from './chars_reco';
import { LIB_CHARS_GRAPE_VINE } from './chars_grape_vine';
import { LIB_CHARS_FLOWER_PLANT } from './chars_flower_plant';
import { LIB_CHARS_NUT_TREE } from './chars_nut_tree';
import { LIB_CHARS_NUT_HARVEST } from './chars_nut_harvest';
import { LIB_CHARS_BERRY_HARVEST } from './chars_berry_harvest';
import { LIB_CHARS_ADVANCED_CITRUS_TREE } from './chars_advanced_citrus_tree';
import { LIB_CHARS_ADVANCED_CITRUS_HARVEST } from './chars_advanced_citrus_harvest';
import { LIB_CHARS_AVOCADO_TREE } from './chars_avocado_tree';
import { LIB_CHARS_AVOCADO_HARVEST } from './chars_avocado_harvest';


export class CharacteristicDictionary extends Dictionary<Characteristic>{
    constructor(){
        super([
            ...LIB_CHARS_FRUT_TREE,
            ...LIB_CHARS_FRUT_HARVEST,
            ...LIB_CHARS_GRAPE_VINE,
            ...LIB_CHARS_GRAPE_HARVEST,
            ...LIB_CHARS_RECO,
            ...LIB_CHARS_FLOWER_PLANT,
            ...LIB_CHARS_NUT_TREE,
            ...LIB_CHARS_NUT_HARVEST,
            ...LIB_CHARS_BERRY_HARVEST,
            ...LIB_CHARS_ADVANCED_CITRUS_TREE,
            ...LIB_CHARS_ADVANCED_CITRUS_HARVEST,
            ...LIB_CHARS_AVOCADO_TREE,
            ...LIB_CHARS_AVOCADO_HARVEST,
        ]);
    }
}

export * from './_types';
