import { Directive, Input, OnInit, OnDestroy } from "@angular/core";
import { TimelineComponent } from './timeline.component';
import { BehaviorSubject } from 'rxjs';




@Directive({
    selector: 'pv-timeline-group'
})
export class TimelineGroupDirective implements OnInit, OnDestroy{

    private static nextId = 0;
    public id: number = TimelineGroupDirective.nextId++;

    @Input()
    set content(content: string){
        this.mergeUpdate({content});
    }

    @Input()
    set tooltipTitle(title: string){
        this.mergeUpdate({title});
    }

    @Input()
    set subgroupStack(obj: any){
        this.mergeUpdate({subgroupStack: obj});
    }

    @Input()
    set subgroupVisibility(obj: any){
        this.mergeUpdate({subgroupVisibility: obj});
    }

    private _data$ = new BehaviorSubject<any>({id: this.id});

    constructor(
        private _timeline: TimelineComponent
    ){}

    ngOnInit(){

        this._data$.subscribe(data => this._timeline.groups.update(data));

    }

    ngOnDestroy(){
        this._data$.complete();
        this._timeline.groups.remove(this.id);
    }

    private mergeUpdate(data: any){
        this._data$.next({
            ...this._data$.getValue(),
            ...data
        });
    }

}