import { Pipe, PipeTransform } from "@angular/core";
import { TastingsIndex } from "library/src/tastings-indexes/_types";

@Pipe({
    name: 'descTags'
})
export class UserTagPipe implements PipeTransform {
    constructor() {}

    transform(tagName: string, tastingsIndex: TastingsIndex, index: number): string {
        if (!tastingsIndex || !tagName || !tastingsIndex.weightedChars[index]) return 'N/A';
        let tag = tastingsIndex.weightedChars[index].tags.find(charTag => charTag.id === tagName);
        return tag ? tag.label : "N/A";
    }
}