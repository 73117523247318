<pv-view toolbarTitle="Group Index" [returnTo]="['..']" *ngIf="state$ | async as state">
    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools>
        <button mat-icon-button [matMenuTriggerFor]="groupIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #groupIndexMenu>
            <button mat-menu-item (click)="addGroup(state.orgKey)" type="button">
                <mat-icon>add</mat-icon>
                <span>Add Group</span>
            </button>
            <button mat-menu-item (click)="reloadGroups()" type="button">
                <mat-icon>refresh</mat-icon>
                <span>Refresh Group Data</span>
            </button>
            <button mat-menu-item (click)="reset()" type="button">
                <mat-icon>block</mat-icon>
                <span>Clear Filters</span>
            </button>
        </mat-menu>
    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SIDEBAR -->
    <div pv-view-sidebar>
        <form [formGroup]="filterFormGroup" class="pv-form single-column p-12 cultivar-index-filter-form">
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" matInput formControlName="search">
                <mat-hint>Find by name</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Filter Type</mat-label>
                <mat-select formControlName="type" multiple>
                    <mat-option *ngFor="let type of typeOptions" [value]="type.value">
                        <span>{{ type.name }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="form-actions">
                <button mat-button
                        type="button"
                        color="warn"
                        (click)="reset()">
                    Clear Filters
                </button>
            </div>
        </form>
    </div>
    <!-- SIDEBAR END -->

    <!-- PAGINATION FOOTER -->
    <div pv-view-footer class="pv-table-footer">
        <label class="mat-caption mr-12" for="group_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                    id="group_index_sort"
                    class="cultivar-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">
                {{ opt.label }}
            </mat-option>
        </mat-select>

        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                       [pageSize]="state.page.size"
                       [pageIndex]="state.page.index"
                       (page)="paginate($event)"
                       [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>
    <!-- PAGINATION FOOTER END -->

    <!-- FAB -->
    <button pv-view-fab mat-fab color="primary" matTooltip="Add Group" (click)="addGroup(state.orgKey)">
        <mat-icon>add</mat-icon>
    </button>

    <!-- BODY -->
    <div pv-view-body class="cultivar-index-body" [ngSwitch]="state.status">
        <!-- STATUS OVERLAY: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Groups..."></pv-view-loader>

        <!-- STATUS MESSAGE: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'" heading="No results"
                            message="Current filters does not match any Groups. Update filters or add a Group."
                            icon="help">
            <button mat-button color="accent" pvMessageAction (click)="reset()">
                Clear Filters
            </button>
            <button mat-button color="primary" pvMessageAction (click)="addGroup(state.orgKey)">
                Add Group
            </button>
        </pv-view-message>

        <!-- STATUS MESSAGE: ERROR -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                            [message]="state.status | descStatusMessage:'Groups'" icon="error">
            <button mat-button
                    color="primary"
                    pvMessageAction
                    (click)="reloadGroups()">Reload</button>
        </pv-view-message>

        <!-- ngSwitch placeholders -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <!-- GROUP TABLE -->
        <div class="pv-cultivar-table pv-responsive-table" *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <table
                   cdk-table
                   [dataSource]="state.data"
                   matSort
                   matSortDisableClear="true"
                   (matSortChange)="sort($event)"
                   [matSortActive]="state.sort.column"
                   [matSortDirection]="state.sort.order">

                <ng-container cdkColumnDef="label">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>Label</th>
                    <td cdk-cell *cdkCellDef="let row" [routerLink]="['/org', row.ownerOrgKey, 'samples', row.type, {groupKey: row.key}]" style="cursor: pointer;">
                        <span class="title-line">
                            <mat-icon class="mat-18" [svgIcon]="'common:' + row.type + '_sample'">
                            </mat-icon>
                            {{row.label}}
                        </span>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="description">
                    <th cdk-header-cell *cdkHeaderCellDef>Description</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.description }}</td>
                </ng-container>

                <ng-container cdkColumnDef="protocols">
                    <th cdk-header-cell *cdkHeaderCellDef>Protocol Filters</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <button mat-icon-button matTooltip="Protocol Filters"
                                [matMenuTriggerFor]="protocolsMenu"
                                [matMenuTriggerData]="{ group: row }">
                            <mat-icon
                                [matBadge]="row.protocolsCount"
                                [matBadgeColor]="row.protocolsCount ? 'primary' : 'accent'">
                                ballot
                            </mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="samples">
                    <th cdk-header-cell *cdkHeaderCellDef>Samples</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <button mat-icon-button matTooltip="Samples"
                            [matMenuTriggerFor]="samplesMenu"
                            [matMenuTriggerData]="{ group: row }">
                            <mat-icon svgIcon="common:{{ row.type }}_sample"
                                [matBadge]="row.samplesCount"
                                [matBadgeColor]="row.samplesCount ? 'primary' : 'accent'">
                            </mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="users">
                    <th cdk-header-cell *cdkHeaderCellDef>Users</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <button mat-icon-button matTooltip="Users"
                            [matMenuTriggerFor]="usersMenu"
                            [matMenuTriggerData]="{ group: row }">
                            <mat-icon [matBadge]="row.usersCount" [matBadgeColor]="row.usersCount ? 'primary' : 'accent'">
                                person
                            </mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="createdAt">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>
                        <mat-icon matTooltip="Created At">access_time</mat-icon>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <small>{{ row.createdAt | date:'mediumDate' }}</small>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                    <th cdk-header-cell *cdkHeaderCellDef></th>
                    <td cdk-cell *cdkCellDef="let row">
                        <button mat-icon-button
                                matTooltip="Group Actions"
                                [matMenuTriggerFor]="groupMenu"
                                [matMenuTriggerData]="{group: row}">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr cdk-header-row *cdkHeaderRowDef="state.displayColumns"></tr>
                <tr cdk-row *cdkRowDef="let row; columns: state.displayColumns"></tr>
            </table>
        </div>
        <!-- GROUP TABLE END -->

        <!-- GROUP ACTION MENUS -->
        <mat-menu #groupMenu>
            <ng-template matMenuContent let-group="group">
                <button mat-menu-item (click)="addGroup(state.orgKey, group)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Group</span>
                </button>
                <button mat-menu-item (click)="removeGroup(group)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Group</span>
                </button>
            </ng-template>
        </mat-menu>
        <mat-menu #protocolsMenu="matMenu">
            <ng-template matMenuContent let-group="group">
                <pv-organization-group-protocl-filter
                    [orgKey]="state.orgKey"
                    [group]="group">
                </pv-organization-group-protocl-filter>
            </ng-template>
        </mat-menu>
        <mat-menu #samplesMenu="matMenu">
            <ng-template matMenuContent let-group="group">
                <pv-organization-group-sample-list
                    [orgKey]="state.orgKey"
                    [group]="group">
                </pv-organization-group-sample-list>
            </ng-template>
        </mat-menu>
        <mat-menu #usersMenu="matMenu">
            <ng-template matMenuContent let-group="group">
                <pv-organization-group-user-list
                    [orgKey]="state.orgKey"
                    [group]="group">
                </pv-organization-group-user-list>
            </ng-template>
        </mat-menu>
        <!-- GROUP ACTION MENUS END -->

    </div>
    <!-- BODY END -->
</pv-view>
