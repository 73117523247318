import { Cultivar } from './cultivar';
import { Evaluation, SampleImage } from './evaluation';
import { Site } from './site';
import { TastingSample } from './tasting-sample';

export enum SampleType {
    PLANT = 'plant',
    HARVEST = 'harvest',
}

export interface Sample {

    type: SampleType;

    key: string;
    code: string;

    label: string | null;
    size: number | null;
    description: string | null;

    siteKey: string | null;
    scionCultivarKey: string | null;
    rootstockCultivarKey: string | null;
    plantSampleKey: string | null;

    primaryImageKey: string | null;

    ownerOrgKey: string;

    birthDate: string | null;
    deathDate: string | null;

    rowIndex: number | null;
    positionIndex: number | null;

    colDistance: number | null;
    rowDistance: number | null;

    // relationships
    evals?: Evaluation[];
    site?: Site;
    scionCultivar?: Cultivar;
    rootstockCultivar?: Cultivar;
    harvestSamples?: Sample[];
    plantSample?: Sample;
    images?: SampleImage[];
    primaryImage?: SampleImage;
    tastingSamples?: TastingSample[];

    //Tastings
    isGenerated?: boolean;

    createdAt: string;
    updatedAt: string;
    deletedAt: string;

}

export function isHarvest(value: Sample): boolean {
    return value.type === SampleType.HARVEST;
}

export function isPlant(value: Sample): boolean {
    return value.type === SampleType.PLANT;
}
