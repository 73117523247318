import { TastingsEvaluationsIndexQuery } from "./tastings-evaluations-index.state-model";

export class InitTastingsEvaluationsIndex {
    static readonly type = "[TastingsEvaluationsIndex] Init"
    constructor(public orgKey: string, public type: string, public subjectKey: string) {}
}

export class LoadTastingsEvaluationsIndex {
    static readonly type = "[TastingsEvaluationsIndex] Load";
    constructor() {}
}

export class PageTastingsEvaluationsIndex {
    static readonly type = "[TastingsEvaluationsIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingsEvaluationsIndex {
    static readonly type = "[TastingsEvaluationsIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingsEvaluationsIndex {
    static readonly type = "[TastingsEvaluationsIndex] Query";
    constructor(public query: TastingsEvaluationsIndexQuery) {}
}

export class ClearTastingsEvaluationsIndexFilter {
    static readonly type = "[TastingsEvaluationsIndex] Clear Filter";
    constructor() {}
}

export class SetTastingsEvaluationIndexSelected {
    static readonly type = "[TastingsEvaluationsIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingsEvaluation {
    static readonly type = "[TastingsEvaluationsIndex] Delete Tastings Evaluation";
    constructor(public key: string) {}
}