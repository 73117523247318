import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthState } from "@app/auth";
import { Cultivar, FilterSort, Organization, Site, Status } from "@core/data";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { TastingSampleIndexState } from "./tastings-sample-index.state";
import { ClearTastingSampleIndexFilter, DeleteTastingSample, ExportTastingSampleIndex, InitTastingSampleIndex, LoadTastingSampleIndex, PageTastingSampleIndex, PushTastingSampleIndexModelQuery, QueryTastingSampleIndex, RemoveTastingsSamplesFromEvent, SetTastingSampleIndexSelected, SortTastingSampleIndex, UpdateTastingsSamplesToEvent } from "./tastings-sample-index.state-actions";
import { TastingSampleIndexQuery, TastingSampleIndexStateModel } from "./tastings-sample-index.state-model";
import { SelectionModel } from "@angular/cdk/collections";
import { FormControl, FormGroup } from "@angular/forms";
import { Crop, Library } from "@library";
import { LIBRARY } from "@app/evaluation/library";
import { debounceTime, takeUntil } from "rxjs/operators";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { Dialog, Snackbar } from "@core/material";
import { TastingSample } from "@core/data/types/tasting-sample";
import { TastingSampleFormDialog, TastingSampleFormDialogData } from "../tastings-sample-forms/tastings-sample-form.dialog";
import { TastingEvent } from "@core/data/types/tastings-event";
import { TastingsSampleLabelOptions } from "../tastings-sample-labels/tastings-sample-label-options";
import { TastingsSampleEventFormDialog, TastingsSampleEventFormDialogData } from "../tastings-sample-forms/tastings-sample-event-form.dialog";
import { ImagesFormDialog, ImagesFormDialogData, ImageUploadType } from "@app/evaluation/components/evaluation-form/images-form/images-form.dialog";
import { ExportHistoryDialog, ExportHistoryDialogData } from "@app/evaluation/components/export-history-form/export-history-form.dialog";
import { ExportType } from "@core/data/types/export-history";

@Component({
    selector: 'pv-tastings-sample-index-view',
    templateUrl: 'tastings-sample-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tastings-sample-index-view'
    }
})
export class TastingsSampleIndexView implements OnInit, OnDestroy {
    @Select(AuthState.selectedOrg)
	selectedOrg$: Observable<Organization>;

    @Select(TastingSampleIndexState)
    state$: Observable<TastingSampleIndexStateModel>;

    @Select(TastingSampleIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(TastingSampleIndexState.query)
    query$: Observable<TastingSampleIndexQuery>;

    @Select(TastingSampleIndexState.labelOptions)
    labelOptions$: Observable<TastingsSampleLabelOptions>;

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    // query form group
    scionCultivarsControl = new FormControl([]);
    sitesControl = new FormControl([]);
    searchControl = new FormControl();
    scionCropIdControl = new FormControl();
    scionLicenseeControl = new FormControl();
    tastingsEventControl = new FormControl([]);
    harvestSampleControl = new FormControl([]);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        tastingsEvent: this.tastingsEventControl,
        harvestSample: this.harvestSampleControl,
        scionCultivars: this.scionCultivarsControl,
        sites: this.sitesControl,
        scionCropId: this.scionCropIdControl,
        scionLicensee: this.scionLicenseeControl,
    });

    // label options form group
    filterSelectedLabels = false;
    labelOptionsFormGroup = new FormGroup({
        layout: new FormControl('landscape'),
        size: new FormControl('small'),
        includeFields: new FormControl([]),
        margin: new FormControl('5mm'),
        whitespace: new FormControl(false)
    });


    // export form group
    exportFormGroup = new FormGroup({
        type: new FormControl('xlsx'),
    });

    selectedTabIndex = 0;

    yearOptions: number[] = [];
    weekOptions: number[] = [];
    cropOptions: Crop[];
    eventOptions: TastingEvent[];

    selection = new SelectionModel<string>(true, []);

    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        @Inject(LIBRARY) private _library: Library,
    ) { }

    ngOnInit(): void {
        // make year options [current year - 20] to [current year + 1]
        let cYear = new Date().getFullYear();
        for (let i = (cYear - 20); i <= (cYear + 1); i++) {
            this.yearOptions.push(i);
        }
        this.yearOptions.reverse();

        // make week options 1-53
        for (let i = 1; i <= 53; i++) {
            this.weekOptions.push(i);
        }

        // setup crop options
        this.cropOptions = this._library.filterExcludedCrops()

        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(new InitTastingSampleIndex(params.get('orgKey')));
            })

        this.state$.subscribe(data => {
            if (data) {
                this.eventOptions = data.tastingEventOptions;
            }
        })

        // apply state changes to query form
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => this.queryFormGroup.patchValue(query, { emitEvent: false }));

        // apply state changes to sort form
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        // apply state changes to label options form
        this.labelOptions$.pipe(takeUntil(this._destroy$))
            .subscribe(labelOptions => this.labelOptionsFormGroup.patchValue(labelOptions, { emitEvent: false }));

        // handle sort changes
        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingSampleIndex(val.column, val.order));
            });

        // handle query changes
        this.queryFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new QueryTastingSampleIndex(val));
            });

        // handle label options changes
        /*this.labelOptionsFormGroup.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SetSampleLabelOptions(val));
            });*/

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                /*if (this.selection.selected.length > 0) {
                    this.filterSelectedLabels = true;
                } else {
                    this.filterSelectedLabels = false;
                }*/
                this._store.dispatch(new SetTastingSampleIndexSelected(this.selection.selected));
            });

        this._route.paramMap
            .subscribe(params => {
                if (params.get('sampleCode')) this.searchControl.setValue(params.get('sampleCode'));
            })
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    navigateToSupport() {
        window.open('https://culteva.hipporello.net/desk', '_blank');
    }

    reload() {
        this._store.dispatch(new LoadTastingSampleIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingSampleIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingSampleIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingSampleIndex('updatedAt', 'desc'));
        }
    }

    export() {
        let exportOptions = this.exportFormGroup.value;

        this.exportFormGroup.disable();

        this._store.dispatch(new ExportTastingSampleIndex(exportOptions.type))
            .subscribe(
                global => {
                    let exp = this._store.selectSnapshot(TastingSampleIndexState.latestExport);

                    if (exp && exp.status === Status.COMPLETE) {
                        this._snackbar.info(
                            `Export is queued. The result will be emailed to you once the export is complete.`,
                        );
                    }
                },
                e => {
                    this.exportFormGroup.enable();
                },
                () => {
                    this.exportFormGroup.enable();
                }
            );
    }

    exportHistory(orgKey: string) {
        const data: ExportHistoryDialogData = {
            orgKey: orgKey,
            type: ExportType.TASTING_SAMPLES,
        }

        this._dialog.open(ExportHistoryDialog, { data })
    }

    addModelFilter(model: Cultivar | Site, control: FormControl) {
        let list = control.value;

        // check for duplicate
        if (Array.isArray(list) && list.findIndex(m => m.key === model.key) !== -1) return;

        // append to filter
        control.setValue([...list, model]);
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingSampleIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
     isAllSelected(data: TastingSample[]) {
        return data.every((sample) => {
            return this.selection.isSelected(sample.key);
        });
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: TastingSample[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    addModelQuery(queryType: string, model: Site | Cultivar) {
        this._store.dispatch(new PushTastingSampleIndexModelQuery(queryType, model));
    }

    add() {
        const state: TastingSampleIndexStateModel = this._store.selectSnapshot(TastingSampleIndexState);

        const data: TastingSampleFormDialogData = {
            defaults: {
                ownerOrgKey: state.orgKey
            },
            eventOptions: this.eventOptions
        }

        this._dialog.open(TastingSampleFormDialog, { data });
    }

    edit(tastingSample: TastingSample) {
        const data: TastingSampleFormDialogData = { key: tastingSample.key, eventOptions: this.eventOptions };
        this._dialog.open(TastingSampleFormDialog, { data });
    }

    delete(sample: TastingSample) {
        if (!this.isDataDeletable([sample])) return;

        this._dialog.confirm(
            `Delete Tasting Sample ${sample.code}`,
            'Are you sure you want to delete this tasting sample and all associated data?'
        )
            .afterClosed()
            .subscribe(result => {
                if (result) this._store.dispatch(new DeleteTastingSample(sample.key));
            });
    }

    bulkAddToEvent(selected: TastingSample[]) {
        if (!this.selectedDataValid(selected)) return;
        const state: TastingSampleIndexStateModel = this._store.selectSnapshot(TastingSampleIndexState);
        const data: TastingsSampleEventFormDialogData = {orgKey: state.orgKey}

        this._dialog.open(TastingsSampleEventFormDialog, { data }).afterClosed()
            .subscribe(result => {
                if(!result) return;
                let submissionList: UpdateTastingsSamplesToEvent[] = [];
                selected.forEach(sample => {
                    let newSample: TastingSample = {
                        ...sample,
                        tastingsEventKeys: result
                    }
                    submissionList.push(new UpdateTastingsSamplesToEvent(newSample))
                })

                this._store.dispatch(submissionList);
            });
    }

    bulkRemoveFromEvent(selected: TastingSample[]) {
        if (selected.length === 0) {
            this.snackbardHandler('No Samples Selected', 'info');
            return;
        }

        const state: TastingSampleIndexStateModel = this._store.selectSnapshot(TastingSampleIndexState);
        const data: TastingsSampleEventFormDialogData = {orgKey: state.orgKey}

        this._dialog.open(TastingsSampleEventFormDialog, { data }).afterClosed()
            .subscribe(result => {
                if(!result) return;
                let submissionList: RemoveTastingsSamplesFromEvent[] = [];
                selected.forEach(sample => {
                    let newSample: TastingSample = {
                        ...sample,
                        tastingsEventKeys: result
                    }
                    submissionList.push(new RemoveTastingsSamplesFromEvent(newSample))
                })

                this._store.dispatch(submissionList);
            });
    }

    deleteSelected(selectedKeys: string[], selectedData: TastingSample[]) {
        if (!this.isDataDeletable(selectedData)) return;

        let submissionList: DeleteTastingSample[] = [];
        selectedKeys.forEach(key => {
            submissionList.push(new DeleteTastingSample(key))
        });

        this._dialog.confirm(
            `Delete ${selectedKeys.length} selected Tasting Samples`,
            `Are you sure you want to delete these samples and all their associated data?`
        ).afterClosed()
        .subscribe(result => {
            if (result) {
                this._store.dispatch(submissionList).subscribe(complete => {
                    this.selection.clear();
                });
            }
        });
    }

    getImage(sample: TastingSample) {
        if (sample.tastingsSampleImageKey) return sample.tastingsSampleImage
        if (sample.harvestSample) return sample.harvestSample.primaryImage
    }

    setPrimaryImage(sample: TastingSample) {
        const data: ImagesFormDialogData = {
            key: sample.key,
            type: ImageUploadType.TASTINGS_SAMPLE
         }

         this._dialog.openFullscreen(ImagesFormDialog, { data });
    }

    //Validators & handler functions
    isDataDeletable(data: TastingSample[]): boolean {
        if (data.length === 0) {
            this.snackbardHandler('No Samples Selected', 'info');
            return false;
        }

        let linked = data.filter(sample => sample.tastingsEvents.length > 0);

        if (linked.length > 0) {
            this.snackbardHandler(`Unlink ${linked.length} samples from events before deleting`, 'error');
            return false;
        }

        return true;
    }

    selectedDataValid(selected: TastingSample[]): boolean {
        if (selected.length === 0) {
            this.snackbardHandler('No Samples Selected', 'info');
            return false;
        }

        return true
    }

    snackbardHandler(message: string, type: string): void {
        switch(type) {
            case 'info':
                this._snackbar.info(message);
                break;
            case 'error':
                this._snackbar.error(message);
                break;
            default:
                this._snackbar.info(message);
                break;
        }
    }
}