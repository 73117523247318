import { Protocol } from './_types';

export const LIB_PROTOCOL_GRAPE_HARVEST_RAISIN_DRY_EVAL: Protocol = {
    id: "grape_harvest_raisin_dry_eval",
    title: "Raisin Grape (Dry) Harvest Evaluation",
    description: "Evaluation of the dried grape product is performed to characterise raisins from new cultivars and selections on the base of their appearance, defects and eating quality in detail.",
    sampleType: "harvest",
    crop: "grape",

    chars: [

        "bery_shrivel",

        "bery_stick",
        "bery_firm_homog",

        "bery_over_color",
        "bery_class_raisin",
        "bery_color_class",
        "bery_color_uniform",
        "bery_shape_type",

        "bery_blemish",
        "bery_damage",

        "bery_crack_detect",

        "bery_extbrown_symptom",
        "bery_intbrown_symptom",

        "bery_seed",
        "bery_seed_trace",

        "bery_taste_sugar",
        "bery_taste_acid",
        "bery_taste_balance",
        "bery_crystal_form",
        "bery_taste_aroma",
        "bery_taste_aroma_type",
        "bery_skin_flavor_type",
        "bery_skin_flavor_intense",
        "bery_skin_thick",

        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_color",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest",
    ],

    measures: [
        "rais_len",
        "rais_dia",
        "rais_wei",
    ],

    indexes: [
        "indx_frut_overall"
    ],
};