import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from './api/auth.service';
import { CultivarService } from './api/cultivar.service';
import { GroupService } from './api/group.service';
import { OrganizationService } from './api/organization.service';
import { SiteService } from './api/site.service';
import { UserService } from './api/user.service';
import { DescribeStatusMessagePipe, DescribeStatusTitlePipe } from './pipes/describe-status.pipe';
import { SampleService } from './api/sample.service';
import { WeatherStationService } from './api/weather-station.service';
import { ReportService } from './api/report.service';
import { EvaluationService } from './api/evaluation.service';
import { ImageFileRefPipe } from './pipes/image-file-ref.pipe';
import { SourceFileRefPipe } from './pipes/source-file-ref.pipe';
import { CustomProtocolService } from "./api/custom-protocol.service";
import { TastingSampleService } from './api/tasting-sample.service';
import { TastingEventService } from './api/tastings-event.service';
import { TastingContactService } from './api/tasting-contact.service';
import { OrganizationSubscriptionHandler } from './globals/subscriptions';
import { ExportHistoryService } from './api/export-history.service';
import { TastingsEvaluationsService } from './api/tastings-evaluation.service';
import { BaseIndexService } from './api/EvaluationLibrary/base-index.service';
import { IndexService } from './api/EvaluationLibrary/index.service';
import { CropGroupService } from './api/EvaluationLibrary/crop-group.service';
import { CropService } from './api/EvaluationLibrary/crop.service';
import { IndexSnapshotService } from './api/EvaluationLibrary/index-snapshot.service';
import { TagService } from './api/EvaluationLibrary/tag.service';
import { WeightedCharService } from './api/EvaluationLibrary/weighted-char.service';

@NgModule({
    declarations: [
        DescribeStatusMessagePipe,
        DescribeStatusTitlePipe,
        ImageFileRefPipe,
        SourceFileRefPipe,
    ],
    imports: [],
    providers: [],
    exports: [
        DescribeStatusMessagePipe,
        DescribeStatusTitlePipe,
        ImageFileRefPipe,
        SourceFileRefPipe,
    ],
    entryComponents: [],
})
export class CoreDataModule {
    static forRoot(): ModuleWithProviders<CoreDataModule> {
        return {
            ngModule: CoreDataModule,
            providers: [
                UserService,
                GroupService,
                CustomProtocolService,
                OrganizationService,
                AuthService,
                CultivarService,
                SiteService,
                ReportService,
                SampleService,
                TastingSampleService,
                TastingEventService,
                TastingContactService,
                TastingsEvaluationsService,
                EvaluationService,
                WeatherStationService,
                OrganizationSubscriptionHandler,
                ExportHistoryService,
                // Evaluation Library Services
                BaseIndexService,
                IndexService,
                CropGroupService,
                CropService,
                IndexSnapshotService,
                TagService,
                WeightedCharService,
            ]
        };
    }
}
