import { HttpErrorResponse } from '@angular/common/http';

export class UnauthenticatedError extends HttpErrorResponse {}
export class UnauthorizedError extends HttpErrorResponse {}
export class NotFoundError extends HttpErrorResponse {}


export class ValidationError extends HttpErrorResponse {

    fieldErrors: FieldValidationError[];

    constructor(err: HttpErrorResponse){
        super(err);
        this.fieldErrors = [];

        for(let field in err.error.errors){
            this.fieldErrors.push({
            field,
            messages: err.error.errors[field]
            });
        }
    }

}

export interface FieldValidationError {
    field: string;
    messages: string[];
}

