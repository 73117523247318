import { Characteristic, CharacteristicType } from './_types';

export const LIB_CHARS_ADVANCED_CITRUS_HARVEST: Characteristic[] = [
    {
        id: "citrus_wind_marks",
        type: CharacteristicType.Interval,
        label: "Wind Marks",
        categoryId: "citrus_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_hail_marks",
        type: CharacteristicType.Interval,
        label: "Hail Marks",
        categoryId: "citrus_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_thrip_scarring",
        type: CharacteristicType.Interval,
        label: "Thrips Scarring",
        categoryId: "citrus_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Slight (1)",
                "Slight (2)",
                "Moderate (3)",
                "Severe (4)",
                "Very Severe (5)",
                "Extreme (6 - 8)",
            ]
        }
    },
    {
        id: "citrus_snail_scarring",
        type: CharacteristicType.Interval,
        label: "Snail Scarring",
        categoryId: "citrus_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_bollworm_injury",
        type: CharacteristicType.Interval,
        label: "Bollworm Injury",
        categoryId: "citrus_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_sunburn",
        type: CharacteristicType.Interval,
        label: "Sunbrun",
        categoryId: "citrus_abrasion_blemish",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_firm",
        type: CharacteristicType.Interval,
        label: "Physical Firmness",
        categoryId: "citrus_firmness",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft",
                "Medium",
                "Firm",
                "Very Firm"
            ]
        }
    },
    {
        id: "citrus_fruit_size",
        type: CharacteristicType.Interval,
        label: "Fruit Size",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
               "Small (<6)",
               "Small - Medium (6-4)",
               "Medium (3-2)",
               "Medium - Large (2-1)",
               "Large (1 - 1XX)",
               "Extra Large (>1XX)",
            ]
        }
    },
    {
        id: "citrus_fruit_size_pick",
        type: CharacteristicType.Nominal,
        label: "Specified Fruit Size ",
        categoryId: "citrus_appearance",
        params:{
            options: [
                {value: "calibre_7", label: "Calibre 7"},
                {value: "calibre_6", label: "Calibre 6"},
                {value: "calibre_5", label: "Calibre 5"},
                {value: "calibre_4", label: "Calibre 4"},
                {value: "calibre_3", label: "Calibre 3"},
                {value: "calibre_2", label: "Calibre 2"},
                {value: "calibre_1", label: "Calibre 1"},
                {value: "calibre_1x", label: "Calibre 1X"},
                {value: "calibre_1xx", label: "Calibre 1XX"},
                {value: "calibre_1xxx", label: "Calibre 1XXX"},
            ]
        }
    },
    {
        id: "citrus_color_oranges",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour - Orange",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow", label: "Yellow (3-4)"},
                { value: "yellow_orange", label: "Yellow/Orange (2)"},
                { value: "orange", label: "Orange (1)"},
            ]
        }
    },
    {
        id: "citrus_color_oranges_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level - Orange",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_color_soft",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour - Soft Citrus",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow", label: "Yellow (3-4)"},
                { value: "yellow_orange", label: "Yellow/Orange (2)"},
                { value: "orange", label: "Orange (1)"},
            ]
        }
    },
    {
        id: "citrus_color_soft_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level - Soft Citrus",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_color_yellow_grapef",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour - Yellow Grapefruit",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow_green", label: "Yellow/Green (3-4)"},
                { value: "yellow", label: "Yellow (1-2)"},
            ]
        }
    },
    {
        id: "citrus_color_yellow_grapef_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level - Yellow Grapefruit",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_color_pigmented_grapef",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour - Pigmented Grapefruit",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow_orange", label: "Yellow/Orange (3-4)"},
                { value: "orange_pink", label: "Orange/Pink (2)"},
                { value: "pink", label: "Pink (1)"},
            ]
        }
    },
    {
        id: "citrus_color_pigment_grapef_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level - Pigmented Grapefruit",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_color_lemons",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour - Lemons",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow_green", label: "Yellow/Green (3-4)"},
                { value: "yelllow", label: "Yellow (1-2)"},
            ]
        }
    },
    {
        id: "citrus_color_lemons_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level - Lemons",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_frut_flesh_color",
        type: CharacteristicType.Color,
        categoryId: "citrus_appearance",
        label: "Flesh Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" },
                { h: 15, s: 90, l: 85, label: "Cream" },
                { h: 0, s: 0, l: 100, label: "White" }
            ]
        }
    },
    {
        id: "citrus_frut_flesh_intense",
        type: CharacteristicType.Interval,
        label: "Flesh Colour Intensity",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Light",
                "Light",
                "Moderate",
                "Intense",
                "Very Intense",
            ]
        }
    },
    {
        id: "citrus_int_color_pigment_grapef",
        type: CharacteristicType.Nominal,
        label: "Internal Colour Pigmented Grapefruit",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "c_1", label: "1"},
                { value: "c_2", label: "2"},
                { value: "c_3", label: "3"},
                { value: "c_4", label: "4"},
                { value: "c_5", label: "5"},
                { value: "c_6", label: "6"},
                { value: "c_7", label: "7"},
                { value: "c_8", label: "8"},
            ]
        }
    },
    {
        id: "citrus_shape_type",
        type: CharacteristicType.Nominal,
        label: "Shape Type",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "round", label: "Spheroid (Round)"},
                { value: "elips", label: "Elipsoid"},
                { value: "ovoid", label: "Ovoid"},
                { value: "obloid", label: "Obloid"},
                { value: "pyriform", label: "Pyriform" },
                { value: "oblique", label: "Oblique"},
            ]
        }
    },
    {
        id: "citrus_shape_fruit_base",
        type: CharacteristicType.Nominal,
        label: "Shape of Fruit Base",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "necked", label: "Necked"},
                { value: "convex", label: "Convex"},
                { value: "truncate", label: "Truncate"},
                { value: "concavae", label: "Concave"},
                { value: "concave_collard", label: "Concave collard"},
                { value: "collard_neck", label: "Collard with neck"},
                { value: "other", label: "Other"},
            ]
        }
    },
    {
        id: "citrus_shape_symmetry",
        type: CharacteristicType.Interval,
        label: "Shape Symmetry",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Asymmetrical",
                "Intermediate",
                "Symmetrical",
            ]
        }
    },
    {
        id: "citrus_shape_consistent",
        type: CharacteristicType.Interval,
        label: "Shape Consistency",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Variable",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "citrus_rind_text",
        type: CharacteristicType.Interval,
        label: "Rind Texture",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Rough/Pebbled",
                "Intermediate",
                "Smooth",
            ]
        }
    },
    {
        id: "citrus_navel_malformed_count",
        type: CharacteristicType.Nominal,
        label: "Malformed Navels",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "nm_1", label: "1"},
                { value: "nm_2", label: "2"},
                { value: "nm_3", label: "3"},
                { value: "nm_4", label: "4"},
                { value: "nm_5", label: "5"},
                { value: "nm_6", label: "6"},
                { value: "nm_7", label: "7"},
                { value: "nm_8", label: "8"},
            ]
        }
    },
    {
        id: "citrus_navel_protruding_count",
        type: CharacteristicType.Nominal,
        label: "Navels Protruding",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "np_1", label: "1"},
                { value: "np_2", label: "2"},
                { value: "np_3", label: "3"},
                { value: "np_4", label: "4"},
                { value: "np_5", label: "5"},
                { value: "np_6", label: "6"},
                { value: "np_7", label: "7"},
                { value: "np_8", label: "8"},
            ]
        }
    },
    {
        id: "citrus_navel_malformed",
        type: CharacteristicType.Nominal,
        label: "Malformed Navels",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "absent", label: "Absent"},
                { value: "moderate", label: "Moderate"},
                { value: "severe", label: "Severe"},
            ]
        }
    },
    {
        id: "citrus_navel_protruding",
        type: CharacteristicType.Nominal,
        label: "Protruding Navels",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "absent", label: "Absent"},
                { value: "moderate", label: "Moderate"},
                { value: "severe", label: "Severe"},
            ]
        }
    },
    {
        id: "citrus_rind_oil_cell",
        type: CharacteristicType.Interval,
        label: "Rind Oil Cells",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
            ]
        }
    },
    {
        id: "citrus_rind_thick",
        type: CharacteristicType.Interval,
        label: "Rind Thickness",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Thin",
                "Intermediate",
                "Thick",
            ]
        }
    },
    {
        id: "citrus_albedo_thick",
        type: CharacteristicType.Interval,
        label: "Albedo Thickness",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Thin",
                "Intermediate",
                "Thick",
            ]
        }
    },
    {
        id: "citrus_albedo_color",
        type: CharacteristicType.Nominal,
        label: "Albedo Colour",
        categoryId: "citrus_appearance",
        params: {
            options: [
                { value: "white", label: "White"},
                { value: "orange_white", label: "Orange white"},
                { value: "pink_white", label: "Pink white"},
            ]
        }
    },
    {
        id: "citrus_peelability",
        type: CharacteristicType.Interval,
        label: "Peelability",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Difficult",
                "Intermediate",
                "Easy",
            ]
        }
    },
    {
        id: "citrus_rind_oil",
        type: CharacteristicType.Interval,
        label: "Rind Oil",
        categoryId: "citrus_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligable",
                "Inconspicuous",
                "Oily",
                "Conspicuous",
                "Very Oily",
            ]
        }
    },
    {
        id: "citrus_granulation",
        type: CharacteristicType.Interval,
        label: "Granulation Pressence",
        categoryId: "citrus_internal_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_granulation_severity",
        type: CharacteristicType.Interval,
        label: "Granulation Severity",
        categoryId: "citrus_internal_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_gumming",
        type: CharacteristicType.Interval,
        label: "Gumming Pressence",
        categoryId: "citrus_internal_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_gumming_severity",
        type: CharacteristicType.Interval,
        label: "Gumming Severity",
        categoryId: "citrus_internal_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_core_size",
        type: CharacteristicType.Interval,
        label: "Core Size",
        categoryId: "citrus_internal_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Closed",
                "Intermidate/Open",
                "Wide Open",
            ]
        }
    },
    {
        id: "citrus_vesicle_size",
        type: CharacteristicType.Interval,
        label: "Vesicle Size",
        categoryId: "citrus_internal_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Small",
                "Average",
                "Large",
            ]
        }
    },
    {
        id: "citrus_rind_disorders",
        type: CharacteristicType.Nominal,
        label: "Physiological Rind Disorders",
        categoryId: "citrus_rind_disorder",
        params: {
            options: [
                { value: "creasing", label: "Creasing"},
                { value: "oleocellosis", label: "Oleocellosis"},
                { value: "ribbing", label: "Ribbing"},
                { value: "Endoxerosis", label: "Endoxerosis"},
                { value: "rind_pitting", label: "Rind Pitting"},
                { value: "peteca_spot", label: "Peteca Spot"},
                { value: "chilling_injury", label: "Chilling Injury"},
                { value: "suburn", label: "Sunburn"},
                { value: "splitting", label: "Splitting"},
            ]
        }
    },
    {
        id: "citrus_rind_breakdown",
        type: CharacteristicType.Interval,
        label: "Rind Breakdown",
        categoryId: "citrus_rind_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_creasing",
        type: CharacteristicType.Interval,
        label: "Creasing Severity",
        categoryId: "citrus_rind_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_creasing_presence",
        type: CharacteristicType.Interval,
        label: "Creasing Presence",
        categoryId: "citrus_rind_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_splitting",
        type: CharacteristicType.Interval,
        label: "Splitting Severity",
        categoryId: "citrus_rind_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_splitting_presence",
        type: CharacteristicType.Interval,
        label: "Splitting Presence",
        categoryId: "citrus_rind_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_disease_detected",
        type: CharacteristicType.Nominal,
        label: "Disease Detected",
        categoryId: "citrus_disease_detect",
        params: {
           options : [
                { value: "stem_end_rot", label: "Stem-End Rot"},
                { value: "black_spot", label: "Citrus Black Spot (CBS)"},
                { value: "altemeria_brown_spot", label: "Altemaria Brown Spot"},
                { value: "anthracnose", label: "Anthracnose"},
                { value: "alternaria_core_rot", label: "Alternaria Core Rot"},
                { value: "greening", label: "Greening"},
                { value: "sour_rot", label: "Sour Rot"},
                { value: "trichoderma_rot", label: "Trichoderma Rot"},
                { value: "tristeza_virus", label: "Tristeza Virus"},
           ]
        }
    },
    {
        id: "citrus_pest_detected",
        type: CharacteristicType.Nominal,
        label: "Pests Detected",
        categoryId: "citrus_disease_detect",
        params: {
           options : [
                { value: "leaf_hopper", label: "Leaf Hopper"},
                { value: "mealy_bug", label: "Mealy Bug"},
                { value: "thrips", label: "Thrips"},
                { value: "bollworm", label: "Bollworm"},
                { value: "false_codling_moth", label: "False Codling Moth"},
                { value: "red_spider_mite", label: "Red Spider Mite"},
                { value: "red_scale", label: "Red Scale"},
                { value: "silver_mite", label: "Silver Mite"},
                { value: "fruit_flies", label: "Fruit Flies"},
           ]
        }
    },
    {
        id: "citrus_disorder_endoxerosis",
        type: CharacteristicType.Interval,
        label: "Endoxerosis",
        categoryId: "citrus_disease_detect",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Minimal",
                "Moderate",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_ripe_stage",
        type: CharacteristicType.Interval,
        label: "Ripening Stage",
        categoryId: "citrus_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Unripe",
                "Immature",
                "Ideal Ripeness",
                "Mature",
                "Post Optimum / Over Ripeness",
            ]
        }
    },
    {
        id: "citrus_seed",
        type: CharacteristicType.Nominal,
        label: "Seed Presence",
        categoryId: "citrus_seed_presence",
        params: {
           options : [
                { value: "seeded", label: "Seeded"},
                { value: "seedless", label: "Seedless"},
           ]
        }
    },
    {
        id: "citrus_seed_detection",
        type: CharacteristicType.Nominal,
        label: "Seed Detection",
        categoryId: "citrus_seed_presence",
        params: {
           options : [
                { value: "none", label: "None (0)"},
                { value: "few", label: "Few (1-2)"},
                { value: "intermediate", label: "Intermediate (3-4)"},
                { value: "numerous", label: "Numerous (5-6)"},
                { value: "high", label: "High (7-10)"},
                { value: "very_high", label: "Very High (>10)"},
           ]
        }
    },
    {
        id: "citrus_seed_size",
        type: CharacteristicType.Interval,
        label: "Seed Size/Description",
        categoryId: "citrus_seed_presence",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Small",
                "Large",
                "Aborted",
            ]
        }
    },
    {
        id: "citrus_taste_sugar",
        type: CharacteristicType.Interval,
        label: "Sugar",
        categoryId:  "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Sweetness",
                "Low Sweetness",
                "Intermediate",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "citrus_taste_acid",
        type: CharacteristicType.Interval,
        label: "Acid",
        categoryId:  "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Acid",
                "Low Acid / “Sprightly”",
                "Intermediate",
                "Tart",
                "Very Tart"
            ]
        }
    },
    {
        id: "citrus_taste_balance",
        type: CharacteristicType.Interval,
        label: "Sugar Acid Balance",
        categoryId:  "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Tarty",
                "Tarty",
                "Balanced",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "citrus_taste_aroma",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "citrus_taste_bitterness",
        type: CharacteristicType.Interval,
        label: "Bitterness",
        categoryId: "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Low",
                "Medium",
                "High",
            ]
        }
    },
    {
        id: "citrus_taste_pulp_firm",
        type: CharacteristicType.Interval,
        label: "Pulp Firmness",
        categoryId: "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Soft",
                "Intermediate",
                "Firm",
            ]
        }
    },
    {
        id: "citrus_taste_juice",
        type: CharacteristicType.Interval,
        label: "Juiciness",
        categoryId: "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Dry",
                "Dry",
                "Intermediate",
                "Juicy",
                "Very Juicy"
            ]
        }
    },
    {
        id: "citrus_taste_ragginess",
        type: CharacteristicType.Interval,
        label: "Ragginess",
        categoryId: "citrus_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft Rag",
                "Intermediate",
                "Tough",
                "Very Tough",
            ]
        }
    },
]