import { Flag } from "./_types";

export const LIB_BREED_FLAG: Flag[] = [
    {
        id: "high_pot",
        label: "High Potential",
        description: "Flagged: High Potential for Selection",
        color: "#9ecf2d",
    },
    {
        id: "medium_pot",
        label: "Medium Potential",
        description: "Flagged: Medium Potential for Selection",
        color: "#cfbe2d",
    },
    {
        id: "low_pot",
        label: "Low Potential",
        description: "Flagged: Low Potential for Selection",
        color: "#664a28",
    },
    {
        id: "discard",
        label: "Discard",
        description: "Flagged: Discard",
        color: "#e8645a",
    },
    {
        id: "unflagged",
        label: "Not Flagged",
        description: "Not Flagged",
        color: "#000000",
    },
]

