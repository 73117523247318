
export enum Status {
    UNINITIALIZED = 'UNINITIALIZED',         // uninitialized

    UNAUTHENTICATED = 'UNAUTHENTICATED',     // 401 unauthenticated
    UNAUTHORIZED = 'UNAUTHORIZED',           // 403 unauthorized
    LOADING = 'LOADING',                     // loading data
    ERROR = 'ERROR',                         // general/unknown error
    DISCONNECTED = 'DISCONNECTED',           // no internet connection
    NOT_FOUND = 'NOT_FOUND',                 // 404 not found
    INVALID = 'INVALID',                     // 422 invalid (list query invalid or form submit invalid)
    OK = 'OK',                               // loading complete / waiting for user input

    // list
    EMPTY = 'EMPTY',                         // list is empty (list only)

    // form
    COMPLETE = 'COMPLETE',                   // save success (form only)

    DELETED = 'DELETED',                      // item is deleted

}


export const StatusTitle = {
    'UNINITIALIZED': "Uninitialized",
    'UNAUTHENTICATED': "Unauthenticated",
    'UNAUTHORIZED': "Unauthorized",
    'LOADING': "Loading",
    'ERROR': "Error",
    'DISCONNECTED': "Disconnected",
    'NOT_FOUND': "Not Found",
    'INVALID': "Invalid",
    'OK': "Ok",
    'EMPTY': "Empty",
    'COMPLETE': "Complete",
    'DELETED': "Deleted",
};

export const StatusMessage = {
    'UNINITIALIZED': "Waiting for initialization.",
    'UNAUTHENTICATED': "Sign in to access resource.",
    'UNAUTHORIZED': "Not authorized to access resource.",
    'LOADING': "Loading resource.",
    'ERROR': "Oops! Something went wrong.",
    'DISCONNECTED': "You seem to be offline.",
    'NOT_FOUND': "The requested resource could not be found.",
    'INVALID': "The request is invalid.",
    'OK': "All good.",
    'EMPTY': "Request returned with no results.",
    'COMPLETE': "Request complete!",
    'DELETED': "Resource is deleted.",
};


export function isErrorStatus(status: Status){
    switch(status){
        case Status.UNAUTHENTICATED:
        case Status.UNAUTHORIZED:
        case Status.ERROR:
        case Status.DISCONNECTED:
        case Status.NOT_FOUND:
        case Status.INVALID:
        case Status.EMPTY:
            return true;
        default:
            return false;
    }
}