<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>{{ state.key ? 'Edit' : 'Add' }}&nbsp;Tasting Contact</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_contact_form"
              #tastingContactForm="ngForm"
              [formGroup]="formGroup"
              class="pv-form"
              (ngSubmit)="attempt()"
              novalidate
              autocomplete="no">

            <div class="form-column">
                <h4 class="form-subheader">Details</h4>

                <mat-form-field appearance="standard">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName"/>
                    <mat-hint>Contact's first name</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName"/>
                    <mat-hint>Contact's last name</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required/>
                    <mat-hint>Contact's email</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Date of Birth</mat-label>
                    <input matInput formControlName="dob" [matDatepicker]="dobPicker">
                    <mat-datepicker-toggle matSuffix [for]="dobPicker">
                    </mat-datepicker-toggle>
                    <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                    <mat-datepicker #dobPicker startView="multi-year"></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country">
                        <mat-option *ngFor="let country of countries" [value]="country.code">{{ country.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
					<mat-label>Business Areas</mat-label>
					<mat-select formControlName="businessAreas" multiple>
						<mat-option *ngFor="let area of businessAreas" [value]="area.id">{{area.label}}</mat-option>
					</mat-select>
					<mat-hint>Business Areas of the tastings contact</mat-hint>
				</mat-form-field>

                <mat-checkbox appearance="standard" formControlName="subscribed">
                    Is Subscribed?
                </mat-checkbox>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingContactForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>