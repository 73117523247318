import { Pipe, PipeTransform } from "@angular/core";
import { ImportSubjectType } from "@core/data/types/import-subject";

@Pipe({
    name: 'describeImportSubjectType'
})
export class ImportSubjectTypePipe implements PipeTransform {
    constructor() {}

    transform(type: ImportSubjectType) {
        if (type === ImportSubjectType.TASTINGS_EVALS) return "Tastings Event";

        return "Unknown Subject";
    }
}