import { Injectable } from "@angular/core";
import { Index, IndexService, Status, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface TastingCustomIndexesFormStateModel {
    key: string;
    status: Status;
    data: Partial<Index>
}

const DEFAULTS: TastingCustomIndexesFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
}

export class InitNewTastingCustomIndexesForm {
    static readonly type = "[TastingCustomIndexesForm] Init New";
    constructor(public defaults?: Partial<Index>) {}
}

export class InitUpdateTastingCustomIndexesForm {
    static readonly type = "[TastingCustomIndexesForm] Init Update";
    constructor(public key: string) {}
}

export class SubmitTastingCustomIndexesForm {
    static readonly type = "[TastingCustomIndexesForm] Submit";
    constructor(public data: Partial<Index>) {}
}

@State<TastingCustomIndexesFormStateModel>({
    name: 'tasting_custom_indexes_form',
    defaults: DEFAULTS
})
@Injectable()
export class TastingCustomIndexesFormState {
    @Selector()
    static data(state: TastingCustomIndexesFormStateModel) {
        return state.data;
    }

    constructor(private _indexService: IndexService) {}

    @Action(InitNewTastingCustomIndexesForm, {cancelUncompleted: true})
    initNewTastingCustomIndexesForm(ctx: StateContext<TastingCustomIndexesFormStateModel>, action: InitNewTastingCustomIndexesForm) {
        ctx.setState({
            key: null,
            data: {
                ...action.defaults
            },
            status: Status.OK,
        });
    }

    @Action(InitUpdateTastingCustomIndexesForm, {cancelUncompleted: true})
    initUpdateTastingCustomIndexesForm(ctx: StateContext<TastingCustomIndexesFormStateModel>, action: InitUpdateTastingCustomIndexesForm) {
        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING,
        });

        return this._indexService.get(action.key)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(SubmitTastingCustomIndexesForm)
    submitTastingCustomIndexesForm(ctx: StateContext<TastingCustomIndexesFormStateModel>, action: SubmitTastingCustomIndexesForm) {
        const state = ctx.getState();

        let request: Observable<Index>;

        if (state.key) request = this._indexService.update(state.key, { ...action.data });
        else request = this._indexService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }
}