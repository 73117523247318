import { Injectable } from "@angular/core";
import { DetailRequest, Status, TastingEventService, TastingSampleService, translateCommonErrorStatus } from "@core/data";
import { TastingsEvaluationsService } from "@core/data/api/tastings-evaluation.service";
import { TastingSample } from "@core/data/types/tasting-sample";
import {
    TastingEvaluation,
    TastingEvaluationIndexType,
} from "@core/data/types/tastings-evaluation";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface TastingEvaluationFormStateModel {
    key: string;
    subjectKey: string;
    subjectType: TastingEvaluationIndexType;
    status: Status;
    data: Partial<TastingEvaluation>;
    samples?: TastingSample[];
}

const DEFAULTS: TastingEvaluationFormStateModel = {
    key: null,
    subjectKey: null,
    subjectType: null,
    status: Status.UNINITIALIZED,
    data: {},
    samples: []
};

export class InitNewTastingEvaluationForm {
    static readonly type = "[TastingEvaluationForm] Init New";
    constructor(
        public subjectKey: string,
        public subjectType: TastingEvaluationIndexType,
        public defaults?: Partial<TastingEvaluation>,
    ) {}
}

export class InitUpdateTastingEvaluationForm {
    static readonly type = "[TastingEvaluationForm] Init Update";
    constructor(
        public key: string,
        public subjectKey: string,
        public subjectType: TastingEvaluationIndexType
    ) {}
}

export class SubmitTastingEvalutaionForm {
    static readonly type = "[TastingEvaluationForm] Submit";
    constructor(
        public data: Partial<TastingEvaluation>
    ) {}
}

export class LoadTastingsEventSamples {
    static readonly type = "[TastingEvaluationForm] Load Samples";
    constructor(
        public eventKey: string
    ) {}
}

@State<TastingEvaluationFormStateModel>({
    name: 'tasting_evaluations_form',
    defaults: DEFAULTS
})
@Injectable()
export class TastingEvaluationFormState {
    @Selector()
    static data(state: TastingEvaluationFormStateModel) {
        return state.data;
    }

    constructor(
        private _tastingEvaluationService: TastingsEvaluationsService,
        private _tastingEventService: TastingEventService,
    ) {}

    @Action(InitNewTastingEvaluationForm, {cancelUncompleted: true})
    initNewTastingEvaluationForm(ctx: StateContext<TastingEvaluationFormStateModel>, action: InitNewTastingEvaluationForm) {
        if (action.subjectType === TastingEvaluationIndexType.EVENT) {
            ctx.dispatch(new LoadTastingsEventSamples(action.subjectKey));
        }

        ctx.setState({
            key: null,
            subjectKey: action.subjectKey,
            subjectType: action.subjectType,
            data: {
                ...action.defaults,
                tastingsSampleKey:
                    action.subjectType === TastingEvaluationIndexType.SAMPLE
                    ? action.subjectKey
                    : null,
                tastingsEventKey:
                    action.subjectType === TastingEvaluationIndexType.EVENT
                    ? action.subjectKey
                    : null,

            },
            status: Status.OK
        });
    }

    @Action(InitUpdateTastingEvaluationForm, {cancelUncompleted: true})
    initUpdateTastingEvaluationForm(ctx: StateContext<TastingEvaluationFormStateModel>, action: InitUpdateTastingEvaluationForm) {
        ctx.setState({
            key: action.key,
            subjectKey: action.subjectKey,
            subjectType: action.subjectType,
            data: null,
            status: Status.LOADING,
        });

        let detailRequest: DetailRequest = {
            related: []
        }

        return this._tastingEvaluationService.get(action.key, detailRequest)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            subjectKey: action.subjectKey,
                            subjectType: action.subjectType,
                            data: result,
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }

    @Action(SubmitTastingEvalutaionForm)
    submitTastingEvalutaionForm(ctx: StateContext<TastingEvaluationFormStateModel>, action: SubmitTastingEvalutaionForm) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        })

        return this._tastingEvaluationService.create({ ...action.data })
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            subjectType: state.subjectType,
                            subjectKey: state.subjectKey,
                            data: result,
                            status: Status.COMPLETE
                        });
                    },
                    error => {
                        // TODO pull errpr from api
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        })
                    }
                )
            )
    }

    @Action(LoadTastingsEventSamples)
    loadTastingsEventSamples(ctx: StateContext<TastingEvaluationFormStateModel>, action: LoadTastingsEventSamples) {
        let detailRequest: DetailRequest = {
            related: ['tastingsSamples']
        }

        return this._tastingEventService.get(action.eventKey, detailRequest)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            samples: result.tastingsSamples
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        })
                    }
                )
            )
    }
}
