<ng-container *ngIf="state$ | async as state">
    <h3 mat-dialog-title>Report Templates</h3>
    <div mat-dialog-content>
        <form #form="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()" style="min-width: 300px;">

            <mat-form-field appearance="standard" floatLabel="always">
                <mat-label>New Template Name</mat-label>
                <input matInput type="text" formControlName="name">
            </mat-form-field>

            <mat-radio-group formControlName="id">
                <mat-list dense>
                    <h3 matSubheader>Existing Templates</h3>
                    <mat-divider></mat-divider>
                    <mat-list-item *ngFor="let template of state.items">
                        <div matListIcon>
                            <mat-radio-button [value]="template.id" (change)="existingSelectionChanged($event, template)"></mat-radio-button>
                        </div>
                        <span matLine>{{ template.name }}</span>
                        <button mat-icon-button type="button" (click)="delete(template)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </mat-radio-group>

            <p class="form-caption" *ngIf="state.items.length === 0">* No existing templates.</p>

            <p class="form-caption" *ngIf="!idControl.value">
                To create a new report template, enter a template name.
                To replace an existing template, select the template to replace and optionally rename the template.
            </p>

            <p class="form-error" *ngIf="idControl.value">
                Existing template will be replaced.
            </p>

        </form>
    </div>
    <pv-form-actions mat-dialog-actions [form]="form" [status]="state.status" (cancel)="cancel()">



    </pv-form-actions>
</ng-container>