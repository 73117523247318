import { Inject, Injectable } from "@angular/core";
import { LIBRARY } from "@app/evaluation/library";
import { SessionStorage } from "@core/browser";
import { CustomProtocolService, Status } from "@core/data";
import { Library, ProtocolDictionary } from "@library";
import { Action, Selector, State, StateContext } from "@ngxs/store";

export interface ProtocolManagerStateModel {
    status: Status;
    data: ProtocolDictionary;
    orgKey: string;
}

const DEFAULTS: ProtocolManagerStateModel = {
    status: Status.UNINITIALIZED,
    data: null,
    orgKey: null,

}

export class InitProtocolManagerState {
    static readonly type = '[ProtocolManagerState] Init';
    constructor(public orgKey:string){}
}

@State<ProtocolManagerStateModel>({
    name: 'protocol_manager_state',
    defaults: DEFAULTS,
})
@Injectable()
export class ProtocolManagerState {

    @Selector()
    static data(state: ProtocolManagerStateModel){
        return state.data;
    }

    constructor(
        private _custom_protocolService: CustomProtocolService,
        @Inject(LIBRARY) private _library: Library,
        @Inject(SessionStorage) private _session: SessionStorage,
    ){}

    @Action(InitProtocolManagerState, {cancelUncompleted: true})
    initProtocolManagerState(ctx: StateContext<ProtocolManagerStateModel>, action: InitProtocolManagerState){
        this._session.set('prev_org_key', null)
        this._session.set('auth_org_key', action.orgKey)

        this._custom_protocolService.allProtocols();

        ctx.setState({
            ...DEFAULTS,
            orgKey : action.orgKey,
            status : Status.OK

        })

        const state = ctx.getState()

    }
}