import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { OrganizationGroup, FilterSort, SampleType } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GroupsState, GroupsStateModel, InitGroupIndex, LoadGroupIndex, AddGroup, RemoveGroup, GroupIndexQuery, FilterGroupIndex, PageGroupIndex, SortGroupIndex, ResetGroupIndex, AddGroupUser } from '@app/auth/components/organization-detail/groups.state';
import { OrganizationGroupFormDialogData, OrganizationGroupFormDialog } from '@app/auth/components/organization-detail/organization-group-form.dialog';
import { OrganizationGroupUserFormDialog, OrganizationGroupUserFormDialogData, OrganizationGroupUserFormDialogResult } from '@app/auth/components/organization-detail/organization-group-user-form.dialog';

@Component({
    selector: 'pv-group-index-view',
    templateUrl: './group-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-cultivar-index-view',
    }
})
export class GroupIndexView implements OnInit, OnDestroy {

    @Select(GroupsState)
    state$: Observable<GroupsStateModel>;

    @Select(GroupsState.query)
    query$: Observable<GroupIndexQuery>;

    @Select(GroupsState.sort)
    sort$: Observable<FilterSort>;

    @Select(GroupsState.newGroupKey)
    newGroupKey$: Observable<string>;

    typeOptions = Object.keys(SampleType).map(key => {
        return {value: SampleType[key], name: key[0] + SampleType[key].slice(1), }
    });

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    filterFormGroup = new FormGroup({
        search: new FormControl(null),
        type: new FormControl([]),
    });

    private _destroy$ = new Subject();

    constructor(
        private _dialogs: Dialog,
        private _route: ActivatedRoute,
        private _snackbar: Snackbar,
        private _store: Store,
    ) {}

    ngOnInit() {
        this._route.paramMap.subscribe(params => {
            this._store.dispatch(new InitGroupIndex(params.get('orgKey')));
        });

        // query state => filter form group
        this.query$
            .pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                this.filterFormGroup.setValue({
                    search: query.search,
                    type: query.type,
                }, {emitEvent: false});
            });

        // sort state => sort form group
        this.sort$
            .pipe(takeUntil(this._destroy$))
            .subscribe(sort => {
                this.sortFormGroup.setValue(sort, {emitEvent: false});
            });

        // filter form group => state changes
        this.filterFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(value => {
                this._store.dispatch(new FilterGroupIndex(value));
            });

        // sort form group => state changes
        this.sortFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(value => {
                this._store.dispatch(new SortGroupIndex(value.column, value.order));
            });

        this.newGroupKey$
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(newGroupKey => {
                const state: GroupsStateModel = this._store.selectSnapshot(GroupsState);
                if (newGroupKey){
                    this.addGroupUser(state.orgKey , newGroupKey);
                }
            })
    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    reloadGroups() {
        this._store.dispatch(new LoadGroupIndex);
    }

    addGroup(orgKey: string, group?: Partial<OrganizationGroup>) {
        let data: OrganizationGroupFormDialogData = {
            ownerOrgKey: orgKey,
            group: group,
        };
        this._dialogs.open(OrganizationGroupFormDialog, { data })
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this._store.dispatch(new AddGroup(result))
                        .subscribe(_ => {
                            this._snackbar.info(result.key ? "Organisation group updated" : "Organisation group created");
                        });
                }
            });
    }

    removeGroup(model: OrganizationGroup) {
        if (model.samplesCount || model.usersCount) {
            this._snackbar.error('Remove user and sample connections or refresh data before group can be deleted.');
            return;
        }
        this._dialogs.confirm(
                'Remove Group',
                'Are you sure you want to delete this group? ',
                'Remove',
                'Cancel'
            ).afterClosed().subscribe(res => {
                if(res) {
                    this._store.dispatch(new RemoveGroup(model.key))
                        .subscribe(_ => {
                            this._snackbar.info("Organisation group deleted");
                        });
                }
            });
    }

    reset(){
        this._store.dispatch(new ResetGroupIndex());
    }

    paginate(page: PageEvent){
        this._store.dispatch(new PageGroupIndex(page.pageIndex, page.pageSize));
    }

    sort(sort: Sort){
        if(sort.direction){
            this.sortFormGroup.setValue({
                column: sort.active,
                order: sort.direction
            });
        } else {
            this.sortFormGroup.setValue({
                column: 'label',
                order: 'asc'
            });
        }
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    addGroupUser(orgKey: string, groupKey: string) {
        const data: OrganizationGroupUserFormDialogData = {
            orgKey,
            groupKey,
        };
        this._dialogs.open(OrganizationGroupUserFormDialog, { data })
            .afterClosed()
            .subscribe((result: OrganizationGroupUserFormDialogResult) => {
                if (result) {
                    for (let i = 0; i < result.userKey.length; i++) {
                        this._store
                            .dispatch(new AddGroupUser(groupKey, result.userKey[i]))
                            .subscribe(_ => {
                                if (i === result.userKey.length - 1) {
                                    this._snackbar.info("Added "+result.userKey.length+" user(s) to group");
                                }
                            });
                    }

                }
            });
    }
}
