import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Evaluation, isPlant, Sample, SampleType, EvaluationService, Organization, CustomProtocolService } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageGalleryDialog, ImageGalleryDialogData } from '../evaluation-detail/image-gallery.dialog';
import { PrintEvaluationFormDialog, PrintEvaluationFormDialogData } from '../evaluation-detail/print-evaluation-form/print-evaluation-form.dialog';
import { CharacteristicsFormDialog } from '../evaluation-form/characteristcs-form/characteristics-form.dialog';
import { EvaluationCompleteFormDialog } from '../evaluation-form/evaluation-complete-form.dialog';
import { EvaluationFormDialog, EvaluationFormDialogData } from '../evaluation-form/evaluation-form.dialog';
import { EvaluationScheduleFormDialogData, EvaluationScheduleFormDialog } from '../evaluation-form/evaluation-schedule-form.dialog';
import { ImagesFormDialog } from '../evaluation-form/images-form/images-form.dialog';
import { MeasurementsFormDialog } from '../evaluation-form/measurements-form/measurements-form.dialog';
import { NotesFormDialog } from '../evaluation-form/notes-form/notes-form.dialog';
import { SampleFormDialog, SampleFormDialogData } from '../sample-form/sample-form.dialog';
import { SampleDetailState } from './sample-detail.state';
import { InitSampleDetail, LoadSampleDetail } from './sample-detail.state-actions';
import { SampleDetailStateModel } from './sample-detail.state-model';
import { SamplePrimaryImageFormDialogData, SamplePrimaryImageFormDialog } from '../sample-form/sample-primary-image-form.dialog';
import * as moment from 'moment';
import { AuthState } from '@app/auth';
import { ProtocolManagerState, ProtocolManagerStateModel } from '../protocol-manager/protocol-manager.state';


@Component({
    selector: 'pv-sample-detail-view',
    templateUrl: 'sample-detail.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-sample-detail-view'
    },
    providers: [
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: {
                expandedHeight: '92px',
                collapsedHeight: '72px'
            }
        }
    ]
})
export class SampleDetailView implements OnInit, OnDestroy {

    @Select(AuthState.selectedOrg)
	selectedOrg$: Observable<Organization>;

    @Select(SampleDetailState)
    state$: Observable<SampleDetailStateModel>;

    @Select(ProtocolManagerState)
    protocols$: Observable<ProtocolManagerStateModel>;

    evalDetailExpansion = new SelectionModel<string>(true, []);

    now = moment();

    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _store: Store,
        private _dialog: Dialog,
        private _snackbar: Snackbar,
        private _evalService: EvaluationService,
        private _custom_protocolService: CustomProtocolService,
    ) { }

    ngOnInit() {

        // setup state with route params
        this._route.paramMap
            .pipe(takeUntil(this._destroy$))
            .subscribe(params => {

                this.now = moment();

                this._store.dispatch(new InitSampleDetail(
                    params.get('orgKey'),
                    <SampleType>params.get('sampleType'),
                    params.get('sampleKey')
                ));

            });

    }

    reload() {
        this._store.dispatch(new LoadSampleDetail);
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    editSample(sampleKey: string){

        this._dialog.open(SampleFormDialog, {data: {key: sampleKey}});

    }

    removeSample(sampleKey: string) {

        this._dialog.confirm(
            'Remove Sample',
            'Are you sure you want to remove this sample?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                // this._store.dispatch(new DeleteSam)
                //     .pipe(takeUntil(this._destroy$))
                //     .subscribe(state => {
                //         this._snackbar.message("Sample Removed");
                //         this.back();
                //     });
            }
        });

    }

    setPrimaryImage(sampleKey: string) {

        const data: SamplePrimaryImageFormDialogData = { key: sampleKey };
        this._dialog.open(SamplePrimaryImageFormDialog, { data });
    }


    addEvaluation(sampleKey: string) {

        const state: SampleDetailStateModel = this._store.selectSnapshot(SampleDetailState);

        const data: EvaluationFormDialogData = {
            defaults: {
                sampleKey,
                massAction: false,
                sample: state.sample,
            }
        };

        this._dialog.open(EvaluationFormDialog, { data });

    }

    scheduleEvaluations(sampleKey: string) {

        const data: EvaluationScheduleFormDialogData = {
            sampleKey
        };

        this._dialog.open(EvaluationScheduleFormDialog, {data});

    }

    editEvaluation(evalKey: string) {

        const state: SampleDetailStateModel = this._store.selectSnapshot(SampleDetailState);

        const data: EvaluationFormDialogData = {
            key: evalKey,
            defaults: {
                massAction: false,
                sample: state.sample,
            }
        };

        this._dialog.open(EvaluationFormDialog, { data });
    }

    completeEvaluation(evalKey: string){
        this._dialog.open(EvaluationCompleteFormDialog, {data: {key: evalKey}});
    }

    deleteEvaluation(evalKey: string) {

        this._dialog.confirm(
            'Remove Evaluation',
            'Are you sure you want to remove this evaluation?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (!res) return;

            this._evalService.delete(evalKey)
            .subscribe(res => {
                this._snackbar.message('Evaluation deleted successfully');
            });

        });

    }

    editCharacteristics(evalKey: string) {
        this._dialog.openFullscreen(CharacteristicsFormDialog, { data: { evalKey } });
    }

    editMeasurements(evalKey: string) {
        this._dialog.openFullscreen(MeasurementsFormDialog, { data: { evalKey } });
    }

    editNotes(evalKey: string) {
        this._dialog.openFullscreen(NotesFormDialog, { data: { evalKey } });
    }

    editImages(evalKey: string){
        this._dialog.openFullscreen(ImagesFormDialog, { data: { key: evalKey } });
    }

    viewImageGallery(evalu: Evaluation){
        let data: ImageGalleryDialogData = {
            images: evalu.images,
            currentIndex: 0,
        };

        this._dialog.openFullscreen(ImageGalleryDialog, {data, disableClose: false}, false);
    }

    printEvaluationForm(evalKey: string) {

        const data: PrintEvaluationFormDialogData = {
            evalKey
        };

        this._dialog.open(PrintEvaluationFormDialog, { data });
    }


    extract(sample: Sample){

        if(!isPlant(sample)) return;

        const data: SampleFormDialogData = {
            defaults: {
                type: SampleType.HARVEST,
                plantSample: sample,
                ownerOrgKey: sample.ownerOrgKey
            }
        };

        this._dialog.open(SampleFormDialog, {data})
            .afterClosed()
            .subscribe(sample => {
                if(sample){
                    this._router.navigate([
                        '/org', sample.ownerOrgKey,
                        'samples', sample.type, sample.key
                    ]);
                }
            });

    }

    toggleDetail(evalKey: string) {
        this.evalDetailExpansion.toggle(evalKey);
    }

    trackEvaluation(index, item) {
        return item.key;
    }

    trackById(index, item){
        return item.id;
    }

}