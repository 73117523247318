import { FocusMonitor } from "@angular/cdk/a11y";
import { ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, forwardRef } from "@angular/core";
import { Component } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { AUTOCOMPETE_CHIPS_STYLES, ModelAutocompleteChipsComponent } from "@app/evaluation/components/model-autocomplete/model-autocomplete-chips-component";
import { SearchRequest, SearchResponse, TastingSampleService } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { Observable } from "rxjs";

@Component({
    selector: 'pv-tasting-sample-autocomplete-chips',
    templateUrl: './tasting-sample-autocomplete-chips.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TastingSampleAutocompleteChipsComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: TastingSampleAutocompleteChipsComponent
        }
    ],
    styles: [AUTOCOMPETE_CHIPS_STYLES]
})
export class TastingSampleAutocompleteChipsComponent extends ModelAutocompleteChipsComponent<TastingSample> {
    constructor(
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeDetect: ChangeDetectorRef,
        private _tastingSampleService: TastingSampleService
    ){
        super(fm, elRef, _changeDetect);
    }

    search(search: SearchRequest): Observable<SearchResponse<TastingSample>> {
        return this._tastingSampleService.search(search);
    }
}