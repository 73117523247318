import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Sample, ReportSampleSummary } from '@core/data';


@Component({
    selector: '[pvSampleLine]',
    template: `
        <mat-icon *ngIf="_cropIcon" [svgIcon]="_cropIcon"></mat-icon>
        <div class="text sampleLine" [innerHtml]="_html"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-sample-line pv-model-line'
    }
})
export class SampleLineComponent {

    @Input('pvSampleLine')
    set sample(sample: Sample | ReportSampleSummary) {
        this.getSampleDescription(sample)
    }

    @Input('pvSampleChip')
    set chipSample(sample: Sample | ReportSampleSummary) {
        this.getSampleDescription(sample, true);
    }

    @HostBinding('attr.title')
    _title: string;

    _graftIcon: string = null;
    _sampleIcon: string = null;
    _cropIcon: string = null;
    _html: string;

    @Input('includeBirthYear') includeBirthYear: boolean = true;
    constructor(private _formatter: SampleFormatter) { }

    private getSampleDescription(sample: Sample | ReportSampleSummary, isChip: boolean = false) {
        if (sample) {
            if (sample.scionCultivar) {
                this._cropIcon = 'common:' + sample.scionCultivar.cropId;
            }
            this._html = this._formatter.describeSample(sample, true, this.includeBirthYear, isChip);
            this._title = this._formatter.describeSample(sample, false, this.includeBirthYear, isChip);
            if("type" in sample) this._sampleIcon = 'common:' + sample.type + "_sample";
            if("sampleType" in sample) this._sampleIcon = 'common:' + sample.sampleType + "_sample";
        } else {
            this._html = '';
            this._cropIcon = null;
        }
    }

}