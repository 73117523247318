import { Dictionary } from "../dictionary";
import { TastingsIndex } from "./_types";
import { LIB_INDEX_TASTINGS_BASE } from "./tastings_base_index";

export class TastingsIndexDictionary extends Dictionary<TastingsIndex> {
    constructor(){
        super([
            LIB_INDEX_TASTINGS_BASE,
        ], false);
    }
}