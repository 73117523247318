import { Index } from './_types';
import { Dictionary } from '../dictionary';

import { LIB_INDEXES } from './indexes';


export class IndexDictionary extends Dictionary<Index> {
    constructor(){
        super(LIB_INDEXES);
    }
}

export * from './_types';