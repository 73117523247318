<ng-container *ngIf="state$ | async as state">

    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Organization</h2>
    </div>

    <!-- Content -->
    <div mat-dialog-content>

        <form #organizationForm="ngForm" [formGroup]="formGroup" class="pv-form" (ngSubmit)="attempt()"
            autocomplete="off">

            <div class="form-column">

                <h4 class="form-subheader">Basic Info</h4>

                <!-- Business -->
                <mat-form-field appearance="standard">
                    <mat-label>Business Name</mat-label>
                    <input matInput type="text" formControlName="business" autocomplete="off" required>
                    <mat-hint>Registered business name</mat-hint>
                    <mat-error>Business name is required, character limit is 32</mat-error>
                </mat-form-field>

                <!-- Website -->
                <h4 class="form-subheader">Website</h4>
                <mat-form-field appearance="standard">
                    <mat-label>Website URL</mat-label>
                    <input matInput type="text" formControlName="website">
                    <mat-hint>Link to your organization website</mat-hint>
                    <mat-error>Enter valid url starting with 'http://' or 'https://</mat-error>
                </mat-form-field>

                <!-- LogoFile -->
                <h4 class="form-subheader">Logo</h4>
                <pv-legacy-file-upload
                    (file)="setFormFile($event)"
                    [maxSize]="maxSize"
                    [allows]="allowedExts"
                    label="Logo File">
                </pv-legacy-file-upload>

            </div>

            <div class="form-column">

                <h4 class="form-subheader">Address</h4>

                <!-- Street Address -->
                <mat-form-field appearance="standard">
                    <mat-label>Street Address</mat-label>
                    <input matInput type="text" formControlName="addrStreet" required>
                    <mat-error>Street address is required, character limit is 32</mat-error>
                </mat-form-field>

                <!-- City -->
                <mat-form-field appearance="standard">
                    <mat-label>City</mat-label>
                    <input matInput type="text" formControlName="addrCity" required>
                    <mat-error>City address is required, character limit is 32</mat-error>
                </mat-form-field>

                <!-- Code -->
                <mat-form-field appearance="standard">
                    <mat-label>Code</mat-label>
                    <input matInput type="text" formControlName="addrCode" required>
                    <mat-hint>Postal Code / Area Code</mat-hint>
                    <mat-error>Area Code is required</mat-error>
                </mat-form-field>

                <!-- Region -->
                <mat-form-field appearance="standard">
                    <mat-label>Region</mat-label>
                    <input matInput type="text" formControlName="addrRegion" required>
                    <mat-error>Region is required, character limit is 32</mat-error>
                </mat-form-field>

                <!-- Country -->
                <mat-form-field appearance="standard">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="addrCountry" required>
                        <mat-option *ngFor="let country of countries" [value]="country.code">{{ country.label }}
                        </mat-option>
                    </mat-select>
                    <mat-error>Country is required</mat-error>
                </mat-form-field>

            </div>

        </form>

    </div>

    <!-- Actions -->
    <pv-form-actions mat-dialog-actions [form]="organizationForm" (cancel)="cancel()" [status]="state.status">
    </pv-form-actions>

</ng-container>