import { Component, ChangeDetectionStrategy, Input, OnInit } from "@angular/core";
import { SampleNote } from '@core/data';


@Component({
    selector: 'pv-notes-list',
    templateUrl: 'notes-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-notes-list'
    }
})
export class NotesListComponent implements OnInit {

    @Input()
    set notes(notes: SampleNote[]){
        this._notes = notes.concat().sort((a, b) => {
            if (a.takenAt < b.takenAt)
                return -1;
            if (a.takenAt > b.takenAt)
                return 1;
            return 0;
        });
    }

    _notes: SampleNote[] = [];

    ngOnInit(){

    }

    trackNote(index, note: SampleNote){
        return note.key;
    }

}