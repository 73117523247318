import { Pipe, PipeTransform } from '@angular/core';
import { Evaluation } from '@core/data';
import * as moment from 'moment';
import { CompiledTableReport, Series } from '../report-compiler.types';

@Pipe({
  name: 'descEval',
})
export class DescribeEvaluationPipe implements PipeTransform {

    constructor() { }

    transform(data: CompiledTableReport | Series, ref: string, cultivar?: string, evalu?: Evaluation) {

        let desc = '';
        let comboObj: any = null;

        let currentData: Series;
        currentData = data as Series;

        if(currentData.shortLabel) {
            //handle series here
            if(currentData.scionCultivarKey) desc += 'Scion Cultivar: '+cultivar+'\n';
            if(currentData.siteLabel) desc += 'Location: ' +currentData.siteLabel+'\n';
            desc += '\n';

            desc += currentData.evalLabel+'\n';
            desc += 'Week '+moment(currentData.startDate).format('w, YYYY, Do MMMM').toString()+'\n';
            if(currentData.sampleType) currentData.sampleType == 'harvest' ? desc += 'Type: Harvest Sample'+'\n' : desc+= 'Type: Plant Sample'+'\n';
            if(currentData.sampleType) currentData.sampleType == 'harvest' ? desc += 'Harvested Date: Week '+moment(currentData.sampleBirthDate).format('w, YYYY, Do MMMM').toString()+'\n' : desc += 'Planted Date: Week '+moment(currentData.sampleBirthDate).format('w, YYYY, Do MMMM').toString()+'\n';

            if(currentData.sampleType == 'harvest') {
                currentData.samplePlantedDate ? desc += "Planted Year: " + moment(currentData.samplePlantedDate).format('YYYY') : "Planted Year: N/A";
            }


            return desc;
        } else {
            //handle any here
            let currentData: CompiledTableReport;
            currentData = data as CompiledTableReport;

            currentData.tableData.forEach(element => {
                if (element != null && evalu != null) {
                    if(element.sampleKey == evalu.sampleKey && element.ref == ref) {
                        comboObj = { ...element, ...evalu};
                    }
                }
            });

            if(comboObj) {
                if(comboObj.sample.scionCultivar) desc += 'Scion Cultivar: '+comboObj.sample.scionCultivar.commonName+'\n';
                if(comboObj.sample.site) desc += 'Location: ' +comboObj.sample.site.name +', '+comboObj.sample.site.block+'\n';
                desc += '\n';

                desc += comboObj.label+'\n';
                desc += 'Week '+moment(comboObj.evalStartDate).format('w, YYYY, Do MMMM').toString()+'\n';
                comboObj.sample.type == 'harvest' ? desc += 'Harvest Sample'+'\n' : desc += 'Plant Sample'+'\n'
                comboObj.sample.type == 'harvest' ? desc += 'Harvested Date: Week '+moment(comboObj.sample.birthDate).format('w, YYYY, Do MMMM').toString()+'\n' : desc += 'Planted Date: Week '+moment(comboObj.sample.birthDate).format('w, YYYY, Do MMMM').toString()+'\n';
                //adds planted date of connected plant sample to harvest sample tooltip
                if (comboObj.sample.type == 'harvest') {
                    comboObj.sample.plantSample ? desc += "Planted Year: " + moment(comboObj.sample.plantSample.birthDate).format('YYYY') : "Planted Year: N/A";
                }
            }
            return desc;
        }
    }
}
