import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_BASE_URI } from '../http/api';
import { User } from '../types/user';
import { Notification } from '../types/notification';


export interface ProfileUpdateRequest {
    name?: string;
    email?: string;
    addrCountry?: string;
    _password?: string;
    _currentPassword?: string;
}

export interface TokenResponse {
    user_key: string;
    user_name: string;
    user_email: string;
    user_addrCountry: string;
    access_token: string;
    token_type: string;
    expires_in: string;
}

export class InvalidCredentialsError extends HttpErrorResponse {}

@Injectable()
export class AuthService {

    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string,
    ){}

    getAuthUser(): Observable<User> {
        return this._http.get<User>(`${this._baseUri}/jwt/user`);
    }

    getProfile(){
        return this._http.get<User>(`${this._baseUri}/profile`);
    }

    updateProfile(request: ProfileUpdateRequest){
        return this._http.put<User>(`${this._baseUri}/profile`, request)
                        .pipe(
                            catchError(e => {
                                if(e instanceof HttpErrorResponse){
                                    if(e.status === 400 && e.error.message === 'invalid_password'){
                                        return throwError(new InvalidCredentialsError(e));
                                    }
                                }
                                return throwError(e);
                            })
                        );
    }

    getNotifications(): Observable<Notification[]> {
        return this._http.get<Notification[]>(`${this._baseUri}/user/notifications`);
    }

    markNotificationsRead(ids: number[]): Observable<Notification[]> {
        return this._http.put<Notification[]>(`${this._baseUri}/user/notifications`, {markAsRead: ids});
    }

    refreshToken(){
        return this._http.post<TokenResponse>(`${this._baseUri}/jwt/refresh`, {});
    }

}

