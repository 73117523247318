import { Status, Sample, SampleType, Evaluation } from '@core/data';

export interface SampleDetailStateModel {
    status: Status;
    orgKey: string;
    sampleType: SampleType;
    sampleKey: string;
    sample: Sample;
    evaluations: Evaluation[];
}

export const SAMPLE_DETAIL_DEFAULTS: SampleDetailStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: null,
    sampleType: SampleType.PLANT,
    sampleKey: null,
    sample: null,
    evaluations: null,
};