<pv-view #view toolbarTitle="Cultivar Index" [returnTo]="['..']" *ngIf="state$ | async as state">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools>

        <button mat-icon-button [matMenuTriggerFor]="cultivarIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #cultivarIndexMenu>
            <button mat-menu-item (click)="add()" type="button">
                <mat-icon>add</mat-icon>
                <span>Add Cultivar</span>
            </button>
            <button mat-menu-item (click)="exportHistory(state.orgKey)" type="button">
                <mat-icon>history</mat-icon>
                <span>Export History</span>
            </button>
            <button mat-menu-item (click)="reload()" type="button">
                <mat-icon>refresh</mat-icon>
                <span>Refresh Cultivar Data</span>
            </button>
            <button mat-menu-item (click)="reset()" type="button">
                <mat-icon>block</mat-icon>
                <span>Clear Filters</span>
            </button>
        </mat-menu>

    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="cultivar-selection-toolbar mat-elevation-z3" color="accent"
        *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Cultivars Selected
        </span>
    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->

    <!-- SIDEBAR -->
    <mat-tab-group pv-view-sidebar>

        <!-- FILTER TAB -->
        <mat-tab label="Filter">
            <form [formGroup]="filterFormGroup" class="pv-form single-column p-12 cultivar-index-filter-form">
                <mat-form-field appearance="outline">
                    <mat-label>Search</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" matInput formControlName="search">
                    <mat-hint>Find by name, IID, SID, etc.</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Filter Crop</mat-label>
                    <mat-select formControlName="cropId" multiple>
                        <mat-option *ngFor="let crop of filterCropOptions" [value]="crop.id">
                            <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                            <span>{{ crop.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Filter Harvest Window</mat-label>
                    <mat-select formControlName="harvestWindow" multiple>
                        <mat-option value="E">Early</mat-option>
                        <mat-option value="M">Middle</mat-option>
                        <mat-option value="L">Late</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Filter Type</mat-label>
                    <mat-select formControlName="isRootstock">
                        <mat-option value="*">Any Graft</mat-option>
                        <mat-option [value]="0">Scion</mat-option>
                        <mat-option [value]="1">Rootstock</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Filter Licensee</mat-label>
                    <mat-select formControlName="licensee" multiple>
                        <mat-option *ngFor="let licensee of state.licenseeOptions" [value]="licensee">
                            {{ licensee }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="form-actions">
                    <button mat-button type="button" color="warn" (click)="reset()">
                        Clear Filters
                    </button>
                </div>

            </form>
        </mat-tab>
        <!-- FILTER TAB END-->


        <!-- IMPORT & EXPORT TAB -->
        <mat-tab label="Import/Export">

            <ng-template matTabContent>
                <ng-container>
                    <form [formGroup]="exportFormGroup" class="pv-form single-column p-12" (ngSubmit)="export()">
                        <h4 class="form-subheader">Export</h4>

                        <mat-form-field appearance="standard">
                            <mat-label>File Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="xlsx">Microsoft Excel (.xlsx)</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">
                            <button mat-flat-button color="accent" type="submit"
                                [disabled]="exportFormGroup.disabled">Export</button>
                        </div>
                    </form>

                    <mat-action-list dense>
                        <mat-divider></mat-divider>

                        <ng-container *ngFor="let export of state.exports; trackBy: trackByKey" [ngSwitch]="export.status">
                            <a mat-list-item [href]="export.url" target="_blank" *ngSwitchCase="'COMPLETE'">
                                <mat-icon matListIcon>email</mat-icon>
                                <span matLine>Export Queued</span>
                                <span matLine>Check your email inbox for result.</span>
                            </a>
                            <button type="button" mat-list-item *ngSwitchCase="'LOADING'">
                                <mat-icon matListIcon>queue</mat-icon>
                                <span matLine>Queueing Export...</span>
                                <mat-spinner [diameter]="18"></mat-spinner>
                            </button>
                            <button type="button" mat-list-item *ngSwitchDefault>
                                <mat-icon matListIcon color="warn">error</mat-icon>
                                <span matLine>Export Failed</span>
                                <span matLine>{{ export.status | descStatusMessage }}</span>
                            </button>
                        </ng-container>

                    </mat-action-list>
                </ng-container>

                <ng-container>
                    <form [formGroup]="importFormGroup" class="pv-form single-column p-12" (ngSubmit)="attemptImport()">
                        <h4 class="form-subheader">Import</h4>

                        <pv-import-upload (file)="setFormFile($event)"></pv-import-upload>

                        <div class="form-actions">
                            <button mat-flat-button color="accent"
                                (click)="$event.preventDefault(); downloadImportTemplate()"
                                [disabled]="importFormGroup.disabled">Import Template</button>
                            <button mat-flat-button color="accent" type="submit"
                                [disabled]="importFormGroup.disabled">Import</button>
                        </div>
                    </form>
                </ng-container>
            </ng-template>
        </mat-tab>
        <!-- EXPORT TAB END -->

        <!-- BULK ACTION TAB -->
        <mat-tab label="Bulk Action">
            <ng-template matTabContent>
                <mat-action-list class="cultivar-bulk-actions" dense>
                    <h2 matSubheader>Tastings Actions</h2>
                    <mat-divider></mat-divider>
                    <ng-container *ngIf="hasTastingsModule else tastingsMessage">
                        <mat-list-item (click)="openGenerateDialog(selection.selected)">
                            <mat-icon matListIcon svgIcon="common:tastings_sample"></mat-icon>
                            <span matLine>Generate Tastings Samples</span>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #tastingsMessage>
                        <mat-list-item (click)="openTastingsLanding()">
                            <mat-icon matListIcon svgIcon="common:tastings_sample"></mat-icon>
                            <span matLine>Tastings Module Subscription is required</span>
                        </mat-list-item>
                    </ng-template>
                </mat-action-list>
            </ng-template>
        </mat-tab>
        <!-- BULK ACTION TAB END -->

    </mat-tab-group>
    <!-- SIDEBAR END -->


    <!-- PAGINATION FOOTER -->
    <div pv-view-footer class="pv-table-footer">

        <label class="mat-caption mr-12" for="cultivar_index_sort">Sort</label>
        <mat-select [formControl]="columnControl" id="cultivar_index_sort" class="cultivar-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">
                {{ opt.label }}
            </mat-option>
        </mat-select>

        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)"
            matTooltip="Change Sort Direction">
            <mat-icon [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total" [pageSize]="state.page.size" [pageIndex]="state.page.index"
            (page)="paginate($event)" [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>


    </div>
    <!-- PAGINATION FOOTER END -->


    <!-- FAB -->
    <button pv-view-fab mat-fab color="primary" matTooltip="Add Cultivar" (click)="add()">
        <mat-icon>add</mat-icon>
    </button>


    <!-- BODY -->
    <div pv-view-body class="cultivar-index-body" [ngSwitch]="state.status">

        <!-- STATUS OVERLAY: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Cultivars..."></pv-view-loader>

        <!-- STATUS MESSAGE: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'" heading="No results"
            message="Current filters does not match any Cultivars. Update filters or add a Cultivar." icon="help">
            <button mat-button color="accent" pvMessageAction (click)="reset()">
                Clear Filters
            </button>
            <button mat-button color="primary" pvMessageAction (click)="add()">
                Add Cultivar
            </button>
        </pv-view-message>

        <!-- STATUS MESSAGE: ERROR -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
            [message]="state.status | descStatusMessage:'Cultivars'" icon="error">
            <button mat-button color="primary" pvMessageAction (click)="reload()">Reload</button>
        </pv-view-message>

        <!-- ngSwitch placeholders -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <!-- CULTIVAR TABLE -->
        <div class="pv-responsive-table" *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <table class="pv-cultivar-table" cdk-table [dataSource]="state.data" [trackBy]="trackByKey" matSort
                matSortDisableClear="true" (matSortChange)="sort($event)" [matSortActive]="state.sort.column"
                [matSortDirection]="state.sort.order">

                <ng-container cdkColumnDef="selectionBox">
                    <th cdk-header-cell *cdkHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle(state.data) : null"
                            [checked]="selection.hasValue() && isAllSelected(state.data)"
                            [indeterminate]="selection.hasValue() && !isAllSelected(state.data)" class="no-print">
                        </mat-checkbox>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <div class="image-overlay no-print" (click)="selection.toggle(row.key)">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row.key) : null"
                                [checked]="selection.isSelected(row.key)">
                            </mat-checkbox>
                        </div>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="cropId">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>Crop</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <mat-icon [svgIcon]="'common:' + row.cropId" class="mat-24"></mat-icon>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="commonName">
                    <th cdk-header-cell mat-sort-header start="asc" *cdkHeaderCellDef>Common Name</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <span class="title-line">
                            <mat-icon class="mat-18"
                                [svgIcon]="row.isRootstock ? 'common:flat_rootstock' : 'common:flat_scion'">
                            </mat-icon>
                            {{row.commonName}}
                        </span>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="tradeNames">
                    <th cdk-header-cell *cdkHeaderCellDef>Trade Name(s)</th>
                    <td cdk-cell *cdkCellDef="let row">{{row.tradeNames | implode}}</td>
                </ng-container>
                <ng-container cdkColumnDef="selectionId">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>Selection ID</th>
                    <td cdk-cell *cdkCellDef="let row" class="mat-code">{{row.selectionId}}</td>
                </ng-container>
                <ng-container cdkColumnDef="importId">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>Import ID</th>
                    <td cdk-cell *cdkCellDef="let row" class="mat-code">{{row.importId}}</td>
                </ng-container>
                <ng-container cdkColumnDef="harvestWindow">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>Harvest Window</th>
                    <td cdk-cell *cdkCellDef="let row">
                        {{ row.harvestWindow === 'U' ? '' : row.harvestWindow }}</td>
                </ng-container>
                <ng-container cdkColumnDef="licensee">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>Licensee</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.licensee }}</td>
                </ng-container>
                <ng-container cdkColumnDef="samples">
                    <th cdk-header-cell *cdkHeaderCellDef>Samples</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <div class="sample-count" *ngIf="row.scionPlantSamplesCount"
                            matTooltip="Associated Plant Sample Scion">
                            <mat-icon svgIcon="common:plant_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;{{ row.scionPlantSamplesCount }}</span>
                        </div>
                        <div class="sample-count" *ngIf="row.rootstockPlantSamplesCount"
                            matTooltip="Associated Plant Sample Rootstock">
                            <mat-icon svgIcon="common:plant_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <span>&nbsp;{{ row.rootstockPlantSamplesCount }}</span>
                        </div>
                        <div class="sample-count" *ngIf="row.scionHarvestSamplesCount"
                            matTooltip="Associated Harvest Sample Scion">
                            <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;{{ row.scionHarvestSamplesCount }}</span>
                        </div>
                        <div class="sample-count" *ngIf="row.rootstockHarvestSamplesCount"
                            matTooltip="Associated Harvest Sample Rootstock">
                            <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <span>&nbsp;{{ row.rootstockHarvestSamplesCount }}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="createdAt">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>
                        <mat-icon matTooltip="Created At">access_time</mat-icon>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <small>{{ row.createdAt | date:'mediumDate' }}</small>
                    </td>
                </ng-container>
                <ng-container cdkColumnDef="actions">
                    <th cdk-header-cell *cdkHeaderCellDef></th>
                    <td cdk-cell *cdkCellDef="let row">
                        <button mat-icon-button matTooltip="Cultivar Actions" [matMenuTriggerFor]="cultivarMenu"
                            [matMenuTriggerData]="{cultivar: row}">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr cdk-header-row *cdkHeaderRowDef="state.displayColumns"></tr>
                <tr cdk-row *cdkRowDef="let row; columns: state.displayColumns"
                    [class.selected]="selection.isSelected(row.key)"></tr>
            </table>
        </div>
        <!-- CULTIVAR TABLE END -->

        <!-- CULTIVAR ACTION MENU -->
        <mat-menu #cultivarMenu>
            <ng-template matMenuContent let-cultivar="cultivar">
                <button mat-menu-item (click)="edit(cultivar)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Cultivar </span>
                </button>
                <button mat-menu-item (click)="remove(cultivar)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete Cultivar</span>
                </button>
            </ng-template>
        </mat-menu>
        <!-- CULTIVAR ACTION MENU END -->

    </div>
    <!-- BODY END -->


</pv-view>