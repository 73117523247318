import { DetailRequest, SampleService, Status, translateCommonErrorStatus } from '@core/data';
import { Action, State, StateContext, Selector } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { SampleFormStateModel } from './sample-form.state';
import { Injectable } from '@angular/core';


const DEFAULTS: SampleFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null
};

export class InitUpdateSamplePrimaryImageForm {
    static readonly type = "[SamplePrimaryImageForm] Init sample Primary Image update";
    constructor(public key: string) { }
}

export class SubmitSamplePrimaryImageForm {
    static readonly type = "[SamplePrimaryImageForm] Submit primary image";
    constructor(public primaryImageKey: string) { }
}


@State<SampleFormStateModel>({
    name: 'sample_primary_image_form',
    defaults: DEFAULTS
})
@Injectable()
export class SamplePrimaryImageFormState {

    @Selector()
    static data(state: SampleFormStateModel){
        return state.data;
    }

    constructor(private _sampleService: SampleService) { }

    @Action(InitUpdateSamplePrimaryImageForm, { cancelUncompleted: true })
    initUpdateSamplePrimaryImageForm(ctx: StateContext<SampleFormStateModel>, action: InitUpdateSamplePrimaryImageForm) {

        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING
        });

        let detailRequest: DetailRequest;

        detailRequest = {
            related: ['images']
        };

        return this._sampleService.get(action.key, detailRequest)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            status: Status.OK
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );

    }


    @Action(SubmitSamplePrimaryImageForm)
    submitSamplePrimaryImageForm(ctx: StateContext<SampleFormStateModel>, action: SubmitSamplePrimaryImageForm) {

        const state = ctx.getState();

        let request = this._sampleService.update(state.key, { primaryImageKey: action.primaryImageKey });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );

    }



}