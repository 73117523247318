import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, ControlContainer, NgForm } from '@angular/forms';
import { Status } from '@core/data';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dialog } from '../dialog/dialog.service';


@Component({
    selector: 'pv-form-actions',
    templateUrl: 'form-actions.component.html',
    styleUrls: ['form-actions.component.scss'],
    host: {
        class: 'pv-form-actions'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class FormActionsComponent implements OnDestroy {


    @Input()
    set formGroup(control: AbstractControl) {
        if(control) {
            this.registerControl(control);
            this.submitted = true;
        }
    }

    @Input()
    set form(form: NgForm) {
        if(form) this.registerForm(form);
    }

    @Input()
    set formId(id: string) {
        this._formId = id;
    }

    @Input()
    status: Status = Status.OK;

    @Output('cancel')
    cancelEmitter = new EventEmitter();

    @Output('submit')
    submitEmitter = new EventEmitter();

    @Input()
    saveAction = "Save";

    @Input()
    set loading(loading: boolean){
        console.warn("FormActionsComponent: Using loading input is deprecated, use status instead");
    }

    @Input()
    confirmDiscard = true;

    @Input()
    tastingsEnabled = false;


    _form: NgForm;
    _formId: string;
    _control: AbstractControl;

    disabled = false;
    dirty = false;
    invalid = false;
    submitted = false;

    private _destroy$ = new Subject();

    private _statusChangeSub = Subscription.EMPTY;
    private _submitSub = Subscription.EMPTY;

    constructor(
        private _dialogs: Dialog,
        private _changeDetector: ChangeDetectorRef
    ) { }

    submit(event: MouseEvent) {
        this.submitEmitter.emit(event);
        if(this._form) {
            event.preventDefault();
            event.stopPropagation();
            this._form.onSubmit(event);
        }
    }

    cancel(event) {

        if (this.confirmDiscard && this._form && this._form.dirty) {
            this.doConfirmDiscard();
        } else if(this.confirmDiscard && this._control && this._control.dirty){
            this.doConfirmDiscard();
        }else{
            this.cancelEmitter.emit(event);
        }

    }

    private doConfirmDiscard(){
        this._dialogs.confirm('Discard Changes', 'Are you sure you want to discard your changes?', 'Discard')
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.cancelEmitter.emit(event);
                }
            });
    }

    ngOnDestroy() {

        this._statusChangeSub.unsubscribe();
        this._submitSub.unsubscribe();

        this._destroy$.next();
        this._destroy$.complete();
    }

    private registerForm(form: NgForm){

        this._form = form;

        this._submitSub.unsubscribe();

        this._submitSub =
            form.ngSubmit
                .pipe(takeUntil(this._destroy$))
                .subscribe(submit => {
                    this.submitted = form.submitted;
                    this._changeDetector.markForCheck();
                });

        this.submitted = form.submitted;

        this.registerControl(form);

    }

    private registerControl(control: AbstractControl | ControlContainer) {

        this._statusChangeSub.unsubscribe();

        this._statusChangeSub =
            control.statusChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(status => {
                this.disabled = control.disabled;
                this.invalid = control.invalid;
                this.dirty = control.dirty;
                this._changeDetector.markForCheck();
            });

        this.disabled = control.disabled;
        this.invalid = control.invalid;
        this.dirty = control.dirty;

    }


}