import { MatDialogConfig } from '@angular/material/dialog';
import {ScrollStrategy} from '@angular/cdk/overlay';

export const APP_UI_DIALOG_CONFIG: MatDialogConfig = {
    // autoFocus: true,
    closeOnNavigation: true,
    disableClose: true,
    maxHeight: '100vh',
    maxWidth: '100vw',
    hasBackdrop: true
};