import * as moment from "moment";
import { OrganizationSubscription } from "../public_api";

enum Subscriptions {
    TASTINGS = 'tastings_module',
    EVALUATION ='evaluation_module',
}

export class OrganizationSubscriptionHandler {

    private subscriptions: OrganizationSubscription[] = [];

    constructor() {}

    setSubscriptions(subs: OrganizationSubscription[]) {
        this.subscriptions = subs;
    }

    getSubscriptions(): string[] {
        return this.subscriptions.map(sub => {return sub.moduleRef});
    }

    isSubscribedToTastings(): boolean {
        let hasTastingsSubscription: boolean = this.subscriptions.map(sub => {return sub.moduleRef}).includes(Subscriptions.TASTINGS);
        let sub: OrganizationSubscription = this.getSub(Subscriptions.TASTINGS);
        let isActive: boolean = this.getActiveStatus(sub);

        return (isActive && hasTastingsSubscription);
    }

    isSubscribedToEvaluation(): boolean {
        let hasEvaluationSubscription: boolean = this.subscriptions.map(sub => {return sub.moduleRef}).includes(Subscriptions.EVALUATION);
        let sub: OrganizationSubscription = this.getSub(Subscriptions.EVALUATION);
        let isActive: boolean = this.getActiveStatus(sub);

        return (isActive && hasEvaluationSubscription);
    }

    isTastingsActive(): boolean {
        return this.getActiveStatus(this.getSub(Subscriptions.TASTINGS));
    }

    isEvaluationActive(): boolean {
        return this.getActiveStatus(this.getSub(Subscriptions.EVALUATION));
    }

    private getActiveStatus(sub: OrganizationSubscription): boolean {
        if (!sub) return false;
        let now = moment();
        return (moment(sub.startAt) <= now && moment(sub.endAt) > now);
    }

    private getSub(ref: string): OrganizationSubscription {
        return this.subscriptions.find(sub => {return sub.moduleRef === ref});
    }
}