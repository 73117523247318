import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Library } from '@library';
import { LIBRARY } from '../library';

@Pipe({
  name: 'labelCrop'
})
export class LabelCropPipe implements PipeTransform {

    constructor(@Inject(LIBRARY) private _evalLib: Library){}

    transform(id: string) {

        if (!id) return '';

        let item = this._evalLib.crops.get(id);

        if (item){
            return item.label;
        }else{
            return 'Unknown Crop';
        }
    }
}