import { Pipe, PipeTransform } from "@angular/core";
import { Report } from '@core/data';


@Pipe({
    name: 'getReportPrimaryImages',
    pure: true
})
export class GetReportPrimaryImagesPipe implements PipeTransform {

    transform(report: Report, limit = 4){

        if(Array.isArray(report.sampleSummaries) && report.sampleSummaries.length > 0){
            let images = [];

            report.sampleSummaries.every(summ => {
                if(summ.samplePrimaryImageRef){
                    images.push(summ.samplePrimaryImageRef);
                }
                return images.length <= limit;
            });
            return images;
        }

        return null;
    }

}
