import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, forwardRef, Injector, Input, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl } from '@angular/forms';
import { coerceMomentProperty } from '@core/utils';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline/esnext";

import { ReportSample, ReportSampleEvalTimelineOptions } from '@core/data';
import { SharedService } from "@app/evaluation/timeline.service";

const DEFAULT_TIMELINE_OPTIONS = {
    editable: false,
    moment: function(date) {
        return moment(date).utc();
    },
    format: {
        minorLabels: {
            millisecond: 'SSS',
            second: 's',
            minute: 'HH:mm',
            hour: 'HH:mm',
            weekday: 'ddd D',
            day: 'D',
            week: '[W]W',
            month: 'MMM',
            year: 'YYYY'
        },
        majorLabels: {
            millisecond: 'HH:mm:ss',
            second: 'D MMMM HH:mm',
            minute: 'ddd D MMMM',
            hour: 'ddd D MMMM',
            weekday: 'MMMM YYYY',
            day: 'MMMM YYYY',
            week: 'MMMM YYYY',
            month: 'YYYY',
            year: ''
        }
    },
    align: 'left',
    zoomKey: 'ctrlKey',
    groupHeightMode: 'fixed',
    showWeekScale: true,
    stackSubgroups: true,
    stack: true
};

@Component({
    selector: 'pv-timeline',
    template: '<div #container></div>',
    styleUrls: ['timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'pv-timeline'
    },
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => TimelineComponent),
          multi: true
        }
    ]
})
export class TimelineComponent implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor {

    @ViewChild('container', {static: true})
    containerRef: ElementRef<HTMLDivElement>;

    public items = new DataSet([]);
    public groups = new DataSet([]);

    private _timeline: Timeline;
    private _allTimelines: Timeline[] = [];
    private _options$ = new BehaviorSubject<any>(DEFAULT_TIMELINE_OPTIONS);
    private _destroy$ = new Subject();

    private _value: Partial<ReportSample>[] = [];
    onChange: any = () => {};
    onTouched: any = () => {};
    private _isWriting = false;
    private _control: FormControl;
    @Input() idKeys: { sampleKey: string, evalKey: string };
    private startDates: moment.Moment[] = [];
    private endDates: moment.Moment[] = [];
    private syncTimelineSubscription: Subscription;;

    @Input()
    set start(date: string | moment.Moment){
        date = coerceMomentProperty(date);
        if(date){
            this.mergeOptions({start: date || undefined}) ;
        }else{
            this.removeOption('start');
        }
    }

    @Input()
    set end(date: string | moment.Moment){
        date = coerceMomentProperty(date);
        if(date){
            this.mergeOptions({end: date || undefined});
        }else{
            this.removeOption('end');
        }
    }

    @Input() lowestStart;
    @Input() highestEnd;

    constructor(
        private _ngZone: NgZone,
        private injector: Injector,
        private sharedService: SharedService
    ) {
        this.syncTimelineSubscription = sharedService.timelineSyncEvent.subscribe(() => {
            this.syncTimelines();
        });
    }

    ngOnInit() { }

    ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this._control = ngControl.control as FormControl;
        }
    }

    ngAfterContentInit(){
        this._timeline = new Timeline(
            this.containerRef.nativeElement,
            this.items,
            this.groups,
            this._options$.getValue(),
        );

        this._allTimelines.push(this._timeline);

        this._timeline.on('rangechanged', (data) => {
            if (!this._isWriting) {
                let reportSamples: Partial<ReportSample>[] = [];
                if (typeof this._control === 'undefined') return;
                this._control.value.forEach(sample => {
                    const rs: Partial<ReportSample> = {
                        sampleKey: sample.sampleKey,
                        includeEvals: sample.includeEvals,
                        includeImages: sample.includeImages,
                        includeNotes: sample.includeNotes,
                        data: sample.data,
                    };

                    let evalTimelineOptions: { [key: string] :ReportSampleEvalTimelineOptions } = {
                        ...sample.evalsTimelineOptions,
                    }
                    if (sample.sampleKey === this.idKeys.sampleKey) {
                        evalTimelineOptions[this.idKeys.evalKey] = {
                            ...evalTimelineOptions[this.idKeys.evalKey],
                            startDate: moment(data.start),
                            endDate: moment(data.end),
                        }
                    }
                    rs.evalsTimelineOptions = evalTimelineOptions;

                    reportSamples.push(rs);
                });
                this._value = reportSamples;
                this.onChange(this._value);
            }
        });
    }

    syncTimelines() {
        this.performTimelineSync(this.lowestStart, this.highestEnd)
    }

    performTimelineSync(startDate: moment.Moment, endDate: moment.Moment) {
        this._allTimelines.forEach((timeline) =>
            timeline.setWindow({
                start: startDate,
                end: endDate,
            })
        );
    }

    ngOnDestroy(){
        this.items = null;
        this.groups = null;
        this._options$.complete();
        this._timeline.destroy();
        this.syncTimelineSubscription.unsubscribe();
    }

    private mergeOptions(options){
        this._options$.next({
            ...this._options$.getValue(),
            ...options
        });
    }

    private removeOption(key: string){
        const options = {...this._options$.getValue()};

        if(options.hasOwnProperty(key)){
            delete options[key];
            this._options$.next(options);
        }
    }

    writeValue(obj: any): void {
        this._isWriting = true;
        try {
            this._value = Array.isArray(obj) ? obj : [];
        } finally {
            this._isWriting = false;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
