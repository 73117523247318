import { Measurement } from './_types';

export const LIB_MEASURES_FRUT_TREE: Measurement[] = [

    // Tree

    {
        id: "tree_trunk_circum",
        unit: "mm",
        label: "Trunk Circumference",
        tag: "CIR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_cir_std",
        format: "####mm",
        min: 0,
        max: 1000,
        suggestMin: 1,
        suggestMax: 3000,
        subjectId: "tree",
    },
    {
        id: "tree_height",
        unit: "m",
        label: "Tree Height",
        tag: "HEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_hei_std",
        format: "##0.00m",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
    {
        id: "tree_depth",
        unit: "m",
        label: "Tree Depth",
        tag: "DEP",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_dep_std",
        format: "##0.00m",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
    {
        id: "tree_width",
        unit: "m",
        label: "Tree Width",
        tag: "WID",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_wid_std",
        format: "##0.00m",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
    {
        id: "tree_fde",
        unit: "%",
        label: "Flower Density",
        tag: "FDE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_fde_10",
        format: "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "tree",
    },
    {
        id: "tree_fruit_count",
        unit: "#",
        label: "Number of Fruit",
        tag: "FNU",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_fruit_count_10",
        format: "##0",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
    {
        id: "tree_total_yield",
        unit: "kg",
        label: "Total Yield",
        tag: "YIE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_yie_std",
        format: "####kg",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
    {
        id: "tree_est_yield",
        unit: "kg",
        label: "Estimated Yield",
        tag: "EYI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_est_yield_10",
        format: "####kg",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
    {
        id: "tree_shoot_length",
        unit: "m",
        label: "Length of Shoots",
        tag: "SHL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_est_yield_10",
        format: "##0.00m",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },

    //Calculated values
    {
        id: "tree_yield_eff",
        unit: "kg/cm2",
        label: "Yield Efficiency",
        tag: "YEF",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_tree_yie_eff",
        format: "####kg/cm2",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
    {
        id: "prod_average",
        unit: "kg/tree",
        label: "Productivity Average",
        tag: "PAV",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_tree_prod_average",
        format: "####kg/tree",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
    {
        id: "kg_per_pick",
        unit: "kg/pick",
        label: "Kilograms per Pick",
        tag: "KPP",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_kg_per_pick",
        format: "####kg/pick",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree_calculated",
    },
    {
        id: "kg_per_pick_perc",
        unit: "%/pick",
        label: "Kilogram Percentage per Pick",
        tag: "PKP",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_kg_per_pick_perc",
        format: "####%/pick",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "tree_calculated",
    },


];