import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Status, Sample, SampleService, EvaluationService, translateCommonErrorStatus, Evaluation } from '@core/data';
import { tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";


export interface EvaluationScheduleFormStateModel {
    status: Status;
    sampleKey: string;
    sample: Sample;
    result: Evaluation[];
}

const DEFAULTS: EvaluationScheduleFormStateModel = {
    status: Status.UNINITIALIZED,
    sampleKey: null,
    sample: null,
    result: null
};


export class InitEvaluationScheduleForm {
    static readonly type = '[EvaluationScheduleForm] Init';
    constructor(public sampleKey: string){}
}

export class SubmitEvaluationScheduleForm {
    static readonly type = '[EvaluationScheduleForm] Submit';
    constructor(public evals: Partial<Evaluation>[]){}
}


@State<EvaluationScheduleFormStateModel>({
    name: 'evaluation_schedule_form',
    defaults: DEFAULTS,
})
@Injectable()
export class EvaluationScheduleFormState {


    @Selector()
    static sample(state: EvaluationScheduleFormStateModel){
        return state.sample;
    }

    constructor(
        private _sampleService: SampleService,
        private _evalService: EvaluationService
    ){}

    @Action(InitEvaluationScheduleForm, {cancelUncompleted: true})
    initEvaluationScheduleForm(ctx: StateContext<EvaluationScheduleFormStateModel>, action: InitEvaluationScheduleForm){

        ctx.patchState({
            ...DEFAULTS,
            status: Status.LOADING,
            sampleKey: action.sampleKey
        });

        return this._sampleService.get(action.sampleKey, {related: ['scionCultivar']})
                .pipe(tap(
                    sample => {

                        ctx.patchState({
                            status: Status.OK,
                            sample
                        });

                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        })
                    }
                ));

    }


    @Action(SubmitEvaluationScheduleForm)
    submitEvaluationScheduleForm(ctx: StateContext<EvaluationScheduleFormStateModel>, action: SubmitEvaluationScheduleForm) {

        ctx.patchState({
            status: Status.LOADING,
        });


        return this._evalService.createMany({evals: action.evals})
            .pipe(tap(
                result => {

                    ctx.patchState({
                        status: Status.COMPLETE,
                        result
                    });

                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));

    }

}