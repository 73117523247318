import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'displayFileSize'
})
export class FileSizePipe implements PipeTransform {
    constructor() {}

    transform(byteSize: number) {
        if (!byteSize) return 'Unable to read file size.';

        let size = this.reduceSize(byteSize);
        let count = 0;
        let sizes = ['KB', 'MB', 'GB'];

        while (size > 100) {
            if (this.reduceSize(size) < 1) break;
            size = this.reduceSize(size);
            count++;
        }

        size = Math.round(size);


        return `${size} ${sizes[count]}`;
    }

    private reduceSize(size: number): number {
        return size / 1000
    }

}