import { Pipe, PipeTransform } from '@angular/core';
import { SampleNote } from '@core/data';

@Pipe({
    name: 'extractMessage'
})
export class ExtractMessagePipe implements PipeTransform {

    constructor(){}

    transform(data: SampleNote[]): any {
        let message: string = "";

        data.forEach(item => {
            message = message + " " + item.message;
        })

        if (message.length == 0) {
            message = "No Notes";
        }

        return message;
    }
}


