import { Cultivar } from './cultivar';
import { Sample, SampleType } from "./sample";
import { WeatherReportData } from './weather-station';
import { Moment } from 'moment';
import { Crop } from 'library/src/crops';
import { Series } from '@app/evaluation/report-compiler.types';

/**
 * Data Models
 *
 */

export interface Report {
    key: string;
    title: string;
    description: string;
    ownerOrgKey: string;
    shareKey: string;

    // relations
    sections?: ReportSection[];
    remarks?: ReportRemark[];
    sampleSummaries?: ReportSampleSummary[];
    samples?: ReportSample[];
    options?: ReportOptions;
    weather?: ReportWeather[];
    tables?: ReportTable[];
}

export interface ReportSample {
    sampleKey: string;
    reportSampleIndex: number;
    includeEvals: string[];
    includeImages: string[];
    includeNotes: string[];
    data?: Sample;

    evalsTimelineOptions?: { [key: string]: ReportSampleEvalTimelineOptions };
}

export interface ReportSampleSummary {
    sampleCode: string;
    sampleKey: string;
    sampleOwnerOrgKey: string;
    samplePrimaryImageRef: string;
    sampleType: SampleType;
    sampleLabel: string;
    scionCultivar?: Cultivar;
}

export interface ReportRemark {
    sectionId: string;
    text: string;
    documents?: ReportDocument[];
    images?: ReportImage[];
    imageOptions?: RemarkImageOption[];
}

export interface ReportWeather extends WeatherReportData {
    id: number;
    title: string;
}

export interface ReportTable {
    index?: number;
    id: number;
    title: string;
    description?: string;
    tableOptions?: TableReportOptions;
}

export interface TableReportOptions {
    columns?: TableReportColumn[];
    isAggregate?: boolean;
    isSpread?: boolean;
    showSummary?: boolean;
    includedEvals?: string[];
    cropId?: string;
}

export enum TableReportColumnType {
    Property = 'p',
    Characteristic = 'c',
    Measurement = 'm',
    Index = 'i',
    Calculation = 'x',
}

export interface TableReportColumn {
    id: string;
    type: TableReportColumnType;
}

export interface ReportOptions {
    // globals
    themeId: string;
    showLegend: boolean;
    showStorageRegime: boolean;
    showEmptyFields: boolean;
    toggleDescription: boolean;
    letterheadRef: string;

    letterheadKey:string;
    letterhead?: ReportLetterhead;

    showTimeline: boolean;
    timelineOptions: TimelineReportOptions;

    showWeather: boolean;
    showTables: boolean;
    tables: ReportTable[];

    showCultivarInfo: boolean;
    cultivarInfoOptions: InfoSheetReportOptions;

    showCharSummary: boolean;
    charSummaryOptions: CharacteristicSummaryReportOptions;

    showMeasureSummary: boolean;
    measureSummaryOptions: MeasureSummaryReportOptions;

    showCharIndex: boolean;
    charIndexOptions: CharacteristicIndexReportOptions;

    showNotes: boolean;
    noteOptions: NoteReportOptions;

    showImages: boolean;
    imageOptions: ImageReportOptions;

    sectionsOrder: string;

    imageSectionOptions: ImageSectionOptions;

}

export interface InfoSheetReportOptions {
    showCultivarSelection: boolean;
    showSampleOrigin: boolean;
    primaryCultivar: Cultivar;
    controlCultivar: Cultivar;
    includeChars: string[];
    includeMeasures: string[];
    includeIndexes: string[];
    includeImages: string[][];
    infoSheetImageOptions?: ReportImageOption[][];
    imageSize?: 's' | 'm' | 'l' | 'xl';
    includeSamples?: ReportSample[];
    showCharts?: boolean;
    showData?: boolean;
    showStats?: boolean;
    overrides: InfosheetMeasurementSummaryOverrides[];
}

export interface InfosheetMeasurementSummaryOverrides {
    measureId: string;
    bucketId?: string;
    chartId?: string;
    showChart?: boolean;
    showData?: boolean;
    showStats?: boolean;
}


export interface MeasurementSummaryOverrides {
    measureId: string;
    bucketId?: string;
    chartId?: string;
    showChart?: boolean;
    showData?: boolean;
    showStats?: boolean;
}

export interface MeasureSummaryReportOptions {
    includeMeasures: string[];
    showCharts?: boolean;
    showData?: boolean;
    showStats?: boolean;

    overrides: MeasurementSummaryOverrides[];
}

export interface CharacteristicSummaryReportOptions {
    includeChars: string[];
    layoutDense: boolean;
}

export interface CharacteristicIndexReportOptions {
    includeIndexes: string[];
    showCharts?: boolean;
    showData?: boolean;
}

export interface TimelineReportOptions {
    startDate: string;
    endDate: string;
}

export interface ReportSampleEvalTimelineOptions {
    exclude: boolean;
    startDate?: Moment;
    endDate?: Moment;
}

export interface ImageReportOptions {
    size?: 's' | 'm' | 'l' | 'xl';
    showNotes?: boolean;
    showDates?: boolean;
    showSite?: boolean;
    showPlantedYear?: boolean;
    overlay?: boolean;
}

export interface NoteReportOptions {

}

export interface ReportTemplate {
    id: number;
    name: string;
    ownerOrgKey: string;
    reportOptions: ReportOptions;
    reportRemarks: ReportRemark[];
}

export interface ReportDocument {
    key: string;
    reportKey: string;
    sectionId: string;
    fileRef: string;
    name: string;
    extension: string;

    //timestamps
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}
export interface ReportImage {
    key: string;
    reportKey: string;
    sectionId: string;
    fileRef: string;
    name: string;
    extension: string;

    //timestamps
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface RemarkImageOption {
    key: string;
    fileRef: string;
    position: number;
    extension?: string;
    name?: string;
}
export interface ReportSection {
    index: number;

    title: string;
    iconRef: string;

    enabled: boolean;
    options: ReportSectionOption[];

    //To reference the appropriate formControls and formGroups
    formControlName: string;
    formGroupName: string;

    optionsData?: TimelineReportOptions | InfoSheetReportOptions | CharacteristicSummaryReportOptions | MeasureSummaryReportOptions | CharacteristicIndexReportOptions | NoteReportOptions | ImageReportOptions
}

export interface ReportSectionOption {
    title: string;
    type: 'check' | 'select-one' | 'select-many';

    //To reference the appropriate formControls
    formControlName: string;
}

export interface ReportLetterhead {
    key: string;
    ownerOrgKey: string;
    name: string;
    fileRef: string;
    extension: string;

    createdAt: string;
    updatedAt: string;
    deletedAt: string;
}

export interface ReportImageOption {
    key: string;
    name: string;
    fileRef: string;
    extension: string;
    position: number;

    note?: string;
    takenAt?: string;
}

export interface ImageSectionOptions {
    images: ReportImageOption[][];
}

export interface ReportTableTemplate {
    id: number;
    name: string;
    ownerOrgKey: string;
    tableOptions?: TableReportOptions;
}

export interface ReportTableTemplateSubjectGroup {
    id: string;
    subject: ReportTableTemplateSubject;
    templates: ReportTableTemplate[];
}

export interface ReportTableTemplateSubject {
    id: string;
    label: string;
}

export interface ReportCharacteristicSummary {
    groupId: string,
    groupLabel: string,
    charId: string,
    charLabel: string,
    values: {
        value: string;
        series: Series
    }[],
}
