<ng-container *ngIf="state$ | async as state" [ngSwitch]="state.status">

    <span *ngSwitchCase="'LOADING'" class="mat-caption">Loading organisation...</span>
    <span *ngSwitchCase="'EMPTY'" class="mat-caption">No organisation</span>

    <ng-container *ngSwitchCase="'OK'">
        <span *ngIf="state.org else empty" class="mat-caption text-primary">{{ state.org.name }}</span>
        <ng-template #empty>
            <span class="mat-caption">Unknown Organisation</span>
        </ng-template>
    </ng-container>

    <span *ngSwitchDefault class="mat-caption text-warn">Error loading organisation.</span>

</ng-container>