import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ExportType } from "@core/data/types/export-history";
import { ExportHistoryFormState, ExportHistoryFormStateModel, ExportHistoryQuery, InitExportHistoryForm, QueryExportHistory } from "./export-history-form.state";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Dialog, Snackbar } from "@core/material";
import { debounceTime, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: 'pv-export-history-dialog',
    templateUrl: 'export-history-form.dialog.html',
    styleUrls: ['export-history-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportHistoryDialog implements OnInit, OnDestroy {

    @Select(ExportHistoryFormState)
    state$: Observable<ExportHistoryFormStateModel>;

    @Select(ExportHistoryFormState.query)
    query$: Observable<ExportHistoryQuery>;

    private _destroy$ = new Subject();

    queryFormGroup = new FormGroup({
        search: new FormControl(null),
    });

    constructor(
        private _dialogRef: MatDialogRef<ExportHistoryDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: ExportHistoryDialogData,
        private _store: Store,
        private _snackBar: Snackbar,
        private dialog: Dialog,
    ) {}

    ngOnInit(): void {
        this._store.dispatch(new InitExportHistoryForm(
            this._data.orgKey,
            this._data.type,
            this._data.sampleType ? this._data.sampleType : null
        ));

        this.query$
            .pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                this.queryFormGroup.setValue({
                    search: query.search,
                }, {emitEvent: false});
            });

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(value => {
                this._store.dispatch(new QueryExportHistory(value));
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    openDownloadLink(url: string): void {
        window.open(url, "_blank");
    }
}

export interface ExportHistoryDialogData {
    orgKey: string;
    type: ExportType;
    sampleType?: string;
}