<div class="mat-dialog-content image-dialog-content" [class.expanded]="expanded">
    <picture class="image-dialog-picture">
        <source *ngFor="let source of sourceSet" [attr.media]="source.media" [attr.srcset]="source.srcset">
        <img [src]="sourceMain" (load)="imageLoad($event)" (error)="imageError($event)" alt="">
        <p *ngIf="showDeviceMessage" class="image-error mat-body-1">
            Image is stored on a mobile device. To see the full image,
            allow the device to sync then refresh this page.
        </p>
        <p *ngIf="showErrorMessage" class="image-error mat-body-1">
            Oops! Unable to download the image from storage...
        </p>
    </picture>

</div>
<div class="mat-dialog-actions actions-right">
    <button mat-icon-button (click)="toggleExpanded()" [matTooltip]="expanded ? 'Limit Size' : 'Expand Size'">
        <mat-icon>{{ expanded ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>
    <button mat-icon-button [matMenuTriggerFor]="downloadMenu" matTooltip="Download" *ngIf="downloads.length > 0">
        <mat-icon>cloud_download</mat-icon>
    </button>
    <mat-menu #downloadMenu="matMenu">
        <a mat-menu-item *ngFor="let dl of downloads" target="_blank" [href]="dl.url">{{ dl.name }}</a>
    </mat-menu>
    <button mat-icon-button (click)="close()" matTooltip="Close">
        <mat-icon>close</mat-icon>
    </button>
</div>