import { SetSampleLabelOptions } from "@app/evaluation/components/sample-index/sample-index.state-actions";
import { LocalStorage } from "@core/browser";
import { CultivarService, CultivarUpdated, DetailRequest, Filter, FilterBuilder, FilterQueryMode, patchArrayItem, replaceArrayItem, SiteUpdated, Status, TastingEventCreated, TastingEventDeleted, TastingEventService, TastingEventUpdated, TastingSampleCreated, TastingSampleDeleted, TastingSampleService, TastingSampleUpdated, translateCommonErrorStatus } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { uuid } from "@core/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ClearTastingSampleIndexFilter, DeleteTastingSample, ExportTastingSampleIndex, InitTastingSampleIndex, LoadTastingSampleIndex, PageTastingSampleIndex, PushTastingSampleIndexModelQuery, QueryTastingSampleIndex, RemoveTastingsSamplesFromEvent, SetTastingSampleIndexSelected, SetTastingsSampleLabelOptions, SortTastingSampleIndex, UpdateTastingsSamplesToEvent } from "./tastings-sample-index.state-actions";
import { DEFAULTS, LABEL_OPTIONS, TastingSampleIndexStateModel } from "./tastings-sample-index.state-model";
import { Injectable } from "@angular/core";

//Index state
@State({
    name: 'tasting_sample_index',
    defaults: DEFAULTS
})
@Injectable()
export class TastingSampleIndexState {

    @Selector()
    static sort(state: TastingSampleIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingSampleIndexStateModel) {
        return state.query;
    }

    @Selector()
    static labelOptions(state: TastingSampleIndexStateModel) {
        return state.labelOptions;
    }

    @Selector()
    static latestExport(state: TastingSampleIndexStateModel) {
        return state.exports.length ? state.exports[state.exports.length - 1] : null;
    }

    constructor(
        private _tastingSampleService: TastingSampleService,
        private _tastingEventService: TastingEventService,
        private _cultivarService: CultivarService,
        private _storage: LocalStorage,
    ) { }

    @Action(InitTastingSampleIndex)
    initTastingSampleIndex(ctx: StateContext<TastingSampleIndexStateModel>, action: InitTastingSampleIndex) {
        const state = ctx.getState();
        if (state.orgKey === action.orgKey && state.status !== Status.UNINITIALIZED) {
            return;
        }

        const labelOptions = LABEL_OPTIONS;

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK
        })

        this.restoreFilter(ctx);

        return forkJoin(
            this.loadTastingEventOptions(ctx),
            this.loadLicenseeOptions(ctx),
            ctx.dispatch(new LoadTastingSampleIndex())
        );
    }

    @Action(LoadTastingSampleIndex, { cancelUncompleted: true })
    loadTastingSampleIndex(ctx: StateContext<TastingSampleIndexStateModel>, action: LoadTastingSampleIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                /*'scionCultivar',
                'site',
                'primaryImage',*/
                'harvestSample',
                'harvestSample.site',
                'harvestSample.primaryImage',
                'tastingsEvents',
                'tastingsSampleImage',
                'tastingsEvaluations',
                /*'maturityIndexEval'*/
            ],
            counts: [
                'tastingsEvaluations'
            ]
        };

        return this._tastingSampleService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(PageTastingSampleIndex)
    pageTastingSampleIndex(ctx: StateContext<TastingSampleIndexStateModel>, action: PageTastingSampleIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadTastingSampleIndex);
    }

    @Action(SortTastingSampleIndex)
    sortTastingSampleIndex(ctx: StateContext<TastingSampleIndexStateModel>, action: SortTastingSampleIndex) {

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingSampleIndex);
    }

    @Action(QueryTastingSampleIndex)
    queryTastingSampleIndex(ctx: StateContext<TastingSampleIndexStateModel>, action: QueryTastingSampleIndex) {

        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingSampleIndex);

    }

    @Action(DeleteTastingSample)
    deleteTastingSample(ctx: StateContext<TastingSampleIndexStateModel>, action: DeleteTastingSample) {
        return this._tastingSampleService.delete(action.key);
    }

    @Action(UpdateTastingsSamplesToEvent)
    updateTastingSamplesToEvent(ctx: StateContext<TastingSampleIndexStateModel>, action: UpdateTastingsSamplesToEvent) {
        const state = ctx.getState();

        let request: Observable<TastingSample>;
        request = this._tastingSampleService.addToEvent(action.data.key, {...action.data});

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        return ctx.dispatch(new LoadTastingSampleIndex);
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(RemoveTastingsSamplesFromEvent)
    removeTastingsSamplesFromEvent(ctx: StateContext<TastingSampleIndexStateModel>, action: RemoveTastingsSamplesFromEvent) {
        const state = ctx.getState();

        let request: Observable<TastingSample>;
        request = this._tastingSampleService.removeFromEvent(action.data.key, {...action.data});

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        return ctx.dispatch(new LoadTastingSampleIndex);
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(PushTastingSampleIndexModelQuery)
    pushTastingSampleIndexModelQuery(ctx: StateContext<TastingSampleIndexStateModel>, action: PushTastingSampleIndexModelQuery) {

        const state = ctx.getState();
        let update;

        switch (action.modelType) {
            case 'scion':
                update = { scionCultivars: patchArrayItem(state.query.scionCultivars, action.model) };
                break;
            case 'site':
                update = { sites: patchArrayItem(state.query.sites, action.model) };
                break;
        }


        ctx.dispatch(new QueryTastingSampleIndex({
            ...state.query,
            ...update
        }));

    }

    @Action(ClearTastingSampleIndexFilter)
    clearTastingSampleIndexFilter(ctx: StateContext<TastingSampleIndexStateModel>, action: ClearTastingSampleIndexFilter) {

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingSampleIndex);

    }

    @Action(SetTastingsSampleLabelOptions)
    setSampleLabelOptions(ctx: StateContext<TastingSampleIndexStateModel>, action: SetTastingsSampleLabelOptions) {
        ctx.patchState({
            labelOptions: action.options
        });
    }

    @Action(ExportTastingSampleIndex)
    exportTastingSampleIndex(ctx: StateContext<TastingSampleIndexStateModel>, action: ExportTastingSampleIndex) {

        const state = ctx.getState();

        const key = uuid();

        ctx.patchState({
            exports: [{
                key,
                status: Status.LOADING,
            }, ...state.exports]
        });

        let filter: Filter;

        if (state.selectedKeys.length > 0) {
            filter = (new FilterBuilder())
                .setSort('updatedAt', 'desc')
                .setQuery('ownerOrgKey', state.orgKey)
                .setQuery('key', state.selectedKeys, FilterQueryMode.IN)
                .get();
        } else {
            filter = this.getCurrentFilter(state);
        }

        return this._tastingSampleService.export(filter, state.orgKey, action.type)
            .pipe(
                tap(
                    result => {

                        let exp = {
                            key,
                            status: Status.COMPLETE
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    },
                    error => {

                        let exp = {
                            key,
                            status: translateCommonErrorStatus(error),
                            url: null,
                            filename: null
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    }
                )
            );
    }

    @Action(SetTastingSampleIndexSelected)
    setTastingSampleIndexSelected(ctx: StateContext<TastingSampleIndexStateModel>, action: SetTastingSampleIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let tastingSample = state.data.find(s => s.key === key);
            if (tastingSample) return tastingSample;
            return state.selectedData.find(s => s.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(TastingSampleUpdated)
    onTastingSampleUpdated(ctx: StateContext<TastingSampleIndexStateModel>, action: TastingSampleUpdated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingSampleIndex);
    }

    @Action(TastingSampleCreated)
    onTastingSampleCreated(ctx: StateContext<TastingSampleIndexStateModel>, action: TastingSampleCreated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingSampleIndex);
    }

    @Action(TastingSampleDeleted)
    onTastingSampleDeleted(ctx: StateContext<TastingSampleIndexStateModel>, action: TastingSampleDeleted) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingSampleIndex);
    }


    @Action(CultivarUpdated)
    onCultivarUpdated(ctx: StateContext<TastingSampleIndexStateModel>, action: CultivarUpdated) {

        const state = ctx.getState();
        if (state.status === Status.UNINITIALIZED) return;

        ctx.patchState({
            query: {
                ...state.query,
                scionCultivars: replaceArrayItem(state.query.scionCultivars, action.cultivar)
            }
        });

        return forkJoin(
            this.loadLicenseeOptions(ctx),
            ctx.dispatch(new LoadTastingSampleIndex)
        );
    }

    @Action(TastingEventCreated)
    onTastingEventCreated(ctx: StateContext<TastingSampleIndexStateModel>, action: TastingEventCreated) {
        const state = ctx.getState();

        if (state.status === Status.UNINITIALIZED) return;

        return forkJoin(
            this.loadTastingEventOptions(ctx),
            ctx.dispatch(new LoadTastingSampleIndex)
        );
    }

    @Action(TastingEventDeleted)
    onTastingEventDeleted(ctx: StateContext<TastingSampleIndexStateModel>, action: TastingEventDeleted) {
        const state = ctx.getState();

        if (state.status === Status.UNINITIALIZED) return;

        return forkJoin(
            this.loadTastingEventOptions(ctx),
            ctx.dispatch(new LoadTastingSampleIndex)
        );
    }

    @Action(TastingEventUpdated)
    onTastingEventUpdated(ctx: StateContext<TastingSampleIndexStateModel>, action: TastingEventUpdated) {
        const state = ctx.getState();

        if (state.status === Status.UNINITIALIZED) return;

        return forkJoin(
            this.loadTastingEventOptions(ctx),
            ctx.dispatch(new LoadTastingSampleIndex)
        );
    }

    @Action(SiteUpdated)
    onSiteUpdated(ctx: StateContext<TastingSampleIndexStateModel>, action: SiteUpdated) {
        const state = ctx.getState();
        if (state.status === Status.UNINITIALIZED) return;

        ctx.patchState({
            query: {
                ...state.query,
                sites: replaceArrayItem(state.query.sites, action.site),
            }
        });

        return ctx.dispatch(new LoadTastingSampleIndex);
    }

    private getCurrentFilter(state: TastingSampleIndexStateModel): Filter {

        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (Array.isArray(state.query.scionCultivars) && state.query.scionCultivars.length > 0) {
            let keys = state.query.scionCultivars.map(i => i.key);
            fb.setQuery('scionCultivarKey', keys, FilterQueryMode.IN);
        }

        if(Array.isArray(state.query.scionCropId) && state.query.scionCropId.length > 0) {
            fb.setQuery('scionCropId', state.query.scionCropId, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.sites) && state.query.sites.length > 0) {
            let keys = state.query.sites.map(i => i.key);
            fb.setQuery('siteKey', keys, FilterQueryMode.IN);
        }

        if(Array.isArray(state.query.tastingsEvent) && state.query.tastingsEvent.length > 0) {
            let keys = state.query.tastingsEvent.map(i => i.key);
            fb.setQuery('tastingsEvents', keys, FilterQueryMode.IN);
        }

        if(Array.isArray(state.query.harvestSample) && state.query.harvestSample.length > 0) {
            let keys = state.query.harvestSample.map(i => i.key);
            fb.setQuery('harvestSampleKey', keys, FilterQueryMode.IN);
        }

        if(Array.isArray(state.query.scionLicensee) && state.query.scionLicensee.length > 0) {
            fb.setQuery('scionLicensee', state.query.scionLicensee, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingSampleIndexStateModel>) {

        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tasting_sample_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tasting_sample_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tasting_sample_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<TastingSampleIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tasting_sample_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tasting_sample_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tasting_sample_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });

    }

    private loadTastingEventOptions(ctx: StateContext<TastingSampleIndexStateModel>) {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.orgKey)
            .get();

        return this._tastingEventService.query(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        tastingEventOptions: result.data
                    });
                }),
                catchError(e => {
                    console.warn("Error loading events", e);
                    ctx.patchState({
                        tastingEventOptions: [],
                    });
                    return of(null);
                })
            );
    }

    private loadLicenseeOptions(ctx: StateContext<TastingSampleIndexStateModel>) {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.orgKey)
            .get();

        return this._cultivarService.groupLicensees(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        licenseeOptions: result.group,
                    });
                }),
                catchError(e => {
                    console.warn("Error loading cultivar licensees", e);
                    ctx.patchState({
                        licenseeOptions: [],
                    });
                    return of(null);
                })
            );
    }
}