<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>{{ state.key ? 'Edit' : 'Add' }}&nbsp;Weighted Characteristic</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_custom_weighted_char_form"
            #tastingCustomWeightedCharForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            novalidate
            autocomplete="off">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Label</mat-label>
                    <input matInput formControlName="label" required>
                    <mat-hint>Weighted Characteristic Label</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Weight</mat-label>
                    <input matInput formControlName="weight" required type="number">
                    <mat-hint>Weighted Characteristic Weight</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Tags</mat-label>
                    <mat-select formControlName="tagKeys" multiple>
                        <mat-option *ngFor="let tag of data.tagOptions" [value]="tag.key">
                            <mat-icon></mat-icon>
                            <span>{{ tag.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Tag Order</h4>
                <div cdkDropList (cdkDropListDropped)="drop($event)" class="example-list" *ngIf="tagKeys.length; else noData">
                    <div cdkDrag class="example-box" *ngFor="let tag of tagKeys">
                        {{ getPrettyTagLabels(tag) }}
                    </div>
                </div>
                <ng-template #noData>
                    <h5>No Characteristics Selected</h5>
                </ng-template>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingCustomWeightedCharForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>