<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>{{ state.key ? 'Edit' : 'Add' }}&nbsp;Index</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_custom_index_form"
            #tastingCustomIndexForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            novalidate
            autocomplete="off">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" required>
                    <mat-hint>Title for the Index</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Range</mat-label>
                    <mat-select formControlName="range" required>
                        <mat-option *ngFor="let option of rangeOptions" [value]="option">
                            <span>{{ option }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Crop</mat-label>
                    <mat-select formControlName="cropKey" required>
                        <mat-option *ngFor="let crop of data.cropOptions" [value]="crop.key">
                            <mat-icon [svgIcon]="'common:' + crop.legacyId"></mat-icon>
                            <span>{{ crop.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Weighted Characteristics</mat-label>
                    <mat-select formControlName="weightedCharKeys" multiple>
                        <mat-option *ngFor="let char of data.weightedCharOptions" [value]="char.key">
                            <mat-icon></mat-icon>
                            <span>{{ char.label }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Characteristics Order</h4>
                <div cdkDropList (cdkDropListDropped)="drop($event)" class="example-list" *ngIf="weightedCharKeys.length; else noData">
                    <div cdkDrag class="example-box" *ngFor="let char of weightedCharKeys">
                        {{ getPrettyCharLabels(char) }}
                    </div>
                </div>
                <ng-template #noData>
                    <h5>No Characteristics Selected</h5>
                </ng-template>
            </div>

        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingCustomIndexForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>