<mat-chip-list #chipList [ngSwitch]="status" [selectable]="false">
    <mat-chip
        *ngFor="let item of selected"
        [removable]="removable"
        [disabled]="_disabled"
        (removed)="remove(item)">
        <span class="mat-caption" [pvCultivarLine]="item" [pvCultivarChip]="item"></span>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
        #textInput
        type="text"
        [id]="id"
        [placeholder]="placeholder"
        [formControl]="textControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList" />
    <mat-spinner *ngSwitchCase="'LOADING'" [diameter]="24" color="primary"></mat-spinner>
    <mat-icon *ngSwitchCase="'EMPTY'" color="primary" matTooltip="No matches found">info</mat-icon>
    <mat-icon *ngSwitchCase="'ERROR'" color="warn" matTooltip="Error occured">error</mat-icon>
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
    <mat-option *ngIf="status === 'EMPTY'" [disabled]="true">No results</mat-option>
    <mat-option class="cultivar-option" *ngFor="let option of options" [value]="option">
        <div class="mat-caption" [pvCultivarLine]="option"></div>
    </mat-option>
</mat-autocomplete>
