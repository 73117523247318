import { TastingSample } from "./tasting-sample";
import { TastingEvent } from "./tastings-event";

export interface TastingEvaluation {
    key: string,
    title: string,
    scores: SampleScores,
    range: number,

    tastingsSampleKey: string,
    tastingsEventKey: string,
    ownerOrgKey: string,

    tastingsEvent?: TastingEvent,
    tastingsSample?: TastingSample,

    createdAt: string,
    updatedAt: string,
    deletedAt: string,
}

//? NEW TASTINGS EVAL
export type SampleScores = {
    user: EvaluationUser,
    characteristics: CharacteristicScore[],
    answers: Answers[],
}

export type EvaluationUser = {
    email: string,
    ident: string,
    subscribed: boolean,
}

export type CharacteristicScore = {
    id: string,
    value: number,
    tags: CharacteristicTag[],
    comment: string,
}

export type CharacteristicTag = {
    id: string,
    value: boolean,
}

export type Answers = {
    id: string,
    value: number,
    tags: CharacteristicTag[],
}

export enum TastingEvaluationIndexType {
    EVENT = 'event',
    SAMPLE = 'sample',
}