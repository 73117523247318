import { Measurement } from './_types';

export const LIB_MEASURES_CANNABIS: Measurement[] = [
    //Yield
    {
        id: "plant_wet_yield",
        unit: "g",
        label: "Wet Yield",
        tag: "WYIEL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_wet_yield",
        format: "####g",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_yield",
    },
    {
        id: "plant_dry_yield",
        unit: "g",
        label: "Dry Yield",
        tag: "DYIEL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_dry_yield",
        format: "####g",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 0,
        subjectId: "plant_yield",
    },
    {
        id: "plant_flower_yield_trimmed",
        unit: "g",
        label: "Flower Yield - Trimmed",
        tag: "FYT",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_flower_yield_trimmed",
        format: "####g",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_yield",
    },
    {
        id: "plant_flower_yield_untrimmed",
        unit: "g",
        label: "Flower Yield - Trimmed & Untrimmed",
        tag: "FYTU",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_flower_yield_untrimmed",
        format: "####g",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "yield",
    },
    {
        id: "plant_trim_yield",
        unit: "g",
        label: "Trim Yield",
        tag: "TY",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_trim_yield",
        format: "####g",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_yield",
    },
    {
        id: "plant_stem_yield",
        unit: "g",
        label: "Stem & Fan-Leaves Yield",
        tag: "SFY",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_stem_yield",
        format: "####g",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_yield",
    },
    // Plant
    {
        id: "plant_height_seed",
        unit: "cm",
        label: "Plant Height",
        tag: "HEIS",
        defaultChart: "chart_bar_buckets",      
        defaultBucket: "bucket_plant_height_seed",
        format: "####cm",
        min: 0,
        max: 500,
        suggestMin: 1,
        suggestMax: 9999,
        subjectId: "plant_seed",
    },
    {
        id: "plant_height_veg",
        unit: "cm",
        label: "Plant Height",
        tag: "HEIV",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_height_veg",
        format: "####cm",
        min: 0,
        max: 500,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_height_flower",
        unit: "cm",
        label: "Plant Height",
        tag: "HEIF",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_height_flower",
        format: "####cm",
        min: 0,
        max: 500,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_canX_seed",
        unit: "cm",
        label: "Canopy Width X-Axis",
        tag: "CWXS",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_can_widthX_seed",
        format: "####cm",
        min: 0,
        max: 500,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_seed",
    },
    {
        id: "plant_canY_seed",
        unit: "cm",
        label: "Canopy Width Y-Axis",
        tag: "CWYS",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_can_widthY_seed",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_seed",
    },
    {
        id: "plant_canX_veg",
        unit: "cm",
        label: "Canopy Width X-Axis",
        tag: "CWXV",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_can_widthX_veg",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_canY_veg",
        unit: "cm",
        label: "Canopy Width Y-Axis",
        tag: "CWYV",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_can_widthY_veg",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_canX_flower",
        unit: "cm",
        label: "Canopy Width X-Axis",
        tag: "CWXF",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_can_widthX_flower",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_canY_flower",
        unit: "cm",
        label: "Canopy Width Y-Axis",
        tag: "CWYF",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_can_widthY_flower",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_internodes_seed",
        unit: "#",
        label: "Number of Internodes",
        tag: "ICNTS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_internodes_seed",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_seed",
    },
    {
        id: "plant_internode_length_seed",
        unit: "cm",
        label: "Internode Length",
        tag: "INLS",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_internode_length_seed",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_seed",
    },
    {
        id: "plant_internode_length_veg",
        unit: "cm",
        label: "Internode Length",
        tag: "INLV",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_internode_length_veg",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_internode_length_flower",
        unit: "cm",
        label: "Internode Length",
        tag: "INLF",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_internode_length_flower",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_primary_cola_size",
        unit: "cm",
        label: "Primary Cola Size",
        tag: "PCS",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_primary_cola_size",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_secondary_cola_size",
        unit: "cm",
        label: "Secondary Cola Size",
        tag: "SCS",
        defaultChart: "chart_bar_buckets",         
        defaultBucket: "bucket_plant_secondary_cola_size",
        format: "####cm",
        min: 0,
        max: 5000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_nodes_primary_veg",
        unit: "#",
        label: "Number of Primary Nodes",
        tag: "PNCV",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_nodes_primary_veg",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_nodes_primary_flower",
        unit: "#",
        label: "Number of Primary Nodes",
        tag: "PNCF",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_nodes_primary_flower",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_internodes_veg",
        unit: "#",
        label: "Number of Internodes",
        tag: "ICNTV",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_internodes_veg",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_internodes_flower",
        unit: "#",
        label: "Number of Internodes",
        tag: "ICNTS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_internodes_flower",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_stem_dia_veg",
        unit: "mm",
        label: "Plant Stem Diameter",
        tag: "STDIAV",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_stem_diameter_veg",
        format: "####mm",
        min: 0,
        max: 500,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_veg",
    },
    {
        id: "plant_stem_dia_flower",
        unit: "mm",
        label: "Plant Stem Diameter",
        tag: "STDIAF",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_stem_diameter_flower",
        format: "####mm",
        min: 0,
        max: 1500,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },

    //Flower
    {
        id: "plant_flower_dia",
        unit: "mm",
        label: "Average Flower Diameter",
        tag: "AVFDIA",
        defaultChart: "chart_bar_buckets",          
        defaultBucket: "bucket_plant_average_flower_diameter",
        format: "####mm",
        min: 0,
        max: 1000,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_number_flowers",
        unit: "#",
        label: "Number of Flowers",
        tag: "FLCNT",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_number_flowers",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "plant_flower",
    },
    {
        id: "plant_total_cann",
        unit: "%",
        label: "Total Cannabinoids",
        tag: "TC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_total_cann",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_thc_d8",
        unit: "%",
        label: "D8-THC Content",
        tag: "THCD8",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_thc_d8",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_thc_d9",
        unit: "%",
        label: "D9-THC Content",
        tag: "THCD9",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_thc_d9",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_thcv",
        unit: "%",
        label: "THCV Content",
        tag: "THCV",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_thcv",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_cbd",
        unit: "%",
        label: "CBD Content",
        tag: "CBD",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_cbd",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_cbdv",
        unit: "%",
        label: "CBDV Content",
        tag: "CBDV",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_cbdv",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_cbl",
        unit: "%",
        label: "CBL Content",
        tag: "CBL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_cbl",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_cbc",
        unit: "%",
        label: "CBC Content",
        tag: "CBC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_cbc",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_cbg",
        unit: "%",
        label: "CBG Content",
        tag: "CBG",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_cbg",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_cbn",
        unit: "%",
        label: "CBN Content",
        tag: "CBN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_plant_cbn",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "cannabinoids",
    },
    {
        id: "plant_total_terpenes",
        unit: "%",
        label: "Total Terpenes",
        tag: "TPT",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_content",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_limonene",
        unit: "%",
        label: "Limonene",
        tag: "TPLIC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_limonene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_bisabolol_alpha",
        unit: "%",
        label: "Bisabolol (alpha)",
        tag: "TPBBA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_bisabolol_alpha",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_pinene_alpha",
        unit: "%",
        label: "Pinene (alpha)",
        tag: "TPPIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_pinene_alpha",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_pinene_beta",
        unit: "%",
        label: "Pinene",
        tag: "TPPIB",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_pinene_beta",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_linalool",
        unit: "%",
        label: "Linalool",
        tag: "TPLIC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_linalool",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_humulene",
        unit: "%",
        label: "Humulene",
        tag: "TPHUC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_humulene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_carophyllene",
        unit: "%",
        label: "Carophyllene",
        tag: "TPCAC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_carophyllene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_myrcrene",
        unit: "%",
        label: "Myrcrene",
        tag: "TPMYC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_myrcrene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_terpinolene",
        unit: "%",
        label: "Terpinolene",
        tag: "TPTPE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_terpinolene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_terpinene",
        unit: "%",
        label: "Terpinene",
        tag: "TPTEE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_terpinene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_eucalyptol",
        unit: "%",
        label: "Eucalyptol",
        tag: "TPEUC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_eucalyptol",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_camphene",
        unit: "%",
        label: "Camphene",
        tag: "TPCAMC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_camphene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_cymene",
        unit: "%",
        label: "Cymene",
        tag: "TPCYE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_cymene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_isopulegol",
        unit: "%",
        label: "Isopulegol",
        tag: "TPIPL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_isopulegol",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_borneol",
        unit: "%",
        label: "Borneol",
        tag: "TPBOC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_borneol",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_valencene",
        unit: "%",
        label: "Valencene",
        tag: "TPVAC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_valencene",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_caryophyllene_oxide",
        unit: "%",
        label: "Caryophyllene Oxide",
        tag: "TPCOX",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_caryophyllene_oxide",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_geraniol",
        unit: "%",
        label: "Geraniol",
        tag: "TPVAC",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_geraniol",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_terpineol_alpha",
        unit: "%",
        label: "Terpineol (alpha)",
        tag: "TPTPA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_terpineol_alpha",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_ocamene_alpha",
        unit: "%",
        label: "Ocamene (alpha)",
        tag: "TPOCA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_ocamene_alpha",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
    {
        id: "plant_terpene_ocamene_beta",
        unit: "%",
        label: "Ocamene (beta)",
        tag: "TPOCB",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_terpene_ocamene_beta",
        format: "###0.0%",
        min: 0,
        max: 50,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "terpenes",
    },
];