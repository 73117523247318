import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatNumber } from  '@angular/common';

@Pipe({
  	name: 'area'
})
export class AreaPipe implements PipeTransform {
	constructor(@Inject(LOCALE_ID) public locale: string) {}

	transform(value: number) {

		if(value) {
            if(value >= 10000) {
                return formatNumber(value/10000, this.locale, "1.1-3") + ' ha';
            } else {
                return formatNumber(value, this.locale, "1.0-0") + " m&sup2;";
            }
        }

        return '';
	}
}