import { DataSource, CollectionViewer } from "@angular/cdk/collections";
import { Observable, Subject, combineLatest } from "rxjs";
import { SampleDataNode } from "./report-sample-data-node";
import { map } from "rxjs/operators";


export class ReportSampleTreeDataSource extends DataSource<SampleDataNode> {
    constructor(public _obs$: Subject<SampleDataNode[]>){
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<SampleDataNode[]> {
        return combineLatest(collectionViewer.viewChange, this._obs$)
                    .pipe(map(res => {
                        let [view, data] = res;
                        if(!data) return [];
                        return data;
                    }));
    }

    disconnect(){}
}