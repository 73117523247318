import { Injectable } from "@angular/core";
import { hexToRGBA } from '@core/utils';
import { CompiledReportChart, CompiledMeasurementSummary, CompiledCharacteristicIndexSummary } from './report-compiler.types';

@Injectable()
export class MeasurementChartCompilerService {

    compileMeasureSummaryChart(measure: CompiledMeasurementSummary, section?: string, displayMode?: string, compiledMeasure: boolean = false){

        if(!measure.showChart || measure.isEmpty) return null;

        if(measure.chartOptions.id === 'chart_bar_buckets'){
            return this.compileChartBarBuckets(measure, section, displayMode, compiledMeasure);
        }else if(measure.chartOptions.id === 'chart_candle_sd'){
            return this.compileChartCandleSD(measure, section);
        }else if(measure.chartOptions.id === 'chart_line_avg'){
            return this.compileChartLineAvg(measure, section);
        }else{
            return null;
        }

    }

    private compileChartCandleSD(measure: CompiledMeasurementSummary, section: string){

        const data = {
            labels: [],
            datasets: [],
        };

        const type = 'boxplot';

        const options = {
            tooltipDecimals: 2,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: true,
                mode: 'nearest',
            },
            scales: {
                xAxes: [{
                    type: 'category',
                    scaleLabel: {
                        display: true,
                        labelString: 'Series',
                        fontStyle: 'bold',
                        fontSize: 14
                    },
                    ticks: {
                        maxRotation: 90,
                    },
                    maxBarThickness: 50
                }],
                yAxes: [{
                    // type: 'linear',
                    scaleLabel: {
                        display: true,
                        labelString: `${measure.options.label} [${measure.options.unit}]`,
                        fontStyle: 'bold',
                        fontSize: 14
                    },
                    ticks: {
                        suggestedMin: 0
                    }
                }]
            },
            responsive: true,
        };


        const dataset = {
            label: `${measure.options.label} [${measure.options.unit}]`,
            borderColor: [],
            backgroundColor: [],
            borderWidth: 2,
            data: [],
        };

        measure.seriesStats.forEach((stats, si) => {

            if(!stats) return;

            dataset.borderColor.push(measure.series[si].color);
            dataset.backgroundColor.push(hexToRGBA(measure.series[si].color, 0.5));

            // Determine the label based on si value
            const label = si === 0 ? 'Primary' : 'Control';

            if (section === 'infosheet') {
                data.labels.push(`${label}`);
            } else {
                data.labels.push(`${measure.series[si].ref} ${measure.series[si].shortLabel}`);
            }

            dataset.data.push({
                min: stats.min,
                whiskerMin: stats.min,
                max: stats.max,
                whiskerMax: stats.max,
                q1: stats.q1,
                q3: stats.q3,
                median: stats.median,
            });

        });

        data.datasets.push(dataset);

        return {
            options,
            data,
            type
        };

    }

    private compileChartBarBuckets(measure: CompiledMeasurementSummary, section?: string, displayMode?: string, compiledMeasure?: boolean){

        let shouldStack = true;
        let shouldShowPercentage = false;

        if (section == 'infosheet' || compiledMeasure) {
            shouldStack = false;
            shouldShowPercentage = true;
        }

        if (compiledMeasure) section = 'measurementSummary'

        let options;
        let data = {
            labels: [],
            datasets: [],
        };
        let type = 'bar';

        options = {
            legend: {
                display: false,
            },
            tooltips: {
                mode: 'index',
                axis: 'y'
            },
            scales: {
                xAxes: [{
                    ticks: {
                        maxRotation: 90,
                    },
                    stacked: shouldStack,
                    scaleLabel: {
                        display: true,
                        labelString: measure.bucketSort.label,
                        fontStyle: 'bold',
                        fontSize: 14
                    },
                    maxBarThickness: 70
                }],
                yAxes: [
                    {
                        stacked: shouldStack,
                        scaleLabel: {
                            display: true,
                            labelString: displayMode,
                            fontStyle: 'bold',
                            fontSize: 14
                        },
                        ticks: shouldShowPercentage ? {
                            suggestedMin: 0
                        } : {
                        }
                    }
                ]
            },
            responsive: true,
        };

        data.labels = measure.bucketSort.bins.map(bin => bin.label);


        if (section === 'infosheet') {
            measure.series.forEach((serie, si) => {
                let values = [];
                let serieIsEmpty = true;

                measure.bucketSort.bins.forEach(bin => {
                    let percentage = measure.seriesTotal[si] > 0 ? (bin.counts[si] / measure.seriesTotal[si]) * 100 : 0;
                    values.push(percentage.toFixed(2));
                    serieIsEmpty = false;
                });

                if (!serieIsEmpty) {
                    data.datasets.push({
                        label: serie.label,
                        backgroundColor: serie.color,
                        data: values
                    });
                }

            });
        } else {
            measure.series.forEach((serie, si) => {
            let values = [];
            let serieIsEmpty = true;
            measure.bucketSort.bins.forEach(bin => {
                values.push(bin.counts[si]);
                if(bin.counts[si] > 0){
                    serieIsEmpty = false;
                }
            });

            if(!serieIsEmpty){
                data.datasets.push({
                    label: serie.label,
                    backgroundColor: serie.color,
                    data: values
                });
            }

            });
        }

        return {
            options,
            data,
            type
        };

    }

    private compileChartLineAvg(measure: CompiledMeasurementSummary, section: string) {
        const shortenLabel = section === 'infosheet'

        let options;
        let data = {
            labels: [],
            datasets: [],
        };
        let type = 'bar';

        type = 'line';
        options = {
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: 'Series',
                            fontStyle: 'bold',
                            fontSize: 14
                        },
                        offset: true
                    }
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: shortenLabel ? `Mean [${measure.options.unit}]` : `Mean ${measure.options.label} [${measure.options.unit}]`,
                            fontStyle: 'bold',
                            fontSize: 14
                        },
                        ticks: {
                            suggestedMin: 0
                        }
                    }
                ]
            },
            responsive: true,
        };
        let dataset = {
            label: 'Average',
            backgroundColor: 'rgba(200,200,200,0.5)',
            borderColor: 'rgba(200,200,200,1)',
            data: []
        };

        measure.seriesStats.forEach((stats, si) => {
            if(stats){
                dataset.data.push(stats.mean);
                data.labels.push([measure.series[si].ref, measure.series[si].shortLabel]);
            }
        });
        data.datasets.push(dataset);


        return {
            options,
            data,
            type
        };

    }

    compileCharacteristicIndexCharts(indexReport: CompiledCharacteristicIndexSummary) : CompiledReportChart[] {
        if (!indexReport.showChart || indexReport.isEmpty) return null;
        return [
            this.compileChartBarHorisontal(indexReport),
            this.compileChartRadarIndex(indexReport),
        ];
    }

    private compileChartBarHorisontal(indexReport: CompiledCharacteristicIndexSummary) : CompiledReportChart {
        if(!indexReport.showChart || indexReport.isEmpty) return null;

        let options = {
            tooltips: {
                mode: 'point'
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 10,
                        },
                        scaleLabel: {
                            type: 'linear',
                            display: true,
                            labelString: 'Score (%)',
                            fontSize: 14,
                            padding: 6
                        },
                        // barThickness: 50,
                    }
                ],
                yAxes: [
                    {
                        // barThickness: 20,
                        scaleLabel: {
                            display: true,
                            labelString: 'Characteristic',
                            fontSize: 14,
                            padding: 6
                        }
                    }
                ]
            },
            responsive: true,
        };

        let data = {
            labels: [],
            datasets: [],
        };
        let type = 'horizontalBar';

        data.labels = indexReport.chars.map(char => char.label);

        indexReport.seriesScores.forEach((seriesScore, si) => {
            if(seriesScore.isComplete){
                data.datasets.push({
                    label: indexReport.series[si].label,
                    backgroundColor: indexReport.series[si].color,
                    data: seriesScore.scores,
                    borderWidth: 0,
                })
            }
        });

        return {
            options,
            data,
            type
        };
    }

    private compileChartRadarIndex(indexReport: CompiledCharacteristicIndexSummary) : CompiledReportChart {
        if (!indexReport.showChart || indexReport.isEmpty) return null;

        let options = {
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function(tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].label + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    }
                }
            },
            legend: {
                display: true,
            },
            scale: {
                angleLines: {
                    display: true,
                },
                ticks: {
                    suggestedMin: 0,
                }
            },
            responsive: true,
        };

        let type = 'radar';
        let data = {
            labels: [],
            datasets: [],
        };

        data.labels = indexReport.chars.map(char => char.label);

        indexReport.seriesScores.forEach((seriesScore, si) => {
            if(seriesScore.isComplete){
                data.datasets.push({
                    label: indexReport.series[si].label,
                    backgroundColor: hexToRGBA(indexReport.series[si].color, 0.2),
                    borderColor: indexReport.series[si].color,
                    data: seriesScore.scores,
                })
            }
        });


        return {
            type,
            options,
            data,
        };
    }
}
