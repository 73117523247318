import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { API_BASE_URI } from '../http/api';
import { User } from '../types/user';
import { FilterBuilder, Filter } from '../http/filter';
import { Collection } from '../http/collection';
import { Dot } from '@core/utils';

@Injectable()
export class UserService {

    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string,
    ){}

    get(key: string, detail?: DetailRequest) {

        let params = {
            ...DetailRequestBuilder.queryParams(detail),
        };
        return this._http.get<User>(`${this._baseUri}/users/${key}`, { params });
    }

    query(filter?: Filter, detail?: DetailRequest): Observable<Collection<User>> {

        let params = {
            ...DetailRequestBuilder.queryParams(detail),
            ...FilterBuilder.queryParams(filter)
        };

        return this._http.get<Collection<User>>(`${this._baseUri}/users`, { params });
    }

    update(key: string, request: UserRequest) {
        return this._http.put<User>(`${this._baseUri}/users/${key}`, request)
            .pipe(catchError(e => this.catchDuplicateEmailError(e)));
    }

    create(request: UserRequest) {
        return this._http.post<User>(`${this._baseUri}/users`, request)
            .pipe(catchError(e => this.catchDuplicateEmailError(e)));
    }

    delete(key: string) {
        return this._http.delete<User>(`${this._baseUri}/users/${key}`);
    }

    private catchDuplicateEmailError(e: any){
        if(e instanceof HttpErrorResponse){

            if(e.status === 422 && 
                String(Dot.get(e.error, 'errors.email.0', '')).includes('taken')
                ) {
                return throwError(new DuplicateEmailError(e));
            }
        }
        return throwError(e);
    }

}

export class DuplicateEmailError extends HttpErrorResponse {}

export interface UserRequest {
    name?: string;
    email?: string;
    addrCountry?: string;
    isAdmin?: boolean;
    password?: string;
}