import { Pipe, PipeTransform } from '@angular/core';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Sample } from '@core/data';

@Pipe({
  name: 'descSample',
})
export class DescribeSamplePipe implements PipeTransform {

    constructor(private _sampleFormatter: SampleFormatter){}

    transform(sample: Sample, html = false) {

        if(sample){
            return this._sampleFormatter.describeSample(sample, html);
        }

        return null;
    }
}
