import { HostBinding, Directive, Input } from "@angular/core";
import { HostNavigator } from "../host-navigator";

@Directive({
    selector: 'a[hostLink]'
})
export class HostLinkDirective {

    @HostBinding() href: string;

    @Input()
    set hostLink(path: string) {
        this.href = this._hostNav.hostUrl(path);
    }

    constructor(private _hostNav: HostNavigator) { }


}