import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Evaluation, ReportSample, Sample, ReportSampleEvalTimelineOptions } from '@core/data';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SampleDataNode, SampleEvaluationNode, SampleImageNode, SampleNode, SampleNoteNode } from './report-sample-data-node';
import { ReportSampleTreeControl } from './report-sample-tree-control';
import { ReportSampleTreeDataSource } from './report-sample-tree-data-source';
import { ReportSampleTreeSelection } from './report-sample-tree-selection';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Store } from '@ngxs/store';
import { ReportUpdateSamples } from './report-builder.state';


@Component({
    selector: 'pv-sample-tree',
    templateUrl: './report-sample-tree.component.html',
    host: {
        class: 'pv-sample-tree'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => ReportSampleTreeComponent),
          multi: true
        }
    ]
})
export class ReportSampleTreeComponent implements OnInit, OnDestroy, ControlValueAccessor {

    _liveData: Sample[] = [];
    _value: Partial<ReportSample>[] = [];
    _reportSamples: ReportSample[] = [];

    dataNodes$ = new BehaviorSubject<SampleDataNode[]>([]);
    treeControl: ReportSampleTreeControl;
    selection: ReportSampleTreeSelection;
    selectionTimeline: SelectionModel<string>;
    sampleTreeDataSource: ReportSampleTreeDataSource;

    onChange: any = () => {};
    onTouched: any = () => {};
    isDisabled = false;

    private _isWriting = false;
    private _destroy$ = new Subject();

    @Input()
    set reportSamples(reportSamples: ReportSample[]) {
        if(!reportSamples){
            console.warn('SampleTreeComponent: received falsy report samples', reportSamples);
            return;
        }

        this._reportSamples = reportSamples;
    }

    @Input()
    set data(data: Sample[]){
        if(!data){
            console.warn('SampleTreeComponent: received falsy data', data);
            return;
        }
        if(this._liveData !== data){
            this._liveData = data;
            this.updateNodes();
        }
    }

    constructor(private _changeDetectRef: ChangeDetectorRef, private _store: Store){
        this.sampleTreeDataSource = new ReportSampleTreeDataSource(this.dataNodes$);
        this.treeControl = new ReportSampleTreeControl();
        this.selection = new ReportSampleTreeSelection();
        this.selectionTimeline = new SelectionModel<string>(true);
    }

    ngOnInit(){
        this.selection.changed
            .pipe(takeUntil(this._destroy$))
            .subscribe(event => {
                if(!this._isWriting){
                    this.updateValueFromSelection();
                    this.onChange(this._value);
                    this.selection.setAllEvalsSelected(null);
                    this.selection.setAllImagesSelected(null);
                    this.selection.setAllNotesSelected(null);
                }
            });
        this.selectionTimeline.changed
            .pipe(takeUntil(this._destroy$))
            .subscribe(event => {
                if(!this._isWriting){
                    this.updateValueFromSelection();
                    this.onChange(this._value);
                }
            });
    }

    ngOnDestroy(){
        this.dataNodes$.complete();
        this._destroy$.next();
        this._destroy$.complete();
    }

    writeValue(obj: any): void {
        this._isWriting = true;
        try {
            this._value = Array.isArray(obj) ? obj : [];
            this.setSelection(obj);
            this._changeDetectRef.markForCheck();
        } finally {
            this._isWriting = false;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    private setSelection(data: ReportSample[]) {
        let keys: string[] = [];
        let keysTimeline: string[] = [];

        data.forEach(rs => {
            keys.push(rs.sampleKey);
            keys.push(...rs.includeEvals);
            keys.push(...rs.includeImages);
            keys.push(...rs.includeNotes);

            if (!rs.evalsTimelineOptions) return;

            Object.keys(rs.evalsTimelineOptions).forEach((key) => {
                if (rs.evalsTimelineOptions[key].exclude) keysTimeline.push(key);
            })
        });

        this.selection.clear();
        this.selection.select(...keys);

        this.selectionTimeline.clear();
        this.selectionTimeline.select(...keysTimeline);
    }

    private updateValueFromSelection() {
        let reportSamples: Partial<ReportSample>[] = [];

        this._liveData.forEach(sample => {
            if(!this.selection.isSelected(sample.key)) return;

            const rs: Partial<ReportSample> = {
                sampleKey: sample.key,
                includeEvals: [],
                includeImages: [],
                includeNotes: [],
                evalsTimelineOptions: {},
            };

            let evalsSnapshot: Evaluation[] = [];

            let evalsTimelineOptions: { [key: string]: ReportSampleEvalTimelineOptions } = {};

            sample.evals.forEach(evalu => {
                if(!this.selection.isSelected(evalu.key)) return;

                rs.includeEvals.push(evalu.key);

                let evalSnapshot = {
                    ...evalu,
                    notes: evalu.notes.filter(note => {
                        if(!this.selection.isSelected(note.key)) return false;
                        rs.includeNotes.push(note.key);
                        return true;
                    }),
                    images: evalu.images.filter(image => {
                        if(!this.selection.isSelected(image.key)) return false;
                        rs.includeImages.push(image.key);
                        return true;
                    })
                };

                evalsSnapshot.push(evalSnapshot);

                let evalTimelineOptions: ReportSampleEvalTimelineOptions = {
                    exclude: this.selectionTimeline.isSelected(evalu.key),
                }
                evalsTimelineOptions[evalu.key] = evalTimelineOptions;
            });

            rs.data = {
                ...sample,
                evals: evalsSnapshot
            };
            rs.evalsTimelineOptions = evalsTimelineOptions;

            reportSamples.push(rs);
        });

        this._value = reportSamples;
    }

    private updateNodes() {
        const data: Sample[] = this._liveData;
        const reportSamples: ReportSample[] = this._reportSamples;

        const allNodes: SampleDataNode[] = [];
        const sampleNodes: SampleNode[] = [];

        const missingSamples = this.findMissingSamples(data, reportSamples)

        reportSamples.forEach((reportSample, index) => {
            const sample = data.find(currentSample => currentSample.key === reportSample.sampleKey)

            if (!sample) return;

            let sampleNode: SampleNode = {
                key: sample.key,
                type: 'sample',
                data: sample,
                children: [],
                parent: null,
                sampleIndex: index
            };

            sample.evals.forEach(evalu => {
                let evalNode: SampleEvaluationNode = {
                    key: evalu.key,
                    type: 'eval',
                    data: evalu,
                    children: [],
                    parent: sampleNode
                };

                evalu.images.forEach(image => {
                    let imageNode: SampleImageNode = {
                        key: image.key,
                        type: 'image',
                        data: image,
                        children: [],
                        parent: evalNode,
                    };
                    evalNode.children.push(imageNode);
                    allNodes.push(imageNode);
                });

                evalu.notes.forEach(note => {
                    let noteNode: SampleNoteNode = {
                        key: note.key,
                        type: 'note',
                        data: note,
                        children: [],
                        parent: evalNode,
                    };
                    evalNode.children.push(noteNode);
                    allNodes.push(noteNode);
                });

                sampleNode.children.push(evalNode);
                allNodes.push(evalNode);
            });

            sampleNodes.push(sampleNode);
            allNodes.push(sampleNode);
        });

        if(missingSamples.length > 0){
            this.createSampleNodes(missingSamples,sampleNodes,allNodes)
        };

        this.dataNodes$.next(sampleNodes);
        this.treeControl.dataNodes = sampleNodes;
    }

    private findMissingSamples(data: Sample[], reportSamples: ReportSample[]): Sample[]{
        const missingSamples: Sample[] = []

        data.forEach((sample) => {
            const foundReportSample = reportSamples.find(rs => rs.sampleKey === sample.key);
            if(!foundReportSample) {
                missingSamples.push(sample);
            }
        })

        return missingSamples
    }

    private createSampleNodes(missingSamples: Sample[], sampleNodes: SampleNode[], allNodes: SampleDataNode[]){

            missingSamples.forEach((sample, index) => {
                let sampleNode: SampleNode = {
                    key: sample.key,
                    type: 'sample',
                    data: sample,
                    children: [],
                    parent: null,
                    sampleIndex: index
                };

                sample.evals.forEach(evalu => {
                    let evalNode: SampleEvaluationNode = {
                        key: evalu.key,
                        type: 'eval',
                        data: evalu,
                        children: [],
                        parent: sampleNode
                    };

                    evalu.images.forEach(image => {
                        let imageNode: SampleImageNode = {
                            key: image.key,
                            type: 'image',
                            data: image,
                            children: [],
                            parent: evalNode,
                        };
                        evalNode.children.push(imageNode);
                        allNodes.push(imageNode);
                    });

                    evalu.notes.forEach(note => {
                        let noteNode: SampleNoteNode = {
                            key: note.key,
                            type: 'note',
                            data: note,
                            children: [],
                            parent: evalNode,
                        };
                        evalNode.children.push(noteNode);
                        allNodes.push(noteNode);
                    });

                    sampleNode.children.push(evalNode);
                    allNodes.push(evalNode);
                });

                sampleNodes.push(sampleNode);
                allNodes.push(sampleNode);

            })
    }

    drop(event: CdkDragDrop<SampleDataNode[]>) {
        let treeNodes = [...this.treeControl.dataNodes]

        moveItemInArray(
            treeNodes,
            event.previousIndex,
            event.currentIndex
        );

        const droppedSample = treeNodes[event.currentIndex];
        const isDroppedSampleSelected = this.selection.isSelected(droppedSample.key);
        if(isDroppedSampleSelected){
            this.treeControl.collapse(droppedSample);
        }
        const unselectedTreeNodes = treeNodes.filter(node => !this.selection.isSelected(node.key));

        // Update dataNodes$ with cleanNodes
        this.updateTreeData(treeNodes)

        // Dispatch an action to update the store
        const selectedNodes = isDroppedSampleSelected ? treeNodes.filter(node => this.selection.isSelected(node.key)) : [];
        const castedNodes = selectedNodes as SampleNode[];
        const nodes = this.convertToReportSamples(castedNodes);
        this._store.dispatch(new ReportUpdateSamples(nodes));
    }

    updateTreeData(sampleNodes: SampleDataNode[]) {
        // Re-render the tree data source
        const treeNodesMap = new Map<string, SampleDataNode>();
        const rootNodes: SampleDataNode[] = [];

        sampleNodes.forEach(node => {
            treeNodesMap.set(node.key, {...node,});
        });

        sampleNodes.forEach(node => {
            if (node.parent) {
                const parentNode = node.parent;
                if (parentNode) {
                    parentNode.children.push(treeNodesMap.get(node.key));
                }
            } else {
                rootNodes.push(treeNodesMap.get(node.key));
            }
        });

        this.sampleTreeDataSource._obs$.next(rootNodes);
        this.dataNodes$.next(sampleNodes);
        this.treeControl.dataNodes = sampleNodes;
    }

    private convertToReportSamples = (nodes: SampleNode[]): ReportSample[] => {
        let index = 0

        const reportSamples: ReportSample[] = [];
        nodes.forEach(node => {
            const rs: ReportSample = {
                reportSampleIndex: index++,
                sampleKey: node.key,
                includeEvals: [],
                includeImages: [],
                includeNotes: [],
                evalsTimelineOptions: {},
                data: {
                    ...node.data,
                    evals: []
                }
            };

            let evalsSnapshot: Evaluation[] = [];

            let evalsTimelineOptions: { [key: string]: ReportSampleEvalTimelineOptions } = {};

            node.data.evals.forEach(evalu => {
                let evaluation: Evaluation = {
                    ...evalu,
                    images: [],
                    notes: [],
                };

                if(!this.selection.isSelected(evalu.key)) return;

                rs.includeEvals.push(evalu.key);

                let evalSnapshot = {
                    ...evalu,
                    notes: evalu.notes.filter(note => {
                        if(!this.selection.isSelected(note.key)) return false;
                        rs.includeNotes.push(note.key);
                        evaluation.notes.push(note);
                        return true;
                    }),
                    images: evalu.images.filter(image => {
                        if(!this.selection.isSelected(image.key)) return false;
                        rs.includeImages.push(image.key);
                        evaluation.images.push(image);
                        return true;
                    })
                };

                evalsSnapshot.push(evalSnapshot);
                rs.data.evals.push(evaluation);

                let evalTimelineOptions: ReportSampleEvalTimelineOptions = {
                    exclude: this.selectionTimeline.isSelected(evalu.key),
                }
                evalsTimelineOptions[evalu.key] = evalTimelineOptions;
            });

            rs.evalsTimelineOptions = evalsTimelineOptions;

            reportSamples.push(rs);
        });
        return reportSamples;
    };

    isNodeDraggable(node: SampleDataNode): boolean {
        return this.selection.isSelected(node.key);
    }

}
