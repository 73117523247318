import { Calculation } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_CALCULATIONS_NUT_HARVEST } from './calculations_nut_harvest';
import { LIB_CALCULATIONS_TREE_HARVEST } from './calculations_tree_harvest';
import { LIB_CALCULATIONS_FRUIT_HARVEST } from './calculations_fruit_harvest';

export class CalculationDictionary extends Dictionary<Calculation> {
    constructor(){
        super([
            ...LIB_CALCULATIONS_NUT_HARVEST,
            ...LIB_CALCULATIONS_TREE_HARVEST,
            ...LIB_CALCULATIONS_FRUIT_HARVEST,
        ]);
    }
}

export * from './_types';