import { ChartOptions } from "./_types";

export const LIB_MEASURE_CHARTS: ChartOptions[] = [
    {
        id: "chart_candle_sd",
        title: "Standard Deviation Candlestick Chart",
        type: 'CandlestickChart',
    },
    {
        id: "chart_bar_buckets",
        title: "Bucketed Bar Chart",
        type: 'ColumnChart',
    },
    {
        id: "chart_line_avg",
        title: "Avg Line Chart",
        type: 'ColumnChart',
    },
];