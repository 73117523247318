import { Characteristic, CharacteristicType } from './_types';


export const LIB_CHARS_NUT_HARVEST: Characteristic[] = [
    {
        id: "nut_husk_texture",
        type: CharacteristicType.Nominal,
        categoryId: "nut_husk",
        label: "Texture",
        params: {
            options: [
                { value: "smooth", label: "Smooth" },
                { value: "intermediate", label: "Intermediate" },
                { value: "rough", label: "Rough" },
            ]
        }
    },
    {
        id: "nut_hirsute",
        type: CharacteristicType.Interval,
        label: "Hirsute",
        categoryId: "nut_husk",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "nut_length",
        type: CharacteristicType.Interval,
        label: "Pedicel Length",
        categoryId: "nut_husk",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Short (~10mm)",
                "Short (~20mm)",
                "Intermediate (~30mm)",
                "Long (~40mm)",
                "Very Lomg (>50mm)"
            ]
        }
    },
    {
        id: "nut_size",
        type: CharacteristicType.Nominal,
        categoryId: "nut_husk",
        label: "Apical Point Size",
        params: {
            options: [
                { value: "no_point", label: "No Point" },
                { value: "small", label: "Small" },
                { value: "medium", label: "Medium" },
                { value: "large", label: "Large" },
                { value: "extra_large", label: "Extra Large" },
            ]
        }
    },
    {
        id: "nut_shape",
        type: CharacteristicType.Nominal,
        categoryId: "nut_husk",
        label: "Shape Type",
        params: {
            options: [
                { value: "round", label: "Round" },
                { value: "elongated", label: "Elongated" },
                { value: "flattened", label: "Flattened" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "nut_dehusk",
        type: CharacteristicType.Interval,
        label: "Ease of First Dehusking",
        categoryId: "nut_husk",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Easy",
                "Easy",
                "Average",
                "Difficult",
                "Very Difficult"
            ]
        }
    },
    {
        id: "nut_husk_colour",
        type: CharacteristicType.Color,
        label: "Husk Colour",
        categoryId: "nut_husk",
        params: {
            palette: [
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 15, s: 50, l: 35, label: "Brown" },
            ]
        }
    },
    {
        id: "nut_shell_texture",
        type: CharacteristicType.Nominal,
        categoryId: "nut_shell",
        label: "Texture",
        params: {
            options: [
                { value: "smooth", label: "Smooth" },
                { value: "intermediate", label: "Intermediate" },
                { value: "rough", label: "Rough" },
            ]
        }
    },
    {
        id: "nut_shell_micropyle",
        type: CharacteristicType.Interval,
        label: "Texture",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Shallow",
                "Shallow",
                "Intermediate",
                "Deep",
                "Very Deep"
            ]
        }
    },
    {
        id: "nut_shell_suture",
        type: CharacteristicType.Interval,
        label: "Suture Openness",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Shallow",
                "Shallow",
                "Intermediate",
                "Deep",
                "Very Deep"
            ]
        }
    },
    {
        id: "nut_shell_adhesion",
        type: CharacteristicType.Interval,
        label: "Husk to Hilum Adhesion",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Free",
                "Semi-Adhesive",
                "Cling",
            ]
        }
    },
    {
        id: "nut_shell_markings",
        type: CharacteristicType.Interval,
        label: "Markings",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "nut_shell_size",
        type: CharacteristicType.Nominal,
        categoryId: "nut_shell",
        label: "Shell Size",
        params: {
            options: [
                { value: "undersize", label: "Undersize" },
                { value: "small", label: "Small" },
                { value: "medium", label: "Medium" },
                { value: "large", label: "Large" },
                { value: "extra_large", label: "Extra Large" },
            ]
        }
    },
    {
        id: "nut_shell_consistent",
        type: CharacteristicType.Interval,
        label: "Size Consistency",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "nut_shell_thick",
        type: CharacteristicType.Interval,
        label: "Shell Thickness",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Thick",
                "Very Thick"
            ]
        }
    },
    {
        id: "nut_shell_shape",
        type: CharacteristicType.Nominal,
        categoryId: "nut_shell",
        label: "Shape Type",
        params: {
            options: [
                { value: "ovate", label: "Ovate" },
                { value: "oblate", label: "Oblate" },
                { value: "circular", label: "Circular" },
                { value: "elliptic", label: "Elliptic" },
                { value: "obovate", label: "Obovate" },
            ]
        }
    },
    {
        id: "nut_shell_symmetry",
        type: CharacteristicType.Interval,
        label: "Shape Symmetry",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Asymmetrical",
                "Intermediate",
                "Symmetrical",
            ]
        }
    },
    {
        id: "nut_shell_shape_consistent",
        type: CharacteristicType.Interval,
        label: "Shape Consistency",
        categoryId: "nut_shell",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "nut_shell_colour",
        type: CharacteristicType.Color,
        label: "Shell Colour",
        categoryId: "nut_shell",
        params: {
            palette: [
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 15, s: 50, l: 35, label: "Brown" },
            ]
        }
    },
    {
        id: "nut_shell_disorders",
        type: CharacteristicType.Nominal,
        categoryId: "nut_shell",
        label: "Shell Disorders",
        params: {
            options: [
                { value: "cracks", label: "Cracks" },
                { value: "pale_colour", label: "Pale Colour" },
                { value: "dark_colour", label: "Dark Colour" },
                { value: "misshapen", label: "Misshapen" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "nut_shell_markings_type",
        type: CharacteristicType.Nominal,
        categoryId: "nut_shell",
        label: "Markings Type",
        params: {
            options: [
                { value: "streeks", label: "Streeks" },
                { value: "spots", label: "Spots" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "nut_kernel_size",
        type: CharacteristicType.Nominal,
        categoryId: "nut_kernel",
        label: "Kernel Size",
        params: {
            options: [
                { value: "undersize", label: "undersize" },
                { value: "small", label: "Small" },
                { value: "medium", label: "Medium" },
                { value: "large", label: "Large" },
                { value: "extra_large", label: "Extra Large" },
            ]
        }
    },
    {
        id: "nut_kernel_size_consistent",
        type: CharacteristicType.Interval,
        label: "Size Consistency",
        categoryId: "nut_kernel",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "nut_kernel_symmetry",
        type: CharacteristicType.Interval,
        label: "Shape Symmetry",
        categoryId: "nut_kernel",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Asymmetrical",
                "Intermediate",
                "Symmetrical",
            ]
        }
    },
    {
        id: "nut_kernel_shape_consistent",
        type: CharacteristicType.Interval,
        label: "Shape Consistency",
        categoryId: "nut_kernel",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "nut_kernel_colour",
        type: CharacteristicType.Color,
        label: "Kernel Colour",
        categoryId: "nut_kernel",
        params: {
            palette: [
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 15, s: 50, l: 35, label: "Brown" },
            ]
        }
    },
    {
        id: "nut_kernel_colour_uniformity",
        type: CharacteristicType.Interval,
        label: "Colour Uniformity",
        categoryId: "nut_kernel",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "nut_kernel_blemishes",
        type: CharacteristicType.Nominal,
        categoryId: "nut_kernel",
        label: "Kernel Blemishes",
        params: {
            options: [
                { value: "gd", label: "General Discolouration" },
                { value: "dk", label: "Dark Kernel" },
                { value: "id", label: "Internal Discolouration / Brown Centers" },
                { value: "bd", label: "Basal Discoloration / Onion Ring" },
                { value: "db", label: "Distal end Browning" },
                { value: "pb", label: "Physiological Browning" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "nut_kernel_diseases",
        type: CharacteristicType.Nominal,
        categoryId: "nut_kernel",
        label: "Kernel Diseases",
        params: {
            options: [
                { value: "mould", label: "Mould" },
                { value: "791", label: "791 Spot" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "nut_kernel_disorders",
        type: CharacteristicType.Nominal,
        categoryId: "nut_kernel",
        label: "Kernel Discorders",
        params: {
            options: [
                { value: "pg", label: "Pre-Germination" },
                { value: "is", label: "Immaturity/ Shrivelling" },
                { value: "mh", label: "Misshapenness" },
                { value: "sp", label: "Sponginess" },
                { value: "hc", label: "Hollow Centre" },
                { value: "dc", label: "Decomposition" },
                { value: "as", label: "Adhered Skin" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "nut_kernel_insect",
        type: CharacteristicType.Nominal,
        categoryId: "nut_kernel",
        label: "Insect Damage",
        params: {
            options: [
                { value: "esb", label: "Early Stink Bug" },
                { value: "lsb", label: "Late Stink Bug" },
                { value: "fcm", label: "False Codling Moth / Nut Borer" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "nut_kernel_roasting",
        type: CharacteristicType.Nominal,
        categoryId: "nut_eat_quality_dried",
        label: "Roasting",
        params: {
            options: [
                { value: "w_oil", label: "With Oil" },
                { value: "wo_oil", label: "Without Oil" },
            ]
        }
    },
    {
        id: "nut_kernel_sweetness",
        type: CharacteristicType.Interval,
        label: "Sweetness",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low Sweetness",
                "Low Sweetness",
                "Intermediate",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "nut_kernel_aroma",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "nut_kernel_firmness",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Soft",
                "Soft",
                "Intermediate",
                "Firm",
                "Compact / Hard"
            ]
        }
    },
    {
        id: "nut_kernel_crisp",
        type: CharacteristicType.Interval,
        label: "Crispness",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Crisp",
                "Very Crisp"
            ]
        }
    },
    {
        id: "nut_kernel_crunch",
        type: CharacteristicType.Interval,
        label: "Crunchiness",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low Resistance",
                "Low Resistance",
                "Intermediate",
                "Crunchy",
                "Very Crunchy"
            ]
        }
    },
    {
        id: "nut_kernel_oily",
        type: CharacteristicType.Interval,
        label: "Oiliness",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Oily",
                "Very Oily"
            ]
        }
    },
    {
        id: "nut_kernel_rancidity",
        type: CharacteristicType.Interval,
        label: "Oiliness",
        categoryId: "nut_eat_quality_dried",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Rancid",
                "Very Rancid"
            ]
        }
    },
    {
        id: "nut_kernel_sweetness_after",
        type: CharacteristicType.Interval,
        label: "Sweetness after Storage",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low Sweetness",
                "Low Sweetness",
                "Intermediate",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "nut_kernel_aroma_after",
        type: CharacteristicType.Interval,
        label: "Aroma after Storage",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "nut_kernel_firmness_after",
        type: CharacteristicType.Interval,
        label: "Aroma after Storage",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Soft",
                "Soft",
                "Intermediate",
                "Firm",
                "Compact / Hard"
            ]
        }
    },
    {
        id: "nut_kernel_crisp_after",
        type: CharacteristicType.Interval,
        label: "Crispness after Storage",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Crisp",
                "Very Crisp"
            ]
        }
    },
    {
        id: "nut_kernel_crunch_after",
        type: CharacteristicType.Interval,
        label: "Crunchiness after Storage",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low Resistance",
                "Low Resistance",
                "Intermediate",
                "Crunchy",
                "Very Crunchy"
            ]
        }
    },
    {
        id: "nut_kernel_oily_after",
        type: CharacteristicType.Interval,
        label: "Oiliness",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Oily",
                "Very Oily"
            ]
        }
    },
    {
        id: "nut_rancidity_after",
        type: CharacteristicType.Interval,
        label: "Oiliness",
        categoryId: "nut_eat_quality_dried_after",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Oily",
                "Very Oily"
            ]
        }
    },
    {
        id: "nut_overall_appear",
        type: CharacteristicType.Interval,
        label: "Appearance",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "nut_overall_roast",
        type: CharacteristicType.Interval,
        label: "Roasting Quality",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "nut_overall_keeping",
        type: CharacteristicType.Interval,
        label: "Keeping Quality",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "nut_overall_taste",
        type: CharacteristicType.Interval,
        label: "Taste & Aroma",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "nut_overall_interest",
        type: CharacteristicType.Interval,
        label: "Interest",
        categoryId: "harvest_overall",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
];
