import { Protocol } from './_types';

export const LIB_PROTOCOL_CITRUS_TREE_SCREEN_EVAL: Protocol = {
    id: "citrus_tree_screen_eval",
    title: "Citrus Tree Evaluation (Alpha)",
    description: "Protocol is in development (alpha stage), changes may occur.",
    sampleType: "plant",
    crop: "hybrid_citrus",

    chars: [
        "tree_dormant_symptom",
        "tree_flower_span",
        "tree_uniform_ripe",
        "tree_overall_product",
        "tree_sunburn_symptom",
        "tree_overall_adapt"
    ],

    measures: [
        "tree_trunk_circum",
        "tree_total_yield",
        "tree_fruit_count",
        "tree_height",
        "tree_width",
        "tree_depth",
        'tree_yield_eff',
    ],

    indexes: [],

    calculations: [
        "tree_yield_eff",
        "tons_per_ha_calc",
    ]

};