import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { WeightedChar } from "../../types/EvaluationLibrary/weighted-char";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class WeightedCharCreated {
    static readonly type = "[WeightedCharService] WeightedChar Created";
    constructor(public weightedChar: WeightedChar) {}
}

export class WeightedCharUpdated {
    static readonly type = "[WeightedCharService] WeightedChar Updated";
    constructor(public weightedChar: WeightedChar) {}
}

export class WeightedCharDeleted {
    static readonly type = "[WeightedCharService] WeightedChar Deleted";
    constructor(public weightedChar: WeightedChar) {}
}

@Injectable()
export class WeightedCharService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) {}

    get(key: string, detail?: DetailRequest): Observable<WeightedChar> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<WeightedChar>(`${this._baseUri}/evaluation-library/weighted-char/${key}`, {params});
    }

    create(model: Partial<WeightedChar>) {
        return this._http.post<WeightedChar>(`${this._baseUri}/evaluation-library/weighted-char`, model)
            .pipe(tap(char => this._store.dispatch(new WeightedCharCreated(char))));
    }

    update(key: string, model: Partial<WeightedChar>) {
        return this._http.put<WeightedChar>(`${this._baseUri}/evaluation-library/weighted-char/${key}`, model)
            .pipe(tap(char => this._store.dispatch(new WeightedCharUpdated(char))));
    }

    delete(key: string) {
        return this._http.delete<WeightedChar>(`${this._baseUri}/evaluation-library/weighted-char/${key}`)
            .pipe(tap(char => this._store.dispatch(new WeightedCharDeleted(char))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<WeightedChar>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<WeightedChar>>(`${this._baseUri}/evaluation-library/weighted-char`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<WeightedChar>>(`${this._baseUri}/evaluation-library/weighted-char/search`, {params: <any>search});
    }
}