
<div class="color-toggle-container">
    <input class="color-toggle"
        type="button"
        [style.background-color]="_value ? _value.toString() : 'white'"
        [class.empty]="!_value"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        [disabled]="_disabled"
        (click)="toggle()"
        cdkMonitorElementFocus (cdkFocusChange)="onFocusChange($event)">
</div>

<ng-template cdkConnectedOverlay
        cdkConnectedOverlayHasBackdrop
        cdkConnectedOverlayBackdropClass="color-picker-backdrop"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen"
        (backdropClick)="backdropClick()">
    <ng-template [cdkPortalOutlet]="overlayPanel"></ng-template>
</ng-template>

<ng-template cdkPortal #overlayPanel="cdkPortal">

    <div class="colorpicker-overlay mat-elevation-z2" role="dialog" aria-label="Color picker" 
        cdkMonitorSubtreeFocus (cdkFocusChange)="onFocusChange($event)">
        <div class="color-display" [class.empty]="!_value" [style.background-color]="_value ? _value.toString() : 'white'"></div>

        <div class="color-slider">
            H <mat-slider thumbLabel min="0" max="360" step="1" [value]="value?.h" (input)="setHue($event.value)" tabindex="0"></mat-slider>
        </div>
        <div class="color-slider">
            S <mat-slider thumbLabel min="0" max="100" step="1" [value]="value?.s" (input)="setSaturation($event.value)"></mat-slider>
        </div>
        <div class="color-slider">
            L <mat-slider thumbLabel min="0" max="100" step="1" [value]="value?.l" (input)="setLightness($event.value)"></mat-slider>
        </div>

        <div class="color-palette">
            <button class="color" type="button" *ngFor="let color of colors" [style.background-color]="color" (click)="selectColor(color)"></button>
        </div>

        <div class="color-actions">
            <button type="button" mat-icon-button (click)="clear()">
                <mat-icon>clear</mat-icon>
            </button>
            <button type="button" mat-icon-button color="primary" (click)="close()">
                <mat-icon>done</mat-icon>
            </button>
        </div>

    </div>

</ng-template>