import { Injectable, NgZone } from '@angular/core';
import { Snackbar } from '@core/material';
import { Action, State, StateContext } from '@ngxs/store';

export class InitSnackbarState {
    static readonly type ="[Snackbar] Init Snackbar";
    constructor(){}
}

export class ShowInfoMessage {
    static readonly type ="[Snackbar] Show Info Message";
    constructor(public message: string, public duration = 4000){}
}

export class ShowErrorMessage {
    static readonly type ="[Snackbar] Show Error Message";
    constructor(public message: string, public duration = 4000){}
}

export interface SnackbarStateModel {
    message: string;
    duration: number;
}

const DEFAULTS: SnackbarStateModel = {
    message: null,
    duration: 6000,
};

@State<SnackbarStateModel>({
    name: 'snackbar',
    defaults: DEFAULTS,
})
@Injectable()
export class SnackbarState {

    constructor(
        private _ngZone: NgZone,
        private _snackbar: Snackbar
    ){}

    /**
     *
     * @param ctx State Context
     * @param action message: to be displayed with the snackbar, duration: of the snackbar message
     */
    @Action(ShowInfoMessage)
    showInfoMessage(ctx: StateContext<SnackbarStateModel>, action: ShowInfoMessage) {

        ctx.patchState({
            message: action.message,
            duration: action.duration,
        });

        this._ngZone.run(() => {
            this._snackbar.info(action.message, action.duration);
        });
    }

    /**
     *
     * @param ctx State Context
     * @param action message: to be displayed with the snackbar, duration: of the snackbar message
     */
    @Action(ShowErrorMessage)
    showErrorMessage(ctx: StateContext<SnackbarStateModel>, action: ShowErrorMessage) {

        ctx.patchState({
            message: action.message,
            duration: action.duration,
        });

        this._ngZone.run(() => {
            this._snackbar.error(action.message, action.duration);
        });
    }
}