import { TastingCustomWeightedCharQuery } from "./tasting-custom-weighted-chars-index.state-model";


export class InitTastingCustomWeightedCharIndex {
    static readonly type = "[TastingCustomWeightedCharIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadTastingCustomWeightedCharIndex {
    static readonly type = "[TastingCustomWeightedCharIndex] Load";
    constructor() {}
}

export class PageTastingCustomWeightedCharIndex {
    static readonly type = "[TastingCustomWeightedCharIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingCustomWeightedCharIndex {
    static readonly type = "[TastingCustomWeightedCharIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingCustomWeightedCharIndex {
    static readonly type = "[TastingCustomWeightedCharIndex] Query";
    constructor(public query: TastingCustomWeightedCharQuery) {}
}

export class ClearTastingCustomWeightedCharIndexFilter {
    static readonly type = "[TastingCustomWeightedCharIndex] Clear Filter";
    constructor() {}
}

export class SetTastingCustomWeightedCharIndexSelected {
    static readonly type = "[TastingCustomWeightedCharIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingCustomWeightedChar {
    static readonly type = "[TastingCustomWeightedCharIndex] Delete Custom Weighted Char";
    constructor(public key: string) {}
}
