import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '../number';

@Pipe({
    name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

    transform(bytes: number, decimals: number = 0) {
        if (typeof bytes === 'number') {
            return formatBytes(bytes, decimals);
        }
        return '';
    }

}