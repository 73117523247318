import { Characteristic, CharacteristicType } from './_types';

export const LIB_CHARS_GRAPE_VINE: Characteristic[] = [

    // Vine Phenology
    {
        id: "date_vine_bud_burst",
        type: CharacteristicType.Event,
        label: "Beginning of Bud Burst",
        categoryId: "vine_phenology",
        params:{
            color: "#FFCC80",
            bbch_code: "07"
        }
    },
    {
        id: "date_vine_10_flower",
        type: CharacteristicType.Event,
        label: "10% Flowering",
        categoryId: "vine_phenology",
        params:{
            color: "#FFCC80",
            bbch_code: "61"
        }
    },
    {
        id: "date_vine_80_flower",
        type: CharacteristicType.Event,
        label: "80% Flowering",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4",
            bbch_code: "68"
        }
    },
    {
        id: "date_vine_berry_set",
        type: CharacteristicType.Event,
        label: "Berry Set",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4",
            bbch_code: "71"
        }
    },
    {
        id: "date_vine_berry_shat",
        type: CharacteristicType.Event,
        label: "Berry Shatter",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_pea_size",
        type: CharacteristicType.Event,
        label: "Pea Size",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_veraison",
        type: CharacteristicType.Event,
        label: "Veraison",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4",
            bbch_code: "81"
        }
    },
    {
        id: "date_vine_harvest_start",
        type: CharacteristicType.Event,
        label: "Harvest Start",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_harvest_end",
        type: CharacteristicType.Event,
        label: "Harvest End",
        categoryId: "vine_phenology",
        params:{
            color: "#00BCD4"
        }
    },

    // Events for Grape Vines
    {
        id: "date_vine_tip_man",
        type: CharacteristicType.Event,
        label: "Tip",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_top_man",
        type: CharacteristicType.Event,
        label: "Top",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_thin_man1",
        type: CharacteristicType.Event,
        label: "Thinning (First)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_thin_man2",
        type: CharacteristicType.Event,
        label: "Thinning (Second)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_thin_man3",
        type: CharacteristicType.Event,
        label: "Thinning (Third)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_set_man1",
        type: CharacteristicType.Event,
        label: "Set (First)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_set_man2",
        type: CharacteristicType.Event,
        label: "Set (Second)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_set_man3",
        type: CharacteristicType.Event,
        label: "Set (Third)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_size_man1",
        type: CharacteristicType.Event,
        label: "Size (First)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_size_man2",
        type: CharacteristicType.Event,
        label: "Size (Second)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_size_man3",
        type: CharacteristicType.Event,
        label: "Size (Third)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },

    {
        id: "date_vine_color_man1",
        type: CharacteristicType.Event,
        label: "Colour (First)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_color_man2",
        type: CharacteristicType.Event,
        label: "Colour (Second)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_color_man3",
        type: CharacteristicType.Event,
        label: "Colour (Third)",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },


    {
        id: "date_vine_rest_break",
        type: CharacteristicType.Event,
        label: "Rest Breaking Application",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },
    {
        id: "date_vine_prune",
        type: CharacteristicType.Event,
        label: "Pruning",
        categoryId: "vine_events",
        params:{
            color: "#00BCD4"
        }
    },


    // Growth and Architecture

    {
        id: "vine_bud_distrib",
        type: CharacteristicType.Interval,
        label: "Bud Break Distribution",
        categoryId: "vine_arch",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Uneven",
                "Intermediate",
                "Even",
            ]
        }
    },
    {
        id: "vine_grow_potent",
        type: CharacteristicType.Interval,
        label: "Growth Potential",
        categoryId: "vine_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },

    {
        id: "vine_shoot_dense",
        type: CharacteristicType.Interval,
        label: "Shoot Growth Density",
        categoryId: "vine_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Rare",
                "Few",
                "Intermediate",
                "Numerous",
                "High",
            ]
        }
    },

    {
        id: "vine_canopy_dense",
        type: CharacteristicType.Interval,
        label: "Canopy Density",
        categoryId: "vine_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Sparse",
                "Intermediate",
                "Dense",
            ]
        }
    },

    // Trellis and Training System

    {
        id: "vine_trellis_system",
        type: CharacteristicType.Nominal,
        categoryId: "vine_trellis_train",
        label: "Trellis System",
        params: {
            options: [
                { value: "trentina", label: "Trentina" },
                { value: "t_trellis", label: "T-trellis" },
                { value: "gable", label: "Gable" },
                { value: "double_gable", label: "Double Gable" },
                { value: "factory_roof", label: "Factory Roof" },
                { value: "roof_pergola", label: "Roof / Pergola" },
                { value: "slanting", label: "Slanting" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },

    {
        id: "vine_train_system",
        type: CharacteristicType.Nominal,
        categoryId: "vine_trellis_train",
        label: "Training System",
        params: {
            options: [
                { value: "split_cordon", label: "Split Cordon" },
                { value: "dbl_split_cordon", label: "Double Split Cordon" },
                { value: "x_form", label: "X-Form" },
                { value: "alt", label: "Alternative" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },

    // Flowering

    {
        id: "vine_flower_bunch_dense",
        type: CharacteristicType.Interval,
        label: "Flower Bunch Density",
        categoryId: "vine_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High",
            ]
        }
    },
    {
        id: "vine_flower_distrib",
        type: CharacteristicType.Interval,
        label: "Flower Distribution",
        categoryId: "vine_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "vine_flower_bunch_distrib",
        type: CharacteristicType.Interval,
        label: "Flower Bunch Distribution",
        categoryId: "vine_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "vine_flower_bunch_thin",
        type: CharacteristicType.Interval,
        label: "Bunch Thinning Required",
        categoryId: "vine_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },

    {
        id: "vine_flower_absciss",
        type: CharacteristicType.Interval,
        label: "Flower Abscission",
        categoryId: "vine_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Low",
                "Intermediate",
                "Good",
                "High",
            ]
        }
    },
    {
        id: "vine_flower_thin",
        type: CharacteristicType.Interval,
        label: "Flower Thinning Required",
        categoryId: "vine_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },

    // Fruit Set

    {
        id: "vine_bear_precocity",
        type: CharacteristicType.Interval,
        label: "Precocity",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Late",
                "Intermediate",
                "Early"
            ]
        }
    },

    {
        id: "vine_bear_habit",
        type: CharacteristicType.Nominal,
        categoryId: "vine_bear",
        label: "Bearing Habit",
        params: {
            options: [
                { value: "spur", label: "Spur" },
                { value: "half_long", label: "Half Long" },
                { value: "long", label: "Long" },
            ]
        }
    },
    {
        id: "vine_berry_shatter",
        type: CharacteristicType.Interval,
        label: "Berry Shatter",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High",
            ]
        }
    },

    {
        id: "vine_bunch_shape",
        type: CharacteristicType.Nominal,
        categoryId: "vine_bear",
        label: "Bunch Shape",
        params: {
            options: [
                { value: "cylindrical", label: "Cylindrical" },
                { value: "short_conical", label: "Short Conical" },
                { value: "round", label: "Round" },
                { value: "long_conical", label: "Long Conical" },
                { value: "winged_cluster", label: "Winged Cluster" },
            ]
        }
    },
    {
        id: "vine_bunch_short",
        type: CharacteristicType.Interval,
        label: "Bunch Shortening Required",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },

    {
        id: "vine_berry_shot",
        type: CharacteristicType.Interval,
        label: "Shot Berries",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High",
            ]
        }
    },

    {
        id: "vine_bunch_dense",
        type: CharacteristicType.Interval,
        label: "Bunch Density",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Straggly",
                "Loose",
                "Ideal",
                "Compact",
                "Very Compact"
            ]
        }
    },
    {
        id: "vine_bunch_uniform",
        type: CharacteristicType.Interval,
        label: "Bunch Uniformity",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Varied",
                "Intermediate",
                "Uniform",
            ]
        }
    },
    {
        id: "vine_berry_uniform",
        type: CharacteristicType.Interval,
        label: "Berry Uniformity",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Varied",
                "Intermediate",
                "Uniform",
            ]
        }
    },

    {
        id: "vine_berry_thin",
        type: CharacteristicType.Interval,
        label: "Berry Thinning Required",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },

    {
        id: "vine_pick_count",
        type: CharacteristicType.Nominal,
        categoryId: "vine_bear",
        label: "Number Of Picks",
        params: {
            options: [
                { value: "pick_1", label: "1" },
                { value: "pick_2", label: "2" },
                { value: "pick_3", label: "3" },
                { value: "pick_4", label: "4" },
                { value: "pick_more", label: "5+" }
            ]
        }
    },

    {
        id: "vine_color_dev",
        type: CharacteristicType.Interval,
        label: "Colour Development",
        categoryId: "vine_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Coverage",
            descriptors: [
                "Little Coverage",
                "Half Covered",
                "Mostly Covered",
                "Completely Covered",
            ]
        }
    },

    // Pests, Diseases & Disorders

    {
        id: "vine_pest_detect",
        type: CharacteristicType.Nominal,
        categoryId: "vine_disorder",
        label: "Pests Detected",
        params: {
            options: [
                { value: "fruit_fly", label: "Fruit fly" },
                { value: "false_codling_moth", label: "False Codling Moth" },
                { value: "nematodes", label: "Nematodes" },
                { value: "mealybug", label: "Mealybug" },
                { value: "thrips", label: "Thrips" },
                { value: "red_spider_mite", label: "Red Spider Mite" },
                { value: "bollworm", label: "Bollworm" },
                { value: "weevil", label: "Weevil" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },

    {
        id: "vine_disease_detect",
        type: CharacteristicType.Nominal,
        categoryId: "vine_disorder",
        label: "Diseases Detected",
        params: {
            options: [
                { value: "powdery_mildew", label: "Powdery Mildew" },
                { value: "downy_mildew", label: "Downy Mildew" },
                { value: "bacterial_blight", label: "Bacterial Blight" },
                { value: "leafroll_virus", label: "Leafroll Virus" },
                { value: "botrytis", label: "Botrytis" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },

    {
        id: "vine_overall_disease",
        type: CharacteristicType.Interval,
        label: "Disease Presence",
        categoryId: "vine_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },

    // Adaptability

    {
        id: "vine_dormant_symptom",
        type: CharacteristicType.Interval,
        label: "Prolonged Dormancy Symptoms",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },

    {
        id: "vine_compat",
        type: CharacteristicType.Interval,
        label: "Compatibility",
        categoryId: "vine_arch",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },


    {
        id: "vine_flower_span",
        type: CharacteristicType.Interval,
        label: "Flower Burst Span",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Brief",
                "Prolonged",
                "Extended"
            ]
        }
    },
    {
        id: "vine_uniform_ripe",
        type: CharacteristicType.Interval,
        label: "Uniform Ripening",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "vine_fertile",
        type: CharacteristicType.Interval,
        label: "Fertility",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Low",
                "Intermediate",
                "High",
            ]
        }
    },
    {
        id: "vine_overall_product",
        type: CharacteristicType.Interval,
        label: "Productivity",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },

    {
        id: "vine_rain_suscept",
        type: CharacteristicType.Interval,
        label: "Rain Susceptibility",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Resistant",
                "Intermediate",
                "Susceptible",
            ]
        }
    },
    {
        id: "vine_sunburn_symptom",
        type: CharacteristicType.Interval,
        label: "Sunburn Presence",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "vine_work",
        type: CharacteristicType.Interval,
        label: "Ease of Handling",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },

    {
        id: "vine_overall_adapt",
        type: CharacteristicType.Interval,
        label: "Adaptability",
        categoryId: "vine_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Poorly Adapted",
                "Intermediate",
                "Well Adapted"
            ]
        }
    },

];