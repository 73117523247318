import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'pv-view-message',
    templateUrl: './view-message.component.html',
    preserveWhitespaces: false,
    host: {
        class: 'pv-view-message'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewMessageComponent implements OnInit {

    @Input('heading')
    title: string;

    @Input()
    message: string;

    @Input()
    icon: string;

    constructor() { }

    ngOnInit(): void { }
}

