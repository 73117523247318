import { Crop, CropSubject } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_CROPS } from './crops';
import { LIB_SUBJECTS } from './subjects';

export class CropDictionary extends Dictionary<Crop>{
    constructor(){
        super(LIB_CROPS);
    }
}

export class SubjectDictionary extends Dictionary<CropSubject>{
    constructor(){
        super(LIB_SUBJECTS);
    }
}

export * from './_types';