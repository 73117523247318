import { Characteristic, CharacteristicType } from './_types';

export const LIB_CHARS_GRAPE_HARVEST: Characteristic[] = [

    // HARVEST

    // Loose berries
    {
        id: "bery_perc_box_loss_wop",
        type: CharacteristicType.Interval,
        label: "From Box, Without Pedicel",
        categoryId: "grap_bery_loose",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_box_loss_wp",
        type: CharacteristicType.Interval,
        label: "From Box, With Pedicel",
        categoryId: "grap_bery_loose",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_box_loss_rot",
        type: CharacteristicType.Interval,
        label: "From Box, Rotten",
        categoryId: "grap_bery_loose",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_sha_loss_wop",
        type: CharacteristicType.Interval,
        label: "After Shake, Without Pedicel",
        categoryId: "grap_bery_loose",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_sha_loss_wp",
        type: CharacteristicType.Interval,
        label: "After Shake, With Pedicel",
        categoryId: "grap_bery_loose",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_sha_loss_rot",
        type: CharacteristicType.Interval,
        label: "After Shake, Rotten",
        categoryId: "grap_bery_loose",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },

    // Storage Disorders

    // -- Sulfur Dioxide Disorder
    {
        id: "bery_perc_sulf_spray",
        type: CharacteristicType.Interval,
        label: "SO2 Spray Disorder",
        categoryId: "grap_bery_disorder",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_sulf_sheet_ble",
        type: CharacteristicType.Interval,
        label: "SO2 Sheet Disorder, Bleaching",
        categoryId: "grap_bery_disorder",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_sulf_sheet_loss",
        type: CharacteristicType.Interval,
        label: "SO2 Sheet Disorder, Loose Necks",
        categoryId: "grap_bery_disorder",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },

    // -- Other
    {
        id: "bery_perc_shrivel",
        type: CharacteristicType.Interval,
        label: "Shrivelling",
        categoryId: "grap_bery_disorder",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_attach",
        type: CharacteristicType.Interval,
        label: "Berry Attachment",
        categoryId: "grap_bery_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Weak",
                "Intermediate",
                "Strong",
            ]
        }
    },
    {
        id: "bery_shrivel",
        type: CharacteristicType.Interval,
        label: "Shrivelling",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Smooth",
                "Inconspicuous",
                "Fine Wrinkles",
            ]
        }
    },

    // Ripening

    {
        id: "bery_ripe_stage",
        type: CharacteristicType.Interval,
        label: "Ripening Stage",
        categoryId: "grap_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Unripe",
                "Immature",
                "Ideal Eat Ripeness",
                "Mature",
                "Post Optimum"
            ]
        }
    },
    {
        id: "bery_firm",
        type: CharacteristicType.Interval,
        label: "Berry Firmness",
        categoryId: "grap_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft",
                "Medium",
                "Firm",
                "Very Firm"
            ]
        }
    },
    {
        id: "bery_stick",
        type: CharacteristicType.Interval,
        label: "Stickiness",
        categoryId: "grap_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Dry",
                "Dry",
                "Medium",
                "Sticky",
                "Very Sticky"
            ]
        }
    },
    {
        id: "bery_firm_homog",
        type: CharacteristicType.Interval,
        label: "Firmness Homogeneity",
        categoryId: "grap_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Large Variation",
                "Variable",
                "Intermediate",
                "Little Variation",
                "Homogenous"
            ]
        }
    },

    // Appearance

    {
        id: "bery_over_color",
        type: CharacteristicType.Color,
        categoryId: "grap_appearance",
        label: "Over Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "bery_ground_color",
        type: CharacteristicType.Color,
        categoryId: "grap_appearance",
        label: "Ground Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "bery_class_raisin",
        type: CharacteristicType.Nominal,
        categoryId: "grap_appearance",
        label: "Raisin Classification",
        params: {
            multiple: true,
            options: [
                { value: "or_sultanas", label: "OR Sultanas" },
                { value: "thompson", label: "Thompson" },
                { value: "goldens", label: "Goldens" },
                { value: "wp_sultanas", label: "WP Sultanas" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "bery_color_class",
        type: CharacteristicType.Nominal,
        categoryId: "grap_appearance",
        label: "Raisin Color Class",
        params: {
            multiple: true,
            options: [
                { value: "A1", label: "A1" },
                { value: "A2", label: "A2" },
                { value: "A3", label: "A3" },
                { value: "A4", label: "A4" },
                { value: "A5", label: "A5" },
                { value: "B1", label: "B1" },
                { value: "B2", label: "B2" },
                { value: "B3", label: "B3" },
                { value: "B4", label: "B4" },
                { value: "B5", label: "B5" },
                { value: "C1", label: "C1" },
                { value: "C2", label: "C2" },
                { value: "C3", label: "C3" },
                { value: "C4", label: "C4" },
                { value: "C5", label: "C5" },
                { value: "D1", label: "D1" },
                { value: "D2", label: "D2" },
                { value: "D3", label: "D3" },
                { value: "D4", label: "D4" },
                { value: "D5", label: "D5" },
                { value: "E1", label: "E1" },
                { value: "E2", label: "E2" },
                { value: "E3", label: "E3" },
                { value: "E4", label: "E4" },
                { value: "E5", label: "E5" },
                { value: "F1", label: "F1" },
                { value: "F2", label: "F2" },
                { value: "F3", label: "F3" },
                { value: "F4", label: "F4" },
                { value: "F5", label: "F5" },
            ]
        }
    },
    {
        id: "bery_color_uniform",
        type: CharacteristicType.Interval,
        label: "Colour Uniformity",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Variable",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "bery_skin_bloom",
        type: CharacteristicType.Nominal,
        categoryId: "grap_appearance",
        label: "Bloom of Skin",
        params: {
            "multiple": false,
            options: [
                { value: "absent", label: "Absent" },
                { value: "moderate", label: "Moderate" },
                { value: "strong", label: "Strong" }
            ]
        }
    },
    {
        id: "bery_flesh_color",
        type: CharacteristicType.Color,
        categoryId: "grap_appearance",
        label: "Flesh Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "bery_rach_color",
        type: CharacteristicType.Color,
        categoryId: "grap_appearance",
        label: "Rachis Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "bery_scap_color",
        type: CharacteristicType.Color,
        categoryId: "grap_appearance",
        label: "Cap Stem Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "bunc_color_cover",
        type: CharacteristicType.Interval,
        label: "Bunch Colour Coverage",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Coverage", maxDesc: "Completely Covered",
            descriptors: [
                "Little Coverage",
                "Half Covered",
                "Mostly Covered"
            ]
        }
    },
    {
        id: "bery_shape_type",
        type: CharacteristicType.Nominal,
        categoryId: "grap_appearance",
        label: "Berry Shape Type",
        params: {
            options: [
                {value: "spherical", label: "Spherical (Round)" },
                {value: "oblate", label: "Oblate" },
                {value: "ellipsoidal", label: "Ellipsoidal" },
                {value: "obvoid", label: "Obvoid" },
                {value: "elongated", label: "Ellipsoidal (Elongated)" },
                {value: "ovoid", label: "Ovoid (Oval)" },
                {value: "cylindrical", label: "Cylindrical" },
            ]
        }
    },
    {
        id: "bery_rach_thick",
        type: CharacteristicType.Interval,
        label: "Rachis Thickness",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Thick",
                "Very Thick"
            ]
        }
    },
    {
        id: "bery_scap_thick",
        type: CharacteristicType.Interval,
        label: "Cap Stem Thickness",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Thick",
                "Very Thick"
            ]
        }
    },
    {
        id: "bery_stem_moist",
        type: CharacteristicType.Interval,
        label: "Stem Freshness",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Dry/Brittle",
                "Wilted",
                "Fresh",
            ]
        }
    },
    {
        id: "bery_veins",
        type: CharacteristicType.Interval,
        label: "Vein Visibility",
        categoryId: "grap_appearance",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe",
            ]
        }
    },

    // Berry Defects

    {
        id: "bery_perc_blemish",
        type: CharacteristicType.Interval,
        label: "Blemishes",
        categoryId: "grap_bery_defects",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_blemish",
        type: CharacteristicType.Interval,
        label: "Blemishes",
        categoryId: "grap_bery_defects",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "bery_lenticel",
        type: CharacteristicType.Interval,
        label: "Lenticel Presence",
        categoryId: "grap_bery_defects",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Inconspicuous",
                "Subtle",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "bery_perc_damage",
        type: CharacteristicType.Interval,
        label: "Mechanical Damage",
        categoryId: "grap_bery_defects",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_damage",
        type: CharacteristicType.Interval,
        label: "Damage",
        categoryId: "grap_bery_defects",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High",
            ]
        }
    },


    // Berry Decay

    {
        id: "bery_perc_decay_bot",
        type: CharacteristicType.Interval,
        label: "Botrytis",
        categoryId: "grap_bery_decay",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_decay_stb",
        type: CharacteristicType.Interval,
        label: "Soft Tissue Breakdown",
        categoryId: "grap_bery_decay",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_decay_pen",
        type: CharacteristicType.Interval,
        label: "Penicillium",
        categoryId: "grap_bery_decay",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_decay_rhi",
        type: CharacteristicType.Interval,
        label: "Rhizopus",
        categoryId: "grap_bery_decay",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_decay_asp",
        type: CharacteristicType.Interval,
        label: "Aspergillus",
        categoryId: "grap_bery_decay",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },




    // Berry Cracking
    {
        id: "bery_perc_bc_lateral",
        type: CharacteristicType.Interval,
        label: "Longitudinal Cracking",
        categoryId: "grap_bery_crack",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_bc_ring",
        type: CharacteristicType.Interval,
        label: "Ring Neck Crack",
        categoryId: "grap_bery_crack",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_bc_stylar",
        type: CharacteristicType.Interval,
        label: "Stylar End Crack",
        categoryId: "grap_bery_crack",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_bc_hairline",
        type: CharacteristicType.Interval,
        label: "Hairline Splits",
        categoryId: "grap_bery_crack",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    
    {
        id: "bery_crack_detect",
        type: CharacteristicType.Nominal,
        label: "Berry Crack",
        categoryId: "grap_bery_crack",
        params: {
            options: [
                { value: "lateral", label: "Lateral Cracking" },
                { value: "ring_neck", label: "Ring Neck Crack" },
                { value: "stylar_end", label: "Stylar End Crack" },
            ]
        }
    },

    // External Browning

    {
        id: "bery_perc_exb_netlike",
        type: CharacteristicType.Interval,
        label: "Net Like",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_mottled",
        type: CharacteristicType.Interval,
        label: "Mottled",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_friction",
        type: CharacteristicType.Interval,
        label: "Friction",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_russet",
        type: CharacteristicType.Interval,
        label: "Styler-End Russet Spots",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_necrotic",
        type: CharacteristicType.Interval,
        label: "Styler-End Necrotic Spots",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_contact",
        type: CharacteristicType.Interval,
        label: "Contact",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_peacock",
        type: CharacteristicType.Interval,
        label: "Peacock Spot",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_sunburn",
        type: CharacteristicType.Interval,
        label: "Sunburn",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_exb_abrasions",
        type: CharacteristicType.Interval,
        label: "Abrasions",
        categoryId: "grap_bery_ext_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_extbrown_symptom",
        type: CharacteristicType.Interval,
        label: "External Browning",
        categoryId: "grap_bery_ext_browning",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },

    // Internal Browning

    {
        id: "bery_perc_inb_chocolate",
        type: CharacteristicType.Interval,
        label: "Chocolate Berry",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_inb_water",
        type: CharacteristicType.Interval,
        label: "Water Berry",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_inb_glass",
        type: CharacteristicType.Interval,
        label: "Glassy Berry",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_inb_cold",
        type: CharacteristicType.Interval,
        label: "Cold Damage",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_inb_freeze",
        type: CharacteristicType.Interval,
        label: "Freezing Damage",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_inb_methyl",
        type: CharacteristicType.Interval,
        label: "Methyl Bromide",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_perc_inb_co2",
        type: CharacteristicType.Interval,
        label: "CO2",
        categoryId: "grap_bery_int_browning",
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "bery_intbrown_symptom",
        type: CharacteristicType.Interval,
        label: "Internal Browning",
        categoryId: "grap_bery_int_browning",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },

    // Seed Presence

    {
        id: "bery_seed",
        type: CharacteristicType.Nominal,
        categoryId: "grap_bery_seed",
        label: "Seed Presence",
        params: {
            options: [
                { value: "seeded", label: "Seeded" },
                { value: "seedless", label: "Seedless" },
            ]
        }
    },
    {
        id: "bery_seed_detect",
        type: CharacteristicType.Interval,
        categoryId: "grap_bery_seed",
        label: "Seed Detection",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Rare",
                "Few",
                "Intermediate",
                "Numerous",
                "High",
            ]
        }
    },
    {
        id: "bery_seed_trace",
        type: CharacteristicType.Interval,
        categoryId: "grap_bery_seed",
        label: "Seed Trace",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Trace",
            descriptors: [
                "Soft Trace",
                "Moderate Trace",
                "Hard Trace",
            ]
        }
    },

    // Eating Quality

    {
        id: "bery_taste_sugar",
        type: CharacteristicType.Interval,
        label: "Sugar",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Sweetness",
                "Low Sweetness",
                "Intermediate",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "bery_taste_acid",
        type: CharacteristicType.Interval,
        label: "Acid",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Acid",
                "Low Acid",
                "Intermediate",
                "Tart",
                "Very Tart"
            ]
        }
    },
    {
        id: "bery_taste_balance",
        type: CharacteristicType.Interval,
        label: "Sugar Acid Balance",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Tarty",
                "Tarty",
                "Balanced",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "bery_crystal_form",
        type: CharacteristicType.Interval,
        label: "Crystal Formation",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe",
            ]
        }
    },
    {
        id: "bery_taste_aroma",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "bery_taste_aroma_type",
        type: CharacteristicType.Nominal,
        categoryId: "grap_eating_quality",
        label: "Aroma Type",
        params: {
            options: [
                { value: "neutral", label: "Neutral" },
                { value: "muscata", label: "Muscata" },
                { value: "labrusca", label: "Labrusca" },
                { value: "signature", label: "Signature" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "bery_taste_juice",
        type: CharacteristicType.Interval,
        label: "Juiciness",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Dry",
                "Dry",
                "Intermediate",
                "Juicy",
                "Very Juicy"
            ]
        }
    },
    {
        id: "bery_skin_flavor_type",
        type: CharacteristicType.Nominal,
        categoryId: "grap_eating_quality",
        label: "Skin Flavour Type",
        params: {
            options: [
                { value: "astringent", label: "Astringent" },
                { value: "grassy", label: "Grassy" },
                { value: "bitter", label: "Bitter" },
                { value: "neutral", label: "Neutral" },
                { value: "complimentary", label: "Complimentary" },
            ]
        }
    },
    {
        id: "bery_skin_flavor_intense",
        type: CharacteristicType.Interval,
        label: "Skin Flavour Intensity",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "bery_skin_thick",
        type: CharacteristicType.Interval,
        label: "Skin Thickness",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Thick",
                "Very Thick"
            ]
        }
    },
    {
        id: "bery_text_crunch",
        type: CharacteristicType.Interval,
        label: "Texture Crunchiness",
        categoryId: "grap_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Resistance",
                "Low Resistance",
                "Intermediate",
                "Crunchy",
                "Very Crunchy"
            ]
        }
    },

];