
export function distance(lat1, lon1, lat2, lon2) {
	let p = 0.017453292519943295;    // Math.PI / 180
	let c = Math.cos;
	let a = 0.5 - c((lat2 - lat1) * p) / 2 +
		c(lat1 * p) * c(lat2 * p) *
		(1 - c((lon2 - lon1) * p)) / 2;

	return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export function polygonCentroid(polygon: {lat: number, lng: number}[]) {

	let points = [...polygon];

	if (polygon.length === 2) {
		return {
			lat: (polygon[0].lat + polygon[1].lat) / 2,
			lng: (polygon[0].lng + polygon[1].lng) / 2,
		}
	}

	let first = points[0], last = points[points.length - 1];
	if (first.lng != last.lng || first.lat != last.lat) points.push(first);
	let twicearea = 0,
		x = 0, y = 0,
		nPts = points.length,
		p1, p2, f;
	for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
		p1 = points[i]; p2 = points[j];
		f = p1.lng * p2.lat - p2.lng * p1.lat;
		twicearea += f;
		x += (p1.lng + p2.lng) * f;
		y += (p1.lat + p2.lat) * f;
	}

	f = twicearea * 3;
	return { lng: x / f, lat: y / f };

}

export enum Direction {
	LATITUDE = 'lat',
	LONGITUDE = 'lng'
}

export class Coordinates {

	static transformDecimalToDegrees(value: number): string {
		const absoluteDegrees = Math.abs(Number(value));
		let fullDegrees = Math.floor(absoluteDegrees);
		const remainingMinutes = (absoluteDegrees - fullDegrees) * 60;
		let minutes = Math.floor(remainingMinutes);
		const remainingSeconds = (remainingMinutes - minutes) * 60;
		let seconds = Math.round(remainingSeconds);
		if (seconds === 60) {
			minutes += 1;
			seconds = 0;
		}
		if (minutes === 60) {
			fullDegrees += 1;
			minutes = 0;
		}
		return (`${fullDegrees}°${minutes}'${seconds}"`)
	}

	static getCardinalDirection(degrees: number, direction: Direction): 'N' | 'S' | 'W' | 'E' {
		if (direction === Direction.LATITUDE) {
			return degrees < 0 ? 'S' : 'N'
		} else {
			return degrees < 0 ? 'W' : 'E'
		}
	}

	static getDirectionBoundaries(direction?: Direction): [number, number] {
		return !direction || direction === Direction.LONGITUDE ? [-180, 180] : [-90, 90]
	}

}