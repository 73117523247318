import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_BASE_URI } from "../http/api";
import { Store } from "@ngxs/store";
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { Filter, FilterBuilder } from '../http/filter';
import { ExportHistory } from "../types/export-history";
import { Collection } from '../http/collection';

@Injectable()
export class ExportHistoryService {
    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUrl: string,
        private _store: Store,
    ) {}

    query(filter: Filter, detail?: DetailRequest) {
        let params = {
            ...(new FilterBuilder(filter)).getQueryParams(),
            ...(detail ? (new DetailRequestBuilder(detail)).getQueryParams() : {})
        }

        return this._http.get<Collection<ExportHistory>>(`${this._baseUrl}/export-history`, { params });
    }
}