<ng-container *ngIf="images$ | async as state">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            Select Images
        </h2>
    </div>

    <div mat-dialog-content *ngIf="state as images">
        <h4>Primary Cultivar</h4>
        <mat-selection-list class="image-list" *ngIf="(images && images.length) else noImages" [formControl]="includeImagesControl" (selectionChange)="handleImageSelection($event.option)">
            <mat-list-option class="image-selector" *ngFor="let image of images" [value]="image.key">
                <pv-image-ref
                    [fileRef]="image.fileRef"
                    transformId="thumb_72">
                </pv-image-ref>
            </mat-list-option>
        </mat-selection-list>

        <ng-template #noImages>
            <p class="text-muted">No images found for cultivar.</p>
        </ng-template>

        <div *ngIf="controlImages$ | async as controlImages">
            <h4>Control Cultivar</h4>
            <mat-selection-list class="image-list" *ngIf="(controlImages && controlImages.length) else noControlImages" [formControl]="controlCultivarImagesControl" (selectionChange)="handleImageSelection($event.option)">
                <mat-list-option class="image-selector" *ngFor="let image of controlImages" [value]="image.key">
                    <pv-image-ref
                        [fileRef]="image.fileRef"
                        transformId="thumb_72">
                    </pv-image-ref>
                </mat-list-option>
            </mat-selection-list>

            <ng-template #noControlImages>
                <p class="text-muted">No images found for control cultivar.</p>
            </ng-template>
        </div>
    </div>

    <mat-dialog-actions align="end">
        <button mat-stroked-button
            color="warn"
            (click)="clearDisconnectedImages()"
            matTooltip="Remove any unselectable images">
                Clear Disconnected Images
        </button>
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-flat-button color="primary" (click)="attempt()">Save</button>
    </mat-dialog-actions>

</ng-container>
