import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filter, FilterQueryMode, Sample, SampleService } from '@core/data';
import { Snackbar } from '@core/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OrganizationGroupSampleFormDialogData {
    orgKey: string;
    groupKey: string;
    groupType: string;
}

export interface OrganizationGroupSampleFormDialogResult {
    sampleKey: string;
}

@Component({
    selector: 'pv-organization-group-sample-form-dialog',
    templateUrl: 'organization-group-sample-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationGroupSampleFormDialog implements OnInit {
    _sampleKeyControl: FormControl = new FormControl('', [Validators.required]);

    formGroup = new FormGroup({
        sampleKey: this._sampleKeyControl,
    });

    samples$: Observable<Sample[]>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrganizationGroupSampleFormDialogData,
        private _dialog: MatDialogRef<OrganizationGroupSampleFormDialog>,
        private _snackbar: Snackbar,
        private _sampleService: SampleService,
    ) { }

    ngOnInit() {
        let filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: this.data.orgKey,
                },
                {
                    key: 'groupKey',
                    mode: FilterQueryMode.NOT_EQUALS,
                    value: this.data.groupKey,
                },
                {
                    key: 'type',
                    mode: FilterQueryMode.EQUALS,
                    value: this.data.groupType,
                }
            ],
            sort: {
                column: 'label',
                order: 'asc',
            }
        };

        this.samples$ =
            this._sampleService.query(filter)
                .pipe(
                    map(result => result.data)
                );
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.valid) this.submit();
        else this._snackbar.error("Check your input and try again.");
    }

    submit() {
        this._dialog.close(this.formGroup.value);
    }

    cancel() {
        this._dialog.close();
    }
}
