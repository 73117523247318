import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { MapsApiLoader } from "./maps-api-loader";
import { LatLng } from "./types";


declare var google: any;

/**
 *
 *
 * See https://developers.google.com/maps/documentation/javascript/geocoding
 */
@Injectable()
export class GeocoderService {

    _ready$ = new BehaviorSubject<boolean>(false);
    _geocoder: any;

    constructor(
        private _mapsApiLoader: MapsApiLoader,
    ){
        this._mapsApiLoader.load().then(
            () => {
                this._geocoder = new google.maps.Geocoder();
                this._ready$.next(true);
            }
        ).catch(
            (err) => {
                console.log("ERROR Loading Geocoder Service", err);
                this._ready$.error(err);
            }
        );

    }

    public requestGeocode(request: GeocodeRequest): Observable<GeocodeResponse[]> {
        return this._ready$.pipe(
                filter(ready => ready),
                switchMap(ready => {
                    return this.makeGeocodeRequest(request);
                })
            );
    }

    private makeGeocodeRequest(request: GeocodeRequest): Observable<GeocodeResponse[]>{

        return Observable.create(observer => {

            this._geocoder.geocode(request, (result: GeocodeResponse[], status) => {

                if (status == 'OK' || status == 'ZERO_RESULTS') {

                    observer.next(this.serializeResponse(result));
                    observer.complete();
                }else{
                    console.warn("GeocoderService: geocode request failed with %s and result %o", status, result);
                    observer.error(status);
                }

            });

            return () => {
            };
        });
    }

    private serializeResponse(results: any): GeocodeResponse{

        return results.map(result => {
            if(result) {
                return {
                    ...result,
                    geometry: {
                        location_type: result.geometry.location_type,
                        location: {
                            lat: result.geometry.location.lat(),
                            lng: result.geometry.location.lng()
                        }
                    }
                }
            }
        });
    }
}

export interface GeocodeRequest {

    // The street address that you want to geocode, in the format
    // used by the national postal service of the country concerned.
    address?: string;

    // The LatLng (or LatLngLiteral) for which you wish to obtain the closest,
    // human-readable address. The geocoder performs a reverse geocode.
    location?: LatLng;

    // The place ID of the place for which you wish to obtain the closest, human-readable address.
    placeId?: string;


    // A components filter with elements separated by a pipe (|).
    // `postal_code` matches postal_code and postal_code_prefix.
    // `country matches` a country name or a two letter ISO 3166-1 country code.
    componentRestrictions?: string[];

    // The bounding box of the viewport within which to bias geocode results more prominently.
    bounds?: any;

    // The language in which to return results.
    language?: string;

    // The region code, specified as a ccTLD ("top-level domain") two-character value.
    region?: string;
}

export interface GeocodeResponse {

    types: string[];
    formatted_address: string;

    address_components: {
        short_name: string;
        long_name: string;
        postcode_localities: string[];
        types: string[];
    }[];
    partial_match: boolean;
    place_id: string;
    postcode_localities: string[];
    geometry: {
        location: LatLng;
        location_type: string;
        // viewport: any;
        // bounds: any;
    }
}

// Response example:

// {
//     "results" : [
//        {
//           "address_components" : [
//              {
//                 "long_name" : "High Street",
//                 "short_name" : "High St",
//                 "types" : [ "route" ]
//              },
//              {
//                 "long_name" : "Hastings",
//                 "short_name" : "Hastings",
//                 "types" : [ "postal_town" ]
//              },
//              {
//                 "long_name" : "East Sussex",
//                 "short_name" : "East Sussex",
//                 "types" : [ "administrative_area_level_2", "political" ]
//              },
//              {
//                 "long_name" : "England",
//                 "short_name" : "England",
//                 "types" : [ "administrative_area_level_1", "political" ]
//              },
//              {
//                 "long_name" : "United Kingdom",
//                 "short_name" : "GB",
//                 "types" : [ "country", "political" ]
//              },
//              {
//                 "long_name" : "TN34 3EY",
//                 "short_name" : "TN34 3EY",
//                 "types" : [ "postal_code" ]
//              }
//           ],
//           "formatted_address" : "High St, Hastings TN34 3EY, UK",
//           "geometry" : {
//              "bounds" : {
//                 "northeast" : {
//                    "lat" : 50.8601041,
//                    "lng" : 0.5957329
//                 },
//                 "southwest" : {
//                    "lat" : 50.8559061,
//                    "lng" : 0.5906163
//                 }
//              },
//              "location" : {
//                 "lat" : 50.85830319999999,
//                 "lng" : 0.5924594
//              },
//              "location_type" : "GEOMETRIC_CENTER",
//              "viewport" : {
//                 "northeast" : {
//                    "lat" : 50.8601041,
//                    "lng" : 0.5957329
//                 },
//                 "southwest" : {
//                    "lat" : 50.8559061,
//                    "lng" : 0.5906163
//                 }
//              }
//           },
//           "partial_match" : true,
//           "place_id" : "ChIJ-Ws929sa30cRKgsMNVkPyws",
//           "types" : [ "route" ]
//        }
//     ],
//     "status" : "OK"
//  }