<ng-container>
    <div class="import-upload-file-dropbox"
        (drop)="onDrop($event)"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        [class.dragging]="isDragging">
        <!-- *ngIf="status === 'OK'" -->

        <div class="import-upload-drop-request">
            <p>Drag and drop files here to upload</p>
            <label for="import_upload_file_input">Browse Files</label>
            <input id="import_upload_file_input" (change)="fileSelected($event.target.files[0])" type="file">
            <div class="import-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>
        </div>
    </div>

    <div class="import-upload-file" *ngIf="uploadedFile as file">
        {{file.name}}
    </div>
</ng-container>