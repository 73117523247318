import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { IndexSnapshot } from "../../types/EvaluationLibrary/index-snapshot";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class IndexSnapshotCreated {
    static readonly type = "[IndexSnapshotService] Snapshot Created";
    constructor(public snapshot: IndexSnapshot) {}
}

export class IndexSnapshotUpdated {
    static readonly type = "[IndexSnapshotService] Snapshot Updated";
    constructor(public snapshot: IndexSnapshot) {}
}

export class IndexSnapshotDeleted {
    static readonly type = "[IndexSnapshotService] Snapshot Deleted";
    constructor(public snapshot: IndexSnapshot) {}
}

@Injectable()
export class IndexSnapshotService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) {}

    get(key: string, detail?: DetailRequest): Observable<IndexSnapshot> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<IndexSnapshot>(`${this._baseUri}/evaluation-library/index-snapshots/${key}`, {params});
    }

    create(model: Partial<IndexSnapshot>) {
        return this._http.post<IndexSnapshot>(`${this._baseUri}/evaluation-library/index-snapshots`, model)
            .pipe(tap(snapshot => this._store.dispatch(new IndexSnapshotCreated(snapshot))));
    }

    update(key: string, model: Partial<IndexSnapshot>) {
        return this._http.put<IndexSnapshot>(`${this._baseUri}/evaluation-library/index-snapshots/${key}`, model)
            .pipe(tap(snapshot => this._store.dispatch(new IndexSnapshotUpdated(snapshot))));
    }

    delete(key: string) {
        return this._http.delete<IndexSnapshot>(`${this._baseUri}/evaluation-library/index-snapshots/${key}`)
            .pipe(tap(snapshot => this._store.dispatch(new IndexSnapshotDeleted(snapshot))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<IndexSnapshot>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<IndexSnapshot>>(`${this._baseUri}/evaluation-library/index-snapshots`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<IndexSnapshot>>(`${this._baseUri}/evaluation-library/index-snapshots/search`, {params: <any>search});
    }
}