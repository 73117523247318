import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Site } from '@core/data';


@Component({
    selector: '[pvSiteLine]',
    template: `
        <mat-icon>place</mat-icon>
        <span class="text" [innerHtml]="_html"></span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-site-line pv-model-line'
    }
})
export class SiteLineComponent {

    @Input('pvSiteLine')
    set site(site: Site) {
        if (site) {
            this._html = this._formatter.describeSite(site, true);
            this._title = this._formatter.describeSite(site, false);
        } else {
            this._html = '';
            this._title = '';
        }
    }

    @HostBinding('attr.title')
    _title: string;
    _html: string;

    constructor(private _formatter: SampleFormatter) { }

}