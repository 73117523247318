import { Protocol } from "../protocols/_types";


/**
 * An Index describes characteristiscs
 * taken into account to determine a given score.
 *
 */
export interface Index {
    id: string;
    label: string;
    chars: {
        charId: string;
        weight: number;
        rescale?: IndexRescaleStrategy;
    }[];
    description: string;
}


/**
 *
 * Determines how an interval characteristic is normalized [0,1]
 * before weight is applied and index score is determined.
 *
 *
 * See https://en.wikipedia.org/wiki/Feature_scaling
 *
 */
export enum IndexRescaleStrategy {

    /**
     *
     * Higher value equals a higher score.
     *
     * normal(x) = (x - char(min)) / (char(max) - char(min))
     *
     */
    POSITIVE = "POSITIVE",


    /**
     *
     * Higher value equals a lower score.
     *
     * normal(x) = 1 - (x - char(min)) / (char(max) - char(min))
     *
     */
    NEGATIVE = "NEGATIVE",

}
