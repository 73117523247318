<div class="file-control-wrapper">
    <mat-label class="file-control-label">{{ label }}</mat-label>
    <div class="mat-form-field-wrapper">
        <div class="file-input-wrapper">
            <input id="file-input" type="file" (change)="fileInputChanged($event)" />
            <label for="file-input">
                <div class="custom-file-input">
                    <span [class.input-text]="!displayName" class="input-size">{{ displayName ? displayName : 'Upload File' }}</span>
                    <mat-icon>folder</mat-icon>
                </div>
            </label>
        </div>

        <div class="file-info">
            <mat-hint class="file-hint">Upload file ({{validFiles}}). Max size {{maxSize / 1000000}}MB.</mat-hint>
            <div class="document-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>
        </div>
    </div>
</div>