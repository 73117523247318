<ng-container>

    <!-- Dialog Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Images</h2>
    </div>

    <div mat-dialog-content>
    
        <form
            [formGroup]="formGroup"
            (ngSubmit)="attempt()"
            autocomplete="disabled"
            #imageForm="ngForm"
            novalidate>

            <div class="image-form-container">

                <div class="image-file-dropbox"
                    (drop)="onDrop($event)"
                    (dragover)="onDragOver($event)"
                    (dragleave)="onDragLeave($event)"
                    [class.dragging]="isDragging">

                    <div class="image-drop-request">

                        <p>Drag and drop image files here to upload.</p>
                        <label for="image_file_input">Browse Files</label>
                        <input id="image_file_input" type="file" (change)="fileInputChanged($event)">
                        <div class="document-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>
                    </div>

                    <div class="image-drop-results" *ngIf="uploads$ | async as uploads">

                        <div class="document-upload" *ngFor="let upload of uploads; trackBy: trackUpload">

                            <div [ngSwitch]="upload.status">

                                {{ upload.loaded | formatBytes }}/{{ upload.total | formatBytes }}
                                <span *ngSwitchCase="'LOADING'">Uploading...</span>
                                <span *ngSwitchCase="'COMPLETE'" class="text-primary">Done</span>
                                <span *ngSwitchDefault class="text-warn">{{ upload.status | descStatusTitle }}</span>
                            </div>

                            <mat-progress-bar mode="determinate" [value]="upload.loaded / upload.total * 100"></mat-progress-bar>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Uploaded Images -->
            <h4 class="image-section-heading">Uploaded Images</h4>

            <mat-selection-list class="image-container"
                                *ngIf="formArray.controls.length; else noUploadImages"
                                [formControl]="uploadedImagesControl"
                                (selectionChange)="handleImageSelection($event.option)"
                                [compareWith]="compareWithFn">
                
                <!-- Image Upload Form -->
                <div class="image-form"
                    *ngFor="let imageGroup of formArray.controls">

                    <pv-image-ref class="image-form-file"
                        *ngIf="imageGroup.value.fileRef as ref"
                        [fileRef]="ref"
                        [transformId]="c_fit">
                    </pv-image-ref>

                    <!-- Actions -->
                    <div class="actions">

                        <!-- Checkbox -->
                        <mat-list-option [value]="imageGroup.value"></mat-list-option>

                        <!-- Download -->
                        <button type="button"
                                class="image-download-button"
                                mat-icon-button
                                (click)="downloadImage(imageGroup.value.fileRef, imageGroup.value.name)"
                                matTooltip="Download Image">
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-selection-list>

            <!-- No Images -->
            <ng-template #noUploadImages>
                <span class="text-muted">
                    No Images to display
                </span>
            </ng-template>
            
            <div class="image-section">

                
                <div class="image-section-container">
                    
                    <!-- Primary Images -->
                    <h4 class="image-section-heading">Primary Cultivar Images</h4>
    
                    <mat-selection-list class="image-container"
                                        *ngIf="(images$ | async)?.length; else noPrimaryImages"
                                        [formControl]="includeImagesControl"
                                        (selectionChange)="handleImageSelection($event.option)">
                        
                        <div class="image-form" *ngFor="let image of images$ | async;">
                            
                            <!-- Primary Image -->
    
                            <pv-image-ref class="image-form-file"
                                *ngIf="image.fileRef as ref"
                                [fileRef]="ref"
                                [transformId]="c_fit">
                            </pv-image-ref>
                            
                            <!-- Actions -->
                            <div class="actions">
                                
                                <!-- Date -->
                                <span class="image-date">{{ image.takenAt | date }}</span>
    
                                <!-- Download -->
                                <button type="button"
                                    class="image-download-button"
                                    mat-icon-button
                                    (click)="downloadImage(image.fileRef, image.name)"
                                    matTooltip="Download Image">
    
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
    
                                <!-- Checkbox -->
                                <mat-list-option class="image-checkbox" [value]="image"></mat-list-option>
                                
                            </div>
                        </div>
                    </mat-selection-list>
    
                    <!-- No Images -->
                    <ng-template #noPrimaryImages>
                        <span class="text-muted">
                            No Images for primary cultivar
                        </span>
                    </ng-template>
                </div>

                <div class="image-section-container">

                    <!-- Control Images -->
                    <h4 class="image-section-heading">Control Cultivar Images</h4>
    
                    <mat-selection-list class="image-container"
                                        *ngIf="controlImages$ | async as images; else noControlImages"
                                        [formControl]="controlCultivarImagesControl"
                                        (selectionChange)="handleImageSelection($event.option)">
                        
                        <div class="image-form" *ngFor="let image of images">
                            
                            <!-- Image -->
                            <pv-image-ref class="image-form-file"
                                *ngIf="image.fileRef as ref"
                                [fileRef]="ref"
                                [width]="250"
                                [quality]="150"
                                [mode]="crop">
                            </pv-image-ref>
    
                            <!-- Actions -->
                            <div class="actions">
    
                                <!-- Date -->
                                <span class="image-date">{{ image.takenAt | date }}</span>
    
                                <!-- Download -->
                                <button type="button"
                                        class="image-download-button"
                                        mat-icon-button
                                        (click)="downloadImage(image.fileRef, image.name)"
                                        matTooltip="Download Image">
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
    
                                <!-- Checkbox -->
                                <mat-list-option class="image-checkbox" [value]="image"></mat-list-option>
                            </div>
                        </div>
                    </mat-selection-list>
    
                    <!-- No Control Images -->
                    <ng-template #noControlImages>
                        <span class="text-muted">
                            No Images for control cultivar
                        </span>
                    </ng-template>
                </div>

            </div>
        </form>
    </div>

    <!-- Container Cancel/Save -->
    <pv-form-actions
        mat-dialog-actions
        (cancel)="cancel(false)"
        [form]="imageForm">
    </pv-form-actions>


</ng-container>