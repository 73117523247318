import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddGroupSample, InitGroups } from '@app/auth/components/organization-detail/groups.state';
import { Filter, FilterQueryMode, GroupService, OrganizationGroup, Sample, SampleService } from '@core/data';
import { Snackbar } from '@core/material';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SampleIndexGroupFormDialogData {
    orgKey: string;
    groupType: string;
    samples: string[];
}

export interface SampleIndexGroupFormDialogResult {
    sampleKey: string;
}

@Component({
    selector: 'pv-sample-index-group-form-dialog',
    templateUrl: 'sample-index-group-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SampleIndexGroupFormDialog implements OnInit {
    _groupKeyControl: FormControl = new FormControl('', [Validators.required]);

    formGroup = new FormGroup({
        groupKey: this._groupKeyControl,
    });

    groups$: Observable<OrganizationGroup[]>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SampleIndexGroupFormDialogData,
        private _dialog: MatDialogRef<SampleIndexGroupFormDialog>,
        private _snackbar: Snackbar,
        private _groupService: GroupService,
        private _store: Store,
    ) { }

    ngOnInit() {
        let filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: this.data.orgKey,
                },
                {
                    key: 'type',
                    mode: FilterQueryMode.EQUALS,
                    value: this.data.groupType,
                }
            ],
            sort: {
                column: 'label',
                order: 'asc',
            }
        };

        this.groups$ =
            this._groupService.query(filter)
                .pipe(
                    map(result => result.data)
                );

        this._store.dispatch(new InitGroups(this.data.orgKey));
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.valid) this.submit();
        else this._snackbar.error("Check your input and try again.");
    }

    submit() {
        let data: AddGroupSample[] = [];

        for (let i: number = 0; i < this.data.samples.length; i++) {
            data.push(new AddGroupSample(this._groupKeyControl.value, this.data.samples[i]));
        }

        this._store.dispatch(data).subscribe(res => {
            if (res) {
                this._snackbar.message("Samples Added");
                this._dialog.close(this.formGroup.value);
            }
        });
    }

    cancel() {
        this._dialog.close();
    }
}
