import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'descComment'
})
export class UserCommentPipe implements PipeTransform {
    constructor() {}

    transform(comment: string): string {
        if (!comment) return 'No Comment';
        return comment;
    }
}