import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Library } from '@library';
import { LIBRARY } from '../library';

@Pipe({
    name: 'labelChar'
})
export class LabelCharPipe implements PipeTransform {

    constructor(@Inject(LIBRARY) private _library: Library){}

    transform(charId:string): any {

        let char = this._library.chars.get(charId);

        if(char){
            return char.label;
        }

        return '[' + charId + ']';
    }
}
