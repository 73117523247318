import { Pipe, PipeTransform } from '@angular/core';
import { TableReportColumn } from '@core/data';
import { ReportCompilerService } from '../report-compiler.service';

@Pipe({
  name: 'descTableReport'
})
export class DescribeTableReportPipe implements PipeTransform {

    constructor(private _compiler: ReportCompilerService) {}

    transform(input: TableReportColumn[]): string {
        return (input.length) ? this._compiler.compileTableColumns(input).map(item => {
            return item.itemDef && item.itemDef.label || 'unknown';
        }).join(', ') : 'None';
    }
}
