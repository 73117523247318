import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { InitUpdateSamplePrimaryImageForm, SamplePrimaryImageFormState, SubmitSamplePrimaryImageForm } from './sample-primary-image-form.state';
import { Observable, Subject } from 'rxjs';
import { SampleFormStateModel } from './sample-form.state';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Status } from '@core/data';

export interface SamplePrimaryImageFormDialogData {
    key: string; // sample key
}

@Component({
    selector: 'pv-sample-primary-image-form-dialog',
    templateUrl: 'sample-primary-image-form.dialog.html',
    styleUrls: ['sample-primary-image-form.dialog.scss']
})
export class SamplePrimaryImageFormDialog implements OnInit, OnDestroy {

    @Select(SamplePrimaryImageFormState)
    state$: Observable<SampleFormStateModel>;

    primaryImageKeyControl = new FormControl(null);

    private _destroy$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: SamplePrimaryImageFormDialogData,
        private _store: Store,
        private _ref: MatDialogRef<SamplePrimaryImageFormDialog>
    ) { }

    ngOnInit() {

        this._store.dispatch(new InitUpdateSamplePrimaryImageForm(this._data.key));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if (state.status === Status.COMPLETE) {
                    this._ref.close(state.data);
                } else if (state.status !== Status.LOADING) {
                    this.primaryImageKeyControl.setValue(state.data.primaryImageKey);
                    this.primaryImageKeyControl.enable();
                } else {
                    this.primaryImageKeyControl.disable();
                }
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt() {
        this._store.dispatch(new SubmitSamplePrimaryImageForm(this.primaryImageKeyControl.value));
    }

    removeImage() {
        this._store.dispatch(new SubmitSamplePrimaryImageForm(null));
    }

    cancel() {
        this._ref.close();
    }
}