import { Injectable, Inject, InjectionToken } from '@angular/core';
import { WindowRef } from './window-ref';
import { encodeQueryParams } from '@core/utils';

export const WEB_BASE_URI = new InjectionToken<string>('SERVER_HOST_BASE_URI');
export const TASTINGS_BASE_URI = new InjectionToken<string>('TASTINGS_BASE_URI');

@Injectable()
export class HostNavigator{

    constructor(
        @Inject(WEB_BASE_URI) private _serverBaseUri: string,
        @Inject(TASTINGS_BASE_URI) private _tastingsBaseUri: string,
        private _windowRef: WindowRef
    ){}

    openWindow(path: string, queryParams?: {[key: string]: any}){
        this._windowRef.getNativeWindow()
            .open(this.hostUrl(path, queryParams), '_blank');
    }

    redirect(path: string, queryParams?: {[key: string]: any}){
        let url = this.hostUrl(path, queryParams);
        this._windowRef.getNativeWindow().location.replace(url);
    }

    hostUrl(path: string, queryParams?: {[key: string]: any}){

        let query = queryParams ? '?' + encodeQueryParams(queryParams) : '';

        return `${this._serverBaseUri}/${path}${query}`;
    }

    openTastings(path: string) {
        this._windowRef.getNativeWindow()
        .open(`${this._tastingsBaseUri}/${path}`, '_blank');
    }

}