import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { BaseIndex } from "../../types/EvaluationLibrary/base-index";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class BaseTastingsIndexCreated {
    static readonly type = "[BaseIndexService] Base Index Created";
    constructor(public index: BaseIndex) {}
}

export class BaseTastingsIndexUpdated {
    static readonly type = "[BaseIndexService] Base Index Updated";
    constructor(public index: BaseIndex) {}
}

export class BaseTastingsIndexDeleted {
    static readonly type = "[BaseIndexService] Base Index Deleted";
    constructor(public index: BaseIndex) {}
}

@Injectable()
export class BaseIndexService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) {}

    get(key: string, detail?: DetailRequest): Observable<BaseIndex> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<BaseIndex>(`${this._baseUri}/evaluation-library/base-index/${key}`, {params});
    }

    create(model: Partial<BaseIndex>) {
        return this._http.post<BaseIndex>(`${this._baseUri}/evaluation-library/base-index`, model)
            .pipe(tap(index => this._store.dispatch(new BaseTastingsIndexCreated(index))));
    }

    update(key: string, model: Partial<BaseIndex>) {
        return this._http.put<BaseIndex>(`${this._baseUri}/evaluation-library/base-index/${key}`, model)
            .pipe(tap(index => this._store.dispatch(new BaseTastingsIndexUpdated(index))));
    }

    delete(key: string) {
        return this._http.delete<BaseIndex>(`${this._baseUri}/evaluation-library/base-index/${key}`)
            .pipe(tap(index => this._store.dispatch(new BaseTastingsIndexDeleted(index))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<BaseIndex>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<BaseIndex>>(`${this._baseUri}/evaluation-library/base-index`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<BaseIndex>>(`${this._baseUri}/evaluation-library/base-index/search`, {params: <any>search});
    }
}