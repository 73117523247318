import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileAttachmentUpdateRequest, ReportDocument, ReportRemark, ReportService, Status, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

export interface FileAttachmentFormStateModel {
    key: string;
    sectionId: string;
    status: Status;
    data: ReportRemark;
    uploads: PendingReportUploadModel[];
}

export interface PendingReportUploadModel {
    id: number;
    loaded: number;
    total: number;
    status: Status;
    name: string;
    data: ReportDocument,
}

export class InitFileAttachmentForm {
    static readonly type = "[FileAttachmentForm] Init";
    constructor(public reportKey: string, public sectionId: string) {}
}

export class SubmitFileAttachmentForm {
    static readonly type = "[FileAttachmentForm] Submit";
    constructor(public payload: FileAttachmentUpdateRequest) {}
}

export class UploadDocument {
    static readonly type = "[FileAttachmentForm] Upload";
    constructor(public file: File, public sectionId: string) {}
}

@State({
    name: 'file_attachment_form',
    defaults: {
        key: null,
        sectionId: null,
        status: Status.UNINITIALIZED,
        data: null,
        uploads: [],
    }
})
@Injectable()
export class FileAttachmentFormState {
    @Selector()
    static data(state: FileAttachmentFormStateModel) { return state.data; }

    @Selector()
    static uploads(state: FileAttachmentFormStateModel) { return state.uploads; }

    constructor(
        private _reportService: ReportService
    ) {}

    @Action(InitFileAttachmentForm)
    initFileAttachmentForm(ctx: StateContext<FileAttachmentFormStateModel>, action: InitFileAttachmentForm) {
        ctx.setState({
            key: action.reportKey,
            sectionId: action.sectionId,
            status: Status.LOADING,
            data: null,
            uploads: []
        });

        return this._reportService
            .getRemark(action.reportKey, action.sectionId, {related: ['documents']})
            .pipe(
                tap(
                    result => {
                        if (!Array.isArray(result)) {
                            ctx.patchState({
                                data: result,
                                status: Status.OK
                            });
                        } else {
                            ctx.patchState({
                                data: {
                                    sectionId: action.sectionId,
                                    text: '',
                                    documents: [],
                                },
                                status: Status.OK
                            });
                        }
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(SubmitFileAttachmentForm)
    submitFileAttachmentForm(ctx: StateContext<FileAttachmentFormStateModel>, action: SubmitFileAttachmentForm) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._reportService
            .updateRemark(state.key, action.payload)
            .pipe(
                tap(
                    result => {
                        const state = ctx.getState();
                        ctx.patchState({
                            status: Status.COMPLETE,
                            data: {
                                ...state.data,
                                ...result
                            }
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(UploadDocument)
    uploadDocument(ctx: StateContext<FileAttachmentFormStateModel>, action: UploadDocument) {
        const state = ctx.getState();

        let upload: PendingReportUploadModel = {
            id: Math.round(Math.random() * 10000000),
            loaded: 0,
            total: action.file.size,
            status: Status.LOADING,
            name: action.file.name,
            data: null,
        };

        ctx.patchState({
            uploads: [...state.uploads, upload],
        });

        return this._reportService.uploadSectionDocument(state.key, action.sectionId, action.file)
            .pipe(
                tap(
                    event => {
                        const state = ctx.getState();
                        console.info('FileAttachmentFormState: Upload event ', event);

                        if (state.status !== Status.OK) return;

                        //upload progress
                        if (event.type === HttpEventType.UploadProgress) {
                            upload = {
                                ...upload,
                                loaded: event.loaded,
                                total: event.total,
                            }

                            this.patchUpload(ctx, upload);
                        } else if (event instanceof HttpResponse) {
                            upload = {
                                ...upload,
                                status: Status.COMPLETE,
                                data: event.body
                            }

                            this.patchUpload(ctx, upload);
                        }
                    },
                    error => {
                        console.warn('FileAttachmentFormState: Upload error ', error);

                        upload = {
                            ...upload,
                            status: translateCommonErrorStatus(error)
                        }

                        this.patchUpload(ctx, upload);
                    }
                ), catchError(e => of(null))
            );
    }

    private patchUpload(ctx: StateContext<FileAttachmentFormStateModel>, upload: PendingReportUploadModel){
        const state = ctx.getState();

        let uploads = state.uploads.map(existing => {
            if(existing.id === upload.id) return upload;
            return existing;
        });

        ctx.patchState({ uploads });

    }
}