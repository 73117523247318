<h2 mat-dialog-title>Edit Remark</h2>
<div mat-dialog-content>

    <div class="markdown-input">
        <textarea matInput [formControl]="remarkControl"
                  placeholder="Enter remarks... (supports markdown)"></textarea>
    </div>

    <div class="markdown-preview pv-report-markdown" *ngIf="showMarkdown">
        <pv-markdown [markdown]="remarkControl.value"></pv-markdown>
    </div>

</div>
<mat-dialog-actions align="end">
    <mat-slide-toggle [(ngModel)]="showMarkdown" class="mlr-12">Show Preview</mat-slide-toggle>
    <a mat-button class="link-button" href="https://commonmark.org/help/" target="_blank">What is
        markdown?</a>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="submit()">Ok</button>
</mat-dialog-actions>
