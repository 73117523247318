<pv-view toolbarTitle="Weather"
         *ngIf="state$ | async as index"
         [ngSwitch]="index.status">

    <div pv-view-tools>
        <button mat-icon-button
                (click)="add(index.orgKey)"
                matTooltip="Add Station">
            <mat-icon>add</mat-icon>
        </button>

        <button mat-icon-button
                (click)="reloadIndex()"
                matTooltip="Refresh List">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>

    <div pv-view-body
         *ngSwitchCase="'LOADING'">
        <pv-view-loader message="Loading Weather Stations"></pv-view-loader>
    </div>

    <div pv-view-body
         *ngSwitchCase="'EMPTY'">
        <pv-view-message heading="Add Weather Station"
                         message="No weather stations have been created for this organisation."
                         icon="satellite">
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="add(index.orgKey)">Add Weather
                Station</button>
        </pv-view-message>
    </div>


    <!-- SIDEBAR -->
    <div pv-view-sidebar
         *ngSwitchCase="'OK'">

        <div class="pv-form single-column p-12">
            <mat-form-field appearance="outline"
                            class="station-select">
                <mat-label>
                    <mat-icon>satellite</mat-icon> Station
                </mat-label>
                <mat-select (selectionChange)="selectStation($event.value)"
                            [value]="selected$ | async"
                            placeholder="Select Station">
                    <mat-option *ngFor="let station of index.list"
                                [value]="station">
                        {{ station.name + (station.type == 'tinyTag' ? ' | Tinytag' : ' | Sigfox' ) }}
                    </mat-option>
                </mat-select>
                <button type="button"
                        matSuffix
                        mat-icon-button
                        matTooltip="Add Station">
                    <mat-icon matListIcon
                              (click)="add(index.orgKey, $event)">add</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <ng-container *ngIf="selected$ | async as selected">

            <div class="station-detail-map"
                 *ngIf="getLatLng(selected) as position">
                <pv-map class="station-detail-map"
                        [center]="position"
                        zoom="5"
                        [disableDefaultUI]="true">
                    <pv-map-marker [position]="position"></pv-map-marker>
                </pv-map>
            </div>

            <mat-action-list class="station-detail-actions"
                             dense>
                <h3 matSubheader>{{ selected.name }}</h3>
                <mat-divider></mat-divider>
                <mat-list-item (click)="edit(selected)">
                    <mat-icon matListIcon>edit</mat-icon>
                    <span matLine>Edit Name or Location</span>
                </mat-list-item>
                <mat-list-item (click)="delete(selected)">
                    <mat-icon matListIcon>delete</mat-icon>
                    <span matLine>Delete</span>
                </mat-list-item>
                <!-- <mat-list-item *ngIf="selected.type=='sigFox'" (click)='syncSigFoxData(selected)'>
                    <mat-icon matListIcon>cloud_sync</mat-icon>
                    <span matLine>Sync Data (from cloud)</span>
                </mat-list-item> -->
                <mat-list-item *ngIf="selected.type=='tinyTag'" (click)='openFileUploadDialog(selected)'>
                    <mat-icon matListIcon>cloud_upload</mat-icon>
                    <span matLine>Upload Data (TinyTag CSV)</span>
                </mat-list-item>
                <mat-list-item (click)="downloadCSV(index.reportData)" *ngIf="index.reportData">
                    <mat-icon matListIcon>cloud_download</mat-icon>
                    <span matLine>Download Report Data CSV</span>
                </mat-list-item>
            </mat-action-list>

            <mat-list dense
                      *ngIf="(selected.sensorSummaries)?.length">
                <h3 matSubheader>Available Sensor Data</h3>
                <mat-divider></mat-divider>
                <mat-list-item *ngFor="let summary of selected.sensorSummaries">
                    <span matLine>{{ summary.sensorId | labelSensor }}</span>
                    <span matLine>
                        {{ summary.firstRecordedAt | date:'mediumDate' }} -
                        {{ summary.lastRecordedAt | date:'mediumDate' }}
                    </span>
                </mat-list-item>
                <mat-divider></mat-divider>
            </mat-list>

        </ng-container>


    </div>
    <!-- SIDEBAR END -->

    <!-- MAIN BODY -->
    <div pv-view-body
         *ngSwitchCase="'OK'">

        <ng-container *ngIf="selected$ | async as selected else noneSelected">

            <div class="station-detail"
                 *ngIf="(selected.sensorSummaries)?.length > 0 else selected.type == 'tinyTag' ? noDataTinyTag : noDataSigFox">

                <form [formGroup]="reportOptionsGroup"
                      class="station-report-options">

                    <mat-form-field appearance="outline"
                                    floatLabel="always">
                        <mat-label>Select Report</mat-label>
                        <mat-select formControlName="reportId"
                                    #sensorOption>
                            <mat-option *ngFor="let report of index.availableReports"
                                        [value]="report.id">
                                {{ report.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline"
                                    floatLabel="always">
                        <mat-label>Start Date</mat-label>
                        <input matInput
                               [matDatepicker]="startDatePicker"
                               formControlName="startDate"
                               [max]="getMaxDate(endDateControl.value)"
                               readonly
                               required
                               (focus)="startDatePicker.open()">
                        <mat-datepicker-toggle matSuffix
                                               [for]="startDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>


                    <mat-form-field appearance="outline"
                                    floatLabel="always">
                        <mat-label>End Date</mat-label>
                        <input matInput
                               [matDatepicker]="endDatePicker"
                               formControlName="endDate"
                               [min]="getMinDate(startDateControl.value)"
                               readonly
                               required
                               (focus)="endDatePicker.open()">
                        <mat-datepicker-toggle matSuffix
                                               [for]="endDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline"
                                    floatLabel="always">
                        <mat-label>Select Interval</mat-label>
                        <mat-select formControlName="interval">
                            <mat-option value="hourly">Hourly</mat-option>
                            <mat-option value="daily">Daily</mat-option>
                            <mat-option value="monthly">Monthly</mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>


                <div class="station-report"
                     [ngSwitch]="index.reportStatus">

                    <mat-tab-group *ngSwitchCase="'OK'">

                        <mat-tab label="Chart">
                            <ng-template matTabContent>
                                <pv-chart class="station-report-chart"
                                            *ngIf="chart$ | async as chart"
                                            [type]="chart.type"
                                            [data]="chart.data"
                                            [options]="chart.options">
                                </pv-chart>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="Data">
                            <ng-template matTabContent>
                                <table class="pv-table" *ngIf="getReportOptions(index.reportData.reportId) as reportOptions">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th *ngFor="let attr of reportOptions.attributes">{{ attr.label }} [{{ attr.unit }}]</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of index.reportData.data">
                                            <td>{{ selected.type === 'sigFox' ? (getSigFoxDate(row.ts) | date:'medium') : (row.ts | date:'medium') }}</td>
                                            <td *ngFor="let attr of reportOptions.attributes">
                                                {{ row[attr.id] !== undefined && row[attr.id] !== null ? row[attr.id] : 0}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-template>
                        </mat-tab>

                    </mat-tab-group>


                    <pv-view-loader *ngSwitchCase="'LOADING'"
                                    message="Loading Weather Report">
                    </pv-view-loader>

                    <pv-view-message *ngSwitchCase="'INVALID'"
                                     heading="Invalid Report Options"
                                     message="The options you selected are invalid for this Weather Station."
                                     icon="error">
                    </pv-view-message>

                    <pv-view-message *ngSwitchCase="'EMPTY'"
                                     heading="Empty Report"
                                     message="No data available for the dates and report selected."
                                     icon="error">
                    </pv-view-message>

                    <pv-view-message *ngSwitchDefault
                                     [heading]="index.reportStatus | descStatusTitle"
                                     [message]="index.reportStatus | descStatusMessage:'Weather Report'"
                                     icon="error">
                        <button mat-raised-button
                                color="primary"
                                pvMessageAction
                                (click)="reloadIndex()">Reload</button>
                    </pv-view-message>
                </div>

            </div>

            <ng-template #noDataTinyTag>
                <pv-view-message heading="Upload Data"
                                 message="You have not imported any data for this Weather Station."
                                 icon="cloud_upload">
                    <button mat-raised-button
                            pvMessageAction
                            color="primary"
                            (click)="openFileUploadDialog(selected)">Upload File</button>
                </pv-view-message>
            </ng-template>

            <ng-template #noDataSigFox>
                <pv-view-message heading="No Data"
                                 message="Data is recorded each 30 minutes and will become available within 30 minutes of device registration"
                                 icon="cloud_sync">
                    <!-- <button mat-raised-button
                            pvMessageAction
                            color="primary"
                            (click)="syncSigFoxData(selected)">Sync Data</button> -->
                </pv-view-message>
            </ng-template>

        </ng-container>

        <ng-template #noneSelected>
            <pv-view-message heading="Select a Weather Station"
                             message="Select a weather station to view detail."
                             icon="ballot">
            </pv-view-message>
        </ng-template>

    </div>
    <!-- MAIN BODY END -->

    <div pv-view-body
         *ngSwitchDefault>
        <pv-view-message [heading]="index.status | descStatusTitle"
                         [message]="index.status | descStatusMessage:'Weather Stations'"
                         icon="error">
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="reloadIndex()">Reload</button>
        </pv-view-message>
    </div>


</pv-view>
