import { Measurement } from './_types';

export const LIB_MEASURES_NUT_TREE: Measurement[] = [

    {
        id: "tree_trunk_dia",
        unit: "mm",
        label: "Trunk Diameter",
        tag: "DIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_dia_std",
        format: "####mm",
        min: 0,
        max: 1000,
        suggestMin: 1,
        suggestMax: 3000,
        subjectId: "tree",
    },
    {
        id: "skirt_height",
        unit: "m",
        label: "Skirt Height",
        tag: "SHEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_skirt_hei_std",
        format: "##0.00m",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "tree",
    },
  
];