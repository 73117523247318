import { State, Action, StateContext } from "@ngxs/store";
import { Status, ReportTemplate, ReportService, translateCommonErrorStatus } from '@core/data';
import { tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";


export interface ReportTemplatesStateModel {
    status: Status;
    orgKey: string;
    items: ReportTemplate[];
    data: Partial<ReportTemplate>;
}

const DEFAULTS: ReportTemplatesStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: null,
    items: [],
    data: null
};


export class InitReportTemplates {
    static readonly type = '[ReportTemplates] Init';
    constructor(public data: Partial<ReportTemplate>){}
}

export class SubmitReportTemplate {
    static readonly type = '[ReportTemplates] Submit';
    constructor(public data: Partial<ReportTemplate>){}
}

export class DeleteReportTemplate {
    static readonly type = '[ReportTemplates] Delete';
    constructor(public id: number){}
}

@State({
    name: 'report_templates',
    defaults: DEFAULTS
})
@Injectable()
export class ReportTemplatesState {

    constructor(
        private _reportService: ReportService
    ){}

    @Action(InitReportTemplates, {cancelUncompleted: true})
    initReportTemplates(ctx: StateContext<ReportTemplatesStateModel>, action: InitReportTemplates){

        let orgKey = action.data.ownerOrgKey;

        if(!orgKey){
            ctx.setState({
                ...DEFAULTS,
                status: Status.ERROR
            });
            console.warn('ReportTemplateState: No org key provided with new template.');
            return;
        }

        ctx.setState({
            ...DEFAULTS,
            status: Status.LOADING,
            data: action.data,
        });


        return this._reportService.listTemplates(orgKey)
                .pipe(tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            items: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                ));

    }


    @Action(SubmitReportTemplate, {cancelUncompleted: true})
    submitReportTemplate(ctx: StateContext<ReportTemplatesStateModel>, action: SubmitReportTemplate){


        const request = action.data.id ?
                            this._reportService.updateTemplate(action.data.id, action.data)
                            : this._reportService.createTemplate(action.data);

        return request.pipe(tap(
            result => {
                ctx.patchState({
                    status: Status.COMPLETE,
                    data: result,
                })
            },
            err => {
                ctx.patchState({
                    status: translateCommonErrorStatus(err),
                });
            }
        ));

    }


    @Action(DeleteReportTemplate, {cancelUncompleted: true})
    deleteReportTemplate(ctx: StateContext<ReportTemplatesStateModel>, action: DeleteReportTemplate){

        const request = this._reportService.deleteTemplate(action.id);

        return request.pipe(tap(
            result => {
                if(result) {
                    ctx.patchState({
                        status: Status.DELETED,
                    })
                 }
            },
            err => {
                ctx.patchState({
                    status: translateCommonErrorStatus(err),
                });
            }
        ));

    }


}