import { Component, Input } from "@angular/core";
import { Dialog, Snackbar } from '@core/material';
import { Store } from '@ngxs/store';
import { OrganizationGroup } from '@core/data';
import { RemoveGroupUser, AddGroupUser } from './groups.state';
import { OrganizationGroupUserFormDialogData, OrganizationGroupUserFormDialog, OrganizationGroupUserFormDialogResult } from './organization-group-user-form.dialog';

@Component({
    selector: 'pv-organization-group-user-list',
    templateUrl: 'organization-group-user-list.component.html',
    styles: [
        `
        :host {
            display: block;
            position: relative;
        }
    `
    ],
})
export class OrganizationGroupUserList {
    @Input()
    orgKey: string;

    @Input()
    group: OrganizationGroup;

    constructor(
        private _store: Store,
        private _dialogs: Dialog,
        private _snackbar: Snackbar
    ) { }

    addGroupUser(orgKey: string, groupKey: string) {
        const data: OrganizationGroupUserFormDialogData = {
            orgKey,
            groupKey,
        };
        this._dialogs.open(OrganizationGroupUserFormDialog, { data })
            .afterClosed()
            .subscribe((result: OrganizationGroupUserFormDialogResult) => {
                if (result) {

                    for (let i = 0; i < result.userKey.length; i++) {
                        this._store
                            .dispatch(new AddGroupUser(groupKey, result.userKey[i]))
                            .subscribe(_ => {
                                if (i === result.userKey.length - 1) {
                                    this._snackbar.info("Added "+result.userKey.length+" user(s) to group");
                                }
                            });
                    }

                }
            });
    }

    removeGroupUser(groupKey: string, userKey: string) {
        this._dialogs.confirm(
            'Remove User from Group',
            'Are you sure you want to remove this member from the group? Guest users will no longer be able to access any data associated with this group.',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new RemoveGroupUser(groupKey, userKey))
                    .subscribe(state => {
                        this._snackbar.info("User removed from group");
                    });
            }
        });
    }
}
