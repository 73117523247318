import { Pipe, PipeTransform } from '@angular/core';
import { TableReportColumn } from '@core/data';
import { ReportCompilerService } from '../report-compiler.service';

@Pipe({
  name: 'descTableReportColumn'
})
export class DescribeTableReportColumnPipe implements PipeTransform {

    constructor(private _compiler: ReportCompilerService) {}

    transform(input: TableReportColumn): string {
        if(input) {
            return (this._compiler.compileTableColumn(input)).itemDef && (this._compiler.compileTableColumn(input)).itemDef.label || 'unknown';   
        } else {
            return 'None';
        }
       
    }
}
