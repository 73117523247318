<mat-toolbar [color]="toolbarColor" class="mat-elevation-z2" class="view-toolbar">
    <mat-toolbar-row>
        <button type="button" mat-icon-button (click)="sidenavService.toggle()" *ngIf="showMenu">
            <mat-icon class="fill-inherit" svgIcon="culteva:icon_light"></mat-icon>
        </button>

        <a type="buttom" mat-icon-button *ngIf="!showMenu" [routerLink]="returnUrl">
            <mat-icon>arrow_back</mat-icon>
        </a>
        
        <span style="margin-right: 12px;">{{ title }}</span>
        
    </mat-toolbar-row>
</mat-toolbar>

<div class="view-content">

    <div #viewSidebar class="view-sidebar" [class.hide-area]="viewSidebar.children.length === 0">
        <ng-content select="[pv-view-sidebar]"></ng-content>
    </div>

    <div class="view-body">
        <ng-content select="[pv-view-body]"></ng-content>
    </div>

</div>

