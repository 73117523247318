import {
    CustomProtocol,
    CustomProtocolService,
    FilterBuilder,
    FilterPage,
    FilterSort,
    Status,
    translateCommonErrorStatus,
    removeArrayItem,
    DetailRequest,
} from '@core/data';
import {Action, State, StateContext, Selector} from '@ngxs/store';
import {Observable, forkJoin, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {LocalStorage} from '@core/browser';
import { SetOrganizationContext } from '@app/auth';
import { Injectable } from '@angular/core';


export interface CustomProtocolIndexStateModel {

    // org context
    orgKey: string;

    // table data
    status: Status;
    data: CustomProtocol[];
    total: number;

    // filter options
    page: FilterPage;
    sort: FilterSort;
    query: CustomProtocolIndexQuery;

    // table options
    displayColumns: string[];
    sortOptions: { id: string, label: string }[];
    pageSizeOptions: number[];

    // export result
    exports: {
        status: Status;
    }[];

}

export interface CustomProtocolIndexQuery {
    title: string[];
    search: string;
    archived: boolean;
}

const DEFAULTS: CustomProtocolIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'title',
        order: 'asc'
    },
    displayColumns: [
        'title',
        'actions',
        'description',
        'type',
        'protocol',
        'filter',
        'createdAt',
    ],
    sortOptions: [
        {id: 'title', label: 'Title'},
        {id: 'createdAt', label: 'Created Date'},
        {id: 'updatedAt', label: 'Updated Date'},
    ],
    pageSizeOptions: [20, 50, 100, 500, 1000],
    query: {
        title: null,
        search: null,
        archived: false,
    },
    exports: []
};

export class InitCustomProtocolIndex {
    static readonly type = '[CustomProtocolIndex] Init';

    constructor(public orgKey: string) {
    }
}

export class LoadCustomProtocolIndex {
    static readonly type = '[CustomProtocolIndex] Load';

    constructor() {
    }
}

export class AddCustomProtocol {
    static readonly type = '[CustomProtocolIndex] Add';

    constructor(
        public orgKey: string,
        public data: Partial<CustomProtocol>,
    ) {}
}

export class RemoveCustomProtocol {
    static readonly type = '[CustomProtocolIndex] Remove';

    constructor(public key: string) {
    }
}

export class PageCustomProtocolIndex {
    static readonly type = '[CustomProtocolIndex] Page';

    constructor(public index: number, public size: number) {
    }
}

export class SortCustomProtocolIndex {
    static readonly type = '[CustomProtocolIndex] Sort';

    constructor(public column: string, public order = 'asc') {
    }
}

export class FilterCustomProtocolIndex {
    static readonly type = '[CustomProtocolIndex] Filter';

    constructor(public query: CustomProtocolIndexQuery) {
    }
}

export class ResetCustomProtocolIndex {
    static readonly type = '[CustomProtocolIndex] Reset';

    constructor() {
    }
}

export class DeleteCustomProtocol {
    static readonly type = '[CustomProtocolIndex] Delete';

    constructor(public key: string) {
    }
}

@State<CustomProtocolIndexStateModel>({
    name: 'custom_protocol_index',
    defaults: DEFAULTS
})
@Injectable()
export class CustomProtocolState {

    @Selector()
    static query(state: CustomProtocolIndexStateModel) {
        return state.query;
    }

    @Selector()
    static sort(state: CustomProtocolIndexStateModel) {
        return state.sort;
    }

    constructor(
        private _custom_protocolService: CustomProtocolService,
        private _storage: LocalStorage,
    ) {
    }

    @Action(InitCustomProtocolIndex)
    initCustomProtocolIndex(ctx: StateContext<CustomProtocolIndexStateModel>, action: InitCustomProtocolIndex) {

        ctx.patchState({
            orgKey: action.orgKey,
            data: [],
            total: 0,
            exports: []
        });


        this.restoreFilter(ctx);

        return ctx.dispatch(new LoadCustomProtocolIndex);
    }

    @Action(LoadCustomProtocolIndex, {cancelUncompleted: true})
    loadCustomProtocolIndex(ctx: StateContext<CustomProtocolIndexStateModel>, action: LoadCustomProtocolIndex) {

        const state = ctx.getState();

        if (!state.orgKey) {
            console.warn('Attempting to load CustomProtocolIndex without organization key.');
        }

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            counts: []
        };

        return this._custom_protocolService.query(this.getFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total === 0) {
                            ctx.patchState({
                                status: Status.EMPTY,
                                total: 0,
                                data: []
                            });
                            return;
                        }

                        ctx.patchState({
                            status: Status.OK,
                            data: result.data,
                            total: result.total
                        });

                        this.storeFilter(ctx);

                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: [],
                            total: 0
                        });
                    },
                )
            );

    }


    @Action(PageCustomProtocolIndex)
    pageCustomProtocolIndex(ctx: StateContext<CustomProtocolIndexStateModel>, action: PageCustomProtocolIndex) {

        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadCustomProtocolIndex);

    }

    @Action(SortCustomProtocolIndex)
    sortCustomProtocolIndex(ctx: StateContext<CustomProtocolIndexStateModel>, action: SortCustomProtocolIndex) {

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadCustomProtocolIndex);

    }

    @Action(FilterCustomProtocolIndex)
    filterCustomProtocolIndex(ctx: StateContext<CustomProtocolIndexStateModel>, action: FilterCustomProtocolIndex) {


        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadCustomProtocolIndex);

    }

    @Action(ResetCustomProtocolIndex)
    resetCustomProtocolIndex(ctx: StateContext<CustomProtocolIndexStateModel>, action: FilterCustomProtocolIndex) {

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadCustomProtocolIndex);

    }

    @Action(DeleteCustomProtocol)
    deleteCustomProtocol(ctx: StateContext<CustomProtocolIndexStateModel>, action: DeleteCustomProtocol) {


        return this._custom_protocolService.delete(action.key)
            .pipe(tap(removed => {
                const state = ctx.getState();

                if (state.status !== Status.OK) {
                    return;
                }

                ctx.patchState({
                    data: removeArrayItem(state.data, removed.key),
                });

            }));

    }

    @Action(AddCustomProtocol)
    addCustomProtocol(ctx: StateContext<CustomProtocolIndexStateModel>, action: AddCustomProtocol) {
        let request: Observable<CustomProtocol>;

        if (action.data.key) request = this._custom_protocolService.update(action.data.key, action.data);
        else request = this._custom_protocolService.create(action.data);

        return request.pipe(
            tap(result => {
                ctx.patchState({
                    status: Status.OK,
                });
                return forkJoin(
                    ctx.dispatch(new LoadCustomProtocolIndex),
                    ctx.dispatch(new SetOrganizationContext(action.orgKey)),
                );
            })
        );
    }

    private handleCustomProtocolChanged(ctx: StateContext<CustomProtocolIndexStateModel>) {

        const state = ctx.getState();

        if (state.status === Status.OK) {
            return ctx.dispatch(new LoadCustomProtocolIndex());
        }

    }


    private getFilter(state: CustomProtocolIndexStateModel, ignorePage = false) {

        const fb = (new FilterBuilder());

        if (state.orgKey) {
            fb.setQuery('ownerOrgKey', state.orgKey)
        }

        if (!ignorePage) {
            fb.setPage(state.page);
        }
        fb.setSort(state.sort);

        if (typeof state.query.search === 'string' && state.query.search.length > 0) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        if (!state.query.archived) fb.setQuery('archived', 0)

        return fb.get();

    }


    private storeFilter(ctx: StateContext<CustomProtocolIndexStateModel>) {
        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_custom_protocol_index_page`, state.page);
        this._storage.set(`${state.orgKey}_custom_protocol_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_custom_protocol_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<CustomProtocolIndexStateModel>) {

        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_custom_protocol_index_page`);
        const sort = this._storage.get(`${state.orgKey}_custom_protocol_index_sort`);
        const query = this._storage.get(`${state.orgKey}_custom_protocol_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });
    }


}



