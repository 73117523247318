import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {CustomProtocol, FilterSort, OrganizationGroup, ReportTable} from '@core/data';
import {Dialog, Snackbar} from '@core/material';
import {Select, Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {
    CustomProtocolFormDialog,
    CustomProtocolFormDialogData
} from '@app/evaluation/components/custom-protocol-index/custom-protocol-form.dialog';
import {
    CustomProtocolState,
    CustomProtocolIndexStateModel,
    FilterCustomProtocolIndex,
    PageCustomProtocolIndex,
    SortCustomProtocolIndex,
    ResetCustomProtocolIndex,
    CustomProtocolIndexQuery,
    InitCustomProtocolIndex, AddCustomProtocol, RemoveCustomProtocol, LoadCustomProtocolIndex,
} from '@app/evaluation/components/custom-protocol-index/custom-protocol.state';

@Component({
    selector: 'pv-custom-protocol-index-view',
    templateUrl: './custom-protocol-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-cultivar-index-view',
    }
})
export class CustomProtocolIndexView implements OnInit, OnDestroy {

    @Select(CustomProtocolState)
    state$: Observable<CustomProtocolIndexStateModel>;

    @Select(CustomProtocolState.query)
    query$: Observable<CustomProtocolIndexQuery>;

    @Select(CustomProtocolState.sort)
    sort$: Observable<FilterSort>;

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    filterFormProtocol = new FormGroup({
        search: new FormControl(null),
        archived: new FormControl(false),
    });

    private _destroy$ = new Subject();

    constructor(
        private _dialogs: Dialog,
        private _route: ActivatedRoute,
        private _snackbar: Snackbar,
        private _store: Store,
    ) {
    }

    ngOnInit() {
        this._route.paramMap.subscribe(params => {
            this._store.dispatch(new InitCustomProtocolIndex(params.get('orgKey')));
        });

        // query state => filter form group
        this.query$
            .pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                this.filterFormProtocol.setValue({
                    search: query.search,
                    archived: query.archived ? query.archived : false,
                }, {emitEvent: false});
            });

        // sort state => sort form group
        this.sort$
            .pipe(takeUntil(this._destroy$))
            .subscribe(sort => {
                this.sortFormGroup.setValue(sort, {emitEvent: false});
            });

        // filter form group => state changes
        this.filterFormProtocol.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(value => {
                this._store.dispatch(new FilterCustomProtocolIndex(value));
            });

        // sort form group => state changes
        this.sortFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(value => {
                this._store.dispatch(new SortCustomProtocolIndex(value.column, value.order));
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    reloadProtocols() {
        this._store.dispatch(new LoadCustomProtocolIndex);
    }

    addProtocol(orgKey: string, protocol?: Partial<CustomProtocol>) {
        const data: CustomProtocolFormDialogData = {
            data: {
                title: 'New Protocol',
                ownerOrgKey: orgKey,
                protocol:true,
                filter:true,
                archived:false,
            },
        };

        if (protocol) data.data = protocol;

        this._dialogs.open(CustomProtocolFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return;

                this._store.dispatch(new AddCustomProtocol(orgKey, result)).subscribe(_ => {
                    this._snackbar.info(result.key ? 'Protocol updated' : 'Protocol created');
                });
            });
    }

    removeProtocol(report: CustomProtocol) {
        const data: CustomProtocolFormDialogData = {
            data: {...report},
        };

        this._dialogs.open(CustomProtocolFormDialog, {data})
            .afterClosed()
            .subscribe(result => {
                if (!result) return;
                this._store.dispatch(new RemoveCustomProtocol(result));
            });
    }

    archiveProtocol(orgKey: string, protocol: CustomProtocol) {
        const status = protocol.archived ? 'unarchive' : 'archive'
        const archivedProtocol: CustomProtocol = {
            ...protocol,
            archived: !protocol.archived
        }

        this._dialogs.confirm(
            `${status[0].toUpperCase() + status.slice(1)} ${protocol.title}`,
            `Are you sure you want to ${status} this protocol?`
        )
            .afterClosed()
            .subscribe(result => {
                if (!result) return;

                this._store.dispatch(new AddCustomProtocol(orgKey, archivedProtocol)).subscribe(res => {
                    this._snackbar.info(`Protocol ${status}d`)
                });
            });
    }

    reset() {
        this._store.dispatch(new ResetCustomProtocolIndex());
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageCustomProtocolIndex(page.pageIndex, page.pageSize));
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this.sortFormGroup.setValue({
                column: sort.active,
                order: sort.direction
            });
        } else {
            this.sortFormGroup.setValue({
                column: 'label',
                order: 'asc'
            });
        }
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }
}
