import { Injectable } from '@angular/core';

import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class Sidenav {
    instance: MatSidenav = null;

    setInstance(instance: MatSidenav){
        instance.autoFocus = false;
        this.instance = instance;
    }

    open() {
        this.instance.open();
    }

    close() {
        this.instance.close();
    }

    toggle() {
        this.instance.toggle();
    }
}