import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ReportTable, ReportTableTemplate, ReportTableTemplateSubjectGroup, Status, TableReportColumn, TableReportColumnType, TableReportOptions } from "@core/data";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { DeleteReportTableTemplate, InitReportTableTemplateForm, ReportTableTemplateStateModel, ReportTableTemplatesState, SubmitReportTableTemplateForm } from "./report-table-templates.state";
import { Select, Store } from "@ngxs/store";
import { Dialog, Snackbar } from "@core/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { CalculationSubjectGroup, CharacteristicCategoryGroup, Crop, Index, Library, MeasurementSubjectGroup, Property, Protocol } from "@library";
import { LIBRARY } from "@app/evaluation/library";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ReportTableTemplatesDialgData {
    data: Partial<ReportTableTemplate>,
    reportTables?: ReportTable[],
}

@Component({
    selector: 'pv-report-table-templates-form-dialog',
    templateUrl: 'report-table-templates-form.dialog.html',
    styleUrls: ['./report-table-templates-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class ReportTableTemplatesDialog implements OnInit, OnDestroy {

    @Select(ReportTableTemplatesState)
    state$: Observable<ReportTableTemplateStateModel>;

    @Select(ReportTableTemplatesState.data)
    data$: Observable<Partial<ReportTableTemplate>>;

    @Select(ReportTableTemplatesState.templates)
    templates$: Observable<ReportTableTemplate[]>;

    //? FILTER CONTROLS
    public charGroupsCtrl: FormControl = new FormControl();
    public measGroupsCtrl: FormControl = new FormControl();
    public calcGroupsCtrl: FormControl = new FormControl();
    public baseGroupsCtrl: FormControl = new FormControl();

    public charGroupsFilterCtrl: FormControl = new FormControl();
    public measGroupsFilterCtrl: FormControl = new FormControl();
    public calcGroupsFilterCtrl: FormControl = new FormControl();
    public baseGroupsFilterCtrl: FormControl = new FormControl();

    public filteredCharGroups: ReplaySubject<CharacteristicCategoryGroup[]> = new ReplaySubject<CharacteristicCategoryGroup[]>(1);
    public filteredMeasGroups: ReplaySubject<MeasurementSubjectGroup[]> = new ReplaySubject<MeasurementSubjectGroup[]>(1);
    public filteredCalcsGroups: ReplaySubject<CalculationSubjectGroup[]> = new ReplaySubject<CalculationSubjectGroup[]>(1);
    public filteredBaseGroups: ReplaySubject<ReportTableTemplateSubjectGroup[]> = new ReplaySubject<ReportTableTemplateSubjectGroup[]>(1);

    protected _onDestroyChar = new Subject<void>();
    protected _onDestroyMeas = new Subject<void>();
    protected _onDestroyCalc = new Subject<void>();
    protected _onDestroyBase = new Subject<void>();

    //? FORM CONTROLS
    idControl = new FormControl(null);
    nameControl = new FormControl(null, [Validators.required]);

    includePropertiesControl = new FormControl([]);
    includeCharsControl = new FormControl([]);
    includeMeasuresControl = new FormControl([]);
    includeCalcsControl = new FormControl([]);
    includeIndexesControl = new FormControl([]);
    baseTemplateControl = new FormControl(null);
    cropIdControl: FormControl = new FormControl(null, [Validators.required]);

    //? FORM GROUP
    formGroup = new FormGroup({
        id: this.idControl,
        name: this.nameControl,
        ownerOrgKey: new FormControl(null, [Validators.required]),
        tableOptions: new FormGroup({
            includeProperties: this.includePropertiesControl,
            includeChars: this.includeCharsControl,
            includeMeasures: this.includeMeasuresControl,
            includeCalcs: this.includeCalcsControl,
            includeIndexes: this.includeIndexesControl,
            baseTemplate: this.baseTemplateControl,
            isAggregate: new FormControl(false),
            isSpread: new FormControl(false),
            showSummary: new FormControl(false),
            cropId: this.cropIdControl,
        }),
    });

    //? FORM OPTIONS
    cropOptions: Crop[];
    propertyOptions: Property[];
    characteristicGroups: CharacteristicCategoryGroup[];
    measurementGroups: MeasurementSubjectGroup[];
    calculationGroups: CalculationSubjectGroup[];
    indexOptions: Index[];
    baseTemplateOptions: ReportTableTemplateSubjectGroup[];

    currentCrop: Crop;
    currentSelection: TableReportColumn[] = [];
    currentTemplates: ReportTableTemplate[] = [];
    tableReportColumnType = TableReportColumnType;
    orgKey: string;
    columns = {
        [TableReportColumnType.Calculation] : [],
        [TableReportColumnType.Characteristic] : [],
        [TableReportColumnType.Index] : [],
        [TableReportColumnType.Measurement] : [],
        [TableReportColumnType.Property] : [],
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportTableTemplatesDialgData,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialogRef: MatDialogRef<ReportTableTemplatesDialgData>,
        private _dialog: Dialog,
        @Inject(LIBRARY) private _library: Library,
    ) {}


    private _destroy$ = new Subject();

    ngOnInit() {
        this.resetSelection();

        this._store.dispatch(new InitReportTableTemplateForm(this._data.data));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) {
                    this._dialogRef.close(state.data);
                } else if (state.status !== Status.LOADING) {
                    this.formGroup.enable();
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });

        this.templates$.pipe(takeUntil(this._destroy$))
            .subscribe(templates => {
                this.currentTemplates = [...templates];
                this.filterTemplateGroups();
            });

        this.charGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyChar))
            .subscribe(() => {
                this.filterCharGroups();
            });

        this.measGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyMeas))
            .subscribe(() => {
                this.filterMeasGroups();

            });

        this.calcGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyCalc))
            .subscribe(() => {
                this.filterCalcGroups();
            });

        this.baseGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyBase))
            .subscribe(() => {
                this.filterTemplateGroups();
            });

        this.cropIdControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (!data) this.disableFormControls();
            })

    }

    reset(data: Partial<ReportTableTemplate>) {
        this.propertyOptions = this._library.properties.all();
        this.characteristicGroups = this._library.characteristicsGroupedByCategory();
        this.measurementGroups = this._library.measurementsGroupedBySubject();
        this.calculationGroups = this._library.calculationsGroupedBySubject();
        this.indexOptions = this._library.indexes.all();

        this.cropOptions = this._library.filterExcludedCrops()

        this.disableFormControls();

        this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
        this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));
        this.filteredCalcsGroups.next(this.copyCalcsGroups(this.calculationGroups));
        this.filteredBaseGroups.next(this.compileBaseTemplateList());

        this.currentSelection = [...data.tableOptions.columns];

        this.formGroup.reset({
            id: data.id || null,
            name: data.name || null,
            ownerOrgKey: data.ownerOrgKey,
            tableOptions: {
                includeProperties: this.getColumnData(data.tableOptions, TableReportColumnType.Property),
                includeChars: this.getColumnData(data.tableOptions, TableReportColumnType.Characteristic),
                includeMeasures: this.getColumnData(data.tableOptions, TableReportColumnType.Measurement),
                includeCalcs: this.getColumnData(data.tableOptions, TableReportColumnType.Calculation),
                includeIndexes: this.getColumnData(data.tableOptions, TableReportColumnType.Index),
                isAggregate: data.tableOptions.isAggregate || null,
                isSpread: data.tableOptions.isSpread || null,
                showSummary: data.tableOptions.showSummary || null,
                cropId: data.tableOptions.cropId || null,
                baseTemplate: this.baseTemplateControl.value,
            }
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.currentSelection, event.previousIndex, event.currentIndex);
    }

    updateCropSelection(crop: Crop, event?: MatOptionSelectionChange): void {
        if (event && !event.isUserInput) return;

        if (!crop) this.disableFormControls();
        else this.enableFormControls()

        if (this.currentCrop && crop !== this.currentCrop) this.resetSelection();

        this.characteristicGroups = this.characteristicsGroupedByCategoryWithIds(this.getCropChars(crop));
        this.measurementGroups = this.measurementsGroupedBySubjectWithIds(this.getCropMeas(crop));
        this.calculationGroups = this.calculationsGroupedBySubjectWithIds(this.getCropCalcs(crop));
        this.indexOptions = this._library.indexes.all();

        this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
        this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));
        this.filteredCalcsGroups.next(this.copyCalcsGroups(this.calculationGroups));

        if(this.calculationGroups.length == 0) {
            this.calcGroupsCtrl.disable();
            this.calcGroupsFilterCtrl.disable();
            this.includeCalcsControl.disable();
        }

        this.currentCrop = crop;
    }

    getCropChars(crop: Crop) {
        let cropChars = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.chars) {
                    protocol.chars.forEach(char => {
                        cropChars.push(char);
                    });
                }
            }
        });

        if(cropChars.length > 0) return cropChars;
        else return null;

    }

    getCropMeas(crop: Crop) {
        let cropMeas = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.measures) {
                    protocol.measures.forEach(meas => {
                        cropMeas.push(meas);
                    });
                }
            }
        });

        if(cropMeas.length > 0) return cropMeas;
        else return null;

    }

    getCropCalcs(crop: Crop) {
        let cropCalcs = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.calculations) {
                    protocol.calculations.forEach(calc => {
                        cropCalcs.push(calc);
                    });
                }
            }
        });

        if(cropCalcs.length > 0) return cropCalcs;
        else return null;

    }

    copyCharGroups(charGroups: CharacteristicCategoryGroup[]) {
        const charGroupsCopy = [];
        charGroups.forEach(charGroup => {
            charGroupsCopy.push({
                category: charGroup.category.label,
                id: charGroup.id,
                chars: charGroup.chars.slice()
            });
        });
        return charGroupsCopy;
    }

    copyMeasGroups(measGroups: MeasurementSubjectGroup[]) {
        const measGroupsCopy = [];
        measGroups.forEach(measGroup => {
            measGroupsCopy.push({
                subject: measGroup.subject.label,
                id: measGroup.id,
                measures: measGroup.measures.slice()
            });
        });
        return measGroupsCopy;
    }

    copyCalcsGroups(calcsGroups: CalculationSubjectGroup[]) {
        const calcsGroupsCopy = [];
        calcsGroups.forEach(calcsGroup => {
            calcsGroupsCopy.push({
                subject: calcsGroup.subject.label,
                id: calcsGroup.id,
                calculations: calcsGroup.calculations.slice()
            });
        });
        return calcsGroupsCopy;
    }

    compileBaseTemplateList() {
        let baseGroupsCopy: ReportTableTemplateSubjectGroup[] = [];
        let templates = [];

        this._data.reportTables.forEach(reportTable => {
            let template: ReportTableTemplate = {
                id: null,
                name: reportTable.title,
                ownerOrgKey: this._data.data.ownerOrgKey,
                tableOptions: reportTable.tableOptions,
            };

            templates.push(template);
        });

        baseGroupsCopy.push({
            subject: {
                id: 'report_tables',
                label: 'Report Tables',
            },
            id: 'report_tables',
            templates: templates
        })

        baseGroupsCopy.push({
            subject: {
                id: 'available_templates',
                label: 'Available Templates',
            },
            id: 'available_templates',
            templates: this.currentTemplates
        })

        this.baseTemplateOptions = baseGroupsCopy;
        return baseGroupsCopy;
    }

    filterCharGroups() {
        if (!this.characteristicGroups) return;

        let search = this.charGroupsFilterCtrl.value;
        const charGroupsCopy = this.copyCharGroups(this.characteristicGroups);

        if (!search) return this.filteredCharGroups.next(charGroupsCopy);

        search = search.toLowerCase();

        this.filteredCharGroups.next(
            charGroupsCopy.filter(charGroup => {
                const showBankGroup = charGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    charGroup.chars = charGroup.chars.filter(char => char.label.toLowerCase().indexOf(search) > -1);
                }

                return charGroup.chars.length > 0;
            })
        );
    }

    filterMeasGroups() {
        if (!this.measurementGroups) return;

        let search = this.measGroupsFilterCtrl.value;
        const measGroupsCopy = this.copyMeasGroups(this.measurementGroups);

        if (!search) return this.filteredMeasGroups.next(measGroupsCopy);

        search = search.toLowerCase();

        this.filteredMeasGroups.next(
            measGroupsCopy.filter(measGroup => {
                const showBankGroup = measGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    measGroup.measures = measGroup.measures.filter(meas => meas.label.toLowerCase().indexOf(search) > -1);
                }

                return measGroup.measures.length > 0;
            })
        );
    }

    filterCalcGroups() {
        if (!this.calculationGroups) return;

        let search = this.calcGroupsFilterCtrl.value;
        const calcGroupsCopy = this.copyCalcsGroups(this.calculationGroups);

        if (!search) return this.filteredCalcsGroups.next(calcGroupsCopy);

        search = search.toLowerCase();

        this.filteredCalcsGroups.next(
            calcGroupsCopy.filter(calcGroup => {
                const showBankGroup = calcGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    calcGroup.calculations = calcGroup.calculations.filter(calc => calc.label.toLowerCase().indexOf(search) > -1);
                }

                return calcGroup.calculations.length > 0;
            })
        );
    }

    filterTemplateGroups() {
        if (!this.baseTemplateOptions) return;

        let search = this.baseGroupsFilterCtrl.value;
        const baseGroupsCopy = this.compileBaseTemplateList();

        if (!search) return this.filteredBaseGroups.next(baseGroupsCopy);

        search = search.toLowerCase();

        this.filteredBaseGroups.next(
            baseGroupsCopy.filter(baseGroup => {
                const showBankGroup = baseGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    baseGroup.templates = baseGroup.templates.filter(template => template.name.toLowerCase().indexOf(search) > -1);
                }

                return baseGroup.templates.length > 0;
            })
        );
    }

    updateSelectedTemplate(event: MatOptionSelectionChange, template: ReportTableTemplate) {
        if (!event.isUserInput) return;

        let crop: Crop = this.cropOptions.find(crop => crop.id === template.tableOptions.cropId);

        if (!crop) return this._snackbar.error('Error retrieving crop from base template');

        this.updateCropSelection(crop);

        this.formGroup.get('tableOptions').reset({
            includeProperties: this.getColumnData(template.tableOptions, TableReportColumnType.Property),
            includeChars: this.getColumnData(template.tableOptions, TableReportColumnType.Characteristic),
            includeMeasures: this.getColumnData(template.tableOptions, TableReportColumnType.Measurement),
            includeCalcs: this.getColumnData(template.tableOptions, TableReportColumnType.Calculation),
            includeIndexes: this.getColumnData(template.tableOptions, TableReportColumnType.Index),
            isAggregate: template.tableOptions.isAggregate || null,
            isSpread: template.tableOptions.isSpread || null,
            showSummary: template.tableOptions.showSummary || null,
            cropId: template.tableOptions.cropId || null,
            baseTemplate: template,
        });

        this.currentSelection = [...template.tableOptions.columns];
    }

    updateTemplateOptions(type: TableReportColumnType, event?: MatOptionSelectionChange): void {
        if (!event.isUserInput) return;

        if (event.source.selected) {
            this.currentSelection.push({
                id: event.source.value,
                type: type,
            });
        } else {
            let index = this.currentSelection.findIndex(column => column.id === event.source.value && column.type === type)
            if (index > -1) {
                this.currentSelection.splice(index, 1);
            }
        }

        this.currentSelection = [...this.currentSelection];
    }

    trackById(index, item){
        return item.id;
    }

    attempt(){
        this.formGroup.updateValueAndValidity();

        const form = this.formGroup.value;

        const template: ReportTableTemplate = {
            id: form.id,
            name: form.name,
            ownerOrgKey: form.ownerOrgKey,
            tableOptions: {
                columns: this.currentSelection,
                isAggregate: form.tableOptions.isAggregate,
                isSpread: form.tableOptions.isSpread,
                showSummary: form.tableOptions.showSummary,
                cropId: form.tableOptions.cropId,
                includedEvals: [],
            }
        }

        if(this.formGroup.valid){
            this._store.dispatch(new SubmitReportTableTemplateForm(template));
            return
        }

        this.touchRequiredControls();
        this._snackbar.error('Invalid Input. Check your input and try again.');
    }

    cancel(){
        this._dialogRef.close();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private touchRequiredControls() {
        this.nameControl.markAsTouched();
        this.cropIdControl.markAsTouched();
    }

    private toggleBooleanControls(control: string) {
        const toggleAggregate = ['isSpread', 'showSummary'];
        const toggleSpread = ['isAggregate', 'showSummary'];
        const toggleSampleSummary = ['isSpread', 'isAggregate'];

        if (control === 'isAggregate') this.toggleControls(toggleAggregate)
        if (control === 'isSpread') this.toggleControls(toggleSpread)
        if (control === 'showSummary') this.toggleControls(toggleSampleSummary)
    }

    private toggleControls(controls: string[]) {
        controls.forEach(control => {
            this.formGroup.get(`tableOptions.${control}`).setValue(false);
        });
    }

    private resetSelection() {
        this.includePropertiesControl.reset();
        this.includeCharsControl.reset();
        this.includeMeasuresControl.reset();
        this.includeCalcsControl.reset();
        this.includeIndexesControl.reset();

        this.charGroupsCtrl.reset();
        this.charGroupsFilterCtrl.reset();

        this.measGroupsCtrl.reset();
        this.measGroupsFilterCtrl.reset();

        this.calcGroupsCtrl.reset();
        this.calcGroupsFilterCtrl.reset();

        this.baseGroupsCtrl.reset();
        this.baseGroupsFilterCtrl.reset();

        this.currentSelection = [];
    }

    private enableFormControls() {
        this.includePropertiesControl.enable();
        this.includeCharsControl.enable();
        this.includeMeasuresControl.enable();
        this.includeCalcsControl.enable();
        this.includeIndexesControl.enable();

        this.charGroupsCtrl.enable();
        this.charGroupsFilterCtrl.enable();

        this.measGroupsCtrl.enable();
        this.measGroupsFilterCtrl.enable();

        this.calcGroupsCtrl.enable();
        this.calcGroupsFilterCtrl.enable();

        this.baseGroupsCtrl.enable();
        this.baseGroupsFilterCtrl.enable();
    }

    private disableFormControls() {
        this.includePropertiesControl.disable();
        this.includeCharsControl.disable();
        this.includeMeasuresControl.disable();
        this.includeCalcsControl.disable();
        this.includeIndexesControl.disable();

        this.charGroupsCtrl.disable();
        this.charGroupsFilterCtrl.disable();

        this.measGroupsCtrl.disable();
        this.measGroupsFilterCtrl.disable();

        this.calcGroupsCtrl.disable();
        this.calcGroupsFilterCtrl.disable();

        this.baseGroupsCtrl.disable();
        this.baseGroupsFilterCtrl.disable();
    }


    private measurementsGroupedBySubjectWithIds(measureIds: string[]): MeasurementSubjectGroup[]{
        const groups: MeasurementSubjectGroup[] = [];

        this._library.measures.all().forEach(measure => {
            if (measureIds.includes(measure.id)) {
                let group = groups.find(group => group.id === measure.subjectId);

                if (!group) {
                    group = {
                        id: measure.subjectId,
                        measures: [],
                        subject: this._library.subjects.get(measure.subjectId)
                    };
                    groups.push(group);
                }

                group.measures.push(measure);
            }
        });

        return groups;
    }

    private calculationsGroupedBySubjectWithIds(calcIds: string[]): CalculationSubjectGroup[]{
        const groups: CalculationSubjectGroup[] = [];

        if(!calcIds) return groups;

        this._library.calculations.all().forEach(calc => {
            if (calcIds.includes(calc.id)) {
                let group = groups.find(group => group.id === calc.subjectId);

                if (!group) {
                    group = {
                        id: calc.subjectId,
                        calculations: [],
                        subject: this._library.subjects.get(calc.subjectId)
                    };
                    groups.push(group);
                }

                group.calculations.push(calc);
            }
        });

        return groups;
    }

    private characteristicsGroupedByCategoryWithIds(charIds: string[]): CharacteristicCategoryGroup[] {
        const groups: CharacteristicCategoryGroup[] = [];

        this._library.chars.all().forEach(char => {
            if (charIds.includes(char.id)){

                let group = groups.find(group => group.id === char.categoryId);

                if (!group) {
                    group = {
                        id: char.categoryId,
                        chars: [],
                        category: this._library.categories.get(char.categoryId)
                    };
                    groups.push(group);
                }

                group.chars.push(char);
            }
        });

        return groups;
    }

    private getColumnData(data: TableReportOptions, type: TableReportColumnType): string[] {
        if (!data.columns) return [];

        return data.columns.filter(col => col.type === type).map(val => val.id);
    }
}