import { NgModule } from '@angular/core';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { ImplodePipe } from './pipes/implode.pipe';

@NgModule({
    declarations: [
        FormatBytesPipe,
        ImplodePipe,
    ],
    imports: [],
    providers: [],
    exports: [
        FormatBytesPipe,
        ImplodePipe,
    ]
})
export class CoreUtilsModule {}
