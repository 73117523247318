import { Component, Input } from "@angular/core";
import { Dialog, Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { InitOrganizationMembers, LoadOrganizationMembers, OrganizationMembersState, OrganizationMembersStateModel, RemoveOrganizationMember, UpdateOrganizationMemberRole } from './organization-members.state';

@Component({
    selector: 'pv-organization-members-list',
    templateUrl: 'organization-members-list.component.html',
    styles: [
        `
        :host {
            display: block;
            position: relative;
        }

    `
    ],
})
export class OrganizationMembersList {

    @Select(OrganizationMembersState)
    membersState$: Observable<OrganizationMembersStateModel>;

    @Input()
    get orgKey() { return this._orgKey; }
    set orgKey(value: string) {
        if (value && this._orgKey !== value) {
            this._orgKey = value;
            this._store.dispatch(new InitOrganizationMembers(value));
        }
    }

    private _orgKey: string;

    constructor(
        private _store: Store,
        private _dialogs: Dialog,
        private _snackbar: Snackbar
    ) { }

    removeMember(userKey: string) {

        let dialog = this._dialogs.confirm(
            'Revoke User Access',
            'Are you sure you want to remove this member from your organization? The member will no longer be able to access any data associated with this organization.',
            'Revoke',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new RemoveOrganizationMember(userKey))
                    .subscribe(state => {
                        this._snackbar.info("Member removed");
                    });
            }
        });
    }

    updateMemberRole(userKey: string, role: string) {
        let dialog = this._dialogs.confirm(
            'Update User Role',
            "Are you sure you want to change this user's role?",
            'Update Role',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new UpdateOrganizationMemberRole(userKey, role))
                    .subscribe(state => {
                        this._snackbar.info("Member role updated");
                    });
            }
        });
    }

    reloadMembers() {
        this._store.dispatch(new LoadOrganizationMembers);
    }
}