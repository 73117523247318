import { Pipe, PipeTransform } from '@angular/core';
import { Site, WeatherStation, SampleImage } from '@core/data';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';

@Pipe({
  name: 'descSiteCoords',
})
export class DescribeSiteCoordsPipe implements PipeTransform {

    constructor(private _sampleFormatter: SampleFormatter){}

    transform(site: Site | WeatherStation | SampleImage) {
        return this._sampleFormatter.describeSiteCoordinates(site);
    }
}
