import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LetterheadUpdateRequest, ReportLetterhead, ReportService, Status, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";


export interface LetterheadUploadFormStateModel {
    key: string;
    reportKey: string;
    ownerOrgKey: string;
    status: Status;
    data: ReportLetterhead[];
    uploads: PendingReportLetterheadUpoadModel[];
    addedLetterhead: boolean;
}

export interface PendingReportLetterheadUpoadModel {
    id: number;
    loaded: number;
    total: number;
    status: Status;
    name: string;
    data: ReportLetterhead;
}

export class InitReportLetterheadUploadForm {
    static readonly type = "[LetterheadUploadForm] Init";
    constructor(public reportKey: string, public ownerOrgKey: string) {}
}

export class UploadLetterhead {
    static readonly type= "[LetterheadUploadForm] Upload";
    constructor(public file: File, public reportKey: string) {}
}

@State({
    name: 'letterhead_upload_form',
    defaults: {
        reportKey: null,
        ownerOrgKey: null,
        status: Status.UNINITIALIZED,
        data: null,
        uploads: [],
        addedLetterhead: false,
    }
})
@Injectable()
export class LetterheadUploadFormState {
    @Selector()
    static data(state: LetterheadUploadFormStateModel) { return state.data; }

    @Selector()
    static uploads(state: LetterheadUploadFormStateModel) { return state.uploads; }

    constructor(
        private _reportsService: ReportService
    ) {}

    @Action(InitReportLetterheadUploadForm)
    initReportLetterheadUploadForm(ctx: StateContext<LetterheadUploadFormStateModel>, action: InitReportLetterheadUploadForm) {
        ctx.setState({
            key: '',
            reportKey: action.reportKey,
            ownerOrgKey: action.ownerOrgKey,
            status: Status.LOADING,
            data: null,
            uploads: [],
            addedLetterhead: false,
        });

        return this._reportsService
            .getOrgReportLetterheads(action.ownerOrgKey)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            data: result,
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        })
                    }
                )
            );
    }

    @Action(UploadLetterhead)
    uploadLetterhead(ctx: StateContext<LetterheadUploadFormStateModel>, action: UploadLetterhead) {
        const state = ctx.getState();

        let upload: PendingReportLetterheadUpoadModel = {
            id: Math.round(Math.random() * 1000000),
            loaded: 0,
            total: action.file.size,
            status: Status.LOADING,
            name: action.file.name,
            data: null,
        }

        ctx.patchState({
            addedLetterhead: true,
            uploads: [...state.uploads, upload],
        });

        return this._reportsService.uploadOrgReportLetterhead(state.ownerOrgKey, state.reportKey, action.file)
            .pipe(
                tap(
                    event => {
                        const state = ctx.getState();

                        if (state.status !== Status.OK) return;

                        //Upload progress
                        if (event.type === HttpEventType.UploadProgress) {
                            upload = {
                                ...upload,
                                loaded: event.loaded,
                                total: event.total,
                            };
                            this.patchUpload(ctx, upload);

                        }else if (event instanceof HttpResponse){
                            upload = {
                                ...upload,
                                status: Status.COMPLETE,
                                data: event.body
                            };

                            this.patchUpload(ctx, upload);
                        }
                    },
                    error => {
                        console.warn('LetterheadUploadFormState: Upload Error ', error);

                        upload = {
                            ...upload,
                            status: translateCommonErrorStatus(error)
                        }

                        this.patchUpload(ctx, upload);
                    }
                ), catchError(e => of(null))
            )
    }

    private patchUpload(ctx: StateContext<LetterheadUploadFormStateModel>, upload: PendingReportLetterheadUpoadModel) {
        const state = ctx.getState();

        let uploads = state.uploads.map(existing => {
            if (existing.id === upload.id) return upload;
            return existing;
        })

        ctx.patchState({ uploads });
    }
}