
export const DEFAULT = '';

export interface WeatherStation {
    key: string;
    type: string; //tinyTag OR sigFox
    
    //SigFox Specific
    deviceId? : string;
    pacCode?: string;
    
    name: string;
    ownerOrgKey: string;
    lat: number;
    lng: number;
    alt: number;
    createdAt: string;
    updatedAt: string;

    // related
    sensorSummaries?: WeatherSensorSummary[]
}

export interface WeatherSensorSummary {
    stationKey: string;
    sensorId: string;
    firstRecordedAt: string;
    lastRecordedAt: string;
    minValue: number;
    maxValue: number;
}

export interface WeatherReportOptions {
    reportId: string;
    endDate: string;
    startDate: string;
    interval: WeatherReportInterval;
}

export interface WeatherReportData {
    stationKey: string;
    station?: WeatherStation;
    reportId: string;
    endDate: string;
    startDate: string;
    interval: WeatherReportInterval;
    data?: any[];
}

export enum WeatherReportInterval {
    HOURLY = 'hourly',
    DAILY = 'daily',
    MONTHLY = 'monthly',
}

//Review Sigfox stations  -  -  -  -  -  -

export interface SigFoxDeviceContact {
    id: string
 }
 
 
 export interface SigFoxDeviceTypeId {
     id: string
 }
 
 export interface SigFoxDeviceGroup {
     id: string
 }
 
 export interface SigFoxDeviceModemCert {
   id: string
 }
 
 export interface SigFoxDeviceProdCert {
   id: string
 }
 
 //review  SFD (SigFoxDevice) + SigFoxDeviceMessage
 
 
 export interface SFDeviceComputedLocation {
   lat: number
   lng: number
   radius: number
   sourceCode: number
 }
 
 export interface SFDeviceLocation {
   lat: number
   lng: number
 }
 
 export interface SFDeviceToken {
   detailMessage: string
   end: number
   state: number
 }

 
 export interface SigFoxDevice {
 
 activable?: boolean
 activationTime?: number
 automaticRenewal?: boolean
 automaticRenewalStatus?: number
 comState?: number
 contract:SigFoxDeviceContact
 createdBy?: string
 creationTime: number
 deviceType: SigFoxDeviceTypeId
 group: SigFoxDeviceGroup
 id: string
 lastCom?: number
 lastComputedLocation?:SFDeviceComputedLocation
 lastEditedBy?: string
 lastEditionTime?: number
 location: SFDeviceLocation
 lqi?: number
 messageModulo?: number
 modemCertificate?:SigFoxDeviceModemCert
 name: string
 pac?: string
 productCertificate?: SigFoxDeviceProdCert
 prototype?: boolean
 repeater?: boolean
 satelliteCapable?: boolean
 sequenceNumber?: number
 state?: number
 token?: SFDeviceToken
 }
 
 
export class DeviceList {
 
    constructor(
    public ID: string,
    public name?: string,
    public companyName?: string
    ) { }
  }