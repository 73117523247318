import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ROLE_LABELS } from '@core/data';

@Pipe({
	name: 'labelAccess'
})
export class LabelAccessPipe implements PipeTransform {
	constructor(@Inject(LOCALE_ID) public locale: string) { }

	transform(value: string) {
		let role = ROLE_LABELS.find(role => role.id === value);
		return role ? role.label : "Unknown Role";
	}
}