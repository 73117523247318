<ng-container *ngIf="state$ | async as state">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            Update Primary Image
        </h2>
    </div>

    <div mat-dialog-content *ngIf="state.data as sample">

        <mat-radio-group
            class="image-list"
            *ngIf="(sample.images && sample.images.length) else noImages"
            [formControl]="primaryImageKeyControl">

            <div class="image-selector" *ngFor="let image of sample.images">

                <pv-image-ref
                            [fileRef]="image.fileRef"
                            transformId="thumb_72">
                </pv-image-ref>

                <mat-radio-button [value]="image.key" color="accent"></mat-radio-button>

            </div>

        </mat-radio-group>

        <ng-template #noImages>
            <p class="text-muted">No images found for sample.</p>
        </ng-template>

    </div>

    <pv-form-actions mat-dialog-actions
        [formGroup]="primaryImageKeyControl"
        (cancel)="cancel()"
        (submit)="attempt()"
        [status]="state.status">
        <button mat-button (click)="removeImage()">Remove Primary</button>
    </pv-form-actions>

</ng-container>
