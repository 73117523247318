import { ReportOptions, ReportSection } from '@core/data';

export const REPORT_OPTIONS_DEFAULT: Readonly<ReportOptions> = {
    themeId: 'provar',
    showLegend: true,
    showStorageRegime: true,
    showEmptyFields: false,
    toggleDescription: false,
    letterheadRef: null,
    letterhead: null,
    letterheadKey: null,
    showCharSummary: true,
    showTimeline: true,
    timelineOptions: {
        startDate: null,
        endDate: null,
    },

    showWeather: false,
    showTables: false,
    tables: [],

    showCultivarInfo: false,
    cultivarInfoOptions: {
        showCultivarSelection: false,
        showSampleOrigin: false,
        primaryCultivar: null,
        controlCultivar: null,
        includeChars: [],
        includeMeasures: [],
        includeIndexes: [],
        includeImages: [[],[]],
        infoSheetImageOptions: [[],[]],
        imageSize: 'm',
        overrides: []
    },

    charSummaryOptions: {
        includeChars: ['*'],
        layoutDense: false,
    },
    showMeasureSummary: true,
    measureSummaryOptions: {
        includeMeasures: ['*'],
        overrides: [],
        showCharts: true,
        showData: false,
        showStats: true,
    },
    showCharIndex: true,
    charIndexOptions: {
        includeIndexes: ['*'],
        showCharts: true,
        showData: true,
    },
    showNotes: true,
    noteOptions: {

    },
    showImages: true,
    imageOptions: {
        size: 'm',
        showDates: true,
        showNotes: true,
        overlay: false,
    },
    sectionsOrder: "Specifications;Timeline;Weather;Tables;InfoSheet;Characteristics;Measurements;Indexes;Images;Notes",
    imageSectionOptions: {
        images: [[]],
    },
};


export function mergeDefaultReportOptions(options: ReportOptions): Readonly<ReportOptions> {
    // if(options == null) return REPORT_OPTIONS_DEFAULT;

    return {
        themeId: "themeId" in options ? options.themeId : REPORT_OPTIONS_DEFAULT.themeId,
        showLegend: "showLegend" in options ? !!options.showLegend : REPORT_OPTIONS_DEFAULT.showLegend,
        showStorageRegime: "showStorageRegime" in options ? !!options.showStorageRegime : REPORT_OPTIONS_DEFAULT.showStorageRegime,
        showEmptyFields: "showEmptyFields" in options ? !!options.showEmptyFields : REPORT_OPTIONS_DEFAULT.showEmptyFields,
        toggleDescription: "toggleDescription" in options ? !!options.toggleDescription : REPORT_OPTIONS_DEFAULT.toggleDescription,
        showTimeline: "showTimeline" in options ? !!options.showTimeline : REPORT_OPTIONS_DEFAULT.showTimeline,
        showWeather: "showWeather" in options ? !!options.showWeather : REPORT_OPTIONS_DEFAULT.showWeather,
        showCultivarInfo: "showCultivarInfo" in options ? !!options.showCultivarInfo : REPORT_OPTIONS_DEFAULT.showCultivarInfo,
        showTables: "showTables" in options ? !!options.showTables : REPORT_OPTIONS_DEFAULT.showTables,
        tables: {
            ...REPORT_OPTIONS_DEFAULT.tables,
            ...options.tables,
        },
        showCharSummary: "showCharSummary" in options ? !!options.showCharSummary : REPORT_OPTIONS_DEFAULT.showCharSummary,
        showMeasureSummary: "showMeasureSummary" in options ? !!options.showMeasureSummary : REPORT_OPTIONS_DEFAULT.showMeasureSummary,
        showCharIndex: "showCharIndex" in options ? !!options.showCharIndex : REPORT_OPTIONS_DEFAULT.showCharIndex,
        showImages: "showImages" in options ? !!options.showImages : REPORT_OPTIONS_DEFAULT.showImages,
        showNotes: "showNotes" in options ? !!options.showNotes : REPORT_OPTIONS_DEFAULT.showNotes,
        letterheadRef: options.letterheadRef || REPORT_OPTIONS_DEFAULT.letterheadRef,
        letterhead: options.letterhead || REPORT_OPTIONS_DEFAULT.letterhead,
        letterheadKey: options.letterheadKey || REPORT_OPTIONS_DEFAULT.letterheadKey,
        timelineOptions: {
            ...REPORT_OPTIONS_DEFAULT.timelineOptions,
            ...options.timelineOptions,
        },
        cultivarInfoOptions: {
            ...REPORT_OPTIONS_DEFAULT.cultivarInfoOptions,
            ...options.cultivarInfoOptions,
        },
        charSummaryOptions: {
            ...REPORT_OPTIONS_DEFAULT.charSummaryOptions,
            ...options.charSummaryOptions,
        },
        measureSummaryOptions: {
            ...REPORT_OPTIONS_DEFAULT.measureSummaryOptions,
            ...options.measureSummaryOptions,
        },
        charIndexOptions: {
            ...REPORT_OPTIONS_DEFAULT.charIndexOptions,
            ...options.charIndexOptions
        },
        noteOptions: {
            ...REPORT_OPTIONS_DEFAULT.noteOptions,
            ...options.noteOptions,
        },
        imageOptions: {
            ...REPORT_OPTIONS_DEFAULT.imageOptions,
            ...options.imageOptions
        },

        sectionsOrder: "sectionsOrder" in options ? options.sectionsOrder : REPORT_OPTIONS_DEFAULT.sectionsOrder,

        imageSectionOptions: {
            ...REPORT_OPTIONS_DEFAULT.imageSectionOptions,
            ...options.imageSectionOptions
        }
    };

};

export const REPORT_SECTIONS_DEFAULT : ReportSection[] = [
    {
        index: 0,
        title: 'Specifications',
        iconRef: 'preview',
        enabled: REPORT_OPTIONS_DEFAULT.showLegend,

        formControlName: 'showLegend',
        formGroupName: 'legendOptions',
        options: [
            { title: 'Show Storage Regime', type: 'check', formControlName: 'showStorageRegime'},
        ]
    },
    {
        index: 1,
        title: 'Timeline',
        iconRef: 'access_time',
        enabled: REPORT_OPTIONS_DEFAULT.showTimeline,

        formControlName: 'showTimeline',
        formGroupName: null,
        options: []
    },
    {
        index: 2,
        title: 'Weather',
        iconRef: 'show_chart',
        enabled: REPORT_OPTIONS_DEFAULT.showWeather,

        formControlName: 'showWeather',
        formGroupName: null,
        options: []
    },
    {
        index: 3,
        title: 'Tables',
        iconRef: 'table_view',
        enabled: REPORT_OPTIONS_DEFAULT.showTables,

        formControlName: 'showTables',
        formGroupName: null,
        options: []
    },
    {
        index: 4,
        title: 'Info Sheet',
        iconRef: 'preview',
        enabled: REPORT_OPTIONS_DEFAULT.showCultivarInfo,

        formControlName: 'showCultivarInfo',
        formGroupName: 'cultivarInfoOptions',

        options: [
            { title: 'Show Sample Origin', type: 'check', formControlName: 'showSampleOrigin' },
            { title: 'Include Characteristics', type: 'select-many', formControlName: 'includeChars' },
            { title: 'Include Measurement Charts', type: 'select-many', formControlName: 'includeMeasures'},
            { title: 'Include Index Charts', type: 'select-many', formControlName: 'includeIndexes'},
            { title: 'Image Size', type: 'select-one', formControlName: 'imageSize'}
        ]
    },
    {
        index: 5,
        title: 'Characteristics',
        iconRef: 'tune',
        enabled: REPORT_OPTIONS_DEFAULT.showCharSummary,

        formControlName: 'showCharSummary',
        formGroupName: 'charSummaryOptions',

        options: [
            { title: 'Include Characteristics', type: 'select-many', formControlName: 'includeChars' },
            { title: 'Dense Layout', type: 'check', formControlName: 'layoutDense'}
        ]
    },
    {
        index: 6,
        title: 'Measurements',
        iconRef: 'straighten',
        enabled: REPORT_OPTIONS_DEFAULT.showMeasureSummary,

        formControlName: 'showMeasureSummary',
        formGroupName: 'measureSummaryOptions',

        options: [
            { title: 'Include Measurements', type: 'select-many', formControlName: 'includeMeasures' },
            { title: 'Show Measurement Charts', type: 'check', formControlName: 'showCharts' },
            { title: 'Show Measurement Stats', type: 'check', formControlName: 'showStats'},
            { title: 'Show Measurement Data', type: 'check', formControlName: 'showData'},
        ]
    },
    {
        index: 7,
        title: 'Indexes',
        iconRef: 'memory',
        enabled: REPORT_OPTIONS_DEFAULT.showCharIndex,

        formControlName: 'showCharIndex',
        formGroupName: 'charIndexOptions',

        options: [
            { title: 'Include Indexes', type: 'select-many', formControlName: 'includeIndexes' },
            { title: 'Show Index Charts', type: 'check', formControlName: 'showCharts' },
            { title: 'Show Index Data', type: 'check', formControlName: 'showData'},
        ]
    },
    {
        index: 8,
        title: 'Images',
        iconRef: 'insert_photo',
        enabled: REPORT_OPTIONS_DEFAULT.showImages,

        formControlName: 'showImages',
        formGroupName: 'imageOptions',

        options: [
            { title: 'Image Size', type: 'select-one', formControlName: 'size' },
            { title: 'Show Site', type: 'check', formControlName: 'showSite' },
            { title: 'Show Planted Year', type: 'check', formControlName: 'showPlantedYear'},
            { title: 'Show Notes', type: 'check', formControlName: 'showNotes'},
            { title: 'Show Dates', type: 'check', formControlName: 'showDates'},
            { title: 'Overlay Info', type: 'check', formControlName: 'overlay'},
        ]
    },
    {
        index: 9,
        title: 'Notes',
        iconRef: 'comment',
        enabled: REPORT_OPTIONS_DEFAULT.showNotes,

        formControlName: 'showNotes',
        formGroupName: null,

        options: []
    },

];
