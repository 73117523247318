import { DetailRequest, FilterBuilder, Status, TastingEventService, translateCommonErrorStatus } from "@core/data";
import { TastingsEvaluationsService } from "@core/data/api/tastings-evaluation.service";
import { ImportSubjectType } from "@core/data/types/import-subject";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { forkJoin, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LoadTastingsEvaluationsIndex } from "../tastings-evaluations-index/tastings-evaluations-index.state-actions";
import { LoadTastingsEventIndex } from "../tasting-events-index/tasting-events-index.state-actions";
import { Injectable } from "@angular/core";

// TODO potentially make generic, if it becomes generic remove any references to tastings eval imports

export interface TastingsEvaluationImportFormStateModel {
    subjectKey: string;
    subjectType: ImportSubjectType;
    subject: Partial<TastingEvent>;
    status: Status;
    ownerOrgKey: string;
    dataKey: string;
    data: Partial<TastingEvent>; // look at this vs subject
    tastingEventOptions?: TastingEvent[]
}

const DEFAULTS: TastingsEvaluationImportFormStateModel = {
    subjectKey: null,
    subjectType: null,
    subject: null,
    status: Status.UNINITIALIZED,
    ownerOrgKey: null,
    dataKey: null,
    data: null,
    tastingEventOptions: [],
}

//? ACTIONS
export class InitGenericDialog {
    static readonly type = "[TastingsEvaluationImportForm] Init Generic Dialog"
    constructor(
        public ownerOrgKey: string,
        public subjectKey: string,
        public subjectType: ImportSubjectType,
    ) {}
}
export class InitTastingsEvaliationImportForm {
    static readonly type = "[TastingsEvaluationImportForm] Init Tasting Eval";
    constructor(
        public eventKey?: string,
    ) {}
}

export class InitTastingsEvaliationReimportForm {
    static readonly type = "[TastingsEvaluationImportForm] Init Tasting Eval Reimport";
    constructor(
        public eventKey: string,
    ) {}
}

export class LoadTastingEventInformation {
    static readonly type = "[TastingsEvaluationImportForm] Load Tasting Event";
    constructor(
        public eventKey ?: string,
    ) {}
}

export class DownloadTastingsEvaluationImportTemplate {
    static readonly type = "[TastingsEvaluationImportForm] Download Evaluations Template";
    constructor(
        public eventKey: string,
    ) {}
}

export class ImportTastingsEvaluations {
    static readonly type = "[TastingsEvaluationImportForm] Import Tastings Evals"
    constructor(
        public file: File,
        public date: string
    ) {}
}

export class ReimportTastingsEvaluations {
    static readonly type = "[TastingsEvaluationImportForm] Reimport Tastings Evals"
    constructor(
        public file: File,
    ) {}
}

export class LoadTastingEventOptions {
    static readonly type = "[TastingsEvaluationImportForm] Load Event Options"
    constructor() {}
}


@State<TastingsEvaluationImportFormStateModel>({
    name: 'tastings_evaluations_import',
    defaults: DEFAULTS
})
@Injectable()
export class TastingsEvaluationImportState {
    @Selector()
    static subject(state: TastingsEvaluationImportFormStateModel) {
        return state.subject;
    }

    constructor(
        private _tastingEventService: TastingEventService,
        private _tastingEvaluationService: TastingsEvaluationsService,
    ) {}

    @Action(InitGenericDialog, {cancelUncompleted: true})
    initGenericDialog(ctx: StateContext<TastingsEvaluationImportFormStateModel>, action: InitGenericDialog) {
        ctx.setState({
            ...DEFAULTS,
            ownerOrgKey: action.ownerOrgKey,
            subjectKey: action.subjectKey,
            subjectType: action.subjectType,
            status: Status.LOADING,
        });

        if (action.subjectType === ImportSubjectType.TASTINGS_EVALS) {
            return ctx.dispatch(new InitTastingsEvaliationImportForm(action.subjectKey));
        }

        if (action.subjectType === ImportSubjectType.TASTINGS_EVAL_REIMPORT) {
            return ctx.dispatch(new LoadTastingEventInformation(null))
            return forkJoin(this.loadTastingEventInformation(ctx));
        }


        return ctx.patchState({
            status: Status.OK,
        });
    }

    @Action(InitTastingsEvaliationImportForm, {cancelUncompleted: true})
    initTastingsEvaliationImportForm(ctx: StateContext<TastingsEvaluationImportFormStateModel>, action: InitTastingsEvaliationImportForm) {
        if (!action.eventKey) {
            ctx.patchState({
                subjectKey: null,
                subject: null,
            });


            return ctx.dispatch(new LoadTastingEventOptions);
        }

        return forkJoin(
            this.loadTastingEventInformation(ctx),
            this.loadTastingEventOptions(ctx),
        )
    }

    @Action(LoadTastingEventInformation)
    loadTastingEventInformation(ctx: StateContext<TastingsEvaluationImportFormStateModel>, action: LoadTastingEventInformation = null) {
        const state = ctx.getState();

        if (state.subjectKey && action){
            if (state.subjectKey === action.eventKey) return;
        }

        ctx.patchState({
            status: Status.LOADING,
        });

        const detail: DetailRequest = {
            related: [
                'indexSnapshot',
                'tastingsSamples',
                'tastingsSamples.tastingsSampleImage',
                'tastingsEventImage',
                // TODO remove after independent sample changes
                'tastingsSamples.harvestSample',
                'tastingsSamples.harvestSample.scionCultivar',
            ]
        };

        let eventKey = action ? action.eventKey : null;

        eventKey = eventKey ? eventKey : state.subjectKey;

        return this._tastingEventService.get(eventKey, detail)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            subjectKey: eventKey,
                            subject: result,
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }

    @Action(DownloadTastingsEvaluationImportTemplate)
    downloadTastingsEvaluationImportTemplate(ctx: StateContext<TastingsEvaluationImportFormStateModel>, action: DownloadTastingsEvaluationImportTemplate) {

        return this._tastingEvaluationService.downloadImportTemplate(action.eventKey)
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = "tasting-evaluation-import-template";
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn("TastingsEvaluationImport: Download Error ", error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(ImportTastingsEvaluations)
    importTastingsEvaluations(ctx: StateContext<TastingsEvaluationImportFormStateModel>, action: ImportTastingsEvaluations) {
        const state = ctx.getState();

        return this._tastingEvaluationService.import(state.ownerOrgKey, state.subjectKey, action.file, action.date)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE,
                        })
                    },
                    error => {
                        console.warn('TastingsEvaluationImportForm: Upload Error', error);

                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            )
    }

    @Action(ReimportTastingsEvaluations)
    reimportTastingsEvaluations(ctx: StateContext<TastingsEvaluationImportFormStateModel>, action: ReimportTastingsEvaluations) {
        const state = ctx.getState();

        return this._tastingEvaluationService.reImport(state.ownerOrgKey, state.subjectKey, action.file)
        .pipe(
            tap(
                result => {
                    ctx.patchState({
                        status: Status.COMPLETE,
                    })

                    return forkJoin(
                        ctx.dispatch(new LoadTastingsEvaluationsIndex),
                        ctx.dispatch(new LoadTastingsEventIndex),
                    )
                },
                error => {
                    console.warn('TastingsEvaluationImportForm: Upload Error', error);

                    ctx.patchState({
                        status: translateCommonErrorStatus(error),
                    });
                }
            )
        )
    }

    @Action(LoadTastingEventOptions)
    loadTastingEventOptions(ctx: StateContext<TastingsEvaluationImportFormStateModel>) {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.ownerOrgKey)
            .get();

        return this._tastingEventService.query(filter)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            tastingEventOptions: result.data,
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            tastingEventOptions: [],
                            status: Status.OK,
                        });
                    }
                )
            );
    }
}