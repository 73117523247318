import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, Output, EventEmitter } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { Sidenav } from '../sidenav/sidenav.service';
import { Location } from '@angular/common';
import { Title }  from '@angular/platform-browser';

@Component({
    selector: 'pv-view',
    templateUrl: './view.component.html',
    host: {
        class: 'pv-view-component',
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewComponent implements OnInit {

    @Input('toolbarTitle') set toolbarTitle(title: string){
        this.title = title;
        this._titleService.setTitle(title + ' | Culteva');
    }

    @Input() set returnTo(returnUrl: any){
        this.returnUrl = returnUrl;
        this.showMenu = !returnUrl;
    }

    @Input() toolbarColor: string = 'primary';

    @Input() showMenu = true;

    title: string = '';
    returnUrl: string;
    userObs: any;

    @HostBinding('class.print-mode')
    printMode = false;

    @Output('printModeChange')
    isPrintModeEmitter = new EventEmitter<boolean>();


    constructor(
        private _sidenav: Sidenav,
        private _route: ActivatedRoute,
        private _router: Router,
        private _location: Location,
        private _titleService: Title,
        private _changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

    home(){
        this._sidenav.open();
    }

    back(){
        this._location.back();
    }

    togglePrintMode(printNow = false){
        this.printMode = !this.printMode;
        this.isPrintModeEmitter.emit(this.printMode);
        this._changeDetector.markForCheck();

        if(printNow){
            setTimeout(() => {
                this.print();
            },  600);
        }

    }

    print(){
        window.print();
    }

    printModeChange(){
        return this.isPrintModeEmitter.asObservable();
    }

}