import { Injectable } from '@angular/core';
import { Status, translateCommonErrorStatus, Collection, CultivarService, SiteService, SearchRequest, SearchResult, Site, Cultivar, SearchResponse, Filter } from '@core/data';
import { State, Action, StateContext } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

export class InitModelSelect {
    static readonly type = "[ModelSelect] Init";
    constructor(public data: ModelSelectDialogData) {}
}

export class SearchModelSelect {
    static readonly type = "[ModelSelect] Set Search Text";
    constructor(public text: string) {}
}

export class GetLatestModel {
    static readonly type = "[ModelSelect] Get Latest";
}

export interface ModelSelectDialogData {
    orgKey: string;
    type: string;
}

export interface ModelSelectStateModel {
    status: Status;
    orgKey: string;
    type: string;
    text: string;
    data: Array<Site | Cultivar>;
}

const DEFAULTS: ModelSelectStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: '',
    type: '',
    text: '',
    data: [],
}

@State({
    name: 'model_select',
    defaults: DEFAULTS,
})
@Injectable()
export class ModelSelectState {

    constructor(
        private _cultivarService: CultivarService,
        private _siteService: SiteService,
    ) {}

    @Action(InitModelSelect)
    initModelSelect(ctx: StateContext<ModelSelectStateModel>, action: InitModelSelect) {

        if(!action.data.orgKey || !action.data.type) {
            ctx.patchState({
                ...DEFAULTS,
                status: Status.ERROR,
            });
        }

        return ctx.patchState({
            status: Status.OK,
            orgKey: action.data.orgKey,
            type: action.data.type,
            text: '',
            data: [],
        });
    }

    @Action(SearchModelSelect, {cancelUncompleted: true})
    searchModelSelect(ctx: StateContext<ModelSelectStateModel>, action: SearchModelSelect) {

        ctx.patchState({
            status: Status.LOADING,
            text: action.text,
        });

        if(action.text.length < 2) {
            ctx.patchState({
                status: Status.OK,
                data: [],
            });

            return of(null);
        }

    //     return this.search(ctx)
    //                 .pipe(
    //                     tap(
    //                         (result: SearchResult) => {

    //                             if(result.results.length < 1) {
    //                                 return ctx.dispatch(new GetLatestModel());
    //                             }

    //                             ctx.patchState({
    //                                 status: Status.OK,
    //                                 data: result.results,
    //                             });
    //                         },
    //                         error => {
    //                             this.handleError(ctx, error);
    //                         },
    //                     ),
    //                     catchError(e => {
    //                         return of(null);
    //                     })
    //                 );
    }

    @Action(GetLatestModel)
    getLatestModel(ctx: StateContext<ModelSelectStateModel>, action: GetLatestModel) {

        ctx.patchState({
            status: Status.EMPTY,
        });

        return this.query(ctx)
                    .pipe(
                        tap(
                            result => {

                                ctx.patchState({
                                    data: result.data,
                                });

                            },
                            error => {
                                this.handleError(ctx, error);
                            },
                        ),
                        catchError(e => {
                            return of(null);
                        }),
                    );

    }

    private search(ctx: StateContext<ModelSelectStateModel>) {

        let state = ctx.getState();

        let searchRequest: SearchRequest = {
            ownerOrgKey: state.orgKey,
            text: state.text,
            limit: 20,
        }

        switch (state.type) {

            case 'cultivar':
                return this._cultivarService.search(searchRequest);

            case 'site':
                return this._siteService.search(searchRequest);

            default:
                return throwError(new Error('Model Type Not Provided'));
        }

    }

    private query(ctx: StateContext<ModelSelectStateModel>): Observable<Collection<Cultivar | Site>> {

        let state = ctx.getState();

        let filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    value: state.orgKey,
                },
            ],
            page: {
                index: 0,
                size: 10,
            },
        }

        switch (state.type) {

            case 'cultivar':
                return this._cultivarService.query(filter);

            case 'site':
                return this._siteService.query(filter);

            default:
                return throwError(new Error('Model Type Not Provided'));
        }

    }

    private handleError(ctx: StateContext<ModelSelectStateModel>, error: any){

        const state = ctx.getState();

        ctx.patchState({
            ...DEFAULTS,
            status: translateCommonErrorStatus(error),
            orgKey: state.orgKey,
        });
    }
}