import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { DownloadTastingsEvaluationImportTemplate, ImportTastingsEvaluations, InitGenericDialog, InitTastingsEvaliationImportForm, LoadTastingEventInformation, ReimportTastingsEvaluations, TastingsEvaluationImportFormStateModel, TastingsEvaluationImportState } from "./tastings-evaluation-import-form.state";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ImportSubjectType } from "@core/data/types/import-subject";
import { debounceTime, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Snackbar } from "@core/material";
import * as moment from "moment";
import { coerceTimestampProperty } from "@core/utils";
import { Status } from "@core/data";

export interface TastingEvaluationsImportFormDialogData {
    orgKey: string;
    subjectKey: string;
    subjectType: ImportSubjectType,
}

@Component({
    selector: 'pv-tasting-evaluation-import-form-dialog',
    templateUrl: 'tastings-evaluation-import-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tasting-evaluation-import-form-dialog'
    }
})
export class TastingEvaluationsFormDialog implements OnInit, OnDestroy {
    @Select(TastingsEvaluationImportState)
    state$: Observable<TastingsEvaluationImportFormStateModel>;

    @Select(TastingsEvaluationImportState.subject)
    subject$: Observable<TastingEvent>;

    // FORM CONTROLS
    subjectKeyControl = new FormControl(null, [Validators.required]);
    ownerOrgKeyControl = new FormControl(null);
    dateControl = this.hasDateControl(this.data.subjectType) ? new FormControl(null, [Validators.required]) : new FormControl(null);
    timeControl = this.hasDateControl(this.data.subjectType) ? new FormControl(null, [Validators.required]) : new FormControl(null);

    importFormGroup = new FormGroup({
        file: new FormControl(null, [Validators.required]),
    });

    formGroup = new FormGroup({
        subjectKey: this.subjectKeyControl,
        ownerOrgKey: this.ownerOrgKeyControl,
        scoreDate: this.dateControl,
        scoreTime: this.timeControl,
        import: this.importFormGroup,
    })

    eventIndexSnapshot = null;

    public defaultBannerPath = "cloudinary:provar/culteva/tastings/default-banner";

    private _destroy$ = new Subject();

    constructor(
        private _snackbar: Snackbar,
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingEvaluationsImportFormDialogData>,
        @Inject(MAT_DIALOG_DATA) public data: TastingEvaluationsImportFormDialogData,
    ) {}

    ngOnInit(): void {
        this._store.dispatch(
            new InitGenericDialog(this.data.orgKey, this.data.subjectKey, this.data.subjectType)
        );

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.subject);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.subject$.pipe(takeUntil(this._destroy$))
            .subscribe(subject => {
                if (subject) {
                    this.reset(subject)

                    if (this.isEvaluationImport(this.data.subjectType)) {
                        this.eventIndexSnapshot = subject.indexSnapshot;
                    }
                }
            })

        this.subjectKeyControl.valueChanges.pipe(
            takeUntil(this._destroy$),
            debounceTime(300),
        ).subscribe(value => {
            if (!value) return;

            this._store.dispatch(
                new LoadTastingEventInformation(value)
            );
        })
    }

    reset(model: Partial<TastingEvent>) {
        const data = {
            subjectKey: model.key,
            ownerOrgKey: model.ownerOrgKey,
            scoreDate: null,
            scoreTime: null,
            import: {
                file: null,
            }
        }

        this.formGroup.reset(data);
    }

    getMidStepHeading(type: ImportSubjectType) {
        if (type === ImportSubjectType.TASTINGS_EVALS) return "Sample Info";
        if (type === ImportSubjectType.TASTINGS_EVAL_REIMPORT) return "Evaluations Reimport";

        return "Step 2"
    }

    currentTypeHasDetail(type: ImportSubjectType) {
        if (type === ImportSubjectType.TASTINGS_EVALS) return true;

        return false
    }

    hasDateControl(type: ImportSubjectType) {
        if (type === ImportSubjectType.TASTINGS_EVALS) return true;

        return false;
    }

    hasImportTemplate(type: ImportSubjectType) {
        if (type === ImportSubjectType.TASTINGS_EVALS) return true;

        return false;
    }

    isEvaluationImport(type: ImportSubjectType) {
        return type === ImportSubjectType.TASTINGS_EVALS;
    }

    setFormFile(file: File) {
        this.importFormGroup.get('file').setValue(file);
    }

    getFormFile() {
        return this.importFormGroup.get('file').value;
    }

    downloadImportTemplate(key: string, subject: TastingEvent) {
        if (this.isEvaluationImport(this.data.subjectType)) {
            if (!subject.indexSnapshot) return this._snackbar.error("No Index Connected to the selected Event");

            return this._store.dispatch(new DownloadTastingsEvaluationImportTemplate(key));
        }

        return this._snackbar.error("Tempalte currently unavailable for the selected import");
    }

    isStaticSubject() {
        return this.data.subjectKey ? true : false;
    }

    attempt() {
        if (!this.formGroup.valid) return this._snackbar.error("Invalid Input, Check your input and try again again.");

        if (this.isEvaluationImport(this.data.subjectType) && !this.eventIndexSnapshot) return this._snackbar.error("Event requires an Inedx to import.");

        const form = this.formGroup.value;

        const file: File = this.importFormGroup.value.file;
        const date: string = this.handleTime(form.scoreDate, form.scoreTime)

        if (this.data.subjectType === ImportSubjectType.TASTINGS_EVALS) {
            this.importTastingsEvaluations(file, date);
        }

        if (this.data.subjectType === ImportSubjectType.TASTINGS_EVAL_REIMPORT) {
            this.reImportTastingsEvaluations(file);
        }
    }

    private handleTime(eventDate, time) {
        let formatted = moment(eventDate).format("YYYY-MM-DD");

        return coerceTimestampProperty(
            moment(formatted + " " + time, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss")
        );
    }

    importTastingsEvaluations(file: File, date: string) {
        this._store.dispatch(new ImportTastingsEvaluations(file, date));
    }

    reImportTastingsEvaluations(file: File) {
        this._store.dispatch(new ReimportTastingsEvaluations(file));
    }

    cancel() {
        this._dialogRef.close(false);
    }

    ngOnDestroy(): void {
        this.formGroup.reset();
        this._destroy$.next();
        this._destroy$.complete();
    }
}