import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';

import { MapMarkerDirective } from './directives/map-marker.directive';
import { MapPolygonDirective } from './directives/map-polygon.directive';
import { MapInfoWindowDirective } from './directives/map-info-window.directive';
import { MapComponent } from './map.component';

import { CoordinatesPipe } from './pipes/coords.pipe';
import { AreaPipe } from './pipes/area.pipe';
import { MapsApiLoader } from './maps-api-loader';
import { GeocoderService } from './geocoder-service';
import { MapPolygonAreaDirective } from './directives/map-polygon-area.directive';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CoordinatesPipe,
        AreaPipe,
        MapComponent,
        MapMarkerDirective,
        MapPolygonDirective,
        MapInfoWindowDirective,
        MapPolygonAreaDirective
    ],
    exports: [
        CoordinatesPipe,
        AreaPipe,
        MapComponent,
        MapMarkerDirective,
        MapPolygonDirective,
        MapInfoWindowDirective,
        MapPolygonAreaDirective
    ],
    providers: [
        MapsApiLoader,
        GeocoderService,
    ]
})
export class MapModule {}

export * from './types';
export * from './map.component';
export * from './google-apis';
export * from './geocoder-service';
