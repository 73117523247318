import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Site, Sample } from '@core/data';

@Pipe({
  name: 'descSpacing',
})
export class DescribeSpacing implements PipeTransform {

    constructor(@Inject(LOCALE_ID) public locale: string){}


    transform(sample: Site | Sample) {

        let col = sample.colDistance;
        let row = sample.rowDistance;

        if(col && row) {
            let density = 10000000000 / (col * row);
            return this.meter(col) + ' x ' + this.meter(row) + " (" + formatNumber(density, this.locale, '1.0-0') + " /ha)";
        }

        return '';
    }

    meter(spacing: any) {
        if(spacing >= 1000) return formatNumber(spacing/1000, this.locale, '1.1-1') + 'm';
        return spacing += 'mm';
    }
}
