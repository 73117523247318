import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@app/auth";
import { SubscriptionGuard } from "@app/auth/subscription.guard";
import { TastingContactIndexView } from "./components/tasting-contact-index/tasting-contact-index.view";
import { TastingsEventsIndexView } from "./components/tasting-events-index/tasting-events-index.view";
import { TastingsSampleIndexView } from "./components/tastings-sample-index/tastings-sample-index.view";
import { TastingsEvaluationsIndexView } from "./components/tastings-evaluations-index/tastings-evaluations-index.view";
import { TastingCustomIndexesIndexView } from "./components/tasting-custom-indexes-index/tasting-custom-indexes-index.view";
import { TastingCustomTagsIndexView } from "./components/tasting-custom-tags-index/tasting-custom-tags-index.view";
import { TastingCustomWeightedCharsIndexView } from "./components/tasting-custom-weighted-chars-index/tasting-custom-weighted-chars-index.view";

const routes: Routes = [
    //Routes

	//Tasting Samples
	{ path: 'org/:orgKey/tastings-samples',  component: TastingsSampleIndexView, canActivate: [AuthGuard, SubscriptionGuard] },
	{ path: 'org/:orgKey/tastings-events',  component: TastingsEventsIndexView, canActivate: [AuthGuard, SubscriptionGuard] },

	//Tasting Contacts
	{ path: 'org/:orgKey/tastings-contacts',  component: TastingContactIndexView, canActivate: [AuthGuard, SubscriptionGuard] },

	//Tasting Scores
	{ path: 'org/:orgKey/tastings-scores/:type/:subjectKey', component: TastingsEvaluationsIndexView, canActivate: [AuthGuard, SubscriptionGuard]},
	// Tasting Indexes
	{ path: 'org/:orgKey/tastings-indexes',  component: TastingCustomIndexesIndexView, canActivate: [AuthGuard, SubscriptionGuard] },

	// Tasting Tags
	{ path: 'org/:orgKey/tastings-tags',  component: TastingCustomTagsIndexView, canActivate: [AuthGuard, SubscriptionGuard] },

	// Tasting Weighted Characteristics
	{ path: 'org/:orgKey/tastings-weighted-chars',  component: TastingCustomWeightedCharsIndexView, canActivate: [AuthGuard, SubscriptionGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class TastingsRoutingModule {}