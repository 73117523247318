<ng-container *ngIf="state$ | async as state">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>Generate Tastings Samples</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="cultivar_tastings_sample_form" [formGroup]="formGroup" #cultivarTastingsSampleForm="ngForm"
            class="pv-form" (ngSubmit)="attempt()" autocomplete="off">

            <div class="form-column">
                <h4 class="form-subheader">Cultivars</h4>

                <div class="list" formArrayName="cultivarSampleRows">
                    <div class="list-item" *ngFor="let cultivar of state.cultivars; let idx=index"
                        [formGroupName]="idx">
                        <!-- CULTIVAR -->
                        <div class="list-item-group">
                            <mat-icon [svgIcon]="'common:' + cultivar.cropId" class="mat-24"></mat-icon>
                            <small>{{cultivar.commonName}}</small>
                        </div>
                        <!-- CULTIVAR END -->

                        <div class="list-item-group">
                            <!-- SITE -->
                            <mat-form-field appearance="standard">
                                <mat-label>
                                    <mat-icon>place</mat-icon>
                                    <span>&nbsp;Site</span>
                                </mat-label>
                                <pv-site-autocomplete [orgKey]="ownerOrgKeyControl.value" formControlName="siteKey">
                                </pv-site-autocomplete>
                                <button type="button" mat-icon-button matSuffix (click)="addSite($event, idx)"
                                    matTooltip="Add new site" *ngIf="!currentSiteControl(idx).value">
                                    <mat-icon>add_box</mat-icon>
                                </button>
                                <mat-error>Select a site</mat-error>
                            </mat-form-field>
                            <!-- SITE END -->

                            <!-- HARVEST DATE -->
                            <mat-form-field appearance="standard">
                                <mat-label>Harvest Date</mat-label>
                                <input matInput formControlName="harvestDate" [matDatepicker]="harvestDatePicker">
                                <mat-datepicker-toggle matSuffix [for]="harvestDatePicker">
                                </mat-datepicker-toggle>
                                <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                                <mat-hint>Defaults to today if no date is entered</mat-hint>
                                <mat-datepicker #harvestDatePicker startView="multi-year"></mat-datepicker>
                            </mat-form-field>
                            <!-- HARVEST DATE END -->

                            <!-- CONNECT HARVEST -->
                            <div class="harvest-sample-item">
                                <mat-checkbox
                                    formControlName="connectHarvestSample"
                                    class="mat-caption"
                                    [matTooltip]="data.hasEvaluationModule ? 'Creates a new Harvest Sample that is connceted to the Tastings Sample' : '*Requires Evaluations Module'"
                                >
                                    <mat-icon mat-list-icon svgIcon="common:harvest_sample"></mat-icon>
                                </mat-checkbox>
                            </div>
                            <!-- CONNECT HARVEST END -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Global Fields</h4>

                <!-- GLOBAL SITE -->
                <mat-form-field appearance="standard">
                    <mat-label>
                        <mat-icon>place</mat-icon>
                        <span>&nbsp;Global Site</span>
                    </mat-label>
                    <pv-site-autocomplete [orgKey]="ownerOrgKeyControl.value" formControlName="globalSite">
                    </pv-site-autocomplete>
                    <button type="button" mat-icon-button matSuffix (click)="addSite($event)" matTooltip="Add new site"
                        *ngIf="!globalSiteControl.value">
                        <mat-icon>add_box</mat-icon>
                    </button>
                    <mat-error>Select a site</mat-error>
                    <mat-hint>Set this field to set all Sites at once</mat-hint>
                </mat-form-field>
                <!-- GLOBAL SITE END -->

                <!-- GLOBAL HARVEST DATE -->
                <mat-form-field appearance="standard">
                    <mat-label>Global Harvest Date</mat-label>
                    <input matInput formControlName="globalHarvestDate" [matDatepicker]="globalHarvestDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="globalHarvestDatePicker">
                    </mat-datepicker-toggle>
                    <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                    <mat-hint>Set this field to set all Harvest Dates</mat-hint>
                    <mat-datepicker #globalHarvestDatePicker startView="multi-year"></mat-datepicker>
                </mat-form-field>
                <!-- GLOBAL HARVEST DATE END -->

                <br/>

                <!-- GLOBAL CONNECT HARVEST -->
                <ng-container>
                    <mat-label [class.module-missing]="!data.hasEvaluationModule">Global Connect Harvest Sample</mat-label>
                    <mat-checkbox
                        formControlName="globalConnectHarvest"
                        class="mat-caption"
                        [matTooltip]="data.hasEvaluationModule ? 'Set this field to set all Connected Harvest boxes at once' : '*Requires Evaluations Module'"
                    >
                        <mat-icon mat-list-icon svgIcon="common:harvest_sample"></mat-icon>
                    </mat-checkbox>
                </ng-container>
                <!-- GLOBAL CONNECT HARVEST END -->
            </div>

        </form>
    </div>

    <pv-form-actions mat-dialog-actions [form]="cultivarTastingsSampleForm" (cancel)="cancel()" [status]="state.status">
    </pv-form-actions>

</ng-container>