import { CropSubject } from "./_types";


export const LIB_SUBJECTS: CropSubject[] = [

    // plant subjects
    {
        id: "tree",
        label: "Tree"
    },
    {
        id: "tree_shoot",
        label: "Shoot"
    },
    {
        id: "vine",
        label: "Vine"
    },
    {
        id: "vine_shoot",
        label: "Shoot"
    },
    {
        id: "pre_cropping",
        label: "Pre-Cropping"
    },
    {
        id: "plant",
        label: "Plant"
    },

    // plant subjects with stages
    {
        id: "plant_seed",
        label: "Plant - Seeding"
    },
    {
        id: "plant_veg",
        label: "Plant - Vegetative"
    },
    {
        id: "plant_flower",
        label: "Plant - Flowering"
    },
    {
        id: "plant_harvest",
        label: "Plant - Post-Harvest"
    },

    // harvest subjects
    {
        id: "fruit",
        label: "Fruit",
    },
    {
        id: "bunch",
        label: "Bunch"
    },
    {
        id: "berry",
        label: "Berry"
    },
    {
        id: "citrus",
        label: "Citrus"
    },
    {
        id: "avocado",
        label: "Avocado"
    },
    {
        id: "raisin",
        label: "Raisin"
    },
    {
        id: "flower",
        label: "Flower"
    },
    {
        id: "terpenes",
        label: "Terpenes"
    },
    {
        id: "bud",
        label: "Bud"
    },
    {
        id: "cannabinoids",
        label: "Cannabinoids"
    },
    {
        id: "yield",
        label: "Yield"
    },
    {
        id: "plant_yield",
        label: "Yield"
    },
    {
        id: "husk",
        label: "Husk"
    },

    {
        id: "nut_in_husk",
        label: "Nut in Husk"
    },
    {
        id: "nut_in_shell",
        label: "Nut in Shell"
    },
    {
        id: "kernel",
        label: "Kernel"
    },
    {
        id: "kernel_disease",
        label: "Kernel Diseases, Disorders and Blemishes"
    },

    //calculated subjects (values calculated at compile time)
    {
        id: "tree_calculated",
        label: "Tree Calculated Values"
    },
    {
        id: "nut_calculated",
        label: "Nut Calculated Values"
    },
    {
        id: "fruit_calculated",
        label: "Fruit Calculated Values"
    },


    // default subject
    {
        id: "unknown",
        label: "Unknown"
    },
];