

import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreUtilsModule } from '@core/utils';
import { MomentModule } from 'ngx-moment';
import { ColorpickerComponent } from './components/colorpicker/colorpicker.component';
import { APP_UI_DIALOG_CONFIG } from './components/dialog/dialog-config';
import { Dialog } from './components/dialog/dialog.service';
import { SimpleDialogComponent } from './components/dialog/simple-dialog.component';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { PrintViewComponent } from './components/print-view/print-view.component';
import { Sidenav } from './components/sidenav/sidenav.service';
import { SnackLinkComponent } from './components/snackbar/snack-link.component';
import { SnackMessageComponent } from './components/snackbar/snack-message.component';
import { Snackbar } from './components/snackbar/snackbar.service';
import { ImageDialog } from './components/thumb/image.dialog';
import { ThumbComponent } from './components/thumb/thumb.component';
import { SearchbarComponent } from './components/view/searchbar.component';
import { ViewLoaderComponent } from './components/view/view-loader.component';
import { ViewMessageComponent } from './components/view/view-message.component';
import { ViewComponent } from './components/view/view.component';
import { PV_DATE_FORMATS } from './date/date-formats';
import {TextFieldModule} from '@angular/cdk/text-field';
import { CoreDataModule } from '@core/data';
import { ImageRefComponent } from './components/image-ref/image-ref.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { LegacyFileUploadComponent } from './components/legacy-file-upload/legacy-file-upload.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CoreDataModule,

        CoreUtilsModule,

        // FlexLayoutModule,
        MomentModule,

        CdkTableModule,

        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatMenuModule,
        MatToolbarModule,
        MatListModule,
        MatCardModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        MatSidenavModule,
        MatInputModule,
        MatMomentDateModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatBadgeModule,

        MatTreeModule,
        ScrollingModule,
        OverlayModule,
        PortalModule,
        A11yModule,
        TextFieldModule,
        DragDropModule,
        MatButtonToggleModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,

        // FlexLayoutModule,
        MomentModule,

        CdkTableModule,

        MatTabsModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatMenuModule,
        MatToolbarModule,
        MatListModule,
        MatCardModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        MatInputModule,
        MatPaginatorModule,
        MatMomentDateModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatTreeModule,
        MatBadgeModule,

        ThumbComponent,
        DropzoneComponent,
        FormActionsComponent,
        MarkdownComponent,
        MatAutocompleteModule,
        MatChipsModule,

        SearchbarComponent,
        ViewComponent,
        ViewMessageComponent,
        ViewLoaderComponent,
        PrintViewComponent,
        ScrollingModule,
        ColorpickerComponent,
        TextFieldModule,
        ImageRefComponent,
        DragDropModule,
        LegacyFileUploadComponent,
        MatButtonToggleModule,
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: PV_DATE_FORMATS },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: APP_UI_DIALOG_CONFIG },
    ],
    declarations: [
        ThumbComponent,
        DropzoneComponent,
        FormActionsComponent,
        ImageDialog,
        MarkdownComponent,
        SimpleDialogComponent,
        SnackLinkComponent,
        SnackMessageComponent,
        SearchbarComponent,
        ViewComponent,
        ViewMessageComponent,
        ViewLoaderComponent,
        PrintViewComponent,
        ColorpickerComponent,
        ImageRefComponent,
        LegacyFileUploadComponent,
    ],
    entryComponents: [
        ImageDialog,
        SnackMessageComponent,
        SnackLinkComponent,
        SimpleDialogComponent,
    ]
})
export class AppMaterialModule {

    static forRoot(): ModuleWithProviders<AppMaterialModule> {
        return {
            ngModule: AppMaterialModule,
            providers: [
                Dialog,
                Sidenav,
                Snackbar,
            ]
        };
    }


    constructor(mdIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        mdIconRegistry.addSvgIconSetInNamespace(
            'culteva',
            sanitizer.bypassSecurityTrustResourceUrl(
                'assets/sprites/culteva-sprites.v1.svg'
            )
        );
        mdIconRegistry.addSvgIconSetInNamespace(
            'common',
            sanitizer.bypassSecurityTrustResourceUrl(
                'assets/sprites/common-sprites.v1.svg'
            )
        );
        mdIconRegistry.addSvgIconSetInNamespace(
            'fa',
            sanitizer.bypassSecurityTrustResourceUrl(
                'assets/sprites/fontawesome-sprites.svg'
            )
        );
    }
}