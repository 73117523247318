<pv-view #view
         *ngIf="state$ | async as state"
         toolbarTitle="Tasting Sample Index">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools *ngIf="selectedOrg$ | async as selectedOrg">

        <button mat-icon-button
                matTooltip="Sample Index Actions"
                [matMenuTriggerFor]="sampleIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #sampleIndexMenu>
            <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                <button mat-menu-item (click)="add()">
                    <mat-icon>add</mat-icon>
                    <span>Add Sample</span>
                </button>
            </ng-container>
            <button mat-menu-item (click)="exportHistory(state.orgKey)" type="button">
                <mat-icon>history</mat-icon>
                <span>Export History</span>
            </button>
            <button mat-menu-item (click)="reload()">
                <mat-icon>refresh</mat-icon>
                <span>Reload Sample Index</span>
            </button>
            <button mat-menu-item (click)="view.togglePrintMode()">
                <mat-icon>print</mat-icon>
                <span>Print Preview</span>
            </button>
            <button mat-menu-item (click)="navigateToSupport()">
                <mat-icon>help_outline</mat-icon>
                <span>Support/Help</span>
            </button>
        </mat-menu>

    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="tastings-sample-selection-toolbar mat-elevation-z3" color="accent"
                 *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Tasting Samples Selected
        </span>

        <button disabled mat-flat-button (click)="createReport()">
            <mat-icon>insert_chart</mat-icon>
            <span>Create Report</span>
        </button>

    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->


    <!-- SIDEBAR -->
    <div pv-view-sidebar *ngIf="selectedOrg$ | async as selectedOrg">


        <mat-tab-group [(selectedIndex)]="selectedTabIndex">

            <!-- FILTER TAB -->
            <mat-tab label="Filter">

                <ng-template matTabContent>
                    <form class="pv-form single-column dense p-12 tastings-sample-index-form"
                          [formGroup]="queryFormGroup">

                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>search</mat-icon> Search Tasting Samples
                            </mat-label>
                            <input type="text" matInput formControlName="search"
                                   placeholder="Enter tasting sample code or label">
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:tastings_events"></mat-icon>
                                <span>Filter Event(s)</span>
                            </mat-label>
                            <pv-tasting-event-autocomplete-chips formControlName="tastingsEvent"
                                                        [orgKey]="state.orgKey">
                            </pv-tasting-event-autocomplete-chips>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:harvest_sample"></mat-icon>
                                <span>Filter Harvest Sample(s)</span>
                            </mat-label>
                            <pv-sample-autocomplete-chips formControlName="harvestSample"
                                                        sampleType="harvest"
                                                        [orgKey]="state.orgKey">
                            </pv-sample-autocomplete-chips>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon svgIcon="common:flat_scion"></mat-icon>
                                <span>Filter Scion Cultivar(s)</span>
                            </mat-label>
                            <pv-cultivar-autocomplete-chips formControlName="scionCultivars"
                                                            [orgKey]="state.orgKey">
                            </pv-cultivar-autocomplete-chips>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <mat-icon>place</mat-icon>
                                <span>Filter Location(s)</span>
                            </mat-label>
                            <pv-site-autocomplete-chips formControlName="sites"
                                                        [orgKey]="state.orgKey">
                            </pv-site-autocomplete-chips>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Crop</mat-label>
                            <mat-select formControlName="scionCropId" multiple>
                                <mat-option *ngFor="let crop of cropOptions" [value]="crop.id">
                                    <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                                    <span>{{ crop.label }}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Filter Licensee</mat-label>
                            <mat-select formControlName="scionLicensee" multiple>
                                <mat-option *ngFor="let licensee of state.licenseeOptions"
                                            [value]="licensee">{{ licensee }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">

                            <button mat-button type="button" color="warn"
                                    (click)="resetFilter()">
                                Clear Filters
                            </button>
                        </div>

                    </form>

                </ng-template>

            </mat-tab>

            <!-- EXPORT TAB -->
            <mat-tab label="Export">

                <ng-template matTabContent>
                    <form [formGroup]="exportFormGroup"
                          class="pv-form single-column p-12 sample-export-form"
                          (ngSubmit)="export()">

                        <ng-container *ngIf="filterSelectedLabels">
                            <div class="form-subheader text-primary">
                                Samples Selected
                            </div>
                            <div class="form-caption">
                                Only selected samples will be exported.
                                Clear selection to export all samples in current filter.
                            </div>
                        </ng-container>

                        <mat-form-field appearance="fill">
                            <mat-label>File Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="xlsx">Microsoft Excel (.xlsx)</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">

                            <button mat-flat-button color="accent" type="submit"
                                    [disabled]="exportFormGroup.disabled">Export</button>
                        </div>
                    </form>

                    <mat-action-list dense>
                        <mat-divider></mat-divider>

                        <ng-container *ngFor="let export of state.exports; trackBy: trackByKey"
                                      [ngSwitch]="export.status">
                            <a mat-list-item [href]="export.url" target="_blank"
                               *ngSwitchCase="'COMPLETE'">
                                <mat-icon matListIcon>email</mat-icon>
                                <span matLine>Export Queued</span>
                                <span matLine>Check your email inbox for result.</span>
                            </a>
                            <button type="button" mat-list-item *ngSwitchCase="'LOADING'">
                                <mat-icon matListIcon>queue</mat-icon>
                                <span matLine>Queueing Export...</span>
                                <mat-spinner [diameter]="18"></mat-spinner>
                            </button>
                            <button type="button" mat-list-item *ngSwitchDefault>
                                <mat-icon matListIcon color="warn">error</mat-icon>
                                <span matLine>Export Failed</span>
                                <span matLine>{{ export.status | descStatusMessage }}</span>
                            </button>
                        </ng-container>

                    </mat-action-list>
                </ng-template>

            </mat-tab>

            <!-- BULK ACTION TAB -->
            <mat-tab label="Bulk Action">
                <ng-container *matTabContent>
                    <mat-action-list class="station-detail-actions" dense>
                        <h2 matSubheader>Event Actions</h2>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <mat-list-item (click)="$event.preventDefault(); bulkAddToEvent(state.selectedData)">
                                <mat-icon matListIcon svgIcon="common:add_sample"></mat-icon>
                                <span matLine>Add Samples to Event</span>
                            </mat-list-item>

                            <mat-list-item (click)="$event.preventDefault(); bulkRemoveFromEvent(state.selectedData)">
                                <mat-icon matListIcon svgIcon="common:remove_sample"></mat-icon>
                                <span matLine>Remove Samples from Event</span>
                            </mat-list-item>
                        </ng-container>

                        <h2 matSubheader>Other</h2>
                        <mat-divider></mat-divider>
                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <mat-list-item (click)="$event.preventDefault()" (click)="deleteSelected(state.selectedKeys, state.selectedData)">
                                <mat-icon matListIcon>delete</mat-icon>
                                <span matLine>Delete Samples</span>
                            </mat-list-item>
                        </ng-container>
                    </mat-action-list>
                </ng-container>
            </mat-tab>
            <!-- BULK ACTION TAB END -->

        </mat-tab-group>

    </div>
    <!-- SIDEBAR END -->


    <!-- PAGINATION & SORT FOOTER -->
    <div pv-view-footer class="pv-table-footer">

        <label class="mat-caption mr-12" for="sample_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                    id="sample_index_sort"
                    class="tastings-sample-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">{{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon
                      [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                       [pageSize]="state.page.size"
                       [pageIndex]="state.page.index"
                       (page)="paginate($event)"
                       [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>
    <!-- PAGINATION & SORT FOOTER END-->


    <!-- FAB -->
    <ng-container pv-view-fab *ngIf="selectedOrg$ | async as selectedOrg">
        <ng-container pv-view-fab *ngIf="selectedOrg?.access.role != 'guest'">
            <button pv-view-fab *ngIf="selection.isEmpty()" mat-fab color="primary" (click)="add()" matTooltip="Add Sample">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-container>


    <!-- BODY STATUS: OK -->
    <div pv-view-body class="tastings-sample-index-body" [ngSwitch]="state.status">


        <!-- STATUS MESSAGE: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Samples..."></pv-view-loader>


        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'"
                         heading="No results"
                         message="The current filters does not match any Samples. Update the filters or add a Sample."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction
                    (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="add()">
                Add
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: INVALID -->
        <pv-view-message *ngSwitchCase="'INVALID'"
                         heading="Invalid Filter Parameters"
                         message="Unable to load data with given filter and sort parameters."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">
                Reload
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                         [message]="state.status | descStatusMessage:'Sample'" icon="error">
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">Reload</button>
        </pv-view-message>


        <!-- STATUS MESSAGE: OK (ngSwitch placeholder) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>


        <!-- TABLE / LABELS -->
        <ng-container [ngSwitch]="selectedTabIndex"
                      *ngIf="state.status === 'OK' || state.status === 'LOADING'">


            <pv-tastings-sample-labels
                *ngSwitchCase="3"
                [samples]="filterSelectedLabels ? state.selectedData : state.data"
                [options]="state.labelOptions">
            </pv-tastings-sample-labels>


            <!-- TABLE -->
            <div class="pv-responsive-table">

                <table cdk-table
                       *ngSwitchDefault
                       class="pv-tastings-sample-table"
                       [dataSource]="state.data"
                       [trackBy]="trackByKey"
                       matSort
                       [matSortActive]="state.sort.column"
                       [matSortDirection]="state.sort.order"
                       [matSortDisableClear]="true"
                       (matSortChange)="sort($event)">

                    <!-- Column: Image -->
                    <ng-container cdkColumnDef="image">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle(state.data) : null"
                                          [checked]="selection.hasValue() && isAllSelected(state.data)"
                                          [indeterminate]="selection.hasValue() && !isAllSelected(state.data)"
                                          class="no-print">
                            </mat-checkbox>
                        </th>
                        <td cdk-cell *matCellDef="let row">

                            <pv-image-ref *ngIf="getImage(row) as image"
                                          [fileRef]="image.fileRef"
                                          transformId="thumb_72">
                            </pv-image-ref>

                            <div class="image-overlay no-print" (click)="selection.toggle(row.key)">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row.key) : null"
                                              [checked]="selection.isSelected(row.key)">
                                </mat-checkbox>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column: Sample Code -->
                    <ng-container cdkColumnDef="code">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="code">Code</th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="hide">{{ row.key }}</span>
                            <span class="title-line">{{ row.code }}</span>
                            <span class="body-line">{{ row.label }}</span>
                            <span class="caption-line">{{ row.description }}</span>
                        </td>
                    </ng-container>

                    <!-- Column: Actions -->
                    <ng-container cdkColumnDef="actions">
                        <th cdk-header-cell *cdkHeaderCellDef class="no-print"></th>
                        <td cdk-cell *matCellDef="let row" class="no-print">

                            <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                                <button mat-icon-button
                                        (click)="$event.stopPropagation()"
                                        [matMenuTriggerFor]="sampleMenu"
                                        [matMenuTriggerData]="{sample: row}"
                                        matTooltip="More Sample Actions">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>


                    <!-- Column: Birth Date -->
                    <ng-container cdkColumnDef="birthDate">
                        <th cdk-header-cell *cdkHeaderCellDef >
                            Harvest Date
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="body-line">
                                {{ row?.birthDate ? (row?.birthDate | amDateFormat:'[Week] W') : 'Not specified' }}
                            </span>
                            <time class="body-line">
                                {{ row?.birthDate ? (row?.birthDate | amDateFormat:'ll') : 'Not specified' }}
                            </time>
                            <small class="caption-line">
                                Harvested {{ row?.birthDate ? (row?.birthDate | amTimeAgo) : 'Not specified' }}
                            </small>
                        </td>
                    </ng-container>

                    <!-- Column: Harvest Sample -->
                    <ng-container cdkColumnDef="harvestSamples">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="harvestSampleKey">
                            Harvest Sample
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span [pvSampleLine]="row.harvestSample"
                                  (click)="$event.stopPropagation()">
                            </span>
                        </td>
                    </ng-container>

                    <!-- Column: Event -->
                    <ng-container cdkColumnDef="tastingsEvents">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="tastingsEventKey">
                            Tastings Event
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <ng-container *ngFor="let event of row.tastingsEvents">
                                <span *ngIf="event"
                                    [pvTastingEventLine]="event"
                                    (click)="$event.stopPropagation();"
                                    matTooltip="{{event.title}} - {{event.code}}"
                                    [routerLink]="['/org', row.ownerOrgKey, 'tastings-events', {eventCode: event.code}]">
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>

                    <!-- Column: Size -->
                    <ng-container cdkColumnDef="size">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="size">Size</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.size">{{ row.size }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Timestamps -->
                    <ng-container cdkColumnDef="timestamps">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="createdAt">Timestamps
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="caption-line">Added
                                {{ row.createdAt | amDateFormat:'ll' }}</span>
                            <span class="caption-line">Updated
                                {{ row.updatedAt | amTimeAgo }}</span>
                        </td>
                    </ng-container>

                    <!-- Row Defs -->
                    <tr cdk-header-row
                        *cdkHeaderRowDef="state.displayColumns">
                    </tr>
                    <tr cdk-row
                        *cdkRowDef="let row; columns: state.displayColumns;"
                        [class.selected]="selection.isSelected(row.key)">
                    </tr>

                </table>
            </div>
            <!-- TABLE END -->


            <!-- Sample Menu -->
            <mat-menu #sampleMenu="matMenu">
                <ng-template matMenuContent let-sample="sample">
                    <!-- <button mat-menu-item [routerLink]="[sample.key]">
                        <mat-icon>pageview</mat-icon>
                        <span>View Tasting Sample Detail</span>
                    </button> -->
                    <button mat-menu-item (click)="edit(sample)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Tasting Sample</span>
                    </button>
                    <button mat-menu-item (click)="setPrimaryImage(sample)">
                        <mat-icon>star</mat-icon>
                        <span>Set Primary Image</span>
                    </button>
                    <button mat-menu-item (click)="delete(sample)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete Tasting Sample</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/org', state.orgKey, 'tastings-scores', 'sample', sample.key]">
                        <mat-icon>score</mat-icon>
                        <span>View Scores</span>
                    </button>
                </ng-template>
            </mat-menu>

            <!-- Scion Cultivar Menu -->
            <mat-menu #scionMenu="matMenu">
                <ng-template matMenuContent let-cultivar="cultivar">
                    <button mat-menu-item (click)="addModelQuery('scion', cultivar)">
                        <mat-icon>playlist_add</mat-icon>
                        <span>Filter Scion</span>
                    </button>
                    <button mat-menu-item (click)="editCultivar(cultivar)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Cultivar Details</span>
                    </button>
                </ng-template>
            </mat-menu>

            <!-- Site Menu -->
            <mat-menu #siteMenu="matMenu">
                <ng-template matMenuContent let-site="site">
                    <button mat-menu-item (click)="addModelQuery('site', site)">
                        <mat-icon>playlist_add</mat-icon>
                        <span>Filter Location</span>
                    </button>
                    <button mat-menu-item (click)="editSite(site)">
                        <mat-icon>edit</mat-icon>
                        <span>Edit Site Details</span>
                    </button>
                </ng-template>
            </mat-menu>


        </ng-container>


    </div>

</pv-view>
