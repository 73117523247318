import {
    CustomProtocolService,
    Evaluation,
    EvaluationService,
    Sample,
    SampleService,
    Status,
    translateCommonErrorStatus
} from '@core/data';
import {Library, Protocol, Schedule} from '@library';
import {Action, State, StateContext, Selector} from '@ngxs/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {LIBRARY} from '@app/evaluation/library';
import {Inject, Injectable} from '@angular/core';
import {Dictionary} from "../../../../library/src/dictionary";
import { InitProtocolManagerState } from '../protocol-manager/protocol-manager.state';


export interface EvaluationFormStateModel {
    key: string; // evaluation key
    status: Status;
    data: Partial<Evaluation>;
    sample: Sample;
    protocolOptions: Protocol[];
    scheduleOptions: Schedule[];
}

const DEFAULTS: EvaluationFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
    sample: null,
    protocolOptions: [],
    scheduleOptions: [],
};

export class InitEvaluationForm {
    static readonly type = "[EvaluationForm] Init";

    constructor(
        public key?: string,
        public defaults?: Partial<Evaluation>
    ) {
    }
}

export class SubmitEvaluationForm {
    static readonly type = "[EvaluationForm] Submit";

    constructor(public data: Partial<Evaluation>) {
    }
}

@State({
    name: "evaluation_form",
    defaults: DEFAULTS
})
@Injectable()
export class EvaluationFormState {

    @Selector()
    static data(state: EvaluationFormStateModel) {
        return state.data;
    }

    constructor(
        private _evalService: EvaluationService,
        private _sampleService: SampleService,
        private _custom_protocolService: CustomProtocolService,
        @Inject(LIBRARY) private _library: Library
    ) {
    }

    @Action(InitEvaluationForm)
    initEvaluationForm(ctx: StateContext<EvaluationFormStateModel>, action: InitEvaluationForm) {
        ctx.setState(DEFAULTS);

        if (action.key) {
            return this.initEditForm(ctx, action.key, action.defaults);
        } else {
            return this.initNewForm(ctx, action.defaults);
        }
    }

    @Action(SubmitEvaluationForm)
    submitEvaluationForm(ctx: StateContext<EvaluationFormStateModel>, action: SubmitEvaluationForm) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        let request: Observable<Evaluation>;

        if (state.key) {
            request = this._evalService.update(state.key, action.data);
        } else {
            request = this._evalService.create(action.data);
        }

        return request
            .pipe(tap(
                result => {
                    ctx.patchState({
                        status: Status.COMPLETE,
                        data: result,
                    });
                },
                error => {
                    ctx.patchState({status: translateCommonErrorStatus(error)});
                }
            ));


    }

    private initNewForm(ctx: StateContext<EvaluationFormStateModel>, defaults: Partial<Evaluation>) {

        if (!defaults.sampleKey) {
            console.error("EvaluationFormState: No sampleKey provided");
            ctx.patchState({
                status: Status.ERROR,
            });
            return;
        }

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._sampleService
            .get(defaults.sampleKey, {related: ['scionCultivar']})
            .pipe(
                tap(
                    result => {

                        let protocols = this.getProtocolOptions(result, true);

                        ctx.patchState({
                            data: {
                                size: result.size || 1,
                                protocolId: protocols.length > 0 ? protocols[0].id : null,
                                evalStartDate: moment(),
                                ...defaults,
                            },
                            sample: result,
                            status: Status.OK,
                            protocolOptions: protocols,
                            scheduleOptions: this.getScheduleOptions(result),
                        });
                    },
                    error => {
                        ctx.patchState({status: translateCommonErrorStatus(error)});
                    }
                )
            );

    }

    private initEditForm(ctx: StateContext<EvaluationFormStateModel>, key: string, defaults: Partial<Evaluation>) {

        ctx.patchState({
            key,
            status: Status.LOADING
        });

        return this._evalService
            .get(key, {related: ['sample', 'sample.scionCultivar']})
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            data: result,
                            sample: result.sample,
                            status: Status.OK,
                            protocolOptions: this.getProtocolOptions(result.sample, true, result ? result.protocolId : null),
                            scheduleOptions: this.getScheduleOptions(result.sample),
                        });
                    },
                    error => {
                        ctx.patchState({status: translateCommonErrorStatus(error)});
                    },
                )
            );

    }

    private getProtocolOptions(sample: Sample, hideArchived: boolean = false, protocolId: string = null) {
        let protocols = [];

        if (sample.scionCultivar) {
            protocols = this._custom_protocolService.protocolsByCropSample(sample.scionCultivar.cropId, sample.type, hideArchived, protocolId);

        } else {
            protocols = this._custom_protocolService.allProtocols().all();
        }

        return protocols;
    }

    private getScheduleOptions(sample: Sample) {

        if (sample.scionCultivar) {
            return this._library.schedulesByCrop(sample.scionCultivar.cropId);
        }

        return this._library.schedules.all();

    }


}
