import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from '@angular/router';
import { FilterSort, Report } from "@core/data";
import { Dialog } from '@core/material';
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { ClearReportIndexFilter, DeleteReport, InitReportIndex, LoadReportIndex, PageReportIndex, QueryReportIndex, ReportIndexQuery, ReportIndexState, ReportIndexStateModel, SortReportIndex } from "./report-index.state";
import { Sort } from '@angular/material/sort';
import { Library, Crop } from '@library';
import { ReportSetupFormDialog, ReportSetupFormDialogData } from '../report-builder/report-setup-form.dialog';
import { LIBRARY } from '@app/evaluation/library';
import { ReportShareDialogData, ReportShareDialog } from '../report-builder/report-share.dialog';


@Component({
    selector: 'pv-report-index-view',
    templateUrl: 'report-index.view.html',
    host: {
        class: 'pv-report-index-view'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportIndexView implements OnInit, OnDestroy {

    columns = [
        'title',
        'actions',
        'samples',
        'images',
        'updatedAt',
    ];

    @Select(ReportIndexState)
    state$: Observable<ReportIndexStateModel>;

    @Select(ReportIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(ReportIndexState.query)
    query$: Observable<ReportIndexQuery>;


    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    // query form group
    scionCultivarsControl = new FormControl([]);
    rootstockCultivarsControl = new FormControl([]);
    searchControl = new FormControl();
    scionCropIdControl = new FormControl();
    cropOptions: Crop[];

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        scionCultivars: this.scionCultivarsControl,
        rootstockCultivars: this.rootstockCultivarsControl,
        scionCropId: this.scionCropIdControl,
    });

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialog: Dialog,
        private _route: ActivatedRoute,
        private _router: Router,
        @Inject(LIBRARY) private _library: Library,
    ){}

    ngOnInit(){

        // setup crop options
        this.cropOptions = this._library.filterExcludedCrops()

        // map route parameter changes
        this._route.paramMap
            .pipe(takeUntil(this._destroy$))
            .subscribe(params => {
                this._store.dispatch(new InitReportIndex(params.get('orgKey')));
            });


        // apply state changes to query form
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => this.queryFormGroup.patchValue(query, {emitEvent: false}));

        // apply state changes to sort form
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        // handle sort changes
        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortReportIndex(val.column, val.order));
            });

        // handle query changes
        this.queryFormGroup.valueChanges
            .pipe(debounceTime(300), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new QueryReportIndex(val));
            });


    }

    add(orgKey: string){

        const data: ReportSetupFormDialogData = {
            ownerOrgKey: orgKey,
        };

        this._dialog.open(ReportSetupFormDialog, {data})
            .afterClosed()
            .subscribe((report: Report) => {
                if(report){
                    this._router.navigate([
                        '/org',
                        report.ownerOrgKey,
                        'reports',
                        report.key,
                        'edit'
                    ]);
                }
            });

    }

    /**
     * Opens delete report confirmation dialog
     *
     * @param report Report to delete
     */
    deleteReport(report: Report) {

        this._dialog.confirm(
            'Remove Report',
            'Are you sure you want to delete this report?',
            'Delete',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if(res) this._store.dispatch(new DeleteReport(report.key));
        });
    }

    shareReport(report: Report){

        const data: ReportShareDialogData = {key: report.key};

        this._dialog.open(ReportShareDialog, {data, disableClose: false});

    }

    // Dispatches page changed action to state
    paginate(page: PageEvent) {
        this._store.dispatch(new PageReportIndex(page.pageIndex, page.pageSize));
    }

    sort(sort: Sort) {

        if (sort.direction) {
            this._store.dispatch(new SortReportIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortReportIndex('updatedAt', 'desc'));
        }

    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    // Refreshes the Index State
    refresh() {
        this._store.dispatch(new LoadReportIndex());
    }

    resetFilter() {
        this._store.dispatch(new ClearReportIndexFilter());
    }

    // Navigates the user back to the dashboard home
    back() {
        this._router.navigateByUrl('/dashboard');
    }

    trackImage(index, image){
        return image.key;
    }

    trackReport(index, report){
        return report.key;
    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }
}