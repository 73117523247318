import { Component, Input } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { InitOrganizationStats, LoadOrganizationStats, OrganizationStatsState, OrgStatsStateModel } from './organization-stats.state';

@Component({
    selector: 'pv-organization-stats',
    templateUrl: 'organization-stats.view.html',
    styleUrls: ['organization-stats.view.scss'],
})
export class OrganizationStatsComponent {

    constructor(
        private _store: Store,
    ) { }

    // Stats
    @Select(OrganizationStatsState)
    statsState$: Observable<OrgStatsStateModel>;

    @Input()
    get orgKey() { return this._orgKey; }
    set orgKey(value: string) {
        if (value && this._orgKey !== value) {
            this._orgKey = value;
            this._store.dispatch(new InitOrganizationStats(value));
        }
    }

    private _orgKey: string;

    // Stats Refresh
    refreshStats() {
        this._store.dispatch(new LoadOrganizationStats());
    }

    // Refresh
    refresh() {
        this.refreshStats();
    }
}
