import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { convertImageFileRef, parseFileRefType } from '@core/data';


@Component({
    selector: 'pv-image-dialog',
    templateUrl: 'image.dialog.html',
    styleUrls: ['image.dialog.scss'],
    host: {
        class: 'pv-image-dialog pv-fullscreen-dialog'
    }
})
export class ImageDialog implements OnInit {

    static DEFAULT_DOWNLOAD_SIZES = [
        {
            max: 2400,
            name: 'X Large',
        },
        {
            max: 1920,
            name: 'Large',
        },
        {
            max: 1024,
            name: 'Medium',
        },
        {
            max: 480,
            name: 'Small',
        }
    ];

    static DEFAULT_SOURCE_SIZES = [
        {
            minWidth: 1920,
            width: 2400,
        },
        {
            minWidth: 1024,
            width: 1920,
        },
        {
            minWidth: 480,
            width: 1024,
        },
        {
            minWidth: 256,
            width: 480,
        },
    ];

    expanded = false;
    sourceSet = [];
    sourceMain = null;
    downloads = [];

    showDeviceMessage = false;
    showErrorMessage = false;

    constructor(
        private _dialogRef: MatDialogRef<ImageDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ){}

    ngOnInit(){

        let type = parseFileRefType(this._data.fileRef);

        if(type === 'cloudinary'){
            this.updateDownloadSizes();
            this.updateSourceSet();
        }else{
            this.showDeviceMessage = true;
        }

    }

    close(){
        this._dialogRef.close();
    }

    imageLoad(event){
        this.showErrorMessage = false;
    }
    imageError(event){
        this.showErrorMessage = true;
    }

    updateDownloadSizes(){

        const fileRef = this._data.fileRef;
        const width = this._data.width;
        const height = this._data.height;

        let scale = 'height';

        if(height > width){
            scale = 'width';
        }

        this.downloads = [];

        ImageDialog.DEFAULT_DOWNLOAD_SIZES.forEach(size => {

            if(scale === 'width' && size.max > width){
                return;
            }else if(scale === 'height' && size.max > height){
                return;
            }

            let opts = {
                quality: 70,
                mode: 'scale',
                format: 'jpg',
                attachment: true
            };

            opts[scale] = size.max;

            this.downloads.push({
                name: `${size.name} (${scale} ${size.max}p)`,
                url: convertImageFileRef(fileRef, opts)
            });
        });

        this.downloads.push({
            name: `Original (${width}p x ${height}p)`,
            url: convertImageFileRef(fileRef, {attachment: true, quality: 90})
        });

        this.downloads.push({
            name: `Square (1280p)`,
            url: convertImageFileRef(fileRef, {attachment: true, width: 1280, height: 1280, mode: 'fill', gravity: 'center', quality: 70})
        });

        this.sourceMain = convertImageFileRef(fileRef, {width: 256, height: 256, mode: 'fill', gravity: 'center', quality: 70})

    }

    updateSourceSet(){

        const fileRef = this._data.fileRef;
        const width = this._data.width;
        const height = this._data.height;

        this.sourceSet = [];

        ImageDialog.DEFAULT_SOURCE_SIZES.forEach(size => {

            if(width < size.width) return;

            let opts = {
                quality: 70,
                mode: 'scale',
                format: 'jpg',
                width: size.width
            };

            this.sourceSet.push({
                media: `(min-width: ${size.minWidth}px)`,
                srcset: convertImageFileRef(fileRef, opts)
            });
        });

    }

    toggleExpanded(){
        this.expanded = !this.expanded;
    }

}