import { WeatherStation, Status, WeatherReportOptions, WeatherReportData, WeatherReportInterval, Filter, DetailRequest, translateCommonErrorStatus, WeatherStationService, patchArrayItem, removeArrayItem, SigFoxMessage } from '@core/data';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Library, WeatherReport } from '@library';
import * as moment from 'moment';
import { CompleteWeatherReadingsUpload } from '../weather-readings-upload/weather-readings-upload.state';
import { CompleteWeatherStationForm } from '../weather-station-form/weather-station-form.state';
import { Inject, Injectable } from '@angular/core';
import { LIBRARY } from '@app/evaluation/library';
import { coerceTimestampProperty } from '@core/utils';
//import { SigFoxMessage } from '@core/data';



export interface WeatherStationIndexStateModel {

    orgKey: string;
    status: Status;
    list: WeatherStation[];

    selectedKey: string;

    reportOptions: WeatherReportOptions;
    reportStatus: Status;
    reportData: WeatherReportData;
    availableReports: WeatherReport[];
}


export class InitWeatherStationIndex {
    static readonly type = "[WeatherStationIndex] Init";
    constructor(public orgKey: string) { }
}

export class LoadWeatherStationIndex {
    static readonly type = "[WeatherStationIndex] Load";
    constructor() { }
}

export class SelectWeatherStation {
    static readonly type = "[WeatherStationIndex] Select";
    constructor(public key: string) { }
}

export class DeleteWeatherStation {
    static readonly type = "[WeatherStationIndex] Delete";
    constructor(public key: string) { }
}

export class SetWeatherStationReportOptions {
    static readonly type = "[WeatherStationIndex] Set Report Options";
    constructor(public options: WeatherReportOptions) { }
}

export class LoadWeatherStationReport {
    static readonly type = "[WeatherStationIndex] Load Report";
    constructor() { }
}

const DEFAULTS: WeatherStationIndexStateModel = {

    orgKey: null,
    status: Status.UNINITIALIZED,
    list: [],

    selectedKey: null,

    reportOptions: {
        reportId: null,
        startDate: null,
        endDate: null,
        interval: WeatherReportInterval.DAILY
    },
    availableReports: [],

    reportStatus: Status.UNINITIALIZED,
    reportData: null
};

@State<WeatherStationIndexStateModel>({
    name: "weather_station_index",
    defaults: DEFAULTS
})
@Injectable()
export class WeatherStationIndexState {

    @Selector()
    static selected(state: WeatherStationIndexStateModel) {
        return state.list.find(m => m.key === state.selectedKey);
    }

    constructor(
        private _weatherService: WeatherStationService,
        @Inject(LIBRARY) private _library: Library
    ) { }

    @Action(InitWeatherStationIndex)
    initWeatherStationIndex(ctx: StateContext<WeatherStationIndexStateModel>, action: InitWeatherStationIndex) {

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey
        });

        return ctx.dispatch(new LoadWeatherStationIndex());
    }

    @Action(LoadWeatherStationIndex, { cancelUncompleted: true })
    loadWeatherStationIndex(ctx: StateContext<WeatherStationIndexStateModel>, action: LoadWeatherStationIndex) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
            list: [],
        });

        const filter: Filter = {
            sort: {
                order: 'asc',
                column: 'name'
            },
            queries: []
        };

        if(state.orgKey){
            filter.queries.push({ key: 'ownerOrgKey', value: state.orgKey });
        }

        const detail = this.getDetailRequest();

        return this._weatherService.query(filter, detail)
            .pipe(
                tap(
                    result => {
                        this.patchList(ctx, result.data);
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            selectedKey: null,
                        });
                    }
                ),
                catchError(error => of(null)),
            );
    }

    @Action(SelectWeatherStation)
    selectWeatherStation(ctx: StateContext<WeatherStationIndexStateModel>, action: SelectWeatherStation) {

        const state = ctx.getState();
        const selected = state.list.find(s => s.key === action.key);

        if (!selected) {
            ctx.patchState({
                selectedKey: action.key,
                reportStatus: Status.ERROR
            });
            return;
        }

        const availableReports = this.getAvailableReports(selected);
        if (availableReports.length === 0) {
            ctx.patchState({
                selectedKey: action.key,
                reportStatus: Status.EMPTY,
                availableReports
            });
            return;
        }

        // keep the current report if available
        const availableReportIds = availableReports.map(r => r.id);
        const reportId = availableReportIds.includes(state.reportOptions.reportId) ? state.reportOptions.reportId : availableReportIds[0];
        const report = availableReports.find(r => r.id === reportId);

        // keep current interval if available
        const interval = report.intervalIds.includes(state.reportOptions.interval) ? state.reportOptions.interval : report.intervalIds[0];

        // keep current dates if available
        // or use time range of first sensor
        const sensor = selected.sensorSummaries[0];
        const startDate = state.reportOptions.startDate || coerceTimestampProperty(sensor.firstRecordedAt, null, true);
        const endDate = state.reportOptions.endDate || coerceTimestampProperty(sensor.lastRecordedAt, null, true);

        let reportOptions: WeatherReportOptions = {
            reportId,
            interval: <any>interval,
            startDate,
            endDate
        };

        ctx.patchState({
            selectedKey: action.key,
            availableReports,
            reportOptions
        });

        return ctx.dispatch(new LoadWeatherStationReport);

    }


    @Action(SetWeatherStationReportOptions, { cancelUncompleted: true })
    setWeatherStationReportOptions(ctx: StateContext<WeatherStationIndexStateModel>, action: SetWeatherStationReportOptions) {

        ctx.patchState({
            reportOptions: action.options
        });

        return ctx.dispatch(new LoadWeatherStationReport());
    }


    @Action(LoadWeatherStationReport, { cancelUncompleted: true })
    loadWeatherStationReport(ctx: StateContext<WeatherStationIndexStateModel>, action: LoadWeatherStationReport) {

        const state = ctx.getState();

        if (!state.reportOptions.reportId || !state.selectedKey) {
            ctx.patchState({
                reportData: null,
                reportStatus: Status.INVALID,
            });
            return;
        }

        ctx.patchState({
            reportStatus: Status.LOADING,
        });

        return this._weatherService.report(state.selectedKey, state.reportOptions)
            .pipe(
                tap(
                    result => {
                        if (result.data.length > 0) {
                            ctx.patchState({
                                reportStatus: Status.OK,
                                reportData: result,
                            });
                        } else {
                            ctx.patchState({
                                reportStatus: Status.EMPTY,
                                reportData: result,
                            });
                        }

                    },
                    error => {
                        ctx.patchState({
                            reportStatus: translateCommonErrorStatus(error),
                            reportData: null,
                        });
                    }
                ),
                catchError(error => of(null))
            );

    }

    @Action(CompleteWeatherReadingsUpload)
    completeWeatherReadingsUpload(ctx: StateContext<WeatherStationIndexStateModel>, action: CompleteWeatherReadingsUpload) {

        const state = ctx.getState();

        return this._weatherService.get(action.key, this.getDetailRequest())
            .pipe(
                tap(result => {

                    ctx.patchState({
                        list: patchArrayItem(state.list, result)
                    });

                    ctx.dispatch(new SelectWeatherStation(result.key));

                }),
                // did the best we can...
                catchError(e => of(null))
            );


    }

    @Action(CompleteWeatherStationForm)
    completeWeatherStationForm(ctx: StateContext<WeatherStationIndexStateModel>, action: CompleteWeatherStationForm) {

        const state = ctx.getState();

        return this._weatherService.get(action.result.key, this.getDetailRequest())
            .pipe(
                tap(result => {
                    const state = ctx.getState();
                    this.patchList(ctx, patchArrayItem(state.list, result));
                    ctx.dispatch(new SelectWeatherStation(result.key));
                }),
                // did the best we can...
                catchError(e => of(null))
            );


    }

    @Action(DeleteWeatherStation)
    deleteWeatherStation(ctx: StateContext<WeatherStationIndexStateModel>, action: DeleteWeatherStation) {


        return this._weatherService.delete(action.key)
            .pipe(
                tap(result => {
                    const state = ctx.getState();
                    this.patchList(ctx, removeArrayItem(state.list, result.key));

                })
            );

    }

    private patchList(ctx: StateContext<WeatherStationIndexStateModel>, list: WeatherStation[]) {

        const state = ctx.getState();

        if (Array.isArray(list) && list.length > 0) {

            let selectedKey = state.selectedKey;
            let selected = list.find(m => m.key === selectedKey);

            if (selected) {
                selectedKey = selected.key;
            } else if (list.length > 0) {
                selectedKey = list[0].key;
            } else {
                selectedKey = null;
            }

            ctx.patchState({
                list: list,
                status: Status.OK,
            });

            if (state.selectedKey !== selectedKey) {
                ctx.dispatch(new SelectWeatherStation(selectedKey));
            }


        } else {
            ctx.patchState({
                selectedKey: null,
                status: Status.EMPTY,
            });
        }
    }


    private getAvailableReports(station: WeatherStation): WeatherReport[] {
        if (station && station.sensorSummaries && station.sensorSummaries.length) {

            let availableSensorIds = station.sensorSummaries.map(s => s.sensorId);
            let avail = true;


            return this._library.weatherReports.all().filter(report => {

                avail = true;

                report.sensorIds.forEach(sensorId => {
                    if (!availableSensorIds.includes(sensorId)) {
                        avail = false;
                        return;
                    }
                });

                return avail;
            });


        } else {
            return [];
        }
    }

    private getDetailRequest(): DetailRequest {
        return {
            related: ['sensorSummaries']
        };
    }


//     private getSigMessage() {

//         let before = moment();
//         let since = moment().subtract(1, 'days');

//         const x =  this._weatherService.getMessagesInTimeRange(since, before)
//         console.log(x)
// }
    // private SigfoxData(): SigMsg {
    // let before = moment();
    // let since = moment().subtract(1, 'days');

    // this._weatherService.getMessagesInTimeRange(since, before)
    // .pipe()
    //   .subscribe(x => {
    //     let dataFin = x.data;
    //     let dataTime = x.data;

    //     let info = dataFin.map((item: { data: any; }) => item.data);
    //     let timeInfo = dataTime.map((item: { time: any; }) => item.time);

    //     this.setPublicMessage(info, timeInfo);

    //    }
    // }

    // private setPublicMessage(data: string[], time: any) {
    //     this.testSigFoxMessage.data = data;
    //     this.testSigFoxMessage.time = time;
    //   };
}