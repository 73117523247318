import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'fileType'
})
export class FileTypePipe implements PipeTransform {

    constructor(){}

    audio = ['mp3', 'flac', 'wav', 'mpga'];
    video = ['mp4', 'mov', 'wmv', 'avi'];
    images = ['jpg', 'jpeg', 'png'];
    pdf = ['pdf'];
    excel = ['xlsx', 'xls', 'csv'];
    word = ['doc', 'docx'];
    powerpoint = ['ppt', 'pptx'];
    text = ['txt', 'rtf'];

    transform(extension: string): any {
        if (this.audio.includes(extension)) return 'audio_file';
        if (this.video.includes(extension)) return 'video_file';
        if (this.images.includes(extension)) return 'image';
        if (this.pdf.includes(extension)) return 'picture_as_pdf';
        if (
            this.excel.includes(extension) ||
            this.word.includes(extension) ||
            this.powerpoint.includes(extension) ||
            this.text.includes(extension)
        ) return 'description';
    }
}


