import { Component, EventEmitter, Output, Input, AfterViewInit, ViewChild, ElementRef} from '@angular/core';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { arrayHas, fileExtension } from '@core/utils';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
    selector: 'pv-dropzone',
    templateUrl: 'dropzone.component.html',
    host: {
        class: 'pv-dropzone',
        '[class.dragging]': 'isDragging',
        '(drop)': "onDrop($event)",
        '(dragover)': "onDragOver($event)" ,
        '(dragleave)': "onDragLeave($event)",
    },
    styleUrls: ['dropzone.component.scss'],
})
export class DropzoneComponent {

    @Output('file')
    fileDropEmitter = new EventEmitter<File>();

    @ViewChild('fileInput', { static: true })
    fileInput: ElementRef;

    @Input()
    multiple = false;

    private _acceptMimes: string[] = [];
    private _acceptExts: string[] = [];
    accepts: string = '';

    @Input()
    set acceptMimes(accepts: string[]){
        this._acceptMimes = accepts;
        this.accepts = this._acceptExts.concat(accepts).join(', ');
    }

    @Input()
    set acceptExts(accepts: string[]){
        this._acceptExts = accepts;
        this.accepts = this._acceptMimes.concat(accepts).join(', ');
    }

    @Input()
    maxSize: number = 40000000;

    isDragging = false;
    validationErrors = [];
    fileUploads = [];
    isBusy = false;

    constructor(){

    }

    watchUpload(filename, observable: Observable<HttpEvent<any>>){

        let fileUpload = {
            name:  filename,
            progress: 0,
            loaded: 0,
            total: 0,
            complete: false,
            error: false
        };

        observable.subscribe(
                event => {
                    if(event.type === HttpEventType.UploadProgress){
                        fileUpload.progress = Math.round(event.loaded/event.total*100);
                        fileUpload.loaded = event.loaded;
                        fileUpload.total = event.total;
                    }
                    if(event.type === HttpEventType.Response){
                        fileUpload.complete = true;
                        this.updateBusy();
                    }
                },
                err => {
                    fileUpload.error = true;
                    this.updateBusy();
                }
            );

        this.fileUploads.push(fileUpload);
        this.updateBusy();
    }

    updateBusy() {
        let busy = false;
        this.fileUploads.forEach(upload => {
            if(!upload.complete && !upload.error) busy = true;
        });
        this.isBusy = busy;
    }


    fileInputChanged(event: Event){

        let fileInput: HTMLInputElement = this.fileInput.nativeElement;

        if(this.validateFiles(fileInput.files)){
            this.emitFiles(fileInput.files);
        }

    }

    onDragOver(event: DragEvent){

        if(event.dataTransfer){
            event.dataTransfer.dropEffect = "copy";
        }

        if(!this.isDragging){
            this.isDragging = true;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent){

        if(this.isDragging){
            this.isDragging = false;
        }

        event.preventDefault();
    }

    onDrop(event: DragEvent){

        if(event.dataTransfer){
            event.dataTransfer.dropEffect = 'copy';
            if(this.validateFiles(event.dataTransfer.files)){
                this.emitFiles(event.dataTransfer.files);
            }
        }

        this.isDragging = false;

        event.preventDefault();
        event.stopPropagation();
    }

    private emitFiles(files: FileList){
        let length = files.length;

        for(let i = 0; i < length; i++){
            let item = files[i];
            this.fileDropEmitter.emit(item);
        }

    }

    private validateFiles(files: FileList){

        let valid = true;
        let length = files.length;
        this.validationErrors = [];

        if(length > 1 && !this.multiple){
            this.validationErrors.push('Multiple file uploads not allowed.')
            return false;
        }

        let badTypes = [];
        let badSizes = [];

        for(let i = 0; i < length; i++){
            let file = files[i];
            let fileExt = fileExtension(file.name).toLowerCase();

            if(!arrayHas(this._acceptExts, fileExt) && !arrayHas(this._acceptMimes, file.type)){
                badTypes.push(fileExt);
            }

            if(file.size > this.maxSize){
                badSizes.push(file.name);
            }

        }

        if(badSizes.length > 0){
            valid = false;
            this.validationErrors.push(`${badSizes.join(', ')} exceeds the maximum file size.`)
        }
        if(badTypes.length > 0){
            valid = false;
            this.validationErrors.push(`${badTypes.join(', ')} is not a valid file type.`)
        }

        return valid;
    }

}