import { LocaleTastingEvaluation } from "./_types";
//WIP (default)
export const LIB_TASTINGS_EVAL_STONEFRUIT_DE: LocaleTastingEvaluation = {
    id: "tastings_stonefruit_scorecard_de",
    title: "Verbrauchereindruck Früchte",
    crop: "Steinfrucht",

    weightedChars: [
        {id: "tastings_Attractiveness", label: "Attraktivität"},
        {id: "tastings_Texture", label: "Textur"},
        {id: "tastings_Taste & Aroma", label: "Geschmack & Aroma"},
        {id: "tastings_Overall Eating Quality", label: "Gesamte Essqualität"},
        {id: "tastings_Overall Interest", label: "Gesamtinteresse"},
    ],

    attributes: [
        {id: "tough skin", label: "Harte Schale"},
        {id: "high acid", label: "Hohe Säure"},
        {id: "high sugar", label: "Hoher Zuckergehalt"},
        {id: "juicy", label: "Saftig"},
        {id: "dry", label: "Trocken"},
        {id: "attractive", label: "Attraktiv"},
        {id: "aromatic", label: "Aromatisch"},
        {id: "bright colour", label: "Helle Farbe"},
        {id: "low flavour", label: "Geringer Geschmack"},
        {id: "abrasions present", label: "Beschädigungen vorhanden"},
        {id: "immature", label: "Unreif"},
        {id: "mixed maturity", label: "Uneinheitliche Reife"},
        {id: "overripe", label: "Überreif"}
    ],

    range: 10,

}