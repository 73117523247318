import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { SampleImage, Sample } from '@core/data';
import { Dialog } from '@core/material';
import { ImageGalleryDialogData, ImageGalleryDialog } from './image-gallery.dialog';





@Component({
    selector: 'pv-image-gallery',
    templateUrl: 'image-gallery.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-image-gallery'
    }
})
export class ImageGalleryComponent {

    @Input()
    set images (images: SampleImage[]){
        this._images = images.concat().sort((a, b) => {
            if (a.takenAt < b.takenAt)
                return -1;
            if (a.takenAt > b.takenAt)
                return 1;
            return 0;
        });
    }

    _images: SampleImage[] = [];

    constructor(private _dialog: Dialog){}

    trackImage(index, note: SampleImage){
        return note.key;
    }

    openGallery(){
        this.openImage(0);
    }

    openImage(index: number){

        let data: ImageGalleryDialogData = {
            images: this._images,
            currentIndex: index,
        };

        this._dialog.openFullscreen(ImageGalleryDialog, {data, disableClose: false}, false);
    }

}