import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorage {

    get(key: string, defaults: any = null): any {
        let value = localStorage.getItem(key);
        if (value === null) return defaults;

        return JSON.parse(value);
    }

    has(key: string) {
        return localStorage.getItem(key) !== null;
    }

    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }

}