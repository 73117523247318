<ng-container *ngIf="state$ | async as state">

    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Images</h2>
        <h3 class="dialog-subtitle" *ngIf="state.type == imageUploadTypes.sampleEvaluation">
            <div *ngIf="asEval(state.data) as eval">
                Sample {{ eval.sample ? eval.sample.code : '' }},
                evaluation {{ eval.sample ? eval.label : '' }}
            </div>
        </h3>
        <h3 class="dialog-subtitle" *ngIf="state.type == imageUploadTypes.tastingsEvent">
            <div *ngIf="asEvent(state.data) as event">
                Event {{ event ? event.code : '' }},
            </div>
        </h3>
        <h3 class="dialog-subtitle" *ngIf="state.type == imageUploadTypes.tastingsSample">
            <div *ngIf="asTasteSample(state.data) as tasteSample">
                Sample {{ tasteSample ? tasteSample.code : '' }},
            </div>
        </h3>
    </div>

    <div mat-dialog-content>

        <form id="notes_form"
              [formGroup]="formGroup"
              (ngSubmit)="attempt()"
              autocomplete="disabled"
              #imagesForm="ngForm"
              novalidate>

            <div class="images-form-container" formArrayName="images">

                <div class="image-file-dropbox"
                     (drop)="onDrop($event)"
                     (dragover)="onDragOver($event)"
                     (dragleave)="onDragLeave($event)"
                     [class.dragging]="isDragging"
                     *ngIf="state.type == imageUploadTypes.sampleEvaluation">

                    <div class="image-drop-request">
                        <p>Drag and drop image files here to upload.</p>
                        <label for="image_file_input">Browse Files</label>
                        <input id="image_file_input" (change)="fileInputChanged($event)" type="file">
                        <div class="image-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>
                    </div>

                    <div class="image-drop-results" *ngIf="uploads$ | async as uploads">
                        <div class="image-upload" *ngFor="let upload of uploads; trackBy: trackUpload">
                            <div [ngSwitch]="upload.status">
                                {{ upload.loaded | formatBytes }}/{{ upload.total | formatBytes }}
                                <span *ngSwitchCase="'LOADING'">Uploading...</span>
                                <span *ngSwitchCase="'COMPLETE'" class="text-primary">Done</span>
                                <span *ngSwitchDefault class="text-warn">{{ upload.status | descStatusTitle }}</span>
                            </div>
                            <mat-progress-bar mode="determinate" [value]="upload.loaded / upload.total * 100"></mat-progress-bar>
                        </div>
                    </div>

                </div>

                <div class="image-drop-request" *ngIf="((state.type == imageUploadTypes.tastingsEvent) || (state.type == imageUploadTypes.tastingsSample)) && canUpload">
                    <label for="image_file_input">Browse Files</label>
                    <input id="image_file_input" (change)="fileInputChanged($event)" type="file">
                    <div class="image-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>

                    <div class="image-drop-results" *ngIf="uploads$ | async as uploads">
                        <div class="image-upload" *ngFor="let upload of uploads; trackBy: trackUpload">
                            <div [ngSwitch]="upload.status">
                                {{ upload.loaded | formatBytes }}/{{ upload.total | formatBytes }}
                                <span *ngSwitchCase="'LOADING'">Uploading...</span>
                                <span *ngSwitchCase="'COMPLETE'" class="text-primary">Done</span>
                                <span *ngSwitchDefault class="text-warn">{{ upload.status | descStatusTitle }}</span>
                            </div>
                            <mat-progress-bar mode="determinate" [value]="upload.loaded / upload.total * 100"></mat-progress-bar>
                        </div>
                    </div>
                </div>


                <div class="image-form"
                     *ngFor="let imageGroup of formArray.controls; index as i"
                     [formGroupName]="i"
                     [class.image-deleted]="imageGroup.disabled">

                    <pv-image-ref class="image-form-file"
                                    *ngIf="imageGroup.value.fileRef as ref"
                                    [fileRef]="ref"
                                    transformId="thumb_300">
                    </pv-image-ref>

                    <div class="image-form-fields" *ngIf="state.type == imageUploadTypes.sampleEvaluation">
                        <mat-form-field appearance="standard" class="date-field">
                            <mat-label>Observed Date</mat-label>
                            <input type="text" matInput formControlName="takenAt"
                                   [matDatepicker]="takenDatepicker">
                            <mat-datepicker-toggle matSuffix [for]="takenDatepicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #takenDatepicker></mat-datepicker>
                            <mat-error>Select a date</mat-error>
                            <mat-hint>{{ formArray.at(i).get('takenAt').value | amTimeAgo }}
                            </mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="standard" class="message-field"
                                        floatLabel="always">
                            <mat-label>Observations</mat-label>
                            <textarea matInput
                                      formControlName="note"
                                      cdkTextareaAutosize
                                      cdkAutosizeMinRows="3"
                                      cdkAutosizeMaxRows="3"></textarea>
                            <mat-error>Max 250 chars</mat-error>
                        </mat-form-field>
                    </div>


                    <button type="button"
                            class="image-delete-button"
                            mat-icon-button
                            (click)="toggleDelete(i)"
                            [matTooltip]="imageGroup.disabled ? 'Restore Image' : 'Delete Image'">
                        <mat-icon>
                            {{ imageGroup.disabled ? 'restore_from_trash' : 'delete' }}
                        </mat-icon>
                    </button>

                </div>

            </div>

        </form>

    </div>

    <!-- Actions -->
    <pv-form-actions mat-dialog-actions
                     [form]="imagesForm"
                     (cancel)="cancel()"
                     [status]="state.status">
    </pv-form-actions>

</ng-container>
