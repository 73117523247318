import { AfterContentInit, AfterViewInit, Component, ElementRef, Inject, InjectionToken, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { AuthProfileFormDialog, AuthState, AuthStateModel, InitCultevaMenu, LogoutAuthUser, SetOrganizationContext } from '@app/auth';
import { WindowRef } from '@core/browser';
import { Organization, Status } from '@core/data';
import { OrganizationSubscriptionHandler } from '@core/data/globals/subscriptions';
import { Dialog, Sidenav, Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppThemes, AppThemesService } from './app-themes.service';
import { AppInfoHandler } from './app.info-handler';

export const APP_DEBUG = new InjectionToken<boolean>('app_debug');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [ ],
	host: {
		//class: 'mat-typography'
	},
	preserveWhitespaces: false
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy, AfterContentInit {

	@ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;

	@Select(AuthState)
	auth$: Observable<AuthStateModel>;

	@Select(AuthState.selectedOrg)
	selectedOrg$: Observable<Organization>;

	isOnline = false;
	contentReady = false;

	tastingsEnabled = false;
	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private _store: Store,
		private _sidenav: Sidenav,
		private _snackbar: Snackbar,
		private _router: Router,
		private _windowRef: WindowRef,
		private _dialog: Dialog,
		private _infoHandler: AppInfoHandler,
		private _appThemesService: AppThemesService,
		private _subscriptionHandler: OrganizationSubscriptionHandler,
		@Inject(APP_DEBUG) public isDebug: boolean,
	){}

	ngOnInit(){
		this.selectedOrg$.subscribe(org => {
			if (org) {
				this._store.dispatch(new InitCultevaMenu(org.key));
				this._subscriptionHandler.setSubscriptions(org.subscriptions);
			}
		});

		this._appThemesService.getTheme().pipe(takeUntil(this.destroy$)).subscribe(data => {
			if (data === AppThemes.tastings) this.tastingsEnabled = true;
			else this.tastingsEnabled = false;
		});

		this._infoHandler.init();

		let window = this._windowRef.getNativeWindow();


		let snackRef = null;

		window.addEventListener('online',  () => {
			this.isOnline = true;
			if(snackRef){
				snackRef.dismiss();
				snackRef = null;
			}
		});
		window.addEventListener('offline', () => {
			this.isOnline = false;
			snackRef = this._snackbar.error('You are offline. Please reconnect.', null);
		});


		this._router.events.subscribe(event => {

			// close sidenav on navigation end
			if(event instanceof NavigationEnd){
				this._sidenav.close();
			}
		});
	}

	ngOnDestroy(){
		this._infoHandler.destroy();
	}

	ngAfterViewInit(){
		this._sidenav.setInstance(this.sidenav);
	}

	/**
	 * This check was added after the Angular 9 upgrade, to prevent an Expression changed after it has been checked error
	 *
	 */
	ngAfterContentInit() {
		this.auth$.subscribe(auth => {
			if (auth.status === Status.OK && this.contentReady == false) return this.contentReady = true
			else return
		})
	}

	updateProfile(){
		this._dialog.open(AuthProfileFormDialog);
	}

	selectOrg(org: Organization){
		this._store.dispatch(new SetOrganizationContext(org.key));
		this._router.navigate(['/']);
	}

	logout(){
		this._store.dispatch(new LogoutAuthUser());
		this._router.navigate(['login']);
	}

	navigate(route: string) {
		this._router.navigate([route]);
	}

}
