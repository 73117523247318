

export class Dictionary<T extends {id: string}>{

    protected _indexMap: { [key: string]: number; } = {};

    constructor(protected _items: T[], private locked: boolean = true){
        _items.forEach((item: T, index: number) => {

            if(this._indexMap.hasOwnProperty(item.id)){
                throw Error(`Dictionary Item id already exists: ${item.id}`);
            }

            this._indexMap[item.id] = index;
        }, this);

        if (locked) {
            Object.freeze(this._items);
            Object.freeze(this._indexMap);
        }
    }

    public get(id: string): T{
        return this._items[ this._indexMap[id] ];
    }

    public add(item: T){
        this._indexMap[item.id] = this._items.length;
        this._items.push(item);
    }

    public has(id: string): boolean{
        return this._indexMap.hasOwnProperty(id);
    }

    public all(): T[]{
        return this._items;
    }

    public serialize() {
        return this._items;
    }

}
