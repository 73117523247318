import { AuthService, Notification } from '@core/data';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { InitAuthContext } from './auth-state';
import { Injectable } from '@angular/core';

export class NotificationStatus {
    static readonly STATUS_UNINITIALIZED = 'UNINITIALIZED';
    static readonly STATUS_OK = 'OK';
    static readonly STATUS_EMPTY = 'EMPTY';
    static readonly STATUS_LOADING = 'LOADING';
    static readonly STATUS_ERROR = 'ERROR';
}

export interface NotificationStateModel {
    status: string;
    items: Notification[];
    unreadCount: number;
}

export class InitNotifications {
    static readonly type = '[Notification] Init';
    constructor() { }
}

export class MarkNotificationRead {
    static readonly type = '[Notification] Mark As Read';
    constructor(public payload: Notification[]) { }
}

@State<NotificationStateModel>({
    name: 'notifications',
    defaults: {
        status: NotificationStatus.STATUS_UNINITIALIZED,
        items: [],
        unreadCount: 0,
    }
})
@Injectable()
export class NotificationState implements NgxsOnInit {

    @Selector()
    static latest(state: NotificationStateModel) {
        return state;
    }

    constructor(
        private _authService: AuthService,
    ) { }

    ngxsOnInit(ctx?: StateContext<NotificationStateModel>) { }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(InitAuthContext)
    InitAuthContext(ctx: StateContext<NotificationStateModel>, action: InitAuthContext) {
        return ctx.dispatch(new InitNotifications());
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(InitNotifications, { cancelUncompleted: true })
    init(ctx: StateContext<NotificationStateModel>, action: InitNotifications) {

        ctx.patchState({
            status: NotificationStatus.STATUS_LOADING,
            items: [],
            unreadCount: 0
        });

        return this._authService.getNotifications()
            .pipe(
                catchError(e => {

                    ctx.patchState({
                        status: NotificationStatus.STATUS_ERROR,
                        items: [],
                        unreadCount: 0,
                    });

                    return of(null);
                }),
                tap(result => {

                    if (result === null) return;

                    if (result.length === 0) {
                        ctx.patchState({
                            status: NotificationStatus.STATUS_EMPTY,
                            items: [],
                            unreadCount: 0,
                        });
                    } else {
                        ctx.patchState({
                            status: NotificationStatus.STATUS_OK,
                            items: result,
                            unreadCount: this.getUnreadCount(result),
                        });
                    }
                })
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action payload: notification form data
     */
    @Action(MarkNotificationRead)
    markAsRead(ctx: StateContext<NotificationStateModel>, action: MarkNotificationRead) {

        const ids = action.payload.map(notification => notification.id);

        return this._authService.markNotificationsRead(ids)
            .pipe(
                tap(result => {
                    if (!result) return;

                    const state = ctx.getState();

                    let notifications = state.items.map(item => {

                        for (const newItem of result) {
                            if (newItem.id === item.id) return newItem;
                        }
                        return item;

                    });

                    ctx.patchState({
                        items: notifications,
                        unreadCount: this.getUnreadCount(notifications)
                    });
                })
            );
    }

    /**
     *
     * @param notifications notifications of the user
     */
    private getUnreadCount(notifications: Notification[]) {
        let unreadCount = 0;

        notifications.forEach(notification => {
            if (notification.read_at === null) unreadCount++;
        });

        return unreadCount;
    }
}