import { Injectable } from '@angular/core';
import { Evaluation, EvaluationService, MeasurementsUpdateRequest, Status, translateCommonErrorStatus } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

export interface MeasurementsFormStateModel {
    key: string;
    status: Status;
    data: Evaluation;
}

export class InitMeasurementsForm {
    static readonly type = "[MeasurementsForm] Init";
    constructor(public evalKey: string){}
}

export class SubmitMeasurementsForm {
    static readonly type = "[MeasurementsForm] Submit";
    constructor(public payload: MeasurementsUpdateRequest){}
}


@State<MeasurementsFormStateModel>({
    name: "measurements_form",
    defaults: {
        key: null,
        status: Status.UNINITIALIZED,
        data: null
    }
})
@Injectable()
export class MeasurementsFormState {

    @Selector()
    static data(state: MeasurementsFormStateModel){
        return state.data;
    }

    constructor(private _evalService: EvaluationService){}

    @Action(InitMeasurementsForm)
    initMeasurementsForm(ctx: StateContext<MeasurementsFormStateModel>, action: InitMeasurementsForm){

        ctx.patchState({
            key: action.evalKey,
            status: Status.LOADING,
            data: null
        });

        return this._evalService
            .get(action.evalKey, {related: ['measures', 'sample']})
            .pipe(tap(
                result => {
                    ctx.patchState({
                        data: result,
                        status: Status.OK
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));

    }

    @Action(SubmitMeasurementsForm)
    submitMeasurementsForm(ctx: StateContext<MeasurementsFormStateModel>, action: SubmitMeasurementsForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._evalService
            .update(state.key, action.payload)
            .pipe(tap(
                result => {
                    const state = ctx.getState();
                    ctx.patchState({
                        key: result.key,
                        data: {
                            ...state.data,
                            ...result
                        },
                        status: Status.COMPLETE
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

}