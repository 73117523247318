import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Snackbar } from '@core/material';
import { ROLES } from '../../roles';

@Component({
    selector: 'pv-organization-invite-form-dialog',
    templateUrl: 'organization-invite-form.dialog.html',
})
export class OrganizationInviteFormDialog {

    formGroup = new FormGroup({
        userName: new FormControl(null, [Validators.required, Validators.minLength(3)]),
        userEmail: new FormControl(null, [Validators.required, Validators.email]),
        userRole: new FormControl('rw', [Validators.required])
    });

    roleOptions = ROLES;

    constructor(
        private _dialog: MatDialogRef<OrganizationInviteFormDialog>,
        private _snackbar: Snackbar
    ) { }

    attempt() {
        if (this.formGroup.valid) {
            this.submit();
        } else {
            this._snackbar.error("Check your input and try again.");
        }
    }

    submit() {
        this._dialog.close(this.formGroup.value);
    }

    cancel() {
        this._dialog.close();
    }

}