import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackMessageComponent, SnackMessageData } from './snack-message.component';
import { SnackLinkData, SnackLinkComponent } from './snack-link.component';
import { SafeUrl } from '@angular/platform-browser';

@Injectable()
export class Snackbar {

    constructor(private _mdSnackbar: MatSnackBar){}

    message(message: string, type: 'info' | 'error' = 'info', duration = 5000){

        this._mdSnackbar.dismiss();

        const data: SnackMessageData = {
            message,
            type
        };


        let ref = this._mdSnackbar.openFromComponent(SnackMessageComponent, { 
            duration: duration,
            data: data,
            panelClass: ['pv-snack-message-container', 'snack-type-' + type]
        });

        return ref;
    }

    info(message: string, duration = 4000){
        return this.message(message, 'info', duration);
    }

    error(message: string, duration = 4000){
        return this.message(message, 'error', duration);
    }

    link(message: string, link: string, url: string | SafeUrl, blank = false, download?: string){

        this._mdSnackbar.dismiss();

        const data: SnackLinkData = {
            message,
            link,
            url,
            blank
        };

        if(download) data.download = download;

        let ref = this._mdSnackbar.openFromComponent(SnackLinkComponent, { duration: 30000, data: data });
        return ref;
    }

    formInvalid(message?: string){
        if (message == '') return this.error("Some fields are invalid. Check your input and try again.");
        else return this.error(message);
    }


}