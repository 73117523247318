import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Status } from "@core/data";
import { BUSINESSAREAS, TastingContact } from "@core/data/types/tastings-contact";
import { Dialog, Snackbar } from "@core/material";
import { coerseDateProperty, COUNTRIES } from "@core/utils";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InitNewTastingContactForm, InitUpdateTastingContactForm, SubmitTastingContactForm, TastingContactFormState, TastingContactFormStateModel } from "./tasting-contact-form.state";

export interface TastingContactFormDialogData {
    key?: string;
    defaults?: Partial<TastingContact>;
}

@Component({
    selector: 'pv-tasting-contact-form-dialog',
    templateUrl: './tasting-contact-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class TastingContactFormDialog implements OnInit, OnDestroy {
    @Select(TastingContactFormState)
    state$: Observable<TastingContactFormStateModel>;

    @Select(TastingContactFormState.data)
    data$: Observable<TastingContactFormStateModel>;

    //Form Controls
    firstNameControl = new FormControl(null, [Validators.maxLength(64)]);
    lastNameControl = new FormControl(null, [Validators.maxLength(64)]);
    emailControl = new FormControl(null, [Validators.required, Validators.email]);
    dobControl = new FormControl(null);
    countryControl = new FormControl(null, [Validators.maxLength(2)]);
    businessAreasControl = new FormControl(null);
    subscribedControl = new FormControl(null);
    ownerOrgKeyControl = new FormControl(null);

    //Form group
    formGroup: FormGroup = new FormGroup({
        firstName: this.firstNameControl,
        lastName: this.lastNameControl,
        email: this.emailControl,
        dob: this.dobControl,
        country: this.countryControl,
        businessAreas: this.businessAreasControl,
        subscribed: this.subscribedControl,
        ownerOrgKey: this.ownerOrgKeyControl,
    })

    private _destroy$ = new Subject();

    countries: {code: string, label: string}[] = [];
    businessAreas : {id: string, label: string}[] = [];

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingContactFormDialog>,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        @Inject(MAT_DIALOG_DATA) public data: TastingContactFormDialogData
    ) {}

    ngOnInit(): void {
        this.countries = COUNTRIES;
        this.businessAreas = BUSINESSAREAS;

        if (this.data.key) this._store.dispatch(new InitUpdateTastingContactForm(this.data.key));
        else this._store.dispatch(new InitNewTastingContactForm(this.data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.data);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });
    }

    reset(model: Partial<TastingContact>) {
        let data = {
            firstName: model.firstName || null,
            lastName: model.lastName || null,
            email: model.email || null,
            dob: model.dob || null,
            country: model.country || null,
            businessAreas: model.businessAreas || null,
            subscribed: model.subscribed || false,
            //Keys
            ownerOrgKey: model.ownerOrgKey
        }

        this.formGroup.reset(data);
    }

    cancel() {
        this._dialogRef.close();
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save()
        } else {
            console.warn("TastingContactDialog: form invalid", this.formGroup.value);
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    save() {
        const form = this.formGroup.value;

        const data: Partial<TastingContact> = {
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            dob: coerseDateProperty(form.dob),
            country: form.country,
            businessAreas: form.businessAreas,
            subscribed: form.subscribed,
            //Keys
            ownerOrgKey: form.ownerOrgKey,
        }

        this._store.dispatch(new SubmitTastingContactForm(data));
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}