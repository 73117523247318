<ng-container *ngIf="state$ | async as state">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 *ngIf="!_data.defaults.massAction" class="dialog-title">{{ state.key ? 'Edit Evaluation' : 'Add Evaluation' }}</h2>
        <h2 *ngIf="_data.defaults.massAction" class="dialog-title">{{ 'Add Evaluations' }}</h2>
        <h3 *ngIf="!_data.defaults.massAction" class="dialog-subtitle">Sample: {{ state.sample?.code }}</h3>
        <ng-container *ngIf="_data.defaults.massAction">
            <h3 class="dialog-subtitle">Selected Samples:</h3>
            <div style="column-count: 2;">
                <h3 class="dialog-subtitle" *ngIf="!_data.defaults.sampleCodes.length">No Samples Selected</h3>
                <h3 *ngFor="let sampleCode of _data.defaults.sampleCodes" class="dialog-subtitle">{{ sampleCode }}</h3>
            </div>
        </ng-container>
    </div>
    <div mat-dialog-content>


        <form id="evaluation_form"
            #form="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            autocomplete="off">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Evaluation Label</mat-label>
                    <input matInput type="text" formControlName="label"  placeholder="eg. 'Screening 2018'" required>
                    <mat-hint>Describe the evaluation stage</mat-hint>
                    <mat-error>Label is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Evaluation Start Date</mat-label>
                    <input matInput formControlName="evalStartDate" [matDatepicker]="startDatePicker" placeholder="YYYY-MM-DD" required>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                    <mat-hint>Select a start date</mat-hint>
                    <mat-error>Start date required, formatted <em>YYYY-MM-DD</em></mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Evaluation End Date</mat-label>
                    <input matInput formControlName="evalEndDate" [min]="evalStartDateControl.value" [matDatepicker]="endDatePicker" placeholder="YYYY-MM-DD">
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <mat-hint>Select end date</mat-hint>
                    <mat-error>Select end date, after start date</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Sample Size</mat-label>
                    <input matInput type="number" formControlName="size" required>
                    <span matSuffix>#</span>
                    <mat-hint>Number of trees/fruit/vines in the sample</mat-hint>
                    <mat-error>Enter a sample size [min 1]</mat-error>
                </mat-form-field>

                <div class="dropdown-container">
                    <div class="select-container">
                      <mat-form-field appearance="standard">
                        <mat-label>Evaluation Protocol</mat-label>
                        <mat-select formControlName="protocolId" required>
                            <mat-option *ngFor="let proto of state.protocolOptions" [value]="proto.id" (onSelectionChange)="setSelectedProtocol($event, proto)">{{ proto.title }}</mat-option>
                        </mat-select>
                        <mat-error>Select an evaluation protocol</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="icon-container">
                        <button mat-icon-button matTooltip="More Info" (click)="$event.preventDefault(); toggleDisplay()">
                            <mat-icon>info</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="state.sample?.type == 'harvest'" class="form-column">

                <div *ngIf="!_data.defaults.massAction else storageRestrict">

                    <div class="form-subheader">Environment</div>

                    <mat-form-field appearance="standard">
                        <mat-label>Storage Regime</mat-label>
                        <mat-select (selectionChange)="applySchedule($event.value)">
                            <ng-container *ngFor="let schedule of state.scheduleOptions">
                                <mat-optgroup *ngIf="schedule" [label]="schedule?.label">
                                    <mat-option
                                        *ngFor="let stage of schedule?.evaluations"
                                        [value]="{scheduleId: schedule?.id, evaluation: stage}">
                                        {{ schedule?.label }}&nbsp;{{ stage?.label }}
                                    </mat-option>
                                </mat-optgroup>
                            </ng-container>
                        </mat-select>
                        <mat-hint>Apply storage schedule according to regime</mat-hint>
                    </mat-form-field>

                    <div class="form-subheader">
                        Storage Schedule
                        <button mat-icon-button class="pull-right" type="button" (click)="addStorageLine()" [disabled]="formGroup.disabled">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button class="pull-right" type="button" (click)="removeStorageLine()" *ngIf="hasStorageLine()" [disabled]="formGroup.disabled">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>

                    <table class="pv-table-form" *ngIf="hasStorageLine() else noStorage" style="max-width: 450px">
                        <tr>
                            <th>#</th>
                            <th class="mat-caption">Temp</th>
                            <th class="mat-caption">Duration</th>
                            <th class="mat-caption">Additional Treatment</th>
                            <th class="mat-caption">Controlled Atmosphere</th>
                        </tr>
                        <tr *ngFor="let storageGroup of storageRegimeControl.controls; index as storageIndex" [formGroup]="storageGroup" (click)="openStorageDialog(editStorageDialog, storageGroup, storageIndex)"
                            class="storage-group-row" title="Click to edit storage schedule">

                            <td>{{ storageIndex + 1 }}</td>
                            <td>
                                {{ storageGroup.controls.temp.value }} °C
                            </td>
                            <td style="display: flex;">
                                {{ storageGroup.controls.time.value }} &nbsp;
                                <div [ngSwitch]="storageGroup.controls.unit.value">
                                    <span *ngSwitchCase="'d'">days</span>
                                    <span *ngSwitchCase="'w'">weeks</span>
                                    <span *ngSwitchCase="'m'">months</span>
                                    <span *ngSwitchDefault>days</span>
                                </div>
                            </td>
                            <td style="width: 75px;">
                                <mat-checkbox disabled style="width: 20px; margin: auto;" [checked]="storageGroup.controls.treatmentTypes.value.length"></mat-checkbox>
                            </td>
                            <td style="width: 75px;">
                                <mat-checkbox disabled style="width: 20px; margin: auto;" [checked]="storageGroup.controls.controlledAtmosphere.value"></mat-checkbox>
                            </td>

                        </tr>
                    </table>

                    <ng-template #noStorage>
                        <div class="mat-caption">
                            No Storage
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="form-column" *ngIf="isDisplay">
                <div *ngIf="selectedProtocol as protocol">

                    <div class="evaluation-form-column">{{protocol.title}}</div>

                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                    Characteristics
                            </mat-expansion-panel-header>
                            <div class="scrollable-container">
                                <mat-list-item *ngFor="let char of protocol.chars">
                                    <li>{{char | labelChar}}</li>
                                </mat-list-item>

                                <mat-list-item *ngIf="protocol.chars.length == 0">
                                    <li> No Characteristics</li>
                                </mat-list-item>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                    Measurements
                            </mat-expansion-panel-header>
                            <div class="scrollable-container">
                                <mat-list-item *ngFor="let measure of protocol.measures">
                                    <li>{{measure | labelMeasure}}</li>
                                </mat-list-item>

                                <mat-list-item *ngIf="protocol.measures.length == 0">
                                    <li> No Measurements</li>
                                </mat-list-item>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <ng-template #storageRestrict>
                <div class="mat-caption">
                    Storage regime not available on bulk actions<br>
                    Storage regime should be set individually per harvest evaluation
                </div>
            </ng-template>

            <!-- DIALOG -->
            <ng-template #editStorageDialog let-data>
                <h4 matDialogTitle>Edit Storage</h4>
                <mat-dialog-content>
                    <div style="display: flex; flex-direction: column;" [formGroup]="data.storageGroup">
                        <mat-form-field>
                            <mat-label>Temperature</mat-label>
                            <input matInput type="number" formControlName="temp" placeholder="Temp" (click)="$event.stopPropagation()">
                        </mat-form-field>

                        <div style="display: flex;">

                            <mat-form-field style="width: 75%;">
                                <mat-label>Duration</mat-label>
                                <input matInput type="number" formControlName="time" value="{{ data.storageGroup.controls.time.value }}" placeholder="Duration" min="0" (click)="$event.stopPropagation()">
                                <span matSuffix style="padding-right: 5px;">
                                    <input #date formControlName="endDate" [min]="evalStartDateControl.value" [matDatepicker]="picker" style="visibility: hidden; width: 1px;" (dateChange)="data.storageGroup.controls.time.value = calculateDuration(date.value ,data.storageIndex)">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </span>
                            </mat-form-field>

                            <mat-form-field style="width: 25%;">
                                <mat-label>Format</mat-label>
                                <mat-select formControlName="unit" (click)="$event.stopPropagation()">
                                    <mat-option value="d">Days</mat-option>
                                    <mat-option value="w">Weeks</mat-option>
                                    <mat-option value="m">Months</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>

                        <mat-form-field appearance="standard">
                            <mat-label>Treatment type</mat-label>
                            <mat-select formControlName="treatmentTypes" multiple>
                                <mat-option *ngFor="let item of treatmentTypesList" [value]="item.id">{{ item.label }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox class="mat-caption" formControlName="controlledAtmosphere">Controlled Atmosphere</mat-checkbox>
                        <ng-container *ngIf="data.storageGroup.controls.controlledAtmosphere.value" formGroupName="caOptions">
                            <mat-form-field>
                                <mat-label>CO<sub>2</sub></mat-label>
                                <input matInput formControlName="co2">
                                <span matSuffix>%</span>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>O<sub>2</sub></mat-label>
                                <input matInput formControlName="o2">
                                <span matSuffix>%</span>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Ethylene Concentration</mat-label>
                                <input matInput formControlName="eth">
                                <span matSuffix>ppm</span>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label>Relative Humidity</mat-label>
                                <input matInput formControlName="rh">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </mat-dialog-content>

                <mat-dialog-actions align="end">
                    <button mat-raised-button (click)="closeStorageDialog()" color="primary" class="form-action mat-raised-button mat-button-base mat-primary">Close</button>
                </mat-dialog-actions>
            </ng-template>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="form"
        (cancel)="cancel()"
        [status]="state.status">

    </pv-form-actions>

</ng-container>