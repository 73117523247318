import { Component, OnDestroy, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { Store, Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { ReportBuilderState, SetReportBuilderInfoSheetImages } from './report-builder.state';
import { ReportImageOption, Report, SampleImage } from '@core/data';
import { fileExtension } from "@core/utils";
import { Dialog } from "@core/material";

export interface ReportSampleImageSelectDialogData {
    cultivarKey: string;
    index: number;
}

@Component({
    selector: 'pv-report-sample-image-select-dialog',
    templateUrl: 'report-sample-image-select.dialog.html',
    styleUrls: ['report-sample-image-select.dialog.scss']
})
export class ReportSampleImageSelectDialog implements OnInit, OnDestroy {

    @Select(ReportBuilderState.data)
    reportState$: Observable<Report>;

    images$: Observable<SampleImage[]>;

    controlImages$: Observable<SampleImage[]>;


    includeImagesControl = new FormControl([]);
    controlCultivarImagesControl = new FormControl([]);

    imageKeys: string[] = [];
    controlCultivarKey: string = null;
    imageOptions: ReportImageOption[][] = [[],[]]
    images: SampleImage[] = [];

    formGroup = new FormGroup({
        images: this.includeImagesControl,
        controlImages: this.controlCultivarImagesControl
    });

    private _destroy$ = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportSampleImageSelectDialogData,
        private _store: Store,
        private _dialogs: Dialog,
        private _ref: MatDialogRef<ReportSampleImageSelectDialog>,
    ) { }

    ngOnInit() {
        this.reportState$.subscribe(state => {

            if (!state || !state.options) return;

            let images = state.options.cultivarInfoOptions.includeImages[this._data.index];

            images.forEach(image => {
                this.imageKeys.push(image);
            });

            this.controlCultivarKey = state.options.cultivarInfoOptions.controlCultivar ? state.options.cultivarInfoOptions.controlCultivar.key : null;

            this.imageOptions = state.options.cultivarInfoOptions.infoSheetImageOptions
                .map(function(arr) {
                    return arr.slice();
                });
        });

        this.images$ =
            this.reportState$
                .pipe(
                    takeUntil(this._destroy$),
                    map(report =>
                        report.samples
                            .filter(sample => sample.data.scionCultivarKey === this._data.cultivarKey)
                            .reduce((acc, sample) => acc.concat(...sample.data.evals.map(e => e.images)), [])
                    )
                );

        // Sepertates Primary from Control and sets included images on the form
        this.images$.subscribe(images => {
            let validImages: string[] = images.map(image => image.key).filter(( key ) => this.imageKeys.includes( key ))
            this.includeImagesControl.setValue(validImages);
            images.forEach((image) => this.images.push(image))
        })

        if (this.controlCultivarKey) {
            this.controlImages$ =
                this.reportState$
                .pipe(
                    takeUntil(this._destroy$),
                    map(report =>
                        report.samples
                            .filter(sample => sample.data.scionCultivarKey === this.controlCultivarKey)
                            .reduce((acc, sample) => acc.concat(...sample.data.evals.map(e => e.images)), [])
                    )
                );

            // Sepertates Control from Primary and sets included images on the form
            this.controlImages$.subscribe(controlImages => {
                let validControlKeys: string[] = controlImages.map(image => image.key).filter((key) => this.imageKeys.includes(key));
                this.controlCultivarImagesControl.setValue(validControlKeys);
                controlImages.forEach((image) => this.images.push(image))
            })
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt() {
        let formValues = [...new Set([...this.formGroup.value.images, ...this.formGroup.value.controlImages])];

        this._store.dispatch(new SetReportBuilderInfoSheetImages(this._data.index, formValues, this.imageOptions[this._data.index]));
        this._ref.close(true);
    }

    cancel() {
        this._ref.close(false);
    }

    clearDisconnectedImages() {
        this._dialogs.confirm(
            "Remove Disconnected Images",
            `Are your sure you want to permanently remove any disconnected images?`
        ).afterClosed()
        .subscribe(result => {
            if(!result) return;

            let images: string[] = [];

            images.push(...this.includeImagesControl.value)
            images.push(...this.controlCultivarImagesControl.value)

            this.imageOptions[this._data.index] = this.imageOptions[this._data.index].filter(image => images.includes(image.key));
            this.attempt();
        });
    }

    private handleImageSelection(matOption: MatListOption) {
        let value = matOption.value;
        let index = this._data.index;

        let selectedImage:SampleImage = this.images.find((image) => image.key === value)

        let option: ReportImageOption = {
            key: selectedImage.key,
            name: selectedImage.name,
            fileRef: selectedImage.fileRef,
            position: -1,
            extension: fileExtension(selectedImage.name),
            takenAt: selectedImage.takenAt,
            note: selectedImage.note,
        }

        if (matOption.selected) {
            option.position = this.imageOptions[index].length;
            this.imageOptions[index].push(option);
            return;
        }

        this.imageOptions[index] = this.imageOptions[index].filter(opt => opt.key !== selectedImage.key && opt.fileRef !== selectedImage.fileRef);
        this.imageOptions[index] = this.imageOptions[index].map((option, index) => ({
            ...option,
            position:index
        }))
    }
}
