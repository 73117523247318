<h1 mat-dialog-title>Select Evaluations:</h1>
<div mat-dialog-content>
    <mat-dialog-content>
        <div class="option-section">
            <div class="section-title">Included Samples</div>
            <div class="included-sample-wrapper">
                <div class="sample-label">
                    <mat-icon matListIcon svgIcon="common:plant_sample"></mat-icon>
                    Plant Samples
                </div>
                <mat-button-toggle-group name="plantSampleGroup" [formControl]="includePlantSamples">
                    <mat-button-toggle value="ps_remove" matTooltip="Remove All Samples">
                        <mat-icon color="warn">remove_circle_outline</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="ps_add" matTooltip="Add All Samples">
                        <mat-icon color="primary">add_circle_outline</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="included-sample-wrapper">
                <div class="sample-label">
                    <mat-icon matListIcon svgIcon="common:harvest_sample"></mat-icon>
                    Harvest Samples
                </div>
                <mat-button-toggle-group name="harvestSampleGroup" [formControl]="includeHarvestSamples">
                    <mat-button-toggle value="hs_remove" matTooltip="Remove All Samples">
                        <mat-icon color="warn">remove_circle_outline</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="hs_add" matTooltip="Add All Samples">
                        <mat-icon color="primary">add_circle_outline</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <mat-selection-list dense [formControl]="includeEvaluationControl">
            <div *ngFor="let sample of samples">
                <div mat-subheader>
                    <mat-icon class="mat-18" [svgIcon]="'common:' + sample.data.type + '_sample'">
                    </mat-icon>
                    <span matLine>{{sample.data.label}}</span>&nbsp;
                    <span matLine>({{sample.data.code}})</span>
                </div>
                <mat-list-option *ngFor="let eval of sample.data.evals" [value]="eval.key">
                    <span matLine>{{eval.label}}</span>
                    <span matLine>{{eval.evalStartDate}} - {{eval.evalEndDate ? eval.evalEndDate : "N/A"}}</span>
                </mat-list-option>
            </div>
        </mat-selection-list>
    </mat-dialog-content>
</div>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="attempt()">Save</button>
</mat-dialog-actions>