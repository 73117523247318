import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { convertDocumentFileRef, convertImageFileRef, FileAttachmentUpdateRequest, Report, ReportDocument, ReportRemark, Status } from "@core/data";
import { Snackbar } from "@core/material";
import { coerceTimestampProperty, fileExtension } from "@core/utils";
import { Select, Store } from "@ngxs/store";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { FileAttachmentFormState, FileAttachmentFormStateModel, InitFileAttachmentForm, PendingReportUploadModel, SubmitFileAttachmentForm, UploadDocument } from "./file-attachment-form.state";

export interface FileAttachmentDialogData {
    key: string;
    sectionId: string;
}

@Component({
    selector: 'pv-file-attachment-dialog',
    templateUrl: './file-attachment-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-file-attachment-dialog pv-fullscreen-dialog'
    }
})
export class FileAttachmentDialog implements OnInit, OnDestroy {

    fileTypes = {
        raw: ['xls', 'xlsx', 'doc', 'docx', 'pdf', 'ppt', 'pptx', 'txt', 'rtf', 'csv'],
        image: ['png', 'jpg', 'jpeg'],
        video: ['mp4', 'avi', 'mov', 'wmv', 'mkv'],
        audio: ['mp3', 'aac', 'flac', 'wav', 'mpga'],
    }

    @Select(FileAttachmentFormState)
    state$: Observable<FileAttachmentFormStateModel>;

    @Select(FileAttachmentFormState.data)
    data$: Observable<ReportRemark>;

    @Select(FileAttachmentFormState.uploads)
    uploads$: Observable<PendingReportUploadModel[]>;

    formArray = new FormArray([]);
    formGroup = new FormGroup({
        _noop: new FormControl(true),
        documents: this.formArray,
    });

    isDragging = false;
    maxSize = 40000000; // 40MB
    allowedExts = this.fileTypes.image.concat(this.fileTypes.raw, this.fileTypes.video, this.fileTypes.audio);
    validationErrors: string[] = [];
    canUpload = true;
    remark: ReportRemark = null;

    private pushedUploadIds: number[] = [];
    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<FileAttachmentDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: FileAttachmentDialogData,
    ) {}

    ngOnInit(): void {
        this._store.dispatch(new InitFileAttachmentForm(this.data.key, this.data.sectionId));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if(state.status === Status.COMPLETE) {
                    this._snackbar.info("Section Documents Updated");
                    this.cancel(true);
                } else if (state.status !== Status.LOADING) {
                    this.formGroup.enable({onlySelf: true});
                } else {
                    this.formGroup.disable({onlySelf: true});
                }
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) {
                    this.buildForm(data);
                    this.remark = data;
                }
            });

        this.uploads$.pipe(
            takeUntil(this._destroy$),
            debounceTime(500)
        )
        .subscribe(uploads => {
            if (Array.isArray(uploads) && uploads.length < 1) return;

            uploads.forEach(upload => {
                if (
                    upload.status === Status.COMPLETE
                    && upload.data
                    && !this.pushedUploadIds.includes(upload.id)
                ) {
                    this.pushedUploadIds.push(upload.id);
                    this.pushUploadedDocument(upload.data);
                }
            });
        });
    }

    fileInputChanged(event: Event) {
        let input = <HTMLInputElement> event.target;
        if (input.files && input.files.length > 0) this.uploadDocuments(input.files);
    }

    onDragOver(event: DragEvent) {
        if (event.dataTransfer) event.dataTransfer.dropEffect = 'copy';
        if (!this.isDragging) this.isDragging = true;
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent) {
        if (this.isDragging) this.isDragging = false;
        event.preventDefault();
    }

    onDrop(event: DragEvent) {
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
            this.uploadDocuments(event.dataTransfer.files);
        }

        this.isDragging = false;
        event.preventDefault();
        event.stopPropagation();
    }

    trackUpload(index, item: PendingReportUploadModel) {
        return item.id;
    }

    private pushUploadedDocument(document: ReportDocument) {
        this.formArray.push(this.makeDocumentGroup(document));
    }

    private uploadDocuments(list: FileList) {
        if (!this.validateFiles(list)) return;

        for(let i = 0; i < list.length; i++) {
            this._store.dispatch(new UploadDocument(list.item(i), this.data.sectionId));
        }
    }

    private buildForm(model: ReportRemark) {
        this.formArray.clear();
        this.formGroup.reset({ documents: [] });

        model.documents.forEach((doc) => {
            let control = this.makeDocumentGroup(doc)
            if (control) this.formArray.push(control);
        })
    }

    private makeDocumentGroup(doc: Partial<ReportDocument>) {
        if (!doc) return null;

        return new FormGroup({
            key: new FormControl(doc.key || null),
            fileRef: new FormControl(doc.fileRef || null),
            name: new FormControl(doc.name || null),
            extension: new FormControl(doc.extension || null),
        })
    }

    private validateFiles(files: FileList) {
        let valid = true;
        this.validationErrors = [];

        for(let i = 0; i < files.length; i++) {
            let file = files[i];
            let fileExt = fileExtension(file.name).toLowerCase();

            if (!this.allowedExts.includes(fileExt)) this.validationErrors.push(`${file.name} is not a valid file type.`);
            if (file.size > this.maxSize) this.validationErrors.push(`${file.name} exceeds the maximum file size.`);
        }

        if(this.validationErrors.length > 0) valid = false;

        return valid;
    }

    private getUpdateRequest(): FileAttachmentUpdateRequest {
        let documentUpdates: Array<Partial<ReportDocument>> = [];
        let value: Partial<ReportDocument>;

        this.formArray.controls.forEach(control => {
            value = control.value;
            if (control.disabled) { //disabled state indicated delete
                documentUpdates.push({
                    key: value.key,
                    deletedAt: coerceTimestampProperty(moment())
                });
            } else if (control.dirty) {
                documentUpdates.push({
                    key: value.key,
                });
            }
        });

        return {
            documents: documentUpdates,
            sectionId: this.data.sectionId,
            remark: this.remark
        };
    }

    attempt() {
        if (this.formGroup.valid) {
            let request: FileAttachmentUpdateRequest = null;
            request = this.getUpdateRequest();

            if (request.documents.length) this._store.dispatch(new SubmitFileAttachmentForm(request))
            else {
                this._snackbar.info("Documents updated");
                this.cancel(true);
            }
        } else {
            this._snackbar.error("One or more documents are invalid. Check your input and try again.");
        }
    }

    toggleDelete(index: number) {
        let group = <FormGroup> this.formArray.at(index);

        if (!group) return;
        if (group.disabled) group.enable();
        else group.disable();
    }

    getSrc(ref: string, ext: string) {
        return convertDocumentFileRef(ref, ext);
    }

    downloadDocument(ref: string, ext: string) {
        let url = this.getSrc(ref, ext);
        window.open(url, "_blank");
    }

    contentLoaded(key: string) {
        document.getElementById('spinner_'+key).style.display = 'none';
        document.getElementById('viewer_'+key).style.visibility = 'visible';
    }

    isImage(extension: string): boolean {
        return this.fileTypes.image.includes(extension);
    }

    isVideo(extension: string): boolean {
        return this.fileTypes.video.includes(extension);
    }

    isAudio(extension: string): boolean {
        return this.fileTypes.audio.includes(extension);
    }

    isRaw(extension: string): boolean {
        return this.fileTypes.raw.includes(extension);
    }

    cancel(refreshBuilder: boolean = false): void {
        this._dialogRef.close({refresh: refreshBuilder, reportKey: this.data.key});
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}