import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Sample } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { Dialog, Snackbar } from "@core/material";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { BulkTasteSampleFormState, BulkTasteSampleFormStateModel, InitNewBulkTasteSampleForm, SubmitBulkTasteSampleForm } from "./bulk-taste-sample-form.state";

export interface BulkTasteSampleFormDialogData {
    data: Sample[];
}

@Component({
    selector: 'pv-bulk-taste-sample-form-dialog',
    templateUrl: 'bulk-taste-sample-form.dialog.html',
    styleUrls: ['bulk-taste-sample-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class BulkTasteSampleFormDialog implements OnInit, OnDestroy {

    @Select(BulkTasteSampleFormState)
    state$: Observable<BulkTasteSampleFormStateModel>;

    tastingsSamples: Partial<TastingSample>[] = [];

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<BulkTasteSampleFormDialog>,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        @Inject(MAT_DIALOG_DATA) public data: BulkTasteSampleFormDialogData
    ) {}

    ngOnInit() {
        this._store.dispatch(new InitNewBulkTasteSampleForm(this.data.data));

        this.state$.subscribe(data => {
            if (data) this.tastingsSamples = this.compileTastingsSamples(data.data);
        })
    }

    private compileTastingsSamples(harvestSamples: Sample[]): Partial<TastingSample>[] {
        let tastingSamples: Partial<TastingSample>[] = [];

        harvestSamples.forEach(sample => {
            let tastingSample: Partial<TastingSample> = {
                label: sample.label || '',
                description: sample.description || null,
                size: sample.size || null,

                harvestSample: sample as Sample || null,
                harvestSampleKey: sample.key,
                ownerOrgKey: sample.ownerOrgKey,

                siteKey: sample.siteKey,
                scionCultivarKey: sample.scionCultivarKey,
                birthDate: sample.birthDate,

                tastingsEventKeys: [],
            }

            tastingSamples.push(tastingSample);
        });

        return tastingSamples;
    }

    attempt() {
        if (this.tastingsSamples.length === 0) {
            this.errorHandler('No Tastings Samples to create', 'error')
            return;
        }

        let list: SubmitBulkTasteSampleForm[] = [];
        this.tastingsSamples.forEach(sample => {
            list.push(new SubmitBulkTasteSampleForm(sample));
        });

        this.save(list);
    }

    save(submissionList: SubmitBulkTasteSampleForm[]) {
        this._store.dispatch(submissionList)
            .subscribe(complete => {
                if (complete) this._dialogRef.close();
            });
    }

    cancel() {
        this._dialogRef.close();
    }

    private errorHandler(message: string, type: string) {
        switch (type) {
            case 'info':
                this._snackbar.info(message);
                break;
            case 'message':
                this._snackbar.message(message);
                break;
            case 'error':
                this._snackbar.error(message);
                break;
            default:
                this._snackbar.info(message);
                break;
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}