import { Characteristic, CharacteristicType } from './_types';

export const LIB_CHARS_RECO: Characteristic[] = [
    {
        id: "reco_breedable",
        type: CharacteristicType.Nominal,
        categoryId: "recommend",
        label: "Next Phase",
        params: {
            options: [
                { value: "phase_1", label: "Phase 1 Evaluation" },
                { value: "phase_2", label: "Phase 2 Evaluation" },
                { value: "phase_3", label: "Phase 3 Evaluation" },
                { value: "breeding_parent", label: "Breeding Parent" },
                { value: "cull", label: "Cull" },
            ]
        }
    }
];