<ng-container *ngIf="currentImage$ | async as current">
    <div class="gallery-toolbar">

        <h4 class="gallery-toolbar-date" *ngIf="current.takenAt">
            {{ current.takenAt | amDateFormat:'ll' }}</h4>
        <p class="gallery-toolbar-note" *ngIf="current.note">{{ current.note }}</p>
        <a *ngIf="current.lat && current.lng"
           matTooltip="View on Map"
           [href]="'http://www.google.com/maps/place/' + current.lat + ',' + current.lng"
           target="_blank">
            <mat-icon>place</mat-icon>&nbsp;{{ current | descSiteCoords }}
        </a>

        <div class="spacer"></div>

        <button type="button" mat-icon-button matTooltip="Expand Image" (click)="toggleExpand()">
            <mat-icon>{{ expanded ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="downloadMenu" matTooltip="Download Image"
                *ngIf="downloads.length > 0">
            <mat-icon>cloud_download</mat-icon>
        </button>

        <mat-menu #downloadMenu="matMenu">
            <a mat-menu-item *ngFor="let dl of downloads" target="_blank"
               [href]="dl.url">{{ dl.name }}</a>
        </mat-menu>

        <button type="button" mat-icon-button matTooltip="Close Gallery" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>


    </div>
    <div class="gallery-viewport" [class.expanded]="expanded">

        <pv-image-ref class="image-preview"
                        [fileRef]="current.fileRef"
                        transformId="preview_2000"
                        format="jpeg"
                        [alt]="current.name">
        </pv-image-ref>

    </div>
</ng-container>
<div class="gallery-footer">
    <button mat-icon-button type="button" class="gallery-control left"
            (click)="prevImage()">
        <mat-icon>navigate_before</mat-icon>
    </button>

    <cdk-virtual-scroll-viewport #scrollViewport
                                    class="gallery-items"
                                    itemSize="56"
                                    minBufferPx="200"
                                    orientation="horizontal"
                                    maxBufferPx="400">
        <pv-image-ref *cdkVirtualFor="let image of images; index as i; trackBy: trackImage"
                        class="image-item"
                        [class.current]="currentIndex$.getValue() === i"
                        (click)="selectImage(i)"
                        [fileRef]="image.fileRef"
                        transformId="thumb_72"
                        [alt]="image.name">
        </pv-image-ref>
    </cdk-virtual-scroll-viewport>

    <button mat-icon-button type="button" class="gallery-control right"
            (click)="nextImage()">
        <mat-icon>navigate_next</mat-icon>
    </button>
</div>
