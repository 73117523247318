import { Routes } from '@angular/router';
import { ErrorView } from './dashboard/components/error/error.view';
import { DashboardView } from './dashboard/components/dashboard/dashboard.view';

export const appRoutes: Routes = [

    { path: '', component: DashboardView },
    { path: 'dashboard', redirectTo: '' },
    { path: 'error/:status', component: ErrorView },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
    },
    {
        path: 'demo',
        loadChildren: () => import('./demo/demo.module').then(mod => mod.DemoModule)
    },
    { path: '**', redirectTo: '/error/404' },
];