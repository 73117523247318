import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialog } from './image.dialog';
import { convertImageFileRef, parseFileRefType } from '@core/data';

@Component({
    selector: 'pv-thumb',
    template: `<a (click)="openDialog()"><img class="pv-thumb-image" [src]="src"></a>`,
    styles: [`
        a {
            display: block;
            cursor: pointer;
        }
        .pv-thumb-image {
            background-color: #ddd;
            display: block;
            max-width: 100%;
        }
        :host.fill {
            width: 100%;
        }
        :host.fill .pv-thumb-image {
            width: 100%;
        }
        :host {
            display: inline-block;
        }
    `]
})
export class ThumbComponent {

    @Input()
    width: number = null;

    @Input()
    height: number = null;

    @Input()
    set fileRef(fileRef: string){

        this._fileRef = fileRef;

        let type = parseFileRefType(fileRef);

        if(type === 'cloudinary'){
            this.src = convertImageFileRef(fileRef, {width: 256, height: 256, mode: 'fill', gravity: 'center', quality: 60});
        }else{

        }

    }

    // @Input()
    // thumb64: string = null;

    @Input()
    set fill(attr: any){
        if(attr === undefined){
            this.isFilled = false;
        }else{
            this.isFilled = true;
        }
    }

    @HostBinding('class.fill')
    isFilled = false;

    private _fileRef: string;
    src: string;

    constructor(private _dialogs: MatDialog){

    }

    openDialog(){
        if(this._fileRef){

            let options = {
                data: {
                    fileRef: this._fileRef,
                    width: this.width,
                    height: this.height
                },
                width: '100vw',
                height: '100vh',
                panelClass: 'fullscreen-dialog',
                disableClose: false,
                autoFocus: false
            };

            this._dialogs.open(ImageDialog, options).afterClosed().subscribe();
        }
    }


}