import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Site, Status } from '@core/data';
import { Snackbar } from '@core/material';
import { CoordinateValidators, NumberValidators } from '@core/utils';
import { COUNTRIES } from '@core/utils/countries';
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InitSiteForm, SiteFormState, SiteFormStateModel, SubmitSiteForm } from './site-form.state';

export interface SiteFormDialogData {
    key?: string;
    defaults?: Partial<Site>;
}

@Component({
    selector: 'pv-site-form-dialog',
    templateUrl: 'site-form.dialog.html'
})
export class SiteFormDialog implements OnInit {

    @Select(SiteFormState)
    state$: Observable<SiteFormStateModel>;

    @Select(SiteFormState.data)
    data$: Observable<Partial<Site>>;

    private _destroy$ = new Subject();

    keyControl: FormControl = new FormControl(null);
    nameControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]);
    blockControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]);
    rowDistanceControl: FormControl = new FormControl(null, [NumberValidators.integer(11)]);
    colDistanceControl: FormControl = new FormControl(null, [NumberValidators.integer(11)]);
    narrowRowDistanceControl: FormControl = new FormControl(null, [NumberValidators.integer(11)]);
    latControl: FormControl = new FormControl(null, [CoordinateValidators.lat(), NumberValidators.decimal(12, 10)]);
    lngControl: FormControl = new FormControl(null, [CoordinateValidators.lng(), NumberValidators.decimal(13, 10)]);
    areaControl: FormControl = new FormControl(null, [NumberValidators.decimal(18, 3)]);
    altControl: FormControl = new FormControl(null, [NumberValidators.decimal(10, 4)]);
    addrStreetControl: FormControl = new FormControl(null, [Validators.maxLength(64)]);
    addrCityControl: FormControl = new FormControl(null, [Validators.maxLength(32)]);
    addrRegionControl: FormControl = new FormControl(null, [Validators.maxLength(32)]);
    addrDistrictControl: FormControl = new FormControl(null, [Validators.maxLength(32)]);
    addrWardControl: FormControl = new FormControl(null, [Validators.maxLength(32)]);
    addrCodeControl: FormControl = new FormControl(null, [Validators.maxLength(16)]);
    addrCountryControl: FormControl = new FormControl(null, [Validators.maxLength(2)]);

    ownerOrgKeyControl = new FormControl(null);

    formGroup: FormGroup = new FormGroup({

        key: this.keyControl,
        name: this.nameControl,
        block: this.blockControl,
        rowDistance: this.rowDistanceControl,
        colDistance: this.colDistanceControl,
        narrowRowDistance: this.narrowRowDistanceControl,
        lat: this.latControl,
        lng: this.lngControl,
        area: this.areaControl,
        alt: this.altControl,
        addrStreet: this.addrStreetControl,
        addrCity: this.addrCityControl,
        addrRegion: this.addrRegionControl,
        addrDistrict: this.addrDistrictControl,
        addrWard: this.addrWardControl,
        addrCode: this.addrCodeControl,
        addrCountry: this.addrCountryControl,
        ownerOrgKey: this.ownerOrgKeyControl,

    });

    cast(model: any): Site {

        return {
            ...model,
            lat: model.lat === null ? null : parseFloat(model.lat),
            lng: model.lng === null ? null : parseFloat(model.lng),
            area: model.area === null ? null : parseFloat(model.area),
            alt: model.alt === null ? null : parseFloat(model.alt)
        };
    }

    countries = COUNTRIES;

    constructor(
        protected _dialogRef: MatDialogRef<SiteFormDialogData>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {

        // Initializes the state
        this._store.dispatch(new InitSiteForm(this.data.key, this.data.defaults));

        // Listens to state changes
        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) {
                    this._dialogRef.close(state.data);
                } else if (state.status === Status.OK || state.status === Status.INVALID) {
                    this.formGroup.enable();
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });
    }

    // Attempts to save the forms data
    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    // Saves the form / Dispatches the Submit form action
    save() {

        const value = this.formGroup.value;

        let model = {
            ...value,
        };

        this._store.dispatch(new SubmitSiteForm(model));
    }

    // Closes the dialog
    cancel() {
        this._dialogRef.close();
    }

    // Resets the form with defaults
    reset(data: Partial<Site>) {

        this.formGroup.reset({
            key: data.key || '',
            name: data.name || '',
            block: data.block || '',
            rowDistance: data.rowDistance || null,
            colDistance: data.colDistance || null,
            narrowRowDistance: data.narrowRowDistance || null,
            lat: data.lat || null,
            lng: data.lng || null,
            area: data.area || null,
            alt: data.alt || null,
            addrStreet: data.addrStreet || '',
            addrCity: data.addrCity || '',
            addrRegion: data.addrRegion || '',
            addrDistrict: data.addrDistrict || '',
            addrWard: data.addrWard || '',
            addrCode: data.addrCode || '',
            addrCountry: data.addrCountry || '',
            ownerOrgKey: data.ownerOrgKey,
        });

    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    refresh() {
        this._store.dispatch(new InitSiteForm(this.data));
    }

}

