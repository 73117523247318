import { Component, Inject, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'pv-snack-message',
    template: `
    <mat-icon>{{ data.type === 'error' ? 'warning' : 'info'  }}</mat-icon>
    <span class="snackbar-message mat-body type-{{ data.type }}">{{ data.message }}</span>
    `,
    styleUrls: ['snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class SnackMessageComponent {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackMessageData,
        private _ref: MatSnackBarRef<SnackMessageComponent>,
    ) { }

    @HostListener('click')
    dismiss(){
        this._ref.dismiss();
    }

}

export interface SnackMessageData {
    message: string;
    type: string;
}