import { Injectable } from '@angular/core';
import { Status, Evaluation, SampleCharacteristic, EvaluationService, translateCommonErrorStatus } from '@core/data';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

export interface CharacteristicsFormStateModel {
    key: string;
    status: Status;
    data: Evaluation;
}

export class InitCharacteristicsForm {
    static readonly type = "[CharacteristicsForm] Init";
    constructor(public evalKey: string){}
}

export class SubmitCharacteristicsForm {
    static readonly type = "[CharacteristicsForm] Submit";
    constructor(public payload: {chars: Partial<SampleCharacteristic>[]}){}
}


@State<CharacteristicsFormStateModel>({
    name: "characteristics_form",
    defaults: {
        key: null,
        status: Status.UNINITIALIZED,
        data: null
    }
})
@Injectable()
export class CharacteristicsFormState {

    @Selector()
    static data(state: CharacteristicsFormStateModel){
        return state.data;
    }

    constructor(private _evalService: EvaluationService){}

    @Action(InitCharacteristicsForm)
    initCharacteristicsForm(ctx: StateContext<CharacteristicsFormStateModel>, action: InitCharacteristicsForm){

        ctx.patchState({
            key: action.evalKey,
            status: Status.LOADING,
            data: null
        });

        return this._evalService
            .get(action.evalKey, {related: ['chars', 'sample']})
            .pipe(tap(
                result => {
                    ctx.patchState({
                        data: result,
                        status: Status.OK
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));

    }

    @Action(SubmitCharacteristicsForm)
    submitCharacteristicsForm(ctx: StateContext<CharacteristicsFormStateModel>, action: SubmitCharacteristicsForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._evalService
            .update(state.key, action.payload)
            .pipe(tap(
                result => {
                    const state = ctx.getState();
                    ctx.patchState({
                        key: result.key,
                        data: {
                            ...state.data,
                            ...result
                        },
                        status: Status.COMPLETE
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

}