import { Pipe, PipeTransform } from '@angular/core';
import { WeatherReportData, ReportWeather } from '@core/data';
import { WeatherChartCompilerService } from '../weather-chart-compiler.service';

@Pipe({
    name: 'compileWeatherChart',
    pure: true
})
export class CompileWeatherChartPipe implements PipeTransform {

    constructor(private _compile: WeatherChartCompilerService){}

    transform(data: WeatherReportData | ReportWeather, dense = false) {
        if(data){
            return this._compile.compileReportChart(data, dense);
        }
        return data;
    }
}
