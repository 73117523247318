import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatListOption } from "@angular/material/list";
import { ImageAttachmentUpdateRequest, RemarkImageOption, Report, ReportImage, ReportRemark, SampleImage, Status, convertDocumentFileRef } from "@core/data";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { ReportBuilderState } from "../report-builder/report-builder.state";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ImageAttachmentFormState, ImageAttachmentFormStateModel, InitImageAttachmentForm, PendingReportImageUploadModel, SubmitImageAttachmentForm, UploadImageAttachment } from "./image-attachment-form.state";
import { Snackbar } from "@core/material";
import { fileExtension } from "@core/utils";

export interface ImageAttachmentDialogData {
    key: string;
    sectionId: string;
    options: RemarkImageOption[];
}

@Component({
    selector: 'pv-image-attachment-dialog',
    templateUrl: './image-attachment-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'pv-image-attachment-dialog pv-fullscreen-dialog'
    }
})
export class ImageAttachmentDialog implements OnInit, OnDestroy {

    fileTypes = {
        image: ['png', 'jpg', 'jpeg'],
    }

    @Select(ImageAttachmentFormState)
    state$: Observable<ImageAttachmentFormStateModel>;

    @Select(ImageAttachmentFormState.data)
    data$: Observable<ReportRemark>;

    @Select(ImageAttachmentFormState.uploads)
    uploads$: Observable<PendingReportImageUploadModel[]>;

    @Select(ReportBuilderState.data)
    reportState$: Observable<Report>;

    images$: Observable<SampleImage[]>;
    controlImages$: Observable<SampleImage[]>;

    imageKeys: string[] = [];
    cultivarKey: string;
    controlCultivarKey: string;
    imagesToAppend: Partial<ReportImage>[] = [];

    isDragging = false;
    maxSize = 40000000; // 40MB
    validationErrors: string[] = [];

    remark: ReportRemark;

    includeImagesControl = new FormControl([]);
    controlCultivarImagesControl = new FormControl([]);
    uploadedImagesControl = new FormControl();

    formArray = new FormArray([]);
    formGroup = new FormGroup({
        includeImagesControl: this.includeImagesControl,
        controlCultivarImagesControl: this.controlCultivarImagesControl,
        uploadedImagesControl: this.uploadedImagesControl,
        imageArray: this.formArray,
        _noop: new FormControl(true),
    });

    private pushedUploadIds: number[] = [];
    private _destroy = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<ImageAttachmentDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public _data: ImageAttachmentDialogData
    ){}

    ngOnInit(): void {
        //* State info
        this._store.dispatch(new InitImageAttachmentForm(this._data.key, this._data.sectionId));

        this.state$
            .pipe(takeUntil(this._destroy))
            .subscribe(state => {
                if(state.status === Status.COMPLETE) {
                    this._snackbar.info("Section Images Updated");
                    this._dialogRef.close(state.data);
                } else if (state.status !== Status.LOADING) {
                    this.formGroup.enable({onlySelf: true});
                } else {
                    this.formGroup.disable({onlySelf: true});
                }
            });

        this.data$
            .pipe(
                takeUntil(this._destroy),
                debounceTime(500)
            )
            .subscribe(data => {
                if (data) {
                    this.buildForm(data);
                    this.remark = data;

                    this.imageKeys = data.imageOptions ? data.imageOptions.map(image => image.key) : [];
                    this.resetUploadedImages()
                    this.resetCultivarImages();
                    if (this._data.options) this.setImageOptions([...this._data.options]);
                    else this.setImageOptions(this._data.options)
                }
            });

        this.uploads$
            .pipe(
                takeUntil(this._destroy),
                debounceTime(500)
            )
            .subscribe(uploads => {
                if (Array.isArray(uploads) && uploads.length < 1) return;

                uploads.forEach(upload => {
                    if (
                        upload.status === Status.COMPLETE
                        && upload.data
                        && !this.pushedUploadIds.includes(upload.id)
                    ) {
                        this.pushedUploadIds.push(upload.id);
                        this.pushUploadedImage(upload.data);
                    }
                });
            });


        //* Cultivar images section
        this.reportState$.subscribe(state => {
            this.cultivarKey =
                state.options.cultivarInfoOptions.primaryCultivar
                ? state.options.cultivarInfoOptions.primaryCultivar.key
                : null
            this.controlCultivarKey =
                state.options.cultivarInfoOptions.controlCultivar
                ? state.options.cultivarInfoOptions.controlCultivar.key
                : null;
        });

        this.images$ =
            this.reportState$
                .pipe(
                    takeUntil(this._destroy),
                    map(report => {
                        let images = report.samples
                            .filter(sample => sample.data.scionCultivarKey === this.cultivarKey)
                            .reduce((acc, sample) => acc.concat(...sample.data.evals.map(e => e.images)), [])
                            
                        return images.sort((a, b) => {
                            if (b.takenAt < a.takenAt)
                                return -1;
                            if (b.takenAt > a.takenAt)
                                return 1;
                            return 0;
                        });
                    })
                );

        if (this.controlCultivarKey) {
            this.controlImages$ =
                this.reportState$
                .pipe(
                    takeUntil(this._destroy),
                    map(report => {

                        let controlImages = report.samples
                            .filter(sample => sample.data.scionCultivarKey === this.controlCultivarKey)
                            .reduce((acc, sample) => acc.concat(...sample.data.evals.map(e => e.images)), [])

                        return controlImages.sort((a, b) => {

                            if (b.takenAt < a.takenAt)
                                return -1;
                            if (b.takenAt > a.takenAt)
                                return 1;
                            return 0;
                        });
                    })
                );
        }
    }

    fileInputChanged(event: Event) {
        let input = <HTMLInputElement> event.target;
        if (input.files && input.files.length > 0) this.uploadImages(input.files);
    }

    onDragOver(event: DragEvent) {
        if (event.dataTransfer) event.dataTransfer.dropEffect = 'copy';
        if (!this.isDragging) this.isDragging = true;
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent) {
        if (this.isDragging) this.isDragging = false;
        event.preventDefault();
    }

    onDrop(event: DragEvent) {
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
            this.uploadImages(event.dataTransfer.files);
        }

        this.isDragging = false;
        event.preventDefault();
        event.stopPropagation();
    }

    trackUpload(index, item: PendingReportImageUploadModel) {
        return item.id;
    }

    getSrc(ref: string, ext: string) {
        return convertDocumentFileRef(ref, ext);
    }

    downloadImage(ref: string, fileName: string) {
        let url = this.getSrc(ref, fileExtension(fileName));
        window.open(url, "_blank");
    }

    compareWithFn(listOfItems, selectedItem){
        return listOfItems && selectedItem && listOfItems.key === selectedItem.key; ;
   }

    attempt() {
        this._store.dispatch(new SubmitImageAttachmentForm(this.getUpdateRequest()))
    }

    private getUpdateRequest(): ImageAttachmentUpdateRequest {

        return {
            images: this.remark.images,
            imageOptions: this.getImageOptions(),
            sectionId: this._data.sectionId,
            remark: this.remark
        }
    }

    private uploadImages(list: FileList) {
        if (!this.validateFiles(list)) return;

        for (let i = 0; i < list.length; i++) {
            this._store.dispatch(new UploadImageAttachment(list[i], this._data.sectionId));
        }
    }

    private pushUploadedImage(image: Partial<ReportImage>) {
        this.formArray.push(this.makeImageGroup(image));
        this.selectUploadedImage(image)
    }

    private buildForm(model: ReportRemark) {
        this.formArray.clear();
        this.formGroup.reset({ images: [] });

        if (!model.images) return;

        model.images.forEach((img) => {
            let control = this.makeImageGroup(img);
            if (control) this.formArray.push(control);
        })
    }

    private makeImageGroup(img: Partial<ReportImage>) {
        if (!img) return null;

        return new FormGroup({
            key: new FormControl(img.key || null),
            fileRef: new FormControl(img.fileRef || null),
            name: new FormControl(img.name || null),
            extension: new FormControl(img.extension || null),
        })
    }

    private validateFiles(files: FileList) {
        let valid = true;
        this.validationErrors = [];

        for(let i = 0; i < files.length; i++) {
            let file = files[i];
            let fileExt = fileExtension(file.name).toLowerCase();

            if (!this.fileTypes.image.includes(fileExt)) this.validationErrors.push(`${file.name} is not a valid file type.`);
            if (file.size > this.maxSize) this.validationErrors.push(`${file.name} exceeds the maximum file size.`);
        }

        if (this.validationErrors.length > 0) valid = false;

        return valid;
    }

    private getImageOptions(): RemarkImageOption[] {
        return this._data.options ? this._data.options : [];
    }

    private setImageOptions(options: RemarkImageOption[]) {
        return this._data.options = options;
    }

    private addImageOption(imageOption: RemarkImageOption) {
        let options = this.getImageOptions();
        options.push(imageOption);

        this.setImageOptions(options);
    }

    private removeImageOption(imageOption: RemarkImageOption) {
        let options: RemarkImageOption[] = this.getImageOptions();
        options = options.filter(opt => opt.key !== imageOption.key && opt.fileRef !== imageOption.fileRef);

        this.setImageOptions(options);
    }

    private handleImageSelection(matOption: MatListOption) {
        let value = matOption.value;
        let option: RemarkImageOption = {
            key: value.key,
            fileRef: value.fileRef,
            position: -1,
            extension: fileExtension(value.name),
            name: value.name,
        };

        if (matOption.selected) {
            option.position = this.getImageOptions().length;
            return this.addImageOption(option);
        }

        this.removeImageOption(option);
    }

    private resetCultivarImages(){
        this.images$.subscribe(images => {
            let selectedImages = images.filter((image) => this.imageKeys.includes(image.key));
            this.updateSampleImages(selectedImages);
            this.includeImagesControl.setValue(selectedImages);
        })

        if (this.controlCultivarKey){
            this.controlImages$.subscribe(images => {
                let selectedImages = images.filter((image) => this.imageKeys.includes(image.key));
                this.updateSampleImages(selectedImages);
                this.controlCultivarImagesControl.setValue(selectedImages);
            })
        }
    }

    private resetUploadedImages() {
        let selectedImages: Partial<ReportImage>[] = this.remark.images ? this.remark.images.filter((image) => this.imageKeys.includes(image.key)) : [];

        if (this.imagesToAppend.length) selectedImages = [...new Set([...selectedImages, ...this.imagesToAppend])];

        this.updateReportImages(selectedImages);
        this.uploadedImagesControl.setValue(selectedImages);
    }

    private selectUploadedImage(image: Partial<ReportImage>) {
        let option: RemarkImageOption = {
            key: image.key,
            fileRef: image.fileRef,
            position: -1,
            extension: image.extension,
            name: image.name,
        }

        this.addImageOption(option);
        this.imageKeys.push(image.key);
        this.imagesToAppend.push(image);
        this.resetUploadedImages();
    }

    private updateSampleImages(images: SampleImage[]) {
        let options = [...this.getImageOptions()];
        let sampleImages = images.filter((image) => this.imageKeys.includes(image.key));

        for (let image of sampleImages) {
            let option = options.find((option) => option.key === image.key);
            let optionIndex = options.findIndex((opt) => opt.key === option.key);

            option = {
                ...option,
                extension: fileExtension(image.name),
                name: image.name,
            };

            options[optionIndex] = option;
            this.setImageOptions(options);
        }
    }

    private updateReportImages(images: Partial<ReportImage>[]) {
        let options = [...this.getImageOptions()];
        let reportImages = images.filter((image) => this.imageKeys.includes(image.key));

        for (let image of reportImages) {
            let option = options.find((option) => option.key === image.key);
            let optionIndex = options.findIndex((opt) => opt.key === option.key);

            option = {
                ...option,
                extension: image.extension,
                name: image.name,
            };

            options[optionIndex] = option;
            this.setImageOptions(options);
        }
    }

    cancel(refreshBuilder: boolean = false): void {
        this._dialogRef.close(refreshBuilder);
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }
}