import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

import { SimpleDialogComponent, SimpleDialogAction } from './simple-dialog.component';
import { translateCommonErrorStatus, StatusTitle, StatusMessage } from '@core/data';

@Injectable()
export class Dialog {

    constructor(private _mdDialog: MatDialog){}

    confirm(title: string, message: string, confirmLabel = 'Confirm', cancelLabel = 'Cancel'): MatDialogRef<SimpleDialogComponent>{

        let actions = [
            {
                result: false,
                label: cancelLabel,
            },
            {
                result: true,
                label: confirmLabel,
                color: 'warn',
                raised: true
            },
        ];

        return this.openSimpleDialog(title, message, actions);
    }

    confirmSave(title: string, message: string, confirmLabel = 'Confirm', cancelLabel = 'Cancel'): MatDialogRef<SimpleDialogComponent>{

        let actions = [
            {
                result: false,
                label: cancelLabel,
            },
            {
                result: true,
                label: confirmLabel,
                color: 'primary',
                raised: true
            },
        ];

        return this.openSimpleDialog(title, message, actions);
    }

    alert(title: string, message: string, confirmLabel = 'OK'){
        let actions = [
            {
                result: true,
                label: confirmLabel,
                color: 'warn',
                raised: true
            },
        ];
        return this.openSimpleDialog(title, message, actions);
    }

    error(error: any){

        const status = translateCommonErrorStatus(error);
        const title = StatusTitle[status];
        const message = StatusMessage[status];

        console.warn("Dialog: displaying error status=%s", status, error);

        return this.alert(title, message);

    }

    openSimpleDialog(title: string, message: string, actions: SimpleDialogAction[]){
        let ref = this.open(SimpleDialogComponent);

        ref.componentInstance.title = title;
        ref.componentInstance.message = message;
        ref.componentInstance.actions = actions;

        return ref;
    }

    open<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig) : MatDialogRef<T>{
        return this._mdDialog.open(componentOrTemplateRef, config);
    }

    openFullscreen<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig, padding = true) : MatDialogRef<T>{

        let fullscreenConfig = {
            panelClass: padding ? 'pv-fullscreen-dialog-pane' : 'pv-fullscreen-dialog-pane-no-padding',
            width: '100vw',
            height: '100vh'
        };

        return this._mdDialog.open(componentOrTemplateRef, {...fullscreenConfig, ...config});

    }

}