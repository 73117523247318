import { Property } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_PROPERTIES } from './properties';

export class PropertyDictionary extends Dictionary<Property>{
    constructor(){
        super(LIB_PROPERTIES);
    }
}

export * from './_types';