import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscriber } from 'rxjs';
import { Status, EvaluationService, MeasurementsConvertRequest, translateCommonErrorStatus } from '@core/data';
import { NumberValidators, fileExtension } from '@core/utils';
import { Snackbar } from '@core/material';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
    selector: 'pv-measurements-upload-dialog',
    templateUrl: 'measurements-upload.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementsUploadDialog implements OnInit, OnDestroy {

    maxSize = 4000000; // 4MB
    allowedExts = ['FTA', 'xls'];
    validationErrors: string[] = [];

    fileControl = new FormControl(null, [Validators.required]);

    formGroup = new FormGroup({
        file: this.fileControl,
        format: new FormControl('guss_fta_raw_format', [Validators.required]),
        indexOffset: new FormControl(0, [NumberValidators.integer(4), Validators.min(0)]),
    });

    status$ = new BehaviorSubject<Status>(Status.OK);

    uploadSub = Subscriber.EMPTY;

    constructor(
        private _dialogRef: MatDialogRef<MeasurementsUploadDialog>,
        private _evalService: EvaluationService,
        private _snackbar: Snackbar,
    ){}

    ngOnInit(){
        this.status$.subscribe(status => {
            if(status === Status.LOADING){
                this.formGroup.disable();
            }else{
                this.formGroup.enable();
            }
        });
    }

    ngOnDestroy(){
        this.status$.complete();
        this.uploadSub.unsubscribe();
    }

    setFormFile(file: File) {
        this.formGroup.get('file').setValue(file ? file : null);
    }

    attempt(){

        this.formGroup.updateValueAndValidity();

        this.uploadSub.unsubscribe();

        if(this.formGroup.valid){

            const form = this.formGroup.value;

            const request: MeasurementsConvertRequest = {
                ...form
            };

            this.status$.next(Status.LOADING);

            this.uploadSub = this._evalService.convertMeasurementsFile(request)
                .subscribe(
                    result => {
                        this.status$.next(Status.COMPLETE);
                        this._dialogRef.close(result);
                    },
                    error => {

                        if(error instanceof HttpErrorResponse && error.status === 422){
                            this._snackbar.error("Invalid file format. Check your file and try again.");
                        }else{
                            this._snackbar.error("Error uploading file.");
                        }

                        this.status$.next(translateCommonErrorStatus(error));
                        console.error("MeasurementsUploadDialog: caught error", error);
                    }
                );
        }

    }

    cancel(){
        this._dialogRef.close();
    }
}