import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filter, FilterQueryMode, User, UserService } from '@core/data';
import { Snackbar } from '@core/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OrganizationGroupUserFormDialogData {
    orgKey: string;
    groupKey: string;
}

export interface OrganizationGroupUserFormDialogResult {
    userKey: string[];
}

@Component({
    selector: 'pv-organization-group-user-form-dialog',
    templateUrl: 'organization-group-user-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationGroupUserFormDialog implements OnInit {
    _userKeyControl: FormControl = new FormControl('',[Validators.required]);

    formGroup = new FormGroup({
        userKey: this._userKeyControl,
    });

    users$: Observable<User[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrganizationGroupUserFormDialogData,
        private _dialog: MatDialogRef<OrganizationGroupUserFormDialog>,
        private _snackbar: Snackbar,
        private _userService: UserService,
    ) { }

    ngOnInit() {
        let filter: Filter = {
            queries: [
                {
                    key: 'orgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: this.data.orgKey
                },
                {
                    key: 'groupKey',
                    mode: FilterQueryMode.NOT_EQUALS,
                    value: this.data.groupKey
                }
            ],
            sort: {
                column: 'name',
                order: 'asc'
            }
        };

        this.users$ =
            this._userService.query(filter)
                .pipe(
                    map(result => result.data)
                );
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.valid) this.submit();
        else this._snackbar.error("Check your input and try again.");
    }

    submit() {
        this._dialog.close(this.formGroup.value);
    }

    cancel() {
        this._dialog.close();
    }
}
