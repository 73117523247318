import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cultivar, Status } from '@core/data';
import { Snackbar } from '@core/material';
import { Crop, Library } from '@library';
import { Select, Store } from '@ngxs/store';
import * as Fuse from 'fuse.js';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CultivarFormState, CultivarFormStateModel, InitCultivarForm, SubmitCultivarForm } from './cultivar-form.state';
import { LIBRARY } from '../../library';

@Component({
    selector: 'pv-cultivar-form-dialog',
    templateUrl: './cultivar-form.dialog.html'
})
export class CultivarFormDialog implements OnInit, OnDestroy {

    commonNameControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(32)]);
    cropIdControl: FormControl = new FormControl(null, [Validators.required]);
    isRootstockControl: FormControl = new FormControl(false);
    harvestWindowControl: FormControl = new FormControl(null);

    licenseeControl: FormControl = new FormControl(null, Validators.maxLength(32));
    tradeNamesControl: FormArray = new FormArray([]);
    selectionIdControl: FormControl = new FormControl(null, Validators.maxLength(32));
    importIdControl: FormControl = new FormControl(null, Validators.maxLength(32));
    ownerOrgKeyControl: FormControl = new FormControl(null, [Validators.required]);

    licensorControl: FormControl = new FormControl(null, Validators.maxLength(32));
    originControl: FormControl = new FormControl(null, Validators.maxLength(32));
    sallelesControl: FormControl = new FormControl(null, Validators.maxLength(32));
    pollinatorsControl: FormControl = new FormControl(null, Validators.maxLength(32));
    seedParentControl: FormControl = new FormControl(null, Validators.maxLength(32));
    pollenParentControl: FormControl = new FormControl(null, Validators.maxLength(32));
    licenseeAgreementControl: FormControl = new FormControl(null);
    licensorAgreementControl: FormControl = new FormControl(null);

    formGroup: FormGroup = new FormGroup({
        commonName: this.commonNameControl,
        cropId: this.cropIdControl,
        isRootstock: this.isRootstockControl,
        licensee: this.licenseeControl,
        tradeNames: this.tradeNamesControl,
        selectionId: this.selectionIdControl,
        importId: this.importIdControl,
        harvestWindow: this.harvestWindowControl,
        licensor: this.licensorControl,
        origin: this.originControl,
        salleles: this.sallelesControl,
        pollinators: this.pollinatorsControl,
        seedParent: this.seedParentControl,
        pollenParent: this.pollenParentControl,
        licenseeAgreement: this.licenseeAgreementControl,
        licensorAgreement: this.licensorAgreementControl,
        ownerOrgKey: this.ownerOrgKeyControl,
    });

    cropOptions: Crop[];
    orgOptions: any[] = [];

    filteredLicenseeOptions$: Observable<string[]>;

    searchOptions = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 30,
        maxPatternLength: 32,
        minMatchCharLength: 1,
    };

    @Select(CultivarFormState)
    state$: Observable<CultivarFormStateModel>;

    @Select(CultivarFormState.data)
    data$: Observable<Partial<Cultivar>>;

    @Select(CultivarFormState.licenseeOptions)
    licenseeOptions$: Observable<string[]>;

    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<CultivarFormDialog>,
        @Inject(MAT_DIALOG_DATA) private _data: CultivarFormDialogData,
        private _store: Store,
        @Inject(LIBRARY) private _evalLib: Library,
        private _snackbar: Snackbar
    ) { }

    ngOnInit() {
        this._store.dispatch(new InitCultivarForm(this._data.key, this._data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if (state.status === Status.COMPLETE) {

                    this._dialogRef.close(state.data);

                } else if (state.status === Status.OK || state.status === Status.INVALID) {
                    this.formGroup.enable();
                    if (state.errors.duplicateCommonName) {
                        this.commonNameControl.setErrors({ duplicateCommonName: true });
                    }
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });




        const fuse = new Fuse([], this.searchOptions);

        this.filteredLicenseeOptions$ =
            combineLatest(this.licenseeOptions$, this.licenseeControl.valueChanges)
            .pipe(
                takeUntil(this._destroy$),
                map(changes => {

                    let [licensees, search] = changes;

                    if(search !== null && search.length > 0 && licensees.length > 0){
                        fuse.setCollection(licensees);
                        return fuse.search(search).map(i => licensees[i]);
                    }

                    return licensees;

                })
            );


        this.cropOptions = this._evalLib.filterExcludedCrops();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    reset(data: Partial<Cultivar>) {

        this.tradeNamesControl.clear();

        if (Array.isArray(data.tradeNames)) {
            data.tradeNames.forEach(name => this.addTradeName());
        }

        const cropId = data.cropId || 'apple'

        const excludedCrop = this._evalLib.getExcludedCropList().find(crop => crop === cropId)

        if(excludedCrop){
           let crop = this._evalLib.crops.get(excludedCrop)
           this.cropOptions.push(crop)
        }

        this.formGroup.reset({
            commonName: data.commonName || '',
            cropId: excludedCrop ? excludedCrop : cropId,
            isRootstock: data.isRootstock || false,
            harvestWindow: data.harvestWindow || null,
            licensee: data.licensee || '',
            selectionId: data.selectionId || '',
            importId: data.importId || '',
            licensor: data.licensor || '',
            origin: data.origin || '',
            salleles: data.salleles || '',
            pollinators: data.pollinators || '',
            seedParent: data.seedParent || '',
            pollenParent: data.pollenParent || '',
            licenseeAgreement: data.licenseeAgreement || null,
            licensorAgreement: data.licensorAgreement || null,
            ownerOrgKey: data.ownerOrgKey,
            tradeNames: Array.isArray(data.tradeNames) ? data.tradeNames : []
        });

    }

    attempt() {

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    save() {

        const value = this.formGroup.value;

        let model = {
            ...value,
            tradeNames: value.tradeNames.filter(el => !!el)
        };

        this._store.dispatch(new SubmitCultivarForm(model));
    }

    cancel() {
        this._dialogRef.close();
    }

    addTradeName() {

        if (this.tradeNamesControl.length < 3) {
            this.tradeNamesControl.push(new FormControl('', Validators.maxLength(32)));
        } else {
            this._snackbar.info("Maximum of 3 designations.");
        }

    }

    removeTradeName(index: number) {
        this.tradeNamesControl.removeAt(index);
    }

}

export interface CultivarFormDialogData {
    key?: string;
    defaults?: Partial<Cultivar>;
}