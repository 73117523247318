import { Pipe, PipeTransform } from '@angular/core';
import { Evaluation } from '@core/data';

@Pipe({
    name: 'descLatestEval',
})
export class DescribeLatestEvaluationPipe implements PipeTransform {

    constructor() {}

    transform(data: Evaluation[]) {
        if (!data || data.length === 0) return 'N/A';

        let uncompletedEvals = data.filter(evaluation => evaluation.evalEndDate == null)
        if (!uncompletedEvals || uncompletedEvals.length === 0) return 'N/A';

        let latestUncompletedEval = uncompletedEvals.reduce((a, b) => {
            return new Date(a.evalStartDate) > new Date(b.evalStartDate) ? a : b;
        })

        return latestUncompletedEval;
    }
}