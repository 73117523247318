import { Protocol } from './_types';

export const LIB_PROTOCOL_AVOCADO_HARVEST_SCREEN_EVAL: Protocol = {
    id: "avocado_harvest_screen_eval",
    title: "Advanced Avocado Harvest Evaluation",
    description: "New Avocado Protocol",
    sampleType: "harvest",
    crop: "avocado",

    chars: [
        "avo_ripe_stage",
        "avo_stem_color",
        "avo_firmness_homog",
        "avo_mature_rate",

        "avo_over_color",

        "avo_seed_size",
        "avo_seed_shape",
        "avo_skin_thick",
        "avo_frut_shape",
        "avo_shape_symmetry",
        "avo_shape_consistent",

        "avo_abrasion",
        "avo_russet_type",

        "avo_defect",
        "avo_skin_tex",

        "avo_taste_aroma",
        "avo_taste_type",
        "avo_taste",
        "avo_text_firm",
        "avo_fibre",
        "avo_text",

        "avo_disease",
        "avo_disorder",
        "avo_chill_sens",

        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest",
    ],

    measures: [
        "frut_length",
        "frut_weight",
        "frut_diameter",
        "frut_firmness",
    ],

    indexes: [],

    calculations: []
}