import { Protocol } from './_types';

export const LIB_PROTOCOL_HARVEST_IMPRESSION_EVAL: Protocol = {
    id: "harvest_impression_eval",
    title: "Harvest Impression Evaluation",
    description: "Provar evaluation protocol for the collecting harvest impressions.",
    sampleType: "harvest",
    crop: "apple" || "pear" || "quince" || "peach" || "nectarine" || "plum" || "apricot" || "prune" || "blueberry" || "cherry" || "grape" || "inter_stone" || "pear" || "hybrid_citrus",

    chars: [
        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_color",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest"
    ],
    measures: [],

    indexes: [
        "indx_frut_overall"
    ],
}