<pv-view toolbarTitle="Dashboard" *ngIf="auth$ | async as auth" [ngSwitch]="auth.status">

    <!-- TOOLBAR -->
    <div pv-view-tools *ngSwitchCase="'OK'">

        <!-- NOTIFICATIONS TOGGLE -->
        <ng-container *ngIf="notifications$ | async as notifications">
            <button mat-icon-button
                [matMenuTriggerFor]="notificationMenu">
                <mat-icon
                    matBadgeColor="warn"
                    [matBadge]="notifications.unreadCount"
                    [matBadgeHidden]="notifications.unreadCount === 0">
                    notification_important
                </mat-icon>
            </button>

            <mat-menu #notificationMenu="matMenu" class="notification-menu" [ngSwitch]="notifications.status">
                <ng-template matMenuContent>

                    <!-- NOTIFICATION STATUS LOADING -->
                    <div class="notification-loader" *ngSwitchCase="'LOADING'">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>

                    <!-- NOTIFICATION STATUS ERROR -->
                    <div class="notification-error" *ngSwitchCase="'ERROR'">
                        <p>Error loading notifications. Please try again.</p>
                        <button mat-raised-button color="accent" (click)="reloadNotifications($event)">Reload</button>
                    </div>

                    <!-- NOTIFICATION STATUS EMPTY -->
                    <div class="notification-error" *ngSwitchCase="'EMPTY'">
                        <p>You have not received any new notifications.</p>
                    </div>

                    <!-- NOTIFICATION STATUS OK -->
                    <cdk-virtual-scroll-viewport
                    class="notification-list"
                    *ngSwitchCase="'OK'"
                    itemSize="100"
                    minBufferPx="200"
                    maxBufferPx="500"
                    (scrolledIndexChange)="onNotificationScroll($event)">
                        <button *cdkVirtualFor="let item of notifications.items; templateCacheSize: 0"
                        mat-menu-item class="notification-item"
                        [class.unread]="item.read_at === null"
                        (click)="selectNotification($event, item)">
                            <h4 class="notification-title">{{ item.data?.title }}</h4>
                            <p class="notification-message">{{ item.data?.message }}</p>
                            <p class="notification-link" *ngIf="item.data?.actionTitle">
                                {{ item.data.actionTitle }}
                                <mat-icon>arrow_right_alt</mat-icon>
                            </p>
                        </button>
                    </cdk-virtual-scroll-viewport>

                </ng-template>
            </mat-menu>
        </ng-container>


        <button mat-icon-button type="button" [matMenuTriggerFor]="profileMenu">
            <mat-icon>person</mat-icon>
        </button>

        <mat-menu #profileMenu="matMenu">
            <a mat-menu-item (click)="updateProfile()">
                <mat-icon>account_circle</mat-icon>
                <span>Edit Profile</span>
            </a>
            <button mat-menu-item (click)="logout()">
                <mat-icon>eject</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>

        <div type="button" class="toolbar-content">
            <h3 class="toolbar-title">{{ auth.user.name }}</h3>
            <h4 class="toolbar-subtitle">{{ auth.user.email }}</h4>
        </div>


    </div>

    <div pv-view-body class="dashboard-body" *ngSwitchCase="'OK'">

        <!-- WELCOME CARD -->
        <div class="dashboard-welcome">
            <mat-icon svgIcon="culteva:icon" class="welcome-icon"></mat-icon>
            <div class="welcome-content">
                <div class="welcome-title">Culteva&trade; Dashboard</div>
                <div class="welcome-subtitle">Your field and laboratory data all in one place.</div>
            </div>
        </div>

        <mat-tab-group class="dashboard-tabs"
            *ngIf="auth.user.organizations.length > 0 else noOrgs"
            [selectedIndex]="selectedOrgIndex$ | async"
            (selectedIndexChange)="selectOrg(auth.user.organizations[$event])">

            <mat-tab *ngFor="let org of auth.user.organizations" [label]="org.name">
                <ng-template matTabContent>

                    <div class="dashboard-cards dashboard-body" *ngIf="subscriptionState$ | async as subscriptionState">
                        <ng-container *ngIf="org?.access.role != 'guest'">
                            <mat-card class="dashboard-card">
                                <mat-card-header>
                                    <img mat-card-avatar *ngIf="(org.logoRef | imageFileRef:'fit_72/r_15') as src; else defaultOrgImageSingle" [src]="src" class="org-avatar">
                                    <ng-template #defaultOrgImageSingle>
                                        <mat-icon mat-card-avatar>account_balance</mat-icon>
                                    </ng-template>
                                    <mat-card-title>{{ org.name }}</mat-card-title>
                                    <mat-card-subtitle>{{ org | descAddress }}</mat-card-subtitle>
                                </mat-card-header>
                                <div mat-card-content class="mat-card-inset">
                                    <mat-nav-list>
                                        <mat-divider></mat-divider>
                                        <a mat-list-item [routerLink]="['/org', org.key, 'detail']">
                                            <mat-icon matListIcon>supervised_user_circle</mat-icon>
                                            <span matLine>Organization details</span>
                                            <span matLine>Organization info, members and invites.</span>
                                            <mat-icon>arrow_forward</mat-icon>
                                        </a>
                                    </mat-nav-list>
                                </div>
                                <mat-nav-list mat-card-content class="mat-card-inset">
                                    <mat-divider></mat-divider>

                                    <ng-container *ngIf="org?.access.role != 'guest'">
                                        <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'cultivars']">
                                            <mat-icon matListIcon svgIcon="common:flat_scion"></mat-icon>
                                            <span matLine>Cultivars</span>
                                            <span matLine>Genotype registration data</span>
                                            <mat-icon>arrow_forward</mat-icon>
                                        </a>
                                        <mat-divider></mat-divider>
                                        <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'reports']">
                                            <mat-icon matListIcon>ballot</mat-icon>
                                            <span matLine>Reports</span>
                                            <span matLine>Evaluation summaries</span>
                                            <mat-icon>arrow_forward</mat-icon>
                                        </a>
                                        <mat-divider></mat-divider>
                                        <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'sites']">
                                            <mat-icon matListIcon>map</mat-icon>
                                            <span matLine>Site Map</span>
                                            <span matLine>Block and boundaries</span>
                                            <mat-icon>arrow_forward</mat-icon>
                                        </a>
                                    </ng-container>

                                </mat-nav-list>
                            </mat-card>
                        </ng-container>

                        <!-- EVALUATION MODULE CARD -->
                        <mat-card class="dashboard-card" [class.disable-link]="!orgSubscriptionStatus.evaluation">

                            <!-- CARD HEADER -->
                            <mat-card-header>
                                <mat-icon mat-card-avatar>assignment</mat-icon>
                                <mat-card-title>Evaluation</mat-card-title>
                                <mat-card-subtitle>Evaluate the cultivars of tomorrow</mat-card-subtitle>
                            </mat-card-header>

                            <mat-nav-list mat-card-content class="mat-card-inset">
                                <mat-divider></mat-divider>
                                <ng-container>
                                    <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'samples', 'plant']">
                                        <mat-icon matListIcon svgIcon="common:plant_sample"></mat-icon>
                                        <span matLine>Plant Samples</span>
                                        <span matLine>Pre-harvest data</span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                    <mat-divider></mat-divider>
                                    <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'samples', 'harvest']">
                                        <mat-icon matListIcon svgIcon="common:harvest_sample"></mat-icon>
                                        <span matLine>Harvest Samples</span>
                                        <span matLine>Post-harvest data </span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                </ng-container>

                            </mat-nav-list>

                        </mat-card>
                        <!-- EVALUATION MODULE CARD END -->

                        <!-- TASTINGS MODULE CARD  -->
                        <mat-card class="dashboard-card" [class.disable-link]="!orgSubscriptionStatus.tastings" *ngIf="org?.access.role != 'guest'">
                            <mat-card-header>
                                <mat-icon mat-card-avatar>event</mat-icon>
                                <mat-card-title>Tastings </mat-card-title>
                                <mat-card-subtitle>Taste the Cultivars of Tomorrow</mat-card-subtitle>

                            </mat-card-header>

                            <mat-nav-list mat-card-content class="mat-card-inset">
                                <mat-divider></mat-divider>

                                <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'tastings-samples']">
                                    <mat-icon matListIcon svgIcon="common:tastings_sample"></mat-icon>
                                    <span matLine>Tastings Sample</span>
                                    <span matLine>Registration and management</span>
                                    <mat-icon>arrow_forward</mat-icon>
                                </a>
                                <mat-divider></mat-divider>
                                <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'tastings-events']">
                                    <mat-icon matListIcon svgIcon="common:tastings_events"></mat-icon>
                                    <span matLine>Tastings Events</span>
                                    <span matLine>Tastings event data</span>
                                    <mat-icon>arrow_forward</mat-icon>
                                </a>
                                <mat-divider></mat-divider>
                                <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'tastings-contacts']">
                                    <mat-icon matListIcon>contacts</mat-icon>
                                    <span matLine>Tastings Contact</span>
                                    <span matLine>Manage and email contact</span>
                                    <mat-icon>arrow_forward</mat-icon>
                                </a>
                                <mat-divider></mat-divider>
                                <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'tastings-indexes']">
                                    <mat-icon matListIcon>calculate</mat-icon>
                                    <span matLine>Consumer Indexes</span>
                                    <span matLine>Create your own</span>
                                    <mat-icon>arrow_forward</mat-icon>
                                </a>
                                <mat-divider></mat-divider>
                                <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'tastings-weighted-chars']">
                                    <mat-icon matListIcon>analytics</mat-icon>
                                    <span matLine>Characteristics</span>
                                    <span matLine>Customise your parameters</span>
                                    <mat-icon>arrow_forward</mat-icon>
                                </a>
                                <mat-divider></mat-divider>
                                <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'tastings-tags']">
                                    <mat-icon matListIcon>label</mat-icon>
                                    <span matLine>Tags</span>
                                    <span matLine>Consumer indicators</span>
                                    <mat-icon>arrow_forward</mat-icon>
                                </a>
                            </mat-nav-list>

                        </mat-card>
                        <!-- TASTINGS MODULE CARD END -->

                        <!-- UTILITY CARD -->
                        <mat-card class="dashboard-card" *ngIf="org?.access.role != 'guest'">
                            <mat-card-header>
                                <mat-icon mat-card-avatar>construction</mat-icon>
                                <mat-card-title>Utilities</mat-card-title>
                                <mat-card-subtitle class="cardsub">Tailor you experience</mat-card-subtitle>
                            </mat-card-header>

                            <mat-nav-list mat-card-content class="mat-card-inset">
                                <mat-divider></mat-divider>
                                <ng-container>
                                    <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'protocols']">
                                        <mat-icon matListIcon>ballot</mat-icon>
                                        <span matLine>Protocols</span>
                                        <span matLine>Create your own</span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                </ng-container>
                                <mat-divider></mat-divider>
                                <ng-container>
                                    <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'weather-stations']">
                                        <mat-icon matListIcon>thermostat</mat-icon>
                                        <span matLine>Weather Station</span>
                                        <span matLine>Connect and upload data</span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                </ng-container>
                                <mat-divider></mat-divider>
                                <ng-container>
                                    <a mat-list-item color="primary" [routerLink]="['/org', org.key, 'groups']">
                                        <mat-icon matListIcon>groups</mat-icon>
                                        <span matLine>Sample Groups</span>
                                        <span matLine>Pre-saved filters</span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                </ng-container>

                            </mat-nav-list>

                        </mat-card>
                        <!-- UTILITY CARD END -->

                        <!-- SUPPORT CARD -->
                        <mat-card class="dashboard-card" *ngIf="org?.access.role != 'guest'">
                            <!-- CARD HEADER -->
                            <mat-card-header>
                                <mat-icon mat-card-avatar>help</mat-icon>
                                <mat-card-title>Support</mat-card-title>
                                <mat-card-subtitle class="cardsub">Let us help you flourish</mat-card-subtitle>
                            </mat-card-header>

                            <mat-nav-list mat-card-content class="mat-card-inset">
                                <mat-divider></mat-divider>
                                <ng-container>
                                    <a mat-list-item color="primary" [hostLink]="'support/user-manual'" target="_blank">
                                        <mat-icon matListIcon>lightbulb_outline</mat-icon>
                                        <span matLine>User Manual</span>
                                        <span matLine>Your comprehensive guide</span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                </ng-container>
                                <mat-divider></mat-divider>
                                <ng-container>
                                    <a mat-list-item color="primary" [hostLink]="'support#section_contact_form'" target="_blank">
                                        <mat-icon matListIcon>contact_support</mat-icon>
                                        <span matLine>Contact Support</span>
                                        <span matLine>Connect with our support team</span>
                                        <mat-icon>arrow_forward</mat-icon>
                                    </a>
                                </ng-container>

                            </mat-nav-list>

                        </mat-card>
                        <!-- SUPPORT CARD END -->

                    </div>

                </ng-template>
            </mat-tab>
        </mat-tab-group>

        <ng-template #noOrgs>
            <mat-card class="m-12">
                <mat-card-header>
                    <mat-icon mat-card-avatar>security</mat-icon>
                    <mat-card-title>No Organization Access</mat-card-title>
                    <mat-card-subtitle>You do not have access to any organizations.</mat-card-subtitle>
                </mat-card-header>
                <div mat-card-content>
                    <p>
                        Your access might have been revoked by an administrator,
                        or you have not accepted an invitation to join an organization.
                        Recheck invite email link. If the problem persists contact
                        support@culteva.pro for more information.
                    </p>
                </div>
            </mat-card>
        </ng-template>

    </div>

    <div pv-view-body *ngSwitchCase="'UNAUTHENTICATED'" class="dashboard-body">

        <div class="dashboard-cards">
            <!-- UNAUTHENTICATED CARD -->
            <mat-card class="sign-in-card">
                <mat-card-header>
                    <mat-icon mat-card-avatar>person</mat-icon>
                    <mat-card-title>Sign In</mat-card-title>
                    <mat-card-subtitle>Sign in to access Culteva</mat-card-subtitle>
                </mat-card-header>
                <mat-card-actions align="end">
                    <a mat-raised-button color="primary" [routerLink]="['/login']">Sign In</a>
                </mat-card-actions>
            </mat-card>
        </div>

    </div>


</pv-view>
