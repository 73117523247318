import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { fileExtension } from "@core/utils";

@Component({
    selector: 'pv-legacy-file-upload',
    templateUrl: './legacy-file-upload.component.html',
    styleUrls: ['./legacy-file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        class: 'pv-legacy-file-upload'
    }
})
export class LegacyFileUploadComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    maxSize: number; // in bytes

    @Input()
    allows: String[];

    @Input()
    label: String;

    @Output() file = new EventEmitter<File>();

    displayName: string;
    validFiles: string = '';
    validationErrors: string[] = [];

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges) {
        let length = this.allows.length;

        for (let i = 0; i < length; i++) {
            if (i == length - 1 && length -1 != 0) this.validFiles += `and ${this.allows[i]}`;
            else if (i != length - 1 && length > 2) this.validFiles += `${this.allows[i]}, `;
            else this.validFiles += length -1 > 0 ? `${this.allows[i]} ` : this.allows[i];
        }
    }

    fileInputChanged(event: Event) {
        let input = <HTMLInputElement> event.target;
        if (input.files && input.files.length > 0) {

            if (!this.validateFile(input.files[0])) return;

            this.displayName = input.files[0].name
            this.file.emit(input.files[0]);
        }
    }

    private validateFile(file: File){

        let valid = true;
        this.validationErrors = [];

        let fileExt = fileExtension(file.name).toLowerCase();

        if(!this.allows.includes(fileExt)){
            this.validationErrors.push(`${file.name} is not a valid file type.`);
        }

        if(file.size > this.maxSize){
            this.validationErrors.push(`${file.name} exceeds the maximum file size.`);
        }

        if(this.validationErrors.length > 0){
            valid = false;
        }

        return valid;
    }
}