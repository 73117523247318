import { Injectable } from "@angular/core";
import {
    CultivarCreated,
    CultivarDeleted,
    CultivarUpdated,
    SiteCreated,
    SiteDeleted,
    SiteUpdated,
    SampleDeleted,
    EvaluationUpdated,
    EvaluationCreated,
    EvaluationDeleted,
    WeatherStationDeleted,
    WeatherStationUpdated,
    WeatherStationCreated,
    OrganizationSubscriptionCreated,
    OrganizationSubscriptionUpdated,
    OrganizationSubscriptionDeleted,
    TastingSampleDeleted,
    TastingSampleCreated,
    TastingSampleUpdated,
    TastingEventCreated,
    TastingEventUpdated,
    TastingEventDeleted,
    TastingContactCreated,
    TastingContactUpdated,
    TastingContactDeleted,
    TastingContactInvited,
    TastingEventInviteSent,
    TastingSampleCreatedFromCultivar,
    CultivarIndexImported,
    SiteIndexImported,
    TastingEventIndexImported,
    SampleIndexImported,
    TastingsEvaluationsImported,
    TastingsIndexCreated,
    TastingsIndexUpdated,
    TastingsIndexDeleted,
    WeightedCharCreated,
    WeightedCharUpdated,
    WeightedCharDeleted,
    TagCreated,
    TagUpdated,
    TagDeleted
} from '@core/data';
import { Snackbar } from '@core/material';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpdateTastingsSamplesToEvent } from "./tastings/components/tastings-sample-index/tastings-sample-index.state-actions";
import { SetOrganizationContext } from "./auth";
import { InitProtocolManagerState } from "./evaluation/components/protocol-manager/protocol-manager.state";
import { InitSampleDetail } from "./evaluation/components/sample-detail/sample-detail.state-actions";
import { InitSampleIndex } from "./evaluation/components/sample-index/sample-index.state-actions";


@Injectable()
export class AppInfoHandler {

    private _destroy = new Subject();

    constructor(
        private _snackbar: Snackbar,
        private _actions$: Actions,
        private _store: Store,
    ){}

    init(){

        this._actions$.pipe(
            takeUntil(this._destroy),
        ).subscribe(result => {

            if(result.status === 'DISPATCHED'){
                this.handleActionDispatch(result.action);

                if (this.requireProtocolDispatch(result.action)) this.handleProtocolDispatch(result.action)
            }

        });

    }

    destroy(){
        this._destroy.next();
        this._destroy.complete();
    }

    private handleActionDispatch(action: any){

        if(action instanceof CultivarUpdated) this._snackbar.info(`Cultivar '${action.cultivar.commonName}' updated.`);
        else if(action instanceof CultivarCreated) this._snackbar.info(`Cultivar '${action.cultivar.commonName}' added.`);
        else if(action instanceof CultivarDeleted) this._snackbar.info(`Cultivar '${action.cultivar.commonName}' deleted.`);
        else if(action instanceof SiteUpdated) this._snackbar.info(`Site '${action.site.block}, ${action.site.name}' updated.`);
        else if(action instanceof SiteCreated) this._snackbar.info(`Site '${action.site.block}, ${action.site.name}' created.`);
        else if(action instanceof SiteDeleted) this._snackbar.info(`Site '${action.site.block}, ${action.site.name}' deleted.`);
        else if(action instanceof SampleDeleted) this._snackbar.info(`Sample '${action.sample.code} deleted.`);
        else if(action instanceof EvaluationUpdated) this._snackbar.info(`Evaluation '${action.evaluation.label}' updated.`);
        else if(action instanceof EvaluationCreated) this._snackbar.info(`Evaluation '${action.evaluation.label}' created.`);
        else if(action instanceof EvaluationDeleted) this._snackbar.info(`Evaluation '${action.evaluation.label}' deleted.`);
        else if(action instanceof OrganizationSubscriptionCreated) this._snackbar.info(`Organization Subscription Created`);
        else if(action instanceof OrganizationSubscriptionUpdated) this._snackbar.info(`Organization Subscription Updated`);
        else if(action instanceof OrganizationSubscriptionDeleted) this._snackbar.info(`Organization Subscription  ${action.orgSubscription.key} Deleted`);
        else if(action instanceof TastingSampleCreated) this._snackbar.info(`Tasting Sample '${action.tastingSample.label}' Created`);
        else if(action instanceof TastingSampleUpdated) this._snackbar.info(`Tasting Sample '${action.tastingSample.label}' Updated`);
        else if(action instanceof TastingSampleDeleted) this._snackbar.info(`Tasting Sample '${action.tastingSample.label}' Deleted`);
        else if(action instanceof TastingEventCreated) this._snackbar.info(`Tasting Event '${action.tastingEvent.title}' Created`);
        else if(action instanceof TastingEventUpdated) this._snackbar.info(`Tasting Event '${action.tastingEvent.title}' Updated`);
        else if(action instanceof TastingEventDeleted) this._snackbar.info(`Tasting Event '${action.tastingEvent.title}' Deleted`);
        else if(action instanceof TastingContactCreated) this._snackbar.info(`Tasting Contact '${action.tastingContact.firstName + " " + action.tastingContact.lastName}' Created`);
        else if(action instanceof TastingContactUpdated) this._snackbar.info(`Tasting Contact '${action.tastingContact.firstName + " " + action.tastingContact.lastName}' Updated`);
        else if(action instanceof TastingContactDeleted) this._snackbar.info(`Tasting Contact '${action.tastingContact.firstName + " " + action.tastingContact.lastName}' Deleted`);
        else if(action instanceof TastingContactInvited) this._snackbar.info(`${action.invitation.contacts.length} Contacts Invited`);
        else if(action instanceof TastingEventInviteSent) this._snackbar.info(`${action.invitation.emails.length} Contacts Invited`);
        else if(action instanceof UpdateTastingsSamplesToEvent) this._snackbar.info(`Tasting Sample ${action.data.label} Link Updated`);
        else if(action instanceof WeatherStationDeleted) this._snackbar.info(`Weather station '${action.weatherStation.name}' Deleted.`);
        else if(action instanceof WeatherStationCreated) this._snackbar.info(`Weather station '${action.weatherStation.name}' Created.`);
        else if(action instanceof WeatherStationUpdated) this._snackbar.info(`Weather station '${action.weatherStation.name}' Updated.`);
        else if(action instanceof TastingSampleCreatedFromCultivar) this._snackbar.info(`${action.createdCount} Tastings Sample(s) Created`);
        else if(action instanceof CultivarIndexImported) this._snackbar.info(action.response.response);
        else if(action instanceof SiteIndexImported) this._snackbar.info(action.response.response);
        else if(action instanceof TastingEventIndexImported) this._snackbar.info(action.response.response);
        else if(action instanceof SampleIndexImported) this._snackbar.info(action.response.response);
        else if(action instanceof TastingsEvaluationsImported) this._snackbar.info(action.response.response);
        else if(action instanceof TastingsIndexCreated) this._snackbar.info(`Index ${action.index.title} Created`);
        else if(action instanceof TastingsIndexUpdated) this._snackbar.info(`Index ${action.index.title} Updated`);
        else if(action instanceof TastingsIndexDeleted) this._snackbar.info(`Index ${action.index.title} Deleted`);
        else if(action instanceof WeightedCharCreated) this._snackbar.info(`Weighted Characteristic ${action.weightedChar.label} Created`);
        else if(action instanceof WeightedCharUpdated) this._snackbar.info(`Weighted Characteristic ${action.weightedChar.label} Updated`);
        else if(action instanceof WeightedCharDeleted) this._snackbar.info(`Weighted Characteristic ${action.weightedChar.label} Deleted`);
        else if(action instanceof TagCreated) this._snackbar.info(`Tag ${action.tag.label} Created`);
        else if(action instanceof TagUpdated) this._snackbar.info(`Tag ${action.tag.label} Updated`);
        else if(action instanceof TagDeleted) this._snackbar.info(`Tag ${action.tag.label} Deleted`);
    }

    private handleProtocolDispatch(action: any){
        this._store.dispatch(new InitProtocolManagerState(action.orgKey));
    }

    private requireProtocolDispatch(action: any):boolean{
        if(action instanceof SetOrganizationContext) return true

        return false;
    }

}