<ng-container *ngIf="state$ | async as state">
    <h2 mat-dialog-title style="max-width: 500px;">Share Report: {{ state.report?.title }}</h2>
    <div mat-dialog-content class="mat-typography">



        <form #reportShareForm="ngForm"
              class="pv-form single-column"
              [formGroup]="formGroup"
              (ngSubmit)="attemptShare()"
              style="max-width: 500px;">

            <ng-container *ngIf="state.report?.shareKey else notShared">

                <p>
                    Copy the link below to share the report,
                    or send the link to someone by adding their email adresses below.
                </p>

                <mat-form-field appearance="fill" *ngIf="getUrl(state.report) as url" style="width: 100%;">
                    <mat-label>Share URL</mat-label>
                    <textarea rows="3" matInput [value]="url" readonly #linkTextarea></textarea>
                    <mat-hint>Copy &amp; Paste the full url to share</mat-hint>
                    <button matSuffix mat-icon-button type="button" (click)="copyToClipboard()" matTooltip="Copy To Clipboard">
                        <mat-icon>file_copy</mat-icon>
                    </button>
                </mat-form-field>

            </ng-container>

            <ng-template #notShared>

                <p>
                    You can create a link to a report so
                    anyone on the internet with the link can view it.
                    Additionally, you may send the
                    link to someone by entering their email address below.
                </p>

            </ng-template>

            <mat-form-field appearance="fill">
                <mat-label>Send Link To Email Adresses</mat-label>
                <textarea matInput
                            rows="4"
                            autocomplete="off"
                            formControlName="contactEmails"
                            style="width: 100%;"
                            placeholder="eg. john@provar.co.za,sally@gmail.com"></textarea>
                <mat-hint>Comma seperated list of addresses</mat-hint>
                <mat-error>Valid email addresses, comma seperated, no spaces</mat-error>
            </mat-form-field>

            <mat-checkbox formControlName="sendSelf">Send to myself</mat-checkbox>

            <p class="mat-caption text-warn">
                <strong>Disclaimer</strong><br>

                - Anyone with the link will be able to view the report
                and all associated sample evaluation data,
                cultivars, locations, etc.<br>
                - Ensure you only
                share the link with trusted individuals.<br>
                - Neither Provar nor you have control to
                whom the initial recipient forwards
                or shares this link with.<br>
                - The generated link will always reflect the current evaluation data.<br>
                - Neither the report nor evaluation data can be modified using the link.
            </p>
        </form>



    </div>

    <pv-form-actions mat-dialog-actions
                     [saveAction]="state.report?.shareKey ? 'Share Link' : 'Create Link & Share'"
                     [confirmDiscard]="false"
                     [form]="reportShareForm"
                     (cancel)="cancel()"
                     [status]="state.status">
        <button pvFormAction
                *ngIf="state.report?.shareKey"
                mat-raised-button
                type="button"
                color="warn"
                (click)="stopSharing()">
            Stop Sharing
        </button>
    </pv-form-actions>
</ng-container>
