import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { LIBRARY } from '@app/evaluation/library';
import { HumidityDataPoint, SFDeviceLocation, SigFoxDataPoint, SigFoxMessage, TempDataPoint, WeatherReportData, WeatherReportInterval, WeatherReportOptions, WeatherStation } from '@core/data';
import { LatLng } from '@core/maps';
import { Dialog, Snackbar } from '@core/material';
import { coerceTimestampProperty } from '@core/utils';
import { Library } from '@library';
import { Select, Store } from "@ngxs/store";
import * as moment from 'moment';
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { WeatherChart, WeatherChartCompilerService } from "../../weather-chart-compiler.service";
import { WeatherReadingsUploadDialog, WeatherReadingsUploadDialogData } from "../weather-readings-upload/weather-readings-upload.dialog";
import { WeatherStationFormDialog, WeatherStationFormDialogData } from "../weather-station-form/weather-station-form.dialog";
import { DeleteWeatherStation, InitWeatherStationIndex, LoadWeatherStationIndex, SelectWeatherStation, SetWeatherStationReportOptions, WeatherStationIndexState, WeatherStationIndexStateModel } from './weather-station-index.state';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadReadingsSigFoxData, WeatherStationService } from '@core/data/api/weather-station.service';



@Component({
    selector: 'pv-weather-station-index-view',
    templateUrl: 'weather-station-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeatherStationIndexView implements OnInit, OnDestroy {

    @Select(WeatherStationIndexState)
    state$: Observable<WeatherStationIndexStateModel>;

    @Select(WeatherStationIndexState.selected)
    selected$: Observable<WeatherStation>;

    chart$: Observable<WeatherChart>;

    startDateControl = new FormControl(null, [Validators.required]);
    endDateControl = new FormControl(null, [Validators.required]);

    syncSinceDateControl = new FormControl(null, [Validators.required]);
    syncBeforeDateControl = new FormControl(null, [Validators.required]);

    reportOptionsGroup = new FormGroup({
        reportId: new FormControl(null, [Validators.required]),
        startDate: this.startDateControl,
        endDate: this.endDateControl,
        interval: new FormControl('daily', [Validators.required]),
    });


    // keep a ref to last report a chart was compiled for
    // as well as the compiled chart
    private _lastReportCompiled: WeatherReportData;
    private _lastChartCompiled: WeatherChart;

    private _destroy$ = new Subject<void>();

    //review ==> Sigfox requests
    testSigFoxMessage: SigFoxMessage = { data: null, time: null };
    testSFDeviceLocation: SFDeviceLocation[] = [];
    testTempDataPoint: TempDataPoint[] = [];
    testHumDataPoint: HumidityDataPoint[] = [];
    testSigFoxDataPoint: SigFoxDataPoint[];

    constructor(
        private _dialogs: Dialog,
        private _store: Store,
        private _route: ActivatedRoute,
        private _changeRef: ChangeDetectorRef,
        private _chartCompiler: WeatherChartCompilerService,
        @Inject(LIBRARY) private _library: Library,
        private _snackbar: Snackbar,
        private _domSanitizer: DomSanitizer,
        private _weatherService: WeatherStationService
    ) { }

    ngOnInit() {

        this._route.paramMap.subscribe(params => {
            this._store.dispatch(new InitWeatherStationIndex(params.get('orgKey')));
        });

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                const reportOptions = this.reportOptionsGroup.value;

                if (
                    state.reportOptions.reportId !== reportOptions.reportId
                    || state.reportOptions.startDate !== reportOptions.startDate
                    || state.reportOptions.endDate !== reportOptions.endDate
                    || state.reportOptions.interval !== reportOptions.interval
                ) {
                    this.reportOptionsGroup.setValue({
                        ...state.reportOptions,
                    }, { emitEvent: false });
                }


            });

        this.chart$ = this.state$
            .pipe(
                map(state => state.reportData),
                distinctUntilChanged(),
                takeUntil(this._destroy$),
                map(data => {
                    return this._chartCompiler.compileReportChart(data)
                })
            );

        this.reportOptionsGroup
            .valueChanges
            .pipe(debounceTime(300))
            .subscribe(form => {
                if (this.reportOptionsGroup.valid) {

                    let options: WeatherReportOptions = {
                        reportId: form.reportId,
                        interval: form.interval,
                        startDate: moment(form.startDate).format(),
                        endDate: moment(form.endDate).endOf('day').format(),
                    };

                    this._store.dispatch(new SetWeatherStationReportOptions(options));

                }

            });

    }

    async getAllSigFoxMessages(): Promise<SigFoxDataPoint[]> {

        let before = moment();
        let since = moment().subtract(1, 'month');

        let dataSet = [];

        return this.testSigFoxDataPoint;

    }


    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    add(orgKey: string, event?: MouseEvent) {

        if (event) event.stopPropagation();

        let data: WeatherStationFormDialogData = {
            defaults: {
                ownerOrgKey: orgKey,
            }
        };

        this._dialogs.open(WeatherStationFormDialog, { data });

    }

    edit(station: WeatherStation) {

        let data: WeatherStationFormDialogData = {
            key: station.key,
        };

        this._dialogs.open(WeatherStationFormDialog, { data });

    }

    delete(station: WeatherStation) {


        let dialog = this._dialogs.confirm(
            'Delete Station ' + station.name,
            'Are you sure you want to delete this weather station?',
            'Delete',
            'Cancel'
        );

        dialog.afterClosed()
            .subscribe(res => {
                if (res) {
                    this._store.dispatch(new DeleteWeatherStation(station.key))
                        .subscribe(state => {
                            this._snackbar.info(`Weather Station ${station.name} deleted`);
                        });
                }

            });

    }

    selectStation(station: WeatherStation) {
        this._store.dispatch(new SelectWeatherStation(station.key));
    }

    openFileUploadDialog(station: WeatherStation) {

        let data: WeatherReadingsUploadDialogData = {
            key: station.key,
        };

        this._dialogs.open(WeatherReadingsUploadDialog, { data });

    }

    async syncSigFoxData(station: WeatherStation) {

        let data: UploadReadingsSigFoxData = {
            data: await this.getAllSigFoxMessages()
        };

        this._weatherService.uploadSigFoxReadings(station.key, data).subscribe(data => {
            console.log(data);
        });
    }

    getChart(report: WeatherReportData) {

        if (report === null) {
            return null;
        }

        if (this._lastReportCompiled && this._lastReportCompiled && this._lastReportCompiled === report) {
            return this._lastChartCompiled;
        }

        this._lastReportCompiled = report;
        this._lastChartCompiled = this._chartCompiler.compileReportChart(report);

        return this._lastChartCompiled;
    }

    getMaxDate(endDate: Date) {
        let startDate = moment(endDate);
        return startDate.subtract(1, 'days').format("YYYY-MM-DD");
    }

    getMinDate(startDate) {
        let endDate = moment(startDate);
        return endDate.add(1, 'days').format("YYYY-MM-DD");
    }

    getLatLng(station: WeatherStation): LatLng {
        if (station.lat !== null && station.lng !== null) {
            return {
                lat: station.lat,
                lng: station.lng
            };
        }
        return null;
    }

    reloadIndex() {
        this._store.dispatch(new LoadWeatherStationIndex());
    }

    downloadCSV(reportData: WeatherReportData){


        if(reportData.data && reportData.data.length > 0){

            const options = this._library.weatherReports.get(reportData.reportId);

            const rows = reportData.data.map(data => {
                return [data.ts, ...options.attributes.map(attr => {
                    return data[attr.id];
                })].join(',');
            }).join('\n');

            const headers = ['Time', ...options.attributes.map(attr => attr.label)].join(';') + '\n';

            const uri = this._domSanitizer.bypassSecurityTrustUrl(encodeURI("data:text/csv;charset=utf-8," + headers + rows));
            const name = `${reportData.reportId}.csv`;

            this._snackbar.link(
                "CSV Ready",
                "Download",
                uri,
                false,
                name
            );
        }


    }

    getReportOptions(reportId: string){
        return this._library.weatherReports.get(reportId);
    }

    getSigFoxDate(date: string) {
        let interval = this.reportOptionsGroup.get('interval').value;
        if (interval === WeatherReportInterval.HOURLY) return moment(date + "Z").format();
        return date;
    }

    setPublicSigFoxDataPoint(point: SigFoxDataPoint[]) {
        this.testSigFoxDataPoint = point;
    }

    setPublicMessage(data: string[], time: any) {
        this.testSigFoxMessage.data = data;
        this.testSigFoxMessage.time = time;
    };

    setPublicTemp(data: TempDataPoint[]) {
        this.testTempDataPoint = data;
    };

    setPublicHum(data: HumidityDataPoint[]) {
        this.testHumDataPoint = data;
    };

    setPublicDeviceLocation(data: SFDeviceLocation[]) {
        this.testSFDeviceLocation = data;
    }

    getPublicTemp(): TempDataPoint[] {
        return this.testTempDataPoint;
    }

    getPublicHum(): HumidityDataPoint[] {
        return this.testHumDataPoint;
    }
}