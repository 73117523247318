import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { Index } from "../../types/EvaluationLibrary/tastings-index";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class TastingsIndexCreated {
    static readonly type = "[IndexService] Index Created";
    constructor(public index: Index) {}
}

export class TastingsIndexUpdated {
    static readonly type = "[IndexService] Index Updated";
    constructor(public index: Index) {}
}

export class TastingsIndexDeleted {
    static readonly type = "[IndexService] Index Deleted";
    constructor(public index: Index) {}
}

@Injectable()
export class IndexService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) {}

    get(key: string, detail?: DetailRequest): Observable<Index> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<Index>(`${this._baseUri}/evaluation-library/index/${key}`, {params});
    }

    create(model: Partial<Index>) {
        return this._http.post<Index>(`${this._baseUri}/evaluation-library/index`, model)
            .pipe(tap(index => this._store.dispatch(new TastingsIndexCreated(index))));
    }

    update(key: string, model: Partial<Index>) {
        return this._http.put<Index>(`${this._baseUri}/evaluation-library/index/${key}`, model)
            .pipe(tap(index => this._store.dispatch(new TastingsIndexUpdated(index))));
    }

    delete(key: string) {
        return this._http.delete<Index>(`${this._baseUri}/evaluation-library/index/${key}`)
            .pipe(tap(index => this._store.dispatch(new TastingsIndexDeleted(index))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<Index>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<Index>>(`${this._baseUri}/evaluation-library/index`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<Index>>(`${this._baseUri}/evaluation-library/index/search`, {params: <any>search});
    }
}