import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'implode' })
export class ImplodePipe implements PipeTransform {

    transform(value: any[]) {

        if (Array.isArray(value)) {
            return value.filter(v => v !== undefined && v !== null).join(', ');
        }

        return '';
    }

}