import { Characteristic, CharacteristicType } from './_types';


export const LIB_CHARS_NUT_TREE: Characteristic[] = [
    {
        id: "date_irb_nut",
        type: CharacteristicType.Event,
        label: "Initial Reproductive Budbreak",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#673AB7"
        }
    },
    {
        id: "date_ivb_nut",
        type: CharacteristicType.Event,
        label:"Initial Vegetative Budbreak",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#4CAF50"
        }
    },
    {
        id: "date_start_flower_nut",
        type: CharacteristicType.Event,
        label: "Start Flowering",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_end_flower_nut",
        type: CharacteristicType.Event,
        label: "End Flowering",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_set_date",
        type: CharacteristicType.Event,
        label: "Set Date",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_kernel_abs",
        type: CharacteristicType.Event,
        label: "Kernel Abscission",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_harvest_start_nut",
        type: CharacteristicType.Event,
        label: "Harvest Start",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "date_harvest_end_nut",
        type: CharacteristicType.Event,
        label: "Harvest End",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#E65100"
        }
    },
    {
        id: "date_leaf_drop_nut",
        type: CharacteristicType.Event,
        label: "Leaf Drop",
        categoryId: "nut_tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "tree_branch_habit_mac",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_arch",
        label: "Branching Habit",
        altLabels: ["Tree Form", "Plant Form"],
        params: {
            options: [
                { value: "umbrella", label: "Umbrella" },
                { value: "broad", label: "Broad" },
                { value: "broad_upright", label: "Broad-Upright" },
                { value: "pyramidal", label: "Pyramidal" },
                { value: "columnar", label: "Columnar" },
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f1_pre",
        type: CharacteristicType.Interval,
        label: "Flush 1 Growth Potential (pre-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f1_post",
        type: CharacteristicType.Interval,
        label: "Flush 1 Growth Potential (post-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f2_pre",
        type: CharacteristicType.Interval,
        label: "Flush 2 Growth Potential (pre-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f2_post",
        type: CharacteristicType.Interval,
        label: "Flush 2 Growth Potential (post-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f3_pre",
        type: CharacteristicType.Interval,
        label: "Flush 3 Growth Potential (pre-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f3_post",
        type: CharacteristicType.Interval,
        label: "Flush 3 Growth Potential (post-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f4_pre",
        type: CharacteristicType.Interval,
        label: "Flush 4 Growth Potential (pre-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f4_post",
        type: CharacteristicType.Interval,
        label: "Flush 4 Growth Potential (post-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f5_pre",
        type: CharacteristicType.Interval,
        label: "Flush 5 Growth Potential (pre-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_grow_potent_mac_f5_post",
        type: CharacteristicType.Interval,
        label: "Flush 5 Growth Potential (post-pruning)",
        categoryId: "nut_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "nut_tree_grow_form",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_arch",
        label: "Growth Form",
        params: {
            options: [
                { value: "basitonic", label: "Basitonic" },
                { value: "mesotonic", label: "Mesotonic" },
                { value: "acrotonic", label: "Acrotonic" }
            ]
        }
    },
    {
        id: "nut_tree_branch_dense",
        type: CharacteristicType.Interval,
        label: "Branching Density",
        categoryId: "nut_tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "nut_tree_canopy_dense",
        type: CharacteristicType.Interval,
        label: "Canopy Density",
        categoryId: "nut_tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "nut_tree_branch_angle",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_arch",
        label: "Branch Angle",
        params: {
            options: [
                { value: "acute", label: "Acute" },
                { value: "obtuse", label: "Obtuse" },
            ]
        }
    },
    {
        id: "nut_tree_weather_damage",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_arch",
        label: "Weather Damage",
        params: {
            options: [
                { value: "small", label: "Small" },
                { value: "medium", label: "Medium" },
                { value: "large", label: "Large" },
            ]
        }
    },
    {
        id: "nut_tree_leaf_colour",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_arch",
        label: "Leaf Colour",
        params: {
            options: [
                { value: "light_green", label: "Light Green" },
                { value: "intermediate", label: "Intermediate" },
                { value: "dark_green", label: "Dark Green" },
                { value: "red", label: "Red" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "nut_tree_plant_system",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_trellis",
        label: "Planting System",
        params: {
            options: [
                { value: "square", label: "Square" },
                { value: "rectangular", label: "Rectangular" },
                { value: "hexagonal", label: "Hexagonal" },
                { value: "square_w_fill", label: "Square with Fillers" },
                { value: "quincunx_w_fill", label: "Quincunx with Fillers" },
            ]
        }
    },
    {
        id: "nut_tree_flower_colour",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_flower",
        label: "Flower Colour",
        params: {
            options: [
                { value: "white", label: "White" },
                { value: "light_pink", label: "Light Pink" },
                { value: "pink", label: "Pink" },
                { value: "dark_pink", label: "Dark Pink" },
                { value: "red", label: "Red" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "nut_tree_flower_pos",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_flower",
        label: "Flower Position",
        params: {
            options: [
                { value: "no_flower", label: "No Flowering" },
                { value: "flushes_new", label: "Flushes on new wood" },
                { value: "flushes_old", label: "Flushes on old wood" },
                { value: "flushes_new_old", label: "Flushes on new and old wood" },
            ]
        }
    },
    {
        id: "nut_tree_flower_distrib",
        type: CharacteristicType.Interval,
        label: "Flower Distribution",
        categoryId: "nut_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "No Flower Clusters",
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "nut_tree_multiple_flower",
        type: CharacteristicType.Interval,
        label: "Multiple Flowering",
        categoryId: "nut_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "nut_tree_inflorescence_length",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_flower",
        label: "Inflorescence Length",
        params: {
            options: [
                { value: "short", label: "Short" },
                { value: "medium", label: "Medium" },
                { value: "long", label: "Long" },
            ]
        }
    },
    {
        id: "nut_tree_inflorescence_cluster",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_flower",
        label: "Inflorescence Clustering",
        params: {
            options: [
                { value: "single", label: "Single" },
                { value: "multiple", label: "Multiple" },
            ]
        }
    },
    {
        id: "nut_tree_bear_precocity",
        type: CharacteristicType.Interval,
        label: "Precocity",
        categoryId: "nut_tree_kernel",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Late",
                "Intermediate",
                "Early"
            ]
        }
    },
    {
        id: "nut_tree_bear_wood",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_kernel",
        label: "Bearing Wood",
        params: {
            options: [
                { value: "no_set", label: "No Set" },
                { value: "bear_new", label: "Bearing on new wood" },
                { value: "bear_old", label: "Bearing on old wood" },
                { value: "bear_new_old", label: "Bearing on new and old wood" },
            ]
        }
    },
    {
        id: "nut_tree_bear_pos",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_kernel",
        label: "Bearing Position",
        params: {
            options: [
                { value: "short_shoots", label: "Short Shoots" },
                { value: "long_shoots", label: "Long Shoots" },
                { value: "all_lengths", label: "All Lengths" }
            ]
        }
    },
    {
        id: "nut_tree_set_pot",
        type: CharacteristicType.Interval,
        label: "Set Potential",
        categoryId: "nut_tree_kernel",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Optimal",
                "High",
                "Very High",
            ]
        }
    },
    {
        id: "nut_tree_alt_bear",
        type: CharacteristicType.Interval,
        label: "Alternate Bearing",
        categoryId: "nut_tree_kernel",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Consistent",
                "Consistent",
                "Intermediate",
                "Alternating",
                "Very Alternating",
            ]
        }
    },
    {
        id: "nut_tree_pest_detect",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_disorder",
        label: "Insects and Pests Detected",
        params: {
            options: [
                { value: "rattus", label: "Rattus rattus" },
                { value: "kerner_borer", label: "Kernel Borer / False Codling Moth" },
                { value: "early_stink", label: "Early Stink Bug" },
                { value: "late_stink", label: "Late Stink Bug" },
                { value: "thrips", label: "Thrips" },
                { value: "mac_felted_coccid", label: "Macadamia Felted Coccid" },
                { value: "stem_borers", label: "Stem Borers" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "nut_tree_disease_detect",
        type: CharacteristicType.Nominal,
        categoryId: "nut_tree_disorder",
        label: "Diseases Detected",
        params: {
            options: [
                { value: "rat_tail", label: "Raceme Blight / Rat Tail" },
                { value: "phytophthora", label: "Trunk and stem canker (Phytophthora cinnamomi)" },
                { value: "anthracnose", label: "Anthracnose Husk Rot (Colletotrichum gloeosporiodes)" },
                { value: "hust_rot", label: "Husk Rot (Diaporthe spp)" },
                { value: "leaf_blight", label: "Leaf Blight / Brown leaf blight (Botryosphaeria)" },
                { value: "black_leaf_blight", label: "Black Leaf Blight (Alternaria)" },
                { value: "leaf_anth", label: "Leaf Anthracnose (Colletotrichum)" },
                { value: "yellow_halo_leaf", label: "Yellow Halo Leaf Blight (Pestalotiopsis/Neopestalotiopsis)" }
            ]
        }
    },
    {
        id: "nut_tree_overall_disease",
        type: CharacteristicType.Interval,
        label: "Overall Diseases Presence",
        categoryId: "nut_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
];