import { Characteristic, CharacteristicType } from './_types';


export const LIB_CHARS_FLOWER_PLANT: Characteristic[] = [
    
    //Generic
    {
        id: "date_plant_flower_start",
        type: CharacteristicType.Event,
        label: "Start of Flowering",
        categoryId: "plant_generic",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_harvest",
        type: CharacteristicType.Event,
        label: "Date of Harvest",
        categoryId: "plant_generic",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "plant_grow_location",
        type: CharacteristicType.Nominal,
        categoryId: "plant_generic",
        label: "Location",
        params: {
            options: [
                { value: "type_1", label: "Indoor" },
                { value: "type_2", label: "Greenhouse" },
                { value: "type_3", label: "Outdoor" },
            ]
        }
    },
    {
        id: "plant_flower_duration",
        type: CharacteristicType.Nominal,
        categoryId: "plant_generic",
        label: "Flowering Duration",
        params: {
            options: [
                { value: "type_1", label: "Short" },
                { value: "type_2", label: "Medium" },
                { value: "type_3", label: "Long" },
            ]
        }
    },
    {
        id: "plant_growth_type",
        type: CharacteristicType.Nominal,
        categoryId: "plant_generic",
        label: "Growth Type (Stretch)",
        params: {
            options: [
                { value: "type_1", label: "Dwarf" },
                { value: "type_2", label: "Short" },
                { value: "type_3", label: "Medium" },
                { value: "type_4", label: "Large" },
                { value: "type_5", label: "Extra Large" },
            ]
        }
    },
    {
        id: "plant_sex",
        type: CharacteristicType.Nominal,
        categoryId: "plant_generic",
        label: "Plant Sex",
        params: {
            options: [
                { value: "type_1", label: "Male" },
                { value: "type_2", label: "Female" },
            ]
        }
    },
    {
        id: "plant_maternal_origin",
        type: CharacteristicType.Nominal,
        categoryId: "plant_generic",
        label: "Plant Sex",
        params: {
            options: [
                { value: "type_1", label: "Seed" },
                { value: "type_2", label: "Clone" },
            ]
        }
    },
    //Pests, Diseases & Disorders
    {
        id: "plant_mealybugs_symptom",
        type: CharacteristicType.Interval,
        label: "Mealybugs Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_root_symptom",
        type: CharacteristicType.Interval,
        label: "Root Disease Presence",
        categoryId: "plant_diseases",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_leaf_septoria_symptom",
        type: CharacteristicType.Interval,
        label: "Leaf Septoria Presence",
        categoryId: "plant_diseases",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_tobacco_symptom",
        type: CharacteristicType.Interval,
        label: "Tobacco Mosaic Virus (TMV) Presence",
        categoryId: "plant_diseases",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_aphids_symptom",
        type: CharacteristicType.Interval,
        label: "Aphids Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_spider_symptom",
        type: CharacteristicType.Interval,
        label: "Spider Mites Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_whiteflies_symptom",
        type: CharacteristicType.Interval,
        label: "Whiteflies Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_thrips_symptom",
        type: CharacteristicType.Interval,
        label: "Thrips Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_scale_symptom",
        type: CharacteristicType.Interval,
        label: "Scale Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_broad_mites_symptom",
        type: CharacteristicType.Interval,
        label: "Broad Mites Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_russet_mites_symptom",
        type: CharacteristicType.Interval,
        label: "Russet Mites Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_caterpillar_symptom",
        type: CharacteristicType.Interval,
        label: "Caterpillar Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_grasshopper_symptom",
        type: CharacteristicType.Interval,
        label: "Grasshopper Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_fungus_gnats_symptom",
        type: CharacteristicType.Interval,
        label: "Fungus Gnats Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_leaf_hoppers_symptom",
        type: CharacteristicType.Interval,
        label: "Leaf Hoppers Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_leaf_miners_symptom",
        type: CharacteristicType.Interval,
        label: "Leaf Miners Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_snails_symptom",
        type: CharacteristicType.Interval,
        label: "Snails Presence",
        categoryId: "plant_pests",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    //Vegetative
    {
        id: "plant_foliage_color_veg",
        type: CharacteristicType.Color,
        categoryId: "plant_vegetative",
        label: "Foliage Color",
        params: {
            palette: [
                { h: 15, s: 50, l: 35, label: "Brown/Dry" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 55, l: 70, label: "Light Green" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 90, s: 50, l: 30, label: "Dark Green" },
            ]
        }
    },

    //Flowering
    {
        id: "plant_foliage_color_flower",
        type: CharacteristicType.Color,
        categoryId: "plant_flowering",
        label: "Foliage Color",
        params: {
            palette: [
                { h: 15, s: 50, l: 35, label: "Brown/Dry" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 55, l: 70, label: "Light Green" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 90, s: 50, l: 30, label: "Dark Green" },
            ]
        }
    },
    {
        id: "plant_leaf_petiole_color_flower",
        type: CharacteristicType.Color,
        categoryId: "plant_flowering",
        label: "Color of Leaf Petiole",
        params: {
            palette: [
                { h: 15, s: 50, l: 35, label: "Brown/Dry" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 55, l: 70, label: "Light Green" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 90, s: 50, l: 30, label: "Dark Green" },
            ]
        }
    },
    {
        id: "plant_flower_color_flower",
        type: CharacteristicType.Color,
        categoryId: "plant_flowering",
        label: "Flower Color",
        params: {
            palette: [
                { h: 15, s: 50, l: 35, label: "Brown/Dry" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 55, l: 70, label: "Light Green" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 90, s: 50, l: 30, label: "Dark Green" },           //make sure of intervals here?
            ]
        }
    },
    {
        id: "plant_trichrome_dense",
        type: CharacteristicType.Interval,
        label: "Trichrome Density",
        categoryId: "plant_flowering",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Trichromes",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "plant_flower_size",
        type: CharacteristicType.Nominal,
        categoryId: "plant_flowering",
        label: "Flower Size",
        params: {
            options: [
                { value: "type_1", label: "Small" },
                { value: "type_2", label: "Medium" },
                { value: "type_3", label: "Large" },
                { value: "type_4", label: "Extra Large" },
            ]
        }
    },
    {
        id: "plant_flower_shape",
        type: CharacteristicType.Nominal,
        categoryId: "plant_flowering",
        label: "Flower Size",
        params: {
            options: [
                { value: "type_1", label: "Spike" },
                { value: "type_2", label: "Cluster" },
                { value: "type_3", label: "Ovate" },
                { value: "type_4", label: "Foxtailed" },
            ]
        }
    },
    {
        id: "plant_flower_size_leaf_blade",
        type: CharacteristicType.Nominal,
        categoryId: "plant_flowering",
        label: "Size of Leaf Blade",
        params: {
            options: [
                { value: "type_1", label: "Small" },
                { value: "type_2", label: "Medium" },
                { value: "type_3", label: "Large" },
            ]
        }
    },
    {
        id: "plant_flower_serrate_nature_leaf_blade",
        type: CharacteristicType.Nominal,
        categoryId: "plant_flowering",
        label: "Serrate Nature of Leaf Blade",
        params: {
            options: [
                { value: "type_1", label: "Weak" },
                { value: "type_2", label: "Medium" },
                { value: "type_3", label: "Strong" },
            ]
        }
    },
    //Flower Development
    {
        id: "date_plant_flower_dev_emerge",
        type: CharacteristicType.Event,
        label: "Pre-Flower Emergence",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_flower_dev_start",
        type: CharacteristicType.Event,
        label: "Start of Flowering",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_flower_dev_primordia",
        type: CharacteristicType.Event,
        label: "Primordia Formation",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_flower_dev_pistol",
        type: CharacteristicType.Event,
        label: "Pistol Emergence",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_flower_dev_calyx",
        type: CharacteristicType.Event,
        label: "Calyx Formation",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_flower_dev_tri",
        type: CharacteristicType.Event,
        label: "Trichome Ripening",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_plant_flower_dev_inflour",
        type: CharacteristicType.Event,
        label: "Calyx/Inflorescence Ripening",
        categoryId: "plant_flower_dev",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "plant_mildew",
        type: CharacteristicType.Interval,
        label: "Powdery Mildew Presence",
        categoryId: "plant_diseases",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "plant_mold",
        type: CharacteristicType.Interval,
        label: "Bud Mold Presence",
        categoryId: "plant_diseases",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },   
];