<pv-view toolbarTitle="Organization Detail" class="organization-detail-view" *ngIf="detailState$ | async as state"
    [ngSwitch]="state.status">

    <div pv-view-body class="organization-detail-body">

        <!-- ORGANIZATION DETAIL -->
        <mat-card *ngSwitchCase="'LOADING'" class="org-detail-card">
            <mat-card-content>
                <pv-view-loader message="Loading detail..."></pv-view-loader>
            </mat-card-content>
        </mat-card>

        <mat-card class="org-detail-card" *ngSwitchCase="'OK'">

            <!-- ORG CARD HEADER -->
            <mat-card-header>
                <img *ngIf="state.organization.logoRef | imageFileRef:'fit_72/r_15' as ref; else defaultAvatar"
                    mat-card-avatar [src]="ref" class="org-info-avatar">
                <ng-template #defaultAvatar>
                    <mat-icon mat-card-avatar>account_balance</mat-icon>
                </ng-template>
                <mat-card-title>{{ state.organization.name }}</mat-card-title>
                <mat-card-subtitle>Basic Information</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>

            <div mat-card-content *ngIf="state.organization as org">
                <mat-list mat-card-content dense *ngSwitchCase="'OK'">
                    <mat-list-item role="list-item">
                        <span matLine><strong class="org-info-headings">Reg. Name </strong><br/>{{ org.business || 'Unkown'}}</span>
                    </mat-list-item>

                    <mat-list-item role="list-item">
                        <span matLine><strong class="org-info-headings">Street</strong><br/>{{ org.addrStreet || 'Unkown'}}</span>
                    </mat-list-item>

                    <mat-list-item role="list-item">
                        <span matLine><strong class="org-info-headings">City</strong><br/>{{ org.addrCity || 'Unkown'}}</span>
                    </mat-list-item>

                    <mat-list-item role="list-item">
                        <span matLine><strong class="org-info-headings">Region</strong><br/>{{ org.addrRegion || 'Unkown'}}</span>
                    </mat-list-item>

                    <mat-list-item role="list-item">
                        <span matLine><strong class="org-info-headings">Country</strong><br/>{{ org.addrCountry || 'Unkown'}}</span>
                    </mat-list-item>

                    <mat-list-item role="list-item">
                        <span matLine><strong class="org-info-headings">Website</strong><br/>{{ org.website || 'Unkown'}}</span>
                    </mat-list-item>
                </mat-list>
            </div>

            <!-- ORG CARD ACTIONS -->
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" (click)="updateInfo(state.orgKey)">Update Info</button>
            </mat-card-actions>

        </mat-card>

        <mat-card *ngSwitchDefault class="org-detail-card">

            <!-- HEADER -->
            <mat-card-header>
                <mat-icon mat-card-avatar>error</mat-icon>
                <mat-card-title>{{ state.status | descStatusTitle }}</mat-card-title>
                <mat-card-subtitle>{{ state.status | descStatusMessage:'Organisation' }}</mat-card-subtitle>
            </mat-card-header>

            <!-- ACTIONS -->
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" (click)="reloadInfo()">Reload</button>
            </mat-card-actions>

        </mat-card>

        <!-- GROUPS -->
        <mat-card class="org-detail-card" *ngIf="groupsState$ | async as groupsState" [ngSwitch]="groupsState.status">
            <mat-card-header>
                <mat-icon mat-card-avatar>groups</mat-icon>
                <mat-card-title>Organisation Groups</mat-card-title>
                <mat-card-subtitle>Sample groups for this organisation</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content *ngSwitchCase="'LOADING'">
                <pv-view-loader message="Loading groups..."></pv-view-loader>
            </mat-card-content>

            <mat-list mat-card-content dense *ngSwitchCase="'OK'">
                <mat-list-item role="list-item" *ngFor="let group of groupsState.data">
                    <mat-icon matListAvatar>people</mat-icon>
                    <span matLine><strong>{{ group.label }}</strong>&nbsp;&lt;{{ group.type }}&gt;</span>
                    <span matLine>{{ group.description }}</span>
                    <button mat-icon-button (click)="addGroup(state.orgKey, group)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="removeGroup(group)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Samples"
                        [routerLink]="['/org', state.orgKey, 'groups']">
                        <mat-icon svgIcon="common:{{ group.type }}_sample"
                            [matBadge]="group.samplesCount"
                            [matBadgeColor]="group.samplesCount ? 'primary' : 'accent'">
                        </mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Users"
                        [matMenuTriggerFor]="usersMenu"
                        [matMenuTriggerData]="{ group: group }">
                        <mat-icon [matBadge]="group.usersCount" [matBadgeColor]="group.usersCount ? 'primary' : 'accent'">
                            people
                        </mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
            <mat-menu #usersMenu="matMenu">
                <ng-template matMenuContent let-group="group">
                    <pv-organization-group-user-list
                        [orgKey]="state.orgKey"
                        [group]="group">
                    </pv-organization-group-user-list>
                </ng-template>
            </mat-menu>

            <mat-card-content *ngSwitchCase="'EMPTY'">
                <mat-list dense>
                    <mat-list-item>
                        <mat-icon matListIcon>error</mat-icon>
                        <span matLine>There are no groups for this organisation</span>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>

            <!-- ACTIONS -->
            <mat-card-actions align="end">
                <button mat-icon-button (click)="reloadGroups()">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button mat-raised-button color="primary" (click)="addGroup(state.orgKey)">Add Group</button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="org-detail-card">
            <mat-card-header>
                <mat-icon mat-card-avatar>people</mat-icon>
                <mat-card-title>Organisation Members</mat-card-title>
                <mat-card-subtitle>Users with access to this organisation</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <pv-organization-members-list [orgKey]="state.orgKey"></pv-organization-members-list>
        </mat-card>

        <ng-container class="org-detail-card">
            <pv-organization-stats [orgKey]="state.orgKey"></pv-organization-stats>
        </ng-container>

        <!-- INVITES -->
        <mat-card *ngIf="invitesState$ | async as invitesState" [ngSwitch]="invitesState.status"
            class="org-detail-card">

            <!-- INVITES CARD HEADER -->
            <mat-card-header>
                <mat-icon mat-card-avatar>contact_mail</mat-icon>
                <mat-card-title>Organisation Invitations</mat-card-title>
                <mat-card-subtitle>Users invited via email to join this organisation</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>

            <!-- INVITES STATUS LOADING -->
            <mat-card-content *ngSwitchCase="'LOADING'">
                <pv-view-loader message="Loading invites..."></pv-view-loader>
            </mat-card-content>

            <!-- INVITES STATUS OK -->
            <mat-list mat-card-content dense *ngSwitchCase="'OK'">
                <mat-list-item role="list-item" *ngFor="let invite of invitesState.data">
                    <mat-icon matListAvatar [color]="!isExpired(invite.expiresAt) ? 'warn' : null">email</mat-icon>
                    <ng-template #expiredAvatar>
                        <mat-icon matListAvatar>event_busy</mat-icon>
                    </ng-template>
                    <span matLine><strong>{{ invite.userName }}&nbsp;&lt;{{ invite.userEmail }}&gt;</strong></span>
                    <span matLine>Role: {{ invite.userRole | labelAccess }}</span>
                    <span matLine *ngIf="isExpired(invite.expiresAt)">Valid until
                        {{ invite.expiresAt | date:'mediumDate' }}</span>
                    <span matLine class="text-warn" *ngIf="!isExpired(invite.expiresAt)">Expired
                        {{ invite.expiresAt | date:'mediumDate' }}</span>
                    <ng-container *ngIf="isExpired(invite.expiresAt)">
                        <button mat-icon-button (click)="expireInvite(invite.id)" matTooltip="Expire Invite">
                            <mat-icon>alarm_off</mat-icon>
                        </button>
                    </ng-container>
                </mat-list-item>
            </mat-list>

            <mat-card-content *ngSwitchCase="'EMPTY'">
                <mat-list dense>
                    <mat-list-item>
                        <mat-icon matListIcon>error</mat-icon>
                        <span matLine>There are no invitations for this organisation</span>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>

            <mat-card-content *ngSwitchDefault>
                <mat-list dense>
                    <mat-list-item>{{ state.status | descStatusMessage:'Organisation' }}</mat-list-item>
                </mat-list>

            </mat-card-content>

            <!-- ACTIONS -->
            <mat-card-actions align="end">
                <button mat-icon-button (click)="reloadInvites()">
                    <mat-icon>refresh</mat-icon>
                </button>
                <button mat-raised-button color="primary" (click)="createInvite()">Create Invite</button>
            </mat-card-actions>
        </mat-card>

        <!-- Subscription card -->
        <mat-card *ngIf="subscriptionState$ | async as subscriptionState" class="org-detail-card">

            <!-- Subscription Card Header -->
            <mat-card-header>
                <mat-icon mat-card-avatar>subscriptions</mat-icon>
                <mat-card-title>Subscriptions</mat-card-title>
                <mat-card-subtitle>Your ogranization's subscriptions</mat-card-subtitle>
            </mat-card-header>

            <mat-divider></mat-divider>

            <!-- Subscription Card Content -->
            <mat-card-content [ngSwitch]="subscriptionState.status">

                <pv-view-loader message="Loading Organisation Subscriptions..." *ngSwitchCase="'LOADING'">
                </pv-view-loader>

                <!-- Subscriptions -->
                <mat-accordion *ngSwitchCase="'OK'">
                    <div *ngFor="let subscription of subscriptionState.data" style="margin-left: -16px; margin-right: -16px;">
                        <mat-expansion-panel style="border-radius: 0 !important;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <ng-container *ngIf="isCommonLib(subscription.moduleRef)">
                                        <mat-icon matListIcon svgIcon="{{getIconRef(subscription.moduleRef)}}"></mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="!isCommonLib(subscription.moduleRef)">
                                        <mat-icon>{{getIconRef(subscription.moduleRef)}}</mat-icon>
                                    </ng-container>
                                    &nbsp;{{ resolveModuleSubscription(subscription.moduleRef) | titlecase }}
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{ subscription.startAt | date:'longDate' }} - {{ subscription.endAt | date:'longDate' }}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="subscription-container">
                                <div class="subscription-content-card">
                                    <span class="subscription-card-title"><strong>Subscribed Module</strong></span>
                                    <span class="subscription-card-content">{{ resolveModuleSubscription(subscription.moduleRef) | titlecase }}</span>
                                </div>

                                <div class="subscription-content-card">
                                    <span class="subscription-card-title"><strong>Fee</strong></span>
                                    <span class="subscription-card-content">ZAR{{ subscription.fee }}</span>
                                </div>

                                <div class="subscription-content-card">
                                    <span class="subscription-card-title"><strong>Evaluators Limit</strong></span>
                                    <span class="subscription-card-content">{{ subscription.usersLimit }}</span>
                                </div>

                                <div class="subscription-content-card">
                                    <span class="subscription-card-title"><strong>Autorenew</strong></span>
                                    <span class="subscription-card-content">{{ subscription.autorenew ? 'Enabled' : 'Disabled' }}</span>
                                </div>

                                <div class="subscription-content-card">
                                    <span class="subscription-card-title"><strong>Created</strong></span>
                                    <span class="subscription-card-content">{{ subscription.createdAt | date:'medium' }}</span>
                                </div>

                                <div class="subscription-content-card">
                                    <span class="subscription-card-title"><strong>Updated</strong></span>
                                    <span class="subscription-card-content">{{ subscription.updatedAt | date:'medium' }}</span>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </mat-accordion>


                <mat-list *ngSwitchCase="'EMPTY'" dense class="mat-card-inset">
                    <mat-list-item>
                        <mat-icon matListIcon>error</mat-icon>
                        <span matLine>There are no subscriptions for this organisation</span>
                    </mat-list-item>
                </mat-list>

                <mat-list *ngSwitchDefault dense class="mat-card-inset">
                    <mat-list-item>
                        <mat-icon matListIcon>error</mat-icon>
                        <span matLine>{{ subscriptionState.status | descStatusTitle }}</span>
                        <span matLine>{{ subscriptionState.status | descStatusMessage:'Subscriptions' }}</span>
                    </mat-list-item>
                </mat-list>

            </mat-card-content>

            <mat-card-actions align="end">
                <button mat-icon-button (click)="refreshSubscriptions()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </mat-card-actions>

        </mat-card>
        <!-- End Subscription Card -->
    </div>
</pv-view>
