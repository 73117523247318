import {Component, ChangeDetectionStrategy} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: 'simple-dialog.component.html',
  styleUrls: ['simple-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent {

    constructor(private _ref: MatDialogRef<SimpleDialogComponent>){}

    title = '';
    message = '';
    type = 'info';

    actions: any[] = [{
      result: 'ok',
      label: 'OK'
    }];

    resolve(result: any){
      this._ref.close(result);
    }

}

export interface SimpleDialogAction {
  result: any;
  label: string;
  color?: string;
  raised?: boolean;
}