import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportPreviewState, ReportPreviewStateModel, InitPreview, LoadReportPreview } from './report-preview.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ViewComponent, Dialog } from '@core/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Report } from '@core/data';
import { ReportShareDialogData, ReportShareDialog } from '../report-builder/report-share.dialog';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'pv-report-preview-view',
    templateUrl: "report-preview.view.html",
    styleUrls: ['report-preview.view.scss'],
})
export class ReportPreviewView implements OnInit {

    @Select(ReportPreviewState)
    state$: Observable<ReportPreviewStateModel>;

    @ViewChild(ViewComponent, { static: true })
    viewComponent: ViewComponent;

    dummyFormControl: FormControl = new FormControl([]);

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _router: Router,
        private _dialog: Dialog,
    ){}

    ngOnInit() {

        // Subscribes to the route params and sends the report key to the state to populate the preview
        this._route.paramMap.subscribe(params => {
            this._store.dispatch(new InitPreview(params.get('reportKey')));
        });
    }

    // Redirects back to report index
    back() {
        this._router.navigate(['..'], { relativeTo: this._route });
    }

    // Switches to edit mode
    edit(){
        this._router.navigate(['..', 'edit'], {relativeTo: this._route});
    }

    // Refreshes the state
    refresh() {
        this._store.dispatch(new LoadReportPreview());
    }

    share(report: Report){

        const data: ReportShareDialogData = {key: report.key};

        this._dialog.open(ReportShareDialog, {data, disableClose: false});

    }

}