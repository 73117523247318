import { Protocol } from './_types';

export const LIB_PROTOCOL_APPLE_HARVEST_BREED_EVAL: Protocol = {
    id: "apple_harvest_breed_eval",
    title: "Observational Apple Harvest Evaluation",
    description: "Observational Fruit evaluation is performed to characterise new cultivars and selections on the base of a sample set of their fruit traits and includes characteristics such as colour and pattern, shapes and symmetry, defects and disorders and eating quality that are scored for brief reference.",
    sampleType: "harvest",
    crop: "apple",

    chars: [

        "frut_over_color",
        "frut_ground_color_pome",
        "frut_flesh_color",

        "frut_shape_type_apple",
        "frut_shape_symmetry",
        "frut_stem_length",

        "frut_russet",
        "frut_lenticel",

        "frut_taste_sugar",
        "frut_taste_acid",
        "frut_taste_balance",
        "frut_taste_aroma",
        "frut_taste_juice",

        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_color",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest",

        "reco_breedable",
    ],

    measures: [
        "frut_weight",
        "frut_diameter",
        "frut_firmness",
        "frut_tss",
    ],

    calculations: [
        "sugar_acid_ratio_calc",
    ],

    indexes: [
        "indx_frut_overall"
    ],
}