import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALIDATORS, ValidationErrors, Validator, NgForm, FormGroupDirective, FormControl } from '@angular/forms';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { HSL } from '@core/utils';
import { Subject, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';


@Component({
    selector: 'pv-colorpicker',
    templateUrl: 'colorpicker.component.html',
    exportAs: 'pvColorpicker',
    styleUrls: ['colorpicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    providers: [
        { provide: MatFormFieldControl, useExisting: ColorpickerComponent },
    ]
})
export class ColorpickerComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator, MatFormFieldControl<HSL> {

    static nextId = 0;

    @HostBinding() id = `colorpicker-input-${ColorpickerComponent.nextId++}`;
    controlType = 'colorpicker-input';

    @HostBinding('attr.aria-describedby') describedBy = '';
    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    @Input()
    set value(value: HSL) {

        if (this._value === value) return;

        if (typeof value === 'string') {
            value = HSL.fromString(value);
        }

        if (value instanceof HSL) {
            if (!value.equals(this._value)) {
                this._value = value;
                this._onChange(this._value);
            }
        } else {
            this._value = value;
            this._onChange(this._value);
        }

        this.stateChanges.next();
        this.updateErrorState();
    }
    get value() {
        return this._value;
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    get empty() {
        return !this._value;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {

        if(this.matFormField && this.matFormField._shouldAlwaysFloat){
            return true;
        }

        return this.focused || !this.empty || this.isOpen;
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }



    @Input('palette')
    set palette(palette: { h: number; s: number; l: number; }[]) {

        if (Array.isArray(palette)) {
            this.colors = palette.map(el => HSL.fromObject(el).toString());
        }

    }

    stateChanges = new Subject<void>();
    errorState = false;
    _changeSub = Subscription.EMPTY;
    _onChange: any = () => { };
    _onTouch: any = () => { };
    _value: HSL;
    _disabled = false;
    _required = false;
    private _placeholder: string;


    isOpen = false;
    focused = false;

    colors = [];

    constructor(
        @Optional() private _parentForm: NgForm,
        @Optional() private _parentFormGroup: FormGroupDirective,
        @Optional() public matFormField: MatFormField,
        @Optional() @Self() public ngControl: NgControl,
        private elRef: ElementRef<HTMLInputElement>,
        public _defaultErrorStateMatcher: ErrorStateMatcher
    ) {

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        } else {
            console.warn("ColorpickerComponent: No ngControl provided");
        }

    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.close();
        this._changeSub.unsubscribe();
        this.stateChanges.complete();
    }

    open() {
        this.isOpen = true;
    }

    close() {
        this.isOpen = false;
        this._onTouch();
        this.focusInput();
    }

    clear() {
        this.value = null;
        this.close();
    }

    toggle() {

        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    backdropClick() {
        this.close();
    }

    getSelectedColor() {
        if (this.value && this.value instanceof HSL && this.value.isValid()) {
            return this.value.toString();
        }

        return null;
    }

    setSaturation(value: number) {
        if (this._value) this.value.s = value;
        else this.value = new HSL(0, value, 0);
    }

    setLightness(value: number) {
        if (this._value) this.value.l = value;
        else this.value = new HSL(0, 0, value);
    }

    setHue(value: number) {
        if (this._value) this.value.h = value;
        else this.value = new HSL(value, 0, 0);
    }

    selectColor(color: string) {
        this.value = HSL.fromString(color);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    validate(control: AbstractControl): ValidationErrors {

        if (control.value === null) return null;

        if (control.value instanceof HSL && control.value.isValid()) {
            return null;
        }

        return { 'color': true };
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.focusInput();
        }
    }

    writeValue(value: any): void {
        if (this._value === value) return;

        if (typeof value === 'string') {
            value = HSL.fromString(value);
        }

        if (value instanceof HSL || value === null) {
            this._value = value;
            this.stateChanges.next();
        } else {
            console.warn('ColorpickerComponent: invalid value written to control', value);
        }
        this.updateErrorState();
    }

    focusInput() {
        this.elRef.nativeElement.querySelector('input').focus();
    }

    onFocusChange(origin: string) {
        this.focused = !!origin;
        this.stateChanges.next();
    }

    updateErrorState() {
        const parent = this._parentFormGroup || this._parentForm;
        const control = this.ngControl ? this.ngControl.control as FormControl : null;

        const errorState = this._defaultErrorStateMatcher.isErrorState(control, parent);
        if(this.errorState !== errorState){
            this.errorState = errorState;
            this.stateChanges.next();
        }
    }

}