import { Dictionary } from 'library/src/dictionary';
import { CULTEVA_MODULES } from './modules';
import { Module } from './_types';

export class CultevaModules {
    
    public modules  = new CultevaModuleDictionary();
 
    //helper functions
    listModulesNames() {
        let list = [];
        this.modules.serialize().forEach(module => {
            list.push(module.name);
        });;
        return list;
    }

    listModules() {
        let mods: Module[] = [];
        this.modules.serialize().forEach(module => {
            mods.push(module);
        });;
        return mods;
    }

    serialize(): CultevaModulesModel{
        return {
            modules: this.modules.serialize(),
        };
    }
}

export interface CultevaModulesModel {
    modules: Module[];
    
}


export class CultevaModuleDictionary extends Dictionary<Module> {
    constructor(){
        super(CULTEVA_MODULES);
    }
}

