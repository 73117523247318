<ng-container *ngIf="state$ | async as state; else loader">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Create Tasting Samples</h2>
        <h3 class="dialog-subtitle">Create tasting samples for selected harvest samples</h3>
    </div>

    <div mat-dialog-content>
        <ng-container *ngIf="tastingsSamples?.length; else noSamples">
            <div class="content-title">The following samples will be created</div>

            <div class="sample-container">
                <div *ngFor="let sample of tastingsSamples" class="sample-item">
                    <pv-image-ref
                        *ngIf="sample.harvestSample.primaryImage"
                        class="sample-item-image"
                        [fileRef]="sample.harvestSample.primaryImage.fileRef"
                        transformId="thumb_300"
                        height="120"
                        width="120"
                        mode="fill"
                        radius="true">
                    </pv-image-ref>
                    <mat-icon [svgIcon]="'common:' + sample.harvestSample.scionCultivar.cropId"
                        *ngIf="!sample.harvestSample.primaryImage"
                        class="sample-item-icon">
                    </mat-icon>
                    <div class="sample-item-info">
                        <span class="sample-item-info-title">{{sample.label}}</span>

                        <span>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            {{sample.harvestSample.scionCultivar.commonName}}
                        </span>
                        <span>
                            <mat-icon>place</mat-icon>
                            {{sample.harvestSample.site.name}}, {{sample.harvestSample.site.block}}
                        </span>

                        <span class="harvest-info">
                            <span>
                                {{ sample.harvestSample?.birthDate ? (sample.harvestSample.birthDate | amDateFormat:'[Week] W') : 'Not specified' }}
                            </span>
                            <time>
                                {{ sample.harvestSample?.birthDate ? (sample.harvestSample.birthDate | amDateFormat:'ll') : 'Not specified' }}
                            </time>
                            <small>
                                Harvested {{ sample.harvestSample?.birthDate ? (sample.harvestSample.birthDate | amTimeAgo) : 'Not specified' }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #noSamples>
        <span class="text-muted">No Tasting Samples</span>
    </ng-template>

    <pv-form-actions mat-dialog-actions
                    [status]="state.status"
                    (cancel)="cancel()"
                    (submit)="attempt()">
    </pv-form-actions>
</ng-container>
<ng-template #loader>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
