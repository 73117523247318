import { Component, ChangeDetectionStrategy, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Snackbar } from '@core/material';
import { ReportTable, ReportTableTemplate, ReportTableTemplateSubjectGroup, Sample, TableReportColumn, TableReportColumnType, TableReportOptions } from '@core/data';
import { LIBRARY } from '@app/evaluation/library';
import { Library, CharacteristicCategoryGroup, MeasurementSubjectGroup, Index, Property, Crop, Protocol, CalculationSubjectGroup } from '@library';
import { Subject, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

export interface ReportTableFormDialogData {
    data: Partial<ReportTable>;
    orgKey: string;
    samples?: Sample[];
    reportTables?: ReportTable[],
    reportTableTemplates?: ReportTableTemplate[];
}

@Component({
    selector: 'pv-report-table-form-dialog',
    templateUrl: 'report-table-form.dialog.html',
    styleUrls: ['report-table-form.dialog.scss'],
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTableFormDialog implements OnInit {


    public charGroupsCtrl: FormControl = new FormControl();
    public measGroupsCtrl: FormControl = new FormControl();
    public calcGroupsCtrl: FormControl = new FormControl();
    public baseGroupsCtrl: FormControl = new FormControl();

    public charGroupsFilterCtrl: FormControl = new FormControl();
    public measGroupsFilterCtrl: FormControl = new FormControl();
    public calcGroupsFilterCtrl: FormControl = new FormControl();
    public baseGroupsFilterCtrl: FormControl = new FormControl();

    public filteredCharGroups: ReplaySubject<CharacteristicCategoryGroup[]> = new ReplaySubject<CharacteristicCategoryGroup[]>(1);
    public filteredMeasGroups: ReplaySubject<MeasurementSubjectGroup[]> = new ReplaySubject<MeasurementSubjectGroup[]>(1);
    public filteredCalcsGroups: ReplaySubject<CalculationSubjectGroup[]> = new ReplaySubject<CalculationSubjectGroup[]>(1);
    public filteredBaseGroups: ReplaySubject<ReportTableTemplateSubjectGroup[]> = new ReplaySubject<ReportTableTemplateSubjectGroup[]>(1);

    protected _onDestroyChar = new Subject<void>();
    protected _onDestroyMeas = new Subject<void>();
    protected _onDestroyCalc = new Subject<void>();
    protected _onDestroyBase = new Subject<void>();

    isIndeterminateChar = false;
    isCheckedChar = false;

    isIndeterminateMeas = false;
    isCheckedMeas = false;

    cropIdControl: FormControl = new FormControl(null, [Validators.required]);
    includePropertiesControl = new FormControl([]);
    includeCharsControl = new FormControl([]);
    includeMeasuresControl = new FormControl([]);
    includeCalcsControl = new FormControl([]);
    includeIndexesControl = new FormControl([]);
    baseTemplateControl = new FormControl(null);

    formGroup = new FormGroup({
        id: new FormControl(null),
        title: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
        description: new FormControl(null, [Validators.maxLength(255)]),
        tableOptions: new FormGroup({
            includeProperties: this.includePropertiesControl,
            includeChars: this.includeCharsControl,
            includeMeasures: this.includeMeasuresControl,
            includeCalcs: this.includeCalcsControl,
            includeIndexes: this.includeIndexesControl,
            isAggregate: new FormControl(false),
            isSpread: new FormControl(false),
            showSummary: new FormControl(false),
            cropId: this.cropIdControl,
            baseTemplate: this.baseTemplateControl,
        }),

    });

    propertyOptions: Property[];
    characteristicGroups: CharacteristicCategoryGroup[];
    measurementGroups: MeasurementSubjectGroup[];
    calculationGroups: CalculationSubjectGroup[];
    indexOptions: Index[];
    baseTemplateOptions: ReportTableTemplateSubjectGroup[];

    tableReportColumnType = TableReportColumnType;
    currentSelection: TableReportColumn[] = [];

    currentCrop: Crop;

    cropOptions: Crop[];
    dialogInit: boolean = true;
    initialLoad: boolean = true;

    samples: Sample[];
    includedEvaluations: string[];

    columns = {
        [TableReportColumnType.Property]: [],
        [TableReportColumnType.Characteristic]: [],
        [TableReportColumnType.Measurement]: [],
        [TableReportColumnType.Index]: [],
        [TableReportColumnType.Calculation]: [],
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportTableFormDialogData,
        private _ref: MatDialogRef<ReportTableFormDialog>,
        private _snackbar: Snackbar,
        @Inject(LIBRARY) private _library: Library,
    ) {}


    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.currentSelection, event.previousIndex, event.currentIndex);

        //Sorts the current selection by Properties
   //     this.currentSelection = this.sortSelectionByProperties(this.currentSelection);
    }

    ngOnInit() {
        this.resetSelection();

        if (this._data.samples) this.samples = this._data.samples;
        else this.samples = null;

        if (this.samples) this.includedEvaluations = this.compileIncludedEvals(this.samples);

        this.propertyOptions = this._library.properties.all();
        this.characteristicGroups = this._library.characteristicsGroupedByCategory();
        this.measurementGroups = this._library.measurementsGroupedBySubject();
        this.calculationGroups = this._library.calculationsGroupedBySubject();
        this.indexOptions = this._library.indexes.all();

        this.cropOptions = this._library.filterExcludedCrops()

        this.filteredBaseGroups.next(this.compileBaseTemplateList());

        this.includePropertiesControl.disable();
        this.includeCharsControl.disable();
        this.includeMeasuresControl.disable();
        this.includeCalcsControl.disable();
        this.includeIndexesControl.disable();

        this.charGroupsCtrl.disable();
        this.charGroupsFilterCtrl.disable();

        this.measGroupsCtrl.disable();
        this.measGroupsFilterCtrl.disable();

        this.calcGroupsCtrl.disable();
        this.calcGroupsFilterCtrl.disable();


        if (this._data.data.tableOptions && this._data.data.tableOptions.columns) {
            this._data.data.tableOptions.columns.forEach(val => {
                this.columns[val.type].push(val.id);
                this.currentSelection.push({
                    id: val.id,
                    type: val.type,
                });
            })

        }

        //Sorts the current selection by Properties
        //this.currentSelection = this.sortSelectionByProperties(this.currentSelection);

        // load the initial char list
        this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));

        // listen for search field value changes
        this.charGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyChar))
            .subscribe(() => {
                this.filterCharGroups();

            });


        // load the initial char list
        this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));

        // listen for search field value changes
        this.measGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyMeas))
            .subscribe(() => {
                this.filterMeasGroups();

            });

        // load the initial calcs list
        this.filteredCalcsGroups.next(this.copyCalcsGroups(this.calculationGroups));

        // listen for search field value changes
        this.calcGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyCalc))
            .subscribe(() => {
                this.filterCalcGroups();
            });

        this.filteredBaseGroups.next(this.compileBaseTemplateList());

        this.baseGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyBase))
            .subscribe(() => {
                this.filterTemplateGroups();
            });

        this.formGroup.reset({
            id: this._data.data.id,
            title: this._data.data.title,
            description: this._data.data.description,
            tableOptions: {
                includeProperties: this.columns[TableReportColumnType.Property],
                includeChars: this.columns[TableReportColumnType.Characteristic],
                includeMeasures: this.columns[TableReportColumnType.Measurement],
                includeCalcs: this.columns[TableReportColumnType.Calculation],
                includeIndexes: this.columns[TableReportColumnType.Index],
                isAggregate: this._data.data.tableOptions && this._data.data.tableOptions.isAggregate,
                isSpread: this._data.data.tableOptions && this._data.data.tableOptions.isSpread,
                showSummary: this._data.data.tableOptions && this._data.data.tableOptions.showSummary,
                cropId: this._data.data.tableOptions && this._data.data.tableOptions.cropId,
                baseTemplate: null,
            },
        });

    }

    private compileIncludedEvals(samples): string[] {
        let evals: string[] = [];

        evals = samples.map(s => {return s.data.evals.map(e => {return e.key});
        }).reduce((accumulator, value) => accumulator.concat(value), []);

        return evals;
    }

    sortSelectionByProperties(Arr: TableReportColumn[]) : TableReportColumn[]  {
        let propArr =  [];
        let restArr =  [];
        let sortedArr: TableReportColumn[] = [];

        if(!Arr) return null;

        Arr.forEach((val, index) => {
            if(val.type == 'p')
                propArr.push(val);


            if(val.type != 'p')
                restArr.push(val);

        });

        propArr.forEach(ele => {
            let foundProp = Arr.find(e => e.id == ele.id);

            if(foundProp) {
                sortedArr.push(foundProp);
            }
        });

        restArr.forEach(ele => {
            let foundRest = Arr.find(e => e.id == ele.id);

            if(foundRest) {
                sortedArr.push(foundRest);
            }
        });

        return sortedArr;
    }

    filterCharGroups() {
        if (!this.characteristicGroups) {
            return;
        }
        // get the search keyword
        let search = this.charGroupsFilterCtrl.value;
        const charGroupsCopy = this.copyCharGroups(this.characteristicGroups);

        if (!search) return this.filteredCharGroups.next(charGroupsCopy);

        search = search.toLowerCase();

        // filter the groups
        this.filteredCharGroups.next(
            charGroupsCopy.filter(charGroup => {
                const showBankGroup = charGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    charGroup.chars = charGroup.chars.filter(char => char.label.toLowerCase().indexOf(search) > -1);
                }

                return charGroup.chars.length > 0;
            })
        );
    }

    copyCharGroups(charGroups: CharacteristicCategoryGroup[]) {
        const charGroupsCopy = [];
        charGroups.forEach(charGroup => {
            charGroupsCopy.push({
                category: charGroup.category.label,
                id: charGroup.id,
                chars: charGroup.chars.slice()
            });
        });
        return charGroupsCopy;
    }

    filterMeasGroups() {
        if (!this.measurementGroups) {
            return;
        }
        // get the search keyword
        let search = this.measGroupsFilterCtrl.value;
        const measGroupsCopy = this.copyMeasGroups(this.measurementGroups);

        if (!search) return this.filteredMeasGroups.next(measGroupsCopy);

        search = search.toLowerCase();

        // filter the groups
        this.filteredMeasGroups.next(
            measGroupsCopy.filter(measGroup => {
                const showBankGroup = measGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    measGroup.measures = measGroup.measures.filter(meas => meas.label.toLowerCase().indexOf(search) > -1);
                }

                return measGroup.measures.length > 0;
            })
        );
    }

    filterCalcGroups() {
        if (!this.calculationGroups) {
            return;
        }
        // get the search keyword
        let search = this.calcGroupsFilterCtrl.value;
        const calcGroupsCopy = this.copyCalcsGroups(this.calculationGroups);

        if (!search) return this.filteredCalcsGroups.next(calcGroupsCopy);

        search = search.toLowerCase();

        // filter the groups
        this.filteredCalcsGroups.next(
            calcGroupsCopy.filter(calcGroup => {
                const showBankGroup = calcGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    calcGroup.calculations = calcGroup.calculations.filter(calc => calc.label.toLowerCase().indexOf(search) > -1);
                }

                return calcGroup.calculations.length > 0;
            })
        );
    }

    filterTemplateGroups() {
        if (!this.baseTemplateOptions) return;

        let search = this.baseGroupsFilterCtrl.value;
        const baseGroupsCopy = this.compileBaseTemplateList();

        if (!search) return this.filteredBaseGroups.next(baseGroupsCopy);

        search = search.toLowerCase();

        this.filteredBaseGroups.next(
            baseGroupsCopy.filter(baseGroup => {
                const showBankGroup = baseGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    baseGroup.templates = baseGroup.templates.filter(template => template.name.toLowerCase().indexOf(search) > -1);
                }

                return baseGroup.templates.length > 0;
            })
        );
    }

    compileBaseTemplateList() {
        let baseGroupsCopy: ReportTableTemplateSubjectGroup[] = [];
        let templates = [];

        this._data.reportTables.forEach(reportTable => {
            let template: ReportTableTemplate = {
                id: null,
                name: reportTable.title,
                ownerOrgKey: this._data.orgKey,
                tableOptions: reportTable.tableOptions,
            };

            templates.push(template);
        });

        baseGroupsCopy.push({
            subject: {
                id: 'report_tables',
                label: 'Report Tables',
            },
            id: 'report_tables',
            templates: templates
        })

        baseGroupsCopy.push({
            subject: {
                id: 'available_templates',
                label: 'Available Templates',
            },
            id: 'available_templates',
            templates: this._data.reportTableTemplates
        })

        this.baseTemplateOptions = baseGroupsCopy;
        return baseGroupsCopy;
    }

    copyMeasGroups(measGroups: MeasurementSubjectGroup[]) {
        const measGroupsCopy = [];
        measGroups.forEach(measGroup => {
            measGroupsCopy.push({
                subject: measGroup.subject.label,
                id: measGroup.id,
                measures: measGroup.measures.slice()
            });
        });
        return measGroupsCopy;
    }

    copyCalcsGroups(calcsGroups: CalculationSubjectGroup[]) {
        const calcsGroupsCopy = [];
        calcsGroups.forEach(calcsGroup => {
            calcsGroupsCopy.push({
                subject: calcsGroup.subject.label,
                id: calcsGroup.id,
                calculations: calcsGroup.calculations.slice()
            });
        });
        return calcsGroupsCopy;
    }


    ngOnDestroy() {
        this._onDestroyChar.next();
        this._onDestroyChar.complete();
        this._onDestroyMeas.next();
        this._onDestroyMeas.complete();
        this._onDestroyCalc.next();
        this._onDestroyCalc.complete();
    }


    attempt(createTemplate: boolean = false) {
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid){
            const form = this.formGroup.value;

            const cast: ReportTable = {
                id: this._data.data.id? this._data.data.id : Math.floor(Math.random() * 100),
                title: form.title,
                description: form.description,
                tableOptions: {
                    columns: this.currentSelection,
                    isAggregate: form.tableOptions.isAggregate,
                    isSpread: form.tableOptions.isSpread,
                    showSummary: form.tableOptions.showSummary,
                    cropId: form.tableOptions.cropId,
                    includedEvals: this._data.data.tableOptions? this._data.data.tableOptions.includedEvals : this.includedEvaluations,
                },
                index: this._data.data.index,
            };


            this._ref.close({data: cast, createTemplate: createTemplate});
        } else {
            this._snackbar.formInvalid();
        }
    }

    cancel() {
        this._ref.close();
    }

    trackById(index, item){
        return item.id;
    }

    updateCropSelection(crop: Crop, event?: MatOptionSelectionChange): void {
        if (event && !event.isUserInput && !this.initialLoad) return;

        if (!crop) this.disableFormControls();
        else this.enableFormControls()

        if (this.currentCrop && crop !== this.currentCrop) this.resetSelection();

        this.characteristicGroups = this.characteristicsGroupedByCategoryWithIds(this.getCropChars(crop));
        this.measurementGroups = this.measurementsGroupedBySubjectWithIds(this.getCropMeas(crop));
        this.calculationGroups = this.calculationsGroupedBySubjectWithIds(this.getCropCalcs(crop));
        this.indexOptions = this._library.indexes.all();

        this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
        this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));
        this.filteredCalcsGroups.next(this.copyCalcsGroups(this.calculationGroups));

        if(this.calculationGroups.length == 0) {
            this.calcGroupsCtrl.disable();
            this.calcGroupsFilterCtrl.disable();
            this.includeCalcsControl.disable();
        }

        this.initialLoad = false;

        this.currentCrop = crop;
    }


    getCropChars(crop: Crop) {
        let cropChars = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.chars) {
                    protocol.chars.forEach(char => {
                        cropChars.push(char);
                    });
                }
            }
        });

        if(cropChars.length > 0) return cropChars;
        else return null;

    }

    getCropMeas(crop: Crop) {
        let cropMeas = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.measures) {
                    protocol.measures.forEach(meas => {
                        cropMeas.push(meas);
                    });
                }
            }
        });

        if(cropMeas.length > 0) return cropMeas;
        else return null;

    }

    getCropCalcs(crop: Crop) {
        let cropCalcs = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.calculations) {
                    protocol.calculations.forEach(calc => {
                        cropCalcs.push(calc);
                    });
                }
            }
        });

        if(cropCalcs.length > 0) return cropCalcs;
        else return null;

    }

    private measurementsGroupedBySubjectWithIds(measureIds: string[]): MeasurementSubjectGroup[]{
        const groups: MeasurementSubjectGroup[] = [];

        this._library.measures.all().forEach(measure => {
            if (measureIds.includes(measure.id)) {
                let group = groups.find(group => group.id === measure.subjectId);

                if (!group) {
                    group = {
                        id: measure.subjectId,
                        measures: [],
                        subject: this._library.subjects.get(measure.subjectId)
                    };
                    groups.push(group);
                }

                group.measures.push(measure);
            }
        });

        return groups;
    }

    private calculationsGroupedBySubjectWithIds(calcIds: string[]): CalculationSubjectGroup[]{
        const groups: CalculationSubjectGroup[] = [];

        if(!calcIds) return groups;

        this._library.calculations.all().forEach(calc => {
            if (calcIds.includes(calc.id)) {
                let group = groups.find(group => group.id === calc.subjectId);

                if (!group) {
                    group = {
                        id: calc.subjectId,
                        calculations: [],
                        subject: this._library.subjects.get(calc.subjectId)
                    };
                    groups.push(group);
                }

                group.calculations.push(calc);
            }
        });

        return groups;
    }

    private characteristicsGroupedByCategoryWithIds(charIds: string[]): CharacteristicCategoryGroup[] {
        const groups: CharacteristicCategoryGroup[] = [];

        this._library.chars.all().forEach(char => {
            if (charIds.includes(char.id)){

                let group = groups.find(group => group.id === char.categoryId);

                if (!group) {
                    group = {
                        id: char.categoryId,
                        chars: [],
                        category: this._library.categories.get(char.categoryId)
                    };
                    groups.push(group);
                }

                group.chars.push(char);
            }
        });

        return groups;
    }

    updateSelection(event: MatOptionSelectionChange, type: TableReportColumnType): void {

        if (!event.isUserInput) return;

        if (event.source.selected) {
            if (this.isDuplicate(this.currentSelection, event.source.value)) {
                this._snackbar.error("Duplicate columns not allowed");
                return;
            }
            this.currentSelection.push({
                id: event.source.value,
                type: type,
            });

        } else {
            let index = this.currentSelection.findIndex(column => column.id === event.source.value && column.type === type)
            if (index > -1) {
                this.currentSelection.splice(index, 1);
            }
        }
        this.currentSelection = [...this.currentSelection];

        //Sorts the current selection by Properties
     //   this.currentSelection = this.sortSelectionByProperties(this.currentSelection);
    }

    updateSelectedTemplate(event: MatOptionSelectionChange, template: ReportTableTemplate) {
        if (!event.isUserInput) return;

        let crop: Crop = this.cropOptions.find(crop => crop.id === template.tableOptions.cropId);

        if (!crop) return this._snackbar.error('Error retrieving crop from base template');

        this.updateCropSelection(crop); // TODO make own crop update call

        this.formGroup.get('tableOptions').reset({
            includeProperties: this.getColumnData(template.tableOptions, TableReportColumnType.Property),
            includeChars: this.getColumnData(template.tableOptions, TableReportColumnType.Characteristic),
            includeMeasures: this.getColumnData(template.tableOptions, TableReportColumnType.Measurement),
            includeCalcs: this.getColumnData(template.tableOptions, TableReportColumnType.Calculation),
            includeIndexes: this.getColumnData(template.tableOptions, TableReportColumnType.Index),
            isAggregate: template.tableOptions.isAggregate || null,
            isSpread: template.tableOptions.isSpread || null,
            showSummary: template.tableOptions.showSummary || null,
            cropId: template.tableOptions.cropId || null,
            baseTemplate: template,
        });

        this.currentSelection = [...template.tableOptions.columns];
    }

    private isDuplicate(selection: TableReportColumn[], newId: string) {
        return selection.map(item => item.id).indexOf(newId) != -1;
    }

    private getColumnData(data: TableReportOptions, type: TableReportColumnType): string[] {
        if (!data.columns) return [];

        return data.columns.filter(col => col.type === type).map(val => val.id);
    }

    private resetSelection() {
        this.includePropertiesControl.reset();
        this.includeCharsControl.reset();
        this.includeMeasuresControl.reset();
        this.includeCalcsControl.reset();
        this.includeIndexesControl.reset();

        this.charGroupsCtrl.reset();
        this.charGroupsFilterCtrl.reset();

        this.measGroupsCtrl.reset();
        this.measGroupsFilterCtrl.reset();

        this.calcGroupsCtrl.reset();
        this.calcGroupsFilterCtrl.reset();

        this.baseGroupsCtrl.reset();
        this.baseGroupsFilterCtrl.reset();

        this.currentSelection = [];
    }

    private enableFormControls() {
        this.includePropertiesControl.enable();
        this.includeCharsControl.enable();
        this.includeMeasuresControl.enable();
        this.includeCalcsControl.enable();
        this.includeIndexesControl.enable();

        this.charGroupsCtrl.enable();
        this.charGroupsFilterCtrl.enable();

        this.measGroupsCtrl.enable();
        this.measGroupsFilterCtrl.enable();

        this.calcGroupsCtrl.enable();
        this.calcGroupsFilterCtrl.enable();

        this.baseGroupsCtrl.enable();
        this.baseGroupsFilterCtrl.enable();
    }

    private disableFormControls() {
        this.includePropertiesControl.disable();
        this.includeCharsControl.disable();
        this.includeMeasuresControl.disable();
        this.includeCalcsControl.disable();
        this.includeIndexesControl.disable();

        this.charGroupsCtrl.disable();
        this.charGroupsFilterCtrl.disable();

        this.measGroupsCtrl.disable();
        this.measGroupsFilterCtrl.disable();

        this.calcGroupsCtrl.disable();
        this.calcGroupsFilterCtrl.disable();

        this.baseGroupsCtrl.disable();
        this.baseGroupsFilterCtrl.disable();
    }

    toggleBooleanControls(control: string) {
        const toggleAggregate = ['isSpread', 'showSummary'];
        const toggleSpread = ['isAggregate', 'showSummary'];
        const toggleSampleSummary = ['isSpread', 'isAggregate'];

        if (control === 'isAggregate') this.toggleControls(toggleAggregate)
        if (control === 'isSpread') this.toggleControls(toggleSpread)
        if (control === 'showSummary') this.toggleControls(toggleSampleSummary)
    }

    private toggleControls(controls: string[]) {
        controls.forEach(control => {
            this.formGroup.get(`tableOptions.${control}`).setValue(false);
        });
    }
}
