import { Pipe, PipeTransform } from "@angular/core";
import { BUSINESSAREAS } from "@core/data/types/tastings-contact";

@Pipe({
    name: 'formatBusinessArea'
})
export class BusinessAreaPipe implements PipeTransform {
    businessAreas: {id: string, label: string}[] = [];

    constructor() {
        this.businessAreas = BUSINESSAREAS;
    }

    transform(areas: string[]): string {
        if (!areas) return 'N/A';
        return areas.map(area => {
            return this.businessAreas.find(businessArea => businessArea.id == area).label
        }).join(', ');
    }
}