import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export class EmailValidators {

    static commaEmails(): ValidatorFn {

        return (control: AbstractControl): ValidationErrors | null => {
            if(control.value === null || control.value.length === 0){
                return null;
            }

            let valid = true;

            String(control.value).split(',').forEach(email => {
                if(!EMAIL_REGEXP.test(email)){
                    valid = false;
                }
            });
    
            return valid ? null : {'comma_email': true};
        };


    }

}