<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header title-section">
        <h2 class="dialog-title">Export History</h2>

        <div class="search-form-container">
            <form [formGroup]="queryFormGroup">
                <mat-form-field appearance="outline">
                    <mat-label>Search</mat-label>
                    <mat-icon matSuffix>search</mat-icon>
                    <input type="text" matInput formControlName="search">
                </mat-form-field>
            </form>
        </div>
    </div>

    <div mat-dialog-content class="dialog-content-container">
        <ng-container [ngSwitch]="state.status">

            <!-- Export List Items -->
            <ng-container *ngSwitchCase="'OK'">
                <ng-container *ngFor="let export of state.data">
                    <div class="export-item-wrapper">
                        <mat-icon>description</mat-icon>

                        <span class="padding">{{ export.fileName }}</span>

                        <span class="padding">{{ export.user.email }}</span>

                        <span class="padding">{{ export.createdAt | amDateFormat:'ll' }}</span>

                        <button
                            matTooltip="Download Export"
                            mat-icon-button
                            (click)="openDownloadLink(export.fileUrl)">
                            <mat-icon>download</mat-icon>
                        </button>
                    </div>
                    <mat-divider class="full-width" *ngIf="state.data.length > 1"></mat-divider>
                </ng-container>
            </ng-container>

            <!-- Loading -->
            <ng-container *ngSwitchCase="'LOADING'">
                <mat-progress-spinner
                    class="spinner"
                    [diameter]="48"
                    color="primary"
                    mode="indeterminate">
                </mat-progress-spinner>
            </ng-container>

            <!-- Empty List -->
            <ng-container *ngSwitchCase="'EMPTY'">
                <div class="export-item-wrapper">
                    <span class="padding">No Exports</span>
                </div>
            </ng-container>

        </ng-container>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</ng-container>