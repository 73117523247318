import { NgModule } from '@angular/core';

import { ChartComponent } from './components/chart.component';
import { AppMaterialModule } from '@core/material';
import { ChartFullscreenDialog } from './components/chart-fullscreen-dialog/chart-fullscreen.dialog';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        AppMaterialModule,
        CommonModule,
    ],
    declarations: [
        ChartComponent,
        ChartFullscreenDialog
    ],
    exports: [
        ChartComponent
    ],
    providers: [
    ],
    entryComponents: [
        ChartFullscreenDialog
    ]
})
export class AppChartsModule { }
