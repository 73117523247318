import { Cultivar, Site } from "@core/data";
import { TastingEvent } from "@core/data/types/tastings-event";
import { TastingsEventIndexQuery } from "./tasting-events-index.state-model";

export class InitTastingsEventIndex {
    static readonly type = "[TastingsEventIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadTastingsEventIndex {
    static readonly type = "[TastingsEventIndex] Load";
    constructor() {}
}

export class PageTastingsEventIndex {
    static readonly type = "[TastingsEventIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingsEventIndex {
    static readonly type = "[TastingsEventIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingsEventIndex {
    static readonly type = "[TastingsEventIndex] Query";
    constructor(public query: TastingsEventIndexQuery) {}
}

export class ClearTastingsEventIndexFilter {
    static readonly type = "[TastingsEventIndex] Clear Filter";
    constructor() {}
}

export class PushTastingsEventIndexModelQuery {
    static readonly type = "[TastingsEventIndex] Push Model Query";
    constructor(public modelType: string, public model: Site | Cultivar) {}
}

export class ExportTastingsEventIndex {
    static readonly type = "[TastingsEventIndex] Export";
    constructor(public type = 'xlsx') {}
}

export class ExportTastingEventResults {
    static readonly type = "[TastingEventIndex] Export Results";
    constructor(public key: string, public event: TastingEvent) {}
}

export class SetTastingsEventIndexSelected {
    static readonly type = "[TastingsEventIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingsEvent {
    static readonly type = "[TastingsEventIndex] Delete Tastings Event";
    constructor(public key: string) {}
}

export class DownloadTastingsEventManifest {
    static readonly type = "[TastingsEventIndex] Download Tastings Event Manifest";
    constructor(public key: string) {}
}

export class MarkTastingsEventComplete {
    static readonly type = "[TastingsEventIndex] Mark Tastings Event Complete";
    constructor(public key: string, public data: Partial<TastingEvent>) {}
}

export class DownloadTastingEventImportTemplate {
    static readonly type = "[TastingsEventIndex] Import Template";
    constructor() {}
}

export class ImportTastingEventIndex {
    static readonly type = "[TastingsEventIndex] Import";
    constructor(public file: File) {}
}
export class DownloadTastingsEvaluationReImportTemplate {
    static readonly type = "[TastingsEvaluationImportForm] Download Evaluations Reimport Template";
    constructor(
        public eventKey: string,
        public eventName: string,
    ) {}
}