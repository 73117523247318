import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sample, SampleType, SiteFormDialogData, Status } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { coerseDateProperty, NumberValidators } from '@core/utils';
import { Select, Store } from "@ngxs/store";
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CultivarFormDialog, CultivarFormDialogData } from '../cultivar-form/cultivar-form.dialog';
import { SiteFormDialog } from '../site-form/site-form.dialog';
import { InitNewSampleForm, InitUpdateSampleForm, SampleFormState, SampleFormStateModel, SubmitSampleForm } from './sample-form.state';

export interface SampleFormDialogData {
    key?: string;
    defaults?: any;
    clone?: boolean;
}

@Component({
    selector: 'pv-sample-form-dialog',
    templateUrl: 'sample-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class SampleFormDialog implements OnInit, OnDestroy {

    @Select(SampleFormState)
    state$: Observable<SampleFormStateModel>;

    @Select(SampleFormState.data)
    data$: Observable<Partial<Sample>>;

    typeControl = new FormControl(null, [Validators.required]);
    labelControl = new FormControl(null, [Validators.maxLength(64)]);
    descriptionControl = new FormControl(null, [Validators.maxLength(255)]);
    sizeControl = new FormControl(null, [NumberValidators.integer(4), Validators.min(1)]);

    siteControl = new FormControl(null);
    scionCultivarControl = new FormControl(null);
    rootstockCultivarControl = new FormControl(null);
    plantSampleControl = new FormControl(null);

    birthDateControl = new FormControl(null, [Validators.required]);
    deathDateControl = new FormControl(null, []);

    rowIndexControl = new FormControl(null, [NumberValidators.integer(3), Validators.min(0)]);
    positionIndexControl = new FormControl(null, [NumberValidators.integer(3), Validators.min(0)]);
    rowDistanceControl = new FormControl(null, [NumberValidators.integer(5), Validators.min(1)]);
    colDistanceControl = new FormControl(null, [NumberValidators.integer(5), Validators.min(1)]);

    ownerOrgKeyControl = new FormControl(null);

    connectPlantSampleControl = new FormControl(false);

    formGroup: FormGroup = new FormGroup({
        type: this.typeControl,
        label: this.labelControl,
        description: this.descriptionControl,
        size: this.sizeControl,

        site: this.siteControl,
        scionCultivar: this.scionCultivarControl,
        rootstockCultivar: this.rootstockCultivarControl,
        plantSample: this.plantSampleControl,

        birthDate: this.birthDateControl,
        deathDate: this.deathDateControl,

        rowIndex: this.rowIndexControl,
        positionIndex: this.positionIndexControl,
        rowDistance: this.rowDistanceControl,
        colDistance: this.colDistanceControl,

        ownerOrgKey: this.ownerOrgKeyControl,

        _connectPlantSample: this.connectPlantSampleControl,
    });

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<SampleFormDialog>,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        @Inject(MAT_DIALOG_DATA) public data: SampleFormDialogData
    ) { }


    ngOnInit() {

        if (!this.data.key) {

            this.formGroup.get('site').valueChanges.subscribe(site => {
                if (site && this.formGroup.get('type').value == 'plant'){
                    this.formGroup.get('rowDistance').setValue(site.rowDistance);
                    this.formGroup.get('colDistance').setValue(site.colDistance);
                }
            });

            if (this.data.defaults.massAction) {
                let initForms: InitNewSampleForm[] = [];

                for (let i = 0; i < this.data.defaults.plantSamples.length; i++) {
                    let dataList = (JSON.parse(JSON.stringify(this.data)));
                    dataList.defaults.plantSample = this.data.defaults.plantSamples[i];
                    initForms.push(new InitNewSampleForm(dataList.defaults));
                }

                this._store.dispatch(initForms);

            } else this._store.dispatch(new InitNewSampleForm(this.data.defaults));

        } else this._store.dispatch(new InitUpdateSampleForm(this.data.key, this.data.clone));

        this.connectPlantSampleControl
            .valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(connect => {
                this.updateValidation();
            });

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if (state.status === Status.COMPLETE) {
                    this._dialogRef.close(state.data);
                } else if (state.status !== Status.LOADING) {
                    this.formGroup.enable();
                } else {
                    this.formGroup.disable();
                }
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });

    }

    reset(model: Partial<Sample>) {

        let data = {
            type: model.type,
            label: model.label || null,
            size: "size" in model ? model.size : null,
            description: model.description || null,

            birthDate: model.birthDate || moment().format('YYYY-MM-DD'),
            deathDate: model.deathDate || null,

            site: model.site || null,
            scionCultivar: model.scionCultivar || null,
            rootstockCultivar: model.rootstockCultivar || null,
            plantSample: model.plantSample || null,

            rowIndex: "rowIndex" in model ? model.rowIndex : null,
            positionIndex: "positionIndex" in model ? model.positionIndex : null,
            rowDistance: "rowDistance" in model ? model.rowDistance : null,
            colDistance: "colDistance" in model ? model.colDistance : null,

            ownerOrgKey: model.ownerOrgKey,
            _connectPlantSample: !!model.plantSample
        };

        this.formGroup.reset(data);
        this.updateValidation();
    }

    private updateValidation(){

        if(this.connectPlantSampleControl.value){
            this.scionCultivarControl.setValidators([]);
            this.siteControl.setValidators([]);
            this.plantSampleControl.setValidators([Validators.required]);
        }else{
            this.scionCultivarControl.setValidators([Validators.required]);
            this.siteControl.setValidators([Validators.required]);
            this.plantSampleControl.setValidators([]);
        }

        this.scionCultivarControl.updateValueAndValidity();
        this.siteControl.updateValueAndValidity();
        this.plantSampleControl.updateValueAndValidity();
    }

    attempt() {

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            console.warn('SampleFormDialog: form invalid', this.formGroup.value);
            this._snackbar.error("Invalid input. Check your input and try again.");
        }

    }

    save() {
        const form = this.formGroup.value;

        const data: Partial<Sample> = {
            type            : form.type,
            birthDate       : coerseDateProperty(form.birthDate),
            deathDate       : coerseDateProperty(form.deathDate),
            label           : form.label,
            description     : form.description,
            size            : coerceNumberProperty(form.size, null),
            rowDistance     : coerceNumberProperty(form.rowDistance, null),
            colDistance     : coerceNumberProperty(form.colDistance, null),
            rowIndex        : coerceNumberProperty(form.rowIndex, null),
            positionIndex   : coerceNumberProperty(form.positionIndex, null),
            ownerOrgKey     : form.ownerOrgKey,
        }

        if(form._connectPlantSample){
            data.plantSampleKey = form.plantSample.key;
            data.siteKey = null;
            data.scionCultivarKey = null;
            data.rootstockCultivarKey = null;
        } else {
            data.plantSampleKey = null;
            data.siteKey = form.site ? form.site.key : null;
            data.scionCultivarKey = form.scionCultivar ? form.scionCultivar.key : null;
            data.rootstockCultivarKey = form.rootstockCultivar ? form.rootstockCultivar.key : null;
        }

        if (this.data.key || !this.data.defaults.massAction) {
            this._store.dispatch(new SubmitSampleForm(data));
        } else {
            let harvestList:SubmitSampleForm[] = []

            for (let i = 0; i < this.data.defaults.plantSamples.length; i++) {
                const dataMassAction: Partial<Sample> = {
                    type            : form.type,
                    birthDate       : coerseDateProperty(form.birthDate),
                    deathDate       : coerseDateProperty(form.deathDate),
                    label           : form.label,
                    description     : form.description,
                    size            : coerceNumberProperty(form.size, null),
                    rowDistance     : coerceNumberProperty(form.rowDistance, null),
                    colDistance     : coerceNumberProperty(form.colDistance, null),
                    rowIndex        : coerceNumberProperty(form.rowIndex, null),
                    positionIndex   : coerceNumberProperty(form.positionIndex, null),
                    ownerOrgKey     : form.ownerOrgKey,
                    plantSampleKey  : this.data.defaults.plantSamples[i].key,
                }

                harvestList[i] = new SubmitSampleForm(dataMassAction);
            }

            this._store.dispatch(harvestList);
        }
    }

    cancel() {
        this._dialogRef.close();
    }

    addScionCultivar(event: MouseEvent) {

        event.stopPropagation();

        const data: CultivarFormDialogData = {
            defaults: {
                ownerOrgKey: this.ownerOrgKeyControl.value,
            }
        };
        this._dialog.open(CultivarFormDialog, { data })
            .afterClosed()
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if (result) {
                    this.scionCultivarControl.setValue(result);
                }
            });
    }

    removeScionCultivar(event: MouseEvent){
        event.stopPropagation();
        this.scionCultivarControl.setValue(null);
    }

    addRootstockCultivar(event: MouseEvent) {

        event.stopPropagation();

        const data: CultivarFormDialogData = {
            defaults: {
                ownerOrgKey: this.ownerOrgKeyControl.value,
                isRootstock: true,
            }
        };
        this._dialog.open(CultivarFormDialog, { data })
            .afterClosed()
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if (result) {
                    this.rootstockCultivarControl.setValue(result);
                }
            });
    }

    removeRootstockCultivar(event: MouseEvent){
        event.stopPropagation();
        this.rootstockCultivarControl.setValue(null);
    }

    addSite(event: MouseEvent) {

        event.stopPropagation();

        const data: SiteFormDialogData = {
            defaults: {
                ownerOrgKey: this.ownerOrgKeyControl.value,
            }
        };
        this._dialog.open(SiteFormDialog, { data })
            .afterClosed()
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if (result) {
                    this.siteControl.setValue(result);
                }
            });
    }

    removeSite(event: MouseEvent){
        event.stopPropagation();
        this.siteControl.setValue(null);
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
