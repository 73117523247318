<ng-container *ngIf="state$ | async as state">

    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Import Station Readings</h2>
        <h3 class="dialog-subtitle">{{ state.key || '' }}</h3>
    </div>

    <div mat-dialog-content>

        <form #stationReadingsImportForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form single-column"
            (ngSubmit)="attempt()">

            <mat-form-field>
                <mat-select formControlName="file_format" placeholder="Select File Format" required>
                    <mat-option value="tinytag_tgp_4510_csv">Tinytag TTF [air/soil] (.csv)</mat-option>
                    <mat-option value="tinytag_tgp_4500_csv">Tinytag TTF [humidity] (.csv)</mat-option>
                    <mat-option value="tinytag_tgp_45001_csv">Tinytag TTF [max/min] (.csv)</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-select formControlName="import" placeholder="Select Import Type">
                    <mat-option value="default">Keep Existing Data</mat-option>
                    <mat-option value="replace_all">Replace All Data</mat-option>
                    <mat-option value="replace_period">Replace Data in Period</mat-option>
                </mat-select>
            </mat-form-field>

            <pv-legacy-file-upload
                (file)="setFormFile($event)"
                [maxSize]="maxSize"
                [allows]="allowedExts"
                label="Tinytag File">
            </pv-legacy-file-upload>

        </form>

    </div>

    <!-- Actions -->
    <pv-form-actions
        mat-dialog-actions
        [form]="stationReadingsImportForm"
        (cancel)="cancel()"
        [status]="state.status">
    </pv-form-actions>

</ng-container>
