import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { API_BASE_URI } from "../http/api";
import { Collection } from "../http/collection";
import { Filter, FilterBuilder } from '../http/filter';
import { DetailRequestBuilder, DetailRequest } from "../http/detail";
import { TastingEvent, TastingsEventImage, TastingsEventInvitation } from "../types/tastings-event";
import { SearchRequest, SearchResponse } from "..";

export type TastingsEventUpdateRequest = Partial<TastingEvent> | TastingsEventImagesUpdateRequest;
export class TastingEventCreated {
    static readonly type = "[TastingEventService] Tasting Event Created";
    constructor(public tastingEvent: TastingEvent){}
}

export class TastingEventUpdated {
    static readonly type = "[TastingEventService] Tasting Event Updated";
    constructor(public tastingEvent: TastingEvent){}
}

export class TastingEventDeleted {
    static readonly type = "[TastingEventService] Tasting Event Deleted";
    constructor(public tastingEvent: TastingEvent){}
}

export class TastingsEventImageUploaded {
    static readonly type = "[EvaluationService] Evaluation Image Uploaded";
    constructor(public image: TastingsEventImage){}
}

export class TastingEventInviteSent {
    static readonly type = "[EvaluationService] Event Invite Sent";
    constructor(public invitation: TastingsEventInvitation) {}
}

export class TastingEventIndexImported {
    static readonly type = "[TastingEventService] Event Index Imported";
    constructor(public response: TastingEventImportResponse) {}
}

export interface TastingsEventImagesUpdateRequest {
    images: Array<Partial<TastingsEventImage>>;
    ownerOrgKey: string;
}

export interface TastingEventImportResponse {
    response: string;
}

@Injectable()
export class TastingEventService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) { }

    get(tastingEventKey: string, detail?: DetailRequest): Observable<TastingEvent> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<TastingEvent>(`${this._baseUri}/tasting-events/${tastingEventKey}`, {params});
    }

    manifest(tastingEventKey: string): Observable<TastingEvent> {
        return this._http.get<TastingEvent>(`${this._baseUri}/tasting-events/${tastingEventKey}/manifest`);
    }

    create(model: Partial<TastingEvent>) {
        return this._http.post<TastingEvent>(`${this._baseUri}/tasting-events`, model)
            .pipe(tap(tastingEvent => this._store.dispatch(new TastingEventCreated(tastingEvent))))
    }

    update(key: string, model: TastingsEventUpdateRequest) {
        return this._http.put<TastingEvent>(`${this._baseUri}/tasting-events/${key}`, model)
                .pipe(tap(tastingEvent => this._store.dispatch(new TastingEventUpdated(tastingEvent))));
    }

    updateImage(key: string, model: TastingsEventUpdateRequest) {
        return this._http.put<TastingEvent>(`${this._baseUri}/tasting-events/${key}/image/update`, model)
                .pipe(tap(tastingEvent => this._store.dispatch(new TastingEventUpdated(tastingEvent))));
    }

    delete(key: string) {
        return this._http.delete<TastingEvent>(`${this._baseUri}/tasting-events/${key}`)
                .pipe(tap(tastingEvent => this._store.dispatch(new TastingEventDeleted(tastingEvent))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<TastingEvent>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<TastingEvent>>(`${this._baseUri}/tasting-events`, { params });
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<TastingEvent>>(`${this._baseUri}/tasting-events/search`, {params: <any>search});
    }

    // Export of events only
    export(filter: Filter, ownerOrgKey: string, type: string = 'xlsx', detail?: DetailRequest) {
        const data = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.post<TastingEventExportResponse>(`${this._baseUri}/tasting-events/export`, { ...data, ownerOrgKey, type });
    }

    // Event results export
    exportResults(key: string) {
        return this._http.get<Blob>(`${this._baseUri}/tasting-events/event-results/${key}/export`, {
            responseType: 'blob' as 'json',
        });
    }

    complete(key: string, model: Partial<TastingEvent>) {
        return this._http.post<TastingEvent>(`${this._baseUri}/tastings-events/event-results/${key}`, model)
                .pipe(tap(tastingEvent => this._store.dispatch(new TastingEventUpdated(tastingEvent))));
    }

    uploadImage(key:string, file: File): Observable<HttpEvent<TastingsEventImage>>{

        let data = new FormData();
        data.set('file', file);

        const req = new HttpRequest('POST', `${this._baseUri}/tasting-events/${key}/images`, data, {
            reportProgress: true
        });

        return this._http.request<TastingsEventImage>(req)
                    .pipe(tap(result => {

                        if(result instanceof HttpResponse){
                            this._store.dispatch(new TastingsEventImageUploaded(result.body));
                        }

                    }));
    }

    invite(invitation: Partial<TastingsEventInvitation>) {
        return this._http.post<TastingsEventInvitation>(`${this._baseUri}/tasting-contact/invite-list`, invitation)
            .pipe(tap(invitation => this._store.dispatch(new TastingEventInviteSent(invitation))));
    }

    downloadImportTemplate() {
        return this._http.get<Blob>(`${this._baseUri}/tasting-events/import/template`, {
            responseType: 'blob' as 'json',
        });
    }

    import(orgKey: string, file: File) {
        let data = new FormData();
        data.set('file', file);
        data.set('ownerOrgKey', orgKey);

        return this._http.post<TastingEventImportResponse>(`${this._baseUri}/tasting-events/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new TastingEventIndexImported(result));
            }));
    }
}

export interface TastingEventExportResponse {}