import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Site, SiteService, SearchResponse, SearchRequest, Cultivar, Status, translateCommonErrorStatus, FilterBuilder } from '@core/data';
import { Observable, throwError } from 'rxjs';
import { AUTOCOMPETE_CHIPS_STYLES, ModelAutocompleteChipsComponent } from './model-autocomplete-chips-component';
import { catchError, map } from 'rxjs/operators';



@Component({
    selector: 'pv-site-autocomplete-chips',
    templateUrl: './site-autocomplete-chips.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SiteAutocompleteChipsComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: SiteAutocompleteChipsComponent
        }
    ],
    styles: [AUTOCOMPETE_CHIPS_STYLES]
})
export class SiteAutocompleteChipsComponent extends ModelAutocompleteChipsComponent<Site>
{
    constructor(
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeDetect: ChangeDetectorRef,
        private _siteService: SiteService
    ){
        super(fm, elRef, _changeDetect);
    }


    search(search: SearchRequest): Observable<SearchResponse<Site>> {
        return this._siteService.search(search);
    }

    query(orgKey: string, text: string){
        return this._siteService.query(this.getFilter(orgKey, text))
    }

    private getFilter(orgKey: string, searchString: string){

        const fb = (new FilterBuilder());

        if(orgKey){
            fb.setQuery('ownerOrgKey', orgKey)
        }

        if(typeof searchString === 'string' && searchString.length > 0){
            if (searchString.trim()) fb.setQuery('$fuzzy', searchString);
        }

        return fb.get();

    }

}