import { TastingEvaluation } from "./_types";
//WIP (default)
export const LIB_TASTINGS_EVAL_BASE: TastingEvaluation = {
    id: "tastings_base_scorecard",
    title: "Consumer Fruit Impression",
    crop: "Apple & Pear",

    weightedChars: [
        "tastings_Attractiveness",
        "tastings_Texture",
        "tastings_Taste & Aroma",
        "tastings_Overall Eating Quality",
        "tastings_Overall Interest",
    ],

    attributes: [
        "tough skin",
        "high acid",
        "high sugar",
        "juicy",
        "dry",
        "attractive",
        "aromatic",
        "bright colour",
        "low flavour",
        "abrasions present",
        "immature",
        "mixed maturity",
    ],

    range: 10,

}