import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'pv-snack-link',
    template: `
        <span class="snackbar-message mat-body">
        {{ data.message }}
        </span>
        <button class="snack-button" type="button" mat-button color="accent" (click)="ok()">Dismiss</button>
        <a mat-flat-button class="snack-button" color="primary" 
            [href]="data.url"
            [attr.target]="data.blank ? '_blank' : '_self'"
            [download]="data.download"
        >{{ data.link }}</a>
    `,
    styleUrls: ['snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class SnackLinkComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackLinkData,
        private _ref: MatSnackBarRef<SnackLinkComponent>
    ) { }

    ok() {
        this._ref.dismiss();
    }
}

export interface SnackLinkData {
    message: string;
    link: string;
    url: string | SafeUrl;
    blank: boolean;
    download?: string;
}