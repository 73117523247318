import { Injectable } from "@angular/core";
import { Status, Tag, TagService, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface TastingCustomTagsFormStateModel {
    key: string;
    status: Status;
    data: Partial<Tag>;
}

const DEFAULTS: TastingCustomTagsFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
}

export class InitNewTastingCustomTagsForm {
    static readonly type = "[TastingCustomTagsForm] Init New";
    constructor(public defaults?: Partial<Tag>) {}
}

export class InitUpdateTastingCustomTagsForm {
    static readonly type = "[TastingCustomTagsForm] Init Update";
    constructor(public key: string) {}
}

export class SubmitTastingCustomTagsForm {
    static readonly type = "[TastingCustomTagsForm] Submit";
    constructor(public data: Partial<Tag>) {}
}

@State<TastingCustomTagsFormStateModel>({
    name: 'tasting_custom_tags_form',
    defaults: DEFAULTS
})
@Injectable()
export class TastingCustomTagsFormState {
    @Selector()
    static data(state: TastingCustomTagsFormStateModel) {
        return state.data;
    }

    constructor(private _tagsService: TagService) {}

    @Action(InitNewTastingCustomTagsForm, {cancelUncompleted: true})
    initNewTastingCustomTagsForm(ctx: StateContext<TastingCustomTagsFormStateModel>, action: InitNewTastingCustomTagsForm) {
        ctx.setState({
            key: null,
            data: {
                ...action.defaults
            },
            status: Status.OK,
        });
    }

    @Action(InitUpdateTastingCustomTagsForm, {cancelUncompleted: true})
    initUpdateTastingCustomTagsForm(ctx: StateContext<TastingCustomTagsFormStateModel>, action: InitUpdateTastingCustomTagsForm) {
        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING,
        });

        return this._tagsService.get(action.key)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            status: Status.OK,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        })
                    }
                )
            );
    }

    @Action(SubmitTastingCustomTagsForm)
    submitTastingCustomTagsForm(ctx: StateContext<TastingCustomTagsFormStateModel>, action: SubmitTastingCustomTagsForm) {
        const state = ctx.getState();

        let request: Observable<Tag>;

        if (state.key) request = this._tagsService.update(state.key, { ...action.data });
        else request = this._tagsService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }
}