<ng-container *ngIf="state$ | async as state" [ngSwitch]="state.status">

    <div class="sample-label-overlay" *ngSwitchCase="'LOADING'">
        <pv-view-loader message="Loading Barcodes..."></pv-view-loader>
    </div>

    <div class="sample-label-overlay" *ngSwitchCase="'EMPTY'">
        <pv-view-message heading="Nothing Here" message="No barcodes to display." icon="error"></pv-view-message>
    </div>

    <div class="sample-label-list" *ngSwitchCase="'OK'">
        <div class="sample-label-wrapper" *ngFor="let label of state.labels">
            <div class="sample-label" [ngClass]="['s-' + state.options.size, 'l-' + state.options.layout]" [style.margin]="state.options.margin">
                <img class="sample-label-barcode" [src]="label.src" [alt]="label.code">
                <div class="sample-label-code">{{ label.code }}</div>
                <div class="sample-label-heading" *ngFor="let heading of label.headings">{{ heading }}</div>
                <div class="sample-label-text" *ngFor="let line of label.lines">{{ line }}</div>
                <div class="sample-label-whitespace" *ngIf="state.options.whitespace"></div>
            </div>
        </div>
    </div>

    <div class="sample-label-overlay" *ngSwitchDefault>
        <pv-view-message heading="Barcode Load Error" message="One ore more barcodes could not be loaded." icon="error">
            <button mat-raised-button color="primary" pvMessageAction (click)="retry()">Retry</button>
        </pv-view-message>
    </div>
</ng-container>
