import { coerceNumberProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapMarkerManager } from './directives/map-marker-manager';
import { MapPolygonManager } from './directives/map-polygon-manager';
import { MapsApiLoader } from './maps-api-loader';
import { LatLng } from './types';

// https://developers.google.com/maps/documentation/javascript/examples/control-positioning

declare var google: any;

@Component({
    selector: 'pv-map',
    template: `
        <div class="map-holder" #mapHolder></div>
    `,
    providers: [
        MapMarkerManager,
        MapPolygonManager
    ],
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('mapHolder', { static: true })
    private mapHolder: ElementRef;

    private _map: any;

    @Input()
    set center(value: LatLng) {
        this._center$.next(value);
    }

    @Input()
    set zoom(value: number) {
        this._zoom$.next(coerceNumberProperty(value));
    }
    get zoom(){
        return this._zoom$.getValue();
    }

    @Input('bounds')
    public set bounds(value: LatLng[]) {
        this.setBounds(value);
    }

    @Input()
    set zoomControl(value: boolean) {
        this.patchOptions({zoomControl: value});
    }

    @Input()
    set zoomControlOptions(value: boolean) {
        this.patchOptions({zoomControlOptions: value});
    }

    @Input()
    set mapTypeControl(value: boolean) {
        this.patchOptions({mapTypeControl: value});
    }

    @Input()
    set mapTypeControlOptions(value: boolean) {
        this.patchOptions({mapTypeControlOptions: value});
    }

    @Input()
    set scaleControl(value: boolean) {
        this.patchOptions({scaleControl: value});
    }

    @Input()
    set streetViewControl(value: boolean) {
        this.patchOptions({streetViewControl: value});
    }

    @Input()
    set streetViewControlOptions(value: boolean) {
        this.patchOptions({streetViewControlOptions: value});
    }

    @Input()
    set rotateControl(value: boolean) {
        this.patchOptions({
            rotateControl: value,
        });
    }

    @Input()
    set fullscreenControl(value: boolean) {
        this.patchOptions({
            fullscreenControl: value,
        });
    }

    @Input()
    set gestureHandling(value: string) {
        this.patchOptions({
            gestureHandling: value
        });
    }

    @Input()
    set mapType(value: string) {
        this.patchOptions({mapTypeId: value })
    }

    @Input()
    set disableDefaultUI(value: boolean) {
        this.patchOptions({disableDefaultUI: value })
    }

    @Output('move')
    moveEmitter = new EventEmitter<MouseEvent>();

    private _defaultOptions = {
        // zoomControl: true,
        zoomControlOptions: {
            position: 9
        },
        // mapTypeControl: true,
        mapTypeControlOptions: {
            position: 7
        },
        // scaleControl: true,
        scaleControlOptions: {
            position: 9
        },
        // streetViewControl: false,
        // rotateControl: true,
        rotateControlOptions: {
            position: 9
        },
        // fullscreenControl: true,
        fullscreenControlOptions: {
            position: 9
        },
        gestureHandling: 'greedy',
        mapTypeId: 'terrain', // roadmap satellite hybrid terrain
        draggableCursor: true,
        streetViewControl: false,
    };


    private _options$ = new BehaviorSubject<any>(this._defaultOptions);

    private _zoom$ = new BehaviorSubject<number>(3);
    private _center$ = new BehaviorSubject<LatLng>({ lat: -30.5595, lng: 22.9375, });
    private _bounds$ = new BehaviorSubject<LatLng[]>([]);

    private _mapListeners = [];

    constructor(
        private _mapsApiLoader: MapsApiLoader,
        private _markers: MapMarkerManager,
        private _polygons: MapPolygonManager,
        private _ngZone: NgZone,
    ) { }

    ngOnInit() {
        this._mapsApiLoader.load().then(
            () => {
                this.initMap();
            }
        ).catch(
            (err) => {
                console.log("ERROR Loading Map", err);
                this.mapHolder.nativeElement.innerHTML = "Error loading map.";
            }
        );
    }

    ngAfterViewInit() { }

    ngOnDestroy() {
        this._map.unbindAll();

        this._markers.clear();
        this._polygons.clear();
        this._zoom$.complete();
        this._center$.complete();
        this._options$.complete();
        this._bounds$.complete();
    }

    selectLocation(pos, zoom) {
        this._map.setCenter(pos.latLng);
        this._map.setZoom(zoom);
    }

    initMap() {
        this._map = new google.maps.Map(this.mapHolder.nativeElement);
        this._markers.useMap(this._map);
        this._polygons.useMap(this._map);


        this._zoom$.subscribe(level => {
            if(this._map.getZoom() !== level){
                this._map.setZoom(level);
            }
        });

        this._center$.subscribe(latLng => {
            this._map.setCenter(latLng);
        });

        this._bounds$.subscribe(latLngs => {
            let bounds = new google.maps.LatLngBounds();
            latLngs.forEach(latLng => {
                bounds.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
            });
            this._map.fitBounds(bounds);
        });

        this._options$.subscribe(options => {
            this._map.setOptions(options);
        });

        this._mapListeners.push(this._map.addListener('mousemove', (e) => {
            this._ngZone.run(() => {
                this.moveEmitter.emit(e);
            });
        }));

        this._mapListeners.push(this._map.addListener('zoom_changed', () => {
            this._zoom$.next(this._map.getZoom());
        }));

        // this._mapListeners.push(this._map.addListener('click', (e) => {
        //     this._ngZone.run(() => {

        //     });
        // }));

    }

    public setBounds(latLngs: LatLng[]){
        this._bounds$.next(latLngs);
    }

    public getCenter(): LatLng{

        if(!this._map) return null;

        let pos = this._map.getCenter();

        return {
            lat: pos.lat(),
            lng: pos.lng()
        };

    }

    private patchOptions(options: any) {
		this._options$.next({
			...this._options$.value,
			...options
		});
	}


}