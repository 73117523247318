import { Injectable } from "@angular/core";
import { DetailRequest, FilterBuilder, Status, TastingContactService, TastingEventService, translateCommonErrorStatus } from "@core/data";
import { TastingContact, TastingContactInvitation } from "@core/data/types/tastings-contact";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

//State model
export interface TastingContactInviteFormStateModel {
    orgKey: string;
    status: Status;
    data: Partial<TastingEvent[]>;
    contacts: Partial<TastingContact[]>;
}

//State defaults
const DEFAULTS: TastingContactInviteFormStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: null,
    contacts: null,
}

//State actions
export class InitTastingContactInviteForm {
    static readonly type = "[TastingContactInviteForm] Init";
    constructor(public orgKey: string, public contacts: Partial<TastingContact[]>, public defaults?: Partial<TastingEvent[]>) {}
}

export class SubmitTastingContactInviteForm {
    static readonly type = "[TastingContactInviteForm] Submit";
    constructor(public data: Partial<TastingContactInvitation>) {}
}

//State logic
@State<TastingContactInviteFormStateModel>({
    name: 'tasting_contact_invite_form_state',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingContactInviteFormState {
    @Selector()
    static data(state: TastingContactInviteFormStateModel) { return state.data; }

    constructor(
        private _tastingContactService: TastingContactService,
        private _tastingEventService: TastingEventService
    ) {}

    @Action(InitTastingContactInviteForm, {cancelUncompleted: true})
    initTastingContactInviteForm(ctx: StateContext<TastingContactInviteFormStateModel>, action: InitTastingContactInviteForm) {
        ctx.setState({
            orgKey: action.orgKey,
            data: {
                ...action.defaults
            },
            contacts: action.contacts,
            status: Status.OK
        });

        return this.loadTastingEventOptions(ctx);
    }

    @Action(SubmitTastingContactInviteForm)
    submitTastingContactInviteForm(ctx: StateContext<TastingContactInviteFormStateModel>, action: SubmitTastingContactInviteForm) {
        const state = ctx.getState();

        let request: Observable<TastingContactInvitation>;

        request = this._tastingContactService.invite(action.data);

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE,
                            contacts: result.contacts,
                            data: {
                                ...result.events
                            },
                        })
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }

    private loadTastingEventOptions(ctx: StateContext<TastingContactInviteFormStateModel>) {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.orgKey)
            .setQuery('completed', false)
            .get();

        const detail: DetailRequest = {
            related: [
                'tastingsEventImage',
            ]
        }

        return this._tastingEventService.query(filter, detail)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        data: result.data
                    })
                }),
                catchError(e => {
                    console.warn("Error loading events", e);
                    ctx.patchState({
                        data: [],
                    });
                    return of(null)
                })
            );
    }
}