import { TastingCustomTagsQuery } from "./tasting-custom-tags-index.state-model";

export class InitTastingCustomTagsIndex {
    static readonly type = "[TastingCustomTagsIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadTastingCustomTagsIndex {
    static readonly type = "[TastingCustomTagsIndex] Load";
    constructor() {}
}

export class PageTastingCustomTagsIndex {
    static readonly type = "[TastingCustomTagsIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingCustomTagsIndex {
    static readonly type = "[TastingCustomTagsIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingCustomTagsIndex {
    static readonly type = "[TastingCustomTagsIndex] Query";
    constructor(public query: TastingCustomTagsQuery) {}
}

export class ClearTastingCustomTagsIndexFilter {
    static readonly type = "[TastingCustomTagsIndex] Clear Filter";
    constructor() {}
}

export class SetTastingCustomTagsIndexSelected {
    static readonly type = "[TastingCustomTagsIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingCustomTag {
    static readonly type = "[TastingCustomTagsIndex] Delete Custom Tag";
    constructor(public key: string) {}
}
