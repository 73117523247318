import { Directive, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { MapsApiLoader } from '../maps-api-loader';
import { LatLng, PolyStyle } from '../types';
import { MapPolygonManager } from './map-polygon-manager';
import { MapPolygonRef } from './map-polygon-ref';


declare var google: any;

export const MAP_POLYGON_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => MapPolygonDirective),
	multi: true
}

@Directive({
	selector: 'pv-map-polygon',
	providers: [
		MAP_POLYGON_VALUE_ACCESSOR,
		MapPolygonRef
	]
})
export class MapPolygonDirective implements OnInit, OnDestroy, ControlValueAccessor {

	private _polygon: any;
	private _path: any;
	private _pathRemoveListener: any;
	private _pathInsertListener: any;
	private _pathSetListener: any;
	private _onChange = (value: any) => { };
	private _ontouch = () => { };

	private _lastValue: any = null;

	private _defaultOptions: any = {
		draggable: false,
		editable: false
	};

	private _path$ = new BehaviorSubject([]);
	private _options$ = new BehaviorSubject(this._defaultOptions);

    private _changes$ = new Subject();

	private _isWriting = false;

	private _timer = null;

	@Input()
    set style(value: PolyStyle) {
		if(value) {
			this.patchOptions(value);
		}
    }

	@Input()
	set fillColor(value: any) {
		this.patchOptions({ fillColor: value })
	}

	@Input()
	set fillOpacity(value: number) {
		this.patchOptions({ fillOpacity: value });
	}

	@Input()
	set strokeColor(value: any) {
		this.patchOptions({ strokeColor: value });
	}

	@Input()
	set strokeWeight(value: any) {
		this.patchOptions({ strokeWeight: value });
	}

	@Input()
	set strokeOpacity(value: any) {
		this.patchOptions({ strokeOpacity: value })
	}

	@Input()
	set path(value: LatLng[]) {
		if(this._lastValue != value){
			this.writeValue(value);
		}
	}

	@Input()
	set draggable(value: boolean) {
		this.patchOptions({ draggable: value });
	}

	@Input()
	set editable(value: boolean) {
		this.patchOptions({ editable: value });
	}

	@Input()
	set geodesic(value: boolean) {
		this.patchOptions({ geodesic: value });
	}

	@Input()
	set visible(value: boolean) {
		this.patchOptions({ visible: value });
	}

	@Output('change')
	changeEmitter = new EventEmitter<LatLng[]>();

	@Output('areaChange')
	areaEmitter = new EventEmitter<number>();

	constructor(
		private _mapsApiLoader: MapsApiLoader,
		private _polygons: MapPolygonManager,
		private _zone: NgZone,
		private _ref: MapPolygonRef
	) {
		_ref.use(this);
	}

	writeValue(value: any): void {
		this._path$.next(value);
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._ontouch = fn;
	}

	ngOnInit() {
		this._mapsApiLoader.load().then(() => {
			this.initPoly();
		});
	}

	initPoly() {
		this._polygon = new google.maps.Polygon(this._defaultOptions);
		this._path = new google.maps.MVCArray([]);
		this._polygon.setPath(this._path);
		this._polygons.add(this._polygon);

        // this._path = new google.maps.MVCArray([]);
        // this._polygon.setPath(this._path);

		this._options$.subscribe(options => {
			this._polygon.setOptions(options);
		});

        this._pathInsertListener = this._path.addListener('insert_at', (event) => {
            if(!this._isWriting) this._changes$.next();
        });

        this._pathRemoveListener = this._path.addListener('remove_at', (event) => {
            if(!this._isWriting) this._changes$.next();
        });

        this._pathSetListener = this._path.addListener('set_at', (event) => {
            if(!this._isWriting) {

				if(this._timer) clearTimeout(this._timer);

				this._timer = setTimeout(() => {
					this._zone.run(() => {
						this._changes$.next();
						this._timer = null;
					});
				}, 50);

			}
		});

		this._polygon.addListener('rightclick', (event) => {
			if(event.path === 0 && event.vertex != undefined){
				if(this._path.getLength() > 2){
					this._path.removeAt(event.vertex);
				}
			}
		});

        this._path$.subscribe(pathArr => {

            this._isWriting = true;

			if(Array.isArray(pathArr)) {
				pathArr.forEach((latLng, index) => {
                    let obj = new google.maps.LatLng(latLng);
                    this._path.setAt(index, obj);
                });

                for(let i = pathArr.length; i < this._path.getLength(); i++){
                    pathArr.pop();
                }

			}else{
                this._path.clear();
            }

            this._isWriting = false;
		});

		this._changes$.subscribe(() => {

            this._lastValue = this.serializePath();
            this._zone.run(() => {
                this._onChange(this._lastValue);
                this.changeEmitter.emit(this._lastValue);
                this.areaEmitter.emit(this.computeArea());
            });
        });

	}

	ngOnDestroy() {
		if (this._polygon) {
			this._polygons.remove(this._polygon);
		}
		this._options$.complete();
		this._path$.complete();
	}

	public getArea(){
		return this.computeArea();
	}

	public onChange(){
		return this.changeEmitter.asObservable();
	}

	private computeArea() {

        if(this._polygon){
            return google.maps.geometry.spherical.computeArea(this._polygon.getPath());
        }

        return null;
	}

	private serializePath() {

		let path = this._polygon
					.getPath()
					.getArray()
					.map(latLng => {
						return {
							lat: latLng.lat(),
							lng: latLng.lng(),
						}
					});

        return path;
	}

	private patchOptions(options: any) {
		this._options$.next({
			...this._options$.value,
			...options
		});
	}

}
