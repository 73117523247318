import { Protocol } from './_types';

export const LIB_PROTOCOL_CANNABIS_PLANT_SCREEN_EVAL: Protocol = {
    id: "cannabis_plant_screen_eval",
    title: "Advanced Medical & Recreational Cannabis Plant Evaluation",
    description: "Plant evaluation is performed to characterise cultivars and selections on the base of a sample set of tree characteristics and includes certain phenological stages. Characteristics such as growth and architecture, flowering and fruit set, pests and diseases and general adaptability are scored for brief reference.",
    sampleType: "plant",
    crop: "cannabis",

    chars: [
        //Generic
        "date_plant_flower_start", 
        "date_plant_harvest", 
        "plant_grow_location", 
        "plant_flower_duration", 
        "plant_growth_type", 
        "plant_sex", 
        "plant_maternal_origin", 

        //Pests
        "plant_mealybugs_symptom",
        "plant_aphids_symptom", 
        "plant_spider_symptom", 
        "plant_whiteflies_symptom", 
        "plant_thrips_symptom", 
        "plant_scale_symptom", 
        "plant_broad_mites_symptom", 
        "plant_russet_mites_symptom", 
        "plant_caterpillar_symptom", 
        "plant_grasshopper_symptom",
        "plant_fungus_gnats_symptom", 
        "plant_leaf_hoppers_symptom", 
        "plant_leaf_miners_symptom", 
        "plant_snails_symptom", 

        //Diseases
        "plant_root_symptom", 
        "plant_leaf_septoria_symptom",
        "plant_tobacco_symptom",

        //Fungi
        "plant_powdery_mildew", 
        "plant_bud_mold", 

        //Flowering
        "plant_foliage_color_flower", 
        "plant_leaf_petiole_color_flower",  
        "plant_flower_color_flower", 
        "plant_flower_size", 
        "plant_flower_shape", 
        "plant_flower_size_leaf_blade", 
        "plant_flower_serrate_nature_leaf_blade", 
        "plant_trichrome_dense", 

        //Flower development
        "date_plant_flower_dev_emerge", 
        "date_plant_flower_dev_start", 
        "date_plant_flower_dev_primordia", 
        "date_plant_flower_dev_pistol", 
        "date_plant_flower_dev_calyx",
        "date_plant_flower_dev_tri", 
        "date_plant_flower_dev_inflour", 

    ],
    measures: [
        //Seeding
        "plant_height_seed", //
        "plant_canX_seed", //
        "plant_canY_seed", //
        "plant_internodes_seed", //
        "plant_internode_length_seed", //

        //Vegetative
        "plant_height_veg", //
        "plant_canX_veg", //
        "plant_canY_veg", //
        "plant_nodes_primary_veg", //
        "plant_internodes_veg", //
        "plant_internode_length_veg", //
        "plant_stem_dia_veg", //

        //Flowering
        "plant_height_flower", //
        "plant_canX_flower", //
        "plant_canY_flower", //
        "plant_nodes_primary_flower", //
        "plant_internodes_flower", //
        "plant_internode_length_flower", //
        "plant_stem_dia_flower", //
        "plant_primary_cola_size", //
        "plant_secondary_cola_size", //
        "plant_flower_dia", //
        "plant_number_flowers", //

       
    ],

    indexes: [],
};