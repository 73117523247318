import { Crop, FilterPage, FilterSort, Index, Status, WeightedChar } from "@core/data";
export interface TastingCustomIndexesIndexStateModel {
    // org context
    orgKey: string;

    // page data
    status: Status;
    data: Index[];
    total: number;

    // data options
    displayColumns: string[];
    sortOptions: {id: string, label: string}[];
    pageSizeOptions: number[];
    cropOptions: Crop[];
    weightedCharOptions: WeightedChar[];

    // filter options
    page: FilterPage;
    sort: FilterSort;
    query: TastingCustomIndexesQuery;

    // selection
    selectedKeys: string[];
    selectedData: Index[];
}

export interface TastingCustomIndexesQuery {
    search: string;
    cropKeys: string[];
    weightedCharKeys: string[];
}

export const TASTING_CUSTOM_INDEX_SORT_OPTIONS: {id: string, label: string}[] = [
    { id: 'title', label: 'Title' },
    { id: 'updatedAt', label: 'Updated Date' },
    { id: 'createdAt', label: 'Created Date' },
]

export const TASTING_CUSTOM_INDEX_DISPLAY_COLUMNS = [
    'checkbox',
    'title',
    'range',
    'crop',
    'weightedCharsCount',
    'actions',
    'timestamps',
]

export const DEFAULTS: TastingCustomIndexesIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    cropOptions: [],
    weightedCharOptions: [],
    total: 0,
    page: {
        index: 0,
        size: 20,
    },
    sort: {
        column: 'updatedAt',
        order: 'desc'
    },
    displayColumns: TASTING_CUSTOM_INDEX_DISPLAY_COLUMNS,
    sortOptions: TASTING_CUSTOM_INDEX_SORT_OPTIONS,
    pageSizeOptions: [5, 10, 20, 50, 100, 500],
    query: {
        search: null,
        cropKeys: [],
        weightedCharKeys: []
    },
    selectedData: [],
    selectedKeys: [],
}