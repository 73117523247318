import { Protocol } from './_types';

export const LIB_PROTOCOL_GRAPE_VINE_SCREEN_EVAL: Protocol = {
    id: "grape_vine_screen_eval",
    title: "Advanced Grape Vine Evaluation",
    description: "Vine Evaluation is performed to characterise the adaptability of cultivars and selections on the base of a full set of tree characteristics and includes all phenological stages and manipulation events. Characteristics such as growth, flowering and fruit set, diseases and pests and general adaptability are evaluated in detail. ",
    sampleType: "plant",
    crop: "grape",

    chars: [

        "date_vine_bud_burst",
        "date_vine_10_flower",
        "date_vine_80_flower",
        "date_vine_berry_set",
        "date_vine_berry_shat",
        "date_vine_pea_size",
        "date_vine_veraison",
        "date_vine_harvest_start",
        "date_vine_harvest_end",

        "date_vine_tip_man",
        "date_vine_top_man",
        "date_vine_thin_man1",
        "date_vine_thin_man2",
        "date_vine_thin_man3",
        "date_vine_set_man1",
        "date_vine_set_man2",
        "date_vine_set_man3",
        "date_vine_size_man1",
        "date_vine_size_man2",
        "date_vine_size_man3",
        "date_vine_color_man1",
        "date_vine_color_man2",
        "date_vine_color_man3",
        "date_vine_rest_break",
        "date_vine_prune",


        "vine_bud_distrib",
        "vine_grow_potent",
        "vine_shoot_dense",
        "vine_canopy_dense",

        "vine_trellis_system",
        "vine_train_system",

        "vine_flower_bunch_dense",
        "vine_flower_bunch_distrib",
        "vine_flower_bunch_thin",
        "vine_flower_absciss",
        "vine_flower_thin",

        "vine_bear_precocity",
        "vine_bear_habit",
        "vine_berry_shatter",
        "vine_bunch_shape",
        "vine_bunch_short",
        "vine_berry_shot",
        "vine_bunch_dense",
        "vine_bunch_uniform",
        "vine_berry_uniform",
        "vine_berry_thin",
        "vine_pick_count",
        "vine_color_dev",

        "vine_pest_detect",
        "vine_disease_detect",
        "vine_overall_disease",

        "vine_dormant_symptom",
        "vine_compat",
        "vine_flower_span",
        "vine_uniform_ripe",
        "vine_fertile",
        "vine_overall_product",
        "vine_rain_suscept",
        "vine_sunburn_symptom",
        "vine_work",
        "vine_overall_adapt",
    ],

    measures: [
        "vine_tdia",
        "vine_bcnt",
        "vine_yiel",
        "vsho_len",
        "vsho_bcnt",
        "prec_bcnt",
        "prec_scnt",
    ],

    indexes: [
        "indx_vine_adapt"
    ],

};