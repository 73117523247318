import { SampleType } from '@core/data';

export class InitSampleDetail {
    static readonly type = "[SampleDetail] Init";
    constructor(public orgKey: string, public sampleType: SampleType, public sampleKey: string){}
}

export class LoadSampleDetail {
    static readonly type = "[SampleDetail] Load";
}

export class DeleteSample {
    static readonly type = "[SampleDetail] Delete";
}
