import { Component, Input } from "@angular/core";
import { Dialog, Snackbar } from '@core/material';
import { Store } from '@ngxs/store';
import { OrganizationGroup } from '@core/data';
import {RemoveGroupSample, AddGroupSample, RemoveGroupProtocolFilter, AddGroupProtocolFilter} from './groups.state';
import {
    OrganizationGroupProtocolFilterFormDialog,
    OrganizationGroupProtocolFilterFormDialogData,
    OrganizationGroupProtocolFilterFormDialogResult
} from "./organization-group-protocol-filter-form.dialog";

@Component({
    selector: 'pv-organization-group-protocl-filter',
    templateUrl: 'organization-group-protocol-filter-list.component.html',
    styles: [
        `
        :host {
            display: block;
            position: relative;
        }
    `
    ],
})
export class OrganizationGroupProtocolFilterList {
    @Input()
    orgKey: string;

    @Input()
    group: OrganizationGroup;

    constructor(
        private _store: Store,
        private _dialogs: Dialog,
        private _snackbar: Snackbar
    ) { }

    addGroupProtocolFilter(orgKey: string, groupKey: string, groupType: string) {
        const data: OrganizationGroupProtocolFilterFormDialogData = {
            orgKey,
            groupKey,
            groupType,
        };
        this._dialogs.open(OrganizationGroupProtocolFilterFormDialog, { data })
            .afterClosed()
            .subscribe((result: OrganizationGroupProtocolFilterFormDialogResult) => {
                if (result) {
                    this._store
                        .dispatch(new AddGroupProtocolFilter(groupKey, result.protocolKey))
                        .subscribe(_ => {
                            this._snackbar.info("Protocol added to group");
                        });
                }
            });
    }

    removeGroupProtocolFilter(groupKey: string, protocolKey: string) {
        this._dialogs.confirm(
            'Remove Protocol from Group',
            'Are you sure you want to remove this Protocol from the group?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new RemoveGroupProtocolFilter(groupKey, protocolKey))
                    .subscribe(state => {
                        this._snackbar.info("Protocol removed from group");
                    });
            }
        });
    }
}
