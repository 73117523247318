import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { AuthState, AuthStateModel } from './auth-state';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _store: Store
    ) { }

    private guestUserRestricedPaths: String[] = [
        'org/:orgKey/cultivars',
        'org/:orgKey/protocols',
        'org/:orgKey/sites',
        'org/:orgKey/weather-stations',
        'org/:orgKey/reports',
        'org/:orgKey/reports/:reportKey',
        'org/:orgKey/reports/:reportKey/edit',
        'report-share/:shareKey',
        'org/:orgKey/groups',
        'org/:orgKey/detail'
    ];
    /**
     *
     * @param route snapshot of activate route
     * @param state snapshot of router state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return this._store.selectOnce(AuthState)
            .pipe(map((authState: AuthStateModel) => {
                let queryParams = {
                    returnTo: state.url
                };

                if (!authState.user) {
                    this._router.navigate(['/login'], { queryParams });
                    return false;
                }

                if (this.getUserRole(authState) === 'guest') {
                    if (this.guestUserRestricedPaths.indexOf(route.routeConfig.path) != -1) {
                        this._router.navigate(['/login'], { queryParams });
                        return false;
                    }
                }

                if (authState.token) {
                    return true;
                }

                this._router.navigate(['/login'], { queryParams });
                return false;
            }));
    }

    private getUserRole(authState: AuthStateModel): string {
        var role: string = '';
        var selectedOrg: string = authState.selectedOrgKey;

        authState.user.organizations.forEach(org => {
            if (org.key === selectedOrg) {
                role = org.access.role;
            }
        })

        return role;
    }
}