import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status, WeatherStation } from '@core/data';
import { Snackbar } from '@core/material';
import { CoordinateValidators, NumberValidators } from '@core/utils';
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InitWeatherStationForm, SubmitWeatherStationForm, WeatherStationFormState, WeatherStationFormStateModel } from './weather-station-form.state';

@Component({
    selector: 'pv-weather-station-form-dialog',
    templateUrl: 'weather-station-form.dialog.html',
    styleUrls: [ 'weather-station-form.dialog.scss' ],
})


export class WeatherStationFormDialog implements OnInit, OnDestroy {

    @Select(WeatherStationFormState)
    state$: Observable<WeatherStationFormStateModel>;

    @Select(WeatherStationFormState.data)
    data$: Observable<Partial<WeatherStation>>;

    nameControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(32)]);
    latControl: FormControl = new FormControl(null, [CoordinateValidators.lat()]);
    lngControl: FormControl = new FormControl(null, [CoordinateValidators.lng()]);
    altControl: FormControl = new FormControl(null, [NumberValidators.decimal(10,4)]);
    deviceIdControl: FormControl = new FormControl(null);
    pacCodeControl: FormControl = new FormControl(null);
    ownerOrgKeyControl = new FormControl(null);
    typeControl = new FormControl(null);

    formGroup: FormGroup = new FormGroup({
        type: this.typeControl,
        name: this.nameControl,
        lat: this.latControl,
        lng: this.lngControl,
        alt: this.altControl,
        ownerOrgKey: this.ownerOrgKeyControl,
        deviceId: this.deviceIdControl,
        pacCode: this.pacCodeControl,
    });

    sensorOptions = [
        {id: 'tinyTag', label: 'TinyTag'},
        {id: 'sigFox', label: 'SigFox'},
    ]

    sigFoxGuide = false;
    sigFoxImagePath = 'cloudinary:provar-dev/culteva/weather/sigFox-guide';

    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<WeatherStationFormDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: WeatherStationFormDialogData,
    ) { }

    ngOnInit() {
        this._store.dispatch(new InitWeatherStationForm(this.data.key, this.data.defaults));

        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) {
                    this.reset(data);
                }
            });

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if (state.status === Status.COMPLETE) {
                    this._dialogRef.close(state.data);
                } else if (state.status === Status.LOADING) {
                    this.formGroup.disable();
                } else {
                    this.formGroup.enable();
                    if (state.errors.duplicateName) {
                        this.nameControl.setErrors({ duplicateName: true });
                    }
                }

            });

        //Dynamically update the form validation on sensorType change
        this.formGroup.get('type').valueChanges
            .subscribe(value => this.updateValidation(this.formGroup, value));
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    reset(data: Partial<WeatherStation>) {
        this.formGroup.reset({
            type: data.type || 'tinyTag',
            name: data.name || null,
            lat: data.lat,
            lng: data.lng,
            alt: data.alt,
            ownerOrgKey: data.ownerOrgKey || null,
            deviceId: data.deviceId || null,
            pacCode: data.pacCode || null,
        });

    }

    attempt() {

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    save() {
        const form = this.formGroup.value;
        let data = {...form};
        this._store.dispatch(new SubmitWeatherStationForm(data));
    }

    cancel() {
        this._dialogRef.close();
    }

    private updateValidation(formGroup: FormGroup, type: string) {
        if (type == 'sigFox') {
            if (formGroup.get('pacCode')) this.setValidation(formGroup.get('pacCode'), this.buildValidatorsList('pacCode'));
            if (formGroup.get('deviceId')) this.setValidation(formGroup.get('deviceId'), this.buildValidatorsList('deviceId'));
        } else {
            if (formGroup.get('pacCode')) this.clearValidation(formGroup.get('pacCode'));
            if (formGroup.get('deviceId')) this.clearValidation(formGroup.get('deviceId'));
        }
    }

    private setValidation(formControl: AbstractControl, validators: ValidatorFn[]) {
        if (!formControl.parent) return;
        formControl.setValidators(validators)
        formControl.updateValueAndValidity()
    }

    private clearValidation(formControl: AbstractControl) {
        if (!formControl.parent) return;
        formControl.setValidators(null);
        formControl.updateValueAndValidity();
    }

    private buildValidatorsList(controlType: string): ValidatorFn[] {
        if (controlType == 'deviceId') return [Validators.required, Validators.minLength(8), Validators.maxLength(8)];
        if (controlType == 'pacCode') return [Validators.required, Validators.minLength(16), Validators.maxLength(16)];
    }
}

export interface WeatherStationFormDialogData {
    key?: string;
    defaults?: Partial<WeatherStation>;
}