import { Protocol } from './_types';

export const LIB_PROTOCOL_APPLE_TREE_SCREEN_EVAL: Protocol = {
    id: "apple_tree_screen_eval",
    title: "Advanced Apple Tree Evaluation",
    description: "Tree Evaluation is performed to characterise the adaptability of cultivars and selections on the base of a full set of tree characteristics and includes all phenological stages. Characteristics such as growth and architecture, flowering and fruit set, pests and diseases and general adaptability are evaluated in detail.",
    sampleType: "plant",
    crop: "apple",

    chars: [
        "date_irb",
        "date_ivb",
        "date_balloon",
        "date_50_flower",
        "date_full_flower",
        "date_end_flower",
        "date_petal_fall",
        "date_flower_thin",
        "date_fruit_thin",
        "date_harvest_start",
        "date_harvest_end",
        "date_leaf_drop",
        "date_50_leaf_drop",
        "date_80_leaf_drop",
        "date_100_leaf_drop",

        "tree_trellis_system",
        "tree_train_system",
        "tree_branch_habit_pome",
        "tree_grow_type_apple",
        "tree_grow_form",
        "tree_grow_potent",
        "tree_branch_dense",
        "tree_leaf_color",

        "tree_flower_dense",
        "tree_flower_distrib",
        "tree_flower_pos",
        "tree_flower_thin",
        "tree_flower_second",

        "tree_bear_precocity",
        "tree_bear_pos_pome",
        "tree_bear_habit",
        "tree_bear_potent",
        "tree_color_inside",
        "tree_fruit_thin",
        "tree_preharvest_drop",
        "tree_pick_count",
        "tree_bear_alternate",

        "tree_pest_detect_apple",
        "tree_neonectria_canker_apple",
        "tree_pseudomonas_pome",
        "tree_fruit_scab_pome",
        "tree_leaf_scab_pome",
        "tree_mildew_symptom_apple",
        "tree_overall_disease",
        "tree_gen_frost_damage",
        "tree_flower_frost_damage",
        "tree_disorder_detect",

        "tree_dormant_symptom",
        "tree_flower_span",
        "tree_uniform_ripe",
        "tree_overall_product",
        "tree_sunburn_symptom",
        "tree_space_filling",
        "tree_overall_adapt",
    ],
    measures: [
        "tree_trunk_circum",
        "tree_total_yield",
        "tree_est_yield",
        "tree_fruit_count",
        "tree_height",
        "tree_width",
        "tree_depth",
        "tree_fde",
        'tree_yield_eff',
        "kg_per_pick",
    ],

    indexes: [
        "indx_pome_adapt",
    ],

    calculations: [
        "tree_yield_eff",
        "tons_per_ha_calc",
    ]
};
