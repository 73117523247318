
export interface LatLng {
    lat: number;
    lng: number;
}

export interface Position extends LatLng {
    alt: number;
}

export class MapTypes {
    static readonly HYBRID = "hybrid";
    static readonly ROADMAP = "roadmap";
    static readonly SATELLITE = "satellite";
    static readonly TERRAIN = "terrain";
}

export interface PolyStyle {
    fillColor: string;
    fillOpacity: number;
    strokeColor: string;
    strokeWeight: number;
    strokeOpacity: number;
}

export interface GeocodeMarker {
    position: LatLng;
}

export interface SiteMarker {
    key: string;
    label: any;
    position: LatLng;
}

export interface SitePolygon {
    key: string;
    path: LatLng[];
}

export enum Focus {
    FOCUS_GEO = "FOCUS_GEO",
    FOCUS_ALL = "FOCUS_ALL",
    FOCUS_SELECTED = "FOCUS_SELECTED",
    FOCUS_USER = "FOCUS_USER",
    FOCUS_DEFAULT = "FOCUS_DEFAULT",
}