import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { fileExtension } from "../../../../core/utils";

@Component({
    selector: 'pv-import-upload',
    templateUrl: './import-form-upload.component.html',
    styleUrls: ['import-form-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-import-upload'
    }
})
export class ImportFormUploadComponent {

    // Class instance members
    isDragging: boolean = false;
    validationErrors: string[] = [];
    maxSize: number = 40000000; // 40MB
    uploadedFile: File = null;
    allowedFiles: string[] = ['xlsx'];

    // Inputs from parent component
    @Input() status: string;
    @Input() tastings: boolean = false;

    // File will be emitted to parent component on upload/change
    @Output() file = new EventEmitter<File>();

    constructor() {}

    onDrop(event: DragEvent): void {
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
            this.uploadedFile = event.dataTransfer.files[0];
            this.file.emit(this.uploadedFile); // Emit file to parent component
        }
        this.isDragging = false;
        event.preventDefault();
        event.stopPropagation();
    }

    onDragOver(event: DragEvent): void {
        if (event.dataTransfer) event.dataTransfer.dropEffect = 'copy';
        if (!this.isDragging) this.isDragging = true;
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent): void {
        if (this.isDragging) this.isDragging = false;
        event.preventDefault();
    }

    fileSelected(file: File): void {
        if (!this.validateFile(file)) return;
        this.uploadedFile = file;
        this.file.emit(this.uploadedFile); // Emit file to parent component
    }

    private validateFile(file: File): boolean {
        let valid: boolean = true;
        this.validationErrors = [];

        if (!file) {
            this.validationErrors.push('No File Present');
            return false;
        }
        if (!this.allowedFiles.includes(fileExtension(file.name).toLowerCase())) {
            this.validationErrors.push(`${file.name} is not a valid file type`);
        }
        if (file.size > this.maxSize) this.validationErrors.push(`${file.name} exceeds the maximum size`);
        if (this.validationErrors.length > 0) valid = false;

        return valid;
    }
}