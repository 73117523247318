import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Cultivar, CultivarService, SearchResponse, SearchRequest, SampleSearchRequest } from '@core/data';
import { Observable } from 'rxjs';
import { AUTOCOMPETE_CHIPS_STYLES, ModelAutocompleteChipsComponent } from './model-autocomplete-chips-component';



@Component({
    selector: 'pv-cultivar-autocomplete-chips',
    templateUrl: './cultivar-autocomplete-chips.component.html',
    styleUrls: ['./model-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CultivarAutocompleteChipsComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: CultivarAutocompleteChipsComponent
        }
    ],
    styles: [AUTOCOMPETE_CHIPS_STYLES]
})
export class CultivarAutocompleteChipsComponent extends ModelAutocompleteChipsComponent<Cultivar>
{
    constructor(
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeDetect: ChangeDetectorRef,
        private _cultivarService: CultivarService
    ){
        super(fm, elRef, _changeDetect);
    }


    search(search: SearchRequest): Observable<SearchResponse<Cultivar>> {
        let query: SampleSearchRequest = {ownerOrgKey: search.ownerOrgKey, text: search.text, limit: 10};
        return this._cultivarService.search(query);
    }

}