import { Protocol } from './_types';

export const LIB_PROTOCOL_GRAPE_HARVEST_RAISIN_EVAL: Protocol = {
    id: "grape_harvest_raisin_eval",
    title: "Raisin Grape (Fresh) Harvest Evaluation",
    description: "Harvest evaluation is performed to characterise new cultivars and selections on the base of their fruit traits. Visual and sensory fruit evaluation are applied to describe bunch and berry quality and keeping ability after storage over a specified storage period. Characteristics such as appearance, defects and disorders and eating quality are evaluated in detail.",
    sampleType: "harvest",
    crop: "grape",

    chars: [

        "bery_perc_shrivel",
        "bery_attach",

        "bery_ripe_stage",
        "bery_firm",
        "bery_firm_homog",

        "bery_over_color",
        "bery_skin_bloom",
        "bery_flesh_color",
        "bunc_color_cover",
        "bery_shape_type",

        "bery_perc_blemish",
        "bery_lenticel",
        "bery_perc_damage",

        "bery_perc_decay_bot",
        "bery_perc_decay_stb",
        "bery_perc_decay_pen",
        "bery_perc_decay_rhi",
        "bery_perc_decay_asp",


        "bery_perc_bc_lateral",
        "bery_perc_bc_ring",
        "bery_perc_bc_stylar",

        "bery_perc_exb_netlike",
        "bery_perc_exb_mottled",
        "bery_perc_exb_friction",
        "bery_perc_exb_russet",
        "bery_perc_exb_contact",
        "bery_perc_exb_peacock",
        "bery_perc_exb_sunburn",
        "bery_perc_exb_abrasions",

        "bery_perc_inb_chocolate",
        "bery_perc_inb_water",
        "bery_perc_inb_glass",
        "bery_perc_inb_cold",
        "bery_perc_inb_freeze",
        "bery_perc_inb_methyl",
        "bery_perc_inb_co2",

        "bery_seed",
        "bery_seed_detect",
        "bery_seed_trace",

        "bery_taste_sugar",
        "bery_taste_acid",
        "bery_taste_balance",
        "bery_taste_aroma",
        "bery_taste_aroma_type",
        "bery_taste_juice",
        "bery_skin_flavor_type",
        "bery_skin_flavor_intense",
        "bery_skin_thick",
        "bery_text_crunch",

        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_color",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest",
    ],

    measures: [
        "bery_len",
        "bery_dia",
        "bery_wei",
        "bnch_wei",
        "bery_rthi",
        "bery_pthi",
        "frut_tss",
        "frut_acid",
        "bery_firm"
    ],

    calculations: [
        "sugar_acid_ratio_calc",
    ],

    indexes: [
        "indx_frut_overall"
    ],
};