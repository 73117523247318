import { Pipe, PipeTransform } from '@angular/core';

import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Sample } from '@core/data';

@Pipe({
    name: 'rowPosition'
})
export class RowPositionPipe implements PipeTransform {

    constructor(private formatter: SampleFormatter){}

    transform(sample: Sample): any {
        return this.formatter.describeRowPosition(sample);
    }
}


