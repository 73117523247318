import { Injectable } from "@angular/core";
import { Filter, FilterQueryMode, ReportService, ReportTableTemplate, Status, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";


export interface ReportTableTemplateStateModel {
    status: Status;
    orgKey: string;
    data: Partial<ReportTableTemplate>;
    templates: ReportTableTemplate[];
}

const DEFAULTS: ReportTableTemplateStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: null,
    templates: [],
    data: null,
}

export class InitReportTableTemplateForm {
    static readonly type = '[ReportTableTemplateForm] Init';
    constructor(public data: Partial<ReportTableTemplate>){}
}

export class SubmitReportTableTemplateForm {
    static readonly type = '[ReportTableTemplateForm] Submit';
    constructor(public data: Partial<ReportTableTemplate>){}
}

export class LoadReportTableTemplates {
    static readonly type = '[ReportTableTemplates] Load';
    constructor(public ownerOrgKey: string){}
}


export class DeleteReportTableTemplate {
    static readonly type = '[ReportTableTemplates] Delete';
    constructor(public id: number){}
}

@State({
    name: 'report_table_templates',
    defaults: DEFAULTS
})
@Injectable()
export class ReportTableTemplatesState {
    constructor(
        private _reportService: ReportService
    ) {}

    @Selector()
    static data(state: ReportTableTemplateStateModel) {
        return state.data;
    }

    @Selector()
    static templates(state: ReportTableTemplateStateModel) {
        return state.templates;
    }

    @Action(InitReportTableTemplateForm, {cancelUncompleted: true})
    initReportTableTemplateForm(ctx: StateContext<ReportTableTemplateStateModel>, action: InitReportTableTemplateForm) {
        if (!action.data.id) {
            ctx.setState({
                ...DEFAULTS,
                orgKey: action.data.ownerOrgKey,
                status: Status.OK,
                data: {
                    ...action.data
                }
            })

            return ctx.dispatch(new LoadReportTableTemplates(action.data.ownerOrgKey));
        }

        ctx.patchState({
            status: Status.LOADING,
        })

        const filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: action.data.ownerOrgKey,
                },
                {
                    key: 'id',
                    mode: FilterQueryMode.EQUALS,
                    value: action.data.id,
                },
            ],
        };

        this._reportService.getTableTemplate(action.data.id, filter)
            .pipe(tap(
                result => {
                    ctx.patchState({
                        status: Status.OK,
                        data: result,
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error),
                    });
                }
            ))

            return ctx.dispatch(new LoadReportTableTemplates(action.data.ownerOrgKey));
    }

    @Action(SubmitReportTableTemplateForm, {cancelUncompleted: true})
    submitReportTableTemplateForm(ctx: StateContext<ReportTableTemplateStateModel>, action: SubmitReportTableTemplateForm){

        const request = action.data.id
            ? this._reportService.updateTableTemplate(action.data.id, action.data)
            : this._reportService.createTableTemplate(action.data);

        return request.pipe(tap(
            result => {
                ctx.patchState({
                    status: Status.COMPLETE,
                    data: result,
                })
            },
            err => {
                ctx.patchState({
                    status: translateCommonErrorStatus(err),
                });
            }
        ));

    }


    @Action(DeleteReportTableTemplate, {cancelUncompleted: true})
    deleteReportTableTemplate(ctx: StateContext<ReportTableTemplateStateModel>, action: DeleteReportTableTemplate){

        const request = this._reportService.deleteTableTemplate(action.id);

        return request.pipe(tap(
            result => {
                if(result) {
                    ctx.patchState({
                        status: Status.DELETED,
                    })
                 }
            },
            err => {
                ctx.patchState({
                    status: translateCommonErrorStatus(err),
                });
            }
        ));

    }

    @Action(LoadReportTableTemplates, {cancelUncompleted: true})
    loadReportTableTemplates(ctx: StateContext<ReportTableTemplateStateModel>, action: LoadReportTableTemplates){

        let orgKey = action.ownerOrgKey;

        if(!orgKey){
            ctx.patchState({
                ...DEFAULTS,
                status: Status.ERROR
            });
            console.warn('ReportTableTemplateState: No org key provided with new template.');
            return;
        }

        ctx.patchState({
            status: Status.LOADING,
        });


        return this._reportService.listTableTemplates(orgKey)
                .pipe(tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            templates: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                ));
    }
}