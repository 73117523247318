import { Chart, ChartOptions } from 'chart.js';
import { Component, ElementRef, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';

import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Dialog } from '@core/material';
import { ChartFullscreenDialog, ChartFullscreenDialogData } from './chart-fullscreen-dialog/chart-fullscreen.dialog';

@Component({
    selector: 'pv-chart',
    template: `
        <div #container class="chart-container">
            <canvas #canvas [ngClass]="{'chart-canvas': expandable, 'fullscreen-canvas': !expandable}"></canvas>
            <button class="chart-button no-print"
                [class.hidden-button]="!showButton"
                (click)="openFullScreenDialog()"
                mat-icon-button
                matTooltip="Full Screen">
                <mat-icon>fullscreen</mat-icon>
            </button>
        </div>`,
    styleUrls: ['chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-chart',
    }
})
export class ChartComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input()
    set type(type: string) {
        if (type && this._type$.getValue() !== type) {
            this._type$.next(type);
        }
    }

    @Input()
    set data(data: any) {
        if (data && this._data$.getValue() !== data) {
            this._data$.next(data);
        }
    }

    @Input()
    set options(options: ChartOptions) {
        if (options && this._options$.getValue() !== options) {
            this._options$.next(options);
        }
    }

    @Input()
    set devicePixelRatio(ratio: number) {
        if (ratio && this._devicePixelRatio$.getValue() !== ratio) {
            this._devicePixelRatio$.next(ratio);
        }
    }

    @Input()
    expandable: boolean = true;

    @Input()
    chartLabel: string;

    @ViewChild('canvas', { static: true })
    canvasRef: ElementRef;

    @ViewChild('container', { static: true })
    containerRef: ElementRef;

    showButton: boolean;

    private _type$ = new BehaviorSubject<string>(null);
    private _options$ = new BehaviorSubject<ChartOptions>(null);
    private _data$ = new BehaviorSubject<any>(null);
    private _devicePixelRatio$ = new BehaviorSubject<number>(null);
    private _destroy$ = new Subject();

    private _canvas: HTMLCanvasElement;
    private _chart: Chart;

    constructor(
        private _host: ElementRef,
        private _dialog: Dialog,
    ) { }

    ngOnInit() {
        this.showButton = this._type$.value === 'radar';
        if (!this.expandable) this.showButton = false;
    }

    ngAfterViewInit() {

        this._canvas = this.canvasRef.nativeElement;

        combineLatest(
                this._type$,
                this._options$,
                this._data$,
                this._devicePixelRatio$
            )
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(100),
            )
            .subscribe(changes => {

                let [type, options, data, devicePixelRatio] = changes;

                let isRadar = type === 'radar';

                try {

                    if (!type || !options || !data) {
                        if (this._chart) {
                            this._chart.destroy();
                            this._chart = null;
                        }
                    }

                    if (!this._chart || this._chart.config.type !== type) {
                        if (this._chart) this._chart.destroy();

                        if (isRadar) {
                            options = {
                                ...options,
                                scale: {
                                    ticks: {
                                        min: 0,
                                        max: 100,
                                    }
                                }
                            }
                        }

                        this._chart = new Chart(this._canvas, {
                            type,
                            options: { ...options, devicePixelRatio },
                            data
                        });
                    } else {
                        this._chart.options = { ...options, devicePixelRatio };
                        this._chart.data = data;
                        this._chart.update();
                    }

                } catch (error) {
                    console.error("ChartComponent: Error compiling chart", error);

                    if (this._chart) {
                        this._chart.destroy();
                        this._chart = null;
                    }
                }


            });

    }

    ngOnDestroy() {

        this._destroy$.next();
        this._destroy$.complete();

        if (this._chart) {
            this._chart.destroy();
            this._chart = null;
        }

        this._type$.complete();
        this._options$.complete();
        this._data$.complete();

    }

    openFullScreenDialog() {
        let data: ChartFullscreenDialogData = {
            type: this._type$.value,
            data: this._data$.value,
            options: this._options$.value,
            ratio: this._devicePixelRatio$.value,
            title: this.chartLabel || "Chart Preview",
        }

        this._dialog.openFullscreen(ChartFullscreenDialog, {data})
    }

}
