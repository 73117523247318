import { Pipe } from '@angular/core';
import { convertImageFileRef, ImageTransformOptions } from '../file-ref';

@Pipe({ name: 'srcFileRef' })
export class SourceFileRefPipe {
    transform(fileRef: string, options?: ImageTransformOptions | string) {

        if (fileRef) {
            if (typeof options === 'string') {
                return convertImageFileRef(fileRef, { transformId: options, format: 'auto' });
            } else {
                return convertImageFileRef(fileRef, options);
            }
        }

        return '';

    }
}