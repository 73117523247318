import { Pipe, PipeTransform } from '@angular/core';

import { EvaluationFormatter } from '../evaluation-formatter.service';

@Pipe({
    name: 'labelCharValue'
})
export class LabelCharValuePipe implements PipeTransform {

    constructor(private _formatter: EvaluationFormatter){}

    transform(value: any, charId:string, rescaledValue?: any , isReportTable?: boolean, showMinDesc?: boolean): any {
        return this._formatter.charValueLabel(value, charId, rescaledValue, isReportTable, showMinDesc);
    }
}


