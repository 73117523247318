import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { AuthLoginView } from './components/auth-login/auth-login.view';
import { OrganizationDetailView } from './components/organization-detail/organization-detail.view';


const routes: Routes = [
    { path: 'login',  component: AuthLoginView },
    { path: 'org/:orgKey/detail', component: OrganizationDetailView, canActivate: [AuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule { }