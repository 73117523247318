export function deleteNullRecursive(object: any) {
    for (let key in object) {
        if (object.hasOwnProperty(key)) {

            if (typeof object[key] === 'object') {
                deleteNullRecursive(object[key]);
            } else if (object[key] === null) {
                delete object[key];
            }

        }
    }
}

export function getType(value: any) {
    if (isString(value))
        return 'string';
    if (isNumber(value))
        return 'number';
    if (isArray(value))
        return 'array';
    if (isObject(value))
        return 'object';
    if (isUndefined(value))
        return 'undefined';
    if (isNull(value))
        return 'null';
}

export function isEmpty(value: any) {
    return (value === undefined || value === null || value === '');
}

export function isString(value: any) {
    return typeof value === 'string' || value instanceof String;
}

export function isNumber(value: any) {
    return typeof value === 'number' && isFinite(value);
}

export function isArray(value: any) {
    return value && typeof value === 'object' && value.constructor === Array;
}

export function isFunction(value: any) {
    return typeof value === 'function';
}

export function isNull(value: any) {
    return value === null;
}

export function isUndefined(value) {
    return typeof value === 'undefined';
}

export function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

export function encodeQueryParams(obj: { [key: string]: any }) {
    let str = [];

    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }

    return str.join("&");
}

export class Dot {
    static get(obj: any, path: string, default_value: any = null) {

        let keys = path.split('.');
        let key;

        for (let i = 0; i < keys.length; i++) {
            key = keys[i];
            if (typeof obj !== "object" || !obj.hasOwnProperty(key)) {
                obj = default_value;
                break;
            }
            obj = obj[key];
        }

        return obj;
    }

    static set(obj: any, path: string, value: any) {

        let keys = path.split('.');
        let key: string, i: number;

        for (i = 0; i < keys.length - 1; i++) {
            key = keys[i];
            if (obj[key] === undefined || obj[key] === null) obj[key] = {};
            obj = obj[key];
        }

        obj[keys[i]] = value;

        return value;
    }
}