import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { API_BASE_URI } from "../http/api";
import { Collection } from "../http/collection";
import { Filter, FilterBuilder } from '../http/filter';
import { DetailRequestBuilder, DetailRequest } from "../http/detail";
import { TastingSample, TastingSampleImage } from "../types/tasting-sample";
import { SearchRequest, SearchResponse } from "..";
import { Sample } from "../public_api";

export class TastingSampleCreated {
    static readonly type = "[TastingSampleService] Tasting Sample Created";
    constructor(public tastingSample: TastingSample){}
}

export class TastingSampleUpdated {
    static readonly type = "[TastingSampleService] Tasting Sample Updated";
    constructor(public tastingSample: TastingSample){}
}

export class TastingSampleDeleted {
    static readonly type = "[TastingSampleService] Tasting Sample Deleted";
    constructor(public tastingSample: TastingSample){}
}

export class TastingSampleImageUploaded {
    static readonly type = "[TastingSampleService] Tasting Sample Image Uploaded";
    constructor(public image: TastingSampleImage){}
}

export class TastingSampleCreatedFromCultivar {
    static readonly type = "[TastingSampleService] Tasting Sample Created From Cultivar";
    constructor(public createdCount: number){}
}

export interface TastingSampleImagesUpdateRequest {
    images: Array<Partial<TastingSampleImage>>;
    ownerOrgKey: string;
}

export interface TastingsSampleCultivarBulkActionRow {
    siteKey: string;
    cultivarKey: string;
    harvestDate: string;
    connectHarvestSample: boolean;
}

export interface TastingsSampleCultivarBulkActionResponse {
    createdCount: number;
}

@Injectable()
export class TastingSampleService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string
    ) { }

    get(tastingsSampleKey: string, detail?: DetailRequest): Observable<TastingSample> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<TastingSample>(`${this._baseUri}/tasting-samples/${tastingsSampleKey}`, {params});
    }

    create(model: Partial<TastingSample>) {
        return this._http.post<TastingSample>(`${this._baseUri}/tasting-samples`, model)
            .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleCreated(tastingSample))))
    }

    // Creates a tasting Sample by generating a harvest sample to attach to first
    createWithHarvest(model: Partial<TastingSample>, harvest: Partial<Sample>) {
        return this._http.post<TastingSample>(`${this._baseUri}/tasting-samples/harvest`, {data: model, harvest: harvest})
            .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleCreated(tastingSample))));
    }

    // Creates a tasting Sample by generating a harvest sample to attach to first from the selected Cultivar
    createFromCultivar(ownerOrgKey: string, data: TastingsSampleCultivarBulkActionRow[]) {
        return this._http.post<TastingsSampleCultivarBulkActionResponse>(`${this._baseUri}/tasting-samples/cultivar`, {ownerOrgKey: ownerOrgKey, data: data})
            .pipe(tap(response => this._store.dispatch(new TastingSampleCreatedFromCultivar(response.createdCount))));
    }

    update(key: string, model: Partial<TastingSample>) {
        return this._http.put<TastingSample>(`${this._baseUri}/tasting-samples/${key}`, model)
                .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleUpdated(tastingSample))));
    }

    addToEvent(key: string, model: Partial<TastingSample>) {
        return this._http.put<TastingSample>(`${this._baseUri}/tasting-samples/${key}/events/add`, model)
                .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleUpdated(tastingSample))));
    }

    removeFromEvent(key: string, model: Partial<TastingSample>) {
        return this._http.put<TastingSample>(`${this._baseUri}/tasting-samples/${key}/events/remove`, model)
                .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleUpdated(tastingSample))));
    }

    updateImage(key: string, model: TastingSampleImagesUpdateRequest) {
        return this._http.put<TastingSample>(`${this._baseUri}/tasting-samples/${key}/image/update`, model)
                .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleUpdated(tastingSample))));
    }

    uploadImage(key:string, file: File): Observable<HttpEvent<TastingSampleImage>>{

        let data = new FormData();
        data.set('file', file);

        const req = new HttpRequest('POST', `${this._baseUri}/tasting-samples/${key}/images`, data, {
            reportProgress: true
        });

        return this._http.request<TastingSampleImage>(req)
                    .pipe(tap(result => {

                        if(result instanceof HttpResponse){
                            this._store.dispatch(new TastingSampleImageUploaded(result.body));
                        }

                    }));
    }

    delete(key: string) {
        return this._http.delete<TastingSample>(`${this._baseUri}/tasting-samples/${key}`)
                .pipe(tap(tastingSample => this._store.dispatch(new TastingSampleDeleted(tastingSample))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<TastingSample>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<TastingSample>>(`${this._baseUri}/tasting-samples`, { params });
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<TastingSample>>(`${this._baseUri}/tasting-samples/search`, {params: <any>search});
    }

    export(filter: Filter, ownerOrgKey: string, type: string = 'xlsx', detail?: DetailRequest) {
        const data = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.post<TastingSampleExportResponse>(`${this._baseUri}/tasting-samples/export`, { ...data, ownerOrgKey, type });
    }
}

export interface TastingSampleExportResponse {}