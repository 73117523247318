import { ChangeDetectionStrategy, Component, HostBinding, Input, Optional, HostListener } from "@angular/core";
import { convertDocumentFileRef, RemarkImageOption, ReportDocument, ReportImage } from "@core/data";
import { ReportBuilderView } from '../report-builder/report-builder.view';



@Component({
    selector: 'pv-report-remark',
    templateUrl: 'report-remark.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-report-remark'
    }
})
export class ReportRemarkComponent {

    @Input()
    @HostBinding('class.editable')
    editable = false;

    @HostBinding('class.has-docs')
    hasDocs = false;

    @HostBinding('class.no-print')
    noPrint = false;

    @HostBinding('class.has-images')
    hasImages = false;

    @Input()
    sectionId: string;

    @Input()
    set text(val: string) {
        this._text = val;
        if (!val) this.noPrint = true;
        else if (val.length == 0) this.noPrint = true;
        else if (val.length > 0) this.noPrint = false;
    }
    get text() {
        return this._text;
    }

    @Input()
    get documents() {
        return this._documents;
    }
    set documents(docs: ReportDocument[]){
        this._documents = docs;
        if (docs) this.hasDocs = docs.length > 0;
        else this.hasDocs = false;
    }

    @Input()
    get images() {
        return this._images;
    }
    set images(imgs: RemarkImageOption[]) {
        this._images = imgs;
        if (imgs) this.hasImages = imgs && imgs.length > 0
        else this.hasImages = false;
    }

    //Reference these values in HTML
    _images: RemarkImageOption[];
    _documents: ReportDocument[];
    _text: string;

    constructor(@Optional() private _reportBuilder: ReportBuilderView){}

    @HostListener('click')
    edit(){
        if(this._reportBuilder && this.editable) this._reportBuilder.editRemark(this.sectionId);
    }

    editDocuments() {
        if(this._reportBuilder && this.editable) this._reportBuilder.editRemarkDocuments(this.sectionId);
    }

    editImages() {
        if(this._reportBuilder && this.editable) this._reportBuilder.editRemarkImages(this.sectionId);
    }

    download(doc: ReportDocument | RemarkImageOption) {
        window.open(this.getSrc(doc.fileRef, doc.extension), "_blank");
    }

    private getSrc(ref: string, ext: string): string {
        return convertDocumentFileRef(ref, ext);
    }
}