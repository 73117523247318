import { Module } from "./_types";

export const LIB_MODULES: Module[] = [
    {
        id: "evaluation",
        name: "Evaluation Module",
        icon: "common:harvest_sample",
    },
    {
        id: "breeders",
        name: "Breeders Module",
        icon: "common:progeny_sample",
    },
    {
        id: "weather",
        name: "Weather Module",
        icon: "common:thermostat",
    },
    {
        id: "miaan",
        name: "Miaan Module",
        icon: "common:plant_sample",
    },
]



