import { Inject, Injectable, NgZone } from '@angular/core';
import { HostNavigator, LocalStorage, SessionStorage } from '@core/browser';
import { AuthService, Organization, OrganizationService, OrganizationSubscription, Status, translateCommonErrorStatus, User } from '@core/data';
import { uuid } from '@core/utils';
import { Action, NgxsAfterBootstrap, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthProfileUpdated } from './components/auth-profile/auth-profile-form.state';
import { MENU } from '@app/evaluation/menu';
import { CultevaMenu, CultevaMenuModel } from '@core/menu';
import * as moment from 'moment';

export interface AuthStateModel {
    token: string;
    instance: string;
    status: Status;
    user: User;  // logged in user
    selectedOrgKey: string; // current selected organization
    intendedUrl: string;
    menu: CultevaMenuModel;
}

export interface AuthProfileFormStateModel {
    status: string;
    data: any;
}

export interface AuthPasswordFormStateModel {
    status: string;
    data: any;
}

export class InitCultevaMenu {
    static readonly type = '[Menu] Init';
    constructor(public orgKey: string) { }
}
export class InitAuthState {
    static readonly type = '[Auth] Init';
    constructor() { }
}
// Auth Token
export class ConsumeAuthToken {
    static readonly type = '[Auth] Consume Token';
    constructor(public token: string) { }
}

export class RefreshAuthToken {
    static readonly type = '[Auth] Refresh Token';
    constructor() {}
}

export class InvalidateAuthToken {
    static readonly type = '[Auth] Invalidate Token';
    constructor() { }
}
// Intended Url
export class SetAuthIntendedUrl {
    static readonly type = '[Auth] Set Return Url';
    constructor(public url: string) { }
}


// User
export class LoadAuthUser {
    static readonly type = '[Auth] Load User';
    constructor() { }
}

export class LogoutAuthUser {
    static readonly type = '[Auth] Logout User';
    constructor() { }
}

export class InitAuthContext {
    static readonly type = '[Auth] Init Auth Context';
    constructor() { }
}
// Organization Context
export class SetOrganizationContext {
    static readonly type = '[Auth] Set Organization Context';
    constructor(public orgKey: string) { }
}

export class OverrideOrganizationContext {
    static readonly type = '[Auth] Override Organization Context';
    constructor(public org: Organization) { }
}

export class InitOrganizationContext {
    static readonly type = '[Auth] Init Organization Context';
    constructor(public org: Organization) { }
}


const DEFAULTS: AuthStateModel = {
    status: Status.UNINITIALIZED,
    token: null,
    instance: null,
    user: null,
    selectedOrgKey: null,
    intendedUrl: null,
    menu: null,
};

@State<AuthStateModel>({
    name: 'auth',
    defaults: DEFAULTS
})
@Injectable()
export class AuthState implements NgxsOnInit, NgxsAfterBootstrap {

    static readonly AUTH_INSTANCE_KEY = 'auth_instance';
    static readonly AUTH_TOKEN_KEY = 'auth_token';
    static readonly AUTH_ORG_KEY = 'auth_org_key';
    static readonly AUTH_RETURN_URL = 'auth_return_url';

    constructor(
        private _service: AuthService,
        private _orgService: OrganizationService,
        private _session: SessionStorage,
        private _local: LocalStorage,
        private _hostNav: HostNavigator,
        private _ngZone: NgZone,
        @Inject(MENU) private _menu: CultevaMenu,
    ) { }


    @Selector()
    static token(state: AuthStateModel) {
        return state.token;
    }

    @Selector()
    static instance(state: AuthStateModel) {
        return state.instance;
    }

    @Selector()
    static user(state: AuthStateModel) {
        return state.user;
    }

    @Selector()
    static status(state: AuthStateModel) {
        return state.status;
    }

    @Selector()
    static selectedOrg(state: AuthStateModel) {
        if (state.user && Array.isArray(state.user.organizations) && state.selectedOrgKey) {
            return state.user.organizations.find(org => org.key === state.selectedOrgKey);
        }
        return;
    }

    @Selector()
    static availableOrgs(state: AuthStateModel) {
        if (state.user && Array.isArray(state.user.organizations)) {
            return state.user.organizations;
        }
        return [];
    }

    @Selector()
    static subscribedModules(state: AuthStateModel) {
        if (state.user && Array.isArray(state.user.organizations) && state.selectedOrgKey) {
            //WIP
            //Using selected org object, find subscribed modules using OrganizationSubscription[]
            let subscribedModules: string[] = [];

            let selectedOrg = state.user.organizations.find(org => org.key === state.selectedOrgKey);
            selectedOrg.subscriptions.forEach(sub => { subscribedModules.push(sub.moduleRef) });

            return subscribedModules;
        }
        return [];
    }

    @Selector()
    static menu(state: AuthStateModel) {
        if (state.user && Array.isArray(state.user.organizations) && state.selectedOrgKey) {
            //WIP
            //Using subscribed modules, return menu object
            this.subscribedModules;
            return null;

        }
        return [];
    }


    /**
     *
     * @param ctx State Context
     */
    ngxsOnInit(ctx: StateContext<AuthStateModel>) {
        ctx.dispatch(new InitAuthState);
    }

    /**
     *
     * @param ctx State Context
     */
    ngxsAfterBootstrap(ctx?: StateContext<any>): void {

    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(InitAuthState)
    init(ctx: StateContext<AuthStateModel>, action: InitAuthState) {

        let storedToken = this._session.get(AuthState.AUTH_TOKEN_KEY);
        let instance = this._local.get(AuthState.AUTH_INSTANCE_KEY);
        let intendedUrl = this._session.get(AuthState.AUTH_RETURN_URL);
        let selectedOrg = this._session.get(AuthState.AUTH_ORG_KEY);

        if (!instance) {
            instance = uuid();
            this._local.set(AuthState.AUTH_INSTANCE_KEY, instance);
        }


        let menu = this._menu.generateMenu([]); //default menu

        ctx.setState({
            ...DEFAULTS,
            instance,
            intendedUrl,
            menu: menu,
            selectedOrgKey: selectedOrg,
            status: Status.UNAUTHENTICATED,
        });

        if (storedToken) {
            return ctx.dispatch([
                new ConsumeAuthToken(storedToken)
            ]);
        }
    }


    @Action(InitCultevaMenu)
    initCultevaMenu(ctx: StateContext<AuthStateModel>, action: InitCultevaMenu) {
        const state = ctx.getState();

        return this._orgService.listSubscriptions(action.orgKey)
            .pipe(
                tap(subscriptions => {
                    let now = moment();

                    //Check if subscription is still active, and set isActive
                    subscriptions.forEach(sub => (moment(sub.startAt) <= now && moment(sub.endAt) > now) ? sub.isActive = true : sub.isActive = false);

                    ctx.setState({
                        ...state,
                        menu: this._menu.generateMenu(subscriptions.map(sub => sub.isActive ? sub.moduleRef : null))
                    })
                })
            )
    }

    /**
     *
     * @param ctx State Context
     * @param action url: Return url
     */
    @Action(SetAuthIntendedUrl)
    setAuthReturnUrl(ctx: StateContext<AuthStateModel>, action: SetAuthIntendedUrl) {

        this._session.set(AuthState.AUTH_RETURN_URL, action.url);

        ctx.patchState({
            intendedUrl: action.url
        });
    }


    /**
     *
     * @param ctx State Context
     * @param action token: consume token
     */
    @Action(ConsumeAuthToken)
    consumeAuthToken(ctx: StateContext<AuthStateModel>, action: ConsumeAuthToken) {
        ctx.patchState({
            token: action.token,
            status: Status.UNAUTHENTICATED
        });

        this._session.set(AuthState.AUTH_TOKEN_KEY, action.token);

        return ctx.dispatch([new InitAuthContext(), new LoadAuthUser()]);
    }

    @Action(RefreshAuthToken)
    refreshAuthToken(ctx: StateContext<AuthStateModel>, action: RefreshAuthToken){

        return this._service.refreshToken()
            .pipe(
                tap(token => {

                    this._session.set(AuthState.AUTH_TOKEN_KEY, token.access_token);

                    ctx.patchState({
                        token: token.access_token
                    });

                }),
                catchError(err => {
                    console.warn("AuthState: Error refreshing token", err);
                    return of(null);
                })
            );
    }

    /**
     *
     * @param ctx State Cotext
     * @param action No action required
     */
    @Action(InvalidateAuthToken)
    InvalidateAuthToken(ctx: StateContext<AuthStateModel>, action: InvalidateAuthToken) {
        this._session.remove(AuthState.AUTH_TOKEN_KEY);
        ctx.patchState({
            token: null,
            status: Status.UNAUTHENTICATED,
        });
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LogoutAuthUser)
    logoutAuthUser(ctx: StateContext<AuthStateModel>, action: LogoutAuthUser) {
        this._session.remove(AuthState.AUTH_TOKEN_KEY);
        this._session.remove(AuthState.AUTH_ORG_KEY);
        this._session.remove(AuthState.AUTH_RETURN_URL);

        this._hostNav.redirect('logout');
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LoadAuthUser, { cancelUncompleted: true })
    loadUser(ctx: StateContext<AuthStateModel>, action: LoadAuthUser) {

        ctx.patchState({
            user: null,
            status: Status.LOADING,
        });

        return this._service.getAuthUser()
            .pipe(
                tap(user => {

                    const state = ctx.getState();

                    ctx.patchState({
                        user: user,
                        status: Status.OK
                    });

                    if (Array.isArray(user.organizations) && user.organizations.length > 0) {

                        let selectedOrgKey = null;

                        let org = user.organizations.find(org => org.key === state.selectedOrgKey);

                        if (org) {
                            selectedOrgKey = org.key;
                        } else {
                            selectedOrgKey = user.organizations[0].key;
                        }

                        ctx.dispatch(new SetOrganizationContext(selectedOrgKey));

                    } else {
                        ctx.dispatch(new SetOrganizationContext(null));
                    }

                }),
                catchError(e => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(e)
                    });

                    return of(null);
                }),
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action orgKey: key of the organization to set the organization context to
     */
    @Action(SetOrganizationContext)
    setOrganizationContext(ctx: StateContext<AuthStateModel>, action: SetOrganizationContext) {

        let state = ctx.getState();

        if (state.selectedOrgKey === action.orgKey) return;

        if (action.orgKey) {
            ctx.patchState({
                selectedOrgKey: action.orgKey
            });
            this._session.set(AuthState.AUTH_ORG_KEY, action.orgKey);
        } else {
            ctx.patchState({
                selectedOrgKey: action.orgKey
            });
            this._session.remove(AuthState.AUTH_ORG_KEY);
        }

        let org = AuthState.selectedOrg(ctx.getState());
        return ctx.dispatch(new InitOrganizationContext(org));
    }

    /**
     *
     * @param ctx State Context
     * @param action org: org to override current context
     */
    @Action(OverrideOrganizationContext)
    overrideOrganizationContext(ctx: StateContext<AuthStateModel>, action: OverrideOrganizationContext) {

        let org = action.org;

        ctx.patchState({
            selectedOrgKey: org.key,
        });

        return ctx.dispatch(new InitOrganizationContext(org));
    }

    /**
     *
     * @param ctx State Context
     * @param action org: the org to set the organization context
     */
    @Action(InitOrganizationContext)
    initOrganizationContext(ctx: StateContext<AuthStateModel>, action: InitOrganizationContext) {
        // console.info("AuthState: InitOrganizationContext");
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(InitAuthContext)
    initInitAuthContext(ctx: StateContext<AuthStateModel>, action: InitAuthContext) {
        // console.info("AuthState: InitAuthContext");
    }

    /**
     *
     * @param ctx State Context
     * @param action user: user that has been updated
     */
    @Action(AuthProfileUpdated)
    authProfileUpdated(ctx: StateContext<AuthStateModel>, action: AuthProfileUpdated) {
        return ctx.dispatch(new LoadAuthUser());
    }
}