
import { Dictionary } from '../dictionary';
import { LIB_TASTINGS_EVAL_BASE } from './tastings_base_scorecard';
import { LIB_TASTINGS_EVAL_STONEFRUIT } from './tastings_stonefruit_scorecard'
import { LIB_TASTINGS_EVAL_BASE_DE } from './tastings_base_scorecard_de';
import { LIB_TASTINGS_EVAL_STONEFRUIT_DE } from './tastings_stonefruit_scorecard_de';
import { LIB_TASTINGS_EVAL_BERRY } from './tastings_berry_scorecard';
import { LocaleTastingEvaluation, TastingEvaluation } from './_types';


export class TastingEvaluationDictionary extends Dictionary<TastingEvaluation>{
    constructor(){
        super([
            LIB_TASTINGS_EVAL_BASE,
            LIB_TASTINGS_EVAL_BERRY,
            LIB_TASTINGS_EVAL_STONEFRUIT
        ], false);
    }
}

export class LocaleTastingEvaluationDictionary extends Dictionary<LocaleTastingEvaluation> {
    constructor() {
        super([
            LIB_TASTINGS_EVAL_BASE_DE,
            LIB_TASTINGS_EVAL_STONEFRUIT_DE
        ], false);
    }
}

export * from './_types';
