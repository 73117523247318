import { Component, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";

import * as commonmark from 'commonmark';

@Component({
    selector: 'pv-markdown',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '<div class="markdown" [innerHtml]="html"></div>',
    host:{
        class: 'pv-markdown'
    },
    styles: [
        `:host{display: block}`
    ]
})
export class MarkdownComponent {

    _markdown: string;
    html: string = '';

    reader = new commonmark.Parser();
    writer = new commonmark.HtmlRenderer({safe: true});

    @Input()
    set markdown(markdown: string){

        if(!markdown){
            this.html = '';
            return;
        }

        if(this._markdown !== markdown){
            this._markdown = markdown;
            this.html = this.writer.render(this.reader.parse(markdown));
        }

    }


}