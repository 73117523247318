<mat-toolbar [color]="toolbarColor" class="view-toolbar mat-elevation-z2">
    <mat-toolbar-row  class="toolbar-header">
        <button mat-icon-button (click)="home()" type="button">
            <mat-icon>menu</mat-icon>
        </button>

        <h1 class="toolbar-title">{{ title }}</h1>

    </mat-toolbar-row>
    <mat-toolbar-row class="toolbar-tools">
        <ng-content select="[pv-view-search]"></ng-content>
        <ng-content select="[pv-view-tools]"></ng-content>
    </mat-toolbar-row>
</mat-toolbar>

<ng-content select="[pv-view-header]"></ng-content>

<div class="view-print-tools-spacer" *ngIf="printMode"></div>

<div class="view-body">
    <ng-content select="[pv-view-sidebar]"></ng-content>
    <ng-content select="[pv-view-body]"></ng-content>
</div>

<div class="view-fabs" *ngIf="!printMode">
    <ng-content select="[pv-view-fab]"></ng-content>
</div>

<ng-content select="[pv-view-footer]" ></ng-content>

<mat-toolbar class="view-print-tools mat-elevation-z2" *ngIf="printMode">

    <button mat-flat-button (click)="togglePrintMode()">
        Close Preview
    </button>
    <button mat-flat-button color="primary" (click)="print()">
        Print
    </button>
</mat-toolbar>