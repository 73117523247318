<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Measurements</h2>
        <h3 class="dialog-subtitle">
            Sample {{ state.data ? state.data.sample.code : '' }},
            evaluation {{ state.data ? state.data.label : '' }}
        </h3>
    </div>
    <div mat-dialog-content>

        <form id="measurements_form"
              class="measures-form"
              [formGroup]="formGroup"
              #measurementsForm="ngForm"
              (ngSubmit)="attempt()"
              autocomplete="disabled"
              novalidate>
            <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">

                <!-- Tabs (for each crop subject) -->
                <mat-tab *ngFor="let tab of tabs; trackBy: trackTab"
                         [disabled]="state.status === 'LOADING'">

                    <ng-template mat-tab-label>
                        <span
                              [class.ng-invalid]="formGroup.get(tab.subject.id).invalid">{{tab.subject.label}}</span>
                    </ng-template>

                    <ng-template matTabContent>

                        <div class="measures-form-table" [formGroupName]="tab.subject.id">

                            <!-- Index Column -->
                            <div class="measures-form-column measures-index-column">
                                <div class="measures-form-header">
                                    Index
                                    <div class="measure-unit">#</div>
                                </div>
                                <div *ngFor="let index of tab.indexes;" class="measures-form-index">
                                    {{ index + 1 }}
                                </div>
                            </div>

                            <!-- Measurement Columns (for each measure in subject) -->
                            <div *ngFor="let measure of tab.measures; let i = index"
                                 class="measures-form-column"
                                 [formArrayName]="measure.id">

                                <!-- Header Cell -->
                                <div class="measures-form-header">
                                    {{ measure.label }}
                                    <div class="measure-unit">
                                        {{measure.min}} - {{ measure.max }} {{ measure.unit }}
                                    </div>
                                </div>

                                <!-- Input Cells (for each tabIndex in subject) -->
                                <div *ngFor="let tabIndex of tab.indexes" class="measures-form-field">
                                    <input type="text"
                                           [name]="measure.id + '_' + tabIndex"
                                           [formControlName]="tabIndex"
                                           autocomplete="off"
                                           id="c_{{i}}_r_{{tabIndex}}">
                                </div>

                            </div>
                        </div>

                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </form>

    </div>

    <pv-form-actions mat-dialog-actions
                     [form]="measurementsForm"
                     [status]="state.status"
                     (cancel)="cancel()">

        <!-- Add Row -->
        <button pvFormAction
                mat-icon-button
                matTooltip="Add Row"
                (click)="increaseSize(tabGroup.selectedIndex)"
                color="accent"
                [disabled]="formGroup.disabled">
            <mat-icon>add_circle</mat-icon>
        </button>

        <!-- Remove Row -->
        <button pvFormAction
                mat-icon-button
                matTooltip="Remove Row"
                (click)="decreaseSize(tabGroup.selectedIndex)"
                color="accent"
                [disabled]="formGroup.disabled || this.getRowCount(tabGroup.selectedIndex) == 0">
            <mat-icon>remove_circle</mat-icon>
        </button>

        <!-- Upload File -->
        <button pvFormAction
                mat-flat-button
                (click)="openFileUploadDialog()"
                color="accent"
                matTooltip="Upload Measurements File"
                [disabled]="formGroup.disabled">
            Upload File
        </button>

    </pv-form-actions>

</ng-container>
