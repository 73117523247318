import { DetailRequest, FilterBuilder, FilterQueryMode, Status, translateCommonErrorStatus } from '@core/data';
import { ExportHistory, ExportType } from '../../../../core/data/types/export-history';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ExportHistoryService } from '../../../../core/data/api/export-history.service';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface ExportHistoryFormStateModel {
    // organization context
    orgKey: string;
    // model type
    type: ExportType;
    sampleType: string;

    status: Status;
    data: ExportHistory[];
    total: number;

    query: ExportHistoryQuery;
}

export interface ExportHistoryQuery {
    search: string;
}

const DEFAULTS: ExportHistoryFormStateModel = {
    orgKey: null,
    type: null,
    sampleType: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    query: {
        search: null,
    }
}

export class InitExportHistoryForm {
    static readonly type = "[ExportHistoryForm] Init";
    constructor(public orgKey: string, public type: ExportType, public sampleType?: string) {}
}

export class LoadExportHistory {
    static readonly type = "[ExportHistoryForm] Load";
    constructor() {}
}

export class QueryExportHistory {
    static readonly type = "[ExportHistoryForm] Query";
    constructor(public query: ExportHistoryQuery) {}
}

@State<ExportHistoryFormStateModel>({
    name: "export_history_form",
    defaults: DEFAULTS
})
@Injectable()
export class ExportHistoryFormState {

    @Selector()
    static query(state: ExportHistoryFormStateModel) {
        return state.query;
    }

    constructor(
        private _exportHistoryService: ExportHistoryService,
    ) {}

    @Action(InitExportHistoryForm)
    initExportHistoryForm(ctx: StateContext<ExportHistoryFormStateModel>, action: InitExportHistoryForm) {
        ctx.patchState({
            orgKey: action.orgKey,
            type: action.type,
            sampleType: action.sampleType ? action.sampleType : null,
            data: [],
            total: 0,
        });

        return ctx.dispatch(new LoadExportHistory);
    }

    @Action(LoadExportHistory)
    loadExportHistory(ctx: StateContext<ExportHistoryFormStateModel>, action: LoadExportHistory) {
        const state = ctx.getState();

        if (!state.orgKey) console.warn("Attempting to load ExportHistory without organization key");

        ctx.patchState({
            status: Status.LOADING,
        });

        const detail: DetailRequest = {
            related: [
                'user',
            ]
        }

        return this._exportHistoryService.query(this.getFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total === 0) {
                            ctx.patchState({
                                status: Status.EMPTY,
                                total: 0,
                                data: []
                            });
                            return;
                        }

                        ctx.patchState({
                            status: Status.OK,
                            data: result.data,
                            total: result.total
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(QueryExportHistory)
    queryExportHistory(ctx: StateContext<ExportHistoryFormStateModel>, action: QueryExportHistory) {
        ctx.patchState({
            query: action.query,
        })

        return ctx.dispatch(new LoadExportHistory);
    }

    private getFilter(state: ExportHistoryFormStateModel) {
        const fb = (new FilterBuilder());

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (typeof state.query.search === 'string' && state.query.search.length > 0){
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        if (state.type) fb.setQuery('model', state.type, FilterQueryMode.EQUALS);
        if (state.sampleType) fb.setQuery('sampleType', state.sampleType, FilterQueryMode.EQUALS);

        return fb.get();
    }
}
