import { Measurement } from "./_types";



export const LIB_MEASURES_BERRY_HARVEST: Measurement[] = [

    // Plant Sample Measurements and Counts
    {
        id: "berry_len",
        unit: "mm",
        label: "Berry Length",
        tag: "BLN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_len_i1",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "berry_dia",
        unit: "mm",
        label: "Berry Diameter",
        tag: "BDI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_dia_i1",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "berry_wei",
        unit: "g",
        label: "Berry Weight",
        tag: "BWE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_wei_i1",
        format: "####g",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 999,
        subjectId: "berry",
    },
    {
        id: "berry_tss",
        unit: "%",
        label: "TSS",
        tag: "TSS",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_tss_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "berry_firmness",
        unit: "kg",
        label: "Berry Firmness",
        tag: "FIR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_fir_i1",
        format: "###0.00kg",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "berry_acid",
        unit: "%",
        label: "Fruit Acidity (Malic)",
        tag: "TMA",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_bery_tta_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "berry",
    },


];