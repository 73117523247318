import { Component, Input } from '@angular/core';
import { HostNavigator } from '@core/browser';
import { Dialog } from '@core/material';
import { BarcodeDialogComponent } from './barcode-dialog.component';



@Component({
    selector: 'pv-barcode-image',
    template: `
        <img [src]="src" style="width: 100%;" (click)="open()">
    `,
})
export class BarcodeImageComponent {

    private _code: string;
    src: string;
    @Input() set code(code: string) {
        this._code = code;
        this.src = this._hostNav.hostUrl('barcode/image/' + encodeURIComponent(code) + '.svg');
    }

    constructor(private _hostNav: HostNavigator, private _dialog: Dialog) { }

    open() {
        let dialog = this._dialog.open(BarcodeDialogComponent, {
            disableClose: false
        });
        dialog.componentInstance.code = this._code;
    }

}