import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { OrganizationSubscriptionHandler } from "@core/data/globals/subscriptions";
import { Store } from "@ngxs/store";

enum PathType {
    TASTINGS = 'tastings',
    EVALUATION = 'evaluation'
}

@Injectable()
export class SubscriptionGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _store: Store,
        private _subscriptionHandler: OrganizationSubscriptionHandler,
    ) {}

    private tastingsPaths: string[] = [
        'org/:orgKey/tastings-samples',
        'org/:orgKey/tastings-events',
        'org/:orgKey/tastings-contacts',
        'org/:orgKey/tastings-scores/:type/:subjectKey',
        'org/:orgKey/tastings-indexes',
        'org/:orgKey/tastings-tags',
        'org/:orgKey/tastings-weighted-chars',
    ];

    private evaluationPaths: string[] = [
        'org/:orgKey/samples/:type',
        'org/:orgKey/samples/:type/:sampleKey',
    ];

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let pathType = this.getPathType(route.routeConfig.path);

        if (pathType === PathType.TASTINGS) return this.handleTastingsPath();
        if (pathType === PathType.EVALUATION) return this.handleEvaluationPath();
        return false;
    }

    private handleTastingsPath(): boolean {
        return this._subscriptionHandler.isSubscribedToTastings();
    }

    private handleEvaluationPath(): boolean {
        return this._subscriptionHandler.isSubscribedToEvaluation();
    }

    private getPathType(path: string): string {
        if (this.tastingsPaths.includes(path)) return PathType.TASTINGS;
        if (this.evaluationPaths.includes(path)) return PathType.EVALUATION;
    }
}