<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>{{ state.key ? 'Edit' : 'Add' }}&nbsp;Tasting Event</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_event_form"
              #tastingEventsForm="ngForm"
              [formGroup]="formGroup"
              class="pv-form"
              (ngSubmit)="attempt()"
              novalidate
              autocomplete="no">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" required>
                    <mat-hint>Title of the event</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="4" formControlName="description"
                        style="resize: none;"></textarea>
                    <mat-hint>Describe the tasting event</mat-hint>
                    <mat-error>Description max 255 chars</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Venue</mat-label>
                    <input matInput formControlName="venueName" required>
                    <mat-hint>Name of the venue for the event</mat-hint>
                    <mat-error>Max characters is 64</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Alternative Venue</mat-label>
                    <input matInput formControlName="altVenueName">
                    <mat-hint>Name of the alternative venue for the event</mat-hint>
                    <mat-error>Max characters is 64</mat-error>
                </mat-form-field>

                <div *ngIf="state?.key">
                    <mat-checkbox appearance="standard" formControlName="allowIndexUpdates">
                        Allow Index Updates
                    </mat-checkbox>
                    <p class="index-update-subtext">Note that substantial changes to the Index can have adverse effects on existing evaluations.</p>
                </div>

                <mat-form-field appearance="standard">
                    <mat-label>Index</mat-label>
                    <mat-select formControlName="indexKey" required>
                        <mat-option
                            *ngIf="shouldShow(state?.data?.indexSnapshot?.index, data.indexOptions) && state?.data?.indexSnapshot?.index as index"
                            [value]="index.key"
                            disabled>
                            <span>{{ index.title }}</span>
                        </mat-option>
                        <mat-option *ngFor="let index of data.indexOptions" [value]="index.key">
                            <span>{{ index.title }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Date & Time</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Date</mat-label>
                    <input matInput formControlName="eventDate" [matDatepicker]="eventDatepicker" required>
                    <mat-datepicker-toggle matSuffix [for]="eventDatepicker"></mat-datepicker-toggle>
                    <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                    <mat-hint>Date of the event [YYYY-MM-DD]</mat-hint>
                    <mat-datepicker #eventDatepicker startView="multi-year"></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Start Time</mat-label>
                    <input matInput type="time" formControlName="eventStartTime">
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>End Time</mat-label>
                    <input matInput type="time" formControlName="eventEndTime">
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Address</h4>

                <!-- STREET -->
                <mat-form-field>
                    <input matInput type="text" formControlName="venueAddrStreet"
                           placeholder="Street Address">
                    <mat-error>Max characters is 64</mat-error>
                </mat-form-field>

                <!-- CITY -->
                <mat-form-field>
                    <input matInput type="text" formControlName="venueAddrCity" placeholder="City">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- REGION -->
                <mat-form-field>
                    <input matInput type="text" formControlName="venueAddrRegion" placeholder="Region">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- CODE -->
                <mat-form-field>
                    <input matInput type="text" formControlName="venueAddrCode"
                           placeholder="Postal Code">
                    <mat-error>Max characters is 16</mat-error>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Aternative Address</h4>

                <!-- STREET -->
                <mat-form-field>
                    <input matInput type="text" formControlName="altVenueAddrStreet"
                           placeholder="Street Address">
                    <mat-error>Max characters is 64</mat-error>
                </mat-form-field>

                <!-- CITY -->
                <mat-form-field>
                    <input matInput type="text" formControlName="altVenueAddrCity" placeholder="City">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- REGION -->
                <mat-form-field>
                    <input matInput type="text" formControlName="altVenueAddrRegion" placeholder="Region">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- CODE -->
                <mat-form-field>
                    <input matInput type="text" formControlName="altVenueAddrCode"
                           placeholder="Postal Code">
                    <mat-error>Max characters is 16</mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingEventsForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>