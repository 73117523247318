import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportSharedPreviewState, ReportSharedPreviewStateModel, InitSharedPreview, LoadReportSharedPreview } from './report-shared-preview.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ViewComponent } from '@core/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'pv-report-shared-preview-view',
    templateUrl: "report-shared-preview.view.html",
    styleUrls: ['report-shared-preview.view.scss'],
    host: {
        class: 'pv-report-shared-preview-view'
    }
})
export class ReportSharedPreviewView implements OnInit {

    @Select(ReportSharedPreviewState)
    state$: Observable<ReportSharedPreviewStateModel>;

    @ViewChild(ViewComponent, { static: true })
    viewComponent: ViewComponent;

    dummyFormControl: FormControl = new FormControl([]);;

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _router: Router,
    ){}

    ngOnInit() {

        // Subscribes to the route params and sends the report key to the state to populate the preview
        this._route.paramMap.subscribe(params => {
            this._store.dispatch(new InitSharedPreview(params.get('shareKey')));
        });
    }

    // Refreshes the state
    refresh() {
        this._store.dispatch(new LoadReportSharedPreview());
    }

}