import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DuplicateWeatherDataError, Status, translateCommonErrorStatus, UploadReadingsFormData, WeatherStationService } from '@core/data';
import { Action, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface WeatherReadingsUploadStateModel {
    key: string;
    status: Status;
    progress: number;
    duplicateDataError: boolean;
}

export class InitWeatherReadingsUpload {
    static readonly type = "[WeatherReadingsUpload] Init";
    constructor(public key: string) { }
}

export class SubmitWeatherReadingsUpload {
    static readonly type = "[WeatherReadingsUpload] Submit";
    constructor(public data: UploadReadingsFormData) { }
}

export class CompleteWeatherReadingsUpload {
    static readonly type = "[WeatherReadingsUpload] Complete";
    constructor(public key: string) { }
}


@State<WeatherReadingsUploadStateModel>({
    name: "weather_readings_upload",
    defaults: {
        key: null,
        status: Status.UNINITIALIZED,
        progress: null,
        duplicateDataError: false,
    }
})
@Injectable()
export class WeatherReadingsUploadState {


    constructor(private _weatherService: WeatherStationService) { }


    @Action(InitWeatherReadingsUpload)
    initWeatherReadingsUpload(ctx: StateContext<WeatherReadingsUploadStateModel>, action: InitWeatherReadingsUpload) {
        ctx.setState({
            key: action.key,
            status: Status.OK,
            progress: 0,
            duplicateDataError: false
        });
    }

    @Action(SubmitWeatherReadingsUpload)
    submitWeatherReadingsUpload(ctx: StateContext<WeatherReadingsUploadStateModel>, action: SubmitWeatherReadingsUpload) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
            progress: 0,
            duplicateDataError: false
        });

        return this._weatherService.uploadReadings(state.key, action.data)
            .pipe(
                tap(event => {


                    if (event === null) return;

                    const state = ctx.getState();

                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            // Update state.fileUpload.uploadProgress == percentDone
                            const percentDone = Math.round(100 * event.loaded / event.total);

                            ctx.patchState({
                                progress: percentDone,
                                status: Status.LOADING
                            });
                            break;
                        case HttpEventType.Response:
                            if (event.statusText === "OK") {

                                ctx.patchState({
                                    progress: 100,
                                    status: Status.COMPLETE
                                });

                                ctx.dispatch(new CompleteWeatherReadingsUpload(state.key));

                            }
                            break;
                    }
                }),
                catchError(error => {

                    if (error instanceof DuplicateWeatherDataError) {
                        ctx.patchState({
                            status: Status.ERROR,
                            progress: 0,
                            duplicateDataError: true
                        });
                        return throwError(error);
                    }

                    ctx.patchState({
                        status: translateCommonErrorStatus(error),
                        progress: 0,
                        duplicateDataError: false
                    });
                    return throwError(error);
                })
            );

    }



}