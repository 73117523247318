
export function parseDuration(value: string): { time: number, unit: 'd' | 'w' | 'm' } {

    if (typeof value === 'string') {
        let matches = value.match(/^([0-9]+)([dwm])$/);
        if (matches.length === 3) {
            return { time: parseInt(matches[1]), unit: <'d' | 'w' | 'm'>matches[2] };
        }
    }

    return null;

}

