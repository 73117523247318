import { Protocol } from './_types';

export const LIB_PROTOCOL_CANNABIS_HARVEST_SCREEN_EVAL: Protocol = {
    id: "cannabis_harvest_screen_eval",
    title: "Advanced Medical & Recreational Cannabis Harvest Evaluation",
    description: "Harvest evaluation is performed to characterise new cultivars and selections on the base of their fruit traits. Visual and sensory fruit evaluation are applied to describe bunch and berry quality and keeping ability after storage over a specified storage period. Characteristics such as appearance, defects and disorders and eating quality are evaluated in detail.",
    sampleType: "harvest",
    crop: "cannabis",

    chars: [

    ],

    measures: [
        //Yield
        "plant_wet_yield", //
        "plant_dry_yield", //
        "plant_flower_yield_trimmed", //
        "plant_flower_yield_trimmed_untrimmed", //
        "plant_trim_yield", //
        "plant_stem_yield", //

        //Cannabinoids
        "plant_thc_d8", //
        "plant_thc_d9", //
        "plant_thcv", //
        "plant_cbd", //
        "plant_cbdv", //
        "plant_cbl", //
        "plant_cbc", //
        "plant_cbg", //
        "plant_cbn", //
        "plant_total_cann", //

        //Terpenes
        "plant_terpene_limonene", //
        "plant_terpene_borneol", //
        "plant_terpene_bisabolol_alpha", //
        "plant_terpene_pinene_alpha", //
        "plant_terpene_pinene_beta", //
        "plant_terpene_linalool", //
        "plant_terpene_humulene", //
        "plant_terpene_carophyllene", //
        "plant_terpene_myrcrene", //
        "plant_terpene_terpinolene", //
        "plant_terpene_terpinene", //
        "plant_terpene_eucalyptol", //
        "plant_terpene_camphene", //
        "plant_terpene_cymene", //
        "plant_terpene_isopulegol", //
        "plant_terpene_valencene", //
        "plant_terpene_caryophyllene_oxide", //
        "plant_terpene_geraniol", //
        "plant_terpene_terpineol_alpha", //
        "plant_terpene_ocamene_alpha", //
        "plant_terpene_ocamene_beta", //
        "plant_total_terpenes", //
    ],

    indexes: [],
};