import { Cultivar, FilterPage, FilterSort, Sample, Site, Status } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { TastingEvent } from "@core/data/types/tastings-event";
import { TastingsSampleLabelOptions } from "../tastings-sample-labels/tastings-sample-label-options";

export interface TastingSampleIndexStateModel {
    //org context
    orgKey: string;

    //table data
    status: Status;
    data: TastingSample[];
    total: number;

    //table options
    displayColumns: string[];
    sortOptions: { id: string, label: string }[];
    pageSizeOptions: number[];

    //filter options
    page: FilterPage;
    sort: FilterSort;
    query: TastingSampleIndexQuery;
    //filter data
    licenseeOptions: string[];

    //selection
    selectedKeys: string[];
    selectedData: TastingSample[];
    tastingEventOptions: TastingEvent[];

    //export result
    exports: {
        key: string;
        status: Status
    }[];

    //barcode/label config
    labelOptions: TastingsSampleLabelOptions;
}

export interface TastingSampleIndexQuery {
    search: string;
    sites: Site[];
    scionCultivars: Cultivar[];
    scionLicensee: string[];
    harvestSample: Sample[];
    tastingsEvent: TastingEvent[];
    scionCropId: string[];
}

export const TASTING_SAMPLE_SORT_OPTIONS: { id: string, label: string }[] = [
    // { id: 'harvestSample.birthDate', label: 'Harvest Date' },
    { id: 'code', label: 'Tasting Sample Code' },
    { id: 'label', label: 'Tasting Sample Label' },
    { id: 'tastingsEventKey', label: 'Tastings Event Key'},
    // { id: 'scionCultivarKey', label: 'Scion Cultivar' },
    { id: 'harvestSampleKey', label: 'Harvest Sample' },
    { id: 'updatedAt', label: 'Updated Date' },
    { id: 'createdAt', label: 'Created Date' },
];

export const TASTING_SAMPLE_DISPLAY_COLUMNS = [
    'image',
    'code',
    'actions',
    'birthDate',
    'harvestSamples',
    'tastingsEvents',
    'size',
    'timestamps',
];

export const LABEL_OPTIONS: TastingsSampleLabelOptions = {
    layout: 'land',
    size: 'md',
    includeFields: ['label', 'eventTitle', 'venueName'],
    margin: '1mm',
    whitespace: true
};

export const DEFAULTS: TastingSampleIndexStateModel = {
    orgKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 20
    },
    sort: {
        column: 'updatedAt',
        order: 'desc'
    },
    displayColumns: TASTING_SAMPLE_DISPLAY_COLUMNS,
    sortOptions: TASTING_SAMPLE_SORT_OPTIONS,
    pageSizeOptions: [20,50,100,500,1000],
    query: {
        search: null,
        sites: [],
        scionCultivars: [],
        harvestSample: [],
        tastingsEvent: [],
        scionCropId: [],
        scionLicensee: [],
    },
    licenseeOptions: [],
    labelOptions: LABEL_OPTIONS,
    exports: [],
    selectedKeys: [],
    selectedData: [],
    tastingEventOptions: [],
};