import { Component, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";
import { Cultivar } from '@core/data';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';


@Component({
    selector: '[pvCultivarLine]',
    template: `
        <mat-icon *ngIf="_cropIcon" [svgIcon]="_cropIcon"></mat-icon>
        <mat-icon *ngIf="_graftIcon" class="graft-icon" [svgIcon]="_graftIcon"></mat-icon>
        <span class="text" [innerHtml]="_html"></span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-cultivar-line pv-model-line'
    }
})
export class CultivarLineComponent {

    @Input('pvCultivarLine')
    set cultivar(cultivar: Cultivar){
        this.getCultivarDescription(cultivar)
    }

    @Input('pvCultivarChip')
    set chipCultivar(cultivar: Cultivar){
        this.getCultivarDescription(cultivar, true)
    }

    @Input()
    set graft(graft: string){
        switch(graft){
            case 'scion':
                this._graftIcon = 'common:flat_scion';
                break;
            case 'rootstock':
                this._graftIcon = 'common:flat_rootstock';
                break;
            default:
                this._graftIcon = null;
        }
    }

    @HostBinding('attr.title')
    _title: string;

    _graftIcon: string = null;
    _cropIcon: string = null;
    _html: string;

    constructor(private _formatter: SampleFormatter){}

    private getCultivarDescription(cultivar: Cultivar, isChip: boolean = false) {
        if (cultivar) {
            this._html = this._formatter.describeCultivar(cultivar, true, isChip);
            this._title = this._formatter.describeCultivar(cultivar, true, isChip);
            this._cropIcon = 'common:' + cultivar.cropId;
        } else {
            this._html = '';
            this._cropIcon = null;
        }
    }


}