import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LIBRARY } from '@app/evaluation/library';
import { ReportThemeCompiler } from '@app/evaluation/themes.service';
import { Evaluation, EvaluationService, ImageReportOptions, NoteReportOptions, RemarkImageOption, Report, ReportDocument, ReportImage, ReportImageOption, ReportLetterhead, ReportOptions, ReportSample, ReportSampleEvalTimelineOptions, Sample, SampleCharacteristic, SampleImage, TableReportColumnType } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { Library, Protocol } from '@library';
import { Store } from '@ngxs/store';
import { pipe, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ReportCompilerService } from '../../report-compiler.service';
import { CompiledCharacteristicIndexSummaryReport, CompiledCharacteristicSummaryReport, CompiledLegend, CompiledInfoSheetReport, CompiledMeasurementSummaryReport, CompiledTimelineReport, CompiledTableReport, CompiledWeatherReport, Series } from '../../report-compiler.types';
import { ImageGalleryDialog, ImageGalleryDialogData } from '../evaluation-detail/image-gallery.dialog';
import { ReportBuilderView } from '../report-builder/report-builder.view';
import { mergeDefaultReportOptions } from '../report-builder/report-defaults';
import { CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { fileExtension } from '@core/utils';
import * as moment from 'moment';
import { SamplePrimaryImageFormDialog, SamplePrimaryImageFormDialogData } from '../sample-form/sample-primary-image-form.dialog';

@Component({
    selector: 'pv-report',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    templateUrl: 'report.component.html',
    host: {
        '[class]': "themeId+'-theme pv-report'"
    }
})
export class ReportComponent implements OnInit, OnDestroy {

    @Input()
    @HostBinding('class.editable')
    editable = false;

    @Input()
    @HostBinding('class.print')
    print = false;

    printChartRatio = 2;

    @Input()
    set report(report: Report) {
        if (report && this._report !== report) {
            this._report = report;
            this._update$.next();
        }
    }

    @Input()
    formControlPassthrough: FormControl;

    @Input()
    infoSheetImagesTemp: ReportImageOption[][];

    tableReportColumnType = TableReportColumnType;

    _report: Report;
    _samples: ReportSample[];
    _charSummary: ReportSample[];
    sectionsOrder = '';

    // compiled things
    title: string;
    description: string;
    toggleDescription = false;
    themeId: string;
    showEmptyFields = false;
    showStorageRegime = false;
    showLegend = true;
    letterheadRef: string;
    letterhead: ReportLetterhead;
    letterheadKey: string;

    theme: ReportThemeCompiler;
    series: Series[];
    legends: CompiledLegend[];
    timelineReport: CompiledTimelineReport;
    lowestStartDate: moment.Moment;
    highestEndDate: moment.Moment;
    charSummary: CompiledCharacteristicSummaryReport;
    measureSummary: CompiledMeasurementSummaryReport;
    charIndexSummary: CompiledCharacteristicIndexSummaryReport;
    weatherReports: CompiledWeatherReport[];
    tableReports: CompiledTableReport[];
    infoSheetReport: CompiledInfoSheetReport;
    mappedRemarks: { [key: string]: string; } = {};
    remarkDocuments: { [key: string]: ReportDocument[]; } = {};
    remarkImages: { [key: string]: ReportImage[]; } = {};
    remarkImageOptions: { [key:string]: RemarkImageOption[]; } = {};
    noteOptions: NoteReportOptions;
    imageOptions: ImageReportOptions;
    allImages: SampleImage[];
    tonnesPerHaAvg: number;
    sugarAcidRatioAvg: number;
    sugarCitrusAcidRatioAvg:number;
    bunchSugarAcidRatioAvg: number;
    imageSectionPosition: number = null;
    imageSectionImages: ReportImageOption[][] = [];

    private _destroy$ = new Subject();
    private _update$ = new Subject();

    DEFAULT_SEQUENCE = { "Specifications": 1, "StorageRegime": 2, "Timeline": 3, "Weather": 4, "Tables":5, "InfoSheet":6, "Characteristics":7, "Measurements":8, "Indexes":9, "Images":10, "Notes":11 }

    sequence:any = {};

    constructor(
        private _compiler: ReportCompilerService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialog: Dialog,
        private _store: Store,
        @Optional() private _reportBuilder: ReportBuilderView,
        @Inject(LIBRARY) private _library: Library,
        private _evalService: EvaluationService,
        private _snackbar: Snackbar,
    ) { }

    ngOnInit(): void {
        this._update$
            .pipe(debounceTime(500))
            .subscribe(() => this.updateReport());

        this.updateReport();

    }

    ngOnDestroy() {
        this._update$.complete();
        this._destroy$.next();
        this._destroy$.complete();
    }

    updateSectionsSequence() {
        if(this._report.options.sectionsOrder) {
            let parts = this._report.options.sectionsOrder.split(';');
            this.sequence = {};

            parts.forEach((part, index) => {
                this.sequence[part] = index+1;
            });
        }
    }

    setReportBuilderProtocol() {
        // Get the protocol IDs from the series
        let protolIds = this.series.map(serie => serie.protocolId);

        // Initialize a Set to collect unique valid charIds
        let protocolCharSet = new Set<string>();

        // Iterate through each series and its chars
        this.series.forEach(serie => {
            if (serie.chars) {
                serie.chars.forEach((char: SampleCharacteristic) => {
                    // Check if the char has a non-empty value
                    if (char.value && char.value.trim() !== '') {
                        protocolCharSet.add(char.charId);  // Add charId to the Set
                    }
                });
            }
        });

        // Convert the Set to an array
        let protocolChars = Array.from(protocolCharSet);

        // Generate the protocol based on the IDs
        let protocol: Protocol = this._compiler.combinationProtocol(protolIds);

        // Replace protocol.chars with the collected and filtered unique charIds
        protocol.chars = protocolChars;

        // Step 8: Set the protocol in the report builder
        this._reportBuilder.setReportProtocol(protocol);
    }

    editRemark(sectionId: string) {
        if (this._reportBuilder) this._reportBuilder.editRemark(sectionId);
    }

    editMeasureSummary(measureId: string, section?: string, report?: any) {
        if (this._reportBuilder) this._reportBuilder.editMeasureSummary(measureId, section,report);
    }

    editWeatherReport(report: CompiledWeatherReport) {
        if (this._reportBuilder) this._reportBuilder.editWeatherReport(report);
    }

    editInfoSheetImages(cultivarKey: string, index: number) {
        if (this._reportBuilder) this._reportBuilder.editInfoSheetImages(cultivarKey, index);
    }

    editInfoSheetImageOptions() {
        if (this._reportBuilder) this._reportBuilder.editInfoSheetImageOrder(this.infoSheetReport.infoSheetImageOptions);
    }

    editImageSectionImageOptions(saveOrder: boolean = false) {
        if (this._reportBuilder) this._reportBuilder.editImageSectionImageOptions(this.imageSectionImages, saveOrder);
    }

    trackById(index, item) {
        return item.id;
    }

    trackByKey(index, item) {
        return item.key;
    }

    openImageGallery(currentImage: SampleImage) {
        if(!this.series) return;

        const currentIndex = this.allImages.findIndex(i => currentImage.key === i.key) || 0;

        const data: ImageGalleryDialogData = {
            images: this.allImages,
            currentIndex
        };

        this._dialog.openFullscreen(ImageGalleryDialog, {data, disableClose: false}, false);
    }

    imageTransformId(size: string) {
        switch(size) {
            case "s":
                return "thumb_300";
            case "m":
                return "thumb_400";
            case "l":
                return "thumb_500";
            case "xl":
                return "thumb_600";
            default:
                return "thumb_400";
        }
    }

    dragMoved(e: CdkDragMove) {
        this.imageSectionPosition = e.source.data
    }

    infoSheetDropped(index: number, imageIndex: number) {
        if (!this.imageSectionPosition === null || !this.editable) return;
        moveItemInArray(this.infoSheetReport.infoSheetImageOptions[index], this.imageSectionPosition, imageIndex);
        this.imageSectionPosition = null;
        this.editInfoSheetImageOptions();
    }

    imageSectionDropped(index: number, imageIndex: number) {
        if (!this.imageSectionPosition === null || !this.editable) return;
        moveItemInArray(this.imageSectionImages[index], this.imageSectionPosition, imageIndex);
        this.imageSectionPosition = null;
        this.editImageSectionImageOptions(true);
    }

    private setReportSectionsOrder(report: Report) : Report {

        let combo = '';

        if(this._reportBuilder) {
            this._reportBuilder.reportSections.forEach(section => {
                combo += section.title + ';';
            });

            //Remove spaces, twice
            combo = combo.replace(' ', '');
            combo = combo.replace(' ', '');

            this.sectionsOrder = combo;

            let repCopy = JSON.parse(JSON.stringify(report));
            repCopy.options.sectionsOrder = combo;

            return repCopy;
        }

        return report;
    }

    private updateReport() {

        if (!this._report) {
            this.title = null;
            this.description = null;
            this.toggleDescription = null;
            this.themeId = null;
            this.letterheadRef = null;
            this.letterhead = null;
            this.letterheadKey = null;
            this.showEmptyFields = false;
            this.showStorageRegime = false;
            this.theme = null;
            this.series = null;
            this.legends = null;
            this.charSummary = null;
            this.measureSummary = null;
            this.charIndexSummary = null;
            this.weatherReports = null;
            this.tableReports = null;
            this.infoSheetReport = null;
            this.mappedRemarks = {};
            this.remarkDocuments = {};
            this.remarkImages = {};
            this.remarkImageOptions = {};
            return;
        }

        let themeChanged = false;
        let seriesChanged = false;

        // map remarks for template access
        if (Array.isArray(this._report.remarks)) {
            this.mappedRemarks = {};
            this._report.remarks.forEach(remark => {
                this.mappedRemarks[remark.sectionId] = remark.text;
                this.remarkDocuments[remark.sectionId] = remark.documents;
                this.remarkImages[remark.sectionId] = remark.images;
                this.remarkImageOptions[remark.sectionId] = remark.imageOptions
            });
        } else {
            this.mappedRemarks = {};
        }

        let reportOptions: ReportOptions = JSON.parse(JSON.stringify(this._report.options));

        if (this.infoSheetImagesTemp && reportOptions.cultivarInfoOptions) {
            if (reportOptions.cultivarInfoOptions.infoSheetImageOptions !== this.infoSheetImagesTemp) {
                reportOptions.cultivarInfoOptions.infoSheetImageOptions = this.infoSheetImagesTemp;
            }
        }

        let compileImages: boolean = this.imageCompilationRequired();

        //? Compiles info sheet images for unsaved reports in preview mode
        if (compileImages) {
            let infoSheetImages: ReportImageOption[][] = this.compileInfoSheetImagesForPreview(this._report)

            reportOptions = {
                ...this._report.options,
                cultivarInfoOptions: {
                    ...this._report.options.cultivarInfoOptions,
                    infoSheetImageOptions: infoSheetImages,
                }
            }
        }

        const options = mergeDefaultReportOptions(reportOptions);

        // update report section order
        let repCopy = JSON.parse(JSON.stringify(this._report));
        this._report = this.setReportSectionsOrder(repCopy);
        this.updateSectionsSequence();

        // set title, letterhead, storageRegime
        this.title = this._report.title;
        this.description = this._report.description;
        this.showEmptyFields = options.showEmptyFields;
        this.toggleDescription = options.toggleDescription;
        this.letterheadRef = options.letterheadRef;
        this.letterhead = options.letterhead;
        this.letterheadKey = options.letterheadKey;
        this.showStorageRegime = options.showStorageRegime;

        // get the theme
        if (options.themeId !== this.themeId) {

            this.themeId = options.themeId;
            this.theme = this._compiler.compileTheme(this.themeId);
            themeChanged = true;
        }

        // compile series from report sample data
        let data = this._compiler.sortData(
            (this._report.samples || [])
        );

        let sortedData = data.map(sample => sample.data)

        let cultivars = this._compiler.compileCultivarList(sortedData);
        let evaluations = this._compiler.compileEvaluationList(sortedData);

        if (this._samples !== this._report.samples || themeChanged) {

            this._samples = this._report.samples || [];
            this.series = this._compiler.compileSeries(sortedData, this.theme);
            seriesChanged = true;

            this._compiler.compileTooltipSeries(this.series, cultivars, evaluations, sortedData);
        }

        if (options.showLegend) {
            this.legends = this._compiler.compileLegend(sortedData, this.series);
        } else {
            this.legends = null;
        }

        // compile characteristic summary
        if (!options.showCharSummary) {
            this.charSummary = null;
        } else if (
            seriesChanged
            || !this.charSummary
            || this.charSummary.options !== options.charSummaryOptions
        ) {

            this.charSummary =
                this._compiler.compileCharacteristicSummary(this.series, options.charSummaryOptions);
        }

        // compile measurement summary
        if (!options.showMeasureSummary) {
            this.measureSummary = null;
        } else if (
            seriesChanged
            || !this.measureSummary
            || this.measureSummary.options !== options.measureSummaryOptions
        ) {

            this.measureSummary =
                this._compiler.compileMeasurementSummaryReport(this.series, options.measureSummaryOptions, 'measurementSummary');
            this.tonnesPerHaAvg = this.calculateTonnesPerHaAvg(this.series);
            this.sugarAcidRatioAvg = this.calculateSugarAcidRatioAvg(this.series);
            this.sugarCitrusAcidRatioAvg = this.calculateCitricSugarAcidRatioAvg(this.series);
            this.bunchSugarAcidRatioAvg = this.calculateBunchSugarAcidRatioAvg(this.series);
        }

        // compile characteristic indexes
        if (!options.showCharIndex) {
            this.charIndexSummary = null;
        } else if (
            seriesChanged
            || !this.charIndexSummary
            || this.charIndexSummary.options !== options.charIndexOptions
        ) {

            this.charIndexSummary =
                this._compiler.compileCharacteristicIndexSummary(this.series, options.charIndexOptions);
        }

        if(!options.showNotes){
            this.noteOptions = null;
        }else{
            this.noteOptions = options.noteOptions;
        }

        if(!options.showImages){
            this.imageOptions = null;
        }else{
            this.imageOptions = options.imageOptions;
        }

        // compile weather reports
        if (options.showWeather) {
            if (Array.isArray(this._report.weather) && this._report.weather.length > 0) {
                this.weatherReports = this._report.weather.map(data => {
                    return this._compiler.compileWeatherReport(data);
                });
            } else {
                this.weatherReports = [];
            }
        } else {
            this.weatherReports = null;
        }

        // compile table reports
        if (options.showTables) {

            if (Array.isArray(this._report.tables) && this._report.tables.length > 0) {

                this.tableReports = this._report.tables.map(table => {

                    let compiledTR : CompiledTableReport = this._compiler.compileTableReport(this.series, table);

                    let tempOrderArr = compiledTR.columnDefs.map(columnDef => columnDef.id);
                    tempOrderArr.unshift('series');
                    compiledTR['cols'] = [];

                    //Sorts columns by selected order
                    tempOrderArr.forEach(e => {compiledTR['cols'].push(e)});
                    compiledTR.columns = tempOrderArr;

                    return compiledTR;
                });

                this._compiler.compileTooltipTable(this.tableReports, sortedData, this.series, evaluations);
            } else {
                this.tableReports = [];
            }
        } else {
            this.tableReports = null;
        }

        // compile info-sheet report
        if (!options.showCultivarInfo) {
            this.infoSheetReport = null;
        } else if (
            seriesChanged
            || !this.infoSheetReport
            || this.infoSheetReport.options !== options.cultivarInfoOptions
        ) {
            this.infoSheetReport =
                this._compiler.compileInfoSheet(this.series, options.cultivarInfoOptions, this._samples, 'infosheet');
        }

        // create array of all images for image gallery
        if(seriesChanged){
            let images = [];

            this.series.forEach(series => {
                images = images.concat(series.images);
            });
            this.allImages = images;
        }

        // compile timeline
        if(!options.showTimeline){
            this.timelineReport = null;
        } else if (
            seriesChanged
            || !this.timelineReport
            || this.timelineReport.options !== options.timelineOptions
        ) {
            let startDates = [];
            let endDates = [];
            let evalsTimelineOptionsArr = (this._report.samples || []).map(sample => sample.evalsTimelineOptions || {});
            let evalsTimelineOptions = {};
            evalsTimelineOptionsArr.forEach(e => { evalsTimelineOptions = Object.assign(evalsTimelineOptions, e); });
            Object.values(evalsTimelineOptions).forEach((evalOption: ReportSampleEvalTimelineOptions) => {
                if (evalOption.startDate && evalOption.endDate) {
                    startDates.push(evalOption.startDate);
                    endDates.push(evalOption.endDate);
                }
            })

            let evalStartDates = this.series.filter((serie) => serie.startDate !== null).map((serie) => moment(serie.startDate));
            let evalEndDates = this.series.filter((serie) => serie.endDate !== null).map((serie) => moment(serie.endDate));

            // Find the earliest date using moment.min
            let lowestStartDate = moment.min(evalStartDates);
            let highestEndDate = moment.max(evalEndDates);

            lowestStartDate = lowestStartDate.isValid() ? lowestStartDate.subtract(1, 'weeks') : moment();
            highestEndDate = highestEndDate.isValid() ? highestEndDate.add(1, 'weeks') : moment();

            this.lowestStartDate = lowestStartDate
            this.highestEndDate = highestEndDate
            this.timelineReport = this._compiler.compileTimeline(this.series, options.timelineOptions, evalsTimelineOptions);
        }

        // console.timeEnd("ReportComponent: update report");
        this._changeDetectorRef.markForCheck();

        //Always place location, description, eval and harvest date first
        if (this.tableReports) {
            this.tableReports.forEach(table =>{
                let subArr: string[] = this.createSubArr(table.columns);

                for (let i: number = 0; i < subArr.length; i++) {
                    if (subArr[i] == "prop_eval_notes") {
                        let index = table.columns.indexOf(subArr[i]);
                        this.arrayMove(index, table.columns.length -1, table.columns);
                    } else {
                        let index = table.columns.indexOf(subArr[i]);
                        this.arrayMove(index, i+1, table.columns);
                    }
                }
            });
        }

        let imageCount: number = 0;
        options.imageSectionOptions.images.forEach((image) => {imageCount += image.length});

        this.imageSectionImages = options.imageSectionOptions.images.map(function(arr) {
            return arr.slice();
        });

        this.imageSectionImages = this._compiler.syncImageSectionImageData(this.series, this.imageSectionImages)
        this.editImageSectionImageOptions();
        this.setReportBuilderProtocol();
    }

    //Move elements in array from old index to new index
    arrayMove(from: number, to: number, arr: string[]) {
        if (from == -1) return arr;
        if (to == from) return arr;
        //remove from item and store
        let f = arr.splice(from, 1)[0];
        //insert stored item into position to
        arr.splice(to, 0, f);

        return arr;
    }

    //Creates sub array of properties
    private createSubArr(arr: string[]) {
        let properties = this._library.properties.all();
        let subArr: string[] = [];

        properties.forEach(property => {
            if (arr.indexOf(property.id) != -1) {
                subArr.push(property.id);
            }
        })

        return subArr;
    }

    private calculateTonnesPerHaAvg(series: Series[]) {
        let total = 0;
        let tonnesValues: number[] = [];

        series.forEach(serie => {
            if (serie.seriesTonsPerHa) {
                tonnesValues.push(serie.seriesTonsPerHa);
            }
        });

        tonnesValues.forEach(val => {
            total = total + val;
        });

        return +(total/tonnesValues.length).toFixed(2)
    }

    private calculateSugarAcidRatioAvg(series: Series[]) {
        let ratioValues: number[] = [];
        let total = 0;

        series.forEach(serie => {
            if(serie.seriesSugarAcidRatio) {
                ratioValues.push(serie.seriesSugarAcidRatio);
            }
        });

        ratioValues.forEach(val => {
            total = total + val;
        })

        return +(total/ratioValues.length).toFixed(2);

    }

    private calculateCitricSugarAcidRatioAvg(series: Series[]) {
        let ratioValues: number[] = [];
        let total = 0;

        series.forEach(serie => {
            if(serie.seriesCitricSugarAcidRatio) {
                ratioValues.push(serie.seriesCitricSugarAcidRatio);
            }
        });

        ratioValues.forEach(val => {
            total = total + val;
        })

        return +(total/ratioValues.length).toFixed(2);

    }

    private calculateBunchSugarAcidRatioAvg(series: Series[]) {
        let ratioValues: number[] = [];
        let total = 0;

        series.forEach(serie => {
            if(serie.seriesBunchSugarAcidRatio) {
                ratioValues.push(serie.seriesBunchSugarAcidRatio);
            }
        });

        ratioValues.forEach(val => {
            total = total + val;
        })

        return +(total/ratioValues.length).toFixed(2);

    }

    private hasCharValues(charValues: any): boolean {
        let showData = false;
        for (let value of charValues) {
            if (value.hasOwnProperty('value') && value.value) showData = true;
        }

        return showData;
    }

    private isPrimaryCultivarImage(imageKey: string): boolean {
        if (!this.infoSheetReport.primaryImageKeys.includes(imageKey)) return false;
        return true;
    }

    private getTransformId(imageSize: string): string{
        if (imageSize == 's') return "thumb_300"
        if (imageSize == 'm') return "thumb_300"
        if (imageSize == 'l') return "thumb_400"
        if (imageSize == 'xl') return "thumb_500"

        return "thumb_300"
    }

    private imageCompilationRequired(): boolean{
        if (this.editable) return false;
        if (!this._report.options.cultivarInfoOptions) return false;
        if (!this._report.options.cultivarInfoOptions.infoSheetImageOptions) return true;

        for (let i = 0; i < 2; i++) {
            if (this._report.options.cultivarInfoOptions.infoSheetImageOptions[i].length < this._report.options.cultivarInfoOptions.includeImages[i].length) return true
        }

        return false;
    }

    private compileInfoSheetImagesForPreview(data: Report): ReportImageOption[][] {
        let infoSheetOptions: ReportImageOption[][] = [[], []]

        let evaluations: Evaluation[] = []
        data.samples.map((sample) => sample.data.evals).map((evaluation) => evaluation.forEach((val) => evaluations.push(val)));

        for (let i = 0; i < 2; i++) {
            let infoOption: ReportImageOption[] =
                data.options.cultivarInfoOptions.infoSheetImageOptions
                ? data.options.cultivarInfoOptions.infoSheetImageOptions[i]
                : []

            if (!data.options.cultivarInfoOptions.hasOwnProperty('infoSheetImageOptions')  || data.options.cultivarInfoOptions.includeImages[i].length !== data.options.cultivarInfoOptions.infoSheetImageOptions[i].length){
                infoOption = this.updateInfoSheetImageOptions(evaluations, data.options.cultivarInfoOptions.includeImages[i]);
            }

            infoSheetOptions[i] = infoOption;
        }

        return infoSheetOptions;
    }

    private updateInfoSheetImageOptions(evaluations: Evaluation[], validImages: string[]): ReportImageOption[] {
        let imageOption: ReportImageOption[] = []
        let dataCount = 0;
        for (let evaluation of evaluations) {
            let images = evaluation.images.filter((image) => validImages.includes(image.key))

            if (!images.length) continue;

            for (let image of images) {
                let data: ReportImageOption = {
                    key: image.key,
                    fileRef: image.fileRef,
                    name: image.name,
                    extension: fileExtension(image.name),
                    position: dataCount,
                }

                imageOption.push(data);
                dataCount++;
            }
        }

        return imageOption;
    }

    // Allows primary image to be set from the specifications
    private setPrimaryImage(sample: Sample) {

        const data: SamplePrimaryImageFormDialogData = { key: sample.key };
        this._dialog.open(SamplePrimaryImageFormDialog, { data }).afterClosed()
            .subscribe(pipe(result => {
                if(result) {
                    this._reportBuilder.reload(false);
                    return this._snackbar.info('Primary Image Updated');
                }
            }));
    }
}
