<ng-container *ngIf="state$ | async as state">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Characteristics</h2>
        <h3 class="dialog-subtitle">Sample {{ state.data ? state.data.sample.code : '?' }}, evaluation {{ state.data ? state.data.label : '?' }}</h3>
    </div>

    <div mat-dialog-content>
        <form class="char-form" [formGroup]="formGroup" #charsForm="ngForm" novalidate (ngSubmit)="attempt()" id="chars_form" autocomplete="off">

            <ng-container *ngFor="let group of groups">

                <h2 class="group-title mat-title">{{ group.category.label }}</h2>

                <ng-container *ngFor="let char of group.chars" [ngSwitch]="char.type">

                    <mat-form-field appearance="outline" floatLabel="always" class="char-field" *ngSwitchCase="'event'">
                        <mat-label>{{ char.label }}</mat-label>
                        <input matInput [matDatepicker]="datePicker" [formControl]="formGroup.controls[char.id]">
                        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                        <mat-datepicker #datePicker></mat-datepicker>
                        <mat-error>Enter valid date [YYYY-MM-DD]</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" floatLabel="always" class="char-field" *ngSwitchCase="'interval'">
                        <mat-label>{{ char.label }}</mat-label>
                        <input matInput type="text"
                            [formControl]="formGroup.controls[char.id]"
                            autocomplete="disabled">
                        <mat-hint>{{ getValueLabel(char.id) }}</mat-hint>
                        <mat-error>Enter number between {{ char.params.min }} and {{ char.params.max }}, rounded to {{ char.params.step }}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" floatLabel="always" class="char-field" *ngSwitchCase="'nominal'">
                        <mat-label>{{ char.label }}</mat-label>
                        <mat-select [formControl]="formGroup.controls[char.id]" multiple>
                            <mat-option *ngFor="let option of char.params.options" [value]="option.value">{{ option.label }}</mat-option>
                        </mat-select>
                        <mat-hint>{{ getValueLabel(char.id) }}</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" floatLabel="always" class="char-field" *ngSwitchCase="'color'">
                        <mat-label>{{ char.label }}</mat-label>
                        <pv-colorpicker [formControl]="formGroup.controls[char.id]" [palette]="char.params.palette"></pv-colorpicker>
                        <mat-hint>{{ getValueLabel(char.id) }}</mat-hint>
                        <mat-error>Select a valid color</mat-error>
                    </mat-form-field>

                </ng-container>
            </ng-container>

        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="charsForm"
        [status]="state.status"
        (cancel)="cancel()">
    </pv-form-actions>

</ng-container>