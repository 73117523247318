<button mat-menu-item (click)="addGroupProtocolFilter(orgKey, group.key, group.type); $event.stopPropagation();" matTooltip="Add Protocol Filter">
    <mat-icon>add</mat-icon>
    <span>Add Protocol Filter</span>
</button>
<ng-container *ngIf="group">
    <a mat-menu-item role="list-item" *ngFor="let protocol of group.protocols"
        (click)="removeGroupProtocolFilter(group.key, protocol.key); $event.stopPropagation();"
        matTooltip="Remove Sample">
        <mat-icon>ballot</mat-icon>
        <span matLine><strong>{{ protocol.title }}</strong></span>&nbsp;
    </a>
</ng-container>
