<h1 mat-dialog-title>Setup Report</h1>
<div mat-dialog-content>
    <form #reportSetupForm="ngForm"
          class="pv-form"
          [formGroup]="formGroup"
          autocomplete="off"
          novalidate
          (ngSubmit)="attempt()">

        <div class="form-column report-setup-title">
            <mat-form-field appearance="standard">
                <mat-label>Title</mat-label>
                <input matInput type="text" formControlName="title" required>
                <mat-error>Title required, max 100 characters</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Description</mat-label>
                <textarea matInput
                          formControlName="description"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5">
                </textarea>
                <mat-hint>Describe report **</mat-hint>
                <mat-error>Max 255 characters</mat-error>
            </mat-form-field>

            <p class="form-caption">
                ** Report description can be toggled on and off in the report builder.
            </p>

        </div>

        <div class="form-column report-setup-template">

            <mat-form-field appearance="standard" floatLabel="always">
                <mat-label><mat-icon>file_copy</mat-icon> Template </mat-label>

                <mat-select [formControl]="templateControl" required>
                    <mat-select-trigger>
                        {{ templateControl.value?.name }}
                    </mat-select-trigger>
                    <mat-option [value]="blankTemplateOption">
                        {{ blankTemplateOption.name }}
                        <ngx-mat-select-search [formControl]="templateFilterCtrl"
                                               placeholderLabel="Find Template..."
                                               noEntriesFoundLabel="No matching entry found">
                        </ngx-mat-select-search>
                    </mat-option>

                    <mat-option *ngFor="let template of filteredTemplates | async" [value]="template">
                        {{ template.name }}
                    </mat-option>
                </mat-select>
                <mat-hint>Choose a template</mat-hint>
                <mat-error>Select a template</mat-error>
            </mat-form-field>


            <p class="form-caption">
                Templates may specify default report letterheads,
                remarks and options for a new report. Create more templates
                in the Report Builder.
            </p>

            <p class="mat-caption text-primary" *ngIf="sampleKeys && sampleKeys.length">
                {{ sampleKeys.length }} samples selected for this report.
            </p>


        </div>

    </form>
</div>
<pv-form-actions
                 mat-dialog-actions
                 [form]="reportSetupForm"
                 (cancel)="cancel()"
                 [status]="status">

</pv-form-actions>
