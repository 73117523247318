<ng-container>
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Documents</h2>
    </div>

    <div mat-dialog-content>

        <form id="document_form"
            [formGroup]="formGroup"
            (ngSubmit)="attempt()"
            autocomplete="disabled"
            #documentForm="ngForm"
            novalidate>

            <div class="document-form-container" formArrayName="documents">

                <div class="document-file-dropbox"
                     (drop)="onDrop($event)"
                     (dragover)="onDragOver($event)"
                     (dragleave)="onDragLeave($event)"
                     [class.dragging]="isDragging">

                    <div class="document-drop-request">
                        <p>Drag and drop image files here to upload.</p>
                        <label for="document_file_input">Browse Files</label>
                        <input id="document_file_input" (change)="fileInputChanged($event)" type="file">
                        <div class="document-error text-warn" *ngFor="let error of validationErrors">{{ error }}</div>
                    </div>

                    <div class="document-drop-results" *ngIf="uploads$ | async as uploads">
                        <div class="document-upload" *ngFor="let upload of uploads; trackBy: trackUpload">
                            <div [ngSwitch]="upload.status">
                                {{ upload.loaded | formatBytes }}/{{ upload.total | formatBytes }}
                                <span *ngSwitchCase="'LOADING'">Uploading...</span>
                                <span *ngSwitchCase="'COMPLETE'" class="text-primary">Done</span>
                                <span *ngSwitchDefault class="text-warn">{{ upload.status | descStatusTitle }}</span>
                            </div>
                            <mat-progress-bar mode="determinate" [value]="upload.loaded / upload.total * 100"></mat-progress-bar>
                        </div>
                    </div>
                </div>

                <div class="document-container">
                    <div class="document-form"
                        *ngFor="let documentGroup of formArray.controls; index as i"
                        [formGroupName]="i"
                        [class.document-deleted]="documentGroup.disabled">

                        <!-- Image preview -->
                        <pv-image-ref class="document-form-file"
                                        *ngIf="isImage(documentGroup.value.extension) && documentGroup.value.fileRef as ref"
                                        [fileRef]="ref"
                                        transformId="thumb_300">
                        </pv-image-ref>

                        <!-- Document preview -->
                        <div class="raw-document-container"
                            *ngIf="isRaw(documentGroup.value.extension) && documentGroup.value.fileRef as ref">
                            <div class="document-name">{{documentGroup.value.name}}</div>
                            <mat-spinner mode="indeterminate" [id]="'spinner_'+documentGroup.value.key" class="document-spinner"></mat-spinner>
                            <ngx-doc-viewer
                                class="document-viewer"
                                style="visibility: hidden"
                                [id]="'viewer_'+documentGroup.value.key"
                                [url]="getSrc(ref, documentGroup.value.extension)"
                                viewer="google"
                                (loaded)="contentLoaded(documentGroup.value.key)">
                            </ngx-doc-viewer>
                        </div>

                        <!-- Video preview -->
                        <div class="raw-document-container" *ngIf="isVideo(documentGroup.value.extension) && documentGroup.value.fileRef as ref">
                            <div class="document-name">{{documentGroup.value.name}}</div>
                            <video [src]="getSrc(ref, documentGroup.value.extension)"
                                controls
                                class="document-viewer video-viewer">
                            </video>
                        </div>

                        <!-- Audio preview -->
                        <div class="raw-document-container" *ngIf="isAudio(documentGroup.value.extension) && documentGroup.value.fileRef as ref">
                            <div class="document-name">{{documentGroup.value.name}}</div>
                            <audio controls>
                                <source [src]="getSrc(ref, documentGroup.value.extension)" [type]="'audio/'+documentGroup.value.extension">
                            </audio>
                        </div>

                        <!-- Actions -->
                        <div class="actions">
                            <button type="button"
                                    class="document-download-button"
                                    mat-icon-button
                                    (click)="downloadDocument(documentGroup.value.fileRef, documentGroup.value.extension)"
                                    matTooltip="Download document">
                                <mat-icon>cloud_download</mat-icon>
                            </button>

                            <button type="button"
                                class="document-delete-button"
                                mat-icon-button
                                (click)="toggleDelete(i)"
                                [matTooltip]="documentGroup.disabled ? 'Restore Document' : 'Delete Document'">
                                <mat-icon>
                                    {{ documentGroup.disabled ? 'restore_from_trash' : 'delete' }}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>

    <pv-form-actions
        mat-dialog-actions
        (cancel)="cancel(false)"
        [form]="documentForm">
    </pv-form-actions>
</ng-container>