<pv-view
         #view
         *ngIf="state$ | async as state"
         toolbarTitle="{{ state.sample?.type | titlecase }} Sample {{ state.sample?.code }}"
         [ngSwitch]="state.status">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools>
        <button mat-icon-button (click)="reload()">
            <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="view.togglePrintMode()">
            <mat-icon>print</mat-icon>
        </button>
    </div>

    <div pv-view-body *ngSwitchCase="'LOADING'">
        <pv-view-loader message="Loading sample..."></pv-view-loader>
    </div>

    <div pv-view-sidebar *ngSwitchCase="'OK'" class="sample-detail-sidebar">

        <ng-container *ngIf="state.sample as sample">

            <mat-action-list class="mat-nopad" *ngIf="selectedOrg$ | async as selectedOrg">

                <a mat-list-item routerLink="..">
                    <mat-icon matListIcon>arrow_back</mat-icon>
                    <span matLine>Return to Index</span>
                    <mat-icon>view_list</mat-icon>
                </a>

                <a mat-list-item [routerLink]="['/org', sample.plantSample.ownerOrgKey, 'samples', 'plant', sample.plantSample.key]" *ngIf="sample.plantSample">
                    <mat-icon matListIcon>arrow_back</mat-icon>
                    <span matLine>Return to Plant Sample</span>
                    <span matLine>{{ sample.plantSample.code }}</span>
                    <mat-icon svgIcon="common:plant_sample"></mat-icon>
                </a>

                <mat-divider></mat-divider>

                <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                    <button mat-list-item type="button" (click)="editSample(sample.key)">
                        <mat-icon matListIcon>edit</mat-icon>
                        <span>Edit Sample</span>
                    </button>
                </ng-container>

                <button mat-list-item type="button" (click)="extract(sample)" *ngIf="sample.type === 'plant'">
                    <mat-icon matListIcon svgIcon="common:harvest_sample"></mat-icon>
                    <span>Extract Harvest Sample</span>
                </button>

                <button mat-list-item type="button" (click)="addEvaluation(sample.key)">
                    <mat-icon matListIcon>add</mat-icon>
                    <span>Add Evaluation</span>
                </button>

                <button mat-list-item type="button" (click)="scheduleEvaluations(sample.key)" *ngIf="sample.type === 'harvest'">
                    <mat-icon matListIcon>playlist_add</mat-icon>
                    <span>Schedule Evaluations</span>
                </button>

                <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                    <button mat-list-item type="button" (click)="setPrimaryImage(sample.key)">
                        <mat-icon matListIcon>star</mat-icon>
                        <span>Set Primary Image</span>
                    </button>

                    <button mat-list-item type="button" (click)="removeSample(sample.key)">
                        <mat-icon matListIcon>delete</mat-icon>
                        <span>Delete Sample</span>
                    </button>
                </ng-container>

            </mat-action-list>

        </ng-container>

    </div>

    <div pv-view-body class="sample-detail-body p-12" *ngSwitchCase="'OK'">

        <div class="sample-detail-header" *ngIf="state.sample as sample">

            <div class="sample-header-content">
                <h2 class="m-0">{{ sample.code }}</h2>
                <div>{{ sample.label }}</div>
                <div>
                    <small>Last updated {{ sample.updatedAt | amDateFormat:'ll' }}
                        added {{ sample.createdAt | amDateFormat:'ll' }}</small>
                </div>
            </div>

            <pv-image-ref *ngIf="sample.primaryImage as image"
                        transformId="thumb_300"
                        [fileRef]="image.fileRef">
            </pv-image-ref>

        </div>

        <div class="sample-detail-content" *ngIf="state.sample as sample" [ngSwitch]="sample.type">



            <!-- SECTION -->
            <section>

                <h4>Basic Info</h4>

                <mat-list class="mat-nopad">

                    <mat-list-item>
                        <pv-barcode-image mat-list-icon [code]="sample.code" class="mat-24">
                        </pv-barcode-image>
                        <p matLine class="mat-code">{{ sample.code }}</p>
                        <span matLine>
                            Label: <span
                                    [class.text-muted]="!sample.label">{{ sample.label || 'Unlabeled' }}</span>
                        </span>
                        <span matLine>
                            Size: <span
                                    [class.text-muted]="!sample.size">{{ sample.size || 'Unknown' }}</span>
                        </span>
                    </mat-list-item>

                    <mat-list-item *ngSwitchCase="'plant'">
                        <mat-icon mat-list-icon>date_range</mat-icon>
                        <span matLine>
                            Planted
                            {{ sample.birthDate | amDateFormat:'ll' }}
                        </span>
                        <span matLine>{{ sample.birthDate | amTimeAgo }}</span>
                        <span matLine *ngIf="sample.deathDate" class="text-warn">
                            Removed
                            {{ sample.deathDate | amDateFormat:'ll' }}
                        </span>
                    </mat-list-item>

                    <mat-list-item *ngSwitchCase="'harvest'">
                        <mat-icon mat-list-icon>date_range</mat-icon>
                        <span matLine>
                            Harvested
                            {{ sample.birthDate | amDateFormat:'[week] W' }},
                            {{ sample.birthDate | amDateFormat:'ll' }}
                        </span>
                        <span matLine>{{ sample.birthDate | amTimeAgo }}</span>
                        <span matLine *ngIf="sample.deathDate" class="text-warn">
                            Destroyed
                            {{ sample.deathDate | amDateFormat:'ll' }}
                        </span>
                    </mat-list-item>

                </mat-list>


            </section>
            <!-- SECTION END -->



            <!-- SECTION -->
            <section>

                <h4>Cultivar Information</h4>

                <mat-list class="mat-nopad">

                    <mat-list-item *ngIf="sample.plantSample as origin">
                        <mat-icon mat-list-icon svgIcon="common:plant_sample"></mat-icon>
                        <p matLine>Origin Sample</p>
                        <p matLine>{{ origin.code }}</p>
                        <p matLine>{{ origin.label }}</p>
                        <p matLine><strong>{{ origin | rowPosition }}</strong></p>
                        <p matLine>
                            Planted
                            {{origin.birthDate | amDateFormat: '[week] W'}},
                            <time>{{ origin.birthDate | amDateFormat:'ll' }}</time>
                        </p>
                        <p matLine>{{ origin.birthDate | amTimeAgo }}</p>
                    </mat-list-item>

                    <mat-list-item *ngIf="sample.scionCultivar as scion">
                        <mat-icon mat-list-icon svgIcon="common:flat_scion"></mat-icon>
                        <mat-icon mat-list-icon [svgIcon]="'common:' + scion.cropId"
                                    class="mat-36"></mat-icon>
                        <p matLine>Scion Cultivar</p>
                        <p matLine>
                            <strong [innerHtml]="scion | descCultivar"></strong>
                        </p>
                    </mat-list-item>

                    <ng-template #noRootstock>
                        <mat-list-item class="list-item-disabled">
                            <mat-icon mat-list-icon svgIcon="common:flat_scion">
                            </mat-icon>
                            <p matLine>Scion Cultivar</p>
                            <p matLine>Not specified</p>
                        </mat-list-item>
                    </ng-template>

                    <mat-list-item *ngIf="sample.rootstockCultivar as rootstock else noRootstock">
                        <mat-icon mat-list-icon svgIcon="common:flat_rootstock">
                        </mat-icon>
                        <mat-icon mat-list-icon [svgIcon]="'common:' + rootstock.cropId"
                                    class="mat-36"></mat-icon>
                        <p matLine>Rootstock Cultivar</p>
                        <p matLine>
                            <strong [innerHtml]="rootstock | descCultivar"></strong>
                        </p>
                    </mat-list-item>

                    <ng-template #noRootstock>
                        <mat-list-item class="list-item-disabled">
                            <mat-icon mat-list-icon svgIcon="common:flat_rootstock">
                            </mat-icon>
                            <p matLine>Rootstock Cultivar</p>
                            <p matLine>Not specified</p>
                        </mat-list-item>
                    </ng-template>

                </mat-list>


            </section>
            <!-- SECTION END -->

            <!-- SECTION -->
            <section>

                <h4>Location</h4>

                <mat-list class="mat-nopad">

                    <mat-list-item *ngIf="sample | rowPosition as rowPosition">
                        <mat-icon mat-list-icon>linear_scale</mat-icon>
                        <p matLine>Row Position</p>
                        <p matLine><span class="mat-title">{{ rowPosition }}</span></p>
                        <p matLine *ngIf="sample | descSpacing as spacing">{{ spacing }}</p>
                    </mat-list-item>

                    <mat-list-item *ngIf="sample.site as site else noSite">
                        <mat-icon mat-list-icon>place</mat-icon>
                        <p matLine>Site</p>
                        <p matLine [innerHtml]="site | descSite"></p>
                        <p matLine *ngIf="site | descSpacing as spacing">
                            Site Spacing: {{ spacing }}
                        </p>
                    </mat-list-item>

                    <ng-template #noSite>
                        <mat-list-item class="list-item-disabled">
                            <mat-icon mat-list-icon>place</mat-icon>
                            <p matLine>Site</p>
                            <p matLine>Not specified</p>
                        </mat-list-item>
                    </ng-template>

                </mat-list>

            </section>
            <!-- SECTION END -->

            <section *ngIf="sample.type === 'plant'">
                <h4>Extracted Harvest Samples</h4>

                <mat-nav-list *ngIf="sample.harvestSamples.length else noExtractions" dense>
                    <a mat-list-item *ngFor="let harvest of sample.harvestSamples" [routerLink]="['../..', harvest.type, harvest.key]">
                        <mat-icon matListIcon svgIcon="common:harvest_sample"></mat-icon>
                        <p matLine>{{ harvest.code }} {{ harvest.label }}</p>
                    </a>
                </mat-nav-list>

                <ng-template #noExtractions>
                    <p class="mat-caption">No extracted harvest samples.</p>
                </ng-template>

            </section>

            <section>
                <h4>Description</h4>
                <p *ngIf="sample.description else noDescription">{{ sample.description }}</p>
                <ng-template #noDescription>
                    <p class="mat-caption">No description.</p>
                </ng-template>
            </section>


        </div>


        <pv-timeline class="sample-detail-timeline" *ngIf="state.sample as sample">

            <pv-timeline-group content="Sample">
                <pv-timeline-item *ngIf="sample.birthDate"
                        type="box"
                        [content]="sample.type === 'harvest' ? 'Harvested' : 'Planted'"
                        [start]="sample.birthDate">
                </pv-timeline-item>
                <pv-timeline-item *ngIf="sample.deathDate"
                        type="box"
                        [content]="sample.type === 'harvest' ? 'Destroyed' : 'Removed'"
                        [start]="sample.deathDate">
                </pv-timeline-item>

                <pv-timeline-item *ngFor="let extract of sample.harvestSamples"
                    type="box"
                    [content]="'Harvest ' + (extract.label || extract.code )"
                    [start]="extract.birthDate"
                ></pv-timeline-item>

            </pv-timeline-group>

            <pv-timeline-group *ngFor="let evalu of state.evaluations; trackBy: trackEvaluation"
                [content]="evalu.label">
                <ng-container *ngIf="evalu | extractTimeline:sample.birthDate as timeline">

                    <!-- BACKGROUND -->
                    <pv-timeline-item
                        type="background"
                        [start]="evalu.evalStartDate || now"
                        [end]="evalu.evalEndDate || now"
                        [content]="evalu.label">
                    </pv-timeline-item>

                    <!-- EVENT CHARS -->
                    <pv-timeline-item
                        *ngFor="let event of timeline.events; trackBy: trackById"
                        type="box"
                        [start]="event.start"
                        [content]="event.label"
                        [color]="event.color">
                    </pv-timeline-item>

                    <!-- STORAGE LINES -->
                    <pv-timeline-item
                        type="background"
                        *ngFor="let storage of timeline.storage; trackBy: trackById"
                        [content]="storage.label"
                        [start]="storage.start"
                        [end]="storage.end"
                        [color]="storage.color">
                    </pv-timeline-item>

                </ng-container>
            </pv-timeline-group>

        </pv-timeline>


        <mat-card class="sample-evaluation-card">

            <mat-card-header>
                <mat-icon mat-card-avatar>assignment</mat-icon>
                <mat-card-title>Evaluations</mat-card-title>
                <mat-card-subtitle>Sample evaluations</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content class="mat-card-inset">


            </mat-card-content>

            <mat-card-content class="mat-card-inset">

                <mat-divider></mat-divider>

                <!-- EVALUATION PANEL -->
                <div class="pv-evaluation-panel"
                     *ngFor="let evalu of state.evaluations; trackBy: trackEvaluation">

                    <!-- EVALUATION PANEL HEADER-->
                    <div class="evaluation-panel-header"
                         [class.detail-expanded]="evalDetailExpansion.isSelected(evalu.key)">

                        <!-- Toggle Detail -->
                        <button mat-icon-button class="no-print" (click)="toggleDetail(evalu.key)">
                            <mat-icon>
                                {{ evalDetailExpansion.isSelected(evalu.key) ? 'expand_more' : 'chevron_right' }}
                            </mat-icon>
                        </button>

                        <h4 class="evaluation-title" [class.text-muted]="!evalu.label">
                            {{ evalu.label || 'Unlabeled' }}
                        </h4>

                        <div class="evaluation-status" [ngSwitch]="evalu | getEvaluationStatus">
                            <span *ngSwitchCase="'PENDING'" class="text-muted">
                                <mat-icon>event</mat-icon> Pending Start in {{ evalu.evalStartDate | amTimeAgo:true }}
                            </span>
                            <span *ngSwitchCase="'ONGOING'" class="text-accent">
                                <mat-icon>event_note</mat-icon> Ongoing for {{ evalu.evalStartDate | amTimeAgo:true }}
                            </span>
                            <span *ngSwitchCase="'COMPLETE'" class="text-primary">
                                <mat-icon>event_available</mat-icon> Completed {{ evalu.evalEndDate | amTimeAgo }}
                            </span>
                            <span *ngSwitchDefault class="text-warn">
                                <mat-icon>event_busy</mat-icon> * Invalid
                            </span>
                        </div>

                        <!-- Info -->
                        <div class="evaluation-info">
                            <div>
                                <span>{{ evalu.evalStartDate | amDateFormat:'ll' }}</span>
                                <span *ngIf="evalu.evalEndDate">
                                    - {{ evalu.evalEndDate | amDateFormat:'ll' }}
                                </span>
                            </div>
                            <div class="mat-caption" *ngIf="evalu.evalStartDate && evalu.evalEndDate">
                                Duration: {{ evalu.evalEndDate | amDifference:evalu.evalStartDate:'days' }} day(s)
                            </div>
                            <span class="mat-caption">{{ evalu.protocolId | labelProtocol }}</span>
                        </div>

                        <!-- Storage Schedule -->
                        <div *ngIf="state.sample?.type == 'harvest'" class="evaluation-storage">
                            <ng-container>
                                <div class="mat-caption storage-regime" *ngFor="let storage of evalu.storageRegime">
                                    <span>{{ storage.duration }}&nbsp;@&nbsp;{{ storage.temp }}&#8451;</span>
                                    <span>Controlled Atmosphere: <span *ngIf="storage.ca">Yes</span><span *ngIf="!storage.ca">No</span></span>
                                    <span>Additional Treatment: <span *ngIf="storage.treatment?.length">Yes</span><span *ngIf="!storage.treatment?.length">No</span></span>
                                    <!-- Tooltip info - displayed on hover -->
                                    <span class="storage-hover-card" *ngIf="storage.ca || storage.treatment?.length">
                                        <div class="card-content" *ngIf="storage.ca">
                                            <span *ngIf="storage.caOptions.co2">CO<sub>2</sub>: {{ storage.caOptions.co2 }}%</span>
                                            <span *ngIf="storage.caOptions.o2">O<sub>2</sub>: {{ storage.caOptions.o2 }}%</span>
                                            <span *ngIf="storage.caOptions.eth">Ethylene concentration: {{ storage.caOptions.eth }}ppm</span>
                                            <span *ngIf="storage.caOptions.rh">Relative Humidity: {{ storage.caOptions.rh }}%</span>
                                        </div>
                                        <div *ngIf="storage.treatment?.length">
                                            <span *ngFor="let treatment of storage.treatment" style="padding-left: 5px;">
                                                <span *ngIf="treatment == 'MCP'">MCP </span>
                                                <span *ngIf="treatment == 'Smartfresh'">Smartfresh </span>
                                                <span *ngIf="treatment == 'Hazel'">Hazel </span>
                                            </span>
                                        </div>
                                    </span>
                                    <span class="storage-hover-card" *ngIf="!storage.ca && !storage.treatment?.length" style="text-align: center;">
                                        No Additional Information
                                    </span>
                                </div>
                            </ng-container>
                        </div>

                        <div class="evaluation-actions no-print">

                            <!-- Edit Notes -->
                            <button mat-icon-button
                                    type="button"
                                    (click)="editNotes(evalu.key)"
                                    matTooltip="Edit Notes">
                                <mat-icon [matBadge]="evalu.notes.length"
                                          [matBadgeColor]="evalu.notes.length > 0 ? 'primary' : 'accent'">
                                    comment</mat-icon>
                            </button>

                            <!-- Edit Images -->
                            <button mat-icon-button
                                    type="button"
                                    (click)="editImages(evalu.key)"
                                    matTooltip="Edit Images">
                                <mat-icon [matBadge]="evalu.images.length"
                                          [matBadgeColor]="evalu.images.length > 0 ? 'primary' : 'accent'">
                                    insert_photo</mat-icon>
                            </button>

                            <!-- Edit Measurements -->
                            <button mat-icon-button
                                    type="button"
                                    (click)="editMeasurements(evalu.key)"
                                    matTooltip="Edit Measurements">
                                <mat-icon [matBadge]="evalu.measures.length"
                                          [matBadgeColor]="evalu.measures.length > 0 ? 'primary' : 'accent'">
                                    straighten</mat-icon>
                            </button>

                            <!-- Edit Characteristics -->
                            <button mat-icon-button
                                    type="button"
                                    (click)="editCharacteristics(evalu.key)"
                                    matTooltip="Edit Characteristics">
                                <mat-icon [matBadge]="evalu.chars.length"
                                          [matBadgeColor]="evalu.chars.length > 0 ? 'primary' : 'accent'">
                                    tune</mat-icon>
                            </button>

                            <button mat-icon-button
                                    type="button"
                                    matTooltip="More Actions"
                                    [matMenuTriggerFor]="evalActionsMenu"
                                    [matMenuTriggerData]="{evalu: evalu}">
                                <mat-icon>more_horiz</mat-icon>
                            </button>

                        </div>
                    </div>
                    <!-- EVALUATION PANEL HEADER END-->

                    <!-- EVALUATION PANEL CONTENT (if expanded) -->
                    <div class="evaluation-detail"
                         *ngIf="evalDetailExpansion.isSelected(evalu.key)">

                        <!-- Characteristics -->
                        <section>
                            <div class="section-header">
                                <mat-icon>tune</mat-icon>
                                <h4>{{ evalu.label || '' }} Characteristics</h4>
                                <button mat-icon-button class="no-print" (click)="printEvaluationForm(evalu.key)"
                                        matTooltip="Print Evaluation Form">
                                    <mat-icon>print</mat-icon>
                                </button>
                                <button mat-icon-button class="no-print" (click)="editCharacteristics(evalu.key)"
                                        matTooltip="Edit Characteristics">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div class="section-content">
                                <pv-characteristics-list [protocolId]="evalu.protocolId"
                                                         [values]="evalu.chars">
                                </pv-characteristics-list>
                            </div>
                        </section>

                        <!-- Measurements -->
                        <section>
                            <div class="section-header">
                                <mat-icon>straighten</mat-icon>
                                <h4>{{ evalu.label || '' }} Measurements</h4>
                                <button mat-icon-button class="no-print" type="button"
                                        (click)="editMeasurements(evalu.key)"
                                        matTooltip="Edit Measurements">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div class="section-content">
                                <pv-measurements-table [protocolId]="evalu.protocolId"
                                                       [values]="evalu.measures">
                                </pv-measurements-table>
                            </div>
                        </section>

                        <!-- Notes -->
                        <section class="section-notes">
                            <div class="section-header">
                                <mat-icon>comment</mat-icon>
                                <h4>{{ evalu.label || '' }} Notes</h4>
                                <button mat-icon-button type="button" class="no-print"
                                (click)="editNotes(evalu.key)"
                                        matTooltip="Edit Notes">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div class="section-content">
                                <pv-notes-list [notes]="evalu.notes"></pv-notes-list>
                            </div>
                        </section>

                        <!-- Images -->
                        <section class="section-images">
                            <div class="section-header">
                                <mat-icon>insert_photo</mat-icon>
                                <h4>{{ evalu.label || '' }} Images</h4>
                                <button mat-icon-button class="no-print"
                                        type="button"
                                        *ngIf="evalu.images.length > 0"
                                        (click)="gallery.openGallery()"
                                        matTooltip="View Image Gallery">
                                    <mat-icon>photo_library</mat-icon>
                                </button>
                                <button mat-icon-button type="button"
                                        class="no-print"
                                        (click)="editImages(evalu.key)" matTooltip="Edit Images">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div class="section-content">
                                <pv-image-gallery #gallery [images]="evalu.images">
                                </pv-image-gallery>
                            </div>
                        </section>

                    </div>
                    <!-- EVALUATION PANEL CONTENT END -->


                    <mat-divider></mat-divider>
                </div>
                <!-- EVALUATION PANEL END -->


                <!-- EVALUATION ACTIONS MENU -->
                <mat-menu #evalActionsMenu>
                    <ng-template matMenuContent let-evalu="evalu" let-context="context" *ngIf="selectedOrg$ | async as selectedOrg">

                        <button mat-menu-item type="button" (click)="editEvaluation(evalu.key)">
                            <mat-icon>edit</mat-icon>
                            <span>Edit Evaluation</span>
                        </button>

                        <button mat-menu-item type="button" (click)="completeEvaluation(evalu.key)">
                            <mat-icon>done</mat-icon>
                            <span>Complete Evaluation</span>
                        </button>

                        <button mat-menu-item type="button"
                                (click)="printEvaluationForm(evalu.key)">
                            <mat-icon>print</mat-icon>
                            <span>Print Evaluation Form</span>
                        </button>

                        <button mat-menu-item type="button" (click)="editNotes(evalu.key)">
                            <mat-icon>comment</mat-icon>
                            <span>Edit Notes</span>
                        </button>

                        <button mat-menu-item type="button" (click)="editImages(evalu.key)">
                            <mat-icon>insert_photo</mat-icon>
                            <span>Edit Images</span>
                        </button>

                        <button mat-menu-item type="button" (click)="viewImageGallery(evalu)">
                            <mat-icon>photo_library</mat-icon>
                            <span>View Image Gallery</span>
                        </button>

                        <button mat-menu-item type="button" (click)="editMeasurements(evalu.key)">
                            <mat-icon>straighten</mat-icon>
                            <span>Edit Measurements</span>
                        </button>

                        <button mat-menu-item type="button"
                                (click)="editCharacteristics(evalu.key)">
                            <mat-icon>tune</mat-icon>
                            <span>Edit Characteristics</span>
                        </button>

                        <ng-container *ngIf="selectedOrg?.access.role != 'guest'">
                            <button mat-menu-item (click)="deleteEvaluation(evalu.key)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete Evaluation</span>
                            </button>
                        </ng-container>

                    </ng-template>
                </mat-menu>
                <!-- EVALUATION ACTIONS MENU END -->

            </mat-card-content>

            <mat-card-actions align="right" class="no-print">
                <button mat-button color="primary" (click)="addEvaluation(state.sample?.key)">
                    <mat-icon class="mat-18">add</mat-icon>
                    <span>Add Evaluation</span>
                </button>
            </mat-card-actions>

        </mat-card>


    </div>

    <!-- BODY STATUS: ERROR / DEFAULT -->
    <div pv-view-body *ngSwitchDefault>
        <pv-view-message [heading]="state.status | descStatusTitle"
                         [message]="state.status | descStatusMessage:'Sample'" icon="error">
            <button mat-button color="primary" pvMessageAction
                    (click)="reload()">Reload</button>
        </pv-view-message>
    </div>


</pv-view>
