import { ChangeDetectionStrategy, Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Report, ReportService, Status, translateCommonErrorStatus } from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { EmailValidators } from '@core/utils';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';


export interface ReportShareDialogData {
    key: string;
}

interface ReportShareDialogStateModel {
    report: Report;
    status: Status;
}

@Component({
    selector: 'pv-report-share-dialog',
    templateUrl: 'report-share.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportShareDialog implements OnInit, OnDestroy {

    @ViewChild('linkTextarea', {static: false})
    linkTextarea: ElementRef<HTMLTextAreaElement>;

    formGroup = new FormGroup({
        sendSelf: new FormControl(false),
        contactEmails: new FormControl(null, [EmailValidators.commaEmails()])
    });

    state$ = new BehaviorSubject<ReportShareDialogStateModel>({
        report: null, status: Status.UNINITIALIZED
    });

    constructor(
        private _reportService: ReportService,
        @Inject(MAT_DIALOG_DATA) private _data: ReportShareDialogData,
        private _ref: MatDialogRef<ReportShareDialog>,
        private _dialogs: Dialog,
        private _snackbar: Snackbar,
    ){}

    ngOnInit(){

        this.state$.next({
            status: Status.LOADING,
            report: null,
        });
        this.formGroup.disable();

        this._reportService.get(this._data.key, false)
            .pipe(tap(
                rep => {
                    this.state$.next({
                        status: Status.OK,
                        report: rep,
                    });
                    this.formGroup.enable();
                },
                error => {
                    this.state$.next({
                        status: translateCommonErrorStatus(error),
                        report: null,
                    });
                    this.formGroup.enable();
                }
            ))
            .subscribe();

    }

    ngOnDestroy(){
        this.state$.complete();
    }

    getUrl(report: Report){
        return `${window.location.origin}/report-share/${report.shareKey}`;
    }

    attemptShare(){

        if(!this.formGroup.valid){
            this._snackbar.formInvalid();
            return;
        }

        const report = this.state$.getValue().report;
        const form = this.formGroup.value;
        const sendSelf = form.sendSelf;
        const contactEmails = form.contactEmails ? String(form.contactEmails).split(',') : [];

        if(report.shareKey && !sendSelf && contactEmails.length === 0){
            this._snackbar.info(
                "A link is already generated for this report."
                +" Copy & paste link to share, or specify recipients to send the link to.",
                10000
            );
            return;
        }


        this.state$.next({
            report,
            status: Status.LOADING
        });

        this._dialogs.confirm(
            "Confirm Share",
            "Are you sure you want to make this report available to anyone who has the link?",
            "Yes",
            "Cancel"
        ).afterClosed().subscribe(res => {
            if(res){
                this._reportService.share(report.key, {
                    sendSelf,
                    sendContacts: contactEmails.map(email => {return {email}})
                })
                .pipe(tap(
                    res => {
                        this.state$.next({
                            report: res,
                            status: Status.OK
                        });
                        this.formGroup.setValue({
                            contactEmails: null,
                            sendSelf: false
                        });
                    },
                    error => {
                        this.state$.next({
                            ...this.state$.getValue(),
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )).subscribe();
            }
        });

    }

    stopSharing(){

        const report = this.state$.getValue().report;

        this.state$.next({
            report,
            status: Status.LOADING
        });

        this._dialogs.confirm(
            "Delete Link",
            "Are you sure you want to remove the shared link for this report?"
            +" The report will no longer be accessible using the link.",
            "Stop Sharing",
            "Cancel"
        ).afterClosed().subscribe(res => {
            if(res){
                this._reportService.share(report.key, {dropKey: true})
                .pipe(tap(
                    res => {
                        this.state$.next({
                            report: res,
                            status: Status.OK
                        });
                        this.formGroup.setValue({
                            contactEmails: null,
                            sendSelf: false
                        })
                    },
                    error => {
                        this.state$.next({
                            ...this.state$.getValue(),
                            status: translateCommonErrorStatus(error)
                        });
                    }
                ))
                .subscribe();
            }
        });
    }

    cancel(){
        this._ref.close();
    }

    copyToClipboard(){

        this.linkTextarea.nativeElement.select();
        document.execCommand('copy');
        this._snackbar.info("Link copied!", 2000);

    }


}