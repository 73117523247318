import { Inject, Injectable } from '@angular/core';
import {
    CharacteristicIndexReportOptions,
    CharacteristicSummaryReportOptions,
    Evaluation,
    InfoSheetReportOptions,
    MeasurementSummaryOverrides,
    MeasureSummaryReportOptions,
    ReportTable,
    ReportWeather,
    Sample,
    SampleCharacteristic,
    SampleImage,
    SampleMeasurement,
    SampleType,
    TimelineReportOptions,
    ReportSampleEvalTimelineOptions,
    TableReportColumn,
    TableReportColumnType,
    CustomProtocolService, SampleCalculation, ReportSample, EvaluationService, CultivarService, Cultivar,
    ReportImageOption,
} from '@core/data';
import { arrayFromMap, arrayGroupBy, mapNumber, parseDuration, sortBy, uniqueStrings, HSL, RGB, fileExtension } from '@core/utils';
import { BucketOptions, Characteristic, CharacteristicType, Index, IndexRescaleStrategy, IntervalParams, Library, Measurement, Protocol, Property, CharacteristicRescaleStrategy } from '@library';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LIBRARY } from './library';
import { MeasurementChartCompilerService } from './measurement-chart-compiler.service';
import { CharacteristicCategoryStatusReport, CompiledCharacteristicIndexSummary, CompiledCharacteristicIndexSummaryReport, CompiledCharacteristicSummaryReport, CompiledInfoSheetReport, CompiledLegend, CompiledMeasurementSummary, CompiledMeasurementSummaryReport, CompiledTimelineData, CompiledTimelineGroup, CompiledTimelineItem, CompiledTimelineReport, CompiledTableReport, CompiledTableReportColumn, CompiledWeatherReport, MeasurementBucketSort, MeasurementStats, MeasureStatusReport, Series, TableReportSampleData } from './report-compiler.types';
import { ReportThemeCompiler, REPORT_THEMES } from './themes.service';
import { WeatherChartCompilerService } from './weather-chart-compiler.service';
import { Snackbar } from '@core/material';

const REFS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz";

function INDEX_RESCALE_POSITIVE(val: number, min: number, max: number): number {
    return (val - min) / (max - min);
}

function INDEX_RESCALE_NEGATIVE(val: number, min: number, max: number): number {
    return 1 - ((val - min) / (max - min));
}

function CHAR_RESCALE_POSITIVE(val: number, min: number, max: number): number {
    return (val - min) / (max - min);
}

function CHAR_RESCALE_NEGATIVE(val: number, min: number, max: number): number {
    return 1 - ((val - min) / (max - min));
}



@Injectable()
export class ReportCompilerService {

    constructor(
        @Inject(LIBRARY) private _library: Library,
        private _chartCompiler: MeasurementChartCompilerService,
        private _weatherChartCompiler: WeatherChartCompilerService,
        private _custom_protocolService: CustomProtocolService,
        private _evalService: EvaluationService,
        private _cultService: CultivarService,
        private _snackbar: Snackbar,
    ){}


    describeEvaluation(evalu: Evaluation) : string {
        let desc = '';

        if(evalu.label) desc += 'Label: '+evalu.label;

        return desc;
    }

    compileTheme(themeId: string){

        let options = REPORT_THEMES[0];

        if(themeId){
            options = REPORT_THEMES.find(theme => theme.id === themeId);
        }

        return new ReportThemeCompiler(options);
    }

    sortData(data: ReportSample[]){
        return [...data].sort((a, b) => {
            // Compare reportSampleIndex
            return a.reportSampleIndex - b.reportSampleIndex;
        });
    }


    compileSeries(data: Sample[], theme: ReportThemeCompiler): Series[]{

        const series: Series[] = [];

        let hsIndex = 0;
        let psIndex = 0;

        let n = 0;
        data.forEach((sample, si) => {
            let sortedEvals = <Evaluation[]>_.orderBy(sample.evals, ['evalStartDate', 'label']);

            let sampleLabel = sample.label ? sample.label.substring(0, 36) : sample.code.substr(0, 17);
            let sampleShort = sample.type.substring(0,1).toUpperCase() + (si+1);

            sortedEvals.forEach((evalu, ei) => {

                let plantedDate = null;
                let sampleTreeCount = sample.type === SampleType.PLANT ? sample.size : null;
                let sampleFruitCount = sample.type === SampleType.HARVEST ? sample.size : null;

                if(sample.type === 'harvest' && sample.plantSample) {
                    plantedDate = sample.plantSample.birthDate;
                    sampleTreeCount = sample.plantSample.size;
                } else if(sample.type === 'plant') {
                    plantedDate = sample.birthDate;
                }

                let evalLabel = evalu.label ? evalu.label.substring(0, 36) : moment(evalu.evalStartDate).format('YYYY-MM-DD');

                let startDate = moment(evalu.evalStartDate).isValid() ? moment(evalu.evalStartDate) : null;
                let endDate = moment(evalu.evalEndDate).isValid() ? moment(evalu.evalEndDate) : null;

                let color = sample.type === 'harvest' ?
                                theme.getSeriesShade(sample.type, hsIndex, ei) :
                                theme.getSeriesShade(sample.type, psIndex, ei);

                let yieldData = this.compileYieldEfficiency(evalu.measures);

                let yieldEfficienciesCalc: SampleCalculation[] = yieldData ? yieldData.yieldCalcs : [];
                let yieldEfficienciesMeas: SampleMeasurement[] = yieldData ? yieldData.yieldMeas : [];

                let evaluationMeasures: SampleMeasurement[] = JSON.parse(JSON.stringify(evalu.measures));
                let evaluationCalculations: SampleCalculation[] = [];

                let kernelUnsoundWeight: SampleMeasurement = this.compileUnsoundKernelWeight(evaluationMeasures);
                if (kernelUnsoundWeight) {
                    evaluationMeasures.push(kernelUnsoundWeight);
                }

                let macadamiaCalcs: SampleCalculation[] = this.compileMacadamiaCalculations(evaluationMeasures);

                let tonsPerHa = this.compileTonsPerHa(evalu.measures, sample.rowDistance, sample.colDistance);

                let sugarAcidRatio = this.compileSugarAcidRatio(evalu.measures, 'frut_tss', 'frut_acid');

                let bunchSugarAcidRatio = this.compileSugarAcidRatio(evalu.measures, 'bnch_tss', 'bnch_amal');

                let prodAverage = this.compileProductivityAverage(evalu.measures);

                let seedAvgerage = this.compileFruitSeedAverage(evalu.measures);

                let sugarAcidRatioCitric = this.compileSugarAcidRatio(evalu.measures, 'frut_tss', 'frut_acid_citric');

                if (yieldEfficienciesCalc) {
                    yieldEfficienciesCalc.forEach(val => {
                        evaluationCalculations.push(val);
                    });
                }

                if (yieldEfficienciesMeas) {
                    yieldEfficienciesMeas.forEach(val => {
                        evaluationMeasures.push(val);
                    });
                }

                if(macadamiaCalcs) {
                    macadamiaCalcs.forEach(val => {
                        evaluationCalculations.push(val);
                    });
                }

                if (tonsPerHa) {
                    evaluationCalculations.push(this.compileSampleCalculation(tonsPerHa, 'tons_per_ha_calc') as SampleCalculation);
                }

                if(sugarAcidRatio) {
                    evaluationCalculations.push(this.compileSampleCalculation(sugarAcidRatio, 'sugar_acid_ratio_calc') as SampleCalculation);
                }

                if(bunchSugarAcidRatio) {
                    evaluationCalculations.push(this.compileSampleCalculation(bunchSugarAcidRatio, 'bunch_sugar_acid_ratio_calc') as SampleCalculation);
                }

                if (prodAverage) {
                    evaluationCalculations.push(this.compileSampleCalculation(prodAverage, 'prod_average') as SampleCalculation);
                }
                if(sugarAcidRatioCitric) {
                    evaluationCalculations.push(this.compileSampleCalculation(sugarAcidRatioCitric, 'citric_sugar_acid_ratio_calc') as SampleCalculation);
                }
                if(seedAvgerage) {
                    evaluationCalculations.push(this.compileSampleCalculation(seedAvgerage, 'seed_count_percentage') as SampleCalculation);
                }

                series.push({
                    ref: REFS[n % REFS.length],
                    key: `${sample.key}-${evalu.key}`,
                    label: `${evalLabel} | ${sampleLabel}`,
                    shortLabel: `${evalLabel}`,
                    color: color,
                    startDate: evalu.evalStartDate,
                    endDate: evalu.evalEndDate,
                    measures: evaluationMeasures,
                    calculations: evaluationCalculations,
                    chars: evalu.chars,
                    notes: sortBy(evalu.notes, 'createdAt'),
                    images: sortBy(evalu.images, 'takenAt'),
                    sampleKey: sample.key,
                    sampleType: sample.type,
                    sampleCode: sample.code,
                    sampleLabel: sample.label,
                    sampleDescription: sample.description,
                    samplePlantedDate: plantedDate,
                    samplePlantedYear: plantedDate ? moment(plantedDate).format('YYYY') : '',
                    evalKey: evalu.key,
                    evalLabel: evalu.label,
                    evalSize: evalu.size,
                    protocolId: evalu.protocolId,
                    storageRegime: evalu.storageRegime,
                    scheduleId: evalu.scheduleId,
                    sampleBirthDate: sample.birthDate,
                    sampleDeathDate: sample.deathDate,
                    siteLabel: `${sample.site.name}, ${sample.site.block}`,
                    siteAlt: sample.site.alt ? `${sample.site.alt}` : null,
                    scionCultivarKey: sample.scionCultivarKey,
                    scionCultivarLabel: sample.scionCultivar.commonName,
                    rootstockCultivarLabel: sample.rootstockCultivar ? sample.rootstockCultivar.commonName : null,
                    sampleSize: sample.size,
                    sampleTreeCount: sampleTreeCount,
                    sampleFruitCount: sampleFruitCount,
                    rowPos: sample.rowIndex && sample.positionIndex ? 'R' + sample.rowIndex + '.' + sample.positionIndex : null,
                    seriesTonsPerHa: tonsPerHa,
                    seriesSugarAcidRatio: sugarAcidRatio,
                    seriesCitricSugarAcidRatio: sugarAcidRatioCitric,
                    seriesBunchSugarAcidRatio: bunchSugarAcidRatio,
                });
                n++;
            });

            if(sample.type === 'harvest'){
                hsIndex++;
            }else{
                psIndex++;
            }
        });

        return series;
    }

    //Compiles list of all scion cultivars in dataset
    compileCultivarList(data: Sample[]) : Cultivar[]{
        return data.map(sample => sample.scionCultivar ? sample.scionCultivar : null);
    }

    //Compiles list of all evaluations in dataset
    compileEvaluationList(data: Sample[]): Evaluation[] {
        return data.map(sample => sample.evals ? sample.evals.map(evalu => evalu ? evalu : null) : null)
                        .reduce((accumulator, value) => accumulator.concat(value), []);
    }

    compileImageSectionImages(series: Series[]): ReportImageOption[][] {
        let imageOptions: ReportImageOption[][] = [];

        series.forEach((serie) => {
            let evalOptions: ReportImageOption[] = [];
            serie.images.forEach((image, index) => {
                let option: ReportImageOption = {
                    key: image.key,
                    name: image.name,
                    fileRef: image.fileRef,
                    extension: fileExtension(image.name),
                    position: index,
                    note: image.note,
                    takenAt: image.takenAt,
                };
                evalOptions.push(option);
            })

            imageOptions.push(sortBy(evalOptions, 'takenAt'));
        })

        return imageOptions;
    }

    syncImageSectionImageData(series: Series[],  options: ReportImageOption[][]): ReportImageOption[][] {
        let optionsCopy: ReportImageOption[][] = JSON.parse(JSON.stringify(options))
        let imageOptions: ReportImageOption[][] = [];
        let imageKeys: string[][] = [];
        let flatKeys: string[] = [];

        optionsCopy.forEach(option => { imageKeys.push(option.map(opt => opt.key)) });
        imageKeys.forEach((keys) => { flatKeys.push(...keys) });

        series.forEach((serie) => {
            let rowIndex: number = null;
            let imageRow: ReportImageOption[] = [];
            let newImages: ReportImageOption[] = [];
            let existingImages: ReportImageOption[] = [];

            if (!serie && !serie.images) return;

            serie.images.forEach((image, serIndex) => {
                let img: ReportImageOption = {
                    key: image.key,
                    extension: fileExtension(image.name),
                    fileRef: image.fileRef,
                    note: image.note,
                    name: image.name,
                    takenAt: image.takenAt,
                    position: serIndex
                }

                if (flatKeys.includes(img.key)) {
                    existingImages.push(img);
                } else {
                    newImages.push(img);
                }
            });

            if (existingImages.length > 0) {
                let count = 0;
                for (let keys of imageKeys) {
                    if (keys.includes(existingImages[0].key)){
                        rowIndex = count;
                        break;
                    }

                    count ++;
                }
            }

            if (rowIndex || rowIndex >= 0) {
                existingImages.sort((a, b) => imageKeys[rowIndex].indexOf(a.key) - imageKeys[rowIndex].indexOf(b.key));
            }

            imageRow.push(...existingImages);
            imageRow.push(...newImages);
            imageOptions.push(imageRow);
        });

        return imageOptions;
    }

    private compileSugarAcidRatio(measures: SampleMeasurement[], sugarId: string, acidId: string) {
        if (!this.checkRequiredValuePair(measures, sugarId, acidId)) return;

        let sugarAverage = this.getMeasureAverage(sugarId, measures);
        let acidAverage = this.getMeasureAverage(acidId, measures);

        return sugarAverage / acidAverage;
    }

    private compileFruitSeedAverage(measures: SampleMeasurement[]){
        let seedId = "frut_seed_count"

        let seedCount = measures.filter((measure) => measure.measureId === seedId)
        let fruitWithSeed = seedCount.filter((sample) => sample.value !== 0)

        return  (fruitWithSeed.length/seedCount.length)*100
    }

    private compileTonsPerHa(measures: SampleMeasurement[], rowDistance: number, colDistance: number) {
        //If rowDistance or colDistance not set return
        if (!(rowDistance && colDistance)) return;
        let rowDist = this.toMeters(rowDistance);
        let colDist = this.toMeters(colDistance);

        let yieldId = "tree_total_yield";

        let yieldValues = this.getYieldValues(measures, yieldId);

        if (yieldValues.length === 0) return null;

        let yieldAvg = this.getSeriesYieldAvg(yieldValues);

        if (yieldAvg === -1) return null;

        return +(yieldAvg * (10000 / (rowDist * colDist)) / 1000).toFixed(2);
    }

    private compileProductivityAverage(measures: SampleMeasurement[]) {

        let yieldId = "tree_total_yield";

        let yieldValues = this.getYieldValues(measures, yieldId);

        if (yieldValues.length === 0) return null;

        let productivityAvg = this.getSeriesProductivityAvg(yieldValues);

        return productivityAvg;
    }

    private compileKgPerPicks(measures: SampleMeasurement[]): SampleMeasurement[] {
        let picks: SampleMeasurement[] = JSON.parse(JSON.stringify(measures.filter(measure => measure.measureId === 'kg_per_pick')));

        if (!picks || !picks.length) return measures;

        let total = picks.reduce((acc, pick) => {
            return acc += pick.value
        }, 0);

        picks.forEach(pick => {
            let pickYield = +((pick.value / total) * 100).toFixed(2);
            pick.value = pickYield;

            let measure = measures.find(meas => meas.measureId === pick.measureId && meas.index === pick.index)
            measure.calculatedValue = pickYield;
        });

        return measures;
    }

    private toMeters(value: number) {
        return +(value/1000).toFixed(2);
    }

    private getYieldValues(measures: SampleMeasurement[], yieldId: string) {
        let yieldValues: number[] = [];

        measures.forEach(val => {
            if(val.measureId === yieldId) {
                yieldValues.push(val.value);
            }
        });

        return yieldValues;
    }

    private getSeriesYieldAvg(values: number[]) {
        let length = values.length;
        let total = 0;

        values.forEach(val => {
            total = total + val;
        });

        if (length === 0) return -1;
        if (total === 0) return -1;

        return +(total/length).toFixed(2);
    }

    private getSeriesProductivityAvg(values: number[]){
        return +(values.reduce((accumulator, value) => accumulator + value) / values.length).toFixed(2);
    }

    private getMeasureAverage(measureId: string, measures: SampleMeasurement[]): number {
        let measureValues = measures.filter(measure => measure.measureId === measureId).map(meas => meas ? meas.value : 0);
        return measureValues.reduce(function(a, b){ return a + b}) / measureValues.length;
    }

    private compileYieldEfficiency(measures: SampleMeasurement[]): { yieldCalcs: SampleCalculation[], yieldMeas: SampleMeasurement[] } {
        let trunkCircumId = "tree_trunk_circum";
        let yieldId = "tree_total_yield";

        //If evaluation does not have required measures to calculate new measurement - return
        if (!this.checkRequiredValues(measures, trunkCircumId, yieldId)) return;

        let values = this.getRequiredValues(measures, trunkCircumId, yieldId);

        let yieldEfficiency: number[] = []

        values.forEach(valueSet => {
            yieldEfficiency.push(this.calculateYieldEfficiency(valueSet.trunk, valueSet.yieldValue));
        })

        return { yieldCalcs: this.toSampleCalculation(yieldEfficiency), yieldMeas: this.toSampleMeasure(yieldEfficiency)};
    }

    private compileMacadamiaCalculations(measures: SampleMeasurement[]) {
        //Kernel Calculations
        let huskWeightId = "nut_husk_weight";
        let NISWeightId = "nut_in_shell_weight";
        let NIHWeightId = "nut_in_husk_weight";
        let kernelWeightId = "nut_kernel_total_weight";
        let DISWeightId = "nut_in_shell_weight_dry";
        let kernelUnsoundWeightId = "nut_kernel_unsound_weight"; //needs to be calculated not a measurement available
        let kernelTotalRecoverWeightId = "nut_kernel_total_recovery_weight";
        let kernelFirstRecoverWeightId = "nut_kernel_first_recovery_weight";

        //ids of calculated values
        //Kernel Calculations
        const nut_totalHuskToNISRatio_calc = "nut_totalHuskToNISRatio_calc";
        const nut_totalHuskToNIHRatio_calc = "nut_totalHuskToNIHRatio_calc";
        const nut_totalTKR_calc = "nut_totalTKR_calc";
        const nut_totalUSRK_calc = "nut_totalUSRK_calc";
        const nut_totalUSRK2_calc = "nut_totalUSRK2_calc";
        const nut_totalUWKR_calc = "nut_totalUWKR_calc";
        const nut_totalSKR_calc = "nut_totalSKR_calc";
        const nut_totalSKR2_calc = "nut_totalSKR2_calc";

        let calculatedVals: {id:string, val:number}[] = [];

        //All of these calculations are aggregate-based (average of all samples in set)
        //Calc 4 & 5 is dependant on an sub-calculation: Unsound-Kernel Weight
        //For now use unsound-kernel weight as a placeholder for future implementation

        //Calc 1:1:
        //Ratio of Husk to Nut in Shell Weight = SUM(nut_husk_weight / nut_in_shell_weight * 100)
        //If evaluation does not have required measures to calculate specific measurement - return
        if (!this.checkRequiredValuePair(measures, huskWeightId, NISWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc1 = this.getRequiredValuePair(measures, huskWeightId, NISWeightId);
        let huskToNISRatio: number = 0;
        let totalHuskToNISRatio: number = 0;

        valuesCalc1.forEach(valueSet => {
            huskToNISRatio = (valueSet.meas1 / valueSet.meas2) * 100;
            totalHuskToNISRatio += huskToNISRatio;
        });

        totalHuskToNISRatio = totalHuskToNISRatio/valuesCalc1.length;
        calculatedVals.push({id:nut_totalHuskToNISRatio_calc, val:totalHuskToNISRatio});


        //Calc 1:2:
        //Ratio of Husk to Nut in Husk Weight =  SUM(nut_husk_weight / nut_in_husk_weight * 100)/sampleSize
        //If evaluation does not have required measures to calculate specific measurement - return
        if (!this.checkRequiredValuePair(measures, huskWeightId, NIHWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc2 = this.getRequiredValuePair(measures, huskWeightId, NIHWeightId);
        let huskToNIHRatio: number = 0;
        let totalHuskToNIHRatio: number = 0;

        valuesCalc2.forEach(valueSet => {
            huskToNIHRatio = (valueSet.meas1 / valueSet.meas2) * 100;
            totalHuskToNIHRatio += huskToNIHRatio;
        });

        totalHuskToNIHRatio = totalHuskToNIHRatio/valuesCalc2.length;
        calculatedVals.push({id:nut_totalHuskToNIHRatio_calc, val:totalHuskToNIHRatio});


        //Calc 1:3:
        //TKR = SUM(nut_kernel_total_weight / nut_in_shell_weight_dry) * 100)/sampleSize
        if (!this.checkRequiredValuePair(measures, kernelWeightId, DISWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc3 = this.getRequiredValuePair(measures, kernelWeightId, DISWeightId);
        let TKR: number = 0;
        let totalTKR: number = 0;

        valuesCalc3.forEach(valueSet => {
            TKR = (valueSet.meas1 / valueSet.meas2);
            totalTKR += TKR;
        });

        totalTKR = totalTKR/valuesCalc3.length*100;
        calculatedVals.push({id:nut_totalTKR_calc, val:totalTKR});

        //Calc 1:4 (dependant on calculcated unsound kernel weight):
        //USRK = SUM(unsound_weight / total_kernel_weigth * 100)/sampleSize
        if (!this.checkRequiredValuePair(measures, kernelUnsoundWeightId, kernelWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc4 = this.getRequiredValuePair(measures, kernelUnsoundWeightId, kernelWeightId);
        let totalKernelUnsoundWeight: number = 0;
        let totalKernelWeight: number = 0;
        let totalUSRK: number = 0;

        valuesCalc4.forEach(valueSet => {
            totalKernelUnsoundWeight += valueSet.meas1;

            totalKernelWeight += valueSet.meas2;
        });


        totalKernelUnsoundWeight = totalKernelUnsoundWeight/valuesCalc4.length;
        totalKernelWeight = totalKernelWeight/valuesCalc4.length;

        totalUSRK = (totalKernelUnsoundWeight/totalKernelWeight)*100;
        calculatedVals.push({id:nut_totalUSRK_calc, val:totalUSRK});


        //Calc 1:5 (dependant on calculcated unsound kernel weight):
        //USRK2 = SUM((unsound_weight / nut_in_shell_weight_dry) * 100)/sampleSize
        if (!this.checkRequiredValuePair(measures, kernelUnsoundWeightId, DISWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc5 = this.getRequiredValuePair(measures, kernelUnsoundWeightId, DISWeightId);
        let USRK2: number = 0;
        let totalUSRK2: number = 0;

        valuesCalc5.forEach(valueSet => {
            USRK2 += valueSet.meas2;
        });

        USRK2 = USRK2/valuesCalc5.length;
        totalUSRK2 = totalKernelUnsoundWeight/USRK2*100

        calculatedVals.push({id:nut_totalUSRK2_calc, val:totalUSRK2});

        //Calc 1:6:
        //UWKR = SUM((nut_kernel_total_recovery_weight/TKR) * 100)/sampleSize
        if (!this.checkRequiredValuePair(measures, kernelTotalRecoverWeightId, kernelTotalRecoverWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc6 = this.getRequiredValuePair(measures, kernelTotalRecoverWeightId, kernelTotalRecoverWeightId);
        let totalUWKR: number = 0;
        let totalMeas6: number = 0;

        valuesCalc6.forEach(valueSet => {
            totalMeas6 += valueSet.meas1;
        });

        totalUWKR = (totalMeas6/valuesCalc6.length)/totalKernelWeight*100;
        calculatedVals.push({id:nut_totalUWKR_calc, val:totalUWKR});

        //Calc 1:7:
        //SKR = SUM((nut_kernel_first_recovery_weight/TKR) * 100)/sampleSize
        if (!this.checkRequiredValuePair(measures, kernelFirstRecoverWeightId, kernelFirstRecoverWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc7 = this.getRequiredValuePair(measures, kernelFirstRecoverWeightId, kernelFirstRecoverWeightId);
        let totalSKR: number = 0;
        let totalMeas7: number = 0;

        valuesCalc7.forEach(valueSet => {
            totalMeas7 += valueSet.meas1;
        });

        totalSKR = (totalMeas7/valuesCalc7.length)/totalKernelWeight*100;
        calculatedVals.push({id:nut_totalSKR_calc, val:totalSKR});

        //Calc 1:8:
        //SKR2 = SUM((nut_kernel_first_recovery_weight/DIS) * 100)/sampleSize
        if (!this.checkRequiredValuePair(measures, kernelFirstRecoverWeightId, DISWeightId)) return;
        //Get required measurement pairs as arrays
        let valuesCalc8 = this.getRequiredValuePair(measures, kernelFirstRecoverWeightId, DISWeightId);
        let SKR2: number = 0;
        let totalSKR2: number = 0;

        valuesCalc8.forEach(valueSet => {
            SKR2 = (valueSet.meas1 / valueSet.meas2);
            totalSKR2 += SKR2;
        });

        totalSKR2 = totalSKR2/valuesCalc8.length*100;
        calculatedVals.push({id:nut_totalSKR2_calc, val:totalSKR2});

        //Calc 2:1:
        let calc2Vals = this.compileUnsoundKernelRecoveryTKR(measures, totalKernelWeight, totalKernelUnsoundWeight);

        calc2Vals.forEach(value => {
            calculatedVals.push({id:value.measureId, val:value.value});
        });

        //Calc 2:2:
        let calc3Vals = this.compileUnsoundKernelRecoveryUSKR(measures, totalKernelWeight, totalKernelUnsoundWeight);
        calc3Vals.forEach(value => {
            calculatedVals.push({id:value.measureId, val:value.value});
        });

        //Calculated values (8 - Kernel Calcs) + (32 - Diseases Calc) all units in percentage (%):
        //needs to be parced into measurements availble for custom table selection on reports
        return this.macadamiaToSampleCalc(calculatedVals);
    }

    private macadamiaToSampleCalc(vals:{id:string, val:number}[]) {
        let macadamiaCalcVals: SampleCalculation[] = [];

        vals.forEach(obj => {
            macadamiaCalcVals.push({
                calcId: obj.id,
                value: obj.val,
                index: 0
            })
        });

        return macadamiaCalcVals;
    }

    private compileUnsoundKernelRecoveryTKR(measures: SampleMeasurement[], totalKernelWeight: number, totalWeight: number) {
        let calcValue = 0;
        let unsoundValue = 0;
        let calcValues = [];

        let measIds = [
            "nut_kernel_mould_weight",
            "nut_kernel_791_weight",
            "nut_kernel_pregerm_weight",
            "nut_kernel_immat_weight",
            "nut_kernel_misshap_weight",
            "nut_kernel_sponginess_weight",
            "nut_kernel_hollow_weight",
            "nut_kernel_decom_weight",
            "nut_kernel_adhered_weight",
            "nut_kernel_insect_weight",
            "nut_kernel_discolor_weight",
            "nut_kernel_dark_weight",
            "nut_kernel_internal_weight",
            "nut_kernel_onion_weight",
            "nut_kernel_distal_weight",
            "nut_kernel_phys_weight",
        ];

        //loop through all measures
        measIds.forEach(meas => {
            calcValue = 0;
            unsoundValue = 0;
            //check if measure exists (else ignore)
            //if measure exists, aggregate the array and add to total
            if(this.checkRequiredValue(measures, meas)) {
                let vals = this.getRequiredValue(measures, meas);

                for( let i = 0; i < vals.length; i++ ) {
                    unsoundValue += parseInt(vals[i], 10);
                }

                unsoundValue = unsoundValue/vals.length;
                unsoundValue = (unsoundValue/totalKernelWeight)*100;

                calcValues.push(this.toSampleMeasureSingle(unsoundValue, meas+'_TKR_calc'));
            }
        });

        if(calcValues) return calcValues;
        else return null;

    }

    private compileUnsoundKernelRecoveryUSKR(measures: SampleMeasurement[], totalUSKR: number, totalWeight: number) {
        let USKR = totalUSKR;
        let calcValue = 0;
        let unsoundValue = 0;
        let calcValues = [];

        let measIds = [
            "nut_kernel_mould_weight",
            "nut_kernel_791_weight",
            "nut_kernel_pregerm_weight",
            "nut_kernel_immat_weight",
            "nut_kernel_misshap_weight",
            "nut_kernel_sponginess_weight",
            "nut_kernel_hollow_weight",
            "nut_kernel_decom_weight",
            "nut_kernel_adhered_weight",
            "nut_kernel_insect_weight",
            "nut_kernel_discolor_weight",
            "nut_kernel_dark_weight",
            "nut_kernel_internal_weight",
            "nut_kernel_onion_weight",
            "nut_kernel_distal_weight",
            "nut_kernel_phys_weight",
        ];

        //loop through all measures
        measIds.forEach(meas => {
            calcValue = 0;
            unsoundValue = 0;
            //check if measure exists (else ignore)
            //if measure exists, aggregate the array and add to total
            if(this.checkRequiredValue(measures, meas)) {
                let vals = this.getRequiredValue(measures, meas);

                for( let i = 0; i < vals.length; i++ ) {
                    unsoundValue += parseInt(vals[i], 10);
                }

                unsoundValue = unsoundValue/vals.length;
                unsoundValue = (unsoundValue/totalWeight)*100;

                calcValues.push(this.toSampleMeasureSingle(unsoundValue, meas+'_USKR_calc'));
            }
        });

       if(calcValues) return calcValues;
       else return null;
    }

    private compileUnsoundKernelWeight(measures: SampleMeasurement[]) {
        let totalkernelUnsoundWeight = 0;
        let kernelUnsoundWeight = 0;

        let measIds = [
            "nut_kernel_mould_weight",
            "nut_kernel_791_weight",
            "nut_kernel_pregerm_weight",
            "nut_kernel_immat_weight",
            "nut_kernel_misshap_weight",
            "nut_kernel_sponginess_weight",
            "nut_kernel_hollow_weight",
            "nut_kernel_decom_weight",
            "nut_kernel_adhered_weight",
            "nut_kernel_insect_weight",
            "nut_kernel_discolor_weight",
            "nut_kernel_dark_weight",
            "nut_kernel_internal_weight",
            "nut_kernel_onion_weight",
            "nut_kernel_distal_weight",
            "nut_kernel_phys_weight",
        ];

        //loop through all measures
        measIds.forEach(meas => {
            kernelUnsoundWeight = 0;

            //check if measure exists (else ignore)
            //if measure exists, aggregate the array and add to total
            if(this.checkRequiredValue(measures, meas)) {
                let vals = this.getRequiredValue(measures, meas);

                for( let i = 0; i < vals.length; i++ ) {
                    kernelUnsoundWeight += parseInt(vals[i], 10);
                }

                totalkernelUnsoundWeight += kernelUnsoundWeight;
            }

        });

        return this.toSampleMeasureSingle(totalkernelUnsoundWeight, 'nut_kernel_unsound_weight');
    }


    private toSampleMeasureSingle(val: number, measId: string) {

        let sampleMeas: SampleMeasurement;

        sampleMeas = {
                measureId: measId,
                value: val,
                index: 0,
        };

        return sampleMeas;
    }

    private toSampleCalculationSingle(val: number, calId: string) {

        let sampleCalc: SampleCalculation;

        sampleCalc = {
                calcId: calId,
                value: val,
                index: 0,
        };

        return sampleCalc;
    }

    private checkRequiredValue(measures: SampleMeasurement[], measId: String): Boolean {
        let measFound = false;

        measures.forEach(measure => {
            if (measure.measureId === measId) {
                measFound = true;
            }
        })

        return measFound;
    }

    private getRequiredValue(measures: SampleMeasurement[], measId: String) {

        let vals = [];

        measures.forEach(measure => {
            if (measure.measureId === measId) vals.push(measure.value);
        });

        return vals;
    }


    private toSampleCalculation(yieldEfficiencies: number[]) {
        let counter = 0;
        const calcId = "tree_yield_eff";
        let yieldEffList: SampleCalculation[] = [];

        yieldEfficiencies.forEach(val => {
            yieldEffList.push({
                calcId: calcId,
                value: val,
                index: counter
            })
            counter++;
        });

        return yieldEffList;
    }

    private toSampleMeasure(yieldEfficiencies: number[]) {
        let counter = 0;
        const measId = "tree_yield_eff";
        let yieldEffList: SampleMeasurement[] = [];

        yieldEfficiencies.forEach(val => {
            yieldEffList.push({
                measureId: measId,
                value: val,
                index: counter
            });
            counter++;
        });

        return yieldEffList;
    }

    private compileSampleCalculation(value: number | number[], id: string): SampleCalculation | SampleCalculation[] {
        if (value instanceof Array && value != null && value.length > 0) {
            let data: SampleCalculation[] = [];
            let counter = 0;

            value.forEach(val => {
                data.push({
                    calcId: id,
                    value: val,
                    index: counter
                });
                counter++;
            });

            return data;

        } else if (typeof value === 'number' && value != null) {
            let data: SampleCalculation = {
                calcId: id,
                value: value,
                index: 0,
            }

            return data;
        }
    }

    private calculateYieldEfficiency(trunkCircum: number, yieldVal: number) {
        let tcsa;
        if (trunkCircum) tcsa = this.calculateTCSA(trunkCircum);

        return +(yieldVal/tcsa).toFixed(2);
    }

    private calculateTCSA(trunkCircum: number) {
        return Math.pow(this.mmToCm(trunkCircum), 2)/(4*Math.PI);
    }

    private mmToCm(value: number): number {
        return value/10;
    }

    private checkRequiredValues(measures: SampleMeasurement[], trunkCircumId: String, yieldId: String): Boolean {
        let yieldFound = false;
        let trunkCircumFound = false;

        measures.forEach(measure => {
            if (measure.measureId === trunkCircumId) trunkCircumFound = true;
            if (measure.measureId === yieldId) yieldFound = true;
        })

        return yieldFound && trunkCircumFound;
    }

    private checkRequiredValuePair(measures: SampleMeasurement[], meas1Id: String, meas2Id: String): Boolean {
        let meas1Found = false;
        let meas2Found = false;

        measures.forEach(measure => {
            if (measure.measureId === meas1Id) meas1Found = true;
            if (measure.measureId === meas2Id) meas2Found = true;
        })

        return meas1Found && meas2Found;
    }

    private getRequiredValues(measures: SampleMeasurement[], trunkCircumId: String, yieldId: String) {
        let data: { trunk: number; yieldValue: number }[] = [];
        let trunkCircumVals = [];
        let yieldVals = [];

        measures.forEach(measure => {
            if (measure.measureId === trunkCircumId) trunkCircumVals.push(measure.value);

            if (measure.measureId === yieldId) yieldVals.push(measure.value);
        });

        let counter = 0;
        if (trunkCircumVals.length === yieldVals.length) {
            trunkCircumVals.forEach(val => {
                data[counter] = {
                    trunk: val,
                    yieldValue: yieldVals[counter]
                }
                counter++;
            })
        }

        return data;
    }

    private getRequiredValuePair(measures: SampleMeasurement[], meas1Id: String, meas2Id: String) {
        let data: { meas1: number; meas2: number }[] = [];
        let meas1Val : number = 0;
        let meas2Val : number = 0;
        let meas1Vals = [];
        let meas2Vals = [];

        measures.forEach(measure => {
            if (measure.measureId === meas1Id) meas1Vals.push(measure.value);
            if (measure.measureId === meas2Id) meas2Vals.push(measure.value);
        });

        meas1Val = meas1Vals.reduce(function(a, b){ return a + b; });
        meas2Val = meas2Vals.reduce(function(a, b){ return a + b; });

        let counter = 0;

        data[counter] = {
            meas1: meas1Val,
            meas2: meas2Val,
        }

        return data;
    }

    compileLegend(samples: Sample[], series: Series[]): CompiledLegend[]{

        return samples.map(sample => {

            const s = series.filter(series => {
                return series.sampleKey === sample.key;
            });

            return {
                id: sample.key,
                sample,
                series: s
            };

        });


    }

    compileCharacteristicSummary(series: Series[], options: CharacteristicSummaryReportOptions): CompiledCharacteristicSummaryReport {

        let isEmpty = true;
        let groups = [];
        let errors = [];

        let includedChars = [];

        if(!(Array.isArray(options.includeChars) && options.includeChars.length > 0)){
            errors.push(`No characteristics selected.`);
            return {
                isEmpty,
                options,
                groups,
                errors
            };
        }else if(options.includeChars[0] === '*'){
            let protocolIds = series.map(series => series.protocolId);
            let protocol = this.combinationProtocol(protocolIds);
            includedChars = protocol.chars;
        }else{
            includedChars = options.includeChars;
        }

        includedChars.forEach(charId => {
            try {
                let char = this._library.chars.get(charId);

                if(!char){
                    throw Error(`Characteristic ${charId} does not exist.`);
                }

                let group = groups.find(group => group.category.id === char.categoryId);
                let values = [];
                if(!group){
                    let category = this._library.categories.get(char.categoryId);
                    group = {
                        id: category.id,
                        category,
                        isEmpty: true,
                        chars: []
                    };
                    groups.push(group);
                }

                series.forEach(serie => {
                    serie.chars
                        .forEach(charValue => {
                            let char = this._library.chars.get(charValue.charId)
                            let showMindesc = (char.type === CharacteristicType.Interval && char.params.minDesc)

                            if(charValue.charId === charId && (charValue.value !== null || showMindesc)){
                                let rescaled = null;

                                if (char.hasOwnProperty('rescale')) {
                                    rescaled = Math.floor(this.getCharRescaleFunction(char.rescale)(parseInt(charValue.value), char.params['min'] || 0, char.params['max'] || 100) * char.weight || 100);
                                }

                                values.push({
                                    value: charValue.value,
                                    series: serie,
                                    rescaled: rescaled
                                });
                                group.isEmpty = false;
                                isEmpty = false;
                            }
                        });
                });

                group.chars.push({
                    id: char.id,
                    options: char,
                    values
                });
            }catch(e){
                console.warn(e);
                errors.push('Error compiling characteristic summary: ' + e.message);
            }
        });

        return {
            isEmpty,
            options,
            groups,
            errors
        };
    }

    compileCharacteristicIndexSummary(series: Series[], options: CharacteristicIndexReportOptions): CompiledCharacteristicIndexSummaryReport{
        let isEmpty = true;
        const indexes: CompiledCharacteristicIndexSummary[] = [];
        const errors = [];

        let includedIndexes = [];

        if(!(Array.isArray(options.includeIndexes) && options.includeIndexes.length > 0)){
            errors.push(`No indexes selected.`);
        }else if(options.includeIndexes[0] === '*'){
            let protocolIds = series.map(series => series.protocolId);
            let protocol = this.combinationProtocol(protocolIds);
            includedIndexes = protocol.indexes;
        }else{
            includedIndexes = options.includeIndexes;
        }

        includedIndexes.forEach(indexId => {

            try {
                let indexReport = this.compileCharacteristicIndexReport(series, indexId, options.showCharts, options.showData);
                indexes.push(indexReport);
                if(!indexReport.isEmpty) isEmpty = false;
            }catch(e){
                console.warn(e);
                errors.push('Error compiling characteristic index: ' + e.message);
            }

        });

        return {
            options,
            isEmpty,
            indexes,
            errors
        };

    }

    compileCharacteristicIndexReport(series: Series[], indexId: string, withChart = true, withData = true): CompiledCharacteristicIndexSummary{

        const index = this._library.indexes.get(indexId);
        if(!index){
            throw Error(`Index ${indexId} does not exist.`);
        }

        let isEmpty = true;

        const overallScore = {
            scores: [],
            avg: null
        };

        let overallAccum = 0, overallWeight = 0;

        const seriesScores: {
            isComplete: boolean;
            scores: number[];
            values: number[];
            avg: number;
        }[] = [];

        const chars = index.chars.map(indexChar => {
            let char = this._library.chars.get(indexChar.charId);
            if(!char){
                throw Error(`EvaluationReporter: Index characteristic does not exist for charId ${indexChar.charId}.`);
            }
            if(char.type !== CharacteristicType.Interval){
                throw Error(`EvaluationReporter: Index characteristic must be of interval type, found ${char.type}.`);
            }
            return char;
        });

        series.forEach(serie => {
            // let values = [];
            let seriesScore = {
                isComplete: true,
                scores: [],
                values: [],
                avg: 0
            };
            let seriesAccum = 0;
            let seriesWeight = 0;
            chars.forEach((char, ci) => {
                let params = <IntervalParams>char.params;
                let charValue = serie.chars.find(charValue => {
                    return charValue.charId === char.id && charValue.value !== null;
                });
                if(!charValue){
                    seriesScore.isComplete = false;
                    return;
                }

                let value = parseInt(charValue.value);
                let score = Math.floor(this.getRescaleFunction(index.chars[ci].rescale)(value, params.min, params.max) * index.chars[ci].weight);

                seriesScore.values.push(value);
                seriesScore.scores.push(score);
                seriesAccum += score;
                seriesWeight += index.chars[ci].weight;
            });
            if(seriesScore.isComplete){
                isEmpty = false;
                seriesScore.avg = (seriesAccum / seriesWeight * 100);
            }

            seriesScores.push(seriesScore);
        });

        let overallAvgAccum = 0;
        let overallAvgWeight = 0;


        index.chars.forEach((indexChar, ci) => {

            let charAccum = 0;
            let charWeight = 0;

            seriesScores.forEach(series => {
                if(series.isComplete){
                    charAccum += series.scores[ci];
                    charWeight += indexChar.weight;
                    overallAvgAccum += series.scores[ci];
                    overallAvgWeight += indexChar.weight;
                }
            });

            if(charWeight > 0){
                overallScore.scores.push(charAccum / charWeight * 100);
            }else{
                overallScore.scores.push(NaN);
            }

        });

        overallScore.avg = overallAvgAccum / overallAvgWeight * 100;

        let report: CompiledCharacteristicIndexSummary = {
            id: index.id,
            series: series,
            isEmpty,
            chars,
            index,
            overallScore,
            seriesScores,
            showChart: withChart,
            showData: withData
        };

        if(withChart && !isEmpty){
            report.charts = this._chartCompiler.compileCharacteristicIndexCharts(report);
        }

        return report;

    }

    getRescaleFunction(rescale: IndexRescaleStrategy): (val: number, min: number, max: number) => number {

        switch(rescale){
            case IndexRescaleStrategy.NEGATIVE:
                return INDEX_RESCALE_NEGATIVE;
            case IndexRescaleStrategy.POSITIVE:
            default:
                return INDEX_RESCALE_POSITIVE;
        }

    }

    getCharRescaleFunction(rescale: CharacteristicRescaleStrategy): (val: number, min: number, max: number) => number {

        switch(rescale){
            case CharacteristicRescaleStrategy.NEGATIVE:
                return CHAR_RESCALE_NEGATIVE;
            case CharacteristicRescaleStrategy.POSITIVE:
            default:
                return CHAR_RESCALE_POSITIVE;
        }

    }

    compileMeasurementSummaryReport(series: Series[], options: MeasureSummaryReportOptions, section?: string): CompiledMeasurementSummaryReport{

        let isEmpty = true;
        const reports: CompiledMeasurementSummary[] = [];
        const errors = [];

        let includedMeasures = [];

        if(!(Array.isArray(options.includeMeasures) && options.includeMeasures.length > 0)){
            errors.push(`No measures selected.`);
        }else if(options.includeMeasures[0] === '*' && section === 'measurementSummary'){
            let protocolIds = series.map(series => series.protocolId);
            let protocol = this.combinationProtocol(protocolIds);
            includedMeasures = protocol.measures;
        }else{
            includedMeasures = options.includeMeasures;
        }

        includedMeasures.forEach(measureId => {
            try {

                let overrides = Array.isArray(options.overrides) ?
                    options.overrides.find(o => o.measureId === measureId) : null;

                        overrides = {
                            ...overrides,
                            showChart: options.showCharts,
                            showData: options.showData,
                            showStats: options.showStats
                        };

                let report = this.compileMeasurementSummary(series, measureId, overrides, section);
                reports.push(report);
                if(!report.isEmpty) isEmpty = false;
            }catch(e){
                console.warn(e);
                errors.push('Error compiling measurement summary: ' + e.message);
            }
        });


        return {
            options,
            measures: reports,
            isEmpty,
            errors
        };
    }

    compileMeasurementSummary(series: Series[], measureId: string, overrides: MeasurementSummaryOverrides, section?: string): CompiledMeasurementSummary{
        let measureIsEmpty = true;
        let hasKgPicks: boolean = false;
        const options = this._library.measures.get(measureId);

        if (measureId === 'kg_per_pick_perc') {
            series.forEach(serie => {
                let picks: SampleMeasurement[] = serie.measures.filter(measure => measure.measureId === 'kg_per_pick')
                if (serie.measures && picks.length > 0) hasKgPicks = true;
            });
        }

        if(!options){
            throw Error(`Measurement ${measureId} does not exist.`);
        }

        const bucketId = (overrides.bucketId) ? overrides.bucketId : options.defaultBucket;
        const bucket = this._library.buckets.get(bucketId);

        if(!options){
            throw Error(`Measurement bucket ${bucketId} does not exist.`);
        }

        const chartId = (overrides.chartId) ? overrides.chartId : options.defaultChart;
        const chart = this._library.charts.get(chartId);

        if(!options){
            throw Error(`Measurement chart ${chartId} does not exist.`);
        }

        const withChart = (overrides.showChart !== undefined) ? overrides.showChart : true;
        const withData = (overrides.showData !== undefined) ? overrides.showData : false;
        const withStats = (overrides.showStats !== undefined) ? overrides.showStats : true;
        const seriesStats: MeasurementStats[] = [];
        const seriesCounts: number[][] = [];
        const seriesTotal: number[] = [];
        const seriesSum: number[] = [];
        const bucketSorter = new BucketSorter(bucket);

        let allValues = [];

        series.forEach((serie, index) => {
            let values:any[] = [];
            let seriesIsEmpty = true;

            if (!hasKgPicks) {
                serie.measures.forEach(measureValue => {
                    let isValidMeasure = typeof measureValue.value === "number" && measureValue.measureId === measureId;
                    if( isValidMeasure && section !== 'infosheet'){
                        values.push(measureValue.value);
                        seriesIsEmpty = false;
                        measureIsEmpty = false;
                    } else if (isValidMeasure && section === 'infosheet') {
                        values.push(...measureValue.allValues);
                        seriesTotal.push(measureValue.total);
                        seriesSum.push(measureValue.sum);
                        seriesIsEmpty = false;
                        measureIsEmpty = false;
                    }
                });
            } else {
                let measures = this.compileKgPerPicks(JSON.parse(JSON.stringify(serie.measures))).filter(meas => meas.measureId === 'kg_per_pick');

                measures.forEach(measure => {
                    seriesIsEmpty = false;
                    measureIsEmpty = false;

                    if (section === 'infosheet') {
                        let total = measure.allValues.reduce((acc, val) => acc + val, 0);

                        if (!total) return;

                        values.push(...measure.allValues.map(val => ((val / total) * 100).toFixed(2)));
                        return;
                    }

                    values.push(measure.calculatedValue);
                })

            }

            if(!seriesIsEmpty && !hasKgPicks){
                let stats = this.compileStats(values);
                seriesStats.push(stats);
                bucketSorter.pushSeries(values);
                allValues = allValues.concat(values);
            } else if (!seriesIsEmpty && hasKgPicks) {
                let stats = this.compileStats(values);
                seriesStats.push(stats);
                allValues = allValues.concat(values);
                bucketSorter.pushCompiledSeries(values);
            } else if (seriesIsEmpty && hasKgPicks) {
                seriesStats.push(null);
                bucketSorter.pushCompiledSeries([]);
            } else{
                seriesStats.push(null);
                bucketSorter.pushSeries([]);
            }
        });

        bucketSorter.trim();

        let allStats = null;

        if(allValues.length > 0){
            allStats = this.compileStats(allValues, true);
        }

        let report: CompiledMeasurementSummary =  {
            id: options.id,
            series: series,
            isEmpty: measureIsEmpty,
            allStats,
            seriesStats,
            seriesCounts,
            seriesTotal,
            seriesSum,
            options,
            chartOptions: chart,
            bucketOptions: bucket,
            showChart: withChart,
            showData: withData,
            showStats: withStats,
            bucketSort: bucketSorter.get(),
        };

        let displayMode = this.determineDisplayMode(section, hasKgPicks);

        if(withChart && !measureIsEmpty){
            report.chart = this._chartCompiler.compileMeasureSummaryChart(report, section, displayMode, hasKgPicks);
        }

        return report;

    }

    determineDisplayMode(section: string, hasKgPicks): string {
        if (section === 'infosheet' || hasKgPicks) return 'Percentage [%]';
        return 'Count'
    }


    compileStats(unsorted: number[], population = false) : MeasurementStats {

        let values: Float64Array = (new Float64Array(unsorted)).sort();

        let n = values.length;

        if(n === 0){
            throw Error("Attempting to compile stats on 0 items.");
        }

        let min = values[0];
        let max = values[n - 1];

        // min max and accum (look for errors)
        let accum: number = 0;
        values.forEach(val => {
            accum += val;
        });

        // mean (average)
        let mean: number = accum / n;

        // variance (summed distance from mean)
        let vari: number = 0;

        values.forEach(val => {
            vari += Math.pow(val - mean, 2);
        });

        if(population){
            vari = vari / n;
        }else{
            vari = vari / (n-1);
        }


        // median (middle value of sorted values array)
        let median: number, q1: number, q3: number;
        let halfN = Math.floor(n/2);

        if(n % 2)
        {
            median = values[halfN];
            q1 = values[Math.floor(halfN/2)];
            q3 = values[halfN + Math.floor(halfN/2)];
        }
        else
        {
            median = (values[halfN-1] + values[halfN]) / 2;
            q1 = values[Math.floor(halfN/2)];
            q3 = values[halfN + Math.floor(halfN/2)];
        }

        // standard deviation
        let sd: number = Math.sqrt(vari);
        let sdl: number = mean - sd;
        let sdh: number = mean + sd;

        // standard error
        let se: number = (sd/Math.sqrt(n));

        return {
            n,
            vari,
            se,
            sd,
            sdl,
            sdh,
            mean,
            median,
            min,
            max,
            values,
            q1,
            q3
        };

    }

    combinationProtocol(protocolIds: string[]) : Protocol{

        let protocolsDict = this._custom_protocolService.allProtocols();

        let protocols = uniqueStrings(protocolIds).map(id => protocolsDict.get(id));
        let sampleType: any = 'plant';
        let chars = [];
        let measures = [];
        let indexes = [];

        protocols.forEach(proto => {
            if (proto) { //protocols can be undefined if it was deleted
                sampleType = proto.sampleType;
                chars = chars.concat(proto.chars);
                measures = measures.concat(proto.measures);
                indexes = indexes.concat(proto.indexes);
            }
        });

        return {
            id: uniqueStrings(protocolIds).join(','),
            title: 'Combination Protocol',
            description: '',
            sampleType: sampleType,
            chars: uniqueStrings(chars),
            measures: uniqueStrings(measures),
            indexes: uniqueStrings(indexes),
            crop: '', //todo fix!
        };
    }

    charCategoryStatusReport(evalu: Evaluation): CharacteristicCategoryStatusReport[]{

        let statsMap: Map<string, CharacteristicCategoryStatusReport> = new Map();

        let protocolsDict = this._custom_protocolService.allProtocols();
        let protocol = protocolsDict.get(evalu.protocolId);

        if(protocol){

            if(protocol.chars.length === 0) return null;


            protocol.chars.forEach(charId => {
                let char = this._library.chars.get(charId)
                let category = this._library.categories.get(char.categoryId);

                if(!statsMap.has(category.id)){
                    statsMap.set(category.id, {
                        id: category.id,
                        label: category.label,
                        completed: 0,
                        total: 0
                    });
                }

                let stats = statsMap.get(category.id);
                let value = evalu.chars.find(value => value.charId === charId);

                if(value !== undefined && value !== null)  stats.completed++;
                stats.total++;
            });

            return arrayFromMap(statsMap);
        }

        return null;

    }

    measureStatusReport(evalu: Evaluation): MeasureStatusReport[]{
        let statsMap: Map<string, MeasureStatusReport> = new Map();

        let protocol = this._custom_protocolService.allProtocols().get(evalu.protocolId);

        if(protocol){

            if(protocol.measures.length === 0) return null;

            protocol.measures.forEach(measureId => {
                let measure = this._library.measures.get(measureId);

                if(!measure){
                    console.warn(`EvaluationReporter: Measurement '${measureId}' not found for measureStatusReport`);
                    return;
                }

                statsMap.set(measure.id, {
                    id: measure.id,
                    label: measure.label,
                    unit: measure.unit,
                    completed: 0,
                    total: evalu.size
                });

            });

            evalu.measures.forEach(measureValue => {
                if(measureValue.value === null)  return;

                if(statsMap.has(measureValue.measureId)){
                    let stats = statsMap.get(measureValue.measureId);
                    stats.completed++;
                }else{
                    console.warn(`EvaluationReporter: Evaluation contains measurement '${measureValue.measureId}' not in protocol ${protocol.id}`);
                }
            });

            return arrayFromMap(statsMap);

        }else{
            console.warn(`EvaluationReporter: Protocol '${evalu.protocolId}' not found for measureStatusReport`);
        }

        return null;

    }

    compileWeatherReport(data: ReportWeather): CompiledWeatherReport{

        const key = `${data.stationKey}-${data.reportId}`;
        const options = this._library.weatherReports.get(data.reportId);
        const chart = this._weatherChartCompiler.compileReportChart(data);

        return {
            ...data,
            key,
            chart,
            options
        };

    }

    compileTimeline(series: Series[], options: TimelineReportOptions, evalsTimelineOptions?: { [key: string]: ReportSampleEvalTimelineOptions }): CompiledTimelineReport {
        const timelines: CompiledTimelineData[] = [];
        const timelinesOptions: ReportSampleEvalTimelineOptions[] = [];

        series.forEach(serie => {
            let timelineStartDate: moment.Moment, timelineEndDate: moment.Moment;
            if (evalsTimelineOptions) {
                let evalTimelineOptions = evalsTimelineOptions[serie.evalKey];
                if (evalTimelineOptions) {
                    if (evalTimelineOptions.exclude) return;
                    if (evalTimelineOptions.startDate) timelineStartDate = moment(evalTimelineOptions.startDate);
                    if (evalTimelineOptions.endDate) timelineEndDate = moment(evalTimelineOptions.endDate);
                }
            }
            if (!timelineStartDate) timelineStartDate = serie.storageRegime ? moment(serie.sampleBirthDate) : moment(serie.startDate);
            if (!timelineEndDate) timelineEndDate = serie.endDate ? moment(serie.endDate) : timelineStartDate.clone().add(1, 'years');

            const charItems: CompiledTimelineItem[] = [];
            const evalItems: CompiledTimelineItem[] = [];

            if(serie.startDate){
                evalItems.push({
                    id: serie.key,
                    title: serie.label,
                    type: 'background',
                    color: serie.color,
                    startDate: moment(serie.startDate),
                    endDate: serie.endDate ? moment(serie.endDate) : moment(),
                });
            }

            serie.chars.forEach(charValue => {
                if(!charValue.value) return;

                const char = this._library.chars.get(charValue.charId);
                if(!char || char.type !== CharacteristicType.Event) return;

                charItems.push({
                    id: char.id,
                    type: 'box',
                    startDate: moment(charValue.value),
                    color: char.params.color,
                    title: char.label
                });
            });

            if(serie.storageRegime){
                const start = moment(serie.sampleBirthDate);
                let rollingStart = start.clone();

                serie.storageRegime.forEach((storageItem, i) => {

                    let dur = parseDuration(storageItem.duration);
                    let hue = mapNumber(storageItem.temp, -5, 10, 240, 120);

                    charItems.push({
                        id: `storage_${i}`,
                        type: 'background',
                        title: `${storageItem.duration} @ ${storageItem.temp.toFixed(1)}&deg;C`,
                        startDate: rollingStart.clone(),
                        endDate: rollingStart.add(dur.time, dur.unit).clone(),
                        color: `hsla(${hue},80%,70%,0.7)`
                    });
                });
            }

            if(charItems.length === 0) return;


            const evalGroup: CompiledTimelineGroup = {
                id: 'eval',
                items: evalItems,
                title: `${serie.ref}`
            };

            const charGroup: CompiledTimelineGroup = {
                id: 'chars',
                items: charItems,
                title: `${serie.ref}`
            };

            timelines.push({
                id: serie.key,
                idKeys: { sampleKey: serie.sampleKey, evalKey: serie.evalKey },
                groups: [evalGroup, charGroup],
            });

            timelinesOptions.push({
                exclude: false,
                startDate: timelineStartDate,
                endDate: timelineEndDate,
            });
        });

        return {
            timelines,
            timelinesOptions,
            isEmpty: false,
            options,
            errors: [],
        };
    }

    compileInfoSheet(series: Series[], options: InfoSheetReportOptions, samples: ReportSample[], infosection?: string): CompiledInfoSheetReport {

        let seriesByCultivar = arrayGroupBy(series, 'scionCultivarKey');
        let seriesPrimary: Series[] = (options.primaryCultivar && seriesByCultivar[options.primaryCultivar.key]) ?  seriesByCultivar[options.primaryCultivar.key] : [];
        let seriesControl: Series[] = (options.controlCultivar && seriesByCultivar[options.controlCultivar.key]) ?  seriesByCultivar[options.controlCultivar.key] : [];

        let includeChars: string[];
        let protocolIds = series.map(series => series.protocolId);
        let protocol = this.combinationProtocol(protocolIds);

        if (Array.isArray(options.includeChars) && options.includeChars.length > 0) {
            if (options.includeChars[0] === '*') {
                includeChars = protocol.chars;
            } else {
                includeChars = uniqueStrings([
                    ...options.includeChars,
                    ...options.includeIndexes.reduce((charIds, indexId) => {
                        const index = this._library.indexes.get(indexId);
                        if (index) charIds = charIds.concat(index.chars.map(char => char.charId));
                        return charIds;
                    }, []),
                ]);
            }
        }

        const errors = [];

        let includedMeasures = [];
        if(!(Array.isArray(options.includeMeasures) && options.includeMeasures.length > 0)){
            errors.push(`No measures selected.`);
        }else if(options.includeMeasures[0] === '*' && infosection){
            let protocolIds = series.map(series => series.protocolId);
            let protocol = this.combinationProtocol(protocolIds);
            includedMeasures = protocol.measures;
        }else{
            includedMeasures = options.includeMeasures;
        }

        let seriesList: Series[] = [];
        [seriesPrimary, seriesControl].forEach( (serie, i) => {
            if (serie.length) {
                let cultivar = [options.primaryCultivar, options.controlCultivar][i];
                seriesList.push({
                    key: serie[0].scionCultivarKey,
                    ref: ['P', 'C'][i],
                    label: cultivar.commonName,
                    shortLabel: cultivar.commonName,
                    color: ["#4CAF50", "#8BC34A"][i],
                    startDate: null,
                    endDate: null,
                    protocolId: serie[0].protocolId,
                    scionCultivarKey: serie[0].scionCultivarKey,
                    scionCultivarLabel: serie[0].scionCultivarLabel,
                    rootstockCultivarLabel: serie[0].rootstockCultivarLabel,
                    sampleSize: serie[0].sampleSize,
                    rowPos: serie[0].rowPos,
                    chars: this.aggregateSeries(serie, TableReportColumnType.Characteristic, includeChars),
                    measures: this.aggregateSeries(serie, TableReportColumnType.Measurement, includedMeasures),
                });
            }
        });

        if (seriesList.length == 2) seriesList = this.checkSeriesListMeasures(seriesList);

        const charSummary: CompiledCharacteristicSummaryReport = this.compileCharacteristicSummary(
            seriesList,
            {
                includeChars: options.includeChars,
                layoutDense: false,
            }
        );
        const measureSummary: CompiledMeasurementSummaryReport = this.compileMeasurementSummaryReport(
            seriesList,
            {
                includeMeasures: includedMeasures,
                showCharts: true,
                showData: false,
                showStats: false,
                overrides: options.overrides,
            },
            infosection
        );
        const indexSummary: CompiledCharacteristicIndexSummaryReport = this.compileCharacteristicIndexSummary(
            seriesList,
            {
                includeIndexes: options.includeIndexes,
                showCharts: true,
                showData: false,
            }
        );

        const images: SampleImage[][] = [[],[]];
        const infoSheetImageOptions: ReportImageOption[][] = [[], []];
        const primaryImageKeys: string[] = [];
        if (options.includeImages) {
            let imageMap = seriesPrimary.reduce((acc, serie) => { serie.images.forEach(i => acc[i.key] = i); return acc; }, {});
            let controlImageMap = seriesControl.reduce((acc, serie) => { serie.images.forEach(i => acc[i.key] = i); return acc;}, {});
            seriesPrimary.reduce((acc, serie) => { serie.images.forEach(image => primaryImageKeys.push(image.key)); return acc;}, {});
            for(let i = 0; i < 2; i++) {
                if (Array.isArray(options.includeImages[i])) {
                    options.includeImages[i].forEach( imageKey => { if (imageMap[imageKey]) images[i].push(imageMap[imageKey]) });
                    options.includeImages[i].forEach( imageKey => { if (controlImageMap[imageKey]) images[i].push(controlImageMap[imageKey]) });
                    options.infoSheetImageOptions[i].forEach((option) => infoSheetImageOptions[i].push(option));
                }
            }
        }

        if(samples) options.includeSamples = samples;

        return {
            options,
            charSummary,
            measureSummary,
            indexSummary,
            images,
            infoSheetImageOptions,
            primaryImageKeys,
        };

    }

    private checkSeriesListMeasures(seriesList: Series[]): Series[] {
        seriesList.forEach((serie, index) => {
            serie.measures.forEach(measureValue => {
                let si = 1;
                if (index == 1) si = 0;
                let hasMeasure = seriesList[si].measures.find(measure => measure.measureId === measureValue.measureId);
                if (!hasMeasure) seriesList[si].measures.push({
                    ...measureValue,
                    value: 0,
                    allValues: [0],
                    sum: 0,
                    total: 0
                });
            });
        });
        return seriesList;
    }

    private aggregateSeries(series: Series[], type: TableReportColumnType, includeIds: string[]) {
        let itemDef: Characteristic | Measurement;
        let values;
        let valuesObj;
        let value: SampleCharacteristic | SampleMeasurement | SampleCalculation;
        let items = [];

        // Check if series and includeIds are valid arrays
        if (!Array.isArray(series) || !Array.isArray(includeIds)) return items;

        switch (type) {
            case TableReportColumnType.Property:
            case TableReportColumnType.Index:
                break;
            case TableReportColumnType.Characteristic:
                includeIds.forEach(charId => {
                    itemDef = this._library.chars.get(charId);
                    if (itemDef) {
                        values = [];
                        series.forEach(serie => {
                            value = serie.chars.find(char => char.charId === charId);
                            if (value && value.value != null) values.push(value.value);
                        });
                        if (values.length) {
                            items.push({
                                charId,
                                value: this.aggregateCharacteristic(itemDef.type, values),
                            });
                        }
                    }
                });
                break;
            case TableReportColumnType.Measurement:

                includeIds.forEach(measureId => {
                    itemDef = this._library.measures.get(measureId);
                    if (itemDef) {
                        values = [];
                        valuesObj = [];

                        series.forEach(serie => {
                            valuesObj = serie.measures.filter(measure => measure.measureId === measureId && measure.value != null);
                            valuesObj.forEach(valObj => {values.push(valObj.value)});
                        });

                        if (values.length) {
                            items.push({
                                measureId,
                                value: values.reduce((a, b) => a + b, 0) / values.length,
                                total: values.length,
                                sum: values.reduce((a, b) => a + b, 0),
                                allValues: values
                            });
                        }
                    }
                });
                break;
                case TableReportColumnType.Calculation:
                includeIds.forEach(calcId => {
                    itemDef = this._library.calculations.get(calcId);
                    if (itemDef) {
                        values = [];
                        series.forEach(serie => {
                            value = serie.calculations.find(calc => calc.calcId === calcId);
                            if (value && value.value != null) values.push(value.value);
                        });
                        if (values.length) {
                            items.push({
                                calcId,
                                value: values.reduce((a, b) => a + b, 0) / values.length,
                            });
                        }
                    }
                });
                break;
        }

        return items;
    }

    compileTableColumns(columnOptions: TableReportColumn[]): CompiledTableReportColumn[] {
        return columnOptions.map( columnOption => {
            let column: CompiledTableReportColumn = {...columnOption};
            switch (columnOption.type) {
                case TableReportColumnType.Property:
                    column.itemDef = this._library.properties.get(columnOption.id);
                    break;
                case TableReportColumnType.Characteristic:
                    column.itemDef = this._library.chars.get(columnOption.id);
                    break;
                case TableReportColumnType.Measurement:
                    column.itemDef = this._library.measures.get(columnOption.id);
                    break;
                case TableReportColumnType.Index:
                    column.itemDef = this._library.indexes.get(columnOption.id);
                    break;
                case TableReportColumnType.Calculation:
                    column.itemDef = this._library.calculations.get(columnOption.id);
                    break;
            }
            return column;
        });
    }

    compileTableColumn(columnOptions: TableReportColumn): CompiledTableReportColumn {
        let column: CompiledTableReportColumn = {...columnOptions};
        switch (columnOptions.type) {
            case TableReportColumnType.Property:
                column.itemDef = this._library.properties.get(columnOptions.id);
                break;
            case TableReportColumnType.Characteristic:
                column.itemDef = this._library.chars.get(columnOptions.id);
                break;
            case TableReportColumnType.Measurement:
                column.itemDef = this._library.measures.get(columnOptions.id);
                break;
            case TableReportColumnType.Index:
                column.itemDef = this._library.indexes.get(columnOptions.id);
                break;
            case TableReportColumnType.Calculation:
                column.itemDef = this._library.calculations.get(columnOptions.id);
                break;
        }
        return column;
    }

    private sampleGroupTag: number = null;

    compileTableReport(data: Series[], report: ReportTable): CompiledTableReport {
        let prevKey: string = "";
        let tableReport: CompiledTableReport = {...report, columns: [], columnDefs: [], tableData: []};
        let includeKgPicks: boolean = false;
        let pickRefs: string[] = [];

        if(report.tableOptions.columns !== undefined) {

            tableReport = {
                ...report,
                columns: [],
                columnDefs: this.compileTableColumns(report.tableOptions.columns),
                tableData: [],
            };

        }

        if (report.tableOptions && report.tableOptions.columns) {
            includeKgPicks = report.tableOptions.columns.map(col => col.id).includes('kg_per_pick') || false;
        }

        tableReport.columns.push(...tableReport.columnDefs.map(c => c.id));

        let kgPerPickColumns: CompiledTableReport['columnDefs'] = [];
        let kgPerPickData: SampleMeasurement[][] = [];

        let sampleData: TableReportSampleData[] = [];

        data.forEach((serie, seriesIndex) => {
            let picks: SampleMeasurement[] = serie.measures.filter(measure => measure.measureId === 'kg_per_pick');
            let evals = report.tableOptions.includedEvals || [];
            let currentSample = serie.sampleKey;

            if ((evals.includes(serie.evalKey) && includeKgPicks) && (picks && picks.length)) {
                let seriePicks = this.compileKgPerPicks(JSON.parse(JSON.stringify(picks)));
                kgPerPickColumns = this.comparePickColumns(kgPerPickColumns, seriePicks)
                kgPerPickData.push(seriePicks);
                pickRefs.push(serie.ref);
            }

            if (prevKey != serie.sampleKey) {
                prevKey = serie.sampleKey;
                this.changeTag();
            }

            let coldat = {
                sampleKey: serie.sampleKey,
                color: serie.color,
                ref: serie.ref,
                sampleGroupTag: this.sampleGroupTag,
            };
            tableReport.columnDefs.forEach(col => {
                let value: number | string;
                let rescaled: number = null;
                let colDef;
                switch (col.type) {
                    case TableReportColumnType.Property:
                        colDef = col.itemDef as Property;
                        if (colDef) {
                            if (colDef.fieldAggr && (report.tableOptions.isAggregate || report.tableOptions.isSpread)) {
                                value = serie[colDef.fieldAggr];
                            } else {
                                if(colDef.field == 'storageRegime') {
                                    if(serie[colDef.field]) {
                                       let arr = serie[colDef.field];
                                       let treatments = [];

                                        arr.forEach(element => {
                                            if(element.treatment) {
                                                element.treatment.forEach(val => {
                                                    treatments.push(val);
                                                });
                                            }
                                        })

                                       treatments = [...new Set(treatments)];
                                       value = treatments.join(', ');

                                    }
                                } else value = serie[colDef.field];

                            }

                            if (colDef.unit && value) value = `${value} ${colDef.unit}`;
                        }
                        break;
                    case TableReportColumnType.Characteristic:
                        let char = serie.chars.find(char => char.charId === col.id);
                        let libraryChar = this._library.chars.get(col.id);
                        if ((libraryChar && libraryChar.rescale) && char) {
                            rescaled = Math.floor(this.getCharRescaleFunction(libraryChar.rescale)(parseInt(char.value), libraryChar.params['min'] || 0, libraryChar.params['max'] || 100) * libraryChar.weight || 100);
                            coldat[col.id+'.rescaled'] = rescaled
                        }
                        if (char) value = char.value;
                        break;
                    case TableReportColumnType.Measurement:
                        let measures = serie.measures.filter(measure => measure.measureId === col.id && measure.value != null);
                        if (measures.length > 0) value = measures.reduce((a, b) => a + b.value, 0) / measures.length;
                        break;
                    case TableReportColumnType.Calculation:
                        let calcs = serie.calculations.filter(calc => calc.calcId === col.id && calc.value != null);
                        if (calcs.length > 0) value = calcs.reduce((a, b) => a + b.value, 0) / calcs.length;
                        break;
                    case TableReportColumnType.Index:
                        let indexReport: CompiledCharacteristicIndexSummary;
                        colDef = col.itemDef as Index;
                        if (!indexReport || indexReport.id !== colDef.id) {
                            indexReport = this.compileCharacteristicIndexReport(data, colDef.id, false, false);
                        }
                        value = indexReport.seriesScores[seriesIndex].avg
                        break;
                }

                sampleData = this.addTableSampleData(sampleData, currentSample, col.id, value);
                coldat[col.id] = value;
            });
            if (!report.tableOptions.includedEvals) tableReport.tableData.push(coldat);
            if (report.tableOptions.includedEvals) {
                if (report.tableOptions.includedEvals.indexOf(serie.evalKey) != -1) tableReport.tableData.push(coldat);
            }
        });

        const groups = arrayGroupBy(tableReport.tableData, 'sampleKey');

        if (report.tableOptions.isAggregate) {
            tableReport.tableData = this.aggregateTableData(tableReport.columnDefs, groups);
        } else if (report.tableOptions.isSpread) {
            tableReport.tableData = this.spreadTableData(tableReport.columnDefs, groups);
            tableReport.columnDefs = this.spreadTableColumns(tableReport.columnDefs, groups);
            tableReport.columns = tableReport.columnDefs.map(c => c.id);
        }

        tableReport.columns.splice(0, 0, 'series');

        if (kgPerPickColumns && kgPerPickColumns.length) {
            let colId = !report.tableOptions.isSpread ? 'kg_per_pick' : 'kg_per_pick_0';
            let index = tableReport.columnDefs.findIndex(col => col.id === colId);

            tableReport.columnDefs.splice(index, 1, ...kgPerPickColumns);
            tableReport.columns = tableReport.columnDefs.map(col => col.id);
            tableReport.tableData = this.compileKgPerPickTableData(tableReport.columnDefs, kgPerPickData, groups, pickRefs)
        }

        if (report.tableOptions.showSummary && (!kgPerPickColumns || kgPerPickColumns.length == 0)) {
            sampleData.forEach(data => {
                let sampleKeys = tableReport.tableData.map(data => data.sampleKey);
                let dataIndex = sampleKeys.lastIndexOf(data.sampleKey);
                let columns = [];

                if (!tableReport.tableData[dataIndex]) return;

                data.values.forEach(val => {
                    columns.push({[val.col]: (val.value / val.size)});
                });

                let summaryData = Object.assign({}, tableReport.tableData[dataIndex], ...columns);
                summaryData.summary = true;
                summaryData.ref = "";

                tableReport.tableData.splice(dataIndex + 1, 0, summaryData);
            });
        }

        return tableReport;
    }

    private addTableSampleData(sampleData: TableReportSampleData[], sampleKey: string, col: string, value: number | string): TableReportSampleData[] {
        if (!value || isNaN(+value)) return sampleData;

        value = +value;

        let data: TableReportSampleData;
        let defaultValue: TableReportSampleData = {
            sampleKey: sampleKey,
            values: [{
                col: col,
                value: value || 0,
                size: 1
            }]
        };

        data = sampleData.find(val => val.sampleKey === sampleKey);

        if (!data) {
            sampleData.push(defaultValue);
            return sampleData;
        }

        let colValue = data.values.find(val => val.col === col);

        if (colValue) {
            colValue.value += value;
            colValue.size += 1
        }
        else data.values.push(...defaultValue.values);

        return sampleData;
    }

    private changeTag() {
        if (this.sampleGroupTag == null) {
            this.sampleGroupTag = 1;
            return;
        }

        if (this.sampleGroupTag == 1) {
            this.sampleGroupTag = 2;
        } else if (this.sampleGroupTag == 2) {
            this.sampleGroupTag = 1;
        }
    }

    private aggregateTableData(columnDefs: CompiledTableReport['columnDefs'], groups): CompiledTableReport['tableData'] {
        const aggregateData: CompiledTableReport['tableData'] = [];

        Object.keys(groups).forEach(sampleKey => {
            let group: CompiledTableReport['tableData'] = groups[sampleKey];
            let coldat = {
                sampleKey: sampleKey,
                color: HSL.average(group.filter(item => item.color != null).map(item => RGB.fromHex(item.color).toHSL())).toString(),
            };

            columnDefs.forEach(col => {
                let value: number | string;
                let rescaled: number = null;
                let items = group.reduce((items, item) => {
                    if (item[col.id] != null) items.push(item[col.id]);
                    return items;
                }, []);

                if (!items.length) return;

                switch (col.type) {
                    case TableReportColumnType.Property:
                        value = group[0][col.id];
                        break;
                    case TableReportColumnType.Characteristic:
                        let colDef = col.itemDef as Characteristic;
                        let libraryChar = this._library.chars.get(col.id);
                        value = this.aggregateCharacteristic(colDef.type, items);
                        if (libraryChar && libraryChar.rescale && value) {
                            rescaled = Math.floor(this.getCharRescaleFunction(libraryChar.rescale)(parseInt(value), libraryChar.params['min'] || 0, libraryChar.params['max'] || 100) * libraryChar.weight || 100);
                            coldat[col.id+'.rescaled'] = rescaled
                        }
                        break;
                    case TableReportColumnType.Measurement:
                    case TableReportColumnType.Calculation:
                    case TableReportColumnType.Index:
                        value = items.reduce((a, b) => a + b, 0) / items.length;
                        break;
                }
                coldat[col.id] = value;
            });
            aggregateData.push(coldat);
        });
        return aggregateData;
    }

    private aggregateCharacteristic(type: CharacteristicType, items: any[]): string {
        switch (type) {
            case CharacteristicType.Interval:
                let values = items.filter((item) => item !== null);
                let total = null;
                if (values.length > 0) total = (values.reduce((a, b) => a + parseInt(b), 0) / values.length).toString();
                return total;
            case CharacteristicType.Nominal:

                let parts = [];
                items.forEach(item => {
                    if(item.includes(',')) {
                        parts = item.split(',');
                        items = items.filter(a => a !== item);
                        items = items.concat(parts);
                    }
                });

                return Array.from(items.reduce((a, b) => a.add(b), new Set<string>())).join(',');
            case CharacteristicType.Event:
                 return moment().dayOfYear(Math.round(items.reduce((a, b) => a + moment(b).dayOfYear(), 0) / items.length)).format('YYYY-MM-DD');
                // return moment(Math.round(items.reduce((a, b) => a + moment(b), 0) / items.length)).format('YYYY-MM-DD');
            case CharacteristicType.Color:
                return HSL.average(items.map(item => HSL.fromString(item))).toString();
        }
    }

    private spreadTableColumns(columnDefs: CompiledTableReport['columnDefs'], groups): CompiledTableReport['columnDefs'] {
        let maxEvals = 0;
        Object.keys(groups).forEach(sampleKey => {
            let group: CompiledTableReport['tableData'] = groups[sampleKey];
            maxEvals = Math.max(maxEvals, group.length);
        });

        const columnDefsSpread: CompiledTableReport['columnDefs'] = [];
        columnDefs.forEach(col => {
            switch (col.type) {
                case TableReportColumnType.Property:
                    columnDefsSpread.push(col);
                    break;
                case TableReportColumnType.Characteristic:
                case TableReportColumnType.Measurement:
                case TableReportColumnType.Calculation:
                case TableReportColumnType.Index:
                    for (let i = 0; i < maxEvals; i++) {
                        let newCol = {...col};
                        newCol.id = `${col.id}_${i}`;
                        columnDefsSpread.push(newCol);
                    }
                    break;
            }
        });
        return columnDefsSpread;
    }

    private spreadTableData(columnDefs: CompiledTableReport['columnDefs'], groups): CompiledTableReport['tableData'] {
        const aggregateData: CompiledTableReport['tableData'] = [];

        Object.keys(groups).forEach(sampleKey => {
            let group = groups[sampleKey];
            let coldat = {
                sampleKey: sampleKey,
                color: HSL.average(group.filter(item => item.color != null).map(item => RGB.fromHex(item.color).toHSL())).toString(),
            };

            columnDefs.forEach(col => {
                let rescaled: number = null;
                switch (col.type) {
                    case TableReportColumnType.Property:
                        coldat[col.id] = group[0][col.id];
                        break;
                    case TableReportColumnType.Characteristic:
                        let libraryChar = this._library.chars.get(col.id);
                        group.forEach((evaluation, i) => {
                            let colId = `${col.id}_${i}`;
                            let value = evaluation[col.id];
                            if (libraryChar && libraryChar.rescale && value) {
                                rescaled = Math.floor(this.getCharRescaleFunction(libraryChar.rescale)(parseInt(value), libraryChar.params['min'] || 0, libraryChar.params['max'] || 100) * libraryChar.weight || 100);
                                coldat[colId+'.rescaled'] = rescaled;
                            }
                        });
                    case TableReportColumnType.Measurement:
                    case TableReportColumnType.Calculation:
                    case TableReportColumnType.Index:
                        let colId;
                        group.forEach((evaluation, i) => {
                            colId = `${col.id}_${i}`;
                            coldat[colId] = evaluation[col.id];
                        });
                        break;
                }
            });
            aggregateData.push(coldat);
        });
        return aggregateData;
    }

    private comparePickColumns(columns: CompiledTableReport['columnDefs'], seriePicks: SampleMeasurement[]): CompiledTableReport['columnDefs'] {
        if (seriePicks.length <= columns.length) return columns;

        let tableColumns = this.compileKgPerPickColumns(seriePicks)
        return tableColumns;
    }

    private compileKgPerPickColumns(seriePicks: SampleMeasurement[]): CompiledTableReport['columnDefs'] {
        const tableColumns: CompiledTableReport['columnDefs'] = [];

        seriePicks.forEach((pick, index) => {
            let label = `Pick ${index+1}`
            let column: CompiledTableReportColumn = {
                ...this.compileTableColumn({id: pick.measureId, type: TableReportColumnType.Measurement}),
                id: `kg_per_pick_no_${index}`,
            }

            if (column.itemDef && 'unit' in column.itemDef) {
                column.itemDef = {
                    ...column.itemDef,
                    label: label,
                    unit: 'kg | %',
                }
            }

            tableColumns.push(column);
        });

        return tableColumns;
    }

    private compileKgPerPickTableData(columnDefs: CompiledTableReport['columnDefs'], seriesData: SampleMeasurement[][], groups: any, pickRefs: string[]): CompiledTableReport['tableData'] {
        const tableData: CompiledTableReport['tableData'] = [];
        let compiledPicks = 0;


        Object.keys(groups).forEach((sampleKey, groupIndex) => {
            let group = JSON.parse(JSON.stringify(groups[sampleKey]));
            group = group[0];
            delete group['kg_per_pick']
            let data = {
                ...group,
            };

            let picks = columnDefs.filter(col => col.id.includes('kg_per_pick'));

            picks.forEach((pick, index) => {
                if(!pickRefs.includes(group.ref)) {
                    return data = {
                        ...data,
                        ...this.getEmptyPickData(pick)
                    };
                }

                if (!seriesData[compiledPicks]) {
                    console.warn('Pick index out of bounds');
                    return;
                }

                let pickData = seriesData[compiledPicks][index];

                if (!pickData) {
                    return data = {
                        ...data,
                        ...this.getEmptyPickData(pick)
                    };
                }

                data[pick.id] = pickData.value;
                data[`${pick.id}.calculated`] = pickData.calculatedValue;
                data[`${pick.id}.unit`] = 'kg';
                data[`${pick.id}.calculated.unit`] = '%';
            });

            if (pickRefs.includes(group.ref)) compiledPicks++;

            tableData.push(data);

        });

        return tableData;
    }

    private getEmptyPickData(pick: CompiledTableReportColumn) {
       return {
            [`${pick.id}`]: 'null',
            [`${pick.id}.empty`]: '-',
            [`${pick.id}.calculated`]: 'null'
        }
    }

    compileTooltipSeries(dataset: Series[], cultivars: Cultivar[], evaluations: Evaluation[], data: Sample[]) {
        let set = dataset;

        set.forEach((item, index) => {
            if (set[index].shortLabel) {
                let cultivar = this.getCultivarDetail(set[index].scionCultivarKey, cultivars);
                let tempSeries = this.seriesTooltipCompiler(set[index], set[index].ref, cultivar.commonName);

                set[index].tooltipInfo = tempSeries.tooltipInfo;
            }
        });
    }

    compileTooltipTable(dataset: CompiledTableReport[], data: Sample[], series: Series[], evaluations: Evaluation[]) {
        let currentSet = dataset;

        currentSet.forEach((item, index) => {
            if (!item.tableOptions.isAggregate && !item.tableOptions.isSpread) {
                if (item.tableData) {
                    item.tableData.forEach((tableRow, rowIndex)=> {
                        let evalu;
                        if (!item.tableData[rowIndex].ref) return;
                        if (item.tableOptions.includedEvals) evalu = this.getEvalDetail(item.tableData[rowIndex].ref, series, evaluations, tableRow.sampleKey, data);
                        let tempTableReport = this.tableTooltipCompiler(item, item.tableData[rowIndex].ref, evalu, rowIndex);

                        currentSet[index].tooltipInfo = tempTableReport.tooltipInfo;
                    })
                }
            }
        });
    }

    private getEvalDetail(ref: string, series: Series[], evaluations: Evaluation[], sampleKey: string, samples: Sample[]) {
        let serie = series.find(s => s.ref === ref);
        let evalKey = serie.evalKey;

        let evalu = evaluations.find(e => e.key === evalKey);

        let sample = samples.find(s => s.key === sampleKey);

        if (!evalu.sample) return {...evalu, sample: sample};
        return evalu;
    }

    private getCultivarDetail(key: string, cultivars: Cultivar[]): Cultivar {
        return cultivars.find(cult => cult.key === key);
    }

    private tableTooltipCompiler(data: CompiledTableReport, ref: string, evalu: Evaluation, index: number) {
        let tooltipData: {ref: string, data: CompiledTableReport, cultivar?: string, evalu?: Evaluation};

        if (data.tableData) {
            tooltipData = {
                ref: ref,
                data: data,
                evalu: evalu
            }

            //Create list if list does not exist
            if (!data.tooltipInfo) {
                data.tooltipInfo = [];
            }

            data.tooltipInfo[index] = tooltipData;
            return data;
        }

        return data;
    }

    private seriesTooltipCompiler(data: Series, ref: string, cultivar: string) {
        let defaultData = data;
        let tooltipData: {ref: string, data: Series, cultivar?: string, evalu?: Evaluation};

        tooltipData = {
            ref: ref,
            data: defaultData,
            cultivar: cultivar
        }

        defaultData.tooltipInfo = tooltipData;
        return defaultData;
    }
}


export class BucketSorter {

    private binMap: {
        label: string,
        lt?: number,
        counts: number[];
        isEmpty: boolean;
    }[];

    private seriesIndex = 0;

    constructor(public options: BucketOptions){
        if(Array.isArray(options.bins)){
            this.binMap = options.bins.map(bin => {
                return {
                    label: bin.label,
                    lt: bin.lt,
                    counts: [],
                    isEmpty: true
                };
            });
        }else{
            this.binMap = [];
            this.binMap.push({
                label: `< ${options.binMin}`,
                lt: options.binMin,
                counts: [],
                isEmpty: true
            });

            let lt = options.binMin + options.binSize;
            let label = '';
            let gte = options.binMin;

            while (lt <= options.binMax) {
                let roundedLT = lt.toString().includes('.');
                let roundedGTE = gte.toString().includes('.');

                if(options.binSize === 1){
                    label = roundedLT ? `${gte.toFixed(1)}` : `${gte}`
                }else{
                    label = `${roundedGTE ? gte.toFixed(1) : gte}-${roundedLT ? lt.toFixed(1) : lt}`;
                }

                this.binMap.push({
                    label,
                    lt,
                    counts: [],
                    isEmpty: true
                });
                gte = lt;
                lt += options.binSize;
            }

            this.binMap.push({
                label: `> ${gte}`,
                counts: [],
                isEmpty: true
            });

        }
    }

    pushSeries(values: number[]){

        let gte = -99999;

        this.binMap.forEach((bin, bi) => {
            bin.counts[this.seriesIndex] = 0;
        });

        values.forEach(val => {

            let bin = this.binMap.find(bin => {
                return val < bin.lt || bin.lt === undefined;
            });

            if (bin) {
                bin.counts[this.seriesIndex]++;
                bin.isEmpty = false;
            }

        });

        this.seriesIndex++;
    }

    pushCompiledSeries(values: number[]) {
        this.binMap = this.binMap.filter(bin => !bin.label.includes('undefined'));

        //? pad binMap if values exceed current bin
        if (this.binMap.length < values.length) {
            let diff = values.length - this.binMap.length;
            let first = this.binMap[0]

            for (let i = 0; i < diff; i++) {
                let index = values.length - diff + i;
                let bin = {
                    label: `Pick ${index + 1}`,
                    lt: index + 1,
                    counts: [],
                    isEmpty: true
                }

                if (first && first.counts.length > 0) {
                    let paddedList = first.counts.map(count => count = 0)
                    bin.counts.push(...paddedList)
                }

                this.binMap.push(bin);
            }
        }

        this.binMap.forEach((bin, index) => {
            let value = values[index]

            if (value) {
                bin.counts.push(value);
                bin.isEmpty = false;
                return;
            }

            bin.counts.push(0);
            bin.isEmpty = false;
        });
    }

    trim(){

        let firstIndex = null;
        let lastIndex = null;

        this.binMap.forEach((bin, bi) => {
            if(!bin.isEmpty){
                if(firstIndex === null) firstIndex = bi;
                lastIndex = bi;
            }
        });

        if(firstIndex !== null && lastIndex !== null){
            this.binMap = this.binMap.slice(firstIndex, lastIndex+1);
        }

    }

    get(): MeasurementBucketSort{
        return {
            label: this.options.label,
            bins: this.binMap
        };
    }

    describeEvaluation() {

    }
}

