import { Pipe, PipeTransform } from '@angular/core';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Cultivar } from '@core/data';

@Pipe({
    name: 'descCultivar',
})
export class DescribeCultivarPipe implements PipeTransform {

    constructor(private _sampleFormatter: SampleFormatter){}

    transform(cultivar: Cultivar, html = false, def = '') {

        if(!cultivar){
            return def;
        }

        return this._sampleFormatter.describeCultivar(cultivar, html);
    }
}
