import { Collection } from "./collection";

export interface FilterQuery {
    label?: string;
    key: string;
    value: any;
    mode?: string;
}

export enum FilterQueryMode {
    EQUALS = 'eq',
    NOT_EQUALS = 'ne',
    LESS_THAN = 'lt',
    GREATER_THAN = 'gt',
    IN = 'in',
    BETWEEN = 'bt',
    YEAR = 'yr',
    HAS_VALUE = 'hv',
    HAS_NO_VALUE = 'hnv',
}

export interface FilterSort {
    column: string;
    order?: string;
}

export interface FilterPage {
    size: number;
    index: number;
}

export interface Filter {
    queries?: FilterQuery[];
    sort?: FilterSort;
    page?: FilterPage;
    keys?: string[];
    related?: string[];
}

export class FilterBuilder {

    private _filter: Filter;

    constructor(filter: Filter = null){

        if(filter === null){
            this._filter = {};
        }else{
            this._filter = {
                ...filter
            };
        }
    }

    removeQuery(key:string){
        this.initQueries();
        this._filter.queries = this._filter.queries.filter(q => q.key !== key);
    }

    setQuery(query: FilterQuery): FilterBuilder
    setQuery(key: string, value: any, mode?: string, label?: string): FilterBuilder
    setQuery(key: string|FilterQuery, value?: any, mode?: string, label?: string): FilterBuilder{

        this.initQueries();

        if(typeof key === 'object'){
            this.removeQuery(key.key);
            this._filter.queries.push(key);
        }else{
            this.removeQuery(key);
            let query: FilterQuery = {key: key, value: value};
            if(mode !== undefined) query.mode = mode;
            if(label !== undefined) query.label = label;
            this._filter.queries.push(query);
        }

        return this;
    }

    setQueryFromObject(object: any){
        this.initQueries();

        for (const key of object) {
            if(object.hasOwnProperty(key)){
                this.setQuery(key, object[key]);
            }
        }

    }

    private initQueries(){
        if(!Array.isArray(this._filter.queries)) this._filter.queries = [];
    }

    setPageIndex(index: number){
        this._filter.page = {
            index,
            size: this._filter.page.size
        };
    }

    setPageSize(size: number){
        this._filter.page = {
            size,
            index: this._filter.page.index
        };
    }

    setPage(page: FilterPage)
    setPage(size: number, index: number)
    setPage(size: number | FilterPage, index: number = 0){

        if(typeof size === 'object'){
            this._filter.page = {...size};
        }else{
            this._filter.page = {
                size,
                index,
            };
        }
        
        return this;
    }

    setSort(sort: FilterSort)
    setSort(column: string, order: string)
    setSort(column: string | FilterSort, order: string = 'desc'){
        if(typeof column === 'object'){
            this._filter.sort = {...column};
        }else{
            this._filter.sort = {
                column,
                order,
            };
        }
        return this;
    }

    get(): Filter{
        return this._filter;
    }

    getQueryParams(){
        return FilterBuilder.queryParams(this._filter);
    }

    public static queryParams(filter: Filter){
        let filterParams: any = {f: 1};

        if(filter.page){
            filterParams.fpi = filter.page.index;
            filterParams.fps = filter.page.size;
        }

        if (filter.sort){
            filterParams.fsc = filter.sort.column;
            filterParams.fso = filter.sort.order;
        }

        if(filter.keys){
            filterParams.fk = filter.keys.join(',');
        }

        if(filter.related){
            filterParams.fr = filter.related.join(',');
        }

        if (filter.queries && filter.queries.length > 0){
            for (let i in filter.queries){
                if(Array.isArray(filter.queries[i].value)){
                    filterParams['fq_' + filter.queries[i].key] = filter.queries[i].value.join(',');
                }else{
                    filterParams['fq_' + filter.queries[i].key] = filter.queries[i].value;
                }

                if (filter.queries[i].mode) filterParams['fqm_' + filter.queries[i].key] = filter.queries[i].mode;
            }
        }

        return filterParams;
    }

}


export class ArrayFilter<M> {

    filter(array: M[], filter: Filter): Collection<M>{
        if(filter.queries) array = this.query(array, filter.queries);
        if(filter.sort) array = this.sort(array, filter.sort);
        let total = array.length;
        if(filter.page) array = this.page(array, filter.page);

        return {
            data: array,
            total
        };
    }

    query(array: M[], queries: FilterQuery[]){
        return array.filter(item => {
            for (const query of queries) {
                if(!this.queryMatch(item, query)) return false;
            }
            return true;
        });
    }

    queryMatch(item: M, query: FilterQuery){
        return query.value === item[query.key];
    }

    sort(array: M[], sort: FilterSort){
        return array.concat().sort((a,b) => {
            const x = a[sort.column];
            const y = b[sort.column];
            if(sort.order === 'desc'){
                return ((x < y) ? 1 : ((x > y) ? -1 : 0));
            }else{
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
        });
    }

    page(array: M[], page: FilterPage){
        const indexStart = page.index * page.size;
        const indexEnd = indexStart + page.size;

        return array.slice(indexStart, indexEnd);
    }


}