<pv-view toolbarTitle="Protocol Index" [returnTo]="['..']" *ngIf="state$ | async as state">
    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools>

        <button mat-icon-button [matMenuTriggerFor]="protocolIndexMenu">
            <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #protocolIndexMenu>
            <button mat-menu-item (click)="addProtocol(state.orgKey)" type="button">
                <mat-icon>add</mat-icon>
                <span>Add Protocol</span>
            </button>
            <button mat-menu-item (click)="reloadProtocols()" type="button">
                <mat-icon>refresh</mat-icon>
                <span>Refresh Protocol Data</span>
            </button>
            <button mat-menu-item (click)="reset()" type="button">
                <mat-icon>block</mat-icon>
                <span>Clear Filters</span>
            </button>
        </mat-menu>

    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SIDEBAR -->
    <div pv-view-sidebar>
        <form [formGroup]="filterFormProtocol" class="pv-form single-column p-12 cultivar-index-filter-form">
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" matInput formControlName="search">
                <mat-hint>Find by title</mat-hint>
            </mat-form-field>

            <ng-container>
                <mat-checkbox formControlName="archived">Show Archived</mat-checkbox>
                <span class="mat-checkbox-hint">Inculde archived protocols in the results</span>
            </ng-container>

            <div class="form-actions">
                <button mat-button
                        type="button"
                        color="warn"
                        (click)="reset()">
                    Clear Filters
                </button>
            </div>
        </form>
    </div>
    <!-- SIDEBAR END -->

    <!-- PAGINATION FOOTER -->
    <div pv-view-footer class="pv-table-footer">
        <label class="mat-caption mr-12" for="protocol_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                    id="protocol_index_sort"
                    class="cultivar-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">
                {{ opt.label }}
            </mat-option>
        </mat-select>

        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                       [pageSize]="state.page.size"
                       [pageIndex]="state.page.index"
                       (page)="paginate($event)"
                       [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>
    <!-- PAGINATION FOOTER END -->

    <!-- FAB -->
    <button pv-view-fab mat-fab color="primary" matTooltip="Add Protocol" (click)="addProtocol(state.orgKey)">
        <mat-icon>add</mat-icon>
    </button>

    <!-- BODY -->
    <div pv-view-body class="cultivar-index-body" [ngSwitch]="state.status">
        <!-- STATUS OVERLAY: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Protocols..."></pv-view-loader>

        <!-- STATUS MESSAGE: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'" heading="No results"
                            message="Current filters does not match any Protocols. Update filters or add a Protocol."
                            icon="help">
            <button mat-button color="accent" pvMessageAction (click)="reset()">
                Clear Filters
            </button>
            <button mat-button color="primary" pvMessageAction (click)="addProtocol(state.orgKey)">
                Add Protocol
            </button>
        </pv-view-message>

        <!-- STATUS MESSAGE: ERROR -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                            [message]="state.status | descStatusMessage:'Protocols'" icon="error">
            <button mat-button
                    color="primary"
                    pvMessageAction
                    (click)="reloadProtocols()">Reload</button>
        </pv-view-message>

        <!-- ngSwitch placeholders -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <!-- GROUP TABLE -->
        <div class="pv-cultivar-table pv-responsive-table" *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <table
                   cdk-table
                   [dataSource]="state.data"
                   matSort
                   matSortDisableClear="true"
                   (matSortChange)="sort($event)"
                   [matSortActive]="state.sort.column"
                   [matSortDirection]="state.sort.order">

                <ng-container cdkColumnDef="title">
                    <th cdk-header-cell *cdkHeaderCellDef>Title</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <mat-icon *ngIf="!row.archived">ballot</mat-icon>
                        <mat-icon *ngIf="row.archived">inventory_2</mat-icon>
                        {{ row.title }}
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="description">
                    <th cdk-header-cell *cdkHeaderCellDef>Description</th>
                    <td cdk-cell *cdkCellDef="let row">{{ row.description }}</td>
                </ng-container>

                <ng-container cdkColumnDef="type">
                    <th cdk-header-cell *cdkHeaderCellDef>Type</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <mat-icon *ngIf="row.protocolType == 'plant'" svgIcon="common:plant_sample"></mat-icon>
                        <mat-icon *ngIf="row.protocolType == 'harvest'" svgIcon="common:harvest_sample"></mat-icon>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="protocol">
                    <th cdk-header-cell *cdkHeaderCellDef>Protocol</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <span *ngIf="row.protocol"><mat-icon>ballot</mat-icon></span>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="filter">
                    <th cdk-header-cell *cdkHeaderCellDef>Filter</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <span *ngIf="row.filter"><mat-icon>filter_alt</mat-icon></span>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="createdAt">
                    <th cdk-header-cell mat-sort-header *cdkHeaderCellDef>
                        <mat-icon matTooltip="Created At">access_time</mat-icon>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <small>{{ row.createdAt | date:'mediumDate' }}</small>
                    </td>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                    <th cdk-header-cell *cdkHeaderCellDef></th>
                    <td cdk-cell *cdkCellDef="let row">
                        <button mat-icon-button
                                matTooltip="Protocol Actions"
                                [matMenuTriggerFor]="protocolMenu"
                                [matMenuTriggerData]="{protocol: row}">
                            <mat-icon>more_horiz</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr cdk-header-row *cdkHeaderRowDef="state.displayColumns"></tr>
                <tr [class.archived-row]="row.archived" cdk-row *cdkRowDef="let row; columns: state.displayColumns"></tr>
            </table>
        </div>
        <!-- GROUP TABLE END -->

        <!-- GROUP ACTION MENUS -->
        <mat-menu #protocolMenu>
            <ng-template matMenuContent let-protocol="protocol">
                <button mat-menu-item (click)="addProtocol(state.orgKey, protocol)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Protocol</span>
                </button>
                <button mat-menu-item (click)="archiveProtocol(state.orgKey, protocol)">
                    <ng-container *ngIf="!protocol.archived; else unArchive">
                        <mat-icon>inventory_2</mat-icon>
                        <span>Archive Protocol</span>
                    </ng-container>
                    <ng-template #unArchive>
                        <mat-icon>ballot</mat-icon>
                        <span>Unarchive Protocol</span>
                    </ng-template>
                </button>
            </ng-template>
        </mat-menu>
        <!-- GROUP ACTION MENUS END -->

    </div>
    <!-- BODY END -->
</pv-view>
