import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AppAuthModule } from '@app/auth';
import { AppBrowserModule, TASTINGS_BASE_URI, WEB_BASE_URI } from '@core/browser';
import { API_BASE_URI, CoreDataModule } from '@core/data';
import { GOOGLE_API_KEY } from '@core/maps';
import { AppMaterialModule } from '@core/material';
import { CoreUtilsModule } from '@core/utils';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { CoalescingComponentFactoryResolver } from './app.coalescing-resolver';
import { AppComponent, APP_DEBUG } from './app.component';
import { AppErrorHandler } from './app.error-handler';
import { appRoutes } from './app.routes';
import { EvaluationModule } from './evaluation';
import { ErrorView } from './dashboard/components/error/error.view';
import { DashboardView } from './dashboard/components/dashboard/dashboard.view';
import { AppInfoHandler } from './app.info-handler';
import { CurrencyPipe } from '@angular/common';
import { TastingsModule } from './tastings';
import { AppThemes, AppThemesService } from './app-themes.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SharedModule } from './shared.module';


@NgModule({

    imports: [

        NgxsModule.forRoot([], { developmentMode: !environment.production }),

        MatMomentDateModule,

        BrowserAnimationsModule,
        HttpClientModule,

        CoreDataModule.forRoot(),
        AppMaterialModule.forRoot(),
        CoreUtilsModule,
        AppBrowserModule,
        AppAuthModule.forRoot(),
        EvaluationModule,
        TastingsModule,

        RouterModule.forRoot(appRoutes),

    ],

    providers: [
        { provide: LOCALE_ID, useValue: 'en-ZA' },
        { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' },
        { provide: API_BASE_URI, useValue: environment.api_host_base_uri },
        { provide: WEB_BASE_URI, useValue: environment.web_host_base_uri },
        { provide: GOOGLE_API_KEY, useValue: environment.google_api_key },
        { provide: APP_DEBUG, useValue: !environment.production },
        { provide: TASTINGS_BASE_URI, useValue: environment.tastings_base_uri },
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        CoalescingComponentFactoryResolver,
        AppInfoHandler,
        CurrencyPipe,
        AppThemesService,
    ],

    declarations: [
        AppComponent,
        DashboardView,
        ErrorView,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        coalescingResolver: CoalescingComponentFactoryResolver,
        private _overlayContainer: OverlayContainer,
        private _appThemesService: AppThemesService,
        private _router: Router,
    ) {
        coalescingResolver.init();

        this._router.events.subscribe(event => {
            if (this._router.url.includes('tastings')) {
                this._appThemesService.getTheme().subscribe(data => {
                    this._overlayContainer.getContainerElement().classList.remove(data);
                })
                this._overlayContainer.getContainerElement().classList.add(AppThemes.tastings);
                this._appThemesService.changeTheme(AppThemes.tastings);
            } else if (this._router.url.includes('breeding')) {
                this._appThemesService.getTheme().subscribe(data => {
                    this._overlayContainer.getContainerElement().classList.remove(data);
                })
                this._overlayContainer.getContainerElement().classList.add(AppThemes.breeding);
                this._appThemesService.changeTheme(AppThemes.breeding);
            } else {
                this._appThemesService.getTheme().subscribe(data => {
                    this._overlayContainer.getContainerElement().classList.remove(data);
                })
                this._overlayContainer.getContainerElement().classList.add(AppThemes.default);
                this._appThemesService.changeTheme(AppThemes.default);
            }
        });
    }
}
