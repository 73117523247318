<figure class="image-item" *ngFor="let image of _images; index as i; trackBy: trackImage" (click)="openImage(i)">
    <pv-image-ref class="image" [fileRef]="image.fileRef" transformId="thumb_300" [alt]="image.name"></pv-image-ref>
    <figcaption class="image-caption">
        <time class="image-date" *ngIf="image.takenAt" [attr.datetime]="image.takenAt">
            {{ image.takenAt | date:'mediumDate' }}<span class="image-timeago">{{ image.takenAt | amTimeAgo }}</span>
        </time>
        <p class="image-note">
            {{ image.note }}
        </p>
        <div class="image-info">
            <div *ngIf="image.lat && image.lng">
                <a [href]="'http://www.google.com/maps/place/' + image.lat + ',' + image.lng" target="_blank">
                    <mat-icon>place</mat-icon>&nbsp;{{ image | descSiteCoords }}
                </a>
            </div>
        </div>
    </figcaption>
</figure>
<p class="mat-caption" *ngIf="_images.length === 0">
    No images have been recorded.
</p>