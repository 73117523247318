import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { ImageTransformOptions, parseFileRefType, convertImageFileRef } from '@core/data';




@Component({
    selector: 'pv-image-ref',
    template: `
        <ng-container [ngSwitch]="status">
            <img [class.hide]="status !== 2" [class.radius]="radius" (load)="onLoad($event)" (error)="onError($event)" [src]="src" [alt]="alt">
            <div *ngSwitchCase="1" class="placeholder"></div>
            <div *ngSwitchCase="3" class="errorholder">
                <mat-icon>cloud_off</mat-icon>
            </div>
        </ng-container>
    `,
    styleUrls: ['image-ref.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-image-ref'
    }
})
export class ImageRefComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    fileRef: string;

    @Input()
    width: number;

    @Input()
    height: number;

    @Input()
    mode: string | 'fill' | 'fit' | 'scale' | 'crop';

    @Input()
    gravity: string | 'center';

    @Input()
    quality: number;

    @Input()
    format: string | 'jpg' | 'png' | 'auto' = 'auto';

    @Input()
    attachment: boolean;

    @Input()
    transformId: string;

    @Input()
    alt: string = '';

    @Input()
    radius: boolean = false;


    /**
     *
     * Current status
     * 0: uninitialized (or no fileRef)
     * 1: loading
     * 2: ok
     * 3: error
     *
     */
    status: number = 0;
    src: string;

    constructor(private _changeRef: ChangeDetectorRef){}

    ngOnInit(){

    }

    ngOnDestroy(){

    }

    ngOnChanges(changes: SimpleChanges){

        if(!this.fileRef){
            this.status = 0;
            this._changeRef.markForCheck();
            return;
        }

        const type = parseFileRefType(this.fileRef);

        if(type !== 'cloudinary'){
            this.status = 2;
            return;
        }

        const options: ImageTransformOptions = {
            width: this.width,
            height: this.height,
            mode: this.mode,
            gravity: this.gravity,
            quality: this.quality,
            format: this.format,
            attachment: this.attachment,
            transformId: this.transformId,
        };

        this.src = convertImageFileRef(this.fileRef, options);
        this.status = 1;
        this._changeRef.markForCheck();
    }

    onLoad(event: Event){
        this.status = 2;
    }

    onError(event: Event){
        this.status = 3;
    }

}