import { TastingEvent } from "./tastings-event";

export interface TastingContact {
    //identifiers
    key: string;

    //data
    firstName: string;
    lastName: string;
    email: string;
    dob: string;
    country: string;
    businessAreas: string[];
    subscribed: boolean;
    // expiryDate: string;

    //foreignKeys
    ownerOrgKey: string;

    //timestamps
    createdAt: string;
    updatedAt: string;
    deletedAt: string;

    //related
    events?: TastingEvent[];
}

export interface TastingContactInvitation {
    contacts: TastingContact[];
    events: TastingEvent[];
    message: string;
    orgKey: string;
}

export const BUSINESSAREAS = [
    {id: 'producer' , label: 'Producer'},
    {id: 'ip_owner', label: 'IP Manager'},
    {id: 'researcher', label: 'Researcher'},
    {id: 'consultant', label: 'Consultant'},
    {id: 'evaluator', label: 'Evaluator'},
    {id: 'marketer', label: 'Marketer'},
    {id: 'other', label: 'Other'},
]