import { Measurement } from './_types';

export const LIB_MEASURES_FRUT_HARVEST: Measurement[] = [

    // Fruit

    {
        id: "frut_length",
        unit: "mm",
        label: "Fruit Length",
        tag: "LEN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_len_std",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },
    {
        id: "frut_diameter",
        unit: "mm",
        label: "Fruit Diameter",
        tag: "DIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_dia_i5",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },
    {
        id: "frut_weight",
        unit: "g",
        label: "Fruit Weight",
        tag: "WEI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_wei_i5",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },
    {
        id: "frut_firmness",
        unit: "kg",
        label: "Fruit Firmness",
        tag: "FIR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_fir_i1",
        format: "###0.00kg",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },
    {
        id: "frut_tss",
        unit: "%",
        label: "Fruit TSS (Brix)",
        tag: "TSS",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_tss_i2",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },
    {
        id: "frut_acid",
        unit: "%",
        label: "Fruit Acidity (Malic)",
        tag: "TMA",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_tta_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "fruit",
    },
    {
        id: "frut_acid_citric",
        unit: "%",
        label: "Fruit Acidity (Citric)",
        tag: "TCA",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_tta_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "fruit",
    },
    {
        id: "frut_acid_tart",
        unit: "%",
        label: "Fruit Acidity (Tartaric)",
        tag: "TTA",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_frut_tta_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "fruit",
    },
    {
        id: "frut_ph",
        unit: "pH",
        label: "Fruit pH",
        tag: "PH",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_frut_tta_i01",
        format: "pH###",
        min: 0,
        max: 14,
        suggestMin: 0,
        suggestMax: 14,
        subjectId: "fruit",
    },
    {
        id: "frut_starch",
        unit: "%",
        label: "Fruit Starch",
        tag: "STA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_starch_20",
        format: "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "fruit",
    },
    {
        id: "frut_blush_perc",
        unit: "%",
        label: "% Blush",
        tag: "PBL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_blush_perc",
        format: "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "fruit",
    },
    {
        id: "frut_color_int",
        unit: "#",
        label: "Colour Intensity",
        tag: "CIN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_color_int",
        format: "####%",
        min: 1,
        max: 12,
        suggestMin: 1,
        suggestMax: 12,
        subjectId: "fruit",
    },
    {
        id: "frut_ctifl",
        unit: "#",
        label: "CTIFL Scale",
        tag: "CTL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_ctifl",
        format: "####%",
        min: 1,
        max: 7,
        suggestMin: 1,
        suggestMax: 7,
        subjectId: "fruit",
    },
    {
        id: "frut_seed_count",
        unit: "#",
        label: "Fruit Seed Count",
        tag: "FSC",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_seed_count",
        format: "##0",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "fruit",
    },
    {
        id: "frut_stem_length",
        unit: "#",
        label: "Stem Length",
        tag: "STL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_frut_stem_length",
        format: "####%",
        min: 1,
        max: 7,
        suggestMin: 1,
        suggestMax: 7,
        subjectId: "fruit",
    },
    {
        id: "frut_stem_colour",
        unit: "%",
        label: "Stem Colour",
        tag: "STC",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_stem_colour",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "fruit",
    },

];