import { BucketOptions } from './_types';

export const LIB_MEASURE_BUCKETS: BucketOptions[] = [
    {
        id: "bucket_tree_cir_std",
        label: "Tree Trunk Circumference (50mm interval)",
        binSize: 50,
        binMin: 50,
        binMax: 1000,
        measureId: "tree_trunk_circum",
    },
    {
        id: "bucket_tree_dia_std",
        label: "Tree Trunk Diameter (50mm interval)",
        binSize: 50,
        binMin: 50,
        binMax: 1000,
        measureId: "tree_trunk_dia",
    },
    {
        id: "bucket_vine_dia_std",
        label: "Vine Diameter (50mm interval)",
        binSize: 50,
        binMin: 50,
        binMax: 1000,
        measureId: "vine_tdia",
    },
    {
        id: "bucket_tree_yie_std",
        label: "Tree Total Yield (10kg interval)",
        binSize: 10,
        binMin: 10,
        binMax: 500,
        measureId: "tree_total_yield",
    },
    {
        id: "bucket_tree_yie_std_1kg",
        label: "Tree Total Yield (1kg interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "tree_total_yield",
    },
    {
        id: "bucket_tree_nob_std",
        label: "Tree Number of Bunches",
        binSize: 1,
        binMin: 0,
        binMax: 999,
        measureId: "prec_bcnt",
    },
    {
        id: "bucket_tree_nos_std",
        label: "Tree Number of Shoots",
        binSize: 1,
        binMin: 0,
        binMax: 999,
        measureId: "prec_scnt",
    },
    {
        id: "bucket_tree_est_yield_10",
        label: "Tree Estimated Yield (10kg interval)",
        binSize: 10,
        binMin: 10,
        binMax: 500,
        measureId: "tree_est_yield",
    },
    {
        id: "bucket_tree_est_yield_1",
        label: "Tree Estimated Yield (1kg interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "tree_est_yield",
    },
    {
        id: "bucket_tree_hei_std",
        label: "Tree Height (0.5m interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 5,
        measureId: "tree_height",
    },
    {
        id: "bucket_skirt_hei_std",
        label: "Skirt Height (0.5m interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 5,
        measureId: "skirt_height",
    },
    {
        id: "bucket_tree_yie_eff",
        label: "Tree Yield Efficiency (0.1 kg/cm interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 5,
        measureId: "tree_yield_eff",
    },
    {
        id: "bucket_tree_prod_average",
        label: "Productivity Average (kg/tree average)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 5,
        measureId: "prod_average",
    },
    {
        id: "bucket_kg_per_pick",
        label: "Kilogram Per Pick (kg/pick)",
        binSize: 10,
        binMin: 0,
        binMax: 100,
        measureId: "kg_per_pick",
    },
    {
        id: "bucket_kg_per_pick_perc",
        label: "Kilogram Percentage per Pick (%/pick)",
        measureId: "kg_per_pick_perc",
    },
    {
        id: "bucket_frut_sugar_acid_ratio",
        label: "Sugar Malic:Acid",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 5,
        measureId: "frut_sugar_acid_ratio",
    },
    {
        id: "bucket_frut_citric_sugar_acid_ratio",
        label: "Sugar Citric:Acid",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 5,
        measureId: "citric_sugar_acid_ratio_calc",
    },
    {
        id: "bucket_seed_count_percentage",
        label: "Seed Count Percentage",
        binSize: 1,
        binMin: 0,
        binMax: 999,
        measureId: "seed_count_percentage",
    },
    {
        id: "bucket_tree_shoot_len",
        label: "Length of Shoots (0.5m interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 5,
        measureId: "tree_shoot_length",
    },
    {
        id: "bucket_frut_bunch_sugar_acid_ratio",
        label: "Sugar:Acid (Bunch)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 5,
        measureId: "frut_bunch_sugar_acid_ratio",
    },
    {
        id: "bucket_tree_wid_std",
        label: "Tree Width (0.5m interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 6,
        measureId: "tree_width",
    },
    {
        id: "bucket_tree_dep_std",
        label: "Tree Depth Class (0.5m interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 5,
        measureId: "tree_depth",
    },
    {
        id: "bucket_tree_fde_10",
        label: "Tree Flower Density (10% interval)",
        binSize: 10,
        binMin: 0,
        binMax: 100,
        measureId: "tree_fde",
    },
    {
        id: "bucket_tree_fruit_count_10",
        label: "Tree Fruit Count (10 interval)",
        binSize: 10,
        binMin: 0,
        binMax: 300,
        measureId: "tree_fruit_count",
    },
    {
        id: "bucket_frut_len_std",
        label: "Fruit Length (10mm interval)",
        binSize: 10,
        binMin: 10,
        binMax: 300,
        measureId: "frut_length",
    },

    {
        id: "bucket_frut_dia_i2",
        label: "Fruit Diameter (2mm interval)",
        binSize: 2,
        binMin: 2,
        binMax: 300,
        measureId: "frut_diameter",
    },
    {
        id: "bucket_frut_dia_i5",
        label: "Fruit Diameter (5mm interval)",
        binSize: 5,
        binMin: 5,
        binMax: 200,
        measureId: "frut_diameter",
    },
    {
        id: "bucket_frut_dia_i10",
        label: "Diameter Class (10mm interval)",
        binSize: 10,
        binMin: 10,
        binMax: 300,
        measureId: "frut_diameter",
    },
    {
        id: "bucket_frut_dia_peachnec_abc",
        label: "Peach & Nectarine Diameter Class (ABC)",
        bins: [
            { label: "D-", lt: 53 },
            { label: "D:30", lt: 56 },
            { label: "C:28", lt: 59 },
            { label: "C:25", lt: 62 },
            { label: "B:23", lt: 65 },
            { label: "B:20", lt: 68 },
            { label: "A:18", lt: 74 },
            { label: "AA:15", lt: 81 },
            { label: "AAA:13", lt: 88 },
            { label: "AAA+" }
        ],
        measureId: "frut_diameter",
        cropIds: ["peach", "nectarine"]
    },
    {
        id: "bucket_frut_dia_plum_abc",
        label: "Plum Diameter Class (ABC)",
        bins: [
            { label: "D- <35", lt: 35 },
            { label: "D 35-39", lt: 40 },
            { label: "C 40-44", lt: 45 },
            { label: "B 45-49", lt: 50 },
            { label: "A 50-54", lt: 55 },
            { label: "AA 55-59", lt: 60 },
            { label: "AAA 60-64", lt: 65 },
            { label: "AAA+ >65" }
        ],
        measureId: "frut_diameter",
        cropIds: ["plum"]
    },
    {
        id: "bucket_frut_dia_apricot_sml",
        label: "Apricot Diameter Class (SML)",
        bins: [
            { label: "S- <35", lt: 35 },
            { label: "S 35-39", lt: 40 },
            { label: "M 40-44", lt: 45 },
            { label: "L 45-49", lt: 50 },
            { label: "XL 50-54", lt: 55 },
            { label: "XXL 55-59", lt: 60 },
            { label: "XXXL 60-64", lt: 65 },
            { label: "XXXL+ >65" }
        ],
        measureId: "frut_diameter",
        cropIds: ["apricot"]
    },
    {
        id: "bucket_frut_wei_i5",
        label: "Fruit Weight (5g interval)",
        binSize: 5,
        binMin: 5,
        binMax: 300,
        measureId: "frut_weight",
    },
    {
        id: "bucket_frut_wei_i10",
        label: "Fruit Weight (10g interval)",
        binSize: 10,
        binMin: 10,
        binMax: 500,
        measureId: "frut_weight",
    },
    {
        id: "bucket_frut_wei_apple_std",
        label: "Apple Weight Class (std-interval)",
        bins: [
            { label: "< 76", lt: 76 },
            { label: "76-92", lt: 93 },
            { label: "93-95", lt: 96 },
            { label: "96-109", lt: 110 },
            { label: "110-118", lt: 119 },
            { label: "119-130", lt: 131 },
            { label: "131-147", lt: 148 },
            { label: "148-162", lt: 163 },
            { label: "163-181", lt: 182 },
            { label: "182-197", lt: 198 },
            { label: "198-206", lt: 207 },
            { label: "207-240", lt: 241 },
            { label: "> 241" }
        ],
        measureId: "frut_weight",
        cropIds: ["apple"]
    },
    {
        id: "bucket_frut_wei_pear_std",
        label: "Pear Weight Class (std-interval)",
        bins: [
            { label: "< 101", lt: 101 },
            { label: "101-109", lt: 110 },
            { label: "110-127", lt: 128 },
            { label: "128-136", lt: 137 },
            { label: "137-149", lt: 150 },
            { label: "150-172", lt: 173 },
            { label: "173-199", lt: 200 },
            { label: "200-237", lt: 238 },
            { label: "238-254", lt: 255 },
            { label: "255-272", lt: 273 },
            { label: "273-291", lt: 292 },
            { label: "292-318", lt: 319 },
            { label: "> 319" }
        ],
        measureId: "frut_weight",
        cropIds: ["pear"]
    },
    {
        id: "bucket_frut_wei_peachnec_std",
        label: "Peach & Nectarine Weight Class (5g interval)",
        bins: [
            { label: "< 50", lt: 50 },
            { label: "50-54", lt: 55 },
            { label: "55-59", lt: 60 },
            { label: "60-64", lt: 65 },
            { label: "65-69", lt: 70 },
            { label: "70-74", lt: 75 },
            { label: "75-79", lt: 80 },
            { label: "80-84", lt: 85 },
            { label: "85-89", lt: 90 },
            { label: "90-94", lt: 95 },
            { label: "95-99", lt: 100 },
            { label: "100-104", lt: 105 },
            { label: "105-109", lt: 110 },
            { label: "110-114", lt: 115 },
            { label: "115-119", lt: 120 },
            { label: "120-124", lt: 125 },
            { label: "125-129", lt: 130 },
            { label: "130-134", lt: 135 },
            { label: "135-139", lt: 140 },
            { label: "140-144", lt: 145 },
            { label: "145-149", lt: 150 },
            { label: "150-154", lt: 155 },
            { label: "155-159", lt: 160 },
            { label: "> 160" }
        ],
        measureId: "frut_weight",
        cropIds: ["peach", "nectarine"]
    },
    {
        id: "bucket_frut_wei_plum_std",
        label: "Plum Weight Class (std-interval)",
        bins: [
            { label: "< 25", lt: 25 },
            { label: "25-29", lt: 30 },
            { label: "30-34", lt: 35 },
            { label: "35-39", lt: 40 },
            { label: "40-44", lt: 45 },
            { label: "45-49", lt: 50 },
            { label: "50-54", lt: 55 },
            { label: "55-59", lt: 60 },
            { label: "60-64", lt: 65 },
            { label: "65-69", lt: 70 },
            { label: "70-74", lt: 75 },
            { label: "75-79", lt: 80 },
            { label: "80-84", lt: 85 },
            { label: "85-89", lt: 90 },
            { label: "90-94", lt: 95 },
            { label: "95-99", lt: 100 },
            { label: "100-104", lt: 105 },
            { label: "> 105" }
        ],
        measureId: "frut_weight",
        cropIds: ["plum"]
    },
    {
        id: "bucket_frut_wei_apricot_std",
        label: "Apricot Weight Class (5g interval)",
        bins: [
            { label: "< 25",    lt: 25 },
            { label: "25-29",   lt: 30 },
            { label: "30-34",   lt: 35 },
            { label: "35-39",   lt: 40 },
            { label: "40-44",   lt: 45 },
            { label: "45-49",   lt: 50 },
            { label: "50-54",   lt: 55 },
            { label: "55-59",   lt: 60 },
            { label: "60-64",   lt: 65 },
            { label: "65-69",   lt: 70 },
            { label: "70-74",   lt: 75 },
            { label: "75-79",   lt: 80 },
            { label: "80-84",   lt: 85 },
            { label: "85-89",   lt: 90 },
            { label: "90-94",   lt: 95 },
            { label: "95-99",  lt: 100 },
            { label: "100-104", lt: 105 },
            { label: "> 105" }
        ],
        measureId: "frut_weight",
        cropIds: ["apricot"]
    },
    {
        id: "bucket_bery_fir_i1",
        label: "Firmness Class (1kg interval)",
        binSize: 1,
        binMin: 1,
        binMax: 25,
        measureId: "bery_firmness",
    },
    {
        id: "bucket_frut_fir_i1",
        label: "Firmness Class (1kg interval)",
        binSize: 1,
        binMin: 1,
        binMax: 25,
        measureId: "frut_firmness",
    },
    {
        id: "bucket_frut_fir_i05",
        label: "Firmness Class (0.5kg interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 10,
        measureId: "frut_firmness",
    },
    {
        id: "bucket_frut_tss_i2",
        label: "TSS/Brix Class (2% interval)",
        binSize: 2,
        binMin: 2,
        binMax: 40,
        measureId: "frut_tss",
    },
    {
        id: "bucket_tree_bnch_cnt",
        label: "Total Bunch Count",
        binSize: 1,
        binMin: 1,
        binMax: 999,
        measureId: "vine_bcnt",
    },
    {
        id: "bucket_frut_tta_i01",
        label: "TTA Class (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 3,
        measureId: "frut_acid",
    },
    {
        id: "bucket_bery_tta_i01",
        label: "TTA Class (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 3,
        measureId: "bery_acid",
    },
    {
        id: "bucket_frut_starch_20",
        label: "Starch Class (20% interval)",
        binSize: 20,
        binMin: 0,
        binMax: 100,
        measureId: "frut_starch",
    },
    {
        id: "bucket_frut_blush_perc",
        label: "Blush Percentage (10% interval)",
        binSize: 10,
        binMin: 0,
        binMax: 100,
        measureId: "frut_blush_perc",
    },
    {
        id: "bucket_frut_color_int",
        label: "Colour Intensity",
        binSize: 1,
        binMin: 1,
        binMax: 12,
        measureId: "frut_color_int",
    },
    {
        id: "bucket_frut_ctifl",
        label: "CTIFL Scale",
        binSize: 1,
        binMin: 1,
        binMax: 7,
        measureId: "frut_ctifl",
    },
    {
        id: "bucket_frut_seed_count",
        label: "Fruit Seed Count",
        binSize: 20,
        binMin: 0,
        binMax: 100,
        measureId: "frut_seed_count",
    },
    // Berries and Bunches
    {
        id: "bucket_bery_len_i1",
        label: "Berry Length (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 40,
        measureId: "bery_len",
    },
    {
        id: "bucket_bery_len_i2",
        label: "Berry Length (2mm interval)",
        binSize: 2,
        binMin: 2,
        binMax: 40,
        measureId: "bery_len",
    },
    {
        id: "bucket_rais_len_i1",
        label: "Raisin Length (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 20,
        measureId: "rais_len",
    },
    {
        id: "bucket_bery_dia_i1",
        label: "Berry Diameter (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 40,
        measureId: "bery_dia",
    },
    {
        id: "bucket_rais_dia_i1",
        label: "Raisin Diameter (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 40,
        measureId: "rais_dia",
    },
    {
        id: "bucket_bery_wei_i1",
        label: "Berry Weight (1g interval)",
        binSize: 1,
        binMin: 1,
        binMax: 40,
        measureId: "bery_wei",
    },
    {
        id: "bucket_bery_wei_i2",
        label: "Berry Weight (2g interval)",
        binSize: 2,
        binMin: 2,
        binMax: 40,
        measureId: "bery_wei",
    },
    {
        id: "bucket_rais_wei_i05",
        label: "Raisin Weight (0.5g interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 20,
        measureId: "rais_wei",
    },
    {
        id: "bucket_bery_rthi_i1",
        label: "Rachis Thickness (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 15,
        measureId: "bery_rthi",
    },
    {
        id: "bucket_bery_pthi_i05",
        label: "Cap Stem Thickness (0.5mm interval)",
        binSize: 0.5,
        binMin: 0.5,
        binMax: 5,
        measureId: "bery_pthi",
    },
    {
        id: "bucket_bnch_wei_i02",
        label: "Bunch Weight (200g interval)",
        binSize: 200,
        binMin: 200,
        binMax: 4000,
        measureId: "bnch_wei",
    },
    {
        id: "bucket_bnch_tss_i02",
        label: "TSS Class (1% interval)",
        binSize: 1,
        binMin: 1,
        binMax: 20,
        measureId: "bnch_tss",
    },
    {
        id: "bucket_bery_tss_i01",
        label: "TSS Class (1% interval)",
        binSize: 1,
        binMin: 1,
        binMax: 20,
        measureId: "bery_tss",
    },
    {
        id: "bucket_bery_ph_i01",
        label: "TTA Class (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 14,
        measureId: "bery_ph",
    },
    {
        id: "bucket_bnch_amal_i01",
        label: "TTA Class (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 4,
        measureId: "bnch_amal",
    },
    {
        id: "bucket_bery_firm_i01",
        label: "Firmness Class (0.5kg interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 4,
        measureId: "bery_firm",
    },
    {
        id: "bucket_bery_volume_i1",
        label: "Berry Volume (1cm3 interval)",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "bery_volume",
    },
    {
        id: "bucket_bnch_bery_count_10",
        label: "Berries Per Bunch (10 interval)",
        binSize: 10,
        binMin: 0,
        binMax: 500,
        measureId: "bnch_bery_count",
    },
    {
        id: "bucket_bery_amal_i01",
        label: "Malic Acid (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 4,
        measureId: "bery_amal",
    },
    {
        id: "bucket_bery_atar_i01",
        label: "Tartaric Acid (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 4,
        measureId: "bery_atar",
    },
    {
        id: "bucket_bery_acit_i01",
        label: "Citric Acid (0.1% interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 4,
        measureId: "bery_acit",
    },
    {
        id: "bucket_frut_stem_length",
        label: "Stem Length",
        binSize: 1,
        binMin: 1,
        binMax: 7,
        measureId: "frut_stem_length",
    },
    {
        id: "bucket_frut_stem_colour",
        label: "Stem Colour",
        binSize: 20,
        binMin: 0,
        binMax: 100,
        measureId: "frut_stem_colour",
    },

    //Cannabis
    {
        id: "bucket_plant_height_seed",
        label: "Plant Height - Seeding (1cm interval)",
        binSize: 1,                                         //in cm - standardize to mm? same for all cannabis measurements
        binMin: 1,
        binMax: 500,
        measureId: "plant_height_seed",
    },
    {
        id: "bucket_plant_height_veg",
        label: "Plant Height - Vegetative (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_height_veg",
    },
    {
        id: "bucket_plant_height_flower",
        label: "Plant Height - Flowering (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_height_flower",
    },
    {
        id: "bucket_plant_can_widthX_seed",
        label: "Canopy Width X-Axis - Seeding (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 5000,
        measureId: "plant_canX_seed",
    },
    {
        id: "bucket_plant_can_widthY_seed",
        label: "Canopy Width Y-Axis - Seeding (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 5000,
        measureId: "plant_canY_seed",
    },
    {
        id: "bucket_plant_can_widthX_veg",
        label: "Canopy Width X-Axis - Vegetative (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 5000,
        measureId: "plant_canX_veg",
    },
    {
        id: "bucket_plant_can_widthY_veg",
        label: "Canopy Width Y-Axis - Vegetative (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 5000,
        measureId: "plant_canY_veg",
    },
    {
        id: "bucket_plant_can_widthX_flower",
        label: "Canopy Width X-Axis - Flowering (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 5000,
        measureId: "plant_canX_flower",
    },
    {
        id: "bucket_plant_can_widthY_flower",
        label: "Canopy Width Y-Axis - Flowering (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 5000,
        measureId: "plant_canY_flower",
    },
    {
        id: "bucket_plant_internode_length_seed",
        label: "Internode Length - Seeding (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_internode_length_seed",
    },
    {
        id: "bucket_plant_internode_length_veg",
        label: "Internode Length - Vegetative (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_internode_length_veg",
    },
    {
        id: "bucket_plant_internode_length_flower",
        label: "Internode Length - Flowering (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_internode_length_flower",
    },
    {
        id: "bucket_plant_primary_cola_size",
        label: "Primary Cola Size (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_plant_primary_cola_size",
    },
    {
        id: "bucket_plant_secondary_cola_size",
        label: "Secondary Cola Size (1cm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_plant_secondary_cola_size",
    },
    {
        id: "bucket_plant_internodes_seed",
        label: "Number of Internodes - Seeding",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "plant_internodes_seed",
    },
    {
        id: "bucket_plant_internodes_veg",
        label: "Number of Internodes - Vegetative",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "plant_internodes_veg",
    },
    {
        id: "bucket_plant_internodes_flower",
        label: "Number of Internodes - Flowering",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "plant_internodes_flower",
    },
    {
        id: "bucket_plant_nodes_primary_veg",
        label: "Number of Primary Nodes - Vegetative",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "plant_nodes_primary_veg",
    },
    {
        id: "bucket_plant_nodes_primary_flower",
        label: "Number of Primary Nodes - Flower",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "plant_nodes_primary_flower",
    },
    {
        id: "bucket_plant_stem_diameter_veg",
        label: "Plant Stem Diameter - Vegetative (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 500,
        measureId: "plant_stem_dia_veg",
    },
    {
        id: "bucket_plant_stem_diameter_flower",
        label: "Plant Stem Diameter - Flowering (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 1500,
        measureId: "plant_stem_dia_flower",
    },
    {
        id: "bucket_plant_average_flower_diameter",
        label: "Average Flower Diameter (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 1000,
        measureId: "plant_flower_dia",
    },
    {
        id: "bucket_plant_number_flowers",
        label: "Number of Flowers",
        binSize: 1,
        binMin: 1,
        binMax: 100,
        measureId: "plant_number_flowers",
    },
    {
        id: "bucket_plant_wet_yield",
        label: "Wet Yield (0.1g interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 1000,
        measureId: "plant_wet_yield",
    },
    {
        id: "bucket_plant_dry_yield",
        label: "Dry Yield (0.1g interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 1000,
        measureId: "plant_dry_yield",
    },
    {
        id: "bucket_plant_flower_yield_trimmed",
        label: "Flower Yield - Trimmed (1g interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 1000,
        measureId: "plant_flower_yield_trimmed",
    },
    {
        id: "bucket_plant_flower_yield_untrimmed",
        label: "Flower Yield - Trimmed & Untrimmed (1g interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 1000,
        measureId: "plant_flower_yield_untrimmed",
    },
    {
        id: "bucket_plant_trim_yield",
        label: "Trim Yield (1g interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 1000,
        measureId: "plant_trim_yield",
    },
    {
        id: "bucket_plant_stem_yield",
        label: "Stem & Fan leaves Yield (1g interval)",
        binSize: 0.1,
        binMin: 0.1,
        binMax: 1000,
        measureId: "plant_stem_yield",
    },
    {
        id: "bucket_plant_thc_d8",
        label: "D8-THC Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_thc_d8",
    },
    {
        id: "bucket_plant_thc_d9",
        label: "D9-THC Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_thc_d9",
    },
    {
        id: "bucket_plant_thcv",
        label: "THCV Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_thcv",
    },
    {
        id: "bucket_plant_cbd",
        label: "CBD Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_cbd",
    },
    {
        id: "bucket_plant_cbdv",
        label: "CBDV Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_cbdv",
    },
    {
        id: "bucket_plant_cbl",
        label: "CBL Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_cbl",
    },
    {
        id: "bucket_plant_cbc",
        label: "CBC Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_cbc",
    },
    {
        id: "bucket_plant_cbg",
        label: "CBG Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_cbg",
    },
    {
        id: "bucket_plant_cbn",
        label: "CBN Content (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_cbn",
    },
    {
        id: "bucket_plant_total_cann",
        label: "Total Cannabinoids (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_total_cann",
    },
    {
        id: "bucket_terpene_content",
        label: "Total Terpenes (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_total_terpenes",
    },
    {
        id: "bucket_terpene_limonene",
        label: "Limonene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_limonene",
    },
    {
        id: "bucket_terpene_bisabolol_alpha",
        label: "Bisabolol (alpha) (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_bisabolol_alpha",
    },
    {
        id: "bucket_terpene_pinene_alpha",
        label: "Pinene (alpha) (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_pinene_alpha",
    },
    {
        id: "bucket_terpene_pinene_beta",
        label: "Pinene (beta) (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_pinene_beta",
    },
    {
        id: "bucket_terpene_linalool",
        label: "Linalool (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_linalool",
    },
    {
        id: "bucket_terpene_humulene",
        label: "Humulene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_humulene",
    },
    {
        id: "bucket_terpene_carophyllene",
        label: "Carophyllene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_carophyllene",
    },
    {
        id: "bucket_terpene_myrcrene",
        label: "Myrcrene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_myrcrene",
    },
    {
        id: "bucket_terpene_terpinolene",
        label: "Terpinolene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_terpinolene",
    },
    {
        id: "bucket_terpene_terpinene",
        label: "Terpinene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_terpinene",
    },
    {
        id: "bucket_terpene_eucalyptol",
        label: "Eucalyptol (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_eucalyptol",
    },
    {
        id: "bucket_terpene_camphene",
        label: "Camphene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_camphene",
    },
    {
        id: "bucket_terpene_cymene",
        label: "Cymene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_cymene",
    },
    {
        id: "bucket_terpene_isopulegol",
        label: "Isopulegol (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_isopulegol",
    },
    {
        id: "bucket_terpene_borneol",
        label: "Borneol (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_borneol",
    },
    {
        id: "bucket_terpene_valencene",
        label: "Valencene (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_valencene",
    },
    {
        id: "bucket_terpene_caryophyllene_oxide",
        label: "Caryophyllene Oxide (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_caryophyllene_oxide",
    },
    {
        id: "bucket_terpene_geraniol",
        label: "Geraniol (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_geraniol",
    },
    {
        id: "bucket_terpene_terpineol_alpha",
        label: "Terpineol (alpha) (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_terpineol_alpha",
    },
    {
        id: "bucket_terpene_ocamene_alpha",
        label: "Ocamene (alpha) (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_ocamene_alpha",
    },
    {
        id: "bucket_terpene_ocamene_beta",
        label: "Ocamene (beta) (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "plant_terpene_ocamene_beta",
    },
    {
        id: "bucket_nut_husk_wei",
        label: "Husk Weight (1g interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_husk_weight",
    },
    {
        id: "bucket_nut_in_husk_wei",
        label: "Weight (1g interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_husk_weight",
    },
    {
        id: "bucket_nut_in_husk_hei",
        label: "Height (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_husk_height",
    },
    {
        id: "bucket_nut_in_husk_dia",
        label: "Diameter (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_husk_diameter",
    },
    {
        id: "bucket_nut_in_husk_pedi",
        label: "Pedicel Length (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_husk_pedi_len",
    },
    {
        id: "bucket_nut_in_husk_ease_dehusk",
        label: "Ease of First Dehusking",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_in_husk_ease_dehusk",
    },
    {
        id: "bucket_nut_in_shell_wei",
        label: "Weight (1g interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_shell_weight",
    },
    {
        id: "bucket_nut_in_shell_hei",
        label: "Height (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_shell_height",
    },
    {
        id: "bucket_nut_in_shell_dia",
        label: "Diameter (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_shell_diameter",
    },
    {
        id: "bucket_nut_in_shell_thickness",
        label: "Shell Thickness (1mm interval)",
        binSize: 1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_in_shell_thickness",
    },
    {
        id: "bucket_nut_in_shell_breakage",
        label: "Shell Breakage",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_in_shell_breakage",
    },
    {
        id: "bucket_nut_in_shell_doubles",
        label: "Double Kernels",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_in_shell_doubles",
    },
    {
        id: "bucket_nut_in_shell_weight_dry",
        label: "Double Kernels",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_in_shell_weight_dry",
    },
    {
        id: "bucket_nut_kernel_height",
        label: "Kernel Height",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_kernel_height",
    },
    {
        id: "bucket_nut_kernel_diameter",
        label: "Kernel Diameter",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_kernel_diameter",
    },
    {
        id: "bucket_nut_kernel_rancidity",
        label: "Kernel Rancidity",
        binSize: 1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_kernel_rancidity",
    },
    {
        id: "bucket_nut_kernel_total_oil",
        label: "Kernel Total Oil",
        binSize: 0.1,
        binMin: 0,
        binMax: 100,
        measureId: "nut_kernel_total_oil",
    },
    {
        id: "bucket_nut_fatty_myristic",
        label: "Myristic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_myristic",
    },
    {
        id: "bucket_nut_fatty_palmitic",
        label: "Palmitic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_palmitic",
    },
    {
        id: "bucket_nut_fatty_palmitoleic",
        label: "Palmitoleic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_palmitoleic",
    },
    {
        id: "bucket_nut_fatty_stearic",
        label: "Stearic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_stearic",
    },
    {
        id: "bucket_nut_fatty_cis_oleic",
        label: "Cis Oleic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId:  "nut_kernel_fatty_cis_oleic",
    },
    {
        id: "bucket_nut_fatty_cis_linoleic",
        label: "Cis Linoleic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId:  "nut_kernel_fatty_cis_linoleic",
    },
    {
        id: "bucket_nut_fatty_n3_linoleic",
        label: "n3 Linolenic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_n3_linoleic",
    },
    {
        id: "bucket_nut_fatty_arachidic",
        label: "Arachidic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_arachidic",
    },
    {
        id: "bucket_nut_fatty_eicosanoic",
        label: "Eicosanoic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_eicosanoic",
    },
    {
        id: "bucket_nut_fatty_eicosadienoic",
        label: "Eicosadienoic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_eicosadienoic",
    },
    {
        id: "bucket_nut_fatty_behenic",
        label: "Behenic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_behenic",
    },
    {
        id: "bucket_nut_fatty_erucic",
        label: "Erucic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_erucic",
    },
    {
        id: "bucket_nut_fatty_lignoceric",
        label: "Lignoceric Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId: "nut_kernel_fatty_lignoceric",
    },
    {
        id: "bucket_nut_fatty_nervonic",
        label: "Nervonic Acid (0.01% interval)",
        binSize: 0.01,
        binMin: 0.01,
        binMax: 50,
        measureId:  "nut_kernel_fatty_nervonic",
    },
    {
        id: "bucket_nut_kernel_total_weight",
        label: "Total Kernel Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_kernel_total_weight",
    },
    {
        id: "bucket_nut_kernel_total_recovery_weight",
        label: "Total Kernel Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_kernel_total_recovery_weight",
    },
    {
        id: "bucket_nut_kernel_first_recovery_weight",
        label: "First Grade Recovery Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 300,
        measureId: "nut_kernel_first_recovery_weight",
    },
    {
        id: "bucket_nut_kernel_recovery_perc",
        label: "Whole Kernel Recovery Percentage (0.1% interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_recovery_perc",
    },
    {
        id: "bucket_nut_kernel_half_recovery_perc",
        label: "Half Kernel Recovery Percentage (0.1% interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_half_recovery_perc",
    },
    {
        id: "bucket_nut_kernel_broken_perc",
        label: "Broken Kernels Percentage (0.1% interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_half_recovery_perc",
    },
    {
        id: "bucket_nut_kernel_mould",
        label: "Mould Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_mould_weight",
    },
    {
        id: "bucket_nut_kernel_791",
        label: "791 Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_mould_weight",
    },
    {
        id: "bucket_nut_kernel_pregerm",
        label: "Pre - Germination Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_pregerm_weight",
    },
    {
        id: "bucket_nut_kernel_immat",
        label: "Immaturity / Shrivelling Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_immat_weight",
    },
    {
        id: "bucket_nut_kernel_misshap",
        label: "Misshapeness Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_misshap_weight",
    },
    {
        id: "bucket_nut_kernel_sponginess",
        label: "Sponginess Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_sponginess_weight",
    },
    {
        id: "bucket_nut_kernel_hollow",
        label: "Hollow Centre Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_hollow_weight",
    },
    {
        id: "bucket_nut_kernel_decom",
        label: "Decomposition Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_decom_weight",
    },
    {
        id: "bucket_nut_kernel_adhered",
        label: "Decomposition Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_adhered_weight",
    },
    {
        id: "bucket_nut_kernel_insect",
        label: "Insect Damage Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_insect_weight",
    },
    {
        id: "bucket_nut_kernel_discolor",
        label: "General Discoloration Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_discolor_weight",
    },
    {
        id: "bucket_nut_kernel_dark",
        label: "Dark Kernel Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_dark_weight",
    },
    {
        id: "bucket_nut_kernel_internal",
        label: "Internal Kernel Discoloration Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_internal_weight",
    },
    {
        id: "bucket_nut_kernel_onion",
        label: "Basal Discoloration / Onion Ring Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_onion_weight",
    },
    {
        id: "bucket_nut_kernel_distal",
        label: "Distal end Browning Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_distal_weight",
    },
    {
        id: "bucket_nut_kernel_phys",
        label: "Physiological Browning Weight (0.1g interval)",
        binSize: 0.1,
        binMin: 1,
        binMax: 100,
        measureId: "nut_kernel_phys_weight",
    },

  //Nut calculated values bucket
    {
        id: "bucket_totalHuskToNISRatio_calculated",
        label: "Husk to NIS Ratio (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalHuskToNISRatio_calc",
    },
    {
        id: "bucket_totalHuskToNIHRatio_calculated",
        label: "Husk to NIH Ratio (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalHuskToNIHRatio_calc",
    },
    {
        id: "bucket_totalTKR_calculated",
        label: "TKR (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalTKR_calc",
    },
    {
        id: "bucket_totalUSRK_calculated",
        label: "USRK (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalUSRK_calc",
    },
    {
        id: "bucket_totalUSRK2_calculated",
        label: "USRK2 (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalUSRK2_calc",
    },
    {
        id: "bucket_totalUWKR_calculated",
        label: "UWKR (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalUWKR_calc",
    },
    {
        id: "bucket_totalSKR_calculated",
        label: "SKR (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalSKR_calc",
    },
    {
        id: "bucket_totalSKR2_calculated",
        label: "SKR2 (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_totalSKR2_calc",
    },
    {
        id: "bucket_mould_weight_TKR_calculated",
        label: "Mould (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_mould_weight_TKR_calc",
    },
    {
        id: "bucket_mould_weight_USKR_calculated",
        label: "Mould (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_mould_weight_USKR_calc",
    },
    {
        id: "bucket_791_weight_TKR_calculated",
        label: "791 (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_791_weight_TKR_calc",
    },
    {
        id: "bucket_791_weight_USKR_calculated",
        label: "791 (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_791_weight_USKR_calc",
    },
    {
        id: "bucket_pregerm_weight_TKR_calculated",
        label: "Pre - Germination (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_pregerm_weight_TKR_calc",
    },
    {
        id: "bucket_pregerm_weight_USKR_calculated",
        label: "Pre - Germination (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_pregerm_weight_USKR_calc",
    },
    {
        id: "bucket_immat_weight_TKR_calculated",
        label: "Immaturity/Shrivelling (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_immat_weight_TKR_calc",
    },
    {
        id: "bucket_immat_weight_USKR_calculated",
        label: "Immaturity/Shrivelling (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_immat_weight_USKR_calc",
    },
    {
        id: "bucket_misshap_weight_TKR_calculated",
        label: "Misshapennes (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_misshap_weight_TKR_calc",
    },
    {
        id: "bucket_misshap_weight_USKR_calculated",
        label: "Misshapennes (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_misshap_weight_USKR_calc",
    },
    {
        id: "bucket_sponginess_weight_TKR_calculated",
        label: "Sponginess (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_sponginess_weight_TKR_calc",
    },
    {
        id: "bucket_sponginess_weight_USKR_calculated",
        label: "Sponginess (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_sponginess_weight_USKR_calc",
    },
    {
        id: "bucket_hollow_weight_TKR_calculated",
        label: "Hollow Centre (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_hollow_weight_TKR_calc",
    },
    {
        id: "bucket_hollow_weight_USKR_calculated",
        label: "Hollow (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_hollow_weight_USKR_calc",
    },
    {
        id: "bucket_decom_weight_TKR_calculated",
        label: "Decomposition (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_decom_weight_TKR_calc",
    },
    {
        id: "bucket_decom_weight_USKR_calculated",
        label: "Decomposition (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_decom_weight_USKR_calc",
    },
    {
        id: "bucket_adhered_weight_TKR_calculated",
        label: "Adhered Skin (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_adhered_weight_TKR_calc",
    },
    {
        id: "bucket_adhered_weight_USKR_calculated",
        label: "Adhered Skin (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_adhered_weight_USKR_calc",
    },
    {
        id: "bucket_insect_weight_TKR_calculated",
        label: "Insect Damage (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_insect_weight_TKR_calc",
    },
    {
        id: "bucket_insect_weight_USKR_calculated",
        label: "Hollow (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_insect_weight_USKR_calc",
    },
    {
        id: "bucket_discolor_weight_TKR_calculated",
        label: "General Discoloration (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_discolor_weight_TKR_calc",
    },
    {
        id: "bucket_discolor_weight_USKR_calculated",
        label: "General Discoloration (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_discolor_weight_USKR_calc",
    },
    {
        id: "bucket_dark_weight_TKR_calculated",
        label: "Dark Kernel (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_dark_weight_TKR_calc",
    },
    {
        id: "bucket_dark_weight_USKR_calculated",
        label: "Dark Kernel (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_dark_weight_USKR_calc",
    },
    {
        id: "bucket_internal_discolor_weight_TKR_calculated",
        label: "Internal Kernal Discoloration (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_internal_discolor_weight_TKR_calc",
    },
    {
        id: "bucket_internal_discolor_weight_USKR_calculated",
        label: "Internal Kernal Discoloration (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_internal_discolor_weight_USKR_calc",
    },
    {
        id: "bucket_basal_weight_TKR_calculated",
        label: "Basal Discoloration (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_basal_weight_TKR_calc",
    },
    {
        id: "bucket_basal_weight_USKR_calculated",
        label: "Basal Discoloration (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_basal_weight_USKR_calc",
    },
    {
        id: "bucket_distal_weight_TKR_calculated",
        label: "Distal end Browning (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_distal_weight_TKR_calc",
    },
    {
        id: "bucket_distal_weight_USKR_calculated",
        label: "Distal end Browning (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_distal_weight_USKR_calc",
    },
    {
        id: "bucket_physio_weight_TKR_calculated",
        label: "Hollow Centre (TKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_physio_weight_TKR_calc",
    },
    {
        id: "bucket_physio_weight_USKR_calculated",
        label: "Hollow (USKR) (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "nut_kernel_physio_weight_USKR_calc",
    },

    //Citrus
    {
        id: "bucket_citrus_tss_acid_i5",
        label: "TSS/Acidity ratio (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "citrus_tss_acid_ratio",
    },
    {
        id: "bucket_citrus_color_i5",
        label: "Colour (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "citrus_cielab_color",
    },
    {
        id: "bucket_citrus_rind_thick_i1",
        label: "Grapefruit Rind Thickness (1mm interval)",
        binSize: 5,
        binMin: 5,
        binMax: 200,
        measureId: "citrus_grapefruit_rind_thick",
    },
    {
        id: "bucket_citrus_jui_i5",
        label: "Fruit Juice (5% interval)",
        binSize: 5,
        binMin: 5,
        binMax: 100,
        measureId: "citrus_juice",
    },

];