import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Crop, Index, Status, WeightedChar } from "@core/data";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { Snackbar } from "@core/material";
import { InitNewTastingCustomIndexesForm, InitUpdateTastingCustomIndexesForm, SubmitTastingCustomIndexesForm, TastingCustomIndexesFormState, TastingCustomIndexesFormStateModel } from "./tasting-custom-indexes-form.state";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export interface TastingCustomIndexesFormDialogData {
    key?: string;
    defaults?: Partial<Index>;
    cropOptions: Crop[];
    weightedCharOptions: WeightedChar[];
}

@Component({
    selector: 'pv-tasting-custom-indexes-form-dialog',
    templateUrl: 'tasting-custom-indexes-form.dialog.html',
    styleUrls: ['tasting-custom-indexes-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class TastingCustomIndexesFormDialog implements OnInit, OnDestroy {

    // STATE SELECTORS
    @Select(TastingCustomIndexesFormState)
    state$: Observable<TastingCustomIndexesFormStateModel>;

    @Select(TastingCustomIndexesFormState.data)
    data$: Observable<Partial<Index>>;

    // FORM CONTROLS
    titleControl = new FormControl(null, [Validators.required]);
    rangeControl = new FormControl(null, [Validators.required]);
    weightedCharKeysControl = new FormControl([]);
    cropKeyControl = new FormControl(null, [Validators.required]);
    ownerOrgKeyControl = new FormControl(null);

    // FORM
    formGroup: FormGroup = new FormGroup({
        title: this.titleControl,
        range: this.rangeControl,
        weightedCharKeys: this.weightedCharKeysControl,
        cropKey: this.cropKeyControl,
        ownerOrgKey: this.ownerOrgKeyControl,
    })

    weightedCharKeys: string[] = [];
    rangeOptions: number[] = [5, 10, 100];
    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingCustomIndexesFormDialog>,
        private _snackBar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: TastingCustomIndexesFormDialogData,
    ) {}

    ngOnInit(): void {
        if (this.data.key) this._store.dispatch(new InitUpdateTastingCustomIndexesForm(this.data.key));
        else this._store.dispatch(new InitNewTastingCustomIndexesForm(this.data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.data);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });

        this.weightedCharKeysControl.valueChanges
            .subscribe(charKeys => {
                if (charKeys.length < this.weightedCharKeys.length) {
                    this.weightedCharKeys = this.weightedCharKeys.filter(key => charKeys.includes(key))
                }

                if (charKeys.length > this.weightedCharKeys.length) {
                    let newItems = charKeys.filter(key => !this.weightedCharKeys.includes(key))
                    this.weightedCharKeys.push(...newItems);
                }
            })
    }

    reset(model: Partial<Index>): void {
        let data = {
            title: model.title || null,
            range: model.range || null,
            weightedCharKeys: model.weighted_char_keys || [],
            cropKey: model.crop_key || null,
            ownerOrgKey: model.ownerOrgKey,
        }

        this.formGroup.reset(data);
        this.weightedCharKeys = [...data.weightedCharKeys];
    }

    cancel(): void {
        this._dialogRef.close();
    }

    attempt(): void {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            this._snackBar.error('Invalid Input. Check your input and try again.');
        }
    }

    save(): void {
        const form = this.formGroup.value;

        const data: Partial<Index> = {
            title: form.title,
            range: form.range,
            weighted_char_keys: [...this.weightedCharKeys],
            crop_key: form.cropKey,
            ownerOrgKey: form.ownerOrgKey,
        }

        this._store.dispatch(new SubmitTastingCustomIndexesForm(data));
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.weightedCharKeys, event.previousIndex, event.currentIndex);

        this.formGroup.patchValue({
            weightedCharKeys: this.weightedCharKeys
        })
    }

    private getPrettyCharLabels(charKey: string): string {
        let char = this.data.weightedCharOptions.find(char => char.key === charKey);
        return char.label ? char.label : 'Unknown Characteristic';
    }
}