import { Component, Input } from "@angular/core";
import { Dialog, Snackbar } from '@core/material';
import { Store } from '@ngxs/store';
import { OrganizationGroup } from '@core/data';
import { RemoveGroupSample, AddGroupSample } from './groups.state';
import { OrganizationGroupSampleFormDialogData, OrganizationGroupSampleFormDialog, OrganizationGroupSampleFormDialogResult } from './organization-group-sample-form.dialog';

@Component({
    selector: 'pv-organization-group-sample-list',
    templateUrl: 'organization-group-sample-list.component.html',
    styles: [
        `
        :host {
            display: block;
            position: relative;
        }
    `
    ],
})
export class OrganizationGroupSampleList {
    @Input()
    orgKey: string;

    @Input()
    group: OrganizationGroup;

    constructor(
        private _store: Store,
        private _dialogs: Dialog,
        private _snackbar: Snackbar
    ) { }

    addGroupSample(orgKey: string, groupKey: string, groupType: string) {
        const data: OrganizationGroupSampleFormDialogData = {
            orgKey,
            groupKey,
            groupType,
        };
        this._dialogs.open(OrganizationGroupSampleFormDialog, { data })
            .afterClosed()
            .subscribe((result: OrganizationGroupSampleFormDialogResult) => {
                if (result) {
                    this._store
                        .dispatch(new AddGroupSample(groupKey, result.sampleKey))
                        .subscribe(_ => {
                            this._snackbar.info("Sample added to group");
                        });
                }
            });
    }

    removeGroupSample(groupKey: string, samplekey: string) {
        this._dialogs.confirm(
            'Remove Sample from Group',
            'Are you sure you want to remove this sample from the group?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if (res) {
                this._store.dispatch(new RemoveGroupSample(groupKey, samplekey))
                    .subscribe(state => {
                        this._snackbar.info("Sample removed from group");
                    });
            }
        });
    }
}
