import { Pipe, PipeTransform } from '@angular/core';
import { SampleFormatter } from '@app/evaluation/sample-formatter.service';
import { Site } from '@core/data';

@Pipe({
  name: 'descSiteAddress',
})
export class DescribeSiteAddressPipe implements PipeTransform {

    constructor(private _sampleFormatter: SampleFormatter){}

    transform(site: Site) {
        return this._sampleFormatter.describeSiteAddress(site);
    }
}
