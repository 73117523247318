import { Pipe, PipeTransform } from "@angular/core";
import { EvaluationUser } from "@core/data/types/tastings-evaluation";

@Pipe({
    name: 'descUserEmail'
})
export class UserEmailPipe implements PipeTransform {
    constructor() {

    }

    transform(user: EvaluationUser): string {
        if (!user) return 'N/A';
        return this.handleUserEmail(user);
    }

    private handleUserEmail(user: EvaluationUser): string {
        if (!user.email) return 'No Email';
        if (user.subscribed) return user.email;
        else return user.ident;
    }
}