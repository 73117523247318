import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DEFAULTS, TastingCustomIndexesIndexStateModel } from "./tasting-custom-indexes-index.state-model";
import { CropService, DetailRequest, Filter, FilterBuilder, FilterQueryMode, IndexService, Status, TastingsIndexCreated, TastingsIndexDeleted, TastingsIndexUpdated, WeightedCharCreated, WeightedCharService, WeightedCharUpdated, translateCommonErrorStatus } from "@core/data";
import { LocalStorage } from "@core/browser";
import { ClearTastingCustomIndexesIndexFilter, DeleteTastingCustomIndex, InitTastingCustomIndexesIndex, LoadTastingCustomIndexesIndex, PageTastingCustomIndexesIndex, QueryTastingCustomIndexesIndex, SetTastingCustomIndexesIndexSelected, SortTastingCustomIndexesIndex } from "./tasting-custom-indexes-index.state-actions";
import { catchError, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { Injectable } from "@angular/core";
@State({
    name: 'tasting_custom_indexes_index',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingCustomIndexesIndexState {
    @Selector()
    static sort(state: TastingCustomIndexesIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingCustomIndexesIndexStateModel) {
        return state.query;
    }

    constructor(
        private _indexService: IndexService,
        private _cropService: CropService,
        private _weightedCharService: WeightedCharService,
        private _storage: LocalStorage,
    ) {}

    @Action(InitTastingCustomIndexesIndex)
    InitTastingCustomIndexesIndex(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: InitTastingCustomIndexesIndex) {
        const state = ctx.getState();
        if (state.orgKey === action.orgKey && state.status !== Status.UNINITIALIZED) return;

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK,
        });

        this.restoreFilter(ctx);
        return forkJoin(
            this.loadCropOptions(ctx),
            this.loadWeightedCharOptions(ctx),
            ctx.dispatch(new LoadTastingCustomIndexesIndex)
        );
    }

    @Action(LoadTastingCustomIndexesIndex, {cancelUncompleted: true})
    loadTastingCustomIndexesIndex(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: LoadTastingCustomIndexesIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'crop',
                'weightedChars',
                'weightedChars.tags'
            ],
            counts: [],
        };

        return this._indexService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(PageTastingCustomIndexesIndex)
    pageTastingCustomIndexesIndex(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: PageTastingCustomIndexesIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size,
            }
        });

        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }

    @Action(SortTastingCustomIndexesIndex)
    sortTastingCustomIndexesIndex(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: SortTastingCustomIndexesIndex) {
        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order,
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }

    @Action(QueryTastingCustomIndexesIndex)
    queryTastingCustomIndexesIndex(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: QueryTastingCustomIndexesIndex) {
        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }

    @Action(DeleteTastingCustomIndex)
    deleteTastingCustomTag(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: DeleteTastingCustomIndex) {
        return this._indexService.delete(action.key);
    }

    @Action(ClearTastingCustomIndexesIndexFilter)
    clearTastingCustomIndexesIndexFilter(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: ClearTastingCustomIndexesIndexFilter) {
        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }

    @Action(SetTastingCustomIndexesIndexSelected)
    setTastingCustomIndexesIndexSelected(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: SetTastingCustomIndexesIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let index = state.data.find(i => i.key === key);
            if (index) return index;
            return state.selectedData.find(i => i.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(TastingsIndexCreated)
    onTastingIndexCreated(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: TastingsIndexCreated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }

    @Action(TastingsIndexUpdated)
    onTastingIndexUpdated(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: TastingsIndexCreated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }
    @Action(TastingsIndexDeleted)
    onTastingIndexDeleted(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: TastingsIndexCreated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomIndexesIndex);
    }

    @Action(WeightedCharCreated)
    onWeightedCharCreated(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: WeightedCharCreated) {
        return this.loadWeightedCharOptions(ctx);
    }

    @Action(WeightedCharUpdated)
    onWeightedCharUpdated(ctx: StateContext<TastingCustomIndexesIndexStateModel>, action: WeightedCharUpdated) {
        return this.loadWeightedCharOptions(ctx);
    }

    private loadCropOptions(ctx: StateContext<TastingCustomIndexesIndexStateModel>) {
        const filter = (new FilterBuilder).get();

        return this._cropService.query(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        cropOptions: result.data,
                    });
                }),
                catchError(e => {
                    console.warn('Error loading crop options', e);
                    ctx.patchState({
                        cropOptions: [],
                    });
                    return of(null);
                })
            );
    }

    private loadWeightedCharOptions(ctx: StateContext<TastingCustomIndexesIndexStateModel>) {
        const state = ctx.getState();
        const fb = new FilterBuilder();
        fb.setQuery('ownerOrgKeyCust', state.orgKey);

        return this._weightedCharService.query(fb.get())
            .pipe(
                tap(result => {
                    ctx.patchState({
                        weightedCharOptions: result.data,
                    });
                }),
                catchError(e => {
                    console.warn('Error loading weighted characteristics options', e);
                    ctx.patchState({
                        weightedCharOptions: [],
                    })
                    return of(null);
                })
            );
    }

    private getCurrentFilter(state: TastingCustomIndexesIndexStateModel): Filter {
        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (Array.isArray(state.query.cropKeys) && state.query.cropKeys.length > 0) {
            fb.setQuery('crop_key', state.query.cropKeys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.weightedCharKeys) && state.query.weightedCharKeys.length > 0) {
            fb.setQuery('weighted_char_keys', state.query.weightedCharKeys, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingCustomIndexesIndexStateModel>) {
        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tasting_custom_indexes_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tasting_custom_indexes_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tasting_custom_indexes_index_query`, state.query);
    }

    private restoreFilter(ctx: StateContext<TastingCustomIndexesIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tasting_custom_indexes_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tasting_custom_indexes_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tasting_custom_indexes_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });
    }
}