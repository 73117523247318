import { TastingEvaluation } from "./_types";
//WIP (default)
export const LIB_TASTINGS_EVAL_BERRY: TastingEvaluation = {
    id: "tastings_berry_scorecard",
    title: "Berry Tasting Evaluation",
    crop: "Berry",

    weightedChars: [
        "tastings_Attractiveness",
        "tastings_Texture",
        "tastings_Burst Sensation",
        "tastings_Taste & Aroma",
        "tastings_Overall Eating Quality",
        "tastings_Overall Interest",
    ],

    attributes: [
        "tough skin",
        "high acid",
        "high sugar",
        "juicy",
        "dry",
        "attractive",
        "aromatic",
        "suture presence",
        "abrasions",
        "firm",
        "tough skin",
        "skin bloom",
        "mixed maturity",
    ],

    range: 10,

}