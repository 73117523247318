<button mat-menu-item (click)="addGroupUser(orgKey, group.key); $event.stopPropagation();" matTooltip="Add User">
    <mat-icon>add</mat-icon>
    <span>Add User(s)</span>
</button>
<ng-container *ngIf="group">
    <a mat-menu-item role="list-item" *ngFor="let user of group.users"
        (click)="removeGroupUser(group.key, user.key); $event.stopPropagation();"
        matTooltip="Remove User">
        <mat-icon matListIcon>account_circle</mat-icon>
        <span matLine><strong>{{ user.name }}</strong></span>&nbsp;
        <span matLine>{{ user.email }}</span>&nbsp;
    </a>
</ng-container>
