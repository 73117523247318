import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { LetterheadUploadFormStateModel, LetterheadUploadFormState, PendingReportLetterheadUpoadModel, InitReportLetterheadUploadForm, UploadLetterhead } from "./letterhead-upload-form.state";
import { Observable, Subject } from "rxjs";
import { LetterheadUpdateRequest, ReportLetterhead, Status, convertDocumentFileRef } from "@core/data";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Snackbar } from "@core/material";
import { debounceTime, takeUntil } from "rxjs/operators";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { fileExtension } from "@core/utils";

export interface LetterheadUploadFormData {
    reportKey: string;
    ownerOrgKey: string;
}

@Component({
    selector: 'pv-letterhead-upload-dialog',
    templateUrl: './letterhead-upload-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'pv-letterhead-upload-dialog pv-fullscreen-dialog'
    }
})
export class LetterheadUploadDialog implements OnInit, OnDestroy {

    fileTypes = {
        image: ['png', 'jpg', 'jpeg'],
    }

    @Select(LetterheadUploadFormState)
    state$: Observable<LetterheadUploadFormStateModel>;

    @Select(LetterheadUploadFormState.data)
    data$: Observable<ReportLetterhead[]>;

    @Select(LetterheadUploadFormState.uploads)
    uploads$: Observable<PendingReportLetterheadUpoadModel[]>;

    formArray = new FormArray([]);
    formGroup = new FormGroup({
        _noop: new FormControl(true),
        letterheads: this.formArray,
    });

    isDragging = false;
    maxSize = 40000000; // 40MB
    validationErrors: string[] = [];
    addedLetterhead: boolean = false;

    private pushedUploadIds: number[] = [];
    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<LetterheadUploadDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: LetterheadUploadFormData,
    ) {}

    ngOnInit(): void {
        this._store.dispatch(new InitReportLetterheadUploadForm(this.data.reportKey, this.data.ownerOrgKey));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                this.addedLetterhead = state.addedLetterhead;

                if(state.status === Status.COMPLETE) {
                    this._snackbar.info("Letterheads Updated");
                    this.cancel();
                } else if (state.status !== Status.LOADING) {
                    this.formGroup.enable({onlySelf: true});
                } else {
                    this.formGroup.disable({onlySelf: true});
                }
            });

        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.buildForm(data);
            })

        this.uploads$
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(500)
            )
            .subscribe(uploads => {
                if (Array.isArray(uploads) && uploads.length < 1) return;

                uploads.forEach(upload => {
                    if (
                        upload.status === Status.COMPLETE
                        && upload.data
                        && !this.pushedUploadIds.includes(upload.id)
                    ) {
                        this.pushedUploadIds.push(upload.id);
                        this.pushUploadedLetterheads(upload.data);
                    }
                });
            })
    }

    fileInputChanged(event: Event) {
        let input = <HTMLInputElement> event.target;
        if (input.files && input.files.length > 0) this.uploadLetterheads(input.files);
    }

    onDragOver(event: DragEvent) {
        if (event.dataTransfer) event.dataTransfer.dropEffect = 'copy';
        if (!this.isDragging) this.isDragging = true;
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent) {
        if (this.isDragging) this.isDragging = false;
        event.preventDefault();
    }

    onDrop(event: DragEvent) {
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
            this.uploadLetterheads(event.dataTransfer.files);
        }

        this.isDragging = false;
        event.preventDefault();
        event.stopPropagation();
    }

    trackUpload(index, item: PendingReportLetterheadUpoadModel) {
        return item.id;
    }

    getSrc(ref: string, ext: string) {
        return convertDocumentFileRef(ref, ext);
    }

    downloadLetterhead(ref: string, fileName: string) {
        let url = this.getSrc(ref, fileExtension(fileName));
        window.open(url, "_blank");
    }

    isImage(extension: string): boolean {
        return this.fileTypes.image.includes(extension);
    }

    attempt() {
        if (this.formGroup.valid) {
            this._dialogRef.close(true);
        }else {
            this._snackbar.error("Form not valid");
        }
    }

    private buildForm(model: ReportLetterhead[]) {
        this.formArray.clear();
        this.formGroup.reset({ letterheads: [] });

        model.forEach(letterhead => {
            let control = this.makeLetterheadGroup(letterhead);
            this.formArray.push(control);
        });
    }

    private makeLetterheadGroup(letterhead: Partial<ReportLetterhead>) {
        if (!letterhead) return null;

        return new FormGroup({
            key: new FormControl(letterhead.key || null),
            fileRef: new FormControl(letterhead.fileRef || null),
            name: new FormControl(letterhead.name || null),
            extension: new FormControl(letterhead.extension || null),
        })
    }

    private pushUploadedLetterheads(letterhead: ReportLetterhead) {
        this.formArray.push(this.makeLetterheadGroup(letterhead));
    }

    private uploadLetterheads(list: FileList) {
        if (!this.validateFiles(list)) return;

        for (let i = 0; i < list.length; i++) {
            this._store.dispatch(new UploadLetterhead(list[i], this.data.reportKey));
        }
    }

    private validateFiles(files: FileList) {
        let valid = true;
        this.validationErrors = [];

        for(let i = 0; i < files.length; i++) {
            let file = files[i];
            let fileExt = fileExtension(file.name).toLowerCase();

            if (!this.fileTypes.image.includes(fileExt)) this.validationErrors.push(`${file.name} is not a valid file type.`);
            if (file.size > this.maxSize) this.validationErrors.push(`${file.name} exceeds the maximum file size.`);
        }

        if (this.validationErrors.length > 0) valid = false;

        return valid;
    }

    cancel() : void {
        this._dialogRef.close(this.addedLetterhead);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}