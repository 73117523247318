import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DEFAULTS, TastingCustomTagsIndexStateModel } from "./tasting-custom-tags-index.state-model";
import { DetailRequest, Filter, FilterBuilder, FilterQueryMode, Status, TagCreated, TagDeleted, TagService, TagUpdated, WeightedCharCreated, WeightedCharService, WeightedCharUpdated, translateCommonErrorStatus } from "@core/data";
import { LocalStorage } from "@core/browser";
import { ClearTastingCustomTagsIndexFilter, DeleteTastingCustomTag, InitTastingCustomTagsIndex, LoadTastingCustomTagsIndex, PageTastingCustomTagsIndex, QueryTastingCustomTagsIndex, SetTastingCustomTagsIndexSelected, SortTastingCustomTagsIndex } from "./tasting-custom-tags-index.state-actions";
import { catchError, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { Injectable } from "@angular/core";

@State({
    name: 'tasting_custom_tags_index',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingCustomTagsIndexState {
    @Selector()
    static sort(state: TastingCustomTagsIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingCustomTagsIndexStateModel) {
        return state.query;
    }

    constructor(
        private _tagService: TagService,
        private _weightedCharService: WeightedCharService,
        private _storage: LocalStorage,
    ) {}

    @Action(InitTastingCustomTagsIndex)
    initTastingCustomTagsIndex(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: InitTastingCustomTagsIndex) {
        const state = ctx.getState();
        if (state.orgKey === action.orgKey && state.status !== Status.UNINITIALIZED) return;

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK,
        })

        this.restoreFilter(ctx);
        return forkJoin(
            this.loadWeightedCharOptions(ctx),
            ctx.dispatch(new LoadTastingCustomTagsIndex)
        );
    }

    @Action(LoadTastingCustomTagsIndex, {cancelUncompleted: true})
    loadTastingCustomTagsIndex(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: LoadTastingCustomTagsIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'weightedChars'
            ],
            counts: [],
        };

        return this._tagService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(PageTastingCustomTagsIndex)
    pageTastingCustomTagsIndex(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: PageTastingCustomTagsIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size,
            }
        });

        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(SortTastingCustomTagsIndex)
    sortTastingCustomTagsIndex(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: SortTastingCustomTagsIndex) {
        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order,
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(QueryTastingCustomTagsIndex)
    queryTastingCustomTagsIndex(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: QueryTastingCustomTagsIndex) {
        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(DeleteTastingCustomTag)
    deleteTastingCustomTag(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: DeleteTastingCustomTag) {
        return this._tagService.delete(action.key);
    }

    @Action(ClearTastingCustomTagsIndexFilter)
    clearTastingCustomTagsIndexFilter(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: ClearTastingCustomTagsIndexFilter) {
        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(SetTastingCustomTagsIndexSelected)
    setTastingCustomTagsIndexSelected(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: SetTastingCustomTagsIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let index = state.data.find(i => i.key === key);
            if (index) return index;
            return state.selectedData.find(i => i.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(TagCreated)
    onCustomTagCreated(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: TagCreated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(TagUpdated)
    onCustomTagUpdated(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: TagUpdated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }
    @Action(TagDeleted)
    onCustomTagDeleted(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: TagDeleted) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(WeightedCharCreated)
    onWeightedCharCreated(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: WeightedCharCreated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    @Action(WeightedCharUpdated)
    onCustomWeightedCharUpdated(ctx: StateContext<TastingCustomTagsIndexStateModel>, action: WeightedCharUpdated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomTagsIndex);
    }

    private loadWeightedCharOptions(ctx: StateContext<TastingCustomTagsIndexStateModel>) {
        const state = ctx.getState();
        const fb = new FilterBuilder()
        fb.setQuery('ownerOrgKeyCust', state.orgKey);

        return this._weightedCharService.query(fb.get())
            .pipe(
                tap(result => {
                    ctx.patchState({
                        weightedCharOptions: result.data,
                    });
                }),
                catchError(e => {
                    console.warn('Error loading weighted characteristics options', e);
                    ctx.patchState({
                        weightedCharOptions: [],
                    })
                    return of(null);
                })
            );
    }

    private getCurrentFilter(state: TastingCustomTagsIndexStateModel): Filter {
        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey)

        if (Array.isArray(state.query.weightedCharKeys) && state.query.weightedCharKeys.length > 0) {
            fb.setQuery('weighted_char_keys', state.query.weightedCharKeys, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingCustomTagsIndexStateModel>) {
        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tasting_custom_tags_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tasting_custom_tags_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tasting_custom_tags_index_query`, state.query);
    }

    private restoreFilter(ctx: StateContext<TastingCustomTagsIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tasting_custom_tags_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tasting_custom_tags_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tasting_custom_tags_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });
    }
}