import { Inject, Injectable } from "@angular/core";
import { TastingEvaluation } from "../types/tastings-evaluation";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { Collection } from "../http/collection";
import { API_BASE_URI } from "../http/api";
import { Filter, FilterBuilder } from '../http/filter';
import { DetailRequestBuilder, DetailRequest } from "../http/detail";
import { tap } from "rxjs/operators";

export type TastingEvaluationUpdateRequest = Partial<TastingEvaluation>;

export class TastingsEvaluationCreated {
    static readonly type = "[TastingsEvaluationService] Tastings Evaluation Created";
    constructor(public tastingsEvaluation: TastingEvaluation){}
}

export class TastingsEvaluationUpdated {
    static readonly type = "[TastingsEvaluationService] Tastings Evaluation Updated";
    constructor(public tastingsEvaluation: TastingEvaluation){}
}

export class TastingsEvaluationDeleted {
    static readonly type = "[TastingsEvaluationService] Tastings Evaluation Deleted";
    constructor(public tastingsEvaluation: TastingEvaluation){}
}

export class TastingsEvaluationsImported {
    static readonly type = "[TastingsEvaluationsService] Tastings Evaluations Imported";
    constructor(public response: TastingsEvaluationImportResponse) {}
}

export interface TastingsEvaluationImportResponse {
    response: string;
}

@Injectable()
export class TastingsEvaluationsService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUrl: string
    ) { }

    get(tastingsEvaluationsKey: string, detail?: DetailRequest): Observable<TastingEvaluation> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<TastingEvaluation>(`${this._baseUrl}/tastings-evaluations/${tastingsEvaluationsKey}`, {params});
    }

    create(model: Partial<TastingEvaluation>) {
        return this._http.post<TastingEvaluation>(`${this._baseUrl}/tastings-evaluations/create`, model)
                .pipe(tap(tastingEvaluation => this._store.dispatch(new TastingsEvaluationCreated(tastingEvaluation))));
    }

    delete(key: string) {
        return this._http.delete<TastingEvaluation>(`${this._baseUrl}/tastings-evaluations/${key}`)
                .pipe(tap(tastingEvaluation => this._store.dispatch(new TastingsEvaluationDeleted(tastingEvaluation))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<TastingEvaluation>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        }

        return this._http.get<Collection<TastingEvaluation>>(`${this._baseUrl}/tastings-evaluations`, {params});
    }

    downloadImportTemplate(key: string) {
        return this._http.get<Blob>(`${this._baseUrl}/tastings-evaluations/import/${key}/template`, {
            responseType: 'blob' as 'json',
        });
    }

    downloadReimportTemplate(key: string) {
        return this._http.get<Blob>(`${this._baseUrl}/tastings-evaluations/reimport/${key}/template`, {
            responseType: 'blob' as 'json',
        });
    }

    import(orgKey: string, eventKey: string, file: File, date: string) {
        let data = new FormData();

        data.set('file', file);
        data.set('ownerOrgKey', orgKey);
        data.set('tastingsEventKey', eventKey);
        data.set('scoreDate', date);

        return this._http.post<TastingsEvaluationImportResponse>(`${this._baseUrl}/tastings-evaluations/import`, data)
            .pipe(tap(result => {
                this._store.dispatch(new TastingsEvaluationsImported(result));
            }))
    }

    reImport(orgKey: string, eventKey: string, file: File) {
        let data = new FormData();

        data.set('file', file);
        data.set('ownerOrgKey', orgKey);
        data.set('tastingsEventKey', eventKey);
        data.set('reimport', true.toString());

        return this._http.post<TastingsEvaluationImportResponse>(`${this._baseUrl}/tastings-evaluations/reimport`, data)
            .pipe(tap(result => {
                this._store.dispatch(new TastingsEvaluationsImported(result));
            }))
    }
}
