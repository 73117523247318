import { Cultivar, FilterBuilder, FilterPage, FilterQueryMode, FilterSort, Report, ReportService, Status, translateCommonErrorStatus } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LocalStorage } from '@core/browser';
import { Injectable } from '@angular/core';

/**
 * Initializes the index with the organization context
 */
export class InitReportIndex {
    static readonly type = "[ReportIndex] Init";
    constructor(public orgKey: string) { }
}

/**
 * Loads the report index
 */
export class LoadReportIndex {
    static readonly type = "[ReportIndex] Load";
}

/**
 * Sets the index page
 */
export class PageReportIndex {
    static readonly type = "[ReportIndex] Page";
    constructor(public index: number, public size: number) { }
}



/**
 * Sets the index sort
 */
export class SortReportIndex {
    static readonly type = "[ReportIndex] Sort";
    constructor(public column: string, public order = 'asc') { }
}

/**
 * Sets the index filter query
 */
export class QueryReportIndex {
    static readonly type = "[ReportIndex] Query";
    constructor(public query: ReportIndexQuery) { }
}

/**
 * Clear all queries, sort and page options
 */
export class ClearReportIndexFilter {
    static readonly type = "[ReportIndex] Clear Filter";
    constructor() { }
}

/**
 * Deletes Report
 */
export class DeleteReport {
    static readonly type = "[Report] Delete Report";
    constructor(public key: string) { }
}

export interface ReportIndexStateModel {
    status: Status;
    orgKey: string;
    data: Report[];
    total: number;

    // filter options
    page: FilterPage;
    pageSizeOptions: number[];
    sort: FilterSort;
    sortOptions: { id: string, label: string }[];
    query: ReportIndexQuery;

}

export interface ReportIndexQuery {
    search: string;
    scionCultivars: Cultivar[];
    rootstockCultivars: Cultivar[];
    scionCropId: string[];
}


const DEFAULTS: ReportIndexStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: null,
    data: null,
    total: 0,
    query: {
        search: null,
        rootstockCultivars: [],
        scionCultivars: [],
        scionCropId: null,
    },
    page: {
        index: 0,
        size: 20
    },
    pageSizeOptions: [20, 50, 100],
    sort: {
        column: 'updatedAt',
        order: 'desc'
    },
    sortOptions: [
        { id: 'title', label: "Title" },
        { id: 'description', label: "Description" },
        { id: 'updatedAt', label: "Updated At" },
        { id: 'createdAt', label: "Created At" },
    ]
};

@State<ReportIndexStateModel>({
    name: 'reports',
    defaults: DEFAULTS
})
@Injectable()
export class ReportIndexState {

    @Selector()
    static sort(state: ReportIndexStateModel){
        return state.sort;
    }

    @Selector()
    static query(state: ReportIndexStateModel){
        return state.query;
    }

    constructor(
        private _reportService: ReportService,
        private _storage: LocalStorage
    ) { }

    /**
     *  Initializes the report index
     * @param ctx State Context
     * @param action OrgKey: string key of organization , to set context
     */
    @Action(InitReportIndex)
    initReportIndex(ctx: StateContext<ReportIndexStateModel>, action: InitReportIndex) {

        ctx.patchState({
            ...DEFAULTS,
            orgKey: action.orgKey,
        });

        this.restoreFilter(ctx);

        return ctx.dispatch(new LoadReportIndex());
    }

    /**
     * Loads the Report Index
     * @param ctx State Context
     * @param action No Action Required
     */
    @Action(LoadReportIndex, { cancelUncompleted: true })
    loadReportIndex(ctx: StateContext<ReportIndexStateModel>, action: LoadReportIndex) {


        const state = ctx.getState();

        const filter = this.getCurrentFilter(state);

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._reportService.query(filter)
            .pipe(
                tap(
                    result => {

                        ctx.patchState({
                            status: result.total > 0 ? Status.OK : Status.EMPTY,
                            ...result
                        });

                        if(result.total > 0) this.storeFilter(ctx);
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }


    /**
     * Deletes Report
     * @param ctx State Context
     * @param action report key
     */
    @Action(DeleteReport)
    deleteReport(ctx: StateContext<ReportIndexStateModel>, action: DeleteReport) {

        return this._reportService.delete(action.key)
            .pipe(
                tap(res => {
                    if (!res) return;

                    let state = ctx.getState();

                    let data = state.data.filter(r => {
                        return r.key !== res.key;
                    });

                    ctx.patchState({
                        data,
                    });
                }),
            )
    }

    @Action(PageReportIndex)
    pageReportIndex(ctx: StateContext<ReportIndexStateModel>, action: PageReportIndex) {

        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadReportIndex);

    }

    @Action(SortReportIndex)
    sortReportIndex(ctx: StateContext<ReportIndexStateModel>, action: SortReportIndex) {

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadReportIndex);

    }

    @Action(QueryReportIndex)
    queryReportIndex(ctx: StateContext<ReportIndexStateModel>, action: QueryReportIndex) {

        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadReportIndex);

    }

    @Action(ClearReportIndexFilter)
    clearReportIndexFilter(ctx: StateContext<ReportIndexStateModel>, action: ClearReportIndexFilter) {

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadReportIndex);

    }

    private getCurrentFilter(state: ReportIndexStateModel) {

        const fb = new FilterBuilder();

        fb.setSort(state.sort);
        fb.setPage(state.page);
        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);


        if (Array.isArray(state.query.scionCultivars) && state.query.scionCultivars.length > 0) {
            let keys = state.query.scionCultivars.map(i => i.key);
            fb.setQuery('scionCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.rootstockCultivars) && state.query.rootstockCultivars.length > 0) {
            let keys = state.query.rootstockCultivars.map(i => i.key);
            fb.setQuery('rootstockCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.scionCropId) && state.query.scionCropId.length > 0) {
            fb.setQuery('scionCropId', state.query.scionCropId, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<ReportIndexStateModel>){

        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_report_index_page`, state.page);
        this._storage.set(`${state.orgKey}_report_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_report_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<ReportIndexStateModel>){
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_report_index_page`);
        const sort = this._storage.get(`${state.orgKey}_report_index_sort`);
        const query = this._storage.get(`${state.orgKey}_report_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });

    }


}