import { LocalStorage } from "@core/browser";
import { DetailRequest, Filter, FilterBuilder, FilterQueryMode, patchArrayItem, Status, TastingContactCreated, TastingContactDeleted, TastingContactService, TastingContactUpdated, translateCommonErrorStatus } from "@core/data";
import { uuid } from "@core/utils";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { ClearTastingContactIndexFilter, DeleteTastingContact, ExportTastingContactIndex, InitTastingContactIndex, LoadTastingContactIndex, PageTastingContactIndex, QueryTastingContactIndex, SetTastingContactIndexSelected, SortTastingContactIndex } from "./tasting-contact-index.state-actions";
import { DEFAULTS, TastingContactIndexStateModel } from "./tasting-contact-index.state-model";
import { Injectable } from "@angular/core";

@State({
    name: 'tasting_contact_index',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingContactIndexState {
    @Selector()
    static sort(state: TastingContactIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingContactIndexStateModel) {
        return state.query;
    }

    @Selector()
    static latestExport(state: TastingContactIndexStateModel) {
        return state.exports.length ? state.exports[state.exports.length - 1] : null;
    }

    constructor(
        private _tastingContactService: TastingContactService,
        private _storage: LocalStorage,
    ) {}

    @Action(InitTastingContactIndex)
    initTastingContactIndex(ctx: StateContext<TastingContactIndexStateModel>, action: InitTastingContactIndex) {
        const state = ctx.getState();
        if (state.orgKey === action.orgKey && state.status !== Status.UNINITIALIZED) {
            return;
        }

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK
        })

        this.restoreFilter(ctx);
        return ctx.dispatch(new LoadTastingContactIndex);
    }

    @Action(LoadTastingContactIndex, {cancelUncompleted: true})
    loadTastingContactIndex(ctx: StateContext<TastingContactIndexStateModel>, action: LoadTastingContactIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'events',
            ],
            counts: [
                //no counts
            ]
        };

        return this._tastingContactService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(PageTastingContactIndex)
    pageTastingContactIndex(ctx: StateContext<TastingContactIndexStateModel>, action: PageTastingContactIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadTastingContactIndex);
    }

    @Action(SortTastingContactIndex)
    sortTastingContactIndex(ctx: StateContext<TastingContactIndexStateModel>, action: SortTastingContactIndex) {

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingContactIndex);
    }

    @Action(QueryTastingContactIndex)
    queryTastingContactIndex(ctx: StateContext<TastingContactIndexStateModel>, action: QueryTastingContactIndex) {

        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingContactIndex);

    }

    @Action(DeleteTastingContact)
    deleteTastingContact(ctx: StateContext<TastingContactIndexStateModel>, action: DeleteTastingContact) {
        return this._tastingContactService.delete(action.key);
    }

    @Action(ClearTastingContactIndexFilter)
    clearTastingContactIndexFilter(ctx: StateContext<TastingContactIndexStateModel>, action: ClearTastingContactIndexFilter) {

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingContactIndex);

    }

    @Action(ExportTastingContactIndex)
    exportTastingContactIndex(ctx: StateContext<TastingContactIndexStateModel>, action: ExportTastingContactIndex) {

        const state = ctx.getState();

        const key = uuid();

        ctx.patchState({
            exports: [{
                key,
                status: Status.LOADING,
            }, ...state.exports]
        });

        let filter: Filter;

        const detail: DetailRequest = {
            counts: [
                //no counts
            ]
        };

        if (state.selectedKeys.length > 0) {
            filter = (new FilterBuilder())
                .setSort('updatedAt', 'desc')
                .setQuery('ownerOrgKey', state.orgKey)
                .setQuery('key', state.selectedKeys, FilterQueryMode.IN)
                .get();
        } else {
            filter = this.getCurrentFilter(state);
        }

        return this._tastingContactService.export(filter)
            .pipe(
                tap(
                    result => {

                        let exp = {
                            key,
                            status: Status.COMPLETE
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    },
                    error => {

                        let exp = {
                            key,
                            status: translateCommonErrorStatus(error),
                            url: null,
                            filename: null
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    }
                )
            );
    }

    @Action(SetTastingContactIndexSelected)
    setTastingContactIndexSelected(ctx: StateContext<TastingContactIndexStateModel>, action: SetTastingContactIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let contact = state.data.find(c => c.key === key);
            if (contact) return contact;
            return state.selectedData.find(c => c.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(TastingContactUpdated)
    onTastingContactUpdated(ctx: StateContext<TastingContactIndexStateModel>, action: TastingContactUpdated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingContactIndex);
    }

    @Action(TastingContactCreated)
    onTastingContactCreated(ctx: StateContext<TastingContactIndexStateModel>, action: TastingContactCreated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingContactIndex);
    }

    @Action(TastingContactDeleted)
    onTastingContactDeleted(ctx: StateContext<TastingContactIndexStateModel>, action: TastingContactDeleted) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingContactIndex);
    }

    private getCurrentFilter(state: TastingContactIndexStateModel): Filter {

        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (state.query.subscribed != null) {
            fb.setQuery('subscribed', state.query.subscribed, FilterQueryMode.EQUALS);
        }

        if (state.query.dobFrom) {
            fb.setQuery('dobFrom', state.query.dobFrom, FilterQueryMode.GREATER_THAN);
        }

        if (state.query.dobTo) {
            fb.setQuery('dobTo', state.query.dobTo, FilterQueryMode.LESS_THAN);
        }

        if (Array.isArray(state.query.country) && state.query.country.length > 0) {
            fb.setQuery('country', state.query.country, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.businessAreas) && state.query.businessAreas.length > 0) {
            fb.setQuery('businessAreas', state.query.businessAreas, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingContactIndexStateModel>) {

        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tasting_contact_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tasting_contact_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tasting_contact_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<TastingContactIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tasting_contact_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tasting_contact_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tasting_contact_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });

    }
}