<ng-container *ngIf="state$ | async as state">

    <div mat-dialog-title class="mat-dialog-header" [ngSwitch]="state.data?.type">
        <h2 class="dialog-title">
            <span>{{ (state.key && !state.clone) ? 'Edit' : 'Add' }}&nbsp;</span>
            <span *ngSwitchCase="'plant'">Plant Sample</span>
            <span *ngSwitchCase="'harvest'">Harvest Sample</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organisation:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
        <ng-container *ngIf="data.defaults?.massAction">
            <h3 class="dialog-subtitle">Selected Samples:</h3>
            <div style="column-count: 2;">
                <h3 class="dialog-subtitle" *ngIf="!data.defaults.plantSamples.length">No Samples Selected</h3>
                <h3 *ngFor="let sample of data.defaults.plantSamples" class="dialog-subtitle">{{ sample.code }}</h3>
            </div>
        </ng-container>
    </div>

    <div mat-dialog-content [ngSwitch]="state.data?.type">

        <form id="sample_form"
              #sampleForm="ngForm"
              [formGroup]="formGroup"
              class="pv-form"
              (ngSubmit)="attempt()"
              novalidate
              autocomplete="no">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label *ngSwitchCase="'plant'">Planted Date</mat-label>
                    <mat-label *ngSwitchCase="'harvest'">Harvest Date</mat-label>
                    <input matInput formControlName="birthDate" [matDatepicker]="birthDatePicker"
                           required>
                    <mat-datepicker-toggle matSuffix [for]="birthDatePicker">
                    </mat-datepicker-toggle>
                    <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                    <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Removed / Destroyed Date</mat-label>
                    <input matInput formControlName="deathDate" [matDatepicker]="deathDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="deathDatePicker">
                    </mat-datepicker-toggle>
                    <mat-hint>Sample removed/destroyed date</mat-hint>
                    <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                    <mat-datepicker #deathDatePicker startView="multi-year"></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Label</mat-label>
                    <input matInput type="text" formControlName="label" autocomplete="off">
                    <mat-hint>Label to identify the sample</mat-hint>
                    <mat-error>Label max 32 chars</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Size</mat-label>
                    <input matInput type="text" formControlName="size" autocomplete="off">
                    <small matSuffix>#</small>
                    <mat-hint>Number of units in sample</mat-hint>
                    <mat-error>Enter integer between 1-9999</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="4" formControlName="description"
                              style="resize: none;"></textarea>
                    <mat-hint>Describe sample or testing conditions</mat-hint>
                    <mat-error>Description max 255 chars</mat-error>
                </mat-form-field>

            </div>

            <div class="form-column" *ngIf="!data.defaults?.massAction">

                <ng-container *ngSwitchCase="'harvest'">
                    <h4 class="form-subheader">Sample Origin</h4>

                    <mat-checkbox formControlName="_connectPlantSample">
                        Connect Plant Sample
                    </mat-checkbox>
                    <span class="mat-checkbox-hint">
                        Whether harvested from existing plant sample
                    </span>
                </ng-container>


                <ng-container *ngIf="!connectPlantSampleControl.value">

                    <h4 class="form-subheader">Scion &amp; Rootstock Cultivar</h4>

                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;Scion Cultivar</span>
                        </mat-label>
                        <pv-cultivar-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                  formControlName="scionCultivar">
                        </pv-cultivar-autocomplete>
                        <button type="button" mat-icon-button matSuffix
                                (click)="addScionCultivar($event)"
                                matTooltip="Add new cultivar"
                                *ngIf="!scionCultivarControl.value">
                            <mat-icon>add_box</mat-icon>
                        </button>
                        <mat-error>Select a scion cultivar</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon svgIcon="common:flat_rootstock"></mat-icon>
                            <span>&nbsp;Rootstock Cultivar</span>
                        </mat-label>
                        <pv-cultivar-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                  formControlName="rootstockCultivar">
                        </pv-cultivar-autocomplete>
                        <button type="button" mat-icon-button matSuffix
                                (click)="addRootstockCultivar($event)"
                                matTooltip="Add new cultivar"
                                *ngIf="!rootstockCultivarControl.value">
                            <mat-icon>add_box</mat-icon>
                        </button>
                        <mat-error>Select a rootstock cultivar</mat-error>
                        <mat-hint></mat-hint>
                    </mat-form-field>

                    <h4 class="form-subheader">Location</h4>

                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon>place</mat-icon>
                            <span>&nbsp;Site</span>
                        </mat-label>
                        <pv-site-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                              formControlName="site">
                        </pv-site-autocomplete>
                        <button type="button" mat-icon-button matSuffix (click)="addSite($event)"
                                matTooltip="Add new site"
                                *ngIf="!siteControl.value">
                            <mat-icon>add_box</mat-icon>
                        </button>
                        <mat-error>Select a site</mat-error>
                    </mat-form-field>

                </ng-container>

                <ng-container *ngIf="connectPlantSampleControl.value">
                    <h4 class="form-subheader">Origin Plant Sample</h4>

                    <mat-form-field appearance="standard">
                        <mat-label>Plant Sample</mat-label>
                        <pv-sample-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                formControlName="plantSample"
                                                sampleType="plant">
                        </pv-sample-autocomplete>
                        <mat-error>Select an existing plant sample</mat-error>
                    </mat-form-field>

                </ng-container>


            </div>

            <div class="form-column" *ngSwitchCase="'plant'">

                <h4 class="form-subheader">Position</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Row</mat-label>
                    <input matInput type="text" formControlName="rowIndex" autocomplete="off">
                    <small matSuffix>#</small>
                    <mat-hint>Row # within block</mat-hint>
                    <mat-error>Enter a row number (1-999) [#]</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Position</mat-label>
                    <input matInput type="text" formControlName="positionIndex" autocomplete="off">
                    <small matSuffix>#</small>
                    <mat-hint>Sample position in row</mat-hint>
                    <mat-error>Enter a position number (1-999) [#]</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Row Spacing</mat-label>
                    <input matInput type="text" formControlName="rowDistance" autocomplete="off">
                    <small matSuffix>mm</small>
                    <mat-hint>Distance between rows</mat-hint>
                    <mat-error>Enter row distance (1-99999) [mm]</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Plant Spacing</mat-label>
                    <input matInput type="text" formControlName="colDistance" autocomplete="off">
                    <small matSuffix>mm</small>
                    <mat-hint>Distance between trees</mat-hint>
                    <mat-error>Enter a planting distance (1-99999) [mm]</mat-error>
                </mat-form-field>
            </div>


        </form>


    </div>
    <pv-form-actions mat-dialog-actions
                     [form]="sampleForm"
                     (cancel)="cancel()"
                     [status]="state.status">
    </pv-form-actions>
</ng-container>
