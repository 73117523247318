<ng-container *ngIf="data$ | async as data">
    <ng-container *ngFor="let group of data.groups; trackBy: trackGroup">
        <h4 class="cat-label">{{ group.category.label }}</h4>
        <div *ngFor="let char of group.chars; trackBy: trackChar" class="char-item" [class.char-empty]="char.value === null">
            <span class="char-label">{{ char.label }}</span>
            <span class="char-value">{{ char.value }}</span>
        </div>
    </ng-container>
    <ng-container *ngIf="data.other?.length > 0">
        <h4 class="cat-label cat-other">* Unkown</h4>
        <div *ngFor="let char of data.other; trackBy: trackChar" class="char-item" [class.char-empty]="char.value === null">
            <span class="char-label">{{ char.label }}</span>
            <span class="char-value">{{ char.value }}</span>
        </div>
        <p class="cat-other-explain">
            * These characteristics are not defined in the protocol "{{ data.protocol?.title }} [{{ data.protocol?.id }}]".
        </p>
    </ng-container>
</ng-container>