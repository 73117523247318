import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    timelineSyncEvent: EventEmitter<void> = new EventEmitter<void>();

    triggerTimelineSyncEvent() {
        this.timelineSyncEvent.emit();
    }
}