<h1 mat-dialog-title>Setup Weather Report</h1>
<div mat-dialog-content>
    <form class="pv-form" [formGroup]="formGroup">

        <div class="form-column">

            <mat-form-field appearance="standard">
                <mat-label>Report Title</mat-label>
                <input matInput type="text" required formControlName="title">
            </mat-form-field>

            <mat-form-field appearance="standard"
                            floatLabel="always">
                <mat-label>Weather Station</mat-label>
                <mat-select formControlName="stationKey" required>
                    <mat-option *ngFor="let station of stationOptions"
                                [value]="station.key">
                        {{ station.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard"
                            floatLabel="always">
                <mat-label>Report</mat-label>
                <mat-select formControlName="reportId" required>
                    <mat-option *ngFor="let report of reportOptions"
                                [value]="report.id">
                        {{ report.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <div class="form-column">

            <mat-form-field appearance="standard"
                            floatLabel="always">
                <mat-label>Start Date</mat-label>
                <input matInput
                       [matDatepicker]="startDatePicker"
                       formControlName="startDate"
                       readonly
                       required
                       (focus)="startDatePicker.open()">
                <mat-datepicker-toggle matSuffix
                                       [for]="startDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard"
                            floatLabel="always">
                <mat-label>End Date</mat-label>
                <input matInput
                       [matDatepicker]="endDatePicker"
                       formControlName="endDate"
                       readonly
                       required
                       (focus)="endDatePicker.open()">
                <mat-datepicker-toggle matSuffix
                                       [for]="endDatePicker">
                </mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="standard"
                            floatLabel="always">
                <mat-label>Select Interval</mat-label>
                <mat-select formControlName="interval" required>
                    <mat-option value="hourly">Hourly</mat-option>
                    <mat-option value="daily">Daily</mat-option>
                    <mat-option value="monthly">Monthly</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" (click)="attempt()">Save</button>
</mat-dialog-actions>
