import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild, Directive, Component } from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { SearchResponse, Status, KeyPrimary, SearchRequest } from '@core/data';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

export const AUTOCOMPETE_CHIPS_STYLES = `
    :host{
        display: block;
        min-height: 18px;
    }

    :host.disabled input {
        display: none;
    }

    input {
        outline: none;
        font: inherit;
        background: none;
        border: none;
    }

    .mat-chip .pv-model-line {
        max-width: 160px;
    }
`;

@Directive()
export abstract class ModelAutocompleteChipsComponent<T extends KeyPrimary>
implements OnDestroy, OnInit, MatFormFieldControl<T[]>, ControlValueAccessor
{

    static nextId = 0;
    id: string;

    @Input()
    set orgKey(key: string){
        this.orgKey$.next(key);
    }

    @Input()
    set placeholder(plh){
        this._placeholder = plh;
        this.stateChanges.next();
    }
    get placeholder(){
        return this._placeholder;
    }

    _placeholder = 'Add ...';

    @Input('value')
    set value(value: T[]){

        if(value === undefined || this.selected === value){
            return;
        }

        this.selected = value;
        this.stateChanges.next();
        this.onChange(value);
        this.onTouch(value);
    }
    get value(): T[] {
        return this.selected;
    }

    get empty(){
        return this.selected.length < 1;
    }


    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        if(this._disabled){
            this.textControl.disable();
        }else{
            this.textControl.enable();
        }
        this.stateChanges.next();
    }

    @HostBinding('class.disabled')
    _disabled = false;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty || this.textControl.value;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';
    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    @ViewChild('auto', {static: false})
    matAutocomplete: MatAutocomplete;

    @ViewChild('textInput', {static: false})
    textInput: ElementRef<HTMLInputElement>;

    selected: T[] = [];
    removable = true;
    focused = false;
    orgKey$ = new BehaviorSubject<string>(null);
    options: T[] = [];
    status = Status.UNINITIALIZED;
    textControl = new FormControl();

    // MatFormFieldControl
    stateChanges = new Subject<void>();
    ngControl: NgControl = null;
    errorState = false;
    controlType = 'autochips';

    onChange: any = () => {};
    onTouch: any = () => {};

    private _destroy$ = new Subject();

    constructor(
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeDetect: ChangeDetectorRef
        ){

        // set unique id
        ModelAutocompleteChipsComponent.nextId++;
        this.id = `${this.controlType}-${ModelAutocompleteChipsComponent.nextId}`;

        // monitor focus state from host element
        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    ngOnInit(){

        this.stateChanges.subscribe(() => this._changeDetect.markForCheck());

        combineLatest(this.orgKey$, this.textControl.valueChanges)
            .pipe(
                takeUntil(this._destroy$),
                debounceTime(300),
                filter(changes => {
                    return changes[0]
                    && typeof changes[1] === 'string'
                    && changes[1].length > 1;
                }),
                tap(changes => {
                    this.options = [];
                    this.status = Status.LOADING;
                    this._changeDetect.markForCheck();
                }),
                switchMap(changes => {
                    let [ownerOrgKey, text] = changes;

                    return this.search({
                        text,
                        ownerOrgKey,
                        limit: 10
                    }).pipe(catchError(err => {
                        return of(null);
                    }));
                }),
            )
            .subscribe(
                result => {
                    if(result){

                        this.options = result.results.filter(m => {
                            return this.findSelectedIndex(m) === -1;
                        });

                        if(this.options.length > 0){
                            this.status = Status.OK;
                        }else{
                            this.status = Status.EMPTY;
                        }
                    }else{
                        this.status = Status.ERROR;
                    }
                    this._changeDetect.markForCheck();
                }
            );

    }

    remove(cultivar: any): void {


        const index = this.findSelectedIndex(cultivar);

        if (!this._disabled && index >= 0) {
            let copy = [...this.selected];
            copy.splice(index, 1)
            this.selected = copy;
            this.stateChanges.next();
            this.onChange(this.selected);
            this.onTouch(this.selected);
        }
    }

    select(event: MatAutocompleteSelectedEvent): void {

        if(!this._disabled && this.findSelectedIndex(event.option.value) === -1){
            this.selected = [...this.selected, event.option.value];
            this.stateChanges.next();
            this.onChange(this.selected);
            this.onTouch(this.selected);
        }
        this.textControl.setValue(null);
    }

    writeValue(obj: any): void {
        this.selected = obj;
        this.stateChanges.next();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onContainerClick(event: MouseEvent) {
        if (!this._disabled && (event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }

    private findSelectedIndex(item: T){
        return this.selected.findIndex(m => {
            return m.key === item.key;
        });
    }

    ngOnDestroy(){
        this.orgKey$.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.stateChanges.complete();
        this._destroy$.complete();
    }

    abstract search(search: SearchRequest): Observable<SearchResponse<T>>;

}