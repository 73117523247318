<ng-container *ngIf="state$ | async as state">

    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">{{ state.key ? 'Edit Weather Station' : 'Add Weather Station' }}</h2>
        <h3 class="dialog-subtitle">{{ state.key || '' }}</h3>
    </div>

    <form #weatherStationForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            autocomplete="off">

        <div class="form-column">

            <h4 class="form-subheader">Weather sensor select</h4>

            <mat-form-field appearance="standard">
                <mat-label>Select a sensor type</mat-label>
                <mat-select formControlName="type">
                    <mat-option [value]="option.id" *ngFor="let option of sensorOptions">{{option.label}}</mat-option>
                </mat-select>
            </mat-form-field>

            <h4 class="form-subheader">Basic Info</h4>

            <mat-form-field appearance="standard">
                <mat-label>Name</mat-label>
                <input matInput
                        formControlName="name"
                        required
                        autocomplete="off">
                <mat-hint>Name to identify Weather Station</mat-hint>
                <mat-error>Enter unique name (max 32 chars)</mat-error>
            </mat-form-field>

            <h4 class="form-subheader">Geolocation</h4>

            <mat-form-field appearance="standard">
                <mat-label>Latitude</mat-label>
                <input matInput
                        type="text"
                        formControlName="lat"
                        autocomplete="off">
                <span matSuffix
                        class="mat-caption">&deg;N</span>
                <mat-error>Enter decimal number between -90 & 90</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Longitude</mat-label>
                <input matInput
                        type="text"
                        formControlName="lng"
                        autocomplete="off">
                <span matSuffix
                        class="mat-caption">&deg;E</span>
                <mat-error>Enter decimal number between -180 & 180</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Altitude</mat-label>
                <input matInput
                        type="text"
                        formControlName="alt"
                        autocomplete="off">
                <span matSuffix
                        class="mat-caption">m</span>
                <mat-error>Enter decimal number</mat-error>
            </mat-form-field>

            <ng-container *ngIf="typeControl.value == 'sigFox'">
                <h4 class="form-subheader">Configuration</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Device ID</mat-label>
                    <input matInput
                            formControlName="deviceId"
                            required
                            autocomplete="off">
                    <mat-hint>Unique 8 digit number on back of device</mat-hint>
                    <mat-error>Enter device ID</mat-error>
                    <mat-icon color="primary" class="button" matSuffix (click)="sigFoxGuide = !sigFoxGuide">help</mat-icon>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Device PAC code</mat-label>
                    <input matInput
                            formControlName="pacCode"
                            required
                            autocomplete="off">
                    <mat-hint>Unique 16 digit PAC code of device</mat-hint>
                    <mat-error>Enter 16 digit PAC code</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="form-column" *ngIf="sigFoxGuide">
            <h4 class="form-subheader">SigFox Device ID</h4>

            <pv-image-ref
                class="sigfox-guide-img"
                [fileRef]="sigFoxImagePath">
            </pv-image-ref>

            <p class="guide-text">
                Please find the Device ID on the bottom of the device as indicated above.
            </p>
        </div>
    </form>

    <!-- Actions -->
    <pv-form-actions
        mat-dialog-actions
        [form]="weatherStationForm"
        (cancel)="cancel()"
        [status]="state.status">
    </pv-form-actions>

</ng-container>
