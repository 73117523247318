import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth';
import { CultivarIndexView } from './components/cultivar-index/cultivar-index.view';
import { GroupIndexView } from './components/group-index/group-index.view';
import { ReportBuilderView } from './components/report-builder/report-builder.view';
import { ReportIndexView } from './components/report-index/report-index.view';
import { ReportPreviewView } from './components/report-preview/report-preview.view';
import { ReportSharedPreviewView } from './components/report-shared-preview/report-shared-preview.view';
import { SampleDetailView } from './components/sample-detail/sample-detail.view';
import { SampleIndexView } from './components/sample-index/sample-index.view';
import { SiteIndexView } from './components/site-index/site-index.view';
import { WeatherStationIndexView } from './components/weather-station-index/weather-station-index.view';
import {CustomProtocolIndexView} from './components/custom-protocol-index/custom-protocol-index.view';
import { SubscriptionGuard } from '@app/auth/subscription.guard';

const routes: Routes = [
    // Cultivars
    { path: 'org/:orgKey/cultivars',  component: CultivarIndexView, canActivate: [AuthGuard] },

    // Protocols
    { path: 'org/:orgKey/protocols',  component: CustomProtocolIndexView, canActivate: [AuthGuard] },

    // Sites
    { path: 'org/:orgKey/sites',  component: SiteIndexView, canActivate: [AuthGuard] },
    { path: 'org/:orgKey/weather-stations',  component: WeatherStationIndexView, canActivate: [AuthGuard] },

    // reports
    { path: 'org/:orgKey/reports', component: ReportIndexView, canActivate: [AuthGuard] },
    { path: 'org/:orgKey/reports/:reportKey', component: ReportPreviewView, canActivate: [AuthGuard] },
    { path: 'org/:orgKey/reports/:reportKey/edit', component: ReportBuilderView, canActivate: [AuthGuard] },
    { path: 'report-share/:shareKey', component: ReportSharedPreviewView },

    // Samples
    { path: 'org/:orgKey/samples/:type',  component: SampleIndexView, canActivate: [AuthGuard, SubscriptionGuard] },
    { path: 'org/:orgKey/samples/:type/:sampleKey', component: SampleDetailView, canActivate: [AuthGuard, SubscriptionGuard] },

    // Groups
    { path: 'org/:orgKey/groups',  component: GroupIndexView, canActivate: [AuthGuard] },

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class EvaluationRoutingModule {}
