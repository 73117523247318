import { IndexRescaleStrategy } from "../indexes/_types";


export enum CharacteristicType {
    Interval = 'interval',
    Nominal = 'nominal',
    Event = 'event',
    Color = 'color',
    Weighted = 'weighted', //NEW
}


export interface EventParams {
    color: string;
    bbch_code?: string;
}

export interface IntervalParams {
    min: number;
    max: number;
    step: number;
    descriptors: string[];
    minDesc?: string;
    maxDesc?: string;
}

export interface WeightedParams extends IntervalParams {
    weight: number;
    rescale?: IndexRescaleStrategy;
}

export interface NominalParams {
    options: { label: string, value: string }[];
    multiple?: boolean;
}

export interface ColorParams {
    palette: {
        h: number;
        s: number;
        l: number;
        label: string;
    }[];
}

export enum CharacteristicRescaleStrategy {
    /**
     *
     * Higher value equals a higher score.
     *
     * normal(x) = (x - char(min)) / (char(max) - char(min))
     *
     */
    POSITIVE = "POSITIVE",


    /**
     *
     * Higher value equals a lower score.
     *
     * normal(x) = 1 - (x - char(min)) / (char(max) - char(min))
     *
     */
    NEGATIVE = "NEGATIVE",

}

interface BaseCharacteristic {
    id: string;
    label: string;
    altLabels?: string[];
    type: CharacteristicType;
    params: EventParams | IntervalParams | NominalParams | ColorParams | WeightedParams;
    categoryId: string;
    rescale?: CharacteristicRescaleStrategy;
    weight?: number;
}

export interface IntervalCharacteristic extends BaseCharacteristic {
    type: CharacteristicType.Interval;
    params: IntervalParams;
}
export interface NominalCharacteristic extends BaseCharacteristic {
    type: CharacteristicType.Nominal;
    params: NominalParams;
}

export interface ColorCharacteristic extends BaseCharacteristic {
    type: CharacteristicType.Color;
    params: ColorParams;
}

export interface EventCharacteristic extends BaseCharacteristic {
    type: CharacteristicType.Event;
    params: EventParams;
}

export interface WeightedCharacteristic extends BaseCharacteristic {
    type: CharacteristicType.Weighted;
    params: WeightedParams;
}

export type Characteristic = IntervalCharacteristic | NominalCharacteristic | ColorCharacteristic | EventCharacteristic | WeightedCharacteristic;
