import { Directive, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { coerseDateProperty } from '@core/utils';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { TimelineGroupDirective } from './timeline-group.directive';
import { TimelineComponent } from './timeline.component';

@Directive({
    selector: 'pv-timeline-item'
})
export class TimelineItemDirective implements OnInit, OnDestroy{

    private static nextId = 0;
    public id: number = TimelineItemDirective.nextId++;

    @Input()
    set content(content: string){
        this.mergeUpdate({content: content || ''});
    }

    @Input()
    set tooltipTitle(title: string){
        this.mergeUpdate({title});
    }

    @Input()
    set start(date: string | moment.Moment){
        date = coerseDateProperty(date);
        this.mergeUpdate({start: date});
    }

    @Input()
    set end(date: string | moment.Moment){
        date = coerseDateProperty(date);
        this.mergeUpdate({end: date});
    }

    @Input()
    set type(type: string){
        this.mergeUpdate({type: type});
    }

    @Input()
    set subgroupId(id: string){
        this.mergeUpdate({subgroup: id});
    }

    @Input()
    set color(color: string){
        this.mergeUpdate({style: 'background-color: ' + color});
    }

    private _data$ = new BehaviorSubject<any>({id: this.id, type: 'box', content: '', subgroupOrder:0});

    constructor(
        private _timeline: TimelineComponent,
        @Optional() private _group: TimelineGroupDirective
    ){
        if(_group){
            this.mergeUpdate({ group: _group.id });
        }
    }

    ngOnInit(){

        this._data$.subscribe(data => {
            this._timeline.items.update(data);
        });

    }

    ngOnDestroy(){
        this._data$.complete();
        this._timeline.groups.remove(this.id);
    }

    private mergeUpdate(data: any){
        this._data$.next({
            ...this._data$.getValue(),
            ...data
        });
    }

}