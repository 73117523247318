import { Injectable } from '@angular/core';
import { FilterBuilder, patchArrayItem, SearchRequest, Site, SiteService, Status, translateCommonErrorStatus, SiteDeleted, SiteCreated, SiteUpdated } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export enum Focus {
    FOCUS_ALL      = "FOCUS_ALL",
    FOCUS_USER     = "FOCUS_USER",
    FOCUS_DEFAULT  = "FOCUS_DEFAULT",
    FOCUS_SELECTED = "FOCUS_SELECTED",
}

export interface SiteIndexStateModel {
    status: Status;
    orgKey: string;
    data: Site[];
    selectedSiteKey: string;

    // export result
    exports: {
        status: Status;
    }[];
}

export class InitSiteIndex {
    static readonly type = '[SiteIndex] Init Index';
    constructor(public orgKey: string) {}
}

export class LoadSiteIndex {
    static readonly type = "[SiteIndex] Load Index";
}

export class SetSelectedKey {
    static readonly type = "[SiteIndex] Set Selected Key";
    constructor(public key: string) {}
}

export class SearchSiteIndex {
    static readonly type = "[SiteIndex] Search Site Index";
    constructor(public search: string) {}
}

export class DeleteSite {
    static readonly type = "[SiteIndex] Delete";
    constructor(public key: string) {}
}

export class ImportSiteIndex {
    static readonly type = "[SiteIndex] Import";
    constructor(public file: File) {}
}

export class ExportSiteIndex {
    static readonly type = "[SiteIndex] Export";
    constructor(public type = 'xlsx') {}
}

export class DownloadSiteImportTemplate {
    static readonly type = "[SiteIndex] Download Template";
    constructor() {}
}


const DEFAULTS: SiteIndexStateModel = {
    status: Status.UNINITIALIZED,
    orgKey: null,
    data: [],
    selectedSiteKey: '',
    exports: [],
};

@State<SiteIndexStateModel>({
    name: 'site_index',
    defaults: DEFAULTS,
})
@Injectable()
export class SiteIndexState {

    constructor(
        private _siteService: SiteService,
    ){}

    @Selector()
    static data(state: SiteIndexStateModel) {
        return state.data;
    }

    /**
     *
     * Returns the corresponding site matching the selected site key.
     *
     * @param state State Context
     */
    @Selector()
    static selected(state: SiteIndexStateModel) {
        return state.data.find(site => {
            return site.key === state.selectedSiteKey;
        });
    }

    @Selector()
    static latestExport(state: SiteIndexStateModel){
        return state.exports.length ? state.exports[state.exports.length - 1] : null;
    }

    /**
     * Inits The Site Index with an org key
     *
     * @param ctx State Context
     * @param action orgKey
     */
    @Action(InitSiteIndex)
    initSiteIndex(ctx: StateContext<SiteIndexStateModel>, action: InitSiteIndex) {

        ctx.patchState({
            ...DEFAULTS,
            orgKey: action.orgKey,
        });

        return ctx.dispatch(new LoadSiteIndex);
    }

    /**
     * Loads The Site Index with a call to the API
     *
     * @param ctx State Context
     * @param action No Action Required
     */
    @Action(LoadSiteIndex, {cancelUncompleted: true})
    loadSiteIndex(ctx: StateContext<SiteIndexStateModel>, action: LoadSiteIndex) {

        ctx.patchState({
            status: Status.LOADING,
        });

        let state = ctx.getState();

        return this._siteService.query(this.getFilter(ctx))
                    .pipe(
                        tap(
                            result => {
                                if(result.data.length < 1){
                                    ctx.patchState({
                                        status: Status.EMPTY,
                                        data: [],
                                        selectedSiteKey: null,
                                    });
                                } else {
                                    ctx.patchState({
                                        status: Status.OK,
                                        data: result.data,
                                        selectedSiteKey: state.selectedSiteKey,
                                    });
                                }

                            },
                            error => {
                                this.handleError(ctx, error);
                            }
                        ),
                        catchError(e => {
                            return of(null);
                        }),
                    );
    }

    /**
     * Sets the selected site key
     *
     * @param ctx State Context
     * @param action Site Key to be selected
     */
    @Action(SetSelectedKey)
    setSelectedKey(ctx: StateContext<SiteIndexStateModel>, action: SetSelectedKey) {

        ctx.patchState({
            selectedSiteKey: action.key,
        });
    }

    /**
     * Searches the index for a site matching the search text
     *
     * @param ctx State Context
     * @param action Search Text
     */
    @Action(SearchSiteIndex, {cancelUncompleted: true})
    searchSiteIndex(ctx: StateContext<SiteIndexStateModel>, action: SearchSiteIndex) {

        let state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        if(action.search === '' || action.search === null) return ctx.dispatch(new LoadSiteIndex());

        let search: SearchRequest = {
            text: action.search,
            ownerOrgKey: state.orgKey,
            limit: 50,
        }

        return this._siteService.search(search)
                    .pipe(
                        tap(
                            result => {

                                let status = Status.OK;

                                if(result.results.length < 1) status = Status.EMPTY;

                                ctx.patchState({
                                    status: status,
                                    data: result.results,
                                });

                            },
                            error => {
                                this.handleError(ctx, error);
                            }

                        ),
                        catchError(e => {
                            return of(null);
                        })
                    );
    }

    /**
     * Delete the selected site
     *
     * @param ctx State Context
     * @param action Key of site to be deleted
     */
    @Action(DeleteSite)
    deleteSite(ctx: StateContext<SiteIndexStateModel>, action: DeleteSite) {

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._siteService.delete(action.key)
                    .pipe(
                        tap(
                            result => {
                                return ctx.dispatch(new LoadSiteIndex());
                            },
                            error => {
                                ctx.patchState({
                                    status: Status.OK
                                });
                            }
                        ),
                    );
    }

    @Action(ImportSiteIndex)
    importSiteIndex(ctx: StateContext<SiteIndexStateModel>, action: ImportSiteIndex) {
        const state = ctx.getState();
        return this._siteService.import(state.orgKey, action.file)
            .pipe(
                tap(
                    result => {
                        // No Action needed
                    },
                    error => {
                        console.warn('SiteIndexState: Upload Error:', error);

                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(DownloadSiteImportTemplate)
    downloadSiteImportTemplate(ctx: StateContext<SiteIndexStateModel>, action: DownloadSiteImportTemplate) {
        return this._siteService.downloadImportTemplate()
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = "site-import-template";
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn("SiteIndexState: Download Error ", error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        })
                    }
                )
            );
    }

    @Action(ExportSiteIndex)
    exportSiteIndex(ctx: StateContext<SiteIndexStateModel>, action: ExportSiteIndex) {
        const state = ctx.getState();
        let index = state.exports.length;

        ctx.patchState({
            exports: [...state.exports, {
                status: Status.LOADING,
            }]
        });

        return this._siteService.export(this.getFilter(ctx), state.orgKey, action.type)
            .pipe(
                tap(
                    result => {
                        let exps = [...ctx.getState().exports];

                        exps[index] = {
                            status: Status.COMPLETE,
                        };

                        ctx.patchState({
                            exports: exps,
                        });
                    },
                    error => {
                        let exps = [...ctx.getState().exports];

                        exps[index] = {
                            status: Status.ERROR,
                        };

                        ctx.patchState({
                            exports: exps,
                        });
                    }
                )
            );
    }

    @Action(SiteUpdated)
    onSiteUpdated(ctx: StateContext<SiteIndexStateModel>, action: SiteUpdated){
        return this.handleSiteChanged(ctx);
    }

    @Action(SiteCreated)
    onSiteCreated(ctx: StateContext<SiteIndexStateModel>, action: SiteCreated){
        return this.handleSiteChanged(ctx);
    }

    @Action(SiteDeleted)
    onSiteDeleted(ctx: StateContext<SiteIndexStateModel>, action: SiteDeleted){
        return this.handleSiteChanged(ctx);
    }

    private handleSiteChanged(ctx: StateContext<SiteIndexStateModel>){

        const state = ctx.getState();

        if(state.status === Status.OK){
            return ctx.dispatch(new LoadSiteIndex());
        }

    }

    /**
     * Handles and errors and sets state status accordingly
     *
     * @param ctx State Context
     * @param error Error
     */
    private handleError(ctx: StateContext<SiteIndexStateModel>, error: any){

        const state = ctx.getState();

        ctx.patchState({
            ...DEFAULTS,
            status: translateCommonErrorStatus(error),
            orgKey: state.orgKey,
        });
    }

    /**
     * Determines filter based on state config
     *
     * @param ctx State Context
     */
    private getFilter(ctx: StateContext<SiteIndexStateModel>){

        const state = ctx.getState();
        const fb = new FilterBuilder();

        if(state.orgKey){
            fb.setQuery('ownerOrgKey', state.orgKey);
        }

        return fb.get();
    }
}