import { Directive, forwardRef, OnDestroy, OnInit, NgZone } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MapPolygonRef } from './map-polygon-ref';
import { Subscription } from 'rxjs';

declare var google: any;

export const MAP_POLYGON_AREA_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MapPolygonAreaDirective),
    multi: true,
}

@Directive({
    selector: 'pv-map-polygon-area',
    providers: [
        MAP_POLYGON_AREA_VALUE_ACCESSOR,
    ],
})
export class MapPolygonAreaDirective implements OnInit, OnDestroy, ControlValueAccessor {

    private _sub: Subscription = null;
    private _value: any = null;
    private _isDisabled: boolean = false;

    private _onChange = (value: any) => { };
    private _ontouch = () => { };

    constructor(private _ref: MapPolygonRef, private _zone: NgZone) { }

    ngOnInit(): void {
        this._sub = this._ref.getPolygon().onChange().subscribe(latLng => {

            this._zone.run(() => {

                let poly = this._ref.getPolygon();

                if (poly.path && poly.path.length < 3) {
                    this._onChange(0);
                } else {
                    let area = this._ref.getPolygon().getArea();
                    this._onChange(area);
                }
            });

        });
    }

    ngOnDestroy(): void {
        this._sub.unsubscribe();
    }

    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._ontouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }


}