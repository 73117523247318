import { Directive, Input, HostBinding } from "@angular/core";
import { HostNavigator } from "@core/browser";



@Directive({
    selector: 'img[barcodeSvg]',

})
export class BarcodeSvgDirective {

    @HostBinding()
    src: string;

    @HostBinding()
    title: string;

    @Input('barcodeSvg')
    set code(code: string){
        this.src = this._hostNav.hostUrl('barcode/image/' + encodeURIComponent(code) + '.svg');
        this.title = code;
    }

    constructor(private _hostNav: HostNavigator){}
}