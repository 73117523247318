<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Schedule Evaluations</h2>
        <h3 *ngIf="!_data.defaults?.massAction; else bulkSampleCodes" class="dialog-subtitle">Sample: {{ state.sample?.code }}</h3>
        <ng-template #bulkSampleCodes>
            <h3 class="dialog-subtitle">Selected Samples:</h3>
            <div style="column-count: 3;">
                <h3 class="dialog-subtitle" *ngIf="!_data.defaults.sampleCodes.length">No Samples Selected</h3>
                <h3 *ngFor="let sampleCode of _data.defaults.sampleCodes" class="dialog-subtitle">{{ sampleCode }}</h3>
            </div>
        </ng-template>
    </div>

    <div mat-dialog-content>

        <form class="pv-form"
              [formGroup]="formGroup"
              #form="ngForm"
              novalidate
              autocomplete="false"
              (ngSubmit)="attempt()">

            <div class="form-column">

                <h3 class="form-subheader">Schedule</h3>

                <mat-form-field appearance="standard">
                    <mat-label>Storage Regime **</mat-label>
                    <mat-select formControlName="scheduleId">
                        <mat-option *ngFor="let schedule of scheduleOptions" [value]="schedule.id">{{ schedule.label }}</mat-option>
                    </mat-select>
                    <mat-hint>Apply storage schedule according to regime</mat-hint>
                    <mat-error>Schedule required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Schedule Start Date</mat-label>
                    <input matInput formControlName="startDate"
                           [matDatepicker]="startDatePicker" placeholder="YYYY-MM-DD" required>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                    <mat-hint>Storage regime start</mat-hint>
                    <mat-error>Start date required, formatted <em>YYYY-MM-DD</em></mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Duration</mat-label>
                    <input matInput type="number" formControlName="duration" required>
                    <span matSuffix>days</span>
                    <mat-hint>Duration [days] of each evaluation</mat-hint>
                    <mat-error>Enter a duration</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Evaluation Protocol ***</mat-label>
                    <mat-select formControlName="protocolId">
                        <mat-option *ngFor="let proto of protocolOptions" [value]="proto.id">{{ proto.title }}</mat-option>
                    </mat-select>
                    <mat-hint>Protocol applied to scheduled evaluations</mat-hint>
                    <mat-error>Protocol required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Sample Size</mat-label>
                    <input matInput type="number" formControlName="size" required>
                    <span matSuffix>#</span>
                    <mat-hint>Number of trees/fruit/vines in the sample</mat-hint>
                    <mat-error>Enter a sample size [min 1]</mat-error>
                </mat-form-field>


            </div>

            <div class="form-column">
                <h3 class="form-subheader">Resulting Evaluations</h3>

                <table class="pv-table">
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Size</th>
                            <th>Storage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let evalu of resultingEvaluations">
                            <td>{{ evalu.label }}</td>
                            <td>{{ evalu.evalStartDate | amDateFormat: 'll' }}</td>
                            <td>{{ evalu.evalEndDate | amDateFormat: 'll' }}</td>
                            <td>{{ evalu.size }}</td>
                            <td>
                                <span *ngFor="let storage of evalu.storageRegime">
                                    {{ storage.duration }}&nbsp;@&nbsp;{{ storage.temp }}&#8451;,
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p class="form-caption" *ngIf="resultingSchedule as schedule">
                    ** {{ schedule.label }} description: {{ schedule.description }}
                </p>

                <p class="form-caption" *ngIf="resultingProtocol as protocol">
                    *** {{ protocol.title }} applied at all stages: {{ protocol.description }}
                </p>


            </div>

        </form>

    </div>

    <pv-form-actions mat-dialog-actions [form]="form" [status]="state.status" (cancel)="cancel()">

    </pv-form-actions>

</ng-container>
