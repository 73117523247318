<pv-view #view
    *ngIf="state$ | async as state"
    toolbarTitle="Tasting Contact Index">

    <!-- TOOLBAR ACTIONS -->
    <div pv-view-tools *ngIf="selectedOrg$ | async as selectedOrg">

        <button mat-icon-button
                matTooltip="Help/Support"
                (click)="navigateToSupport()">
            <mat-icon>help_outline</mat-icon>
        </button>
    </div>
    <!-- TOOLBAR ACTIONS END -->

    <!-- SELECTION TOOLBAR -->
    <mat-toolbar pv-view-header class="tastings-contact-selection-toolbar mat-elevation-z3" color="primary"
                 *ngIf="!selection.isEmpty() && !view.printMode">

        <button mat-icon-button (click)="selection.clear()" matTooltip="Clear Selection">
            <mat-icon>clear</mat-icon>
        </button>

        <span class="flex-spacer">
            {{ selection.selected.length }} Tasting Contacts Selected
        </span>

        <button mat-flat-button matTooltip="Invite users to event" color="primary" (click)="inviteToEvent(state.selectedData)">
            <mat-icon>mail</mat-icon>
        </button>
        <button mat-flat-button matTooltip="Delete selected contacts" color="warn" (click)="deleteSelected(state.selectedKeys)">
            <mat-icon>delete</mat-icon>
        </button>
    </mat-toolbar>
    <!-- SELECTION TOOLBAR END -->

    <div pv-view-footer class="pv-table-footer tastings-contact-footer">
        <label class="mat-caption mr-12" for="sample_index_sort">Sort</label>
        <mat-select [formControl]="columnControl"
                id="sample_index_sort"
                class="tastings-contact-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">{{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)" matTooltip="Change Sort Direction">
            <mat-icon
                    [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                    [pageSize]="state.page.size"
                    [pageIndex]="state.page.index"
                    (page)="paginate($event)"
                    [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>

    <!-- FAB -->
    <ng-container pv-view-fab *ngIf="selectedOrg$ | async as selectedOrg">
        <ng-container pv-view-fab *ngIf="selectedOrg?.access.role != 'guest'">
            <button pv-view-fab *ngIf="selection.isEmpty()" mat-fab color="primary" (click)="add()" matTooltip="Add Contact">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </ng-container>

    <!-- BODY STATUS: OK -->
    <div pv-view-body class="tastings-contact-index-body" [ngSwitch]="state.status">


        <!-- STATUS MESSAGE: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Contacts..."></pv-view-loader>


        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'"
                         heading="No results"
                         message="The current filters does not match any Contacts. Update the filters or add an Contact."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction
                    (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button
                    color="primary"
                    pvMessageAction
                    (click)="add()">
                Add
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: INVALID -->
        <pv-view-message *ngSwitchCase="'INVALID'"
                         heading="Invalid Filter Parameters"
                         message="Unable to load data with given filter and sort parameters."
                         icon="error">
            <button mat-raised-button color="accent"
                    pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">
                Reload
            </button>
        </pv-view-message>


        <!-- STATUS MESSAGE: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault [heading]="state.status | descStatusTitle"
                         [message]="state.status | descStatusMessage:'Sample'" icon="error">
            <button mat-raised-button color="primary" pvMessageAction
                    (click)="reload()">Reload</button>
        </pv-view-message>


        <!-- STATUS MESSAGE: OK (ngSwitch placeholder) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>

        <div class="side-filter-container">
            <button class="filter-button" mat-icon-button matTooltip="{{showFilter ?  'Close Filter' : 'Filter options'}}" (click)="showFilter = !showFilter">
                <mat-icon *ngIf="!showFilter" matBadge="{{activeFilterCount}}" matBadgeHidden="{{activeFilterCount === 0}}"
                    matBadgePosition="before" matBadgeColor="accent" matBadgeSize="small">
                    filter_list
                </mat-icon>
                <mat-icon *ngIf="showFilter">close</mat-icon>
            </button>
            <div class="side-panel"  [ngClass]="{'slide-out': showFilter} ">
                <div class="side-panel-content">
                    <button mat-icon-button matTooltip="Reload index" (click)="reload()" id="reloadIndex">
                        <mat-icon>refresh</mat-icon>
                    </button>

                    <div class="filter-title">Filter Contacts:</div>

                    <form class="pv-tasting-contact-query-form" [formGroup]="queryFormGroup">

                        <mat-form-field appearance="outline">
                            <mat-label>
                                <mat-icon>search</mat-icon> Search Contacts
                            </mat-label>
                            <input type="text" matInput formControlName="search"
                                    placeholder="Enter contact first name, last name or email">
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Date of Birth From</mat-label>
                            <input matInput formControlName="dobFrom" [matDatepicker]="dobPickerFrom">
                            <mat-datepicker-toggle matSuffix [for]="dobPickerFrom">
                            </mat-datepicker-toggle>
                            <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                            <mat-datepicker #dobPickerFrom startView="multi-year"></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Date of Birth To</mat-label>
                            <input matInput formControlName="dobTo" [matDatepicker]="dobPickerTo">
                            <mat-datepicker-toggle matSuffix [for]="dobPickerTo">
                            </mat-datepicker-toggle>
                            <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                            <mat-datepicker #dobPickerTo startView="multi-year"></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Country</mat-label>
                            <mat-select formControlName="country" multiple>
                                <mat-option *ngFor="let country of countries" [value]="country.code">{{ country.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Business Areas</mat-label>
                            <mat-select formControlName="businessAreas" multiple>
                                <mat-option *ngFor="let area of businessAreas" [value]="area.id">{{area.label}}</mat-option>
                            </mat-select>
                            <mat-hint>Business Areas of the tastings contact</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="standard">
                            <mat-label>Subscribed</mat-label>
                            <mat-select formControlName="subscribed">
                                <mat-option *ngFor="let item of subscriptionFilters" [value]="item.value">{{item.label}}</mat-option>
                            </mat-select>
                            <mat-hint>Filter users by subscription status</mat-hint>
                        </mat-form-field>

                        <div class="form-actions">
                            <button mat-icon-button color="warn" (click)="resetFilter()">
                                <mat-icon matTooltip="Clear Filters">
                                    filter_alt_off
                                </mat-icon>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <!-- PAGE DATA -->
        <ng-container *ngIf="state.status === 'OK' || state.status === 'LOADING'">
            <!-- TABLE -->
            <div class="pv-responsive-table">

                <table cdk-table
                       class="pv-tasting-contact-table"
                       [dataSource]="state.data"
                       [trackBy]="trackByKey"
                       matSort
                       [matSortActive]="state.sort.column"
                       [matSortDirection]="state.sort.order"
                       [matSortDisableClear]="true"
                       (matSortChange)="sort($event)">

                    <!-- Column: Checkbox -->
                    <ng-container cdkColumnDef="checkbox">
                        <th cdk-header-cell *cdkHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle(state.data) : null"
                                          [checked]="selection.hasValue() && isAllSelected(state.data)"
                                          [indeterminate]="selection.hasValue() && !isAllSelected(state.data)"
                                          class="no-print">
                            </mat-checkbox>
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="image-overlay no-print" (click)="selection.toggle(row.key)">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row.key) : null"
                                              [checked]="selection.isSelected(row.key)">
                                </mat-checkbox>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Column: First Name -->
                    <ng-container cdkColumnDef="firstName">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="firstName">First Name</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.firstName">{{ row.firstName }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Last Name -->
                    <ng-container cdkColumnDef="lastName">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="lastName">Last Name</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.lastName">{{ row.lastName }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Email -->
                    <ng-container cdkColumnDef="email">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="email">Email</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.email && row.subscribed">{{ row.email }}</div>
                            <div class="body-line" *ngIf="!row.subscribed">Not shared by user</div> <!-- Hide email if user is not subscribed - prefers to keep it private -->
                        </td>
                    </ng-container>

                    <!-- Column: DOB -->
                    <ng-container cdkColumnDef="dob">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="dob">Date of Birth</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.dob">{{ row.dob | date: 'mediumDate' }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Country -->
                    <ng-container cdkColumnDef="country">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="country">Country</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.country">{{ row.country | country }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Business Areas -->
                    <ng-container cdkColumnDef="businessAreas">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="businessAreas">Business Areas</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.businessAreas">{{ row.businessAreas | formatBusinessArea }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Subscribed -->
                    <ng-container cdkColumnDef="subscribed">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="subscribed">Subscribed</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line">{{ row.subscribed ? 'Yes' : 'No' }}</div>
                        </td>
                    </ng-container>

                    <!-- Column: Expiry Date
                    <ng-container cdkColumnDef="expiryDate">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="expiryDate">Expiry Date</th>
                        <td cdk-cell *matCellDef="let row">
                            <div class="body-line" *ngIf="row.expiryDate">{{ row.expiryDate | amDateFormat:'ll' }}</div>
                        </td>
                    </ng-container> -->

                    <!-- Column: Actions -->
                    <ng-container cdkColumnDef="actions">
                        <th cdk-header-cell *cdkHeaderCellDef class="no-print"></th>
                        <td cdk-cell *matCellDef="let row" class="no-print">
                            <a mat-icon-button
                                *ngIf="row.subscribed"
                                (click)="$event.stopPropagation(); edit(row)"
                                matTooltip="Edit Contact">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a mat-icon-button
                               (click)="$event.stopPropagation(); delete(row)"
                               matTooltip="Delete Contact">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <!-- Column: Timestamps -->
                    <ng-container cdkColumnDef="timestamps">
                        <th cdk-header-cell *cdkHeaderCellDef mat-sort-header="createdAt">Timestamps
                        </th>
                        <td cdk-cell *matCellDef="let row">
                            <span class="caption-line">Added
                                {{ row.createdAt | amDateFormat:'ll' }}</span>
                            <span class="caption-line">Updated
                                {{ row.updatedAt | amTimeAgo }}</span>
                        </td>
                    </ng-container>

                    <!-- Row Defs -->
                    <tr cdk-header-row
                        *cdkHeaderRowDef="state.displayColumns">
                    </tr>
                    <tr cdk-row
                        *cdkRowDef="let row; columns: state.displayColumns;"
                        [class.selected]="selection.isSelected(row.key)">
                    </tr>

                </table>
            </div>
            <!-- TABLE END -->
        </ng-container>
        <!-- PAGE DATA END -->

    </div>
</pv-view>