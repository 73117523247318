



export interface DetailRequest {
    counts?: string[];
    related?: string[];
}


export class DetailRequestBuilder {

    private _detail: DetailRequest;

    constructor(detail?: DetailRequest){

        if(detail){
            this._detail = {
                counts: Array.isArray(detail.counts) ? [...detail.counts] : [],
                related: Array.isArray(detail.related) ? [...detail.related] : []
            };
        }else{
            this._detail = {
                counts: [],
                related: []
            };
        }

    }

    addCount(countCol: string){
        if(this._detail.counts.indexOf(countCol) === -1){
            this._detail.counts.push(countCol);
        }
        return this;
    }

    addRelated(relatedCol: string){
        if(this._detail.related.indexOf(relatedCol) === -1){
            this._detail.related.push(relatedCol);
        }
        return this;
    }

    get(): DetailRequest {
        return this._detail;
    }

    getQueryParams(){
        return DetailRequestBuilder.queryParams(this._detail);
    }

    public static queryParams(detail: DetailRequest){
        let params:any = {};
        
        if(detail){
            if(Array.isArray(detail.counts) && detail.counts.length > 0){
                params.dc = detail.counts.join(',');
            }
    
            if(Array.isArray(detail.related) && detail.related.length > 0){
                params.dr = detail.related.join(',');
            }
        }


        return params;
    }


}