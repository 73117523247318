import {Component, ChangeDetectionStrategy, OnInit, Inject} from "@angular/core";
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Dialog, Snackbar} from '@core/material';
import {LIBRARY} from '@app/evaluation/library';
import {Library, CharacteristicCategoryGroup, MeasurementSubjectGroup, Index, Protocol, Crop} from '@library';
import {CustomProtocol, ProtocolColumn, ProtocolColumnType} from '@core/data/types/custom-protocol';
import {CustomProtocolService} from "@core/data";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export interface CustomProtocolFormDialogData {
    data: Partial<CustomProtocol>;
}

@Component({
    selector: 'pv-custom-protocol-form-dialog',
    templateUrl: 'custom-protocol-form.dialog.html',
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomProtocolFormDialog implements OnInit {

    public charGroupsCtrl: FormControl = new FormControl();
    public measGroupsCtrl: FormControl = new FormControl();

    public charGroupsFilterCtrl: FormControl = new FormControl();
    public measGroupsFilterCtrl: FormControl = new FormControl();

    public filteredCharGroups: ReplaySubject<CharacteristicCategoryGroup[]> = new ReplaySubject<CharacteristicCategoryGroup[]>(1);
    public filteredMeasGroups: ReplaySubject<MeasurementSubjectGroup[]> = new ReplaySubject<MeasurementSubjectGroup[]>(1);

    protected _onDestroyChar = new Subject<void>();
    protected _onDestroyMeas = new Subject<void>();

    isIndeterminateChar = false;
    isCheckedChar = false;

    isIndeterminateMeas = false;
    isCheckedMeas = false;

    characteristicsControl = new FormControl([]);
    measurementsControl = new FormControl([]);
    indexesControl = new FormControl([]);
    baseProtocolConrol =  new FormControl([]);
    cropIdControl: FormControl = new FormControl(null, [Validators.required]);

    public protocolTypeCtrl: FormControl = new FormControl();

    formGroup = new FormGroup({
        ownerOrgKey: new FormControl(null, [Validators.required]),
        key: new FormControl(null),
        title: new FormControl(null, [Validators.required, Validators.maxLength(64)]),
        description: new FormControl(null, [Validators.maxLength(255)]),
        protocol: new FormControl(null, [Validators.required]),
        filter: new FormControl(null, [Validators.required]),
        archived: new FormControl(false, [Validators.required]),
        protocolSubset: new FormGroup({
            characteristics: this.characteristicsControl,
            measurements: this.measurementsControl,
            indexes: this.indexesControl,
        }),
        cropId: this.cropIdControl,
        protocolType: this.protocolTypeCtrl,
        baseProtocol: this.baseProtocolConrol,
    });

    cropOptions: Crop[];
    protocols: Protocol[];
    filteredProtocols: Protocol[];
    characteristicGroups: CharacteristicCategoryGroup[];
    measurementGroups: MeasurementSubjectGroup[];
    indexOptions: Index[];
    tableReportColumnType = ProtocolColumnType;
    currentSelection: ProtocolColumn[] = [];

    dialogInit: boolean = true;
    selectedCropId: string = null;
    selectedCrop: Crop = null;
    protocolType: string = null;
    initialLoad: boolean = true;

    columns = {
        [ProtocolColumnType.Property]: [],
        [ProtocolColumnType.Characteristic]: [],
        [ProtocolColumnType.Measurement]: [],
        [ProtocolColumnType.Index]: [],
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: CustomProtocolFormDialogData,
        private _ref: MatDialogRef<CustomProtocolFormDialog>,
        private _snackbar: Snackbar,
        private _custom_protocolService: CustomProtocolService,
        private _dialog: Dialog,
        @Inject(LIBRARY) private _library: Library,
    ) {
    }


    ngOnInit() {

        this.resetSelection();


        this.protocols = this._custom_protocolService.allProtocols().all();
        this.characteristicGroups = this._library.characteristicsGroupedByCategory();
        this.measurementGroups = this._library.measurementsGroupedBySubject();
        this.indexOptions = this._library.indexes.all();

        this.cropOptions = this._library.filterExcludedCrops()

        this.characteristicsControl.disable();
        this.measurementsControl.disable();
        this.indexesControl.disable();

        this.charGroupsCtrl.disable();
        this.charGroupsFilterCtrl.disable();

        this.measGroupsCtrl.disable();
        this.measGroupsFilterCtrl.disable();

        this.baseProtocolConrol.disable();

        if (this._data.data.protocolSubset && this._data.data.protocolSubset.columns) {
            this._data.data.protocolSubset.columns.forEach(val => {
                this.columns[val.type].push(val.id);
                this.currentSelection.push({
                    id: val.id,
                    type: val.type,
                });
            })
        }

        // load the initial char list
        this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));

        // listen for search field value changes
        this.charGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyChar))
            .subscribe(() => {
                this.filterCharGroups();
            });

        // load the initial char list
        this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));

        // listen for search field value changes
        this.measGroupsFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroyMeas))
            .subscribe(() => {
                this.filterMeasGroups();
            });

        this.protocolTypeCtrl.valueChanges
            .pipe()
            .subscribe(() => {
                this.toggleType();
            })

        this.formGroup.reset({
            ...this._data.data,
            protocolSubset: {
                characteristics: this.columns[ProtocolColumnType.Characteristic],
                measurements: this.columns[ProtocolColumnType.Measurement],
                indexes: this.columns[ProtocolColumnType.Index],
            }
        });

        this.formGroup.controls.protocolSubset.setValidators(this.atLeastOneValidator());
        this.setType();

    }

    public setType() {
        //Sets the protocolType toggle switch
        this.protocolType = this._data.data.protocolType;

        if (this.protocolType) {
            if(this.protocolType == 'plant')  this.protocolTypeCtrl.setValue(0);
            if(this.protocolType == 'harvest') this.protocolTypeCtrl.setValue(1);
        } else {
            this.protocolType = 'plant';
            this.protocolTypeCtrl.setValue(0);
        }
    }

    public toggleType() {
        if(this.protocolTypeCtrl.value == 0) this.protocolType = 'plant';
        if(this.protocolTypeCtrl.value == 1) this.protocolType = 'harvest';

        if(this.selectedCrop) {
            // Update chars and meas
            this.characteristicGroups = [];
            this.measurementGroups = [];
            this.filteredProtocols = [];
            this.currentSelection = [];

            this.characteristicGroups = this.characteristicsGroupedByCategoryWithIds(this.getCropChars(this.selectedCrop, this.protocolType));
            this.measurementGroups = this.measurementsGroupedBySubjectWithIds(this.getCropMeas(this.selectedCrop, this.protocolType));

            this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
            this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));

            this.filterProtocolList(this.selectedCrop.id, this.protocolType);
        }

    }

    public filterCharGroups() {
        if (!this.characteristicGroups) {
            return;
        }
        // get the search keyword
        let search = this.charGroupsFilterCtrl.value;
        const charGroupsCopy = this.copyCharGroups(this.characteristicGroups);
        if (!search) {
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the groups
        this.filteredCharGroups.next(
            charGroupsCopy.filter(charGroup => {
                const showBankGroup = charGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    charGroup.chars = charGroup.chars.filter(char => char.label.toLowerCase().indexOf(search) > -1);
                }
                return charGroup.chars.length > 0;
            })
        );
    }

    protected copyCharGroups(charGroups: CharacteristicCategoryGroup[]) {
        const charGroupsCopy = [];
        charGroups.forEach(charGroup => {
            charGroupsCopy.push({
                category: charGroup.category.label,
                id: charGroup.id,
                chars: charGroup.chars.slice()
            });
        });
        return charGroupsCopy;
    }

    protected filterMeasGroups() {
        if (!this.measurementGroups) {
            return;
        }
        // get the search keyword
        let search = this.measGroupsFilterCtrl.value;
        const measGroupsCopy = this.copyMeasGroups(this.measurementGroups);
        if (!search) {
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the groups
        this.filteredMeasGroups.next(
            measGroupsCopy.filter(measGroup => {
                const showBankGroup = measGroup.id.indexOf(search) > -1;
                if (!showBankGroup) {
                    measGroup.measures = measGroup.measures.filter(meas => meas.label.toLowerCase().indexOf(search) > -1);
                }
                return measGroup.measures.length > 0;
            })
        );
    }

    protected copyMeasGroups(measGroups: MeasurementSubjectGroup[]) {
        const measGroupsCopy = [];
        measGroups.forEach(measGroup => {
            measGroupsCopy.push({
                subject: measGroup.subject.label,
                id: measGroup.id,
                measures: measGroup.measures.slice()
            });
        });
        return measGroupsCopy;
    }

    ngOnDestroy() {
        this._onDestroyChar.next();
        this._onDestroyChar.complete();
        this._onDestroyMeas.next();
        this._onDestroyMeas.complete();
    }

    attempt() {
        this.formGroup.updateValueAndValidity();

        if (this.formGroup.valid && this.formGroup.controls.protocolSubset.valid) {
            if(this.currentSelection.length != 0) {
                this._dialog.confirmSave(
                    'Save Protocol',
                    'Are you sure you want to save '+this.formGroup.controls.title.value+'?' +
                    '<br>'+
                    '<b>' +
                        'This action may affect existing evaluations and reports.'+
                    '</b>',
                    'Save',
                    'Cancel'
                ).afterClosed().subscribe(res => {
                    if (res) {
                        const form = this.formGroup.value;
                        const cast = {
                            key: this._data.data.key,
                            ownerOrgKey: this._data.data.ownerOrgKey,
                            title: form.title,
                            description: form.description,
                            protocol: form.protocol,
                            baseProtocolId: null,
                            filter: form.filter,
                            archived: form.archived,
                            cropId: form.cropId,
                            protocolType: form.protocolType == 0 ? 'plant' : 'harvest',
                            protocolSubset: {
                                columns: this.currentSelection,
                            },
                        };
                        this._ref.close(cast);
                    }
                });
            } else this._snackbar.formInvalid('Please select at least one property.');
        } else this._snackbar.formInvalid();
    }

    cancel() {
        this._ref.close();
    }

    trackById(index, item) {
        return item.id;
    }

    getCropChars(crop: Crop, sampleType: string) {
        let cropChars = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.chars && protocol.sampleType == sampleType) {
                    protocol.chars.forEach(char => {
                        cropChars.push(char);
                    });
                }
            }
        });

        if(cropChars.length > 0) return cropChars;
        else return null;

    }

    getCropMeas(crop: Crop, sampleType: string) {
        let cropMeas = [];
        let protocol: Protocol;

        crop.protocols.forEach(proto => {
            if(this._library.protocols.get(proto)) {
                protocol = this._library.protocols.get(proto);

                if(protocol.measures && protocol.sampleType == sampleType) {
                    protocol.measures.forEach(meas => {
                        cropMeas.push(meas);
                    });
                }
            }
        });

        if(cropMeas.length > 0) return cropMeas;
        else return null;

    }

    private atLeastOneValidator = () => {
        return (controlGroup) => {
            let controls = controlGroup.controls;
            if (controls) {
                let theOne = Object.keys(controls).find(key => controls[key].value !== null);
                if (!theOne) {
                    return {
                        atLeastOneRequired : {
                            text : 'Please select at least one measurement, characteristic or index'
                        }
                    }
                }
            }
            return null;
        };
    };

    private enableAllControls() : void {
        this.characteristicsControl.enable();
        this.measurementsControl.enable();
        this.indexesControl.enable();

        this.charGroupsCtrl.enable();
        this.charGroupsFilterCtrl.enable();

        this.measGroupsCtrl.enable();
        this.measGroupsFilterCtrl.enable();

        this.baseProtocolConrol.enable();
    }

    private disableAllControls() : void {
        this.characteristicsControl.disable();
        this.measurementsControl.disable();
        this.indexesControl.disable();

        this.charGroupsCtrl.disable();
        this.charGroupsFilterCtrl.disable();

        this.measGroupsCtrl.disable();
        this.measGroupsFilterCtrl.disable();

        this.baseProtocolConrol.disable();
    }

    private filterProtocolList(cropId: string, sampleType: string) {
        this.baseProtocolConrol.setValue(null);
        this.filteredProtocols = this._library.protocolsByCropSample(cropId, sampleType);
    }

    updateBaseProtocol(protocol: Protocol = null){
        this.resetSelection()

        if (protocol == null) return;

        for (let i = 0; i < protocol.chars.length; i++) {
            this.currentSelection.push({
                id: protocol.chars[i],
                type: ProtocolColumnType.Characteristic,
            });

        }

        for (let i = 0; i < protocol.measures.length; i++) {
            this.currentSelection.push({
                id: protocol.measures[i],
                type: ProtocolColumnType.Measurement,
            });
        }

        this.characteristicsControl.patchValue(protocol.chars);
        this.measurementsControl.patchValue(protocol.measures);
        this.currentSelection = [...this.currentSelection];
    }

    updateCropSelection(event: MatOptionSelectionChange, crop: Crop): void {

        if (!event.isUserInput && !this.initialLoad) return;

        this.initialLoad = false;

        if (!this._data.data.key){
            this.selectedCropId = this.cropIdControl.value;
            this.selectedCrop = crop;
            this.toggleType();
        }

        if (this.dialogInit) {
           this.dialogInit = false;

            if(event.source.selected) {

                if(this.cropIdControl.value) {
                    this.selectedCropId = this.cropIdControl.value;

                    //Init with Crop
                    this.enableAllControls();

                    this.characteristicGroups = [];
                    this.measurementGroups = [];

                    this.characteristicGroups = this.characteristicsGroupedByCategoryWithIds(this.getCropChars(crop, this.protocolType));
                    this.measurementGroups = this.measurementsGroupedBySubjectWithIds(this.getCropMeas(crop, this.protocolType));
                    this.indexOptions = this._library.indexes.all();

                    this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
                    this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));

                    this.filterProtocolList(crop.id, this.protocolType);

                } else {

                    this.selectedCropId = this.cropIdControl.value;

                    //Init without Crop
                    this.enableAllControls();

                    this.characteristicGroups = [];
                    this.measurementGroups = [];

                    this.characteristicGroups = this.characteristicsGroupedByCategoryWithIds(this.getCropChars(crop, this.protocolType));
                    this.measurementGroups = this.measurementsGroupedBySubjectWithIds(this.getCropMeas(crop, this.protocolType));
                    this.indexOptions = this._library.indexes.all();

                    this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
                    this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));

                    this.filterProtocolList(crop.id, this.protocolType);

                    this.resetSelection();
                }
            }
            return;

        } else {

            if(!event.source.selected) {
                this.selectedCropId = null;

                //Edit without Crop
                this.disableAllControls();

                this.resetSelection();

            } else if (this.selectedCropId != event.source.value) {

                //Edit with Crop
                this.enableAllControls();

                this.characteristicGroups = this.characteristicsGroupedByCategoryWithIds(this.getCropChars(crop, this.protocolType));
                this.measurementGroups = this.measurementsGroupedBySubjectWithIds(this.getCropMeas(crop, this.protocolType));
                this.indexOptions = this._library.indexes.all();

                this.filteredCharGroups.next(this.copyCharGroups(this.characteristicGroups));
                this.filteredMeasGroups.next(this.copyMeasGroups(this.measurementGroups));

                this.resetSelection();
            }
        }

    }

    public resetSelection() {
        this.characteristicsControl.reset();
        this.measurementsControl.reset();
        this.indexesControl.reset();

        this.charGroupsCtrl.reset();
        this.charGroupsFilterCtrl.reset();

        this.measGroupsCtrl.reset();
        this.measGroupsFilterCtrl.reset();

        this.currentSelection = [];
    }

    public measurementsGroupedBySubjectWithIds(measureIds: string[]): MeasurementSubjectGroup[]{
        const groups: MeasurementSubjectGroup[] = [];

        this._library.measures.all().forEach(measure => {
            if (measureIds.includes(measure.id)) {
                let group = groups.find(group => group.id === measure.subjectId);

                if (!group) {
                    group = {
                        id: measure.subjectId,
                        measures: [],
                        subject: this._library.subjects.get(measure.subjectId)
                    };
                    groups.push(group);
                }

                group.measures.push(measure);
            }
        });

        return groups;
    }

    public characteristicsGroupedByCategoryWithIds(charIds: string[]): CharacteristicCategoryGroup[] {
        const groups: CharacteristicCategoryGroup[] = [];

        this._library.chars.all().forEach(char => {
            if (charIds.includes(char.id)){

                let group = groups.find(group => group.id === char.categoryId);

                if (!group) {
                    group = {
                        id: char.categoryId,
                        chars: [],
                        category: this._library.categories.get(char.categoryId)
                    };
                    groups.push(group);
                }

                group.chars.push(char);
            }
        });

        return groups;
    }

    updateSelection(event: MatOptionSelectionChange, type: ProtocolColumnType): void {
        if (!event.isUserInput) return;

        if (event.source.selected) {
            this.currentSelection.push({
                id: event.source.value,
                type: type,
            });
        } else {
            let index = this.currentSelection.findIndex(column => column.id === event.source.value && column.type === type);
            if (index > -1) {
                this.currentSelection.splice(index, 1);
            }
        }

        this.currentSelection = [...this.currentSelection];
    }
}
