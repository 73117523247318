import { Component, ChangeDetectionStrategy, HostBinding, ElementRef, ChangeDetectorRef, Optional, Self } from "@angular/core";
import { ModelAutocompleteComponent } from './model-autocomplete.component';
import { MatFormFieldControl, MatFormField } from '@angular/material/form-field';
import { Site, SiteService, Status } from '@core/data';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import { map, catchError, startWith } from 'rxjs/operators';
import { of, Observable } from 'rxjs';





@Component({
    selector: 'pv-site-autocomplete',
    templateUrl: 'site-autocomplete.component.html',
    styleUrls: [ 'model-autocomplete.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    providers: [{
        provide: MatFormFieldControl, 
        useExisting: SiteAutocompleteComponent
    }]
})
export class SiteAutocompleteComponent extends ModelAutocompleteComponent<Site> {


    @HostBinding()
    id = `pv-site-autocomplete-${SiteAutocompleteComponent.nextId++}`;

    constructor(
        private _service: SiteService,
        protected fm: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>,
        protected _changeRef: ChangeDetectorRef,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() public _parentForm: NgForm,
        @Optional() public _parentFormGroup: FormGroupDirective,
        @Optional() public _parentFormField: MatFormField,
    ){
        super(fm, elRef, _changeRef, _defaultErrorStateMatcher, ngControl, _parentForm, _parentFormGroup, _parentFormField);
    }

    displayFn(model: Site){
        if(model) return model.name;
        return '';
    }

    search(orgKey: string, text: string): Observable<{status: Status, options: Site[]}> {
        return this._service.search({ownerOrgKey: orgKey, text: text, limit: 10})
                .pipe(
                    map(result => {

                        if(result.results.length === 0){
                            return {
                                status: Status.EMPTY,
                                options: result.results,
                            };
                        }

                        return {
                            status: Status.OK,
                            options: result.results,
                        };
                    }),
                    catchError(error => {
                        return of({
                            status: Status.ERROR,
                            options: [],
                        });
                    }),
                    startWith({
                        status: Status.LOADING,
                        options: this.options,
                    })
                );
    }

}