
<div class="report-preview-layout" *ngIf="state$ | async as state" [ngSwitch]="state.status">

        <!-- STATUS LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Report Preview...">
        </pv-view-loader>

        <!-- STATUS OK -->
        <pv-report *ngSwitchCase="'OK'" pv-view-body [report]="state.data" [formControlPassthrough]="dummyFormControl"></pv-report>

        <!-- STATUS ERROR -->
        <pv-view-message *ngSwitchCase="'NOT_FOUND'"
                         heading="Report Not Found"
                         message="Contact the owner of the report to ensure you have the updated link."
                         icon="error">
        </pv-view-message>

        <!-- STATUS ERROR -->
        <pv-view-message *ngSwitchDefault
                         heading="{{ state.status | descStatusTitle }}"
                         message="{{ state.status | descStatusMessage: 'Report Preview' }}"
                         icon="error">
            <button pvMessageAction mat-raised-button color="accent"
                    (click)="refresh()">Refresh</button>
        </pv-view-message>

</div>
