import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Evaluation, EvaluationService, Filter, FilterQueryMode } from '@core/data';
import { Snackbar } from '@core/material';
import { coerseDateProperty } from '@core/utils';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SampleIndexEvaluationBulkDialogData {
    orgKey: string;
    sampleKeys: string[];
}

@Component({
    selector: 'pv-sample-index-evaluation-bulk-dialog',
    templateUrl: 'sample-index-evaluation-bulk.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SampleIndexEvaluationBulkDialog implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SampleIndexEvaluationBulkDialogData,
        private _dialog: MatDialogRef<SampleIndexEvaluationBulkDialog>,
        private _evalService: EvaluationService,
        private _snackbar: Snackbar,
        private _store: Store,
    ) { }

    evaluations$: Observable<Evaluation[]>;
    private _destroy$ = new Subject();

    evalSizeControl = new FormControl();
    evalStartDateControl = new FormControl();

    filterFormGroup: FormGroup = new FormGroup({
        evalStartDate: this.evalStartDateControl,
        evalSize: this.evalSizeControl,
    });

    evalEndDateControl = new FormControl();

    evaluationEditFormGroup: FormGroup = new FormGroup({
        evalEndDate: this.evalEndDateControl,
    });

    ngOnInit() {
        let filter: Filter = {
            queries: [
                {
                    key: 'sampleKey',
                    mode: FilterQueryMode.IN,
                    value: this.data.sampleKeys,
                },
            ]
        };

        this.evaluations$ = this._evalService.query(filter).pipe(map(result => result.data));
    }

    updateFilters() {

        let val = this.filterFormGroup.value;

        let filter: Filter = {
            queries: [
                {
                    key: 'sampleKey',
                    mode: FilterQueryMode.IN,
                    value: this.data.sampleKeys,
                },
            ]
        };

        if (val.evalSize) {
            filter.queries.push({
                key: 'size',
                mode: FilterQueryMode.EQUALS,
                value: val.evalSize
            });
        }

        if (val.evalStartDate) {
            filter.queries.push({
                key: 'evalStartDate',
                mode: FilterQueryMode.EQUALS,
                value: coerseDateProperty(val.evalStartDate, null)
            });
        }

        this.evaluations$ = this._evalService.query(filter).pipe(map(result => result.data));
    }

    clearFilters() {
        this.filterFormGroup.reset();
        this.updateFilters();
    }

    attempt() {
        this.evaluationEditFormGroup.updateValueAndValidity();
        if (this.evaluationEditFormGroup.valid) {
            this.evaluations$.subscribe(evals => {
                this.submit(evals);
            });
        }
        else this._snackbar.error("Check your input and try again.");
    }

    submit(evals: Evaluation[]) {
        if (!evals.length) {
            this._snackbar.error("No Evaluations to update");
            return;
        }

        for(let i = 0; i < evals.length; i++) {
            this._evalService.update(evals[i].key, {evalEndDate: coerseDateProperty(this.evalEndDateControl.value)})
                .subscribe(res => {});
        }

        this._dialog.close(true);
    }

    cancel() {
        this._dialog.close();
    }
}
