import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Sample, SampleType, SiteFormDialogData, Status } from "@core/data";
import { OrganizationSubscriptionHandler } from "@core/data/globals/subscriptions";
import { TastingSample } from "@core/data/types/tasting-sample";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Dialog, Snackbar } from "@core/material";
import { coerseDateProperty, NumberValidators } from "@core/utils";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InitNewTastingSampleForm, InitUpdateTastingSampleForm, SubmitTastingSampleForm, SubmitTastingSampleWithHarvestForm, TastingSampleFormState, TastingsSampleFormStateModel } from "./tastings-sample-form.state";
import { CultivarFormDialog, CultivarFormDialogData } from "@app/evaluation/components/cultivar-form/cultivar-form.dialog";
import { SiteFormDialog } from "@app/evaluation/components/site-form/site-form.dialog";

export interface TastingSampleFormDialogData {
    key?: string;
    defaults?: Partial<TastingSample>;
    eventOptions: TastingEvent[];
}

@Component({
    selector: 'pv-tasting-sample-form-dialog',
    templateUrl: 'tastings-sample-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class TastingSampleFormDialog implements OnInit, OnDestroy {
    @Select(TastingSampleFormState)
    state$: Observable<TastingsSampleFormStateModel>;

    @Select(TastingSampleFormState.data)
    data$: Observable<Partial<TastingSample>>;

    eventOptions: TastingEvent[];
    hasEvaluationModule: boolean = true;
    isExistingSample: boolean = false;

    //Form controls
    labelControl = new FormControl(null, [Validators.required, Validators.maxLength(64)]);
    descriptionControl = new FormControl(null, [Validators.maxLength(255)]);
    sizeControl = new FormControl(null, [NumberValidators.integer(4), Validators.min(1)]);

    harvestSampleControl = new FormControl(null);
    maturityIndexControl = new FormControl(null);
    tastingsEventKeysControl = new FormControl([]);

    //Harvest info controls
    harvestLabelControl = new FormControl(null);
    harvestDateControl = new FormControl(null);
    scionCultivarControl = new FormControl(null, [Validators.required]);
    siteControl = new FormControl(null, [Validators.required]);
    birthDateControl = new FormControl(null, [Validators.required]);

    useExistingHarvestControl = new FormControl(false);

    ownerOrgKeyControl = new FormControl(null);

    connectHarvestSampleControl = new FormControl(false);
    //Form controls

    //Form groups
    formGroup: FormGroup = new FormGroup({
        label: this.labelControl,
        description: this.descriptionControl,
        size: this.sizeControl,
        //Linked data
        harvestSample: this.harvestSampleControl,
        maturityIndex: this.maturityIndexControl,
        tastingsEventKeys: this.tastingsEventKeysControl,
        //Harvest info
        harvestLabel: this.harvestLabelControl,
        harvestDate: this.harvestDateControl,
        scionCultivar: this.scionCultivarControl,
        site: this.siteControl,
        useExistingHarvest: this.useExistingHarvestControl,
        birthDate: this.birthDateControl,
        //Keys
        ownerOrgKey: this.ownerOrgKeyControl,

        _connectHarvesttSample: this.connectHarvestSampleControl,
    });
    //Form groups end

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingSampleFormDialog>,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        private _subscriptionHandler: OrganizationSubscriptionHandler,
        @Inject(MAT_DIALOG_DATA) public data: TastingSampleFormDialogData
    ) {}

    ngOnInit(): void {
        if (this.data.key) {
            this.isExistingSample = true;
            this._store.dispatch(new InitUpdateTastingSampleForm(this.data.key));
        } else {
            this.isExistingSample = false;
            this.hasEvaluationModule = this._subscriptionHandler.isSubscribedToEvaluation();
            this._store.dispatch(new InitNewTastingSampleForm(this.data.defaults));
        }

        this.eventOptions = this.data.eventOptions;

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.data);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            })

        this.formGroup.get('useExistingHarvest').valueChanges
            .subscribe(value => {
                if (!this.isExistingSample && !this.hasEvaluationModule) this.handleRequiredState(value, this.formGroup);
            });

        this.formGroup.get('harvestSample').valueChanges
            .subscribe(value => {
                if (!value) {
                    return this.formGroup.patchValue({
                        scionCultivar: null,
                        site: null,
                        birthDate: null,
                    })
                }

                this.formGroup.patchValue({
                    scionCultivar: value.scionCultivar,
                    site: value.site,
                    birthDate: value.birthDate,
                })
            })
    }

    reset(model: Partial<TastingSample>) {
        let data = {
            label: model.label || null,
            description: model.description || null,
            size: model.size || null,
            //Linked data
            harvestSample: model.harvestSample || null,
            harvestSampleKey: model.harvestSampleKey || null,
            maturityIndex: model.maturityIndexKey || null,
            tastingsEventKeys: model.tastingsEvents ? model.tastingsEvents.map(event => event.key) : [],
            scionCultivar: model.scionCultivar,
            site: model.site,
            birthDate: model.birthDate,
            //Keys
            ownerOrgKey: model.ownerOrgKey,
            _connectHarvesttSample: !!model.harvestSample,
        }

        this.formGroup.reset(data);
        this.updateValidation(this.formGroup);
    }

    cancel() {
        this._dialogRef.close();
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            if (this.isExistingSample
                || this.hasEvaluationModule
                || this.useExistingHarvestControl.value
            ) this.save();
            if (!this.isExistingSample
                && !this.hasEvaluationModule
                && !this.useExistingHarvestControl.value
            ) this.saveWithHarvest();
        } else {
            console.warn('TastingSampleDialog: form invalid', this.formGroup.value);
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    save() {
        const form = this.formGroup.value;

        //cast form data to request data
        const data: Partial<TastingSample> = {
            label: form.label,
            description: form.description,
            size: form.size,

            harvestSampleKey: form.harvestSample ? form.harvestSample.key : null,
            maturityIndexKey: form.maturityIndex,
            tastingsEventKeys: form.tastingsEventKeys,

            ownerOrgKey: form.ownerOrgKey,

            scionCultivarKey: form.scionCultivar.key,
            siteKey: form.site.key,
            birthDate: coerseDateProperty(form.birthDate),
        }

        this._store.dispatch(new SubmitTastingSampleForm(data));
    }

    saveWithHarvest() {
        const form = this.formGroup.value;

        const data: Partial<TastingSample> = {
            label: form.label,
            description: form.description,
            size: form.size,

            harvestSampleKey: null,
            maturityIndexKey: form.maturityIndex,
            tastingsEventKeys: form.tastingsEventKeys,

            ownerOrgKey: form.ownerOrgKey,
        }

        const harvestData: Partial<Sample> =
            form.harvestLabel && form.harvestDate ?
                {
                    type: SampleType.HARVEST,
                    label: form.harvestLabel,
                    birthDate: coerseDateProperty(form.harvestDate),
                    scionCultivarKey: form.scionCultivar.key,
                    siteKey: form.site.key,
                    ownerOrgKey: form.ownerOrgKey,
                    isGenerated: true,
                }
            : null

        this._store.dispatch(new SubmitTastingSampleWithHarvestForm(data, harvestData))
    }

    addScionCultivar(event: MouseEvent) {

        event.stopPropagation();

        const data: CultivarFormDialogData = {
            defaults: {
                ownerOrgKey: this.ownerOrgKeyControl.value,
            }
        };
        this._dialog.open(CultivarFormDialog, { data })
            .afterClosed()
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if (result) {
                    this.scionCultivarControl.setValue(result);
                }
            });
    }

    addSite(event: MouseEvent) {

        event.stopPropagation();

        const data: SiteFormDialogData = {
            defaults: {
                ownerOrgKey: this.ownerOrgKeyControl.value,
            }
        };
        this._dialog.open(SiteFormDialog, { data })
            .afterClosed()
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                if (result) {
                    this.siteControl.setValue(result);
                }
            });
    }

    //Custom validators
    private updateValidation(formGroup: FormGroup) {
        //Harvest info conditional validation
        if (formGroup.get('scionCultivar')) this.conditionalHarvestOptionValidation(formGroup.get('scionCultivar'));
        if (formGroup.get('site')) this.conditionalHarvestOptionValidation(formGroup.get('site'));
    }

    private conditionalHarvestOptionValidation(formControl: AbstractControl) {
        if (!formControl.parent) return;
        if (!this.isExistingSample && !this.hasEvaluationModule) formControl.setValidators(Validators.required);
    }

    private handleRequiredState(requiredState: boolean, formGroup: FormGroup) {
        if (formGroup.get('scionCultivar')) this.setRequired(!requiredState, formGroup.get('scionCultivar'));
        if (formGroup.get('site')) this.setRequired(!requiredState, formGroup.get('site'));
    }

    private setRequired(required: boolean, formControl: AbstractControl) {
        if (!formControl.parent) return;
        if (required) formControl.setValidators(Validators.required);
        else formControl.setValidators(null);
        formControl.updateValueAndValidity();
    }
    //Custom validators end

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}