import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/';

if (environment.production) {
    enableProdMode();
}

try {

    // bootstrap angular
    platformBrowserDynamic().bootstrapModule(AppModule);

    // remove splash overlay
    let splash = document.getElementById('splash_container');

    if (typeof splash.remove === 'function') {
        splash.remove();
    } else {
        splash.parentNode.removeChild(splash);
    }

}catch(e){
    console.error('Application Bootstrap Error...', e);
}

