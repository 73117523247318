<ng-container *ngIf="state$ | async as state" [ngSwitch]="state.status">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">{{ state.key ? 'Edit Site' : 'Add Site' }}</h2>
        <h3 class="dialog-subtitle">
            Organisation:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>

        <form class="pv-form"
              id="site_form"
              [formGroup]="formGroup"
              (ngSubmit)="attempt()"
              novalidate
              #siteForm="ngForm"
              autocomplete="off">

            <div class="form-column">

                <h4 class="form-subheader">Basic Info</h4>

                <!-- LOCATION/FARM -->
                <mat-form-field focus="1">
                    <input matInput formControlName="name" placeholder="Location / Farm" required
                           autocomplete="off">
                    <mat-error>Required (min 3 chars)</mat-error>
                </mat-form-field>

                <!-- BLOCK/ORCHARD -->
                <mat-form-field>
                    <input matInput formControlName="block" placeholder="Block / Orchard Name"
                           required autocomplete="off">
                    <mat-error>Required (min 3 chars)</mat-error>
                </mat-form-field>

                <!-- ROW SPACING -->
                <mat-form-field>
                    <input matInput type="text" formControlName="rowDistance" step="100"
                           placeholder="Row Spacing" autocomplete="off">
                    <span matSuffix class="mat-caption">mm</span>
                    <mat-error>Enter whole number in millimeters</mat-error>
                </mat-form-field>

                <!-- COLUMN SPACING -->
                <mat-form-field>
                    <input matInput type="text" formControlName="colDistance" step="100"
                           placeholder="Column/Plant Spacing" autocomplete="off">
                    <span matSuffix class="mat-caption">mm</span>
                    <mat-error>Enter whole number in millimeters</mat-error>
                </mat-form-field>

                <!-- NARROW ROW SPACING -->
                <mat-form-field>
                    <input matInput type="text" formControlName="narrowRowDistance" step="100"
                            placeholder="Narrow Row Spacing" autocomplete="off">
                    <span matSuffix class="mat-caption">mm</span>
                    <mat-error>Enter whole number in millimeters</mat-error>
                </mat-form-field>

            </div>

            <div class="form-column">

                <h4 class="form-subheader">Geolocation</h4>

                <!-- LAT -->
                <mat-form-field>
                    <input matInput type="text" formControlName="lat" placeholder="Latitude">
                    <span matSuffix class="mat-caption">&deg;N</span>
                    <mat-error>Enter latitude in decimal format (-90 to 90, max 8 decimals)
                    </mat-error>
                </mat-form-field>

                <!-- LNG -->
                <mat-form-field>
                    <input matInput type="text" formControlName="lng" placeholder="Longitude">
                    <span matSuffix class="mat-caption">&deg;E</span>
                    <mat-error>Enter longitude in decimal format (-180 to 180, max 8 decimals)
                    </mat-error>
                </mat-form-field>

                <!-- AREA -->
                <mat-form-field>
                    <input matInput type="text" formControlName="area" placeholder="Area">
                    <span matSuffix class="mat-caption">m<sup>2</sup></span>
                    <mat-error>Enter number [m<sup>2</sup>] up to 10<sup>14</sup> (4 decimals)
                    </mat-error>
                </mat-form-field>

                <!-- ALTITUDE -->
                <mat-form-field>
                    <input matInput type="text" formControlName="alt" placeholder="Altitude">
                    <span matSuffix class="mat-caption">m</span>
                    <mat-error>Enter number [m] up to 10<sup>5</sup></mat-error>
                </mat-form-field>

            </div>

            <div class="form-column">

                <h4 class="form-subheader">Address</h4>

                <!-- STREET -->
                <mat-form-field>
                    <input matInput type="text" formControlName="addrStreet"
                           placeholder="Street Address">
                    <mat-error>Max characters is 64</mat-error>
                </mat-form-field>

                <!-- CITY -->
                <mat-form-field>
                    <input matInput type="text" formControlName="addrCity" placeholder="City">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- REGION -->
                <mat-form-field>
                    <input matInput type="text" formControlName="addrRegion" placeholder="Region">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- DISTRICT -->
                <mat-form-field>
                    <input matInput type="text" formControlName="addrDistrict" placeholder="District">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- WARD    -->
                <mat-form-field>
                    <input matInput type="text" formControlName="addrWard" placeholder="Ward">
                    <mat-error>Max characters is 32</mat-error>
                </mat-form-field>

                <!-- CODE -->
                <mat-form-field>
                    <input matInput type="text" formControlName="addrCode"
                           placeholder="Postal Code">
                    <mat-error>Max characters is 16</mat-error>
                </mat-form-field>

                <!-- COUNTRY -->
                <mat-form-field>
                    <mat-select placeholder="Country" formControlName="addrCountry">
                        <mat-option *ngFor='let country of countries' [value]="country.code">
                            {{ country.label }}</mat-option>
                    </mat-select>
                    <mat-error>Required</mat-error>
                </mat-form-field>

            </div>

        </form>

    </div>

    <!-- ACTIONS -->
    <pv-form-actions mat-dialog-actions
                     [form]="siteForm"
                     (cancel)="cancel()"
                     [status]="state.status">

    </pv-form-actions>

</ng-container>
