import { Calculation } from './_types';

export const LIB_CALCULATIONS_NUT_HARVEST: Calculation[] = [

    //Calculated values
    {
        id: "nut_totalHuskToNISRatio_calc",
        unit: "%",
        label: "Ratio of Husk to Nut in Shell Weight",
        tag: "HTNISR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalHuskToNISRatio_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalHuskToNIHRatio_calc",
        unit: "%",
        label: "Ratio of Husk to Nut in Husk Weight",
        tag: "HTNIHR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalHuskToNIHRatio_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalTKR_calc",
        unit: "%",
        label: "Total Kernel Recovery (TKR)",
        tag: "TTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalTKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalUSRK_calc",
        unit: "%",
        label: "Total Unsound Kernel Recovery (USKR)",
        tag: "TUSRK",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalUSRK_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalUSRK2_calc",
        unit: "%",
        label: "Total Unsound Kernel Recovery 2 (USKR2)",
        tag: "TUSRK2",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalUSRK2_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalUWKR_calc",
        unit: "%",
        label: "Whole Kernel Recovery",
        tag: "TUWKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalUWKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalSKR_calc",
        unit: "%",
        label: "Sound Kernel Recovery",
        tag: "TSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalSKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_totalSKR2_calc",
        unit: "%",
        label: "Sound Kernel Recovery 2",
        tag: "TSKR2",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_totalSKR2_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_mould_weight_TKR_calc",
        unit: "%",
        label: "Mould (TKR)",
        tag: "MTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_mould_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_mould_weight_USKR_calc",
        unit: "%",
        label: "Mould (USKR)",
        tag: "MUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_mould_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_791_weight_TKR_calc",
        unit: "%",
        label: "791 Spot (TKR)",
        tag: "7TKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_791_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_791_weight_USKR_calc",
        unit: "%",
        label: "791 Spot (USKR)",
        tag: "7USKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_791_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_pregerm_weight_TKR_calc",
        unit: "%",
        label: "Pre - Germination (TKR)",
        tag: "PTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_pregerm_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_pregerm_weight_USKR_calc",
        unit: "%",
        label: "Pre - Germination (USKR)",
        tag: "PUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_pregerm_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_immat_weight_TKR_calc",
        unit: "%",
        label: "Immaturity / Shrivelling (TKR)",
        tag: "ISTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_immat_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_immat_weight_USKR_calc",
        unit: "%",
        label: "Immaturity / Shrivelling (USKR)",
        tag: "ISUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_immat_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_misshap_weight_TKR_calc",
        unit: "%",
        label: "Misshapenness (TKR)",
        tag: "MTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_misshap_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_misshap_weight_USKR_calc",
        unit: "%",
        label: "Misshapenness (USKR)",
        tag: "MUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_misshap_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_sponginess_weight_TKR_calc",
        unit: "%",
        label: "Sponginess (TKR)",
        tag: "STKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_misshap_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_sponginess_weight_USKR_calc",
        unit: "%",
        label: "Sponginess (USKR)",
        tag: "SUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_sponginess_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_hollow_weight_TKR_calc",
        unit: "%",
        label: "Hollow Centre (TKR)",
        tag: "HTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_hollow_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_hollow_weight_USKR_calc",
        unit: "%",
        label: "Hollow Centre (USKR)",
        tag: "HUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_hollow_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_decom_weight_TKR_calc",
        unit: "%",
        label: "Decomposition (TKR)",
        tag: "DTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_decom_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_decom_weight_USKR_calc",
        unit: "%",
        label: "Decomposition (USKR)",
        tag: "DUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_decom_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_adhered_weight_TKR_calc",
        unit: "%",
        label: "Adhered Skin (TKR)",
        tag: "ATKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_adhered_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_adhered_weight_USKR_calc",
        unit: "%",
        label: "Adhered Skin (USKR)",
        tag: "AUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_adhered_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_insect_weight_TKR_calc",
        unit: "%",
        label: "Insect Damage (TKR)",
        tag: "ITKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_insect_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_insect_weight_USKR_calc",
        unit: "%",
        label: "Insect Damage (USKR)",
        tag: "IUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_insect_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_discolor_weight_TKR_calc",
        unit: "%",
        label: "General Discoloration (TKR)",
        tag: "GTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_discolor_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_discolor_weight_USKR_calc",
        unit: "%",
        label: "General Discoloration (USKR)",
        tag: "TUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_discolor_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_dark_weight_TKR_calc",
        unit: "%",
        label: "Dark Kernel (TKR)",
        tag: "DTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_dark_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_dark_weight_USKR_calc",
        unit: "%",
        label: "Dark Kernel (USKR)",
        tag: "DUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_dark_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_internal_discolor_weight_TKR_calc",
        unit: "%",
        label: "Internal Kernel Discoloration (TKR)",
        tag: "IKTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_internal_discolor_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_internal_discolor_weight_USKR_calc",
        unit: "%",
        label: "Internal Kernel Discoloration (USKR)",
        tag: "IKUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_internal_discolor_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_basal_weight_TKR_calc",
        unit: "%",
        label: "Basal Discoloration (TKR)",
        tag: "BDTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_basal_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_basal_weight_USKR_calc",
        unit: "%",
        label: "Basal Discoloration (USKR)",
        tag: "BDUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_basal_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_distal_weight_TKR_calc",
        unit: "%",
        label: "Distal end Browning (TKR)",
        tag: "DETKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_distal_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_distal_weight_USKR_calc",
        unit: "%",
        label: "Distal end Browning (USKR)",
        tag: "DEUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_distal_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_physio_weight_TKR_calc",
        unit: "%",
        label: "Physiological Browning (TKR)",
        tag: "PBTKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_physio_weight_TKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
    {
        id: "nut_kernel_physio_weight_USKR_calc",
        unit: "%",
        label: "Physiological Browning (USKR)",
        tag: "PBUSKR",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_physio_weight_USKR_calculated",
        format:  "####%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "nut_calculated",
    },
];