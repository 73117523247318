import { Injectable } from '@angular/core';
import { OrganizationInvite, OrganizationInviteRequest, OrganizationService, Status, translateCommonErrorStatus } from '@core/data';
import { Action, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface OrganizationInvitesStateModel {
    orgKey: string;
    status: Status;
    data: OrganizationInvite[];
    withExpired: boolean;
}

export class InitOrganizationInvites {
    static readonly type = "[OrganizationInvites] Init";
    constructor(public orgKey: string, public withExpired = false) { }
}

export class LoadOrganizationInvites {
    static readonly type = "[OrganizationInvites] Load";
    constructor() { }
}

export class CreateOrganizationInvite {
    static readonly type = "[OrganizationInvites] Create";
    constructor(public data: OrganizationInviteRequest) { }
}

export class ExpireOrganizationInvite {
    static readonly type = "[OrganizationInvites] Expire";
    constructor(public inviteId: number) { }
}

@State<OrganizationInvitesStateModel>({
    name: "organization_invites",
    defaults: {
        orgKey: null,
        status: Status.UNINITIALIZED,
        data: [],
        withExpired: false
    }
})
@Injectable()
export class OrganizationInvitesState {

    constructor(private _orgService: OrganizationService) { }

    /**
     *
     * @param ctx State Context
     * @param action orgKey: organization key, withExpired: whether or not to include expired invites (boolean)
     */
    @Action(InitOrganizationInvites)
    initOrganizationInvites(ctx: StateContext<OrganizationInvitesStateModel>, action: InitOrganizationInvites) {
        ctx.setState({
            orgKey: action.orgKey,
            status: Status.LOADING,
            data: [],
            withExpired: action.withExpired
        });

        return ctx.dispatch(new LoadOrganizationInvites);
    }

    /**
     *
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LoadOrganizationInvites, { cancelUncompleted: true })
    loadOrganizationInvites(ctx: StateContext<OrganizationInvitesStateModel>, action: LoadOrganizationInvites) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
            data: []
        });

        return this._orgService.listInvites(state.orgKey, state.withExpired)
            .pipe(
                tap(
                    result => {

                        let status = Status.OK;

                        if (result.length < 1) status = Status.EMPTY;

                        ctx.patchState({
                            status: status,
                            data: result,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: [],
                        });
                    }
                ),
                catchError(err => of(null))
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action data: invite form data
     */
    @Action(CreateOrganizationInvite)
    createOrganizationInvite(ctx: StateContext<OrganizationInvitesStateModel>, action: CreateOrganizationInvite) {

        const state = ctx.getState();

        return this._orgService.createInvite(state.orgKey, action.data)
            .pipe(
                tap(result => {

                    ctx.patchState({
                        status: Status.OK,
                    });

                    return ctx.dispatch(new LoadOrganizationInvites);
                })
            );
    }

    /**
     *
     * @param ctx State Context
     * @param action inviteId: id of the invite that you want to expire
     */
    @Action(ExpireOrganizationInvite)
    expireOrganizationInvite(ctx: StateContext<OrganizationInvitesStateModel>, action: ExpireOrganizationInvite) {

        const state = ctx.getState();

        return this._orgService.expireInvite(state.orgKey, action.inviteId)
            .pipe(
                tap(
                    result => {
                        return ctx.dispatch(new LoadOrganizationInvites);
                    },
                    error => {
                        ctx.patchState({
                            status: Status.OK,
                        });
                    },
                ),
            );
    }
}