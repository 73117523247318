import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { Dialog, Snackbar } from "@core/material";
import { ClearTastingCustomTagsIndexFilter, DeleteTastingCustomTag, InitTastingCustomTagsIndex, LoadTastingCustomTagsIndex, PageTastingCustomTagsIndex, QueryTastingCustomTagsIndex, SetTastingCustomTagsIndexSelected, SortTastingCustomTagsIndex } from "./tasting-custom-tags-index.state-actions";
import { AuthState } from "@app/auth";
import { FilterSort, Organization, Tag } from "@core/data";
import { TastingCustomTagsIndexState } from "./tasting-custom-tags-index.state";
import { TastingCustomTagsIndexStateModel, TastingCustomTagsQuery } from "./tasting-custom-tags-index.state-model";
import { FormControl, FormGroup } from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { debounceTime, takeUntil } from "rxjs/operators";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { TastingCustomTagsFormDialog, TastingCustomTagsFormDialogData } from "../tasting-custom-tags-form/tasting-custom-tags-form.dialog";

@Component({
    selector: 'pv-tasting-custom-tags-index-view',
    templateUrl: 'tasting-custom-tags-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tasting-custom-tags-index-view',
    }
})
export class TastingCustomTagsIndexView implements OnInit, OnDestroy {

    @Select(AuthState.selectedOrg)
    selectedOrg$: Observable<Organization>;

    @Select(TastingCustomTagsIndexState)
    state$: Observable<TastingCustomTagsIndexStateModel>;

    @Select(TastingCustomTagsIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(TastingCustomTagsIndexState.query)
    query$: Observable<TastingCustomTagsQuery>;

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl
    });

    searchControl = new FormControl();
    weightedCharKeysControl = new FormControl([]);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        weightedCharKeys: this.weightedCharKeysControl
    });

    selection = new SelectionModel<string>(true, []);
    selectedTabIndex = 0;
    private _destroy$ = new Subject();

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: Dialog
    ) {}

    ngOnInit(): void {
        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(new InitTastingCustomTagsIndex(params.get('orgKey')));
            });

        //QUERY LOGIC
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => {
                this.queryFormGroup.patchValue(query, {emitEvent: false})
            });

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new QueryTastingCustomTagsIndex(val));
            });
        //QUERY LOGIC END

        //SORT LOGIC
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingCustomTagsIndex(val.column, val.order));
            });
        //SORT LOGIC END

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetTastingCustomTagsIndexSelected(this.selection.selected));
            });
    }

    reload() {
        this._store.dispatch(new LoadTastingCustomTagsIndex);
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingCustomTagsIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingCustomTagsIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingCustomTagsIndex('updatedAt', 'desc'));
        }
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingCustomTagsIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
     isAllSelected(data: Tag[]) {
        return data.every((tag) => {
            return this.selection.isSelected(tag.key);
        });
    }

    isSelected(tag: Tag) {
        return this.selection.isSelected(tag.key)
    }

    select(tag: Tag) {
        if (this.selection.isSelected(tag.key)) this.selection.deselect(tag.key);
        else this.selection.select(tag.key);
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: Tag[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    add() {
        const state: TastingCustomTagsIndexStateModel = this._store.selectSnapshot(TastingCustomTagsIndexState);

        const data: TastingCustomTagsFormDialogData = {
            defaults: {
                ownerOrgKey: state.orgKey
            }
        }

        this._dialog.open(TastingCustomTagsFormDialog, {data});
    }

    edit(tag: Tag) {
        const data: TastingCustomTagsFormDialogData = { key:  tag.key};
        this._dialog.open(TastingCustomTagsFormDialog, {data});
    }

    delete(tag: Tag) {
        if (tag.weighted_chars.length > 0) {
            this._snackbar.error('Remove Weighted Characteristics connections or refresh data before Tag can be deleted.');
            return;
        }

        this._dialog.confirm(
            `Delete Custom Tag ${tag.label}`,
            'Are you sure you want to delete this tag and all its associated data?'
        )
            .afterClosed()
            .subscribe(result => {
                if (result) this._store.dispatch(new DeleteTastingCustomTag(tag.key));
            });
    }

    deleteSelected(tags: Tag[]) {
        if (!this.canDelete(tags)) {
            this._snackbar.error('Remove Weighted Characteristics connections or refresh data before Tag can be deleted.');
            return;
        }

        var deleteList: DeleteTastingCustomTag[] = [];
        tags.forEach(tag => deleteList.push(new DeleteTastingCustomTag(tag.key)));

        this._dialog.confirm(
            `Delete ${tags.length} selected Custom Tags`,
            `Are you sure you want to delete these tags and all their associated data?`
        ).afterClosed()
        .subscribe(result => {
            if (result) {
                this._store.dispatch(deleteList).subscribe(complete => {
                    this.selection.clear();
                });
            }
        });
    }

    navigateToSupport() {
        window.open('https://culteva.hipporello.net/desk', '_blank');
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private canDelete(tags: Tag[]): boolean {
        let hasConnections = false;

        tags.forEach(tag => {
            if (tag.weighted_chars.length > 0) hasConnections = true;
        });

        return !hasConnections;
    }
}