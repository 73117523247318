import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject, SystemJsNgModuleLoader } from "@angular/core";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { ReportService, Status, ReportTemplate, Report, translateCommonErrorStatus, ReportCreateRequest } from '@core/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Dialog, Snackbar } from '@core/material';
import { REPORT_OPTIONS_DEFAULT } from './report-defaults';


export interface ReportSetupFormDialogData {
    ownerOrgKey: string;
    sampleKeys?: string[];
}


@Component({
    selector: 'pv-report-setup-form-dialog',
    templateUrl: 'report-setup-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    styleUrls: ['./report-setup-form.dialog.scss']
})
export class ReportSetupFormDialog implements OnInit, OnDestroy {

    titleControl = new FormControl(null, [Validators.required, Validators.maxLength(100)]);
    descriptionControl = new FormControl(null, [Validators.maxLength(255)]);
    templateControl = new FormControl(null, [Validators.required]);

    formGroup = new FormGroup({
        title: this.titleControl,
        description: this.descriptionControl,
        _template: this.templateControl,
    });

    orgKey: string;
    sampleKeys: string[];
    status: Status;
    templateOptions: ReportTemplate[];

    blankTemplateOption: Partial<ReportTemplate> = {
        name: "Blank Report (default)",
        reportOptions: REPORT_OPTIONS_DEFAULT,
        reportRemarks: []
    };

    public templateFilterCtrl: FormControl = new FormControl('');
    public filteredTemplates: ReplaySubject<ReportTemplate[]> = new ReplaySubject<ReportTemplate[]>(1);

    private _destroy$ = new Subject();

    constructor(
        private _reportService: ReportService,
        @Inject(MAT_DIALOG_DATA) private _data: ReportSetupFormDialogData,
        private _dialog: MatDialogRef<ReportSetupFormDialog>,
        private _dialogs: Dialog,
        private _snackbar: Snackbar
    ) {}

    ngOnInit() {

        this._dialog.updateSize();

        this.orgKey = this._data.ownerOrgKey;
        this.sampleKeys = this._data.sampleKeys;

        this.reset();

        this.templateFilterCtrl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(value => {
                this.filterTemplateOptions()
            });
    }

    reset() {

        this.status = Status.LOADING;
        this.formGroup.disable();

        this._reportService.listTemplates(this.orgKey)
            .pipe(
                takeUntil(this._destroy$)
            )
            .subscribe((templates) => {
                    this.status = Status.OK;
                    this.templateOptions = templates;
                    this.formGroup.enable();
                    this.formGroup.reset({
                        title: null,
                        description: null,
                        _template: this.blankTemplateOption
                    });

                    // set initial selection
                    this.templateControl.setValue(this.blankTemplateOption);

                    // load the initial bank list
                    const defaultOption: ReportTemplate = {
                        id: 0,
                        name: "Blank Report (default)",
                        ownerOrgKey: this.orgKey,
                        reportOptions: REPORT_OPTIONS_DEFAULT,
                        reportRemarks: []
                    }

                    this.templateOptions.unshift(defaultOption)
                    this.filteredTemplates.next(this.templateOptions.slice());
                },
                error => {
                    this._dialog.close();
                    this._dialogs.error(error);
                }
            );

    }

    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.disabled) return;

        if(this.formGroup.valid){

            this.formGroup.disable();
            this.status = Status.LOADING;

            const form = this.formGroup.value;

            const data: ReportCreateRequest = {
                title: form.title,
                description: form.description,
                ownerOrgKey: this.orgKey,
                sampleKeys: this.sampleKeys || null
            };

            if(form._template){

                if(form._template.reportOptions)
                    data.options = form._template.reportOptions;

                if(Array.isArray(form._template.reportRemarks))
                    data.remarks = form._template.reportRemarks;
            }

            let dataCopy = data;
            delete dataCopy.options.showStorageRegime;

            this._reportService.create(dataCopy)
                .pipe(takeUntil(this._destroy$))
                .subscribe(
                    report => {
                        this._snackbar.info(`Created report '${report.title}'`);
                        this._dialog.close(report);
                    },
                    error => {
                        this.status = translateCommonErrorStatus(error);
                        this.formGroup.enable();
                    }
                );

        }else{
            this._snackbar.formInvalid();
        }

    }

    protected filterTemplateOptions() {
        if (!this.templateOptions) {
          return;
        }

        // get the search keyword
        let search = this.templateFilterCtrl.value;
        if (!search) {
          this.filteredTemplates.next(this.templateOptions.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
        // filter the templates
        this.filteredTemplates.next(
          this.templateOptions.filter(option => option.name.toLowerCase().indexOf(search) > -1)
        );
      }

    cancel(){
        this._dialog.close();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

}