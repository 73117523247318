import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {

    constructor(){}

    transform(birthdate: string): any {

        let m = moment(birthdate);
        if(!m.isValid()) return null;

        let d = moment.duration(moment().diff(m));

        return `${d.humanize()}`;
    }
}


