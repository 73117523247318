import { Protocol } from './_types';

export const LIB_PROTOCOL_CHERRY_HARVEST_SCREEN_EVAL: Protocol = {
    id: "cherry_harvest_screen_eval",
    title: "Advanced Cherry Harvest Evaluation",
    description: "Harvest evaluation is performed to characterise new cultivars and selections on the base of their fruit traits. Visual and sensory fruit evaluation are applied to describe fruit quality and keeping ability after storage over a specified storage period. Characteristics such as colour and pattern, shapes and symmetry, defects and disorders and eating quality are evaluated in detail.",
    sampleType: "harvest",
    crop: "cherry",

    chars: [
        "frut_ripe_stage",
        "frut_ripe_uniformity",
        "frut_firmness",
        "frut_firmness_homog",

        "frut_over_color",
        "frut_ground_color_stone",
        "frut_flesh_color",
        "frut_over_pattern_cherry",
        "frut_stem_color",
        "frut_stem_fresh",

        "frut_shape_type_cherry",
        "frut_shape_symmetry",
        "frut_shape_consistent",
        "frut_stem_length",
        "frut_stem_thick",

        "frut_abrasion",
        "frut_blemish",
        "frut_wounds",

        "frut_taste_sugar",
        "frut_taste_acid",
        "frut_taste_balance",
        "frut_taste_aroma",
        "frut_taste_juice",
        "frut_skin_thick",
        "frut_text_firm",
        "frut_text_crunch",

        "frut_disease_stone",
        "frut_rot_symptom",
        "frut_extdisorder_stone",
        "frut_crack_sens",
        "frut_bruise_symptom",
        "frut_shrivel_symptom",
        "frut_intdisorder_stone",

        "frut_overall_keepqual",
        "frut_overall_appear",
        "frut_overall_color",
        "frut_overall_texture",
        "frut_overall_taste",
        "frut_overall_eatqual",
        "frut_overall_interest"
    ],

    measures: [
        "frut_length",
        "frut_weight",
        "frut_diameter",
        "frut_firmness",
        "frut_tss",
        "frut_acid",
        "frut_ctifl",
        "frut_stem_length",
        "frut_stem_colour",
        "frut_color_int",
    ],

    indexes: [
        "indx_frut_eating_quality",
        "indx_frut_overall"
    ],

}