import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';
import { Snackbar } from '@core/material';
import { InitImagesForm, ImagesFormState, ImagesFormStateModel, UploadImage, PendingUploadModel, SubmitImagesForm, InitTastingEventImagesForm, UploadTastingsEventImage, SubmitTastingEventImagesForm, InitTastingSampleImagesForm, SubmitTastingSampleImagesForm, UploadTastingSampleImage } from './images-form.state';
import { Evaluation, Status, SampleImage, ImagesUpdateRequest, TastingsEventImagesUpdateRequest, TastingSampleImagesUpdateRequest } from '@core/data';
import { takeUntil, debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { coerceMomentProperty, fileExtension, coerceTimestampProperty } from '@core/utils';
import { TastingEvent, TastingsEventImage } from "@core/data/types/tastings-event";
import { TastingSample, TastingSampleImage } from "@core/data/types/tasting-sample";


export interface ImagesFormDialogData {
    key: string;
    type?: string;
}

export enum ImageUploadType {
    TASTINGS_EVENT = 'tastingsEvent',
    TASTINGS_SAMPLE = 'tastingsSample',
    SAMPLE_EVALUATION = 'sampleEvaluation',
}

@Component({
    selector: 'pv-images-form-dialog',
    templateUrl: 'images-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-images-form-dialog pv-fullscreen-dialog'
    }
})
export class ImagesFormDialog implements OnInit, OnDestroy{

    @Select(ImagesFormState)
    state$: Observable<ImagesFormStateModel>;

    @Select(ImagesFormState.data)
    data$: Observable<Evaluation>;

    @Select(ImagesFormState.uploads)
    uploads$: Observable<PendingUploadModel[]>;

    formArray = new FormArray([]);
    formGroup = new FormGroup({
        // noop control to prevent formgroup being
        // disabled if all image controls is disabled
        _noop: new FormControl(true),
        images: this.formArray,
    });

    isDragging = false;
    maxSize = 40000000; // 40MB
    allowedExts = ['jpg', 'jpeg', 'png'];
    validationErrors: string[] = [];
    canUpload = true;

    imageUploadTypes = {
        sampleEvaluation: ImageUploadType.SAMPLE_EVALUATION,
        tastingsEvent: ImageUploadType.TASTINGS_EVENT,
        tastingsSample: ImageUploadType.TASTINGS_SAMPLE,
    }

    private pushedUploadIds: number[] = [];
    private _destroy$ = new Subject();

    constructor(
        private _dialogRef: MatDialogRef<ImagesFormDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: ImagesFormDialogData
    ){}


    ngOnInit(){
        //Set default type
        if(this.data.type === undefined) this.data.type = 'sampleEvaluation';
        //Handle type of upload
        if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) this.handleSampleEvaluationImage()
        if (this.data.type === ImageUploadType.TASTINGS_EVENT) this.handleTastingEventImage()
        if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) this.handleTastingSampleImage()
    }

    private handleSampleEvaluationImage() {
        this._store.dispatch(new InitImagesForm(this.data.key));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Images updated");
                    this.cancel();
                }else if(state.status !== Status.LOADING){
                    this.formGroup.enable({onlySelf: true});
                }else{
                    this.formGroup.disable({onlySelf: true});
                }

            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.buildForm(data);
            });


        // check for completed uploads to add to the form
        this.uploads$.pipe(
                takeUntil(this._destroy$),
                debounceTime(500)
            )
            .subscribe(uploads => {

                if(Array.isArray(uploads) && uploads.length < 1) return;

                uploads.forEach(upload => {
                    if(
                        upload.status === Status.COMPLETE
                        && upload.data
                        && !this.pushedUploadIds.includes(upload.id)
                        ){

                        this.pushedUploadIds.push(upload.id);
                        this.pushUploadedImage(upload.data as SampleImage);

                    }
                });

            });
    }

    private handleTastingEventImage() {
        this._store.dispatch(new InitTastingEventImagesForm(this.data.key));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Images updated");
                    this.cancel();
                }else if(state.status !== Status.LOADING){
                    this.formGroup.enable({onlySelf: true});
                }else{
                    this.formGroup.disable({onlySelf: true});
                }

                if (state.data) this.canUpload = (state.data as TastingEvent).tastingsEventImage ? false : true;
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.buildForm(data);
            });

        // check for completed uploads to add to the form
        this.uploads$.pipe(
            takeUntil(this._destroy$),
            debounceTime(500)
        )
        .subscribe(uploads => {

            if(Array.isArray(uploads) && uploads.length < 1) return;
            if(Array.isArray(uploads) && uploads.length == 1 && this.data.type == ImageUploadType.TASTINGS_EVENT) this.canUpload = false;

            uploads.forEach(upload => {
                if(
                    upload.status === Status.COMPLETE
                    && upload.data
                    && !this.pushedUploadIds.includes(upload.id)
                    ){

                    this.pushedUploadIds.push(upload.id);
                    this.pushUploadedImage(upload.data as TastingsEventImage);

                }
            });

        });
    }

    private handleTastingSampleImage() {
        this._store.dispatch(new InitTastingSampleImagesForm(this.data.key));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Images updated");
                    this.cancel();
                }else if(state.status !== Status.LOADING){
                    this.formGroup.enable({onlySelf: true});
                }else{
                    this.formGroup.disable({onlySelf: true});
                }

                if (state.data) this.canUpload = (state.data as TastingSample).tastingsSampleImage ? false : true;
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.buildForm(data);
            });

        // check for completed uploads to add to the form
        this.uploads$.pipe(
            takeUntil(this._destroy$),
            debounceTime(500)
        )
        .subscribe(uploads => {

            if(Array.isArray(uploads) && uploads.length < 1) return;
            if(Array.isArray(uploads) && uploads.length == 1 && this.data.type == ImageUploadType.TASTINGS_SAMPLE) this.canUpload = false;

            uploads.forEach(upload => {
                if(
                    upload.status === Status.COMPLETE
                    && upload.data
                    && !this.pushedUploadIds.includes(upload.id)
                    ){

                    this.pushedUploadIds.push(upload.id);
                    this.pushUploadedImage(upload.data as TastingSampleImage);

                }
            });

        });
    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt(){

        if(this.formGroup.valid){

            let request = null;
            if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) request = this.getUpdateRequest();
            if (this.data.type === ImageUploadType.TASTINGS_EVENT) request = this.getTastingsEventUpdateRequest();
            if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) request = this.getTastingSampleUpdateRequest();

            if(request.images.length){

                if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) this._store.dispatch(new SubmitImagesForm(request as ImagesUpdateRequest));
                if (this.data.type === ImageUploadType.TASTINGS_EVENT) this._store.dispatch(new SubmitTastingEventImagesForm(request as TastingsEventImagesUpdateRequest));
                if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) this._store.dispatch(new SubmitTastingSampleImagesForm(request as TastingSampleImagesUpdateRequest));

            }else{
                this._snackbar.info("No changes to images.");
                this.cancel();
            }


        }else{
            this._snackbar.error("One or more notes has an invalid date/observation. Check your input and try again.");
        }

    }

    cancel() {
        this._dialogRef.close();
    }

    asEval(obj) : Evaluation {return obj};
    asEvent(obj) : TastingEvent {return obj};
    asTasteSample(obj) : TastingSample {return obj};

    toggleDelete(index: number) {
        let group = <FormGroup>this.formArray.at(index);

        if (!group) return;

        if (group.disabled) {
            group.enable();
        }else{
            group.disable();
        }

    }

    fileInputChanged(event: Event){

        let input = <HTMLInputElement> event.target;

        if(input.files && input.files.length > 0){
            this.uploadFiles(input.files);
        }

    }

    onDragOver(event: DragEvent){

        if(event.dataTransfer){
            event.dataTransfer.dropEffect = "copy";
        }

        if(!this.isDragging){
            this.isDragging = true;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    onDragLeave(event: DragEvent){

        if(this.isDragging){
            this.isDragging = false;
        }

        event.preventDefault();
    }

    onDrop(event: DragEvent){

        if(event.dataTransfer){
            event.dataTransfer.dropEffect = 'copy';

            this.uploadFiles(event.dataTransfer.files);
        }

        this.isDragging = false;

        event.preventDefault();
        event.stopPropagation();
    }

    trackUpload(index, item: PendingUploadModel){
        return item.id;
    }

    private pushUploadedImage(image: SampleImage | TastingsEventImage | TastingSampleImage) {
        if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) this.formArray.push(this.makeImageGroup(image as SampleImage));
        else if (this.data.type === ImageUploadType.TASTINGS_EVENT) this.formArray.push(this.makeImageGroup(image as TastingsEventImage));
        else if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) this.formArray.push(this.makeImageGroup(image as TastingSampleImage));
    }

    private uploadFiles(list: FileList){
        if(this.validateFiles(list)){
            for(let i = 0; i < list.length; i++){
                if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) this._store.dispatch(new UploadImage(list.item(i)));
                if (this.data.type === ImageUploadType.TASTINGS_EVENT) this._store.dispatch(new UploadTastingsEventImage(list.item(i)));
                if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) this._store.dispatch(new UploadTastingSampleImage(list.item(i)));
            }
        }

    }

    private buildForm(model: Evaluation | TastingEvent | TastingSample) {

        this.formArray.clear();
        this.formGroup.reset({ images: [] });

        if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) {
            this.asEval(model).images.forEach(note => {
                let control = this.makeImageGroup(note);
                this.formArray.push(control);
            });
        }

        if (this.data.type === ImageUploadType.TASTINGS_EVENT) {
            if (this.asEvent(model).tastingsEventImage) {
                let control = this.makeImageGroup(this.asEvent(model).tastingsEventImage);
                this.formArray.push(control);
            }
        }

        if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) {
            if (this.asTasteSample(model).tastingsSampleImage) {
                let control = this.makeImageGroup(this.asTasteSample(model).tastingsSampleImage);
                this.formArray.push(control);
            }
        }
    }

    private makeImageGroup(image: Partial<SampleImage | TastingsEventImage | TastingSampleImage>) {
        if (this.data.type === ImageUploadType.SAMPLE_EVALUATION) {
            return new FormGroup({
                key: new FormControl((image as SampleImage).key || null),
                fileRef: new FormControl((image as SampleImage).fileRef || null),
                note: new FormControl((image as SampleImage).note || null, [Validators.maxLength(250)]),
                takenAt: new FormControl(coerceMomentProperty((image as SampleImage).takenAt), []),
            });
        }

        if (this.data.type === ImageUploadType.TASTINGS_EVENT) {
            return new FormGroup({
                key: new FormControl((image as TastingsEventImage).key || null),
                fileRef: new FormControl((image as TastingsEventImage).fileRef || null),
            });
        }

        if (this.data.type === ImageUploadType.TASTINGS_SAMPLE) {
            return new FormGroup({
                key: new FormControl((image as TastingSampleImage).key || null),
                fileRef: new FormControl((image as TastingSampleImage).fileRef || null),
            });
        }
    }

    private validateFiles(files: FileList){

        let valid = true;
        this.validationErrors = [];

        for(let i = 0; i < files.length; i++){
            let file = files[i];
            let fileExt = fileExtension(file.name).toLowerCase();

            if(!this.allowedExts.includes(fileExt)){
                this.validationErrors.push(`${file.name} is not a valid file type.`);
            }

            if(file.size > this.maxSize){
                this.validationErrors.push(`${file.name} exceeds the maximum file size.`);
            }

        }

        if(this.validationErrors.length > 0){
            valid = false;
        }

        return valid;
    }

    private getUpdateRequest(): ImagesUpdateRequest {

        let imageUpdates: Array<Partial<SampleImage>> = [];

        let value: Partial<SampleImage>;

        this.formArray.controls.forEach(control => {

            value = control.value;

            if(control.disabled){
                // disabled indicates delete

                imageUpdates.push({
                    key: value.key,
                    deletedAt: coerceTimestampProperty(moment())
                });

            }else if(control.dirty){

                imageUpdates.push({
                    key: value.key,
                    note: value.note,
                    takenAt: coerceTimestampProperty(value.takenAt)
                });

            }

        });


        return {
            images: imageUpdates
        };
    }

    private getTastingsEventUpdateRequest(): TastingsEventImagesUpdateRequest {
        const state: ImagesFormStateModel = this._store.selectSnapshot(ImagesFormState);

        var imageUpdates: Array<Partial<TastingsEventImage>> = [];

        var value: Partial<TastingsEventImage>;

        this.formArray.controls.forEach(control => {

            value = control.value;

            if (control.disabled) {
                // disabled indicates delete
                imageUpdates.push({
                    key: value.key,
                    deletedAt: coerceTimestampProperty(moment()),
                });

            } else {
                imageUpdates.push({
                    key: value.key,
                });
            }

        });

        return {
            images: imageUpdates,
            ownerOrgKey: (state.data as TastingEvent).ownerOrgKey,
        };
    }

    private getTastingSampleUpdateRequest(): TastingSampleImagesUpdateRequest {
        const state: ImagesFormStateModel = this._store.selectSnapshot(ImagesFormState);

        var imageUpdates: Array<Partial<TastingSampleImage>> = [];

        var value: Partial<TastingSampleImage>;

        this.formArray.controls.forEach(control => {

            value = control.value;

            if (control.disabled) {
                // disabled indicates delete
                imageUpdates.push({
                    key: value.key,
                    deletedAt: coerceTimestampProperty(moment()),
                });

            } else {
                imageUpdates.push({
                    key: value.key,
                });
            }

        });

        return {
            images: imageUpdates,
            ownerOrgKey: (state.data as TastingSample).ownerOrgKey,
        };
    }
}