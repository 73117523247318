import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { API_BASE_URI } from '../http/api';
import { Collection } from '../http/collection';
import { DetailRequest, DetailRequestBuilder } from '../http/detail';
import { Filter, FilterBuilder } from '../http/filter';
import { OrganizationGroup } from '../types/organization';
import { Observable } from 'rxjs';

@Injectable()
export class GroupService {

    constructor(
        private _http: HttpClient,
        @Inject(API_BASE_URI) private _baseUri: string,
    ) { }

    query(filter: Filter, detail?: DetailRequest) {
        let params = {
            ...DetailRequestBuilder.queryParams(detail),
            ...FilterBuilder.queryParams(filter),
        };
        return this._http.get<Collection<OrganizationGroup>>(`${this._baseUri}/groups`, { params });
    }

    create(model: Partial<OrganizationGroup>) {
        return this._http.post<OrganizationGroup>(`${this._baseUri}/groups`, model);
    }

    read(sampleKey: string, detail?: DetailRequest): Observable<OrganizationGroup> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<OrganizationGroup>(`${this._baseUri}/groups/${sampleKey}`, { params });
    }

    update(key: string, model: Partial<OrganizationGroup>) {
        return this._http.put<OrganizationGroup>(`${this._baseUri}/groups/${key}`, model);
    }

    delete(key: string) {
        return this._http.delete<OrganizationGroup>(`${this._baseUri}/groups/${key}`);
    }

    addGroupUser(groupKey: string, userKey: string) {
        return this._http.post<{ key: string }>(`${this._baseUri}/groups/${groupKey}/users/${userKey}`, {});
    }

    removeGroupUser(groupKey: string, userKey: string) {
        return this._http.delete<{ key: string }>(`${this._baseUri}/groups/${groupKey}/users/${userKey}`);
    }

    addGroupSample(groupKey: string, sampleKey: string) {
        return this._http.post<{ key: string }>(`${this._baseUri}/groups/${groupKey}/samples/${sampleKey}`, {});
    }

    removeGroupSample(groupKey: string, sampleKey: string) {
        return this._http.delete<{ key: string }>(`${this._baseUri}/groups/${groupKey}/samples/${sampleKey}`);
    }

    addGroupProtocolFilter(groupKey: string, protocolKey: string) {
        return this._http.post<{ key: string }>(`${this._baseUri}/groups/${groupKey}/protocolFilter/${protocolKey}`, {});
    }

    removeGroupProtocolFilter(groupKey: string, protocolKey: string) {
        return this._http.delete<{ key: string }>(`${this._baseUri}/groups/${groupKey}/protocolFilter/${protocolKey}`);
    }
}
