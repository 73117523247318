export function mapNumber(number: number, in_min: number, in_max: number, out_min: number, out_max: number) {
    return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

// export function mapInteger(number: number, in_min: number, in_max: number, out_min: number, out_max: number){
//   return  Math.round(mapNumber(number, in_min, in_max, out_min, out_max));
// }

export function mapInterval(value: number, labels: string[], min = 0, max = 100) {

    if (value < min || value > max) {
        return 'OoR';
    }

    let relValue = value - min;
    let fullRange = max - min;

    let discIndex = Math.floor((relValue / fullRange) * labels.length);

    if (discIndex === labels.length) discIndex--;

    return labels[discIndex];
}

export function formatBytes(bytes: number, decimals?: number) {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function decimalLength(value: number) {
    if (Math.floor(value) === value) {
        return 0;
    } else {
        return value.toString().split('.')[1].length || 0;
    }
}

// See https://github.com/angular/components/blob/master/src/cdk/coercion/number-property.ts
export function isNumberValue(value: any): boolean {
    // parseFloat(value) handles most of the cases we're interested in (it treats null, empty string,
    // and other non-number values as NaN, where Number just uses 0) but it considers the string
    // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
    return !isNaN(parseFloat(value as any)) && !isNaN(Number(value));
}


// NUMBER SET/RANGE

const NUMBER_RANGE_REGEX = /^([0-9]+)\-([0-9]+)$/;
const NUMBER_SET_REGEX = /^[0-9]+(?:,[0-9]+)*$/;

export function isNumberRange(value: any): boolean {
    return NUMBER_RANGE_REGEX.test(String(value));
}

export function parseNumberRangeValue(value: any): number[] {
    let parts = String(value).match(NUMBER_RANGE_REGEX);

    if(!parts) return null;

    return [Number(parts[1]), Number(parts[2])];
}

export function isNumberSet(value: any): boolean {
    return NUMBER_SET_REGEX.test(String(value));
}

export function parseNumberSet(value: any){
    let parts = String(value).match(NUMBER_SET_REGEX);

    if(!parts) return null;

    return String(parts[0]).split(',').map(val => Number(val));
}
