
<mat-icon svgIcon="culteva:icon_light" class="mat-64 m-24"></mat-icon>


<mat-card *ngIf="status$ | async as status" [ngSwitch]="status">

    <ng-container *ngSwitchCase="404">
        <mat-card-header>
            <mat-icon mat-card-avatar>error</mat-icon>
            <mat-card-title>Not Found</mat-card-title>
            <mat-card-subtitle>The requested resource could not be found.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions align="end">
            <a mat-raised-button href="/" color="primary">Return Home</a>
        </mat-card-actions>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <mat-card-header>
            <mat-icon mat-card-avatar>error</mat-icon>
            <mat-card-title>Oops! Something Went Wrong</mat-card-title>
        </mat-card-header>
        <mat-card-actions align="end">
            <a mat-raised-button href="/" color="primary">Return Home</a>
        </mat-card-actions>
    </ng-container>

</mat-card>


