import { TastingsIndex } from "./_types";

export const LIB_INDEX_TASTINGS_BASE: TastingsIndex = {
    id: "tastings_base_index",
    title: "Consumer Fruit Impression",
    crop: "Apple & Pear",

    weightedChars: [
        {
            weight: 100,
            id: "tastings_appearance",
            label: "Appearance",
            tags: [
                {id: "colour", label: "Colour"},
                {id: "shape", label: "Shape"},
                {id: "size", label: "Size"},
                {id: "skin_finish", label: "Skin Finish"},
            ]
        },
        {
            weight: 100,
            id: "tastings_texture",
            label: "Texture",
            tags: [
                {id: "crispy", label: "Crispy"},
                {id: "juicy", label: "Juicy"},
                {id: "dry", label: "Dry"},
                {id: "firm", label: "Firm"},
                {id: "soft", label: "Soft"},
                {id: "skin_texture", label: "Skin Texture"},
            ]
        },
        {
            weight: 100,
            id: "tastings_taste",
            label: "Taste",
            tags: [
                {id: "acid", label: "Acid"},
                {id: "balanced", label: "Balanced"},
                {id: "sweet", label: "Sweet"},
            ]
        },
        {
            weight: 100,
            id: "tastings_aroma",
            label: "Aroma",
            tags: []
        },
        {
            weight: 100,
            id: "tastings_overall_eating_quality",
            label: "Overall Eating Quality",
            tags: []
        },
        {
            weight: 100,
            id: "tastings_overall_interest",
            label: "Overall Interest",
            tags: [
                {id: "promising", label: "Promising"},
                {id: "limited", label: "Limited"},
                {id: "not_for_us", label: "Not For Us"},
                {id: "interested_in_testing", label: "Interested In Testing"},
            ]
        },
    ],

    range: 10,

    questions: []
}