import { Property } from "./_types";

export const LIB_PROPERTIES: Property[] = [
    {
        id: "prop_location",
        label: "Location",
        field: 'siteLabel',
        width: 100,
    },
    {
        id: "prop_location_alt",
        label: "Location Altitude",
        field: 'siteAlt',
        width: 100,
        unit: 'm',
    },
    {
        id: "prop_sample_description",
        label: "Description",
        field: 'sampleDescription',
        fieldAggr: 'sampleDescription',
        width: 100,
    },
    {
        id: "prop_sample_label",
        label: "Sample Label",
        field: 'sampleLabel',
        fieldAggr: 'sampleLabel',
        width: 100,
    },
    {
        id: "prop_cultivar",
        label: "Scion Cultivar",
        field: "scionCultivarLabel",
        width: 100,
    },
    {
        id: "prop_rootstock",
        label: "Rootstock Cultivar",
        field: "rootstockCultivarLabel",
        width: 100,
    },
    {
        id: "prop_size",
        label: "Sample Size",
        field: 'evalSize',
        fieldAggr: "sampleSize",
        width: 100,
    },
    {
        id: "prop_tree_count",
        label: "Tree Count",
        field: "sampleTreeCount",
        width: 100,
    },
    {
        id: "prop_fruit_count",
        label: "Fruit Count",
        field: "sampleFruitCount",
        width: 100,
    },
    {
        id: "prop_row_pos",
        label: "Row & Position",
        field: "rowPos",
        width: 100,
    },
    {
        id: "prop_eval_label",
        label: "Evaluation Label",
        field: 'evalLabel',
        width: 100,
    },
    {
        id: "prop_eval_date",
        label: "Evaluation Date",
        field: 'startDate',
        width: 100,
    },
    {
        id: "prop_planted_year",
        label: "Planted Year",
        field: "samplePlantedYear",
        width: 100,
    },
    {
        id: "prop_harvest_date",
        label: "Plant/Harvest Date",
        field: 'sampleBirthDate',
        width: 100,
    },
    {
        id: "prop_death_date",
        label: "Grubbed/Destroyed Date",
        field: 'sampleDeathDate',
        width: 100,
    },
    {
        id: "prop_eval_notes",
        label: "Evaluation Notes",
        field: "notes",
        width: 300,
    },
    {
        id: "prop_treatment",
        label: "Treatment Type",
        field: "storageRegime",
        width: 100,
    },

];