import { DetailRequest, Evaluation, EvaluationCreated, EvaluationDeleted, EvaluationImageUploaded, EvaluationService, EvaluationUpdated, Filter, patchArrayItem, removeArrayItem, Sample, SampleService, SampleUpdated, Status, translateCommonErrorStatus } from '@core/data';
import { Action, State, StateContext } from "@ngxs/store";
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadSampleIndex } from '../sample-index/sample-index.state-actions';
import { InitSampleDetail, LoadSampleDetail } from './sample-detail.state-actions';
import { SampleDetailStateModel, SAMPLE_DETAIL_DEFAULTS } from './sample-detail.state-model';
import { Injectable } from '@angular/core';

@State<SampleDetailStateModel>({
    name: 'sample_detail',
    defaults: SAMPLE_DETAIL_DEFAULTS
})
@Injectable()
export class SampleDetailState {

    readonly evalDetailRequest: DetailRequest = {
        related: ['images', 'notes', 'chars', 'measures']
    };

    constructor(
        private _sampleService: SampleService,
        private _evalService: EvaluationService
    ) { }

    @Action(InitSampleDetail)
    initSampleDetail(ctx: StateContext<SampleDetailStateModel>, action: InitSampleDetail) {

        ctx.setState({
            ...SAMPLE_DETAIL_DEFAULTS,
            ...action,
        });


        return ctx.dispatch(new LoadSampleDetail());

    }

    @Action(LoadSampleDetail, { cancelUncompleted: true })
    loadSampleDetail(ctx: StateContext<SampleDetailStateModel>, action: LoadSampleDetail) {

        return forkJoin(
            this.reloadSample(ctx),
            this.reloadEvaluations(ctx)
        );

    }

    @Action(EvaluationCreated)
    handleEvaluationCreated(ctx: StateContext<SampleDetailStateModel>, action: EvaluationCreated) {
        return this.handleEvaluationChanged(ctx, action.evaluation);
    }

    @Action(EvaluationUpdated)
    handleEvaluationUpdated(ctx: StateContext<SampleDetailStateModel>, action: EvaluationUpdated) {
        return this.handleEvaluationChanged(ctx, action.evaluation);
    }

    @Action(EvaluationDeleted)
    handleEvaluationDeleted(ctx: StateContext<SampleDetailStateModel>, action: EvaluationDeleted) {
        return this.removeEvaluation(ctx, action.evaluation.key);
    }


    @Action(EvaluationImageUploaded)
    handleImageUploaded(ctx: StateContext<SampleDetailStateModel>, action: EvaluationImageUploaded) {

        const state = ctx.getState();

        if(state.status !== Status.OK) return;

        let evalu = state.evaluations.find(evalu => evalu.key === action.image.evalKey);

        if(!evalu) return;

        return this.reloadEvaluation(ctx, evalu.key);

    }


    @Action(SampleUpdated)
    handleSampleUpdated(ctx: StateContext<SampleDetailStateModel>, action: SampleUpdated){
        return this.handleSampleChanged(ctx, action.sample);
    }


    private handleSampleChanged(ctx: StateContext<SampleDetailStateModel>, sample: Sample){

        const state = ctx.getState();

        if(state.status !== Status.UNINITIALIZED && state.sampleKey === sample.key){
            return this.reloadSample(ctx);
        }

    }

    private handleEvaluationChanged(ctx: StateContext<SampleDetailStateModel>, evaluation: Evaluation) {

        const state = ctx.getState();

        if (state.status !== Status.OK || evaluation.sampleKey !== state.sampleKey) {
            return;
        }

        if(evaluation.deletedAt){
            return this.removeEvaluation(ctx, evaluation.key);
        }else{
            return this.reloadEvaluation(ctx, evaluation.key);
        }

    }

    private reloadSample(ctx: StateContext<SampleDetailStateModel>){

        const state = ctx.getState();

        const detailRequest: DetailRequest = {
            related: [
                'primaryImage',
                'site',
                'rootstockCultivar',
                'scionCultivar',
                'plantSample',
                'harvestSamples',
            ]
        };

        ctx.patchState({
            status: Status.LOADING,
            sample: null,
        });

        return this._sampleService.get(state.sampleKey, detailRequest)
            .pipe(tap(
                sample => {
                    ctx.patchState({
                        status: Status.OK,
                        sample: sample
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error),
                        sample: null
                    });
                }
            ));

    }

    private reloadEvaluations(ctx: StateContext<SampleDetailStateModel>){

        const state = ctx.getState();

        const evalFilter: Filter = {
            queries: [
                { key: 'sampleKey', value: state.sampleKey },
            ],
            sort: {
                column: 'evalStartDate',
                order: 'asc',
            }
        };


        return this._evalService.query(evalFilter, this.evalDetailRequest)
                    .pipe(tap(
                        coll => {
                            ctx.patchState({
                                evaluations: this.sortEvaluations(coll.data)
                            });
                        },
                        error => {
                            ctx.patchState({
                                evaluations: []
                            });
                        }
                    ));


    }

    private removeEvaluation(ctx: StateContext<SampleDetailStateModel>, key: string){
        const state = ctx.getState();

        ctx.patchState({
            evaluations: removeArrayItem(state.evaluations, key)
        });

        ctx.dispatch(new LoadSampleIndex);

    }

    private reloadEvaluation(ctx: StateContext<SampleDetailStateModel>, key: string) {

        const state = ctx.getState();

        if (state.status !== Status.OK) return;

        return this._evalService.get(key, this.evalDetailRequest)
            .pipe(tap(
                evalu => {

                    const state = ctx.getState();

                    if (state.sampleKey !== evalu.sampleKey) return;

                    ctx.patchState({
                        evaluations: this.sortEvaluations(
                            patchArrayItem(state.evaluations || [], evalu)
                        )
                    });

                    ctx.dispatch(new LoadSampleIndex);
                }
            ));
    }

    private sortEvaluations(evaluations: Evaluation[]){

        return evaluations.sort((a, b) => {
            let ad = new Date(a.evalStartDate);
            let bd = new Date(b.evalStartDate);
            return ad>bd ? 1 : ad<bd ? -1 : 0;
        });

    }

}
