import { Injectable } from "@angular/core";
import { Cultivar, Sample, Status, TastingSampleService, TastingsSampleCultivarBulkActionRow, translateCommonErrorStatus } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";

//STATE MODEL
export interface CultivarTastingsSampleFormStateModel {
    status: Status;
    cultivarKeys: string[];
    cultivars: Cultivar[];
    data: Partial<TastingSample>;
}

// STATE DEFAULTS
const DEFAULTS: CultivarTastingsSampleFormStateModel = {
    status: Status.UNINITIALIZED,
    cultivarKeys: [],
    cultivars: [],
    data: null,
}

// STATE ACTIONS
export class InitCultivarTastingsSampleForm {
    static readonly type = "[CultivarTastingSampleForm] Init";
    constructor(public cultivarKeys: string[], public cultivars: Cultivar[], public defaults?: Partial<TastingSample>) { }
}

export class SubmitCultivarTastingSampleForm {
    static readonly type = "[CultivarTastingSampleForm] Submit";
    constructor(public ownerOrgKey: string, public data: TastingsSampleCultivarBulkActionRow[]) { }
}

// STATE
@State<CultivarTastingsSampleFormStateModel>({
    name: 'cultivar_tasting_sample_form',
    defaults: DEFAULTS
})
@Injectable()
export class CultivarTastingsSampleFormState {
    @Selector()
    static data(state: CultivarTastingsSampleFormStateModel) {
        return state.data;
    }

    constructor(public _tastingSampleService: TastingSampleService) { }

    @Action(InitCultivarTastingsSampleForm, { cancelUncompleted: true })
    initCultivarTastingsSampleForm(ctx: StateContext<CultivarTastingsSampleFormStateModel>, action: InitCultivarTastingsSampleForm) {
        ctx.setState({
            status: Status.OK,
            cultivarKeys: action.cultivarKeys,
            cultivars: action.cultivars,
            data: {
                ...action.defaults
            }
        });
    }

    @Action(SubmitCultivarTastingSampleForm)
    submitCultivarTastingSampleForm(ctx: StateContext<CultivarTastingsSampleFormStateModel>, action: SubmitCultivarTastingSampleForm) {
        ctx.patchState({
            status: Status.LOADING,
        });

        return this._tastingSampleService.createFromCultivar(action.ownerOrgKey, action.data)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.COMPLETE,
                        })
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        })
                    }
                )
            );
    }
}