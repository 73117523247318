<ng-container *ngIf="status$ | async as status">
    <h2 mat-dialog-title>Import Measurements</h2>
    <mat-dialog-content>

        <form id="measurements_import_form"
              [formGroup]="formGroup"
              class="pv-form single-column"
              novalidate
              (ngSubmit)="attempt()"
              autocomplete="disabled">

            <mat-form-field appearance="standard">
                <mat-label>File Format</mat-label>
                <mat-select formControlName="format" placeholder="Select File Format" required>
                    <mat-option value="guss_fta_raw_format">Guss FTA Raw Format (.FTA)</mat-option>
                    <mat-option value="guss_fta_excel_format">Guss FTA Excel Format (.xls)
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Index Offset</mat-label>
                <input matInput type="text" formControlName="indexOffset">
            </mat-form-field>

            <pv-legacy-file-upload
                (file)="setFormFile($event)"
                [maxSize]="maxSize"
                [allows]="allowedExts"
                label="Upload File">
            </pv-legacy-file-upload>

        </form>


    </mat-dialog-content>
    <pv-form-actions
        mat-dialog-actions
        formId="measurements_import_form"
        saveAction="Import"
        [formGroup]="formGroup"
        (cancel)="cancel()"
        [loading]="status === 'LOADING'">

    </pv-form-actions>
</ng-container>
