import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { API_BASE_URI } from "../../http/api";
import { Tag } from "../../types/EvaluationLibrary/tag";
import { DetailRequestBuilder, DetailRequest } from "../../http/detail";
import { Filter, FilterBuilder } from "../../http/filter";
import { Collection } from "../../http/collection";
import { SearchRequest, SearchResponse } from '../../http/search';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export class TagCreated {
    static readonly type = "[TagService] Tag Created";
    constructor(public tag: Tag) {}
}

export class TagUpdated {
    static readonly type = "[TagService] Tag Updated";
    constructor(public tag: Tag) {}
}

export class TagDeleted {
    static readonly type = "[TagService] Tag Deleted";
    constructor(public tag: Tag) {}
}

@Injectable()
export class TagService {

    constructor(
        private _http: HttpClient,
        private _store: Store,
        @Inject(API_BASE_URI) private _baseUri: string,
    ) {}

    get(tagKey: string, detail?: DetailRequest): Observable<Tag> {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<Tag>(`${this._baseUri}/evaluation-library/tag/${tagKey}`, {params});
    }

    create(model: Partial<Tag>) {
        return this._http.post<Tag>(`${this._baseUri}/evaluation-library/tag`, model)
            .pipe(tap(tag => this._store.dispatch(new TagCreated(tag))));
    }

    update(key: string, model: Partial<Tag>) {
        return this._http.put<Tag>(`${this._baseUri}/evaluation-library/tag/${key}`, model)
            .pipe(tap(tag => this._store.dispatch(new TagUpdated(tag))));
    }

    delete(key: string) {
        return this._http.delete<Tag>(`${this._baseUri}/evaluation-library/tag/${key}`)
            .pipe(tap(tag => this._store.dispatch(new TagDeleted(tag))));
    }

    query(filter: Filter, detail?: DetailRequest): Observable<Collection<Tag>> {
        const params = {
            ...FilterBuilder.queryParams(filter),
            ...DetailRequestBuilder.queryParams(detail),
        };

        return this._http.get<Collection<Tag>>(`${this._baseUri}/evaluation-library/tag`, {params});
    }

    search(search: SearchRequest) {
        return this._http.get<SearchResponse<Tag>>(`${this._baseUri}/evaluation-library/tag/search`, {params: <any>search});
    }
}