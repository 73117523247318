import { Characteristic, CharacteristicRescaleStrategy, CharacteristicType } from './_types';


export const LIB_CHARS_FRUT_TREE: Characteristic[] = [

    {
        id: "date_irb",
        type: CharacteristicType.Event,
        label: "Initial Reproductive Budbreak",
        categoryId: "tree_phenology",
        params:{
            color: "#673AB7"
        }
    },
    {
        id: "date_ivb",
        type: CharacteristicType.Event,
        label:"Initial Vegetative Budbreak",
        categoryId: "tree_phenology",
        params:{
            color: "#4CAF50"
        }
    },
    {
        id: "date_swollen_bud",
        type: CharacteristicType.Event,
        label: "Swollen Bud",
        categoryId: "tree_phenology",
        params:{
            color: "#7E57C2"
        }
    },
    {
        id: "date_balloon",
        type: CharacteristicType.Event,
        label: "Balloon Stage",
        categoryId: "tree_phenology",
        params:{
            color: "#9575CD"
        }
    },
    {
        id: "date_start_flower",
        type: CharacteristicType.Event,
        label: "10% Flowering",
        categoryId: "tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_30_flower",
        type: CharacteristicType.Event,
        label: "30% Flowering",
        categoryId: "tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_50_flower",
        type: CharacteristicType.Event,
        label: "50% Flowering",
        categoryId: "tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_full_flower",
        type: CharacteristicType.Event,
        label: "80% Flowering",
        categoryId: "tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_end_flower",
        type: CharacteristicType.Event,
        label: "End Flowering",
        categoryId: "tree_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_petal_fall",
        type: CharacteristicType.Event,
        label: "Petal Fall",
        categoryId: "tree_phenology",
        params:{
            color: "#006064"
        }
    },
    {
        id: "date_flower_thin",
        type: CharacteristicType.Event,
        label: "Flower Thinning",
        categoryId: "tree_phenology",
        params:{
            color: "#26A69A"
        }
    },
    {
        id: "date_fruit_thin",
        type: CharacteristicType.Event,
        label: "Fruit Thinning",
        categoryId: "tree_phenology",
        params:{
            color: "#00695C"
        }
    },
    {
        id: "date_stone_hard",
        type: CharacteristicType.Event,
        label: "Stone Hardening",
        categoryId: "tree_phenology",
        params:{
            color: "#E65100"
        }
    },
    {
        id: "date_harvest_start",
        type: CharacteristicType.Event,
        label: "Harvest Start",
        categoryId: "tree_phenology",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "date_harvest_end",
        type: CharacteristicType.Event,
        label: "Harvest End",
        categoryId: "tree_phenology",
        params:{
            color: "#E65100"
        }
    },
    {
        id: "date_leaf_drop",
        type: CharacteristicType.Event,
        label: "Leaf Drop",
        categoryId: "tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_50_leaf_drop",
        type: CharacteristicType.Event,
        label: "50% Leaf Drop",
        categoryId: "tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_80_leaf_drop",
        type: CharacteristicType.Event,
        label: "80% Leaf Drop",
        categoryId: "tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_100_leaf_drop",
        type: CharacteristicType.Event,
        label: "100% Leaf Drop",
        categoryId: "tree_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "tree_branch_habit_pome",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Branching Habit",
        altLabels: ["Tree Form", "Plant Form"],
        params: {
            options: [
                { value: "columnar", label: "Columnar" },
                { value: "upright", label: "Upright" },
                { value: "semi_upright", label: "Semi-Upright" },
                { value: "intermediate", label: "Intermediate" },
                { value: "semi_spreading", label: "Semi-Spreading" },
                { value: "spreading", label: "Spreading" },
                { value: "weeping", label: "Drooping/Weeping" }
            ]
        }
    },
    {
        id: "tree_branch_habit_stone",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Branching Habit",

        params: {
            options: [
                { value: "upright", label: "Upright" },
                { value: "semi_upright", label: "Semi-Upright" },
                { value: "intermediate", label: "Intermediate" },
                { value: "semi_spreading", label: "Semi-Spreading" },
                { value: "spreading", label: "Spreading" },
                { value: "weeping", label: "Drooping/Weeping" }
            ]
        }
    },
    {
        id: "tree_grow_type_apple",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Growth Type Lespinasse",
        params: {
            options: [
                { value: "type_1", label: "I (Spur)" },
                { value: "type_2", label: "II (Reine des Reinettes)" },
                { value: "type_3", label: "III (Golden Delicious)" },
                { value: "type_4", label: "IV (Granny Smith)" }
            ]
        }
    },
    {
        id: "tree_grow_form",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Growth Form",
        params: {
            options: [
                { value: "basitonic", label: "Basitonic" },
                { value: "mesotonic", label: "Mesotonic" },
                { value: "acrotonic", label: "Acrotonic" }
            ]
        }
    },
    {
        id: "tree_grow_potent",
        type: CharacteristicType.Interval,
        label: "Growth Potential",
        categoryId: "tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_branch_dense",
        type: CharacteristicType.Interval,
        label: "Branching Density",
        categoryId: "tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_feather_dense_stone",
        type: CharacteristicType.Interval,
        label: "Feather Density",
        categoryId: "tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Rare",
                "Few",
                "Intermediate",
                "Numerous",
                "Plentiful"
            ]
        }
    },
    {
        id: "tree_leaf_color",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Leaf Colour",
        params: {
            "multiple": false,
            options: [
                { value: "red", label: "Red" },
                { value: "green", label: "Green" }
            ]
        }
    },
    {
        id: "tree_leaf_color_cherry",
        type: CharacteristicType.Color,
        categoryId: "tree_arch",
        label: "Leaf Color",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "tree_trellis_system",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Trellis System",
        altLabels: ["Espalier"],
        params: {
            options: [
                { value: "palmette", label: "Palmette" },
                { value: "y_tatura", label: "Y-Tatura" },
                { value: "v_tatura", label: "V-Tatura" },
                { value: "basic", label: "Basic Support" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_train_system",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Training System",
        params: {
            options: [
                { value: "solaxe", label: "Solaxe" },
                { value: "open_vase", label: "Open Vase" },
                { value: "central_leader", label: "Central Leader" },
                { value: "bi_axis", label: "Bi Axis" },
                { value: "spindle", label: "Spindle" },
                { value: "ufo", label: "UFO" },
                { value: "kgb", label: "KGB" },
                { value: "multileader", label: "Multileader" },
                { value: "three_leader", label: "Three Leader System" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_train_system_cherry",
        type: CharacteristicType.Nominal,
        categoryId: "tree_arch",
        label: "Training System",
        params: {
            options: [
                { value: "central_leader", label: "Central Leader" },
                { value: "bi_axis", label: "Bi Axis" },
                { value: "ufo", label: "UFO" },
                { value: "kgb", label: "KGB" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_flower_type_stone",
        type: CharacteristicType.Nominal,
        categoryId: "tree_flower",
        label: "Flower Type",
        params: {
            options: [
                { value: "showy", label: "Showy" },
                { value: "non_showy", label: "Non-showy" }
            ]
        }
    },
    {
        id: "tree_flower_window",
        type: CharacteristicType.Interval,
        label: "Flowering Time",
        categoryId: "tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Early",
                "Early",
                "Intermediate",
                "Late",
                "Very Late",
            ]
        }
    },
    {
        id: "tree_flower_dense",
        type: CharacteristicType.Interval,
        label: "Flower Density",
        categoryId: "tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Flowering",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_flower_distrib",
        type: CharacteristicType.Interval,
        label: "Flower Distribution",
        categoryId: "tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "tree_flower_pos",
        type: CharacteristicType.Nominal,
        categoryId: "tree_flower",
        label: "Flower Position",
        params: {
            options: [
                { value: "base", label: "Base" },
                { value: "middle", label: "Middle" },
                { value: "tip", label: "Tip" },
                { value: "spur", label: "Spur" },
                { value: "whole_branch", label: "Whole Branch" }
            ]
        }
    },
    {
        id: "tree_flower_thin",
        type: CharacteristicType.Interval,
        label: "Flower Thinning Required",
        categoryId: "tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },
    {
        id: "tree_flower_second",
        type: CharacteristicType.Interval,
        label: "Secondary Flowering",
        categoryId: "tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },
    {
        id: "tree_flower_color_stone",
        type: CharacteristicType.Nominal,
        categoryId: "tree_flower",
        label: "Flower Colour",
        params: {
            "multiple": false,
            options: [
                { value: "white", label: "White" },
                { value: "cream", label: "Cream" },
                { value: "pink", label: "Pink" },
                { value: "red", label: "Red" },
                { value: "purple", label: "Purple" }
            ]
        }
    },
    {
        id: "tree_bear_precocity",
        type: CharacteristicType.Interval,
        label: "Precocity",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Late",
                "Intermediate",
                "Early"
            ]
        }
    },
    {
        id: "tree_bear_pos_pome",
        type: CharacteristicType.Nominal,
        categoryId: "tree_bear",
        label: "Bearing Position",
        params: {
            options: [
                { value: "tip_bearing", label: "Terminal Bearing" },
                { value: "lateral", label: "Lateral Bearing" }
            ]
        }
    },
    {
        id: "tree_bear_pos_stone",
        type: CharacteristicType.Nominal,
        categoryId: "tree_bear",
        label: "Bearing Position",
        params: {
            options: [
                { value: "base", label: "Base" },
                { value: "middle", label: "Middle" },
                { value: "tip", label: "Tip" },
                { value: "whole_branch", label: "Whole Branch" }
            ]
        }
    },
    {
        id: "tree_bear_habit",
        type: CharacteristicType.Nominal,
        categoryId: "tree_bear",
        label: "Bearing Habit",
        params: {
            options: [
                { value: "spur", label: "Spur" },
                { value: "short_shoots", label: "Short Shoots" },
                { value: "med_shoots", label: "Med Shoots" },
                { value: "long_shoots", label: "Long Shoots" },
                { value: "all_lengths", label: "All Lengths" }
            ]
        }
    },
    {
        id: "tree_bear_distrib",
        type: CharacteristicType.Interval,
        label: "Fruit Distribution",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "tree_cluster_form",
        type: CharacteristicType.Interval,
        label: "Cluster Formation",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Single",
                "Few",
                "Average",
                "Numerous",
                "Full Clusters",
            ]
        }
    },
    {
        id: "tree_bear_age",
        type: CharacteristicType.Nominal,
        categoryId: "tree_bear",
        label: "Age of Bearing Units",
        params: {
            options: [
                { value: "spur", label: "Spurs" },
                { value: "one_year", label: "One year" },
                { value: "two_year", label: "Two years and older" },
            ]
        }
    },
    {
        id: "tree_shoot_extinct",
        type: CharacteristicType.Interval,
        label: "Shoot Extinction",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_bear_potent",
        type: CharacteristicType.Interval,
        label: "Fruit Set Potential",
        altLabels: ["Crop Load"],
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Optimal",
                "Overcropped",
                "Very Overcropped"
            ]
        }
    },
    {
        id: "tree_color_inside",
        type: CharacteristicType.Interval,
        label: "Colour Potential Inside Tree",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "tree_fruit_thin",
        type: CharacteristicType.Interval,
        label: "Fruit Thinning Required",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },
    {
        id: "tree_preharvest_drop",
        type: CharacteristicType.Interval,
        label: "Pre-Harvest Drop",
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_pick_count",
        type: CharacteristicType.Nominal,
        categoryId: "tree_bear",
        label: "Number Of Picks",
        params: {
            options: [
                { value: "pick_1", label: "1" },
                { value: "pick_2", label: "2" },
                { value: "pick_3", label: "3" },
                { value: "pick_4", label: "4" },
                { value: "pick_more", label: "5+" }
            ]
        }
    },
    {
        id: "tree_bear_alternate",
        type: CharacteristicType.Interval,
        label: "Alternate Bearing",
        altLabels: ["Biennial Bearing"],
        categoryId: "tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Consistent",
                "Consistent",
                "Intermediate",
                "Alternating",
                "Very Alternating"
            ]
        }
    },
    {
        id: "tree_pest_detect_stone",
        type: CharacteristicType.Nominal,
        categoryId: "tree_disorder",
        label: "Pests Detected",
        params: {
            options: [
                { value: "aphids", label: "Aphids" },
                { value: "drosophila_suzukki", label: "Drosophila suzukki" },
                { value: "false_codling_moth", label: "False Codling Moth" },
                { value: "fruit_fly", label: "Fruit Fly" },
                { value: "spider_mites", label: "Spider Mites" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_pest_detect_pear",
        type: CharacteristicType.Nominal,
        categoryId: "tree_disorder",
        label: "Pests Detected",
        params: {
            options: [
                { value: "aphids", label: "Aphids" },
                { value: "codling_moth", label: "Codling Moth" },
                { value: "blister_mite", label: "Leaf Blister Mite (Phytoptus pyri)" },
                { value: "psylla_pyri", label: "Psylla (Psylla pyri)" },
                { value: "spider_mites", label: "Spider Mites" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_pest_detect_apple",
        type: CharacteristicType.Nominal,
        categoryId: "tree_disorder",
        label: "Pests Detected",
        params: {
            options: [
                { value: "aphids", label: "Aphids" },
                { value: "woolly_apple_aphids", label: "Woolly Apple Aphids" },
                { value: "codling_moth", label: "Codling Moth" },
                { value: "fruit_moth", label: "Fruit Moth (Argyresthis conjugella)" },
                { value: "leaf_curling_midge", label: "Leaf Curling Midge (Dasineura mali)" },
                { value: "spider_mites", label: "Spider Mites" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_fruit_scab_pome",
        type: CharacteristicType.Interval,
        label: "Fruit Scab Presence",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_leaf_scab_pome",
        type: CharacteristicType.Interval,
        label: "Leaf Scab Presence",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_pseudomonas_pome",
        type: CharacteristicType.Interval,
        label: "Bacterial Infection (Pseudomonas)",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_mildew_symptom_apple",
        type: CharacteristicType.Interval,
        label: "Mildew Presence",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_neonectria_canker_apple",
        type: CharacteristicType.Interval,
        label: "Apple Canker (Neonectria ditissima)",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_disease_detect_stone",
        type: CharacteristicType.Nominal,
        categoryId: "tree_disorder",
        label: "Diseases Detected",
        params: {
            options: [
                { value: "pseudomonas", label: "Bacterial Infection (Pseudomonas)" },
                { value: "xanthomonas", label: "Bacterial Infection (Xanthomonas)" },
                { value: "fruit_infection", label: "Fruit Infection" },
                { value: "leaf_curl", label: "Leaf Curl" },
                { value: "plum_gall", label: "Pocket or Bladder Plum Gall (Taphrina pruni)" },
                { value: "stem_cankers", label: "Stem Cankers" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_overall_disease",
        type: CharacteristicType.Interval,
        label: "Disease Presence",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_leaf_symptom",
        type: CharacteristicType.Interval,
        label: "Leaf Disorders",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_gen_frost_damage",
        type: CharacteristicType.Interval,
        label: "General Winter Frost Damage",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_flower_frost_damage",
        type: CharacteristicType.Interval,
        label: "Flower Winter Frost Damage",
        categoryId: "tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_disorder_detect",
        type: CharacteristicType.Nominal,
        categoryId: "tree_disorder",
        label: "Disorders Detected",
        params: {
            options: [
                { value: "leaf_spots", label: "Leaf spots" },
                { value: "magnesium_deficiency", label: "Magnesium deficiency" },
                { value: "bitter_pit", label: "Bitter pit" },
                { value: "cracking", label: "Cracking" },
                { value: "sunburn", label: "Sunburn" },
                { value: "water_core", label: "Water core" },
                { value: "russeting", label: "Russeting" },
                { value: "winter_damages", label: "Winter damages" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }
    },
    {
        id: "tree_dormant_symptom",
        type: CharacteristicType.Interval,
        label: "Prolonged Dormancy",
        categoryId: "tree_adapt",
        rescale: CharacteristicRescaleStrategy.NEGATIVE,
        weight: 100,
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_flower_span",
        type: CharacteristicType.Interval,
        label: "Flower Burst Span",
        categoryId: "tree_adapt",
        rescale: CharacteristicRescaleStrategy.NEGATIVE,
        weight: 100,
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Brief",
                "Prolonged",
                "Extended"
            ]
        }
    },
    {
        id: "tree_uniform_ripe",
        type: CharacteristicType.Interval,
        label: "Uniform Ripening",
        categoryId: "tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "tree_sunburn_symptom",
        type: CharacteristicType.Interval,
        label: "Sunburn Presence",
        categoryId: "tree_adapt",
        rescale: CharacteristicRescaleStrategy.NEGATIVE,
        weight: 100,
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_crack_symptom",
        type: CharacteristicType.Interval,
        label: "Fruit Cracking",
        categoryId: "tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_root_grow_sync",
        type: CharacteristicType.Interval,
        label: "Rootstock Synchronization",
        categoryId: "tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Out of Sync",
                "Moderate Sync",
                "In Sync"
            ]
        }
    },
    {
        id: "tree_overall_adapt",
        type: CharacteristicType.Interval,
        label: "Adaptability",
        categoryId: "tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Poorly Adapted",
                "Intermediate",
                "Well Adapted"
            ]
        }
    },
    {
        id: "tree_overall_product",
        type: CharacteristicType.Interval,
        label: "Productivity",
        categoryId: "tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_space_filling",
        type: CharacteristicType.Interval,
        label: "Space Filling Potential",
        categoryId: "tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Low",
                "Intermediate",
                "High",
            ]
        }
    },
];