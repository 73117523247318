import { Component, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";
import { TastingEvent } from "@core/data/types/tastings-event";


@Component({
    selector: '[pvTastingEventLine]',
    template: `
        <mat-icon *ngIf="_icon" [svgIcon]="_icon"></mat-icon>
        <span class="text" [innerHtml]="_html"></span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-model-line pv-tasting-event-line'
    }
})
export class TastingEventLineComponent {

    @Input('pvTastingEventLine')
    set tastingEvent(event: TastingEvent){
        if(event){
            this._html = event.title;
            this._icon = 'common:tastings_events';
        }else{
            this._html = '';
            this._icon = null;
        }
    }

    @HostBinding('attr.title')
    _title: string;

    _icon: string = null;
    _html: string;

    constructor(){}
}