import { Module } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_MODULES } from './modules';


export class ModuleDictionary extends Dictionary<Module>{
    constructor(){
        super(LIB_MODULES);
    }
}

export * from './_types';