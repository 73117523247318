import { Protocol } from './_types';

export const LIB_PROTOCOL_BLUEBERRY_BUSH_SCREEN_EVAL: Protocol = {
    id: "blueberry_bush_screen_eval",
    title: "Advanced Blueberry Bush Evaluation",
    description: "Bush evaluation is performed to characterise the adaptability of cultivars and selections on the base of a full set of bush characteristics and includes all phenological stages. Characteristics such as growth and architecture, flowering and fruit set, pests and diseases and general adaptability are evaluated in detail.",
    sampleType: "plant",
    crop: "blueberry",

    chars: [
       
        "date_bush_bud_burst",
        "date_bush_10_flower",
        "date_bush_50_flower",
        "date_bush_75_flower",
        "date_bush_berry_set_25",
        "date_bush_berry_set_50",
        "date_bush_berry_set_75",
        "date_bush_berry_onset",
        "date_bush_berry_harvest_start",
        "date_bush_berry_harvest_half",
        "date_bush_berry_harvest_end",
        "date_bush_berry_std_prune",
        "date_bush_berry_light_prune",
        "date_bush_berry_hard_prune",
        "date_bush_berry_tip_thin",
        "date_bush_berry_top_thin",
        "date_bush_berry_thin_1",
        "date_bush_berry_thin_2",
        "date_bush_berry_thin_3",
        "date_bush_berry_defol",

        "bush_bud_distrib",
        "bush_grow_potent",
        "bush_canopy_dense",
        "bush_trellis_system",
        "bush_train_system",
        "bush_flower_bunch_distrib",
        "bush_bear_habit",
        "bush_cluster_dense",
        "bush_flower_length",
        "bush_flower_opening",
        "bush_pollen",
        "bush_berry_uniform",
        "bush_pick_count",

        "bush_pest_detect",
        "bush_disease_detect",
        "bush_overall_disease",
        "bush_dormant_symptom",
        "bush_flower_span",
        "bush_uniform_ripe",
        "bush_fertile",
        "bush_overall_product",
        "bush_rain_suscept",
        "bush_sunburn_symptom",
        "bush_ease_prune",
        "bush_ease_harvest",
    ],

    measures: [
        
    ],

    indexes: [
       
    ],
};