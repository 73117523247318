
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'pv-view-loader',
    templateUrl: './view-loader.component.html',
    host: {
        class: 'pv-view-loader'
    }
})
export class ViewLoaderComponent implements OnInit {

    @Input()
    message: string;

    constructor() { }

    ngOnInit(): void { }
}
