import { Injectable } from "@angular/core";
import { Status, TagService, WeightedChar, WeightedCharService, translateCommonErrorStatus } from "@core/data";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface TastingCustomWeightedCharFormStateModel {
    key: string;
    status: Status;
    data: Partial<WeightedChar>;
}

const DEFAULTS: TastingCustomWeightedCharFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
}

export class InitNewTastingCustomWeightedCharForm {
    static readonly type = "[TastingCustomWeightedCharForm] Init New";
    constructor(public defaults?: Partial<WeightedChar>) {}
}

export class InitUpdateTastingCustomWeightedCharForm {
    static readonly type = "[TastingCustomWeightedCharForm] Init Update";
    constructor(public key: string) {}
}

export class SubmitTastingCustomWeightedCharForm {
    static readonly type = "[TastingCustomWeightedCharForm] Submit";
    constructor(public data: Partial<WeightedChar>) {}
}

@State<TastingCustomWeightedCharFormStateModel>({
    name: 'tasting_custom_weighted_char_form',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingCustomWeightedCharFormState {
    @Selector()
    static data(state: TastingCustomWeightedCharFormStateModel) {
        return state.data;
    }

    constructor(
        private _weightedCharService: WeightedCharService,
        private _tagService: TagService,
    ) {}

    @Action(InitNewTastingCustomWeightedCharForm, {cancelUncompleted: true})
    initNewTastingCustomWeightedCharForm(ctx: StateContext<TastingCustomWeightedCharFormStateModel>, action: InitNewTastingCustomWeightedCharForm) {
        ctx.setState({
            key: null,
            data: {
                ...action.defaults
            },
            status: Status.OK,
        });
    }

    @Action(InitUpdateTastingCustomWeightedCharForm, {cancelUncompleted: true})
    initUpdateTastingCustomWeightedCharForm(ctx: StateContext<TastingCustomWeightedCharFormStateModel>, action: InitUpdateTastingCustomWeightedCharForm) {
        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING,
        });

        return this._weightedCharService.get(action.key)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            status: Status.OK
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(SubmitTastingCustomWeightedCharForm)
    submitTastingCustomWeightedCharForm(ctx: StateContext<TastingCustomWeightedCharFormStateModel>, action: SubmitTastingCustomWeightedCharForm) {
        const state = ctx.getState();

        let request: Observable<WeightedChar>;

        if (state.key) request = this._weightedCharService.update(state.key, { ...action.data });
        else request = this._weightedCharService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }
}