import { Injectable } from '@angular/core';
import { Evaluation, EvaluationService, NotesUpdateRequest, Status, translateCommonErrorStatus } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

export interface NotesFormStateModel {
    key: string;
    status: Status;
    data: Evaluation;
}

export class InitNotesForm {
    static readonly type = "[NotesForm] Init";
    constructor(public evalKey: string){}
}

export class SubmitNotesForm {
    static readonly type = "[NotesForm] Submit";
    constructor(public payload: NotesUpdateRequest){}
}


@State<NotesFormStateModel>({
    name: "notes_form",
    defaults: {
        key: null,
        status: Status.UNINITIALIZED,
        data: null
    }
})
@Injectable()
export class NotesFormState {

    @Selector()
    static data(state: NotesFormStateModel){
        return state.data;
    }

    constructor(private _evalService: EvaluationService){}

    @Action(InitNotesForm)
    initNotesForm(ctx: StateContext<NotesFormStateModel>, action: InitNotesForm){

        ctx.patchState({
            key: action.evalKey,
            status: Status.LOADING,
            data: null
        });

        return this._evalService
            .get(action.evalKey, {related: ['notes', 'sample']})
            .pipe(tap(
                result => {
                    ctx.patchState({
                        data: result,
                        status: Status.OK
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

    @Action(SubmitNotesForm)
    submitNotesForm(ctx: StateContext<NotesFormStateModel>, action: SubmitNotesForm){

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING,
        });

        return this._evalService
            .update(state.key, action.payload)
            .pipe(tap(
                result => {
                    const state = ctx.getState();
                    ctx.patchState({
                        status: Status.COMPLETE,
                        key: result.key,
                        data: {
                            ...state.data,
                            ...result
                        },
                    });
                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    });
                }
            ));
    }

}