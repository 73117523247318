import { Report, Status, translateCommonErrorStatus } from '@core/data';
import { Action, StateContext, State } from '@ngxs/store';
import { ReportService } from '@core/data';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * Initializes The State With The Reports' Key
 */
export class InitSharedPreview {
    static readonly type = "[ReportSharedPreview] Init Preview";
    constructor(public shareKey: string) {}
}

/**
 * Loads The Report
 */
export class LoadReportSharedPreview {
    static readonly type = '[ReportSharedPreview] Preview Report';
}


/**
 * State Model
 */
export interface ReportSharedPreviewStateModel {
    status: Status;
    shareKey: string;
    data: Partial<Report>;
}

// DEFAULTS Of State Model
const DEFAULTS: ReportSharedPreviewStateModel = {
    status: Status.UNINITIALIZED,
    shareKey: null,
    data: null,
}

@State<ReportSharedPreviewStateModel>({
    name: 'reportSharedPreview',
    defaults: DEFAULTS,
})
@Injectable()
export class ReportSharedPreviewState {

    constructor(
        private _reportService: ReportService,
    ) {}

    /**
     * @param ctx State Context
     * @param action Key: Contains the reports' key that needs to be loaded
     */
    @Action(InitSharedPreview)
    initPreview(ctx: StateContext<ReportSharedPreviewStateModel>, action: InitSharedPreview) {

        if(!action.shareKey) {

            return ctx.patchState({
                ...DEFAULTS,
                status: Status.NOT_FOUND,
            });
        }

        ctx.patchState({
            shareKey: action.shareKey,
        });

        return ctx.dispatch(new LoadReportSharedPreview());
    }

    /**
     * @param ctx State Context
     * @param action No Action Required
     */
    @Action(LoadReportSharedPreview)
    previewReport(ctx: StateContext<ReportSharedPreviewStateModel>, action: LoadReportSharedPreview) {

        ctx.patchState({
            status: Status.LOADING,
        });

        let state = ctx.getState();

        return this._reportService.getShared(state.shareKey)
                    .pipe(
                        tap(
                            result => {

                                ctx.patchState({
                                    status: Status.OK,
                                    data: result,
                                });
                            },
                            error => {
                                this.handleError(ctx, error);
                            }
                        ),
                        catchError(e => {
                            return of(null);
                        }),
                    );
    }


    /**
     * Basic State Error Handling
     * @param ctx State Context
     * @param error Error Any
     */
    private handleError(ctx: StateContext<ReportSharedPreviewStateModel>, error: any){

        const state = ctx.getState();

        ctx.patchState({
            ...DEFAULTS,
            status: translateCommonErrorStatus(error),
            shareKey: state.shareKey,
        });
    }

}