<pv-view #view toolbarTitle="Report Preview" *ngIf="state$ | async as state">

    <!-- TOOLS -->
    <div pv-view-tools>

        <!-- PRINT -->
        <button mat-icon-button
                (click)="view.togglePrintMode(true)"
                matTooltip="Print Preview"
                type="button">
            <mat-icon>print</mat-icon>
        </button>

        <button mat-icon-button
                *ngIf="state.data as report"
                (click)="share(report)"
                matTooltip="Share Report"
                type="button">
            <mat-icon [matBadge]="report.shareKey ? 'S' : null" matBadgeColor="accent">share</mat-icon>
        </button>

        <button mat-icon-button
                matTooltip="More Actions"
                [matMenuTriggerFor]="toolsMenu"
                type="button">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #toolsMenu="matMenu">
            <ng-template matMenuContent>
                <button type="button" mat-menu-item (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                    <span>Reload Report</span>
                </button>
                <button type="button" mat-menu-item (click)="view.togglePrintMode(true)">
                    <mat-icon>print</mat-icon>
                    <span>Print Preview</span>
                </button>
                <a mat-menu-item [routerLink]="['./edit']">
                    <mat-icon>edit</mat-icon>
                    <span>Edit Report</span>
                </a>
            </ng-template>
        </mat-menu>


    </div>

    <!-- BODY -->
    <div pv-view-body [ngSwitch]="state.status">

        <!-- STATUS LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Report Preview...">
        </pv-view-loader>

        <!-- STATUS OK -->
        <pv-report *ngSwitchCase="'OK'" pv-view-body [report]="state.data" [formControlPassthrough]="dummyFormControl"></pv-report>

        <!-- STATUS ERROR -->
        <pv-view-message *ngSwitchDefault
                         heading="{{ state.status | descStatusTitle }}"
                         message="{{ state.status | descStatusMessage: 'Report Preview' }}"
                         icon="error">
            <button pvMessageAction mat-raised-button color="accent"
                    (click)="refresh()">Refresh</button>
            <button pvMessageAction mat-button (click)="back()">Back</button>
        </pv-view-message>

    </div>

</pv-view>
    