import { TastingCustomIndexesQuery } from "./tasting-custom-indexes-index.state-model";

export class InitTastingCustomIndexesIndex {
    static readonly type = "[TastingCustomIndexesIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadTastingCustomIndexesIndex {
    static readonly type = "[TastingCustomIndexesIndex] Load";
    constructor() {}
}

export class PageTastingCustomIndexesIndex {
    static readonly type = "[TastingCustomIndexesIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingCustomIndexesIndex {
    static readonly type = "[TastingCustomIndexesIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingCustomIndexesIndex {
    static readonly type = "[TastingCustomIndexesIndex] Query";
    constructor(public query: TastingCustomIndexesQuery) {}
}

export class ClearTastingCustomIndexesIndexFilter {
    static readonly type = "[TastingCustomIndexesIndex] Clear Filter";
    constructor() {}
}

export class SetTastingCustomIndexesIndexSelected {
    static readonly type = "[TastingCustomIndexesIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingCustomIndex {
    static readonly type = "[TastingCustomIndexesIndex] Delete Custom Index";
    constructor(public key: string) {}
}