import { Report, Status, translateCommonErrorStatus } from '@core/data';
import { Action, StateContext, State } from '@ngxs/store';
import { ReportService } from '@core/data';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * Initializes The State With The Reports' Key
 */
export class InitPreview {
    static readonly type = "[ReportPreview] Init Preview";
    constructor(public key: string) {}
}

/**
 * Loads The Report
 */
export class LoadReportPreview {
    static readonly type = '[ReportPreview] Preview Report';
}

/**
 * Generates Key To Be able To Share Report
 */
export class GenerateShareKey {
    static readonly type = '[ReportPreview] Generate ShareKey';
    constructor(public model: Report){}
}

/**
 * State Model
 */
export interface ReportPreviewStateModel {
    status: Status;
    key: string;
    data: Partial<Report>;
}

// DEFAULTS Of State Model
const DEFAULTS: ReportPreviewStateModel = {
    status: Status.UNINITIALIZED,
    key: null,
    data: null,
}

@State<ReportPreviewStateModel>({
    name: 'reportPreview',
    defaults: DEFAULTS,
})
@Injectable()
export class ReportPreviewState {

    constructor(
        private _reportService: ReportService,
    ) {}

    /**
     * @param ctx State Context
     * @param action Key: Contains the reports' key that needs to be loaded
     */
    @Action(InitPreview)
    initPreview(ctx: StateContext<ReportPreviewStateModel>, action: InitPreview) {

        if(!action.key) {

            return ctx.patchState({
                ...DEFAULTS,
                status: Status.NOT_FOUND,
            });
        }

        ctx.patchState({
            key: action.key,
        });

        return ctx.dispatch(new LoadReportPreview());
    }

    /**
     * @param ctx State Context
     * @param action No Action Required
     */
    @Action(LoadReportPreview)
    previewReport(ctx: StateContext<ReportPreviewStateModel>, action: LoadReportPreview) {

        ctx.patchState({
            status: Status.LOADING,
        });

        let state = ctx.getState();

        return this._reportService.get(state.key)
                    .pipe(
                        tap(
                            result => {

                                ctx.patchState({
                                    status: Status.OK,
                                    data: result,
                                });
                            },
                            error => {
                                this.handleError(ctx, error);
                            }
                        ),
                        catchError(e => {
                            return of(null);
                        }),
                    );
    }

    /**
     * @param ctx State Context
     * @param action Key: of report you want to generate a shareKey for
     */
    @Action(GenerateShareKey)
    generateShareKey(ctx: StateContext<ReportPreviewStateModel>, action: GenerateShareKey) {}

    /**
     * Basic State Error Handling
     * @param ctx State Context
     * @param error Error Any
     */
    private handleError(ctx: StateContext<ReportPreviewStateModel>, error: any){

        const state = ctx.getState();

        ctx.patchState({
            ...DEFAULTS,
            status: translateCommonErrorStatus(error),
            key: state.key,
        });
    }

}