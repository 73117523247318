import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SampleFormatter } from "@app/evaluation/sample-formatter.service";
import { HostNavigator } from "@core/browser";
import { Sample, Status } from '@core/data';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Observer, of } from "rxjs";
import { catchError, debounceTime, map, startWith, switchMap } from "rxjs/operators";
import { SampleLabelOptions } from './sample-label-options';
import { Snackbar } from "@core/material";


@Component({
    selector: 'pv-sample-labels',
    templateUrl: 'sample-labels.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['sample-labels.component.scss'],
    host: {
        class: 'pv-sample-labels'
    }
})
export class SampleLabelsComponent implements OnInit, OnDestroy{

    _samples$ = new BehaviorSubject<Sample[]>([]);
    _options$ = new BehaviorSubject<SampleLabelOptions>({
        layout: 'port',
        size: 'md',
        includeFields: [],
        margin: '5mm',
        whitespace: false,
        duplicateAmount: 0,
    });

    state$: Observable<SampleLabelState>;

    @Input()
    set options(options: SampleLabelOptions){
        this._options$.next(options);
    }

    @Input()
    set samples(samples: Sample[]){
        this._samples$.next(samples);
    }

    constructor(
        private _hostNav: HostNavigator,
        private _changeDetector: ChangeDetectorRef,
        private _sampleFormatter: SampleFormatter,
        private _snackbar: Snackbar,
    ) {}

    ngOnInit() {

        // listen for changes
        this.state$ = combineLatest(this._samples$, this._options$)
            .pipe(
                debounceTime(200),
                switchMap(changes => {

                    let [samples, options] = changes;
                    let duplicate: number = JSON.parse(JSON.stringify(options.duplicateAmount ? options.duplicateAmount : 0));

                    if(Array.isArray(samples) && samples.length > 0){

                        let labels: LabelData[] = []

                        labels = samples.map(sample => {
                            return this.getLabelData(sample, options);
                        });

                        if (duplicate < 0) {
                            duplicate = 0
                            this._snackbar.error('Invalid duplicate amount given, amount reset to 0.');
                        }

                        if (duplicate > 50) {
                            duplicate = 50
                            this._snackbar.error('Maximum of 50 duplicates allowed at a time, amount reset to 50.');
                        }

                        if (duplicate > 0){
                            let duplicateLabels: LabelData[] = []

                            for (let label of labels) {
                                let sample: Sample = samples.find(sample => sample.code === label.code)
                                duplicateLabels.push(label)

                                for (let i = 0; i < duplicate; i++)  {
                                    duplicateLabels.push(this.getLabelData(sample, options));
                                }

                                if (duplicateLabels.length >= 1000) break;
                            }

                            labels = duplicateLabels;
                        }

                        // Ensure limit is not exceeded
                        const limit = 1000;
                        if (labels.length > limit) {
                            labels = labels.slice(0, limit);
                        }

                        if (duplicate > 0) {
                            return this.loadDuplicateImages(labels, options);
                        } else {
                            return this.loadImages(labels, options);
                        }
                    }else {
                        return of({
                            labels: [],
                            options,
                            status: Status.EMPTY
                        });
                    }

                })
            );

    }

    retry(){
        this._samples$.next(this._samples$.getValue());
    }

    ngOnDestroy(){
        this._samples$.complete();
        this._options$.complete();
    }

    private loadDuplicateImages(labels: LabelData[], options: SampleLabelOptions): Observable<SampleLabelState> {
        return this.loadImages(labels, options);
    }

    private loadImages(labels: LabelData[], options: SampleLabelOptions): Observable<SampleLabelState> {

        let loaders = labels.map(label => {
            return this.loadImage(label);
        });

        return forkJoin(loaders).pipe(
            map(labels => {
                return {
                    labels,
                    options,
                    status: Status.OK
                };
            }),
            catchError(err => {
                return of({
                    labels: [],
                    status: Status.ERROR,
                    options: null
                });
            }),
            startWith({
                labels: [],
                status: Status.LOADING,
                options: null
            })
        );

    }

    private loadImage(label: LabelData): Observable<LabelData>{

        return Observable.create((observer: Observer<LabelData>) => {

            const img = new Image();

            img.onload = () => {
                observer.next(label);
                observer.complete();
            };
            img.onerror = (e) => {
                // img.src = '';
                observer.error(e);
            };
            img.src = label.src;

            return () => {
                // img.src = '';
            };
        });
    }


    private getLabelData(sample: any, options: SampleLabelOptions): LabelData{

        const headings: string[] = [];
        const lines: string[] = [];
        const scionCommon: string[] = [];

        if(Array.isArray(options.includeFields)){
            options.includeFields.forEach(field => {

                switch(field){
                    case 'scionCultivar':
                        if(!this._sampleFormatter.describeCultivar(sample.scionCultivar)){
                            return
                        }
                        scionCommon.push(this._sampleFormatter.describeCultivar(sample.scionCultivar));
                        break;

                    case 'rowPosition':
                        if (!(sample.type === 'plant')) return;

                        if (!this._sampleFormatter.describeRowPosition(sample)) {
                            return;
                        }

                        headings.push(this._sampleFormatter.describeRowPosition(sample));
                        break;

                    case 'harvestWeek':
                        if(!(sample.type === 'harvest' && this._sampleFormatter.describeHarvestWeek(sample))) {
                            return;
                        }
                        headings.push(this._sampleFormatter.describeHarvestWeek(sample));
                        break;


                    case 'rootstockCultivar':
                        if(!this._sampleFormatter.describeCultivar(sample.rootstockCultivar)){
                            return
                        }
                        lines.push(this._sampleFormatter.describeCultivar(sample.rootstockCultivar));
                        break;

                    case 'site':
                        if(!this._sampleFormatter.describeSite(sample.site)){
                            return
                        }
                        lines.push(this._sampleFormatter.describeSite(sample.site));
                        break;

                    case 'label':
                        if(!sample.label){
                            return
                        }
                        lines.push(sample.label);
                        break;

                    case 'tradeNames':
                        if (!sample.scionCultivar.tradeNames || !sample.scionCultivar.tradeNames.length) {
                            return
                        }
                        lines.push((sample.scionCultivar.tradeNames))
                        break;

                    case 'harvestDate':
                        if(!sample.birthDate){
                            return;
                        }
                        lines.push((sample.birthDate));
                        break;

                    case 'codes':
                        if(!sample.code){
                            return;
                        }
                        lines.push((sample.code));
                        break;

                    case 'description':
                        if(!sample.description){
                            return;
                        }
                        lines.push((sample.description));
                        break;
                }
            });
        }

        return {
            src: this.getLabelSrc(sample.code),
            code: sample.code,
            headings,
            lines,
            scionCommon,
        };
    }

    private getLabelSrc(code: string) {
        return this._hostNav.hostUrl('barcode/image/' + encodeURIComponent(code) + '.svg');
    }

}

interface SampleLabelState {
    status: Status;
    options: SampleLabelOptions;
    labels: LabelData[];
}

interface LabelData {
    src: string;
    code: string;
    headings: string[];
    lines: string[];
    scionCommon: string[];
}

