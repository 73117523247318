import { SelectionModel } from "@angular/cdk/collections";
import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { AuthState } from "@app/auth";
import { ImagesFormDialog, ImagesFormDialogData, ImageUploadType } from "@app/evaluation/components/evaluation-form/images-form/images-form.dialog";
import { LIBRARY } from "@app/evaluation/library";
import { HostNavigator } from "@core/browser";
import { Cultivar, FilterSort, ImageTransformOptions, Organization, Site, Status } from "@core/data";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Dialog, Snackbar } from "@core/material";
import { Crop, Library } from "@library";
import { Select, Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { TastingEventsFormDialog, TastingEventsFormDialogData } from "../tasting-events-forms/tasting-events-form.dialog";
import { TastingEventsInviteFormDialog, TastingEventsInviteFormDialogData } from "../tasting-events-forms/tasting-events-invite-form.dialog";
import { TastingsEventsIndexState } from "./tasting-events-index.state";
import { ClearTastingsEventIndexFilter, DeleteTastingsEvent, DownloadTastingEventImportTemplate, DownloadTastingsEvaluationReImportTemplate, DownloadTastingsEventManifest, ExportTastingEventResults, ExportTastingsEventIndex, ImportTastingEventIndex, InitTastingsEventIndex, LoadTastingsEventIndex, MarkTastingsEventComplete, PageTastingsEventIndex, PushTastingsEventIndexModelQuery, QueryTastingsEventIndex, SetTastingsEventIndexSelected, SortTastingsEventIndex } from "./tasting-events-index.state-actions";
import { TastingsEventIndexQuery, TastingsEventIndexStateModel } from "./tasting-events-index.state-model";
import { ExportHistoryDialog, ExportHistoryDialogData } from "@app/evaluation/components/export-history-form/export-history-form.dialog";
import { ExportType } from "@core/data/types/export-history";
import { TastingEvaluationsFormDialog, TastingEvaluationsImportFormDialogData } from "../tastings-evaluation-import-form/tastings-evaluation-import-form.dialog";
import { ImportSubjectType } from "@core/data/types/import-subject";

@Component({
    selector: 'pv-tasting-events-index-view',
    templateUrl: 'tasting-events-index.view.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-tasting-events-index-view'
    }
})
export class TastingsEventsIndexView implements OnInit, OnDestroy {
    @Select(AuthState.selectedOrg)
	selectedOrg$: Observable<Organization>;

    @Select(TastingsEventsIndexState)
    state$: Observable<TastingsEventIndexStateModel>;

    @Select(TastingsEventsIndexState.sort)
    sort$: Observable<FilterSort>;

    @Select(TastingsEventsIndexState.query)
    query$: Observable<TastingsEventIndexQuery>;

    completedEvents: TastingEvent[];
    nonCompletedEvents: TastingEvent[];

    // sort form group
    orderControl = new FormControl('desc');
    columnControl = new FormControl('createdAt');
    sortFormGroup = new FormGroup({
        column: this.columnControl,
        order: this.orderControl,
    });

    // export form group
    exportFormGroup = new FormGroup({
        type: new FormControl('xlsx'),
    });

    // import form group
    importFormGroup = new FormGroup({
        file: new FormControl(null),
    });

    searchControl = new FormControl();
    tastingsSamplesControl = new FormControl([]);
    scionCultivarsControl = new FormControl([]);
    sitesControl = new FormControl([]);
    scionCropIdControl = new FormControl([]);
    scionLicenseeControl = new FormControl([]);

    queryFormGroup = new FormGroup({
        search: this.searchControl,
        tastingsSamples: this.tastingsSamplesControl,
        scionCultivars: this.scionCultivarsControl,
        sites: this.sitesControl,
        scionCropId: this.scionCropIdControl,
        scionLicensee: this.scionLicenseeControl,
    });

    selection = new SelectionModel<string>(true, []);

    activeFilterCount: number = 0;
    showFilter: boolean = false;
    uiData: {key: string, showInfo: boolean}[] = []
    cropOptions: Crop[];
    showImportExport: boolean = false;
    adminIdent: string = "xswedca";

    private baseUrl: string = "https://www.google.com/maps/search/?api=1";
    public defaultBannerPath = "cloudinary:provar/culteva/tastings/default-banner";

    private _destroy$ = new Subject();

    public bannerImageOptions: ImageTransformOptions = {
        width: 200,
        height: 115,
        mode: 'fill',
    }

    constructor(
        private _route: ActivatedRoute,
        private _store: Store,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        private _navigator: HostNavigator,
        @Inject(LIBRARY) private _library: Library,
    ) {}

    ngOnInit(): void {
        this._route.paramMap
            .subscribe(params => {
                this._store.dispatch(new InitTastingsEventIndex(params.get('orgKey')));
            });

        // setup crop options
        this.cropOptions = this._library.filterExcludedCrops()

        this.state$.subscribe(data => {
            if (data) {
                this.resolveFilterBadge(data.query);
                this.uiDataCompiler(data.data);

                let grouped = this.groupEvents(data.data);
                this.completedEvents = grouped.completed;
                this.nonCompletedEvents = grouped.nonCompleted;
            }
        });

        //QUERY LOGIC
        this.query$.pipe(takeUntil(this._destroy$))
            .subscribe(query => this.queryFormGroup.patchValue(query, {emitEvent: false}));

        this.queryFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new QueryTastingsEventIndex(val));
            });
        //QUERY LOGIC END

        //SORT LOGIC
        this.sort$.pipe(takeUntil(this._destroy$))
            .subscribe(sort => this.sortFormGroup.patchValue(sort, { emitEvent: false }));

        this.sortFormGroup.valueChanges
            .pipe(debounceTime(200), takeUntil(this._destroy$))
            .subscribe(val => {
                this._store.dispatch(new SortTastingsEventIndex(val.column, val.order));
            });
        //SORT LOGIC END

        // handle selection changes
        this.selection.changed
            .pipe(takeUntil(this._destroy$), debounceTime(100))
            .subscribe(val => {
                this._store.dispatch(new SetTastingsEventIndexSelected(this.selection.selected));
            });

        this._route.paramMap
            .subscribe(params => {
                if (params.get('eventCode')) this.searchControl.setValue(params.get('eventCode'));
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private resolveFilterBadge(formData) {
        this.activeFilterCount = 0;
        Object.keys(formData).forEach(key => {
            if (Array.isArray(formData[key]) && formData[key].length > 0) {
                this.activeFilterCount++;
            } else if ((!Array.isArray(formData[key])) && (formData[key] != null && formData[key].length > 0)) {
                this.activeFilterCount++;
            }
        });
    }

    reload() {
        this._store.dispatch(new LoadTastingsEventIndex);
    }

    downloadManifest(event: TastingEvent) {
      //  this._store.dispatch(new DownloadTastingsEventManifest(event.key));
        this._navigator.openWindow(`tasting-events-manifest/${event.key}`)
    }

    paginate(page: PageEvent) {
        this._store.dispatch(new PageTastingsEventIndex(page.pageIndex, page.pageSize));
    }

    toggleSortOrder(event: MouseEvent) {
        event.stopPropagation();
        this.orderControl.setValue(this.orderControl.value === 'asc' ? 'desc' : 'asc');
    }

    sort(sort: Sort) {
        if (sort.direction) {
            this._store.dispatch(new SortTastingsEventIndex(sort.active, sort.direction));
        } else {
            this._store.dispatch(new SortTastingsEventIndex('updatedAt', 'desc'));
        }
    }

    export() {
        let exportOptions = this.exportFormGroup.value;

        this.exportFormGroup.disable();

        this._store.dispatch(new ExportTastingsEventIndex(exportOptions.type))
            .subscribe(
                global => {
                    let exp = this._store.selectSnapshot(TastingsEventsIndexState.latestExport);

                    if (exp && exp.status === Status.COMPLETE) {
                        this._snackbar.info(
                            `Export is queued. The result will be emailed to you once the export is complete.`,
                        );
                    }
                },
                e => {
                    this.exportFormGroup.enable();
                },
                () => {
                    this.exportFormGroup.enable();
                }
            );
    }

    exportHistory(orgKey: string) {
        const data: ExportHistoryDialogData = {
            orgKey: orgKey,
            type: ExportType.TASTING_EVENTS,
        }

        this._dialog.open(ExportHistoryDialog, { data })
    }

    addModelFilter(model: Cultivar | Site, control: FormControl) {
        let list = control.value;

        // check for duplicate
        if (Array.isArray(list) && list.findIndex(m => m.key === model.key) !== -1) return;

        // append to filter
        control.setValue([...list, model]);
    }

    clearControl(event: MouseEvent, control: FormControl, value = null) {
        event.stopPropagation();
        control.setValue(value);
    }

    resetFilter() {
        this._store.dispatch(new ClearTastingsEventIndexFilter());
    }

    trackByKey(index, item) {
        return item.key;
    }

    /**
     * Whether the number of selected elements matches the total number of rows.
     */
    isAllSelected(data: TastingEvent[]) {
        return data.every((sample) => {
            return this.selection.isSelected(sample.key);
        });
    }

    isSelected(event: TastingEvent) {
        return this.selection.isSelected(event.key)
    }

    select(event: TastingEvent) {
        if (this.selection.isSelected(event.key)) this.selection.deselect(event.key);
        else this.selection.select(event.key);
    }

    uploadEventImage(event: TastingEvent) {
        const data: ImagesFormDialogData = {
           key: event.key,
           type: ImageUploadType.TASTINGS_EVENT
        }

        this._dialog.openFullscreen(ImagesFormDialog, { data });
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection.
     */
    masterToggle(data: TastingEvent[]) {
        this.isAllSelected(data) ?
            data.forEach(row => this.selection.deselect(row.key)) :
            data.forEach(row => this.selection.select(row.key));
    }

    addModelQuery(queryType: string, model: Site | Cultivar) {
        this._store.dispatch(new PushTastingsEventIndexModelQuery(queryType, model));
    }

    add() {
        const state: TastingsEventIndexStateModel = this._store.selectSnapshot(TastingsEventsIndexState);

        const data: TastingEventsFormDialogData = {
            defaults: {
                ownerOrgKey: state.orgKey
            },
            indexOptions: state.indexOptions,
        }

        this._dialog.open(TastingEventsFormDialog, { data });
    }

    edit(tastingEvent: TastingEvent) {
        const state: TastingsEventIndexStateModel = this._store.selectSnapshot(TastingsEventsIndexState);
        const data: TastingEventsFormDialogData = {
            key: tastingEvent.key,
            indexOptions: state.indexOptions,
        };
        this._dialog.open(TastingEventsFormDialog, { data });
    }

    delete(event: TastingEvent) {
        this._dialog.confirm(
            `Delete Tasting Event ${event.code}`,
            'Are you sure you want to delete this event and all associated data?'
        )
            .afterClosed()
            .subscribe(result => {
                if (result) this._store.dispatch(new DeleteTastingsEvent(event.key));
            });
    }

    deleteSelected() {
        let deleteList: DeleteTastingsEvent[] = this.getDeleteList();

        this._dialog.confirm(
            `Delete ${this.selection.selected.length} Tasting Events`,
            `Are you sure you want to delete these events and all associated data?`
        )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this._store.dispatch(deleteList).subscribe(res => {
                        this.selection.clear();
                    });
                }
            });
    }

    getDeleteList() {
        let list: DeleteTastingsEvent[] = [];
        this.selection.selected.forEach(item => {
            list.push(new DeleteTastingsEvent(item));
        })

        return list;
    }

    urlBuilder(event: TastingEvent) {
        if (!this.canLocate(event)) return;

        let url = '&query=' + event.venueAddrStreet + " " + event.venueAddrCity;

        if (event.venueAddrRegion) url = url + " " + event.venueAddrRegion;
        if (event.venueAddrCode) url = url + " " + event.venueAddrCode;

        let encoded = encodeURI(url);

        this.openMaps(encoded);
    }

    openMaps(url) {
        window.open(this.baseUrl + url, '_blank').focus();
    }

    private canLocate(event: TastingEvent): boolean {
        let canLocate = false;
        if (event.venueAddrStreet && event.venueAddrCity) canLocate = true;
        else {
            canLocate = false;
            this._snackbar.message("There is not enough location information available");
        }

        return canLocate;
    }

    moreInfo(event: TastingEvent) {
        let item = this.uiData.find(e => e.key === event.key);

        item.showInfo = !item.showInfo;
    }

    uiDataCompiler(data: TastingEvent[]) {
        data.forEach(event => {
            this.uiData.push({key: event.key, showInfo: false});
        });
    }

    show(event: TastingEvent) {
        return this.uiData.find(e => e.key === event.key).showInfo;
    }

    openOverview(event: TastingEvent) {
        this._navigator.openTastings(`event-overview/${event.ownerOrgKey}/${event.key}`)
    }

    openResults(event: TastingEvent) {
        this._navigator.openTastings(`event-results/${event.ownerOrgKey}/${event.key}/${this.adminIdent}`)
    }

    exportResults(event: TastingEvent) {
        this._store.dispatch(new ExportTastingEventResults(event.key, event));
    }

    markEventComplete(event: TastingEvent) {
        this._store.dispatch(new MarkTastingsEventComplete(event.key, event));
    }

    navigateToSupport() {
        window.open('https://culteva.hipporello.net/desk', '_blank');
    }

    inviteContacts(events: TastingEvent[]) {
        const state: TastingsEventIndexStateModel = this._store.selectSnapshot(TastingsEventsIndexState);
        const data: TastingEventsInviteFormDialogData = {
            orgKey: state.orgKey,
            events: events,
        }

        this._dialog.open(TastingEventsInviteFormDialog, { data });
    }

    setFormFile(file: File) {
        this.importFormGroup.get('file').setValue(file);
    }

    attemptImport() {
        if (!this.importFormGroup.valid) {
            this.handleErrorMessage('Invalid Input. Check your input and try again.');
            return;
        }

        let file: File = this.importFormGroup.value.file;
        this.import(file);
    }

    import(file: File) {
        this._store.dispatch(new ImportTastingEventIndex(file));
    }

    downloadImportTemplate() {
        this._store.dispatch(new DownloadTastingEventImportTemplate());
    }

    openImportDialog(orgKey: string, eventKey: string = null) {
        const data: TastingEvaluationsImportFormDialogData = {
            orgKey: orgKey,
            subjectKey: eventKey,
            subjectType: ImportSubjectType.TASTINGS_EVALS,
        }

        this._dialog.open(TastingEvaluationsFormDialog, { data });
    }

    downloadReimportTemplate(event: TastingEvent) {
        if (!event.indexSnapshot) return this._snackbar.error("No Index Connected to the selected Event");

        this._store.dispatch(new DownloadTastingsEvaluationReImportTemplate(event.key, event.title.toLocaleLowerCase().replace(/ /g, "-")));
    }

    private handleErrorMessage(message: string) {
        this._snackbar.error(message);
    }

    private groupEvents(data: TastingEvent[]) {
        let nonCompleted = data.filter(item => item.completed == false);
        let completed = data.filter(item => item.completed == true);
        return {completed: completed, nonCompleted: nonCompleted};
    }
}

