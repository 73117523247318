<!-- MEMBERS -->
<ng-container *ngIf="membersState$ | async as membersState" [ngSwitch]="membersState.status">

    <mat-progress-bar mode="indeterminate" *ngSwitchCase="'LOADING'"></mat-progress-bar>

    <mat-list role="list" dense *ngSwitchCase="'OK'">
        <mat-list-item role="list-item" *ngFor="let user of membersState.data">

            <mat-icon matListIcon>account_circle</mat-icon>

            <span matLine><strong>{{ user.name }}</strong></span>

            <span matLine>{{ user.email }}</span>

            <strong>{{ user.access.role | labelAccess }}</strong>

            <button mat-icon-button [matMenuTriggerFor]="memberMenu"
                [matMenuTriggerData]="{key: user.key, role: user.access.role}">
                <mat-icon>menu</mat-icon>
            </button>

        </mat-list-item>
    </mat-list>

    <mat-menu #memberMenu="matMenu">

        <ng-template matMenuContent let-key="key" let-role="role">

            <button mat-menu-item type="button" (click)="removeMember(key)">Remove Member</button>

            <button mat-menu-item type="button" [disabled]="role === 'owner'" (click)="updateMemberRole(key, 'owner')">
                Set member role to 'Owner'
            </button>

            <button mat-menu-item type="button" [disabled]="role === 'admin'" (click)="updateMemberRole(key, 'admin')">
                Set member role to 'Administrator'
            </button>

            <button mat-menu-item type="button" [disabled]="role === 'rw'" (click)="updateMemberRole(key, 'rw')">
                Set member role to 'Evaluator'
            </button>

            <button mat-menu-item type="button" [disabled]="role === 'r'" (click)="updateMemberRole(key, 'r')">
                Set member role to 'Read-Only'
            </button>

            <button mat-menu-item type="button" [disabled]="role === 'guest'" (click)="updateMemberRole(key, 'guest')">
                Set member role to 'Guest'
            </button>

        </ng-template>

    </mat-menu>

    <mat-list *ngSwitchDefault>
        <mat-list-item>
            <mat-icon matListIcon>error</mat-icon>
            <span matLine>{{ membersState.status | descStatusTitle }}</span>
            <span matLine>{{ membersState.status | descStatusMessage:'Members' }}</span>
            <button mat-icon-button color="primary" pvMessageAction (click)="reloadMembers()">
                <mat-icon>refresh</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>

</ng-container>
