import { Cultivar, Sample, Site } from "@core/data";
import { TastingSample } from "@core/data/types/tasting-sample";
import { TastingsSampleLabelOptions } from "../tastings-sample-labels/tastings-sample-label-options";
import { TastingSampleIndexQuery } from "./tastings-sample-index.state-model";

export class InitTastingSampleIndex {
    static readonly type = "[TastingSampleIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadTastingSampleIndex {
    static readonly type = "[TastingSampleIndex] Load";
    constructor() {}
}

export class PageTastingSampleIndex {
    static readonly type= "[TastinSampleIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortTastingSampleIndex {
    static readonly type = "[TastingSampleIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

export class QueryTastingSampleIndex {
    static readonly type = "[TastingSampleIndex] Query";
    constructor(public query: TastingSampleIndexQuery) {}
}

export class ClearTastingSampleIndexFilter {
    static readonly type = "[TastingSampleIndex] Clear Filter";
    constructor() {}
}

export class PushTastingSampleIndexModelQuery {
    static readonly type = "[TastingSampleIndex] Push Model Query";
    constructor(public modelType: string, public model: Site | Cultivar) {} // Query/Filter tasting samples by related harvest sample
}

//WIP: set label options - if needed
export class SetTastingsSampleLabelOptions {
    static readonly type = "[TastingsSampleIndex] Set label options";
    constructor(public options: TastingsSampleLabelOptions){}
}

export class ExportTastingSampleIndex {
    static readonly type = "[TastingSampleIndex] Export";
    constructor(public type = 'xlsx') {}
}

export class SetTastingSampleIndexSelected {
    static readonly type = "[TastingSampleIndex] Set Selected";
    constructor(public keys: string[]) {}
}

export class DeleteTastingSample {
    static readonly type = "[TastingSampleIndex] Delete Tasting Sample";
    constructor(public key: string) {}
}

export class UpdateTastingsSamplesToEvent {
    static readonly type = "[TastingSampleIndex] Bulk Add To Event";
    constructor(public data: Partial<TastingSample>) {}
}

export class RemoveTastingsSamplesFromEvent {
    static readonly type = "[TastingSampleIndex] Bulk Remove From Event";
    constructor(public data: Partial<TastingSample>) {}
}