<ng-container *ngIf="state$ | async as state">

    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>{{ state.key ? 'Edit' : 'Add' }}&nbsp;Tasting Sample</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_sample_form"
              #tastingSampleForm="ngForm"
              [formGroup]="formGroup"
              class="pv-form"
              (ngSubmit)="attempt()"
              novalidate
              autocomplete="no">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard" required>
                    <mat-label>Label</mat-label>
                    <input matInput formControlName="label">
                    <mat-hint>Label to identify the sample</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="4" formControlName="description"
                        style="resize: none;"></textarea>
                    <mat-hint>Describe tastings sample or tasting conditions</mat-hint>
                    <mat-error>Description max 255 chars</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Size</mat-label>
                    <input matInput type="text" formControlName="size" autocomplete="off">
                    <small matSuffix>#</small>
                    <mat-hint>Number of units in tasting sample</mat-hint>
                    <mat-error>Enter integer between 1-9999</mat-error>
                </mat-form-field>
            </div>

            <div class="form-column" *ngIf="hasEvaluationModule || isExistingSample">

                <h4 class="form-subheader">Sample Origin</h4>
                <ng-container>
                    <mat-checkbox formControlName="_connectHarvesttSample">
                        Connect Harvest Sample
                    </mat-checkbox>
                    <span class="mat-checkbox-hint">
                        Whether connected to existing harvest sample
                    </span>
                </ng-container>

                <ng-container *ngIf="!connectHarvestSampleControl.value">

                    <h4 class="form-subheader">Scion Cultivar</h4>

                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;Scion Cultivar</span>
                        </mat-label>
                        <pv-cultivar-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                    formControlName="scionCultivar">
                        </pv-cultivar-autocomplete>
                        <button type="button" mat-icon-button matSuffix
                                (click)="addScionCultivar($event)"
                                matTooltip="Add new cultivar"
                                *ngIf="!scionCultivarControl.value">
                            <mat-icon>add_box</mat-icon>
                        </button>
                        <mat-error>Select a scion cultivar</mat-error>
                    </mat-form-field>

                    <h4 class="form-subheader">Location</h4>

                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon>place</mat-icon>
                            <span>&nbsp;Site</span>
                        </mat-label>
                        <pv-site-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                formControlName="site">
                        </pv-site-autocomplete>
                        <button type="button" mat-icon-button matSuffix
                                (click)="addSite($event)"
                                matTooltip="Add new site"
                                *ngIf="!siteControl.value">
                            <mat-icon>add_box</mat-icon>
                        </button>
                        <mat-error>Select a site</mat-error>
                    </mat-form-field>

                    <h4 class="form-subheader">Harvested Date</h4>

                    <mat-form-field appearance="standard">
                        <input matInput formControlName="birthDate" [matDatepicker]="birthDatePicker" required>
                        <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
                        <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                        <mat-hint>Date of the event</mat-hint>
                        <mat-datepicker #birthDatePicker startView="multi-year"></mat-datepicker>
                    </mat-form-field>

                </ng-container>

                <ng-container *ngIf="connectHarvestSampleControl.value">
                    <h4 class="form-subheader">Origin Harvest Sample</h4>

                    <mat-form-field appearance="standard">
                        <mat-label>Harvest Sample</mat-label>
                        <pv-sample-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                formControlName="harvestSample"
                                                sampleType="harvest">
                        </pv-sample-autocomplete>
                        <mat-error>Select an existing harvest sample</mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="form-column">
                <h4 class="form-subheader">Technical Details</h4>

                <mat-form-field appearance="standard"
                    *ngIf="!hasEvaluationModule && !isExistingSample && useExistingHarvestControl.value">
                    <mat-label>Origin Harvest Sample</mat-label>
                    <pv-sample-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                            formControlName="harvestSample"
                                            sampleType="harvest">
                    </pv-sample-autocomplete>
                    <mat-hint>Search for origin Harvest Sample</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Tasting Event</mat-label>
                    <mat-select formControlName="tastingsEventKeys" multiple>
                        <mat-option *ngFor="let event of eventOptions" [value]="event.key">
                            {{ event.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-column" *ngIf="!hasEvaluationModule && !isExistingSample">
                <h4 class="form-subheader">Harvest Sample Details</h4>

                <mat-checkbox matInput formControlName="useExistingHarvest">Use Existing Harvest Sample</mat-checkbox>

                <ng-container *ngIf="!useExistingHarvestControl.value">
                    <mat-form-field appearance="standard">
                        <mat-label>Harvest Sample Label</mat-label>
                        <input matInput formControlName="harvestLabel">
                        <mat-hint>Label to identify the harvest sample</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>Harvest Date</mat-label>
                        <input matInput formControlName="harvestDate" [matDatepicker]="harvestDatePicker">
                        <mat-datepicker-toggle matSuffix [for]="harvestDatePicker">
                        </mat-datepicker-toggle>
                        <mat-error>Valid date required [YYYY-MM-DD]</mat-error>
                        <mat-datepicker #harvestDatePicker startView="multi-year"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon svgIcon="common:flat_scion"></mat-icon>
                            <span>&nbsp;Scion Cultivar</span>
                        </mat-label>
                        <pv-cultivar-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                                  formControlName="scionCultivar">
                        </pv-cultivar-autocomplete>
                        <mat-error>Select a scion cultivar</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                        <mat-label>
                            <mat-icon>place</mat-icon>
                            <span>&nbsp;Site</span>
                        </mat-label>
                        <pv-site-autocomplete [orgKey]="ownerOrgKeyControl.value"
                                              formControlName="site">
                        </pv-site-autocomplete>
                        <mat-error>Select a site</mat-error>
                    </mat-form-field>

                    <p class="tastings-description">
                        You don't have the Evaluation Module.
                        We will use the information above to
                        generate a new Harvest Sample for you
                    </p>
                </ng-container>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingSampleForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>