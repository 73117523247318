<input type="text"
       matInput
       class="text-input input-h"
       [class.focused]="focused"
       [formControl]="control"
       (blur)="blur()"
       [placeholder]="_placeholder"
       [matAutocomplete]="auto"
       autocomplete="no"
       [id]="id + '-input'">

<label class="value-overlay" *ngIf="_value && !focused" [pvSampleLine]="_value" [for]="id + '-input'"></label>

<div class="status-overlay">
    <mat-icon *ngIf="status === 'LOADING'">hourglass_empty</mat-icon>
    <mat-icon *ngIf="status === 'OK' && _value" class="clear" (click)="clear()">clear</mat-icon>
    <mat-icon *ngIf="status === 'ERROR'" color="warn">error</mat-icon>
</div>

<mat-autocomplete #auto="matAutocomplete"
                  [displayWith]="displayFn"
                  (optionSelected)="optionSelected($event)"
                  [autoActiveFirstOption]="true">
    <mat-option *ngIf="status === 'EMPTY'" [disabled]="true">
        <small>No results for '{{ control.value }}'</small>
    </mat-option>
    <mat-option class="sample-option" *ngFor="let option of options" [value]="option" [class.darken]="option.destroyedDate != null">
        <div [pvSampleLine]="option" [includeBirthYear]="true"></div>
    </mat-option>
</mat-autocomplete>
