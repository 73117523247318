import { Characteristic, CharacteristicType } from './_types';


export const LIB_CHARS_AVOCADO_HARVEST: Characteristic[] = [
    // RIPENING

    {
        id: "avo_ripe_stage",
        type: CharacteristicType.Interval,
        label: "Ripening Stage",
        categoryId: "avo_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Hard",
                "Rubbery",
                "Softening",
                "Firm Ripe",
                "Medium-soft Ripe"
            ]
        }
    },
    {
        id: "avo_stem_color",
        type: CharacteristicType.Color,
        label: "Stem Cavity Colour",
        categoryId: "avo_ripening",
        params: {
            palette: [
                { h: 101, s: 38, l: 63, label: "Green" },
                { h: 31, s: 94, l: 24, label: "Brown" },
            ]
        }
    },
    {
        id: "avo_firmness_homog",
        type: CharacteristicType.Interval,
        label: "Firmness Homogeneity",
        categoryId: "avo_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Large Variation",
                "Variable",
                "Intermediate",
                "Little Variation",
                "Homogenous"
            ]
        }
    },
    {
        id: "avo_mature_rate",
        type: CharacteristicType.Interval,
        label: "Maturation Rate",
        categoryId: "avo_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Slow",
                "Slow",
                "Average",
                "Rapid",
                "Very Rapid"
            ]
        }
    },

    // APPEARANCE
    {
        id: "avo_over_color",
        type: CharacteristicType.Color,
        categoryId: "avo_appearance",
        label: "Over Colour",
        params: {
            palette: [
                { h: 101, s: 38, l: 63, label: "Green" },
                { h: 330, s: 37, l: 48, label: "Purple" },
                { h: 0, s: 0, l: 26, label: "Black" },
                { h: 31, s: 94, l: 24, label: "Brown" },
            ]
        }
    },

    // SHAPE & SYMMETRY
    {
        id: "avo_seed_size",
        type: CharacteristicType.Interval,
        label: "Seed Size",
        categoryId: "avo_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Small",
                "Small",
                "Medium",
                "Large",
                "Very Large"
            ]
        }
    },
    {
        id: "avo_seed_shape",
        type: CharacteristicType.Nominal,
        categoryId: "avo_shape_symmetry",
        label: "Seed Shape",
        params: {
            options: [
                { value: "oblate", label: "Oblate" },
                { value: "spheroid", label: "Spheroid" },
                { value: "elipsoid", label: "Elipsoid" },
                { value: "ovate", label: "Ovate" },
                { value: "broadly_ovate", label: "Broadly Ovate" },
                { value: "cordiform", label: "Cordiform" },
                { value: "base_flat_apex_round", label: "Base Flattened, Apex Rounded" },
                { value: "base_flat_apex_conical", label: "Base Flattened, Apex Conical" },
            ]
        }
    },
    {
        id: "avo_skin_thick",
        type: CharacteristicType.Interval,
        label: "Skin Thickness",
        categoryId: "avo_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Thin (<1 mm)",
                "Medium Thin (1 mm)",
                "Medium (2 mm)",
                "Medium Thick (3 mm)",
                "Thick (>3 mm)"
            ]
        }
    },
    {
        id: "avo_frut_shape",
        type: CharacteristicType.Nominal,
        label: "Fruit Shape",
        categoryId: "avo_tree_bear",
        params: {
            options: [
                { value: "spheroid", label: "Spheroid" },
                { value: "clavate", label: "Clavate" },
                { value: "obovate", label: "Obovate" },
                { value: "elipsoid", label: "Elipsoid" },
                { value: "high_spheroid", label: "High Spheroid" },
                { value: "pyriform", label: "Pyriform" },
                { value: "narrowly_obovate", label: "Narrowly Obovate" },
                { value: "rhomboidal", label: "Rhomboidal" },
                { value: "oblate", label: "Oblate" },
            ]
        }
    },
    {
        id: "avo_shape_symmetry",
        type: CharacteristicType.Interval,
        label: "Shape Symmetry",
        categoryId: "avo_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Asymmetrical",
                "Intermediate",
                "Symmetrical",
            ]
        }
    },
    {
        id: "avo_shape_consistent",
        type: CharacteristicType.Interval,
        label: "Shape Consistency",
        categoryId: "avo_shape_symmetry",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Itermediate",
                "Uniform",
                "Very Uniform",
            ]
        }
    },

    // ABRASIONS & BLEMISHES
    {
        id: "avo_abrasion",
        type: CharacteristicType.Nominal,
        label: "Fruit Shape",
        categoryId: "avo_abrasion_blemish",
        params: {
            options: [
                { value: "flesh_bruising", label: "Flesh Bruising" },
                { value: "blemishes", label: "Blemishes" },
                { value: "russeting", label: "Russeting" },
                { value: "lenticel_presence", label: "Lenticel Presence" },
                { value: "mechanical_damage", label: "Mechanical Damage" },
                { value: "pedicular_tearing", label: "Pedicular Tearing" },
                { value: "insect_damage", label: "Insect Damage" },
                { value: "carapace_skin", label: "Carapace Skin" },
                { value: "sunburn", label: "Sunburn" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "avo_russet_type",
        type: CharacteristicType.Nominal,
        label: "Russeting Type",
        categoryId: "avo_abrasion_blemish",
        params: {
            options: [
                { value: "fine", label: "Fine" },
                { value: "net_like", label: "Net-Like" },
                { value: "rough", label: "Rough" },
                { value: "cracked", label: "Cracked" },
            ]
        }
    },

    // DEFECTS
    {
        id: "avo_defect",
        type: CharacteristicType.Nominal,
        label: "Defects",
        categoryId: "avo_defects",
        params: {
            options: [
                { value: "malformation", label: "Malformation" },
                { value: "bent_necks", label: "Bent Necks" },
                { value: "off_seasion_frut", label: "Off-Season Fruit" },
                { value: "cuke", label: "Cuke" },
                { value: "sectional_chimera", label: "Sectional Chimera" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "avo_skin_tex",
        type: CharacteristicType.Nominal,
        label: "Skin Texture",
        categoryId: "avo_defects",
        params: {
            options: [
                { value: "smooth", label: "Smooth" },
                { value: "medium", label: "Medium" },
                { value: "pebbly", label: "Pebbly" },
                { value: "leathery", label: "Leathery" },
                { value: "rough", label: "Rough" },
                { value: "rough_warty", label: "Rough, Warty" },
                { value: "almost_smooth", label: "Almost Smooth" },
                { value: "medium_rough_glossy", label: "Medium Rough, Glossy" },
                { value: "slightly_pebbly", label: "Slightly Pebbly" },
                { value: "medium_leathery", label: "Medium, Leathery" },
                { value: "semi_rough", label: "Semi-Rough" },
            ]
        }
    },

    // EATING QUALITY
    {
        id: "avo_taste_aroma",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "avo_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "avo_taste_type",
        type: CharacteristicType.Nominal,
        label: "Taste Quality/Flavour",
        categoryId: "avo_eating_quality",
        params: {
            options: [
                { value: "other", label: "Other" },
                { value: "neutral", label: "Neutral" },
                { value: "slightly_bitter", label: "Slightly Bitter" },
                { value: "nutty", label: "Nutty" },
                { value: "sweet", label: "Sweet" },
            ]
        }
    },
    {
        id: "avo_taste",
        type: CharacteristicType.Interval,
        label: "Taste",
        categoryId: "avo_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },
    {
        id: "avo_text_firm",
        type: CharacteristicType.Nominal,
        label: "Firmness",
        categoryId: "avo_eating_quality",
        params: {
            options: [
                { value: "hard", label: "Hard" },
                { value: "rubbery", label: "Rubbery" },
                { value: "softening", label: "Softening" },
                { value: "firm_ripe", label: "Firm Ripe" },
                { value: "medium_soft_ripe", label: "Medium-Soft Ripe" },
            ]
        }
    },
    {
        id: "avo_fibre",
        type: CharacteristicType.Interval,
        label: "Flesh Fibres",
        categoryId: "avo_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "avo_text",
        type: CharacteristicType.Nominal,
        label: "Texture",
        categoryId: "avo_eating_quality",
        params: {
            options: [
                { value: "creamy", label: "Creamy" },
                { value: "smooth", label: "Smooth" },
                { value: "melting", label: "Melting" },
                { value: "watery", label: "Watery" },
                { value: "doughy", label: "Doughy" },
                { value: "buttery", label: "Buttery" },
            ]
        }
    },

    // DISEASES & DISORDERS
    {
        id: "avo_disease",
        type: CharacteristicType.Nominal,
        label: "Diseases",
        categoryId: "avo_disease_disorder",
        params: {
            options: [
                { value: "dothiorella_rot", label: "Dothiorella Rot" },
                { value: "fruit_rot", label: "Fruit Rot" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "avo_disorder",
        type: CharacteristicType.Nominal,
        label: "Disorders",
        categoryId: "avo_disease_disorder",
        params: {
            options: [
                { value: "dark_patches", label: "Dark Patches" },
                { value: "grey_flesh", label: "Grey Flesh" },
                { value: "vascular_browning", label: "Vascular Browning" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "avo_chill_sens",
        type: CharacteristicType.Interval,
        label: "Chilling Sensitivity",
        categoryId: "avo_disease_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Resistant",
                "Intermediate",
                "Susceptible"
            ]
        }
    },
];