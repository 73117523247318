import { Injectable } from '@angular/core';
import { Site, SiteService, Status, translateCommonErrorStatus } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface SiteFormStateModel {
    key: string;
    status: Status;
    data: Partial<Site>;
}

/**
 * Initialize site form
 *
 *
 *
 */
export class InitSiteForm {
    static readonly type = '[SiteForm] Init Site Form';
    /**
     *
     * @param key Specified key of site, or null for new
     * @param defaults Defaults override initial form
     */
    constructor(public key?: string, public defaults?: Partial<Site>) { }
}


/**
 * Saves changes to existing sites or creating a new one
 */
export class SubmitSiteForm {
    static readonly type = "[SiteForm] Submit Form";
    constructor(public payload: Partial<Site>) { }
}

/**
 * Site form defaults
 */
const DEFAULTS: SiteFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
};

@State<SiteFormStateModel>({
    name: 'site_form',
    defaults: DEFAULTS,
})
@Injectable()
export class SiteFormState {

    @Selector()
    static status(state: SiteFormStateModel) {
        return state.status;
    }

    @Selector()
    static data(state: SiteFormStateModel){
        return state.data;
    }

    constructor(
        private _siteService: SiteService,
    ) { }

    /**
     *
     * Handle init form action
     *
     * @param ctx State context
     * @param action Action
     */
    @Action(InitSiteForm)
    initSiteForm(ctx: StateContext<SiteFormStateModel>, action: InitSiteForm) {

        if (action.key) {

            ctx.setState({
                key: action.key,
                status: Status.LOADING,
                data: null,
            });

            return this._siteService.get(action.key)
                .pipe(
                    tap(
                        result => {

                            ctx.setState({
                                key: result.key,
                                data: {
                                    ...result,
                                    // override with defaults
                                    ...action.defaults
                                },
                                status: Status.OK,
                            });

                        },
                        error => {

                            ctx.patchState({
                                status: translateCommonErrorStatus(error),
                            });

                        }
                    )
                );

        } else {

            ctx.setState({
                key: null,
                status: Status.OK,
                data: {
                    ...action.defaults,
                },
            });

        }
    }

    /**
     * Saves changes to existing sites or creating a new one
     *
     * @param ctx State Context
     * @param action payload: Partial<Site> New Site Data To Be Saved, key: string Key of the site (Present if updating)
     */
    @Action(SubmitSiteForm, { cancelUncompleted: true })
    submitSiteForm(ctx: StateContext<SiteFormStateModel>, action: SubmitSiteForm) {

        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        let request: Observable<Site>;
        if (state.key){
            request = this._siteService.update(state.key, action.payload);
        }else{
            request = this._siteService.create(action.payload)
        }

        return request.pipe(
            tap(
                result => {

                    ctx.patchState({
                        ...DEFAULTS,
                        status: Status.COMPLETE,
                        data: result,
                    });

                },
                error => {
                    ctx.patchState({
                        status: translateCommonErrorStatus(error)
                    })
                }
            )
        );
    }

}