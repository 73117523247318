<div class="sample-tree-header">
    <mat-checkbox class="select-all-checkbox"
        (change)="selection.selectAllNodes(treeControl, $event.checked)"
        matTooltip="Select/Deselect All"
        [checked]="selection.isAllSampleNodesSelected(treeControl)">
    </mat-checkbox>

    <div class="sample-tree-title">Sample Imports</div>

    <mat-slide-toggle class="select-all-checkbox"
        (change)="selection.selectAllEvaluations(treeControl, $event.checked)"
        matTooltip="Select/Deselect All"
        [checked]="selection.isAllSampleNodesEvaluationsSelected(treeControl)"
        [disabled]="!selection.isAllSampleNodesSelected(treeControl)">
    </mat-slide-toggle>

    <div class="sample-tree-title">Evaluations</div>

    <mat-slide-toggle class="select-all-checkbox"
        (change)="selection.selectAllImages(treeControl, $event.checked)"
        matTooltip="Select/Deselect All Images"
        [checked]="selection.isAllSampleNodesChildrenSelected(treeControl, 'images')"
        [disabled]="!selection.isAnySampleNodesEvaluationsSelected(treeControl)">
    </mat-slide-toggle>

    <div class="sample-tree-title">Images</div>

    <mat-slide-toggle class="select-all-checkbox"
        (change)="selection.selectAllNotes(treeControl, $event.checked)"
        matTooltip="Select/Deselect All Notes"
        [checked]="selection.isAllSampleNodesChildrenSelected(treeControl, 'notes')"
        [disabled]="!selection.isAnySampleNodesEvaluationsSelected(treeControl)">
    </mat-slide-toggle>

    <div class="sample-tree-title">Notes</div>

    <button type="button"
            mat-icon-button
            (click)="treeControl.expandAll()"
            matTooltip="Expand All"
            [disabled]="!_liveData.length">
        <mat-icon>expand_more</mat-icon>
    </button>

    <button type="button"
            mat-icon-button
            (click)="treeControl.collapseAll()"
            matTooltip="Collapse All"
            [disabled]="!_liveData.length">
        <mat-icon>expand_less</mat-icon>
    </button>

</div>
<p class="p-12 mat-caption" *ngIf="!_liveData.length">
    No samples have been imported.
    To import a sample, use the sample search to find your sample and select <i>+</i> to add the
    sample.
</p>
<div class="sample-tree-scroll" cdkScrollable>
    <mat-tree [dataSource]="sampleTreeDataSource" [treeControl]="treeControl" cdkDropList (cdkDropListDropped)="drop($event)">

        <!-- UNKNOWN NODE (default) -->
        <mat-tree-node *matTreeNodeDef="let node" class="default-node">
            {{ node.type }}
        </mat-tree-node>

        <!-- IMAGE NODE -->
        <mat-tree-node *matTreeNodeDef="let node; when: treeControl.nodeIsImage"
                       class="tree-node image-node">

            <div class="check-overlay"
                 [class.selected]="selection.isNodeSelected(node) && selection.isSelectable(node)">
                <mat-checkbox
                              [disabled]="!selection.isSelectable(node)"
                              [checked]="selection.isNodeSelected(node)"
                              (change)="selection.selectNode(node, $event.checked)">
                </mat-checkbox>
            </div>

            <pv-image-ref [fileRef]="node.data.fileRef" transformId="thumb_72"></pv-image-ref>
        </mat-tree-node>

        <!-- NOTE NODE -->
        <mat-tree-node *matTreeNodeDef="let node; when: treeControl.nodeIsNote"
                       class="tree-node note-node">
            <div class="note-box"
                 [class.selected]="selection.isNodeSelected(node) && selection.isSelectable(node)">
                <mat-checkbox
                              [disabled]="!selection.isSelectable(node)"
                              [checked]="selection.isNodeSelected(node)"
                              (change)="selection.selectNode(node, $event.checked)">
                </mat-checkbox>
                <time>{{ node.data.takenAt | amDateFormat:'ll' }}</time> - {{ node.data.message }}
            </div>
        </mat-tree-node>

        <!-- EVAL NODE -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: treeControl.nodeIsEval"
                              class="tree-node eval-node">
            <div class="tree-node-item" [class.node-is-empty]="!treeControl.nodeHasChildren(node)">
                <button type="button" mat-icon-button matTreeNodeToggle class="node-toggle">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox
                              [disabled]="!selection.isSelectable(node)"
                              [checked]="selection.isNodeSelected(node)"
                              (change)="selection.selectNode(node, $event.checked)">
                </mat-checkbox>
                <span class="node-label">
                    {{ node.data.label || 'Unlabelled' }}
                </span>
                <mat-checkbox
                              [disabled]="!(node.data.storageRegime === null || node.data.storageRegime.length)"
                              [checked]="this.isDisabled ? true : selectionTimeline.isSelected(node.data.key)"
                              (change)="selectionTimeline.toggle(node.data.key)">
                </mat-checkbox>
                <span class="node-label">Exclude from Timeline</span>
                <span class="node-label">
                    {{ node.data.evalStartDate | amDateFormat:'ll' }} -
                    {{ node.data.evalEndDate | amDateFormat:'ll' }}
                </span>
            </div>
            <div class="tree-node-children" [class.show-children]="treeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>


        <!-- SAMPLE NODE -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: treeControl.nodeIsSample;" cdkDrag [cdkDragData]="node" [cdkDragDisabled]="!selection.isNodeSelected(node)"
                              class="tree-node">
            <div class="tree-node-item sample-node">
                <button type="button" mat-icon-button matTreeNodeToggle class="node-toggle"
                        [disabled]="!treeControl.nodeHasChildren(node)">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox
                              [checked]="selection.isNodeSelected(node)"
                              (change)="selection.selectNode(node, $event.checked)">
                </mat-checkbox>
                <mat-icon class="type-icon" [svgIcon]="'common:' + node.data.type + '_sample'">
                </mat-icon>
                <div class="node-label">
                    <div>{{ node.data.code }}</div>
                    <div>{{ node.data.label }}</div>
                </div>
                <div class="node-label">
                    <span>{{ node.data.birthDate | amDateFormat:'ll' }}</span>
                </div>
                <div class="node-label">
                    <span *ngIf="node.data.scionCultivar" [pvCultivarLine]="node.data.scionCultivar"
                          graft="scion"></span>
                    <span *ngIf="node.data.rootstockCultivar"
                          [pvCultivarLine]="node.data.rootstockCultivar" graft="rootstock"></span>
                </div>
                <div class="node-label">
                    <span *ngIf="node.data | rowPosition as rp">{{ rp }}</span>
                    <span *ngIf="node.data.site" [pvSiteLine]="node.data.site"></span>
                </div>

            </div>
            <div class="tree-node-children" [class.show-children]="treeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
            </div>
        </mat-nested-tree-node>

    </mat-tree>
</div>
