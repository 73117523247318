import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Evaluation, SampleMeasurement, Status, MeasurementsConvertResponse, CustomProtocolService} from '@core/data';
import { Dialog, Snackbar } from '@core/material';
import { NumberValidators } from '@core/utils';
import {CropSubject, Library, Measurement, Protocol} from '@library';
import {Select, StateContext, Store} from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InitMeasurementsForm, MeasurementsFormState, MeasurementsFormStateModel, SubmitMeasurementsForm } from './measurements-form.state';
import { MeasurementsUploadDialog } from './measurements-upload.dialog';
import { LIBRARY } from '@app/evaluation/library';
import {EvaluationFormStateModel} from "@app/evaluation/components/evaluation-form/evaluation-form.state";
import { MatTabChangeEvent } from '@angular/material/tabs';


export interface MeasurementsFormDialogData {
    evalKey: string;
}

interface MeasurementsFormTab {
    subject: CropSubject;
    measures: Measurement[];
    indexes: number[];
}

export enum NavigationKeys {
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ENTER = 'Enter',
}

@Component({
    selector: 'pv-measurements-form-dialog',
    templateUrl: './measurements-form.dialog.html',
    host: {
        class: 'pv-measurements-form-dialog pv-fullscreen-dialog'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementsFormDialog implements OnInit, OnDestroy {

    static DEFAULT_SIZE = 10;

    @Select(MeasurementsFormState)
    state$: Observable<MeasurementsFormStateModel>;

    @Select(MeasurementsFormState.data)
    data$: Observable<Evaluation>;
    private _data: Evaluation;

    acceptedKeys: string[] = [
        NavigationKeys.ARROW_UP,
        NavigationKeys.ARROW_DOWN,
        NavigationKeys.ARROW_LEFT,
        NavigationKeys.ARROW_RIGHT,
        NavigationKeys.ENTER
    ];

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: any) {
        if (!this.acceptedKeys.includes(event.key)) return;

        let cellPattern = new RegExp(/^c_\d+_r_\d+$/);
        let isCell = cellPattern.test(event.target.id)

        if (!isCell) {
            this.handleNavigation(0,0);
            return;
        }

        this.selectCell(event);
    }

    formGroup = new FormGroup({});
    tabs: MeasurementsFormTab[] = [];
    rowMax: number = null;
    colMax: number = null;

    private _destroy$ = new Subject();

    constructor(
        protected _dialogRef: MatDialogRef<MeasurementsFormDialog>,
        @Inject(LIBRARY) private _library: Library,
        private _changeDetector: ChangeDetectorRef,
        private _dialogs: Dialog,
        private _store: Store,
        private _snackbar: Snackbar,
        private _custom_protocolService: CustomProtocolService,
        @Inject(MAT_DIALOG_DATA) public data: MeasurementsFormDialogData
    ) { }

    ngOnInit() {
        this._store.dispatch(new InitMeasurementsForm(this.data.evalKey));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Measurements updated");
                    this.cancel();
                }else if(state.status === Status.OK || state.status === Status.INVALID){
                    this.formGroup.enable();
                }else{
                    this.formGroup.disable();
                }

            });

        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if(data){
                    this._data = data;
                    this.reset(data);
                }
            });

    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    save(model) {
        this._dialogRef.close(model);
    }

    cancel() {
        this._dialogRef.close();
    }

    increaseSize(tabIndex: number) {
        this.setRowCount(tabIndex, this.getRowCount(tabIndex) + 1, true);
        this.determineTabRange(this.tabs[tabIndex]);
    }

    decreaseSize(tabIndex: number) {
        this.setRowCount(tabIndex, this.getRowCount(tabIndex) - 1, true);
        this.determineTabRange(this.tabs[tabIndex]);
    }

    openFileUploadDialog() {
        let dialogRef = this._dialogs.open(MeasurementsUploadDialog);

        dialogRef.afterClosed().subscribe((result: MeasurementsConvertResponse) => {
            if (result) this.import(result.measures);
        });
    }

    reset(model: Evaluation) {

        if (!model) return;

        this.buildForm(model.protocolId, 20,()=>{

            // sort measures by index, desc, to optimize
            // form creation
            let sortedMeasures = model.measures.concat()
                .sort((a, b) => b.index - a.index);

            sortedMeasures.forEach(value => {
                if (value.value === null) return;
                this.setValue(value);
            });

            this.formGroup.updateValueAndValidity();
            this._changeDetector.markForCheck();
            if (this.tabs[0]) this.determineTabRange(this.tabs[0]);
        })
    }

    attempt(){

        this.formGroup.updateValueAndValidity();

        if(this.formGroup.valid){
            this.submit();
        }else{
            this._snackbar.error("One or more values is invalid. Check your input and try again.");
        }

    }

    submit(){

        const updates = this.getUpdatedMeasurements();

        if(updates.length > 0){
            this._store.dispatch(new SubmitMeasurementsForm({ measures: updates }));
        } else {
            this._snackbar.info('No changes to measurements.');
            this.cancel();
        }

    }

    trackTab(index, item) {
        return item.subject.id;
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        let tab: MeasurementsFormTab = this.tabs[tabChangeEvent.index];
        this.determineTabRange(tab);
    }

    determineTabRange(tab: MeasurementsFormTab): void {
        this.rowMax = tab.indexes.length - 1;
        this.colMax = tab.measures.length - 1;
    }

    selectCell(event: any) {
        let positions = event.target.id.split('_');
        let col = +positions[1];
        let row = +positions[3];
        let isPositive = (event.key == NavigationKeys.ARROW_DOWN || event.key == NavigationKeys.ARROW_RIGHT || event.key == NavigationKeys.ENTER);

        if (event.key == NavigationKeys.ARROW_UP || event.key == NavigationKeys.ARROW_DOWN || event.key == NavigationKeys.ENTER) row = this.navigateAxis(isPositive, row, this.rowMax);

        if (event.key == NavigationKeys.ARROW_LEFT || event.key == NavigationKeys.ARROW_RIGHT) col = this.navigateAxis(isPositive, col, this.colMax);

        this.handleNavigation(row, col);
    }

    navigateAxis(positiveNav: boolean, dynamicAxis: number, dynamicMax: number) {
        let axis = dynamicAxis;

        if (positiveNav) axis = dynamicAxis == dynamicMax ? 0 : axis += 1
        else axis = dynamicAxis == 0 ? dynamicAxis = dynamicMax : axis -= 1;

        return axis
    }

    handleNavigation(row: number, col: number) {
        let cell = document.getElementById(`c_${col}_r_${row}`);
        if (cell) cell.focus();
        return;
    }

    private buildForm(protocolId: string, indexCount = 20, callback: () => void) {

        this.tabs = [];

        const protocol = this._custom_protocolService.allProtocols().get(protocolId);
        if (protocol){
            this.buildFormForProtocol(protocol, indexCount, callback);
            return;
        }

        this.getProtocol(protocolId,  (result: Protocol) => {
            this.buildFormForProtocol(result, indexCount, callback);
        });

    }


    private buildFormForProtocol(protocol: Protocol, indexCount = 20, callback: () => void) {
        const measures = protocol.measures.map(measureId => {
            return this._library.measures.get(measureId);
        }).filter(measure => !!measure);

        let subject: CropSubject,
            tabs: MeasurementsFormTab[] = [],
            tab: MeasurementsFormTab;

        let controlsArray: FormArray,
            controls: FormControl[],
            controlGroup: FormGroup;

        // build tab data according to protocol
        measures.forEach(measure => {

            subject = this._library.subjects.get(measure.subjectId);
            if (!subject) subject = this._library.subjects.get('unknown');

            tab = tabs.find(tab => tab.subject.id === subject.id);

            if (!tab) {

                tab = {
                    subject,
                    measures: [measure],
                    indexes: Array(indexCount).fill(0).map((n, i) => i)
                };

                tabs.push(tab);
                controlGroup = new FormGroup({});
                this.formGroup.registerControl(subject.id, controlGroup);
            } else {
                tab.measures.push(measure);
                controlGroup = <FormGroup>this.formGroup.get(subject.id);
            }

            controls = Array(indexCount).fill(0).map((n, i) => this.makeControl(measure));
            controlsArray = new FormArray(controls);
            controlGroup.registerControl(measure.id, controlsArray);

        });

        this.tabs = tabs;

        callback();
    }

    private setValue(value: Partial<SampleMeasurement>, markAsDirty = false){

        let tab: MeasurementsFormTab, tabIndex: number, measure: Measurement;

        for(let i = 0; i < this.tabs.length; i++){
            measure = this.tabs[i].measures.find(m => m.id === value.measureId);
            if(measure){
                tabIndex = i;
                tab = this.tabs[i];
                break;
            }
        }

        if(!tab){
            console.warn("MeasurementsFormDialog: Subject or measure not found for measurement id %o", value.measureId);
            return false;
        }

        if(this.getRowCount(tabIndex) < (value.index + 1)){
            this.setRowCount(tabIndex, value.index + 1);
        }

        let control = (this.formGroup.get(tab.subject.id).get(measure.id) as FormArray)
                        .at(value.index);

        control.setValue(value.value);

        if(markAsDirty){
            control.markAsDirty();
        }

        return true;
    }

    private getRowCount(tabIndex: number){
        return this.tabs[tabIndex] ? this.tabs[tabIndex].indexes.length : 0;
    }

    private setRowCount(tabIndex: number, size: number, markAsDirty = false) {

        if (this.tabs[tabIndex] === undefined) return;

        let tab = this.tabs[tabIndex],
            arrayControl: FormArray,
            currentSize: number = tab.indexes.length,
            group = this.formGroup.get(tab.subject.id);

        if (currentSize === size) return;

        while (currentSize < size) {
            tab.measures.forEach(measure => {
                arrayControl = <FormArray>group.get(measure.id);
                let control = this.makeControl(measure, null);
                if(markAsDirty) control.markAsDirty();
                arrayControl.push(control);
            });
            currentSize++;
        }

        while (size < currentSize) {
            tab.measures.forEach(measure => {
                arrayControl = <FormArray>group.get(measure.id);
                arrayControl.removeAt(currentSize - 1);
            });
            currentSize--;
        }

        tab.indexes = Array(size).fill(0).map((item, i) => i);

        this.formGroup.updateValueAndValidity();
        this._changeDetector.markForCheck();
    }

    private getUpdatedMeasurements(): Partial<SampleMeasurement>[] {

        const formValues = this.getFormValues();
        const prevValues = this._data.measures.concat();
        const changedValues: Partial<SampleMeasurement>[] = [];

        let pv: SampleMeasurement, tab: MeasurementsFormTab;

        // compare the initial values with updated values
        // and only take what has changed
        formValues.forEach(fv => {

            pv = prevValues.find(pv => {
                return pv.measureId === fv.measureId
                    && pv.index === fv.index;
            });

            if(!pv && fv.value === null) return;

            if(!pv || pv.value !== fv.value){
                changedValues.push(fv);
            }

        });

        // look for values outside the bounds
        // of the current form to be set to null
        prevValues.forEach(pv => {

            if(pv.value === null) return;

            tab = this.tabs.find(tab => {
                return tab.measures.findIndex(m => m.id == pv.measureId) !== -1;
            });

            if (!tab) return;

            if(pv.index > (tab.indexes.length - 1)){
                changedValues.push({
                    measureId: pv.measureId,
                    index: pv.index,
                    value: null
                });
            }


        });

        return changedValues;
    }


    private getFormValues(): Partial<SampleMeasurement>[]{

        let values: Partial<SampleMeasurement>[] = [];

        let subjectControl: FormGroup, measureControl: FormArray, measureValues: number[];

        Object.keys(this.formGroup.controls).forEach(subjectId => {

            subjectControl = <FormGroup>this.formGroup.get(subjectId);

            Object.keys(subjectControl.controls).forEach(measureId => {

                measureControl = <FormArray>subjectControl.get(measureId);

                measureControl.controls.forEach((control, index) => {

                    if(control.dirty){
                        values.push({
                            measureId,
                            index,
                            value: this.castValue(control.value)
                        });
                    }

                });

            });

        });

        return values;
    }

    private castValue(value: any): number{

        if(value === null || value === undefined || value === ''){
            return null;
        }

        return Number(value);
    }

    private import(values: Partial<SampleMeasurement>[]) {

        values.forEach(value => {
            this.setValue(value, true);
        });

        this.formGroup.updateValueAndValidity();
        this._changeDetector.markForCheck();

    }

    private makeControl(measure: Measurement, value: number = null) {
        return new FormControl(value, { validators: [
            NumberValidators.decimal(10, 3),
            Validators.min(measure.min),
            Validators.max(measure.max)
        ]});
    }

    private getProtocol(protocolId: string, callback: (protocol: Protocol) => void) {
        this._custom_protocolService.read(protocolId).subscribe(result => {
            callback(this._custom_protocolService.toProtocol(result));
        });
    }
}
