import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'routerLinkParser',
})
export class RouterLinkParserPipe implements PipeTransform {

	constructor() { }

	transform(value: string[], param: string) {
		let concat = '';

		value.forEach(val => {
			if(!val.startsWith(':')) concat += '/'+val;
			else concat += '/'+param+'/';
		});

		return concat;
	}
}
