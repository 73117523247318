import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppAuthModule } from "@app/auth";
import { EvaluationModule } from "@app/evaluation";
import { LIBRARY } from "@app/evaluation/library";
import { MENU } from "@app/evaluation/menu";
import { MODULES } from "@app/evaluation/modules";
import { SharedModule } from "@app/shared.module";
import { AppBrowserModule } from "@core/browser";
import { AppChartsModule } from "@core/charts";
import { CoreDataModule } from "@core/data";
import { MapModule } from "@core/maps";
import { AppMaterialModule } from "@core/material";
import { CultevaMenu } from "@core/menu";
import { CultevaModules } from "@core/modules";
import { TimelineModule } from "@core/timeline";
import { CoreUtilsModule } from "@core/utils";
import { Library } from "@library";
import { NgxsModule } from "@ngxs/store";
import { TastingEventAutocompleteChipsComponent } from "./components/model-autocomplete/tasting-event-autocomplete-chips.component";
import { TastingEventAutocompleteComponent } from "./components/model-autocomplete/tasting-event-autocomplete.component";
import { TastingSampleAutocompleteChipsComponent } from "./components/model-autocomplete/tasting-sample-autocomplete-chips.component";
import { TastingSampleAutocompleteComponent } from "./components/model-autocomplete/tasting-sample-autocomplete.component";
import { TastingEventLineComponent } from "./components/model-line/tasting-event-line.component";
import { TastingSampleLineComponent } from "./components/model-line/tasting-sample-line.component";
import { TastingContactFormDialog } from "./components/tasting-contact-forms/tasting-contact-form.dialog";
import { TastingContactFormState } from "./components/tasting-contact-forms/tasting-contact-form.state";
import { TastingContactInviteFormDialog } from "./components/tasting-contact-forms/tasting-contact-invite-form.dialog";
import { TastingContactInviteFormState } from "./components/tasting-contact-forms/tasting-contact-invite-form.state";
import { TastingContactIndexState } from "./components/tasting-contact-index/tasting-contact-index.state";
import { TastingContactIndexView } from "./components/tasting-contact-index/tasting-contact-index.view";
import { TastingEventsFormDialog } from "./components/tasting-events-forms/tasting-events-form.dialog";
import { TastingEventsFormState } from "./components/tasting-events-forms/tasting-events-form.state";
import { TastingEventsInviteFormDialog } from "./components/tasting-events-forms/tasting-events-invite-form.dialog";
import { TastingEventInviteFormState } from "./components/tasting-events-forms/tasting-events-invite-form.state";
import { TastingsEventsIndexState } from "./components/tasting-events-index/tasting-events-index.state";
import { TastingsEventsIndexView } from "./components/tasting-events-index/tasting-events-index.view";
import { TastingsSampleEventFormDialog } from "./components/tastings-sample-forms/tastings-sample-event-form.dialog";
import { TastingsSampleEventFormState } from "./components/tastings-sample-forms/tastings-sample-event-form.state";
import { TastingSampleFormDialog } from "./components/tastings-sample-forms/tastings-sample-form.dialog";
import { TastingSampleFormState } from "./components/tastings-sample-forms/tastings-sample-form.state";
import { TastingSampleIndexState } from "./components/tastings-sample-index/tastings-sample-index.state";
import { TastingsSampleIndexView } from "./components/tastings-sample-index/tastings-sample-index.view";
import { TastingsSampleLabelsComponent } from "./components/tastings-sample-labels/tastings-sample-labels.component";
import { BusinessAreaPipe } from "./pipes/businessarea.pipe";
import { CountryPipe } from "./pipes/country.pipe";
import { TastingsRoutingModule } from "./tastings-routing.module";
import { TastingsEvaluationsIndexView } from "./components/tastings-evaluations-index/tastings-evaluations-index.view";
import { TastingsEvaluationsIndexState } from "./components/tastings-evaluations-index/tastings-evaluations-index.state";
import { UserEmailPipe } from "./pipes/user-email.pipe";
import { UserCommentPipe } from "./pipes/comment.pipe";
import { UserScorePipe } from "./pipes/score-value.pipe";
import { UserTagPipe } from "./pipes/tags.pipe";
import { TastingEvaluationFormDialog } from "./components/tastings-evaluations-forms/tastings-evaluations-form.dialog";
import { TastingEvaluationFormState } from "./components/tastings-evaluations-forms/tastings-evaluations-form.state";
import { TastingCustomIndexesIndexView } from "./components/tasting-custom-indexes-index/tasting-custom-indexes-index.view";
import { TastingCustomIndexesIndexState } from "./components/tasting-custom-indexes-index/tasting-custom-indexes-index.state";
import { TastingCustomTagsIndexState } from "./components/tasting-custom-tags-index/tasting-custom-tags-index.state";
import { TastingCustomTagsIndexView } from "./components/tasting-custom-tags-index/tasting-custom-tags-index.view";
import { TastingCustomWeightedCharIndexState } from "./components/tasting-custom-weighted-chars-index/tasting-custom-weighted-chars-index.state";
import { TastingCustomWeightedCharsIndexView } from "./components/tasting-custom-weighted-chars-index/tasting-custom-weighted-chars-index.view";
import { TastingCustomTagsFormState } from "./components/tasting-custom-tags-form/tasting-custom-tags-form.state";
import { TastingCustomTagsFormDialog } from "./components/tasting-custom-tags-form/tasting-custom-tags-form.dialog";
import { TastingCustomWeightedCharFormDialog } from "./components/tasting-custom-weighted-chars-form/tasting-custom-weighted-chars-form.dialog";
import { TastingCustomWeightedCharFormState } from "./components/tasting-custom-weighted-chars-form/tasting-custom-weighted-chars-form.state";
import { TastingCustomIndexesFormDialog } from "./components/tasting-custom-indexes-form/tasting-custom-indexes-form.dialog";
import { TastingCustomIndexesFormState } from "./components/tasting-custom-indexes-form/tasting-custom-indexes-form.state";
import { EvaluationCharacteristic } from "./pipes/characteristics.pipe";
import { TastingEvaluationsFormDialog } from "./components/tastings-evaluation-import-form/tastings-evaluation-import-form.dialog";
import { TastingsEvaluationImportState } from "./components/tastings-evaluation-import-form/tastings-evaluation-import-form.state";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { ImportSubjectTypePipe } from "./pipes/importSubjectType.pipe";

const libraryInstance = new Library;
const menuInstance = new CultevaMenu;
const modulesInstance = new CultevaModules;

@NgModule({
    imports: [
        CommonModule,
        CoreDataModule,
        AppMaterialModule,
        AppChartsModule,
        AppAuthModule,
        CoreUtilsModule,
        MapModule,
        TimelineModule,
        AppBrowserModule,
        TastingsRoutingModule,
        SharedModule,
        NgxsModule.forFeature([
            //states
            TastingSampleIndexState,
            TastingsEventsIndexState,
            TastingContactIndexState,
            TastingsEvaluationsIndexState,
            TastingCustomIndexesIndexState,
            TastingCustomTagsIndexState,
            TastingCustomWeightedCharIndexState,
            //dialog states
            TastingSampleFormState,
            TastingEventsFormState,
            TastingContactFormState,
            TastingContactInviteFormState,
            TastingsSampleEventFormState,
            TastingEventInviteFormState,
            TastingEvaluationFormState,
            TastingCustomTagsFormState,
            TastingCustomWeightedCharFormState,
            TastingCustomIndexesFormState,
            TastingsEvaluationImportState,
        ])
    ],
    declarations: [
        //Views
        TastingsSampleIndexView,
        TastingsSampleLabelsComponent,
        TastingsEventsIndexView,
        TastingContactIndexView,
        TastingsEvaluationsIndexView,
        TastingCustomIndexesIndexView,
        TastingCustomTagsIndexView,
        TastingCustomWeightedCharsIndexView,
        //Dialogs
        TastingSampleFormDialog,
        TastingEventsFormDialog,
        TastingContactFormDialog,
        TastingContactInviteFormDialog,
        TastingsSampleEventFormDialog,
        TastingEventsInviteFormDialog,
        TastingEvaluationFormDialog,
        TastingCustomTagsFormDialog,
        TastingCustomWeightedCharFormDialog,
        TastingCustomIndexesFormDialog,
        TastingEvaluationsFormDialog,
        //Autocomplete
        TastingEventAutocompleteComponent,
        TastingSampleAutocompleteComponent,
        //Autocomplete chips
        TastingEventAutocompleteChipsComponent,
        TastingSampleAutocompleteChipsComponent,
        //Lines
        TastingEventLineComponent,
        TastingSampleLineComponent,
        //Pipes
        CountryPipe,
        BusinessAreaPipe,
        UserEmailPipe,
        UserCommentPipe,
        UserScorePipe,
        UserTagPipe,
        EvaluationCharacteristic,
        FileSizePipe,
        ImportSubjectTypePipe,
    ],
    exports: [
    ],
    providers: [
        {
            provide: LIBRARY,
            useValue: libraryInstance
        },
        {
            provide: MENU,
            useValue: menuInstance
        },
        {
            provide: MODULES,
            useValue: modulesInstance
        }
    ],
    entryComponents: [
        //Dialogs
        TastingSampleFormDialog,
        TastingEventsFormDialog,
        TastingContactFormDialog,
        TastingContactInviteFormDialog,
        TastingsSampleEventFormDialog,
        TastingEventsInviteFormDialog,
        TastingEvaluationFormDialog,
        TastingCustomTagsFormDialog,
        TastingCustomWeightedCharFormDialog,
        TastingCustomIndexesFormDialog,
        TastingEvaluationsFormDialog,
    ]
})
export class TastingsModule { }