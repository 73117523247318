import { Component, ChangeDetectionStrategy, OnInit, Inject } from "@angular/core";
import { ReportSample, ReportTable, Sample, SampleType } from "@core/data";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";

export interface ReportTableEvaluationFormDialogData {
    data: Partial<ReportTable>;
    samples: ReportSample[];
}

@Component({
    selector: 'pv-report-table-evaluation-form-dialog',
    templateUrl: 'report-table-evaluation-form.dialog.html',
    styleUrls: ['report-table-evaluation-form.dialog.scss'],
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTableEvaluationFormDialog implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: ReportTableEvaluationFormDialogData,
        private _ref: MatDialogRef<ReportTableEvaluationFormDialog>,
    ) {}

    samples: ReportSample[];
    table: Partial<ReportTable>;

    includeEvaluationControl = new FormControl([]);

    includePlantSamples = new FormControl(null);
    includeHarvestSamples = new FormControl(null);

    ngOnInit() {
        this.table = this._data.data;
        this.samples = this._data.samples;

        this.includeEvaluationControl.setValue(this.table.tableOptions.includedEvals);

        this.includePlantSamples.valueChanges
            .subscribe(data => {
                if (data) this.sampleAction(SampleType.PLANT, data.includes('add'));
            })

        this.includeHarvestSamples.valueChanges
            .subscribe(data => {
                if (data) this.sampleAction(SampleType.HARVEST, data.includes('add'));
            })
    }

    attempt() {
        const cast = {
            id: this._data.data.id,
            title: this.table.title,
            description: this.table.description,
            tableOptions: {
                columns: this.table.tableOptions.columns,
                isAggregate: this.table.tableOptions.isAggregate,
                isSpread: this.table.tableOptions.isSpread,
                showSummary: this.table.tableOptions.showSummary,
                includedEvals: this.includeEvaluationControl.value,
                cropId: this.table.tableOptions.cropId,
            },
        };

        this._ref.close(cast);
    }

    cancel() {
        this._ref.close();
    }

    sampleAction(sampleType: SampleType, addAction: boolean) {
        let evals: string[] = [];
        let targetEvals: string[] = [];
        let includedEvals: string[] = this.includeEvaluationControl.value;

        this.samples.forEach(sample => {
            if (sample.data.type === sampleType) targetEvals.push(...sample.includeEvals);
            evals.push(...sample.includeEvals);
        });

        let filteredEvals = evals.filter(evalu => !targetEvals.includes(evalu));

        if (addAction) this.includeEvaluationControl.setValue([...new Set([...includedEvals, ...targetEvals])]);
        else this.includeEvaluationControl.setValue(includedEvals.filter(evalu => filteredEvals.includes(evalu)));
    }
}
