import { WeatherSensor, WeatherReport } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_WEATHER_SENSORS } from './sensors';
import { LIB_WEATHER_REPORTS } from './reports';

export class WeatherSensorDictionary extends Dictionary<WeatherSensor>{
    constructor(){
        super(LIB_WEATHER_SENSORS);
    }
}

export class WeatherReportDictionary extends Dictionary<WeatherReport>{
    constructor(){
        super(LIB_WEATHER_REPORTS);
    }
}

export * from './_types';