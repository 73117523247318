import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Sidenav } from '../sidenav/sidenav.service';
import { Location } from '@angular/common';
import { Title }  from '@angular/platform-browser';

@Component({
    selector: 'pv-print-view',
    templateUrl: './print-view.component.html',
    host: {
        class: 'pv-print-view-component'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintViewComponent implements OnInit {

    @Input('toolbarTitle') set toolbarTitle(title: string){
        this.title = title;
        this._titleService.setTitle(title + ' | Culteva');
    }

    @Input() set returnTo(returnUrl: any){
        this.returnUrl = returnUrl;
        this.showMenu = !returnUrl;
    }

    @Input() toolbarColor: string = 'primary';

    @Input() showMenu = true;

    title: string = '';
    returnUrl: string;
    userObs: any;

    constructor(
        public sidenavService: Sidenav, 
        private _route: ActivatedRoute, 
        private _location: Location,
        private _titleService: Title
    ) { }

    ngOnInit() { 
    }

    ngOnDestroy() {

    }

    back(){
        this._location.back();
    }

}