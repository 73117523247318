import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status, UploadReadingsFormData } from '@core/data';
import { Snackbar } from '@core/material';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { InitWeatherReadingsUpload, SubmitWeatherReadingsUpload, WeatherReadingsUploadState, WeatherReadingsUploadStateModel } from './weather-readings-upload.state';
import { fileExtension } from '@core/utils';


@Component({
    selector: 'pv-weather-readings-upload-dialog',
    templateUrl: 'weather-readings-upload.dialog.html',
    styleUrls: ['weather-readings-upload.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherReadingsUploadDialog implements OnInit, OnDestroy {

    @Select(WeatherReadingsUploadState)
    state$: Observable<WeatherReadingsUploadStateModel>;

    maxSize = 20000000; // 20MB
    allowedExts = ['csv'];
    validationErrors: string[] = [];

    fileControl = new FormControl(null, [Validators.required]);
    fileFormatControl = new FormControl('', [Validators.required]);

    formGroup = new FormGroup({
        file: this.fileControl,
        file_format: this.fileFormatControl,
        import: new FormControl('default'),
    });

    private _destroy$ = new Subject();

    constructor(
        private _dialog: MatDialogRef<WeatherReadingsUploadDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) private _data: WeatherReadingsUploadDialogData,
    ) { }

    ngOnInit() {
        this._store.dispatch(new InitWeatherReadingsUpload(this._data.key));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if (state.status === Status.COMPLETE) {
                    this.formGroup.disable();
                    this._dialog.close(state.key);
                } else if (state.status === Status.LOADING) {
                    this.formGroup.disable();
                } else {
                    this.formGroup.enable();
                }
            });

    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    setFormFile(file: File) {
        this.formGroup.get('file').setValue(file ? file : null);
    }

    attempt() {

        if (this.formGroup.valid) {

            const form = this.formGroup.value;

            let formData = this.formGroup.getRawValue();

            let data: UploadReadingsFormData = {
                file: form.file,
                file_format: formData.file_format,
                import: formData.import,
            };

            this._store.dispatch(new SubmitWeatherReadingsUpload(data))
                .pipe(delay(1000));

        } else {
            this._snackbar.error("Input invalid. Check your input and try again.");
        }

    }

    cancel() {
        this._dialog.close();
    }
}

export interface WeatherReadingsUploadDialogData {
    key: string;
}
