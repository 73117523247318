import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Organization, OrganizationService, Status, translateCommonErrorStatus } from '@core/data';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pv-org-control',
    templateUrl: 'org-control.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrgControlComponent),
            multi: true
        }
    ]
})
export class OrgControlComponent implements ControlValueAccessor, OnDestroy, OnInit {

    private _value$ = new BehaviorSubject(null);
    state$: Observable<{ org: Organization, status: Status }>;

    private onChange: Function = (randomNumber: number) => { };
    private onTouch: Function = () => { };
    private disabled: boolean = false;

    constructor(private _orgService: OrganizationService) { }

    ngOnInit() {
        this.state$ =
            this._value$
                .pipe(
                    distinctUntilChanged(),
                    switchMap(key => {
                        if (key == null) return of({
                            org: null,
                            status: Status.EMPTY
                        });
                        return this._orgService.get(key).pipe(
                            map(result => {
                                return {
                                    status: Status.OK,
                                    org: result
                                };
                            }),
                            catchError(err => {
                                return of({
                                    status: translateCommonErrorStatus(err),
                                    org: null
                                });
                            }),
                            startWith({ org: null, status: Status.LOADING }),
                        );
                    }),
                );
    }

    writeValue(obj: any): void {
        this._value$.next(obj);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngOnDestroy() {
        this._value$.complete();
    }

}