<!-- STATS CARD -->
<mat-card *ngIf="statsState$ | async as statsState">
    <!-- Stats Card Header -->
    <mat-card-header>
        <mat-icon mat-card-avatar>assessment</mat-icon>
        <mat-card-title>Organization Stats</mat-card-title>
        <mat-card-subtitle>Summary of the data within the organization</mat-card-subtitle>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content [ngSwitch]="statsState.status">
        <!-- Stats State Status: LOADING -->
        <pv-view-loader mat-card-content message="Loading Organization Stats..."
                        *ngSwitchCase="'LOADING'">
        </pv-view-loader>

        <!-- Stats State Status: OK -->
        <mat-list *ngSwitchCase="'OK'" dense class="mat-card-inset">
            <ng-container *ngIf="statsState.data as stats">
                <!-- Users -->
                <mat-list-item>
                    <span matSuffix>{{ stats.usersCount }}</span>
                    <span matLine>Members</span>
                </mat-list-item>

                <!-- Cultivars -->
                <mat-list-item>
                    <span matSuffix>{{ stats.cultivarsCount }}</span>
                    <span matLine>Cultivars</span>
                </mat-list-item>

                <!-- Sites -->
                <mat-list-item>
                    <span matSuffix>{{ stats.sitesCount }}</span>
                    <span matLine>Sites</span>
                </mat-list-item>

                <!-- Reports -->
                <mat-list-item>
                    <span matSuffix>{{ stats.reportsCount }}</span>
                    <span matLine>Reports</span>
                </mat-list-item>

                <!-- Plant Samples -->
                <mat-list-item>
                    <span matSuffix>{{ stats.plantSamplesCount }}</span>
                    <span matLine>Plant Samples</span>
                </mat-list-item>

                <!-- Plant Evaluations -->
                <mat-list-item>
                    <span matSuffix>{{ stats.plantEvaluationsCount }}</span>
                    <span matLine>Plant Evaluations</span>
                </mat-list-item>

                <!-- Harvest Samples -->
                <mat-list-item>
                    <span matSuffix>{{ stats.harvestSamplesCount }}</span>
                    <span matLine>Harvest Samples</span>
                </mat-list-item>

                <!-- Harvest Evaluations -->
                <mat-list-item>
                    <span matSuffix>{{ stats.harvestEvaluationsCount }}</span>
                    <span matLine>Harvest Evaluations</span>
                </mat-list-item>

                <!-- Tastings Samples -->
                <mat-list-item>
                    <span matSuffix>{{ stats.tastingSamplesCount }}</span>
                    <span matLine>Tasting Samples</span>
                </mat-list-item>

                <!-- Tastings Events -->
                <mat-list-item>
                    <span matSuffix>{{ stats.tastingEventsCount }}</span>
                    <span matLine>Tasting Events</span>
                </mat-list-item>

                <!-- Tastings Contacts -->
                <mat-list-item>
                    <span matSuffix>{{ stats.tastingContactsCount }}</span>
                    <span matLine>Tasting Contacts</span>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-icon-button (click)="refreshStats()">
            <mat-icon>refresh</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>
<!-- STATS CARD END -->
