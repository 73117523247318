<pv-view toolbarTitle="Report Index" *ngIf="state$ | async as state">


    <!-- Tools -->
    <div pv-view-tools>

        <!-- Refresh Reports -->
        <button mat-icon-button
                [matMenuTriggerFor]="toolsMenu"
                matTooltip="More Actions">
            <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #toolsMenu="matMenu">
            <ng-template matMenuContent>
                <button mat-menu-item (click)="refresh()">
                    <mat-icon>refresh</mat-icon>
                    <span>Refresh Report Index</span>
                </button>
            </ng-template>
        </mat-menu>


    </div>


    <!-- ADD -->
    <button pv-view-fab mat-fab (click)="add(state.orgKey)" color="primary"
            matTooltip="Create New Report">
        <mat-icon>add</mat-icon>
    </button>


    <!-- PAGINATION & SORT FOOTER -->
    <div pv-view-footer class="pv-table-footer">

        <label class="mat-caption mr-12" for="report_index_sort">Sort</label>
        <mat-select [formControl]="columnControl" id="report_index_sort"
                    class="report-index-sort-select">
            <mat-option *ngFor="let opt of state.sortOptions" [value]="opt.id">
                {{ opt.label }}
            </mat-option>
        </mat-select>
        <button type="button" class="ml-12" mat-icon-button (click)="toggleSortOrder($event)">
            <mat-icon
                      [svgIcon]="orderControl.value === 'asc' ? 'fa:solid-sort-alpha-up' : 'fa:solid-sort-alpha-down'">
            </mat-icon>
        </button>

        <mat-paginator [length]="state.total"
                       [pageSize]="state.page.size"
                       [pageIndex]="state.page.index"
                       (page)="paginate($event)"
                       [pageSizeOptions]="state.pageSizeOptions">
        </mat-paginator>
    </div>


    <!-- SIDEBAR -->
    <div pv-view-sidebar>

        <form class="pv-form single-column dense p-12 report-filter-form"
              [formGroup]="queryFormGroup">

            <mat-form-field appearance="outline">
                <mat-label>
                    <mat-icon>search</mat-icon> Search Reports
                </mat-label>
                <input type="text" matInput formControlName="search"
                       placeholder="Enter report title or description">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <mat-icon svgIcon="common:flat_scion"></mat-icon> Filter Scion Cultivar(s)
                </mat-label>
                <pv-cultivar-autocomplete-chips formControlName="scionCultivars"
                                                [orgKey]="state.orgKey">
                </pv-cultivar-autocomplete-chips>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>
                    <mat-icon svgIcon="common:flat_rootstock"></mat-icon> Filter Rootstock
                    Cultivar(s)
                </mat-label>
                <pv-cultivar-autocomplete-chips formControlName="rootstockCultivars"
                                                [orgKey]="state.orgKey">
                </pv-cultivar-autocomplete-chips>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Filter Crop</mat-label>
                <mat-select formControlName="scionCropId" multiple>
                    <mat-option *ngFor="let crop of cropOptions" [value]="crop.id">
                        <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                        <span>{{ crop.label }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="form-actions">
                <button mat-button type="button" color="warn"
                        (click)="resetFilter()">Clear Filters</button>
            </div>

        </form>


    </div>
    <!-- SIDEBAR END -->


    <!-- BODY -->
    <div pv-view-body class="report-index-body" [ngSwitch]="state.status">

        <!-- BODY STATUS: EMPTY -->
        <pv-view-message *ngSwitchCase="'EMPTY'" heading="No results"
                         message="The current filters does not match any Reports."
                         icon="help">
            <button mat-raised-button color="accent" pvMessageAction (click)="resetFilter()">
                Reset Filters
            </button>
        </pv-view-message>

        <!-- BODY STATUS: LOADING -->
        <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Reports..."></pv-view-loader>

        <!-- REPORT TABLE (BODY STATUS: OK) -->
        <ng-container *ngSwitchCase="'OK'"></ng-container>
        <div *ngIf="state.status === 'OK' || state.status === 'LOADING'"
            class="pv-report-table pv-responsive-table">

            <table cdk-table
                   [dataSource]="state.data"
                   [trackBy]="trackReport"
                   matSort
                   [matSortDisableClear]="true"
                   [matSortDirection]="state.sort.order"
                   [matSortActive]="state.sort.column"
                   (matSortChange)="sort($event)">

                <!-- TITLE -->
                <ng-container cdkColumnDef="title">
                    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>Title</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <a class="title-line" [routerLink]="[row.key]">
                            {{ row.title }}
                        </a>
                        <div class="caption-line">{{ row.description }}</div>
                    </td>
                </ng-container>

                <!-- ACTIONS -->
                <ng-container cdkColumnDef="actions">
                    <th cdk-header-cell *cdkHeaderCellDef>Actions</th>
                    <td cdk-cell *cdkCellDef="let row">

                        <!-- PREVIEW -->
                        <a mat-icon-button [routerLink]="[row.key]"
                           matTooltip="View Report">
                            <mat-icon [matBadge]="row.shareKey ? 'S' : null">pageview</mat-icon>
                        </a>
                        <button mat-icon-button [matMenuTriggerFor]="reportMenu"
                                [matMenuTriggerData]="{report: row}"
                                matTooltip="More Actions">
                            <mat-icon>more_horiz</mat-icon>
                        </button>

                    </td>
                </ng-container>

                <!-- SAMPLES -->
                <ng-container cdkColumnDef="samples">
                    <th cdk-header-cell *cdkHeaderCellDef># Samples</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <span class="title-line">
                            {{ row.sampleSummaries?.length }} samples
                        </span>
                    </td>
                </ng-container>

                <!-- IMAGES -->
                <ng-container cdkColumnDef="images">
                    <th cdk-header-cell *cdkHeaderCellDef>Images</th>
                    <td cdk-cell *cdkCellDef="let row">
                        <div class="report-image-gallery"
                             *ngIf="row | getReportPrimaryImages as imageRefs">

                            <pv-image-ref *ngFor="let imageRef of imageRefs"
                                          [fileRef]="imageRef"
                                          transformId="thumb_72">
                            </pv-image-ref>

                        </div>
                    </td>
                </ng-container>

                <!-- TIMESTAMPS -->
                <ng-container cdkColumnDef="updatedAt">
                    <th cdk-header-cell *cdkHeaderCellDef mat-sort-header>
                        <mat-icon>access_time</mat-icon>
                    </th>
                    <td cdk-cell *cdkCellDef="let row">
                        <span class="caption-line">Created
                            {{ row.createdAt | date:'mediumDate' }}</span>
                        <span class="caption-line">Updated
                            {{ row.updatedAt | date:'mediumDate' }}</span>
                    </td>
                </ng-container>


                <tr cdk-header-row *cdkHeaderRowDef="columns;" class="no-print"></tr>
                <tr cdk-row *cdkRowDef="let row; columns: columns;"></tr>

            </table>


        </div>
        <!-- REPORT TABLE END -->

        <!-- BODY STATUS: ERROR / DEFAULT -->
        <pv-view-message *ngSwitchDefault
                         heading="{{ state.status | descStatusTitle }}"
                         message="{{ state.status | descStatusMessage: 'Reports' }}"
                         icon="error">
            <button pvMessageAction mat-raised-button color="accent"
                    (click)="refresh()">Refresh</button>
            <button pvMessageAction mat-button (click)="back()">Back</button>
        </pv-view-message>

    </div>
    <!-- BODY END -->


    <!-- Report Context Menu -->
    <mat-menu #reportMenu="matMenu">
        <ng-template matMenuContent let-report="report">
            <a mat-menu-item [routerLink]="[report.key]">
                <mat-icon>pageview</mat-icon>
                <span>View Report</span>
            </a>
            <button *ngIf="report.sampleSummaries?.length > 0"
                    mat-menu-item
                    [matMenuTriggerFor]="reportSamplesMenu"
                    [matMenuTriggerData]="{report: report}">
                <mat-icon>list</mat-icon>
                <span>Samples</span>
            </button>
            <a mat-menu-item [routerLink]="[report.key, 'edit']">
                <mat-icon>edit</mat-icon>
                <span>Edit Report</span>
            </a>
            <button mat-menu-item (click)="shareReport(report)">
                <mat-icon>share</mat-icon>
                <span>Share Report</span>
            </button>
            <button mat-menu-item (click)="deleteReport(report)">
                <mat-icon>delete</mat-icon>
                <span>Delete Report</span>
            </button>
        </ng-template>
    </mat-menu>


    <!-- Report Samples Submenu -->
    <mat-menu #reportSamplesMenu="matMenu">
        <ng-template matMenuContent let-report="report">
            <a mat-menu-item
               *ngFor="let summ of report.sampleSummaries"
               [routerLink]="['/org', summ.sampleOwnerOrgKey, 'samples', summ.sampleType, summ.sampleKey]">
                <span [pvSampleLine]="summ"></span>
            </a>
        </ng-template>
    </mat-menu>


</pv-view>
