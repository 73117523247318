import { Action, Selector, State, StateContext } from "@ngxs/store";
import { DEFAULTS, TastingCustomWeightedCharIndexStateModel } from "./tasting-custom-weighted-chars-index.state-model";
import { DetailRequest, Filter, FilterBuilder, FilterQueryMode, IndexService, Status, TagCreated, TagDeleted, TagService, TagUpdated, TastingsIndexCreated, TastingsIndexDeleted, TastingsIndexUpdated, WeightedCharCreated, WeightedCharDeleted, WeightedCharService, WeightedCharUpdated, translateCommonErrorStatus } from "@core/data";
import { LocalStorage } from "@core/browser";
import { ClearTastingCustomWeightedCharIndexFilter, DeleteTastingCustomWeightedChar, InitTastingCustomWeightedCharIndex, LoadTastingCustomWeightedCharIndex, PageTastingCustomWeightedCharIndex, QueryTastingCustomWeightedCharIndex, SetTastingCustomWeightedCharIndexSelected, SortTastingCustomWeightedCharIndex } from "./tasting-custom-weighted-chars-index.state-actions";
import { catchError, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { Injectable } from "@angular/core";

@State({
    name: 'tasting_custom_weighted_char_index',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingCustomWeightedCharIndexState {
    @Selector()
    static sort(state: TastingCustomWeightedCharIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingCustomWeightedCharIndexStateModel) {
        return state.query;
    }

    constructor(
        private _weightedCharService: WeightedCharService,
        private _storage: LocalStorage,
        private _tagService: TagService,
        private _indexService: IndexService,
    ) {}

    @Action(InitTastingCustomWeightedCharIndex)
    initTastingCustomWeightedCharIndex(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: InitTastingCustomWeightedCharIndex) {
        const state = ctx.getState();
        if (state.orgKey === action.orgKey && state.status !== Status.UNINITIALIZED) return;

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK,
        })

        this.restoreFilter(ctx);
        return forkJoin(
            this.loadTagOptions(ctx),
            this.loadIndexOptions(ctx),
            ctx.dispatch(new LoadTastingCustomWeightedCharIndex)
        );
    }

    @Action(LoadTastingCustomWeightedCharIndex, {cancelUncompleted: true})
    loadTastingCustomWeightedCharIndex(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: LoadTastingCustomWeightedCharIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'tags',
                'indexes',
            ],
            counts: [],
        };

        return this._weightedCharService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(PageTastingCustomWeightedCharIndex)
    pageTastingCustomWeightedCharIndex(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: PageTastingCustomWeightedCharIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size,
            }
        });

        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    @Action(SortTastingCustomWeightedCharIndex)
    sortTastingCustomWeightedCharIndex(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: SortTastingCustomWeightedCharIndex) {
        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order,
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    @Action(QueryTastingCustomWeightedCharIndex)
    queryTastingCustomWeightedCharIndex(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: QueryTastingCustomWeightedCharIndex) {
        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    @Action(DeleteTastingCustomWeightedChar)
    deleteTastingCustomTag(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: DeleteTastingCustomWeightedChar) {
        return this._weightedCharService.delete(action.key);
    }

    @Action(ClearTastingCustomWeightedCharIndexFilter)
    clearTastingCustomWeightedCharIndexFilter(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: ClearTastingCustomWeightedCharIndexFilter) {
        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    @Action(SetTastingCustomWeightedCharIndexSelected)
    setTastingCustomWeightedCharIndexSelected(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: SetTastingCustomWeightedCharIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let index = state.data.find(i => i.key === key);
            if (index) return index;
            return state.selectedData.find(i => i.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(WeightedCharCreated)
    onCustomWeightedCharCreated(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: WeightedCharCreated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    @Action(WeightedCharUpdated)
    onCustomWeightedCharUpdated(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: WeightedCharUpdated) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }
    @Action(WeightedCharDeleted)
    onCustomWeightedCharDeleted(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: WeightedCharDeleted) {
        const state = ctx.getState();
        if (![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingCustomWeightedCharIndex);
    }

    @Action(TastingsIndexCreated)
    onTastingsIndexCreated(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: TastingsIndexCreated) {
        return this.loadIndexOptions(ctx);
    }

    @Action(TastingsIndexUpdated)
    onTastingsIndexUpdated(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: TastingsIndexUpdated) {
        return this.loadIndexOptions(ctx);
    }

    @Action(TastingsIndexDeleted)
    onTastingsIndexDeleted(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: TastingsIndexUpdated) {
        return this.loadIndexOptions(ctx);
    }

    @Action(TagCreated)
    onTagCreated(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: TagCreated) {
        return this.loadTagOptions(ctx);
    }

    @Action(TagUpdated)
    onTagUpdated(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: TagUpdated) {
        return this.loadTagOptions(ctx);
    }

    @Action(TagDeleted)
    onTagDeleted(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>, action: TagDeleted) {
        return this.loadTagOptions(ctx);
    }

    private loadTagOptions(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>) {
        const state = ctx.getState();
        const fb = new FilterBuilder();
        fb.setQuery('ownerOrgKeyCust', state.orgKey);

        return this._tagService.query(fb.get())
            .pipe(
                tap(result => {
                    ctx.patchState({
                        tagOptions: result.data
                    });
                }),
                catchError(e => {
                    console.warn('Error loading tag options', e);
                    ctx.patchState({
                        tagOptions: []
                    });
                    return of(null);
                })
            );
    }

    private loadIndexOptions(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>) {
        const state = ctx.getState();
        const fb = new FilterBuilder();
        fb.setQuery('ownerOrgKeyCust', state.orgKey);

        return this._indexService.query(fb.get())
            .pipe(
                tap(result => {
                    ctx.patchState({
                        indexOptions: result.data,
                    });
                }),
                catchError(e => {
                    console.warn('Error loading index options', e);
                    ctx.patchState({
                        indexOptions: [],
                    });
                    return of(null);
                })
            );
    }

    private getCurrentFilter(state: TastingCustomWeightedCharIndexStateModel): Filter {
        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (Array.isArray(state.query.tagKeys) && state.query.tagKeys.length > 0) {
            fb.setQuery('tag_keys', state.query.tagKeys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.indexKeys) && state.query.indexKeys.length > 0) {
            fb.setQuery('index_keys', state.query.indexKeys, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>) {
        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tasting_custom_weighted_char_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tasting_custom_weighted_char_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tasting_custom_weighted_char_index_query`, state.query);
    }

    private restoreFilter(ctx: StateContext<TastingCustomWeightedCharIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tasting_custom_weighted_char_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tasting_custom_weighted_char_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tasting_custom_weighted_char_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });
    }
}