import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBrowserModule } from '@core/browser';
import { CoreDataModule } from '@core/data';
import { AppMaterialModule } from '@core/material';
import { CoreUtilsModule } from '@core/utils';
import { NgxsModule } from '@ngxs/store';
import { AuthInterceptor } from './auth-interceptor';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthState } from './auth-state';
import { AuthGuard } from './auth.guard';
import { AuthLoginView } from './components/auth-login/auth-login.view';
import { AuthProfileFormDialog } from './components/auth-profile/auth-profile-form.dialog';
import { AuthProfileFormState } from './components/auth-profile/auth-profile-form.state';
import { OrgControlComponent } from './components/org-control/org-control.component';
import { OrganizationDetailState } from './components/organization-detail/organization-detail.state';
import { OrganizationDetailView } from './components/organization-detail/organization-detail.view';
import { OrganizationGroupFormDialog } from './components/organization-detail/organization-group-form.dialog';
import { OrganizationGroupSampleFormDialog } from './components/organization-detail/organization-group-sample-form.dialog';
import { OrganizationGroupSampleList } from './components/organization-detail/organization-group-sample-list.component';
import { OrganizationGroupProtocolFilterList } from './components/organization-detail/organization-group-protocol-filter-list.component';
import {OrganizationGroupProtocolFilterFormDialog} from './components/organization-detail/organization-group-protocol-filter-form.dialog';
import { OrganizationGroupUserFormDialog } from './components/organization-detail/organization-group-user-form.dialog';
import { OrganizationGroupUserList } from './components/organization-detail/organization-group-user-list.component';
import { GroupsState } from './components/organization-detail/groups.state';
import { OrganizationInviteFormDialog } from './components/organization-detail/organization-invite-form.dialog';
import { OrganizationInvitesState } from './components/organization-detail/organization-invites.state';
import { OrganizationMembersList as OrganizationMembersListComponent } from './components/organization-detail/organization-members-list.component';
import { OrganizationMembersState } from './components/organization-detail/organization-members.state';
import { OrganizationFormDialog } from './components/organization-form/organization-form.dialog';
import { OrganizationFormState } from './components/organization-form/organization-form.state';
import { OrganizationStatsState } from '@app/auth/components/organization-detail/organization-stats.state';
import { OrganizationStatsComponent } from '../auth/components/organization-detail/organization-stats.component';
import { NotificationState } from './notification-state';
import { LabelAccessPipe } from './pipes/label-access.pipe';
import { RouterLinkParserPipe } from './pipes/router-link-parser.pipe';
import { DescribeAddressPipe } from './pipes/label-address.pipe';
import { SnackbarState } from './snackbar-state';
import { OrganizationSubscriptionState } from '@app/admin/organization-detail/organization-subscription.state';
import { SubscriptionGuard } from './subscription.guard';

@NgModule({
    imports: [
        CommonModule,
        CoreUtilsModule,
        CoreDataModule,
        HttpClientModule,
        AppMaterialModule,
        AppBrowserModule,
        AuthRoutingModule,
        NgxsModule.forFeature([
            AuthState,
            NotificationState,
            AuthProfileFormState,
            GroupsState,
            OrganizationInvitesState,
            OrganizationMembersState,
            OrganizationDetailState,
            OrganizationFormState,
            OrganizationStatsState,
            AuthProfileFormState,
            SnackbarState,
            OrganizationSubscriptionState,
        ]),
    ],
    exports: [
        RouterModule,
        LabelAccessPipe,
        RouterLinkParserPipe,
        DescribeAddressPipe,
        OrgControlComponent,
        OrganizationGroupSampleList,
        OrganizationGroupProtocolFilterList,
        OrganizationGroupUserList,
        OrganizationMembersListComponent,
        OrganizationStatsComponent,
    ],
    declarations: [
        OrganizationDetailView,
        AuthLoginView,
        LabelAccessPipe,
        RouterLinkParserPipe,
        DescribeAddressPipe,
        OrgControlComponent,
        AuthProfileFormDialog,
        OrganizationGroupFormDialog,
        OrganizationGroupSampleFormDialog,
        OrganizationGroupSampleList,
        OrganizationGroupProtocolFilterList,
        OrganizationGroupProtocolFilterFormDialog,
        OrganizationGroupUserFormDialog,
        OrganizationGroupUserList,
        OrganizationInviteFormDialog,
        OrganizationFormDialog,
        OrganizationMembersListComponent,
        OrganizationStatsComponent,
    ],
    providers: [],
    entryComponents: [
        AuthProfileFormDialog,
        OrganizationGroupFormDialog,
        OrganizationGroupSampleFormDialog,
        OrganizationGroupProtocolFilterFormDialog,
        OrganizationGroupUserFormDialog,
        OrganizationInviteFormDialog,
        OrganizationFormDialog,
    ]
})
export class AppAuthModule {
    static forRoot(): ModuleWithProviders<AppAuthModule> {
        return {
            ngModule: AppAuthModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true
                },
                AuthGuard,
                SubscriptionGuard,
            ]
        };
    }
}
