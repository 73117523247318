import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseIndex, Index, Status } from "@core/data";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Dialog, Snackbar } from "@core/material";
import { coerseDateProperty } from "@core/utils";
import { Select, Store } from "@ngxs/store";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { InitNewTastingEventsForm, InitUpdateTastingEventsForm, SubmitTastingEventsForm, TastingEventsFormState, TastingEventsFormStateModel } from "./tasting-events-form.state";

export interface TastingEventsFormDialogData {
    key?: string;
    defaults?: Partial<TastingEvent>;
    indexOptions: Array<Index|BaseIndex>,
}

@Component({
    selector: 'pv-tasting-events-form-dialog',
    templateUrl: 'tasting-events-form.dialog.html',
    styleUrls: ['tasting-events-form.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false
})
export class TastingEventsFormDialog implements OnInit, OnDestroy {
    @Select(TastingEventsFormState)
    state$: Observable<TastingEventsFormStateModel>;

    @Select(TastingEventsFormState.data)
    data$: Observable<TastingEventsFormStateModel>;

    //FORM CONTROLS
    titleControl = new FormControl(null, [Validators.required, Validators.maxLength(64)]);
    descriptionControl = new FormControl(null, [Validators.maxLength(255)]);
    eventImageControl = new FormControl(null);
    //Primary venue
    venueNameControl = new FormControl(null, [Validators.maxLength(64)]);
    venueAddrStreetControl = new FormControl(null, [Validators.maxLength(64)]);
    venueAddrCityControl = new FormControl(null, [Validators.maxLength(32)]);
    venueAddrRegionControl = new FormControl(null, [Validators.maxLength(32)]);
    venueAddrCodeControl = new FormControl(null, [Validators.maxLength(16)]);
    //Alternative venue
    altVenueNameControl = new FormControl(null, [Validators.maxLength(64)]);
    altVenueAddrStreetControl = new FormControl(null, [Validators.maxLength(64)]);
    altVenueAddrCityControl = new FormControl(null, [Validators.maxLength(32)]);
    altVenueAddrRegionControl = new FormControl(null, [Validators.maxLength(32)]);
    altVenueAddrCodeControl = new FormControl(null, [Validators.maxLength(16)]);

    eventDateControl = new FormControl(null);
    eventStartTimeControl = new FormControl(null);
    eventEndTimeControl = new FormControl(null);
    publishDateControl = new FormControl(null);
    indexKeyControl = new FormControl(null, [Validators.required]);
    ownerOrgKeyControl = new FormControl(null);
    allowIndexUpdateControl = new FormControl(false);
    //FOMR CONTROLS END

    //FORM GROUP
    formGroup: FormGroup = new FormGroup({
        title: this.titleControl,
        description: this.descriptionControl,
        eventImage: this.eventImageControl,
        //Primary venue
        venueName: this.venueNameControl,
        venueAddrStreet: this.venueAddrStreetControl,
        venueAddrCity: this.venueAddrCityControl,
        venueAddrRegion: this.venueAddrRegionControl,
        venueAddrCode: this.venueAddrCodeControl,
        //Alternative venue
        altVenueName: this.altVenueNameControl,
        altVenueAddrStreet: this.altVenueAddrStreetControl,
        altVenueAddrCity: this.altVenueAddrCityControl,
        altVenueAddrRegion: this.altVenueAddrRegionControl,
        altVenueAddrCode: this.altVenueAddrCodeControl,

        eventDate: this.eventDateControl,
        eventStartTime: this.eventStartTimeControl,
        eventEndTime: this.eventEndTimeControl,
        publishDate: this.publishDateControl,

        indexKey: this.indexKeyControl,

        ownerOrgKey: this.ownerOrgKeyControl,

        allowIndexUpdates: this.allowIndexUpdateControl,
    })
    //FORM GROUP END

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingEventsFormDialog>,
        private _snackbar: Snackbar,
        private _dialog: Dialog,
        @Inject(MAT_DIALOG_DATA) public data: TastingEventsFormDialogData
    ) {}

    ngOnInit(): void {
        if (this.data.key) this._store.dispatch(new InitUpdateTastingEventsForm(this.data.key));
        else this._store.dispatch(new InitNewTastingEventsForm(this.data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.data);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });

        this.allowIndexUpdateControl.valueChanges
            .subscribe(allowed => {
                if (!this.data.key) return;

                if (allowed) this.formGroup.controls.indexKey.enable();
                else this.formGroup.controls.indexKey.disable();
            })
    }

    reset(model: Partial<TastingEvent>) {
        let data = {
            //Data
            title: model.title || null,
            description: model.description || null,
            eventImage: model.eventImageKey || null,
            //Primary venue
            venueName: model.venueName || null,
            venueAddrStreet: model.venueAddrStreet || null,
            venueAddrCity: model.venueAddrCity || null,
            venueAddrRegion: model.venueAddrRegion || null,
            venueAddrCode: model.venueAddrCode || null,
            //Alternative venue
            altVenueName: model.altVenueName || null,
            altVenueAddrStreet: model.altVenueAddrStreet || null,
            altVenueAddrCity: model.altVenueAddrCity || null,
            altVenueAddrRegion: model.altVenueAddrRegion || null,
            altVenueAddrCode: model.altVenueAddrCode || null,

            eventDate: model.eventDate || null,
            eventStartTime: this.handleDisplayTime(model.eventStartTime) || null,
            eventEndTime: this.handleDisplayTime(model.eventEndTime) || null,
            publishDate: model.publishDate || null,

            //Keys
            indexKey: model.indexSnapshot ? model.indexSnapshot.index.key : null,
            ownerOrgKey: model.ownerOrgKey,

            allowIndexUpdates: model.allowIndexUpdates,
        }

        this.formGroup.reset(data);
    }

    cancel() {
        this._dialogRef.close();
    }

    attempt() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save();
        } else {
            console.warn('TastingEventsDialog: form invalid', this.formGroup.value);
            this._snackbar.error("Invalid input. Check your input and try again.");
        }
    }

    save() {
        const form = this.formGroup.getRawValue();

        //cast form data to request data
        const data: Partial<TastingEvent> = {
            //Data
            title: form.title,
            description: form.description,
            //Primary venue
            venueName: form.venueName,
            venueAddrStreet: form.venueAddrStreet,
            venueAddrCity: form.venueAddrCity,
            venueAddrRegion: form.venueAddrRegion,
            venueAddrCode: form.venueAddrCode,
            //Alternative venue
            altVenueName: form.altVenueName,
            altVenueAddrStreet: form.altVenueAddrStreet,
            altVenueAddrCity: form.altVenueAddrCity,
            altVenueAddrRegion: form.altVenueAddrRegion,
            altVenueAddrCode: form.altVenueAddrCode,

            eventDate: coerseDateProperty(form.eventDate),
            eventStartTime: form.eventStartTime ? this.handleTime(form.eventDate, form.eventStartTime) : null,
            eventEndTime: form.eventEndTime ? this.handleTime(form.eventDate, form.eventEndTime) : null,
            publishDate: form.publishDate,
            //Key
            indexKey: form.indexKey,
            ownerOrgKey: form.ownerOrgKey,

            allowIndexUpdates: form.allowIndexUpdates ? true : false,
        }

        this._store.dispatch(new SubmitTastingEventsForm(data));
    }

    shouldShow(index: Index, indexOptions: Index[]) {
        if (index) {
            let indexKey = index.key;
            let indexOptionKeys = indexOptions.map(opt => opt.key);
            return !indexOptionKeys.includes(indexKey);
        }
    }

    private handleTime(eventDate, time) {
        let formatted = moment(eventDate).format("YYYY-MM-DD");
        return moment(formatted + " " + time, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm:ss");
    }

    private handleDisplayTime(value) {
        return moment(value).format("HH:mm");
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}