import { Pipe, PipeTransform } from '@angular/core';
import { ImageTransformOptions, parseFileRefType, convertImageFileRef } from '../file-ref';

@Pipe({
  	name: 'imageFileRef'
})
export class ImageFileRefPipe implements PipeTransform {

    constructor(){}

    transform(value: string, options?: ImageTransformOptions | string) {

        if(value === null || value === undefined) return value;

        let type = parseFileRefType(value);

        if(type === 'cloudinary'){

            if(typeof options === 'string'){
                return convertImageFileRef(value, { transformId: options, format: 'auto' });
            }else{
                return convertImageFileRef(value, options);
            }

        }

        return null;

    }
}