
export function parseFileRefType(fileRef: string){
    if(fileRef.startsWith('cloudinary:')) return 'cloudinary';
    if(fileRef.startsWith('android:')) return 'android';
    return 'unknown';
}

export function convertImageFileRef(fileRef: string, options?: ImageTransformOptions){
    if(fileRef.startsWith('cloudinary:')){
        const matches = fileRef.match(/cloudinary\:([a-z0-9\-]+)\/(.+)/i);

        const url = [
            'https://res.cloudinary.com',
            matches[1],
            'image/upload',
        ];
        const transformArr = [];
        let ext = 'jpg';
        if(options){
            if(options.width) transformArr.push('w_' + options.width);
            if(options.height) transformArr.push('h_' + options.height);
            if(options.mode) transformArr.push('c_' + options.mode);
            if(options.gravity) transformArr.push('g_' + options.gravity);
            if(options.quality) transformArr.push('q_' + options.quality);
            if(options.attachment) transformArr.push('fl_attachment');
            if(options.transformId) transformArr.push('t_' + options.transformId);
            if(options.format) {
                if(options.format === 'auto'){
                    transformArr.push('f_auto');
                    ext = null;
                }else{
                    ext = options.format;
                }
            }
            if(transformArr.length > 0) url.push(transformArr.join(','));
        }

        // url.push('v1');

        if(ext){
            url.push(matches[2] + '.' + ext);
        }else{
            url.push(matches[2]);
        }


        return url.join('/');
    }

    return fileRef;
}

export function convertDocumentFileRef(fileRef: string, ext?: string) {
    if(fileRef.startsWith('cloudinary:')){
        const matches = fileRef.match(/cloudinary\:([a-z0-9\-]+)\/(.+)/i);

        let url
        if (isImage(ext) || isPdf(ext)) {
            url = [
                'https://res.cloudinary.com',
                matches[1],
                'image/upload',
            ];
        } else if (isVideo(ext) || isAudio(ext)) {
            url = [
                'https://res.cloudinary.com',
                matches[1],
                'video/upload',
            ];
        } else {
            url = [
                'https://res.cloudinary.com',
                matches[1],
                'raw/upload',
            ];
        }


        url.push(matches[2]);
        return url.join('/');
    }

    return fileRef;
}

const fileTypes = {
    raw: ['xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'rtf', 'csv'],
    image: ['png', 'jpg', 'jpeg'],
    video: ['mp4', 'avi', 'mov', 'wmv', 'mkv'],
    audio: ['mp3', 'aac', 'flac', 'wav', 'mpga'],
    pdf: ['pdf'],
}

function isRaw(ext: string): boolean {
    return fileTypes.raw.includes(ext);
}

function isImage(extension: string): boolean {
    return fileTypes.image.includes(extension);
}

function isVideo(extension: string): boolean {
    return fileTypes.video.includes(extension);
}

function isAudio(extension: string): boolean {
    return fileTypes.audio.includes(extension);
}

function isPdf(extension: string): boolean {
    return fileTypes.pdf.includes(extension);
}
export interface ImageTransformOptions {
    width?: number;
    height?: number;
    mode?: string | 'fill' | 'fit' | 'scale' | 'crop';
    gravity?: string | 'center';
    quality?: number;
    format?: string | 'jpg' | 'png' | 'auto';
    attachment?: boolean;
    transformId?: string;
}