<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>{{ state.key ? 'Edit' : 'Add' }}&nbsp;Tag</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_custom_tag_form"
            #tastingCustomTagForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            novalidate
            autocomplete="off">

            <div class="form-column">
                <h4 class="form-subheader">Basic Info</h4>

                <mat-form-field appearance="standard">
                    <mat-label>Label</mat-label>
                    <input matInput formControlName="label" required>
                    <mat-hint>Label for the Tag</mat-hint>
                </mat-form-field>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingCustomTagForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>