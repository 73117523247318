<ng-container *ngIf="state$ | async as state">
    <h3 mat-dialog-title>Report Table Templates</h3>

    <div mat-dialog-content>
        <form class="pv-form" [formGroup]="formGroup">
            <div class="form-column">
                <mat-form-field appearance="standard">
                    <mat-label>Template Name</mat-label>
                    <input matInput type="text" required formControlName="name">
                    <mat-error>Template name is required</mat-error>
                </mat-form-field>

                <ng-container formGroupName="tableOptions">
                    <mat-form-field appearance="standard" floatLabel="always">
                        <mat-label>Base Template</mat-label>
                        <mat-select formControlName="baseTemplate" placeholder="None">
                            <mat-select-trigger>
                                {{ baseTemplateControl?.value?.name + ' Selected' }}
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="baseGroupsFilterCtrl"
                                                    placeholderLabel="Find Template..."
                                                    noEntriesFoundLabel="No matching entry found"
                                                    style="height: 556px !important; overflow-x: hidden;"
                                                    >
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-optgroup *ngFor="let group of filteredBaseGroups | async; trackBy: trackById" [label]="group.subject.label">
                                <mat-option *ngIf="!group.templates?.length" disabled>No Templates Available</mat-option>
                                <mat-option *ngFor="let template of group.templates; trackBy: trackById"
                                            (onSelectionChange)="updateSelectedTemplate($event, template)"
                                            [value]="template" (click)="$event.stopPropagation()">
                                    <div class="base-template-item-wrapper">
                                        <span class="base-template-title ">{{ template?.name }} {{ template?.tableOptions?.isAggregate ? '(Aggregate)' : ''}} {{ template?.tableOptions?.isSpread ? '(Spread)' : ''}}</span>
                                        <mat-icon [svgIcon]="'common:' + template?.tableOptions?.cropId"></mat-icon>
                                    </div>
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <ng-container formGroupName="tableOptions">
                    <mat-checkbox formControlName="isAggregate"  class="mat-caption"
                                  (change)="toggleBooleanControls('isAggregate')">
                        Aggregate Evaluations
                    </mat-checkbox>

                    <mat-checkbox formControlName="isSpread" class="mat-caption"
                                  (change)="toggleBooleanControls('isSpread')">
                        Spread Evaluations
                    </mat-checkbox>

                    <mat-checkbox formControlName="showSummary" class="mat-caption"
                                  (change)="toggleBooleanControls('showSummary')">
                        Show Sample Summary
                    </mat-checkbox>
                </ng-container>

                <mat-form-field appearance="standard" formGroupName="tableOptions">
                    <mat-label>Crop</mat-label>
                    <mat-select formControlName="cropId" required>
                        <mat-select-trigger>
                            <ng-container *ngIf="cropIdControl.value as cropId">
                                <mat-icon [svgIcon]="'common:' + cropId" class="mat-18 mr-12">
                                </mat-icon>
                                <span>{{ cropId | labelCrop }}</span>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option *ngFor="let crop of cropOptions"
                                    [value]="crop.id"
                                    (onSelectionChange)="updateCropSelection(crop, $event)">

                            <mat-icon [svgIcon]="'common:' + crop.id"></mat-icon>
                            <span>{{ crop.label }}</span>
                        </mat-option>
                    </mat-select>
                    <mat-error>Select a crop to continue</mat-error>
                </mat-form-field>
            </div>

            <div class="form-column">
                <mat-form-field formGroupName="tableOptions" appearance="standard" floatLabel="always">
                    <mat-label [ngStyle]="{'background': '#D3D3D3'}" >Properties</mat-label>
                    <mat-select formControlName="includeProperties" multiple placeholder="None">
                        <mat-select-trigger>
                            {{includePropertiesControl.value?.length + ' Selected' }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let property of propertyOptions; trackBy: trackById"
                                    [value]="property.id"
                                    (onSelectionChange)="updateTemplateOptions(tableReportColumnType.Property, $event)">
                            {{ property.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field formGroupName="tableOptions" appearance="standard" floatLabel="always">
                    <mat-label>Characteristics</mat-label>
                    <mat-select formControlName="includeChars" multiple placeholder="None" #multiSelect>
                    <mat-select-trigger>
                        {{ includeCharsControl.value?.length + ' Selected' }}
                    </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search [formControl]="charGroupsFilterCtrl"
                                               placeholderLabel="Find Characteristic..."
                                               noEntriesFoundLabel="No matching entry found"
                                               style="height: 556px !important; overflow-x: hidden;"
                                               >
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-optgroup *ngFor="let group of filteredCharGroups | async; trackBy: trackById" [label]="group.category">
                        <mat-option *ngFor="let char of group.chars; trackBy: trackById"
                                    (onSelectionChange)="updateTemplateOptions(tableReportColumnType.Characteristic, $event)"
                                    [value]="char.id" (click)="$event.stopPropagation()">
                          {{char.label}}
                        </mat-option>
                      </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <mat-form-field formGroupName="tableOptions" appearance="standard" floatLabel="always">
                    <mat-label>Measurements</mat-label>
                    <mat-select formControlName="includeMeasures" multiple placeholder="None" #multiSelect>
                        <mat-select-trigger>
                            {{ includeMeasuresControl.value?.length + ' Selected' }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="measGroupsFilterCtrl"
                                                   placeholderLabel="Find Measurement..."
                                                   noEntriesFoundLabel="No matching entry found"
                                                   style="height: 556px !important; overflow-x: hidden;"
                                                  >
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-optgroup *ngFor="let group of filteredMeasGroups | async; trackBy: trackById" [label]="group.subject">
                            <mat-option *ngFor="let meas of group.measures; trackBy: trackById"
                                        (onSelectionChange)="updateTemplateOptions(tableReportColumnType.Measurement, $event)"
                                        [value]="meas.id" (click)="$event.stopPropagation()">
                              {{meas.label }} [{{meas.unit}}]
                            </mat-option>
                          </mat-optgroup>
                    </mat-select>
                </mat-form-field>

                <mat-form-field formGroupName="tableOptions" appearance="standard" floatLabel="always">
                    <mat-label>Indexes</mat-label>
                    <mat-select formControlName="includeIndexes" multiple placeholder="None">
                        <mat-select-trigger>
                            {{ includeIndexesControl.value?.length + ' Selected' }}
                        </mat-select-trigger>
                        <mat-option *ngFor="let index of indexOptions; trackBy: trackById"
                                    [value]="index.id"
                                    (onSelectionChange)="updateTemplateOptions(tableReportColumnType.Index, $event)">
                            {{ index.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field formGroupName="tableOptions" appearance="standard" floatLabel="always">
                    <mat-label>Calculations</mat-label>
                    <mat-select formControlName="includeCalcs" multiple placeholder="None" #multiSelect>
                        <mat-select-trigger>
                            {{ includeCalcsControl.value?.length + ' Selected' }}
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search [formControl]="calcGroupsFilterCtrl"
                                                   placeholderLabel="Find Calculation..."
                                                   noEntriesFoundLabel="No matching entry found"
                                                   style="height: 556px !important; overflow-x: hidden;"
                                                   >
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-optgroup *ngFor="let group of filteredCalcsGroups | async; trackBy: trackById" [label]="group.subject">
                            <mat-option *ngFor="let calc of group.calculations; trackBy: trackById"
                                        (onSelectionChange)="updateTemplateOptions(tableReportColumnType.Calculation, $event)"
                                        [value]="calc.id" (click)="$event.stopPropagation()">
                              {{calc.label }} [{{calc.unit}}]
                            </mat-option>
                          </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-column">
                <h4>Selected Columns</h4>
                <div *ngIf="!currentSelection.length">
                    <h5>No Selected Columns</h5>
                </div>
                <div *ngIf="currentSelection.length">
                    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let col of currentSelection" cdkDrag
                                [ngStyle]="{'background': col.type == 'p' ? '#D3D3D3' : 'white' }">
                            {{col.id ? (col | descTableReportColumn) : 'No Selected Columns'}}
                        </div>
                        <div ></div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary" (click)="attempt()">Save</button>
    </mat-dialog-actions>
</ng-container>