<ng-container>
	<!-- Title -->
	<div mat-dialog-title class="mat-dialog-header">
		<h2 class="dialog-title">Add Sample To Group</h2>
	</div>
	<div mat-dialog-content>
		<!-- Form -->
		<form #orgMemberForm="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()">
			<!-- Sample -->
			<mat-form-field *ngIf="samples$ | async as samples; else sampleLoader">
				<mat-label>Sample</mat-label>
				<mat-select formControlName="sampleKey">
					<mat-option *ngFor="let sample of samples" [value]="sample.key">{{ sample.label }} &lt;{{ sample.code }}&gt;
					</mat-option>
				</mat-select>
				<mat-error>Select a sample</mat-error>
			</mat-form-field>
			<ng-template #sampleLoader>
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</ng-template>
		</form>
	</div>
	<!-- Actions -->
	<pv-form-actions mat-dialog-actions [form]="orgMemberForm" (cancel)="cancel()">
	</pv-form-actions>
</ng-container>
