import { Injectable } from '@angular/core';

@Injectable()
export class WindowRef {
    getNativeWindow(): Window { return window; }

    navigateUrl(url: string, blank = true) {
        this.getNativeWindow().open(url, blank ? '_blank' : null);
    }

}

