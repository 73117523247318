import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Evaluation, NotesUpdateRequest, SampleNote, Status } from '@core/data';
import { Snackbar } from '@core/material';
import { coerceMomentProperty, coerceTimestampProperty } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InitNotesForm, NotesFormState, NotesFormStateModel, SubmitNotesForm } from './notes-form.state';


export interface NotesFormDialogData {
    evalKey: string;
}

@Component({
    selector: 'pv-notes-form-dialog',
    templateUrl: 'notes-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-notes-form-dialog pv-fullscreen-dialog'
    }
})
export class NotesFormDialog implements OnInit, OnDestroy {

    @Select(NotesFormState)
    state$: Observable<NotesFormStateModel>;

    @Select(NotesFormState.data)
    data$: Observable<Evaluation>;

    formArray = new FormArray([]);
    formGroup = new FormGroup({
        // noop control to prevent formgroup being
        // disabled if all image controls is disabled
        _noop: new FormControl(true),
        notes: this.formArray,
    });

    private _destroy$ = new Subject();

    constructor(
        private _dialogRef: MatDialogRef<NotesFormDialog>,
        private _store: Store,
        private _snackbar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: NotesFormDialogData
    ) { }

    ngOnInit() {

        this._store.dispatch(new InitNotesForm(this.data.evalKey));

        this.state$
            .pipe(takeUntil(this._destroy$))
            .subscribe(state => {

                if(state.status === Status.COMPLETE){
                    this._snackbar.info("Notes updated");
                    this.cancel();
                }else if(state.status !== Status.LOADING){
                    this.formGroup.enable({onlySelf: true});
                }else{
                    this.formGroup.disable({onlySelf: true});
                }

            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.buildForm(data);
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    attempt() {

        this.formGroup.updateValueAndValidity();

        if(!this.formGroup.valid){
            this._snackbar.error("One or more notes are invlid. Check your input and try again.");
            return;
        }

        let request = this.getUpdateRequest();

        if(request.notes.length > 0){
            this._store.dispatch(new SubmitNotesForm(request));
        }else{
            this._snackbar.info("No changes to notes.");
            this._dialogRef.close();
        }

    }

    cancel() {
        this._dialogRef.close();
    }

    addNote() {
        this.formArray.push(this.makeNoteGroup({}));
    }

    deleteNote(index: number) {
        let group = <FormGroup>this.formArray.at(index);

        if (group) {

            if (group.disabled) {
                group.enable();
            } else if(group.get('key').value === null) {
                this.formArray.removeAt(index);
            }else{
                group.disable();
            }


        }

    }

    private getUpdateRequest(): NotesUpdateRequest {

        let noteUpdates: Array<Partial<SampleNote>> = [];

        let value: Partial<SampleNote>;

        this.formArray.controls.forEach(control => {

            value = control.value;

            if(control.disabled){
                // disabled indicates delete

                if(!value.key) return;

                noteUpdates.push({
                    key: value.key,
                    deletedAt: coerceTimestampProperty(moment())
                });

            }else if(control.dirty){

                noteUpdates.push({
                    key: value.key,
                    message: value.message,
                    takenAt: coerceTimestampProperty(value.takenAt)
                });

            }

        });


        return {
            notes: noteUpdates
        };
    }

    private buildForm(model: Evaluation) {

        this.formArray.clear();
        this.formGroup.reset({ notes: [] });

        model.notes.forEach(note => {

            let control = this.makeNoteGroup(note);
            this.formArray.push(control);

        });


    }

    private makeNoteGroup(note: Partial<SampleNote>) {
        return new FormGroup({
            key: new FormControl(note.key || null),
            message: new FormControl(note.message || null, [Validators.required, Validators.maxLength(10000), Validators.minLength(2)]),
            takenAt: new FormControl(coerceMomentProperty(note.takenAt, moment()), [Validators.required]),
        });
    }

}
