import { Protocol } from './_types';

export const LIB_PROTOCOL_MACADAMIA_HARVEST_SCREEN_EVAL: Protocol = {
    id: "macadamia_harvest_screen_eval",
    title: "Advanced Macadamia Harvest Evaluation",
    description: "Harvest evaluation is performed to characterise new cultivars and selections on the base of their fruit traits. Visual and sensory fruit evaluation are applied to describe bunch and berry quality and keeping ability after storage over a specified storage period. Characteristics such as appearance, defects and disorders and eating quality are evaluated in detail.",
    sampleType: "harvest",
    crop: "macadamia",

    chars: [                    
        "nut_husk_texture",    
        "nut_hirsute",             
        "nut_length",
        "nut_size",
        "nut_shape",
        "nut_dehusk",
        "nut_husk_colour",

        "nut_shell_texture",
        "nut_shell_micropyle",
        "nut_shell_suture",
        "nut_shell_adhesion",
        "nut_shell_markings",
        "nut_shell_markings_type",
        "nut_shell_size",
        "nut_shell_consistent",
        "nut_shell_thick",
        "nut_shell_shape",
        "nut_shell_shape_consistent",
        "nut_shell_colour",
        "nut_shell_disorders",

        "nut_kernel_size",
        "nut_kernel_size_consistent",
        "nut_kernel_symmetry",
        "nut_kernel_shape_consistent",
        "nut_kernel_colour",
        "nut_kernel_colour_uniformity",
        "nut_kernel_blemishes",
        "nut_kernel_diseases",
        "nut_kernel_disorders",
        "nut_kernel_insect",

        "nut_kernel_roasting",
        "nut_kernel_sweetness",
        "nut_kernel_aroma",
        "nut_kernel_firmness",
        "nut_kernel_crisp",
        "nut_kernel_crunch",
        "nut_kernel_oily",
        "nut_kernel_rancidity",

        "nut_kernel_sweetness_after",
        "nut_kernel_aroma_after",
        "nut_kernel_firmness_after",
        "nut_kernel_crisp_after",
        "nut_kernel_crunch_after",
        "nut_kernel_oily_after",
        "nut_rancidity_after",

        "nut_overall_appear",
        "nut_overall_roast",
        "nut_overall_keeping",
        "nut_overall_taste",
        "nut_overall_interest",
    ],

    measures: [
        "nut_husk_weight",

        "nut_in_husk_weight",
        "nut_in_husk_height",
        "nut_in_husk_diameter",
        "nut_in_husk_pedi_len", 
        "nut_in_husk_ease_dehusk", 

        "nut_in_shell_weight",
        "nut_in_shell_height",
        "nut_in_shell_diameter",
        "nut_in_shell_thickness",
        "nut_in_shell_breakage",
        "nut_in_shell_doubles",
        "nut_in_shell_weight_dry",

        "nut_kernel_height",
        "nut_kernel_diameter",
        "nut_kernel_rancidity",
        "nut_kernel_total_oil",
        
        "nut_kernel_fatty_myristic",
        "nut_kernel_fatty_palmitic",
        "nut_kernel_fatty_palmitoleic",
        "nut_kernel_fatty_stearic",
        "nut_kernel_fatty_cis_oleic",
        "nut_kernel_fatty_cis_linoleic",
        "nut_kernel_fatty_n3_linoleic",
        "nut_kernel_fatty_arachidic",
        "nut_kernel_fatty_eicosanoic",
        "nut_kernel_fatty_eicosadienoic",
        "nut_kernel_fatty_behenic",
        "nut_kernel_fatty_erucic",
        "nut_kernel_fatty_lignoceric",
        "nut_kernel_fatty_nervonic",

        "nut_kernel_total_weight",
        "nut_kernel_total_recovery_weight",
        "nut_kernel_first_recovery_weight",
        "nut_kernel_recovery_perc",
        "nut_kernel_half_recovery_perc",
        "nut_kernel_broken_perc",

        "nut_kernel_mould_weight",
        "nut_kernel_791_weight",
        "nut_kernel_pregerm_weight",
        "nut_kernel_immat_weight",
        "nut_kernel_misshap_weight",
        "nut_kernel_sponginess_weight",
        "nut_kernel_hollow_weight",
        "nut_kernel_decom_weight",
        "nut_kernel_adhered_weight",
        "nut_kernel_insect_weight",
        "nut_kernel_discolor_weight",
        "nut_kernel_dark_weight",
        "nut_kernel_internal_weight",
        "nut_kernel_onion_weight",
        "nut_kernel_distal_weight",
        "nut_kernel_phys_weight",

    ],

    indexes: [
        "indx_nut_overall",
    ],

    calculations: [
        //Kernel Calcs
        "nut_totalHuskToNISRatio_calc",
        "nut_totalHuskToNIHRatio_calc",
        "nut_totalTKR_calc",
        "nut_totalUSRK_calc",
        "nut_totalUSRK2_calc",
        "nut_totalUWKR_calc",
        "nut_totalSKR_calc",
        "nut_totalSKR2_calc",

        //Unsound Calcs (TKR)
        "nut_kernel_mould_weight_TKR_calc",
        "nut_kernel_791_weight_TKR_calc",
        "nut_kernel_pregerm_weight_TKR_calc",
        "nut_kernel_immat_weight_TKR_calc",
        "nut_kernel_misshap_weight_TKR_calc",
        "nut_kernel_sponginess_weight_TKR_calc",
        "nut_kernel_hollow_weight_TKR_calc",
        "nut_kernel_decom_weight_TKR_calc",
        "nut_kernel_adhered_weight_TKR_calc",
        "nut_kernel_insect_weight_TKR_calc",
        "nut_kernel_discolor_weight_TKR_calc",
        "nut_kernel_dark_weight_TKR_calc",
        "nut_kernel_internal_weight_TKR_calc",
        "nut_kernel_onion_weight_TKR_calc",
        "nut_kernel_distal_weight_TKR_calc",
        "nut_kernel_phys_weight_TKR_calc",

        //Unsound Calcs (USKR)
        "nut_kernel_mould_weight_USKR_calc",
        "nut_kernel_791_weight_USKR_calc",
        "nut_kernel_pregerm_weight_USKR_calc",
        "nut_kernel_immat_weight_USKR_calc",
        "nut_kernel_misshap_weight_USKR_calc",
        "nut_kernel_sponginess_weight_USKR_calc",
        "nut_kernel_hollow_weight_USKR_calc",
        "nut_kernel_decom_weight_USKR_calc",
        "nut_kernel_adhered_weight_USKR_calc",
        "nut_kernel_insect_weight_USKR_calc",
        "nut_kernel_discolor_weight_USKR_calc",
        "nut_kernel_dark_weight_USKR_calc",
        "nut_kernel_internal_weight_USKR_calc",
        "nut_kernel_onion_weight_USKR_calc",
        "nut_kernel_distal_weight_USKR_calc",
        "nut_kernel_phys_weight_USKR_calc",
    ],
}
