import { DEFAULTS, TastingsEventIndexStateModel } from "./tasting-events-index.state-model";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { BaseIndex, BaseIndexService, Collection, CultivarService, CultivarUpdated, DetailRequest, Filter, FilterBuilder, FilterQueryMode, Index, IndexService, patchArrayItem, replaceArrayItem, SiteUpdated, Status, TastingEventCreated, TastingEventDeleted, TastingEventService, TastingEventUpdated, TastingSampleUpdated, TastingsEvaluationsService, TastingsIndexCreated, TastingsIndexDeleted, TastingsIndexUpdated, translateCommonErrorStatus } from "@core/data";
import { LocalStorage } from "@core/browser";
import { ClearTastingsEventIndexFilter, DeleteTastingsEvent, DownloadTastingEventImportTemplate, DownloadTastingsEvaluationReImportTemplate, DownloadTastingsEventManifest, ExportTastingEventResults, ExportTastingsEventIndex, ImportTastingEventIndex, InitTastingsEventIndex, LoadTastingsEventIndex, MarkTastingsEventComplete, PageTastingsEventIndex, PushTastingsEventIndexModelQuery, QueryTastingsEventIndex, SetTastingsEventIndexSelected, SortTastingsEventIndex } from "./tasting-events-index.state-actions";
import { catchError, tap } from "rxjs/operators";
import { uuid } from "@core/utils";
import { Observable, forkJoin, of } from "rxjs";
import * as moment from "moment";
import { Injectable } from "@angular/core";

@State({
    name: 'tastings_events_index',
    defaults: DEFAULTS,
})
@Injectable()
export class TastingsEventsIndexState {

    @Selector()
    static sort(state: TastingsEventIndexStateModel) {
        return state.sort;
    }

    @Selector()
    static query(state: TastingsEventIndexStateModel) {
        return state.query;
    }

    @Selector()
    static latestExport(state: TastingsEventIndexStateModel) {
        return state.exports.length ? state.exports[state.exports.length - 1] : null;
    }

    constructor(
        private _tastingsEventService: TastingEventService,
        private _cultivarService: CultivarService,
        private _indexService: IndexService,
        private _baseIndexService: BaseIndexService,
        private _storage: LocalStorage,
        private _tastingEvaluationService: TastingsEvaluationsService,
    ) {}

    @Action(InitTastingsEventIndex)
    initTastingsEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: InitTastingsEventIndex) {
        const state = ctx.getState();
        if (state.orgKey === action.orgKey && state.status !== Status.UNINITIALIZED) {
            return;
        }

        //const labelOptions = action.labelOptions

        ctx.setState({
            ...DEFAULTS,
            orgKey: action.orgKey,
            status: Status.OK
        })

        this.restoreFilter(ctx);
        return forkJoin(
            this.loadLicenseeOptions(ctx),
            this.compileIndexOptions(ctx),
            ctx.dispatch(new LoadTastingsEventIndex())
        );
    }

    @Action(LoadTastingsEventIndex, {cancelUncompleted: true})
    loadTastingsEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: LoadTastingsEventIndex) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                /*'scionCultivar',
                'site',
                'primaryImage',
                'harvestSample',
                'maturityIndexEval'*/
                'tastingsSamples',
                'tastingsSamples.harvestSample',
                'tastingsEventImage',
                'contacts',
                'indexSnapshot',
            ],
            counts: [
                //no counts
            ]
        };

        return this._tastingsEventService
            .query(this.getCurrentFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total > 0) {
                            this.storeFilter(ctx);
                            ctx.patchState({
                                status: Status.OK,
                                data: result.data,
                                total: result.total
                            });
                        } else {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0
                            });
                        }
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(DownloadTastingsEventManifest, {cancelUncompleted: true})
    downloadTastingsEventManifest(ctx: StateContext<TastingsEventIndexStateModel>, action : DownloadTastingsEventManifest) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        return this._tastingsEventService
            .manifest(action.key)
            .pipe(
                tap(
                    result => {
                       console.log(result);
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err),
                            data: [],
                            total: 0
                        });
                    }
                )
            );
    }

    @Action(MarkTastingsEventComplete, {cancelUncompleted: true})
    markTastingsEventComplete(ctx: StateContext<TastingsEventIndexStateModel>, action : MarkTastingsEventComplete) {
        const state = ctx.getState();

        ctx.patchState({
            status: Status.LOADING
        });

        return this._tastingsEventService
            .complete(action.key, { ...action.data })
            .pipe(
                tap(
                    result => {
                        ctx.dispatch(new LoadTastingsEventIndex()); //Reload tastings event index after state update
                    },
                    err => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(err)
                        });
                    }
                )
            );
    }

    @Action(PageTastingsEventIndex)
    pageTastingsEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: PageTastingsEventIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });

        return ctx.dispatch(new LoadTastingsEventIndex);
    }

    @Action(SortTastingsEventIndex)
    sortTastingsEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: SortTastingsEventIndex) {

        const state = ctx.getState();

        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingsEventIndex);
    }

    @Action(QueryTastingsEventIndex)
    queryTastingsEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: QueryTastingsEventIndex) {

        const state = ctx.getState();

        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });

        return ctx.dispatch(new LoadTastingsEventIndex);

    }

    @Action(DeleteTastingsEvent)
    deleteTastingEvent(ctx: StateContext<TastingsEventIndexStateModel>, action: DeleteTastingsEvent) {
        return this._tastingsEventService.delete(action.key);
    }

    @Action(PushTastingsEventIndexModelQuery)
    pushTastingsEventIndexModelQuery(ctx: StateContext<TastingsEventIndexStateModel>, action: PushTastingsEventIndexModelQuery) {

        const state = ctx.getState();
        let update;

        switch (action.modelType) {
            case 'scion':
                update = { scionCultivars: patchArrayItem(state.query.scionCultivars, action.model) };
                break;
            case 'site':
                update = { sites: patchArrayItem(state.query.sites, action.model) };
                break;
        }


        ctx.dispatch(new QueryTastingsEventIndex({
            ...state.query,
            ...update
        }));

    }

    @Action(ClearTastingsEventIndexFilter)
    clearTastingsEventIndexFilter(ctx: StateContext<TastingsEventIndexStateModel>, action: ClearTastingsEventIndexFilter) {

        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });

        return ctx.dispatch(new LoadTastingsEventIndex);

    }

    @Action(ExportTastingsEventIndex)
    exportTastingEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: ExportTastingsEventIndex) {

        const state = ctx.getState();

        const key = uuid();

        ctx.patchState({
            exports: [{
                key,
                status: Status.LOADING,
            }, ...state.exports]
        });

        let filter: Filter;

        if (state.selectedKeys.length > 0) {
            filter = (new FilterBuilder())
                .setSort('updatedAt', 'desc')
                .setQuery('ownerOrgKey', state.orgKey)
                .setQuery('key', state.selectedKeys, FilterQueryMode.IN)
                .get();
        } else {
            filter = this.getCurrentFilter(state);
        }

        return this._tastingsEventService.export(filter, state.orgKey, action.type)
            .pipe(
                tap(
                    result => {

                        let exp = {
                            key,
                            status: Status.COMPLETE
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    },
                    error => {

                        let exp = {
                            key,
                            status: translateCommonErrorStatus(error),
                            url: null,
                            filename: null
                        };

                        ctx.patchState({
                            exports: patchArrayItem(
                                ctx.getState().exports,
                                exp
                            )
                        });
                    }
                )
            );
    }

    @Action(ExportTastingEventResults)
    exportTastingEventResults(ctx: StateContext<TastingsEventIndexStateModel>, action: ExportTastingEventResults) {
        const state = ctx.getState();

        return this._tastingsEventService.exportResults(action.key)
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = `${action.event.title}_export_${moment()}`;
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn('TastingEventsResultsExport: Export Error ', error);

                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(DownloadTastingEventImportTemplate)
    downloadTastingEventImportTemplate(ctx: StateContext<TastingsEventIndexStateModel>, action: DownloadTastingEventImportTemplate) {
        return this._tastingsEventService.downloadImportTemplate()
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = "tasting-event-import-template";
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn("TastingEventIndexState: Download Error ", error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(ImportTastingEventIndex)
    importTastingEventIndex(ctx: StateContext<TastingsEventIndexStateModel>, action: ImportTastingEventIndex) {
        const state = ctx.getState();
        return this._tastingsEventService.import(state.orgKey, action.file)
            .pipe(
                tap(
                    result => {
                        // No Action needed
                    },
                    error => {
                        console.warn('TastingEventIndexState: Upload Error ', error);

                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(DownloadTastingsEvaluationReImportTemplate)
    downloadTastingsEvaluationReImportTemplate(ctx: StateContext<TastingsEventIndexStateModel>, action: DownloadTastingsEvaluationReImportTemplate) {

        return this._tastingEvaluationService.downloadReimportTemplate(action.eventKey)
            .pipe(
                tap(
                    result => {
                        const url = window.URL.createObjectURL(result);
                        let anchor = document.createElement('a');
                        anchor.download = `${action.eventName}-reimport-template`;
                        anchor.href = url;
                        anchor.click();
                    },
                    error => {
                        console.warn("TastingsEvaluationImport: Download Error ", error);
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                        });
                    }
                )
            );
    }

    @Action(SetTastingsEventIndexSelected)
    setTastingEventIndexSelected(ctx: StateContext<TastingsEventIndexStateModel>, action: SetTastingsEventIndexSelected) {
        const state = ctx.getState();

        const selectedData = action.keys.map(key => {
            let tastingSample = state.data.find(s => s.key === key);
            if (tastingSample) return tastingSample;
            return state.selectedData.find(s => s.key === key);
        });

        ctx.patchState({
            selectedKeys: action.keys,
            selectedData
        });
    }

    @Action(TastingEventUpdated)
    onTastingEventUpdated(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingEventUpdated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingsEventIndex);
    }

    @Action(TastingEventCreated)
    onTastingEventCreated(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingEventCreated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingsEventIndex);
    }

    @Action(TastingEventDeleted)
    onTastingEventDeleted(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingEventDeleted) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingsEventIndex);
    }


    @Action(CultivarUpdated)
    onCultivarUpdated(ctx: StateContext<TastingsEventIndexStateModel>, action: CultivarUpdated) {

        const state = ctx.getState();
        if (state.status === Status.UNINITIALIZED) return;

        ctx.patchState({
            query: {
                ...state.query,
                scionCultivars: replaceArrayItem(state.query.scionCultivars, action.cultivar)
            }
        });

        return forkJoin(
            this.loadLicenseeOptions(ctx),
            ctx.dispatch(new LoadTastingsEventIndex)
        );
    }

    @Action(SiteUpdated)
    onSiteUpdated(ctx: StateContext<TastingsEventIndexStateModel>, action: SiteUpdated) {
        const state = ctx.getState();
        if (state.status === Status.UNINITIALIZED) return;

        ctx.patchState({
            query: {
                ...state.query,
                sites: replaceArrayItem(state.query.sites, action.site),
            }
        });

        return ctx.dispatch(new LoadTastingsEventIndex);
    }

    @Action(TastingSampleUpdated)
    onTastingSampleUpdated(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingSampleUpdated) {
        const state = ctx.getState();
        if(![Status.OK, Status.EMPTY].includes(state.status)) return;
        return ctx.dispatch(new LoadTastingsEventIndex);
    }

    @Action(TastingsIndexCreated)
    onTastingsIndexCreated(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingsIndexCreated) {
        return this.compileIndexOptions(ctx);
    }

    @Action(TastingsIndexUpdated)
    onTastingsIndexUpdated(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingsIndexUpdated) {
        return this.compileIndexOptions(ctx);
    }

    @Action(TastingsIndexDeleted)
    onTastingsIndexDeleted(ctx: StateContext<TastingsEventIndexStateModel>, action: TastingsIndexDeleted) {
        return this.compileIndexOptions(ctx);
    }

    private getCurrentFilter(state: TastingsEventIndexStateModel): Filter {

        const fb = new FilterBuilder();

        fb.setPage(state.page);
        fb.setSort(state.sort);

        if (state.orgKey) fb.setQuery('ownerOrgKey', state.orgKey);

        if (Array.isArray(state.query.scionCultivars) && state.query.scionCultivars.length > 0) {
            let keys = state.query.scionCultivars.map(i => i.key);
            fb.setQuery('scionCultivarKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.sites) && state.query.sites.length > 0) {
            let keys = state.query.sites.map(i => i.key);
            fb.setQuery('siteKey', keys, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.scionCropId) && state.query.scionCropId.length > 0) {
            fb.setQuery('scionCropId', state.query.scionCropId, FilterQueryMode.IN);
        }

        if (Array.isArray(state.query.scionLicensee) && state.query.scionLicensee.length > 0) {
            fb.setQuery('scionLicensee', state.query.scionLicensee, FilterQueryMode.IN);
        }

        if(Array.isArray(state.query.tastingsSamples) && state.query.tastingsSamples.length > 0) {
            let keys = state.query.tastingsSamples.map(i => i.key);
            fb.setQuery('tastingsSamples', keys, FilterQueryMode.IN);
        }

        if (state.query.search) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<TastingsEventIndexStateModel>) {

        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_tastings_event_index_page`, state.page);
        this._storage.set(`${state.orgKey}_tastings_event_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_tastings_event_index_query`, state.query);

    }

    private restoreFilter(ctx: StateContext<TastingsEventIndexStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_tastings_event_index_page`);
        const sort = this._storage.get(`${state.orgKey}_tastings_event_index_sort`);
        const query = this._storage.get(`${state.orgKey}_tastings_event_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });

    }

    private loadLicenseeOptions(ctx: StateContext<TastingsEventIndexStateModel>) {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKey', state.orgKey)
            .get();

        return this._cultivarService.groupLicensees(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        licenseeOptions: result.group,
                    });
                }),
                catchError(e => {
                    console.warn("Error loading cultivar licensees", e);
                    ctx.patchState({
                        licenseeOptions: [],
                    });
                    return of(null);
                })
            );
    }

    private loadIndexOptions(ctx: StateContext<TastingsEventIndexStateModel>): Observable<Collection<Index>> {
        const state = ctx.getState();

        const filter = (new FilterBuilder)
            .setQuery('ownerOrgKeyCust', state.orgKey)
            .get();

        return this._indexService.query(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        indexes: result.data,
                    });
                }),
                catchError(e => {
                    console.warn('Error loading index options', e);
                    ctx.patchState({
                        indexes: []
                    });
                    return of(null);
                })
            );
    }

    private loadBaseIndexOptions(ctx: StateContext<TastingsEventIndexStateModel>): Observable<Collection<BaseIndex>> {
        const filter = (new FilterBuilder).get();

        return this._baseIndexService.query(filter)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        baseIndexes: result.data,
                    });
                }),
                catchError(e => {
                    console.warn('Error loading base index options', e);
                    ctx.patchState({
                        baseIndexes: []
                    });
                    return of(null);
                })
            );
    }

    private compileIndexOptions(ctx: StateContext<TastingsEventIndexStateModel>) {
        let res = forkJoin(
            this.loadBaseIndexOptions(ctx),
            this.loadIndexOptions(ctx),
        )

        res.subscribe(results => {
            let array: Array<Index| BaseIndex> = [];
            results.map(res => {
                array.push(...res.data);
            });

            ctx.patchState({
                indexOptions: array
            });
        });

        return res;
    }
}