<ng-container *ngIf="state$ | async as state">

    <!-- DIALOG TITLE -->
    <h2 mat-dialog-title>

        <!-- ICON SWITCH -->
        <ng-container [ngSwitch]="state.type">

            <!-- Cultivar -->
            <mat-icon *ngSwitchCase="'cultivar'" svgIcon="common:flat_scion"></mat-icon>

            <!-- Site -->
            <mat-icon *ngSwitchCase="'site'">place</mat-icon>

        </ng-container>
        {{ state.type | titlecase }} Select

    </h2>

    <!-- FORM FIELD -->
    <mat-form-field>

        <!-- LABEL -->
        <mat-label>Search {{ state.type | titlecase }}</mat-label>

        <!-- SEARCH ICON -->
        <mat-icon matPrefix class="mr-12">search</mat-icon>

        <!-- ICONS SUFFIX -->

            <!-- LOADING -->
            <mat-spinner matSuffix *ngIf="state.status === 'LOADING'" diameter="20" color="accent"></mat-spinner>

            <!-- ERROR -->
            <mat-icon matSuffix *ngIf="state.status === 'ERROR'">error</mat-icon>

        <!-- CONTROL -->
        <input type="text" matInput [formControl]="searchControl" autofocus>

        <!-- HINT -->
        <mat-hint mat-line [innerHTML]="modelHint(state.type)"></mat-hint>

    </mat-form-field>

    <!-- CONTENT -->
    <mat-nav-list mat-dialog-content dense>

        <!-- LOADING -->
        <mat-list-item *ngIf="state.status === 'LOADING'">
            Searching for {{ state.type | titlecase }}s...
        </mat-list-item>

        <!-- EMPTY -->
        <mat-list-item *ngIf="state.status === 'EMPTY'">
            No {{ state.type | titlecase }}s match your search.
        </mat-list-item>

        <!-- MODELS -->
        <mat-list-item *ngFor="let model of state.data" (click)="select(model)" [ngSwitch]="state.type">

            <!-- CULTIVAR -->
            <ng-container *ngSwitchCase="'cultivar'">
                <mat-icon mat-list-icon [svgIcon]="model.isRootstock ? 'common:flat_rootstock' : 'common:flat_scion'"></mat-icon>
                <mat-icon mat-list-icon class="mat-24" [svgIcon]="'common:' + model.cropId"></mat-icon>
                <span mat-line>{{ model.commonName }}</span>
            </ng-container>

            <!-- SITE -->
            <ng-container *ngSwitchCase="'site'">
                <mat-icon mat-list-icon>place</mat-icon>
                <span mat-line style="max-width: 250px;">{{ model.name }}</span>
            </ng-container>

        </mat-list-item>

        <!-- ERROR -->
        <mat-list-item *ngIf="state.status === 'ERROR'">
            An Error Occurred While Retrieving Data
        </mat-list-item>

    </mat-nav-list>

    <!-- ACTIONS -->
    <div mat-dialog-actions align="end">

        <!-- CANCEL -->
        <button (click)="cancel()" mat-raised-button color="accent" tabindex="3">Cancel</button>

        <!-- REFRESH -->
        <button *ngIf="state.status === 'ERROR'" (click)="refresh()" mat-button tabindex="5">Refresh</button>

    </div>
</ng-container>
