import { Characteristic, CharacteristicType } from './_types';

export const LIB_CHARS_BERRY_HARVEST: Characteristic[] = [

    // HARVEST

    {
        id: "blueberry_defects",
        type: CharacteristicType.Nominal,
        categoryId: "blueberry_disorders", 
        label: "Defects",
        params: {
            options: [
                {value: "shriv", label: "Shrivelled" },
                {value: "coll", label: "Collapsed/Soft berries" },
                {value: "decay", label: "Decayed/Rotten berries" },
                {value: "imm", label: "Immature berries" },
            ]
        }
    },
    {
        id: "blueberry_perc_shrivel",
        type: CharacteristicType.Interval,
        label: "Shrivelling",
        categoryId: "blueberry_disorders", 
        params: { min: 0, max: 100, step: 1,
             descriptors: [
                "Smooth",
                "Inconspicuous",
                "Fine Wrinkles",
            ] }
    },
    {
        id: "blueberry_perc_collapse",              
        type: CharacteristicType.Interval,
        label: "Collapsing",
        categoryId: "blueberry_disorders", 
        params: { min: 0, max: 100, step: 1,
             descriptors: [
                "Firm",
                "Medium",
                "Collapsed",
            ] }
    },
    {
        id: "blueberry_perc_decay",              //WIP
        type: CharacteristicType.Interval,
        label: "Decaying",
        categoryId: "blueberry_disorders", 
        params: { min: 0, max: 100, step: 1,
             descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High",
            ] }
    },
    {
        id: "blueberry_perc_immature",             
        type: CharacteristicType.Interval,
        label: "Immature",
        categoryId: "blueberry_disorders", 
        params: { min: 0, max: 100, step: 1,
             descriptors: [
                "Immature",
                "Ideal",
                "Mature",
            ] }
    },
    {
        id: "blueberry_ripe_stage",
        type: CharacteristicType.Interval,
        label: "Ripening Stage",
        categoryId: "blueberry_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Unripe",
                "Immature",
                "Ideal Eat Ripeness",
                "Mature",
                "Post Optimum"
            ]
        }
    },
    {
        id: "blueberry_firm",
        type: CharacteristicType.Interval,
        label: "Berry Firmness",
        categoryId: "blueberry_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft",
                "Medium",
                "Firm",
                "Very Firm"
            ]
        }
    },
    {
        id: "blueberry_firm_homog",
        type: CharacteristicType.Interval,
        label: "Firmness Homogeneity",
        categoryId: "blueberry_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Large Variation",
                "Variable",
                "Intermediate",
                "Little Variation",
                "Homogenous"
            ]
        }
    },
    {
        id: "blueberry_mature_rate",
        type: CharacteristicType.Interval,
        label: "Degradation Rate",
        categoryId: "blueberry_ripening",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Slow",
                "Slow",
                "Average",
                "Rapid",
                "Very Rapid"
            ]
        }
    },
    {
        id: "blueberry_over_color",
        type: CharacteristicType.Color,
        categoryId: "blueberry_appearance",
        label: "Over Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "blueberry_ground_color",
        type: CharacteristicType.Color,
        categoryId: "blueberry_appearance",
        label: "Ground Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "blueberry_skin_bloom",
        type: CharacteristicType.Nominal,
        categoryId: "blueberry_appearance",
        label: "Bloom of Skin",
        params: {
            "multiple": false,
            options: [
                { value: "absent", label: "Absent" },
                { value: "moderate", label: "Moderate" },
                { value: "strong", label: "Strong" }
            ]
        }
    },
    {
        id: "blueberry_flesh_color",
        type: CharacteristicType.Color,
        categoryId: "blueberry_appearance",
        label: "Flesh Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "blueberry_pip_cavity_colour",
        type: CharacteristicType.Color,
        categoryId: "blueberry_appearance",
        label: "Pip Cavity Colour",
        params: {
            palette: [
                { h: 0, s: 50, l: 50, label: "Red" },
                { h: 330, s: 50, l: 50, label: "Pink" },
                { h: 45, s: 50, l: 50, label: "Yellow" },
                { h: 90, s: 50, l: 50, label: "Green" },
                { h: 30, s: 50, l: 50, label: "Orange" },
                { h: 300, s: 50, l: 50, label: "Purple" },
                { h: 0, s: 0, l: 0, label: "Black" },
                { h: 15, s: 50, l: 35, label: "Brown" }
            ]
        }
    },
    {
        id: "blueberry_pip_cavity_size",
        type: CharacteristicType.Nominal,
        categoryId: "blueberry_appearance",
        label: "Pip Cavity Size",
        params: {
            multiple: false,
            options: [
                { value: "very_large", label: "Very Large" },
                { value: "acceptable", label: "Acceptable" },
                { value: "adhering", label: "Adhering" },
            ]
        }
    },
    {
        id: "blueberry_over_colour_area",
        type: CharacteristicType.Interval,
        label: "Over Colour Area",
        categoryId: "blueberry_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "No Coverage",
                "Little Coverage",
                "Half Covered",
                "Mostly Covered",
                "Completely Covered"

            ]
        }
    },
    {
        id: "blueberry_over_pattern",
        type: CharacteristicType.Nominal,
        categoryId: "blueberry_appearance",
        label: "Over Colour Pattern",
        params: {
            multiple: false,
            options: [
                { value: "uncoloured", label: "Uncoloured" },
                { value: "striped", label: "Striped" },
                { value: "blushed", label: "Blushed" },
                { value: "bicoloured", label: "Bi-Coloured" },
                { value: "mottled", label: "Mottled" },
                { value: "fullcolour", label: "Full Coloured" },
            ]
        }
    },
    {
        id: "blueberry_shape_type",
        type: CharacteristicType.Nominal,
        categoryId: "blueberry_shapes",  
        label: "Berry Shape Type",
        params: {
            options: [
                {value: "spherical", label: "Spherical (Round)" },
                {value: "oblate", label: "Oblate" },
                {value: "ellipsoidal", label: "Ellipsoidal" },
                {value: "obvoid", label: "Obvoid" },
                {value: "elongated", label: "Ellipsoidal (Elongated)" },
                {value: "ovoid", label: "Ovoid (Oval)" },
                {value: "flattened", label: "Flattended" },
                {value: "v_flattened", label: "Very Flattended" },
            ]
        }
    },
    {
        id: "blueberry_symmetry",
        type: CharacteristicType.Interval,
        label: "Shape Symmetry",
        categoryId: "blueberry_appearance",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Asymmetrical",
                "Intermediate",
                "Symmetrical",
            ]
        }
    },
    {
        id: "blueberry_shape_consistent",
        type: CharacteristicType.Interval,
        label: "Shape Consistency",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Variable",
                "Not Uniform",
                "Intermediate",
                "Uniform",
                "Very Uniform"
            ]
        }
    },
    {
        id: "blueberry_pendicul_depth",
        type: CharacteristicType.Interval,
        label: "Pendicular Opening Depth",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Shallow",
                "Shallow",
                "Intermediate",
                "Deep",
                "Very Deep"
            ]
        }
    },
    {
        id: "blueberry_pendicul_width",
        type: CharacteristicType.Interval,
        label: "Pendicular Opening Width",
        categoryId:  "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Narrow",
                "Narrow",
                "Intermediate",
                "Wide",
                "Very Wide"
            ]
        }
    },
    {
        id: "blueberry_calyx_open",
        type: CharacteristicType.Interval,
        label: "Calyx Opening",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Small",
                "Small",
                "Intermediate",
                "Large",
                "Very Large"
            ]
        }
    },
    {
        id: "blueberry_calyx_depth",
        type: CharacteristicType.Interval,
        label: "Calyx Opening Depth",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Shallow",
                "Shallow",
                "Intermediate",
                "Deep",
                "Very Deep"
            ]
        }
    },
    {
        id: "blueberry_calyx_width",
        type: CharacteristicType.Interval,
        label: "Calyx Opening Width",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Narrow",
                "Narrow",
                "Intermediate",
                "Wide",
                "Very Wide"
            ]
        }
    },
    {
        id: "blueberry_pendicul_tear",
        type: CharacteristicType.Interval,
        label: "Pendicular Tearing",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_suture",
        type: CharacteristicType.Interval,
        label: "Fruit Suture",
        categoryId: "blueberry_shapes", 
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_sepal_remnant",
        type: CharacteristicType.Interval,
        label: "Sepal Remnants",
        categoryId: "blueberry_shapes",  
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Absent",
                "Noticeable",
                "Conspicuous"
            ]
        }
    },
    {
        id: "blueberry_abrasion",
        type: CharacteristicType.Interval,
        label: "Abrasions",
        categoryId: "blueberry_abrasions",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_bruised",
        type: CharacteristicType.Interval,
        label: "Bruised Fruit",
        categoryId: "blueberry_abrasions",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "blueberry_blemish",
        type: CharacteristicType.Interval,
        label: "Blemishes",
        categoryId: "blueberry_abrasions",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_collaps",
        type: CharacteristicType.Interval,
        label: "Collapsed Fruit",
        categoryId: "blueberry_abrasions",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "blueberry_lenticel",
        type: CharacteristicType.Interval,
        label: "Lenticel Presence",
        categoryId: "blueberry_abrasions",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_wounds",
        type: CharacteristicType.Interval,
        label: "Wounds",
        categoryId: "blueberry_abrasions",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_taste_sugar",
        type: CharacteristicType.Interval,
        label: "Sugar",
        categoryId: "blueberry_eating_quality", 
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Sweetness",
                "Low Sweetness",
                "Intermediate",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "blueberry_taste_acid",
        type: CharacteristicType.Interval,
        label: "Acid",
        categoryId: "blueberry_eating_quality", 
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Acid",
                "Low Acid",
                "Intermediate",
                "Tart",
                "Very Tart"
            ]
        }
    },
    {
        id: "blueberry_taste_balance",
        type: CharacteristicType.Interval,
        label: "Sugar Acid Balance",
        categoryId: "blueberry_eating_quality", 
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Tarty",
                "Tarty",
                "Balanced",
                "Sweet",
                "Very Sweet"
            ]
        }
    },
    {
        id: "blueberry_taste_aroma",
        type: CharacteristicType.Interval,
        label: "Aroma",
        categoryId: "blueberry_eating_quality", 
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Flat / Insipid",
                "Bland",
                "Intermediate",
                "Flavoursome",
                "Intense"
            ]
        }
    },
    {
        id: "blueberry_taste_burst",
        type: CharacteristicType.Interval,
        label: "Bursting Energy",
        categoryId: "blueberry_eating_quality", 
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Mushy",
                "Mushy",
                "Intermediate",
                "Pop",
                "Explosive"
            ]
        }
    },
    {
        id: "blueberry_taste_juice",
        type: CharacteristicType.Interval,
        label: "Juiciness",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Dry",
                "Dry",
                "Intermediate",
                "Juicy",
                "Very Juicy"
            ]
        }
    },
    {
        id: "blueberry_taste_skin_thicc",
        type: CharacteristicType.Interval,
        label: "Skin Thickness",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Thin",
                "Thin",
                "Intermediate",
                "Think / Crisp",
                "Very Thick"
            ]
        }
    },
    {
        id: "blueberry_taste_skin_tough",
        type: CharacteristicType.Interval,
        label: "Skin Toughness",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Melting",
                "Soft",
                "Intermediate",
                "Tough",
                "Very Tough"
            ]
        }
    },
    {
        id: "blueberry_taste_firmness",
        type: CharacteristicType.Interval,
        label: "Skin Toughness",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Soft",
                "Soft",
                "Intermediate",
                "Firm",
                "Compact / Hard"
            ]
        }
    },
    {
        id: "blueberry_taste_crisp",
        type: CharacteristicType.Interval,
        label: "Texture Cripsness",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Melting",
                "Soft",
                "Intermediate",
                "Crisp",
                "Very Crisp"
            ]
        }
    },
    {
        id: "blueberry_taste_crunch",
        type: CharacteristicType.Interval,
        label: "Texture Crunchiness",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low Resistance",
                "Low Resistance",
                "Intermediate",
                "Crunchy",
                "Very Crunchy"
            ]
        }
    },
    {
        id: "blueberry_taste_grain",
        type: CharacteristicType.Interval,
        label: "Grain",
        categoryId: "blueberry_eating_quality",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Melting / Very Fine",
                "Fine",
                "Intermediate",
                "Grainy",
                "Coarse"
            ]
        }
    },
    {
        id: "blueberry_perc_decay_bot",
        type: CharacteristicType.Interval,
        label: "Botrytis",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_stb",
        type: CharacteristicType.Interval,
        label: "Soft Tissue Breakdown",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_pen",
        type: CharacteristicType.Interval,
        label: "Penicillium",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_rhi",
        type: CharacteristicType.Interval,
        label: "Rhizopus",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_asp",
        type: CharacteristicType.Interval,
        label: "Aspergillus",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_alt",
        type: CharacteristicType.Interval,
        label: "Alternaria",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_cyl",
        type: CharacteristicType.Interval,
        label: "Cylindrocladium",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_exo",
        type: CharacteristicType.Interval,
        label: "Exobasidium",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_phi",
        type: CharacteristicType.Interval,
        label: "Phimosis",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_decay_phy",
        type: CharacteristicType.Interval,
        label: "Phyllosticta",
        categoryId:  "blueberry_bery_decay",  
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_rot_symptom",
        type: CharacteristicType.Interval,
        label: "Mouldy/Rotten Fruit",
        categoryId: "blueberry_storage_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High",
            ]
        }
    },
    {
        id: "blueberry_extdisorder",
        type: CharacteristicType.Nominal,
        categoryId: "blueberry_storage_disorder",
        label: "External Disorders Detected",
        params: {
            options: [
                { value: "shrivel", label: "Shrivel" },
                { value: "poor_skin_colour", label: "Poor Skin Colouration" },
                { value: "bruising", label: "Bruising" },
                { value: "skin_cracking", label: "Skin Cracking" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" }
            ]
        }

    },
    {
        id: "blueberry_shrivel_degree",
        type: CharacteristicType.Interval,
        label: "Degree of Shrivelling",
        categoryId: "blueberry_storage_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Noticeable",
                "Acceptable",
                "High",
                "Very High",
            ]
        }
    },
    {
        id: "blueberry_perc_bc_lateral",
        type: CharacteristicType.Interval,
        label: "Longitudinal Cracking",
        categoryId: "blueberry_bery_crack", 
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_bc_ring",
        type: CharacteristicType.Interval,
        label: "Ring Neck Crack",
        categoryId: "blueberry_bery_crack", 
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_perc_bc_stylar",
        type: CharacteristicType.Interval,
        label: "Stylar End Crack",
        categoryId: "blueberry_bery_crack", 
        params: { min: 0, max: 100, step: 1, descriptors: [""] }
    },
    {
        id: "blueberry_woolli_symptom",
        type: CharacteristicType.Interval,
        label: "Woolliness",
        categoryId: "blueberry_storage_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_sponge_symptom",
        type: CharacteristicType.Interval,
        label: "Sponginess",
        categoryId:  "blueberry_storage_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "blueberry_intbreak_symptom",
        type: CharacteristicType.Interval,
        label: "Internal Breakdown",
        categoryId: "blueberry_storage_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
];