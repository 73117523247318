import { DetailRequest, Filter, FilterBuilder, FilterQueryMode, FilterPage, FilterSort, GroupService, OrganizationGroup, Status, translateCommonErrorStatus, removeArrayItem } from '@core/data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LocalStorage } from '@core/browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export interface GroupsStateModel {
    orgKey: string;
    newGroupKey: string;
    // table data
    status: Status;
    data: OrganizationGroup[];
    total: number;

    // filter options
    page: FilterPage;
    sort: FilterSort;
    query: GroupIndexQuery;

    // table options
    displayColumns: string[];
    sortOptions: { id: string, label: string }[];
    pageSizeOptions: number[];
}

export interface GroupIndexQuery {
    type: string[];
    search: string;
}

const DEFAULTS: GroupsStateModel = {
    orgKey: null,
    newGroupKey: null,
    status: Status.UNINITIALIZED,
    data: [],
    total: 0,
    page: {
        index: 0,
        size: 5,
    },
    sort: {
        column: 'label',
        order: 'asc'
    },
    query: {
        type: [],
        search: null,
    },
    displayColumns: [
        'label',
        'actions',
        'description',
        'protocols',
        'samples',
        'users',
        'createdAt',
    ],
    sortOptions: [
        { id: 'label', label: "Label" },
        { id: 'createdAt', label: "Created Date" },
    ],
    pageSizeOptions: [5,10,20,50,100],
};

export class InitGroups {
    static readonly type = "[Groups] Init";
    constructor(public orgKey: string) {}
}

export class LoadGroups {
    static readonly type = "[Groups] Load";
    constructor(public orgKey?: string, public groupKey?: string) {}
}

export class AddGroup {
    static readonly type = "[Groups] Add";
    constructor(public data: Partial<OrganizationGroup>) {}
}

export class RemoveGroup {
    static readonly type = "[Groups] Remove";
    constructor(public key: string) {}
}

export class AddGroupUser {
    static readonly type = "[Groups] AddUser";
    constructor(public groupKey: string, public userKey: string) {}
}

export class RemoveGroupUser {
    static readonly type = "[Groups] RemoveUser";
    constructor(public groupKey: string, public userKey: string) {}
}

export class AddGroupSample {
    static readonly type = "[Groups] AddSample";
    constructor(public groupKey: string, public sampleKey: string) {}
}

export class RemoveGroupSample {
    static readonly type = "[Groups] RemoveSample";
    constructor(public groupKey: string, public sampleKey: string) {}
}

export class AddGroupProtocolFilter {
    static readonly type = "[Groups] AddProtocolFilter";
    constructor(public groupKey: string, public protocolKey: string) {}
}

export class RemoveGroupProtocolFilter {
    static readonly type = "[Groups] RemoveProtocolFilter";
    constructor(public groupKey: string, public protocolKey: string) {}
}


export class InitGroupIndex {
    static readonly type = "[GroupIndex] Init";
    constructor(public orgKey: string) {}
}

export class LoadGroupIndex {
    static readonly type = "[GroupIndex] Load";
    constructor() {}
}

export class ResetGroupIndex {
    static readonly type = "[GroupIndex] Reset";
    constructor() {}
}

export class FilterGroupIndex {
    static readonly type = "[GroupIndex] Filter";
    constructor(public query: GroupIndexQuery) {}
}

export class PageGroupIndex {
    static readonly type = "[GroupIndex] Page";
    constructor(public index: number, public size: number) {}
}

export class SortGroupIndex {
    static readonly type = "[GroupIndex] Sort";
    constructor(public column: string, public order = 'asc') {}
}

@State<GroupsStateModel>({
    name: "groups",
    defaults: DEFAULTS,
})
@Injectable()
export class GroupsState {

    @Selector()
    static query(state: GroupsStateModel) {
        return state.query;
    }

    @Selector()
    static sort(state: GroupsStateModel) {
        return state.sort;
    }

    @Selector()
    static newGroupKey(state: GroupsStateModel){
        return state.newGroupKey;
    }

    constructor(
        private _groupService: GroupService,
        private _storage: LocalStorage,
    ) {}

    /**
     * @param ctx State Context
     * @param action orgKey: organization key
     */
    @Action(InitGroups)
    initGroups(ctx: StateContext<GroupsStateModel>, action: InitGroups) {
        ctx.patchState({
            orgKey: action.orgKey,
            status: Status.LOADING,
            data: [],
            total: 0,
        });
        return ctx.dispatch(new LoadGroups);
    }

    /**
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LoadGroups, { cancelUncompleted: true })
    loadGroups(ctx: StateContext<GroupsStateModel>, action?: LoadGroups) {
        const state = ctx.getState();
        let orgKey: string;

        if (action.orgKey) {
            orgKey = action.orgKey;
        } else {
            orgKey = state.orgKey;
        }

        ctx.patchState({
            status: Status.LOADING,
            data: [],
            total: 0,
            newGroupKey: action.groupKey ? action.groupKey : null,
        });

        const filter: Filter = {
            queries: [
                {
                    key: 'ownerOrgKey',
                    mode: FilterQueryMode.EQUALS,
                    value: orgKey,
                },
            ],
            sort: {
                column: 'label',
                order: 'asc'
            },
        };

        const detail: DetailRequest = {
            related: [
                'samples',
                'protocols',
                'users',
            ],
            counts: [
                'samples',
                'protocols',
                'users',
            ],
        };

        return this._groupService.query(filter, detail)
            .pipe(
                tap(
                    result => {
                        if(result.total === 0) {
                            ctx.patchState({
                                status: Status.EMPTY,
                                data: [],
                                total: 0,
                            });
                            return;
                        }
                        ctx.patchState({
                            status: Status.OK,
                            data: result.data,
                            total: result.total,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: [],
                            total: 0,
                        });
                    },
                )
            );
    }

    /**
     * @param ctx State Context
     * @param action data: Group form data
     */
    @Action(AddGroup)
    addGroup(ctx: StateContext<GroupsStateModel>, action: AddGroup) {
        let request: Observable<OrganizationGroup>;
        let newGroup: boolean = false;

        if (action.data.key) request = this._groupService.update(action.data.key, action.data);
        else {
            request = this._groupService.create(action.data);
            newGroup = true;
        }

        return request.pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });

                    if (newGroup) return ctx.dispatch(new LoadGroups(action.data.ownerOrgKey, result.key));
                    else return ctx.dispatch(new LoadGroups(action.data.ownerOrgKey));
                })
            );
    }

    /**
     * @param ctx State Context
     * @param action data: Group form data
     */
    @Action(RemoveGroup)
    removeGroup(ctx: StateContext<GroupsStateModel>, action: RemoveGroup) {
        return this._groupService.delete(action.key)
            .pipe(tap(removed => {
                const state = ctx.getState();

                if (state.status !== Status.OK) return;
                const total = state.total - 1;

                ctx.patchState({
                    status: (total <= 0) ? Status.EMPTY : Status.OK,
                    data: removeArrayItem(state.data, removed.key),
                    total: total,
                });
            }));
    }

    /**
     * @param ctx State Context
     * @param action
     */
    @Action(AddGroupUser)
    addGroupUser(ctx: StateContext<GroupsStateModel>, action) {
        return this._groupService.addGroupUser(action.groupKey, action.userKey)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                    return ctx.dispatch(new LoadGroups);
                })
            );
    }

    /**
     * @param ctx State Context
     * @param action
     */
    @Action(RemoveGroupUser)
    removeGroupUser(ctx: StateContext<GroupsStateModel>, action) {
        return this._groupService.removeGroupUser(action.groupKey, action.userKey)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                    return ctx.dispatch(new LoadGroups);
                })
            );
    }

    /**
     * @param ctx State Context
     * @param action
     */
    @Action(AddGroupSample)
    addGroupSample(ctx: StateContext<GroupsStateModel>, action) {
        return this._groupService.addGroupSample(action.groupKey, action.sampleKey)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                    return ctx.dispatch(new LoadGroups);
                })
            );
    }

    /**
     * @param ctx State Context
     * @param action
     */
    @Action(RemoveGroupSample)
    removeGroupSample(ctx: StateContext<GroupsStateModel>, action) {
        return this._groupService.removeGroupSample(action.groupKey, action.sampleKey)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                    return ctx.dispatch(new LoadGroups);
                })
            );
    }


    /**
     * @param ctx State Context
     * @param action
     */
    @Action(AddGroupProtocolFilter)
    addGroupProtocolFilter(ctx: StateContext<GroupsStateModel>, action) {
        return this._groupService.addGroupProtocolFilter(action.groupKey, action.protocolKey)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                    return ctx.dispatch(new LoadGroups);
                })
            );
    }

    /**
     * @param ctx State Context
     * @param action
     */
    @Action(RemoveGroupProtocolFilter)
    removeGroupProtocolFilter(ctx: StateContext<GroupsStateModel>, action) {
        return this._groupService.removeGroupProtocolFilter(action.groupKey, action.protocolKey)
            .pipe(
                tap(result => {
                    ctx.patchState({
                        status: Status.OK,
                    });
                    return ctx.dispatch(new LoadGroups);
                })
            );
    }

    @Action(InitGroupIndex)
    initGroupIndex(ctx: StateContext<GroupsStateModel>, action: InitGroupIndex) {
        ctx.patchState({
            orgKey: action.orgKey,
            data: [],
            total: 0,
        });
        this.restoreFilter(ctx);
        return ctx.dispatch(new LoadGroupIndex);
    }

    @Action(LoadGroupIndex, {cancelUncompleted: true})
    loadGroupIndex(ctx: StateContext<GroupsStateModel>) {
        const state = ctx.getState();

        if(!state.orgKey){
            console.warn("Attempting to load Groups without organization key.");
        }

        ctx.patchState({
            status: Status.LOADING
        });

        const detail: DetailRequest = {
            related: [
                'samples',
                'protocols',
                'users',
            ],
            counts: [
                'samples',
                'protocols',
                'users',
            ],
        };
        return this._groupService.query(this.getFilter(state), detail)
            .pipe(
                tap(
                    result => {
                        if (result.total === 0) {
                            ctx.patchState({
                                status: Status.EMPTY,
                                total: 0,
                                data: []
                            });
                            return;
                        }
                        ctx.patchState({
                            status: Status.OK,
                            data: result.data,
                            total: result.total,
                        });
                        this.storeFilter(ctx);
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error),
                            data: [],
                            total: 0
                        });
                    },
                )
            );
    }

    @Action(PageGroupIndex)
    pageGroupIndex(ctx: StateContext<GroupsStateModel>, action: PageGroupIndex) {
        ctx.patchState({
            page: {
                index: action.index,
                size: action.size
            }
        });
        return ctx.dispatch(new LoadGroupIndex);
    }

    @Action(SortGroupIndex)
    sortGroupIndex(ctx: StateContext<GroupsStateModel>, action: SortGroupIndex) {
        const state = ctx.getState();
        ctx.patchState({
            sort: {
                column: action.column,
                order: action.order
            },
            page: {
                index: 0,
                size: state.page.size
            }
        });
        return ctx.dispatch(new LoadGroupIndex);
    }

    @Action(FilterGroupIndex)
    filterGroupIndex(ctx: StateContext<GroupsStateModel>, action: FilterGroupIndex) {
        const state = ctx.getState();
        ctx.patchState({
            query: action.query,
            page: {
                index: 0,
                size: state.page.size
            }
        });
        return ctx.dispatch(new LoadGroupIndex);
    }

    @Action(ResetGroupIndex)
    resetGroupIndex(ctx: StateContext<GroupsStateModel>) {
        ctx.patchState({
            query: DEFAULTS.query,
            page: DEFAULTS.page,
            sort: DEFAULTS.sort
        });
        return ctx.dispatch(new LoadGroupIndex);
    }

    private getFilter(state: GroupsStateModel, ignorePage = false) {
        const fb = (new FilterBuilder());

        if (state.orgKey){
            fb.setQuery('ownerOrgKey', state.orgKey)
        }

        if (!ignorePage) fb.setPage(state.page);
        fb.setSort(state.sort);

        if (typeof state.query.search === 'string' && state.query.search.length > 0) {
            if (state.query.search.trim()) fb.setQuery('$fuzzy', state.query.search);
        }

        if (Array.isArray(state.query.type) && state.query.type.length > 0) {
            fb.setQuery('type', state.query.type, FilterQueryMode.IN);
        }

        return fb.get();
    }

    private storeFilter(ctx: StateContext<GroupsStateModel>) {
        const state = ctx.getState();

        this._storage.set(`${state.orgKey}_group_index_page`, state.page);
        this._storage.set(`${state.orgKey}_group_index_sort`, state.sort);
        this._storage.set(`${state.orgKey}_group_index_query`, state.query);
    }

    private restoreFilter(ctx: StateContext<GroupsStateModel>) {
        const state = ctx.getState();

        const page = this._storage.get(`${state.orgKey}_group_index_page`);
        const sort = this._storage.get(`${state.orgKey}_group_index_sort`);
        const query = this._storage.get(`${state.orgKey}_group_index_query`);

        ctx.patchState({
            sort: sort || DEFAULTS.sort,
            page: page || DEFAULTS.page,
            query: query || DEFAULTS.query,
        });
    }
}
