import { Characteristic, CharacteristicType } from "./_types";

export const LIB_CHARS_ADVANCED_CITRUS_TREE: Characteristic[] = [
    {
        id: "date_swollen_bud_citrus",
        type: CharacteristicType.Event,
        label: "Bud Swell",
        categoryId: "citrus_tree_phenology",
        params:{
            color: "#7E57C2"
        }
    },
    {
        id: "date_balloon_citrus",
        type: CharacteristicType.Event,
        label: "Balloon Stage",
        categoryId: "citrus_tree_phenology",
        params:{
            color: "#9575CD"
        }
    },
    {
        id: "date_full_bloom_citrus",
        type: CharacteristicType.Event,
        label: "Full Bloom",
        categoryId: "citrus_tree_phenology",
        params:{
            color: "#9575CD"
        }
    },
    {
        id: "date_50_petal_citrus",
        type: CharacteristicType.Event,
        label: "50 % petal drop",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#673AB7"
        }
    },
    {
        id: "date_90_petal_citrus",
        type: CharacteristicType.Event,
        label: "90 % petal drop",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#673AB7"
        }
    },
    {
        id: "date_100_petal_citrus",
        type: CharacteristicType.Event,
        label: "100 % petal drop",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#673AB7"
        }
    },
    {
        id: "date_fruit_drop_citrus",
        type: CharacteristicType.Event,
        label: "Physiological fruit drop",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#E65100"
        }
    },
    {
        id: "date_color_break_citrus",
        type: CharacteristicType.Event,
        label: "Colour Break",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#9575CD"
        }
    },
    {
        id: "date_harvest_start_citrus",
        type: CharacteristicType.Event,
        label: "Start of Harvest",
        categoryId: "citrus_tree_phenology",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "date_harvest_end_citrus",
        type: CharacteristicType.Event,
        label: "End of Harvest",
        categoryId: "citrus_tree_phenology",
        params:{
            color: "#E65100"
        }
    },
    {
        id: "date_winter_pruning",
        type: CharacteristicType.Event,
        label: "Date of winter Structural pruning",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "date_fruit_set_application",
        type: CharacteristicType.Event,
        label: "Date of application for fruit set manipulations",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "date_summer_pruning",
        type: CharacteristicType.Event,
        label: "Date of summer pruning",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "date_thinning_application",
        type: CharacteristicType.Event,
        label: "Date of application for thinning manipulations",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "date_gib_application",
        type: CharacteristicType.Event,
        label: "Date of gib application (1)",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "date_gib_application_two",
        type: CharacteristicType.Event,
        label: "Date of gib application (2)",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "date_girdling",
        type: CharacteristicType.Event,
        label: "Date of girdling",
        categoryId: "citrus_tree_phenology",
        params: {
            color: "#00BCD4"
        }
    },
    {
        id: "citrus_tree_shape",
        type: CharacteristicType.Nominal,
        label: "Tree Shape",
        categoryId: "citrus_tree_arch",
        altLabels: ["Branching Habits"],
        params: {
            options: [
                { value: "drooping", label: "Drooping"},
                { value: "spreading", label: "Spreading"},
                { value: "upright", label: "Upright"},
            ]
        }
    },
    {
        id: "citrus_tree_grow_potent",
        type: CharacteristicType.Interval,
        label: "Tree Vigour",
        categoryId: "citrus_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Vigorous",
                "Very Vigorous"
            ]
        }
    },
    {
        id: "citrus_tree_density",
        type: CharacteristicType.Interval,
        label: "Tree Density",
        categoryId: "citrus_tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "Dense",
                "Very Dense",
            ]
        }
    },
    {
        id: "citrus_tree_thorns",
        type: CharacteristicType.Interval,
        label: "Thorn Presence",
        categoryId: "citrus_tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_tree_thorn_length",
        type: CharacteristicType.Nominal,
        label: "Thorn Length",
        categoryId: "citrus_tree_arch",
        params: {
            options: [
                { value: "<_1cm", label: "< 1cm"},
                { value: "1_2cm", label: "1 - 2 cm"},
                { value: "2_4cm", label: "2 - 4 cm"},
                { value: ">_4cm", label: "> 4 cm"},
            ]
        }
    },
    {
        id: "citrus_tree_flower_dense",
        type: CharacteristicType.Interval,
        label: "Flower Density",
        categoryId: "citrus_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Flowering",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "citrus_tree_flower_events",
        type: CharacteristicType.Interval,
        label: "Flowering Events",
        categoryId: "citrus_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "1 Flowering Event",
                "2 Flowering Events",
                "3 Flowering Events",
                "> 3 Flowering Events",
            ]
        }
    },
    {
        id: "citrus_tree_leaf_inflorescence",
        type: CharacteristicType.Interval,
        label: "Leafy Inflorescence",
        categoryId: "citrus_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
               "Leafless",
               "Few",
               "Medium",
               "Leafy",
               "Very Leafy"
            ]
        }
    },
    {
        id: "citrus_tree_bear_alternate",
        type: CharacteristicType.Interval,
        label: "Alternate Bearing",
        altLabels: ["Biennial Bearing"],
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Consistent",
                "Consistent",
                "Intermediate",
                "Alternating",
                "Very Alternating"
            ]
        }
    },
    {
        id: "citrus_tree_fruit_set_manip",
        type: CharacteristicType.Nominal,
        label: "Fruit set Manipulation",
        categoryId: "citrus_tree_bear",
        params: {
            options: [
                { value: "urea", label: "Urea"},
                { value: "ga3", label: "GA3"},
                { value: "boron", label: "Boron"},
                { value: "girdling", label: "Girdling"},
                { value: "other_uniconazole", label: "Other Uniconazole"},
                { value: "none", label: "None"},
            ]
        }
    },
    {
        id: "citrus_tree_fruit_set",
        type: CharacteristicType.Interval,
        label: "Fruit Set",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
               "Very Low",
               "Low",
               "Intermediate",
               "High",
               "Very High",
            ]
        }
    },
    {
        id: "citrus_tree_fruit_drop",
        type: CharacteristicType.Interval,
        label: "Physiological Fruit Drop",
        altLabels: ["November/December Fruit Drop"],
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
               "Very Low",
               "Low",
               "Intermediate",
               "High",
               "Very High",
            ]
        }
    },
    {
        id: "citrus_tree_creasing",
        type: CharacteristicType.Interval,
        label: "Creasing Presence",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_creasing_severity",
        type: CharacteristicType.Interval,
        label: "Creasing Severity",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_split",
        type: CharacteristicType.Interval,
        label: "Splitting Presence",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_split_severity",
        type: CharacteristicType.Interval,
        label: "Splitting Severity",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_disorder_manip",
        type: CharacteristicType.Nominal,
        label: "Manipulations for Physiological disorders",
        categoryId: "citrus_tree_bear",
        params: {
            options: [
                { value: "24D", label: "2,4-D"},
                { value: "GA3", label: "GA3"},
            ]
        }
    },
    {
        id: "citrus_tree_out_season",
        type: CharacteristicType.Interval,
        label: "Out of Season Fruit",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
               "None",
               "Odd",
               "Many",
            ]
        }
    },
    {
        id: "citrus_tree_fruit_thin",
        type: CharacteristicType.Interval,
        label: "Fruit Thinning Required",
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Low",
                "Intermediate",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_tree_thinning_method",
        type: CharacteristicType.Nominal,
        label: "Fruit Thinning Method",
        categoryId: "citrus_tree_bear",
        params: {
            options: [
                { value: "corasil", label: "Corasil"},
                { value: "maxim", label: "Maxim"},
                { value: "hand_thin", label: "Hand Thin"},
                { value: "prune", label: "Prune"},
                { value: "other", label: "Other"},
                { value: "none", label: "None"},
            ]
        }
    },
    {
        id: "citrus_tree_pick_count",
        type: CharacteristicType.Nominal,
        categoryId: "citrus_tree_bear",
        label: "Number Of Picks",
        params: {
            options: [
                { value: "pick_1", label: "1" },
                { value: "pick_2", label: "2" },
                { value: "pick_3", label: "3" },
            ]
        }
    },
    {
        id: "citrus_tree_crop_load",
        type: CharacteristicType.Interval,
        label: "Crop Load",
        altLabels: ["Crop Load"],
        categoryId: "citrus_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Low",
                "Moderate",
                "High",
            ]
        }
    },
    {
        id: "citrus_tree_pest_detect",
        type: CharacteristicType.Nominal,
        label: "Pests Detected",
        categoryId: "citrus_tree_disorder",
        params: {
            options: [
                { value: "citrus_nematodes", label: "Citrus Nematodes"},
                { value: "mealy_bug", label: "Mealy Bug"},
                { value: "thrips", label: "Thrips"},
                { value: "bollworm", label: "Bollworm"},
                { value: "false_codling_moth", label: "False Codling Moth"},
                { value: "aphids", label: "Aphids"},
                { value: "leaf_miner", label: "Leaf Miner"},
                { value: "orange_dog", label: "Orange Dog"},
                { value: "fruit_flies", label: "Fruit Flies"},
                { value: "red_spider_mite", label: "Red Spider Mite"},
                { value: "psylla", label: "Psylla"},
                { value: "red_scale", label: "Red Scale"},
                { value: "other", label: "Other"},
                { value: "none", label: "None"},
            ]
        }
    },
    {
        id: "citrus_tree_disease_detect",
        type: CharacteristicType.Nominal,
        label: "Disease Detected",
        categoryId: "citrus_tree_disorder",
        params: {
            options: [
                { value: "alternaria", label: "Alternaria"},
                { value: "citrus_black_spot", label: "Citrus Black Spot (CBS)"},
                { value: "tristeza_virus", label: "Tristeza Virus"},
                { value: "other", label: "Other"},
                { value: "none", label: "None"},
            ]
        }
    },
    {
        id: "citrus_tree_granulation",
        type: CharacteristicType.Interval,
        label: "Granulation Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_tree_granulation_severity",
        type: CharacteristicType.Interval,
        label: "Granulation Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_tree_gumming",
        type: CharacteristicType.Interval,
        label: "Gumming Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_tree_gumming_severity",
        type: CharacteristicType.Interval,
        label: "Gumming Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Mild",
                "Severe",
            ]
        }
    },
    {
        id: "citrus_tree_sunburn_symptom",
        type: CharacteristicType.Interval,
        label: "Sunburn Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_sunburn_severity",
        type: CharacteristicType.Interval,
        label: "Sunburn Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_variegation_presence",
        type: CharacteristicType.Interval,
        label: "Variegation Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_variegation",
        type: CharacteristicType.Interval,
        label: "Variegation Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "citrus_tree_chimera_presence",
        type: CharacteristicType.Interval,
        label: "Chimera Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_chimera",
        type: CharacteristicType.Interval,
        label: "Chimera Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "citrus_tree_mal_navels_presence",
        type: CharacteristicType.Interval,
        label: "Malformed Navels Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_malformed_navels",
        type: CharacteristicType.Interval,
        label: "Malformed Navels Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_prot_navels_presence",
        type: CharacteristicType.Interval,
        label: "Protruding Navels Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_protruding_navels",
        type: CharacteristicType.Interval,
        label: "Protruding Navels Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_alternaria_presence",
        type: CharacteristicType.Interval,
        label: "Alternaria Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_alternaria",
        type: CharacteristicType.Interval,
        label: "Alternaria Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_cbs_presence",
        type: CharacteristicType.Interval,
        label: "Citrus Black Spot (CBS) Presence",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "citrus_tree_cbs",
        type: CharacteristicType.Interval,
        label: "Citrus Black Spot (CBS) Severity",
        categoryId: "citrus_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "None",
                "Very Slight",
                "Slight",
                "Severe",
                "Very Severe",
            ]
        }
    },
    {
        id: "citrus_tree_flower_span",
        type: CharacteristicType.Interval,
        label: "Flower Burst Span",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Brief",
                "Prolonged",
                "Extended"
            ]
        }
    },
    {
        id: "citrus_tree_uniform_ripe",
        type: CharacteristicType.Interval,
        label: "Uniform Ripening",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "citrus_tree_overall_product",
        type: CharacteristicType.Interval,
        label: "Productivity",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "citrus_tree_precociousness",
        type: CharacteristicType.Interval,
        label: "Precociousness",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
               "Late",
               "Intermediate",
               "Early",
            ]
        }
    },
    {
        id: "citrus_tree_fruit_size",
        type: CharacteristicType.Interval,
        label: "Fruit Size",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
               "Small (<6)",
               "Small - Medium (6-4)",
               "Medium (3-2)",
               "Medium - Large (2-1)",
               "Large (1 - 1XX)",
               "Extra Large (>1XX)",
            ]
        }
    },
    {
        id: "citrus_tree_fruit_size_pick",
        type: CharacteristicType.Nominal,
        label: "Specified Fruit Size ",
        categoryId: "citrus_tree_adapt",
        params:{
            options: [
                {value: "calibre_7", label: "Calibre 7"},
                {value: "calibre_6", label: "Calibre 6"},
                {value: "calibre_5", label: "Calibre 5"},
                {value: "calibre_4", label: "Calibre 4"},
                {value: "calibre_3", label: "Calibre 3"},
                {value: "calibre_2", label: "Calibre 2"},
                {value: "calibre_1", label: "Calibre 1"},
                {value: "calibre_1x", label: "Calibre 1X"},
                {value: "calibre_1xx", label: "Calibre 1XX"},
                {value: "calibre_1xxx", label: "Calibre 1XXX"},
            ]
        }
    },
    {
        id: "citrus_tree_fruit_color_soft",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Orange and Soft Citrus",
        categoryId: "citrus_tree_adapt",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow", label: "Yellow (3-4)"},
                { value: "yellow_orange", label: "Yellow/Orange (2)"},
                { value: "orange", label: "Orange (1)"},
            ]
        }
    },
    {
        id: "cit_tree_fruit_color_soft_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level Orange and Soft Citrus",
        categoryId: "citrus_tree_adapt",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_tree_fruit_color_grapef",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Yellow Grapefruit",
        categoryId: "citrus_tree_adapt",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow_green", label: "Yellow/Green (3-4)"},
                { value: "yellow", label: "Yellow (1-2)"},
            ]
        }
    },
    {
        id: "cit_tree_fruit_color_grapef_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level Yellow Grapefruit",
        categoryId: "citrus_tree_adapt",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_tree_fruit_color_lemon",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Lemons",
        categoryId: "citrus_tree_adapt",
        params: {
            options: [
                { value: "green", label: "Green (7-8)"},
                { value: "green_yellow", label: "Green/Yellow (5-6)"},
                { value: "yellow_green", label: "Yellow/Green (3-4)"},
                { value: "yellow", label: "Yellow (1-2)"},
            ]
        }
    },
    {
        id: "cit_tree_fruit_color_lemon_lvl",
        type: CharacteristicType.Nominal,
        label: "Fruit Colour Level Lemons",
        categoryId: "citrus_tree_adapt",
        params: {
            options: [
                { value: "eight", label: "Eight (8)"},
                { value: "seven", label: "Seven (7)"},
                { value: "six", label: "Six (6)"},
                { value: "five", label: "Five (5)"},
                { value: "four", label: "Four (4)"},
                { value: "three", label: "Three (3)"},
                { value: "two", label: "Two (2)"},
                { value: "one", label: "One (1)"},
            ]
        }
    },
    {
        id: "citrus_tree_overall_adapt",
        type: CharacteristicType.Interval,
        label: "Overall Adaptability",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Poorly Adapted",
                "Intermediate",
                "Well Adapted"
            ]
        }
    },
    {
        id: "citrus_tree_bud_union",
        type: CharacteristicType.Interval,
        label: "Bud Union",
        categoryId: "citrus_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
               "Smooth",
               "Slightly Benched",
               "Average Benched",
               "Severly Benched",
               "Over Benched",
            ]
        }
    },

]