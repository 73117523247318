import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Site, Status } from '@core/data';
import { LatLng, MapComponent } from '@core/maps';
import { Focus, GeocodeMarker, SiteMarker, SitePolygon } from '@core/maps/types';
import { Dialog, Snackbar } from '@core/material';
import { isEmpty, polygonCentroid } from '@core/utils';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { isArray } from 'util';
import { SiteFormDialog } from '../site-form/site-form.dialog';
import { InitSiteForm, SiteFormState, SiteFormStateModel, SubmitSiteForm } from '../site-form/site-form.state';
import { DeleteSite, DownloadSiteImportTemplate, ExportSiteIndex, ImportSiteIndex, InitSiteIndex, LoadSiteIndex, SearchSiteIndex, SetSelectedKey, SiteIndexState, SiteIndexStateModel } from './site-index.state';
import { ExportHistoryDialog, ExportHistoryDialogData } from '../export-history-form/export-history-form.dialog';
import { ExportType } from '../../../../core/data/types/export-history';

@Component({
    selector: 'pv-site-index-view',
    templateUrl: './site-index.view.html',
    styleUrls: ['site-index.view.scss']
})
export class SiteIndexView implements OnInit {

    // INDEX STATE
    @Select(SiteIndexState)
    public state$: Observable<SiteIndexStateModel>;

    // SITES
    @Select(SiteIndexState.data)
    private data$: Observable<Site[]>;

    // SELECTED STATE
    @Select(SiteIndexState.selected)
    public selected$: Observable<Site>;

    @Select(SiteFormState)
    public form$: Observable<SiteFormStateModel>;

    // USER LOCATION
    userLocation$ = new BehaviorSubject<LatLng>(null);

    // FOCUS
    private _focus$ =  new BehaviorSubject<Focus>(Focus.FOCUS_DEFAULT);

    private DEFAULT_CENTER = {
        lat: -30.5595,
        lng: 22.9375,
    };

    // MAP CONTROLS
    private _zoom$   = new BehaviorSubject<number>(3);
    private _bounds$ = new BehaviorSubject<LatLng[]>([]);
    private _center$ = new BehaviorSubject<LatLng>(this.DEFAULT_CENTER);

    // Polygons & Markers
    public _polygons$   = new BehaviorSubject<SitePolygon[]>([]);
    public _markers$    = new BehaviorSubject<SiteMarker[]>([]);
    public _geoMarkers$ = new BehaviorSubject<GeocodeMarker[]>([]);


    // EDIT MODE
    public _editMode = false;
    public _saveAreaControl = new FormControl(true);
    private lastRelativeBounds: LatLng[] = null;

    // SEARCH CONTROL
    public searchControl = new FormControl('');

    // SEARCH FORM GROUP
    public searchGroup: FormGroup = new FormGroup({
        search: this.searchControl,
    });

    // FORM CONTROLS
    private _polygonControl = new FormControl();
    private _markerControl = new FormControl();
    private _areaControl = new FormControl();

    // FORMGROUP
    public formGroup = new FormGroup({
        polygon: this._polygonControl,
        marker: this._markerControl,
        area: this._areaControl,
    });

    // TAKE UNTIL
    private _destroy$ = new Subject();

    // The Map Google Maps Component
    @ViewChild('map', {static: true})
    map: MapComponent;

    // Import Form Group
    importFormGroup = new FormGroup({
        file: new FormControl(null),
    });

    // Export Form Group
    exportFormGroup = new FormGroup({
        type: new FormControl('xlsx'),
    });

    constructor(
        private _dialogs: MatDialog,
        private _store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private _dialog: Dialog,
        private _activated: ActivatedRoute,
        private _router: Router,
        private _snackbar: Snackbar,
    ){}

    ngOnInit(){

        // Gets the users' current location to orientate the user
        this.updateUserLocation();

        // Different Behavior subjects used to control map zoom, bounds and center
        this._zoom$.subscribe(zoom => this.map.zoom = zoom);
        this._bounds$.subscribe(bounds => this.map.bounds = bounds);
        this._center$.subscribe(center => this.map.center = center);

        // Initializes Site Index with orgKey retrieved from activated route params
        this._activated.paramMap
            .pipe(takeUntil(this._destroy$))
            .subscribe(params => {
                this._store.dispatch(new InitSiteIndex(params.get('orgKey')));
            });

        // Moves Polygon relative to new marker position
        // The boundary saved on the site model is not the absolute bounds of the boundary
        // but rather the relative bounds to the marker (Polygon centroid) of the site
        this._markerControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((marker: LatLng) => {

                let selected = this._store.selectSnapshot(SiteIndexState.selected);
                if(!marker) return;
                let pos = marker;
                let bounds = selected.boundary || this.lastRelativeBounds;
                let absBounds = this.getAbsoluteBounds(pos, bounds);

                this._polygonControl.setValue(absBounds, {emitEvent: false});
                this._changeDetectorRef.markForCheck();
            });

        // Moves Marker relative to new boundary position
        this._polygonControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(poly => {

                if(!isArray(poly)) return;
                let markerNewPos = polygonCentroid(poly);
                this._markerControl.setValue(markerNewPos, {emitEvent: false});
                this.lastRelativeBounds = this.getRelativeBounds(markerNewPos, poly);
                this._changeDetectorRef.markForCheck();
            });

        this.selected$
            .pipe(takeUntil(this._destroy$))
            .subscribe((selectedSite: Site) => {
                if(selectedSite){
                    let polygons = this.hasLatLng(selectedSite) && this.hasBounds(selectedSite)
                    ? [this.getPolygon(selectedSite)]
                    : [];


                    this._polygons$.next(polygons);
                }else{
                    this._polygons$.next([]);
                }
            });

        // Subscribes to the focus behavior subject and changes the map focus accordingly
        this._focus$
            .pipe(takeUntil(this._destroy$))
            .subscribe(focus => {

                let markers: SiteMarker[] = this._markers$.getValue();
                let selected: Site = this._store.selectSnapshot(SiteIndexState.selected);
                let latLng = this.userLocation$.getValue();

                switch (focus) {

                    // ALL
                    case Focus.FOCUS_ALL:
                        if(!this._editMode) {
                            if(selected && this.hasLatLng(selected)) {
                                this.focusMarkers([this.getMarker(selected)]);
                                break;
                            }

                            if(markers && markers.length > 0 && !selected) this.focusMarkers(markers);
                            else this.focusDefault();
                        }
                        break;

                    // SELECT
                    case Focus.FOCUS_SELECTED:
                        if(selected && this.hasBounds(selected) && this.hasLatLng(selected)){
                            this.focusBounds(this.getAbsoluteBounds(selected, selected.boundary));
                        } else if(selected && this.hasLatLng(selected)){
                            this.focusBounds([this.getLatLng(selected)]);
                        }
                        // else if(markers && markers.length > 0) this.focusMarkers(markers);
                        // else this.focusDefault();
                        break;

                    // USER
                    case Focus.FOCUS_USER:
                        this.focusBounds([latLng]);
                        break;

                    // DEFAULT
                    default:
                        this.focusDefault();
                        break;
                }
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe sto state data and maps markers and polygons to display on the map
        this.data$
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {

                // Markers
                let markers = data.filter(site => this.hasLatLng(site))
                    .map(site => this.getMarker(site));
                this._markers$.next(markers);

                // Sets focus to all
                this._focus$.next(Focus.FOCUS_ALL);
            });

        // Listens for changes on the search control and dispatches
        // an action to do a geo code search else if it is a geocode response if focuses on the response marker
        this.searchControl.valueChanges
            .pipe(takeUntil(this._destroy$), debounceTime(700))
            .subscribe((search: string) => {
                this._store.dispatch(new SearchSiteIndex(search));
                this.selectKey("");
            });

        // Subscribes to the site form state and reset the formgroup accordingly
        this.form$.pipe(takeUntil(this._destroy$))
            .subscribe(form => {

                if(form.status !== Status.LOADING && form.data){
                    this.formGroup.reset({
                        polygon: this.getAbsoluteBoundsOrDefault(<Site>form.data),
                        marker: this.getLatLng(<Site>form.data),
                        area: coerceNumberProperty(form.data.area, null)
                    }, {emitEvent: false});
                }

            });
    }

    // Get the user's current location and set the userControl to the value
    private updateUserLocation() {
        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(
                // success
                (position) => {
                    this.userLocation$.next({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                    this._changeDetectorRef.markForCheck();
                },
                // error
                (err) => {
                    console.warn("SiteIndexView: unable to determine user location: %o", err);
                    this.userLocation$.next(null);
                }
            );
        }
    }

    // Checks if the key of the site matches the select sites' key and return boolean accordingly (Used to highlight currently selected site)
    checkIfSelected(key: string): boolean {
        return this._store.selectSnapshot(SiteIndexState).selectedSiteKey === key;
    }

    // Opens The Create Site Dialog
    add() {
        const state: SiteIndexStateModel = this._store.selectSnapshot(SiteIndexState);
        this.selectKey(null);

        let data = {
            key: '',
            defaults: {
                ownerOrgKey: state.orgKey,
            }
        };

        this._dialogs.open(SiteFormDialog, { data });
    }

    // Opens site form dialog
    edit(model: Site) {

        let data = {
            key: model.key,
            defaults: model,
        }

        this._dialogs.open(SiteFormDialog, { data });
    }

    // Opens confirmation for site deletion
    delete(site: Site) {
        let dialog = this._dialog.confirm(
            'Remove Site',
            'Are you sure you want to remove this site?',
            'Remove',
            'Cancel'
        ).afterClosed().subscribe(res => {
            if(res) this._store.dispatch(new DeleteSite(site.key));
        });
    }

    // Navigates Bck To The dashboard
    back() {
        this._router.navigate(['/dashboard']);
    }

    // Reloads the SiteIndex
    refresh() {
        this.searchControl.setValue('', { emitEvent: false });
        this._editMode = false;
        this._store.dispatch(new LoadSiteIndex());
    }

    // Edit Boundary enters editMode
    editBoundary(model: Site, overrides?: Partial<Site>) {
        this.formGroup.reset();
        this._editMode = true;
        this.lastRelativeBounds = null;
        this._store.dispatch(new InitSiteForm(model.key, overrides || {}));

        if(overrides) {
            this.focusBounds(
                this.getAbsoluteBounds(
                    this.getLatLng(overrides), overrides.boundary
                )
            );

            this.lastRelativeBounds = overrides.boundary;
        }

        this._focus$.next(Focus.FOCUS_SELECTED);
    }

    // Exits editMode and resets formgroup
    cancelEditBoundary() {

        this.lastRelativeBounds = null;
        this.formGroup.reset();
        this._editMode = false;
        this._focus$.next(Focus.FOCUS_SELECTED);
    }

    // Saves the boundary as edited
    saveBoundary(selected: Site) {
        this.lastRelativeBounds = null;
        let form = this.formGroup.value;

        let updated: Partial<Site> = {
            boundary: this.getRelativeBounds(form.marker, form.polygon),
            lat: form.marker.lat,
            lng: form.marker.lng,
        };

        if(this._saveAreaControl.value) updated.area = form.area;
        this._store.dispatch(new SubmitSiteForm(updated));
        this._editMode = false;
    }

    // Selects site for detail
    selectKey(key: string){
        this._editMode = false;
        this._store.dispatch(new SetSelectedKey(key));
        this._focus$.next(Focus.FOCUS_SELECTED);
    }

    unselect() {
        this._store.dispatch(new SetSelectedKey(null));
    }

    // Places default marker for site and enters edit mode
    placeDefaultMarker(site: Site): void {

        this.formGroup.reset();

        let center = this.map.getCenter();

        if(!center) return;

        let def = [
            {
                lat: .001,
                lng: -.00125
            },
            {
                lat: .001,
                lng: .00125
            },
            {
                lat: -.001,
                lng: .00125
            },
            {
                lat: -.001,
                lng: -.00125
            },
        ];

        let updated: Partial<Site> = {
            lat: center.lat,
            lng: center.lng,
            boundary: def
        };

        this.editBoundary(site, updated);
    }

    // Returns true if site has bounds and false if not
    hasBounds(site: Site){
        if(site) return Array.isArray(site.boundary) && site.boundary.length > 2;
        else return false;
    }

    // Returns the relative bounds from the marker to the boundary
    getRelativeBounds(pos: LatLng, absBoundary: LatLng[]) {

        if(isArray(absBoundary) && absBoundary) {
            return absBoundary.map(latLng => {
                return {
                    lat: latLng.lat - pos.lat,
                    lng: latLng.lng - pos.lng
                };
            });
        }

        return [];
    }

    // Returns the absolute bounds of the site or returns the default boundary based on the markers location
    getAbsoluteBoundsOrDefault(site: Site): LatLng[]{

        if(this.hasBounds(site)){
            return this.getAbsoluteBounds(this.getLatLng(site), site.boundary);
        }

        let def = [
                {
                    lat: site.lat + .001,
                    lng: site.lng - .00125
                },
                {
                    lat: site.lat + .001,
                    lng: site.lng + .00125
                },
                {
                    lat: site.lat - .001,
                    lng: site.lng + .00125
                },
                {
                    lat: site.lat - .001,
                    lng: site.lng - .00125
                }
            ];

        return def;
    }

    // Returns the absolute bounds of the polygon based on the markers coordinates
    getAbsoluteBounds(position: LatLng, relativeBounds: LatLng[]): LatLng[] {

        return relativeBounds.map(bound => {
            return {
                lat: position.lat + bound.lat,
                lng: position.lng + bound.lng,
            };
        });
    }

    // Gets the sites' marker
    getMarker(site: Site): SiteMarker {

        return {
            key: site.key,
            position: {
                lat: site.lat,
                lng: site.lng
            },
            label: {
                color: '#FFFFFF',
                text: site.block[0]
            }
        };
    }

    // Returns the boundary of the site
    getPolygon(site: Site): SitePolygon{

        return {
            key: site.key,
            path: this.getAbsoluteBounds(this.getLatLng(site), site.boundary)
        };
    }
    // Returns boolean based on whether the site has a latlng or not
    hasLatLng(site: Site) : boolean{
        if(site) return !isEmpty(site.lat) && !isEmpty(site.lng);
        else return false;
    }

    // Returns boolean based on whether the site has address or not
    hasAddress(site: Site) : boolean {
        if(site) return !isEmpty(site.addrStreet) || !isEmpty(site.addrCity) || !isEmpty(site.addrCountry) || !isEmpty(site.addrRegion) || !isEmpty(site.addrCode);
        else return false;
    }

    // Returns boolean based on whether the site has spacing or not
    hasSpacing(site: Site) : boolean {
        if(site) return !isEmpty(site.colDistance) && !isEmpty(site.rowDistance);
        else return false;
    }

    // Return the latlng of an site object
    private getLatLng(site: Partial<Site>): LatLng{

        if(!site) return {
            lat: 0,
            lng: 0,
        }

        return {
            lat: site.lat,
            lng: site.lng
        };
    }

    // Focusses the map on an array of marker object
    private focusMarkers(markers: {position: LatLng}[]) {

        if(markers && isArray(markers)){
            let bounds: LatLng[] = markers.map(mark => mark.position);
            this._bounds$.next(bounds);
        }
    }

    // Focusses the map on a array of latlng coordinates
    focusBounds(bounds: LatLng[]) {

        if(bounds && isArray(bounds)) this._bounds$.next(bounds);
        else this.focusDefault();
    }

    // Focusses the map on the users' current location
    focusUser() {

        let userLocation = this.userLocation$.getValue();
        if(userLocation) {
            this.focusBounds([userLocation]);
        }
    }

    // Focusses the map on the sites currently in the index
    focusAll() {

        let markers = this._markers$.getValue()
            .map(marker => {
                return marker.position;
            });

        this.focusBounds(markers);
    }

    // Focusses the map on the default center location
    private focusDefault(){
        this._center$.next(this.DEFAULT_CENTER);
        this._zoom$.next(6);
    }

    setFormFile(file: File) {
        this.importFormGroup.get('file').setValue(file);
    }

    attemptImport() {
        if (!this.importFormGroup.valid || !this.importFormGroup.value.file) {
            this.handleErrorMessage('Invalid Input. Check your input and try again.');
            return;
        }

        let file: File = this.importFormGroup.value.file;
        this.import(file);
    }

    import(file: File) {
        this._store.dispatch(new ImportSiteIndex(file));
    }

    downloadImportTemplate() {
        this._store.dispatch(new DownloadSiteImportTemplate());
    }

    export() {
        let exportOptions = this.exportFormGroup.value;
        this.exportFormGroup.disable();

        this._store.dispatch(new ExportSiteIndex(exportOptions.type))
            .subscribe(
                s => {
                    this.exportFormGroup.enable();
                    let exp = this._store.selectSnapshot(SiteIndexState.latestExport);

                    if (exp && exp.status === Status.COMPLETE) {
                        this._snackbar.info(
                            'Export is queued. The result will be emailed to you once the export is complete.'
                        );
                    }
                },
                e => {
                    this.exportFormGroup.enable();
                }
            );
    }

    exportHistory(orgKey: string) {
        const data: ExportHistoryDialogData = {
            orgKey: orgKey,
            type: ExportType.SITES,
        }

        this._dialog.open(ExportHistoryDialog, { data })
    }

    private handleErrorMessage(message: string) {
        this._snackbar.error(message);
    }

    // Complete BS
    ngOnDestroy(){
        this.userLocation$.complete();
        this._geoMarkers$.complete();
        this._polygons$.complete();
        this._markers$.complete();
        this._bounds$.complete();
        this._center$.complete();
        this._zoom$.complete();
        this._destroy$.next();
        this._destroy$.complete();
    }
}