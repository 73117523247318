import { Measurement } from "./_types";



export const LIB_MEASURES_GRAPES: Measurement[] = [

    // Plant Sample Measurements and Counts

    {
        id: "vine_tdia",
        unit: "mm",
        label: "Trunk Diameter",
        tag: "TDIA",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_vine_dia_std",
        format: "####mm",
        min: 0,
        max: 1000,
        suggestMin: 1,
        suggestMax: 3000,
        subjectId: "vine",
    },
    {
        id: "vine_bcnt",
        unit: "#",
        label: "Total Bunch Count",
        tag: "BCNT",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_bnch_cnt",
        format: "##0",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 999,
        subjectId: "vine",
    },
    {
        id: "vine_yiel",
        unit: "kg",
        label: "Total Yield",
        tag: "YIEL",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_yie_std",
        format: "####kg",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "vine",
    },
    {
        id: "prec_bcnt",
        unit: "#",
        label: "Number of Bunches",
        tag: "NOB",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_nob_std",
        format: "##0",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "pre_cropping",
    },
    {
        id: "prec_scnt",
        unit: "#",
        label: "Number of Shoots",
        tag: "NOS",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_nos_std",
        format: "##0",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "pre_cropping",
    },

    // Vine Shoots
    {
        id: "vsho_len",
        unit: "m",
        label: "Shoot Length",
        tag: "SLN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_cir_std",
        format: "####m",
        min: 0,
        max: 10,
        suggestMin: 1,
        suggestMax: 10,
        subjectId: "vine_shoot",
    },
    {
        id: "vsho_bcnt",
        unit: "#",
        label: "Shoot Bunch Count",
        tag: "BNU",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_tree_dep_std",
        format: "##0",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "vine_shoot",
    },


    // Bunches / Berries

    {
        id: "bery_len",
        unit: "mm",
        label: "Berry Length",
        tag: "BLN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_len_i2",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "rais_len",
        unit: "mm",
        label: "Raisin Length",
        tag: "RLN",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_rais_len_i1",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "raisin",
    },
    {
        id: "bery_dia",
        unit: "mm",
        label: "Berry Diameter",
        tag: "BDI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_dia_i1",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "rais_dia",
        unit: "mm",
        label: "Raisin Diameter",
        tag: "RDI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_rais_dia_i1",
        format: "####mm",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "raisin",
    },
    {
        id: "bery_wei",
        unit: "g",
        label: "Berry Weight",
        tag: "BWE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_wei_i2",
        format: "####g",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 999,
        subjectId: "berry",
    },
    {
        id: "rais_wei",
        unit: "g",
        label: "Raisin Weight",
        tag: "RWE",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_rais_wei_i05",
        format: "####g",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 999,
        subjectId: "raisin",
    },
    {
        id: "bery_rthi",
        unit: "mm",
        label: "Rachis Thickness",
        tag: "GDI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_rthi_i1",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",

    },
    {
        id: "bery_pthi",
        unit: "mm",
        label: "Cap Stem Thickness",
        tag: "GDI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bery_pthi_i05",
        format: "####mm",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "bnch_wei",
        unit: "g",
        label: "Bunch Weight",
        tag: "GDI",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bnch_wei_i02",
        format: "####g",
        min: 0,
        max: 9999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "bunch",
    },
    {
        id: "bnch_tss",
        unit: "%",
        label: "TSS",
        tag: "TSS",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bnch_tss_i02",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "bunch",
    },
    {
        id: "bnch_amal",
        unit: "%",
        label: "Acidity",
        tag: "TMA",
        defaultChart: "chart_line_avg",
        defaultBucket: "bucket_bnch_amal_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "bunch",
    },
    {
        id: "bery_firm",
        unit: "kg",
        label: "Berry Firmness",
        tag: "FIR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_firm_i01",
        format: "###0.00kg",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "bery_sugar_acid_ratio",
        unit: "%",
        label: "Sugar Acid Ratio",
        tag: "SAR",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_frut_sugar_acid_ratio",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 100,
        subjectId: "bunch",
    },
    {
        id: "bery_volume",
        unit: "cm3",
        label: "Berry Volume",
        tag: "BVO",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_volume_i1",
        format: "###0.00cm3",
        min: 0,
        max: 999,
        suggestMin: 0,
        suggestMax: 9999,
        subjectId: "berry",
    },
    {
        id: "bnch_bery_count",
        unit: "#",
        label: "Berries Per Bunch",
        tag: "BPB",
        defaultChart: "chart_bar_buckets",
        defaultBucket: "bucket_bnch_bery_count_10",
        format: "##0",
        min: 0,
        max: 500,
        suggestMin: 0,
        suggestMax: 500,
        subjectId: "bunch",
    },
    {
        id: "bery_amal",
        unit: "%",
        label: "Acidity (Malic)",
        tag: "BMA",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_amal_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "berry",
    },
    {
        id: "bery_atar",
        unit: "%",
        label: "Acidity (Tartaric)",
        tag: "BTA",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_atar_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "berry",
    },
    {
        id: "bery_acit",
        unit: "%",
        label: "Acidity (Citric)",
        tag: "BCA",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_acit_i01",
        format: "###0.0%",
        min: 0,
        max: 100,
        suggestMin: 0,
        suggestMax: 10,
        subjectId: "berry",
    },
    {
        id: "bery_ph",
        unit: "pH",
        label: "Berry pH",
        tag: "PH",
        defaultChart: "chart_candle_sd",
        defaultBucket: "bucket_bery_ph_i01",
        format: "pH###",
        min: 0,
        max: 14,
        suggestMin: 0,
        suggestMax: 14,
        subjectId: "berry",
    }
];