import { NestedTreeControl } from "@angular/cdk/tree";
import { of } from "rxjs";
import { SampleDataNode } from "./report-sample-data-node";

export class ReportSampleTreeControl extends NestedTreeControl<SampleDataNode> {

    // selectionModel = new SelectionModel<string>(true);
    // selectionChange$ = new Subject<any>();

    constructor(){
        super(node => of(node.children));
    }

    nodeIsSample(_: number, _nodeData: SampleDataNode){
        return _nodeData.type === 'sample';
    }

    nodeIsEval(_: number, _nodeData: SampleDataNode){
        return _nodeData.type === 'eval';
    }

    nodeIsImage(_: number, _nodeData: SampleDataNode){
        return _nodeData.type === 'image';
    }

    nodeIsNote(_: number, _nodeData: SampleDataNode){
        return _nodeData.type === 'note';
    }

    nodeHasChildren(node: SampleDataNode){
        return node.children.length !== 0;
    }
}