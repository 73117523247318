<pv-view toolbarTitle="Site Map" [returnTo]="['..']" class="site-index-view">

    <!-- TOOLS -->
    <div pv-view-tools>

        <!-- EXPORT HISTORY -->
        <ng-container *ngIf="state$ | async as state">
            <button
                matTooltip="View Export History"
                mat-icon-button
                (click)="exportHistory(state.orgKey)">
                <mat-icon>history</mat-icon>
            </button>
        </ng-container>

        <!-- ADD SITE -->
        <button [disabled]="_editMode"
                mat-icon-button
                (click)="add()"
                matTooltip="Create A New Site">
            <mat-icon>add_location</mat-icon>
        </button>

        <!-- FOCUS ALL -->
        <button [disabled]="_editMode"
                mat-icon-button
                (click)="focusAll()"
                matTooltip="Focus All Sites">
            <mat-icon>zoom_out_map</mat-icon>
        </button>

        <!-- FOCUS USER -->
        <button mat-icon-button (click)="focusUser()" matTooltip="Focus On Current Location">
            <mat-icon>my_location</mat-icon>
        </button>

        <!-- REFRESH SITES -->
        <button mat-icon-button (click)="refresh()" matTooltip="Refresh Site Map">
            <mat-icon>refresh</mat-icon>
        </button>

    </div>

    <!-- SIDEBAR -->
    <mat-tab-group pv-view-sidebar *ngIf="!_editMode" class="site-index-sidebar">

        <!-- FILTER TAB -->
        <mat-tab label="Filter">
            <ng-template matTabContent>
                <!-- SEARCH -->
                <form [formGroup]="searchGroup" class="pv-form single-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Search</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" matInput formControlName="search">
                        <mat-hint>Search for a site...</mat-hint>
                    </mat-form-field>
                </form>

                <mat-divider></mat-divider>

                <ng-container *ngIf="state$ | async as state" [ngSwitch]="state.status">

                    <!-- STATUS LOADING -->
                    <pv-view-loader *ngSwitchCase="'LOADING'" message="Loading Sites..."></pv-view-loader>

                    <!-- STATUS OK -->
                    <ng-container *ngSwitchCase="'OK'">

                        <!-- SITES -->
                        <div class="site-list">
                            <mat-action-list dense color="primary">
                                <button *ngFor="let site of state.data" mat-list-item
                                        (click)="selectKey(site.key)"
                                        [ngClass]="{ 'selected-site': checkIfSelected(site.key) }">
                                    <mat-icon mat-list-icon>
                                        {{ hasLatLng(site) ? 'place' : 'not_listed_location' }}</mat-icon>
                                    <span mat-line>{{ site.block }}</span>
                                    <span mat-line>{{ site.name }}</span>
                                </button>
                            </mat-action-list>
                        </div>

                    </ng-container>

                    <!-- EMPTY -->
                    <ng-container *ngSwitchCase="'EMPTY'">
                        <div class="sidebar-error">
                            <h3>No Results</h3>
                            <p>Your search query does not match any Sites. Add a Site or view all Sites.</p>
                            <div align="end">
                                <button mat-button (click)="add()">Create Site</button>
                                <button mat-raised-button color="primary" (click)="refresh()">View All
                                    Sites</button>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </ng-container>

                    <!-- ERRORS -->
                    <ng-container *ngSwitchDefault>
                        <div class="sidebar-error">
                            <h3>{{ state.status | descStatusTitle }}</h3>
                            <p>{{ state.status | descStatusMessage: 'Sites' }}</p>
                            <div align="end">
                                <button pvMessageAction mat-button (click)="back()">Back</button>
                                <button pvMessageAction mat-raised-button color="accent"
                                        (click)="refresh()">Refresh</button>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </ng-container>

                </ng-container>
            </ng-template>
        </mat-tab>

        <!-- IMPORT & EXPORT TAB -->
        <mat-tab label="Import/Export">
            <ng-template matTabContent>
                <ng-container>
                    <form [formGroup]="exportFormGroup" class="pv-form single-column p-12" (ngSubmit)="export()">
                        <h4 class="form-subheader">Export</h4>

                        <mat-form-field appearance="standard">
                            <mat-label>File Type</mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="xlsx">Microsoft Excel (.xlsx)</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-actions">
                            <button mat-flat-button color="accent" type="submit"
                                [disabled]="exportFormGroup.disabled">Export</button>
                        </div>
                    </form>
                </ng-container>
                <ng-container>
                    <form [formGroup]="importFormGroup" class="pv-form single-column p-12" (ngSubmit)="attemptImport()">
                        <h4 class="form-subheader">Import</h4>

                        <pv-import-upload (file)="setFormFile($event)"></pv-import-upload>

                        <div class="form-actions">
                            <button mat-flat-button color="accent"
                                (click)="$event.preventDefault(); downloadImportTemplate()"
                                [disabled]="importFormGroup.disabled">Import Template</button>
                            <button mat-flat-button color="accent" type="submit"
                                [disabled]="importFormGroup.disabled">Import</button>
                        </div>
                    </form>
                </ng-container>
            </ng-template>
        </mat-tab>

    </mat-tab-group>

    <!-- BODY -->
    <div pv-view-body>

        <!-- Selected Site -->
        <ng-container *ngIf="selected$ | async as site">
            <mat-card class="card-map-overlay" *ngIf="form$ | async as form">

                <!-- CARD HEADER -->
                <mat-card-header>

                    <!-- Status Spinner -->
                    <mat-spinner mat-card-avatar *ngIf="form.status === 'LOADING'; else avatar"
                                diameter="40" color="accent"></mat-spinner>
                    
                    <!-- Location Icon -->
                    <ng-template #avatar>
                        <mat-icon mat-card-avatar>place</mat-icon>
                    </ng-template>

                    <div class="selected-site-wrapper">

                        <!-- Card Title -->
                        <div>
                            <mat-card-title>{{ site.block }}</mat-card-title>
                            <mat-card-subtitle>{{ site.name }}</mat-card-subtitle>
                        </div>

                        <!-- Selected Site Close Button -->
                        <div>
                            <button mat-icon-button class="close-overlay" (click)="unselect()">
                                <mat-icon class='mediumIcon'>close</mat-icon>
                            </button>
                        </div>

                    </div>


                </mat-card-header>

                <mat-divider></mat-divider>

                <!-- Edit Mode -->
                <ng-container *ngIf="_editMode">
                    <mat-card-content>
                        <p *ngIf="(_markerControl.value | descSiteCoords) as coords">
                            Center: {{ coords }}
                        </p>

                        <!-- Save Control -->
                        <mat-checkbox [formControl]="_saveAreaControl">
                            Use calculated area: <span [innerHtml]="_areaControl.value | area"></span>
                        </mat-checkbox>

                        <!-- Caption -->
                        <p class="mat-caption">
                            Drag the boundary to move the site to a new location.
                            Drag the polygon corners and add new polygon points to set
                            the block boundary. The marker will always move to the polygon centroid.
                        </p>

                    </mat-card-content>

                    <mat-card-actions align="end">
                        <button mat-button *ngIf="_editMode"
                                (click)="cancelEditBoundary()">
                            Cancel
                        </button>
                        <button mat-flat-button
                                [disabled]="form.status === 'LOADING'"
                                *ngIf="_editMode" color="primary"
                                (click)="saveBoundary(site)">
                            Save Boundary
                        </button>
                    </mat-card-actions>
                </ng-container>

                <!-- Preview Mode -->
                <ng-container *ngIf="!_editMode">
                    <mat-card-content class="site-mat-card-content">

                        <p *ngIf="hasLatLng(site) else noLatLongMessage">
                            <strong>Coordinates: </strong><small [innerHtml]="site | descSiteCoords"></small>
                        </p>
                        <ng-template #noLatLongMessage>
                            <small class="mat-caption">
                                No position has been set for this location.
                                Select "Place Marker" to position the site in the map center.
                            </small>
                        </ng-template>

                        <p><strong>Area: </strong><small [innerHtml]="site.area | area"></small></p>
                        <p><strong>Spacing: </strong><small [innerHtml]="site | descSpacing"></small></p>
                        <p><strong>Address: </strong><small [innerHtml]="site | descSiteAddress"></small></p>

                    </mat-card-content>

                    <mat-card-actions align="end">

                        <button mat-button (click)="edit(site)" matTooltip="Edit Location Information">
                            <mat-icon class="largeIcon" aria-hidden="false" aria-label="edit location" fontIcon="edit_location">edit_location</mat-icon>
                        </button>

                        <button mat-button (click)="delete(site)" matTooltip="Remove Location">
                            <mat-icon class="largeIcon" aria-hidden="false" aria-label="wrong location" fontIcon="wrong_location">wrong_location</mat-icon>
                        </button>

                        <button *ngIf="site | descSiteCoords as coords else placeMarkerButton"
                                [disabled]="form.status === 'LOADING'"
                                mat-flat-button color="accent"
                                matTooltip="Edit Location Boundary"
                                color="primary"
                                (click)="editBoundary(site)">
                                <mat-icon class="largeIcon" aria-hidden="false" aria-label="edit boundary" fontIcon="edit_location">edit_location_alt</mat-icon>
                        </button>

                        <ng-template #placeMarkerButton>
                            <button
                                    [disabled]="form.status === 'LOADING'"
                                    mat-flat-button color="accent"
                                    (click)="placeDefaultMarker(site)">
                                Place Marker
                            </button>
                        </ng-template>

                    </mat-card-actions>

                </ng-container>


            </mat-card>

            <div class="site-index-map-target" *ngIf="!(site | descSiteCoords) && !_editMode">
                <mat-icon>add</mat-icon>
            </div>

        </ng-container>

        <!-- MAP -->
        <pv-map
                #map
                class="map-component"
                mapType="hybrid">

            <!-- USER LOCATION -->
            <pv-map-marker
                           *ngIf="userLocation$ | async as userLocation"
                           icon="/assets/markers/marker_user.svg"
                           [position]="userLocation">
            </pv-map-marker>

            <!-- EDIT MODE -->
            <ng-container *ngIf="_editMode" [formGroup]="formGroup">

                <!-- EDIT POLYGON -->
                <pv-map-polygon
                                #editablePolygon
                                formControlName="polygon"
                                [editable]="true"
                                [draggable]="true"
                                fillColor="#90A4AE"
                                strokeColor="#90A4AE">
                    <pv-map-polygon-area formControlName="area"></pv-map-polygon-area>
                </pv-map-polygon>

                <!-- EDIT MARKER -->
                <pv-map-marker
                               formControlName="marker"
                               icon="/assets/markers/marker_accent.svg"
                               [draggable]="true">
                </pv-map-marker>

            </ng-container>

            <!-- NON EDIT MODE -->
            <ng-container *ngIf="!_editMode">

                <!-- MARKERS -->
                <ng-container *ngIf="_markers$ | async as markers">
                    <pv-map-marker
                                   *ngFor="let marker of markers"
                                   [position]="marker.position"
                                   [label]="marker.label"
                                   (click)="selectKey(marker.key)"
                                   icon="/assets/markers/marker_primary.svg">
                    </pv-map-marker>
                </ng-container>

                <!-- POLYGONS -->
                <ng-container *ngIf="_polygons$ | async as polygons">
                    <pv-map-polygon
                                    *ngFor="let poly of polygons"
                                    [path]="poly.path"
                                    fillColor="#559659"
                                    strokeColor="#559659">
                    </pv-map-polygon>
                </ng-container>

            </ng-container>

        </pv-map>
    </div>
</pv-view>
