import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Status, Tag } from "@core/data";
import { Select, Store } from "@ngxs/store";
import { InitNewTastingCustomTagsForm, InitUpdateTastingCustomTagsForm, SubmitTastingCustomTagsForm, TastingCustomTagsFormState, TastingCustomTagsFormStateModel } from "./tasting-custom-tags-form.state";
import { Observable, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Snackbar } from "@core/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";

export interface TastingCustomTagsFormDialogData {
    key?: string;
    defaults?: Partial<Tag>;
}

@Component({
    selector: 'pv-tasting-custom-tags-form-dialog',
    templateUrl: 'tasting-custom-tags-form.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
})
export class TastingCustomTagsFormDialog implements OnInit, OnDestroy {

    @Select(TastingCustomTagsFormState)
    state$: Observable<TastingCustomTagsFormStateModel>;

    @Select(TastingCustomTagsFormState.data)
    data$: Observable<Partial<Tag>>;

    // FORM CONTROLS
    labelControl = new FormControl(null, [Validators.required]);
    ownerOrgKeyControl = new FormControl(null);

    formGroup: FormGroup = new FormGroup({
        label: this.labelControl,
        ownerOrgKey: this.ownerOrgKeyControl
    });

    private _destroy$ = new Subject();

    constructor(
        private _store: Store,
        private _dialogRef: MatDialogRef<TastingCustomTagsFormDialog>,
        private _snackBar: Snackbar,
        @Inject(MAT_DIALOG_DATA) public data: TastingCustomTagsFormDialogData,
    ) {}

    ngOnInit(): void {
        if (this.data.key) this._store.dispatch(new InitUpdateTastingCustomTagsForm(this.data.key));
        else this._store.dispatch(new InitNewTastingCustomTagsForm(this.data.defaults));

        this.state$.pipe(takeUntil(this._destroy$))
            .subscribe(state => {
                if (state.status === Status.COMPLETE) this._dialogRef.close(state.data);
                else if (state.status !== Status.LOADING) this.formGroup.enable();
                else this.formGroup.disable();
            });

        this.data$.pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                if (data) this.reset(data);
            });
    }

    reset(model: Partial<Tag>): void {
        let data = {
            label: model.label || null,
            ownerOrgKey: model.ownerOrgKey
        };

        this.formGroup.reset(data);
    }

    cancel(): void {
        this._dialogRef.close();
    }

    attempt(): void {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();

        if (this.formGroup.valid) {
            this.save()
        } else {
            this._snackBar.error('Invalid Input. Check your input and try again.');
        }
    }

    save(): void {
        const form = this.formGroup.value;

        const data: Partial<Tag> = {
            label: form.label,
            ownerOrgKey: form.ownerOrgKey,
        };

        this._store.dispatch(new SubmitTastingCustomTagsForm(data));
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

}