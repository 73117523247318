import { Injectable } from "@angular/core";
import { DetailRequest, Status, TastingEventService, translateCommonErrorStatus } from "@core/data";
import { TastingEvent } from "@core/data/types/tastings-event";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

export interface TastingEventsFormStateModel {
    key: string;
    status: Status;
    data: Partial<TastingEvent>;
}

const DEFAULTS: TastingEventsFormStateModel = {
    key: null,
    status: Status.UNINITIALIZED,
    data: null,
}

export class InitNewTastingEventsForm {
    static readonly type = "[TastingEventsForm] Init new";
    constructor(public defaults?: Partial<TastingEvent>) {}
}

export class InitUpdateTastingEventsForm {
    static readonly type = "[TastingEventsForm] Init update";
    constructor(public key: string) {}
}

export class SubmitTastingEventsForm {
    static readonly type = "[TastingEventsForm] Submit";
    constructor(public data: Partial<TastingEvent>) {}
}

@State<TastingEventsFormStateModel>({
    name: 'tasting_events_form',
    defaults: DEFAULTS
})
@Injectable()
export class TastingEventsFormState {
    @Selector()
    static data(state: TastingEventsFormStateModel) {
        return state.data;
    }

    constructor(private _tastingEventsService: TastingEventService) {}

    @Action(InitNewTastingEventsForm, {cancelUncompleted: true})
    initNewTastingEventsForm(ctx: StateContext<TastingEventsFormStateModel>, action: InitNewTastingEventsForm) {
        ctx.setState({
            key: null,
            data: {
                ...action.defaults
            },
            status: Status.OK
        });
    }

    @Action(InitUpdateTastingEventsForm, {cancelUncompleted: true})
    initUpdateTastingEventsForm(ctx: StateContext<TastingEventsFormStateModel>, action: InitUpdateTastingEventsForm) {
        ctx.setState({
            key: action.key,
            data: null,
            status: Status.LOADING
        });

        let detailRequest: DetailRequest = {
            related: [
                'indexSnapshot'
            ]
        }

        return this._tastingEventsService.get(action.key, detailRequest)
            .pipe(
                tap(
                    result => {
                        ctx.setState({
                            key: result.key,
                            data: result,
                            status: Status.OK
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }

    @Action(SubmitTastingEventsForm)
    submitTastingEventsForm(ctx: StateContext<TastingEventsFormStateModel>, action: SubmitTastingEventsForm) {
        const state = ctx.getState();

        let request: Observable<TastingEvent>;

        if (state.key) request = this._tastingEventsService.update(state.key, { ...action.data });
        else request = this._tastingEventsService.create({ ...action.data });

        ctx.patchState({
            status: Status.LOADING,
        });

        return request
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            key: result.key,
                            status: Status.COMPLETE,
                            data: result
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            )
    }
}