import { Index, IndexRescaleStrategy } from './_types';

export const LIB_INDEXES: Index[] = [
    {
        id: "indx_frut_eating_quality",
        label: "Eating Quality",
        description: "Combines fruit sensory characteristics to score 'Eating Quality'.",
        chars: [
            { charId: "frut_taste_sugar", weight: 100 },
            { charId: "frut_taste_acid", weight: 100 },
            { charId: "frut_taste_aroma", weight: 100 },
            { charId: "frut_taste_juice", weight: 100 },
            { charId: "frut_text_firm", weight: 100 }
        ]
    },
    {
        id: "indx_frut_overall",
        label: "Overall Score - Fruit",
        description: "Subjective sample impression.",
        chars: [
            { charId: "frut_overall_keepqual", weight: 100 },
            { charId: "frut_overall_appear", weight: 100 },
            { charId: "frut_overall_color", weight: 100 },
            { charId: "frut_overall_texture", weight: 100 },
            { charId: "frut_overall_taste", weight: 100 },
            { charId: "frut_overall_eatqual", weight: 100 },
            { charId: "frut_overall_interest", weight: 100 }
        ]
        
    },
    {
        id: "indx_nut_overall",
        label: "Overall Score - Nut",
        description: "Subjective sample impression.",
        chars: [
            { charId: "nut_overall_keeping", weight: 100 },
            { charId: "nut_overall_appear", weight: 100 },
            { charId: "nut_overall_roast", weight: 100 },
            { charId: "nut_overall_taste", weight: 100 },
            { charId: "nut_overall_interest", weight: 100 },
        ]
    },
    {
        id: "indx_vine_adapt",
        label: "Vine Adaptability Index",
        description: "Vine adaptability score.",
        chars: [
            {
                charId: "vine_flower_bunch_thin",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "vine_berry_shot",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "vine_berry_thin",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "vine_fertile",
                weight: 100
            },
            {
                charId: "vine_overall_adapt",
                weight: 100
            },
        ]
    },
    { 
        id: "indx_pome_adapt",
        label: "Pome Tree Adaptability Index",
        description: "Pome Tree adaptability score.",
        chars: [
            {
                charId: "tree_dormant_symptom",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "tree_flower_span",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "tree_uniform_ripe",
                weight: 100,
            },
            {
                charId: "tree_overall_product",
                weight: 100
            },
            {
                charId: "tree_sunburn_symptom",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "tree_overall_adapt",
                weight: 100
            },
        ]
    },
    { 
        id: "indx_stone_adapt",
        label: "Stone Tree Adaptability Index",
        description: "Stone Tree adaptability score.",
        chars: [
            {
                charId: "tree_dormant_symptom",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "tree_flower_span",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "tree_uniform_ripe",
                weight: 100,
            },
            {
                charId: "tree_overall_product",
                weight: 100
            },
            {
                charId: "tree_sunburn_symptom",
                weight: 100,
                rescale: IndexRescaleStrategy.NEGATIVE
            },
            {
                charId: "tree_root_grow_sync",
                weight: 100
            },
            {
                charId: "tree_overall_adapt",
                weight: 100
            },
        ]
    },
];