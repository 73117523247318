import { Module } from "./_types"

export const CULTEVA_MODULES: Module[] = [ 
    //Evaluation Module
    { 
        id: 'evaluation_module', 
        name: 'Evaluation', 
        description: 'Culteva Evaluation Module', 
        
        price: 0, 
        
        iconLib: 'common', 
        iconRef: 'eval_module'
    },
    //Tastings Module
    { 
        id: 'tastings_module',   
        name: 'Tastings',   
        description: 'Culteva Tastings Module',   
        
        price: 0, 
        
        iconLib: 'common', 
        iconRef: 'tastings_module'
    },
    // //Breeders Module
    // { 
    //     id: 'breeding_module',   
    //     name: 'Breeding',   
    //     description: 'Culteva Breeding Module',   
        
    //     price: 0, 
        
    //     iconLib: 'common', 
    //     iconRef: 'breeding_module'
    // },
];



