<ng-container *ngIf="data$ | async as data">
    <section class="subject-section" *ngFor="let table of data.tables; trackBy: trackTable">
        <h4 class="subject-header">{{ table.subject.label }}</h4>
        <table class="subject-table">
            <thead>
                <tr>
                    <th>Index</th>
                    <th *ngFor="let measure of table.measures">{{ measure.label }} <small>[{{ measure.unit }}]</small></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!table.rows || table.rows.length === 0">
                    <td [attr.colspan]="table.measures.length + 1">No {{ table.subject.label }} measurements.</td>
                </tr>
                <tr *ngFor="let row of table.rows; index as i">
                    <td>{{ i + 1 }}</td>
                    <td *ngFor="let value of row">{{ value ? (value | number:'1.1-3') : '' }}</td>
                </tr>
            </tbody>
        </table>
    </section>
</ng-container>