import { WeatherSensor } from "./_types";

export const LIB_WEATHER_SENSORS: WeatherSensor[] = [
    {
        id: 'tair',
        label: 'Air Temperature',
        unit: '\u00B0C',
        format: '##0.00\u00B0C'
    },
    {
        id: 'tmax',
        label: 'Maximum Air Temperature',
        unit: '\u00B0C',
        format: '##0.00\u00B0C'
    },
    {
        id: 'tmin',
        label: 'Minimum Air Temperature',
        unit: '\u00B0C',
        format: '##0.00\u00B0C'
    },
    {
        id: 'tsoi',
        label: 'Soil Temperature',
        unit: '\u00B0C',
        format: '##0.00\u00B0C'
    },
    {
        id: 'hurh',
        label: 'Humidity',
        unit: '%',
        format: '##0.00%'
    },
    {
        id: 'tdpt',
        label: 'Dew Point',
        unit: '\u00B0C',
        format: '##0.00\u00B0C'
    },
];