import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Library } from '@library';
import { LIBRARY } from '../library';

@Pipe({
    name: 'labelMeasure'
})
export class LabelMeasurePipe implements PipeTransform {

    constructor(@Inject(LIBRARY) private _library: Library){}

    transform(measureId:string): any {
        let measure = this._library.measures.get(measureId);
        if(measure){
            return measure.label;
        }
        return '';
    }
}
