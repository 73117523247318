<ng-container *ngIf="state$ | async as state">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">
            <span>Add&nbsp;Tastings Evaluation</span>
        </h2>
        <h3 class="dialog-subtitle">
            Organization:
            <pv-org-control [formControl]="ownerOrgKeyControl"></pv-org-control>
        </h3>
    </div>

    <div mat-dialog-content>
        <form id="tasting_evaluation_form"
              #tastingEvaluationForm="ngForm"
              [formGroup]="formGroup"
              class="pv-form"
              (ngSubmit)="attempt()"
              novalidate
              autocomplete="no">

            <div class="form-column">
                <div *ngIf="isOfTypeEvent(data.subjectType)">
                    <h4>Sample Selection</h4>
                    <mat-form-field>
                        <mat-label>Sample Select</mat-label>
                        <mat-select formControlName="tastingsSampleKey">
                            <mat-option *ngFor="let sample of state.samples" [value]="sample.key">
                                {{sample.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ng-container formGroupName="scores">
                    <ng-container formGroupName="user">
                        <h4>User Info</h4>
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" type="email" [value]="userEmailControl.value">
                            <mat-error>Invalid Email <address></address></mat-error>
                        </mat-form-field>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-column tastings-column">
                <ng-container formGroupName="scores">
                    <mat-tab-group color="accent">
                        <mat-tab formArrayName="characteristics" *ngFor="let char of formScores().controls; let i = index">
                            <ng-template mat-tab-label>
                                {{ char.value.id | descCharacteristic: baseIndex }}
                            </ng-template>
                            <div class="tastings-tab-body" [formGroupName]="i">
                                <!--* SLIDER START -->
                                <div class="tastings-heading-wrapper">
                                    <h4>Score</h4>
                                    <h4>{{ char.value.value !== -1 ? char.value.value +'/'+ baseIndex.range : 'Unscored'}}</h4>
                                </div>
                                <div class="tastings-input-wrapper">
                                    <mat-slider
                                        class="tastings-slider taste-slider"
                                        color="accent"
                                        [max]="baseIndex.range"
                                        min="0"
                                        step="1"
                                        thumbLabel="value"
                                        tickInterval="1"
                                        [value]="char.value.value"
                                        (change)="char.value.value = $event.value"
                                        formControlName="value">
                                    </mat-slider>
                                    <button
                                        class="tastings-button-theme"
                                        mat-raised-button
                                        (click)="$event.preventDefault(); char.value.value = -1;"
                                    >
                                        Set as Unscored
                                    </button>
                                </div>
                                <!--* SLIDER END -->

                                <!--* TAGS START -->
                                <h4>Tags</h4>
                                <div class="tastings-input-wrapper">
                                    <ng-container class="tastings-tags-wrapper">
                                        <mat-chip-list [multiple]="true" [selectable]="true">
                                            <div *ngIf="!char.value?.tags?.length">
                                                This characteristic does not have any tags
                                            </div>
                                            <mat-chip [class.tastings-tag-theme]="tag.value" formArrayName="tags" *ngFor="let tag of char.value.tags; let idx = index"
                                                [selected]="tag.value"
                                                (click)="tag.value = !tag.value"
                                            >
                                                {{ tag.id | descTags: baseIndex : i }}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </ng-container>
                                </div>
                                <!--* TAGS END -->

                                <!--* COMMENT START -->
                                <h4>Comment</h4>
                                <div class="tastings-input-wrapper">
                                    <mat-form-field>
                                        <textarea
                                            formControlName="comment"
                                            matInput
                                            [value]="char.value.comment"
                                            rows="5">
                                        </textarea>
                                        <mat-placeholder>Add a Comment...</mat-placeholder>
                                    </mat-form-field>
                                </div>
                                <!--* COMMENT END -->
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </ng-container>
            </div>
        </form>
    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingEvaluationForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>