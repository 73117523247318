import { Pipe, PipeTransform } from "@angular/core";
import { COUNTRIES } from "@core/utils";

@Pipe({
    name: 'country'
})
export class CountryPipe implements PipeTransform {
    countries: {code: string, label: string}[] = [];

    constructor() {
        this.countries = COUNTRIES;
    }

    transform(countryCode: string): string {
        if (!countryCode) return 'N/A';
        return this.countries.find(country => country.code === countryCode).label;
    }
}