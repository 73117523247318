import { mapInterval } from "./number";
import { isString } from "util";

// See https://css-tricks.com/converting-color-spaces-in-javascript/

export class HSL {

    constructor(public h: number, public s: number, public l: number) { }

    static fromObject(obj: { h: number, s: number, l: number }) {
        return new HSL(obj.h, obj.s, obj.l);
    }

    static fromString(value: string) {
        if (isString(value)) {
            let matches = value.match(HSL_REGEX);
            if (matches !== null && matches.length === 4) {
                return new HSL(
                    parseInt(matches[1]),
                    parseInt(matches[2]),
                    parseInt(matches[3])
                );
            }
        }
        return null;
    }

    static average(values: HSL[]): HSL {
        let hy = 0, hx = 0, h = 0, s = 0, l = 0;
        values.forEach(value => {
            hy += Math.sin(value.h / 180 * Math.PI);
            hx += Math.cos(value.h / 180 * Math.PI);
            s += value.s;
            l += value.l;
        });
        h = Math.atan2(hy / values.length, hx / values.length) * 180 / Math.PI;
        s /= values.length;
        l /= values.length;

        return new HSL(Math.round(h), Math.round(s), Math.round(l));
    }

    isValid() {
        return this.h >= 0 && this.h <= 360 &&
            this.s >= 0 && this.s <= 100 &&
            this.l >= 0 && this.l <= 100;
    }

    toString() {
        return `hsl(${this.h},${this.s}%,${this.l}%)`;
    }

    describe() {
        if (!this.isValid()) return 'Invalid Color';

        if (this.l === 0) return 'Black';
        if (this.l === 100) return 'White';

        let hue = this.hueLabel();
        let sat = this.saturationLabel();
        let lig = this.lightnessLabel();

        return `${sat}, ${lig} ${hue}`;

    }

    hueLabel() {

        if (this.s === 0) return 'Gray';

        let index = HUE_VALUES.findIndex(ab => {
            return this.h >= ab[0] && this.h < ab[1];
        });
        return HUE_LABELS[index];
    }

    lightnessLabel() {
        return mapInterval(this.l, LIGHTNESS_INTERVALS);
    }

    saturationLabel() {
        return mapInterval(this.s, SATURATION_INTERVALS);
    }

    toRGB() {
        // Must be fractions of 1
        let h = this.h;
        let s = this.s / 100;
        let l = this.l / 100;

        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2,
            r = 0,
            g = 0,
            b = 0;

        if (0 <= h && h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x;
        }
        r = Math.round((r + m) * 255);
        g = Math.round((g + m) * 255);
        b = Math.round((b + m) * 255);

        return new RGB(r, g, b);

    }

    equals(value: any) {
        if (value instanceof HSL) {
            return value.h === this.h
                && value.s === this.s
                && value.l === this.l;
        }
        return false;
    }

}

export class RGB {

    constructor(public r: number, public g: number, public b: number) { }

    static fromHex(h: string) {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (h.length == 4) {
            r = parseInt("0x" + h[1] + h[1]);
            g = parseInt("0x" + h[2] + h[2]);
            b = parseInt("0x" + h[3] + h[3]);
            // 6 digits
        } else if (h.length == 7) {
            r = parseInt("0x" + h[1] + h[2]);
            g = parseInt("0x" + h[3] + h[4]);
            b = parseInt("0x" + h[5] + h[6]);
        }

        if (isNaN(r) || isNaN(g) || isNaN(b)) {
            return null;
        }

        return new RGB(r, g, b);
    }

    toHSL() {

        // Make r, g, and b fractions of 1
        let r = this.r / 255;
        let g = this.g / 255;
        let b = this.b / 255;

        // Find greatest and smallest channel values
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        // Calculate hue
        // No difference
        if (delta == 0) h = 0;
        // Red is max
        else if (cmax == r) h = ((g - b) / delta) % 6;
        // Green is max
        else if (cmax == g) h = (b - r) / delta + 2;
        // Blue is max
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        // Make negative hues positive behind 360°
        if (h < 0) h += 360;

        // Calculate lightness
        l = (cmax + cmin) / 2;

        // Calculate saturation
        s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

        // Multiply l and s by 100
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return new HSL(Math.round(h), Math.round(s), Math.round(l));
    }

    toString() {
        return "rgb(" + this.r + "," + this.g + "," + this.b + ")";
    }

    toHexString() {
        return "#" + this.r.toString(16) + this.g.toString(16) + this.b.toString(16);
    }

}

export const HSL_REGEX = /^hsl\(([0-9]+),([0-9]+)%,([0-9]+)%\)$/;

export const HUE_VALUES = [
    [0, 10], // Red
    [10, 20], // Red-Orange
    [20, 40], // Orange
    [40, 50], // Orange-Yellow
    [50, 60], // Yellow
    [60, 80], // Yellow-Green
    [80, 140], // Green
    [140, 170], // Green Cyan
    [170, 190], // Cyan
    [190, 220], // Cyan-Blue
    [220, 240], // Blue
    [240, 280], // Blue-Magenta
    [280, 320], // Magenta
    [320, 330], // Magenta-Pink
    [330, 345], // Pink
    [345, 355], // Pink-Red
    [355, 361], // Red
];
export const HUE_LABELS = [
    'Red',
    'Red-Orange',
    'Orange-Brown',
    'Orange-Yellow',
    'Yellow',
    'Yellow-Green',
    'Green',
    'Green Cyan',
    'Cyan',
    'Cyan-Blue',
    'Blue',
    'Blue-Magenta',
    'Magenta',
    'Magenta-Pink',
    'Pink',
    'Pink-Red',
    'Red'
];

export const LIGHTNESS_INTERVALS = [
    'Very Dark',
    'Dark',
    'Medium',
    'Light',
    'Very Light'
];

export const SATURATION_INTERVALS = [
    'Very Dull',
    'Dull',
    'Intermediate',
    'Vivid',
    'Bright'
];


export function parseHSL(value: string): HSL {
    return HSL.fromString(value);
}

export function hexToRGBA(hex, alpha = 1) {
    let parseString = hex;
    if (hex.startsWith('#')) { parseString = hex.slice(1, 7); }
    if (parseString.length !== 6) { return null; }
    const r = parseInt(parseString.slice(0, 2), 16);
    const g = parseInt(parseString.slice(2, 4), 16);
    const b = parseInt(parseString.slice(4, 6), 16);
    if (isNaN(r) || isNaN(g) || isNaN(b)) { return null; }
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// https://www.tutorialrepublic.com/html-reference/html-color-picker.php
// https://superdevresources.com/tools/color-shades#8cb30b





/**
 *
 * http://www.w3schools.com/lib/w3color.js
 *
 */