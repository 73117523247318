import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Library } from '@library';
import { LIBRARY } from '../library';

@Pipe({
  name: 'fetchProtocol'
})
export class FetchProtocolPipe implements PipeTransform {

    constructor(@Inject(LIBRARY) private _evalLib: Library){}

    transform(protocolId: string) {
        return this._evalLib.protocols.get(protocolId);
    }
}