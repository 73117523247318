import { Injectable } from '@angular/core';
import { Organization, OrganizationService, Status, translateCommonErrorStatus } from '@core/data';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

export interface OrganizationDetailStateModel {
    orgKey: string;
    status: Status;
    organization: Organization;
}

export class InitOrganizationDetail {
    static readonly type = "[OrganizationDetail] Init";
    constructor(public orgKey: string) {}
}

export class LoadOrganizationDetail {
    static readonly type = "[OrganizationDetail] Load";
    constructor() {}
}

@State<OrganizationDetailStateModel>({
    name: "organization_detail",
    defaults: {
        orgKey: null,
        status: Status.UNINITIALIZED,
        organization: null
    }
})
@Injectable()
export class OrganizationDetailState {

    constructor(private _orgService: OrganizationService) {}

    /**
     * @param ctx State Context
     * @param action orgKey: the key of the organization , authUserKey:the user trying to access the organization detail
     */
    @Action(InitOrganizationDetail)
    initOrganizationDetail(ctx: StateContext<OrganizationDetailStateModel>, action: InitOrganizationDetail) {
        ctx.setState({
            orgKey: action.orgKey,
            status: Status.LOADING,
            organization: null,
        });

        return ctx.dispatch(new LoadOrganizationDetail);
    }

    /**
     * @param ctx State Context
     * @param action No action required
     */
    @Action(LoadOrganizationDetail, { cancelUncompleted: true })
    loadOrganizationDetail(ctx: StateContext<OrganizationDetailStateModel>) {
        const state = ctx.getState();
        ctx.patchState({
            status: Status.LOADING,
        });

        return this._orgService.get(state.orgKey)
            .pipe(
                tap(
                    organization => {
                        ctx.patchState({
                            status: Status.OK,
                            organization,
                        });
                    },
                    error => {
                        ctx.patchState({
                            status: translateCommonErrorStatus(error)
                        });
                    }
                )
            );
    }
}
