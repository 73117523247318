<ng-container *ngIf="state$ | async as state">

    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Edit Notes</h2>
        <h3 class="dialog-subtitle" *ngIf="state.data">
            Sample {{ state.data ? state.data.sample.code : '' }},
            evaluation {{ state.data ? state.data.label : '' }}
        </h3>
    </div>

    <div mat-dialog-content>

        <form id="notes_form"
              [formGroup]="formGroup"
              (ngSubmit)="attempt()"
              autocomplete="disabled"
              #notesForm="ngForm"
              novalidate>

            <div class="notes-form-container" formArrayName="notes">

                <div class="note-form" *ngFor="let noteGroup of formArray.controls; index as i"
                     [formGroupName]="i">

                    <mat-form-field appearance="standard" class="date-field">
                        <mat-label>Observed Date</mat-label>
                        <input type="text" matInput formControlName="takenAt"
                               [matDatepicker]="takenDatepicker">
                        <mat-datepicker-toggle matSuffix [for]="takenDatepicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #takenDatepicker></mat-datepicker>
                        <mat-error>Select a date</mat-error>
                        <mat-hint>{{ formArray.at(i).get('takenAt').value | amTimeAgo }}</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="message-field" floatLabel="always">
                        <mat-label>Observations</mat-label>
                        <textarea matInput
                                  formControlName="message"
                                  cdkTextareaAutosize
                                  cdkAutosizeMinRows="1"
                                  cdkAutosizeMaxRows="50"></textarea>
                        <mat-error>Enter observations (max 10k chars) or delete note</mat-error>
                    </mat-form-field>

                    <button type="button" mat-icon-button (click)="deleteNote(i)">
                        <mat-icon>{{ formArray.at(i).disabled ? 'restore_from_trash' : 'delete' }}
                        </mat-icon>
                    </button>

                </div>

            </div>

        </form>

    </div>

    <!-- Actions -->
    <pv-form-actions mat-dialog-actions
                     [form]="notesForm"
                     (cancel)="cancel()"
                     [status]="state.status">
        <button pvFormAction
                type="button"
                mat-raised-button
                color="accent"
                (click)="addNote()"
                [disabled]="formGroup.disabled">
            <mat-icon>add</mat-icon> Add Note
        </button>
    </pv-form-actions>

</ng-container>
