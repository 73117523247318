import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { takeUntil, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';


@Component({
    selector: 'pv-error-view',
    templateUrl: 'error.view.html',
    host: {
        class: 'pv-flex-view bg-primary',
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorView implements OnInit, OnDestroy {

    status$: Observable<number>;

    private _destroy$ = new Subject();

    constructor(private _route: ActivatedRoute){}

    ngOnInit(){

        this.status$ =
            this._route.paramMap
                .pipe(
                    takeUntil(this._destroy$),
                    map(params => {
                        if(params.has('status')) {
                            return parseInt(params.get('status'));
                        }
                        return 500;
                    })
                );


    }

    ngOnDestroy(){
        this._destroy$.next();
        this._destroy$.complete();
    }

}