import { Injectable, Inject } from "@angular/core";
import { Filter, FilterBuilder } from '../http/filter';
import { API_BASE_URI } from '../http/api';
import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Collection } from '../http/collection';
import { RemarkImageOption, Report, ReportDocument, ReportImage, ReportLetterhead, ReportRemark, ReportTableTemplate, ReportTemplate } from '../types/report';
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { DetailRequestBuilder, DetailRequest } from "../http/detail";
import { InitReportLetterheadUploadForm } from "@app/evaluation/components/letterhead-upload-form/letterhead-upload-form.state";


export interface ReportCreateRequest extends Partial<Report> {
    sampleKeys?: string[];
}

export interface ReportShareRequest {
    regenKey?: boolean;
    dropKey?: boolean;
    sendSelf?: boolean;
    sendContacts?: {
        name?: string;
        email: string;
    }[];
}

export interface FileAttachmentUpdateRequest {
    documents: Array<Partial<ReportDocument>>;
    sectionId: string;
    remark: Partial<ReportRemark>;
}
export interface ImageAttachmentUpdateRequest {
    images: Array<Partial<ReportImage>>;
    imageOptions: Array<RemarkImageOption>;
    sectionId: string;
    remark: Partial<ReportRemark>;
}

export interface LetterheadUpdateRequest {
    letterheads: Array<Partial<ReportLetterhead>>;
    reportKey: string;
}
export class ReportDocumentUploaded {
    static readonly type = "[ReportService] Report Document Uploaded";
    constructor(public document: ReportDocument) {}
}

export class ReportImageUploaded {
    static readonly type = "[ReportService] Report Image Uploaded";
    constructor(public image: ReportImage) {}
}

export class ReportLetterheadUploaded {
    static readonly type = "[ReportService] Report Letterhead Uploaded"
    constructor(public letterhead: ReportLetterhead) {}
}

@Injectable()
export class ReportService {

    constructor(
        @Inject(API_BASE_URI) private _baseUri: string,
        private _http: HttpClient,
        private _store: Store,
    ){}

    query(filter: Filter) {
        let params = (new FilterBuilder(filter)).getQueryParams();
        return this._http.get<Collection<Report>>(`${this._baseUri}/reports`, { params })
    }

    get(key: string, withData = true) {

        const params = { withData: withData ? '1' : '0'};

        return this._http.get<Report>(`${this._baseUri}/reports/${key}`, {params});
    }

    getRemark(reportKey: string, sectionId: string, detail?: DetailRequest) {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<ReportRemark>(`${this._baseUri}/reports/${reportKey}/remark/${sectionId}`, {params});
    }

    getRemarks(reportKey: string, detail?: DetailRequest) {
        const params = detail ? DetailRequestBuilder.queryParams(detail) : {};
        return this._http.get<ReportRemark[]>(`${this._baseUri}/reports/${reportKey}/remarks`, {params});
    }

    updateRemark(reportKey: string, model: FileAttachmentUpdateRequest) {
        return this._http.put<ReportRemark>(`${this._baseUri}/reports/${reportKey}/remark/update`, model);
    }

    updateRemarkImageOptions(reportKey: string, sectionId: string, model: ImageAttachmentUpdateRequest) {
        return this._http.put<ReportRemark>(`${this._baseUri}/reports/${reportKey}/remark/options/update/${sectionId}`, model);
    }

    create(data: ReportCreateRequest, withData = true) {
        const params = { withData: withData ? '1' : '0'};
        return this._http.post<Report>(`${this._baseUri}/reports`, data, {params});
    }

    update(key: string, model: Partial<Report>, withData = true) {
        const params = { withData: withData ? '1' : '0'};
        return this._http.put<Report>(`${this._baseUri}/reports/${key}`, model, {params});
    }

    uploadSectionDocument(key: string, sectionId: string, file: File): Observable<HttpEvent<ReportDocument>> {
        let data = new FormData();
        data.set('file', file);
        data.set('sectionId', sectionId);

        const req = new HttpRequest('POST', `${this._baseUri}/reports/${key}/documents`, data, {
            reportProgress: true
        });

        return this._http.request<ReportDocument>(req)
            .pipe(tap(result => {
                if (result instanceof HttpResponse) this._store.dispatch(new ReportDocumentUploaded(result.body));
            }));
    }

    uploadSectionImages(key: string, sectionId: string, file: File): Observable<HttpEvent<ReportImage>> {
        let data = new FormData();
        data.set('file', file);
        data.set('sectionId', sectionId);

        const req = new HttpRequest('POST', `${this._baseUri}/reports/${key}/images`, data, {
            reportProgress: true
        })

        return this._http.request<ReportImage>(req)
            .pipe(tap(result => {
                if (result instanceof HttpResponse) this._store.dispatch(new ReportImageUploaded(result.body));
            }));
    }

    delete(key: string) {
        return this._http.delete<Report>(`${this._baseUri}/reports/${key}`);
    }

    share(key: string, request: ReportShareRequest){
        return this._http.put<Report>(`${this._baseUri}/reports/${key}/share`, request);
    }

    getShared(shareKey: string){
        return this._http.get<Report>(`${this._baseUri}/reports-shared/${shareKey}`);
    }

    listTemplates(orgKey: string){
        return this._http.get<ReportTemplate[]>(`${this._baseUri}/organizations/${orgKey}/report-templates`);
    }

    listLetterheads(orgKey: string){
        return this._http.get<string[]>(`${this._baseUri}/organizations/${orgKey}/report-letterheads`);
    }

    getOrgReportLetterheads(orgKey: string) {
        return this._http.get<ReportLetterhead[]>(`${this._baseUri}/organizations/${orgKey}/org-report-letterheads`);
    }

    uploadOrgReportLetterhead(orgKey: string, reportKey:string, file: File): Observable<HttpEvent<ReportLetterhead>> {
        let data = new FormData();
        data.set('file', file);

        const req = new HttpRequest('POST', `${this._baseUri}/organizations/${orgKey}/reports/${reportKey}/org-report-letterheads/upload`, data, {
            reportProgress: true
        })

        return this._http.request<ReportLetterhead>(req)
            .pipe(tap(result => {
                if (result instanceof HttpResponse) this._store.dispatch(new ReportLetterheadUploaded(result.body));
            }));
    }

    createTemplate(data: Partial<ReportTemplate>){
        return this._http.post<ReportTemplate>(`${this._baseUri}/report-templates`, data);
    }

    updateTemplate(id: number, data: Partial<ReportTemplate>){
        return this._http.put<ReportTemplate>(`${this._baseUri}/report-templates/${id}`, data);
    }

    deleteTemplate(id: number){
        return this._http.delete<{id: number}>(`${this._baseUri}/report-templates/${id}`);
    }

    getTableTemplate(templateId: number, filter: Filter) {
        const params = {
            ...FilterBuilder.queryParams(filter),
        }

        return this._http.get<ReportTableTemplate>(`${this._baseUri}/report-table-templates/${templateId}`, { params });
    }

    listTableTemplates(orgKey: string){
        return this._http.get<ReportTableTemplate[]>(`${this._baseUri}/organizations/${orgKey}/report-table-templates`);
    }

    createTableTemplate(data: Partial<ReportTableTemplate>){
        return this._http.post<ReportTableTemplate>(`${this._baseUri}/report-table-templates`, data);
    }

    updateTableTemplate(id: number, data: Partial<ReportTableTemplate>){
        return this._http.put<ReportTableTemplate>(`${this._baseUri}/report-table-templates/${id}`, data);
    }

    deleteTableTemplate(id: number){
        return this._http.delete<{id: number}>(`${this._baseUri}/report-table-templates/${id}`);
    }
}