import { Schedule } from './_types';
import { Dictionary } from '../dictionary';
import { LIB_SCHEDULES } from './schedules';

export class ScheduleDictionary extends Dictionary<Schedule>{
    constructor(){
        super(LIB_SCHEDULES);
    }
}

export * from './_types';