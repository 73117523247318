import { Pipe, PipeTransform } from '@angular/core';
import { Evaluation, EvaluationStatus } from '@core/data';
import * as moment from 'moment';

@Pipe({
    name: 'getEvaluationStatus',
    pure: true
})
export class GetEvaluationStatusPipe implements PipeTransform {

    transform(evalu: Evaluation): EvaluationStatus {

        let now = moment(),
            start = evalu.evalStartDate ? moment(evalu.evalStartDate) : null,
            end = evalu.evalEndDate ? moment(evalu.evalEndDate) : null;

        if(start && start.isValid()){

            if(start.isAfter(now)){
                return EvaluationStatus.PENDING;
            }else if(end && end.isValid() && end.isBefore(now)){
                return EvaluationStatus.COMPLETE;
            }else{
                return EvaluationStatus.ONGOING;
            }

        }

        return EvaluationStatus.INVALID;

    }

}
