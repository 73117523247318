import { Schedule } from './_types';

export const LIB_SCHEDULES: Schedule[] = [
    {
        "id": "PEC_28S7",
        "label": "PEC-28S7",
        "description": "28 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PEC_35S7",
        "label": "PEC-35S7",
        "description": "35 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "17d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "35d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "35d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PEC_42S7",
        "label": "PEC-42S7",
        "description": "42 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "42d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "NEC_28S7",
        "label": "NEC-28S7",
        "description": "28 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "NEC_35S7",
        "label": "NEC-35S7",
        "description": "35 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "17d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "35d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "35d" },
                    { "temp": 7, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "NEC_42S7",
        "label": "NEC-42S7",
        "description": "42 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "42d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PLM_28S7",
        "label": "PLM-28S7",
        "description": "28 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PLM_35S7",
        "label": "PLM-35S7",
        "description": "35 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "17d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "35d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "35d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PLM_33R9S7",
        "label": "PLM-33R9S7 [PD9]",
        "description": "10 days @ - 0,5°C, then 9 days @ 7,5°C, then 23 days @ - 0,5°C followed by a shelf life period of 7 days @ 10°C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "9d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "9d" },
                    { "temp": -0.5, "duration": "23d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "9d" },
                    { "temp": -0.5, "duration": "23d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PLM_33R7S7",
        "label": "PLM-33R7S7 [PD7]",
        "description": "10 days @ - 0,5°C, 7 days @ 7,5°C, 23 days @ - 0,5°C followed by a shelf life period of 7 days @ 10°C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "7d" },
                    { "temp": -0.5, "duration": "23d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "7d" },
                    { "temp": -0.5, "duration": "23d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PLM_35R7S7",
        "label": "PLM-35R7S7 [PD7]",
        "description": "10 days @ - 0,5°C, 7 days @ 7,5°C, 25 days @ - 0,5°C followed by a shelf life period of 7 days @ 10°C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "7d" },
                    { "temp": -0.5, "duration": "23d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "10d" },
                    { "temp": 7.5, "duration": "7d" },
                    { "temp": -0.5, "duration": "23d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PLM_42S7",
        "label": "PLM-42S7",
        "description": "42 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "42d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "APR_28S4",
        "label": "APR-28S4",
        "description": "28 days @ -0.5˚C followed by shelf life of 4 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 10, "duration": "4d" }
                ]
            }
        ]
    },
    {
        "id": "APR_35S7",
        "label": "APR-35S7",
        "description": "35 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "17d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "35d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "35d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "APR_42S4",
        "label": "APR-42S4",
        "description": "42 days @ -0.5˚C followed by shelf life of 4 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "42d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 10, "duration": "4d" }
                ]
            }
        ]
    },
    {
        "id": "APL_42S7",
        "label": "APL-42S7",
        "description": "42 days @ -0.5˚C, extracted at 14 day intervals where each stage is followed by shelf life of 7 days @ 20˚ C respectively, for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 20, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "APL_84S7",
        "label": "APL-84S7",
        "description": "84 days @ -0.5˚C, extracted at 28 day intervals where each stage is followed by shelf life of 7 days @ 20˚ C respectively, for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "56d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "84d" },
                    { "temp": 20, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PYR_42S7",
        "label": "PYR-42S7",
        "description": "42 days @ -0.5˚C, extracted at 14 day intervals where each stage is followed by shelf life of 7 days @ 20˚ C respectively, for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 20, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "PYR_84S7",
        "label": "PYR-84S7",
        "description": "84 days @ -0.5˚C, extracted at 28 day intervals where each stage is followed by shelf life of 7 days @ 20˚ C respectively, for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "56d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "84d" },
                    { "temp": 20, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "GEN_42S7",
        "label": "GEN-42S7",
        "description": "Generic Schedule. 42 days @ -0.5˚C, extracted at 14 day intervals, followed by shelf life of 7 days @ 20˚ C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 20, "duration": "7d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 20, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "CAN_ALTO",
        "label": "CAN-ALTO",
        "description": "Alto Cannabis Curing Schedule",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Storage",
                "storage": []
            },
        ]
    },
    {
        "id": "GRA_28S7",
        "label": "GRA-28S7",
        "description": "28 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },

    {
        "id": "GRA_35S7",
        "label": "GRA-35S7",
        "description": "35 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "35d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "35d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "GRA_42S7",
        "label": "GRA-42S7",
        "description": "42 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "42d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "42d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "GRA_56S7",
        "label": "GRA-56S7",
        "description": "56 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "56d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "56d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "GRA_63S7",
        "label": "GRA-63S7",
        "description": "63 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "63d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "63d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "BLU_21S7",
        "label": "BLU-21S7",
        "description": "28 days @ 0.5˚C followed by shelf life of 7 days @ 10˚C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": 0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": 0.5, "duration": "28d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "CHR_28S4",
        "label": "CHR-28S4",
        "description": "28 days @ -0.5˚C followed by shelf life of 4 days @ 10˚C for early cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "14d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "28d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "28d" },
                    { "temp": 10, "duration": "4d" }
                ]
            }
        ]
    },
    {
        "id": "CHR_35S7",
        "label": "CHR-35S7",
        "description": "35 days @ -0.5˚C followed by shelf life of 7 days @ 10˚C for mid to late cultivars",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "17d" }
                ]
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": -0.5, "duration": "35d" }
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": -0.5, "duration": "35d" },
                    { "temp": 10, "duration": "7d" }
                ]
            }
        ]
    },
    {
        "id": "CIT_28S7",
        "label": "CIT-28S7",
        "description": "28 days @ -0.6 ̊C followed by shelf life of 7 - 14 days @ 21 - 24 ̊C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": [
                    { "temp": -0.6, "duration": "28d" }
                ]
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": 21, "duration": "7d" },
                    { "temp": 24, "duration": "14d" },
                ]
            }
        ]
    },
    {
        "id": "CIT_28S4",
        "label": "CIT-28S4",
        "description": "28 days @ 2 and/or 4 ̊C followed by shelf life of 7 - 14 days @ 21 - 24 ̊C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": [
                    { "temp": 2, "duration": "28d" },
                    { "temp": 4, "duration": "28d" },
                ]
            },
            {
                "label": "Mid-Storage",
                "storage": [
                    { "temp": 21, "duration": "7d" },
                    { "temp": 24, "duration": "14d" },
                ]
            }
        ]
    },
    {
        "id": "AVO_28S10",
        "label": "AVO-28S10",
        "description": "7 days @ 7,5°C, then 14 days @ 5,5°C, then 7 days @ 3,5°C followed by a shelf life period of 10 days @ 20°C",
        "evaluations": [
            {
                "label": "Pre-Storage",
                "storage": []
            },
            {
                "label": "Post-Storage",
                "storage": [
                    { "temp": 7.5, "duration": "7d" },
                    { "temp": 5.5, "duration": "14d" },
                ]
            },
            {
                "label": "Post-Shelflife",
                "storage": [
                    { "temp": 3.5, "duration": "7d" },
                    { "temp": 20, "duration": "10d" }
                ]
            }
        ]
    },
]