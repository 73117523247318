import { Characteristic, CharacteristicType } from './_types';


export const LIB_CHARS_AVOCADO_TREE: Characteristic[] = [
    // ORIGIN
    {
        id: "tree_race_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_origin",
        label: "Origin",

        params: {
            options: [
                { value: "mexican", label: "Mexican" },
                { value: "west_indian", label: "West Indian" },
                { value: "guatemalan", label: "Guatemalan" },
                { value: "mexican_west_indian_hybrid", label: "Mexican/West Indian Hybrid" },
                { value: "west_indian_guatemalan_hybrid", label: "West Indian/Guatemalan Hybrid" },
                { value: "guatemalan_mexican_hybrid", label: "Guatemalan/Mexican Hybrid" },
                { value: "unkown", label: "Unkown" },
            ]
        }
    },

    // PHENOLOGY

    {
        id: "date_ispr_bud_swell_avo",
        type: CharacteristicType.Event,
        label: "Beginning of Bud Swell",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#673AB7"
        }
    },
    {
        id: "date_espr_bud_swell_avo",
        type: CharacteristicType.Event,
        label: "End of Bud Swell",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#673AB7"
        }
    },
    {
        id: "date_ispr_bud_break_avo",
        type: CharacteristicType.Event,
        label: "Beginning of Bud Break",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#4CAF50"
        }
    },
    {
        id: "date_espr_bud_break_avo",
        type: CharacteristicType.Event,
        label: "End of Bud Break",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#4CAF50"
        }
    },
    {
        id: "date_spr_leaves_full_avo",
        type: CharacteristicType.Event,
        label: "All leaves fully expanded",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#7E57C2"
        }
    },
    {
        id: "date_spr_sylleptic_shoot_avo",
        type: CharacteristicType.Event,
        label: "Sylleptic shoots visible",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_spr_90_shoot_avo",
        type: CharacteristicType.Event,
        label: "90% or more of final shoot length",
        categoryId: "avo_tree_spr_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_irbs_avo",
        type: CharacteristicType.Event,
        label: "Beginning of Reproductive Bud Swell",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_erbs_avo",
        type: CharacteristicType.Event,
        label: "End of Reproductive Bud Swell",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_rbb_avo",
        type: CharacteristicType.Event,
        label: "Reproductive Bud Break",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_10_flower_avo",
        type: CharacteristicType.Event,
        label: "10% Flowering",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "date_50_flower_avo",
        type: CharacteristicType.Event,
        label: "50% Flowering",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "date_90_flower_avo",
        type: CharacteristicType.Event,
        label: "90% Flowering",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#EF6C00"
        }
    },
    {
        id: "date_ifa_avo",
        type: CharacteristicType.Event,
        label: "1st Fruitlet abscission",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#26A69A"
        }
    },
    {
        id: "date_50_size_avo",
        type: CharacteristicType.Event,
        label: "50% Final Size",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#00695C"
        }
    },
    {
        id: "date_90_size_avo",
        type: CharacteristicType.Event,
        label: "90% Final Size",
        categoryId: "avo_tree_reproductive_phenology",
        params:{
            color: "#00695C"
        }
    },
    {
        id: "date_isum_bud_swell_avo",
        type: CharacteristicType.Event,
        label: "Beginning of Bud Swell",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#673AB7"
        }
    },
    {
        id: "date_esum_bud_swell_avo",
        type: CharacteristicType.Event,
        label: "End of Bud Swell",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#673AB7"
        }
    },
    {
        id: "date_isum_bud_break_avo",
        type: CharacteristicType.Event,
        label: "Beginning of Bud Break",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#4CAF50"
        }
    },
    {
        id: "date_esum_bud_break_avo",
        type: CharacteristicType.Event,
        label: "End of Bud Break",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#4CAF50"
        }
    },
    {
        id: "date_sum_leaves_full_avo",
        type: CharacteristicType.Event,
        label: "All leaves fully expanded",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#7E57C2"
        }
    },
    {
        id: "date_isum_sylleptic_shoot_avo",
        type: CharacteristicType.Event,
        label: "First Sylleptic shoot visible",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#00838F"
        }
    },
    {
        id: "date_sum_sylleptic_shoots_avo",
        type: CharacteristicType.Event,
        label: "Sylleptic shoots visible",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#43A047"
        }
    },
    {
        id: "date_esum_sylleptic_shoot_avo",
        type: CharacteristicType.Event,
        label: "End of shoot extension",
        categoryId: "avo_tree_sum_phenology",
        params:{
            color: "#E65100"
        }
    },

    // GROWTH AND ARCHITECTURE
    {
        id: "tree_branch_habit_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_arch",
        label: "Branching Habit",
        altLabels: ["Tree Form", "Plant Form"],
        params: {
            options: [
                { value: "columnar", label: "Columnar" },
                { value: "pyramidal", label: "Pyramidal" },
                { value: "obovate", label: "Obovate" },
                { value: "rectangular", label: "Rectangular" },
                { value: "circular", label: "Circular" },
                { value: "semicircular", label: "Semicircular" },
                { value: "semielliptic", label: "Semielliptic" },
                { value: "irregular", label: "Irregular" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "tree_branch_pattern_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_arch",
        label: "Branching Pattern",
        params: {
            options: [
                { value: "extensive", label: "Extensive" },
                { value: "intensive", label: "Intensive" },
                { value: "both", label: "Both" },
            ]
        }
    },
    {
        id: "tree_branch_dist_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_arch",
        label: "Distrobution of Branches",
        params: {
            options: [
                { value: "ascendant", label: "Ascendant" },
                { value: "irregular", label: "Irregular" },
                { value: "verticillate", label: "Verticillate" },
                { value: "axial", label: "Axial" },
                { value: "horizontal", label: "Horizontal" },
            ]
        }
    },
    {
        id: "tree_branch_crotch_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_arch",
        label: "Distrobution of Branches",
        params: {
            options: [
                { value: "acute", label: "Acute" },
                { value: "obtuse", label: "Obtuse" },
            ]
        }
    },
    {
        id: "tree_grow_potent_avo",
        type: CharacteristicType.Interval,
        label: "Growth Potential",
        categoryId: "avo_tree_arch",
        altLabels: ["Growth Vigour", "Tree Vigour"],
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_branch_dense_avo",
        type: CharacteristicType.Interval,
        label: "Branching Density",
        categoryId: "avo_tree_arch",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Weak",
                "Weak",
                "Intermediate",
                "Strong",
                "Very Strong"
            ]
        }
    },
    {
        id: "tree_yng_leaf_colour_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_arch",
        label: "Young Leaf Colour",
        params: {
            options: [
                { value: "yellow", label: "Yellow" },
                { value: "red", label: "Red" },
                { value: "green", label: "Green" },
            ]
        }
    },
    {
        id: "tree_mat_leaf_colour_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_arch",
        label: "Mature Leaf Colour",
        params: {
            options: [
                { value: "light_green", label: "Light Green" },
                { value: "green", label: "Green" },
                { value: "dark_green", label: "Dark Green" },
            ]
        }
    },

    // FLOWERING

    {
        id: "tree_flower_type_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_flower",
        label: "Flower Type",
        params: {
            options: [
                { value: "typa_a", label: "Type A" },
                { value: "type_b", label: "Tybe B" },
            ]
        }
    },
    {
        id: "tree_flower_dense_avo",
        type: CharacteristicType.Interval,
        label: "Inflorescence Density",
        categoryId: "avo_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "No Flowering",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_flower_distrib_avo",
        type: CharacteristicType.Interval,
        label: "Inflorescence Distribution",
        categoryId: "avo_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Uneven",
                "Uneven",
                "Intermediate",
                "Even",
                "Very Even"
            ]
        }
    },
    {
        id: "tree_flower_pos_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_flower",
        label: "Inflorescence Position",
        params: {
            options: [
                { value: "terminal", label: "Terminal" },
                { value: "sub_terminal", label: "Sub-terminal" },
                { value: "axillary", label: "Axillary" },
                { value: "other", label: "Other" },
            ]
        }
    },
    {
        id: "tree_flower_color_avo",
        type: CharacteristicType.Color,
        categoryId: "avo_tree_flower",
        label: "Flower Colour",
        params: {
            palette: [
                { h: 57, s: 100, l: 91, label: "Cream" },
                { h: 57, s: 100, l: 69, label: "Yellow" },
                { h: 101, s: 38, l: 63, label: "Green" },
                { h: 33, s: 46, l: 56, label: "Brown" },
            ]
        }
    },
    {
        id: "tree_flower_absciss_avo",
        type: CharacteristicType.Interval,
        label: "Flower Abscission",
        categoryId: "avo_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Low",
                "Intermediate",
                "High",
                "Severe"
            ]
        }
    },
    {
        id: "tree_flower_second_avo",
        type: CharacteristicType.Interval,
        label: "Secondary Flowering",
        categoryId: "avo_tree_flower",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Little",
                "Little",
                "Intermediate",
                "Intensive",
                "Very Intensive"
            ]
        }
    },

    // FRUIT SET

    {
        id: "tree_bear_precocity_avo",
        type: CharacteristicType.Interval,
        label: "Precocity",
        categoryId: "avo_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Late",
                "Intermediate",
                "Early"
            ]
        }
    },
    {
        id: "tree_bear_potent_avo",
        type: CharacteristicType.Interval,
        label: "Fruit Set Potential",
        altLabels: ["Crop Load"],
        categoryId: "avo_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Low",
                "Low",
                "Optimal",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_fruit_drop_avo",
        type: CharacteristicType.Interval,
        label: "Fruit Drop",
        categoryId: "avo_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_preharvest_drop_avo",
        type: CharacteristicType.Interval,
        label: "Pre-Harvest Drop",
        categoryId: "avo_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Very Low",
                "Low",
                "Intermediate",
                "High",
                "Very High"
            ]
        }
    },
    {
        id: "tree_frut_habit_avo",
        type: CharacteristicType.Nominal,
        label: "Fruit Habit",
        categoryId: "avo_tree_bear",
        params: {
            options: [
                { value: "single_isolated_fruit", label: "Single Isolated Fruit" },
                { value: "clusters", label: "Clusters" },
            ]
        }
    },
    {
        id: "tree_frut_shape_avo",
        type: CharacteristicType.Nominal,
        label: "Fruit Shape",
        categoryId: "avo_tree_bear",
        params: {
            options: [
                { value: "oblate", label: "Oblate" },
                { value: "spheroid", label: "Spheroid" },
                { value: "high_spheroid", label: "High Spheroid" },
                { value: "elipsoid", label: "Elipsoid" },
                { value: "narrowly_obovate", label: "Narrowly Obovate" },
                { value: "obovate", label: "Obovate" },
                { value: "pyriform", label: "Pyriform" },
                { value: "clavate", label: "Clavate" },
                { value: "rhomboidal", label: "Rhomboidal" },
            ]
        }
    },
    {
        id: "tree_pick_count_avo",
        type: CharacteristicType.Nominal,
        label: "Number of Picks",
        categoryId: "avo_tree_bear",
        params: {
            options: [
                { value: "pick_1", label: "1" },
                { value: "pick_2", label: "2" },
                { value: "pick_3", label: "3" },
                { value: "pick_4", label: "4" },
                { value: "pick_more", label: "5+" },
            ]
        }
    },
    {
        id: "tree_bear_alternate_avo",
        type: CharacteristicType.Interval,
        label: "Alternate Bearing",
        altLabels: ["Biennial Bearing"],
        categoryId: "avo_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Consistent",
                "Consistent",
                "Intermediate",
                "Alternating",
                "Very Alternating"
            ]
        }
    },
    {
        id: "tree_storage_avo",
        type: CharacteristicType.Interval,
        label: "Tree Storage",
        categoryId: "avo_tree_bear",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Very Poor",
                "Poor",
                "Average",
                "Good",
                "Excellent"
            ]
        }
    },

    // PESTS, DISEASES & DISORDERS

    {
        id: "tree_pest_detect_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_disorder",
        label: "Pests Detected",
        params: {
            options: [
                { value: "fruit_fly", label: "Fruit Fly" },
                { value: "false_codling_moth", label: "False Codling Moth" },
                { value: "thrips", label: "Thrips" },
                { value: "citrus_looper", label: "Citrus Looper" },
                { value: "stink_bugs", label: "Stink Bugs" },
                { value: "spider_mite", label: "Spider Mite" },
                { value: "mealybug", label: "Mealybug" },
                { value: "ambrosia_beetle", label: "Ambrosia Beetle" },
                { value: "tea_red_mite", label: "Tea Red Mite" },
                { value: "weevil", label: "Weevil" },
                { value: "brown_avocado_mite", label: "Brown Avocado Mite" },
                { value: "avocado_scale", label: "Avocado Scale" },
                { value: "coconut_bug", label: "Coconut Bug" },
                { value: "tip_wilter", label: "Tip Wilter" },
                { value: "avocado_bug", label: "Avocado Bug" },
                { value: "citrus_leafhopper", label: "Citrus Leafhopper" },
                { value: "leaf_roller", label: "Leaf Roller" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "tree_disease_detect_avo",
        type: CharacteristicType.Nominal,
        categoryId: "avo_tree_disorder",
        label: "Disease Detected",
        params: {
            options: [
                { value: "anthracnose_fruit_rot", label: "Anthracnose Fruit Rot" },
                { value: "armillaria_root_rot", label: "Armillaria Root Rot" },
                { value: "avocado_black_streak", label: "Avocado Black Streak" },
                { value: "phytophthora_root_rot", label: "Phytophthora Root Rot" },
                { value: "cercospora_spot", label: "Cercospora Spot" },
                { value: "dothiorella_rot", label: "Dothiorella Rot" },
                { value: "sooty_mold", label: "Sooty Mold" },
                { value: "fusarium_dieback_wilt", label: "Fusarium Dieback/Wilt" },
                { value: "powdery_mildew", label: "Powdery Mildew" },
                { value: "sunblotch", label: "Sunblotch" },
                { value: "colletotrichum_speckle", label: "Colletotrichum Speckle" },
                { value: "bacterial_canker", label: "Bacterial Canker" },
                { value: "phomopsis_spot", label: "Phomopsis Spot" },
                { value: "scab", label: "Scab" },
                { value: "sooty_blotch", label: "Sooty Blotch" },
                { value: "verticillium_wilt", label: "Verticillium Wilt" },
                { value: "black_spot", label: "Black Spot" },
                { value: "other", label: "Other" },
                { value: "none", label: "None" },
            ]
        }
    },
    {
        id: "tree_overall_disease_avo",
        type: CharacteristicType.Interval,
        label: "Overall Disease Presence",
        categoryId: "avo_tree_disorder",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },

    // ADAPTABILITY

    {
        id: "tree_flower_span_avo",
        type: CharacteristicType.Interval,
        label: "Flower Burst Span",
        categoryId: "avo_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Brief",
                "Prolonged",
                "Extended"
            ]
        }
    },
    {
        id: "tree_rain_suscept_avo",
        type: CharacteristicType.Interval,
        label: "Rain Susceptibility",
        categoryId: "avo_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Resistant",
                "Intermediate",
                "Susceptible"
            ]
        }
    },
    {
        id: "tree_frost_suscept_avo",
        type: CharacteristicType.Interval,
        label: "Frost Susceptibility",
        categoryId: "avo_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Resistant",
                "Intermediate",
                "Susceptible"
            ]
        }
    },
    {
        id: "tree_chll_sens_avo",
        type: CharacteristicType.Interval,
        label: "Chilling Sensitivity",
        categoryId: "avo_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Resistant",
                "Intermediate",
                "Susceptible"
            ]
        }
    },
    {
        id: "tree_sunburn_symptom_avo",
        type: CharacteristicType.Interval,
        label: "Sunburn Presence",
        categoryId: "avo_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            minDesc: "None",
            descriptors: [
                "Negligible",
                "Inconspicuous",
                "Intermediate",
                "Conspicuous",
                "Severe"
            ]
        }
    },
    {
        id: "tree_overall_adapt_avo",
        type: CharacteristicType.Interval,
        label: "Overall Adaptability",
        categoryId: "avo_tree_adapt",
        params: {
            min: 0, max: 100, step: 1,
            descriptors: [
                "Poorly Adapted",
                "Intermediate",
                "Well Adapted"
            ]
        }
    },

];