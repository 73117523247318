<ng-container *ngIf="state$ | async as state; else loader">
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">Event Invitation</h2>
        <h3 class="dialog-subtitle">Invite contacts to event</h3>
    </div>

    <div mat-dialog-content>

        <form id="tasting_events_invite_form"
            #tastingEventsInviteForm="ngForm"
            [formGroup]="formGroup"
            class="pv-form"
            (ngSubmit)="attempt()"
            novalidate
            autocomplete="no">

            <div class="form-column">
                <h4 class="form-subheader">Selected Events</h4>
                <div class="selected-event-container">
                    <div class="selected-event" *ngFor="let event of state.data">
                        <pv-image-ref
                            class="event-image"
                            [fileRef]="event.tastingsEventImage ? event.tastingsEventImage.fileRef : defaultBannerPath"
                            transformId="thumb_300"
                            height="100"
                            width="100"
                            mode="fill"
                            radius="true">
                        </pv-image-ref>
                        <div class="selected-event-info-container">
                            <div class="selected-event-info-item">
                                <span class="event-title darker">{{event.title}}</span>
                                <span class="event-subtitle">{{event.code}}</span>
                            </div>
                            <div class="selected-event-info-item">
                                <span class="event-text darker">{{event.venueName}}</span>
                                <span class="event-text sm">{{event.eventDate | date:'fullDate'}}</span>
                                <span class="event-text lightened sm">{{event.eventStartTime | date:'HH:mm'}} - {{event.eventEndTime | date:'HH:mm'}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <mat-form-field appearance="standard">
                    <mat-label>Email list</mat-label>
                    <textarea matInput rows="4" formControlName="inviteEmailList"
                        style="resize: none;"></textarea>
                    <mat-hint>Comma separated list of emails</mat-hint>
                    <mat-error>List max 255 chars</mat-error>
                </mat-form-field>

                <mat-form-field appearance="standard">
                    <mat-label>Invitation Message</mat-label>
                    <textarea matInput rows="4" formControlName="inviteMessage"
                        style="resize: none;"></textarea>
                    <mat-hint>Invitation message for invite email</mat-hint>
                    <mat-error>Description max 255 chars</mat-error>
                </mat-form-field>
            </div>
        </form>

    </div>

    <pv-form-actions
        mat-dialog-actions
        [form]="tastingEventsInviteForm"
        (cancel)="cancel()"
        [status]="state.status"
        [tastingsEnabled]="true">
    </pv-form-actions>
</ng-container>

<ng-template #loader>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>