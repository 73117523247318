import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { HSL } from 'core/utils';
import * as moment from 'moment';


export class EvaluationValidators {


    static interval(min: number, max: number, step: number): ValidatorFn{
        return (control: AbstractControl): ValidationErrors | null => {

            const v = control.value;

            if(v === null || v === ''){
                return null;
            }

            if(!isNaN(parseFloat(v as any)) && !isNaN(Number(v))){
                const num = Number(v);
                if(num < min || num > max || num % step !== 0){
                    return {'interval': true};
                }
                return null;
            }

            return {'interval': true};

        };
    }

    static event(): ValidatorFn{
        return (control: AbstractControl): ValidationErrors | null => {

            if(control.value === null) return null;

            if( moment.isMoment(control.value) && control.value.isValid() ){
                return null;
            }
            return { 'date': true };

        };

    }

    static color(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if(control.value === null) return null;

            if(control.value instanceof HSL && control.value.isValid()){
                return null;
            }

            return { 'color': true };

        };
    }

    static measure(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            let value = control.value;

            if(value === null || value === "") return null;

            if(/^(\-|\+)?([0-9]{1,7}(\.[0-9]{1,3})?)$/.test(value)){
                value = parseFloat(value);
                if(value <= max && value >= min) return null;
            }

            return { 'measure': true };

        };
    }

}