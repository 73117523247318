import { Injectable } from '@angular/core';
import { OrganizationStats, OrganizationService, Status } from '@core/data';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

// Initializes the state and sets the orgKey to be used in the LoadOrg Action
export class InitOrganizationStats {
    static readonly type = "[AdminOrganizationStats] Init Organization Stats";
    constructor(public orgKey: string) { }
}

// Loads the organization based on the states' properties
export class LoadOrganizationStats {
    static readonly type = "[AdminOrganizationStats] Load Organization Stats";
}

// State Model
export interface OrgStatsStateModel {
    status: Status;
    key: string;
    data: OrganizationStats;
}

// Defaults
const DEFAULTS: OrgStatsStateModel = {
    status: Status.UNINITIALIZED,
    key: '',
    data: null,
}

@State<OrgStatsStateModel>({
    name: "admin_organization_stats",
    defaults: DEFAULTS,
})
@Injectable()
export class OrganizationStatsState {

    constructor(
        private _organizationService: OrganizationService,
    ) { }

    /**
     * @param ctx State Context
     * @param action orgKey: The organization key used to initialize the states' data
     * Used to set the orgKey and calls the LoadOrg Action to retrieve the data
     */
    @Action(InitOrganizationStats)
    initOrgStats(ctx: StateContext<OrgStatsStateModel>, action: InitOrganizationStats) {

        if (!action.orgKey) {
            return ctx.patchState({
                ...DEFAULTS,
                status: Status.ERROR,
            });
        }

        ctx.patchState({
            key: action.orgKey,
        });

        return ctx.dispatch(new LoadOrganizationStats);
    }

    /**
     * @param ctx State Context
     * @param action No Action Required
     * Retrieves the organization Data from the organization service to populate the state
     */
    @Action(LoadOrganizationStats, { cancelUncompleted: false })
    loadOrgStats(ctx: StateContext<OrgStatsStateModel>, action: LoadOrganizationStats) {

        ctx.patchState({
            status: Status.LOADING,
            data: null,
        });

        let state = ctx.getState();

        return this._organizationService.stats(state.key)
            .pipe(
                tap(
                    result => {
                        ctx.patchState({
                            status: Status.OK,
                            data: result,
                        });
                    },
                ),
            );
    }
}
