import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Inject } from "@angular/core";
import {CustomProtocolService, SampleMeasurement} from '@core/data';
import { CropSubject, Library, Measurement } from '@library';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LIBRARY } from '@app/evaluation/library';

interface MeasurementTableData {
    subject: CropSubject;
    measures: Measurement[];    // headings
    rows: number[][];           // 2d array of values
}

@Component({
    selector: 'pv-measurements-table',
    templateUrl: 'measurements-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    host: {
        class: 'pv-measurements-table'
    }
})
export class MeasurementsTableComponent implements OnInit, OnDestroy {

    @Input()
    set protocolId(protocolId: string) {
        this._protocolId$.next(protocolId);
    }

    @Input()
    set values(values: SampleMeasurement[]) {
        this._values$.next(values);
    }

    data$: Observable<{}>;

    private _protocolId$ = new BehaviorSubject<string>(null);
    private _values$ = new BehaviorSubject<SampleMeasurement[]>(null);


    constructor(
        @Inject(LIBRARY) private _library: Library,
        private _custom_protocolService : CustomProtocolService
    ) { }

    ngOnInit() {
        this.data$ =
            combineLatest(this._values$, this._protocolId$)
                .pipe(
                    map(changes => {
                        let [values, protocolId] = changes;
                        return this.compileData(values, protocolId);
                    })
                );
    }

    ngOnDestroy() {
        this._protocolId$.complete();
        this._values$.complete();
    }

    trackTable(index, item) {
        return item.subject.id;
    }

    private compileData(values: SampleMeasurement[], protocolId: string) {

        const tables: MeasurementTableData[] = [];

        const protocol = protocolId ? this._custom_protocolService.allProtocols().get(protocolId) : null;
        values = Array.isArray(values) ? values : [];

        const measureIds = protocol ? protocol.measures : [];

        let table: MeasurementTableData,
            measure: Measurement,
            subject: CropSubject,
            measureIndex: number;

        // group measures by subject
        // and make a table for each
        measureIds.forEach(measureId => {

            measure = this._library.measures.get(measureId);

            if (measure) {
                subject = this._library.subjects.get(measure.subjectId);
                if (!subject) subject = this._library.subjects.get('unknown');
            } else {
                subject = this._library.subjects.get('unknown');
            }

            table = tables.find(group => group.subject.id === subject.id);

            if (!table) {
                table = {
                    subject,
                    measures: [measure],
                    rows: null
                };
                tables.push(table);
            } else {
                table.measures.push(measure);
            }

        });

        if (tables.length === 0) {
            return { tables };
        }

        // sort values by index, desc
        values = values.concat().sort((a, b) => b.index - a.index);

        // fill table with values
        values.forEach(value => {

            // ignore empty values
            if (value.value === null) return;

            table = null;
            measure = null;

            // find the applicable table and measurement
            for (let ti = 0; ti < tables.length; ti++) {
                measureIndex = tables[ti].measures.findIndex(m => m.id === value.measureId);
                if (measureIndex >= 0) {
                    measure = tables[ti].measures[measureIndex];
                    table = tables[ti];
                    break;
                }
            }

            if (measure) {

                if (table.rows === null) {
                    // build the 2d array filled with null values
                    // since values are ordered by index, desc
                    // the first value we encounter will be the highest index

                    table.rows =
                        Array(value.index + 1)
                            .fill(null)
                            .map((row, i) => Array(table.measures.length).fill(null));
                }

                table.rows[value.index][measureIndex] = value.value;

            } else {
                console.warn(
                    "MeasurementsTableComponent: encountered value with unknown measure, %s:%s:%s",
                    value.measureId, value.index, value.value
                );
            }
        });

        return {
            tables
        };

    }



}



