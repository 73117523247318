<ng-container>
    <!-- Title -->
    <div mat-dialog-title class="mat-dialog-header">
        <h2 class="dialog-title">{{ editMode ? 'Edit' : 'Create' }} Group</h2>
    </div>
    <!-- Content -->
    <div mat-dialog-content>
        <!-- Form -->
        <form #organizationGroupForm="ngForm" class="pv-form single-column" [formGroup]="formGroup" (ngSubmit)="attempt()">
            <!-- Label -->
            <mat-form-field appearance="standard">
                <mat-label>Group Name</mat-label>
                <input type="text" matInput formControlName="label">
                <mat-error>Enter a group name</mat-error>
            </mat-form-field>
            <!-- Description -->
            <mat-form-field appearance="standard">
                <mat-label>Group Description</mat-label>
                <input type="text" matInput formControlName="description">
                <mat-error>Enter a group description</mat-error>
            </mat-form-field>
            <!-- Type -->
            <mat-form-field appearance="standard">
                <mat-label>Group Type</mat-label>
                <mat-select formControlName="type">
                    <ng-container *ngIf="data.fixedType">
                        <mat-option [value]="data.fixedType.value">{{ data.fixedType.name }}</mat-option>
                    </ng-container>
                    <ng-container *ngIf="!data.fixedType">
                        <mat-option *ngFor="let type of typeOptions" [value]="type.value">{{ type.name }}</mat-option>
                    </ng-container>
                </mat-select>
                <mat-error>Select a sample type</mat-error>
            </mat-form-field>
        </form>
    </div>
    <!-- Actions -->
    <pv-form-actions mat-dialog-actions [form]="organizationGroupForm" (cancel)="cancel()"></pv-form-actions>
</ng-container>
