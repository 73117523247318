import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'pv-searchbar',
    host: {
        class: 'pv-searchbar'
    },
    template: `
        <input #searchInput name="search" [class.active]="searchActive" type="text" [formControl]="searchControl">
        <button mat-icon-button (click)="toggleSearch()"><mat-icon>{{ searchActive ? 'close' : 'search' }}</mat-icon></button>
    `
})
export class SearchbarComponent implements OnInit{

    @ViewChild('searchInput', { static: true })
    searchInput: ElementRef;

    @Input('control')
    set formControl(control: FormControl){
        this.searchControl = control;
        control.disable();
    }

    searchControl: FormControl;
    searchActive = false;

    constructor(){}

    ngOnInit(){

    }

    toggleSearch(){
        this.searchActive = !this.searchActive;

        if (this.searchActive){
            if(this.searchControl) this.searchControl.reset({value: '', disabled: false});
            this.searchInput.nativeElement.focus();
        }else{
            if(this.searchControl) this.searchControl.reset({value: '', disabled: true});
        }
    }


}