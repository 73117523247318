import { Pipe, PipeTransform } from "@angular/core";
import { TastingsIndex, WeightedChar } from "library/src/tastings-indexes/_types";

@Pipe({
    name: 'descCharacteristic'
})
export class EvaluationCharacteristic implements PipeTransform {
    constructor() {}

    transform(characteristics: string, index: TastingsIndex): string {
        let char = index.weightedChars.find(char => char.id === characteristics);
        return char ? char.label : "N/A";
    }
}